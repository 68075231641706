import _ from 'lodash';

import BubbleChart from '@analytics-components/charts/BubbleChart';
import { ReleaseOrCandidate } from '@analytics-components/charts/Tooltip';
import { SimpleKPI, UNITS } from '@analytics-components/insights/KPI';
import { palette } from '@analytics-pages/delivery-pipeline/insights/release';
import { BIG_RELEASE_THRESHOLD } from '@analytics-pages/delivery-pipeline/insights/release/releaseSize';
import { dateTime } from '@common-services/dateService';
import { github } from '@common-services/format';

const BUBBLES_MAX_NUMBER_OF_RELEASES = 15;
const BUBBLES_MAX_NUMBER_OF_WAITING = 15;
const BUBBLES_USE_CONSTANT_SIZE = false;

const MAX_BUBBLE_SIZE = 6;
const BUBBLES_SIZE_RANGE = BUBBLES_USE_CONSTANT_SIZE
  ? [MAX_BUBBLE_SIZE, MAX_BUBBLE_SIZE]
  : [2, MAX_BUBBLE_SIZE];
const getBubbleSize = (size) => (BUBBLES_USE_CONSTANT_SIZE ? MAX_BUBBLE_SIZE : size);

const releaseSizeBubbles = {
  plumber: (fetchedData, cachedData, apiContext) => {
    // TODO: DEPRECATED::GLOBAL_RELEASES_ABUSE
    // See alternatives below
    const releases = fetchedData.releases?.data;
    const chartDataReleases = _(releases).map((release) => {
      const released = true;
      const name = release.name;
      const repository = github.repoName(release.repository);
      const url = release.url;
      const age = 1000 * parseInt(release.age);
      const added_lines = release.added_lines;
      const deleted_lines = release.deleted_lines;
      const prs = release.prs.length;

      const tooltip = {
        released,
        repository,
        name,
        url,
        added_lines,
        deleted_lines,
        prs,
        age,
      };

      return {
        released,
        loc: release.added_lines + release.deleted_lines,
        prs,
        age,
        size: getBubbleSize(prs),
        tooltip,
      };
    });

    const chartDataWaiting = fetchedData.reposWaiting
      .slice(0, BUBBLES_MAX_NUMBER_OF_WAITING)
      .map((pr) => ({
        ...pr,
        released: false,
        loc: pr.added_lines + pr.deleted_lines,
        size: getBubbleSize(pr.prs),
        tooltip: {
          ...pr,
          repository: github.repoName(pr.repository),
        },
      }));

    const bigReleases = chartDataReleases.filter((release) => release.prs >= BIG_RELEASE_THRESHOLD);

    // TODO: DEPRECATED::GLOBAL_RELEASES_ABUSE
    // API should let us to paginate 15 first releases from /filter/releases, sorted by release.prs.length
    const releasesBubbles = chartDataReleases
      .orderBy(['prs'], ['desc'])
      .take(BUBBLES_MAX_NUMBER_OF_RELEASES)
      .value();

    return {
      chartData: releasesBubbles.concat(...chartDataWaiting),
      KPIsData: {
        // TODO: DEPRECATED::GLOBAL_RELEASES_ABUSE (ready, DEV-622)
        // API should let define ticks for /histogram/releases::release-prs[ticks:[1, 5, 15, 30, 50]],
        // and then use frequencies[4]
        // see https://athenianco.atlassian.net/browse/DEV-622
        bigReleasesCount: bigReleases.size(),

        // TODO: DEPRECATED::GLOBAL_RELEASES_ABUSE (ready, DEV-622)
        // API should provide either:
        // 1. a way to filter Releases by `prs.length` when calling `/filter/releases` +
        //    accept that list of Releases when calling `/metrics/releases::release-age`
        // 2. a way to filter releases by `prs.length` when calling `/metrics/releases::release-age`
        // 3. a way to have secondary metrics in addition to the one the ticks are defined on
        //    see https://athenianco.atlassian.net/browse/DEV-622
        bigPrsAvgAge: bigReleases.meanBy('age'),
      },
      axisKeys: {
        x: 'loc',
        y: 'prs',
        size: 'size',
      },
      sizeRange: BUBBLES_SIZE_RANGE,
      grouper: 'released',
      groups: {
        false: {
          title: 'Waiting for Release',
          color: palette.duo[1],
        },
        true: {
          title: 'Releases',
          color: palette.duo[0],
        },
      },
    };
  },
  factory: (computed) => {
    const {
      chartData,
      grouper,
      groups,
      axisKeys,
      sizeRange,
      KPIsData: { bigReleasesCount, bigPrsAvgAge },
    } = computed;

    return {
      content: [
        {
          empty: chartData.length === 0,
          chart: {
            component: BubbleChart,
            title: 'Largest releases',
            description: `Shed light on the largest releases which slow down the delivery pipeline.`,
            params: {
              data: chartData,
              extra: {
                grouper: grouper,
                groups: groups,
                axisKeys: axisKeys,
                sizeRange: sizeRange,
                axisLabels: {
                  x: 'Lines of Code',
                  y: 'Pull Requests',
                },
                zoomed: true,
                isLogScale: true,
                tooltip: {
                  template: ReleaseOrCandidate,
                  persistent: true,
                },
              },
            },
          },
          kpis: [
            {
              title: { text: 'Releases', bold: true },
              subtitle: { text: `with ${BIG_RELEASE_THRESHOLD}+ pull requests` },
              component: SimpleKPI,
              params: {
                value: bigReleasesCount,
                unit: UNITS.release,
              },
            },
            {
              title: { text: 'Average release cycle', bold: true },
              subtitle: { text: `for ${BIG_RELEASE_THRESHOLD}+ pull requests releases` },
              component: SimpleKPI,
              params: {
                value: dateTime.human(bigPrsAvgAge),
              },
            },
          ],
        },
      ],
    };
  },
};

export default releaseSizeBubbles;
