import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '@lib/Button';
import { Input } from '@lib/Input';
import Selector from '@lib/Selector';
import { optionType } from '@lib/Selector/types';
import { AvatarType } from '@lib/avatar';
import { DropdownSelectorBodyTs } from '@lib/dropdown-selector/components/dropdown-selector-body/dropdownSelectorBody';
import FormattedLabel from '@lib/dropdown-selector/components/formatted-label';
import { Icon, icons } from '@lib/icon';
import { MultiTagLabel } from '@lib/tag-label';

import {
  dropdownWrapperStyles,
  fieldStyles,
  noCheckboxesHeaderStyles,
  selectorWrapperStyles,
} from './styles';

const DropdownSelector = React.forwardRef(
  (
    {
      disabled = false,
      isOpen = false,
      options,
      placeholder = '',
      inputValue = '',
      theme,
      selected = [],
      applied = [],
      width,
      withAllOption = false,
      noAvatar = false,
      noCheckboxes = false,
      updatedSelection = false,
      shouldRenderOption,
      onSelectedChange,
      onApply,
      onCancel,
      onClick,
      onInputChange,
      noCheckboxesTagLabelRender,
    },
    ref
  ) => {
    const { fieldRef, selectorRef } = ref;

    return (
      <div css={dropdownWrapperStyles} data-cy="dropdown-selector">
        <DropdownSelectorHeader
          disabled={disabled}
          placeholder={placeholder}
          theme={theme}
          selected={selected}
          applied={applied}
          width={width}
          noAvatar={noAvatar}
          noCheckboxes={noCheckboxes}
          ref={fieldRef}
          inputValue={inputValue}
          updatedSelection={updatedSelection}
          onInputChange={onInputChange}
          onApply={onApply}
          onClick={onClick}
          noCheckboxesTagLabelRender={noCheckboxesTagLabelRender}
        />
        {noCheckboxes && (
          <DropdownSelectorBody
            isOpen={isOpen}
            options={options}
            selected={selected}
            applied={applied}
            withAllOption={withAllOption}
            noAvatar={noAvatar}
            noCheckboxes={noCheckboxes}
            inputValue={inputValue}
            shouldRenderOption={shouldRenderOption}
            ref={selectorRef}
            onSelectedChange={onSelectedChange}
            onApply={!noCheckboxes ? onApply : null}
            onCancel={onCancel}
          />
        )}
        {isOpen && !noCheckboxes && (
          <DropdownSelectorBodyTs
            ref={selectorRef}
            options={options}
            applied={applied}
            onApply={onApply}
            onCancel={onCancel}
          />
        )}
      </div>
    );
  }
);

const DropdownSelectorHeader = React.forwardRef(
  (
    {
      disabled,
      placeholder,
      theme,
      selected,
      applied,
      width,
      noAvatar,
      noCheckboxes,
      inputValue,
      updatedSelection,
      onInputChange,
      onApply,
      onClick,
      noCheckboxesTagLabelRender,
    },
    ref
  ) => {
    return noCheckboxes ? (
      <div css={noCheckboxesHeaderStyles} ref={ref.wrapperRef} onClick={onClick}>
        <DropdownSelectorNoCheckboxesHeader
          disabled={disabled}
          placeholder={placeholder}
          theme={theme}
          selected={selected}
          applied={applied}
          width={width}
          inputValue={inputValue}
          updatedSelection={updatedSelection}
          onInputChange={onInputChange}
          onApply={onApply}
          onClick={onClick}
          noCheckboxesTagLabelRender={noCheckboxesTagLabelRender}
          ref={ref.inputRef}
        />
      </div>
    ) : (
      <div css={fieldStyles(disabled, applied)} ref={ref.wrapperRef} onClick={onClick}>
        <DropdownSelectorWithCheckboxesHeader
          disabled={disabled}
          placeholder={placeholder}
          theme={theme}
          selected={selected}
          applied={applied}
          noAvatar={noAvatar}
          width={width}
          inputValue={inputValue}
          onInputChange={onInputChange}
          onClick={onClick}
        />
      </div>
    );
  }
);

const DropdownSelectorNoCheckboxesHeader = React.forwardRef(
  (
    {
      disabled,
      placeholder,
      theme,
      selected,
      applied,
      width,
      inputValue,
      updatedSelection,
      onInputChange,
      onApply,
      onClick,
      noCheckboxesTagLabelRender,
    },
    ref
  ) => {
    // TODO: this logic shouldn't be here in the UI part of the organism.
    // Since this will be refactored by splitting the two versions of the dropdown (with
    // and without checkboxes), this can be postponed. The lack of integration tests
    // are currently the only blockers for splitting this.
    const groupedSelection = _(selected)
      .map((opt) => ({ ...opt, group: opt.group || '' }))
      .groupBy('group')
      .value();
    return (
      <div>
        <Input
          disabled={disabled}
          placeholder={selected?.length > 0 ? '' : placeholder}
          withSearchIcon={true}
          initialValue={inputValue}
          onClick={onClick}
          onChange={onInputChange}
          ref={ref}
        >
          {/* TODO: this doesn't handle when the space is not enough. */}
          {selected?.length > 0 && (
            <MultiTagLabel
              avatarType={AvatarType.none}
              disabled={disabled}
              items={_(groupedSelection)
                .map((sel, group) => ({
                  selected: sel,
                  group,
                  label: noCheckboxesTagLabelRender(group, sel),
                }))
                .value()}
              onRemove={(item) => {
                const others = _(groupedSelection)
                  .filter((sel, group) => group !== item.group)
                  .flatMap((v) => v)
                  .value();
                onApply({ applied: others });
              }}
            />
          )}
        </Input>
        {updatedSelection && (
          <div className="apply-button-wrapper">
            <Button label="Apply" onClick={() => onApply({ applied: selected })} />
          </div>
        )}
      </div>
    );
  }
);

const DropdownSelectorWithCheckboxesHeader = ({ placeholder, theme, applied, noAvatar, width }) => {
  return (
    <>
      {!applied?.length && (
        <div className="ath-dropdown-placeholder" data-cy="dropdown-selector-placeholder">
          {placeholder}
        </div>
      )}
      <div className="ath-dropdown-value" data-cy="dropdown-selector-value">
        {applied?.length > 0 && (
          <FormattedLabel list={applied} noAvatar={noAvatar} wrapperWidth={width} />
        )}
      </div>
      <div className="ath-dropdown-icon-wrapper">
        <Icon color={theme.color.neutral[80]} icon={icons.caret_down} size={8} />
      </div>
    </>
  );
};

const DropdownSelectorBody = React.forwardRef(
  (
    {
      isOpen,
      options,
      selected,
      applied,
      withAllOption,
      noAvatar,
      noCheckboxes,
      inputValue,
      shouldRenderOption,
      onSelectedChange,
      onApply,
      onCancel,
    },
    ref
  ) => {
    return (
      <>
        {isOpen && (
          <div css={selectorWrapperStyles} ref={ref}>
            <Selector
              initialApplied={applied}
              options={options}
              withAllOption={withAllOption}
              noAvatar={noAvatar}
              noCheckboxes={noCheckboxes}
              inputValue={inputValue}
              updateOnInputValuePropChange={true}
              shouldRenderOption={shouldRenderOption}
              onSelectedChange={onSelectedChange}
              onApply={onApply}
              onCancel={onCancel}
            />
          </div>
        )}
      </>
    );
  }
);

const DropdownSelectorPropTypes = {
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  options: PropTypes.arrayOf(optionType).isRequired,
  placeholder: PropTypes.string,
  inputValue: PropTypes.string,
  theme: PropTypes.object,
  selected: PropTypes.arrayOf(optionType),
  applied: PropTypes.arrayOf(optionType),
  width: PropTypes.number,
  withAllOption: PropTypes.bool,
  noAvatar: PropTypes.bool,
  noCheckboxes: PropTypes.bool,
  updatedSelection: PropTypes.bool,
  shouldRenderOption: PropTypes.func,
  onSelectedChange: PropTypes.func,
  onApply: PropTypes.func,
  onCancel: PropTypes.func,
  onClick: PropTypes.func,
  onInputChange: PropTypes.func,
  noCheckboxesTagLabelRender: PropTypes.func,
};

DropdownSelector.propTypes = DropdownSelectorPropTypes;

export default DropdownSelector;
