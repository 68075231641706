import { useCallback, useEffect, useState } from 'react';

import { errorMessages } from '@align-pages/goals/single/components/goals-single-wrapper/helpers';
import log from '@common-services/logger';

import { useRemoveGoal } from '../../common/services/api/private/hooks/useRemoveGoal';
import { useSuccessRedirect } from './useSuccessRedirect';

interface IUseGoalRemoveModal {
  isModalOpen: boolean;
  openRemoveModal: () => void;
  removeGoal: () => void;
  openModal: () => void;
  closeModal: () => void;
}

export function useGoalRemoveModal(goalId: string, teamId: number): IUseGoalRemoveModal {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openRemoveModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const { mutate, isError, isSuccess } = useRemoveGoal({
    team: teamId,
    recursive: true,
  });

  const { successRedirect } = useSuccessRedirect();

  const removeGoal = useCallback(() => {
    mutate({ goalId: parseInt(goalId) });
  }, [goalId]);

  const openModal = useCallback(() => setIsModalOpen(true), []);

  const closeModal = useCallback(() => setIsModalOpen(false), []);

  useEffect(() => {
    if (isSuccess) {
      successRedirect(`Goal #${goalId} has been removed`);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      log.fatal(errorMessages.REMOVE);
    }
  }, [isError]);

  return { isModalOpen, openRemoveModal, removeGoal, openModal, closeModal };
}
