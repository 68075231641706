import React from 'react';

import noData from '@assets/images/empty-states/no-data.svg';

import { Empty } from '..';

interface Props {
  textOnly?: boolean;
}

const NoData: React.FC<Props> = React.memo(({ textOnly }) => (
  <Empty textOnly={textOnly} copy="no data" background={noData}>
    <h3>There is no data for the selected filters.</h3>
    <p>You can filter for different dates, repositories and contributors.</p>
  </Empty>
));

NoData.defaultProps = {
  textOnly: false,
};

export { NoData };
