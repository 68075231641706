import _ from 'lodash';
import React from 'react';

import Chevron from '@ui/Chevron';
import { primaryText, secondaryText } from '@ui/typography';

import { expandableStyles, headerStyles, wrapperStyles } from './styles';

const Accordion = ({ header, body, footer, rows, isExpanded, onHeaderClick }) => {
  return (
    <div css={wrapperStyles}>
      <AccordionHeader header={header} isExpanded={isExpanded} onClick={onHeaderClick} />
      <AccordionBody body={body} isOpen={isExpanded} rows={rows} />
      {footer && <AccordionFooter footer={footer} isOpen={isExpanded} />}
    </div>
  );
};

const AccordionHeader = ({ header, isExpanded, onClick }) => {
  const headerObj = _(header).isObject() ? header : { primary: header };
  const { render: headerRenderer, ...restHeader } = headerObj;
  return (
    <div onClick={onClick} css={headerStyles}>
      <div className="content-wrapper">
        <div>
          <Chevron isExpanded={isExpanded} />
        </div>
        <div>
          {headerRenderer ? (
            headerRenderer(DefaultHeaderRenderer, restHeader)
          ) : (
            <DefaultHeaderRenderer header={restHeader} />
          )}
        </div>
      </div>
    </div>
  );
};

const DefaultHeaderRenderer = ({ header }) => {
  return (
    <>
      <span css={primaryText}>{header.primary}</span>
      {header.secondary && (
        <span css={({ spacing }) => [{ marginLeft: spacing.gap['04'] }, secondaryText]}>
          {header.secondary}
        </span>
      )}
    </>
  );
};

const AccordionBody = ({ body, isOpen, rows }) => {
  return (
    <div css={expandableStyles(isOpen)}>
      {_(rows)
        .map((r, i) => {
          return (
            <React.Fragment key={i}>
              {body?.render ? body.render(r) : <DefaultBodyRenderer row={r} />}
            </React.Fragment>
          );
        })
        .value()}
    </div>
  );
};

const DefaultBodyRenderer = ({ row }) => (
  <div className="accordion-row">
    <span>{row.name}</span>
  </div>
);

const AccordionFooter = ({ footer, isOpen }) => {
  const footerObj = _(footer).isObject() ? footer : { text: footer };
  const { render: footerRenderer, ...restFooter } = footerObj;
  return (
    <div css={expandableStyles(isOpen)}>
      {footerRenderer ? footerRenderer(restFooter) : <DefaultFooterRenderer footer={restFooter} />}
    </div>
  );
};

const DefaultFooterRenderer = ({ footer }) => (
  <div className="accordion-row">
    <span>{footer.text}</span>
  </div>
);

export default Accordion;
