import { css } from '@emotion/react';

export const baseIconWrapperStyle = (size) => ({ color, spacing }) => css`
  align-items: center;
  background-color: ${color.neutral['white']};
  border-bottom: 1px solid ${color.neutral['60']};
  border-top: 1px solid ${color.neutral['60']};
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: ${size}px;
  width: ${size}px;

  & > div {
    padding: ${spacing.gap['03']};
  }
`;

export const borderStyle = ({ color }) => css`
  border-left: 1px solid ${color.neutral['60']};
  border-right: 1px solid ${color.neutral['60']};
`;

export const firstIconWrapperStyle = ({ radius }) => css`
  border-radius: ${radius.default} ${radius.sharp} ${radius.sharp} ${radius.default};
`;

export const lastIconWrapperStyle = ({ radius }) => css`
  border-radius: ${radius.sharp} ${radius.default} ${radius.default} ${radius.sharp};
`;

export const activeIconWrapperStyle = ({ color, shadow }) => css`
  background-color: ${color.ui.orange['100']};
  box-shadow: ${shadow.none};

  & > div > svg > path {
    fill: ${color.neutral['white']};
  }
`;

export const switcherWrapperStyles = ({ spacing }) => css`
  display: flex;
  flex-direction: row;
  padding: ${spacing.gap['0']};
`;
