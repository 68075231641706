import { css } from '@emotion/react';

export const titleStyles = ({ color, font, spacing, radius }) => css`
  .epic-details {
    align-items: center;
    display: flex;

    .issue-type {
      height: 17px;
      margin-left: ${spacing.gap['05']};
      margin-right: ${spacing.gap['06']};
      width: 17px;
    }

    .epic-title-box {
      max-width: 200px;

      @media (min-width: 1200px) {
        max-width: 200px;
      }

      @media (min-width: 1300px) {
        max-width: 280px;
      }

      @media (min-width: 1400px) {
        max-width: 460px;
      }
    }

    .epic-title {
      color: ${color.text.dark};

      &:hover:not(.no-link) {
        text-decoration: underline !important;
      }

      .epic-title-text {
        color: ${color.text.dark};
        display: block;
        font-size: ${font.size.s};
        font-weight: ${font.weight.regular};
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 200px;

        .epic-id {
          color: ${color.text.secondary};
        }

        span {
          font-size: ${font.size.s};
          font-weight: ${font.weight.regular};
        }

        .issues-amount {
          background-color: ${color.neutral[40]};
          border-radius: ${radius.default};
          color: ${color.neutral[80]};
          display: inline-block;
          font-size: ${font.size.default};
          font-weight: ${font.weight.regular};
          margin-left: ${spacing.gap['03']};
          opacity: 0.9;
          padding-left: ${spacing.gap['03']};
          padding-right: ${spacing.gap['03']};
        }

        @media (min-width: 1300px) {
          width: 280px;
        }

        @media (min-width: 1400px) {
          width: 460px;
        }
      }
    }
  }
`;

export const placeholderStyles = css`
  min-width: 20px;
`;

export const progressBarStyles = ({ color, font, spacing }) => css`
  align-items: center;
  display: flex;
  margin-top: calc(${spacing.gap['1px']} * 2);

  .bar-wrapper {
    width: 250px;
  }

  .progress-label {
    color: ${color.text.secondary};
    font-size: 11px;
    font-weight: ${font.weight.regular};
    margin-left: ${spacing.gap['04']};
  }
`;

export const taskDetailsStyles = ({ color, font, spacing }) => css`
  align-items: center;
  display: flex;

  .task-details {
    color: ${color.text.secondary};
    font-size: ${font.size.xs};
    font-weight: ${font.weight.light};
    margin-left: ${spacing.gap['03']};

    span {
      font-size: ${font.size.xs};
    }

    .reporter {
      color: ${color.text.dark};
    }
  }

  .pr-user-avatar {
    height: 22px;
    margin-right: ${spacing.gap['0']};
    width: 22px;
  }
`;

export const statusStyles = ({ font, spacing, radius }, statusColor) => css`
  align-items: center;
  border: 1px solid ${statusColor};
  border-radius: ${radius.default};
  color: ${statusColor};
  display: flex;
  height: 26px;
  justify-content: center;
  width: 150px;
  padding: ${spacing.gap['04']};

  span {
    font-size: 11px;
    font-weight: ${font.weight.regular};
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const infoStyles = ({ color, font, spacing }, { isChild }) => css`
  align-items: center;
  display: flex;

  svg path {
    fill: ${isChild ? color.neutral[60] : color.text.secondary};
  }

  .number {
    color: ${isChild ? color.text.secondary : color.text.dark};
    font-size: ${font.size.default};
    font-weight: ${font.weight.light};
    margin-left: ${spacing.gap['03']};
  }
`;

export const leadTimeStyles = ({ color, font }, { isChild }) => css`
  color: ${isChild ? color.text.secondary : color.text.dark};
  font-size: ${font.size.xs};
  font-weight: ${font.weight.light};
  white-space: nowrap;
`;

export const searchStyles = ({ spacing }) => css`
  &.epics-search {
    margin-bottom: ${spacing.gap['05']};
  }
`;

export const showInactiveLinkStyles = ({ color, font, spacing, radius }) => css`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-left: ${spacing.gap['05']};

  .empty-icon {
    background-color: ${color.neutral[40]};
    border-radius: ${radius.default};
    height: 17px;
    width: 17px;
  }

  .link-text {
    color: ${color.text.secondary};
    font-size: ${font.size.default};
    font-weight: ${font.weight.regular};
    margin-left: ${spacing.gap['06']};

    &:hover {
      color: ${color.text.dark};
      text-decoration: underline;
    }
  }
`;

export const filtersStyles = css`
  align-items: baseline;
  display: flex;
  justify-content: space-between;
`;

export const updatedColumnStyles = ({ color, font, spacing }) => css`
  align-items: center;
  display: flex;

  .text {
    margin-left: ${spacing.gap['05']};

    div {
      color: ${color.text.dark};
      font-size: ${font.size.xs};
      font-weight: ${font.weight.light};
      line-height: 13px;
      white-space: nowrap;
    }
  }
`;

export const tableHeaderStyles = ({ spacing }) => css`
  th {
    &:first-of-type {
      padding-left: ${spacing.gap['11']};
    }

    &:not(:first-of-type) {
      text-align: left;
    }
  }
`;

export const tableBodyStyles = ({ font, spacing }) => css`
  font-size: ${font.size.xs};

  && td {
    &:first-of-type > div {
      padding: ${spacing.gap['0']} ${spacing.gap['06']};
    }

    &:not(:first-of-type) > div {
      justify-content: flex-start;
      padding: ${spacing.gap['0']} ${spacing.gap['04']};
    }
  }
`;
