import React, { useEffect, useMemo } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import Simple from '@analytics-pages/templates/Simple';
import { useAuth0 } from '@common-context/Auth0';
import { useDemoCookie } from '@common-pages/Demo';

const Login = () => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();
  const { removeDemoCookie } = useDemoCookie();
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location]);

  useEffect(() => {
    removeDemoCookie();
  }, [removeDemoCookie]);

  useEffect(() => {
    if (!isAuthenticated) {
      const targetUrl = query.get('targetUrl');
      const loginParams = targetUrl
        ? {
            appState: { targetUrl },
          }
        : null;
      loginWithRedirect(loginParams);
    }
  }, []);

  if (loading) {
    return (
      <Simple>
        <div className="my-5">
          <p className="text-secondary">Loading...</p>
        </div>
      </Simple>
    );
  }

  if (isAuthenticated) {
    return <Redirect to={{ pathname: '/' }} />;
  } else {
    return null;
  }
};

export default Login;
