import { css } from '@emotion/react';

export const teamStyles = ({ color, font }) => css`
  display: flex;
  align-items: center;
  color: ${color.neutral['100']};
  font-size: ${font.size.l};

  > span {
    font-size: ${font.size.l};
  }
`;

export const avatarStyles = ({ spacing }) => css`
  margin-right: ${spacing.gap['03']};
`;
