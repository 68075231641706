import React, { useCallback } from 'react';

import { Accordion } from '@lib/Accordion';

import { NestedAccordionProps } from '../types';
import { NestedAccordionHeader } from './NestedAccordionHeader';
import { getOverrideHeaderStyle, getOverrideWrapperStyle } from './ui/styles';

const NestedAccordion: React.FC<NestedAccordionProps> = React.memo(
  ({ level, ancestorsNames, name, totalMembersCount, totalTeamsCount, subteams, children }) => {
    const renderHead = useCallback(
      () => (
        <NestedAccordionHeader
          name={name}
          ancestorsNames={ancestorsNames}
          totalMembersCount={totalMembersCount}
          totalTeamsCount={totalTeamsCount}
        />
      ),
      [name, totalMembersCount, totalTeamsCount]
    );
    const renderBody = useCallback(() => children, [children]);

    return (
      <Accordion
        renderHeader={renderHead}
        renderBody={renderBody}
        overrideWrapperStyle={getOverrideWrapperStyle(level)}
        overrideHeaderStyle={getOverrideHeaderStyle(level, subteams)}
      />
    );
  }
);

export { NestedAccordion };
