import React, { useCallback, useMemo } from 'react';

import { ChartRule } from '@align-pages/dashboard/components/chart-edit/components/chart-sidebar/components/chart-rule';
import { ChartParamsConfig } from '@align-pages/dashboard/config';
import { useDashboardStore } from '@align-pages/dashboard/store';
import IconButton from '@lib/IconButton';
import { ButtonMenu, ButtonMenuTypes } from '@lib/button-menu';
import { icons } from '@lib/icon';

import { wrapperStyles } from './chartProperty.styles';
import { IChartProperty } from './chartProperty.types';

export const ChartProperty: React.FC<IChartProperty> = React.memo(
  ({ data, disabledOptions, availableOptions }) => {
    const [onUpdateProperty, onRemoveParam] = useDashboardStore((state) => [
      state.onUpdateProperty,
      state.onRemoveParam,
    ]);

    const chartParamConfig = ChartParamsConfig[data?.type];

    const onSelectCallback = useCallback(
      (property) => {
        onUpdateProperty({ ...data, property });
      },
      [data]
    );

    const onFiltersChangeCallback = useCallback(
      ({ update }) => {
        onUpdateProperty({ ...data, rules: update });
      },
      [data]
    );

    const onRemoveCallback = useCallback(() => {
      onRemoveParam(data);
    }, [data]);

    const hasRules = useMemo(() => {
      if (data?.property) {
        return chartParamConfig?.properties?.find(({ value }) => value === data.property)?.rules;
      }
    }, [data]);

    return (
      <div css={wrapperStyles}>
        <div className="content">
          <div className="prop-menu">
            <ButtonMenu
              options={availableOptions}
              onSelect={onSelectCallback}
              disabledOptions={disabledOptions}
              type={ButtonMenuTypes.DROPDOWN}
              initialSelection={data?.property}
            />
          </div>
          {hasRules && (
            <ChartRule
              filterName={data.property}
              chartParamType={data?.type}
              onFiltersChange={onFiltersChangeCallback}
            />
          )}
        </div>
        <div className="remove">
          <IconButton icon={icons.delete} buttonSize={32} onClick={onRemoveCallback} />
        </div>
      </div>
    );
  }
);
