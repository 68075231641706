import { IDeveloper } from '@analytics-types/common';

export interface ITeamEditAccordionFooterCommon {
  footer: {
    text: string;
  };
  usersOptions: IDeveloper[];
  loadedAllOptions: boolean;
  onLoadAllOptionsClick: () => Promise<void>;
}

export interface ITeamEditAccordionFooter extends ITeamEditAccordionFooterCommon {
  onMembersAddition: (newMembers: IDeveloper[]) => Promise<void>;
}

export interface IOptionData {
  data: IDeveloper;
}

export enum OpeningDirection {
  up = 'up',
  down = 'down',
}

export interface ITeamEditAccordionFooterUI extends ITeamEditAccordionFooterCommon {
  isUsersPanelOpen: boolean;
  buttonId: string;
  userPanelOpeningDirection: OpeningDirection;
  filterOption: (data: IOptionData, input: string) => boolean;
  onMembersAddition: (selected: IDeveloper[], flag: boolean) => void;
  onMembersAdditionCancel: () => void;
  onUserPanelButtonClick: () => void;
}
