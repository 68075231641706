/**
 * {{ title }}
 * Server version: {{ server_version }} built on {{ build_date }} @ [{{ commit }}](https://github.com/athenianco/athenian-api/commit/{{ commit }})  Origin GitHub repository: [athenianco/api-spec](https://github.com/athenianco/api-spec).  Authorization persists between sessions. 🛡️ means that the endpoint requires account admin privileges. 👑 means that the endpoint is only accessible to Athenian authorities. <details> <summary>List of common server errors.</summary> * __500__ endpoint crashed. We reported the incident to Sentry and will fix it soon! Please include the value of `instance` in the response if you contact the support. Repeating the request will not help, most probably. * __501__ some functions are missing, either because they are not implemented yet or a non-critical runtime dependency is not satisfied. Repeating the request will not help, guaranteed. * __502__ server crashed badly, either due to a memory access violation in native code or running out of memory. We reported this incident to Sentry and will fix it soon! Try repeating the request. * __503__ server has not fully launched yet, e.g. hasn't connected to the database; server is shutting down; we are experiencing a partial outage. Try repeating the request. * __504__ endpoint took too much time and was interrupted. We reported the incident to Sentry and will see how to improve the performance. Repeating the request will not help, most probably. </details>
 *
 * Contact: admin@athenian.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The DeploymentAnalysisCode model module.
 * @module model/DeploymentAnalysisCode
 */
class DeploymentAnalysisCode {
    /**
     * Constructs a new <code>DeploymentAnalysisCode</code>.
     * Summary of the deployed code.
     * @alias module:model/DeploymentAnalysisCode
     * @param prs {Object.<String, Number>} Number of deployed pull requests per repository.
     * @param linesPrs {Object.<String, Number>} Number of changed lines in the deployed PRs per repository.
     * @param linesOverall {Object.<String, Number>} Number of changed lines in the deployment per repository.
     * @param commitsPrs {Object.<String, Number>} Number of deployed PR commits per repository.
     * @param commitsOverall {Object.<String, Number>} Number of deployed commits per repository.
     */
    constructor(prs, linesPrs, linesOverall, commitsPrs, commitsOverall) { 
        
        DeploymentAnalysisCode.initialize(this, prs, linesPrs, linesOverall, commitsPrs, commitsOverall);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, prs, linesPrs, linesOverall, commitsPrs, commitsOverall) { 
        obj['prs'] = prs;
        obj['lines_prs'] = linesPrs;
        obj['lines_overall'] = linesOverall;
        obj['commits_prs'] = commitsPrs;
        obj['commits_overall'] = commitsOverall;
    }

    /**
     * Constructs a <code>DeploymentAnalysisCode</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DeploymentAnalysisCode} obj Optional instance to populate.
     * @return {module:model/DeploymentAnalysisCode} The populated <code>DeploymentAnalysisCode</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DeploymentAnalysisCode();

            if (data.hasOwnProperty('prs')) {
                obj['prs'] = ApiClient.convertToType(data['prs'], {'String': 'Number'});
            }
            if (data.hasOwnProperty('lines_prs')) {
                obj['lines_prs'] = ApiClient.convertToType(data['lines_prs'], {'String': 'Number'});
            }
            if (data.hasOwnProperty('lines_overall')) {
                obj['lines_overall'] = ApiClient.convertToType(data['lines_overall'], {'String': 'Number'});
            }
            if (data.hasOwnProperty('commits_prs')) {
                obj['commits_prs'] = ApiClient.convertToType(data['commits_prs'], {'String': 'Number'});
            }
            if (data.hasOwnProperty('commits_overall')) {
                obj['commits_overall'] = ApiClient.convertToType(data['commits_overall'], {'String': 'Number'});
            }
            if (data.hasOwnProperty('jira')) {
                obj['jira'] = ApiClient.convertToType(data['jira'], {'String': ['String']});
            }
        }
        return obj;
    }


}

/**
 * Number of deployed pull requests per repository.
 * @member {Object.<String, Number>} prs
 */
DeploymentAnalysisCode.prototype['prs'] = undefined;

/**
 * Number of changed lines in the deployed PRs per repository.
 * @member {Object.<String, Number>} lines_prs
 */
DeploymentAnalysisCode.prototype['lines_prs'] = undefined;

/**
 * Number of changed lines in the deployment per repository.
 * @member {Object.<String, Number>} lines_overall
 */
DeploymentAnalysisCode.prototype['lines_overall'] = undefined;

/**
 * Number of deployed PR commits per repository.
 * @member {Object.<String, Number>} commits_prs
 */
DeploymentAnalysisCode.prototype['commits_prs'] = undefined;

/**
 * Number of deployed commits per repository.
 * @member {Object.<String, Number>} commits_overall
 */
DeploymentAnalysisCode.prototype['commits_overall'] = undefined;

/**
 * JIRA issues mentioned in the deployed PRs.
 * @member {Object.<String, Array.<String>>} jira
 */
DeploymentAnalysisCode.prototype['jira'] = undefined;






export default DeploymentAnalysisCode;

