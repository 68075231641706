import React, { useMemo } from 'react';

import { getReadableValue } from '@align-services/metricService';
import { PRsTableTabLabel } from '@align-types/constants';
import { Icon, icons } from '@lib/icon';
import { useTheme } from '@styles/hooks';

import { wrapperStyles } from './goalKPIs.styles';
import { IGoalKPIs } from './goalKPIs.types';

const GoalKPIs: React.FC<IGoalKPIs> = React.memo(({ metricConfig, metricsValues }) => {
  const theme = useTheme();

  const mainKPI = useMemo(
    () =>
      metricsValues?.length &&
      metricConfig &&
      getReadableValue(metricsValues[0].value.initial, metricConfig.valueType, metricConfig.unit),
    [metricConfig, metricsValues]
  );

  return (
    <div css={wrapperStyles}>
      <div className="kpi-block">
        <div className="kpi-title">{metricConfig?.displayName}</div>
        <div className="kpi-value">{mainKPI || '-'}</div>
      </div>
      <div className="kpi-block">
        <div className="kpi-title">
          {metricConfig.allKPI ? PRsTableTabLabel.ALL : PRsTableTabLabel.COMPLETED}
        </div>
        <div className="kpi-value">
          <Icon color={theme.color.neutral['60']} icon={icons.nav_pr} size={13} />
          <span>{metricsValues?.[1].value.initial.int}</span>
        </div>
      </div>
    </div>
  );
});

export { GoalKPIs };
