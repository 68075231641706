import React from 'react';

import { ActiveGoalRow } from '../active-goal-row';
import { wrapperStyles } from './activeGoal.styles';
import { IActiveGoal } from './activeGoal.types';

export const ActiveGoal: React.FC<IActiveGoal> = React.memo(({ goal, team, onArchive }) => (
  <div css={wrapperStyles}>
    <ActiveGoalRow goal={goal} team={team} onArchive={onArchive} />
  </div>
));
