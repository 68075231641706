import { featuresList, getFeature } from '@analytics-services/flags';
import { LooseObject } from '@analytics-types/common';
import { PublicPRsServices } from '@common-services/api/public/services/pullRequests';
import { IFilteredPRs } from '@common-services/api/public/types/pullRequests';
import { zip } from '@common-services/vendor/lodash';

export const fetchPaginationPlan = async (
  apiContext: LooseObject,
  token: string
): Promise<[string, string][]> => {
  const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, apiContext.features)
    ?.parameters?.value;
  const resp = await PublicPRsServices.fetchFilteredPRsPaginationPlan(
    {
      account: apiContext.account,
      interval: apiContext.interval,
      excludeInactive: apiContext.excludeInactive,
      filters: {
        repositories: apiContext.repositories,
        developers: apiContext.contributors,
        labels_include: apiContext.labels,
        ...(excludedLabels ? { labels_exclude: excludedLabels } : {}),
        jira: {
          epics: apiContext.epics,
        },
      },
    },
    token
  );
  const updatedTimestamps = resp.updated;
  const updatedFroms = updatedTimestamps.slice(0, -1);
  const updatedTos = updatedTimestamps.slice(1);
  const pages = zip(updatedFroms, updatedTos);

  return pages;
};

export const fetchPage = async (
  apiContext: LooseObject,
  updatedFrom: string,
  updatedTo: string,
  token: string,
  repositories: string[]
): Promise<IFilteredPRs> => {
  const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, apiContext.features)
    ?.parameters?.value;

  return await PublicPRsServices.fetchFilteredPRs(
    {
      account: apiContext.account,
      interval: apiContext.interval,
      excludeInactive: apiContext.excludeInactive,
      filters: {
        repositories: repositories || apiContext.repositories,
        developers: apiContext.contributors,
        labels_include: apiContext.labels,
        ...(excludedLabels ? { labels_exclude: excludedLabels } : {}),
        jira: {
          epics: apiContext.epics,
          issue_types: apiContext.issueTypes,
        },
      },
      limit: null,
      updatedFrom,
      updatedTo,
    },
    token
  );
};
