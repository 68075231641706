import { useEffect, useReducer, useState } from 'react';

import { useDataHelper } from '@analytics-context/Data';
import { useApi } from '@analytics-hooks';
import { useAuth0 } from '@common-context/Auth0';
import { isEqual } from '@common-services/vendor/lodash';

import { prsTableReducer, initPrsTableState } from '../pullRequests.reducer';
import { PRS_TABLE_STATE, IPrsDataProps, PRS_ACTION_TYPE } from '../pullRequests.types';
import { fetchPage, fetchPaginationPlan } from '../services/fetchers';

export const usePrsData = ({ repositories, stage }: IPrsDataProps) => {
  const [repos, setRepos] = useState(repositories);
  const { context: apiContext, ready: apiReady } = useApi(true);
  const { getTokenSilently } = useAuth0();
  const [state, dispatch] = useReducer(prsTableReducer, {}, initPrsTableState);
  const { get: getData, set: setData } = useDataHelper();
  const dataId = `pull-requests-table-${stage}`;

  // TODO: refactor to not use Data cache, but rely on React Query cache instead
  const getAndSetCachedData = async (dataId, func) => {
    const cached = getData(dataId);
    const data = cached && cached.done ? cached.data : await func();
    setData(dataId, data);
    return data;
  };

  // reset data in case repositories change (which means we are switching between Deploy and other stages)
  useEffect(() => {
    if (!isEqual(repositories, repos)) {
      const sid = state.sid;
      dispatch({ type: PRS_ACTION_TYPE.RESET, sid });
      setRepos(repositories);
    }
  }, [repositories, repos, state.sid]);

  useEffect(() => {
    const sid = state.sid;
    if (!apiReady) {
      if (state.state !== PRS_TABLE_STATE.WAITING_FOR_API) {
        dispatch({ type: PRS_ACTION_TYPE.RESET, sid });
      }
      return;
    }

    if (
      !isEqual(apiContext, state.api.context) &&
      state.state !== PRS_TABLE_STATE.WAITING_FOR_API
    ) {
      dispatch({ type: PRS_ACTION_TYPE.RESET, sid });
      return;
    }

    if (state.state === PRS_TABLE_STATE.WAITING_FOR_API) {
      dispatch({
        type: PRS_ACTION_TYPE.LOAD_API,
        sid,
        payload: {
          apiContext,
        },
      });
    } else if (state.state === PRS_TABLE_STATE.WAITING_FOR_PAGINATION_PLAN) {
      dispatch({ type: PRS_ACTION_TYPE.RESOLVING_PAGINATION_PLAN, sid });
      (async () => {
        const token: string = await getTokenSilently();
        const paginationPlanDataId = `${dataId}-pagination-plan`;
        const plan = await getAndSetCachedData(
          paginationPlanDataId,
          async () => await fetchPaginationPlan(state.api.context, token)
        );
        if (plan.length > 0) {
          dispatch({
            type: PRS_ACTION_TYPE.RESOLVE_PAGINATION_PLAN,
            sid,
            payload: plan,
          });
        } else {
          dispatch({
            type: PRS_ACTION_TYPE.FETCHED_NO_DATA,
            sid,
          });
        }
      })();
    } else if (state.state === PRS_TABLE_STATE.WAITING_FOR_FIRST_PAGE) {
      dispatch({ type: PRS_ACTION_TYPE.RESOLVING_FIRST_PAGE, sid });
      (async () => {
        const token: string = await getTokenSilently();
        const firstPageDataId = `${dataId}-page-1`;
        const [updatedTo, updatedFrom] = state.plan.length > 0 ? state.plan[0] : [null, null];
        const firstPage = await getAndSetCachedData(
          firstPageDataId,
          async () =>
            await fetchPage(state.api.context, updatedFrom, updatedTo, token, repositories)
        );
        dispatch({
          type: PRS_ACTION_TYPE.RESOLVE_FIRST_PAGE,
          sid,
          payload: firstPage,
        });
      })();
    } else if (state.state === PRS_TABLE_STATE.WAITING_PAGE) {
      dispatch({ type: PRS_ACTION_TYPE.RESOLVING_PAGE, sid });
      (async () => {
        const token: string = await getTokenSilently();
        const pageDataId = `${dataId}-page-${state.fetched + 1}`;
        const [updatedTo, updatedFrom] =
          state.plan.length > 0 ? state.plan[state.fetched] : [null, null];
        const page = await getAndSetCachedData(
          pageDataId,
          async () =>
            await fetchPage(state.api.context, updatedFrom, updatedTo, token, repositories)
        );
        dispatch({
          type: PRS_ACTION_TYPE.RESOLVE_PAGE,
          sid,
          payload: page,
        });
      })();
    }
  }, [
    apiContext,
    apiReady,
    repositories,
    state.api.context,
    state.fetched,
    state.plan,
    state.sid,
    state.state,
  ]);

  return {
    earlyUserInteraction: state.earlyUserInteraction,
    pagesData: state.pagesData,
    state: state.state,
  };
};
