import { breakdownConfig } from '@align-constants';
import { IUseBreakdownOptionsData } from '@align-pages/dashboard/hooks/types';
import { IBreakdownOptionItem } from '@align-pages/dashboard/reducers/breakdownOptionsReducer.types';
import { ISeriesInfo } from '@align-services/api/metricService.types';
import {
  DashboardChart,
  DashboardChartFilters,
  DashboardChartGroupBy,
  TeamTree,
} from '@common-services/api/private/generated-from-backend/models';
import { FilteredJIRAStuff } from '@common-services/api/public/generated-from-backend/models';
import { github } from '@common-services/format';
import { StringService } from '@common-services/stringService';
import { FilterType } from '@lib/filter-panel';
import { theme } from '@styles/theme';
import { IColor } from '@styles/theme/colors/colors';

interface IGroupByData {
  teams: TeamTree[];
  repositories: string[];
  jiraFilters: FilteredJIRAStuff;
}

export interface IColorSetItem {
  color: IColor;
  used?: boolean;
}

export let colorSet: IColorSetItem[] = [
  { color: theme.color.ui.orange['100'] },
  { color: theme.color.ui.lightpink['100'] },
  { color: theme.color.ui.darkpurple['100'] },
  { color: theme.color.ui.yellow['100'] },
  { color: theme.color.ui.green['100'] },
  { color: theme.color.ui.ocean['100'] },
  { color: theme.color.ui.lightblue['100'] },
  { color: theme.color.ui.blue['100'] },
];

export const toggleColor = (
  breakdownItem: IBreakdownOptionItem,
  isUsed: boolean = false
): IBreakdownOptionItem => {
  const firstUnusedColorIndex = colorSet.findIndex(({ used, color }) =>
    isUsed ? !used : color === breakdownItem.color
  );
  if (colorSet[firstUnusedColorIndex]) {
    colorSet[firstUnusedColorIndex].used = isUsed;
  }
  return { ...breakdownItem, color: isUsed ? colorSet[firstUnusedColorIndex]?.color : null };
};

export const unAssignAllColors = () => {
  colorSet = colorSet.map((colorSetItem) => ({
    ...colorSetItem,
    used: false,
  }));
};

export const getChartGroupBy = (
  filterType: FilterType,
  data: IGroupByData
): DashboardChartGroupBy => {
  const { teams, repositories, jiraFilters } = data;

  switch (filterType) {
    case FilterType.TEAMS:
      return {
        teams: teams?.map(({ id }) => id),
      };
    case FilterType.REPOSITORY:
      return { repositories };
    case FilterType.JIRA_PRIORITIES:
      return {
        jira_priorities: jiraFilters.priorities
          .sort((a, b) => a.rank - b.rank)
          .map(({ name }) => name),
      };
    case FilterType.JIRA_ISSUE_TYPES:
      return { jira_issue_types: jiraFilters.issue_types.map(({ name }) => name) };
    case FilterType.JIRA_LABELS:
      return {
        jira_labels: jiraFilters.labels
          .filter(({ kind }) => kind !== 'component')
          .map(({ title }) => title),
      };
    default:
      return {};
  }
};

export const getGroupByOptions = (
  data: DashboardChartGroupBy,
  filterType: FilterType
): IBreakdownOptionItem[] => {
  switch (filterType) {
    case FilterType.TEAMS:
      return data.teams.map((team) => ({ name: team.toString(), value: team.toString() }));
    case FilterType.REPOSITORY:
      return data.repositories.map((repository) => ({
        name: repository.toString(),
        value: repository.toString(),
      }));
    case FilterType.JIRA_PRIORITIES:
      return data.jira_priorities.map((jiraPriority) => ({
        name: jiraPriority.toString(),
        value: StringService.capitalCase(jiraPriority.toString()),
      }));
    case FilterType.JIRA_ISSUE_TYPES:
      return data.jira_issue_types.map((jiraIssueType) => ({
        name: jiraIssueType.toString(),
        value: jiraIssueType.toString(),
      }));
    case FilterType.JIRA_LABELS:
      return data.jira_labels.map((jiraLabel) => ({
        name: jiraLabel.toString(),
        value: jiraLabel.toString(),
      }));
    default:
      return [];
  }
};

export const getBreakdownOptionsName = (value: string, type: FilterType, optionsData): string => {
  switch (type) {
    case FilterType.REPOSITORY:
      return github.repoName(value);
    case FilterType.TEAMS:
      return (
        optionsData?.[FilterType.TEAMS]?.find(({ id }) => id === parseInt(value))?.name || value
      );
    case FilterType.JIRA_ISSUE_TYPES:
      return (
        optionsData?.[FilterType.JIRA_ISSUE_TYPES]?.find(
          ({ normalized_name }) => normalized_name === value
        )?.name || value
      );
    default:
      return value?.toString() || '';
  }
};

interface IGetBreakdownOptionsParams {
  breakdownOptions?: IBreakdownOptionItem[];
  groupByInfo?: DashboardChartGroupBy;
  type: FilterType;
  optionsData: IUseBreakdownOptionsData;
}

export const getBreakdownOptions = ({
  breakdownOptions,
  groupByInfo,
  type,
  optionsData,
}: IGetBreakdownOptionsParams): IBreakdownOptionItem[] => {
  if (!type) {
    return [];
  }

  return (breakdownOptions || groupByInfo?.[type] || [])?.map((item, index) => {
    const value = breakdownOptions ? item.value : item;

    return {
      name: getBreakdownOptionsName(value, type, optionsData),
      value,
      selected: breakdownOptions ? index < breakdownConfig.optionsInitialSelection : true,
      color: breakdownOptions ? null : colorSet[index]?.color,
    };
  });
};

export const getBreakdownType = (groupByData: DashboardChartGroupBy): FilterType => {
  if (groupByData.teams) {
    return FilterType.TEAMS;
  } else if (groupByData.repositories) {
    return FilterType.REPOSITORY;
  } else if (groupByData.jira_labels) {
    return FilterType.JIRA_LABELS;
  } else if (groupByData.jira_issue_types) {
    return FilterType.JIRA_ISSUE_TYPES;
  } else if (groupByData.jira_priorities) {
    return FilterType.JIRA_PRIORITIES;
  }

  return null;
};

export const getGroupByFromFilters = (filters: DashboardChartFilters): DashboardChartGroupBy => {
  return {
    ...(filters?.repositories ? { repositories: filters?.repositories } : {}),
    ...(filters?.jira?.issue_types ? { jira_issue_types: filters?.jira?.issue_types } : {}),
    ...(filters?.jira?.priorities ? { jira_priorities: filters?.jira?.priorities } : {}),
    ...(filters?.jira?.labels_include ? { jira_labels: filters?.jira?.labels_include } : {}),
  };
};

interface ISortBreakdownOptions {
  options: IBreakdownOptionItem[];
  type: FilterType;
  seriesInfo: ISeriesInfo[];
}

export const sortBreakdownOptions = ({
  options,
  type,
  seriesInfo,
}: ISortBreakdownOptions): IBreakdownOptionItem[] => {
  // jira-priorities are already sorted by their rank
  if (type === FilterType.JIRA_PRIORITIES) {
    return options;
  }

  return options.sort((a, b) => {
    const aRank = seriesInfo?.find(({ id }) => id === a.value)?.counter || 0;
    const bRank = seriesInfo?.find(({ id }) => id === b.value)?.counter || 0;
    return bRank - aRank;
  });
};

export const getFilteredChartParams = (
  chartParams: DashboardChart,
  breakdownOptions: IBreakdownOptionItem[],
  breakdownProp: FilterType
): DashboardChart => {
  const breakdownValues = breakdownOptions
    .filter((option) => option.selected && option.color)
    .map((option) => option.value);

  switch (breakdownProp) {
    case FilterType.REPOSITORY:
      return {
        ...chartParams,
        filters: {
          ...chartParams.filters,
          repositories: breakdownValues,
        },
      };
    default:
      const jiraField = breakdownProp.split('_').slice(1).join('_');
      return {
        ...chartParams,
        filters: {
          ...chartParams.filters,
          jira: {
            ...chartParams.filters.jira,
            [jiraField]: breakdownValues,
          },
        },
      };
  }
};

export * as DashboardBreakdownServices from './dashboardBreakdownServices';
