import React, { useCallback, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { ChartProperty } from '@align-pages/dashboard/components/chart-edit/components/chart-sidebar/components/chart-property';
import { ChartParamsConfig, UnsupportedChartFilters } from '@align-pages/dashboard/config';
import { useDashboardStore } from '@align-pages/dashboard/store';
import { ChartParamSelection } from '@align-pages/dashboard/types';
import { IMemoizedFilters } from '@align-pages/goals/single/components/filter-bar/filterBar.types';
import { useGetAccountDetails } from '@common-services/api/public/hooks/useGetAccountData';
import { Button, ButtonType } from '@lib/Button';
import IconButton from '@lib/IconButton';
import { FILTER_TYPE_OPTIONS } from '@lib/filter-panel';
import { icons } from '@lib/icon';

import { wrapperStyles } from './chartParam.styles';
import { IChartParam } from './chartParam.types';

export const ChartParam: React.FC<IChartParam> = React.memo(
  ({ type, name, description, properties, selectedMetric }) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const onAddParam = useDashboardStore((state) => state.onAddParam);
    const { data: accountDetailsData } = useGetAccountDetails(true);

    const onHeaderClickHandler = useCallback(() => {
      setIsExpanded(!isExpanded);
    }, [isExpanded]);

    const onAddFilterHandler = useCallback(() => {
      onAddParam(type);
    }, [type]);

    const disabledOptions = useMemo(() => {
      return properties.reduce((a, props) => [...a, props.property], []);
    }, [properties]);

    const availableOptions = useMemo(() => {
      if (accountDetailsData?.datasources) {
        return ChartParamsConfig[type]?.properties?.filter((option) => {
          return (
            !UnsupportedChartFilters[selectedMetric]?.includes(option.value) &&
            accountDetailsData?.datasources.includes(option.group)
          );
        });
      }
      return [];
    }, [type, selectedMetric, accountDetailsData]);

    // Create memoized components for all the active filters to have memoized state
    const memoizedFilters = useMemo<IMemoizedFilters>(
      () =>
        Object.values(FILTER_TYPE_OPTIONS).reduce(
          (a, filter) => ({
            ...a,
            [filter.value]: (
              <div className="property" key={uuidv4()}>
                <ChartProperty
                  data={{ id: filter.value, type, property: filter.value }}
                  disabledOptions={disabledOptions}
                  availableOptions={availableOptions}
                />
              </div>
            ),
          }),
          {}
        ),
      [availableOptions, disabledOptions]
    );

    const maxPropertyAdded = useMemo(
      () =>
        (ChartParamsConfig[type].selection === ChartParamSelection.SINGLE
          ? 1
          : availableOptions.length) === properties.length,
      [type, availableOptions, properties]
    );

    const isAddButtonAvailable = useMemo(
      () =>
        availableOptions.length > disabledOptions.length &&
        properties.every(({ id }) => id !== 'new') &&
        !maxPropertyAdded,
      [properties, availableOptions, disabledOptions]
    );

    return (
      <div css={wrapperStyles}>
        <div className="header" onClick={onHeaderClickHandler}>
          <div className="name">{name}</div>
          <IconButton icon={isExpanded ? icons.angle_down : icons.angle_right} iconSize={10} />
        </div>
        {isExpanded && (
          <div className="details">
            <div className="description">{description}</div>{' '}
            {properties?.map((prop) =>
              prop.property ? (
                memoizedFilters[prop.property]
              ) : (
                <div key={uuidv4()} className="property">
                  <ChartProperty
                    data={prop}
                    disabledOptions={disabledOptions}
                    availableOptions={availableOptions}
                  />
                </div>
              )
            )}
            {isAddButtonAvailable && (
              <Button
                label={`+ Add ${ChartParamsConfig[type]?.singular}`}
                type={ButtonType.GHOST}
                onClick={onAddFilterHandler}
              />
            )}
          </div>
        )}
      </div>
    );
  }
);
