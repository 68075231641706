import { calculateGranularity } from '@analytics-components/Prefetcher';
import HoveredBarChart from '@analytics-components/charts/HoveredBarChart';
import { contentTypes } from '@analytics-context/Allocation';
import {
  generateDisplayData,
  sortData,
} from '@analytics-pages/outcome/insights/allocation/work-types/helpers';
import { dateTime } from '@common-services/dateService';
import * as NumberService from '@common-services/numberService';
import { AddWorkTypes } from '@lib/empty/addWorkTypes';
import { theme } from '@styles/theme';

import { IAllocationType } from '../types';

const allocationChart = {
  plumber: (fetchedData, apiContext, appliedWorkTypes) => {
    // sort PRs data by their corresponding work types as the order with which it comes from API does not guarantee it
    let sortedPrsData = sortData(fetchedData?.granularPrs, appliedWorkTypes);
    // remove the last item from the data array which stands for Other section and not needed for this chart
    const granularPrsData = sortedPrsData?.slice(0, -1) || null;
    const granularIssuesData = fetchedData?.granularIssues?.slice(0, -1) || null;
    // pick the last item from data array for Other section
    const allPrs = sortedPrsData?.slice(-1)[0]?.values || null;
    const allIssues = fetchedData?.granularIssues?.slice(-1)[0]?.[0].values || null;
    const prsData = generateDisplayData({
      data: granularPrsData,
      workTypes: appliedWorkTypes,
      type: IAllocationType.prs,
      allData: allPrs,
      granular: true,
    });

    const issuesData = generateDisplayData({
      data: granularIssuesData,
      workTypes: appliedWorkTypes,
      type: IAllocationType.issues,
      allData: allIssues || [],
      granular: true,
    });

    return {
      interval: apiContext.interval,
      issuesData,
      prsData,
    };
  },
  factory: (computed, contentType) => {
    const data = contentType === contentTypes.prs ? computed.prsData : computed.issuesData;
    const totals = {};
    data.forEach((item) =>
      item.values.forEach((elem) => {
        if (totals[elem.date]) {
          totals[elem.date] += elem.value;
        } else {
          totals[elem.date] = elem.value;
        }
      })
    );

    // calculate values in percentage
    const chartData = data.map((item, index) => {
      if (index < data.length - 1) {
        return {
          ...item,
          values: item.values.map((elem) => ({
            ...elem,
            value: totals[elem.date]
              ? NumberService.round((elem.value * 100) / totals[elem.date])
              : 0,
          })),
        };
      }
      return null;
    });

    chartData.pop();

    const isEmpty =
      !chartData.length ||
      chartData.reduce(
        (accOuter, item) =>
          accOuter + item.values.reduce((accInner, elem) => accInner + elem.value, 0),
        0
      ) === 0;

    // calculate values of "Other" work types by substracting sum of values of all the other work types
    if (!!chartData.length) {
      chartData.push({
        ...data[data.length - 1],
        values: data[0].values.map((elem, index) => {
          const sumOfAll = chartData.reduce(
            (acc, item) => acc + (item.values[index]?.value || 0),
            0
          );
          return {
            ...elem,
            value:
              sumOfAll > 100 || !data[data.length - 1].values[index]?.value ? 0 : 100 - sumOfAll,
          };
        }),
      });
    }

    const colors = data.slice(0, -1).map((v) => v.color);
    colors.push(theme.color.neutral[40]);
    const customGranularity = calculateGranularity(computed.interval);
    const tickFormat = customGranularity === 'month' ? dateTime.month : dateTime.monthDay;

    return {
      meta: { title: 'Allocation by Work Type', description: '' },
      empty: isEmpty,
      emptyPage: <AddWorkTypes />,
      content: [
        {
          chart: {
            component: HoveredBarChart,
            params: {
              data: chartData,
              extra: {
                height: 400,
                color: colors,
                axisKeys: {
                  x: 'date',
                  y: 'value',
                },
                axisLabels: {
                  y: contentType === contentTypes.prs ? 'Pull requests' : 'Issues',
                },
                axisFormat: {
                  tickAngle: {
                    x: 0,
                  },
                  tickSize: {
                    x: 0,
                  },
                  tickPadding: {
                    x: 15,
                  },
                  tickFormat: {
                    x: tickFormat,
                    y: (v) => `${v}%`,
                  },
                },
                maxNumberOfTicks: 5,
              },
            },
          },
          kpis: [],
        },
      ],
    };
  },
};

export default allocationChart;
