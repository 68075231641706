import { css } from '@emotion/react';

const getStyle = ({ spacing, color }) => css`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  line-height: 16px;

  & > svg {
    margin-right: ${spacing.gap['03']};
  }

  & > input {
    border: ${spacing.gap['0']};
    outline: ${spacing.gap['0']};
    flex-grow: 1;
  }
`;

export default getStyle;
