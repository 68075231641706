import { useMemo } from 'react';

const useProgress = (initial: number, target: number, current: number) => {
  return useMemo(() => {
    const diff = target - initial;
    const progress = current - initial;
    return {
      initial,
      target,
      current,
      diff,
      progress,
      percentage: (progress / diff) * 100,
    };
  }, [initial, target, current]);
};

export { useProgress };
