import { KPIType } from '@analytics-components/insights/Box/types';
import { SimpleKPI } from '@analytics-components/insights/KPI';

import { KPIInputType } from '.';

const kpis = (kpisData: KPIInputType): KPIType[] => [
  {
    title: { text: 'Deployment Frequency', bold: true },
    subtitle: { text: 'Average' },
    component: SimpleKPI,
    params: {
      value: kpisData.depFrequency[0],
      secondary: `/ ${kpisData.depFrequency[1]}`,
    },
  },
  {
    title: { text: 'Deployments', bold: true },
    subtitle: { text: 'Total' },
    component: SimpleKPI,
    params: {
      value: kpisData.deployments,
      secondary: `(${kpisData.prsCount} PRs)`,
    },
  },
];

export default kpis;
