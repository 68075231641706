import React, { useContext } from 'react';

import { PrimaryLayoutModes } from '@align-components/layouts/primary';
import { wrapperStyles } from '@align-components/layouts/primary/actionButtons/actionButtons.styles';
import { PrimaryLayoutContext } from '@align-components/layouts/primary/context';
import { Button } from '@lib/Button';
import { ButtonUISize, PresetButtonUIType } from '@lib/Button';
import { Tooltip } from '@lib/tooltip';

export const ActionButtons: React.FC = React.memo(() => {
  const { mode, isSubmitAllowed, onSubmit, onCancel, onRemove } = useContext(PrimaryLayoutContext);
  return (
    <div css={wrapperStyles}>
      {mode === PrimaryLayoutModes.EDIT && (
        <Button
          label="Remove"
          size={ButtonUISize.SMALL}
          type={PresetButtonUIType.TEXT}
          onClick={onRemove}
        />
      )}
      {mode !== PrimaryLayoutModes.VIEW && (
        <>
          <Button
            label="Cancel"
            size={ButtonUISize.SMALL}
            type={PresetButtonUIType.GHOST}
            onClick={onCancel}
          />
          <Tooltip
            enabled={!isSubmitAllowed}
            content="Select a template and set team targets to be able to save a goal."
            // TODO: fix positioning of the tooltip here, "place" param is not working
          >
            <Button
              label="Save Goal"
              size={ButtonUISize.SMALL}
              type={PresetButtonUIType.PRIMARY}
              disabled={!isSubmitAllowed}
              onClick={onSubmit}
            />
          </Tooltip>
        </>
      )}
    </div>
  );
});
