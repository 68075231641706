import React from 'react';

import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import Panel from '@analytics-components/insights/Panel';
import { prepareChartBoxes } from '@analytics-pages/delivery-pipeline/insights/helper';

import perRepository from './perRepository';

const meta = {
  title: 'Success Ratio - Average per Repository',
  description:
    'Shed light on repositories where GitHub checks tend to fail abnormally which delays the overall software delivery.',
};

const chartBoxes = [
  {
    id: 'success-ratio-per-repository',
    component: ChartBoxWithKPIWidget,
    params: perRepository,
  },
];

const PerRepositoryPanel = () => {
  const preparedChartBoxes = prepareChartBoxes(chartBoxes);
  return <Panel meta={meta} chartBoxes={preparedChartBoxes} />;
};

export default PerRepositoryPanel;
