import { IPrStageApiMapping } from '@common-services/prHelpers';
import { icons } from '@lib/icon';
import { IColor } from '@styles/theme/colors/colors';

export interface Unit {
  singular: string;
  plural: string;
  isFrequency?: boolean;
}

export enum IMetricsConfigNames {
  prWipTime = 'pr-wip-time',
  prReviewComments = 'pr-review-comments-per',
  prReviewTime = 'pr-review-time',
  prMergeTime = 'pr-merging-time',
  prReleaseTime = 'pr-release-time',
  prSize = 'pr-size',
  prCycleTime = 'pr-cycle-time',
  releaseCount = 'release-count',
  jiraResolved = 'jira-resolved',
  jiraAcknowledgedTime = 'jira-acknowledge-time',
  prAllMappedToJira = 'pr-all-mapped-to-jira',
  prWaitFirstReviewTime = 'pr-wait-first-review-time',
  prOpenTime = 'pr-open-time',
  jiraLeadTime = 'jira-lead-time',
  jiraLifeTime = 'jira-life-time',
  jiraAckTime = 'jira-acknowledge-time',
  prReviewedRatio = 'pr-reviewed-ratio',
  prWipTimeThreshold = 'pr-wip-time-below-threshold-ratio',
  prReviewTimeThreshold = 'pr-review-time-below-threshold-ratio',
  prMergeTimeThreshold = 'pr-merging-time-below-threshold-ratio',
  prWaitFirstReviewTimeThreshold = 'pr-wait-first-review-time-below-threshold-ratio',
  prSizeThreshold = 'pr-size-below-threshold-ratio',
  prReviewCommentsThreshold = 'pr-review-comments-per-above-threshold-ratio',
  prCycleDeploymentThreshold = 'pr-cycle-deployment-time-below-threshold-ratio',
  prOpenTimeThreshold = 'pr-open-time-below-threshold-ratio',
  jiraLeadTimeThreshold = 'jira-lead-time-below-threshold-ratio',
  prCycleTimeThreshold = 'pr-lead-time-below-threshold-ratio',
  jiraLifeTimeThreshold = 'jira-life-time-below-threshold-ratio',
  jiraAckTimeThreshold = 'jira-acknowledge-time-below-threshold-ratio',
}

export interface IMetricConfig {
  hidden?: boolean;
  name: IMetricsConfigNames;
  displayName: string;
  color: IColor;
  icon: icons;
  valueType: ValueType;
  unit: Unit;
  better: IMetricBetter;
  metricDescription?: {
    short: string;
    full: string;
  };
  allKPI?: CompletedKPIMetric;
  completedKPI?: CompletedKPIMetric;
  mainKPI?: IMetricsConfigNames;
  inProgressStages?: (keyof IPrStageApiMapping | JiraStatusLabel)[];
  inProgressOrderBy?: PrInProgressOrderByField | JiraInProgressOrderByField;
  inProgressOrderAscending?: boolean;
  jiraMetricField?: JiraIssueMetricField;
}

export enum IMetricBetter {
  LOWER = 'lower',
  HIGHER = 'higher',
}

export type IMetricsConfig = Partial<Record<IMetricsConfigNames, IMetricConfig>>;

export enum CompletedKPIMetric {
  WIP = 'pr-wip-count',
  CYCLE = 'pr-lead-count',
  REVIEW = 'pr-review-count',
  MERGE = 'pr-merging-count',
  ALL = 'pr-opened',
  JIRA_RESOLVED = 'jira-resolved',
  JIRA_ACKNOWLEDGED = 'jira-acknowledged',
  OPEN = 'pr-open-count',
  WAIT = 'pr-wait-first-review-count',
}

export enum ValueType {
  date = 'date',
  int = 'int',
  float = 'float',
}

export enum PrInProgressOrderByField {
  WIP = 'pr-wip-stage-timing',
  REVIEW_REQUEST = 'pr-review-stage-timing',
  TOTAL_STAGE = 'pr-total-stage-timing',
  MERGE = 'pr-merge-stage-timing',
  RELEASE = 'pr-release-stage-timing',
  SIZE = 'pr-size',
}

export enum JiraInProgressOrderByField {
  CREATED = 'created',
  WORK_BEGAN = 'work_began',
}

export enum PRsTableTabLabel {
  ALL = 'All',
  COMPLETED = 'Completed',
  IN_PROGRESS = 'In Progress',
}

export interface IBreakdownConfig {
  optionsInitialSelection: number;
  optionsMaxSelection: number;
  optionsMinSelection: number;
}

export enum JiraStatusLabel {
  TO_DO = 'To Do',
  IN_PROGRESS = 'In Progress',
  DONE = 'Done',
}

export enum JiraIssueMetricField {
  MTTR = 'life_time',
  LEAD_TIME = 'lead_time',
  ACK_TIME = 'acknowledge_time',
}

export enum MetricColumnName {
  LEAD_TIME = 'Lead Time',
  CYCLE_TIME = 'Cycle',
  WIP_TIME = 'WIP',
  REVIEW_TIME = 'Review',
  MERGE_TIME = 'Merge',
  RELEASE_TIME = 'Release',
  WAIT_TIME = 'Wait',
  OPEN_TIME = 'Open',
  MTTR = 'MTTR',
  REPAIR_TIME = 'Repair Time',
  MTTA = 'MTTA',
}
