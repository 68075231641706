import { useCallback } from 'react';

import { OverrideStyleFunc, StyleFunc } from '@lib/Accordion/types';

const useOverrideStyle = (defaultStyle: StyleFunc, overrideStyle: OverrideStyleFunc) => {
  const wrapperStyle = useCallback(
    (theme: any) => {
      const style = [defaultStyle(theme)];
      if (overrideStyle) {
        let override = overrideStyle(theme);
        if (Array.isArray(override)) {
          style.push(...override);
        } else {
          style.push(override);
        }
      }

      return style;
    },
    [defaultStyle, overrideStyle]
  );

  return wrapperStyle;
};

export { useOverrideStyle };
