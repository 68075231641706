import { IThemeColor } from '@styles/types';

import { neutral } from './color-neutral';
import { sets } from './color-sets';
import { stage } from './color-stage';
import { status } from './color-status';
import { text } from './color-text';
import { ui } from './color-ui';

export const color: IThemeColor = {
  neutral,
  ui,
  status,
  text,
  stage,
  sets,
};
