const styles = {
  container: (provided) => ({
    ...provided,
    height: 40,
    minWidth: 400,
  }),
  control: (provided) => ({
    ...provided,
    'borderRadius': 0,
    'height': 40,
    'borderColor': '#E7E7EC',
    'boxShadow': 'none',
    ':hover': {
      borderColor: '#ccc',
    },
  }),
  indicatorSeparator: () => ({
    backgroundColor: 'transparent',
  }),
  option: (styles) => ({
    ...styles,
    'borderTop': '1px solid #ccc',
    'padding': '12px 16px',
    'display': 'flex',
    'alignItems': 'center',
    ':hover': {
      backgroundColor: '#f6f6f8',
    },
  }),
  input: (styles, state) => ({
    ...styles,
    color: '#333',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 0,
    top: '110%',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#8889A1',
    fontSize: '1.2rem',
    fontWeight: 300,
    paddingLeft: '2.4rem',
    cursor: 'text',
  }),
  groupHeading: (provided) => ({
    ...provided,
    fontSize: 11,
    padding: '12px 16px 10px',
    background: 'rgb(250, 250, 251)',
  }),
  group: (provided) => ({
    ...provided,
    padding: 0,
    borderBottom: '1px solid #ccc',
  }),
};

export default styles;
