import React, { useMemo } from 'react';
import { useTable, useExpanded } from 'react-table';

import { PrsIssuesTableServices } from '@align-services/prsIssuesTableService';
import { IIssuesTableRow } from '@align-types/issues';
import { useOwnedJiraFilter } from '@common-services/api/public/hooks/useFiltersData';
import { Spinner } from '@lib/Spinner';
import { Table } from '@lib/Table';
import { tableBodyStyles } from '@lib/Table/components/pr-table/prTable.styles';
import { tableHeaderStyles } from '@lib/Table/styles';

import { issuesTableColumns } from './columns';
import { spinnerWrapperStyles } from './issuesTable.styles';
import { IIssuesTable } from './issuesTable.types';

export const IssuesTable: React.FC<IIssuesTable> = React.memo(
  ({ data, templateMetric, isLoadingPart, onShowMoreHandler }) => {
    const { data: jiraStuff } = useOwnedJiraFilter(
      ['issues', 'priorities', 'issue_types', 'statuses'],
      true
    );

    const serializedData = useMemo<IIssuesTableRow[]>(
      () => PrsIssuesTableServices.serializeIssuesTableData(data, jiraStuff) as IIssuesTableRow[],
      [data, jiraStuff]
    );

    const columns = useMemo(() => issuesTableColumns(templateMetric), [templateMetric]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<
      IIssuesTableRow
    >(
      {
        autoResetExpanded: false,
        columns,
        data: serializedData,
      },
      useExpanded
    );

    rows.forEach(prepareRow);

    return jiraStuff ? (
      <Table
        rows={rows}
        headerGroups={headerGroups}
        tableProps={getTableProps()}
        tableBodyProps={getTableBodyProps()}
        extraCSS={{
          header: tableHeaderStyles,
          body: tableBodyStyles,
        }}
        isLoadingPart={isLoadingPart}
        isSortingEnabled={false}
        onShowMoreHandler={onShowMoreHandler}
        showMoreText="issues"
      />
    ) : (
      <div css={spinnerWrapperStyles}>
        <Spinner loading />
      </div>
    );
  }
);
