import PropTypes from 'prop-types';
import React from 'react';

import { Icon, icons } from '@lib/icon';

import { styles } from './styles';

const IconButton = ({ buttonSize = 26, disabled, icon, iconSize, title, onClick }) => (
  <button css={styles(buttonSize, disabled)} disabled={disabled} title={title} onClick={onClick}>
    <Icon icon={icon} size={iconSize} />
  </button>
);

const IconButtonPropTypes = {
  buttonSize: PropTypes.number,
  disabled: PropTypes.bool,
  icon: PropTypes.oneOf(Object.values(icons)).isRequired,
  iconSize: PropTypes.number,
  title: PropTypes.string,
  onClick: PropTypes.func,
};

IconButton.propTypes = IconButtonPropTypes;

export default IconButton;
