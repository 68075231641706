/**
 * {{ title }}
 * Server version: {{ server_version }} built on {{ build_date }} @ [{{ commit }}](https://github.com/athenianco/athenian-api/commit/{{ commit }})  Origin GitHub repository: [athenianco/api-spec](https://github.com/athenianco/api-spec).  Authorization persists between sessions. 🛡️ means that the endpoint requires account admin privileges. 👑 means that the endpoint is only accessible to Athenian authorities. <details> <summary>List of common server errors.</summary> * __500__ endpoint crashed. We reported the incident to Sentry and will fix it soon! Please include the value of `instance` in the response if you contact the support. Repeating the request will not help, most probably. * __501__ some functions are missing, either because they are not implemented yet or a non-critical runtime dependency is not satisfied. Repeating the request will not help, guaranteed. * __502__ server crashed badly, either due to a memory access violation in native code or running out of memory. We reported this incident to Sentry and will fix it soon! Try repeating the request. * __503__ server has not fully launched yet, e.g. hasn't connected to the database; server is shutting down; we are experiencing a partial outage. Try repeating the request. * __504__ endpoint took too much time and was interrupted. We reported the incident to Sentry and will see how to improve the performance. Repeating the request will not help, most probably. </details>
 *
 * Contact: admin@athenian.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import JIRAFilterWith from './JIRAFilterWith';
import JIRAMetricID from './JIRAMetricID';

/**
 * The JIRAMetricsRequest model module.
 * @module model/JIRAMetricsRequest
 */
class JIRAMetricsRequest {
    /**
     * Constructs a new <code>JIRAMetricsRequest</code>.
     * Request body of &#x60;/metrics/jira&#x60;.
     * @alias module:model/JIRAMetricsRequest
     * @param account {Number} Session account ID.
     * @param dateFrom {Date} Issues must be resolved after this date.
     * @param dateTo {Date} Issues must be created before this date.
     * @param excludeInactive {Boolean} Value indicating whether issues with the last update older than `date_from` should be ignored. If `date_from` and `date_to` are `null`, does nothing.
     * @param metrics {Array.<module:model/JIRAMetricID>} List of measured metrics.
     * @param granularities {Array.<String>} Splits of the specified time range `[date_from, date_to)`.
     */
    constructor(account, dateFrom, dateTo, excludeInactive, metrics, granularities) { 
        
        JIRAMetricsRequest.initialize(this, account, dateFrom, dateTo, excludeInactive, metrics, granularities);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, account, dateFrom, dateTo, excludeInactive, metrics, granularities) { 
        obj['account'] = account;
        obj['date_from'] = dateFrom;
        obj['date_to'] = dateTo;
        obj['exclude_inactive'] = excludeInactive;
        obj['metrics'] = metrics;
        obj['granularities'] = granularities;
    }

    /**
     * Constructs a <code>JIRAMetricsRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/JIRAMetricsRequest} obj Optional instance to populate.
     * @return {module:model/JIRAMetricsRequest} The populated <code>JIRAMetricsRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new JIRAMetricsRequest();

            if (data.hasOwnProperty('account')) {
                obj['account'] = ApiClient.convertToType(data['account'], 'Number');
            }
            if (data.hasOwnProperty('date_from')) {
                obj['date_from'] = ApiClient.convertToType(data['date_from'], 'Date');
            }
            if (data.hasOwnProperty('date_to')) {
                obj['date_to'] = ApiClient.convertToType(data['date_to'], 'Date');
            }
            if (data.hasOwnProperty('timezone')) {
                obj['timezone'] = ApiClient.convertToType(data['timezone'], 'Number');
            }
            if (data.hasOwnProperty('priorities')) {
                obj['priorities'] = ApiClient.convertToType(data['priorities'], ['String']);
            }
            if (data.hasOwnProperty('types')) {
                obj['types'] = ApiClient.convertToType(data['types'], ['String']);
            }
            if (data.hasOwnProperty('labels_include')) {
                obj['labels_include'] = ApiClient.convertToType(data['labels_include'], ['String']);
            }
            if (data.hasOwnProperty('labels_exclude')) {
                obj['labels_exclude'] = ApiClient.convertToType(data['labels_exclude'], ['String']);
            }
            if (data.hasOwnProperty('exclude_inactive')) {
                obj['exclude_inactive'] = ApiClient.convertToType(data['exclude_inactive'], 'Boolean');
            }
            if (data.hasOwnProperty('epics')) {
                obj['epics'] = ApiClient.convertToType(data['epics'], ['String']);
            }
            if (data.hasOwnProperty('with')) {
                obj['with'] = ApiClient.convertToType(data['with'], [JIRAFilterWith]);
            }
            if (data.hasOwnProperty('projects')) {
                obj['projects'] = ApiClient.convertToType(data['projects'], ['String']);
            }
            if (data.hasOwnProperty('metrics')) {
                obj['metrics'] = ApiClient.convertToType(data['metrics'], [JIRAMetricID]);
            }
            if (data.hasOwnProperty('quantiles')) {
                obj['quantiles'] = ApiClient.convertToType(data['quantiles'], ['Number']);
            }
            if (data.hasOwnProperty('granularities')) {
                obj['granularities'] = ApiClient.convertToType(data['granularities'], ['String']);
            }
            if (data.hasOwnProperty('group_by_jira_label')) {
                obj['group_by_jira_label'] = ApiClient.convertToType(data['group_by_jira_label'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * Session account ID.
 * @member {Number} account
 */
JIRAMetricsRequest.prototype['account'] = undefined;

/**
 * Issues must be resolved after this date.
 * @member {Date} date_from
 */
JIRAMetricsRequest.prototype['date_from'] = undefined;

/**
 * Issues must be created before this date.
 * @member {Date} date_to
 */
JIRAMetricsRequest.prototype['date_to'] = undefined;

/**
 * Local time zone offset in minutes, used to adjust `date_from` and `date_to`.
 * @member {Number} timezone
 */
JIRAMetricsRequest.prototype['timezone'] = undefined;

/**
 * Selected issue priorities.
 * @member {Array.<String>} priorities
 */
JIRAMetricsRequest.prototype['priorities'] = undefined;

/**
 * Selected issue types.
 * @member {Array.<String>} types
 */
JIRAMetricsRequest.prototype['types'] = undefined;

/**
 * JIRA issues must contain at least one label from the list. Several labels may be concatenated by a comma `,` so that all of them are required.
 * @member {Array.<String>} labels_include
 */
JIRAMetricsRequest.prototype['labels_include'] = undefined;

/**
 * JIRA issues may not contain labels from this list.
 * @member {Array.<String>} labels_exclude
 */
JIRAMetricsRequest.prototype['labels_exclude'] = undefined;

/**
 * Value indicating whether issues with the last update older than `date_from` should be ignored. If `date_from` and `date_to` are `null`, does nothing.
 * @member {Boolean} exclude_inactive
 */
JIRAMetricsRequest.prototype['exclude_inactive'] = undefined;

/**
 * JIRA issues must be attached to any of the epic IDs from this list.
 * @member {Array.<String>} epics
 */
JIRAMetricsRequest.prototype['epics'] = undefined;

/**
 * Groups of issue participants. The metrics will be calculated for each group.
 * @member {Array.<module:model/JIRAFilterWith>} with
 */
JIRAMetricsRequest.prototype['with'] = undefined;

/**
 * Issues must belong to these JIRA projects.
 * @member {Array.<String>} projects
 */
JIRAMetricsRequest.prototype['projects'] = undefined;

/**
 * List of measured metrics.
 * @member {Array.<module:model/JIRAMetricID>} metrics
 */
JIRAMetricsRequest.prototype['metrics'] = undefined;

/**
 * Cut the distributions at certain quantiles. The default values are [0, 1] which means no cutting.
 * @member {Array.<Number>} quantiles
 */
JIRAMetricsRequest.prototype['quantiles'] = undefined;

/**
 * Splits of the specified time range `[date_from, date_to)`.
 * @member {Array.<String>} granularities
 */
JIRAMetricsRequest.prototype['granularities'] = undefined;

/**
 * Value indicating whether the metrics should be grouped by assigned JIRA issue label.
 * @member {Boolean} group_by_jira_label
 */
JIRAMetricsRequest.prototype['group_by_jira_label'] = undefined;






export default JIRAMetricsRequest;

