import { BasicApiClass } from '@common-services/api/common/BasicApiClass';
import { REST_TYPES } from '@common-services/api/common/types/apiClient';
import { ApiMode } from '@common-services/api/common/types/common';
import { ISentryMethodParams } from '@common-services/api/common/types/sentry';
import { PUBLIC_API_ENDPOINTS_CONSTANTS } from '@common-services/api/public/constants';
import { User } from '@common-services/api/public/generated-from-backend/models';

export class UserApi extends BasicApiClass {
  constructor() {
    super(ApiMode.PUBLIC);
  }
  public getUser(token: string, sentry?: ISentryMethodParams): Promise<User> {
    this.assignToken(token);
    const callApi = this.callApi<User>(PUBLIC_API_ENDPOINTS_CONSTANTS.USER, REST_TYPES.GET);
    return sentry ? this.callSentry<User>(callApi, sentry) : callApi;
  }
}
