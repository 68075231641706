import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { useNodeState, ALL_NODE_ID } from '@lib/Selector';
import { optionType, optionNoColorType } from '@lib/Selector/types';
import { Avatar, AvatarType } from '@lib/avatar';

import { default as OptionUI, AllOption as AllOptionUI } from './ui';

const Option = ({ avatarType, data, noAvatar, noCheckbox }) => {
  const [nodeState, { toggleSelectionState, toggleExpansionState }] = useNodeState(data.id);

  const onClick = toggleSelectionState;
  const onExpand = toggleExpansionState;
  const avatar = (
    <Avatar
      color={data.color}
      icon={data.icon}
      image={data.image}
      label={data.label}
      size={noCheckbox ? 14 : 20}
      type={noAvatar ? AvatarType.none : avatarType}
    />
  );

  return nodeState.isVisible ? (
    <OptionUI
      level={data.level}
      data={data}
      selectionState={nodeState.selectionState}
      isExpanded={nodeState.isExpanded}
      avatar={noAvatar ? null : avatar}
      noCheckbox={noCheckbox}
      onClick={onClick}
      onExpand={onExpand}
    >
      {_(data.children)
        .map((c, i) => <Option key={i} data={c} />)
        .value()}
    </OptionUI>
  ) : null;
};

const AllOption = () => {
  const [nodeState, { toggleSelectionState }] = useNodeState(ALL_NODE_ID);
  const onClick = toggleSelectionState;
  return (
    <AllOptionUI
      allCount={nodeState.node.descendants.length}
      selectionState={nodeState.selectionState}
      onClick={onClick}
    />
  );
};

Option.propTypes = {
  data: (props, propName, componentName, ...rest) =>
    (!!props.noAvatar ? optionNoColorType : optionType).isRequired(
      props,
      propName,
      componentName,
      ...rest
    ),
  avatarType: PropTypes.oneOf(Object.values(AvatarType)),
  noAvatar: PropTypes.bool,
  noCheckbox: PropTypes.bool,
};

AllOption.propTypes = {};

export default Option;
export { AllOption };
