import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { CacheService } from '@align-services/cacheService';
import { TableSearch } from '@analytics-components/tables/table-search';
import { useApi } from '@analytics-hooks';
import {
  createTeam,
  fetchContributors,
  getDevelopers,
  getTeams,
  removeTeam,
} from '@analytics-services/api';
import { fetchJIRAidentities } from '@analytics-services/api/jira';
import { DatetimeService } from '@analytics-services/datetimeService';
import { IDeveloper, IJiraIdentity } from '@analytics-types/common';
import { SettingsGroup } from '@common-pages/Settings';
import { TeamEditAccordion } from '@common-pages/Settings/teams/components/team-edit-accordion';
import { TeamProvider } from '@common-pages/Settings/teams/teams.context';
import log from '@common-services/logger';
import { Spinner } from '@lib/Spinner';
import Breadcrumb from '@ui/Breadcrumb';

import { AddTeam } from './components/add-team';
import { ISaveTeamParams } from './components/add-team/addTeam.types';
import { teamsMainWrapperStyles, teamWrapperStyles } from './teams.styles';
import { IJiraLabel, IMappedTeam } from './teams.types';

const Teams: React.FC = React.memo(() => {
  const [tempFilterTermState, setTempFilterTermState] = useState('');
  const [filterTermState, setFilterTermState] = useState('');
  const [teams, setTeams] = useState<IMappedTeam[]>([]);
  const [jiraIdentities, setJiraIdentities] = useState<IJiraLabel[]>([]);
  const [developers, setDevelopers] = useState<IDeveloper[]>([]);
  const { api, ready: apiReady, context } = useApi(true, false);
  const [allUsersLoaded, setAllUsersLoaded] = useState(false);

  const fetchAndSetTeams = useCallback(async () => {
    const [devTeams, devs, jiraIds] = await Promise.all([
      getTeams(api, context.account),
      fetchContributors(api, context.account, {
        from: DatetimeService.Defaults.FROM,
        to: DatetimeService.Defaults.TO,
      }),
      context.user.account.jira
        ? fetchJIRAidentities(api, context.account)
        : Promise.resolve([] as IJiraIdentity[]),
    ]);

    const mappedTeams: IMappedTeam[] = devTeams.map((team) => {
      const members = team.members.map((member) => {
        if (member?.jira_user) {
          return member;
        } else {
          // enrich each member with Jira identity obtained from Contributors list
          const dev = devs.find((d) => d.login === member.login);
          return {
            ...member,
            jira_user: dev?.jira_user,
          };
        }
      });
      return {
        ...team,
        allMembers: team.members,
        members,
      };
    });

    setTeams(mappedTeams);
    setDevelopers(devs);
    setJiraIdentities(
      _(jiraIds)
        .map((jid) => ({ label: jid.jira_name }))
        .value()
    );
  }, [api, context.account]);

  useEffect(() => {
    if (!apiReady) {
      return;
    }
    fetchAndSetTeams();
  }, [api, apiReady, context.account]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilterTermState(tempFilterTermState);
    }, 500);

    return () => clearTimeout(timer);
  }, [tempFilterTermState]);

  const saveTeam = async ({ name, members, parentTeam }: ISaveTeamParams) => {
    try {
      await createTeam(api, context.account, name, members, parentTeam);
      log.ok(`New team ${name} has been created`);
      await fetchAndSetTeams();
    } catch (err) {
      log.fatal(`Could not save the team`, err);
    }
  };

  const onRemove = useCallback(
    async (id: number) => {
      try {
        await removeTeam(api, id);
        await invalidateTeamsQuery();
        log.ok(`Team #${id} has been removed`);
        await fetchAndSetTeams();
      } catch (err) {
        log.fatal(`Could not delete the team`, err);
      }
    },
    [fetchAndSetTeams]
  );

  const onLoadAllUsers = useCallback(async () => {
    const developers = await getDevelopers(api, context.account);
    setDevelopers(developers);
    setAllUsersLoaded(true);
  }, [setDevelopers, setAllUsersLoaded]);

  const filteredTeams = useMemo(() => {
    if (filterTermState) {
      return teams
        .map((t) => ({
          ...t,
          members: t.members.filter(
            (m) =>
              m.login?.toLowerCase().includes(filterTermState.toLowerCase()) ||
              m.name?.toLowerCase().includes(filterTermState.toLowerCase())
          ),
        }))
        .map((t) => ({
          ...t,
          isVisible:
            t.members.length > 0 || t.name.toLowerCase().includes(filterTermState.toLowerCase()),
        }));
    } else {
      return teams.map((t) => ({
        ...t,
        isVisible: true,
      }));
    }
  }, [filterTermState, teams]);

  console.log(filteredTeams);

  const AddTeamsProps = useMemo(
    () => ({
      teams,
      onSave: saveTeam,
    }),
    [teams]
  );

  if (!apiReady) {
    return null;
  }

  return (
    <>
      <Breadcrumb breadcrumb={[{ name: 'Settings' }, { name: 'Teams' }]} />
      <SettingsGroup extra={<AddTeam {...AddTeamsProps} />}>
        <p className="text-secondary mt-2 mb-3">Manage and add teams for your organization</p>
        <TableSearch
          placeholder="Find a team or a member..."
          value={tempFilterTermState}
          onFilter={setTempFilterTermState}
        />
        {filteredTeams && !!developers?.length ? (
          <div css={teamsMainWrapperStyles}>
            {filteredTeams.map((t, i) => (
              <div key={t.name} css={teamWrapperStyles(t.isVisible)}>
                <TeamProvider members={t.members}>
                  <TeamEditAccordion
                    id={t.id}
                    name={t.name}
                    members={t.members}
                    parent={t.parent}
                    allMembers={t.allMembers}
                    developers={developers}
                    jiraIdentities={t.name === 'Bots' ? [] : jiraIdentities}
                    allUsersLoaded={allUsersLoaded}
                    teams={teams}
                    onRemove={onRemove}
                    onLoadAllUsers={onLoadAllUsers}
                    onMembersChange={fetchAndSetTeams}
                  />
                </TeamProvider>
              </div>
            ))}
          </div>
        ) : (
          <div css={{ textAlign: 'center' }}>
            <Spinner loading />
          </div>
        )}
      </SettingsGroup>
    </>
  );
});

const invalidateTeamsQuery = async (): Promise<void> => {
  try {
    await CacheService.removeQuery(['teams']);
  } catch {
    log.fatal('Cache error');
  }
};

export { Teams };
