import React, { useContext } from 'react';

import { Spinner } from '@lib/Spinner';
import { SelectorTsIds } from '@lib/selector-ts/__tests__/testIds.ids';
import { RenderChild } from '@lib/selector-ts/components/children';
import { SelectorTsContext } from '@lib/selector-ts/context';

export const SelectorTsOptionsSection: React.FC = React.memo(() => {
  const {
    options,
    maxHeight,
    isLoading,
    isLoadMoreEnabled,
    loadMoreLabel,
    loadMoreHandler,
  } = useContext(SelectorTsContext);
  return (
    <div data-cy="selector-menu" className="menu" data-testid={SelectorTsIds.optionsBlock}>
      <ul className="menu-options" style={{ maxHeight: maxHeight + 'px' }}>
        <RenderChild options={options} level={1} />
        {isLoadMoreEnabled && (
          <li className="load-more" onClick={loadMoreHandler}>
            <Spinner loading={isLoading} />
            {!isLoading && loadMoreLabel}
          </li>
        )}
      </ul>
    </div>
  );
});
