import React from 'react';

import { IGoalMetricValue } from '@align-services/api/types/goalsTypes';
import { IDateRange } from '@align-services/dateService';
import { MetricService } from '@align-services/metricService';

export const getReferenceValueWithFrequency = (
  range: IDateRange,
  initialValue: IGoalMetricValue,
  setConversionValue: React.Dispatch<React.SetStateAction<number>>
): string => {
  const [displayValue, conversionVal] = MetricService.getFrequencyValue(range, initialValue);
  setConversionValue(conversionVal);
  return displayValue;
};

export * as TeamNodeServices from './teamNode.services';
