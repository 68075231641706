type ISettingsRepoName = string;

export type ISetting = {
  branches: string;
  tags: string;
  match: string;
  events: string;
  default_branch;
  url?: string;
};

export type ISettings = {
  [Keys in ISettingsRepoName]: ISetting;
};

type ISettingsArr = ISetting[];

export type IGroupedSettings = ISettingsArr[];

export enum ITypeOptions {
  BRANCH = 'branch',
  TAG = 'tag',
  EVENT = 'event',
  AUTO = 'tag_or_branch',
}

export enum MESSAGES {
  SUCCESS = 'Settings updated',
}
