import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';

import DropdownSelector from '@lib/dropdown-selector';

import { fieldWrapperStyles } from './styles';

const IssueType = React.memo(({ allIssueTypes, allProjects, initialValue = {}, onChange }) => {
  const [selectedIssueTypes, setSelectedIssueTypes] = useState(initialValue.issueTypes || []);
  const [selectedProjects, setSelectedProjects] = useState(initialValue.projects || []);

  const issueTypes = useMemo(() => {
    const projectIds = _(selectedProjects?.length ? selectedProjects : allProjects)
      .map('id')
      .value();
    return _(allIssueTypes)
      .filter((type) => _(projectIds).includes(type.project))
      .orderBy(['value', 'count'], ['asc', 'desc'])
      .uniqBy('value')
      .value();
  }, [allIssueTypes, allProjects, selectedProjects]);

  const handleIssueTypeChange = useCallback(
    (value) => {
      setSelectedIssueTypes(value);
      onChange({
        issueTypes: value,
        projects: selectedProjects,
      });
    },
    [selectedProjects]
  );

  const handleProjectChange = useCallback(
    (value) => {
      setSelectedProjects(value);
      onChange({
        issueTypes: selectedIssueTypes,
        projects: value,
      });
    },
    [selectedIssueTypes]
  );

  return (
    <>
      <div css={fieldWrapperStyles(true)}>
        <DropdownSelector
          initialValue={selectedProjects}
          options={allProjects}
          placeholder="All projects"
          withAllOption
          onChange={handleProjectChange}
        />
      </div>
      <div css={fieldWrapperStyles(false)}>
        <DropdownSelector
          initialValue={selectedIssueTypes}
          options={issueTypes}
          placeholder="All issue types"
          withAllOption
          onChange={handleIssueTypeChange}
        />
      </div>
    </>
  );
});

export default IssueType;
