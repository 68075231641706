import { css } from '@emotion/react';

export const listItemStyles = ({ color, radius }, isActive) => css`
  &::before {
    background-color: ${isActive ? color.ui.orange[100] : color.neutral[80]};
    border-radius: ${radius.default};
    content: '';
    display: flex;
    height: 4px;
    margin: -0.2rem 1.2rem 0;
    width: 4px;
  }
`;

export const sectionNameStyles = ({ color, font, spacing }, hasIcon, isActive, isLeaf) => css`
  &&& {
    color: ${isActive ? color.neutral[100] : color.neutral[80]};
    font-size: ${hasIcon ? font.size.s : font.size.default};
    ${hasIcon && `margin-left: ${spacing.gap['04']};`}
    ${isLeaf && `margin-left: ${spacing.gap['05']};`}
    margin-right: ${spacing.gap['03']};
`;

export const leafSectionHeaderStyles = ({ spacing, color, radius, transition }, isActive) => css`
  &&& {
    border-radius: ${radius.default};
    margin: ${spacing.gap['0']} ${spacing.gap['03']};
    padding: ${spacing.gap['04']};
    transition: ${transition.long};
    
    ${isActive && `background-color: ${color.neutral.white};`}
`;

export const sectionHeaderStyles = (
  { color, radius, spacing, transition },
  hasIcon,
  isActive
) => css`
  align-items: center;
  border-radius: ${radius.default};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-left: ${spacing.gap['04']};
  margin-right: ${spacing.gap['04']};
  padding: ${hasIcon ? spacing.gap['04'] : spacing.gap['03']};
  padding-right: ${spacing.gap['05']};
  transition: ${transition.long};

  ${isActive && `background-color: ${color.neutral.white};`}

  ${!hasIcon &&
  `
    margin-top: ${spacing.gap['02']};

    &:first-of-type {
      margin-top: 0;
    }
  `}

  &:hover {
    background-color: ${color.neutral[10]};
  }
`;

export const subsectionsStyles = ({ spacing }, parent = false) => css`
  margin-bottom: ${parent ? spacing.gap['02'] : spacing.gap['0']};

  .nav-item {
    display: block;
    width: 100%;

    .nav-link {
      align-items: center;
      display: flex;

      .badge-wrapper {
        margin-left: ${spacing.gap['03']};
      }
    }
  }
`;

export const wrapperStyles = ({ spacing, transition }, isExpanded) => css`
  height: 0;
  margin-bottom: ${isExpanded ? spacing.gap['03'] : 0};
  margin-top: ${isExpanded ? spacing.gap['02'] : 0};
  overflow: hidden;
  transition: ${transition.height};
`;
