import React, { useCallback, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import logoOwl from '@assets/images/logos/logo-owl.svg';
import logoFull from '@assets/images/logos/logo-transparent.svg';
import { useHistory } from '@common-hooks/useHistory';
import { pathToName } from '@lib/Navbar/helpers';

import { backButtonStyles } from './NavbarLogo.styles';

interface INavbarLogo {
  backButton?: boolean;
  isDemo?: boolean;
}

interface StateLocation extends Location {
  state: {
    from?: string;
  };
}

export const NavbarLogo: React.FC<INavbarLogo> = React.memo(({ backButton, isDemo }) => {
  const location = (useLocation() as unknown) as StateLocation;
  const history = useHistory();
  const [prevPath, setPrevPath] = useState('/');

  useEffect(() => {
    if (location.state?.from && !location.state?.from.includes('settings')) {
      setPrevPath(location.state.from);
    }
  }, [location]);

  const goBack = useCallback(() => {
    if (!isDemo && prevPath) {
      history.push(prevPath);
    } else {
      goHome();
    }
  }, [isDemo, prevPath]);

  const goHome = useCallback(() => {
    history.push('/');
  }, []);

  return backButton ? (
    <>
      <img src={logoOwl} alt="athenian" onClick={goHome} />
      <div css={backButtonStyles} onClick={goBack}>
        Back to {pathToName(prevPath) || 'Analytics'}
      </div>
    </>
  ) : (
    <Link to="/">
      <img src={logoFull} alt="athenian" />
    </Link>
  );
});
