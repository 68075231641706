import React, { useMemo } from 'react';

import { Chart, ChartTypeEnum } from '@lib/chart';

import { IActiveGoalChart } from './activeGoalChart.types';

export const ActiveGoalChart: React.FC<IActiveGoalChart> = React.memo(
  ({ data, seriesColours, targetLine }) => {
    const maxValue = useMemo(
      () => (data?.some((value) => value > targetLine) ? null : targetLine),
      [data, targetLine]
    );
    return (
      <Chart
        chartType={ChartTypeEnum.BASIC_COLUMN}
        options={{
          series: [
            {
              data,
              colors: seriesColours,
              colorByPoint: true,
              type: 'column',
            },
          ],
          yAxis: { plotLines: [{ value: targetLine }], max: maxValue },
        }}
        isTooltipEnabled={false}
      />
    );
  }
);
