import React from 'react';
import { Column } from 'react-table';

import { PR_LABELS_ORDERS, PR_STAGE } from '@common-services/prHelpers';
import { PullRequestsCellComments } from '@lib/Table/components/pr-table/components/cell-comments';
import { PullRequestsCellIcon } from '@lib/Table/components/pr-table/components/cell-icon';
import { PullRequestsCellReviewers } from '@lib/Table/components/pr-table/components/cell-reviewers';
import { PullRequestsCellSize } from '@lib/Table/components/pr-table/components/cell-size';
import { PullRequestsCellStage } from '@lib/Table/components/pr-table/components/cell-stage';
import { PullRequestsCellTime } from '@lib/Table/components/pr-table/components/cell-time';
import { PullRequestsCellTitle } from '@lib/Table/components/pr-table/components/cell-title';

import { getColumnTitle } from './prTable.helpers';
import { IPrsTableRow } from './prTable.types';

export const prTableColumns: Column<IPrsTableRow>[] = [
  {
    accessor: 'status',
    Cell: PullRequestsCellIcon,
  },
  {
    Header: 'Pull Requests | Created',
    accessor: 'created',
    Cell: PullRequestsCellTitle,
  },
  {
    Header: 'Size',
    accessor: (row) => row.size_added + row.size_removed,
    Cell: PullRequestsCellSize,
  },
  {
    Header: 'Comments',
    accessor: (row) => row.comments + row.review_comments,
    Cell: PullRequestsCellComments,
  },
  {
    Header: 'Reviewers',
    accessor: (row) => row.reviewers.length,
    Cell: PullRequestsCellReviewers,
  },
  {
    Header: ({ rows }) => {
      return <span>{getColumnTitle(rows?.[0]?.original.stage)}</span>;
    },
    accessor: 'cycleTime',
    Cell: PullRequestsCellTime,
  },
  {
    id: 'stage',
    Header: 'Stage',
    accessor: (row) =>
      row.stage === PR_STAGE.DEPLOY ? row?.environment : PR_LABELS_ORDERS[row.statusLabel],
    Cell: PullRequestsCellStage,
  },
];
