import React, { useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { ArrayService } from '@common-services/arrayService';

import { Props } from './Tabs.types';
import { TabsService } from './service';
import { TabsUI } from './ui/TabsUI';

const Tabs: React.FC<Props> = React.memo(({ tabs, initialActive, onTabChange }) => {
  const isMountRef = useRef(true);
  const [activeTab, setActiveTab] = useState(initialActive);
  const [tabsValues, setTabsValues] = useState<number[]>(ArrayService.getNullArray(tabs.length));

  useEffect(() => {
    const getAndSetTabsValues = async () => {
      setTabsValues(await TabsService.getTabsValues(tabs));
    };

    getAndSetTabsValues();
  }, [tabs]);

  // if parent changes an active tab explicitly a child should follow the order
  useEffect(() => setActiveTab(initialActive), [initialActive]);

  useEffect(() => {
    if (!isMountRef.current && onTabChange) {
      onTabChange(activeTab);
    }
  }, [onTabChange, activeTab]);

  useEffect(() => {
    isMountRef.current = false;
  }, []);

  const memoizedTabs = useMemo(
    () =>
      tabs.map((t, i) => ({
        key: uuidv4(),
        label: t.label,
        value: tabsValues[i],
        isActive: i === activeTab,
        onClick: () => setActiveTab(i),
      })),
    [tabs, tabsValues, activeTab]
  );

  return <TabsUI tabs={memoizedTabs} />;
});

Tabs.displayName = 'Tabs';
export { Tabs };
