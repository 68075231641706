import { publicApi } from '@common-services/api/public';
import { User } from '@common-services/api/public/generated-from-backend/models';

export const getUserDetails = async (token: string): Promise<User> => {
  return publicApi.user.getUser(token, {
    message: 'Cannot get user',
  });
};

export * as UserServices from './user';
