import { css } from '@emotion/react';

export const statusStyles = (statusColor) => ({ font, radius, spacing }) => css`
  align-items: center;
  border: 1px solid ${statusColor};
  border-radius: ${radius.default};
  color: ${statusColor};
  display: flex;
  height: 26px;
  justify-content: center;
  width: 150px;
  padding: ${spacing.gap['04']};

  span {
    font-size: 11px;
    font-weight: ${font.weight.regular};
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
