import { css } from '@emotion/react';

import SearchIcon from '@assets/icons/IconSearch.svg';

const placeholder = (color, spacing) => ({
  'alignItems': 'center',
  'display': 'flex',
  'color': color.neutral[60],
  ':before': {
    background: `url(${SearchIcon}) no-repeat`,
    backgroundSize: '100%',
    content: '" "',
    display: 'block',
    marginRight: spacing.gap['03'],
    height: 12,
    width: 12,
  },
});

const selectStyles = ({ color, spacing, font, radius, shadow }, hasGroups) => ({
  container: (styles) => ({
    ...styles,
    position: 'absolute',
    background: color.neutral[40],
    border: `1px solid ${color.neutral[40]}`,
    borderRadius: `${radius.default} ${radius.default} 0 0`,
    top: 'calc(100% + 8px)',
    minWidth: '240px',
    width: '240px',
  }),
  valueContainer: (styles) => ({
    ...styles,
    marginTop: -spacing.gap['03'],
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    backgroundColor: 'transparent',
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    visibility: 'hidden',
  }),
  option: (styles, state) => {
    const padding =
      state.root || !hasGroups
        ? `${spacing.gap['0']} ${spacing.gap['04']}`
        : `${spacing.gap['03']} ${spacing.gap['04']} ${spacing.gap['03']} ${spacing.gap['07']}`;
    return {
      ...styles,
      'cursor': 'pointer',
      'backgroundColor': color.neutral.white,
      'color': color.text.dark,
      'minHeight': 36,
      'display': 'grid',
      'alignItems': 'center',
      'borderBottom': `1px solid ${color.neutral[40]}`,
      'gridTemplateColumns': '20px calc(100% - 16px)',
      'gridColumnGap': 4,
      'padding': padding,
      'fontSize': font.size.default,
      'height': 'auto',
      'wordBreak': 'break-word',
      'lineHeight': 1.4,
      'overflow': 'hidden',
      ':hover': {
        backgroundColor: color.neutral[0],
      },
      ':active': {
        backgroundColor: color.neutral[40],
      },
      '&:last-child': {
        borderBottom: 'none',
      },
    };
  },
  control: (base, state) => ({
    ...base,
    'borderRadius': radius.default,
    'borderColor': state.isFocused ? color.neutral[80] : color.neutral[40],
    'boxShadow': state.isFocused ? shadow.focus : shadow.none,
    'paddingRight': spacing.gap['03'],
    'margin': `${spacing.gap['04']}`,
    'minHeight': 40,
    'height': 40,
    'transition': 'all 0.3s',
    '&:hover': {
      borderColor: color.neutral[80],
    },
  }),
  noOptionsMessage: (styles) => ({
    ...styles,
    borderTop: `1px solid ${color.neutral[40]}`,
    color: color.neutral[80],
    padding: '28px 20px',
    em: {
      fontStyle: 'normal',
    },
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: color.neutral[0],
    marginTop: spacing.gap['0'],
    borderRadius: `0 0 ${radius.default} ${radius.default}`,
    boxSizing: 'content-box',
    left: '-1px',
    boxShadow: 'none',
    border: `1px solid ${color.neutral[40]}`,
    borderTopWidth: 0,
  }),
  menuList: (styles) => ({
    ...styles,
    padding: spacing.gap['0'],
  }),
  input: (styles) => ({
    ...styles,
    color: color.text.dark,
    ...placeholder(color, spacing),
  }),
  placeholder: (styles) => ({
    ...styles,
    ...placeholder(color, spacing),
  }),
  group: (styles) => ({
    ...styles,
    'cursor': 'pointer',
    'padding': spacing.gap['0'],
    'background': color.neutral.white,
    '&:hover': {
      backgroundColor: color.neutral[0],
    },
  }),
  groupHeading: (styles) => ({
    ...styles,
    'cursor': 'pointer',
    'textTransform': 'none',
    'background': color.neutral[0],
    'color': 'rgb(18, 19, 67)',
    'fontSize': font.size.default,
    'fontWeight': font.weight.regular,
    'padding': `0 ${spacing.gap['04']}`,
    'margin': spacing.gap['0'],
    'borderBottom': `1px solid ${color.neutral[40]}`,
    'display': 'grid',
    'gridTemplateColumns': '16px 22px auto 16px',
    'alignItems': 'center',
    'gridColumnGap': 8,
    'height': 36,
    '&:hover': {
      backgroundColor: color.neutral[20],
    },
  }),
});

const noDropdownStyles = ({ color, spacing, radius, font, shadow }, hasGroups) => ({
  ...selectStyles({ color, spacing, radius, font, shadow }, hasGroups),
  container: (styles) => ({
    ...styles,
    ...selectStyles({ color, spacing, radius, font, shadow }, hasGroups).container(styles),
    position: 'static',
  }),
  menu: (styles) => ({
    ...styles,
    ...selectStyles({ color, spacing, radius, font, shadow }, hasGroups).menu(styles),
    position: 'static',
    border: 0,
    margin: spacing.gap['0'],
  }),
});

export const filterStyles = ({ color, spacing, radius }) => css`
  align-items: center;
  cursor: pointer;
  background: ${color.neutral.white};
  border: 1px solid ${color.neutral[40]};
  border-radius: ${radius.default};
  display: flex;
  padding: ${spacing.gap['03']} ${spacing.gap['04']};
  position: relative;
  width: 190px;
  height: 40px;
  justify-content: space-between;
  transition: ease-in-out border 0.3s;
  position: relative;
  z-index: 10;

  svg {
    height: 4px;
    fill: ${color.neutral[60]};
    width: 8px;
  }

  &:hover,
  &.open {
    border-color: ${color.neutral[60]};
    & svg {
      fill: ${color.neutral[80]};
    }
  }
`;

export const filterDropdownStyles = ({ color, spacing }) => css`
  align-items: center;
  display: flex;
`;

export const filterDropdownLabelStyles = ({ color, font, spacing }) => css`
  font-size: ${font.size.default};
  color: ${color.text.dark};
  margin-right: ${spacing.gap['03']};
  font-weight: ${font.weight.regular};
`;

export const filterDropdownAllStyles = ({ color, font, radius, spacing }) => css`
  padding: ${spacing.gap['04']};
  background: ${color.neutral[40]};
  border-bottom: 1px solid ${color.neutral[40]};
  display: flex;
  align-items: center;
  color: ${color.text.dark};
  cursor: pointer;
  margin-top: -12px;

  span > span:first-of-type {
    display: inline-block;
    padding: ${spacing.gap['0']} ${spacing.gap['03']};
  }
`;

export const filterDropdownPillStyles = ({ color, font, radius, spacing }) => css`
  background: ${color.ui.turquoise[100]};
  color: ${color.neutral.white};
  display: inline-block;
  font-size: ${font.size.xs};
  padding: ${spacing.gap['0']} ${spacing.gap['02']};
  border-radius: ${radius.default};
  height: 1.5rem;
  line-height: 1.5rem;
  min-width: 2.2rem;
  text-align: center;
`;

export const filterButtonsStyles = ({ color, radius, spacing }) => css`
  align-items: center;
  background: ${color.neutral.white};
  display: flex;
  border-radius: 0 0 ${radius.default} ${radius.default};
  border-top: 1px solid ${color.neutral['20']};
  justify-content: space-between;
  padding: ${spacing.gap['03']} ${spacing.gap['04']};
`;

export const selectTsStyles = () => css`
  position: absolute;
  width: 240px;
  min-width: 240px;
  top: calc(100% + 8px);
`;

export default selectStyles;
export { noDropdownStyles };
