import React from 'react';

import { Outage } from '@lib/empty/outage';

const OutagePrototype = () => {
  return (
    <div className="mt-2">
      <Outage />
    </div>
  );
};

export default OutagePrototype;
