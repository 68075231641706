import {
  ChartParamsReducerTypes,
  IChartParamsReducerAction,
} from '@align-pages/dashboard/reducers/chartParamsReducerTypes';
import { DashboardChart } from '@common-services/api/private/generated-from-backend/models';

export function chartParamsReducer(
  state: DashboardChart,
  action: IChartParamsReducerAction
): DashboardChart {
  switch (action.type) {
    case ChartParamsReducerTypes.INIT:
      return action.params;
    case ChartParamsReducerTypes.DATE_CHANGE:
      return {
        ...state,
        ...action.dateParams,
      };
    case ChartParamsReducerTypes.FILTERS_UPDATE:
      return {
        ...state,
        filters: action.filtersParams,
      };
    case ChartParamsReducerTypes.GROUP_BY_UPDATE:
      return {
        ...state,
        group_by: action.groupByParams,
      };
    default:
      return state;
  }
}
