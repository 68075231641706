import { css } from '@emotion/react';

export const issueTypeLabelStyles = ({ color, font, spacing }) => css`
  align-items: center;
  display: flex;
  margin-left: ${spacing.gap['03']};

  .label {
    color: ${color.neutral[100]};
    font-size: ${font.size.default};
    font-weight: ${font.weight.regular};
    margin-left: ${spacing.gap['02']};
  }
`;

export const emptyStateImgStyles = () => css`
  margin: auto;
  width: 80%;
`;
