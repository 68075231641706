import React from 'react';

import Input from '@lib/Selector/components/Input';

import getStyle from './styles';

interface Props {
  placeholder?: string;
  inputValue: string;
  onInputChange?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  onInputClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
}

const Control: React.FC<Props> = ({ placeholder, inputValue, onInputClick, onInputChange }) => (
  <div css={getStyle}>
    <div className="value-container">
      <Input
        placeholder={placeholder}
        value={inputValue}
        onClick={onInputClick}
        onChange={onInputChange}
      />
    </div>
  </div>
);

export default Control;
