import React from 'react';

import Simple from '@analytics-pages/templates/Simple';
import { useAuth0 } from '@common-context/Auth0';

const Logout = () => {
  const { loading, isAuthenticated, logout } = useAuth0();

  if (loading) {
    return <Simple>Loading...</Simple>;
  }

  if (!isAuthenticated) {
    return <Simple>Not authenticated!</Simple>;
  }

  logout({ returnTo: window.ENV.auth.logoutRedirectUri });
  return [];
};

export default Logout;
