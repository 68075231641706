import { CSSProperties } from 'react';
import { Edge, Node } from 'react-flow-renderer';

import {
  INodeData,
  INodeEdgeGenerator,
} from '@align-pages/goals/single/components/tree-view/treeView.types';
import { ITeamGoal, TeamGoalInput } from '@align-services/api/types/goalsTypes';
import { Unit, ValueType } from '@align-types/constants';
import { GoalMetricParams } from '@common-services/api/private/generated-from-backend/models';

export const calculateLength = (childrenCount: number): number => {
  return childrenCount * 300 + (childrenCount - 1) * 40;
};

export const calculateX = (parentX: number, childrenCount: number, index: number): number => {
  const overallLength = calculateLength(childrenCount);
  return parentX + 150 - overallLength / 2 + index * 340;
};

export const removeNodes = (nodes: Node[], goal: ITeamGoal): Node[] => {
  let filteredNodes = [...nodes];
  const removeChildren = (childGoal) => {
    filteredNodes = filteredNodes.filter((node) => node.id !== childGoal.team.id.toString());
    childGoal.children?.forEach((child) => removeChildren(child));
  };
  removeChildren(goal);
  return filteredNodes;
};

export const removeEdges = (edges: Edge[], goal: ITeamGoal): Edge[] => {
  let filteredEdges = [...edges];
  const removeChildren = (childGoal) => {
    filteredEdges = filteredEdges.filter((edge) => edge.target !== childGoal.team.id.toString());
    childGoal.children?.forEach((child) => removeChildren(child));
  };
  removeChildren(goal);
  return filteredEdges;
};

export const canSaveGoal = (
  targetValues: TeamGoalInput[],
  existingTargetsCount: number
): boolean => {
  if (!targetValues?.length) return false;
  const targetsToRemove = targetValues.filter((target) => target.remove).length;
  const targetsToAdd = targetValues.length - targetsToRemove;
  if (existingTargetsCount > targetsToRemove || targetsToAdd > 0) {
    return true;
  }
  return false;
};

export const findNestedTeamById = (teamGoal: ITeamGoal, id: number): ITeamGoal => {
  return teamGoal.team.id === id
    ? teamGoal
    : teamGoal.children.reduce(
        (acc, teamGoal) => acc ?? findNestedTeamById(teamGoal, id),
        undefined
      );
};

export const generateNode = (
  rootGoal: INodeEdgeGenerator,
  parentGoal: ITeamGoal,
  childGoal: ITeamGoal,
  targetValues: TeamGoalInput[],
  valueType: ValueType,
  unit: Unit,
  parentNode: Node<INodeData>,
  index: number,
  handleToggle: (
    goal: ITeamGoal,
    targetValues: TeamGoalInput[],
    valType: ValueType,
    unit: Unit
  ) => void,
  isTLO: boolean,
  threshold: GoalMetricParams['threshold']
): Node<INodeData> => {
  // add the child node to the nodes tree if expanding

  // new coordinates for the child node relatively from the coordinates of its parent
  const newX = parentNode
    ? calculateX(parentNode.position.x, parentGoal.children.length, index)
    : window.innerWidth / 2;
  const newY = parentNode ? parentNode.position.y + 300 : 50;

  return {
    id: childGoal.team.id?.toString(),
    data: {
      range: {
        dateFrom: rootGoal.validFrom,
        dateTo: rootGoal.expiresAt,
      },
      goal: childGoal,
      isExpanded: false,
      targetValues,
      valueType,
      unit,
      onSetTarget: rootGoal.onSetTarget,
      onToggle: (goal, targetValues, valType, unit) =>
        handleToggle(goal, targetValues, valType, unit),
      isTLO,
      threshold,
    },
    draggable: false,
    position: { x: newX, y: newY },
    type: 'teamNode',
  };
};

export const generateEdge = (parentGoal: ITeamGoal, childGoal: ITeamGoal, style: CSSProperties) => {
  return {
    id: `${parentGoal.team.id}-${childGoal.team.id}`,
    source: parentGoal.team.id.toString(),
    target: childGoal.team.id.toString(),
    type: 'smoothstep',
    style,
  };
};

export * as TreeViewServices from './treeView.services';
