import React from 'react';

import { wrapperStyles } from './filterDropdownBoxItem.styles';
import { IFilterDropdownBoxItem } from './filterDropdownBoxItem.types';

export const FilterDropdownBoxItem: React.FC<IFilterDropdownBoxItem> = React.memo(({ data }) => {
  const { image, name } = data;
  return (
    <div css={wrapperStyles}>
      {image && <img className="option-image" src={image} alt={name} />}
      {name}
    </div>
  );
});
