import _ from 'lodash';
import moment from 'moment';
import React from 'react';

import PositiveNegativeOverlappedBarChart from '@ui/charts/PositiveNegativeOverlappedBarChart';

const startDate = moment(new Date('2020-01-01')).startOf('day');
const data = _(Array(15))
  .map((v, i) => ({
    x: moment(startDate).add(i, 'days').toDate(),
    y: { good: parseInt(Math.random() * 100), bad: parseInt(Math.random() * 100) },
  }))
  .value();

const PositiveNegativeOverlappedBarChartDemo = () => (
  <PositiveNegativeOverlappedBarChart
    data={data}
    params={{
      yAxis: {
        text: 'Bugs',
      },
      tooltips: {
        extra: {
          bad: 'Raised',
          good: 'Fixed',
        },
      },
    }}
  />
);

export default PositiveNegativeOverlappedBarChartDemo;
