import React, { useCallback, useState } from 'react';

import { fieldWrapperStyles } from '@common-pages/Settings/Allocation/WorkTypes/rulesDef/IssueType/styles';
import DropdownSelector from '@lib/dropdown-selector';

const JiraLabel = ({ allJiraLabels, initialValue, onChange }) => {
  const [selectedJiraLabels, setSelectedJiraLabels] = useState(initialValue || []);

  const handleChange = useCallback(
    (value) => {
      setSelectedJiraLabels(value);
      onChange(value);
    },
    [selectedJiraLabels]
  );

  return (
    <div css={fieldWrapperStyles}>
      <DropdownSelector
        initialValue={selectedJiraLabels}
        noAvatar
        options={allJiraLabels}
        placeholder="All Jira labels"
        onChange={handleChange}
      />
    </div>
  );
};

export default JiraLabel;
