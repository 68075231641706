import React from 'react';

import NotFound404 from '@analytics-pages/NotFound404';
import { Share } from '@analytics-pages/Share';
import Prototypes from '@analytics-pages/prototypes';
import Charts from '@analytics-pages/prototypes/Charts';
import Knowledge from '@analytics-pages/prototypes/Knowledge';
import Maintenance from '@analytics-pages/prototypes/Maintenance';
import Omnibox from '@analytics-pages/prototypes/Omnibox';
import Outage from '@analytics-pages/prototypes/Outage';
import Sidebar from '@analytics-pages/prototypes/Sidebar';
import Tooltips from '@analytics-pages/prototypes/Tooltips';
import Upgrade from '@analytics-pages/prototypes/Upgrade';
import MultiSelect from '@analytics-pages/prototypes/multi-select';
import Development from '@utils/development';

import UIController from './UIController';

const extraRoutes = [
  {
    route: {
      path: '/share',
    },
    component: Share,
  },
  {
    route: {
      path: '/ui/switch/:version',
    },
    component: UIController,
  },
  {
    route: {
      path: '/prototypes/:name?',
    },
    component: Prototypes,
    componentProps: {
      prototypes: {
        charts: <Charts />,
        tooltips: <Tooltips />,
        omnibox: <Omnibox />,
        maintenance: <Maintenance />,
        outage: <Outage />,
        multiSelect: <MultiSelect />,
        sidebar: <Sidebar />,
        knowledge: <Knowledge />,
        upgrade: <Upgrade />,
      },
    },
  },
  {
    route: {
      path: '/bearer',
    },
    component: Development.Bearer,
  },
  {
    route: {
      path: '*',
    },
    component: NotFound404,
  },
];

export default extraRoutes;
