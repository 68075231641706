import {
  CompletedKPIMetric,
  IBreakdownConfig,
  IMetricBetter,
  IMetricsConfig,
  IMetricsConfigNames,
  JiraInProgressOrderByField,
  JiraIssueMetricField,
  JiraStatusLabel,
  PrInProgressOrderByField,
  ValueType,
} from '@align-types/constants';
import { GoalTemplate } from '@common-services/api/public/generated-from-backend/models';
import { ObjectService } from '@common-services/objectService';
import { PR_STAGE, PR_STAGE_API_MAPPING } from '@common-services/prHelpers';
import { icons } from '@lib/icon';
import { theme } from '@styles/theme';

export const CONFIG = {
  APP_ROUTES: {
    DASHBOARD: '/align/:path(.*)/dashboard',
    GOALS: '/align/:path(.*)/goals',
    TEAMS: '/align/:path(.*)/teams',
    STORE: '/store-examples',
  },
  API: {
    FETCH_OPTIONS: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      optimisticResults: false,
      staleTime: 300000,
      cacheTime: 300000,
    },
    TEAMS: {
      ROOT_TEAM_ID: 0,
    },
  },
  MOCK: {
    MOCK_FOLDER_PATH: './mock-api-server/json/',
  },
};

// TODO: update config: remove unnecessary fields: name, displayName, valueType
export const metricsConfig: IMetricsConfig = {
  [IMetricsConfigNames.prWipTime]: {
    hidden: true,
    name: IMetricsConfigNames.prWipTime,
    displayName: 'WIP Time',
    color: theme.color.ui.blue['100'],
    icon: icons.nav_outcome,
    valueType: ValueType.date,
    unit: null,
    better: IMetricBetter.LOWER,
    metricDescription: {
      short: 'under <span>:value</span>',
      full: 'Average time to submit pull requests',
    },
    allKPI: CompletedKPIMetric.WIP,
    mainKPI: IMetricsConfigNames.prWipTime,
    inProgressStages: [ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.WIP)],
    inProgressOrderBy: PrInProgressOrderByField.WIP,
  },
  [IMetricsConfigNames.prReviewComments]: {
    name: IMetricsConfigNames.prReviewComments,
    displayName: 'Review Comments / PR',
    color: theme.color.neutral['80'],
    icon: icons.nav_outcome,
    valueType: ValueType.float,
    unit: {
      singular: 'comment',
      plural: 'comments',
    },
    better: IMetricBetter.HIGHER,
  },
  [IMetricsConfigNames.prReviewTime]: {
    hidden: true,
    name: IMetricsConfigNames.prReviewTime,
    displayName: 'Review Time',
    color: theme.color.ui.blue['100'],
    icon: icons.nav_outcome,
    valueType: ValueType.date,
    unit: null,
    better: IMetricBetter.LOWER,
    metricDescription: {
      short: 'under <span>:value</span>',
      full: 'Average time to review pull requests',
    },
    completedKPI: CompletedKPIMetric.REVIEW,
    mainKPI: IMetricsConfigNames.prReviewTime,
    inProgressStages: [ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.REVIEW)],
    inProgressOrderBy: PrInProgressOrderByField.REVIEW_REQUEST,
  },
  [IMetricsConfigNames.prMergeTime]: {
    hidden: true,
    name: IMetricsConfigNames.prMergeTime,
    displayName: 'Merge Time',
    color: theme.color.ui.blue['100'],
    icon: icons.nav_outcome,
    valueType: ValueType.date,
    unit: null,
    better: IMetricBetter.LOWER,
    metricDescription: {
      short: 'under <span>:value</span>',
      full: 'Average time to merge pull requests',
    },
    completedKPI: CompletedKPIMetric.MERGE,
    mainKPI: IMetricsConfigNames.prMergeTime,
    inProgressStages: [
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.WIP),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.REVIEW),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.MERGE),
    ],
    inProgressOrderBy: PrInProgressOrderByField.MERGE,
  },
  [IMetricsConfigNames.prSize]: {
    hidden: true,
    name: IMetricsConfigNames.prSize,
    displayName: 'PR Size',
    color: theme.color.ui.blue['100'],
    icon: icons.nav_outcome,
    valueType: ValueType.int,
    unit: {
      singular: 'line',
      plural: 'lines',
    },
    better: IMetricBetter.LOWER,
    metricDescription: {
      short: 'less than <span>:value</span>',
      full: 'Average lines of code in pull requests',
    },
    allKPI: CompletedKPIMetric.ALL,
    mainKPI: IMetricsConfigNames.prSize,
    inProgressStages: [
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.WIP),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.REVIEW),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.MERGE),
    ],
    inProgressOrderBy: PrInProgressOrderByField.SIZE,
  },
  [IMetricsConfigNames.prCycleTime]: {
    hidden: true,
    name: IMetricsConfigNames.prCycleTime,
    displayName: 'PR Cycle Time',
    color: theme.color.ui.blue['100'],
    icon: icons.nav_outcome,
    valueType: ValueType.date,
    unit: null,
    better: IMetricBetter.LOWER,
    metricDescription: {
      short: 'under <span>:value</span>',
      full: 'Average time to deliver pull requests',
    },
    completedKPI: CompletedKPIMetric.CYCLE,
    mainKPI: IMetricsConfigNames.prCycleTime,
    inProgressStages: [
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.WIP),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.REVIEW),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.MERGE),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.RELEASE),
    ],
    inProgressOrderBy: PrInProgressOrderByField.TOTAL_STAGE,
  },
  [IMetricsConfigNames.releaseCount]: {
    name: IMetricsConfigNames.releaseCount,
    displayName: 'Release Frequency',
    color: theme.color.neutral['80'],
    icon: icons.nav_outcome,
    valueType: ValueType.int,
    unit: {
      singular: 'release',
      plural: 'releases',
      isFrequency: true,
    },
    better: IMetricBetter.HIGHER,
  },
  [IMetricsConfigNames.jiraResolved]: {
    name: IMetricsConfigNames.jiraResolved,
    displayName: 'Issue Resolution Frequency',
    color: theme.color.neutral['80'],
    icon: icons.nav_outcome,
    valueType: ValueType.int,
    unit: {
      singular: 'issue',
      plural: 'issues',
      isFrequency: true,
    },
    better: IMetricBetter.HIGHER,
  },
  [IMetricsConfigNames.prAllMappedToJira]: {
    name: IMetricsConfigNames.prAllMappedToJira,
    displayName: 'PR Mapping',
    color: theme.color.neutral['80'],
    icon: icons.nav_outcome,
    valueType: ValueType.float,
    unit: {
      singular: '%',
      plural: '%',
    },
    better: IMetricBetter.HIGHER,
  },
  [IMetricsConfigNames.prWaitFirstReviewTime]: {
    hidden: true,
    name: IMetricsConfigNames.prWaitFirstReviewTime,
    displayName: 'Wait Time 1st Review',
    color: theme.color.neutral['80'],
    icon: icons.nav_outcome,
    valueType: ValueType.date,
    unit: null,
    better: IMetricBetter.LOWER,
  },
  [IMetricsConfigNames.prOpenTime]: {
    hidden: true,
    name: IMetricsConfigNames.prOpenTime,
    displayName: 'PR Open Time',
    color: theme.color.neutral['80'],
    icon: icons.nav_outcome,
    valueType: ValueType.date,
    unit: null,
    better: IMetricBetter.LOWER,
  },
  [IMetricsConfigNames.jiraLeadTime]: {
    hidden: true,
    name: IMetricsConfigNames.jiraLeadTime,
    displayName: 'Lead Time',
    color: theme.color.neutral['80'],
    icon: icons.nav_outcome,
    valueType: ValueType.date,
    unit: null,
    better: IMetricBetter.LOWER,
    jiraMetricField: JiraIssueMetricField.LEAD_TIME,
  },
  [IMetricsConfigNames.prReviewedRatio]: {
    name: IMetricsConfigNames.prReviewedRatio,
    displayName: 'PRs Reviewed',
    color: theme.color.neutral['80'],
    icon: icons.nav_outcome,
    valueType: ValueType.float,
    unit: {
      singular: '%',
      plural: '%',
    },
    better: IMetricBetter.HIGHER,
  },
  [IMetricsConfigNames.prWipTimeThreshold]: {
    name: IMetricsConfigNames.prWipTimeThreshold,
    displayName: 'WIP Time',
    color: theme.color.ui.blue['100'],
    icon: icons.percentage,
    valueType: ValueType.date,
    unit: null,
    better: IMetricBetter.LOWER,
    metricDescription: {
      short: 'under <span>:value</span>',
      full: ':percentage of pull requests are submitted',
    },
    allKPI: CompletedKPIMetric.WIP,
    mainKPI: IMetricsConfigNames.prWipTime,
    inProgressStages: [ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.WIP)],
    inProgressOrderBy: PrInProgressOrderByField.WIP,
  },
  [IMetricsConfigNames.prReviewTimeThreshold]: {
    name: IMetricsConfigNames.prReviewTimeThreshold,
    displayName: 'Review Time',
    color: theme.color.ui.blue['100'],
    icon: icons.percentage,
    valueType: ValueType.date,
    unit: null,
    better: IMetricBetter.LOWER,
    completedKPI: CompletedKPIMetric.REVIEW,
    mainKPI: IMetricsConfigNames.prReviewTime,
    metricDescription: {
      short: 'within <span>:value</span>',
      full: ':percentage of pull requests reviewed',
    },
    inProgressStages: [ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.REVIEW)],
    inProgressOrderBy: PrInProgressOrderByField.REVIEW_REQUEST,
  },
  [IMetricsConfigNames.prWaitFirstReviewTimeThreshold]: {
    name: IMetricsConfigNames.prWaitFirstReviewTimeThreshold,
    displayName: 'Wait Time for 1st Review',
    color: theme.color.ui.blue['100'],
    icon: icons.percentage,
    valueType: ValueType.date,
    unit: null,
    better: IMetricBetter.LOWER,
    completedKPI: CompletedKPIMetric.WAIT,
    mainKPI: IMetricsConfigNames.prWaitFirstReviewTime,
    metricDescription: {
      short: 'less than <span>:value</span>',
      full: ':percentage of pull requests waiting for review during',
    },
    inProgressStages: [
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.WIP),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.REVIEW),
    ],
    inProgressOrderBy: PrInProgressOrderByField.REVIEW_REQUEST,
  },
  [IMetricsConfigNames.prMergeTimeThreshold]: {
    name: IMetricsConfigNames.prMergeTimeThreshold,
    displayName: 'Merge Time',
    color: theme.color.ui.blue['100'],
    icon: icons.percentage,
    valueType: ValueType.date,
    unit: null,
    better: IMetricBetter.LOWER,
    metricDescription: {
      short: 'under <span>:value</span>',
      full: ':percentage of pull requests merged',
    },
    completedKPI: CompletedKPIMetric.MERGE,
    mainKPI: IMetricsConfigNames.prMergeTime,
    inProgressStages: [
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.WIP),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.REVIEW),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.MERGE),
    ],
    inProgressOrderBy: PrInProgressOrderByField.MERGE,
  },
  [IMetricsConfigNames.prSizeThreshold]: {
    name: IMetricsConfigNames.prSizeThreshold,
    displayName: 'PR Size',
    color: theme.color.ui.blue['100'],
    icon: icons.percentage,
    valueType: ValueType.int,
    unit: {
      singular: 'line',
      plural: 'lines',
    },
    better: IMetricBetter.LOWER,
    metricDescription: {
      short: 'less than <span>:value</span>',
      full: ':percentage of pull requests with',
    },
    allKPI: CompletedKPIMetric.ALL,
    mainKPI: IMetricsConfigNames.prSize,
    inProgressStages: [
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.WIP),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.REVIEW),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.MERGE),
    ],
    inProgressOrderBy: PrInProgressOrderByField.SIZE,
  },
  [IMetricsConfigNames.prReviewCommentsThreshold]: {
    name: IMetricsConfigNames.prReviewCommentsThreshold,
    displayName: 'Review Comments / PR',
    color: theme.color.neutral['80'],
    icon: icons.percentage,
    valueType: ValueType.float,
    unit: {
      singular: 'comment',
      plural: 'comments',
    },
    better: IMetricBetter.HIGHER,
    metricDescription: {
      short: 'more than <span>:value</span>',
      full: ':percentage of pull requests with',
    },
  },
  [IMetricsConfigNames.prCycleDeploymentThreshold]: {
    name: IMetricsConfigNames.prCycleDeploymentThreshold,
    displayName: 'PR Cycle Time',
    color: theme.color.ui.blue['100'],
    icon: icons.percentage,
    valueType: ValueType.date,
    unit: null,
    better: IMetricBetter.LOWER,
    completedKPI: CompletedKPIMetric.CYCLE,
    mainKPI: IMetricsConfigNames.prCycleTime,
    metricDescription: {
      short: 'within <span>:value</span>',
      full: ':percentage of pull requests delivered',
    },
    inProgressStages: [
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.WIP),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.REVIEW),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.MERGE),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.RELEASE),
    ],
    inProgressOrderBy: PrInProgressOrderByField.TOTAL_STAGE,
  },
  [IMetricsConfigNames.prOpenTimeThreshold]: {
    name: IMetricsConfigNames.prOpenTimeThreshold,
    displayName: 'PR Open Time',
    color: theme.color.ui.blue['100'],
    icon: icons.percentage,
    valueType: ValueType.date,
    unit: null,
    better: IMetricBetter.LOWER,
    metricDescription: {
      short: 'less than <span>:value</span>',
      full: ':percentage of pull requests opened for',
    },
    completedKPI: CompletedKPIMetric.OPEN,
    mainKPI: IMetricsConfigNames.prOpenTime,
    inProgressStages: [
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.WIP),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.REVIEW),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.MERGE),
    ],
    inProgressOrderBy: PrInProgressOrderByField.TOTAL_STAGE,
  },
  [IMetricsConfigNames.jiraLeadTimeThreshold]: {
    name: IMetricsConfigNames.jiraLeadTimeThreshold,
    displayName: 'Lead Time',
    color: theme.color.ui.blue['100'],
    icon: icons.percentage,
    valueType: ValueType.date,
    unit: null,
    better: IMetricBetter.LOWER,
    metricDescription: {
      short: 'within <span>:value</span>',
      full: ':percentage of issues resolved',
    },
    completedKPI: CompletedKPIMetric.JIRA_RESOLVED,
    mainKPI: IMetricsConfigNames.jiraLeadTime,
    inProgressStages: [JiraStatusLabel.IN_PROGRESS],
    inProgressOrderBy: JiraInProgressOrderByField.WORK_BEGAN,
    inProgressOrderAscending: true,
    jiraMetricField: JiraIssueMetricField.LEAD_TIME,
  },
  [IMetricsConfigNames.prCycleTimeThreshold]: {
    name: IMetricsConfigNames.prCycleTimeThreshold,
    displayName: 'PR Cycle Time',
    color: theme.color.ui.blue['100'],
    icon: icons.percentage,
    valueType: ValueType.date,
    unit: null,
    better: IMetricBetter.LOWER,
    metricDescription: {
      short: 'within <span>:value</span>',
      full: ':percentage of pull requests delivered',
    },
    completedKPI: CompletedKPIMetric.CYCLE,
    mainKPI: IMetricsConfigNames.prCycleTime,
    inProgressStages: [
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.WIP),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.REVIEW),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.MERGE),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.RELEASE),
    ],
    inProgressOrderBy: PrInProgressOrderByField.TOTAL_STAGE,
  },
  [IMetricsConfigNames.jiraLifeTimeThreshold]: {
    name: IMetricsConfigNames.jiraLifeTimeThreshold,
    displayName: 'MTTR',
    color: theme.color.ui.blue['100'],
    icon: icons.percentage,
    valueType: ValueType.date,
    unit: null,
    better: IMetricBetter.LOWER,
    metricDescription: {
      short: 'within <span>:value</span>',
      full: ':percentage of issues resolved',
    },
    completedKPI: CompletedKPIMetric.JIRA_RESOLVED,
    mainKPI: IMetricsConfigNames.jiraLifeTime,
    inProgressStages: [JiraStatusLabel.TO_DO, JiraStatusLabel.IN_PROGRESS],
    inProgressOrderBy: JiraInProgressOrderByField.CREATED,
    inProgressOrderAscending: true,
    jiraMetricField: JiraIssueMetricField.MTTR,
  },
  [IMetricsConfigNames.jiraAckTimeThreshold]: {
    name: IMetricsConfigNames.jiraAckTimeThreshold,
    displayName: 'MTTA',
    color: theme.color.ui.blue['100'],
    icon: icons.percentage,
    valueType: ValueType.date,
    unit: null,
    better: IMetricBetter.LOWER,
    metricDescription: {
      short: 'within <span>:value</span>',
      full: ':percentage of issues acknowledged',
    },
    completedKPI: CompletedKPIMetric.JIRA_ACKNOWLEDGED,
    mainKPI: IMetricsConfigNames.jiraAcknowledgedTime,
    inProgressStages: [JiraStatusLabel.TO_DO],
    inProgressOrderBy: JiraInProgressOrderByField.CREATED,
    inProgressOrderAscending: true,
    jiraMetricField: JiraIssueMetricField.ACK_TIME,
  },
};

export const goalTemplatesStorybookConfig: GoalTemplate[] = [
  { metric: 'pr-review-time', id: 1, name: 'Reduce code review time', available: true },
];

export const drillDownTooltipText =
  '[Coming soon] This goal still does not support drill down on a weekly/monthly basis.';

export const breakdownConfig: IBreakdownConfig = {
  optionsInitialSelection: 5,
  optionsMaxSelection: 8,
  optionsMinSelection: 1,
};
