import React from 'react';
import { CellProps, Renderer } from 'react-table';

import { IIssuesTableRow } from '@align-types/issues';

import { imageStyles } from './cellAssignee.styles';

export const IssuesCellAssignee: Renderer<CellProps<IIssuesTableRow>> = React.memo(
  ({ row }) =>
    row.depth === 0 && (
      <img
        alt="Avatar of the assignee"
        className="pr-user-avatar"
        css={imageStyles}
        src={row.original.assigneeAvatar}
        title={row.original.assignee}
      />
    )
);
