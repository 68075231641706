import classnames from 'classnames';
import React from 'react';

import { SmallTitle } from '@analytics-components/Typography';
import { InsightsError } from '@analytics-components/insights/Helper';
import DataWidget, { useDataWidget } from '@analytics-context/DataWidget';
import { NoData } from '@lib/empty/noData';
import { Info } from '@lib/info';

import { chartTitleStyles } from './styles';

const ChartTitle = ({ title, description }) => {
  if (!title) return null;

  return (
    <div css={chartTitleStyles}>
      <SmallTitle content={title} />
      {description && <Info content={description} />}
    </div>
  );
};

const ChartBox = ({ title, description, children }) => (
  <>
    <ChartTitle title={title} description={description} />
    {children}
  </>
);

const ChartBoxWithKPIWidget = ({ id, title, description, fetcher, plumber, prefetchedDataIds }) => {
  return (
    <ChartBox title={title} description={description}>
      <DataWidget id={id} fetcher={fetcher} plumber={plumber} prefetchedDataIds={prefetchedDataIds}>
        <ChartBoxWithKPI />
      </DataWidget>
    </ChartBox>
  );
};

const DefaultKpiWrapper = ({ children }) => (
  <div className="col-5 align-self-center">
    <div className="row justify-content-center">
      <div className="col-8">{children}</div>
    </div>
  </div>
);

const ChartBoxWithKPI = () => {
  const { data, error, isLoading } = useDataWidget();
  if (isLoading) {
    return <div className="insights-placeholder" />;
  }

  if (error) {
    return <InsightsError />;
  }

  return <ChartBoxWithKPIUI data={data} />;
};

const ChartBoxWithKPIUI = ({ data }) => {
  const { chart, empty, emptyPage, kpis, kpiClassName, kpisWrapper, table } = data;

  if (empty) {
    return emptyPage || <NoData />;
  }

  const chartColSize = kpis.length === 0 || kpisWrapper ? 'col-12' : 'col-7';

  const KpisWrapper = kpisWrapper || DefaultKpiWrapper;

  return (
    <>
      <div className="row">
        {chart?.component && !chart?.isRenderedLast && (
          <div className={chartColSize}>
            <chart.component {...chart.params} />
          </div>
        )}
        {kpis.length > 0 && (
          <KpisWrapper>
            {kpis.map((kpi, i) => (
              <BoxKPI className={kpiClassName} key={i} title={kpi.title} subtitle={kpi.subtitle}>
                <kpi.component key={i} params={kpi.params} />
              </BoxKPI>
            ))}
          </KpisWrapper>
        )}
        {chart?.component && chart?.isRenderedLast && (
          <div className={chartColSize}>
            <chart.component {...chart.params} />
          </div>
        )}
      </div>
      {table && <table.component {...table.data} />}
    </>
  );
};

const BoxKPI = ({ className, title, subtitle, children }) => (
  <div className={`card border-0 ${className || 'bg-light mb-4'}`}>
    <div className={`card-body ${!className && 'p-4'}`}>
      <h5
        className={classnames(
          'card-title text-xs text-uppercase',
          title.bold && 'font-weight-bold'
        )}
      >
        {title.text}
      </h5>
      {subtitle && (
        <h6
          className={classnames(
            'card-subtitle mb-2 text-xs text-uppercase',
            subtitle.bold && 'font-weight-bold'
          )}
        >
          {subtitle.text}
        </h6>
      )}
      <div className="card-text">{children}</div>
    </div>
  </div>
);

export default ChartBox;
export { ChartBoxWithKPIWidget, ChartBoxWithKPIUI };
