export function updateNestedObjByKeyValue<T extends Record<string, any>, K extends keyof T>(
  item: T,
  key: K,
  val: any
): T {
  let result = { ...item };
  const childrenRes = [];
  result?.children.forEach((children) => {
    const childrenUpdated = updateNestedObjByKeyValue(children, key, val);
    childrenRes.push(childrenUpdated);
  });
  result = {
    ...result,
    [key]: val,
    children: childrenRes,
  };
  return result;
}

export function updateNestedObjByIdKeyValue<T extends Record<string, any>, K extends keyof T>(
  item: T,
  ids: string[],
  key: K,
  val: any
): T {
  if (ids.includes(item.id)) item[key] = val;
  item?.children.map((children) => {
    return updateNestedObjByIdKeyValue(children, ids, key, val);
  });
  return item;
}

export function updateNestedObjToggle(item, id: string | null, name: string) {
  if (item.id === id) item[name] = !item[name];
  item?.children.map((children) => {
    return updateNestedObjToggle(children, id, name);
  });
  return item;
}

export function getNestedObjAllIdsByKeyValue<K extends keyof T, T extends Record<string, any>>(
  item: T,
  name: K,
  val: any
): string[] {
  let result: string[] = [];
  if ((val && item[name] === val) || item[name]) {
    result.push(item.id);
  }
  item?.children.forEach((children) => {
    const int = getNestedObjAllIdsByKeyValue(children, name, val);
    result = [...result, ...int];
  });
  return result;
}

export function isNestedObjContain<K extends keyof T, T extends Record<string, any>>(
  item: T,
  name: K,
  contain: RegExp | string | number | boolean | null
): boolean {
  let result = false;
  if (contain === null && item[name] === contain) return true;
  if (typeof contain === 'object' && contain !== null && contain.test(item[name])) return true;
  if (typeof contain !== 'object' && item[name] === contain) return true;
  item?.children.every((children) => {
    result = isNestedObjContain(children, name, contain);
    return !result;
  });
  return result;
}

export function getKeyByValue<T extends Record<string, any>, K extends keyof T>(
  object: T,
  value: any
): K {
  return Object.keys(object).find((key) => object[key] === value) as K;
}

export * as ObjectService from './objectService';
