import { css } from '@emotion/react';

import { IBadgeSize } from '@common-types/common';

const sizeProps = (color) => ({
  [IBadgeSize.DEFAULT]: {
    height: '40px',
  },
  [IBadgeSize.SMALL]: {
    height: '32px',
    border: `1px solid ${color.neutral['60']}`,
  },
  [IBadgeSize.XSMALL]: {
    height: '28px',
  },
});

export const wrapperStyles = ({ isOpened, size }) => ({
  spacing,
  radius,
  font,
  color,
  transition,
}) => css`
  ${sizeProps(color)[size]}
  padding: ${spacing.gap['03']};
  font-weight: ${font.weight.medium};
  border-radius: ${radius.default};
  user-select: none;

  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: ${color.neutral[100]};
  background-color: ${color.neutral.white};
  transition: ${transition.default};

  ${
    isOpened
      ? `background: ${color.neutral[20]};`
      : `&:hover {
        background-color: ${color.neutral[20]};
        border-color: ${color.neutral[80]};
      }`
  }

  .icon {
    margin-right: ${spacing.gap['03']};
  }
`;
