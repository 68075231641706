import { css } from '@emotion/react';

export const wrapperStyles = ({ color, font, spacing }) => css`
  align-items: center;
  border-bottom: 1px solid ${color.neutral[40]};
  display: flex;
  justify-content: space-between;
  padding: ${spacing.gap['03']} ${spacing.gap['05']} ${spacing.gap['03']}
    calc(${spacing.gap['06']} + ${spacing.gap['07']});

  .ath-rule-fields {
    align-items: center;
    display: flex;

    .ath-rule-title {
      align-items: center;
      display: flex;
      width: 172px;

      .ath-rule-label {
        color: ${color.text.dark};
        font-size: ${font.size.default};
        font-weight: ${font.weight.regular};
        margin-left: ${spacing.gap['03']};
      }
    }
  }
`;
