import React from 'react';

import { useBreadcrumb } from '@analytics-context/Breadcrumb';
import { ComingSoon } from '@lib/empty/comingSoon';

import RunTimeSummaryBox from './run-time';
import SuccessRatioSummaryBox from './success-ratio';

const SummaryBox = () => {
  const { subsection: activeStage } = useBreadcrumb();
  const Component =
    {
      'success-ratio': SuccessRatioSummaryBox,
      'run-time': RunTimeSummaryBox,
    }[activeStage] || ComingSoon;

  return <Component />;
};

export default SummaryBox;
