import React from 'react';

import { getFeature, featuresList } from '@analytics-services/flags';
import { useUserContext } from '@common-context/User';

import CodeReviewsPanel from './CodeReviewsPanel';
import ReviewActivityPanel from './ReviewActivityPanel';
import ReviewEngagementPanel from './ReviewEngagementPanel';
import WaitTimePanel from './WaitTimePanel';

const InsightsReview = () => {
  const { features } = useUserContext();
  const hideReviewActivityFeatureFlag = getFeature(featuresList.hide_review_activity, features);

  return (
    <>
      <div className="row mx-0 px-0 mb-4">
        <div className="col-md-12 mx-0 px-0">
          <CodeReviewsPanel />
        </div>
      </div>
      <div className="row mx-0 px-0 mb-4">
        <div className="col-md-12 mx-0 px-0">
          <WaitTimePanel />
        </div>
      </div>
      <div className="row mx-0 px-0 mb-4">
        <div className="col-md-12 mx-0 px-0">
          <ReviewEngagementPanel />
        </div>
      </div>
      {!hideReviewActivityFeatureFlag && (
        <div className="row mx-0 px-0 mb-4">
          <div className="col-md-12 mx-0 px-0">
            <ReviewActivityPanel />
          </div>
        </div>
      )}
    </>
  );
};

export default InsightsReview;
