import React from 'react';

import { Spinner } from '@lib/Spinner';

import {
  showMoreCellStyles,
  showMoreTextStyles,
  spinnerWrapperStyles,
  tableBodyCellStyles,
  tableBodyRowStyles,
  tableHeaderRowStyles,
  tableHeaderStyles,
  tableStyles,
  trSpacerStyles,
} from './styles';

export const Table = ({
  rows,
  headerGroups,
  tableProps,
  tableBodyProps,
  extraCSS = null,
  renderRowSubComponent = null,
  visibleColumns = null,
  isSortingEnabled = true,
  isLoadingPart = false,
  onShowMoreHandler = null,
  showMoreText = 'pull requests',
}) => (
  <table {...tableProps} css={tableStyles}>
    <TableHeader
      headerGroups={headerGroups}
      extraCSS={extraCSS?.header}
      isSortingEnabled={isSortingEnabled}
    />
    <TableBody
      rows={rows}
      extraCSS={extraCSS?.body}
      renderRowSubComponent={renderRowSubComponent}
      visibleColumns={visibleColumns}
      isLoadingPart={isLoadingPart}
      onShowMoreHandler={onShowMoreHandler}
      showMoreText={showMoreText}
      {...tableBodyProps}
    />
  </table>
);

const TableHeader = ({ headerGroups, extraCSS, isSortingEnabled }) => (
  <thead css={[tableHeaderStyles, extraCSS]}>
    {headerGroups.map((headerGroup) => {
      return isSortingEnabled ? (
        <TableHeaderRowWithSorting
          headers={headerGroup.headers}
          {...headerGroup.getHeaderGroupProps()}
        />
      ) : (
        <TableHeaderRow headers={headerGroup.headers} {...headerGroup.getHeaderGroupProps()} />
      );
    })}
  </thead>
);

const TableHeaderRowWithSorting = ({ headers, ...rowProps }) => (
  <tr {...rowProps}>
    {headers.map((column) => {
      const sortByProps = column.getSortByToggleProps();
      sortByProps.onClick = () => {
        if (!column.canSort) {
          return;
        }

        column.toggleSortBy(!column.isSortedDesc);
      };

      return (
        <TableHeaderCell
          css={tableHeaderRowStyles}
          {...column.getHeaderProps({
            ...sortByProps,
            ...(column.width ? { width: column.width } : {}),
          })}
        >
          <div
            className={
              column.isSorted ? (column.isSortedDesc ? 'sorted_desc' : 'sorted_asc') : 'sorting'
            }
            css={tableHeaderRowStyles}
          >
            {column.render('Header')}
          </div>
        </TableHeaderCell>
      );
    })}
  </tr>
);

const TableHeaderRow = ({ headers, ...rowProps }) => (
  <tr {...rowProps}>
    {headers.map((column) => {
      return (
        <TableHeaderCell
          css={tableHeaderRowStyles}
          {...column.getHeaderProps({
            ...(column.width ? { width: column.width } : {}),
          })}
        >
          <div css={tableHeaderRowStyles}>{column.render('Header')}</div>
        </TableHeaderCell>
      );
    })}
  </tr>
);

const TableHeaderCell = ({ children, ...cellProps }) => <th {...cellProps}>{children}</th>;

const TableBody = ({
  rows,
  extraCSS,
  renderRowSubComponent,
  visibleColumns,
  isLoadingPart,
  onShowMoreHandler,
  showMoreText,
  ...tableBodyProps
}) => (
  <tbody {...tableBodyProps} css={extraCSS}>
    {rows.map((row, i) => (
      <React.Fragment key={`row-${i}`}>
        <TableBodyRow
          cells={row.cells}
          depth={row.depth}
          serviceRow={row.original?.serviceRow && row.depth > 0}
          {...row.getRowProps()}
        />
        {row.isExpanded && renderRowSubComponent && visibleColumns ? (
          <tr css={(theme) => tableBodyRowStyles(theme, { depth: 1 })}>
            <td colSpan={visibleColumns.length}>{renderRowSubComponent({ row })}</td>
          </tr>
        ) : null}
      </React.Fragment>
    ))}
    {onShowMoreHandler && (
      <TableBodyRowShowMore
        isLoadingPart={isLoadingPart}
        cellsAmount={rows[0].cells.length}
        onShowMoreHandler={onShowMoreHandler}
        showMoreText={showMoreText}
      />
    )}
  </tbody>
);

const TableBodyRow = ({ cells, depth, serviceRow, ...rowProps }) => (
  <>
    {depth === 0 && <tr {...rowProps} css={trSpacerStyles} />}
    <tr {...rowProps} css={(theme) => tableBodyRowStyles(theme, { depth, serviceRow })}>
      {cells.map((cell) => (
        <TableBodyCell {...cell.getCellProps()}>{cell.render('Cell')}</TableBodyCell>
      ))}
    </tr>
  </>
);

const TableBodyRowShowMore = ({ cellsAmount, isLoadingPart, onShowMoreHandler, showMoreText }) => (
  <>
    <tr css={trSpacerStyles} />
    <tr css={showMoreCellStyles} role="row">
      <td colSpan={cellsAmount}>
        {isLoadingPart ? (
          <div css={spinnerWrapperStyles}>
            <Spinner loading />
          </div>
        ) : (
          <div className="show-more" css={showMoreTextStyles} onClick={onShowMoreHandler}>
            <div className="empty-icon" />
            <div className="link-text">Show more {showMoreText}</div>
          </div>
        )}
      </td>
    </tr>
  </>
);

const TableBodyCell = ({ children, ...cellProps }) => (
  <td {...cellProps}>
    <div css={tableBodyCellStyles}>{children}</div>
  </td>
);
