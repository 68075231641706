import { useQuery } from 'react-query';

import { CONFIG } from '@align-constants';
import { useCurrentTeam } from '@align-hooks/useTeams';
import { IUseDashboardChartsParams } from '@align-pages/dashboard/hooks/types';
import { IUseQueryHookRest } from '@align-types/common';
import { useAuth0 } from '@common-context/Auth0';
import { useUserContext } from '@common-context/User';
import { useDemoCookie } from '@common-pages/Demo';
import { IApiBasicError } from '@common-services/api/common/types/common';
import {
  CalculatedJIRAMetricValues,
  CalculatedPullRequestMetrics,
  CalculatedReleaseMetrics,
} from '@common-services/api/public/generated-from-backend/models';
import { MetricsServices } from '@common-services/api/public/services/metrics';

export interface IUseMetricsParams extends IUseDashboardChartsParams {
  accountId: number;
  teamId: number;
}

function usePullRequests(
  params: IUseMetricsParams,
  isCached: boolean = false,
  isEnabled: boolean = true
): IUseQueryHookRest<CalculatedPullRequestMetrics> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();

  const { isLoading, data, error } = useQuery<CalculatedPullRequestMetrics, IApiBasicError>(
    [
      'pull_requests',
      params.accountId,
      params.teamId,
      params.date_from,
      params.date_to,
      params.metric,
      params.filters,
      params.group_by,
    ],
    async () => {
      const token: string = isDemo === false ? await getTokenSilently() : null;
      return isEnabled ? MetricsServices.getPullRequests(params, token) : null;
    },
    {
      ...CONFIG.API.FETCH_OPTIONS,
      retry: 5,
      cacheTime: isCached ? CONFIG.API.FETCH_OPTIONS.cacheTime : 0,
      staleTime: isCached ? CONFIG.API.FETCH_OPTIONS.staleTime : 0,
      enabled: isEnabled,
    }
  );

  return { isLoading, data, error };
}

export function useOwnedPullRequests(
  params: IUseDashboardChartsParams,
  isCached: boolean = false,
  isEnabled: boolean = true
): IUseQueryHookRest<CalculatedPullRequestMetrics> {
  const currentTeam = useCurrentTeam();
  const {
    user: {
      defaultAccount: { id: accountId },
    },
  } = useUserContext();
  return usePullRequests({ ...params, accountId, teamId: currentTeam.id }, isCached, isEnabled);
}

function useJiraIssues(
  params: IUseMetricsParams,
  isCached: boolean = false,
  isEnabled: boolean = true
): IUseQueryHookRest<CalculatedJIRAMetricValues> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();

  const { isLoading, data, error } = useQuery<CalculatedJIRAMetricValues, IApiBasicError>(
    [
      'issues',
      params.accountId,
      params.teamId,
      params.date_from,
      params.date_to,
      params.metric,
      params.filters,
      params.group_by,
    ],
    async () => {
      const token: string = isDemo === false ? await getTokenSilently() : null;
      return isEnabled ? MetricsServices.getJiraIssues(params, token) : null;
    },
    {
      ...CONFIG.API.FETCH_OPTIONS,
      retry: 5,
      cacheTime: isCached ? CONFIG.API.FETCH_OPTIONS.cacheTime : 0,
      staleTime: isCached ? CONFIG.API.FETCH_OPTIONS.staleTime : 0,
      enabled: isEnabled,
    }
  );

  return { isLoading, data, error };
}

export function useOwnedJiraIssues(
  params: IUseDashboardChartsParams,
  isCached: boolean = false,
  isEnabled: boolean = true
): IUseQueryHookRest<CalculatedJIRAMetricValues> {
  const currentTeam = useCurrentTeam();
  const {
    user: {
      defaultAccount: { id: accountId },
    },
  } = useUserContext();
  return useJiraIssues({ ...params, accountId, teamId: currentTeam.id }, isCached, isEnabled);
}

function useReleases(
  params: IUseMetricsParams,
  isCached: boolean = false,
  isEnabled: boolean = true
): IUseQueryHookRest<CalculatedReleaseMetrics> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();

  const { isLoading, data, error } = useQuery<CalculatedReleaseMetrics, IApiBasicError>(
    [
      'releases',
      params.accountId,
      params.teamId,
      params.date_from,
      params.date_to,
      params.metric,
      params.filters,
      params.group_by,
    ],
    async () => {
      const token: string = isDemo === false ? await getTokenSilently() : null;
      return isEnabled ? MetricsServices.getReleases(params, token) : null;
    },
    {
      ...CONFIG.API.FETCH_OPTIONS,
      retry: 5,
      cacheTime: isCached ? CONFIG.API.FETCH_OPTIONS.cacheTime : 0,
      staleTime: isCached ? CONFIG.API.FETCH_OPTIONS.staleTime : 0,
      enabled: isEnabled,
    }
  );

  return { isLoading, data, error };
}

export function useOwnedReleases(
  params: IUseDashboardChartsParams,
  isCached: boolean = false,
  isEnabled: boolean = true
): IUseQueryHookRest<CalculatedReleaseMetrics> {
  const currentTeam = useCurrentTeam();
  const {
    user: {
      defaultAccount: { id: accountId },
    },
  } = useUserContext();
  return useReleases({ ...params, accountId, teamId: currentTeam.id }, isCached, isEnabled);
}
