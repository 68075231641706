import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { SELECTION_STATE } from '@analytics-components/MultiSelect/ui/Checkbox';
import { useSelectorState, ALL_NODE_ID } from '@lib/Selector';
import { optsType } from '@lib/Selector/types';

import { WithoutCheckboxes as SelectorWithoutCheckboxesUI } from './ui';

const uniqOptions = (opts) => _(opts).uniqBy('value').value();

const getNodes = (nodesStates, selected) =>
  uniqOptions(
    _(nodesStates)
      .filter((ns) => ns.id !== ALL_NODE_ID)
      .filter((ns) =>
        selected === undefined
          ? true
          : selected === true
          ? ns.selectionState === SELECTION_STATE.SELECTED
          : ns.selectionState !== SELECTION_STATE.SELECTED
      )
      .map('node')
      .value()
  );
const getNonSelectedNodes = (nodesStates) => getNodes(nodesStates, false);
const getSelectedNodes = (nodesStates) => getNodes(nodesStates, true);

const SelectorWithoutCheckboxes = ({ options, noAvatar }) => {
  const {
    nodesStates: [nodesStates, setNodesStates],
    applied: [applied, setApplied],
    onApply,
    onSelectedChange,
  } = useSelectorState();

  useEffect(() => {
    const selected = getSelectedNodes(nodesStates);
    if (!_.isEqual(_(selected).map('id').sortBy().value(), _(applied).map('id').sortBy().value())) {
      setApplied(selected);
      if (onApply) {
        onApply({ selected, applied: selected });
      }
    }
  }, [nodesStates, applied]);

  const onRemove = (node) => {
    const updatedNodesState = _(nodesStates)
      .map((ns) => ({
        ...ns,
        selectionState: ns.id === node.id ? SELECTION_STATE.NOT_SELECTED : ns.selectionState,
      }))
      .value();
    setNodesStates(updatedNodesState);
    const selected = getSelectedNodes(updatedNodesState);
    if (onSelectedChange) {
      onSelectedChange({
        action: 'remove',
        selected,
      });
    }
  };

  const nonSelectedVisibleOptions = _(
    getNonSelectedNodes(
      _(nodesStates)
        .filter((ns) => ns.isVisible)
        .value()
    )
  ).value();
  const groupedNonSelectedOptions = _(nonSelectedVisibleOptions)
    .map((opt) => ({ group: null, ...opt }))
    .groupBy('group')
    .value();

  // Concat `null` so that ungrouped options go first
  const orderedGroups = _([null]).concat(_(options).map('group').uniqBy().filter().value()).value();

  const optionsGroups = _(orderedGroups)
    .map((g) => ({
      group: g,
      options: groupedNonSelectedOptions[g] || [],
    }))
    .value();

  return (
    <SelectorWithoutCheckboxesUI
      appliedOptions={applied}
      optionsGroups={optionsGroups}
      noAvatar={noAvatar}
      onRemove={onRemove}
    />
  );
};

SelectorWithoutCheckboxes.propTypes = {
  options: optsType,
  noAvatar: PropTypes.bool,
};

export default SelectorWithoutCheckboxes;
