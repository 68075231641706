import React, { useCallback, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useOnClickOutside } from '@common-hooks/useOnClickOutside';
import { renderAvatars } from '@common-services/format';
import IconButton from '@lib/IconButton';
import { icons } from '@lib/icon';

import { TeamEditModal } from '../team-edit-modal';
import {
  contextMenuStyles,
  rightSideStyles,
  wrapperStyles,
} from './teamEditAccordionHeader.styles';
import { ITeamEditAccordionHeader } from './teamEditAccordionHeader.types';

const TeamEditAccordionHeader: React.FC<ITeamEditAccordionHeader> = React.memo(
  ({
    DefaultRenderer,
    header,
    members,
    onEdit,
    onRemove,
    teamNameInit,
    parentTeamInit,
    parentTeamOptions,
  }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
    const menuOptionsRef = useRef(null);
    const buttonRef = useRef(null);

    useOnClickOutside([buttonRef, menuOptionsRef], () => setIsContextMenuOpen(false));

    const openModal = useCallback(() => {
      setIsModalOpen(true);
      setIsContextMenuOpen(false);
    }, []);
    const closeModal = useCallback(() => {
      setIsModalOpen(false);
    }, []);
    const toggleContextMenu = useCallback(() => {
      setIsContextMenuOpen(!isContextMenuOpen);
    }, [isContextMenuOpen]);
    const handleRemove = useCallback(() => {
      onRemove();
      setIsContextMenuOpen(false);
    }, []);

    return (
      <div css={wrapperStyles}>
        <TeamEditModal
          id={header.primary}
          teamNameInit={teamNameInit}
          parentTeamInit={parentTeamInit}
          parentTeamOptions={parentTeamOptions}
          isOpen={isModalOpen}
          onCancel={closeModal}
          onEdit={onEdit}
        />
        <div>
          <DefaultRenderer header={header} />
        </div>
        <div className="controller" css={rightSideStyles} onClick={(e) => e.stopPropagation()}>
          <div className="avatars">
            {/* TODO: Make this a separate component */}
            {renderAvatars(
              members,
              null,
              // @ts-ignore Ignoring TS error because of rudimental renderAvatars method
              () => (user) => (
                <img
                  key={uuidv4()}
                  src={user.avatar || user.picture}
                  className="pr-user-avatar"
                  alt={user.name}
                  onError={(e) => {
                    (e.target as HTMLImageElement).src =
                      'https://avatars2.githubusercontent.com/u/10137';
                  }}
                />
              ),
              (count) => (
                <span key="count" className="pr-user-avatar count">
                  +{count}
                </span>
              )
            )}
          </div>
          <div className="actions">
            <div className="dropdown">
              <div ref={buttonRef}>
                <IconButton icon={icons.dots} iconSize={14} onClick={toggleContextMenu} />
              </div>
              {isContextMenuOpen && (
                <div css={contextMenuStyles} ref={menuOptionsRef}>
                  <div className="dropdown-item" onClick={openModal}>
                    Edit
                  </div>
                  <div className="dropdown-item" onClick={handleRemove}>
                    Remove
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export { TeamEditAccordionHeader };
