import { IColor } from './colors';

export const ui = {
  blue: {
    100: IColor.ui_blue_100,
    50: IColor.ui_blue_50,
  },
  purple: {
    100: IColor.ui_purple_100,
    50: IColor.ui_purple_50,
  },
  darkpurple: {
    100: IColor.ui_darkpurple_100,
    50: IColor.ui_darkpurple_50,
  },
  lightpink: {
    100: IColor.ui_lightpink_100,
    50: IColor.ui_lightpink_50,
  },
  pink: {
    100: IColor.ui_pink_100,
    50: IColor.ui_pink_50,
  },
  red: {
    100: IColor.ui_red_100,
    50: IColor.ui_red_50,
    20: IColor.ui_red_20,
  },
  darkred: { 100: IColor.ui_darkred_100 },
  brown: {
    100: IColor.ui_brown_100,
    50: IColor.ui_brown_50,
  },
  orange: {
    100: IColor.ui_orange_100,
    50: IColor.ui_orange_50,
  },
  lightorange: {
    100: IColor.ui_lightorange_100,
    50: IColor.ui_lightorange_50,
  },
  yellow: {
    100: IColor.ui_yellow_100,
    50: IColor.ui_yellow_50,
    10: IColor.ui_yellow_10,
  },
  green: {
    100: IColor.ui_green_100,
    50: IColor.ui_green_50,
    20: IColor.ui_green_20,
  },
  darkgreen: {
    100: IColor.ui_darkgreen_100,
  },
  turquoise: {
    100: IColor.ui_turquoise_100,
    50: IColor.ui_turquoise_50,
  },
  lightblue: {
    100: IColor.ui_lightblue_100,
    50: IColor.ui_lightblue_50,
  },
  ocean: {
    100: IColor.ui_ocean_100,
  },
};
