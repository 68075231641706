import React from 'react';
import { CellProps, Renderer } from 'react-table';

import { dateTime } from '@common-services/dateService';
import { github } from '@common-services/format';
import { IPrsTableRow } from '@lib/Table/components/pr-table/prTable.types';
import { Tooltip } from '@lib/tooltip';

import { detailsStyles, titleStyles } from './cellTitle.styles';

const PullRequestsCellTitle: Renderer<CellProps<IPrsTableRow>> = ({ row }) => {
  const title = row.original.title;
  const author = github.userName(row.original.authors[0]);
  const created = dateTime.interval(new Date(row.original.created), new Date());

  return (
    <div>
      <Tooltip content={<div>{`#${row.original.number} ${title}`}</div>}>
        <div css={titleStyles}>
          <div className="repo-name">
            {`${row.original.organization}/${row.original.repo}`}&nbsp;
          </div>
          <a
            className="pr-text"
            href={github.prLink(row.original.repository, row.original.number)}
            rel="noreferrer"
            target="_blank"
          >
            {`#${row.original.number} ${title}`}
          </a>
        </div>
      </Tooltip>
      <div css={detailsStyles}>
        <img alt="" className="user-avatar" src={row.original.authorAvatar} title={author} />
        <div className="details-text">
          <span>Created by </span>
          <span className="reporter">{author || 'an unknown author'}</span> {created}
          <span> ago</span>
        </div>
      </div>
    </div>
  );
};
export { PullRequestsCellTitle };
