import _ from 'lodash';
import React, { useState } from 'react';

import LegendSelectUI from '@ui/charts/LegendSelect';

const LegendSelect = ({ items, onActivesChange }) => {
  const [actives, setActives] = useState(
    _(items)
      .map((item) => ('active' in item ? item.active : true))
      .value()
  );
  const onClick = (index) => {
    if (onActivesChange) {
      const newActives = [...actives];
      newActives[index] = !newActives[index];
      setActives(newActives);
      onActivesChange(newActives);
    }
  };
  return (
    <LegendSelectUI
      items={_(items)
        .map((item, i) => ({ ...item, active: actives[i] }))
        .value()}
      onClick={onClick}
    />
  );
};

export default LegendSelect;
