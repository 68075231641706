import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { IGoalSingleParams } from '@align-pages/goals/single/components/goals-single-component';
import { NavigationService } from '@align-services/navigationService';
import { TeamTree } from '@common-services/api/private/generated-from-backend/models';
import { useOwnedTeamTree } from '@common-services/api/private/hooks/useTeamTreeData';
import { slugify } from '@utils/index';

export function useCurrentTeam(): TeamTree {
  const { path } = useParams<IGoalSingleParams>();
  const { data: teamsData } = useOwnedTeamTree(true);
  const currentTeam = teamsData && NavigationService.getCurrentTeam(path, teamsData, false);
  return currentTeam;
}

export function useRootTeam(): boolean {
  const { path } = useParams<IGoalSingleParams>();
  const isParentTeam = useMemo(() => path.split('/').length === 1, [path]);
  return isParentTeam;
}

export function useTeamsPath(): string {
  const { path } = useParams();
  const { data: teamsData } = useOwnedTeamTree(true);
  const teams = teamsData && NavigationService.getTeamsFromPath(path, teamsData, false);
  const teamsPath = useMemo(
    () => (!!teams?.length ? teams.map((t) => slugify(t.name.toLowerCase())).join('/') : ''),
    [teams]
  );
  return teamsPath;
}
