import React from 'react';
import { useExpanded, useTable, useSortBy, usePagination } from 'react-table';

import TablePagination from '@analytics-components/tables/TablePagination';
import { TableSearch } from '@analytics-components/tables/table-search';
import { IReleasesPrRow } from '@analytics-pages/Tables/releases/releases.types';
import { Table } from '@lib/Table';
import { Icon, icons } from '@lib/icon';
import { Tooltip } from '@lib/tooltip';

import { columns } from './columns';
import { searchStyles, tableBodyStyles } from './releasesTable.styles';
import { IReleasesTable } from './releasesTable.types';

const searchHint = (
  <div>
    You can search by release title, repository or participant, or use this other syntax for these
    other filters:
    <br />
    <b>by author:</b> <span className="code">author:user_handler</span>
    <br />
    <b>by reviewer:</b> <span className="code">reviewer:user_handler</span>
  </div>
);

const ReleasesTable: React.FC<IReleasesTable> = React.memo(
  ({ releases, searchedString, onSearch }) => {
    const {
      getTableProps,
      getTableBodyProps,
      gotoPage,
      headerGroups,
      nextPage,
      page,
      pageCount,
      prepareRow,
      previousPage,
      rows,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable<IReleasesPrRow>(
      {
        autoResetExpanded: false, // this prop will preserve expanded rows from collapsing when data changes
        columns,
        data: releases,
        initialState: { pageIndex: 0 },
        paginateExpandedRows: false,
      },
      useSortBy,
      useExpanded,
      usePagination
    );
    page.forEach(prepareRow);

    return (
      <>
        <div css={searchStyles}>
          <TableSearch placeholder="Search..." value={searchedString} onFilter={onSearch} />
          <Tooltip className="info-icon" content={searchHint}>
            <Icon icon={icons.check_failed} size={18} />
          </Tooltip>
        </div>
        <Table
          rows={page}
          headerGroups={headerGroups}
          tableProps={getTableProps()}
          tableBodyProps={getTableBodyProps()}
          extraCSS={{
            body: tableBodyStyles,
          }}
        />
        <TablePagination
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          total={rows.length}
          gotoPage={gotoPage}
          gotoNextPage={nextPage}
          gotoPrevPage={previousPage}
          setPageSize={setPageSize}
        />
      </>
    );
  }
);

export { ReleasesTable };
