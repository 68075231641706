import { useMemo } from 'react';

import { DateService } from '@align-services/dateService';
import { ArrayService } from '@common-services/arrayService';
import { IDropdownNameValue } from '@lib/Dropdown';

import { PrimaryLayoutModes } from '..';

export function useDateRangeOptions(
  mode: PrimaryLayoutModes,
  dateRangeInit: IDropdownNameValue
): IDropdownNameValue[] {
  const generatedOptions = useMemo<IDropdownNameValue[]>(() => {
    const numberedArr = ArrayService.getNumberedArray(2);
    const generatedQuarters = numberedArr.map((num) => {
      return {
        name: DateService.getQuarterYearAhead(num),
        value: `${DateService.getUnitsAheadStart(num, 'quarter')}/${DateService.getUnitsAheadEnd(
          num,
          'quarter'
        )}`,
      };
    });
    const generateYear = (yearAhead: number) => {
      return {
        name: DateService.getYearAhead(yearAhead),
        value: `${DateService.getUnitsAheadStart(yearAhead, 'year')}/${DateService.getUnitsAheadEnd(
          yearAhead,
          'year'
        )}`,
      };
    };
    // if in Edit mode add the goal's timeframe value to the list of options for the value to render
    if (
      mode === PrimaryLayoutModes.EDIT &&
      !generatedQuarters.some((quarter) => quarter.name === dateRangeInit.name)
    ) {
      generatedQuarters.unshift({
        name: dateRangeInit.name,
        value: dateRangeInit.value,
      });
    }
    return [...generatedQuarters, generateYear(0), generateYear(1)];
  }, [dateRangeInit]);
  return generatedOptions;
}
