import React, { useCallback, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { RemoveModal } from '@align-components/remove-modal';
import { metricsConfig } from '@align-constants';
import { useActionMenu } from '@align-hooks/useActionMenu';
import { useGoalRemoveModal } from '@align-hooks/useGoalRemoveModal';
import { useRootTeam } from '@align-hooks/useTeams';
import { useRemainingDays } from '@align-pages/goals/list/active-goals/activeGoals.hooks';
import { getPathToGoalView } from '@align-pages/goals/list/active-goals/activeGoals.services';
import { ActiveGoalBadges } from '@align-pages/goals/list/active-goals/components/active-goal-badges';
import { ActiveGoalDetails } from '@align-pages/goals/list/active-goals/components/active-goal-details';
import { useActiveGoalRowChartData } from '@align-pages/goals/list/active-goals/components/active-goal-row/activeGoalRow.hooks';
import { GoalsSingleComponentServices } from '@align-pages/goals/single/components/goals-single-component';
import {
  IMetricDescriptionTextMode,
  MetricDescriptionText,
} from '@align-pages/goals/single/components/metric-description/components/metric-description-text';
import { DateService } from '@align-services/dateService';
import { GoalsService } from '@align-services/goalsService';
import { MetricService } from '@align-services/metricService';
import { useHistory } from '@common-hooks/useHistory';
import { useOwnedTeamTree } from '@common-services/api/private/hooks/useTeamTreeData';
import { getTeamColor } from '@common-services/colorService';
import { ActionMenu } from '@lib/ActionMenu';
import IconButton from '@lib/IconButton';
import { icons } from '@lib/icon';

import { ActiveGoalChart } from '../active-goal-chart';
import { ActiveGoalProgress } from '../active-goal-progress';
import {
  actionsStyles,
  badgesStyles,
  chartStyles,
  expandStyles,
  mainColStyles,
  nameColStyles,
  progressAreaStyles,
  titleStyles,
  wrapperStyles,
} from './activeGoalRow.styles';
import { IActiveGoalRow } from './activeGoalRow.types';

export const ActiveGoalRow: React.FC<IActiveGoalRow> = React.memo(
  ({ goal, team, level = 0, templateName, templateMetric, onArchive }) => {
    const history = useHistory();
    const { url } = useRouteMatch();
    const [isExpanded, setIsExpanded] = useState(false);
    const remainingDays = useRemainingDays({ goal });
    const { data: teamsData } = useOwnedTeamTree(true);

    const { isModalOpen, removeGoal, openModal, closeModal } = useGoalRemoveModal(
      goal?.id.toString(),
      team?.team.id
    );

    const currentTemplateName = useMemo(() => (goal ? goal.name : templateName), [
      goal,
      templateName,
    ]);
    const currentTemplateMetric = useMemo(() => (goal ? goal.metric : templateMetric), [
      goal,
      templateMetric,
    ]);

    const isParentTeam = useRootTeam();

    const thresholdReadable = useMemo<string>(() => {
      const threshold = goal?.metric_params?.threshold;
      return threshold && currentTemplateMetric
        ? MetricService.getReadableValue(
            GoalsSingleComponentServices.deNormaliseMetricValue(threshold),
            metricsConfig[currentTemplateMetric].valueType,
            metricsConfig[currentTemplateMetric].unit
          )
        : null;
    }, [goal, currentTemplateMetric]);

    const expandToggle = useCallback(() => setIsExpanded(!isExpanded), [isExpanded]);

    const avatarColor: string = useMemo(() => getTeamColor(team.team.name), [team]);

    const goalComplete = useMemo(() => DateService.isBeforeToday(goal?.expires_at), [goal]);

    const { goalSeries, seriesColours, targetValue } = useActiveGoalRowChartData(
      goal,
      team,
      currentTemplateMetric,
      !!thresholdReadable
    );

    const { shouldCloseMenu, setShouldCloseMenu } = useActionMenu();

    const actionMenuItems = useMemo(
      () => [
        {
          name: 'Edit targets',
          onClick: () => history.pushWithBackState(`${url}/edit/${goal.id}`),
        },
        ...(goalComplete && isParentTeam
          ? [
              {
                name: 'Archive',
                onClick: () =>
                  onArchive(goal.id, {
                    ...goal,
                    archived: true,
                    team_goals: GoalsService.serializeTeamGoalTree(goal.team_goal),
                  }),
              },
            ]
          : []),
        {
          name: 'Remove',
          onClick: () => {
            openModal();
            setShouldCloseMenu(true);
          },
        },
      ],
      [goal, goalComplete, url, isParentTeam]
    );

    const renderTimeFrame = useMemo(
      () => `
        ${DateService.getCurrentRange({ dateFrom: goal?.valid_from, dateTo: goal?.expires_at })} |
        ${remainingDays}
      `,
      [goal?.valid_from, goal?.expires_at, remainingDays]
    );

    const goalDateRange = useMemo(
      () =>
        goal
          ? {
              dateFrom: goal.valid_from,
              dateTo: goal.expires_at,
            }
          : null,
      [goal]
    );

    const isLink = useMemo(
      () =>
        metricsConfig[currentTemplateMetric]?.metricDescription &&
        metricsConfig[currentTemplateMetric]?.mainKPI &&
        targetValue,
      [currentTemplateMetric, targetValue]
    );

    const navigateToGoalView = useCallback(() => {
      const pathToGoalView = getPathToGoalView(teamsData, team, goal.id);
      if (isLink && pathToGoalView) history.pushWithBackState(pathToGoalView);
    }, [history, isLink, goal, teamsData, team]);

    return (
      <>
        <div css={wrapperStyles({ level, isLink })}>
          <div css={expandStyles({ level })}>
            {!!team?.children?.length && (
              <IconButton
                icon={isExpanded ? icons.angle_down : icons.angle_right}
                iconSize={10}
                onClick={expandToggle}
              />
            )}
          </div>
          <div css={titleStyles} onClick={navigateToGoalView}>
            <div css={mainColStyles}>
              <div css={nameColStyles}>
                {!level && (
                  <>
                    {isLink ? (
                      <div className="title with-link">{currentTemplateName}</div>
                    ) : (
                      <div className="title">{currentTemplateName}</div>
                    )}
                    {thresholdReadable && (
                      <div className="description">
                        <MetricDescriptionText
                          metricValue={currentTemplateMetric}
                          threshold={thresholdReadable}
                          mode={IMetricDescriptionTextMode.LIST}
                        />
                      </div>
                    )}
                  </>
                )}

                <ActiveGoalDetails
                  teamName={team.team.name}
                  avatarColor={avatarColor}
                  timeFrame={renderTimeFrame}
                  level={level}
                />
              </div>
            </div>
            {level === 0 && (
              <div css={badgesStyles}>
                <ActiveGoalBadges goal={goal} metricName={currentTemplateMetric} />
              </div>
            )}
            <div className="separator" />
            {MetricService.hasValue(team?.value?.target) && (
              <div css={progressAreaStyles}>
                <ActiveGoalProgress
                  range={goalDateRange}
                  value={team.value}
                  unit={metricsConfig[currentTemplateMetric].unit}
                  isPercentage={!!thresholdReadable}
                />
                <div css={chartStyles}>
                  {MetricService.hasValue(team?.value?.current) && (
                    <ActiveGoalChart
                      data={goalSeries}
                      seriesColours={seriesColours}
                      targetLine={targetValue}
                    />
                  )}
                </div>
              </div>
            )}
            <div css={actionsStyles}>
              {goal && level === 0 && (
                <ActionMenu actions={actionMenuItems} shouldClose={shouldCloseMenu} />
              )}
            </div>
          </div>
        </div>
        {isExpanded &&
          team?.children?.map((child) => (
            <ActiveGoalRow
              key={child.team.id}
              team={child}
              goal={goal}
              level={level + 1}
              templateName={currentTemplateName}
              templateMetric={currentTemplateMetric}
            />
          ))}
        <RemoveModal isOpen={isModalOpen} onRemove={removeGoal} onCancel={closeModal} />
      </>
    );
  }
);
