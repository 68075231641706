import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';

import { PrimaryLayoutTabs } from '@align-components/layouts/primary/context.types';
import { IGoalSingleParams } from '@align-pages/goals/single/components/goals-single-component';
import { useHistory } from '@common-hooks/useHistory';
import { DateService } from '@common-services/dateService';
import { Icon, icons } from '@lib/icon';
import { useTheme } from '@styles/hooks';

import { wrapperStyles } from './goalNotStarted.styles';
import { IGoalNotStarted } from './goalNotStarted.types';

const GoalNotStarted: React.FC<IGoalNotStarted> = React.memo(({ startDate }) => {
  const theme = useTheme();
  const history = useHistory();
  const { path } = useParams<IGoalSingleParams>();

  const pathToGoalsList = useMemo(() => `/align/${path}/${PrimaryLayoutTabs.GOALS}`, [
    history,
    path,
  ]);

  const remainingDays = useMemo(
    () => DateService.daysBetween(new Date().toDateString(), startDate),
    [startDate]
  );

  return (
    <div css={wrapperStyles}>
      <Icon color={theme.color.neutral['60']} icon={icons.clock} size={92} />
      <div className="title">Your goal has not started yet</div>
      <div className="subtitle">
        Track your progress once your goal has started, in {remainingDays} days.
      </div>
      <Link className="link" to={pathToGoalsList}>
        Go back to the list of Goals
      </Link>
    </div>
  );
});

export { GoalNotStarted };
