import { useTheme } from '@emotion/react';
import _ from 'lodash';
import React from 'react';
import Select, { components } from 'react-select';

import ButtonWithSelector from '@analytics-components/ButtonWithSelector';
import { useCollectionHandler } from '@analytics-components/CollectionHandler';
import { noDropdownStyles } from '@analytics-components/MultiSelect/styles';
import { Button } from '@lib/Button';
import { icons } from '@lib/icon';

const defaultProps = {
  backspaceRemovesValue: false,
  closeMenuOnSelect: true,
  isClearable: false,
  openMenuOnClick: false,
  isSearchable: true,
  isMulti: true,
  hideSelectedOptions: false,
  controlShouldRenderValue: false,
  tabSelectsValue: false,
};

const isOptionDisabled = (data) => data.selected;

const Option = (props) => {
  const { innerProps, ...commonProps } = props;
  return (
    <components.Option
      {...props}
      css={({ color }) => ({
        'cursor': `${commonProps.isSelected ? 'not-allowed' : 'pointer'}`,
        '&:hover': {
          backgroundColor: `${commonProps.isSelected ? 'none' : color.neutral['20']}`,
        },
      })}
    >
      <span
        css={({ color }) => ({
          color: `${color.text.dark}`,
          opacity: `${commonProps.isSelected ? '0.3' : '1'}`,
        })}
      >
        {commonProps.label}
      </span>
    </components.Option>
  );
};

const GroupHeading = (props) => {
  return (
    <components.GroupHeading {...props}>
      <span
        css={({ color }) => ({
          color: `${color.neutral['80']}`,
        })}
      >
        {props.data.label}
      </span>
    </components.GroupHeading>
  );
};

const Menu = (props) => {
  return <components.Menu {...props}>{props.children}</components.Menu>;
};

const getOptionValue = (data) => data.name;
const getOptionLabel = (data) => data.displayName;

const selectStyles = (theme) => {
  const { spacing, color, font, radius, transition } = theme;
  const styles = noDropdownStyles(theme);
  const origControlStyle = styles.control;
  const origContainerStyle = styles.container;
  const origMenuStyle = styles.menu;
  styles.control = (base, state) => ({
    ...origControlStyle(base, state),
    margin: `${spacing.gap['04']} ${spacing.gap['04']}`,
  });
  styles.container = (styles) => ({
    ...origContainerStyle(styles),
    borderRadius: `${radius.default}`,
    minWidth: '192px',
    width: '192px',
  });
  styles.menu = (styles) => ({
    ...origMenuStyle(styles),
    backgroundColor: `${color.neutral.white}`,
    padding: `${spacing.gap['04']}`,
    boxSizing: 'border-box',
    borderRadius: `${radius.default}`,
  });
  styles.option = (base, state) => ({
    borderRadius: `${radius.default}`,
    padding: `${spacing.gap['03']}`,
    transition: `${transition.long}`,
  });
  styles.group = (styles) => ({
    padding: `${spacing.gap['0']}`,
  });
  styles.groupHeading = (styles) => ({
    fontSize: `${font.size.xs}`,
    padding: `${spacing.gap['03']}`,
    textTransform: 'uppercase',
  });

  return styles;
};

const AddChartButton = () => {
  const theme = useTheme();
  const { getItems, modifyItem } = useCollectionHandler();
  const metricsItems = getItems(true);
  const options = _(metricsItems)
    .groupBy('group')
    .toPairs()
    .map((item) => ({ label: item[0], options: item[1] }))
    .value();
  const defaultValue = _(metricsItems)
    .filter((item) => item.selected)
    .value();
  const styles = selectStyles(theme);
  const props = { ...defaultProps };

  const addButtonFn = (isOpen, setIsOpen, ref) => {
    const handleClick = () => setIsOpen(!isOpen);
    return <Button icon={icons.add} label="Add chart" onClick={handleClick} />;
  };

  const selectorFn = (isOpen, setIsOpen, ref) => {
    const onChange = (selected, change) => {
      if (change.action === 'select-option') {
        setIsOpen(false);
        modifyItem(change.option.id, { position: { target: 'top' }, visible: true });
      }
    };
    return (
      <Select
        menuIsOpen
        options={options}
        onChange={onChange}
        defaultValue={defaultValue}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        isOptionDisabled={isOptionDisabled}
        components={{ Menu, GroupHeading, Option }}
        placeholder="Find metrics..."
        styles={styles}
        {...props}
      />
    );
  };

  return (
    <ButtonWithSelector
      buttonFn={addButtonFn}
      selectorFn={selectorFn}
      width="192px"
      alignRight={true}
    />
  );
};

export { AddChartButton };
