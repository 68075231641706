import { useTheme } from '@emotion/react';
import React, { useCallback, useRef, useState } from 'react';

import { useOnClickOutside } from '@common-hooks/useOnClickOutside';
import { Circle, ICircleOnClick } from '@lib/circle';
import { ColorGrid } from '@lib/color-grid';
import { Icon, icons } from '@lib/icon';
import { ITheme, IColor } from '@styles/types';

import { dropdownStyles, selectStyles, wrapperStyles } from './colorSelect.styles';
import { IColorSelect } from './colorSelect.types';

export const ColorSelect: React.FC<IColorSelect> = React.memo(
  ({ colors, initialValue, onChange }) => {
    const theme = useTheme() as ITheme;
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState(initialValue);
    const selectRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useOnClickOutside([selectRef, dropdownRef], () => setIsOpen(false));

    const handleClick = useCallback(() => {
      setIsOpen(!isOpen);
    }, [isOpen]);

    const handleChange = useCallback<ICircleOnClick>((event, newColor) => {
      setValue(newColor as IColor);
      if (onChange) onChange(newColor);
      setIsOpen(false);
    }, []);

    return (
      <div css={wrapperStyles}>
        <div css={selectStyles} ref={selectRef} onClick={handleClick}>
          <Circle color={value} size={22} />
          <Icon color={theme.color.neutral[80]} icon={icons.caret_down} size={8} />
        </div>
        {isOpen && (
          <div css={dropdownStyles} ref={dropdownRef}>
            <ColorGrid colors={colors} value={value} onClick={handleChange} />
          </div>
        )}
      </div>
    );
  }
);
