import BubbleChart from '@analytics-components/charts/BubbleChart';
import { PullRequestReview } from '@analytics-components/charts/Tooltip';
import {
  IncludedNativeUsers,
  PullRequest,
} from '@common-services/api/public/generated-from-backend/models';
import { DateService } from '@common-services/dateService';
import { github } from '@common-services/format';
import { extractParticipantsByKind } from '@common-services/prHelpers';
import { theme } from '@styles/theme';

import { BUBBLES_SIZE_RANGE, MAX_BUBBLE_SIZE } from './biggest-prs.constants';

export const getChartData = (prs: PullRequest[], users: IncludedNativeUsers['users']) => {
  const chartData = prs.map((pr) => {
    const endTime = pr.released ? DateService.strToMoment(pr.released) : DateService.strToMoment();
    const timeActive = endTime.valueOf() - DateService.strToMoment(pr.created).valueOf();
    const released = !!pr.released;
    const { authors } = extractParticipantsByKind(pr);
    const ghUsername = authors[0];
    const author = ghUsername ? github.userName(ghUsername) : undefined;

    const tooltip = {
      number: pr.number,
      repository: pr.repository,
      linesAdded: pr.size_added,
      linesRemoved: pr.size_removed,
      title: pr.title,
      image: users[ghUsername]?.avatar,
      released,
      author: author || 'unknown',
      timeActive: DateService.human(timeActive),
    };

    const bubble = {
      loc: pr.size_added + pr.size_removed,
      files: pr.files_changed,
      age: timeActive,
      released,
      tooltip,
    };

    return {
      ...bubble,
      size: MAX_BUBBLE_SIZE,
    };
  });

  return {
    empty: chartData.length === 0,
    chart: {
      component: BubbleChart,
      params: {
        data: chartData,
        extra: {
          grouper: 'released',
          groups: {
            false: {
              title: 'active',
              color: theme.color.ui.yellow[100],
            },
            true: {
              title: 'released',
              color: theme.color.ui.turquoise[100],
            },
          },
          axisKeys: {
            x: 'loc',
            y: 'files',
            size: 'size',
          },
          sizeRange: BUBBLES_SIZE_RANGE,
          axisLabels: {
            x: 'Lines of Code',
            y: 'Files',
          },
          zoomed: true,
          isLogScale: true,
          tooltip: {
            template: PullRequestReview,
            persistent: true,
          },
        },
      },
    },
    kpis: [],
  };
};

export const getLines = (pr: PullRequest): number => pr.size_added + pr.size_removed;
