import React from 'react';

import BugFixingPanel from './BugFixingPanel';
import BugsFixingRatioPanel from './BugsFixingRatioPanel';
import BugsPanel from './BugsPanel';
import BugsRaisedByPriorityPanel from './BugsRaisedByPriorityPanel';
import BugsRaisedDistributionPanel from './BugsRaisedDistributionPanel';
import BugsRaisedVsFixedPanel from './BugsRaisedVsFixedPanel';

const InsightsBugs = () => (
  <>
    <div className="row mx-0 px-0 mb-4">
      <div className="col-md-9 mx-0 pl-0 pr-1">
        <BugFixingPanel />
      </div>
      <div className="col-md-3 mx-0 pl-3 pr-0">
        <BugsPanel />
      </div>
    </div>
    <div className="row mx-0 px-0 mb-4">
      <div className="col-md-9 mx-0 pl-0 pr-1">
        <BugsRaisedVsFixedPanel />
      </div>
      <div className="col-md-3 mx-0 pl-3 pr-0">
        <BugsFixingRatioPanel />
      </div>
    </div>
    <div className="row mx-0 px-0 mb-4">
      <div className="col-md-9 mx-0 pl-0 pr-1">
        <BugsRaisedByPriorityPanel />
      </div>
      <div className="col-md-3 mx-0 pl-3 pr-0">
        <BugsRaisedDistributionPanel />
      </div>
    </div>
  </>
);

export default InsightsBugs;
