import _ from 'lodash';
import React, { useState, useEffect } from 'react';

import { TagsContainerUI } from './components';
import { ITagsContainer } from './tagsContainer.types';

export const TagsContainer: React.FC<ITagsContainer> = ({
  avatarType,
  items,
  placeholder,
  isLoading,
  withSmallTags,
  onRemove,
  children,
}) => {
  const [appliedItems, setAppliedItems] = useState(items);

  useEffect(() => {
    setAppliedItems(items);
  }, [items]);

  const onRemoveWrapper = (removed) => {
    setAppliedItems(
      _(appliedItems)
        .filter((item) => item.value !== removed.value)
        .value()
    );
    if (onRemove) {
      onRemove(removed);
    }
  };

  return (
    <TagsContainerUI
      avatarType={avatarType}
      items={appliedItems}
      placeholder={placeholder}
      isLoading={isLoading}
      withSmallTags={withSmallTags}
      onRemove={onRemoveWrapper}
    >
      {children}
    </TagsContainerUI>
  );
};
