import { css } from '@emotion/react';

export const wrapperStyles = (isLoading, hasExtra) => ({ spacing, radius, color }) => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing.gap['04']};
  min-height: 58px;
  background: ${isLoading ? color.neutral['20'] : color.neutral.white};
  border: 1px solid ${color.neutral['40']};
  box-sizing: border-box;
  border-radius: ${radius.default};
  flex: none;

  & > div.main {
    width: ${hasExtra ? '75%' : '100%'};
  }

  & > div.extra {
    width: ${hasExtra ? '25%' : '0%'};
  }
`;
