import { motion } from 'framer-motion';
import React from 'react';

import { ContentProps } from '../types';
import { getContentStyle } from './ui/styles';

const AccordionContent = React.forwardRef<HTMLDivElement, ContentProps>(
  ({ animate, animationVariants, children }, ref) => {
    return (
      <motion.div
        ref={ref}
        initial={false}
        animate={animate}
        transition={{ duration: 0.1 }}
        variants={animationVariants}
        css={getContentStyle}
      >
        {children}
      </motion.div>
    );
  }
);

export { AccordionContent };
