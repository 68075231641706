import { writeStorage } from '@rehooks/local-storage';
import { useEffect, useMemo, useState } from 'react';

import { useApi } from '@analytics-hooks';
import { ShareService } from '@analytics-services/shareService';
import {
  FiltersStorageKeysPrefix,
  ShareFilters,
  ShareParams,
} from '@analytics-services/shareService.types';

const useSyncShareFilters = (): [string, boolean] => {
  const { api, ready: apiReady } = useApi(true, false);
  const [ready, setReady] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);
  const query = useMemo(() => new URLSearchParams(window.location.search), [window.location]);
  useEffect(() => {
    if (!apiReady) {
      return;
    }

    (async () => {
      const params = await ShareService.getShareParams(api, query.get('id'));
      syncStorage(params);
      setRedirectTo(params.data.targetUrl);
      setReady(true);
    })();
  }, [apiReady, query]);

  return [redirectTo, ready];
};

const syncStorage = (params: ShareParams) => {
  const account = params.data.account;
  if (params.data.filters.dateRange) {
    writeStorage(`${FiltersStorageKeysPrefix.DATE_RANGE}${account}`, {
      from: params.data.filters.dateRange.from.format(),
      to: params.data.filters.dateRange.to.format(),
    });
  }

  const storagePrefixandKey: [FiltersStorageKeysPrefix, keyof ShareFilters][] = [
    [FiltersStorageKeysPrefix.CONTRIBUTORS, 'contributors'],
    [FiltersStorageKeysPrefix.METRICS_SELECTOR, 'metricsSelector'],
    [FiltersStorageKeysPrefix.TEAMS_TAGGED_SELECTOR, 'teamsTaggedSelector'],
    [FiltersStorageKeysPrefix.REPOSITORIES, 'repositories'],
    [FiltersStorageKeysPrefix.CONTRIBUTOR_TEAMS, 'contributorTeams'],
    [FiltersStorageKeysPrefix.OMNIBOX, 'omnibox'],
    [FiltersStorageKeysPrefix.WORK_TYPES, 'workTypes'],
  ];

  for (let [prefix, key] of storagePrefixandKey) {
    writeStorage(`${prefix}${account}`, params.data.filters[key]);
  }
};

export { useSyncShareFilters };
