import { css } from '@emotion/react';

export const fieldStyles = (disabled, value) => ({
  color,
  font,
  radius,
  spacing,
  transition,
}) => css`
  align-items: center;
  background-color: ${color.neutral.white};
  border: 1px solid ${color.neutral[40]};
  border-radius: ${radius.default};
  cursor: pointer;
  display: flex;
  font-size: ${font.size.default};
  font-weight: ${font.weight.regular};
  height: 40px;
  line-height: 16px;
  padding: ${spacing.gap['04']};
  position: relative;
  transition: ${transition.default};
  width: 100%;

  &:hover {
    ${!disabled &&
    `
      border: 1px solid ${color.neutral[60]};
    `}
  }

  .ath-dropdown-placeholder {
    color: ${color.neutral[80]};
    display: block;
    left: ${spacing.gap['04']};
    overflow: hidden;
    position: absolute;
    top: ${spacing.gap['04']};
    line-height: 1.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100% - 3 * ${spacing.gap['04']});
  }

  .ath-dropdown-value {
    display: flex;
    overflow: hidden;
    width: calc(100% - 3 * ${spacing.gap['04']} - ${spacing.gap['03']});
  }

  .ath-dropdown-icon-wrapper {
    position: absolute;
    right: ${spacing.gap['05']};
    top: 50%;
    transform: translateY(-50%);
  }

  ${disabled &&
  `
    cursor: unset;
    opacity: 0.5;
  `}
`;

export const dropdownWrapperStyles = () => css`
  position: relative;
`;

export const selectorWrapperStyles = ({ spacing }) => css`
  margin-top: ${spacing.gap['03']};
  position: absolute;
  width: 100%;
  z-index: 10;
`;

export const noCheckboxesHeaderStyles = () => css`
  height: 40px;

  & > div {
    position: relative;
    height: 100%;

    & div.ath-raw-input-wrapper {
      height: 40px;
    }

    & div.apply-button-wrapper {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;

      & > button {
        padding-top: 6px;
        padding-bottom: 6px;
      }
    }
  }
`;
