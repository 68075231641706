import React from 'react';

import { Icon, icons } from '@lib/icon';

import { ShowUsersProps } from '../UsersPanel.types';
import { getStyle } from './ShowUsers.styles';

const ShowUsers: React.FC<ShowUsersProps> = React.memo(({ onClick, text }) => {
  return (
    <div css={getStyle} onClick={onClick}>
      <Icon icon={icons.person} size={15} />
      <span>{text}</span>
    </div>
  );
});

export { ShowUsers };
