import _ from 'lodash';

import { calculateGranularity } from '@analytics-components/Prefetcher';
import { DateRangeBigNumber } from '@analytics-components/charts/Tooltip';
import { fetchJIRAMetrics } from '@analytics-services/api/jira';
import { FAKE_USERNAME } from '@common-pages/Settings/constants';
import { dateTime } from '@common-services/dateService';
import PositiveNegativeOverlappedBarChart from '@ui/charts/PositiveNegativeOverlappedBarChart';

const bugsRaisedVsFixed = {
  fetcher: async (api, cachedData, apiContext) => {
    const {
      account,
      interval,
      excludeInactive,
      contributors,
      includeNullContributor,
      includeFakeContributor,
      epics,
    } = apiContext;
    const jiraAssignees = _(contributors).map('jira_user').filter().value();
    if (includeNullContributor) {
      jiraAssignees.push(null);
    }
    if (includeFakeContributor) {
      jiraAssignees.push(FAKE_USERNAME);
    }

    const bugs = await fetchJIRAMetrics(
      api,
      account,
      ['auto'],
      interval,
      ['raised', 'resolved'],
      excludeInactive,
      { types: ['Bug'], epics },
      { assignees: jiraAssignees }
    );

    return {
      bugs: _(bugs[0].values)
        .map((v) => ({ date: v.date, raised: v.values[0], resolved: v.values[1] }))
        .value(),
    };
  },
  plumber: (fetchedData, cachedData, apiContext) => {
    const chartData = _(fetchedData.bugs)
      .map((d) => ({ x: d.date, y: { good: d.resolved, bad: d.raised } }))
      .value();
    const bugsRaisedAmount = _(fetchedData.bugs)
      .map((v) => v.raised)
      .sum();
    const bugsResolvedAmount = _(fetchedData.bugs)
      .map((v) => v.resolved)
      .sum();

    const customGranularity = calculateGranularity(apiContext.interval);
    const tickFormat = customGranularity === 'month' ? dateTime.month : null;

    const tooltips = {
      align: {
        bad: {
          horizontal: 'auto',
          vertical: 'bottom',
        },
        good: {
          horizontal: 'auto',
          vertical: 'top',
        },
      },
      extra: {
        bad: 'raised',
        good: 'fixed',
      },
    };
    if (_(['month', 'week']).includes(customGranularity)) {
      tooltips.template = DateRangeBigNumber;
      tooltips.extra.interval = { ...apiContext.interval };
      tooltips.granularity = customGranularity;
    }

    return {
      empty: chartData.length === 0 || (!bugsRaisedAmount && !bugsResolvedAmount),
      chart: {
        component: PositiveNegativeOverlappedBarChart,
        params: {
          data: chartData,
          params: {
            yAxis: {
              text: 'Bugs',
            },
            xAxis: {
              tickFormat,
            },
            tooltips,
          },
        },
      },
      kpis: [],
    };
  },
};

export default bugsRaisedVsFixed;
