import { useTheme } from '@emotion/react';
import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';

import { filtersStyles } from '@analytics-components/TopFilter/styles';
import DateInterval from '@analytics-components/filters/DateInterval';
import RepositoriesMultiSelect from '@analytics-components/filters/RepositoriesMultiSelect';
import UserMultiSelect, { nullUser } from '@analytics-components/filters/UserMultiSelect';
import { useDataWidget } from '@analytics-context/DataWidget';
import { filtersStorageKeys, useFilters } from '@analytics-context/Filters';
import { DatetimeService } from '@analytics-services/datetimeService';
import { isFeatureEnabled, featuresList } from '@analytics-services/flags';
import { getTeamsWithoutRoot } from '@analytics-services/teamsService';
import { useUserContext } from '@common-context/User';
import DropdownSelector from '@lib/dropdown-selector';
import { icons } from '@lib/icon';

const TopFilter = ({ usersFilterEnabled, reposFilterEnabled, labelsFilterEnabled }) => {
  const theme = useTheme();
  const {
    user: {
      defaultAccount: { id: accountID },
    },
    features,
  } = useUserContext();
  const {
    ready,
    appliedRepos,
    appliedContribs,
    dateInterval,
    hasAppliedNullContrib,
    reposOptions,
    contribsOptions,
    labels,
    epics,
    issueTypes,
    reposReady,
    contribsReady,
    teams,
    excludeInactive,
    onReposInit,
    onContribsInit,
    onOmniboxInit,
    onDateIntervalChange,
    onReposApplyChange,
    onContribsApplyChange,
    onExcludeInactive,
    onLabelsApplyChange,
  } = useFilters();
  const { isLoading } = useDataWidget();
  const allowedDateInterval = {
    from: DatetimeService.Presets.OLDEST,
    to: DatetimeService.Presets.TODAY,
  };

  const initialTeamSelectValues = useMemo(() => [...appliedContribs], [appliedContribs]);

  const extendedTeams = useMemo(() => {
    const teamsWithoutRoot = getTeamsWithoutRoot(teams);
    const unassignedTeam = {
      name: 'Unassigned',
      id: null,
      parent: null,
      members: [
        {
          jira_user: '',
          login: '',
          name: 'Unassigned',
          picture: '/src/assets/icons/default_user.svg',
        },
      ],
    };
    return [...teamsWithoutRoot, unassignedTeam];
  }, [teams]);

  const extendedInitialValues = useMemo(() => {
    const unassignedGrouped = initialTeamSelectValues.map((val) => {
      return {
        ...val,
        team: val.name === 'Unassigned' ? val.name : val.team,
        group: val.name === 'Unassigned' ? val.name : val.group,
      };
    });
    return unassignedGrouped;
  }, [initialTeamSelectValues]);

  useEffect(() => {
    if (!usersFilterEnabled) {
      onContribsInit([]);
    }

    if (!reposFilterEnabled) {
      onReposInit([]);
    }
  }, [usersFilterEnabled, reposFilterEnabled]);

  const reposFilter = (
    <RepositoriesMultiSelect
      isLoading={!ready}
      options={reposOptions}
      areOptionsLoading={!reposReady}
      onApply={onReposApplyChange}
      onInit={onReposInit}
      persistentKey={filtersStorageKeys.REPOSITORIES(accountID)}
      initialValues={appliedRepos}
      isDisabled={!reposFilterEnabled}
    />
  );

  if (hasAppliedNullContrib) {
    initialTeamSelectValues.push(nullUser);
  }

  const contribsFilter = (
    <UserMultiSelect
      isLoading={!ready}
      options={contribsOptions}
      areOptionsLoading={!contribsReady}
      onInit={onContribsInit}
      onApply={onContribsApplyChange}
      persistentKey={filtersStorageKeys.CONTRIBUTORS(accountID)}
      persistentKeyGroups={filtersStorageKeys.CONTRIBUTOR_TEAMS(accountID)}
      resetStorage={
        isFeatureEnabled(featuresList.filter_teams_mandatory, features) && teams.length === 0
      }
      initialValues={extendedInitialValues}
      teams={extendedTeams}
      isDisabled={!usersFilterEnabled}
    />
  );

  const formatGhLabels = (labels) =>
    _(labels)
      .map((v) => ({
        ...v,
        labelType: 'github',
        group: 'Labels',
        labelPrefix: 'Pull requests labeled',
        icon: icons.nav_pr,
        color: theme.color.ui.orange['100'],
        label: v.name,
        value: v.name,
      }))
      .value();

  const formatJiraEpics = (epics) =>
    _(epics)
      .map((v) => {
        const { children, ...epic } = v;
        return {
          ...epic,
          labelType: 'jira',
          group: 'Epics',
          icon: icons.jira_epic,
          color: theme.color.ui.purple['100'],
          value: v.id,
          label: v.title,
        };
      })
      .value();

  const formatIssueTypes = (types) =>
    _(types)
      .map((v) => {
        return {
          ...v,
          group: 'Issue Types',
          labelType: 'issueType',
        };
      })
      .value();

  const ghLabelsOptions = formatGhLabels(labels.options.values);
  const ghLabelsInitial = formatGhLabels(labels.applied.values);
  const jiraEpicsOptions = formatJiraEpics(epics.options.values);
  const jiraEpicsInitial = formatJiraEpics(epics.applied.values);
  const issueTypesOptions = formatIssueTypes(issueTypes.options.values);
  const issueTypesInitial = formatIssueTypes(issueTypes.applied.values);

  const omniboxOptions = _(issueTypesOptions)
    .concat(ghLabelsOptions)
    .concat(jiraEpicsOptions)
    .value();
  const omniboxInitialValue = _(issueTypesInitial)
    .concat(ghLabelsInitial)
    .concat(jiraEpicsInitial)
    .value();

  const shouldRenderOption = (
    nodeId,
    matchingOptions,
    matchingAncestors,
    inputValue,
    prevInputValue
  ) => {
    const labels = _(matchingOptions)
      .filter((opt) => opt.labelType === 'github')
      .value();
    const epics = _(matchingOptions)
      .filter((opt) => opt.labelType === 'jira')
      .value();
    const issueTypes = _(matchingOptions)
      .filter((opt) => opt.labelType === 'issueType')
      .value();
    const topLabels = _(labels).take(3).map('id').value();
    const topEpics = _(epics).take(3).map('id').value();
    const topIssueTypes = _(issueTypes).take(3).map('id').value();
    return (
      _(topLabels).includes(nodeId) ||
      _(topEpics).includes(nodeId) ||
      _(topIssueTypes).includes(nodeId)
    );
  };

  const omniboxFilter = (
    <DropdownSelector
      disabled={isLoading}
      initialValue={omniboxInitialValue}
      options={omniboxOptions}
      placeholder="Filter by issue type, label, epic, ..."
      withAllOption={false}
      noCheckboxes={true}
      noAvatar={false}
      useStorage={true}
      onLoadFromStorage={onOmniboxInit}
      storageKey={filtersStorageKeys.OMNIBOX(accountID)}
      shouldRenderOption={shouldRenderOption}
      onChange={onLabelsApplyChange}
      noCheckboxesTagLabelRender={(group, selected) => {
        const isPlural = selected.length > 1;
        if (group === 'Labels') {
          return `${selected.length} label${isPlural ? 's' : ''}`;
        } else if (group === 'Epics') {
          return `${selected.length} epic${isPlural ? 's' : ''}`;
        } else if (group === 'Issue Types') {
          return `${selected.length} issue type${isPlural ? 's' : ''}`;
        } else {
          return `${selected.length} unknown${isPlural ? 's' : ''}`;
        }
      }}
    />
  );

  const dateIntervalFilter = (
    <DateInterval
      minDate={allowedDateInterval.from.toMoment()}
      maxDate={allowedDateInterval.to.toMoment()}
      initialFrom={dateInterval.from.toMoment()}
      initialTo={dateInterval.to.toMoment()}
      onChange={onDateIntervalChange}
      onExcludeInactive={onExcludeInactive}
      isExcludeInactive={excludeInactive}
      persistentKey={filtersStorageKeys.DATE_RANGE(accountID)}
    />
  );

  return (
    <div css={filtersStyles} className="row">
      <div className="col-12">
        <div className="form">
          <div className="left">
            {usersFilterEnabled && <div>{contribsFilter}</div>}
            {reposFilterEnabled && <div>{reposFilter}</div>}
            {labelsFilterEnabled && <div>{omniboxFilter}</div>}
          </div>
          <div className="right">
            <div>{dateIntervalFilter}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopFilter;
