import React from 'react';

import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import Panel from '@analytics-components/insights/Panel';
import { prepareChartBoxes } from '@analytics-pages/delivery-pipeline/insights/helper';

import bugFixing from './bugFixing';

const meta = {
  title: 'Bug Fixing',
  description:
    'Proportion of tickets resolved being bugs. Evolving through time, this metric stands for the effort dedicated to bug fixing.',
};

const chartBoxes = [
  {
    id: 'bug-fixing',
    component: ChartBoxWithKPIWidget,
    params: bugFixing,
  },
];

const BugFixingPanel = () => {
  const preparedChartBoxes = prepareChartBoxes(chartBoxes);
  return <Panel meta={meta} chartBoxes={preparedChartBoxes} />;
};

export default BugFixingPanel;
