import { useLocalStorage } from '@rehooks/local-storage';
import classnames from 'classnames';
import React, { useState } from 'react';
import { Link, NavLink, Redirect } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import Page from '@analytics-pages/Page';
import defaultImage from '@assets/images/default-user-image.png';
import welcome from '@assets/images/settings-welcome.svg';
import { useUserContext } from '@common-context/User';
import { AuthRouteEnum } from '@routes/authRoutes';

import {
  settingsContainerStyles,
  settingsGroupStyles,
  settingsGroupHeaderStyles,
  settingsGroupBodyStyles,
  settingsExtraStyles,
} from './styles';

const welcomedKey = (user) => `settings.welcomed${user?.id ? '.' + user.id : ''}`;

const Settings = ({ children }) => {
  const { user, isAuthenticated, isDemo } = useUserContext();
  const [welcomedStorage, setWelcomedStorage] = useLocalStorage(welcomedKey(user), false);

  if (!isAuthenticated || isDemo) return <Redirect to={AuthRouteEnum.LOGIN} />;

  return (
    <Page>
      <div className="row">
        <div className="col-2">
          <div className="card mb-5">
            <div className="card-header text-center bg-white">
              <img
                className="rounded-circle mt-2 mb-4"
                src={user.picture || defaultImage}
                alt=""
                width="100"
              />
              <h3 className="text-dark text-truncate h5">{user.name}</h3>
            </div>
            <div className="card-body p-0">
              <div className="list-group list-group-flush">
                <NavLink className="list-group-item py-2" to="/settings/profile">
                  Profile
                </NavLink>
                <NavLink className="list-group-item py-2" to="/settings/teams">
                  Teams
                </NavLink>
                <NavLink className="list-group-item py-2" to="/settings/releases">
                  Releases
                </NavLink>
                <NavLink className="list-group-item py-2" to="/settings/allocation">
                  Allocation
                </NavLink>
                <NavLink className="list-group-item py-2" to="/settings/users">
                  Users
                </NavLink>
                <Link
                  to="/logout"
                  className="list-group-item bg-light text-right py-2 rounded-bottom"
                >
                  Logout
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div css={settingsContainerStyles} className="col-10">
          <Welcome
            welcomed={welcomedStorage}
            dismissFn={() => {
              setWelcomedStorage(true);
            }}
          />
          {children}
        </div>
      </div>
    </Page>
  );
};

const Welcome = ({ welcomed, dismissFn }) => {
  return (
    <CSSTransition in={!welcomed} timeout={200} classNames="disposable" unmountOnExit>
      <div className="alert alert-default py-0 mb-4">
        <div className="d-flex align-items-center">
          <div className="p-4 w-50">
            <h4 className="alert-heading h2 mb-2">Welcome to your personal settings area</h4>
            <p className="font-weight-light">
              Here you can check your Athenian setup information and choose your organization
              preferences
            </p>
          </div>
          <div className="w-50 text-center mt-4">
            <img src={welcome} alt="" width="220" />
          </div>
        </div>
        <button type="button" className="close" aria-label="Close" onClick={dismissFn}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </CSSTransition>
  );
};

const SettingsGroup = ({ title = null, extra = null, children = null }) => {
  return (
    <div css={settingsGroupStyles}>
      {(title || extra) && (
        <div css={settingsGroupHeaderStyles}>
          <h2>{title}</h2>
          {extra && <div css={settingsExtraStyles}>{extra}</div>}
        </div>
      )}
      <div css={settingsGroupBodyStyles}>{children}</div>
    </div>
  );
};

const Accordion = ({ id, items }) => (
  <div id={id} className="accordion w-dropdowns">
    {items.map((item, key) => (
      <AccordionItem parentId={id} key={item.title} pos={key} shown={key === 0} item={item} />
    ))}
  </div>
);

const AccordionItem = ({ parentId, pos, shown, item }) => {
  const [isShown, setIsShown] = useState(shown);
  const [isRenderedOnce, setIsRenderedOnce] = useState(shown);
  const itemId = `${parentId}-heading-${pos}`;

  const onClickHandler = () => {
    setIsShown(!isShown);
    if (!isRenderedOnce) {
      setIsRenderedOnce(true);
    }
  };

  return (
    <div className="card mb-4 rounded-0 border-bottom">
      <div className="card-header d-flex align-items-center p-0" id={itemId}>
        <h5 className="mb-0">
          <button
            className={classnames(
              'btn',
              'text-dark',
              'd-flex',
              'align-items-center',
              'w-100',
              'py-3',
              'pl-3',
              !isShown && 'collapsed'
            )}
            onClick={onClickHandler}
            aria-expanded={isShown}
            aria-controls={`collapse-${itemId}`}
          >
            <i className="fas fa-angle-right accordion-icon mr-3" />
            <span>
              {item.title}
              {item.description && (
                <span className="text-secondary font-weight-light d-inline-block ml-2">
                  {item.description}
                </span>
              )}
            </span>
          </button>
        </h5>
        {item.extra && <div className="ml-auto d-flex mr-4">{item.extra}</div>}
      </div>
      <div
        id={`collapse-${itemId}`}
        className={classnames('collapse', isShown && 'show')}
        aria-labelledby={itemId}
        data-parent={`#${parentId}`}
      >
        {isRenderedOnce && <div className="card-body p-0">{item.content}</div>}
      </div>
    </div>
  );
};

export default Settings;
export { Accordion, SettingsGroup };
