import React from 'react';

import Badge, { NEGATIVE_IS_BETTER, POSITIVE_IS_BETTER } from '@analytics-components/Badge';
import { READY } from '@analytics-components/StatusIndicator';
import { BigNumber, SmallTitle, RegularTitle } from '@analytics-components/Typography';
import { useHelpContext } from '@analytics-context/Help';
import * as NumberService from '@common-services/numberService';
import { Info } from '@lib/info';
import Hint from '@ui/Hint';

import { mainMetricStyles, mainMetricTextStyles, mainMetricValueStyles } from './styles';

const MainMetrics = ({ data, status, definitions }) => {
  if (12 % definitions.length > 0) {
    return null;
  }

  const colsPerMetric = 12 / definitions.length;
  const className = `col-md-${colsPerMetric}`;
  return (
    <div className="row my-4">
      {definitions.map((d, i) => (
        <div key={i} className={className}>
          <MainMetric
            title={d.title}
            hint={<MainMetricHint {...d.hint} />}
            dataGetter={() => d.dataGetter(data)}
            status={status}
            emptyMessage={d.emptyMessage}
            negativeIsBetter={d.negativeIsBetter}
          />
        </div>
      ))}
    </div>
  );
};

const MainMetricHint = ({ primaryText, secondaryText, learnMore }) => {
  const { openSection } = useHelpContext();
  return (
    <Hint
      primaryText={primaryText}
      secondaryText={secondaryText}
      learnMore={learnMore}
      onLearnMoreClick={openSection}
    />
  );
};

const MainMetric = ({
  title,
  hint,
  dataGetter,
  status,
  emptyMessage,
  negativeIsBetter = false,
}) => {
  let content;
  if (status !== READY) {
    content = <div className="main-metrics-placeholder" />;
  } else {
    const [value, variation] = dataGetter();
    if (!value.raw && value.raw !== 0) {
      content = <RegularTitle content="-" />;
    } else {
      content =
        value.raw === 0 && emptyMessage ? (
          <RegularTitle content={emptyMessage} />
        ) : (
          <>
            <BigNumber content={value.formatted} />
            <Badge
              value={NumberService.round(value.raw ? variation : null)}
              trend={negativeIsBetter ? NEGATIVE_IS_BETTER : POSITIVE_IS_BETTER}
              className="ml-1"
            />
          </>
        );
    }
  }

  return (
    <div css={mainMetricStyles}>
      <div css={mainMetricTextStyles}>
        <SmallTitle content={title} isUppercase />
        <Info content={hint} />
      </div>
      <div css={mainMetricValueStyles}>{content}</div>
    </div>
  );
};

export default MainMetrics;
