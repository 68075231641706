/**
 * {{ title }}
 * Server version: {{ server_version }} built on {{ build_date }} @ [{{ commit }}](https://github.com/athenianco/athenian-api/commit/{{ commit }})  Origin GitHub repository: [athenianco/api-spec](https://github.com/athenianco/api-spec).  Authorization persists between sessions. 🛡️ means that the endpoint requires account admin privileges. 👑 means that the endpoint is only accessible to Athenian authorities. <details> <summary>List of common server errors.</summary> * __500__ endpoint crashed. We reported the incident to Sentry and will fix it soon! Please include the value of `instance` in the response if you contact the support. Repeating the request will not help, most probably. * __501__ some functions are missing, either because they are not implemented yet or a non-critical runtime dependency is not satisfied. Repeating the request will not help, guaranteed. * __502__ server crashed badly, either due to a memory access violation in native code or running out of memory. We reported this incident to Sentry and will fix it soon! Try repeating the request. * __503__ server has not fully launched yet, e.g. hasn't connected to the database; server is shutting down; we are experiencing a partial outage. Try repeating the request. * __504__ endpoint took too much time and was interrupted. We reported the incident to Sentry and will see how to improve the performance. Repeating the request will not help, most probably. </details>
 *
 * Contact: admin@athenian.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CalculatedCodeCheckMetricsItem from './CalculatedCodeCheckMetricsItem';
import CodeCheckMetricID from './CodeCheckMetricID';

/**
 * The CalculatedCodeCheckMetrics model module.
 * @module model/CalculatedCodeCheckMetrics
 */
class CalculatedCodeCheckMetrics {
    /**
     * Constructs a new <code>CalculatedCodeCheckMetrics</code>.
     * Response from &#x60;/metrics/code_checks&#x60;. The dates start from &#x60;date_from&#x60; and end earlier or equal to &#x60;date_to&#x60;. The order of the items is *not* guaranteed to match any expectations. 
     * @alias module:model/CalculatedCodeCheckMetrics
     * @param calculated {Array.<module:model/CalculatedCodeCheckMetricsItem>} Values of the requested metrics through time.
     * @param metrics {Array.<module:model/CodeCheckMetricID>} Repeats `CodeCheckMetricsRequest.metrics`.
     * @param dateFrom {Date} Repeats `CodeCheckMetricsRequest.date_from`.
     * @param dateTo {Date} Repeats `CodeCheckMetricsRequest.date_to`.
     * @param granularities {Array.<String>} 
     */
    constructor(calculated, metrics, dateFrom, dateTo, granularities) { 
        
        CalculatedCodeCheckMetrics.initialize(this, calculated, metrics, dateFrom, dateTo, granularities);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, calculated, metrics, dateFrom, dateTo, granularities) { 
        obj['calculated'] = calculated;
        obj['metrics'] = metrics;
        obj['date_from'] = dateFrom;
        obj['date_to'] = dateTo;
        obj['granularities'] = granularities;
    }

    /**
     * Constructs a <code>CalculatedCodeCheckMetrics</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CalculatedCodeCheckMetrics} obj Optional instance to populate.
     * @return {module:model/CalculatedCodeCheckMetrics} The populated <code>CalculatedCodeCheckMetrics</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CalculatedCodeCheckMetrics();

            if (data.hasOwnProperty('calculated')) {
                obj['calculated'] = ApiClient.convertToType(data['calculated'], [CalculatedCodeCheckMetricsItem]);
            }
            if (data.hasOwnProperty('metrics')) {
                obj['metrics'] = ApiClient.convertToType(data['metrics'], [CodeCheckMetricID]);
            }
            if (data.hasOwnProperty('date_from')) {
                obj['date_from'] = ApiClient.convertToType(data['date_from'], 'Date');
            }
            if (data.hasOwnProperty('date_to')) {
                obj['date_to'] = ApiClient.convertToType(data['date_to'], 'Date');
            }
            if (data.hasOwnProperty('timezone')) {
                obj['timezone'] = ApiClient.convertToType(data['timezone'], 'Number');
            }
            if (data.hasOwnProperty('granularities')) {
                obj['granularities'] = ApiClient.convertToType(data['granularities'], ['String']);
            }
            if (data.hasOwnProperty('quantiles')) {
                obj['quantiles'] = ApiClient.convertToType(data['quantiles'], ['Number']);
            }
            if (data.hasOwnProperty('split_by_check_runs')) {
                obj['split_by_check_runs'] = ApiClient.convertToType(data['split_by_check_runs'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * Values of the requested metrics through time.
 * @member {Array.<module:model/CalculatedCodeCheckMetricsItem>} calculated
 */
CalculatedCodeCheckMetrics.prototype['calculated'] = undefined;

/**
 * Repeats `CodeCheckMetricsRequest.metrics`.
 * @member {Array.<module:model/CodeCheckMetricID>} metrics
 */
CalculatedCodeCheckMetrics.prototype['metrics'] = undefined;

/**
 * Repeats `CodeCheckMetricsRequest.date_from`.
 * @member {Date} date_from
 */
CalculatedCodeCheckMetrics.prototype['date_from'] = undefined;

/**
 * Repeats `CodeCheckMetricsRequest.date_to`.
 * @member {Date} date_to
 */
CalculatedCodeCheckMetrics.prototype['date_to'] = undefined;

/**
 * Local time zone offset in minutes, used to adjust `date_from` and `date_to`.
 * @member {Number} timezone
 */
CalculatedCodeCheckMetrics.prototype['timezone'] = undefined;

/**
 * @member {Array.<String>} granularities
 */
CalculatedCodeCheckMetrics.prototype['granularities'] = undefined;

/**
 * Cut the distributions at certain quantiles. The default values are [0, 1] which means no cutting.
 * @member {Array.<Number>} quantiles
 */
CalculatedCodeCheckMetrics.prototype['quantiles'] = undefined;

/**
 * Repeats `CodeCheckMetricsRequest.split_by_check_runs`.
 * @member {Boolean} split_by_check_runs
 */
CalculatedCodeCheckMetrics.prototype['split_by_check_runs'] = undefined;






export default CalculatedCodeCheckMetrics;

