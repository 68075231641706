import { privateApi } from '@common-services/api/private';
import { User } from '@common-services/api/public/generated-from-backend/models';

export const becomeUser = async (userId: string, token: string): Promise<User> => {
  return privateApi.backoffice.becomeUser(
    userId,
    token,
    userId
      ? {
          message: 'Cannot become user ' + userId,
        }
      : null
  );
};

export * as BackofficeServices from './backoffice';
