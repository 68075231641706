import React from 'react';

import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import Panel from '@analytics-components/insights/Panel';
import { prepareChartBoxes } from '@analytics-pages/delivery-pipeline/insights/helper';

import activeContributors from './activeContributors';

const meta = {
  title: 'Contributor Timeline',
  description: 'Evolution of the number of contributors (any GitHub activity) through time.',
};

const chartBoxes = [
  {
    id: 'active-contributors',
    component: ChartBoxWithKPIWidget,
    params: activeContributors,
  },
];

const ActiveContributorsPanel = () => {
  const preparedChartBoxes = prepareChartBoxes(chartBoxes);
  return <Panel meta={meta} chartBoxes={preparedChartBoxes} />;
};

export default ActiveContributorsPanel;
