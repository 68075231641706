import { css } from '@emotion/react';

export const headerStyles = ({ color, spacing }) => css`
  align-items: center;
  background-color: ${color.neutral.white};
  border-bottom: 1px solid ${color.neutral['40']};
  display: flex;
  justify-content: space-between;
  min-height: 52px;
  padding: ${spacing.gap['04']} ${spacing.gap['05']};

  .navigation-part {
    align-items: center;
    display: flex;

    .arrow-wrapper {
      cursor: pointer;
      margin-right: ${spacing.gap['05']};
    }
  }

  .buttons-part {
    display: flex;

    button:first-of-type {
      margin-right: ${spacing.gap['04']};
    }
  }
`;
