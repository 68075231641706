// TODO: Refactor @analytics-components/Badge with TS and replace this with that
import React from 'react';

import { Icon } from '@lib/icon';
import { theme } from '@styles/theme';

import { useCaretIcon, useRatio } from './variationBadge.hooks';
import { wrapperStyles } from './variationBadge.styles';
import { IVariationBadgeProps } from './variationBadge.types';

const VariationBadge: React.FC<IVariationBadgeProps> = React.memo(
  ({ refValue, targetValue, positiveIsBetter }) => {
    const ratio = useRatio(refValue, targetValue);

    const caretIcon = useCaretIcon(positiveIsBetter, ratio);

    return (
      <div css={wrapperStyles}>
        {caretIcon && <Icon color={theme.color.ui.blue['100']} icon={caretIcon} size={6} />}
        <div className="variation-value">{ratio ? `${Math.abs(ratio)}%` : '-'}</div>
      </div>
    );
  }
);

VariationBadge.defaultProps = {
  positiveIsBetter: true,
};

export { VariationBadge };
