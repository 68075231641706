import React, { useState } from 'react';
import Select from 'react-select';

import { useApi } from '@analytics-hooks';
import { saveRepoSettings } from '@analytics-services/api';
import { Accordion } from '@common-pages/Settings';
import { DEFAULT_PATTERNS } from '@common-pages/Settings/Releases/constants';
import { github } from '@common-services/format';
import log from '@common-services/logger';

import { ITypeOptions, MESSAGES } from '../types';
import { getDefaultText, isFilteredIn } from '../utils';
import Strategy from './Strategy';

const RepoConfig = ({ accountId, config, filterTerm }) => {
  const { api, ready: apiReady } = useApi(true, false);
  const [matchState, setMatchState] = useState<ITypeOptions>(config.match);
  const [branchState, setBranchState] = useState(config.branches);
  const [tagState, setTagsState] = useState(config.tags);

  const changeMatchStrategy = async (strategy) => {
    try {
      if (!apiReady) {
        throw new Error('Could not obtain an API client');
      }

      await saveRepoSettings(
        api,
        accountId,
        [config.url],
        strategy,
        strategy === ITypeOptions.AUTO
          ? DEFAULT_PATTERNS[ITypeOptions.BRANCH]
          : strategy === ITypeOptions.EVENT
          ? ITypeOptions.BRANCH
          : branchState,
        strategy === ITypeOptions.AUTO
          ? DEFAULT_PATTERNS[ITypeOptions.TAG]
          : strategy === ITypeOptions.EVENT
          ? ITypeOptions.TAG
          : tagState
      );

      setMatchState(strategy);

      if (strategy === ITypeOptions.AUTO || strategy === ITypeOptions.EVENT) {
        setBranchState(ITypeOptions.BRANCH);
        setTagsState(ITypeOptions.TAG);
      }

      log.ok(MESSAGES.SUCCESS);
    } catch (e) {
      log.fatal(`Could not change release workflow`, e);
    }
  };

  const changePattern = async (strategy, pattern, onSuccess, onError) => {
    try {
      if (!apiReady) {
        throw new Error('Could not obtain an API client');
      }
      switch (strategy) {
        case ITypeOptions.BRANCH:
          await saveRepoSettings(api, accountId, [config.url], strategy, pattern, tagState);
          setBranchState(pattern);
          break;
        case ITypeOptions.TAG:
          await saveRepoSettings(api, accountId, [config.url], strategy, branchState, pattern);
          setTagsState(pattern);
          break;
        default:
          throw new Error('Automatic and Event strategies do not support patterns');
      }
      onSuccess && onSuccess();
      log.ok(MESSAGES.SUCCESS);
    } catch (e) {
      onError && onError();
      log.fatal(`Could not configure release workflow`, e);
    }
  };

  return (
    <li
      className="list-group-item bg-white font-weight-normal"
      style={{ display: isFilteredIn(config, filterTerm) ? 'block' : 'none' }}
    >
      <div className="row">
        <div className="col-3">
          <p className="text-secondary text-truncate my-3 pl-5">
            {github.repoOrg(config.url)}/
            <span className="text-dark">{github.repoName(config.url)}</span>
          </p>
        </div>

        <div className="col-9 d-flex align-items-center">
          <div className="dropdown mr-5">
            <Select
              className="releases-dropdown simple-dropdown ucfirst"
              value={[matchState]}
              onChange={changeMatchStrategy}
              options={Object.values(ITypeOptions)}
              getOptionLabel={(value) => (value === ITypeOptions.AUTO ? 'automatic' : value)}
              getOptionValue={(value) => value}
              isMulti={false}
              isSearchable={false}
              hideSelectedOptions={false}
              controlShouldRenderValue={true}
            />
          </div>

          <Strategy
            id={`${github.repoOrg(config.url)}-${github.repoName(config.url)}`}
            name={matchState}
            pattern={matchState === ITypeOptions.BRANCH ? branchState : tagState}
            defaultText={getDefaultText(matchState, config.default_branch)}
            onChange={changePattern}
          />
        </div>
      </div>
    </li>
  );
};

const RepoGroup = ({ accountId, configs, filterTerm }): JSX.Element => {
  return (
    <ul className="list-group list-group-flush">
      {configs.map((config, key) => (
        <RepoConfig
          key={config.url}
          accountId={accountId}
          config={config}
          filterTerm={filterTerm}
        />
      ))}
    </ul>
  );
};

const RepoGroups = ({ accountId, groups, filterTerm }): JSX.Element => {
  return (
    <Accordion
      id="accordion"
      items={groups.map((repoGroup) => ({
        title: <span>{github.repoOrg(repoGroup[0].url)}</span>,
        description: `(${
          repoGroup.filter((config) => isFilteredIn(config, filterTerm)).length
        } repositories)`,
        content: <RepoGroup accountId={accountId} configs={repoGroup} filterTerm={filterTerm} />,
      }))}
    />
  );
};

export default RepoGroups;
