import _ from 'lodash';

import { calculateGranularity } from '@analytics-components/Prefetcher';
import { BigText, DateRangeBigNumber } from '@analytics-components/charts/Tooltip';
import VerticalBarChart from '@analytics-components/charts/VerticalBarChart';
import { contentTypes } from '@analytics-context/Allocation';
import { sortData } from '@analytics-pages/outcome/insights/allocation/work-types/helpers';
import { dateTime } from '@common-services/dateService';
import { theme } from '@styles/theme';

const throughputChart = {
  plumber: (fetchedData, apiContext, appliedWorkTypes) => {
    // sort PRs data by their corresponding work types as the order with which it comes from API does not guarantee it
    let granularPrsData = sortData(fetchedData.granularPrs, appliedWorkTypes);
    // remove the last item from the data array which stands for Other section and not needed for this chart
    if (!!appliedWorkTypes?.length) {
      granularPrsData = granularPrsData.slice(0, -1);
    }
    const granularIssuesData = !!appliedWorkTypes?.length
      ? fetchedData.granularIssues.slice(0, -1)
      : fetchedData.granularIssues;

    const prsData = _.zip(...granularPrsData.map((item) => item.values)).map(
      (item) =>
        item && {
          date: item[0].date,
          value: item.reduce((acc, val) => acc + val.values[0], 0),
        }
    );

    const issuesData = fetchedData.allIssues.length
      ? _.zip(...granularIssuesData.map((item) => item[0].values)).map(
          (item) =>
            item && {
              date: item[0].date,
              value: item.reduce((acc, val) => acc + val.values[0], 0),
            }
        )
      : [];

    return {
      interval: apiContext.interval,
      issuesData,
      prsData,
    };
  },
  factory: (computed, contentType) => {
    const customGranularity = calculateGranularity(computed.interval);

    const tooltip = {
      align: {
        horizontal: 'auto',
        vertical: 'top',
      },
      renderBigFn: (v) => (
        <BigText
          content={`${v.y} ${contentType === contentTypes.prs ? 'Pull request' : 'Issue'}${
            v.y === 1 ? '' : 's'
          }`}
        />
      ),
    };

    if (_(['month', 'week']).includes(customGranularity)) {
      tooltip.template = DateRangeBigNumber;
      tooltip.interval = { ...computed.interval };
      tooltip.granularity = customGranularity;
    }

    const tickFormat = customGranularity === 'month' ? dateTime.month : dateTime.monthDay;

    const chartData = contentType === contentTypes.prs ? computed.prsData : computed.issuesData;

    return {
      meta: { title: 'Throughput', description: '' },
      empty: chartData.reduce((acc, item) => acc + item.value, 0) === 0,
      content: [
        {
          chart: {
            component: VerticalBarChart,
            params: {
              data: chartData,
              timeMode: true,
              extra: {
                axisLabels: {
                  y: contentType === contentTypes.prs ? 'Pull requests' : 'Issues',
                },
                axisFormat: {
                  tickFormat: {
                    x: tickFormat,
                  },
                },
                axisKeys: {
                  x: 'date',
                  y: 'value',
                },
                margin: { bottom: 60 },
                maxNumberOfTicks: 5,
                color: theme.color.ui.blue[100],
                tooltip,
              },
            },
          },
          kpis: [],
        },
      ],
    };
  },
};

export default throughputChart;
