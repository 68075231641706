import React, { useContext, useMemo } from 'react';

import { FooterButtons } from '@lib/FooterButtons';
import { SelectorTsContext } from '@lib/selector-ts/context';

export const SelectorTsFooterSection: React.FC = React.memo(() => {
  const {
    applyHandler,
    cancelHandler,
    selectedIds,
    maxSelected,
    minSelected,
    isLoading,
  } = useContext(SelectorTsContext);
  const isDisabled = useMemo<boolean>(() => {
    return selectedIds
      ? selectedIds.length < minSelected || (maxSelected && selectedIds.length > maxSelected)
      : false;
  }, [selectedIds, maxSelected, minSelected]);
  const maxSelectedTitle = maxSelected ? 'Max selected:' + maxSelected : '';
  const minSelectedTitle = minSelected ? 'Min selected:' + minSelected : '';
  return (
    <FooterButtons
      label={'Apply'}
      title={isDisabled ? `${minSelectedTitle} ${maxSelectedTitle}` : 'Apply'}
      onApply={applyHandler}
      isDisabled={isDisabled || isLoading}
      onCancel={cancelHandler}
    />
  );
});
