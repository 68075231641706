import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import {
  IPrimaryLayoutContext,
  IPrimaryLayoutProvider,
} from '@align-components/layouts/primary/context.types';
import { NavigationService } from '@align-services/navigationService';
import { useOwnedTeamTree } from '@common-services/api/private/hooks/useTeamTreeData';
import { ITabsRouteVars } from '@routes/align/tabs';

import { LoadingView } from './loadingView';

const defaultContext: IPrimaryLayoutContext = {
  path: '',
  currentTeamPath: '',
  section: '',
  parentTeams: [],
  currentTeam: null,
  breadcrumbs: [],
};
export const PrimaryLayoutContext = React.createContext<IPrimaryLayoutContext>(defaultContext);
export const useNavigation = () => useContext<IPrimaryLayoutContext>(PrimaryLayoutContext);

export const PrimaryLayoutProvider: React.FC<IPrimaryLayoutProvider> = React.memo(
  ({
    children,
    tab,
    mode,
    isSubmitAllowed,
    dateRangeInit,
    selectedMetric,
    metricParams,
    onFiltersChange,
    onMetricParamsChange,
    dateRangeOnChange,
    onNewGoalNameChange,
    onSubmit,
    onCancel,
    onRemove,
  }) => {
    const { path } = useParams<ITabsRouteVars>();

    const { data: teamsData, isLoading } = useOwnedTeamTree(true);
    const fullPath = `${path}/${tab}`;

    if (isLoading) {
      return <LoadingView />;
    }
    const [breadcrumbs, teamPath] = NavigationService.getBreadcrumbsAndTeams(
      fullPath,
      tab,
      teamsData
    );

    if (!breadcrumbs || !teamPath) {
      return <LoadingView />;
    }

    return (
      <PrimaryLayoutContext.Provider
        value={{
          path: fullPath,
          currentTeamPath: path,
          section: tab,
          parentTeams: teamPath.slice(0, -1),
          currentTeam: teamPath.at(-1),
          breadcrumbs,
          mode,
          selectedMetric,
          metricParams,
          isSubmitAllowed,
          dateRangeInit,
          onSubmit,
          onCancel,
          onRemove,
          onNewGoalNameChange,
          dateRangeOnChange,
          onFiltersChange,
          onMetricParamsChange,
        }}
      >
        {children}
      </PrimaryLayoutContext.Provider>
    );
  }
);
