import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Button, ButtonType } from '@lib/Button';
import { icons } from '@lib/icon';

import { ICopyButtonProps } from './CopyButton.types';

const CopyButton: React.FC<ICopyButtonProps> = React.memo(({ content }) => {
  const [copiedState, setCopiedState] = useState(false);

  return (
    <CopyToClipboard text={content} onCopy={() => setCopiedState(true)}>
      <Button
        icon={!copiedState && icons.copy}
        label={copiedState ? 'Copied' : 'Copy'}
        type={ButtonType.GHOST}
        onClick={() => {}}
      />
    </CopyToClipboard>
  );
});

export { CopyButton };
