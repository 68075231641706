import { css } from '@emotion/react';

export const wrapperStyles = ({ color, spacing, radius }) => css`
  display: flex;
  justify-content: center;

  .legends-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: ${spacing.gap['04']};
    margin: ${spacing.gap['0']} ${spacing.gap['04']};
    padding: ${spacing.gap['03']} ${spacing.gap['04']};
    border-radius: ${radius.default};
    border: 1px solid ${color.neutral['40']};
    justify-content: center;
  }
`;

export const badgeStyles = ({ spacing }) => css`
  display: inline-flex;
  align-items: center;
  gap: ${spacing.gap['02']};
`;

export const legendCircleStyles = ({ circleColor }) => ({ radius }) => css`
  background: ${circleColor};
  width: 12px;
  height: 12px;
  border-radius: ${radius.circle};
`;
