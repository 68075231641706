import { css } from '@emotion/react';

export const wrapperStyles = ({ color, spacing, radius }) => css`
  padding: ${spacing.gap['03']} ${spacing.gap['05']} ${spacing.gap['03']}
    calc(${spacing.gap['06']} + ${spacing.gap['07']});
  position: relative;
`;

export const listStyles = ({ color, radius, spacing }) => css`
  background-color: ${color.neutral.white};
  border: 1px solid ${color.neutral[40]};
  border-radius: ${radius.default};
  position: absolute;
  top: calc(32px + ${spacing.gap['05']});
  z-index: 100;
`;

export const listItemStyles = (disabled) => ({ color, font, radius, spacing }) => css`
  align-items: center;
  border-radius: ${radius.default};
  color: ${color.text.secondary};
  cursor: ${disabled ? 'unset' : 'pointer'};
  display: flex;
  padding: ${spacing.gap['05']} ${spacing.gap['06']};

  &:hover {
    background-color: ${color.neutral[20]};
  }

  .ath-rule-label {
    color: ${color.text.dark};
    font-size: ${font.size.default};
    font-weight: ${font.weight.regular};
    margin-left: ${spacing.gap['03']};
    ${disabled && 'user-select: none;'}
  }

  ${disabled && 'opacity: 0.3;'}
`;
