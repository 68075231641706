export interface IRemoveModal {
  entityType?: RemoveEntityType;
  isOpen: boolean;
  onRemove: () => void;
  onCancel: () => void;
}

export enum RemoveEntityType {
  CHART = 'Chart',
  GOAL = 'Goal',
}
