import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import React from 'react';

import PipelineSummaryBox from '@analytics-components/PipelineSummaryBox';
import { DATA_STATUS } from '@analytics-components/StatusIndicator';
import MultiChart from '@analytics-components/charts/MultiChart';
import { StagesEnum } from '@analytics-constants';
import { useDataWidget } from '@analytics-context/DataWidget';
import { NoData } from '@lib/empty/noData';

import { noDataStatus } from '.';
import buildMultiChartConf from './services/buildMultiChartConf';

interface Props {
  activeStage: string;
  className: string;
  color: string;
  renderKPI: (data: any) => EmotionJSX.Element;
  title: string;
}

const SummaryBoxDataBinder: React.FC<Props> = React.memo(
  ({ activeStage, color, className, renderKPI, title }) => {
    const { data, isLoading } = useDataWidget();

    if (!data && !isLoading) return null;

    const chart = buildMultiChartConf(data, isLoading, color);

    return activeStage === StagesEnum.DEPLOY && !data?.environments?.length && !isLoading ? (
      <NoData />
    ) : (
      <PipelineSummaryBox
        chart={<MultiChart charts={chart} />}
        className={className}
        data={data}
        dataStatus={noDataStatus[activeStage] || DATA_STATUS.NO_DATA}
        isLoading={isLoading}
        renderKPI={renderKPI}
        statusColor={color}
        title={title}
      />
    );
  }
);

export default SummaryBoxDataBinder;
