import React from 'react';

import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import Panel from '@analytics-components/insights/Panel';
import { prepareChartBoxes } from '@analytics-pages/delivery-pipeline/insights/helper';

import bugsRaisedByPriorityRatio from './bugs-raised-by-priority';

const meta = {
  title: 'Bugs Raised by Priority',
  description:
    'Monitor the number of bugs raised through time. Broken down by severity, it gives you an overview of the coding quality.',
};

const chartBoxes = [
  {
    id: 'bugs-raised-by-priority',
    component: ChartBoxWithKPIWidget,
    params: bugsRaisedByPriorityRatio,
  },
];

const BugsRaisedByPriorityPanel = () => {
  const preparedChartBoxes = prepareChartBoxes(chartBoxes);
  return <Panel meta={meta} chartBoxes={preparedChartBoxes} />;
};

export default BugsRaisedByPriorityPanel;
