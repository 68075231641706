import { useCallback, useEffect, useMemo, useState } from 'react';

import { IDateRangeInMls, PrsIssuesTableTabs } from '@align-types/common';
import { IUsePrsForTable } from '@align-types/prs';
import { IApiBasicError } from '@common-services/api/common/types/common';
import { SearchPullRequestsResponse } from '@common-services/api/private/generated-from-backend/models';
import {
  GetPullRequestsRequest,
  PullRequestSet,
} from '@common-services/api/public/generated-from-backend/models';
import log from '@common-services/logger';

import { useGetPRs } from './useGetPRs';

export function usePrsForTable(
  accountId: number,
  activeTab: PrsIssuesTableTabs,
  selectedRange: IDateRangeInMls,
  initialCompletedPRs: SearchPullRequestsResponse,
  inProgressPRs: SearchPullRequestsResponse,
  completedPRsIsLoading: boolean,
  inProgressPRsIsLoading: boolean,
  completedPRsError: IApiBasicError,
  inProgressPRsError: IApiBasicError
): IUsePrsForTable {
  const [completedPrOffset, setCompletedPrOffset] = useState(0);
  const [inProgressPrOffset, setInProgressPrOffset] = useState(0);
  const [completedPRs, setCompletedPRs] = useState<SearchPullRequestsResponse>(null);

  useEffect(() => {
    setCompletedPrOffset(0);
    // Completed PRs should be reset whenever Timeframe value changes
    setCompletedPRs(null);
  }, [selectedRange]);

  useEffect(() => {
    setCompletedPrOffset(0);
    setCompletedPRs(initialCompletedPRs);
  }, [initialCompletedPRs]);

  const prOffset = useMemo(
    () => (activeTab === PrsIssuesTableTabs.COMPLETED ? completedPrOffset : inProgressPrOffset),
    [activeTab, completedPrOffset, inProgressPrOffset]
  );

  const prsToRequest = useMemo<GetPullRequestsRequest['prs']>(() => {
    const prs = activeTab === PrsIssuesTableTabs.COMPLETED ? completedPRs : inProgressPRs;
    const formattedPrs = (prs?.pull_requests || []).slice(prOffset, prOffset + 10).map((pr) => ({
      numbers: [pr.number],
      repository: pr.repository,
    }));
    return formattedPrs.length ? (formattedPrs as GetPullRequestsRequest['prs']) : null;
  }, [activeTab, completedPRs, inProgressPRs, prOffset]);

  const { data: getPRsData, isLoading: getPRsIsLoading, error: getPRsIsError } = useGetPRs(
    {
      account: accountId,
      prs: prsToRequest,
      activeTab,
    },
    true
  );

  const data = useMemo<PullRequestSet>(() => {
    if (
      !(activeTab === PrsIssuesTableTabs.COMPLETED ? completedPRs : inProgressPRs)?.pull_requests
        ?.length
    ) {
      return {
        data: [],
        include: {
          deployments: {},
          users: {},
        },
      };
    }
    return getPRsData ? { ...getPRsData } : null;
  }, [activeTab, completedPRs, inProgressPRs, getPRsData]);

  const isLoading = useMemo(
    () =>
      activeTab === PrsIssuesTableTabs.COMPLETED ? completedPRsIsLoading : inProgressPRsIsLoading,
    [activeTab, completedPRsIsLoading, inProgressPRsIsLoading]
  );

  const error = useMemo(() => completedPRsError || inProgressPRsError || getPRsIsError, [
    completedPRsError,
    inProgressPRsError,
    getPRsIsError,
  ]);

  useEffect(() => {
    if (error) {
      log.error(
        'We are sorry, but we can not process your request at the moment. Please try again later.'
      );
    }
  }, [error]);

  const onShowMoreHandler = useCallback(() => {
    if (activeTab === PrsIssuesTableTabs.COMPLETED) {
      setCompletedPrOffset(completedPrOffset + 10);
    } else {
      setInProgressPrOffset(inProgressPrOffset + 10);
    }
  }, [activeTab, completedPrOffset, inProgressPrOffset]);

  return {
    data,
    completedPRs,
    isLoading,
    isLoadingMore: getPRsIsLoading,
    error,
    onShowMoreHandler,
  };
}
