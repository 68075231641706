import React, { useMemo } from 'react';

import { Icon, icons } from '@lib/icon';
import { theme } from '@styles/theme';

import { messageWrapperStyles } from './styles';

const ExpiryMessage = ({ date }) => {
  const daysDiff = useMemo(() => (new Date(date) - new Date()) / (1000 * 60 * 60 * 24), [date]);

  const icon = useMemo(() => (daysDiff >= 7 ? icons.check_succeed : icons.check_failed), [
    daysDiff,
  ]);

  const iconColor = useMemo(
    () =>
      daysDiff >= 7
        ? theme.color.status.success
        : daysDiff < 0
        ? theme.color.status.danger
        : theme.color.status.warning,
    [daysDiff]
  );

  const text = useMemo(
    () =>
      daysDiff >= 7
        ? 'The account is active'
        : daysDiff < 0
        ? 'The account expired'
        : 'The account is about to expire',
    [daysDiff]
  );

  return (
    <div css={messageWrapperStyles(iconColor)}>
      <Icon color={iconColor} icon={icon} size={14} />
      <div className="message">{text}</div>
    </div>
  );
};

export default ExpiryMessage;
