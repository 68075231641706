import { AccountsApiClass } from '@common-services/api/public/partials/AccountsApiClass';
import { ContributorsApi } from '@common-services/api/public/partials/ContributorsApiClass';
import { DeploymentsApi } from '@common-services/api/public/partials/DeploymentsApi';
import { EnvironmentsApi } from '@common-services/api/public/partials/EnvironmentsApiClass';
import { FiltersApi } from '@common-services/api/public/partials/FiltersApiClass';
import { GoalTemplatesApi } from '@common-services/api/public/partials/GoalTemplatesApiClass';
import { JiraIssuesApi } from '@common-services/api/public/partials/JiraIssuesApiClass';
import { MetricsApi } from '@common-services/api/public/partials/MetricsApiClass';
import { PullRequestsApi } from '@common-services/api/public/partials/PullRequestsApiClass';
import { RegistrationApi } from '@common-services/api/public/partials/RegistrationApiClass';
import { UserApi } from '@common-services/api/public/partials/UserApiClass';

class PublicApiClass {
  public user: UserApi;
  public registration: RegistrationApi;
  public goalTemplates: GoalTemplatesApi;
  public filters: FiltersApi;
  public pullRequests: PullRequestsApi;
  public environments: EnvironmentsApi;
  public deployments: DeploymentsApi;
  public contributors: ContributorsApi;
  public accounts: AccountsApiClass;
  public metrics: MetricsApi;
  public issues: JiraIssuesApi;
  constructor() {
    this.user = new UserApi();
    this.registration = new RegistrationApi();
    this.goalTemplates = new GoalTemplatesApi();
    this.filters = new FiltersApi();
    this.pullRequests = new PullRequestsApi();
    this.environments = new EnvironmentsApi();
    this.deployments = new DeploymentsApi();
    this.contributors = new ContributorsApi();
    this.accounts = new AccountsApiClass();
    this.metrics = new MetricsApi();
    this.issues = new JiraIssuesApi();
  }
}

export const publicApi = new PublicApiClass();
