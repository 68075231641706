import { useMemo } from 'react';

import { chartConfig } from '@align-pages/dashboard/config';
import {
  IUseDashboardChartsParams,
  IUseDashboardChartsReturn,
} from '@align-pages/dashboard/hooks/types';
import { useBreakdownOptionsData } from '@align-pages/dashboard/hooks/useDashboardBreakdown';
import { DashboardBreakdownServices } from '@align-pages/dashboard/services/dashboardBreakdownServices';
import { useDashboardStore } from '@align-pages/dashboard/store';
import { IChartConfig } from '@align-pages/dashboard/types';
import { IValuesSerializer } from '@align-services/api/metricService.types';
import { MetricService } from '@align-services/metricService';
import { PUBLIC_API_ENDPOINTS_CONSTANTS } from '@common-services/api/public/constants';
import {
  CalculatedJIRAMetrics,
  CalculatedPullRequestMetrics,
  CalculatedReleaseMetrics,
} from '@common-services/api/public/generated-from-backend/models';
import {
  useOwnedJiraIssues,
  useOwnedPullRequests,
  useOwnedReleases,
} from '@common-services/api/public/hooks/useMetricsData';
import { DateService } from '@common-services/dateService';
import {
  ChartTypeEnum,
  IChartSeriesItem,
  LineWithPlotBandService,
  MultiLineServices,
} from '@lib/chart';
import { FilterType } from '@lib/filter-panel';

export const useOwnedChartConfig = (): IChartConfig => {
  const chartParams = useDashboardStore((state) => state.chartParams);
  return chartConfig.find((conf) => conf.metric === chartParams?.metric);
};

export const useDashboardCharts = (
  params: IUseDashboardChartsParams,
  type?: FilterType
): IUseDashboardChartsReturn => {
  const { date_from, date_to, metric, group_by, filters } = params;
  const isBreakdown = useMemo(() => Object.entries(group_by || {}).length, [group_by]);

  const { endpoint, isFrequency, valueType } =
    chartConfig.find((conf) => conf.metric === metric) || {};

  const { data: pullRequestsData, isLoading: isPullRequestsLoading } = useOwnedPullRequests(
    params,
    true,
    endpoint === PUBLIC_API_ENDPOINTS_CONSTANTS.PULL_REQUESTS
  );

  const { data: jiraIssuesData, isLoading: isJiraIssuesLoading } = useOwnedJiraIssues(
    params,
    true,
    endpoint === PUBLIC_API_ENDPOINTS_CONSTANTS.ISSUES
  );

  const { data: releasesData, isLoading: isReleasesLoading } = useOwnedReleases(
    params,
    true,
    endpoint === PUBLIC_API_ENDPOINTS_CONSTANTS.RELEASES
  );

  const data = useMemo(() => {
    switch (endpoint) {
      case PUBLIC_API_ENDPOINTS_CONSTANTS.PULL_REQUESTS:
        return pullRequestsData;
      case PUBLIC_API_ENDPOINTS_CONSTANTS.ISSUES:
        return jiraIssuesData;
      case PUBLIC_API_ENDPOINTS_CONSTANTS.RELEASES:
        return releasesData;
      default:
        return [];
    }
  }, [endpoint, pullRequestsData, jiraIssuesData, releasesData]);

  const dataValues = useMemo<IValuesSerializer['values']>(
    () =>
      endpoint === PUBLIC_API_ENDPOINTS_CONSTANTS.PULL_REQUESTS
        ? (data as CalculatedPullRequestMetrics)?.calculated
        : (data as CalculatedJIRAMetrics | CalculatedReleaseMetrics),
    [endpoint, data]
  );

  const seriesData = useMemo<IChartSeriesItem[]>(
    () => MetricService.valuesSerializer({ values: dataValues, valueType }),
    [dataValues, valueType]
  );

  const groupByType = group_by && DashboardBreakdownServices.getBreakdownType(group_by);

  // Generate generatedBreakdownOptions data if the relevant filter applied, or use group_by directly
  const generatedBreakdownOptions = useMemo(() => {
    const groupByFromFilters = DashboardBreakdownServices.getGroupByFromFilters(filters);
    const groupByData = groupByFromFilters?.[groupByType]?.length ? groupByFromFilters : group_by;
    return DashboardBreakdownServices.getGroupByOptions(groupByData, groupByType);
  }, [type, groupByType, group_by, filters]);

  // Generate info object array that includes counters and ids as sorted already
  const multiSeriesInfo = useMemo(
    () =>
      generatedBreakdownOptions
        ? MetricService.combineMultiSeriesInfo({
            values: dataValues,
            breakdownOptions: generatedBreakdownOptions,
          })
        : [],
    [dataValues, generatedBreakdownOptions]
  );

  const breakdownOptionsData = useBreakdownOptionsData(groupByType);

  // If it's dashboard page, generate groupByOptions by itself instead of breakdownOptions state
  const groupByOptions = useMemo(
    () =>
      DashboardBreakdownServices.getBreakdownOptions({
        groupByInfo: group_by,
        type: groupByType,
        optionsData: breakdownOptionsData,
      }),
    [group_by, groupByType, breakdownOptionsData]
  );

  // Create actual series data for multiline chart from multiSeriesInfo
  // breakdownOptions will be used to include selected options
  const { multiLineSeries, seriesNames, seriesColors } = useMemo(
    () =>
      MetricService.generateMultiLineSeries(
        multiSeriesInfo,
        valueType,
        groupByOptions,
        groupByType,
        breakdownOptionsData
      ),
    [multiSeriesInfo, valueType, groupByOptions, groupByType, breakdownOptionsData]
  );

  const granularity = useMemo(() => {
    return DateService.calculateGranularity({ dateFrom: date_from, dateTo: date_to });
  }, [date_from, date_to]);

  const options = useMemo(() => {
    const chartConf = chartConfig.find((conf) => conf.metric === metric);
    if (isBreakdown) {
      return MultiLineServices.getOptions({
        multiLineSeries,
        startDate: date_from,
        endDate: date_to,
        unit: chartConf?.unit,
        valueType: chartConf?.valueType,
        granularity,
        isFrequency,
      });
    }
    return LineWithPlotBandService.getOptions({
      series: seriesData,
      startDate: date_from,
      endDate: date_to,
      unit: chartConf?.unit,
      valueType: chartConf?.valueType,
      granularity,
      isFrequency,
    });
  }, [
    seriesData,
    multiLineSeries,
    date_from,
    date_to,
    metric,
    isBreakdown,
    granularity,
    isFrequency,
  ]);

  const chartType = useMemo(
    () => (isBreakdown ? ChartTypeEnum.MULTI_LINE : ChartTypeEnum.FILLED_AREA),
    [isBreakdown]
  );

  const isLoading = useMemo(
    () => isPullRequestsLoading || isJiraIssuesLoading || isReleasesLoading,
    [isPullRequestsLoading, isJiraIssuesLoading, isReleasesLoading]
  );

  return {
    options,
    chartType,
    isLoading,
    seriesNames,
    seriesColors,
    groupByOptions,
  };
};
