// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';

import { useHelpContext } from '@analytics-context/Help';
import {
  Main,
  Title,
  Summary,
  Subtitle,
  Paragraph,
  Separator,
  ContentBlock,
  BlockQuote,
  Source,
  Sidebar,
  RelatedContent,
  Navigation,
  Image,
} from '@analytics-pages/Help/components';
import ContextSwitching from '@assets/images/educational/work_in_progress.png';

const WorkInProgress = ({ fullPage }) => {
  const { openSection, stack } = useHelpContext();
  return (
    <>
      <Navigation pages={stack} />
      <Main>
        <section>
          <Title>Work in Progress Cycle Time</Title>
          <Summary>
            This metric helps you to understand what proportion of your end-to-end delivery pipeline
            is dedicated to the actual code implementation.
          </Summary>

          <Subtitle>Why is it important?</Subtitle>
          <Paragraph>
            The code being implemented during the WIP stage, it is crucial to initiate the best
            practices from the beginning to not let potential flaws propagate through the rest of
            the pipeline. Creating a huge pull request not only will slow down your WIP cycle time
            but also will have negative impacts downstream, in the Review stage for instance.
          </Paragraph>

          <Paragraph>
            Studies have shown that software can be delivered faster by managing Work in Progress
            and product quality rather than pushing employees to work overtime. In particular, the
            main reasons for long WIP cycle times are:
          </Paragraph>

          <ContentBlock>
            <ol>
              <li>Overloaded product development queue.</li>
              <li>Overcommitment to too many products.</li>
              <li>Too many separate tasks per engineer.</li>
              <li>Too large chunks of work per engineer.</li>
            </ol>
          </ContentBlock>

          <Subtitle>How is it calculated?</Subtitle>
          <Paragraph>
            In Athenian Work in Progress Cycle Time is calculated from the 1st commit till the
            review request of a pull request. If no review has been requested and the pull request
            has some comments, the WIP stage ends at the 1st review comment, and if there is no
            review comment it ends at the last event before merging the pull request. This metric
            approximates the elapsed time the developer is actually implementing a new feature or
            fixing a bug. Indeed, the WIP stage always starts before the pull request creation.
          </Paragraph>
          <Paragraph>
            Note that the Work in Progress cycle time is calculated on the pull requests which
            completed the given stage during the time period selected. If a pull request is closed
            before entering the review process, it won't be included in the calculation for example.
          </Paragraph>

          <Separator />

          <Title>How to improve it using Athenian?</Title>
          <Paragraph>
            Athenian allows you to detect early on tasks that are taking longer than expected in the
            WIP stage. It is then worth investigating why the corresponding pull requests are still
            WIP and not asking for review. If required a decision can be made here to split a task
            into smaller instances or resolving any large blockers that appeared during the
            development.
          </Paragraph>

          <Paragraph>
            To reduce WIP Cycle Time and consequently the end-to-end Lead Time, a good practice is
            to minimize multi-tasking. Indeed, if engineers are working on more than one project at
            a time, they loose efficiency in switching context. According to Gerald Weinnberg's
            research in{' '}
            <a href="https://geraldmweinberg.com/Site/QSM_vol_1.html">
              Quality Software Management: System Thinking
            </a>
            , adding a single project in your workload makes you loose 20% of effective work time by
            project.
          </Paragraph>

          <Image src={ContextSwitching} />

          <Paragraph>
            To minimize the negative impact of multi-tasking on your WIP cycle time, teams could
            focus on the following engineering best practices:
          </Paragraph>

          <ContentBlock>
            <ol>
              <li>Commit to fewer product features at a time.</li>
              <li>Commit their code daily, shortening the feedback loop.</li>
              <li>Monitor and reduce the number of bugs over time.</li>
            </ol>
          </ContentBlock>

          <Paragraph>
            On the other side, developers need to multitask between planning, developing, reviewing,
            and fixing bugs. Interrupting any of these will increase the time to finish. As a
            consequence, work should be structured in smaller pieces to avoid the possibility of
            interruption and breaking focus.
          </Paragraph>
        </section>
        <Sidebar>
          <BlockQuote>
            "Keeping the ratio of WIP to contributors below 1.0 helps us decrease cycle Time by
            minimizing multitasking."
            <Source>SoundCloud</Source>
          </BlockQuote>

          <RelatedContent>
            <h4>Related Content</h4>
            <ul>
              <li>
                <a href="https://geraldmweinberg.com/Site/QSM_vol_1.html">
                  Quality Software Management: System Thinking
                </a>
              </li>
              <li>
                <a href="https://developers.soundcloud.com/blog/deliver-software-faster-by-managing-work-in-progress-not-by-adding-overtime">
                  Product Development Flow at SoundCloud
                </a>
              </li>
              <li>
                {fullPage ? (
                  <Link to={`/learn/knowledge/lead-time`} className="link">
                    <span>Lead Time</span>
                  </Link>
                ) : (
                  <span
                    onClick={() => openSection({ label: 'Lead Time', path: 'leadTime' })}
                    className="link"
                  >
                    Lead Time
                  </span>
                )}
              </li>
            </ul>
          </RelatedContent>
        </Sidebar>
      </Main>
    </>
  );
};

export default WorkInProgress;
