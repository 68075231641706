import React from 'react';

import ActiveContributorsPanel from './ActiveContributorsPanel';
import PullRequestsCreatedPanel from './PullRequestsCreatedPanel';
import PullRequestsCycleTimePanel from './PullRequestsCycleTimePanel';

export const BIG_PR_CYCLE_TIME_THRESHOLD = 604800; // 7 days

const InsightsSummary = () => {
  return (
    <>
      <PullRequestsCreatedPanel />
      <PullRequestsCycleTimePanel />
      <ActiveContributorsPanel />
    </>
  );
};

export default InsightsSummary;
