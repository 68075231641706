import _ from 'lodash';
import React from 'react';

import { BigText, DefaultXYTooltip } from '@analytics-components/charts/Tooltip';
import VerticalBarChart from '@analytics-components/charts/VerticalBarChart';
import { firstNRepos } from '@analytics-components/insights/Helper';
import { SimpleKPI, withUnit } from '@analytics-components/insights/KPI';
import IconPullRequest from '@assets/images/icons/IconPullRequest';
import { github, number } from '@common-services/format';
import { theme } from '@styles/theme';
import { hexToRGBA } from '@utils/colors';

const [COLOR_PRIMARY, COLOR_SECONDARY] = [
  hexToRGBA(theme.color.stage.review, 0.7),
  hexToRGBA(theme.color.neutral[40], 0.7),
];

const codeReviews = {
  title: 'Pull Requests Not Reviewed',
  description:
    'Evaluate the efficiency of a review policy by monitoring the proportion of pull requests being reviewed for each repository.',
  prefetchedDataIds: ['repos-metrics'],
  plumber: (fetchedData, cachedData) => {
    const repos = _(cachedData['repos-metrics'])
      .map((v, k) => ({
        repo: github.repoName(k),
        prs: {
          reviewed: v['reviewed'] || 0,
          notReviewed: v['not-reviewed'] || 0,
        },
      }))
      .orderBy(['prs.notReviewed', 'prs.reviewed'], ['desc', 'desc'])
      .take(firstNRepos)
      .value();

    const totalPrsReviewed = _(cachedData['repos-metrics'])
      .map((v) => v['reviewed'])
      .sum();

    const totalPrsNotReviewed = _(cachedData['repos-metrics'])
      .map((v) => v['not-reviewed'])
      .sum();

    const [leastReviewedRepo] = repos.length ? repos.slice(0, 1) : [{ prs: {} }];

    const reviewsPerRepo = repos.reduce((acc, curr) => {
      const { reviewed = 0, notReviewed = 0 } = curr.prs;
      acc[curr.repo] = reviewed + notReviewed;
      return acc;
    }, {});

    const chartData = repos.reduce(
      (acc, { repo, prs }) => {
        const [notReviewed, reviewed] = acc;
        reviewed.push({ repo, reviews: prs.reviewed || 0, tooltip: { color: COLOR_SECONDARY } });
        notReviewed.push({
          repo,
          reviews: prs.notReviewed || 0,
          tooltip: { color: COLOR_PRIMARY },
        });
        return acc;
      },
      [[], []]
    );

    const { reviewed = 0, notReviewed = 0 } = leastReviewedRepo.prs;
    const computed = {
      chartData,
      reviewsPerRepo,
      KPIsData: {
        pullRequestsReviewed: totalPrsReviewed,
        percentageReviewed: number.fixed(
          (totalPrsReviewed / (totalPrsReviewed + totalPrsNotReviewed || 1)) * 100,
          0
        ),
        leastReviewedRepo: leastReviewedRepo?.repo,
        leastReviewedPercent: number.fixed(
          (reviewed / Math.max(1, reviewed + notReviewed)) * 100,
          0
        ),
      },
      axisKeys: {
        x: 'repo',
        y: 'reviews',
      },
    };

    return {
      empty: computed.chartData.filter((v) => v.length > 0).length === 0,
      chart: {
        component: VerticalBarChart,
        params: {
          data: computed.chartData,
          extra: {
            stacked: true,
            legend: [
              { title: 'Not Reviewed', strokeWidth: 10, color: COLOR_PRIMARY },
              { title: 'Reviewed', strokeWidth: 10, color: COLOR_SECONDARY },
            ],
            axisKeys: computed.axisKeys,
            maxNumberOfTicks: 4,
            axisLabels: {
              y: 'Pull Requests',
            },
            color: [COLOR_PRIMARY, COLOR_SECONDARY],
            height: '350',
            margin: { bottom: 80 },
            tooltip: {
              align: {
                horizontal: 'auto',
                vertical: 'top',
              },
              template: DefaultXYTooltip,
              y: (v) => {
                const countPrs = v.y0 ? v.y - v.y0 : v.y;
                return (
                  <BigText
                    content={countPrs}
                    extra={`${number.fixed(
                      (countPrs / Math.max(1, computed.reviewsPerRepo[v.x])) * 100,
                      0
                    )}%`}
                    icon={<IconPullRequest color={v.tooltip.color} />}
                  />
                );
              },
            },
          },
        },
      },
      kpis: [
        {
          title: { text: 'Pull Requests reviewed', bold: true },
          subtitle: { text: 'Total' },
          component: SimpleKPI,
          params: {
            value: withUnit(computed.KPIsData.pullRequestsReviewed),
            secondary: `(${computed.KPIsData.percentageReviewed}%)`,
          },
        },
        {
          title: { text: 'Repository with the smallest', bold: true },
          subtitle: { text: 'ratio of PRs reviewed' },
          component: SimpleKPI,
          params: {
            value: computed.KPIsData.leastReviewedRepo,
            secondary: `(${computed.KPIsData.leastReviewedPercent}%)`,
          },
        },
      ],
    };
  },
};

export default codeReviews;
