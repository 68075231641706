const abbreviations = ['ci', 'wip'];

const slugify = (s) => s.toLowerCase().replace(/\s+/g, '-');
const unslugify = (s, ignoreAbbreviations = false) =>
  s
    .replaceAll('-', ' ')
    .replace(/\w\S*/g, (s) =>
      !ignoreAbbreviations && abbreviations.indexOf(s) >= 0
        ? s.toUpperCase()
        : s.charAt(0).toUpperCase() + s.substr(1).toLowerCase()
    );

export { slugify, unslugify };
