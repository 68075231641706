import { useMutation, useQuery } from 'react-query';

import { CONFIG } from '@align-constants';
import { IUseMutationHook, IUseQueryHookRest } from '@align-types/common';
import { useAuth0 } from '@common-context/Auth0';
import { useDemoCookie } from '@common-pages/Demo';
import { IApiBasicError } from '@common-services/api/common/types/common';
import {
  AcceptedInvitation,
  InvitationCheckResult,
  InvitationLink,
  InvitedUser,
} from '@common-services/api/public/generated-from-backend/models';
import { RegistrationServices } from '@common-services/api/public/services/registration';

export function useCheckInvitation(
  params: InvitationLink,
  isCached: boolean = false
): IUseQueryHookRest<InvitationCheckResult> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();

  const { isLoading, data, error } = useQuery<InvitationCheckResult, IApiBasicError>(
    ['checkInvitation', params.url],
    async () => {
      const token: string = isDemo === false ? await getTokenSilently() : null;
      return RegistrationServices.checkInvitation(params, token);
    },
    {
      ...CONFIG.API.FETCH_OPTIONS,
      cacheTime: isCached ? CONFIG.API.FETCH_OPTIONS.cacheTime : 0,
      staleTime: isCached ? CONFIG.API.FETCH_OPTIONS.staleTime : 0,
    }
  );

  return { isLoading, data, error };
}

export function useInvitationAccept(): IUseMutationHook<
  InvitedUser,
  IApiBasicError,
  AcceptedInvitation
> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();
  const invitationAccept = async (params: AcceptedInvitation): Promise<InvitedUser> => {
    const token: string = isDemo === false ? await getTokenSilently() : null;
    return RegistrationServices.putInvitationAccept(params, token);
  };

  const { mutate, isLoading, data, error, isError, isSuccess } = useMutation<
    InvitedUser,
    IApiBasicError,
    AcceptedInvitation
  >(invitationAccept);

  return { mutate, isLoading, data, error, isError, isSuccess };
}
