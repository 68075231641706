import { css } from '@emotion/react';

export const styles = ({ color, font, radius, spacing, shadow }) => css`
  background-color: ${color.neutral.white};
  border: 1px solid ${color.neutral[40]};
  border-radius: ${radius.default};
  box-shadow: ${shadow.full};
  margin-top: ${spacing.gap['02']};
  position: absolute;
  right: 0;
  z-index: 100;

  .ath-menu-item {
    color: ${color.neutral[100]};
    font-size: ${font.size.default};
    font-weight: ${font.weight.regular};
    line-height: 1.5;
    padding: ${spacing.gap['03']} ${spacing.gap['04']};

    &:hover {
      background-color: ${color.neutral[20]};
    }
  }

  .ath-divider {
    background-color: ${color.neutral[40]};
    height: 1px;
    width: 100%;
  }
`;
