import PropTypes from 'prop-types';
import React from 'react';

import ButtonWithSelector from '@analytics-components/ButtonWithSelector';
import { Button } from '@lib/Button';
import * as ControlsService from '@lib/TaggedSelector/services/controls';
import { convertTsOptionsRecursion } from '@lib/TaggedSelector/services/controls';
import { optionType } from '@lib/TaggedSelector/types';
import { AvatarType } from '@lib/avatar';
import { icons } from '@lib/icon';
import { SelectorTs } from '@lib/selector-ts';

import getStyle from './styles';

const ButtonControl = ({
  isCascading = false,
  options = [],
  initialApplied = [],
  maxSelected,
  button,
  isLoading = false,
  isLimitReached = false,
  onSelectorApply,
}) => {
  const icon = button?.icon || icons.add;
  const label = button?.label || 'Add';

  const convertedOptionsTs = React.useMemo(() => {
    if (options.length > 0) {
      const initData = options.map((opt) => {
        return {
          ...opt,
          name: opt.label,
          id: opt.value,
          isSelected: !!initialApplied.find((initial) => opt.value === initial.value),
          isOpened: !!initialApplied.find((initial) => opt.value === initial.parent),
          children: [],
        };
      });
      return isCascading ? convertTsOptionsRecursion(initData) : initData;
    }
  }, [options, initialApplied]);

  const buttonFn = (isOpen, setIsOpen) => {
    const isDisabled = isLoading || isLimitReached;
    const handleClick = isDisabled ? () => {} : () => setIsOpen(!isOpen);
    return (
      <div
        data-cy="tagged-selector-add-button"
        title={isLimitReached ? button.titleOnLimitReached : null}
      >
        <Button disabled={isDisabled} icon={icon} label={label} onClick={handleClick} />
      </div>
    );
  };

  const selectorFn = (isOpen, setIsOpen) => {
    const onCancelTs = () => {
      setIsOpen(false);
    };

    const onApplyTs = (selectedIdsArr) => {
      const converted = ControlsService.convertTsSelected(options, selectedIdsArr);
      onSelectorApply({ selected: converted, applied: converted });
      setIsOpen(false);
    };

    return (
      <SelectorTs
        optionsInit={convertedOptionsTs}
        maxSelected={maxSelected}
        onCancel={onCancelTs}
        onApply={onApplyTs}
        isCascading
      />
    );
  };

  return (
    <ButtonWithSelector buttonFn={buttonFn} selectorFn={selectorFn} width={240} alignRight={true} />
  );
};

const Controls = ({ children }) => {
  return <div css={getStyle}>{children}</div>;
};

ButtonControl.propTypes = {
  avatarType: PropTypes.oneOf(Object.values(AvatarType)),
  options: PropTypes.arrayOf(optionType),
  initialApplied: PropTypes.arrayOf(optionType),
  placeholder: PropTypes.string,
  maxSelected: PropTypes.number,
  button: PropTypes.shape({
    label: PropTypes.element,
    icon: PropTypes.string,
    titleOnLimitReached: PropTypes.string,
  }),
  isLimitReached: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSelectorCancel: PropTypes.func.isRequired,
  onSelectorApply: PropTypes.func.isRequired,
};

Controls.propTypes = {
  children: PropTypes.node,
};

export default Controls;
export { ButtonControl };
