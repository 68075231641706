import React from 'react';

import { BREADCRUMBS_MEMBERS } from '@e2e/core/testIds/align/appearance';
import { DetailTag } from '@lib/DetailTag';
import { icons } from '@lib/icon';

import { testIds } from '../__tests__/testIds.ids';
import { ITeamBadgeDetails } from '../types';
import { wrapperStyles } from './TeamBadgeDetails.styles';

export const TeamBadgeDetails: React.FC<ITeamBadgeDetails> = React.memo(({ teams, members }) => {
  const hasMembers = members !== undefined && members !== null;
  const hasTeams = teams !== undefined && teams !== null;
  return hasTeams || hasMembers ? (
    <div css={wrapperStyles}>
      {hasMembers && (
        <span data-pw={BREADCRUMBS_MEMBERS.MEMBERS} data-testid={testIds.detailUsers}>
          <DetailTag count={members} text="member" icon={icons.person} />
        </span>
      )}
      {hasTeams && (
        <span data-pw={BREADCRUMBS_MEMBERS.TEAMS} data-testid={testIds.detailTeams}>
          <DetailTag count={teams} text="team" icon={icons.people} />
        </span>
      )}
    </div>
  ) : null;
});
