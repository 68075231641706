import React from 'react';

import TimeSeries from '@analytics-components/charts/TimeSeries';
import { BigText, DateRangeBigNumber } from '@analytics-components/charts/Tooltip';
import { ChartType } from '@analytics-components/insights/Box/types';
import { GranularityEnum } from '@analytics-constants';
import { ValueType } from '@analytics-pages/delivery-pipeline/insights/deploy/services/types';
import { IntervalType, LooseObject } from '@analytics-types/common';
import { dateTime } from '@common-services/dateService';
import { theme } from '@styles/theme';
import { hexToRGBA } from '@utils/colors';

const chart = (
  env: string,
  granularity: GranularityEnum,
  interval: IntervalType,
  values: ValueType[]
): ChartType => {
  const tooltip: LooseObject = {
    align: {
      horizontal: 'auto',
      vertical: 'top',
    },
    renderBigFn: (v) => <BigText content={dateTime.human(v.y)} />,
  };
  if ([GranularityEnum.MONTH, GranularityEnum.WEEK].indexOf(granularity) >= 0) {
    tooltip.template = DateRangeBigNumber;
    tooltip.interval = { ...interval };
    tooltip.granularity = granularity;
  }
  return {
    component: TimeSeries,
    description: '',
    title: `PR Cycle Time to ${env}`,
    params: {
      data: values,
      timeMode: true,
      extra: {
        axisKeys: {
          x: 'date',
          y: 'value',
        },
        color: theme.color.stage.deploy,
        fillColor: hexToRGBA(theme.color.stage.deploy, 0.1),
        maxNumberOfTicks: 4,
        ticks: {
          x: {
            tickFormat: granularity === GranularityEnum.MONTH ? dateTime.month : dateTime.monthDay,
          },
        },
        tooltip,
      },
    },
  };
};

export default chart;
