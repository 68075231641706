import { useLocalStorage } from '@rehooks/local-storage';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import { END_DATE, START_DATE } from 'react-dates/constants';
import 'react-dates/initialize';

import Checkbox, { SELECTION_STATE } from '@analytics-components/Checkbox';
import { Datetime, DatetimeService } from '@analytics-services/datetimeService';

import FilterFooter from './FilterFooter';
import LeftInfoPanel from './LeftInfoPanel';
import { IMomentDateInterval } from './dateInterval.types';
import { datePickerWrapperStyles } from './styles';

const isInRange = (candidate, lower, upper) =>
  candidate.isSameOrBefore(upper) && candidate.isSameOrAfter(lower);

/**
 * validateOrFixFn returns a function that calls resetFn if the passed dateInterval is empty,
 * or it will run fixFn over the field that could be missing in dateInterval; in both cases
 * it will also return false; otherwise it will return true.
 * @param {()=>{}} resetFn Function to be called in case the passed dateInterval is empty.
 * @param {(string)=>{}} fixFn Function to be called in case the passed dateInterval lacks of any field.
 * @returns {(<moment>)=>bolean}
 */
const validateOrFixFn = (resetFn, fixFn) => (dateInterval) => {
  if (!dateInterval.startDate && !dateInterval.endDate) {
    resetFn();
    return false;
  }

  if (!dateInterval.startDate) {
    fixFn(START_DATE);
    return false;
  } else if (!dateInterval.endDate) {
    fixFn(END_DATE);
    return false;
  }

  return true;
};

const isValidInterval = ({ startDate, endDate }) => startDate && endDate;

const isSameDateInterval = (momentA, momentB) => {
  return momentA.startDate.isSame(momentB.startDate) && momentA.endDate.isSame(momentB.endDate);
};

const defaultDateInterval = {
  startDate: moment(DatetimeService.Defaults.FROM.asTimestamp()),
  endDate: moment(DatetimeService.Defaults.TO.asTimestamp()),
};

// TODO: Make this component work with `DatetimeService.interval`
export default function DateInterval({
  minDate = moment(DatetimeService.Presets.OLDEST.asTimestamp()),
  maxDate = moment(DatetimeService.Presets.TODAY.asTimestamp()),
  initialFrom = defaultDateInterval.startDate,
  initialTo = defaultDateInterval.endDate,
  onChange,
  isExcludeInactive,
  onExcludeInactive,
  persistentKey,
}) {
  // eslint-disable-next-line no-unused-vars
  const [last, setLast] = useLocalStorage(persistentKey);

  const initialDateInterval = {
    startDate: initialFrom,
    endDate: initialTo,
  };
  const [dateIntervalState, setDateIntervalState] = useState(initialDateInterval);
  const [prevDateIntervalState, setPrevDateIntervalState] = useState(initialDateInterval);

  const [focusedInputState, setFocusedInputState] = useState(null);
  const [validState, setValidState] = useState(true);
  const [presetIntervalActive, setPresetIntervalActive] = useState(false);
  const excludeInactive = useRef(isExcludeInactive);

  const validateOrFix = validateOrFixFn(
    () => setDateIntervalState(initialDateInterval),
    setFocusedInputState
  );

  // It will only call the passed onChange callback if the process of changing it is finished
  // (the calendar is not opened, and the date interval is valid) and if the date interval changed.
  useEffect(() => {
    if (focusedInputState) {
      // the calendar is opened (the changing process didn't finished)
      setValidState(isValidInterval(dateIntervalState));
      return;
    }

    if (
      !validateOrFix(dateIntervalState) || // the date interval lacks of any field (e.g. the calendar is closed after choosing start and end dates)
      (isSameDateInterval(dateIntervalState, prevDateIntervalState) && // when the date interval has not changed
        excludeInactive.current === isExcludeInactive) // enable/disable stalled PRs
    ) {
      return;
    }
    excludeInactive.current = isExcludeInactive;

    setPrevDateIntervalState(dateIntervalState);
    onChange(
      DatetimeService.interval(
        new Datetime(dateIntervalState.startDate.startOf('day')),
        new Datetime(dateIntervalState.endDate.startOf('day'))
      )
    );
  }, [
    dateIntervalState,
    prevDateIntervalState,
    validateOrFix,
    focusedInputState,
    onChange,
    isExcludeInactive,
  ]);

  const cancel = () => {
    setDateIntervalState(prevDateIntervalState);
    setLast(
      JSON.stringify({
        from: prevDateIntervalState.startDate.utc().format(),
        to: prevDateIntervalState.endDate.utc().format(),
      })
    );
    setFocusedInputState(null);

    if (excludeInactive.current !== isExcludeInactive) {
      onExcludeInactive(excludeInactive.current);
    }
  };

  const isOutside = (day) =>
    !isInRange(day.clone().utcOffset('+00:00', true).startOf('day'), minDate, maxDate);

  const onDatesChange = useCallback(
    (dateInterval: IMomentDateInterval) => {
      let newDateInterval: IMomentDateInterval;
      if (!dateInterval.endDate && dateInterval.startDate) {
        if (dateInterval.startDate < dateIntervalState.startDate) {
          newDateInterval = {
            startDate: dateInterval.startDate,
            endDate: dateIntervalState.endDate,
          };
        } else if (dateInterval.startDate > dateIntervalState.endDate) {
          newDateInterval = {
            startDate: dateIntervalState.startDate,
            endDate: dateInterval.startDate,
          };
        }
      } else {
        newDateInterval = isValidInterval(dateInterval) ? dateInterval : defaultDateInterval;
      }
      setDateIntervalState(newDateInterval);
      setLast(
        JSON.stringify({
          from: newDateInterval.startDate.utc().format('YYYY-MM-DDT00:00:00'),
          to: newDateInterval.endDate.utc().format('YYYY-MM-DDT00:00:00'),
        })
      );
      setPresetIntervalActive(false);
    },
    [dateIntervalState]
  );

  const setPresetInterval = useCallback((getStartDateFn) => {
    const startDate = moment(getStartDateFn()).utcOffset('+00:00', true).startOf('day');
    const endDate = moment(new Date()).utcOffset('+00:00', true).startOf('day');
    setDateIntervalState({
      startDate,
      endDate,
    });
    setLast(
      JSON.stringify({
        from: startDate.utc().format(),
        to: endDate.utc().format(),
      })
    );
    setPresetIntervalActive(true);
  }, []);

  return (
    <div id="date-picker" data-cy="date-picker" css={datePickerWrapperStyles}>
      <DateRangePicker
        startDate={dateIntervalState.startDate}
        endDate={dateIntervalState.endDate}
        minDate={minDate}
        maxDate={maxDate.clone().add(1, 'day')}
        //behavior
        minimumNights={0}
        keepOpenOnDateSelect
        reopenPickerOnClearDates={false}
        showClearDates
        initialVisibleMonth={() => moment(dateIntervalState.endDate).subtract(1, 'month')}
        renderCalendarInfo={() => (
          <>
            <LeftInfoPanel isActive={presetIntervalActive} setInterval={setPresetInterval} />
            <FilterFooter
              extra={
                <Checkbox
                  label="Include stalled pull requests"
                  selectionState={
                    isExcludeInactive ? SELECTION_STATE.NOT_SELECTED : SELECTION_STATE.SELECTED
                  }
                  onClick={onExcludeInactive}
                  info="Include open pull requests which do not show any activity during the selected timeframe"
                />
              }
              onCancel={cancel}
              onApply={() => setFocusedInputState(null)}
              isAcceptable={validState}
            />
          </>
        )}
        //Look and feel
        firstDayOfWeek={0}
        anchorDirection="right"
        displayFormat="MMM Do, YYYY"
        endDatePlaceholderText="End Date"
        startDatePlaceholderText="Start Date"
        showDefaultInputIcon
        hideKeyboardShortcutsPanel
        customArrowIcon="-"
        small
        daySize={30}
        //Internals
        onDatesChange={onDatesChange}
        onFocusChange={setFocusedInputState}
        focusedInput={focusedInputState}
        startDateId="dateIntervalFrom"
        endDateId="dateIntervalTo"
        isOutsideRange={isOutside}
      />
    </div>
  );
}
