import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAllocationContext } from '@analytics-context/Allocation';
import { useHistory } from '@common-hooks/useHistory';
import { Button, ButtonType } from '@lib/Button';
import TaggedSelector from '@lib/TaggedSelector';
import { AvatarType } from '@lib/avatar';

import { extraControlStyles } from './styles';

const MAX_SELECTED_WORK_TYPES = 5;

const IssueSelector = () => {
  const history = useHistory();
  const location = useLocation();
  const { appliedWorkTypes, workTypes, workTypesReady, onWorkTypesChange } = useAllocationContext();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!!appliedWorkTypes?.length) {
      setSelectedOptions(
        appliedWorkTypes.map((v) => ({ color: `#${v.color}`, label: v.name, value: v.name }))
      );
      setIsLoading(false);
    } else if (workTypesReady) {
      setIsLoading(false);
    }
  }, [appliedWorkTypes, workTypesReady]);

  const handleChange = useCallback(
    (newOptions) => {
      setIsLoading(true);
      if (!_.isEqual(newOptions, selectedOptions)) {
        const newSelectedOptions = workTypes.filter((item) =>
          newOptions.find((option) => option.value === item.name)
        );
        setSelectedOptions(
          newSelectedOptions.map((v) => ({ color: `#${v.color}`, label: v.name, value: v.name }))
        );
        if (!_.isEqual(newSelectedOptions, appliedWorkTypes)) {
          onWorkTypesChange(newSelectedOptions);
        }
      }
      setIsLoading(false);
    },
    [appliedWorkTypes, selectedOptions, workTypes]
  );

  const options = useMemo(
    () => workTypes.map((v) => ({ color: `#${v.color}`, label: v.name, value: v.name })),
    [workTypes]
  );

  const extraControl = (
    <div css={extraControlStyles}>
      <Button
        label="Manage Work Types"
        type={ButtonType.TEXT}
        onClick={() => history.push('/settings/allocation', { from: location.pathname })}
      />
    </div>
  );

  return (
    <TaggedSelector
      name={'allocation'}
      avatarType={AvatarType.dot}
      button={{
        titleOnLimitReached: `You reached the maximum of ${MAX_SELECTED_WORK_TYPES} work categories`,
      }}
      extraControl={extraControl}
      initialApplied={selectedOptions}
      isLoading={isLoading}
      maxSelected={MAX_SELECTED_WORK_TYPES}
      placeholder="Add work types to see Allocation insights"
      options={options}
      onAppliedChange={handleChange}
    />
  );
};

export default IssueSelector;
