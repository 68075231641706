import React from 'react';

import { Maintenance } from '@lib/empty/maintenance';

const MaintenancePrototypes = () => {
  return (
    <div className="mt-2">
      <Maintenance />
    </div>
  );
};

export default MaintenancePrototypes;
