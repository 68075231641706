import React, { useCallback } from 'react';

import { wrapperStyles } from './circle.styles';
import { ICircleProps } from './circle.types';

export const Circle: React.FC<ICircleProps> = React.memo(
  ({ active = false, children, color, size = 12, onClick }) => {
    const onClickHandler = useCallback(
      (event) => {
        if (onClick) onClick(event, color);
      },
      [onClick]
    );
    return (
      <div
        css={wrapperStyles({ circleColor: color, size: size, isOnClick: Boolean(onClick) })}
        onClick={onClickHandler}
      >
        {active && <div className="ath-outer-circle" />}
        {children}
      </div>
    );
  }
);
