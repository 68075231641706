import { css } from '@emotion/react';

export const wrapperStyles = ({ color, font, spacing }) => css`
  align-items: center;
  border-bottom: 1px solid ${color.neutral['40']};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.gap['06']};

  .timeframe {
    align-items: center;
    display: flex;

    .timeframe-label {
      color: ${color.text.dark};
      font-size: ${font.size.default};
      font-weight: ${font.weight.regular};
      margin-right: ${spacing.gap['04']};
    }
  }

  .hint-sentence {
    color: ${color.text.secondary};
    font-size: ${font.size.default};
    font-weight: ${font.weight.regular};
  }
`;
