import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';

import { useExtendedState } from '@analytics-hooks';
import { AvatarType } from '@lib/avatar';

import { optionType } from './types';
import { default as TaggedSelectorUI } from './ui';

/**
 *  Component for displaying a container with the selected items as tags and a button for opening a selector.
 */
const TaggedSelector = ({
  isCascading,
  options = [],
  initialApplied = [],
  placeholder = '',
  button,
  isLoading = false,
  colors = [],
  avatarType,
  maxSelected,
  useStorage = false,
  extraControl,
  storageKey = '',
  onAppliedChange,
}) => {
  const props = {
    isCascading,
    options,
    initialApplied,
    placeholder,
    button,
    isLoading,
    colors,
    avatarType,
    maxSelected,
    extraControl,
    useStorage,
    storageKey,
    onAppliedChange,
  };

  const Component = isLoading ? DummyTaggedSelector : TaggedSelectorReady;
  return <Component {...props} />;
};

const TaggedSelectorReady = ({
  isCascading,
  options = [],
  initialApplied = [],
  placeholder = '',
  button,
  colors = [],
  avatarType,
  maxSelected,
  extraControl,
  useStorage = false,
  storageKey = '',
  onAppliedChange,
}) => {
  const computeColorsDistribution = (opts, currentColorsMapping = {}) => {
    const appliedValue = _(opts).map('value').value();
    const newlyAppliedColorsMapping = _(appliedValue)
      .map((v) => [v, ''])
      .fromPairs()
      .value();
    const previouslyAppliedColors = _(currentColorsMapping || {})
      .pick(appliedValue)
      .value();
    const filteredAppliedColors = { ...newlyAppliedColorsMapping, ...previouslyAppliedColors };
    const optsWithoutColor = _(filteredAppliedColors)
      .map((v, k) => [k, v])
      .filter((mapping) => mapping[1] === '')
      .map((mapping) => mapping[0])
      .value();
    const unassignedColors = _(colors)
      .difference(_(filteredAppliedColors).values().value())
      .value();
    const newItemColorMapping = _(optsWithoutColor).zipObject(unassignedColors).value();
    return { ...filteredAppliedColors, ...newItemColorMapping };
  };

  const [appliedOptions, setAppliedOptions] = useExtendedState(initialApplied, {
    useStorage,
    storageKey,
  });
  const [appliedColors, setAppliedColors] = useState(computeColorsDistribution(appliedOptions));

  useEffect(() => {
    if (!!initialApplied?.length && !appliedOptions?.length) {
      setAppliedOptions(initialApplied);
    }
  }, [initialApplied]);

  useEffect(() => {
    if (!!onAppliedChange) {
      onAppliedChange(appliedOptions, appliedColors);
    }
  }, [appliedOptions]);

  const applyOptionsAndDistributeColors = useCallback(
    (opts) => {
      const colorsDistribution = computeColorsDistribution(opts, appliedColors);
      setAppliedColors(colorsDistribution);
      setAppliedOptions(opts);
    },
    [appliedColors, setAppliedOptions, setAppliedColors]
  );

  const isLimitReached = maxSelected > 0 && appliedOptions.length >= maxSelected;

  const onSelectorCancel = ({ applied }) => applyOptionsAndDistributeColors(applied);
  const onSelectorApply = ({ applied }) => applyOptionsAndDistributeColors(applied);
  const onRemove = (item) => {
    const newAppliedOptions = _(appliedOptions)
      .filter((opt) => opt.value !== item.value)
      .value();
    applyOptionsAndDistributeColors(newAppliedOptions);
  };

  return (
    <TaggedSelectorUI
      isCascading={isCascading}
      options={options}
      appliedOptions={_(appliedOptions)
        .map((opt) => ({ ...opt, color: opt.color || appliedColors[opt.value] }))
        .value()}
      placeholder={placeholder}
      maxSelected={maxSelected}
      button={button}
      isLoading={false}
      isLimitReached={isLimitReached}
      avatarType={avatarType}
      extraControl={extraControl}
      onRemove={onRemove}
      onSelectorCancel={onSelectorCancel}
      onSelectorApply={onSelectorApply}
    />
  );
};

const dummyFunc = () => {};

const DummyTaggedSelector = ({ placeholder = '', button }) => {
  return (
    <TaggedSelectorUI
      options={[]}
      appliedOptions={[]}
      placeholder={placeholder}
      button={button}
      isLoading={true}
      onRemove={dummyFunc}
      onSelectorCancel={dummyFunc}
      onSelectorApply={dummyFunc}
    />
  );
};

TaggedSelector.propTypes = {
  options: PropTypes.arrayOf(optionType),
  initialApplied: PropTypes.arrayOf(optionType),
  placeholder: PropTypes.string,
  button: PropTypes.shape({
    label: PropTypes.element,
    icon: PropTypes.string,
    titleOnLimitReached: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  colors: PropTypes.arrayOf(PropTypes.string),
  avatarType: PropTypes.oneOf(Object.values(AvatarType)),
  maxSelected: PropTypes.number,
  useStorage: PropTypes.bool,
  storageKey: (props, propName, componentName, ...rest) => {
    const validator = props.useStorage
      ? () => {
          const err = PropTypes.string.isRequired(props, propName, componentName, ...rest);
          if (err) {
            return err;
          }

          if (props[propName].length === 0) {
            return new Error(`The prop '${propName}' has to have a length > 0`);
          }

          return null;
        }
      : PropTypes.string;
    return validator(props, propName, componentName, ...rest);
  },
  extraControl: PropTypes.elementType,
  onAppliedChange: PropTypes.func,
};

export default TaggedSelector;
