import React from 'react';

import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import Panel from '@analytics-components/insights/Panel';
import { prepareChartBoxes } from '@analytics-pages/delivery-pipeline/insights/helper';

import flakinessTimeline from './flakinessTimeline';

const meta = {
  title: 'Flakiness - Timeline',
  description:
    'Percentage of check suites triggered by the same commit but having different outcomes: one successful and one failed.',
};

const chartBoxes = [
  {
    id: 'flakiness-timeline',
    component: ChartBoxWithKPIWidget,
    params: flakinessTimeline,
  },
];

const FlakinessTimelinePanel = () => {
  const preparedChartBoxes = prepareChartBoxes(chartBoxes);
  return <Panel meta={meta} chartBoxes={preparedChartBoxes} />;
};

export default FlakinessTimelinePanel;
