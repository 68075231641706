import React from 'react';

import { activeGoalDetailsStyles } from '@align-pages/goals/list/active-goals/components/active-goal-details/activeGoalDetails.styles';
import { Avatar, AvatarType } from '@lib/avatar';

import { IActiveGoalDetails } from './activeGoalDetails.types';

export const ActiveGoalDetails: React.FC<IActiveGoalDetails> = React.memo(
  ({ teamName, avatarColor, timeFrame, level }) => {
    return (
      <div css={activeGoalDetailsStyles}>
        <div className="team-name">
          <Avatar label={teamName} type={AvatarType.text} color={avatarColor} />
          {teamName}
        </div>
        {!level && <div>{timeFrame}</div>}
      </div>
    );
  }
);
