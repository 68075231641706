import { css } from '@emotion/react';

export const wrapperStyles = css`
  display: flex;
  justify-content: center;
`;

export const dashboardStyles = ({ spacing }) => css`
  display: grid;
  gap: ${spacing.gap['06']};
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 1430px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 2120px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
