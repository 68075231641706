import { css } from '@emotion/react';

export const wrapperStyles = ({ color, font, spacing }) => css`
  text-align: center;

  .text {
    color: ${color.text.secondary};
    font-size: ${font.size.m};
    font-weight: ${font.weight.regular};
    margin-top: ${spacing.gap['06']};
  }
`;
