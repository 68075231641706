import { truncate } from '@common-services/vendor/lodash';

export function truncateText(txt: string, length: number, tail: string = '...'): string {
  if (!txt || !length) return null;
  const res: string = truncate(txt, {
    length: length,
    omission: tail,
  });
  return res;
}

export function capitalizeFirstLetter(txt: string): string {
  if (!txt) return null;
  return txt.charAt(0).toUpperCase() + txt.slice(1);
}

export * as StringService from './stringService';
