import { BasicApiClass } from '@common-services/api/common/BasicApiClass';
import { IUrlParams, REST_TYPES } from '@common-services/api/common/types/apiClient';
import { ApiMode } from '@common-services/api/common/types/common';
import { ISentryMethodParams } from '@common-services/api/common/types/sentry';
import { PUBLIC_API_ENDPOINTS_CONSTANTS } from '@common-services/api/public/constants';
import { GoalTemplate } from '@common-services/api/public/generated-from-backend/models';

export class GoalTemplatesApi extends BasicApiClass {
  constructor() {
    super(ApiMode.PUBLIC);
  }
  public getGoalTemplates(
    urlParams: IUrlParams<'accountId'>,
    token: string,
    sentry?: ISentryMethodParams
  ): Promise<GoalTemplate[]> {
    const apiPath: string = this.replaceRouteLink(
      PUBLIC_API_ENDPOINTS_CONSTANTS.GOAL_TEMPLATES,
      urlParams
    );
    this.assignToken(token);
    const callApi = this.callApi<GoalTemplate[]>(apiPath + '?include_tlo=true', REST_TYPES.GET);
    return sentry ? this.callSentry<GoalTemplate[]>(callApi, sentry) : callApi;
  }
  public getGoalTemplate(
    urlParams: IUrlParams<'goalId'>,
    token: string,
    sentry?: ISentryMethodParams
  ): Promise<GoalTemplate> {
    const apiPath: string = this.replaceRouteLink(
      PUBLIC_API_ENDPOINTS_CONSTANTS.GOAL_TEMPLATE,
      urlParams
    );
    this.assignToken(token);
    const callApi = this.callApi<GoalTemplate>(apiPath, REST_TYPES.GET);
    return sentry ? this.callSentry<GoalTemplate>(callApi, sentry) : callApi;
  }
}
