import { useMemo } from 'react';

import { metricsConfig } from '@align-constants';
import { useSearchedIssues } from '@align-hooks/useSearchedIssues';
import { ITemplateMetric } from '@align-services/api/types/goalsTypes';
import { DateService as AlignDateService } from '@align-services/dateService';
import { IDateRangeInMls, PrsIssuesTableTabs } from '@align-types/common';
import { IUseGetSearchedIssues } from '@align-types/issues';
import { Goal } from '@common-services/api/private/generated-from-backend/models';
import { useGetAccountDetails } from '@common-services/api/public/hooks/useGetAccountData';
import { DateService } from '@common-services/dateService';

export function useGetSearchedIssues(
  accountId: number,
  curGoalData: Goal,
  templateMetric: ITemplateMetric,
  activeTab: PrsIssuesTableTabs,
  selectedRange: IDateRangeInMls
): IUseGetSearchedIssues {
  const isGoalComplete = useMemo(() => AlignDateService.isBeforeToday(curGoalData?.expires_at), [
    curGoalData,
  ]);
  const { data: accountDetailsData, isLoading: accountDetailsLoading } = useGetAccountDetails(true);
  const isJiraDataInstalled = useMemo(() => {
    if (accountDetailsData?.datasources) return accountDetailsData?.datasources.includes('jira');
  }, [accountDetailsData]);
  const { data, isLoading: searchedIssuesIsLoading, error } = useSearchedIssues(
    {
      account: accountId,
      date_from: selectedRange
        ? DateService.formatDate(selectedRange.dateFrom, 'YYYY-MM-DD')
        : curGoalData?.valid_from,
      date_to: selectedRange
        ? DateService.formatDate(selectedRange.dateTo, 'YYYY-MM-DD')
        : curGoalData?.expires_at,
      filter: {
        issue_types: curGoalData?.jira_issue_types || [],
        projects: curGoalData?.jira_projects || [],
        priorities: curGoalData?.jira_priorities || [],
        ...(activeTab === PrsIssuesTableTabs.IN_PROGRESS
          ? { status_categories: metricsConfig[templateMetric]?.inProgressStages || [] }
          : {}),
      },
      with: {
        assignees: [`{${curGoalData?.team_goal.team.id}}`],
      },
      order_by: [
        {
          field:
            activeTab === PrsIssuesTableTabs.COMPLETED
              ? metricsConfig[templateMetric]?.mainKPI
              : metricsConfig[templateMetric]?.inProgressOrderBy,
          direction:
            activeTab === PrsIssuesTableTabs.IN_PROGRESS &&
            metricsConfig[templateMetric]?.inProgressOrderAscending
              ? 'ascending'
              : 'descending',
        },
      ],
    },
    true,
    (activeTab === PrsIssuesTableTabs.IN_PROGRESS && isGoalComplete) || // do not fetch PRs for In Progress tab if the goal has expired
      !accountDetailsData ||
      !isJiraDataInstalled ||
      !metricsConfig[templateMetric]?.jiraMetricField
  );
  const isLoading = useMemo(() => {
    return searchedIssuesIsLoading || accountDetailsLoading;
  }, [searchedIssuesIsLoading, accountDetailsLoading]);
  return { data, isLoading, error };
}
