import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTable, useSortBy, usePagination, useExpanded } from 'react-table';
import ReactTooltip from 'react-tooltip';

import TimeSeries from '@analytics-components/charts/TimeSeries';
import { BigText } from '@analytics-components/charts/Tooltip';
import TablePagination from '@analytics-components/tables/TablePagination';
import { TableSearch } from '@analytics-components/tables/table-search';
import { dateTime } from '@common-services/dateService';
import { number } from '@common-services/format';
import { Table } from '@lib/Table';
import { NoData } from '@lib/empty/noData';
import { Icon, icons } from '@lib/icon';
import { theme } from '@styles/theme';
import Chevron from '@ui/Chevron';
import { ProgressBarLine } from '@ui/ProgressBar';
import { hexToRGBA } from '@utils/colors';

import { chartsStyles, progressColumnStyles, searchStyles, titleStyles } from './styles';

const CellEpicTitle = (row) => {
  const { onClick } = row.getToggleRowExpandedProps();

  return (
    <div css={titleStyles}>
      <div className="chevron">
        <Chevron isExpanded={row.isExpanded} onClick={onClick} />
      </div>
      <Icon color={theme.color.neutral[80]} icon={icons.code_check} size={14} />
      <div className="check-title">
        <a
          className="check-link"
          href={`https://${row.original.repo}`}
          rel="noreferrer"
          target="_blank"
        >
          {row.original.repoName}
        </a>{' '}
        <a
          className="check-link"
          data-tip
          data-for={`tooltip-${row.original.title}`}
          href={row.original.url}
          rel="noreferrer"
          target="_blank"
        >
          <span className="check-name">{row.original.title}</span>
        </a>
        <ReactTooltip
          className="athenian-tooltip"
          id={`tooltip-${row.original.title}`}
          effect="solid"
          place="bottom"
          type="light"
          getContent={() => (
            <div className="check-link">
              {row.original.repoName} <span className="check-name">{row.original.title}</span>
            </div>
          )}
        />
        <div className="check-subtitle">
          Last run <span className="age">{dateTime.human(row.original.lastRun)} ago</span>
        </div>
      </div>
    </div>
  );
};

const progressColumn = (value, icon, color) => {
  return (
    <>
      {value !== null ? (
        <div css={progressColumnStyles}>
          {icon}
          <div className="progress-details">
            {number.percentage(value, 1)}
            <div className="progress-line">
              <ProgressBarLine color={color} height={4} value={value} />
            </div>
          </div>
        </div>
      ) : (
        '-'
      )}
    </>
  );
};

const columns = [
  {
    Header: 'GitHub Checks | Last Run',
    accessor: 'lastRun',
    Cell: ({ row }) => CellEpicTitle(row),
  },
  {
    Header: 'Completed',
    accessor: 'total',
  },
  {
    Header: 'Success Ratio',
    accessor: 'successRatio',
    Cell: ({ row }) =>
      progressColumn(
        row.original.successRatio,
        <Icon color={theme.color.status.success} icon={icons.check_succeed} size={14} />,
        theme.color.status.success
      ),
    sortType: (a, b) => (a.original.successRatio > b.original.successRatio ? 1 : -1),
  },
  {
    Header: 'Run Time',
    accessor: 'runTime',
    Cell: ({ row }) =>
      row.original.runTime !== null ? dateTime.timeValue(row.original.runTime) : '-',
  },
  {
    Header: 'Skipped',
    accessor: 'skipped',
    Cell: ({ row }) =>
      progressColumn(
        row.original.skipped,
        <Icon color={theme.color.neutral[60]} icon={icons.check_skipped} size={14} />,
        theme.color.neutral[80]
      ),
    sortType: (a, b) => (a.original.skipped > b.original.skipped ? 1 : -1),
  },
  {
    Header: 'Flakiness',
    accessor: 'flakiness',
    Cell: ({ row }) =>
      row.original.flakiness !== null ? number.percentage(row.original.flakiness, 1) : '-',
    sortType: (a, b) => (a.original.flakiness > b.original.flakiness ? 1 : -1),
  },
];

const ChecksTable = ({ data, searchedString, onSearch }) => {
  const [tableRows, setTableRows] = useState([]);
  const {
    getTableProps,
    getTableBodyProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageCount,
    rows,
    prepareRow,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    visibleColumns,
  } = useTable(
    {
      autoResetExpanded: false, // this prop will preserve expanded rows from collapsing when data changes
      columns,
      data,
      initialState: {
        pageIndex: 0,
      },
      paginateExpandedRows: false,
    },
    useSortBy,
    useExpanded,
    usePagination
  );

  useEffect(() => {
    _(page).forEach(prepareRow);
    setTableRows([...page]);
  }, [page]);

  const renderRowSubComponent = useCallback(({ row }) => {
    const tickFormat = row.original.granularity === 'month' ? dateTime.month : dateTime.monthDay;
    const successRatioTooltip = {
      align: {
        horizontal: 'auto',
        vertical: 'top',
      },
      renderBigFn: (v) => <BigText content={number.percentage(v.y * 100, 1)} />,
    };
    const runTimeTooltip = {
      align: {
        horizontal: 'auto',
        vertical: 'top',
      },
      renderBigFn: (v) => <BigText content={dateTime.timeValue(v.y)} />,
    };
    if (_(['month', 'week']).includes(row.original.granularity)) {
      successRatioTooltip.dateRange = { ...row.original.interval };
      successRatioTooltip.granularity = row.original.granularity;
      runTimeTooltip.dateRange = { ...row.original.interval };
      runTimeTooltip.granularity = row.original.granularity;
    }
    let successRatioData = row.original.successRatioTimeline;
    let runTimeData = row.original.runTimeTimeline;
    if (row.original.granularity === 'month') {
      // remove last item since it's redundant and is returned by API as such
      successRatioData = successRatioData.slice(0, -1);
      runTimeData = runTimeData.slice(0, -1);
    }

    const successRatioParams = {
      data: successRatioData,
      empty: !successRatioData.length || !successRatioData.some((v) => v.value !== null),
      timeMode: false,
      extra: {
        average: {
          value: row.original.successRatio,
          color: theme.color.neutral[80],
          renderFn: successRatioTooltip.renderBigFn,
        },
        axisKeys: {
          x: 'date',
          y: 'value',
        },
        color: theme.color.ui.lightblue[100],
        fillColor: hexToRGBA(theme.color.ui.lightblue[100], 0.1),
        height: 200,
        margin: {
          left: 40,
        },
        maxNumberOfTicks: 10,
        ticks: { x: { tickFormat }, y: { tickFormat: (v) => `${v * 100}%` } },
        tooltip: successRatioTooltip,
      },
    };
    const runTimeParams = {
      data: runTimeData,
      empty: !runTimeData.length || !runTimeData.some((v) => v.value !== null),
      timeMode: false,
      extra: {
        average: {
          value: row.original.runTime,
          color: theme.color.neutral[80],
          renderFn: runTimeTooltip.renderBigFn,
        },
        axisKeys: {
          x: 'date',
          y: 'value',
        },
        color: theme.color.ui.lightblue[100],
        fillColor: hexToRGBA(theme.color.ui.lightblue[100], 0.1),
        height: 200,
        margin: {
          left: 40,
        },
        maxNumberOfTicks: 10,
        ticks: {
          x: { tickFormat },
          y: { tickFormat: (v) => dateTime.timeValue(v) },
        },
        tooltip: runTimeTooltip,
      },
    };

    return (
      <div css={chartsStyles}>
        <div className="chart">
          <div className="chart-title">Success Ratio</div>
          {successRatioParams.empty ? (
            <div className="no-data-wrapper">
              <NoData textOnly />
            </div>
          ) : (
            <TimeSeries {...successRatioParams} />
          )}
        </div>
        <div className="chart">
          <div className="chart-title">Check Runtime</div>
          {runTimeParams.empty ? (
            <div className="no-data-wrapper">
              <NoData textOnly />
            </div>
          ) : (
            <TimeSeries {...runTimeParams} />
          )}
        </div>
      </div>
    );
  }, []);

  return (
    <>
      {onSearch && (
        <TableSearch
          css={searchStyles}
          placeholder="Search..."
          value={searchedString}
          onFilter={onSearch}
        />
      )}
      <Table
        headerGroups={headerGroups}
        renderRowSubComponent={renderRowSubComponent}
        rows={tableRows}
        tableProps={getTableProps()}
        tableBodyProps={getTableBodyProps()}
        visibleColumns={visibleColumns}
      />
      <TablePagination
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        total={rows.length}
        gotoPage={gotoPage}
        gotoNextPage={nextPage}
        gotoPrevPage={previousPage}
        setPageSize={setPageSize}
      />
    </>
  );
};

export default ChecksTable;
