import { useCallback, useState } from 'react';
import { useMemo } from 'react';

import { ITableFilterOption } from '@analytics-components/tables/table-filter/tableFilter.types';
import { prLabel, PR_STAGE } from '@common-services/prHelpers';
import { throttle } from '@common-services/vendor/lodash';
import { IPrsTableRow } from '@lib/Table/components/pr-table/prTable.types';

import { searchTerm } from '../services/search';

interface IUseSearchProps {
  allPrs: IPrsTableRow[];
  currentFilter: ITableFilterOption;
  stage: PR_STAGE;
  setFilteredPrs: (prs: IPrsTableRow[]) => void;
}

export const useSearch = ({ allPrs, currentFilter, stage, setFilteredPrs }: IUseSearchProps) => {
  const [searchedString, setSearchedString] = useState('');

  const prsAfterSearch = useMemo(
    () => (
      prs: IPrsTableRow[],
      string: string,
      filterValue: ITableFilterOption,
      activeStage: PR_STAGE
    ) => searchTerm(prs, string, filterValue, activeStage),
    []
  );

  const throttledSearch = throttle((string: string) => {
    const filteredData = prsAfterSearch(allPrs, string, currentFilter, stage);
    setFilteredPrs(filteredData);
  }, 300);

  const handleSearch = useCallback(
    (string: string) => {
      setSearchedString(string);
      if (string?.length > 1) {
        throttledSearch(string);
      } else {
        // in case searched string is empty or too short initialize it back to full data
        // using timeout to prevent clash in setting React state
        const timeoutId = setTimeout(
          () => {
            let filteredData = allPrs;
            if (currentFilter) {
              // if there is a filter set, apply it
              filteredData = filteredData.filter(
                (pr) => prLabel(stage)(pr) === currentFilter.value
              );
            }
            setFilteredPrs(filteredData);
          },
          0,
          () => clearTimeout(timeoutId)
        );
      }
    },
    [currentFilter, allPrs]
  );

  return { searchedString, handleSearch };
};
