import React from 'react';

import FlakinessTimelinePanel from './FlakinessTimelinePanel';
import PerRepositoryPanel from './PerRepositoryPanel';
import TimelinePanel from './TimelinePanel';
import TimelinePerRepoPanel from './TimelinePerRepoPanel';

const InsightsSuccessRatio = () => (
  <>
    <div className="row mx-0 px-0 mb-4">
      <div className="col-md-12 mx-0 px-0">
        <TimelinePanel />
      </div>
    </div>
    <div className="row mx-0 px-0 mb-4">
      <div className="col-md-12 mx-0 px-0">
        <PerRepositoryPanel />
      </div>
    </div>
    <div className="row mx-0 px-0 mb-4">
      <div className="col-md-12 mx-0 px-0">
        <TimelinePerRepoPanel />
      </div>
    </div>
    <div className="row mx-0 px-0 mb-4">
      <div className="col-md-12 mx-0 px-0">
        <FlakinessTimelinePanel />
      </div>
    </div>
  </>
);

export default InsightsSuccessRatio;
