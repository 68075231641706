// @flow
import * as React from 'react';

import { LOADING } from '@analytics-components/StatusIndicator';
import Help, { sections } from '@analytics-pages/Help';
import { Spinner } from '@lib/Spinner';

// left the below type definitions for the sake of history in case we enable typescript one day
// type HelpTypes = {
//   children: React.Node,
// };

// type SectionObject = {
//   label: string,
//   path: string,
// };

// type HelpContext = {
//   isHelpOpen: boolean,
//   openSection: (p: SectionObject) => void,
//   stack: {
//     pop: () => void,
//     items: Array<SectionObject>,
//   },
// };

const Context = React.createContext({});
export const useHelpContext = () => React.useContext(Context);

const HelpComponent = ({ children }) => {
  const [isOpen, setOpen] = React.useState(false);
  const [stack, setStack] = React.useState([]);
  const [lastPosition] = stack.slice(-1);
  const Section = sections[lastPosition?.path];

  React.useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isOpen]);

  return (
    <Context.Provider
      value={{
        openSection: (section) => {
          setStack([...stack, section]);
          setOpen(true);
        },
        isHelpOpen: isOpen,
        stack: {
          pop: () => {
            if (stack.length) {
              setStack([...stack.slice(0, stack.length - 1)]);
            }
          },
          items: stack,
        },
      }}
    >
      {children}
      {isOpen && Section && (
        <Help
          onClick={() => {
            setOpen(false);
            setStack([]); // remove stacked sections
          }}
        >
          <React.Suspense fallback={<Spinner loading={LOADING} />}>
            <Section />
          </React.Suspense>
        </Help>
      )}
    </Context.Provider>
  );
};

export default HelpComponent;
