import { useTheme } from '@emotion/react';
import classnames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

import { useBreadcrumb } from '@analytics-context/Breadcrumb';
import DataWidget, { useDataWidget } from '@analytics-context/DataWidget';
import { dateTime } from '@common-services/dateService';
import { Tooltip } from '@lib/tooltip';
import { theme } from '@styles/theme';
import StageCard from '@ui/StageCard';
import { slugify } from '@utils/index';

import { pipelineThumbnailsStyles, pipelineStageStyles } from './styles';

const events = ['First Commit', 'Review Requested', 'Approved', 'Merged', 'Released'];
const stages = ['WIP', 'Review', 'Merge', 'Release'];
const hints = {
  wip: 'From the 1st commit of the pull request until the review is requested.',
  review: 'From the moment the review is requested until the pull request is approved.',
  merge: 'From the moment the pull request is approved until it is merged.',
  release: 'From the moment the pull request gets merged until it is released.',
};
const noDataText = {
  wip: 'No PRs',
  review: 'No PRs',
  merge: 'No PRs',
  release: 'No releases',
};

const getStages = () => stages;

const getMetrics = (stageSlug) => {
  let prefix;
  switch (stageSlug) {
    case 'summary':
      prefix = 'lead';
      break;
    case 'merge':
      prefix = 'merging';
      break;
    case 'deploy':
      prefix = 'deployment';
      break;
    default:
      prefix = stageSlug;
  }
  return {
    metric: `${prefix}-time`,
    metricCount: `${prefix}-count`,
    metricCountQ: `${prefix}-count-q`,
    metricPendingCount: `${prefix}-pending-count`,
  };
};

const stagesConf = _(stages)
  .map((s, index) => {
    const stageSlug = slugify(s);
    return {
      ...getMetrics(stageSlug),
      title: s,
      slug: stageSlug,
      noDataText: noDataText[stageSlug],
      color: theme.color.stage[stageSlug],
      hint: {
        primaryText: hints[stageSlug],
        secondaryText: 'Calculated up to the 95th percentile.',
        learnMore: {
          params: { label: s, path: stageSlug },
        },
      },
      event: {
        before: events[index],
        after: events[index + 1],
      },
    };
  })
  .value();

const Thumbnails = () => {
  const { subsection: activeStage } = useBreadcrumb();
  const plumber = (fetchedData, cachedData, apiContext) => {
    const timeseriesGranularity =
      cachedData['prs-metrics'].customGranularity === 'month' ? 'custom-aligned' : 'custom';

    return {
      aggregated: cachedData['prs-metrics']['values']['all'],
      timeseries: _(cachedData['prs-metrics']['values'][timeseriesGranularity]).reduce(
        (result, datapoints, k) => {
          result[k] = _(datapoints)
            .map((d) => ({ x: d.date, y: d.value }))
            .value();
          return result;
        },
        {}
      ),
      variations: cachedData['prs-metrics']['variations'],
    };
  };

  return (
    <DataWidget id="thumbnails" plumber={plumber} prefetchedDataIds={['prs-metrics']}>
      <ThumbnailsDataBinder activeStage={activeStage} />
    </DataWidget>
  );
};

const ThumbnailsDataBinder = ({ activeStage }) => {
  const { data, isLoading } = useDataWidget();
  const theme = useTheme();

  const getDataForCard = (card) =>
    isLoading
      ? {}
      : {
          stageData: data.timeseries[card.metric],
          textValue:
            data.aggregated[card.metric] !== null
              ? dateTime.human(data.aggregated[card.metric] * 1000)
              : card.noDataText,
          variationValue: data.aggregated[card.metric] !== null ? data.variations[card.metric] : 0,
          pendingPRs: data.aggregated[card.metricPendingCount] || 0,
        };

  return (
    <div css={pipelineThumbnailsStyles} className="row">
      {stagesConf.map((card, i) => {
        const isActive = activeStage === card.slug;
        const color = isActive ? theme.color.neutral.white : card.color;
        const { stageData, textValue, variationValue, pendingPRs } = getDataForCard(card);

        return (
          <div
            css={pipelineStageStyles}
            className={classnames('col-md-3', card.slug, isActive && 'active')}
            key={i}
          >
            <Tooltip content={card.event.before} className="event-before" />
            <Link to={'/analytics/delivery-pipeline/' + card.slug}>
              <StageCard
                data={stageData}
                isLoading={isLoading}
                title={card.title}
                text={textValue}
                hint={card.hint}
                variation={variationValue}
                color={color}
                active={isActive}
                badge={pendingPRs}
              />
            </Link>
            <Tooltip content={card.event.after} className="event-after" />
          </div>
        );
      })}
    </div>
  );
};

export default Thumbnails;
export { getStages, getMetrics };
