import { css } from '@emotion/react';

export const summaryMetricStyles = ({ color }) => css`
  &.wip {
    border-color: ${color.stage.wip};

    header {
      color: ${color.stage.wip};
    }
  }
  &.review {
    border-color: ${color.stage.review};

    header {
      color: ${color.stage.review};
    }
  }
  &.merge {
    border-color: ${color.stage.merge};

    header {
      color: ${color.stage.merge};
    }
  }
  &.release {
    border-color: ${color.stage.release};

    header {
      color: ${color.stage.release};
    }
  }
  &.deploy {
    border-color: ${color.stage.deploy};

    header {
      color: ${color.stage.deploy};
    }
  }
`;

export const overallProportionStyles = ({ color, spacing }) => css`
  display: block;
  height: 5px;
  margin: ${spacing.gap['02']} ${spacing.gap['0']} ${spacing.gap['03']};
  min-width: 7px;

  &.wip {
    background: ${color.stage.wip};
  }
  &.review {
    background: ${color.stage.review};
  }
  &.merge {
    background: ${color.stage.merge};
  }
  &.release {
    background: ${color.stage.release};
  }
`;
