import { css } from '@emotion/react';

export const metricDescriptionStyles = ({ spacing, color, radius, transition }) => css`
  padding: ${spacing.gap['04']} ${spacing.gap['05']};
  border-bottom: 1px solid ${color.neutral['40']};
  background: ${color.neutral['20']};
  display: flex;
  align-items: center;
  .change-icon {
    transition: ${transition.default};
    padding: ${spacing.gap['02']};
    border-radius: ${radius.default};
    cursor: pointer;
    &:hover {
      background-color: ${color.neutral['40']};
    }
  }
`;

export const thresholdEditStyles = () => css`
  position: relative;
`;
