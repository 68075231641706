import { DatetimeService } from '@analytics-services/datetimeService';
import { LooseObject } from '@analytics-types/common';

// TODO: There are many types that are defined here. Ideally they should be imported
// from where they should belong. This needs to be refactored once the "owners" of these types
// are ported to TS.
interface IContributor {
  group: string;
  login: string;
  picture: string;
  team: string;
  jira_user?: string;
  name?: string;
  email?: string;
}
type IssueTypeFilter = LooseObject[];
type LabelFilter = LooseObject[];
type EpicFilter = LooseObject[];
export type Repositories = string[];
export type Contributors = IContributor[];
type OmniboxItem = IssueTypeFilter | LabelFilter | EpicFilter;
export type Omnibox = OmniboxItem[];
interface MetricSelectorItem {
  displayName: string;
  endpoint: string;
  group: string;
  id: string;
  initialOrder: number;
  metricFormat: LooseObject;
  name: string;
  sources: string[];
}
export type MetricsSelector = MetricSelectorItem[];
interface TeamsTaggedSelectorItem {
  ancestors: any[];
  descendants: any[];
  id: string;
  label: string;
  parent: string;
  value: string;
}
export type TeamsTaggedSelector = TeamsTaggedSelectorItem[];
export type WorkTypes = string[];
export type ContributorTeams = string[];

export enum FiltersStorageKeysPrefix {
  CONTRIBUTORS = 'filter.contributors.',
  DATE_RANGE = 'filter.date_range.',
  METRICS_SELECTOR = 'filter.metrics-selector.',
  TEAMS_TAGGED_SELECTOR = 'filter.teams-tagged-selector.',
  REPOSITORIES = 'filter.repositories.',
  CONTRIBUTOR_TEAMS = 'filter.contributor_teams.',
  OMNIBOX = 'filter.omnibox.',
  WORK_TYPES = 'filter.work_types.',
}

export interface IShareAPI {
  saveShare: ({ body }: { body: RawShareData }) => Promise<string>;
  getShare: (id: string) => Promise<RawShareParams>;
}

export type VersionType = string;

interface IShareFilters<T extends DatetimeService.Interval | DatetimeService.RawInterval> {
  dateRange: T;
  repositories: Repositories;
  contributors: Contributors;
  omnibox: Omnibox;
  metricsSelector: MetricsSelector;
  teamsTaggedSelector: TeamsTaggedSelector;
  workTypes: WorkTypes;
  contributorTeams: ContributorTeams;
}

export type RawShareFilters = IShareFilters<DatetimeService.RawInterval>;
export type ShareFilters = IShareFilters<DatetimeService.Interval>;

interface IShareData<T extends DatetimeService.Interval | DatetimeService.RawInterval> {
  account: number;
  targetUrl: string;
  version: VersionType;
  filters: IShareFilters<T>;
}

export type RawShareData = IShareData<DatetimeService.RawInterval>;
export type ShareData = IShareData<DatetimeService.Interval>;

interface IShareParams<T extends RawShareData | ShareData> {
  author: string;
  created: DatetimeService.Datetime;
  data: T;
}

export type RawShareParams = IShareParams<RawShareData>;
export type ShareParams = IShareParams<ShareData>;
