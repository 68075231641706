import React, { useEffect, useState } from 'react';

import { LOADING } from '@analytics-components/StatusIndicator';
import { TableSearch } from '@analytics-components/tables/table-search';
import { useApi } from '@analytics-hooks';
import { fetchReleaseSettings } from '@analytics-services/api';
import { SettingsGroup } from '@common-pages/Settings';
import { github } from '@common-services/format';
import { Spinner } from '@lib/Spinner';
import Breadcrumb from '@ui/Breadcrumb';

import RepoGroups from './components/RepoGroups';
import { IGroupedSettings, ISetting, ISettings } from './types';

const Releases: React.FC = () => {
  const { api, ready: apiReady, context } = useApi(true, false);

  const [repoGroupsConfigsState, setRepoGroupsConfigsState] = useState([]);
  const [filterTermState, setFilterTermState] = useState('');
  const [accountIdState, setAccountIdState] = useState();

  const accountId = context.account;

  useEffect(() => {
    if (!apiReady) {
      return;
    }

    (async () => {
      const releaseSettings = await fetchReleaseSettings(api, accountId);
      setAccountIdState(accountId);

      // Flatten the object
      const flatSettings: ISettings = releaseSettings.reduce(
        (total: ISettings, current: ISetting) => ({ ...total, ...current }),
        {}
      );

      // Sort all the repos by name and group them
      const sortedSettings: ISettings = Object.entries(flatSettings)
        .sort((a, b) => (github.repoName(a[0]) > github.repoName(b[0]) ? 1 : -1))
        .reduce((total, [url, data]) => {
          const orgName = github.repoOrg(url);
          if (!total[orgName]) {
            total[orgName] = [];
          }

          total[orgName].push({ url, ...data });

          return total;
        }, {});

      // Sort the groups
      const sortedGroups: IGroupedSettings = Object.entries(sortedSettings)
        .sort((a, b) => (a[0] > b[0] ? 1 : -1))
        .reduce((total, [, data]) => [...total, data], []);

      setRepoGroupsConfigsState(sortedGroups);
    })();
  }, [api, apiReady, accountId]);

  return (
    <>
      <Breadcrumb breadcrumb={[{ name: 'Settings' }, { name: 'Releases' }]} />
      <SettingsGroup>
        <p className="text-secondary mt-2 mb-3">Select your release workflow</p>
        <TableSearch
          placeholder="Find a repository..."
          value={filterTermState}
          onFilter={setFilterTermState}
        />
        {repoGroupsConfigsState.length ? (
          <RepoGroups
            accountId={accountIdState}
            groups={repoGroupsConfigsState}
            filterTerm={filterTermState}
          />
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Spinner loading={!!LOADING} />
          </div>
        )}
      </SettingsGroup>
    </>
  );
};

export default Releases;
