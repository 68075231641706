import React, { useMemo } from 'react';

import { ChartParam } from '@align-pages/dashboard/components/chart-edit/components/chart-sidebar/components/chart-param/chartParam';
import { ChartParamsConfig } from '@align-pages/dashboard/config';
import { DashboardPropertyServices } from '@align-pages/dashboard/services/dashboardPropertyServices';
import { useDashboardStore } from '@align-pages/dashboard/store';
import { ChartMetrics, ChartParamsType } from '@align-pages/dashboard/types';

export const ChartSidebar: React.FC = React.memo(() => {
  const chartParams = useDashboardStore((state) => state.chartParams);
  const properties = useDashboardStore((state) => state.properties);
  const filtersConfig = ChartParamsConfig[ChartParamsType.FILTERS];
  const breakdownsConfig = ChartParamsConfig[ChartParamsType.BREAKDOWNS];
  const props = useMemo(() => DashboardPropertyServices.parseProperties(properties), [properties]);
  return chartParams?.metric ? (
    <>
      <ChartParam
        type={ChartParamsType.FILTERS}
        name={filtersConfig.name}
        description={filtersConfig.description}
        properties={props[ChartParamsType.FILTERS]}
        selectedMetric={chartParams.metric as ChartMetrics}
      />
      <ChartParam
        type={ChartParamsType.BREAKDOWNS}
        name={breakdownsConfig.name}
        description={breakdownsConfig.description}
        properties={props[ChartParamsType.BREAKDOWNS]}
        selectedMetric={chartParams.metric as ChartMetrics}
      />
    </>
  ) : null;
});
