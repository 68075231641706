import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';

import { PersistentMultiSelect } from '@analytics-components/MultiSelect';
import {
  filterDropdownOptionStyles,
  filterDropdownImgStyles,
} from '@analytics-components/filters/UserMultiSelect/styles';
import { isFeatureEnabled, featuresList } from '@analytics-services/flags';
import { default as nullUserImage } from '@assets/icons/default_user.svg';
import { useUserContext } from '@common-context/User';
import { github } from '@common-services/format';

const nullUser = {
  name: 'Unassigned',
  group: 'Unassigned',
  team: 'Unassigned',
  login: '',
  avatar: nullUserImage,
};

const UsersMultiSelect = React.memo((props) => {
  const {
    onInit,
    onApply,
    isLoading,
    options,
    areOptionsLoading,
    initialValues,
    persistentKey,
    persistentKeyGroups,
    resetStorage,
    teams,
    isDisabled,
  } = props;

  const { features } = useUserContext();
  const noOther = isFeatureEnabled(featuresList.filter_teams_mandatory, features);

  const processedOpts = useMemo(
    () =>
      teams.length > 0
        ? mapContribsToTeam(options, teams, noOther)
        : _(options)
            .map((opt) => ({ ...opt, label: opt.name }))
            .value(),
    [teams, options, noOther]
  );

  const processedInitialValues = useMemo(
    () =>
      teams.length > 0
        ? mapTeam(initialValues, teams)
        : _(initialValues)
            .map((v) => ({ ...v, label: v.name }))
            .value(),
    [teams, initialValues]
  );

  // this is a custom function that sets the logic for a search in MultiSelect
  // which includes group names
  const filterOption = useCallback(
    ({ data }, string) => {
      const { name, team, login } = data;

      if (!name && !string) {
        return true;
      }

      // default search
      if (
        name?.toLocaleLowerCase().includes(string.toLocaleLowerCase()) ||
        login?.toLocaleLowerCase().includes(string.toLocaleLowerCase())
      )
        return true;

      // find groups whose names include a searched string
      const groupOptions = processedOpts.filter((g) =>
        g.label.toLocaleLowerCase().includes(string.toLocaleLowerCase())
      );

      if (groupOptions) {
        for (const groupOption of groupOptions) {
          // Check if current option is in group
          const option = groupOption.options.find((opt) => opt.name === name);
          if (option && team && team.toLocaleLowerCase().includes(string.toLocaleLowerCase())) {
            return true;
          }
        }
      }
      return false;
    },
    [processedOpts]
  );

  return (
    <PersistentMultiSelect
      label="Teams"
      className="filter"
      name="users"
      noDataMsg="Neither teams nor users match your query for the given filters."
      persistentKey={persistentKey}
      persistentKeyGroups={persistentKeyGroups}
      resetStorage={resetStorage}
      isLoading={isLoading}
      getOptionLabel={usersLabelFormat}
      getOptionValue={getOptionValueUsers}
      options={processedOpts}
      areOptionsLoading={areOptionsLoading}
      onInit={onInit}
      onApply={onApply}
      initialValues={processedInitialValues}
      forceGroups={teams.length > 0}
      isDisabled={isDisabled}
      nullOption={nullUser}
      filterOption={filterOption}
      hasGroups
      teams={teams}
    />
  );
});

const mapTeam = (contribs, teams) => {
  if (!teams.length) return contribs;
  // if contrib has `teams` property
  // `initialValues` are coming from localStorage and no need to
  // re-process the list
  const [sample] = contribs;
  if (sample?.team) return contribs;

  return mapContribsToTeam(contribs, teams).reduce((acc, curr) => {
    return acc.concat(curr.options);
  }, []);
};

/**
 * Merge and map contributors list and teams
 * contributors without a team is left in the 'Other' team
 * @param {Array} contribs
 * @param {Array} teams
 */
const mapContribsToTeam = (contribs, teams, noOther) => {
  if (!teams.length) return contribs;

  const contribsWithTeam = _(teams).flatMap('members').map('login').value();
  const teamsBlueprint = teams.map(({ name: label, members }) => {
    return {
      label,
      options: _(members)
        .map((member) => ({ ...member, team: label }))
        .value(),
    };
  });

  if (!noOther) {
    teamsBlueprint.push({
      label: 'Other',
      options: _(contribs)
        .differenceWith(contribsWithTeam, (arr1, arr2) => arr1.login === arr2)
        .filter((c) => c.name !== nullUser.name)
        .value(),
    });
  }

  if (_(contribs).findIndex((c) => c.login === nullUser.login && c.name === nullUser.name) > -1) {
    teamsBlueprint.push({
      label: nullUser.name,
      options: [nullUser],
    });
  }

  return teamsBlueprint;
};

const getOptionValueUsers = (val) =>
  val.login ? `${val.team} ${val.login}` : `${val.team} ${val.name}`;
const usersLabelFormat = ({ name, login, avatar, picture }) => {
  const user = name === nullUser.name ? nullUser.name : login ? github.userName(login) : '';
  return (
    <div css={filterDropdownOptionStyles} data-cy="option-label">
      <img css={filterDropdownImgStyles} src={avatar || picture} alt={name} />
      {name && (
        <span className="filter-dropdown-option-name" data-cy="option-label-name">
          {name}
        </span>
      )}
      {user && user !== name && (
        <span
          className={`filter-dropdown-option-user${!name ? '__dark' : ''} mr-2`}
          data-cy="option-label-user"
        >
          {user}
        </span>
      )}
    </div>
  );
};

export default UsersMultiSelect;
export { usersLabelFormat, getOptionValueUsers, nullUser };
