// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';

import { useHelpContext } from '@analytics-context/Help';
import {
  Main,
  Title,
  Summary,
  Subtitle,
  Paragraph,
  Separator,
  ContentBlock,
  BlockQuote,
  Source,
  Sidebar,
  RelatedContent,
  Navigation,
  Image,
} from '@analytics-pages/Help/components';
import ReviewImg from '@assets/images/educational/review.png';

const Review = ({ fullPage }) => {
  const { openSection, stack } = useHelpContext();
  return (
    <>
      <Navigation pages={stack} />
      <Main>
        <section>
          <Title>Review Cycle Time</Title>
          <Summary>
            Code review being the most difficult and time-consuming part of the software development
            process, review cycle time often stands for the biggest opportunity to improve your
            overall speed delivery.
          </Summary>

          <Subtitle>Why is it important?</Subtitle>
          <Paragraph>
            Besides delaying the entire software delivery pipeline, long review cycle times are
            usually the consequence of poor engineering practices, and often represent a strong
            signal for problems such as:
          </Paragraph>
          <ContentBlock>
            <ol>
              <li>Poor review efficiency and engagement.</li>
              <li>Exponential cost and schedule growth.</li>
              <li>Rise of technical debt.</li>
              <li>Low motivation from the team.</li>
            </ol>
          </ContentBlock>
          <Paragraph>
            Since code reviews often require experienced team members to spend their time reading,
            thinking, and responding to implementations of new features or systems, it becomes even
            more important to ease the review process and minimize the number of review iterations.
          </Paragraph>

          <Subtitle>How is it calculated?</Subtitle>
          <Paragraph>
            The Review cycle time is the average time pull requests take to go from the 1st review
            request until the last approval. It stands for the elapsed time in which pull requests
            are in the review process and include the time engineers request the review, gather and
            integrate feedback.
          </Paragraph>

          <Image src={ReviewImg} />

          <Paragraph>
            In case a pull request is not reviewed, it does not contribute to the average review
            cycle time.
          </Paragraph>

          <Separator />

          <Subtitle>How to improve it using Athenian?</Subtitle>
          <Paragraph>
            Slow review cycle times can have several origins. Among them, the most common are:
          </Paragraph>
          <ContentBlock>
            <ol>
              <li>Big pull requests harder to review.</li>
              <li>Human bottlenecks due to overwhelmed reviewers.</li>
              <li>Confusion around the requirements and lack of product specs.</li>
            </ol>
          </ContentBlock>
          <Paragraph>
            Athenian measures your average review cycle time and allow you to quickly spot pull
            requests blocked along the process. For example, if one of your pull request is spending
            more time than usual waiting for review or getting stuck into endless discussions,
            you'll get informed before it starts having a negative impact on your overall lead time.
          </Paragraph>

          <Paragraph>
            With Athenian, you can also target the best engineering practices to improve your review
            process:
          </Paragraph>
          <ContentBlock>
            <ol>
              <li>Make smaller pull requests and do self code reviews.</li>
              <li>Think about breaking tickets down before starting to code.</li>
              <li>Write useful and concise pull request descriptions to give more context.</li>
              <li>Assign reviewers who wrote related code.</li>
              <li>Help reviewers navigate pull requests with inline comments.</li>
              <li>Discuss code reviews face-to-face or via VC.</li>
              <li>Run tests before requesting review.</li>
            </ol>
          </ContentBlock>
        </section>
        <Sidebar>
          <BlockQuote>
            "If code reviews are required, they should be performed synchronously."
            <Source>Google</Source>
          </BlockQuote>
          <BlockQuote>
            "Don't hesitate to suggest a quick meeting, face-to-face or via VC."
            <Source>Chromium</Source>
          </BlockQuote>
          <BlockQuote>
            "People closest to a problem typically know the most about it."
            <Source>Toyota Production System</Source>
          </BlockQuote>

          <RelatedContent>
            <h4>Related Content</h4>
            <ul>
              <li>
                <a href="https://google.github.io/eng-practices/review/reviewer/speed.html">
                  Why should code reviews be fast?
                </a>
              </li>
              <li>
                <a href="https://engineering.shopify.com/blogs/engineering/great-code-reviews">
                  Great Code Reviews at Shopify
                </a>
              </li>
              <li>
                {fullPage ? (
                  <Link to={`/learn/knowledge/lead-time`} className="link">
                    <span>Lead Time</span>
                  </Link>
                ) : (
                  <span
                    onClick={() => openSection({ label: 'Lead Time', path: 'leadTime' })}
                    className="link"
                  >
                    Lead Time
                  </span>
                )}
              </li>
            </ul>
          </RelatedContent>
        </Sidebar>
      </Main>
    </>
  );
};

export default Review;
