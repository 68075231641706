import { useTheme } from '@emotion/react';
import React, { useCallback, useMemo } from 'react';

import ruleTypes from '@common-pages/Settings/Allocation/WorkTypes/ruleTypes';
import IconButton from '@lib/IconButton';
import { Icon, icons } from '@lib/icon';

import { wrapperStyles } from './styles';

// This is a generalized Rule component that renders rules according to their specifications,
// defined in ruleTypes.jsx file and corresponding customer data
const Rule = ({ rule, workType, onRemove }) => {
  const theme = useTheme();
  const currentRule = useMemo(() => ruleTypes[rule.name], [rule]);

  const handleClickRemove = useCallback(() => {
    onRemove(rule.name, workType);
  }, [rule, workType]);

  return (
    <div css={wrapperStyles}>
      <div className="ath-rule-fields">
        <div className="ath-rule-title">
          <Icon color={theme.color.neutral[80]} icon={currentRule.icon} size={14} />
          <div className="ath-rule-label">{currentRule.label}</div>
        </div>
        {currentRule.renderFn(rule, workType)}
      </div>
      <IconButton icon={icons.cross} iconSize={8} onClick={handleClickRemove} />
    </div>
  );
};

export default Rule;
