import { css } from '@emotion/react';

const getWrapperStyle = ({ spacing, color }) => css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: ${spacing.gap['03']} ${spacing.gap['04']};
  background: ${color.neutral.white};
  flex: none;
  align-self: stretch;
  margin: ${spacing.gap['0']};

  & > button {
    height: 28px;
  }
  & .maxSelected {
    color: red;
  }
`;

const getCancelButtonStyle = ({ spacing, color, transition }) => css`
  color: ${color.neutral['80']};
  background: ${color.neutral.white};
  margin-right: ${spacing.gap['03']};
  transition: ${transition.default};
`;

const getApplyButtonStyle = ({ spacing, color }, isDisabled) => css`
  background: ${color.ui.orange['100']};
  color: ${color.neutral.white};
  opacity: ${isDisabled ? 0.65 : 1};

  &:hover {
    color: ${color.neutral.white};
  }
`;

export { getWrapperStyle, getCancelButtonStyle, getApplyButtonStyle };
