import React, { useMemo } from 'react';
import { useTable } from 'react-table';

import { metricsConfig } from '@align-constants';
import { processPR } from '@common-services/prHelpers';
import { Table } from '@lib/Table';
import { prTableColumns } from '@lib/Table/components/pr-table/prTable.columns';
import { tableBodyStyles } from '@lib/Table/components/pr-table/prTable.styles';
import { IPrsTableRow } from '@lib/Table/components/pr-table/prTable.types';
import { tableHeaderStyles } from '@lib/Table/styles';

import { PrTableServices } from './prTable.services';
import { IPrTable } from './prTable.types';

export const PrTable: React.FC<IPrTable> = React.memo(
  ({ data, templateMetric, isLoadingPart, onShowMoreHandler }) => {
    const serializedData = useMemo<IPrsTableRow[]>(() => {
      return PrTableServices.serializePrTableData(
        {
          deployments: {},
          prs: data?.data?.map(processPR) || [],
          users: data?.include?.users || {},
        },
        metricsConfig[templateMetric]?.completedKPI || metricsConfig[templateMetric]?.allKPI
      );
    }, [data, templateMetric]);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<
      IPrsTableRow
    >({
      columns: prTableColumns,
      data: serializedData,
    });
    rows.forEach(prepareRow);
    return (
      <Table
        rows={rows}
        headerGroups={headerGroups}
        tableProps={getTableProps()}
        tableBodyProps={getTableBodyProps()}
        extraCSS={{
          header: tableHeaderStyles,
          body: tableBodyStyles,
        }}
        isLoadingPart={isLoadingPart}
        isSortingEnabled={false}
        onShowMoreHandler={onShowMoreHandler}
      />
    );
  }
);
