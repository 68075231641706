import { counterMetric } from '@align-pages/dashboard/config';
import {
  DashboardChartGroupBy,
  Jira,
  Metric,
} from '@common-services/api/private/generated-from-backend/models';
import { FILTER_BREAKDOWN_MAP } from '@common-services/api/public/constants';
import '@common-services/api/public/generated-from-backend/models';
import {
  Granularity,
  JIRAGroups,
  JIRAMetricsRequest,
} from '@common-services/api/public/generated-from-backend/models';
import {
  GetGroupInfoType,
  IGetGranularityParams,
  IGetMetrics,
} from '@common-services/api/public/types/metrics';
import { DateService, TimeUnit } from '@common-services/dateService';

export function generateTeamGroupParams(
  teams: number[],
  groupInfoType: GetGroupInfoType
): Record<string, string[]>[] {
  switch (groupInfoType) {
    case GetGroupInfoType.PULL_REQUESTS:
      return teams?.map((id) => ({ author: [`{${id}}`] }));
    case GetGroupInfoType.JIRA:
      return teams?.map((id) => ({ assignees: [`{${id}}`] }));
    case GetGroupInfoType.RELEASES:
      return teams?.map((id) => ({ pr_author: [`{${id}}`] }));
    default:
      return [];
  }
}

export const generateJiraGroupParams = (data: DashboardChartGroupBy, jira?: Jira): JIRAGroups => {
  // data can only have one group. Use the name of the first element
  if (!data) {
    return null;
  }

  const targetGroup = Object.keys(data)[0];

  // Find corresponding filtername if any
  const targetFilter = FILTER_BREAKDOWN_MAP[targetGroup];

  // If there is no matching filter with the breakdown then there is no correlation
  if (!targetFilter) {
    return null;
  }

  // If filters has a jira field same as the selected breakdown, use filters instead
  const targetField = jira?.[targetFilter]?.length ? jira?.[targetFilter] : data?.[targetGroup];

  // If chart has other jira filters than selected breakdown
  const otherFields = jira
    ? Object.entries(jira).reduce((a, jiraProp) => {
        return {
          ...a,
          ...(jiraProp[0] !== targetFilter ? { [jiraProp[0]]: jiraProp[1] } : {}),
        };
      }, {})
    : {};

  // Generate jira group by adding other filters to it
  return (
    targetField.map((jiraProp) => ({
      [targetFilter]: [jiraProp],
      ...otherFields,
    })) || null
  );
};

export const generateGranularities = (
  { dateFrom, dateTo }: IGetGranularityParams,
  hasCounterMetric: boolean
): Granularity[] => {
  const mainGranularity = DateService.calculateGranularity({ dateFrom, dateTo });
  return [
    mainGranularity === TimeUnit.MONTH ? 'aligned month' : mainGranularity,
    ...(hasCounterMetric ? ['all'] : []),
  ];
};

export const generateMetrics = (metric: Metric, hasCounterMetric: boolean): IGetMetrics =>
  [metric, hasCounterMetric ? counterMetric[metric] : null].filter((v) => v);

export const generateJiraFilterParams = (
  filters: Jira
): Pick<JIRAMetricsRequest, 'types' | 'priorities' | 'projects'> => {
  const { priorities, projects, issue_types: types } = filters;
  return {
    priorities,
    projects,
    types,
  };
};
