import { css } from '@emotion/react';

export const styles = (size, disabled) => ({ color, radius, transition }) => css`
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: ${radius.default};
  cursor: ${disabled ? 'unset' : 'pointer'};
  display: flex;
  justify-content: center;
  height: ${size}px;
  transition: ${transition.default};
  width: ${size}px;

  path {
    fill: ${color.neutral[80]};
  }

  &:hover {
    ${!disabled && `background-color: ${color.neutral[40]};`}
  }

  &:active {
    ${!disabled &&
    `
      background-color: ${color.neutral[60]};

      path {
        fill: ${color.neutral.white};
      }
    `}
  }

  ${disabled && `opacity: 0.3;`}
`;
