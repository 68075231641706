import React, { useCallback } from 'react';

import { wrapperStyles } from './ActionMenuListItem.styles';
import { IActionMenuListItem } from './ActionMenuListItem.types';

export const ActionMenuListItem: React.FC<IActionMenuListItem> = React.memo(({ name, onClick }) => {
  const onClickHandler = useCallback(
    (event) => {
      event.stopPropagation();
      onClick && onClick(event);
    },
    [onClick]
  );
  return (
    <div css={wrapperStyles} onClick={onClickHandler}>
      {name}
    </div>
  );
});
