import { FilterType, IFilterBarOption } from '@lib/filter-panel';

export const filterBarOptions: IFilterBarOption[] = [
  {
    type: FilterType.REPOSITORY,
    group: 'github',
  },
  {
    type: FilterType.JIRA_ISSUE_TYPES,
    group: 'jira',
  },
  {
    type: FilterType.JIRA_PROJECTS,
    group: 'jira',
  },
  {
    type: FilterType.JIRA_PRIORITIES,
    group: 'jira',
  },
];
