import React from 'react';

import { cardBodyStyles } from '@analytics-components/insights/styles';
import { NoData } from '@lib/empty/noData';

import BoxBody from './components/BoxBody';
import BoxHeader from './components/BoxHeader';
import { ContentType, MetaType } from './types';

//  Component for the boxes in the "Insights" tab of each stage of the pipeline.
interface Props {
  content: ContentType[];
  empty?: boolean;
  emptyPage?: JSX.Element;
  meta: MetaType;
}

const Box: React.FC<Props> = React.memo(({ meta, content, empty, emptyPage }) => (
  <div className="card insight-card mb-4">
    <BoxHeader meta={meta} />
    {empty ? (
      emptyPage || (
        <div css={cardBodyStyles}>
          <NoData />
        </div>
      )
    ) : (
      <BoxBody content={content} />
    )}
  </div>
));

Box.defaultProps = {
  empty: false,
  emptyPage: null,
};

export default Box;
