import { useEffect, useMemo, useState } from 'react';

import { useGetPRs } from '@align-hooks/useGetPRs';
import { useSearchedPRs } from '@align-hooks/useSearchedPRs';
import { useApi } from '@analytics-hooks';
import { IApiBasicError } from '@common-services/api/common/types/common';
import {
  Contributor,
  GetPullRequestsRequest,
  IncludedNativeUsers,
  PullRequest,
} from '@common-services/api/public/generated-from-backend/models';

import { BUBBLES_MAX_NUMBER_OF_PRS, MAX_PR_SIZE, PRS_TO_REQUEST } from './biggest-prs.constants';
import { getLines } from './biggest-prs.services';

interface IUseBiggestPRs {
  prs: PullRequest[];
  users: IncludedNativeUsers['users'];
  isLoading: boolean;
  isError: IApiBasicError;
}

export function useBiggestPRs(): IUseBiggestPRs {
  const [offset, setOffset] = useState(0);
  const [prs, setPrs] = useState<PullRequest[]>([]);
  const [users, setUsers] = useState<IncludedNativeUsers['users']>({});
  // tempPrs and tempUsers are used to incrementally save fetched PRs
  // after a required amount of PRs is fetched they will be copied to prs and users
  const [tempPrs, setTempPrs] = useState<PullRequest[]>([]);
  const [tempUsers, setTempUsers] = useState<IncludedNativeUsers['users']>({});

  const { account, interval, repositories, issueTypes, epics, contributors } = useApi().context;

  const { data: searchedData, isLoading: isSearchLoading, error: isSearchError } = useSearchedPRs(
    {
      account,
      date_from: interval?.from.format('YYYY-MM-DD'),
      date_to: interval?.to.format('YYYY-MM-DD'),
      jira: {
        epics,
        issue_types: issueTypes,
      },
      participants: {
        author: contributors?.map((c: Contributor) => c.login).filter((c: Contributor) => c),
      },
      repositories,
      stages: [
        'wip',
        'reviewing',
        'merging',
        'releasing',
        'force_push_dropped',
        'done',
        'release_ignored',
        'deployed',
      ],
      filters: [
        {
          field: 'pr-size',
          operator: '>=',
          value: MAX_PR_SIZE,
        },
      ],
      order_by: [
        {
          field: 'pr-size',
          direction: 'descending',
        },
      ],
    },
    true
  );

  useEffect(() => {
    setTempPrs([]);
    setTempUsers({});
    setOffset(0);
  }, [searchedData?.pull_requests?.length]);

  const prsToRequest = useMemo<GetPullRequestsRequest['prs']>(() => {
    const nextSlice = (searchedData?.pull_requests || [])
      .slice(offset * PRS_TO_REQUEST, (offset + 1) * PRS_TO_REQUEST)
      .map((pr) => ({
        numbers: [pr.number],
        repository: pr.repository,
      }));
    return nextSlice.length ? [nextSlice[0], ...nextSlice.slice(1)] : null;
  }, [offset, searchedData?.pull_requests?.length]);

  const { data: prsData, isLoading: isGetLoading, error: isGetError } = useGetPRs(
    {
      account,
      prs: prsToRequest,
    },
    true
  );

  useEffect(() => {
    if (prsData?.data?.length) {
      // filter our PRs and sort them by LOC desc
      const newPrs = prsData.data
        .filter((pr) => !pr.closed || pr.merged)
        .sort((pr1, pr2) => (getLines(pr1) > getLines(pr2) ? -1 : 1));
      if (tempPrs.length + newPrs.length < BUBBLES_MAX_NUMBER_OF_PRS) {
        // if the total amount of fetched PRs is less than max, increase the offset to fetch more
        setOffset((prevOffset) => prevOffset + 1);
      }
      if (newPrs.length) {
        setTempPrs((prevFilteredPrs) => {
          const length = BUBBLES_MAX_NUMBER_OF_PRS - prevFilteredPrs.length;
          return [...prevFilteredPrs, ...newPrs.slice(0, length)];
        });
        setTempUsers((prevUsers) => ({
          ...prevUsers,
          ...prsData.include.users,
        }));
      }
    }
  }, [prsData?.data?.length]);

  const isLoading = useMemo(() => isSearchLoading || isGetLoading, [isSearchLoading, isGetLoading]);
  const isError = useMemo(() => isSearchError || isGetError, [isSearchError, isGetError]);

  // after fetching is over copy tempPrs to prs and tempUsers to users and return them to the parent component
  useEffect(() => {
    if (tempPrs.length && !isLoading) {
      setPrs(tempPrs);
      setUsers(tempUsers);
    }
  }, [isLoading, tempPrs, tempUsers]);

  return { prs, users, isLoading, isError };
}
