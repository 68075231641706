import { css } from '@emotion/react';

import { hexToRGBA } from '@utils/colors';

export const wrapperStyles = ({ color, font, radius, shadow, spacing }) => css`
  background-color: ${color.neutral.white};
  border: 1px solid ${color.neutral['40']};
  border-top: 4px solid ${color.ui.blue['100']};
  border-radius: ${radius.default};
  cursor: pointer;
  height: 108px;
  padding: ${spacing.gap['06']};
  width: 330px;

  &:hover {
    border: 1px solid ${color.neutral['80']};
    border-top: 4px solid ${color.ui.blue['100']};
    box-shadow: ${shadow.full};
  }

  &:active {
    background-color: ${hexToRGBA(color.ui.blue['100'], 0.1)};
    border: 1px solid ${color.ui.blue['100']};
    border-top: 4px solid ${color.ui.blue['100']};
    box-shadow: ${shadow.full};
  }

  .metric-title {
    color: ${color.text.dark};
    font-size: ${font.size.m};
    font-weight: ${font.weight.regular};
  }

  .metric-description {
    --max-lines: 2;
    color: ${color.text.secondary};
    display: -webkit-box;
    font-size: ${font.size.default};
    font-weight: ${font.weight.regular};
    margin-top: ${spacing.gap['02']};
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines);
  }
`;
