import { useLocalStorage } from '@rehooks/local-storage';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useApi } from '@analytics-hooks';
import { DatetimeService } from '@analytics-services/datetimeService';
import {
  Contributors,
  ContributorTeams,
  FiltersStorageKeysPrefix,
  MetricsSelector,
  Omnibox,
  Repositories,
  ShareFilters,
  TeamsTaggedSelector,
  WorkTypes,
} from '@analytics-services/shareService.types';
import { ButtonAction } from '@lib/Button';

import { getCreateUrlAction } from '../ShareViewButton.services';

const useShareUrlAction = (): ButtonAction<string> => {
  const { api, context: apiContext, ready: apiReady } = useApi(true);
  const location = useLocation();
  const buildStorageKey = useCallback(
    (prefix: FiltersStorageKeysPrefix) => `${prefix}${apiContext.account}`,
    [apiContext.account]
  );
  const [dateRange] = useLocalStorage<DatetimeService.RawInterval>(
    buildStorageKey(FiltersStorageKeysPrefix.DATE_RANGE)
  );
  const [repositories] = useLocalStorage<Repositories>(
    buildStorageKey(FiltersStorageKeysPrefix.REPOSITORIES),
    []
  );
  const [contributors] = useLocalStorage<Contributors>(
    buildStorageKey(FiltersStorageKeysPrefix.CONTRIBUTORS),
    []
  );
  const [omnibox] = useLocalStorage<Omnibox>(buildStorageKey(FiltersStorageKeysPrefix.OMNIBOX), []);
  const [metricsSelector] = useLocalStorage<MetricsSelector>(
    buildStorageKey(FiltersStorageKeysPrefix.METRICS_SELECTOR),
    []
  );
  const [teamsTaggedSelector] = useLocalStorage<TeamsTaggedSelector>(
    buildStorageKey(FiltersStorageKeysPrefix.TEAMS_TAGGED_SELECTOR),
    []
  );
  const [workTypes] = useLocalStorage<WorkTypes>(
    buildStorageKey(FiltersStorageKeysPrefix.WORK_TYPES),
    []
  );
  const [contributorTeams] = useLocalStorage<ContributorTeams>(
    buildStorageKey(FiltersStorageKeysPrefix.CONTRIBUTOR_TEAMS)
  );

  // TODO: DEV-6187 workaround is applied. Find the exact reason why this type is not correct
  const typeFixedDateRange = useMemo(
    () => (typeof dateRange === 'string' ? JSON.parse(dateRange) : dateRange),
    [dateRange]
  );

  const filters = useMemo<ShareFilters>(
    () =>
      apiReady
        ? {
            dateRange: typeFixedDateRange
              ? DatetimeService.interval(typeFixedDateRange.from, typeFixedDateRange.to)
              : null,
            repositories,
            contributors,
            omnibox,
            metricsSelector,
            teamsTaggedSelector,
            workTypes,
            contributorTeams,
          }
        : null,
    [
      apiReady,
      dateRange,
      repositories,
      contributors,
      omnibox,
      metricsSelector,
      teamsTaggedSelector,
      workTypes,
      contributorTeams,
    ]
  );

  const action = useMemo<ButtonAction<string>>(() => {
    return filters ? getCreateUrlAction(api, apiContext, location.pathname, filters) : null;
  }, [api, apiContext, location.pathname, filters]);

  return action;
};

export { useShareUrlAction };
