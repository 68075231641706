import { css } from '@emotion/react';

export const formControlStyles = ({ color, font, radius, spacing }) => css`
  background-color: ${color.neutral.white};
  background-clip: padding-box;
  border-radius: ${radius.default};
  border: 1px solid ${color.neutral[40]};
  color: ${color.text.dark};
  display: block;
  font-size: ${font.size.default};
  font-weight: ${font.weight.light};
  height: 3.2rem;
  line-height: 1.5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: ${spacing.gap['03']} ${spacing.gap['04']};
  width: 100%;

  .input-group & {
    padding-left: ${spacing.gap['08']};
  }
`;
