import React from 'react';

import comingSoon from '@assets/images/empty-states/coming-soon.svg';

import { Empty } from '..';

const ComingSoon: React.FC = React.memo(() => {
  return (
    <Empty background={comingSoon}>
      <h3>We are working on adding new insights.</h3>
      <p>Meanwhile, please let us know what you'd like to see here.</p>
      <button onClick={() => window.Intercom('show')} className="btn btn-large btn-orange">
        Give feedback
      </button>
    </Empty>
  );
});

export { ComingSoon };
