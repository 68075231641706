import { css } from '@emotion/react';

export const tableStyles = ({ color, spacing }) => css`
  color: ${color.text.dark};
  width: 100%;
  margin-bottom: ${spacing.gap['06']};
`;

export const tableHeaderStyles = ({ color, spacing }) => css`
  th {
    border-top: 1px solid ${color.neutral[40]};
    border-bottom: 1px solid ${color.neutral[40]};
    padding: ${spacing.gap['04']};
    line-height: 1.1rem;
    font-size: 1.1rem;
    color: ${color.neutral[80]};
    background-color: ${color.neutral[20]};
    text-transform: uppercase;
    white-space: nowrap;

    &:first-of-type {
      border-left: 1px solid ${color.neutral[40]};
      padding-left: ${spacing.gap['06']};
    }

    &:not(:first-of-type) {
      text-align: left;
    }

    &:last-child {
      border-right: 1px solid ${color.neutral[40]};
    }
  }
`;

export const tableHeaderRowStyles = ({ color, spacing }) => css`
  &.sorting {
    &:after {
      content: '\u2193';
      position: absolute;
      display: inline;
      margin-left: ${spacing.gap['04']};
      opacity: 0;
    }

    &:hover {
      &:after {
        opacity: 0.7;
      }
    }
  }

  &.sorted_desc,
  &.sorted_asc {
    color: ${color.text.dark};

    &:before {
      content: none;
      position: absolute;
    }

    &:after {
      opacity: 1;
      position: absolute;
      display: inline;
      margin-left: ${spacing.gap['04']};
    }
  }

  &.sorted_desc:after {
    content: '\u2191';
  }

  &.sorted_asc:after {
    content: '\u2193';
  }
`;

export const tableBodyRowStyles = ({ color }, { depth, serviceRow }) => css`
  td {
    background-color: ${color.neutral.white};
    border-top: ${depth > 0 ? 0 : `1px solid ${color.neutral[40]}`};
    border-bottom: 1px solid ${color.neutral[40]};
    height: ${depth < 2 && !serviceRow ? `${64 - depth * 8}px` : '48px'};

    &:first-of-type {
      border-left: 1px solid ${color.neutral[40]};
    }

    &:last-child {
      border-right: 1px solid ${color.neutral[40]};
    }

    &:not(:first-of-type) > div {
      justify-content: flex-start;
    }
  }
  *: {
    font-size: ${depth < 2 ? `${15 - depth * 2}px` : '11px'};
  }
`;

export const tableBodyCellStyles = ({ spacing }) => css`
  height: 100%;
  display: flex;
  align-items: center;
  padding: ${spacing.gap['0']} ${spacing.gap['07']};
  overflow-wrap: break-word;
`;

export const showMoreCellStyles = ({ color }) => css`
  border: 1px solid ${color.neutral[40]};
`;

export const showMoreTextStyles = ({ color, radius, font, spacing }) => css`
  height: 48px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-items: flex-start;

  .empty-icon {
    background-color: ${color.neutral[40]};
    border-radius: ${radius.default};
    height: 17px;
    width: 17px;
  }

  .link-text {
    color: ${color.neutral['80']};
    font-size: ${font.size.default};
    font-weight: ${font.weight.regular};
    margin-left: ${spacing.gap['06']};

    &:hover {
      color: ${color.text.dark};
      text-decoration: underline;
    }
  }
`;

export const spinnerWrapperStyles = ({ color, radius }) => css`
  height: 48px;
  display: flex;
  justify-items: center;
  align-items: center;
`;

export const trSpacerStyles = css`
  height: 3px;
`;
