import React from 'react';

import { emptyContainerStyles } from './empty.styles';

interface Props {
  background: string;
  children: any;
  copy?: string;
  textOnly?: boolean;
}

const Empty: React.FC<Props> = React.memo(({ textOnly, copy, background, children }) => {
  if (textOnly) return <div>{copy}</div>;

  return (
    <div css={emptyContainerStyles}>
      <img src={background} alt="" width="300" />
      {children}
    </div>
  );
});

export { Empty };
