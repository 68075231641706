import releaseCreationPerRepo from './releaseCreation_perRepo';
import releaseCreationTimeline from './releaseCreation_timeline';
import waitingRelease from './releaseCreation_waitingRelease';

const releaseCreation = {
  plumber: (fetchedData, cachedData, apiContext) => {
    const plumbed = {
      firstBox: releaseCreationTimeline.plumber(fetchedData, cachedData, apiContext),
      secondBox: releaseCreationPerRepo.plumber(fetchedData, cachedData, apiContext),
    };

    if (apiContext.user.isGod) {
      plumbed['thirdBox'] = waitingRelease.plumber(fetchedData, cachedData, apiContext);
    }

    return plumbed;
  },
  factory: (computed, isGod) => {
    const content = [
      ...releaseCreationTimeline.factory(computed.firstBox).content,
      ...releaseCreationPerRepo.factory(computed.secondBox).content,
    ];

    if (isGod) {
      content.push(...waitingRelease.factory(computed.thirdBox).content);
    }

    return {
      meta: {
        title: 'Release Frequency',
        description: 'Maximize the release frequency and shorten feedback loops.',
      },
      empty: content.filter((c) => !c.empty).length === 0,
      content,
    };
  },
};

export default releaseCreation;
