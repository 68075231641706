import React, { useCallback, useEffect, useState } from 'react';

import { leftPanelStyles } from './styles';

const options = [
  {
    id: 1,
    label: '1 week',
    fn: () => {
      const date = new Date();
      date.setDate(date.getDate() - 6);
      return date;
    },
  },
  {
    id: 2,
    label: '2 weeks',
    fn: () => {
      const date = new Date();
      date.setDate(date.getDate() - 13);
      return date;
    },
  },
  {
    id: 3,
    label: '1 month',
    fn: () => {
      const date = new Date();
      date.setMonth(new Date().getMonth() - 1);
      return date;
    },
  },
  {
    id: 4,
    label: '3 months',
    fn: () => {
      const date = new Date();
      date.setMonth(new Date().getMonth() - 3);
      return date;
    },
  },
  {
    id: 5,
    label: '6 months',
    fn: () => {
      const date = new Date();
      date.setMonth(new Date().getMonth() - 6);
      return date;
    },
  },
  {
    id: 6,
    label: '1 year',
    fn: () => {
      const date = new Date();
      date.setMonth(new Date().getMonth() - 12);
      return date;
    },
  },
];

const LeftInfoPanel = ({ isActive, setInterval }) => {
  const [activeOption, setActiveOption] = useState(null);

  const handleClick = useCallback(
    (option) => () => {
      setActiveOption(option.id);
      setInterval(option.fn);
    },
    []
  );

  useEffect(() => {
    if (!isActive) {
      setActiveOption(null);
    }
  }, [isActive]);

  return (
    <div css={leftPanelStyles}>
      {options.map((option, index) => (
        <div
          className={`interval-option${option.id === activeOption ? ' active' : ''}`}
          key={`option-${index}`}
          onClick={handleClick(option)}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};

export default LeftInfoPanel;
