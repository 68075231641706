import { ITab } from './Tabs.types';

export const getTabsValues = async (tabs: ITab[]) => {
  const valueGettersProms = tabs.map((t) =>
    t.valueGetter ? t.valueGetter() : Promise.resolve(t.value)
  );
  return await Promise.all(valueGettersProms);
};

export * as TabsService from './service';
