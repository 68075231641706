import { ContentType } from '@analytics-components/insights/Box/types';
import { GranularityEnum } from '@analytics-constants';
import { ValueType } from '@analytics-pages/delivery-pipeline/insights/deploy/services/types';
import { IntervalType } from '@analytics-types/common';

import chart from './chart';
import kpis from './kpis';

export interface KPIInputType {
  cycleTime: () => number;
  prsCount: number;
}

interface DataType {
  env: string;
  granularity: GranularityEnum;
  interval: IntervalType;
  kpisData: KPIInputType;
  values: ValueType[];
}

const cycleTime = (data: DataType): ContentType => {
  const { env, granularity, interval, kpisData, values } = data;

  return {
    chart: chart(env, granularity, interval, values),
    kpis: kpis(kpisData),
  };
};

export default cycleTime;
