import { css } from '@emotion/react';

export const wrapperStyles = css`
  display: flex;
`;

export const stepStyles = (isLink: boolean) => ({ color, font }) => css`
  color: ${isLink ? color.neutral['80'] : color.neutral['100']};
  font-size: ${font.size.s};

  ${isLink &&
  `
    &:hover {
      color: ${color.neutral['80']};
      text-decoration: underline !important;
    }
  `}
`;

export const slashStyles = ({ spacing, color }) => css`
  display: inline-block;
  color: ${color.neutral['60']};
  margin: 0 ${spacing.gap['02']};
`;
