import { css } from '@emotion/react';

export const wrapperStyles = ({ color, font, spacing }) => css`
  margin: calc(${spacing.gap['12']} + ${spacing.gap['11']}) auto;
  text-align: center;

  .title {
    color: ${color.text.dark};
    font-size: ${font.size.m};
    font-weight: ${font.weight.regular};
    margin-top: ${spacing.gap['05']};
  }

  .subtitle {
    color: ${color.text.secondary};
    font-size: ${font.size.default};
    font-weight: ${font.weight.regular};
    margin-top: ${spacing.gap['04']};
  }

  .link {
    color: ${color.ui.orange['100']};
    font-size: ${font.size.default};
    font-weight: ${font.weight.regular};
    margin-top: ${spacing.gap['04']};
    text-decoration: underline !important;
  }
`;
