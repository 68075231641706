import React from 'react';

import RingChart from '@ui/charts/RingChart';

const RADIAL_DATA = [
  { value: 1, label: 'uno' },
  { value: 2, label: 'dos' },
  { value: 5, label: 'cinco' },
  { value: 3, label: 'tres' },
];

const Chart = () => (
  <RingChart
    data={RADIAL_DATA}
    params={{
      labels: {
        primary: '10%',
        secondary: 'Bug Fixing Ratio',
      },
    }}
  />
);

export default Chart;
