import { useTheme } from '@emotion/react';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  ChartLabel,
  VerticalBarSeries,
  HorizontalGridLines,
  VerticalGridLines,
  CustomSVGSeries,
} from 'react-vis';

import LegendSelect from '@analytics-components/LegendSelect';
import { AltTitle } from '@analytics-components/Typography';
import {
  DefaultXYTooltip,
  BigText,
  onValueChange,
  onValueReset,
} from '@analytics-components/charts/Tooltip';
import defaults from '@analytics-components/charts/defaults';
import { dateTime } from '@common-services/dateService';
import { NoData } from '@lib/empty/noData';

const Chart = ({ data, extra, ...rest }) => {
  const [filteredFrequenciesData, setFilteredFrequenciesData] = useState(data.frequencies);
  const [filteredColors, setFilteredColors] = useState(extra.fillColor);
  const onActivesChange = (actives) => {
    setFilteredFrequenciesData(
      _(data.frequencies)
        .filter((_, i) => actives[i])
        .value()
    );
    setFilteredColors(
      _(extra.fillColor)
        .filter((_, i) => actives[i])
        .value()
    );
  };

  return (
    <div style={{ background: 'white' }}>
      {extra.legend && (
        <header className="text-center text-center mb-3 pt-2" style={{ marginLeft: '100px' }}>
          <LegendSelect
            items={_(extra.legend.labels)
              .zip(extra.fillColor)
              .map((v) => ({ label: v[0], color: v[1] }))
              .filter((item) => item.label)
              .value()}
            onActivesChange={onActivesChange}
          />
        </header>
      )}
      <DistributionChart
        data={{
          ...data,
          frequencies: filteredFrequenciesData,
        }}
        extra={{
          ...extra,
          fillColor: filteredColors,
        }}
        {...rest}
      />
    </div>
  );
};

export const computeTickValues = (sliceCenters, maxNumberOfTicks = 0) => {
  if (maxNumberOfTicks > 0) {
    const everyEach = Math.ceil(sliceCenters.length / maxNumberOfTicks);
    const selectedTicks = _.range(0, sliceCenters.length - 1, everyEach);
    return _(sliceCenters).at(selectedTicks).value();
  } else {
    return sliceCenters;
  }
};

const calcQuantileMarker = (data, barWidth) => {
  const quantile = data.quantile;
  const leftCount = data.leftCount || 0;
  const quantileMarker = { sum: leftCount, index: 0, lastBarCount: leftCount };

  if (!quantile) return null;

  for (let i = 0; i < data.frequencies.length; i++) {
    quantileMarker.sum += data.frequencies[i];
    if (quantileMarker.sum >= quantile) {
      quantileMarker.lastBarCount = data.frequencies[i];
      quantileMarker.lastBarCountQ = data.frequencies[i] - (quantileMarker.sum - quantile);
      quantileMarker.index = quantileMarker.lastBarCountQ === data.frequencies[i] ? i + 1 : i;
      break;
    }
  }

  let offset;
  if (quantileMarker.lastBarCount === quantileMarker.lastBarCountQ) {
    offset = quantileMarker.index - 0.5;
  } else {
    const quantileVerticalLineBaseOffset = quantileMarker.index - barWidth / 2;
    offset =
      quantileVerticalLineBaseOffset +
      (barWidth / data.frequencies[quantileMarker.index]) * quantileMarker.lastBarCountQ;
  }

  return { offset, index: quantileMarker.index };
};

const DistributionChart = ({ data, extra = {} }) => {
  const [currentHover, setCurrentHover] = useState(null);
  const marginLeft = extra.margin?.left ?? defaults.marginLeft;
  const marginRight = extra.margin?.right;
  const marginBottom = extra.margin?.bottom || 60;
  const marginTop = extra.margin?.top || 10;
  const labelYMargin = extra.margin?.labelY || 60;

  const handleValueMouseOver = useCallback(
    (datapoint) => onValueChange({ type: 'bar', datapoint }, currentHover, setCurrentHover),
    [currentHover]
  );

  const handleValueMouseOut = useCallback(() => onValueReset(setCurrentHover), []);

  if (data.frequencies.length === 0) {
    return <NoData textOnly />;
  }

  const frequencies = _(data.frequencies[0]).isArray() ? data.frequencies : [data.frequencies];

  const xDomain = [0, frequencies[0].length - 1];
  const yDomain = [0, _(frequencies).flatMap().max()];
  const bucketCenters = data.bucketCenters || frequencies[0].map((_, i) => i);

  const barWidth = 0.75;
  const quantileMarker = _(data.frequencies[0]).isArray()
    ? null
    : calcQuantileMarker(data, barWidth);

  const opacity = extra.baseOpacity || 1;
  const series = _(frequencies)
    .map((s) =>
      _(s)
        .map((v, i) => ({
          x: i,
          y: v,
          opacity: quantileMarker ? (i >= quantileMarker.index ? 0.5 * opacity : opacity) : opacity,
        }))
        .value()
    )
    .value();

  const tickValues = computeTickValues(
    _(series).flatMap().map('x').sortBy().uniq().value(),
    extra.maxNumberOfTicks
  );

  const tickFormat = (i) => {
    const center = bucketCenters[i];
    return extra.axisFormat?.x ? extra.axisFormat.x(center) : dateTime.human(center, 0, true);
  };

  const chartHeight = extra.height || 300;
  const fillColor = _(extra.fillColor).isArray() ? extra.fillColor : [extra.fillColor];
  return (
    <FlexibleWidthXYPlot
      height={chartHeight}
      margin={{ left: marginLeft, right: marginRight, bottom: marginBottom, top: marginTop }}
      xDomain={xDomain}
      yDomain={yDomain}
    >
      <XAxis tickValues={tickValues} tickFormat={tickFormat} tickSizeOuter={0} tickSizeInner={0} />
      <YAxis tickTotal={3} on0={false} tickSizeOuter={0} tickSizeInner={0} />
      <HorizontalGridLines tickTotal={3} />
      <VerticalGridLines tickTotal={tickValues.length} />

      <AxisLabel text={extra.axisLabels?.x} mode={AXIS_X} margin={marginBottom} />
      <AxisLabel text={extra.axisLabels?.y} mode={AXIS_Y} margin={labelYMargin} />

      {_(series)
        .map((dataPoints, index) => (
          <VerticalBarSeries
            key={index}
            sameTypeIndex={0}
            sameTypeTotal={1}
            data={dataPoints}
            fill={fillColor[index]}
            barWidth={barWidth}
            stroke="none"
            onValueMouseOver={handleValueMouseOver}
            onValueMouseOut={handleValueMouseOut}
          />
        ))
        .value()}

      {quantileMarker && (
        <VerticalMarker
          position={quantileMarker.offset}
          top={yDomain[1]}
          height={chartHeight - marginTop - marginBottom}
          currentHover={currentHover}
          setCurrentHover={setCurrentHover}
        />
      )}

      <DefaultXYTooltip
        align={extra?.tooltip?.align}
        value={currentHover?.datapoint}
        dataPoint={currentHover}
        x={(p) => {
          if (p.type === 'bar') {
            return (
              <AltTitle
                uppercase
                content={
                  extra.tooltip?.y?.(p.datapoint.y) || `${p.datapoint.y} ${extra.axisLabels?.y}`
                }
              />
            );
          }

          return <AltTitle uppercase content={`95th percentile`} />;
        }}
        y={(p) => {
          if (p.type === 'bar') {
            return (
              <BigText
                content={
                  extra.tooltip?.x?.(bucketCenters[p.datapoint.x]) || tickFormat(p.datapoint.x)
                }
              />
            );
          }

          return null;
        }}
      />
    </FlexibleWidthXYPlot>
  );
};

const VerticalMarker = ({ position, top, height, currentHover, setCurrentHover, ...props }) => {
  const theme = useTheme();

  const quantileMarkerSpecs = [
    {
      x: position,
      y: 0,
      customComponent: () => {
        return (
          <g className="inner-inner-component">
            <line x1={-1} y1={0} x2={-1} y2={-height} stroke="transparent" strokeWidth="9px" />
            <line
              x1={0}
              y1={0}
              x2={0}
              y2={-height}
              stroke={theme.color.ui.blue[100]}
              strokeWidth="1px"
              strokeDasharray="3px 3px"
            />
          </g>
        );
      },
    },
  ];

  const handleValueMouseOut = useCallback(() => onValueReset(setCurrentHover), []);

  return (
    <CustomSVGSeries
      {...props}
      data={quantileMarkerSpecs}
      onValueMouseOver={(datapoint, event) =>
        onValueChange(
          { type: 'line', datapoint: { ...datapoint, y: top } },
          currentHover,
          setCurrentHover
        )
      }
      onValueMouseOut={handleValueMouseOut}
    />
  );
};

VerticalMarker.requiresSVG = true;

const AxisLabel = ({ text, mode, margin, ...props }) => {
  const conf = axisConf(mode, margin);
  if (!text || !conf) return null;
  return <ChartLabel {...props} text={text} {...conf} />;
};

AxisLabel.requiresSVG = true;

const AXIS_X = Symbol();
const AXIS_Y = Symbol();

const axisConf = (mode, margin) => {
  switch (mode) {
    case AXIS_X:
      return {
        includeMargin: false,
        xPercent: 0.5,
        yPercent: 1.0,
        style: {
          y: margin,
          textAnchor: 'middle',
        },
      };
    case AXIS_Y:
      return {
        includeMargin: false,
        xPercent: 0.0,
        yPercent: 0.5,
        style: {
          y: -(margin - 10),
          textAnchor: 'middle',
          transform: 'rotate(-90)',
        },
      };
    default:
      return null;
  }
};

export default Chart;
