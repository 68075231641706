import React from 'react';

import { stringToColor } from '@utils/colors';

import { IRow } from './types';

interface ICellTeamExpandable {
  row: IRow;
  hasChevron: boolean;
  membersCount: number;
}

export const CellTeamExpandable: React.FC<ICellTeamExpandable> = React.memo(
  ({ row, hasChevron, membersCount }) => (
    <>
      <div
        css={({ spacing }) => ({
          background: stringToColor(row.values.name),
          marginLeft: hasChevron ? spacing.gap['05'] : spacing.gap['08'],
        })}
      >
        {row.values.name[0]}
      </div>
      <p>
        {row.values.name}
        <span>
          {membersCount} member{membersCount > 1 ? 's' : ''}
        </span>
      </p>
    </>
  )
);
