import React from 'react';

import { Chevron } from '@lib/Chevron';

import { useOverrideStyle } from '../hooks';
import { HeaderProps } from '../types';
import { getHeaderStyle } from './ui/styles';

const AccordionHeader = React.forwardRef<HTMLDivElement, HeaderProps>((props, ref) => {
  const { onClick, isExpanded, overrideStyle } = props;
  const style = useOverrideStyle(getHeaderStyle, overrideStyle);

  return (
    <div ref={ref} css={style} onClick={onClick}>
      <div>
        <Chevron isExpanded={isExpanded} size={10} />
      </div>
      {props.render(props)}
    </div>
  );
});

export { AccordionHeader };
