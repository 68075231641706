import { metricsConfig } from '@align-constants';
import { IGoalMetricValue, ITeamGoal, TeamGoalInput } from '@align-services/api/types/goalsTypes';
import { Unit, ValueType } from '@align-types/constants';
import {
  Goal,
  GoalMetricParams,
  GoalMetricValue,
  TeamGoal,
  TeamMetricValueNode,
} from '@common-services/api/private/generated-from-backend/models';
import { GoalTemplate } from '@common-services/api/public/generated-from-backend/models';
import { DateService } from '@common-services/dateService';
import { NumberService } from '@common-services/numberService';
import { StringService } from '@common-services/stringService';
import { FilterType, ISelectedFilters } from '@lib/filter-panel';

/*TODO: remove after complete moving from GQL to REST*/
export const normaliseMetricValue = (value: IGoalMetricValue): GoalMetricValue => {
  if (value?.str != null) {
    return value.str;
  } else if (value?.int != null) {
    return value.int;
  } else if (value?.float != null) {
    return value.float;
  } else {
    return null;
  }
};

/*TODO: remove after complete moving from GQL to REST*/
export const deNormaliseMetricValue = (value: GoalMetricValue): IGoalMetricValue => {
  if (StringService.isString(value)) {
    return { str: value as string };
  } else if (StringService.isInteger(value)) {
    return { int: value as number };
  } else if (StringService.isFloat(value)) {
    return { float: value as number };
  } else {
    return null;
  }
};

/*TODO: remove after complete moving from GQL to REST*/
export const deNormaliseAllLevels = (teamGoal: TeamGoal): ITeamGoal => {
  return {
    ...teamGoal,
    value: {
      current: deNormaliseMetricValue(teamGoal.value.current),
      target: deNormaliseMetricValue(teamGoal.value.target),
      initial: deNormaliseMetricValue(teamGoal.value.initial),
    },
    children: teamGoal.children.map((teamGoalChild) => deNormaliseAllLevels(teamGoalChild)),
  };
};

export const convertReadableToServerValue = (
  value: string,
  valueType: ValueType,
  unit: Unit,
  conversionRate: number
): GoalMetricParams['threshold'] => {
  switch (valueType) {
    case ValueType.date:
      return `${((parseFloat(value) * conversionRate) / 1000).toFixed(0)}s`;
    case ValueType.int:
      return NumberService.round(parseInt(value) / conversionRate);
    // TODO: Checking the `unit` is not ideal, worth having custom functions to
    // serialize/deserialize or have a `ValueType.percentage`
    case ValueType.float:
      return parseFloat(value) / (unit?.singular === '%' ? 100 : 1);
    default:
      return null;
  }
};

export const updateMetricsValues = (
  metricsValue: TeamMetricValueNode,
  targetValues: TeamGoalInput[]
): ITeamGoal => {
  const oldMetricsValue = Object.assign({}, metricsValue);
  const updateValue = (teamValue: TeamMetricValueNode): ITeamGoal => {
    const existingTargetValue = targetValues.find((target) => target.teamId === teamValue.team.id);
    const newTeamValue: ITeamGoal = {
      /*TODO: remove after complete moving from GQL to REST*/
      // @ts-ignore
      children: teamValue.children,
      team: teamValue.team,
      value: {
        current: null,
        initial: deNormaliseMetricValue(teamValue.value),
        target: existingTargetValue ? existingTargetValue.target : null,
      },
    };
    newTeamValue.children = teamValue.children.map((childValue) => updateValue(childValue));
    return newTeamValue;
  };
  const newMetricsValue = updateValue(oldMetricsValue);
  return newMetricsValue;
};

export const convertToTargetValue = (data: TeamGoal[]): TeamGoalInput[] =>
  data.reduce((a, team) => {
    return [
      ...a,
      ...(team.value?.target
        ? [
            {
              teamId: team.team.id,
              target: deNormaliseMetricValue(team.value.target),
            },
          ]
        : []),
      ...(team.children ? convertToTargetValue(team.children) : []),
    ];
  }, []);

export const generateFiltersObject = (data: Goal | ISelectedFilters): ISelectedFilters => {
  const selectedFiltersKeys: FilterType[] = [
    FilterType.REPOSITORY,
    FilterType.JIRA_ISSUE_TYPES,
    FilterType.JIRA_PROJECTS,
    FilterType.JIRA_PRIORITIES,
  ];
  return selectedFiltersKeys.reduce(
    (a, c) => {
      return {
        ...a,
        [c]: data?.[c] || [],
      };
    },
    {
      repositories: [],
      jira_issue_types: [],
      jira_projects: [],
      jira_priorities: [],
    }
  );
};

export const getConvertedServerData = (
  value: string,
  unit: string,
  valueType: ValueType,
  metricValue: GoalTemplate['metric']
): GoalMetricParams['threshold'] => {
  const conversionRate = valueType === ValueType.date ? DateService.getConvertRate(unit) : 1;
  return convertReadableToServerValue(
    value,
    valueType,
    metricsConfig[metricValue].unit,
    conversionRate
  );
};

export * as GoalsSingleComponentServices from './goalsSingleComponent.services';
