import React, { useEffect } from 'react';
import ReactFlow, { Controls, useReactFlow } from 'react-flow-renderer';

import { TeamNode } from '@align-pages/goals/single/components/tree-view/components/team-node';

import { IFittedTreeView } from './fittedTreeView.types';

const nodeTypes = {
  teamNode: TeamNode,
};

const FittedTreeView: React.FC<IFittedTreeView> = React.memo(({ nodes, edges }) => {
  const { fitView } = useReactFlow();

  const doFitView = () => {
    // hack with timeout to make the fitting work when both expanding and collapsing the tree view
    const timeoutId = setTimeout(() => {
      fitView({ duration: 800 });
      clearTimeout(timeoutId);
    }, 100);
  };

  useEffect(() => {
    doFitView();
  }, [nodes.length]);

  return (
    <ReactFlow nodes={nodes} edges={edges} fitView nodeTypes={nodeTypes} nodesConnectable={false}>
      <Controls showInteractive={false} />
    </ReactFlow>
  );
});

export { FittedTreeView };
