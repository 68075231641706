import { SelectOptionSingle } from '@lib/TaggedSelector/types/controls';
import { SelectTsOptionSingle } from '@lib/selector-ts';

export interface ISelectedCaseOutput extends ISelectedCaseOutputBase {
  ancestors: ISelectedCaseOutputBase[];
  descendants: ISelectedCaseOutputBase[];
}

interface ISelectedCaseOutputBase {
  id: string;
  label: string;
  parent: string;
  value: string;
  isSelected?: boolean;
  isOpened?: boolean;
}

export function selectTsSerializer(option: SelectOptionSingle): SelectTsOptionSingle {
  return {
    id: option.value.toString(),
    name: option.label,
    isSelected: option.isSelected,
    isOpened: option.isOpened,
  };
}

export function convertTsOptionsRecursion(
  incOptions: SelectOptionSingle[],
  childId = ''
): SelectTsOptionSingle[] {
  return incOptions
    .filter((item) => item.parent === childId)
    .map((item) => ({
      ...selectTsSerializer(item),
      children: convertTsOptionsRecursion(incOptions, item.value),
    }));
}

export function convertTsSelected(
  source: SelectOptionSingle[],
  result: string[]
): ISelectedCaseOutput[] {
  const generateId = (value: string): string => {
    const option = source.find((o) => o.value === value);
    return `${option.parent ? generateId(option.parent) + '-' : ''}${option.value}`;
  };
  return source
    .filter((item) => result.includes(item.value))
    .map((item) => {
      return {
        ...item,
        id: generateId(item.value),
        ancestors: [],
        descendants: [],
      };
    });
}

export * as ControlsService from './controls';
