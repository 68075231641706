import React from 'react';
import { Redirect } from 'react-router-dom';

import { PrimaryLayoutTabs } from '@align-components/layouts/primary/context.types';
import { NavigationService } from '@align-services/navigationService';
import { useAlignStore } from '@align-store/index';
import { useAuth0 } from '@common-context/Auth0';
import { useUserContext } from '@common-context/User';
import { useDemoCookie } from '@common-pages/Demo';
import { useOwnedTeamTree } from '@common-services/api/private/hooks/useTeamTreeData';
import { AuthRouteEnum } from '@routes/authRoutes';

export const RedirectionDefaultTab: React.FC = () => {
  const { data: teamsData } = useOwnedTeamTree(true);
  const teamsPath = useAlignStore((state) => state.teamsPath);
  return (
    <>
      {teamsData && (
        <Redirect
          to={NavigationService.getRedirectToTeam(
            teamsData,
            PrimaryLayoutTabs.DASHBOARD,
            teamsPath
          )}
        />
      )}
    </>
  );
};

export const RedirectionsAlign: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  const { isDemo } = useDemoCookie();
  const userContext = useUserContext();
  const account = userContext?.user?.defaultAccount?.id || null;

  if ((!isAuthenticated && !isDemo) || !account) return <Redirect to={AuthRouteEnum.LOGIN} />;

  return <RedirectionDefaultTab />;
};
