import { css } from '@emotion/react';

export const placeholderStyles = css`
  min-width: 20px;
`;

export const wrapperStyles = css`
  align-items: center;
  display: flex;
`;

export const titleStyles = ({ color, font }) => css`
  color: ${color.text.dark};

  .title {
    &:hover {
      text-decoration: underline !important;
      text-decoration-color: ${color.text.dark} !important;
    }

    .issue-title-text {
      color: ${color.text.dark};
      font-size: ${font.size.s};
      font-weight: ${font.weight.regular};
      width: 243px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media (min-width: 1300px) {
        width: 420px;
      }

      @media (min-width: 1400px) {
        width: 500px;
      }

      .issue-id {
        color: ${color.text.secondary};
      }
      å span {
        color: ${color.text.secondary};
        font-size: ${font.size.s};
        font-weight: ${font.weight.regular};
      }
    }

    .pr-title-text {
      color: ${color.text.dark};
      font-size: ${font.size.default};
      font-weight: ${font.weight.regular};
      width: 216px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .pr-repo {
        color: ${color.text.secondary};
      }

      span {
        font-size: ${font.size.s};
        font-weight: ${font.weight.regular};
      }

      @media (min-width: 1200px) {
        width: 293px;
      }

      @media (min-width: 1300px) {
        width: 393px;
      }

      @media (min-width: 1400px) {
        width: 473px;
      }
    }
  }

  .tooltip-text {
    color: ${color.text.dark};
    font-size: ${font.size.default};
    font-weight: ${font.weight.regular};

    .entity-id {
      color: ${color.text.secondary};
    }
  }
`;

export const taskDetailsStyles = ({ color, font, spacing }) => css`
  align-items: center;
  display: flex;

  .task-details {
    color: ${color.text.secondary};
    font-size: ${font.size.xs};
    font-weight: ${font.weight.light};
    margin-left: ${spacing.gap['03']};
    white-space: nowrap;

    span {
      font-size: ${font.size.xs};
    }

    .reporter {
      color: ${color.text.dark};
    }
  }

  .user-avatar {
    height: 22px;
    margin-right: ${spacing.gap['0']};
    width: 22px;
  }
`;

export const iconWrapperStyles = ({ spacing }) => css`
  margin-right: ${spacing.gap['05']};

  .pr-icon {
    margin-left: ${spacing.gap['08']};
  }
`;
