import React from 'react';

import { useNavigation } from '@align-components/layouts/primary/context';
import { LoadingView } from '@align-components/layouts/primary/loadingView';
import { useOwnedDashboard } from '@align-hooks/useDashboard';
import { ChartPlaceholder } from '@align-pages/dashboard/components/chart-placeholder';
import { ChartOperationType } from '@align-pages/dashboard/hooks/types';
import { useDashboardResponseHandle } from '@align-pages/dashboard/hooks/useDashboardResponseHandle';
import { useDragAndDrop } from '@common-hooks/useDragAndDrop';
import { DashboardChart } from '@common-services/api/private/generated-from-backend/models';
import { useReorderChartsOnDashboard } from '@common-services/api/private/hooks/useReorderChartsOnDashboard';

import { AddChartPanel, DashboardChartBox } from '../components';
import { dashboardStyles, wrapperStyles } from './dashboardContent.styles';

const DashboardContent: React.FC = React.memo(() => {
  const { currentTeam } = useNavigation();
  const { data, isLoading: isDashboardLoading } = useOwnedDashboard(currentTeam.id, true);

  const {
    mutate: reorderCharts,
    isSuccess,
    isError,
    isLoading: isReorderLoading,
  } = useReorderChartsOnDashboard();

  useDashboardResponseHandle({
    isSuccess,
    isError,
    operationType: ChartOperationType.REORDER,
  });

  const {
    displayList,
    draggingId,
    handleDragStart,
    handleDragEnter,
    handleDrop,
    handleDragEvent,
  } = useDragAndDrop<DashboardChart>(data?.charts || [], (data) => reorderCharts({ charts: data }));

  if (isDashboardLoading && !displayList) {
    return <LoadingView />;
  }

  return (
    <div css={wrapperStyles}>
      <div css={dashboardStyles}>
        {(displayList || []).map((chart) => (
          <React.Fragment key={chart.id}>
            {isReorderLoading && <ChartPlaceholder />}
            {!isReorderLoading && (
              <DashboardChartBox
                draggingId={draggingId}
                onDragStart={handleDragStart(chart.id)}
                onDragEnter={handleDragEnter(chart.id)}
                onDrop={handleDrop}
                onDragLeave={handleDragEvent}
                onDragOver={handleDragEvent}
                chart={chart}
              />
            )}
          </React.Fragment>
        ))}
        {!isReorderLoading && <AddChartPanel />}
      </div>
    </div>
  );
});

export { DashboardContent };
