import React from 'react';

import { wrapperStyles } from './metricTemplate.styles';
import { IMetricTemplate } from './metricTemplate.types';

const MetricTemplate: React.FC<IMetricTemplate> = React.memo(({ metric, onSelect }) => (
  <div css={wrapperStyles} onClick={onSelect(metric)}>
    <div className="metric-title">{metric.name}</div>
    <div className="metric-description">{metric.description}</div>
  </div>
));

export { MetricTemplate };
