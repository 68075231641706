import { Column } from 'react-table';

import { IDeploymentsRow } from '@analytics-pages/Tables/deployments/deployments.types';
import { dateTime } from '@common-services/dateService';
import { number } from '@common-services/format';
import { Icon, icons } from '@lib/icon';
import { theme } from '@styles/theme';

import { DeploymentsCellTitle } from '../cell-title';
import { badgeStyles, columnStyles } from './deploymentsTable.styles';

export const columns: Column<IDeploymentsRow>[] = [
  {
    Header: 'Deployments',
    accessor: 'finished',
    Cell: DeploymentsCellTitle,
  },
  {
    Header: 'Pull Requests',
    accessor: 'prsAmount',
    Cell: ({ row }) =>
      row.depth === 0 && (
        <div css={columnStyles}>
          <Icon color={theme.color.neutral['80']} icon={icons.nav_pr} size={16} />
          <span className="prs-count">{row.original.prsAmount || 0}</span>
        </div>
      ),
  },
  {
    Header: 'Size',
    accessor: (row) => row.additions + row.deletions,
    Cell: ({ row }) =>
      row.depth === 0 && (
        <div css={columnStyles}>
          <span className="size-added">+{number.si(row.original.additions)}</span>
          <span className="size-removed">-{number.si(row.original.deletions)}</span>
        </div>
      ),
  },
  {
    Header: 'Deploy Time',
    accessor: 'deployTime',
    Cell: ({ row }) =>
      row.depth === 0 && (
        <div css={columnStyles}>{dateTime.timeValue(row.original.deployTime)}</div>
      ),
  },
  {
    Header: 'Environment',
    accessor: 'environment',
    Cell: ({ row }) => row.depth === 0 && <div css={badgeStyles}>{row.original.environment}</div>,
  },
];
