import { ITheme } from '@styles/types';

import { color } from './colors';
import { font } from './fonts';
import { radius } from './radius';
import { shadow } from './shadow';
import { spacing } from './spacing';
import { transition } from './transition';

export const theme: ITheme = {
  color,
  font,
  spacing,
  radius,
  shadow,
  transition,
};

export type { ITheme };
