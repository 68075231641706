import { useLocalStorage } from '@rehooks/local-storage';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import { useApiContext } from '@analytics-context/Api';
import { useFilters } from '@analytics-context/Filters';
import { buildApi } from '@analytics-services/api';
import { useAuth0 } from '@common-context/Auth0';
import { useUserContext } from '@common-context/User';
import { useDemoCookie } from '@common-pages/Demo';

export const useMountEffect = (fun) => useEffect(fun, []);

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useExtendedState = (defaultValue, { useStorage, storageKey, emptyValue }) => {
  const [storageValue, writeStorageValue, deleteStorageValue] = useLocalStorage(
    storageKey,
    defaultValue
  );
  const [localValue, setLocalValue] = useState(useStorage ? storageValue : defaultValue);

  const setValue = useCallback(
    (value) => {
      setLocalValue(value);
      if (useStorage) {
        const func = value === emptyValue ? deleteStorageValue : writeStorageValue;
        func(value);
      }
    },
    [useStorage, setLocalValue, writeStorageValue, deleteStorageValue]
  );

  return [localValue, setValue];
};

const useApiOnly = () => {
  const { loading, isAuthenticated, loginWithRedirect, logout, getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();
  const [apiState, setApiState] = useState(null);

  useEffect(() => {
    if (!isAuthenticated && !isDemo) {
      return;
    }

    const prepareApi = async () => {
      const token = isDemo ? null : await getTokenSilently();
      const api = buildApi(token);

      setApiState(api);
    };

    prepareApi();
  }, [isAuthenticated, getTokenSilently, isDemo]);

  return {
    api: apiState,
    ready: !!apiState,
    auth: {
      loading,
      isAuthenticated,
      loginWithRedirect,
      logout,
    },
    context: {},
  };
};

const useApiWithUser = (withFilters) => {
  const { api, auth, ready: apiReady } = useApiContext();
  const { user: profile, logout, features, ...user } = useUserContext();
  const {
    appliedRepos: repositories,
    appliedContribs: contributors,
    hasAppliedNullContrib,
    hasAppliedBotContrib,
    teams,
    labels,
    epics,
    issueTypes,
    dateInterval: interval,
    excludeInactive,
    ready: filtersReady,
  } = useFilters();

  let context = {};
  if (apiReady) {
    context = {
      ...context,
      account: profile.defaultAccount.id,
      isAdmin: profile.defaultAccount.isAdmin,
      features,
      user: {
        profile,
        ...user,
      },
    };
    if (withFilters) {
      context = {
        ...context,
        contributors,
        excludeInactive,
        includeFakeContributor: hasAppliedBotContrib,
        includeNullContributor: hasAppliedNullContrib,
        interval,
        repositories,
        teams,
        labels: _(labels.applied?.values).map('label').value(),
        epics: _(epics.applied?.values).map('id').value(),
        issueTypes: _(issueTypes.applied?.values).map('id').value(),
      };
    }
  }

  return {
    api,
    auth,
    context,
    ready: apiReady && (!withFilters || filtersReady),
  };
};

export const useApi = (withUser = true, withFilters = true) => getApiHook(withUser, withFilters)();

const getApiHook = (withUser, withFilters) => {
  if (withUser) {
    return () => useApiWithUser(withFilters);
  } else {
    return useApiOnly;
  }
};

// this hook tracks measure (width and height) of an HTML element
export function useMeasure() {
  const ref = useRef();
  const [bounds, setBounds] = useState({ width: 0, height: 0 });
  const ro = useMemo(
    () =>
      new ResizeObserver(([entry]) => {
        const { width, height } = entry.contentRect;
        if (width !== bounds.width || height !== bounds.height) {
          setBounds({
            width,
            height,
          });
        }
      }),
    []
  );

  useEffect(() => {
    if (!ref) return;
    const observerOptions = {
      box: 'border-box',
    };

    if (ref.current) ro.observe(ref.current, observerOptions);
    return () => ro.disconnect();
  }, [ref.current]);

  return [ref, bounds];
}
