import _ from 'lodash';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { unslugify } from '@utils/index';

const Context = React.createContext({
  part: '',
  section: '',
  subsection: '',
  breadcrumb: [],
});
const useBreadcrumb = () => useContext(Context);

const BreadcrumbProvider = ({ children }) => {
  const { part: activePart, section: activeSection, subsection: activeSubsection } = useParams();
  const breadcrumbCtx = buildBreadcrumbCtx(activePart, activeSection, activeSubsection);

  return <Context.Provider value={{ ...breadcrumbCtx }}>{children}</Context.Provider>;
};

const buildBreadcrumbCtx = (activePart, activeSection, activeSubsection) => {
  const isMainPart = ['summary', 'delivery-pipeline', 'ci', 'quality', 'outcome'].includes(
    activePart
  );
  const mainViewMap = {
    quality: 'bugs',
    outcome: 'allocation',
    ci: 'run-time',
  };

  const isComparePart = activePart === 'compare';
  const ctx = isMainPart
    ? {
        part: '',
        section: activePart || '',
        subsection: activeSection || '',
        breadcrumb:
          activeSection === 'summary' || activePart === 'summary'
            ? [{ name: activePart }]
            : [
                {
                  name: activePart,
                  link: `/analytics/${activePart}/${mainViewMap[activePart] || 'summary'}`,
                },
                { name: activeSection },
              ],
      }
    : {
        part: activePart || '',
        section: activeSection || '',
        subsection: activeSubsection || '',
        breadcrumb: [
          { name: activePart },
          { name: activeSection },
          ...(isComparePart ? [] : [{ name: activeSubsection }]),
        ],
      };

  ctx.breadcrumb = _(ctx.breadcrumb)
    .filter((b) => b.name)
    .map((b) => ({
      ...b,
      name: unslugify(b.name),
    }))
    .value();

  return { ...ctx };
};

export default BreadcrumbProvider;
export { useBreadcrumb };
