import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { FilterDetailAnimationWrapper } from './filter-detail-animation-wrapper';
import { FilterDetailRow } from './filter-detail-row';
import { wrapperStyles } from './filterDetail.styles';
import { IFilterDetail } from './filterDetail.types';

export const FilterDetail: React.FC<IFilterDetail> = React.memo(({ data, isOpen }) => {
  return (
    <FilterDetailAnimationWrapper isOpen={isOpen} rowCount={data.length}>
      <div css={wrapperStyles}>
        {data.map((item) => (
          <FilterDetailRow key={uuidv4()} {...item} />
        ))}
      </div>
    </FilterDetailAnimationWrapper>
  );
});
