import { AvatarType } from '@lib/avatar';
import { icons } from '@lib/icon';
import { ITagsContainerOption } from '@lib/tags-container';
import { IColor } from '@styles/types';

export enum COLOR_TYPE {
  light = 0,
  medium = 1,
  green = 2,
}

export enum LABEL_SIZE {
  default = 0,
  small = 1,
}

export interface ITagLabel {
  avatarType: AvatarType;
  avatarColor?: IColor;
  avatarImage?: string;
  avatarIcon?: icons;
  bgType?: COLOR_TYPE;
  disabled?: boolean;
  label: string;
  labelSize?: LABEL_SIZE;
  truncate?: boolean;
  onRemove?: () => void;
}

export interface IMultiTagLabel {
  avatarType: AvatarType;
  disabled?: boolean;
  items: ITagsContainerOption[];
  withSmallTags: boolean;
  onRemove: (ITagsContainerOption) => void;
}
