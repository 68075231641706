import { css } from '@emotion/react';

import { ChartBlockSize } from '@align-pages/dashboard/config';

export const wrapperStyles = css`
  min-width: ${ChartBlockSize.WIDTH}px;
  height: ${ChartBlockSize.HEIGHT}px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
`;
