import { AccountsApiClass } from '@common-services/api/private/partials/AccountsApiClass';
import { BackofficeApiClass } from '@common-services/api/private/partials/BackofficeApiClass';
import { DashboardApi } from '@common-services/api/private/partials/Dashboard';
import { AlignGoalsApi } from '@common-services/api/private/partials/GoalsApiClass';
import { JiraIssuesApi } from '@common-services/api/private/partials/JiraIssuesApiClass';
import { MembersApi } from '@common-services/api/private/partials/MembersApiClass';
import { MetricsApiClass } from '@common-services/api/private/partials/MetricsApiClass';
import { PullRequestsApi } from '@common-services/api/private/partials/PullRequestsApiClass';
import { TeamTreeApi } from '@common-services/api/private/partials/TeamTreeApiClass';

class PrivateApiClass {
  public alignGoals: AlignGoalsApi;
  public metrics: MetricsApiClass;
  public backoffice: BackofficeApiClass;
  public pullRequests: PullRequestsApi;
  public accounts: AccountsApiClass;
  public dashboard: DashboardApi;
  public teamTree: TeamTreeApi;
  public members: MembersApi;
  public issues: JiraIssuesApi;
  constructor() {
    this.alignGoals = new AlignGoalsApi();
    this.metrics = new MetricsApiClass();
    this.backoffice = new BackofficeApiClass();
    this.pullRequests = new PullRequestsApi();
    this.accounts = new AccountsApiClass();
    this.dashboard = new DashboardApi();
    this.teamTree = new TeamTreeApi();
    this.members = new MembersApi();
    this.issues = new JiraIssuesApi();
  }
}

export const privateApi = new PrivateApiClass();
