import React from 'react';
import { CellProps, Renderer } from 'react-table';

import { columnStyles } from '@lib/Table/components/pr-table/prTable.styles';
import { IPrsTableRow } from '@lib/Table/components/pr-table/prTable.types';
import { Icon, icons } from '@lib/icon';

export const PullRequestsCellComments: Renderer<CellProps<IPrsTableRow>> = React.memo(({ row }) => {
  return (
    <div css={columnStyles}>
      <Icon icon={icons.comment} size={15} />
      <span className="column-value">{row.original.comments + row.original.review_comments}</span>
    </div>
  );
});
