import { useMutation } from 'react-query';

import { IUseMutationHook } from '@align-types/common';
import { useAuth0 } from '@common-context/Auth0';
import { useUserContext } from '@common-context/User';
import { useDemoCookie } from '@common-pages/Demo';
import { IApiBasicError } from '@common-services/api/common/types/common';
import { ProductFeatures } from '@common-services/api/public/generated-from-backend/models';

import { AccountsServices } from '../services/accounts';

export function useUpdateAccountFeature(): IUseMutationHook<
  ProductFeatures,
  IApiBasicError,
  ProductFeatures
> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();
  const {
    user: {
      defaultAccount: { id: accountId },
    },
  } = useUserContext();

  const updateAccountFeature = async (params: ProductFeatures): Promise<ProductFeatures> => {
    const token: string = isDemo === false ? await getTokenSilently() : null;
    return AccountsServices.updateAccountFeature({ accountId }, params, token);
  };

  const { mutate, isLoading, data, error, isError, isSuccess } = useMutation<
    ProductFeatures,
    IApiBasicError,
    ProductFeatures
  >(updateAccountFeature);

  return { mutate, isLoading, data, error, isError, isSuccess };
}
