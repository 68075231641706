import _ from 'lodash';
import React, { useEffect, useRef } from 'react';

import { PRODUCTION_ENV } from '@analytics-constants';
import { useBreadcrumb } from '@analytics-context/Breadcrumb';
import { useDataHelper } from '@analytics-context/Data';
import pageToDataIds from '@analytics-context/pageToDataIds';
import { useApi } from '@analytics-hooks';
import {
  fetchCIMetrics,
  fetchContributors,
  fetchDeploymentMetrics,
  fetchDevsMetrics,
  fetchHistogram,
  fetchPRsMetrics,
  fetchReleasesMetrics,
  getReleases,
  HISTOGRAM_LOG_SCALE,
  unwrap,
} from '@analytics-services/api';
import {
  fetchJIRAidentities,
  filterJIRAStuff,
  getJiraProjects,
} from '@analytics-services/api/jira';
import { DeveloperMetricID } from '@analytics-services/api/openapi-client';
import { DatetimeService } from '@analytics-services/datetimeService';
import { featuresList, getFeature } from '@analytics-services/flags';
import { LooseObject } from '@analytics-types/common';
import { useAuth0 } from '@common-context/Auth0';
import { FAKE_USERNAME } from '@common-pages/Settings/constants';
import { ApiType } from '@common-services/api/common/types/common';
import { FilteredEnvironment } from '@common-services/api/public/generated-from-backend/models';
import { fetchEnvironments } from '@common-services/api/public/services/environments';

const HISTOGRAM_GLOBAL_BINS = 15;

const calcVariation = (prev, curr) => (prev > 0 ? ((curr - prev) * 100) / prev : null);

const calcPeriodVariation = (period, idx) => {
  if (period?.values?.length !== 2) {
    return null;
  }

  return calcVariation(period.values[0].values?.[idx], period.values[1].values?.[idx]);
};

const fetchGlobalPRsMetrics = async (
  metrics,
  interval: DatetimeService.Interval,
  granularity,
  api,
  apiContext,
  groupBy = null
) => {
  try {
    const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, apiContext.features)
      ?.parameters?.value;
    return await fetchPRsMetrics(
      api,
      apiContext.account,
      granularity,
      interval,
      metrics,
      {
        repositories: apiContext.repositories,
        with: { author: apiContext.contributors },
        labels_include: apiContext.labels,
        ...(excludedLabels ? { labels_exclude: excludedLabels } : {}),
        jira: {
          epics: apiContext.epics,
          issue_types: apiContext.issueTypes,
        },
        environments: [PRODUCTION_ENV],
      },
      groupBy,
      apiContext.excludeInactive
    );
  } catch (err) {
    return new Error(
      `Could not fetch metrics; ${err.body?.detail || err.body?.type || err.error?.message || err}`
    );
  }
};

const prefetchPRsMetrics = async (api, apiContext, section, subsection, metrics) => {
  // const allMetrics = Object.keys(new PullRequestMetricID());
  const pageMetrics = metrics || pageToDataIds['prs-metrics']?.[section]?.[subsection];
  return await getMetricsAndVariations(
    (m, i, g) => fetchGlobalPRsMetrics(m, i, g, api, apiContext),
    (res) => res.calculated,
    pageMetrics,
    apiContext.interval
  );
};

const prefetchCIMetrics = async (api, apiContext) => {
  const metrics = [
    'chk-suites-count',
    'chk-success-ratio',
    'chk-flaky-commit-checks-count',
    'chk-suite-time-per-pr',
    'chk-suite-time',
    'chk-suites-per-pr',
  ];
  const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, apiContext.features)
    ?.parameters?.value;

  return await getMetricsAndVariations(
    (m, i, g) =>
      fetchCIMetrics(
        api,
        apiContext.account,
        i,
        g,
        m,
        apiContext.repositories,
        apiContext.contributors,
        apiContext.labels,
        excludedLabels || [],
        { epics: apiContext.epics, issue_types: apiContext.issueTypes }
      ),
    (res) => res.calculated,
    metrics,
    apiContext.interval
  );
};

const prefetchReposCIMetrics = async (api, apiContext) => {
  const metrics = ['chk-suites-count', 'chk-success-ratio'];
  const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, apiContext.features)
    ?.parameters?.value;
  const data = await fetchCIMetrics(
    api,
    apiContext.account,
    apiContext.interval,
    ['all'],
    metrics,
    apiContext.repositories,
    apiContext.contributors,
    apiContext.labels,
    excludedLabels || [],
    { epics: apiContext.epics, issue_types: apiContext.issueTypes },
    'repositories'
  );

  if (!data.calculated?.[0]) {
    return [];
  }

  const repositories = _(data.calculated)
    .map((v) => v.for.repositories[0])
    .value();
  const metricsValues = _(data.calculated)
    .map((v) => v.values[0].values)
    .value();
  const formattedData = _(repositories)
    .zipObject(
      _(metricsValues)
        .map((values) => _(metrics).zipObject(values).value())
        .value()
    )
    .value();

  return formattedData;
};

const getGlobalReleasesMetrics = async (
  api,
  account,
  interval: DatetimeService.Interval,
  repositories,
  contributors,
  labels,
  features,
  epics,
  issueTypes
) => {
  const metrics = ['count', 'avg-prs', 'prs', 'lines'];
  const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, features)?.parameters
    ?.value;

  return await getMetricsAndVariations(
    (m, i, g) =>
      fetchReleasesMetrics(
        api,
        account,
        g,
        i,
        m,
        repositories,
        contributors,
        labels,
        excludedLabels || [],
        { epics, issue_types: issueTypes }
      ),
    (res) => res,
    metrics,
    interval
  );
};

const getMetricsAndVariations = async (
  metricsFetcher,
  extractor,
  metrics,
  interval: DatetimeService.Interval
) => {
  const emptyMetrics = () => metrics.map(() => null);

  const customGranularity = calculateGranularity(interval);

  const getCustomGranularityMetrics = async () => {
    const granularity = customGranularity;
    const alignedGranularity = `aligned ${granularity}`;
    const currentPeriodGranularity = granularity === 'month' ? alignedGranularity : granularity;
    const metricsData = extractor(
      await metricsFetcher(metrics, { ...interval }, [currentPeriodGranularity])
    );

    if (metricsData.length === 0) {
      return [
        {
          values: [
            {
              values: emptyMetrics(),
            },
          ],
          granularity: 'custom',
        },
        {
          values: [
            {
              values: emptyMetrics(),
            },
          ],
          granularity: 'custom-aligned',
        },
      ];
    }

    const currentPeriodMetrics = _(metricsData).reduce((acc, granularMetric) => {
      const respGranularity = granularMetric.granularity;
      const aggGranularity =
        respGranularity === granularity
          ? 'custom'
          : respGranularity === alignedGranularity
          ? 'custom-aligned'
          : respGranularity;
      acc.push({
        granularity: aggGranularity,
        values: granularMetric.values,
      });

      return acc;
    }, []);

    return currentPeriodMetrics;
  };

  const getAllGranularityMetricsAndVariations = async () => {
    const prevInterval = interval.getPrevious();
    // get metrics values for previous period
    const metricsDataPrevInterval = extractor(
      await metricsFetcher(metrics, { from: prevInterval.from, to: prevInterval.to }, ['all'])
    );
    // get metrics values for current period
    const metricsDataCurrInterval = extractor(
      await metricsFetcher(metrics, { from: interval.from, to: interval.to }, ['all'])
    );

    if (metricsDataCurrInterval.length === 0 || metricsDataPrevInterval.values === 0) {
      return {
        metrics: {
          granularity: 'all',
          values: [
            {
              values: emptyMetrics(),
            },
          ],
          prevValues: [
            {
              values: emptyMetrics(),
            },
          ],
        },
        variations: _(metrics).reduce((acc, metric) => {
          acc[metric] = null;
          return acc;
        }, {}),
      };
    }

    const variationsSources = {
      values: [metricsDataPrevInterval[0].values[0], metricsDataCurrInterval[0].values[0]],
    };

    // calculate variations from comparing prev and curr periods
    const variations = metrics.reduce((acc, metric, i) => {
      acc[metric] = calcPeriodVariation(variationsSources, i);
      return acc;
    }, {});

    return {
      metrics: {
        granularity: 'all',
        values: [metricsDataCurrInterval[0].values[0]],
        prevValues: [metricsDataPrevInterval[0].values[0]],
      },
      variations,
    };
  };

  const [
    customGranularityMetrics,
    { metrics: allGranularityMetrics, variations },
  ] = await Promise.all([getCustomGranularityMetrics(), getAllGranularityMetricsAndVariations()]);

  const data = [...customGranularityMetrics, allGranularityMetrics];
  const values = unwrap(data, metrics);
  const prevValues = unwrap([allGranularityMetrics], metrics, true);

  return { variations, customGranularity, values, prevValues };
};

const prefetchPRsHistogram = async (api, apiContext) => {
  const allHistogramMetrics = [
    'lead-time',
    'wip-time',
    'review-time',
    'merging-time',
    'release-time',
    'deployment-time',
    'size',
  ];

  const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, apiContext.features)
    ?.parameters?.value;

  try {
    return await fetchHistogram(
      api,
      apiContext.account,
      apiContext.interval,
      allHistogramMetrics,
      {
        repositories: apiContext.repositories,
        with: { author: apiContext.contributors },
        labels_include: apiContext.labels,
        ...(excludedLabels ? { labels_exclude: excludedLabels } : {}),
        environments: [PRODUCTION_ENV],
      },
      apiContext.excludeInactive,
      HISTOGRAM_LOG_SCALE,
      HISTOGRAM_GLOBAL_BINS,
      null,
      { epics: apiContext.epics, issue_types: apiContext.issueTypes }
    );
  } catch (err) {
    return new Error(
      `Could not fetch histograms; ${
        err.body?.detail || err.body?.type || err.error.message || err
      }`
    );
  }
};

const prefetchReposMetrics = async (api, apiContext, section, subsection, metrics) => {
  // const metrics = Object.keys(new PullRequestMetricID());
  const pageMetrics = metrics || pageToDataIds['repos-metrics']?.[section]?.[subsection];
  const data = await fetchGlobalPRsMetrics(
    pageMetrics,
    apiContext.interval,
    ['all'],
    api,
    apiContext,
    'repositories'
  );

  if (!data.calculated?.[0]) {
    return [];
  }

  const repositories = _(data.calculated)
    .map((v) => v.for.repositories[0])
    .value();
  const metricsValues = _(data.calculated)
    .map((v) => v.values[0].values)
    .value();
  const formattedData = _(repositories)
    .zipObject(
      _(metricsValues)
        .map((values) => _(pageMetrics).zipObject(values).value())
        .value()
    )
    .value();

  return formattedData;
};

const prefetchDevsMetrics = async (api, apiContext) => {
  const metrics = Object.keys(new DeveloperMetricID());
  const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, apiContext.features)
    ?.parameters?.value;
  const data = await fetchDevsMetrics(
    api,
    apiContext.account,
    ['all'],
    apiContext.interval,
    metrics,
    {
      repositories: apiContext.repositories,
      developers: _(apiContext.contributors).uniqBy('login').value(),
      labels_include: apiContext.labels,
      ...(excludedLabels ? { labels_exclude: excludedLabels } : {}),
      jira: { epics: apiContext.epics, issue_types: apiContext.issueTypes },
    }
  );

  if (!data.calculated?.[0]) {
    return {};
  }

  const formattedData = _(data.calculated[0].for.developers)
    .zipObject(
      _(data.calculated[0].values)
        .map((values) => _(metrics).zipObject(values[0].values).value())
        .value()
    )
    .value();

  return formattedData;
};

const prefetchReleasesMetrics = async (api, apiContext) => {
  return getGlobalReleasesMetrics(
    api,
    apiContext.account,
    apiContext.interval,
    apiContext.repositories,
    apiContext.contributors.map((c) => c.login).filter((c) => c),
    apiContext.labels,
    apiContext.features,
    apiContext.epics,
    apiContext.issueTypes
  );
};

const prefetchContributors = async (api, apiContext) => {
  const { account, interval, repositories } = apiContext;
  const currInterval = interval;
  const prevInterval = interval.getPrevious();
  const [dataContribsPrev, dataContribsCurr] = await Promise.all([
    fetchContributors(api, account, prevInterval, { repositories }),
    fetchContributors(api, account, currInterval, { repositories }),
  ]);

  return Promise.resolve({
    prev: dataContribsPrev,
    curr: dataContribsCurr,
  });
};

const prefetchJiraIdentities = async (api, apiContext) => {
  return await fetchJIRAidentities(api, apiContext.account);
};

const fetchReleases = async (api, apiContext) => {
  const {
    account,
    interval,
    repositories,
    contributors,
    labels,
    features,
    epics,
    issueTypes,
  } = apiContext;
  const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, features)?.parameters
    ?.value;
  const releases = await getReleases(
    api,
    account,
    interval,
    repositories,
    _(contributors)
      .map('login')
      .filter((c) => c)
      .value(),
    labels,
    excludedLabels || [],
    {
      epics,
      issue_types: issueTypes,
    }
  );

  const latestReleasesIndexes = _(releases.data)
    .groupBy('repository')
    .mapValues((v) => _(releases.data).indexOf(_(v).orderBy('published', 'desc').value()[0]))
    .value();

  Object.values(latestReleasesIndexes).forEach((index) => {
    releases.data[index].latest_release = true;
  });

  return releases;
};

const fetchJiraPriorities = async (api, apiContext, all = null) => {
  return await fetchJiraEntities(api, apiContext, ['issues', 'priorities'], all);
};

const fetchJiraLabels = async (api, apiContext, all = null) => {
  return await fetchJiraEntities(api, apiContext, ['issues', 'labels'], all);
};

const fetchJiraIssueTypes = async (api, apiContext, all = null) => {
  const resp = await fetchJiraEntities(api, apiContext, ['issues', 'issue_types'], all);
  return {
    issue_types: _(resp.issue_types)
      .groupBy('normalized_name')
      .mapValues((v) => ({
        count: _(v).map('count').sum(),
        image: v[0].image,
        is_subtask: v[0].is_subtask,
        is_epic: v[0].is_epic,
        name: v[0].name,
        project: v[0].project,
        value: v[0].normalized_name,
      }))
      .values()
      .value(),
  };
};

const fetchJiraEpics = async (api, apiContext, all = null) => {
  return await fetchJiraEntities(
    api,
    apiContext,
    ['epics', 'issues', 'issue_bodies', 'only_flying'],
    all
  );
};

const fetchJiraStatuses = async (api, apiContext, all = null) => {
  return await fetchJiraEntities(api, apiContext, ['issues', 'statuses'], all);
};

const fetchJiraEntities = async (api, apiContext, what, all) => {
  const {
    account,
    interval,
    excludeInactive,
    contributors,
    includeNullContributor,
    includeFakeContributor,
  } = apiContext;
  const jiraAssignees = all ? [] : _(contributors).map('jira_user').filter().value();
  if (!all && includeNullContributor) {
    jiraAssignees.push(null);
  }
  if (!all && includeFakeContributor) {
    jiraAssignees.push(FAKE_USERNAME);
  }
  return await filterJIRAStuff(api, account, all ? null : interval, excludeInactive, what, {
    assignees: jiraAssignees,
  });
};

const fetchJiraProjects = async (api, apiContext) => {
  return await getJiraProjects(api, apiContext.account);
};

const prefetchEnvironments = async (
  apiContext: LooseObject,
  getTokenFn: () => string
): Promise<FilteredEnvironment[]> => {
  const token = await getTokenFn();
  return await fetchEnvironments(
    {
      account: apiContext.account,
      dateInterval: apiContext.interval,
      repositories: apiContext.repositories,
    },
    token
  );
};

const prefetchDeployments = async (
  api: ApiType,
  apiContext: LooseObject,
  getTokenFn: () => string
) => {
  const envs = await prefetchEnvironments(apiContext, getTokenFn);
  const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, apiContext.features)
    ?.parameters?.value;
  const metrics = [
    'count',
    'duration-successful',
    'prs-count',
    'success-ratio',
    'change-failure-ratio',
  ];

  return await fetchDeploymentMetrics(
    api,
    apiContext.account,
    apiContext.interval,
    ['all'],
    metrics,
    {
      environments: envs.map((env) => env.name),
      jira: { epics: apiContext.epics, issue_types: apiContext.issueTypes },
      pr_labels_include: apiContext.labels,
      pr_labels_exclude: excludedLabels || [],
      repositories: apiContext.repositories,
      with: { pr_author: apiContext.contributors.map((c) => c.login).filter((c) => !!c) },
    }
  );
};

const getRegisteredDataDefs = (
  api: ApiType,
  apiContext: LooseObject,
  section: string,
  subsection: string,
  getTokenFn: () => string
) => {
  const baseDataDefs = {
    'releases': () => fetchReleases(api, apiContext),
    'prs-metrics': (metrics) => prefetchPRsMetrics(api, apiContext, section, subsection, metrics),
    'prs-histogram': () => prefetchPRsHistogram(api, apiContext),
    'repos-metrics': (metrics) =>
      prefetchReposMetrics(api, apiContext, section, subsection, metrics),
    'devs-metrics': () => prefetchDevsMetrics(api, apiContext),
    'releases-metrics': () => prefetchReleasesMetrics(api, apiContext),
    'contributors': () => prefetchContributors(api, apiContext),
    'environments': () => prefetchEnvironments(apiContext, getTokenFn),
  };

  const ciDataDefs = {
    'ci-metrics': () => prefetchCIMetrics(api, apiContext),
    'repos-ci-metrics': () => prefetchReposCIMetrics(api, apiContext),
  };

  const jiraDataDefs = {
    'jira-identities': () => prefetchJiraIdentities(api, apiContext),
    'jira-priorities': () => fetchJiraPriorities(api, apiContext),
    'jira-priorities-all': () => fetchJiraPriorities(api, apiContext, true),
    'jira-labels': () => fetchJiraLabels(api, apiContext),
    'jira-issue-types': () => fetchJiraIssueTypes(api, apiContext),
    'jira-epics': () => fetchJiraEpics(api, apiContext),
    'jira-statuses': () => fetchJiraStatuses(api, apiContext),
    'jira-projects': () => fetchJiraProjects(api, apiContext),
  };

  const deploymentsDataDefs = {
    deployments: () => prefetchDeployments(api, apiContext, getTokenFn),
  };

  let dataDefs = { ...baseDataDefs };
  if (apiContext.user.hasCI) {
    dataDefs = { ...dataDefs, ...ciDataDefs };
  }
  if (apiContext.user.hasJIRA) {
    dataDefs = { ...dataDefs, ...jiraDataDefs };
  }
  if (apiContext.user.hasDeployments) {
    dataDefs = { ...dataDefs, ...deploymentsDataDefs };
  }

  return dataDefs;
};

const Prefetcher = ({ children }) => {
  const { api, context: apiContext, ready: apiReady } = useApi(true);
  const prevApiContext = useRef({});
  const { reset: resetData, missingMetrics, merge, register, setReloading } = useDataHelper();
  const { section, subsection } = useBreadcrumb();
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    if (!apiReady) {
      return;
    }

    if (!_.isEqual(prevApiContext.current, apiContext)) {
      resetData();
      prevApiContext.current = apiContext;
    }
  }, [apiReady, apiContext]);

  useEffect(() => {
    if (!apiReady) {
      return;
    }

    const dataDefs = getRegisteredDataDefs(api, apiContext, section, subsection, getTokenSilently);
    _(dataDefs).forEach((func, id) => {
      register(id, func);
    });
  }, [apiReady, apiContext, section, subsection]);

  // the following effect checks if there are any missing metrics in cache
  // and if there are fetches them and adds/merges to cache
  useEffect(() => {
    if (!apiReady) {
      return;
    }
    if (missingMetrics && Object.keys(missingMetrics).length > 0) {
      const fetchFunctions = getRegisteredDataDefs(
        api,
        apiContext,
        section,
        subsection,
        getTokenSilently
      );
      _(missingMetrics).forOwn(async (metrics, def) => {
        setReloading(def);
        const result = await fetchFunctions[def](metrics);
        merge(def, result, fetchFunctions[def]);
      });
    }
  }, [apiReady, missingMetrics]);

  return <>{children}</>;
};

export const calculateGranularity = (interval: DatetimeService.Interval) => {
  // TODO: Replace all the calls to direct usage
  return interval.calculateGranularity();
};

export default Prefetcher;
