import { useTheme } from '@emotion/react';
import React from 'react';

const Button = ({ children, onClick, disabled = false, style, ...props }) => {
  const theme = useTheme();

  const cssStyles = [
    {
      'border': '0',
      'background': 'transparent',
      'borderRadius': '0.35rem',
      'padding': `${theme.spacing.gap['02']} ${theme.spacing.gap['03']}`,
      ':focus': {
        boxShadow: 'none',
        outline: 0,
      },
      ':hover': {
        opacity: '0.7',
      },
    },
  ];
  if (disabled) {
    cssStyles.push({
      opacity: '0.65',
    });
  }

  cssStyles.push(style);
  return (
    <button disabled={disabled} css={cssStyles} onClick={onClick} {...props}>
      <span>{children}</span>
    </button>
  );
};

export default Button;
