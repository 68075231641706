import _ from 'lodash';

import { DATA_STATUS } from '@analytics-components/StatusIndicator';
import { EnvironmentType } from '@common-services/api/common/types/environments';
import { dateTime } from '@common-services/dateService';

interface ResultType {
  average: number;
  distribution: any;
  empty: boolean;
  environments: EnvironmentType[];
  formatFn: (v: number) => string;
  granularity: string;
  status: string;
  timeseries: any;
  variation: number;
}

const commonPlumber = (
  data: any,
  metric: string,
  metricCount: string,
  metricCountQ: string,
  noDataStatus: string
): ResultType => {
  const distribution = data['prs-histogram'][metric];
  const metricFullCount = data['prs-metrics']['values']['all'][metricCount];
  const metricQuantileCount = data['prs-metrics']['values']['all'][metricCountQ];
  const timeseriesGranularity =
    data['prs-metrics'].customGranularity === 'month' ? 'custom-aligned' : 'custom';
  const timeseries = _(data['prs-metrics']['values'][timeseriesGranularity][metric]).map((d) => ({
    x: d.date,
    y: d.value === null ? null : d.value * 1000,
  }));

  const stagePRsCount = data['prs-metrics']['values']['all'][metricCount];
  const allPRs = data['prs-metrics']['values']['all']['all-count'];
  return {
    granularity: data['prs-metrics'].customGranularity,
    average: data['prs-metrics']['values']['all'][metric],
    variation: data['prs-metrics']['variations'][metric],
    formatFn: (v) => dateTime.human(v * 1000),
    timeseries: timeseries.value(),
    distribution: {
      ...distribution,
      quantile: metricQuantileCount,
      leftCount: metricFullCount - _(distribution?.frequencies).sum() || 0,
    },
    empty: timeseries.map((v) => v.y).sum() === 0 && distribution?.empty,
    status: allPRs > 0 && stagePRsCount === 0 ? noDataStatus : DATA_STATUS.NO_DATA,
    environments: data['environments'],
  };
};

export default commonPlumber;
