import { css } from '@emotion/react';

export const taskIconStyles = ({ spacing }) => css`
  margin-right: ${spacing.gap['02']};
  width: 12px;
`;

export const tooltipWrapperStyles = css`
  align-items: center;
  display: flex;
`;
