import React from 'react';

import CleanAreaChart, { vertical } from '@analytics-components/charts/CleanAreaChart';

const PipelineCardMiniChart = ({ data, config, dataStatus }) => {
  const color = config.color;
  const fill = {
    direction: vertical,
    stops: [
      {
        offset: '0%',
        color,
        opacity: 0.8,
      },
      {
        offset: '100%',
        color,
        opacity: 0.1,
      },
    ],
  };

  const stroke = {
    direction: vertical,
    stops: [
      {
        offset: '0%',
        color,
        opacity: 1,
      },
      {
        offset: '100%',
        color,
        opacity: 1,
      },
    ],
  };

  return <CleanAreaChart fill={fill} stroke={stroke} data={data} dataStatus={dataStatus} />;
};

export default PipelineCardMiniChart;
