/**
 * {{ title }}
 * Server version: {{ server_version }} built on {{ build_date }} @ [{{ commit }}](https://github.com/athenianco/athenian-api/commit/{{ commit }})  Origin GitHub repository: [athenianco/api-spec](https://github.com/athenianco/api-spec).  Authorization persists between sessions. 🛡️ means that the endpoint requires account admin privileges. 👑 means that the endpoint is only accessible to Athenian authorities. <details> <summary>List of common server errors.</summary> * __500__ endpoint crashed. We reported the incident to Sentry and will fix it soon! Please include the value of `instance` in the response if you contact the support. Repeating the request will not help, most probably. * __501__ some functions are missing, either because they are not implemented yet or a non-critical runtime dependency is not satisfied. Repeating the request will not help, guaranteed. * __502__ server crashed badly, either due to a memory access violation in native code or running out of memory. We reported this incident to Sentry and will fix it soon! Try repeating the request. * __503__ server has not fully launched yet, e.g. hasn't connected to the database; server is shutting down; we are experiencing a partial outage. Try repeating the request. * __504__ endpoint took too much time and was interrupted. We reported the incident to Sentry and will see how to improve the performance. Repeating the request will not help, most probably. </details>
 *
 * Contact: admin@athenian.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class ReleaseMetricID.
* @enum {}
* @readonly
*/
export default class ReleaseMetricID {
    
        /**
         * value: "release-count"
         * @const
         */
        "count" = "release-count";

    
        /**
         * value: "release-tag-count"
         * @const
         */
        "tag-count" = "release-tag-count";

    
        /**
         * value: "release-branch-count"
         * @const
         */
        "branch-count" = "release-branch-count";

    
        /**
         * value: "release-prs"
         * @const
         */
        "prs" = "release-prs";

    
        /**
         * value: "release-tag-prs"
         * @const
         */
        "tag-prs" = "release-tag-prs";

    
        /**
         * value: "release-branch-prs"
         * @const
         */
        "branch-prs" = "release-branch-prs";

    
        /**
         * value: "release-commits"
         * @const
         */
        "commits" = "release-commits";

    
        /**
         * value: "release-tag-commits"
         * @const
         */
        "tag-commits" = "release-tag-commits";

    
        /**
         * value: "release-branch-commits"
         * @const
         */
        "branch-commits" = "release-branch-commits";

    
        /**
         * value: "release-lines"
         * @const
         */
        "lines" = "release-lines";

    
        /**
         * value: "release-tag-lines"
         * @const
         */
        "tag-lines" = "release-tag-lines";

    
        /**
         * value: "release-branch-lines"
         * @const
         */
        "branch-lines" = "release-branch-lines";

    
        /**
         * value: "release-avg-prs"
         * @const
         */
        "avg-prs" = "release-avg-prs";

    
        /**
         * value: "release-tag-avg-prs"
         * @const
         */
        "tag-avg-prs" = "release-tag-avg-prs";

    
        /**
         * value: "release-branch-avg-prs"
         * @const
         */
        "branch-avg-prs" = "release-branch-avg-prs";

    
        /**
         * value: "release-avg-commits"
         * @const
         */
        "avg-commits" = "release-avg-commits";

    
        /**
         * value: "release-tag-avg-commits"
         * @const
         */
        "tag-avg-commits" = "release-tag-avg-commits";

    
        /**
         * value: "release-branch-avg-commits"
         * @const
         */
        "branch-avg-commits" = "release-branch-avg-commits";

    
        /**
         * value: "release-avg-lines"
         * @const
         */
        "avg-lines" = "release-avg-lines";

    
        /**
         * value: "release-tag-avg-lines"
         * @const
         */
        "tag-avg-lines" = "release-tag-avg-lines";

    
        /**
         * value: "release-branch-avg-lines"
         * @const
         */
        "branch-avg-lines" = "release-branch-avg-lines";

    
        /**
         * value: "release-age"
         * @const
         */
        "age" = "release-age";

    
        /**
         * value: "release-tag-age"
         * @const
         */
        "tag-age" = "release-tag-age";

    
        /**
         * value: "release-branch-age"
         * @const
         */
        "branch-age" = "release-branch-age";

    
        /**
         * value: "release-time-to-deploy"
         * @const
         */
        "time-to-deploy" = "release-time-to-deploy";

    

    /**
    * Returns a <code>ReleaseMetricID</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/ReleaseMetricID} The enum <code>ReleaseMetricID</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

