import { css } from '@emotion/react';

const getStyle = ({ spacing: { gap } }) => css`
  padding: ${gap['0']};
  height: 59px;
  min-width: fit-content;
  display: flex;
  gap: ${gap['09']};
  align-items: flex-end;
`;

export { getStyle };
