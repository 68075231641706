import { AnimatePresence } from 'framer-motion';
import React, { useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { UsersPanelBodyProps } from '../UsersPanel.types';
import { MotionWrapper } from './MotionWrapper';
import { ShowUsers } from './ShowUsers';
import { UserRow } from './UserRow';
import { UserRowHeader } from './UserRowHeader';
import { getStyle } from './UsersPanelBody.styles';

const UsersPanelBody: React.FC<UsersPanelBodyProps> = React.memo(
  ({ childMembers, directMembers }) => {
    const [isDirectMembersExpanded, setIsDirectMembersExpanded] = useState(false);
    const [isChildMembersExpanded, setIsChildMembersExpanded] = useState(false);
    const onShowDirectMembersClick = useCallback(() => {
      setIsDirectMembersExpanded(true);
    }, [isDirectMembersExpanded]);
    const onShowChildMembersClick = useCallback(() => {
      setIsChildMembersExpanded(true);
    }, [isChildMembersExpanded]);

    return (
      <div css={getStyle(!directMembers)}>
        <AnimatePresence>
          {isDirectMembersExpanded && directMembers ? (
            <MotionWrapper>
              <UserRowHeader text={`${directMembers.length} direct team members`} />
              {directMembers.map((user) => (
                <UserRow key={uuidv4()} {...user} />
              ))}

              {childMembers?.length ? (
                isChildMembersExpanded ? (
                  <MotionWrapper>
                    <UserRowHeader text={`${childMembers.length} child team members`} />
                    {childMembers.map((user) => (
                      <UserRow key={uuidv4()} {...user} />
                    ))}
                  </MotionWrapper>
                ) : (
                  <ShowUsers
                    onClick={onShowChildMembersClick}
                    text={`Show ${childMembers.length} child team members`}
                  />
                )
              ) : null}
            </MotionWrapper>
          ) : (
            <ShowUsers
              onClick={onShowDirectMembersClick}
              text={`Show ${directMembers.length} direct team members`}
            />
          )}
        </AnimatePresence>
      </div>
    );
  }
);

export { UsersPanelBody };
