import React from 'react';

import { Spinner } from '@lib/Spinner';

import { wrapperStyles } from './LoadingView.styles';

export const LoadingView: React.FC = () => {
  return (
    <div css={wrapperStyles}>
      <Spinner loading />
    </div>
  );
};
