import React from 'react';

import { useUserContext } from '@common-context/User';

import { leftSideStyles, wrapperStyles } from './Navbar.styles';
import { NavbarButtons, NavbarLogo, NavbarUser } from './components';

interface INavbar {
  backButton?: boolean;
}

export const Navbar: React.FC<INavbar> = React.memo(({ backButton }) => {
  const { user, isDemo } = useUserContext();

  return (
    <div css={wrapperStyles} className="navbar navbar-expand-sm">
      <div css={leftSideStyles}>
        <NavbarLogo backButton={backButton} />
        {!backButton && <NavbarButtons />}
      </div>
      <NavbarUser user={user} isDemo={isDemo} />
    </div>
  );
});
