import React, { useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useNavigation } from '@align-components/layouts/primary/context';
import { useGoalsWithTarget } from '@align-pages/goals/list/active-goals/activeGoals.hooks';
import { GoalsSingleComponentServices } from '@align-pages/goals/single/components/goals-single-component/goalsSingleComponent.services';
import { DateService } from '@align-services/dateService';
import { useHistory } from '@common-hooks/useHistory';
import { sortBy } from '@common-services/vendor/lodash';
import { Card } from '@lib/Card';
import { NoActiveGoals } from '@lib/empty/noActiveGoals';

import { useArchiveGoal } from './activeGoals.hooks';
import { titleStyles } from './activeGoals.styles';
import { ActiveGoal, ActiveGoalPlaceholder } from './components';

enum ActiveGoalsTitle {
  ACTIVE = 'Active Goals',
  FUTURE = 'Future Goals',
  COMPLETED = 'Completed Goals',
}

export const ActiveGoals: React.FC = React.memo(() => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { currentTeam } = useNavigation();

  const { data: goalsData, isLoading, refetch: refetchGoals } = useGoalsWithTarget(
    currentTeam.id,
    true
  );

  const handleArchive = useArchiveGoal(refetchGoals);

  const routeToCreateGoal = useCallback(() => {
    history.pushWithBackState(`${url}/add`);
  }, [history, url]);

  const completedGoals = useMemo(
    () =>
      sortBy(goalsData.filter((goal) => DateService.isBeforeToday(goal.expires_at)) || [], [
        'valid_from',
        'name',
      ]),
    [goalsData]
  );

  const futureGoals = useMemo(
    () =>
      sortBy(goalsData.filter((goal) => DateService.isAfterToday(goal.valid_from)) || [], [
        'valid_from',
        'name',
      ]),
    [goalsData]
  );

  const activeGoals = useMemo(
    () =>
      sortBy(
        goalsData.filter(
          (goal) =>
            !DateService.isBeforeToday(goal.expires_at) &&
            !DateService.isAfterToday(goal.valid_from)
        ) || [],
        ['valid_from', 'name']
      ),
    [goalsData]
  );

  const noGoals = useMemo(
    () => !activeGoals?.length && !futureGoals?.length && !completedGoals?.length,
    [activeGoals, futureGoals, completedGoals]
  );

  return (
    <Card
      headerText={
        noGoals || activeGoals?.length
          ? ActiveGoalsTitle.ACTIVE
          : futureGoals.length
          ? ActiveGoalsTitle.FUTURE
          : ActiveGoalsTitle.COMPLETED
      }
      headerButtonText="Add Goal"
      onHeaderButtonClick={routeToCreateGoal}
    >
      {isLoading ? (
        <ActiveGoalPlaceholder />
      ) : noGoals ? (
        <NoActiveGoals />
      ) : (
        <>
          {activeGoals.map((goal) => (
            <ActiveGoal
              key={goal.id}
              goal={goal}
              team={GoalsSingleComponentServices.deNormaliseAllLevels(goal.team_goal)}
              onArchive={handleArchive}
            />
          ))}
          {activeGoals?.length && futureGoals?.length ? (
            <div css={titleStyles}>{ActiveGoalsTitle.FUTURE}</div>
          ) : null}
          {futureGoals.map((goal) => (
            <ActiveGoal
              key={goal.id}
              goal={goal}
              team={GoalsSingleComponentServices.deNormaliseAllLevels(goal.team_goal)}
              onArchive={handleArchive}
            />
          ))}
          {(activeGoals?.length || futureGoals?.length) && completedGoals?.length ? (
            <div css={titleStyles}>{ActiveGoalsTitle.COMPLETED}</div>
          ) : null}
          {completedGoals.map((goal) => (
            <ActiveGoal
              key={goal.id}
              goal={goal}
              team={GoalsSingleComponentServices.deNormaliseAllLevels(goal.team_goal)}
              onArchive={handleArchive}
            />
          ))}
        </>
      )}
    </Card>
  );
});
