import React, { useCallback, useEffect, useState } from 'react';

import { useApi } from '@analytics-hooks';
import { changeUserRole, getAccountDetails, removeUser } from '@analytics-services/api';
import { useUserContext } from '@common-context/User';
import { SettingsGroup } from '@common-pages/Settings';
import log from '@common-services/logger';
import { Spinner } from '@lib/Spinner';
import { NoData } from '@lib/empty/noData';
import Breadcrumb from '@ui/Breadcrumb';

import { spinnerWrapperStyles } from './Users.styles';
import { IUserWithRole } from './Users.types';
import { UsersList } from './components/UsersList';

const roles = ['Role: Admin', 'Role: Regular'];

const Users = () => {
  const { api, ready: apiReady, context } = useApi(true, false);
  const { isAdmin }: { isAdmin: boolean } = useUserContext();
  const [isLoading, setIsLoading] = useState(true);

  const [users, setUsers] = useState<IUserWithRole[]>([]);

  useEffect(() => {
    if (!apiReady) return;
    getAccountDetails(api, context.account).then((account) => {
      const admins = account?.admins.map((user) => ({
        ...user,
        role: roles[0],
      }));
      const regulars = account?.regulars.map((user) => ({
        ...user,
        role: roles[1],
      }));
      setUsers((admins || []).concat(regulars || []).sort((a, b) => (a.name > b.name ? 1 : -1)));
      setIsLoading(false);
    });
  }, [api, apiReady, context.account]);

  const handleRemoveUser = useCallback(
    (userId: string) => () => {
      if (!apiReady) return;
      removeUser(api, context.account, userId);
      setUsers(users.filter((user) => user.id !== userId));
      log.ok('User has successfully been removed');
    },
    [api, apiReady, context, users]
  );

  const handleRoleChange = useCallback(
    (userId: string, role: string) => {
      if (!apiReady) return;
      const newRole = role === roles[0] ? 'admin' : 'regular';
      changeUserRole(api, context.account, userId, newRole)
        .then(() => {
          const updatedUsers = [...users];
          const affectedUser = updatedUsers.find((user) => user.id === userId);
          if (affectedUser) {
            affectedUser.role = role;
            setUsers(updatedUsers);
            log.ok('User role has successfully been updated');
          }
        })
        .catch(() => {
          setUsers([...users]);
          log.error('Role change was unsuccesful, please try again');
        });
    },
    [api, apiReady, context, users]
  );

  return (
    <>
      <Breadcrumb breadcrumb={[{ name: 'Settings' }, { name: 'Users' }]} />
      <SettingsGroup title="Members">
        {isLoading ? (
          <div css={spinnerWrapperStyles}>
            <Spinner loading />
          </div>
        ) : users.length > 0 ? (
          <UsersList
            isAdmin={isAdmin}
            roles={roles}
            users={users}
            onRemoveUser={handleRemoveUser}
            onRoleChange={handleRoleChange}
          />
        ) : (
          <NoData />
        )}
      </SettingsGroup>
    </>
  );
};

export { Users };
