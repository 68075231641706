import { css } from '@emotion/react';

export const columnStyles = ({ color, font, spacing }) => css`
  align-items: center;
  display: flex;
  font-size: ${font.size.default};
  font-weight: ${font.weight.light};
  white-space: nowrap;

  .column-value {
    color: ${color.text.secondary};
    margin-left: ${spacing.gap['02']};
  }

  .size-added {
    color: ${color.status.success};
    margin-left: ${spacing.gap['05']};
  }

  .size-removed {
    color: ${color.status.danger};
    margin-left: ${spacing.gap['03']};
  }
`;

export const badgeStyles = css`
  width: 159px;
`;

export const tableHeaderStyles = css`
  th:first-of-type {
    max-width: 40px;
  }
`;

export const tableBodyStyles = ({ spacing }) => css`
  && td {
    &:first-of-type > div {
      justify-content: center;
      padding: 0 0 0 ${spacing.gap['05']};
      &.show-more {
        justify-content: flex-start;
      }
    }

    &:not(:first-of-type) > div {
      justify-content: flex-start;
      padding: ${spacing.gap['0']} ${spacing.gap['04']};
    }
  }
`;
