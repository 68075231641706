import _ from 'lodash';

import {
  METRICS_JIRA_ENDPOINT,
  METRICS_PRS_ENDPOINT,
  METRICS_RELEASES_ENDPOINT,
  METRICS_CHECKS_ENDPOINT,
} from '@analytics-services/api';

const CHART_TYPE_ABSOLUTE = 'absolute';
const CHART_TYPE_RELATIVE = 'relative';
const METRIC_DURATION_FORMAT = 'duration';
const METRIC_PERCENTAGE_FORMAT = 'percentage';
const METRIC_COUNT_FORMAT = 'count';
const metricsGroups = [
  {
    group: 'Velocity',
    metrics: [
      {
        displayName: 'Lead Time',
        sources: ['lead-time'],
        endpoint: METRICS_JIRA_ENDPOINT,
        metricFormat: {
          type: METRIC_DURATION_FORMAT,
        },
        selected: true,
        requiresJira: true,
      },
      {
        displayName: 'PR Cycle Time',
        sources: ['lead-time'],
        endpoint: METRICS_PRS_ENDPOINT,
        metricFormat: {
          type: METRIC_DURATION_FORMAT,
        },
      },
      {
        displayName: 'WIP Time',
        sources: ['wip-time'],
        endpoint: METRICS_PRS_ENDPOINT,
        metricFormat: {
          type: METRIC_DURATION_FORMAT,
        },
      },
      {
        displayName: 'Review Time',
        sources: ['review-time'],
        endpoint: METRICS_PRS_ENDPOINT,
        metricFormat: {
          type: METRIC_DURATION_FORMAT,
        },
      },
      {
        displayName: 'Merge Time',
        sources: ['merging-time'],
        endpoint: METRICS_PRS_ENDPOINT,
        metricFormat: {
          type: METRIC_DURATION_FORMAT,
        },
      },
      {
        displayName: 'Release Time',
        sources: ['release-time'],
        endpoint: METRICS_PRS_ENDPOINT,
        metricFormat: {
          type: METRIC_DURATION_FORMAT,
        },
      },
    ],
  },
  {
    group: 'Review',
    metrics: [
      {
        displayName: '1st Review Wait Time',
        sources: ['wait-first-review-time'],
        endpoint: METRICS_PRS_ENDPOINT,
        metricFormat: {
          type: METRIC_DURATION_FORMAT,
        },
      },
    ],
  },
  {
    group: 'Throughput',
    metrics: [
      {
        displayName: 'Releases',
        sources: ['count'],
        endpoint: METRICS_RELEASES_ENDPOINT,
        metricFormat: {
          type: METRIC_COUNT_FORMAT,
          label: 'Releases',
        },
        chartType: CHART_TYPE_ABSOLUTE,
      },
      {
        displayName: 'PRs Created',
        sources: ['opened'],
        endpoint: METRICS_PRS_ENDPOINT,
        metricFormat: {
          type: METRIC_COUNT_FORMAT,
          label: 'Pull Requests',
        },
        selected: true,
        chartType: CHART_TYPE_ABSOLUTE,
      },
      {
        displayName: 'PRs Merged',
        sources: ['merged'],
        endpoint: METRICS_PRS_ENDPOINT,
        metricFormat: {
          type: METRIC_COUNT_FORMAT,
          label: 'Pull Requests',
        },
        chartType: CHART_TYPE_ABSOLUTE,
      },
      {
        displayName: 'PRs Released',
        sources: ['lead-count'],
        endpoint: METRICS_PRS_ENDPOINT,
        metricFormat: {
          type: METRIC_COUNT_FORMAT,
          label: 'Pull Requests',
        },
        chartType: CHART_TYPE_ABSOLUTE,
      },
      {
        displayName: 'Issues Created',
        sources: ['open'],
        endpoint: METRICS_JIRA_ENDPOINT,
        metricFormat: {
          type: METRIC_COUNT_FORMAT,
          label: 'Issues',
        },
        chartType: CHART_TYPE_ABSOLUTE,
        requiresJira: true,
      },
      {
        displayName: 'Issues Resolved',
        sources: ['resolved'],
        endpoint: METRICS_JIRA_ENDPOINT,
        metricFormat: {
          type: METRIC_COUNT_FORMAT,
          label: 'Issues',
        },
        chartType: CHART_TYPE_ABSOLUTE,
        requiresJira: true,
      },
    ],
  },
  {
    group: 'Quality',
    metrics: [
      {
        displayName: 'PR Size (average)',
        sources: ['size'],
        endpoint: METRICS_PRS_ENDPOINT,
        metricFormat: {
          type: METRIC_COUNT_FORMAT,
          label: 'Lines of code',
        },
      },
      {
        displayName: 'PR Size (median)',
        sources: ['median-size'],
        endpoint: METRICS_PRS_ENDPOINT,
        metricFormat: {
          type: METRIC_COUNT_FORMAT,
          label: 'Lines of code',
        },
      },
      {
        displayName: 'PR Mapping',
        sources: ['all-mapped-to-jira'],
        endpoint: METRICS_PRS_ENDPOINT,
        metricFormat: {
          type: METRIC_PERCENTAGE_FORMAT,
        },
        requiresJira: true,
      },
      {
        displayName: 'MTTRestore',
        sources: ['life-time'],
        buildFilter: async (api, cachedData, apiContext) => {
          return buildTopPrioritiesBugsFilter(cachedData['jira-priorities-all'].priorities);
        },
        endpoint: METRICS_JIRA_ENDPOINT,
        metricFormat: {
          type: METRIC_DURATION_FORMAT,
        },
        requiresJira: true,
      },
      {
        displayName: 'MTTRepair',
        sources: ['lead-time'],
        buildFilter: async (api, cachedData, apiContext) => {
          return buildTopPrioritiesBugsFilter(cachedData['jira-priorities-all'].priorities);
        },
        endpoint: METRICS_JIRA_ENDPOINT,
        metricFormat: {
          type: METRIC_DURATION_FORMAT,
        },
        requiresJira: true,
      },
      {
        displayName: 'MTTAcknowledge',
        sources: ['acknowledge-time'],
        buildFilter: async (api, cachedData, apiContext) => {
          return buildTopPrioritiesBugsFilter(cachedData['jira-priorities-all'].priorities);
        },
        endpoint: METRICS_JIRA_ENDPOINT,
        metricFormat: {
          type: METRIC_DURATION_FORMAT,
        },
        requiresJira: true,
      },
    ],
  },
  {
    group: 'CI',
    metrics: [
      // {
      //   displayName: 'Run Time / PR',
      //   sources: ['chk-suite-time-per-pr'],
      //   endpoint: METRICS_CHECKS_ENDPOINT,
      //   metricFormat: {
      //     type: METRIC_DURATION_FORMAT,
      //   },
      // },
      {
        displayName: 'Success Ratio',
        sources: ['chk-success-ratio'],
        endpoint: METRICS_CHECKS_ENDPOINT,
        metricFormat: {
          type: METRIC_PERCENTAGE_FORMAT,
        },
      },
      {
        displayName: 'Flakiness',
        sources: ['chk-flaky-commit-checks-count', 'chk-suites-count'],
        endpoint: METRICS_CHECKS_ENDPOINT,
        aggregate: (values) => values['chk-flaky-commit-checks-count'] / values['chk-suites-count'],
        metricFormat: {
          type: METRIC_PERCENTAGE_FORMAT,
        },
      },
    ],
  },
];

const buildTopPrioritiesBugsFilter = (priorities) => {
  const topPrioties = _(priorities.slice(0, 2)).map('name').value();

  return {
    types: ['Bug'],
    priorities: topPrioties,
  };
};

export default metricsGroups;
export {
  CHART_TYPE_ABSOLUTE,
  CHART_TYPE_RELATIVE,
  METRIC_DURATION_FORMAT,
  METRIC_PERCENTAGE_FORMAT,
  METRIC_COUNT_FORMAT,
};
