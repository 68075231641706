import React from 'react';

import Box from '@analytics-components/insights/Box';
import { InsightsError } from '@analytics-components/insights/Helper';
import { useDataWidget } from '@analytics-context/DataWidget';
import { isFeatureEnabled, featuresList } from '@analytics-services/flags';
import { useUserContext } from '@common-context/User';

import allocationChart from './allocationChart';
import allocationTotal from './allocationTotal';
import teamsAllocationTable from './teamsAllocationTable';
import throughputChart from './throughputChart';
import throughputTotal from './throughputTotal';

const InsightsAllocationDataBinder = ({ contentType }) => {
  const { data, error, isLoading } = useDataWidget();
  const { features } = useUserContext();

  const isTeamAllocationTableEnabled = isFeatureEnabled(
    featuresList.team_allocation_table_enabled,
    features
  );
  const { isGod } = useUserContext();

  if (isLoading) {
    return <div className="insights-placeholder" />;
  }

  if (error) {
    return <InsightsError />;
  }

  const throughputChartInsight = {
    component: Box,
    data: throughputChart.factory(data.throughputChartData, contentType),
  };
  const throughputTotalInsight = {
    component: Box,
    data: throughputTotal.factory(data.throughputTotalData, contentType),
  };
  const allocationChartInsight = {
    component: Box,
    data: allocationChart.factory(data.allocationChartData, contentType),
  };
  const allocationTotalInsight = {
    component: Box,
    data: allocationTotal.factory(data.allocationTotalData, contentType),
  };
  const teamsAllocationTableInsight = isGod &&
    isTeamAllocationTableEnabled && {
      component: teamsAllocationTable.component,
      props: { ...data.teamsAllocationTableData, contentType },
    };

  return (
    <>
      <div className="row mx-0 px-0 mb-4">
        <div className="col-md-9 mx-0 pl-0 pr-1">
          <throughputChartInsight.component {...throughputChartInsight.data} />
        </div>
        <div className="col-md-3 mx-0 pl-3 pr-0">
          <throughputTotalInsight.component {...throughputTotalInsight.data} />
        </div>
      </div>
      <div className="row mx-0 px-0 mb-4">
        <div className="col-md-9 mx-0 pl-0 pr-1">
          <allocationChartInsight.component {...allocationChartInsight.data} />
        </div>
        <div className="col-md-3 mx-0 pl-3 pr-0">
          <allocationTotalInsight.component {...allocationTotalInsight.data} />
        </div>
      </div>
      {isGod && isTeamAllocationTableEnabled && (
        <div className="row mx-0 px-0 mb-4">
          <div className="col-md-12 mx-0 pl-0 pr-0">
            <teamsAllocationTableInsight.component {...teamsAllocationTableInsight.props} />
          </div>
        </div>
      )}
    </>
  );
};

export default InsightsAllocationDataBinder;
