import React from 'react';

import { IRow } from './types';

interface ICellTeamSingleLine {
  row: IRow;
}

export const CellTeamSingleLine: React.FC<ICellTeamSingleLine> = React.memo(({ row }) => (
  <>
    <div
      css={({ spacing }) => ({
        background: `url(${row.original.avatar})`,
        marginLeft: spacing.gap['08'],
      })}
    />
    <p>{row.values.name}</p>
  </>
));
