import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Tab } from '../components/Tab';
import { getStyle } from './TabsUI.styles';
import { Props } from './TabsUI.types';

const TabsUI: React.FC<Props> = React.memo(({ tabs }) => {
  return (
    <div css={getStyle}>
      {tabs.map((t) => (
        <Tab key={uuidv4()} {...t} />
      ))}
    </div>
  );
});

export { TabsUI };
