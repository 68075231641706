import React from 'react';

import { QualityMainMetrics as MainMetrics } from '@analytics-components/MainMetrics';
import { BIG_PR_THRESHOLD } from '@analytics-pages/delivery-pipeline/insights/work-in-progress';
import { useUserContext } from '@common-context/User';
import { dateTime } from '@common-services/dateService';
import { number } from '@common-services/format';
import * as NumberService from '@common-services/numberService';

import Insights from './insights';
import { subLabelStyles } from './styles';

const mainMetricsDefinitions = (hasJira) => [
  ...(hasJira
    ? [
        {
          title: 'Bugs',
          dataGetter: (data) => [
            { raw: data.bugs.avg, formatted: data.bugs.avg },
            data.bugs.variation,
          ],
          hint: {
            primaryText: 'Number of bugs raised during the time period selected.',
          },
          emptyMessage: 'No bugs',
          negativeIsBetter: true,
        },
        {
          title: 'MTTR',
          dataGetter: (data) => [
            { raw: data.MTTR.avg, formatted: dateTime.human(data.MTTR.avg) },
            data.MTTR.variation,
          ],
          hint: {
            primaryText:
              'Mean Time To Restore (ie. from the bug filing to its resolution) for bugs with the two highest priority levels. ',
            secondaryText: 'Calculated up to the 95th percentile.',
          },
          emptyMessage: 'No bugs',
          negativeIsBetter: true,
        },
        {
          title: 'Bug Fixing',
          dataGetter: (data) => [
            {
              raw: data.bugFixing.avg,
              formatted: number.percentage(data.bugFixing.avg),
            },
            data.bugFixing.variation,
          ],
          hint: {
            primaryText: 'Percentage of resolved tickets being bugs',
          },
          emptyMessage: 'No bugs',
          negativeIsBetter: true,
        },
      ]
    : [
        {
          title: 'Code Review',
          dataGetter: (data) => [
            { raw: data.reviews.avg, formatted: number.percentage(data.reviews.avg) },
            data.reviews.variation,
          ],
          hint: {
            primaryText: 'Proportion of pull requests reviewed.',
          },
          emptyMessage: 'No reviews',
        },
        {
          title: 'Comments',
          dataGetter: (data) => [
            {
              raw: data.comments.avg,
              formatted: (
                <>
                  {number.fixed(data.comments.avg, 1)}
                  <span css={subLabelStyles}> / PR</span>
                </>
              ),
            },
            data.comments.variation,
          ],
          hint: {
            primaryText: 'Average number of review comments per pull request.',
          },
          emptyMessage: 'No comments',
        },
      ]),
  {
    title: 'PR Size',
    dataGetter: (data) => [
      { raw: data.PRsSize.avg || 0, formatted: `${NumberService.round(data.PRsSize.avg)} lines` },
      data.PRsSize.variation,
    ],
    hint: {
      primaryText: 'Average pull request size in absolute lines of code changed.',
      secondaryText: 'Calculated up to the 95th percentile.',
    },
    emptyMessage: 'No PRs',
    negativeIsBetter: true,
  },
  ...(!hasJira
    ? [
        {
          title: `PRs < ${BIG_PR_THRESHOLD} lines`,
          dataGetter: (data) => [
            { raw: data.prsLines.avg, formatted: number.percentage(data.prsLines.avg) },
            data.prsLines.variation,
          ],
          hint: {
            primaryText: 'Proportion of pull requests where less than 500 lines have been changed.',
          },
          emptyMessage: 'No PRs',
        },
      ]
    : []),
];

const Content = ({ data, status }) => {
  const { account } = useUserContext();

  return (
    <>
      <MainMetrics data={data} status={status} definitions={mainMetricsDefinitions(account.jira)} />
      <Insights />
    </>
  );
};

export default Content;
