import { ITypeOptions } from './types';

export const PLACEHOLDER_PATTERNS = {
  [ITypeOptions.BRANCH]: 'branch_name',
  [ITypeOptions.TAG]: '.*',
};

export const DEFAULT_PATTERNS = {
  [ITypeOptions.BRANCH]: '{{default}}',
  [ITypeOptions.TAG]: '.*',
};

export const USE_DEFAULT_PATTERN = 'default';
export const USE_CUSTOM_PATTERN = 'custom';
