import classnames from 'classnames';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

import { sanitize } from '@common-services/htmlService';
import { ITooltipProps, TooltipPlace } from '@lib/tooltip/tooltip.types';

export const Tooltip: React.FC<ITooltipProps> = ({
  content,
  className,
  style,
  children,
  place = TooltipPlace.BOTTOM,
  enabled = true,
}) => {
  const id = `prefix-${uuidv4()}`;
  const isString = typeof content === 'string';

  return enabled ? (
    <>
      <div
        data-tip
        data-for={id}
        className={classnames('d-inline-block', className)}
        style={{ ...style, pointerEvents: 'auto' }}
      >
        {children}
      </div>

      <ReactTooltip
        id={id}
        className="athenian-tooltip"
        offset={{ top: 4, left: 0 }}
        place={place}
        type="light"
        effect="solid"
        delayHide={100}
        multiline
        html={!!isString}
        getContent={() => (!!isString ? sanitize(content) : content)}
      />
    </>
  ) : (
    <>{children}</>
  );
};

Tooltip.defaultProps = {
  style: {},
};
