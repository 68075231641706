import { useTheme } from '@emotion/react';
import classnames from 'classnames';
import React from 'react';

import {
  filterStyles,
  filterDropdownStyles,
  filterDropdownLabelStyles,
  filterDropdownPillStyles,
} from '@analytics-components/MultiSelect/styles';
import { StatusIndicator, LOADING } from '@analytics-components/StatusIndicator';
import { ReactComponent as DropdownIndicator } from '@assets/icons/IconDropdownIndicator.svg';

const Dropdown = ({ label, isLoading, isOpen, count, onClick, isDisabled }) => {
  const theme = useTheme();

  return (
    <div
      css={filterStyles}
      className={`${classnames({ open: isOpen })}`}
      style={{ display: isDisabled ? 'none' : 'flex' }}
      onClick={onClick}
    >
      <div css={filterDropdownStyles}>
        <span css={filterDropdownLabelStyles}>{label}</span>
        {isLoading || count == null ? (
          <StatusIndicator size={5} status={LOADING} margin={theme.spacing.gap['0']} />
        ) : (
          <span css={filterDropdownPillStyles} data-cy="dropdown-count">
            {count}
          </span>
        )}
      </div>
      <DropdownIndicator />
    </div>
  );
};

export default Dropdown;
