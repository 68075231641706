import {
  IPropertyReducerAction,
  PropertyReducerTypes,
} from '@align-pages/dashboard/reducers/propertyReducer.types';
import { INewChartParam } from '@align-pages/dashboard/types';
import { isEqual } from '@common-services/vendor/lodash';

export function propertyReducer(
  state: INewChartParam[],
  action: IPropertyReducerAction
): INewChartParam[] {
  switch (action.type) {
    case PropertyReducerTypes.ADD_NEW:
      return [
        ...state,
        { id: `new-${action.paramType}`, type: action.paramType, property: null, rules: [] },
      ];
    case PropertyReducerTypes.ADD_EXISTING:
      return action.data as INewChartParam[];
    case PropertyReducerTypes.UPDATE:
      let newState = [...state];
      const dataUpdate = action.data as INewChartParam;
      const targetId = newState.findIndex(
        ({ id }) => id === `${dataUpdate.type}/${dataUpdate.id}` || id === `new-${dataUpdate.type}`
      );
      const isNewProperty = newState[targetId].id.includes('new');

      if (
        !isNewProperty &&
        isEqual(newState[targetId].property, dataUpdate.property) &&
        isEqual(newState[targetId].rules, dataUpdate.rules)
      ) {
        return state;
      }

      newState[targetId].id = `${dataUpdate.type}/${dataUpdate.property}`;
      newState[targetId].property = dataUpdate.property;
      newState[targetId].rules = dataUpdate.rules;
      return newState;
    case PropertyReducerTypes.REMOVE:
      const dataRemove = action.data as INewChartParam;
      return [
        ...state.filter(
          ({ id, property, type }) =>
            !(
              (id === dataRemove.id || property === dataRemove.property) &&
              type === dataRemove.type
            )
        ),
      ];
    case PropertyReducerTypes.CLEAR_PARAM:
      return [...state.filter(({ type }) => type !== action.paramType)];
    default:
      return [...state];
  }
}
