import { default as nullUserImage } from '@assets/icons/default_user.svg';
import { theme } from '@styles/theme';

import {
  GetJIRAIssuesResponse,
  JIRAIssueType,
  JIRAStatus,
  JIRAStatusCategory,
  JIRAUser,
} from './api/public/generated-from-backend/models';

export const statusColors = {
  'To Do': theme.color.neutral['80'],
  'In Progress': theme.color.ui.blue['100'],
  'Done': theme.color.ui.green['100'],
};

export const getIssueTypeImage = (type: string, cachedIssueTypes: JIRAIssueType[]): string =>
  cachedIssueTypes.find((v) => v.name === type)?.image;

export const getStatusStage = (
  status: string,
  project: string,
  cachedStatuses: JIRAStatus[]
): JIRAStatusCategory => {
  const searchedStatus = cachedStatuses.find(
    (s) =>
      s.name.toLowerCase() === status.toLowerCase() &&
      s.project.toLowerCase() === project.toLowerCase()
  );
  if (searchedStatus?.stage) {
    return searchedStatus.stage;
  }
  return 'To Do';
};

export const getAvatar = (userName: string, contribsOptions): string => {
  const jiraIdentity = contribsOptions.find(
    (v) => v.name === userName || v.login === userName || v.jira_user === userName
  );
  if (jiraIdentity) {
    return jiraIdentity.avatar;
  }
  return nullUserImage;
};

export const getAvatarFromJiraUsers = (userName: string, jiraUsers: JIRAUser[]): string => {
  const user = jiraUsers.find((u) => u.name === userName);
  return user?.avatar || nullUserImage;
};

export const getAvatarFromGHUsers = (
  userName: string,
  ghUsers: GetJIRAIssuesResponse['include']['github_users']
): string => {
  const user = ghUsers[userName];
  return user?.avatar || nullUserImage;
};

export * as JiraServices from './jiraService';
