import React from 'react';

import { wrapperStyles } from './referenceValue.styles';

interface IReferenceValueProps {
  value: string;
  isTLO: boolean;
}

const ReferenceValue: React.FC<IReferenceValueProps> = React.memo(({ value, isTLO }) => (
  <div css={wrapperStyles}>
    <div className="time-range">Last 30 days</div>
    <div className="reference-value">{value ? `${value}${isTLO ? ` %` : ''}` : '-'}</div>
  </div>
));

export { ReferenceValue };
