import { icons } from '@lib/icon';
import { IColor } from '@styles/types';

export enum AvatarType {
  none = 1,
  dot = 2,
  text = 3,
  icon = 4,
  image = 5,
}

export interface IAvatar {
  color?: IColor | string;
  icon?: icons;
  image?: string;
  label?: string;
  type?: AvatarType;
  size?: number;
}
