import { css } from '@emotion/react';

const getStyle = ({ spacing, color, font }) => css`
  height: 40px;
  display: flex;
  align-items: center;
  align-content: center;
  gap: ${spacing.gap['03']};
  padding: ${spacing.gap['04']};
  background: ${color.neutral['20']};
  border-bottom: 1px solid ${color.neutral['40']};

  & > span {
    color: ${color.neutral['100']};
    font-size: ${font.size.default};
    font-weight: ${font.weight.regular};
  }
`;

export { getStyle };
