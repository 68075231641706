import React, { useContext, useMemo } from 'react';

import { ArrayService } from '@common-services/arrayService';
import { Filter } from '@lib/Filter';
import { SelectorTsIds } from '@lib/selector-ts/__tests__/testIds.ids';
import { SelectorTsOption } from '@lib/selector-ts/components/option';
import { SelectorTsContext } from '@lib/selector-ts/context';
import { getContainerSize, getSelectAllStyle } from '@lib/selector-ts/styles/filter';

export const SelectorTsFilterSection: React.FC = React.memo(() => {
  const {
    filter,
    filterOnChangeHandler,
    filterOnClearHandler,
    withAllOption,
    options,
    isAllSelected,
  } = useContext(SelectorTsContext);
  const allOptionsCounter = useMemo(() => {
    const withoutUnassigned = options.filter((opt) => opt.name !== 'Unassigned');
    return ArrayService.getFlatArrayFromNested(withoutUnassigned, 'children').length;
  }, [options]);
  return (
    <div data-testid={SelectorTsIds.filterBlock} css={getContainerSize}>
      <Filter
        placeholder={'Select'}
        inputValue={filter}
        onInputChange={filterOnChangeHandler}
        onClear={filterOnClearHandler}
      />
      {withAllOption && (
        <div css={(theme) => getSelectAllStyle(theme)}>
          <SelectorTsOption
            isSelected={isAllSelected}
            name={'All'}
            allOptionsCounter={allOptionsCounter}
            id={'0'}
          />
        </div>
      )}
    </div>
  );
});
