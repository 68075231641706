import { IChartConfig } from '@align-pages/dashboard/types';
import { MetricColumnName } from '@align-types/constants';
import {
  IFilteredPRs,
  ISerializedPullRequest,
} from '@common-services/api/public/types/pullRequests';
import { github } from '@common-services/format';
import { PR_STAGE, prLabel } from '@common-services/prHelpers';
import { uniqBy } from '@common-services/vendor/lodash';
import { IPrsTableRow } from '@lib/Table/components/pr-table/prTable.types';

const getMetricTime = (metric: IChartConfig, pr: ISerializedPullRequest): number => {
  switch (metric.metricColumnName) {
    case MetricColumnName.WIP_TIME:
    case MetricColumnName.REVIEW_TIME:
    case MetricColumnName.MERGE_TIME:
    case MetricColumnName.RELEASE_TIME:
      const stage = metric?.metricColumnName?.toLowerCase();
      return pr.filtered_stage_timings?.[stage] != null ? pr.filtered_stage_timings[stage] : null;
    case MetricColumnName.WAIT_TIME:
      return (
        ((pr.first_review ? new Date(pr.first_review) : new Date()).getTime() -
          new Date(pr.review_requested).getTime()) /
        1000
      );
    case MetricColumnName.OPEN_TIME:
      return (
        ((pr.closed ? new Date(pr.closed) : new Date()).getTime() -
          new Date(pr.created).getTime()) /
        1000
      );
    default:
      return Object.values(pr.filtered_stage_timings).reduce((a, b) => a + b);
  }
};

export const serializePrTableData = (data: IFilteredPRs, metric: IChartConfig): IPrsTableRow[] =>
  uniqBy(
    data.prs.flatMap((pr) => {
      return {
        ...pr,
        id: `${pr.repository}-${pr.number}`,
        author: pr.authors[0],
        authorAvatar: data.users[pr.authors[0]]?.avatar || null,
        reviewer: pr.reviewers.map((r) => github.userName(r)),
        reviewersAvatars: pr.reviewers.map((r) => ({
          avatar: data.users[r]?.avatar || null,
          userName: github.userName(r),
        })),
        cycleTime: getMetricTime(metric, pr) || null,
        stage: (metric?.metricColumnName as unknown) as PR_STAGE,
        statusLabel: prLabel(PR_STAGE.SUMMARY)(pr),
      };
    }),
    'id'
  );

export * as ChartPrTableServices from './chartPrTable.services';
