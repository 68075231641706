import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { CONFIG } from '@align-constants';
import { IUseQueryHookRest } from '@align-types/common';
import { useAuth0 } from '@common-context/Auth0';
import { useUserContext } from '@common-context/User';
import { useDemoCookie } from '@common-pages/Demo';
import { IApiBasicError } from '@common-services/api/common/types/common';
import {
  Account,
  ProductFeatures,
} from '@common-services/api/public/generated-from-backend/models';
import log from '@common-services/logger';

import { AccountsServices } from '../services/accounts';

export function useGetAccountFeature(
  isCached: boolean = false
): IUseQueryHookRest<ProductFeatures> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();
  const {
    user: {
      defaultAccount: { id: accountId },
    },
  } = useUserContext();

  const getAccountFeature = async (): Promise<ProductFeatures> => {
    const token: string = isDemo === false ? await getTokenSilently() : null;
    return AccountsServices.getAccountFeature({ accountId }, token);
  };
  const { isLoading, data, error } = useQuery<ProductFeatures, IApiBasicError>(
    ['accountFeature', accountId],
    getAccountFeature,
    {
      ...CONFIG.API.FETCH_OPTIONS,
      cacheTime: isCached ? CONFIG.API.FETCH_OPTIONS.cacheTime : 0,
      staleTime: isCached ? CONFIG.API.FETCH_OPTIONS.staleTime : 0,
    }
  );
  return { isLoading, data, error };
}

export function useGetAccountDetails(isCached: boolean = false): IUseQueryHookRest<Account> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();
  const {
    user: {
      defaultAccount: { id: accountId },
    },
  } = useUserContext();

  const getAccountDetails = async (): Promise<Account> => {
    const token: string = isDemo === false ? await getTokenSilently() : null;
    return AccountsServices.getAccountDetails({ accountId }, token);
  };
  const { isLoading, data, error } = useQuery<Account, IApiBasicError>(
    ['accountDetails', accountId],
    getAccountDetails,
    {
      ...CONFIG.API.FETCH_OPTIONS,
      cacheTime: isCached ? CONFIG.API.FETCH_OPTIONS.cacheTime : 0,
      staleTime: isCached ? CONFIG.API.FETCH_OPTIONS.staleTime : 0,
    }
  );
  useEffect(() => {
    if (error) {
      log.fatal('Can not get account details', error);
    }
  }, [error]);
  return { isLoading, data, error };
}
