import React from 'react';

import { Placeholder } from '@lib/Placeholder';
import { IPlaceholderData } from '@lib/Placeholder';

export const placeholderData: IPlaceholderData = {
  height: 188,
  items: [
    {
      count: 1,
      border: true,
      height: 188,
      gap: 16,
      elements: [
        {
          // Members Icon
          circle: true,
          width: 20,
          left: 20,
          top: 24,
        },
        {
          // Title
          width: 100,
          height: 20,
          left: 50,
          top: 24,
        },
        {
          // Button
          width: 120,
          height: 28,
          right: 20,
          top: 20,
        },
        {
          // List Item 1
          width: 1398,
          height: 48,
          left: 20,
          top: 68,
        },
        {
          // List Item 2
          width: 1398,
          height: 48,
          left: 20,
          top: 117,
        },
      ],
    },
  ],
};

const UsersListPlaceholder: React.FC = React.memo(() => {
  return <Placeholder data={placeholderData} />;
});

export { UsersListPlaceholder };
