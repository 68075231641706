import React from 'react';

import { BiggestPRsPanel } from './BiggestPRsPanel';
import CycleTimePanel from './CycleTimePanel';
import PRSizeDistributionPanel from './PRSizeDistributionPanel';
import PRSizeTimelinePanel from './PRSizeTimelinePanel';
import ReviewTimePanel from './ReviewTimePanel';

const InsightsPullRequestSize: React.FC = React.memo(() => (
  <>
    <div className="row mx-0 px-0 mb-4">
      <div className="col-md-12 mx-0 px-0">
        <PRSizeTimelinePanel />
      </div>
    </div>
    <div className="row mx-0 px-0 mb-4">
      <div className="col-md-12 mx-0 px-0">
        <PRSizeDistributionPanel />
      </div>
    </div>
    <div className="row mx-0 px-0 mb-4">
      <div className="col-md-6 mx-0 pl-0 pr-2">
        <CycleTimePanel />
      </div>
      <div className="col-md-6 mx-0 pl-2 pr-0">
        <ReviewTimePanel />
      </div>
    </div>
    <div className="row mx-0 px-0 mb-4">
      <div className="col-md-12 mx-0 px-0">
        <BiggestPRsPanel />
      </div>
    </div>
  </>
));

export default InsightsPullRequestSize;
