import _ from 'lodash';
import React from 'react';

import { calculateGranularity } from '@analytics-components/Prefetcher';
import MultiChart from '@analytics-components/charts/MultiChart';
import { BigText, DateRangeBigNumber } from '@analytics-components/charts/Tooltip';
import VerticalBarChart from '@analytics-components/charts/VerticalBarChart';
import { SimpleKPI, UNITS } from '@analytics-components/insights/KPI';
import { fetchDevsMetrics, fetchPRsMetrics } from '@analytics-services/api';
import { getFeature, featuresList } from '@analytics-services/flags';
import { dateTime } from '@common-services/dateService';
import * as NumberService from '@common-services/numberService';
import { theme } from '@styles/theme';

const prsCreatedTimeseries = {
  title: 'Timeline',
  description: 'Pull request throughput through time.',
  fetcher: async (api, cachedData, apiContext) => {
    const {
      account,
      interval,
      repositories,
      contributors,
      labels,
      excludeInactive,
      epics,
      features,
      issueTypes,
    } = apiContext;
    const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, features)?.parameters
      ?.value;

    // Cannot use global data as the KPI needs `day` granularty
    const dayCreations = await fetchPRsMetrics(
      api,
      account,
      ['day'],
      interval,
      ['opened'],
      {
        repositories,
        with: { author: contributors },
        labels_include: labels,
        ...(excludedLabels ? { labels_exclude: excludedLabels } : {}),
        jira: { epics, issue_types: issueTypes },
      },
      null,
      excludeInactive
    );

    let granularity = calculateGranularity(interval);
    if (granularity === 'month') {
      granularity = `aligned ${granularity}`;
    }
    const developers = _(contributors)
      .filter((c) => c.login)
      .uniqBy('login')
      .value();
    const prsCreated = await fetchDevsMetrics(
      api,
      account,
      [granularity],
      interval,
      ['prs-created'],
      {
        developers,
        repositories,
        labels_include: labels,
        ...(excludedLabels ? { labels_exclude: excludedLabels } : {}),
        jira: { epics, issue_types: issueTypes },
      }
    );

    return {
      dayCreations,
      prsPerContributor:
        prsCreated.calculated.length > 0
          ? _(prsCreated.calculated[0].values)
              .zip(developers)
              .map((v) => ({
                developer: v[1],
                prs: v[0],
              }))
              .value()
          : null,
    };
  },
  plumber: (fetchedData, cachedData, apiContext) => {
    const customGranularity = cachedData['prs-metrics'].customGranularity;
    const timeseriesGranularity = customGranularity === 'month' ? 'custom-aligned' : 'custom';
    const openedMetrics = cachedData['prs-metrics']['values'][timeseriesGranularity]['opened'];
    const dayCreations = fetchedData.dayCreations.calculated.length
      ? fetchedData.dayCreations.calculated[0].values
      : [];
    const createdPRs = cachedData['prs-metrics']['values']['all']['opened'] || 0;
    const days = _(dayCreations).reduce((acc, day) => (day.values[0] ? acc + 1 : acc), 0) || 1;
    const avgCreatedPRs = createdPRs / days;

    const totalChartData = openedMetrics.map((v) => ({
      day: v.date,
      value: v.value,
    }));

    const perContribChartData = fetchedData.prsPerContributor
      ? fetchedData.prsPerContributor[0].prs.map((pr, index) => {
          let devsAmount = 0;
          const totalPrs = fetchedData.prsPerContributor.reduce((acc, devPrs) => {
            if (devPrs.prs[index].values[0] !== 0) {
              devsAmount += 1;
              return acc + devPrs.prs[index].values[0];
            }
            return acc;
          }, 0);
          return {
            day: pr.date,
            value: !!devsAmount ? parseFloat((totalPrs / devsAmount).toFixed(1)) : 0,
          };
        })
      : [];

    const KPIsData = {
      avgCreatedPRs,
      createdPRs,
    };
    const axisKeys = {
      x: 'day',
      y: 'value',
    };

    const tickFormat = customGranularity === 'month' ? dateTime.month : dateTime.monthDay;
    const tooltip = {
      align: {
        horizontal: 'auto',
        vertical: 'top',
      },
      renderBigFn: (v) => <BigText content={`${v.y} Pull Requests`} />,
    };
    if (_(['month', 'week']).includes(customGranularity)) {
      tooltip.template = DateRangeBigNumber;
      tooltip.interval = { ...apiContext.interval };
      tooltip.granularity = customGranularity;
    }

    return {
      empty: totalChartData.filter((v) => v[axisKeys.y] !== null).length === 0,
      chart: {
        component: MultiChart,
        params: {
          charts: [
            {
              name: 'Total',
              Component: VerticalBarChart,
              props: {
                data: totalChartData,
                timeMode: true,
                extra: {
                  axisLabels: {
                    y: 'Pull Requests',
                  },
                  axisFormat: {
                    tickFormat: {
                      x: tickFormat,
                    },
                  },
                  margin: { bottom: 60 },
                  maxNumberOfTicks: 5,
                  axisKeys: axisKeys,
                  color: theme.color.ui.blue[100],
                  tooltip,
                },
              },
            },
            {
              name: 'Per Contributor',
              Component: VerticalBarChart,
              props: {
                data: perContribChartData,
                timeMode: true,
                extra: {
                  axisLabels: {
                    y: 'Pull Requests',
                  },
                  axisFormat: {
                    tickFormat: {
                      x: tickFormat,
                    },
                  },
                  margin: { bottom: 60 },
                  maxNumberOfTicks: 5,
                  axisKeys: axisKeys,
                  color: theme.color.ui.blue[100],
                  tooltip,
                },
              },
            },
          ],
        },
      },
      kpis: [
        {
          title: { text: 'Total Created', bold: true },
          component: SimpleKPI,
          params: {
            value: KPIsData.createdPRs,
            unit: UNITS.pr,
          },
        },
        {
          title: { text: 'Pull Requests Created', bold: true },
          subtitle: { text: 'Per Day' },
          component: SimpleKPI,
          params: {
            value: NumberService.round(KPIsData.avgCreatedPRs, 1),
          },
        },
      ],
    };
  },
  prefetchedDataIds: ['prs-metrics'],
};

export default prsCreatedTimeseries;
