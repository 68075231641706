import React from 'react';

import { IDropdownControl } from '@lib/Dropdown';
import { Avatar, AvatarType } from '@lib/avatar';
import { Icon, icons } from '@lib/icon';
import { color } from '@styles/theme/colors';

import { wrapperStyles } from './DropdownControl.styles';

export const DropdownControl = React.forwardRef<HTMLDivElement, IDropdownControl>(
  ({ isOpen, value, width, placeholder, withAvatar, disabled, onClick, icon }, ref) => {
    return (
      <div css={wrapperStyles({ width, isOpen, disabled, withAvatar })} ref={ref} onClick={onClick}>
        {icon && <Icon icon={icon} size={32} color={color.neutral['80']} />}
        {withAvatar && value?.name && (
          <div className="avatar">
            <Avatar label={value.name} type={AvatarType.text} color={value.color} />
          </div>
        )}
        {!value && placeholder && <div className="placeholder">{placeholder}</div>}
        <div className="value">{value?.name || ''}</div>
        <div className="icon">
          <Icon icon={icons.caret_down} size={8} color={color.neutral['80']} />
        </div>
      </div>
    );
  }
);
