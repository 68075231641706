import React, { useState } from 'react';

import { default as AccordionUI } from '@ui/Accordion';

const Accordion = ({ header, body, footer, rows }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <AccordionUI
      header={header}
      body={body}
      footer={footer}
      rows={rows}
      isExpanded={isExpanded}
      onHeaderClick={() => setIsExpanded(!isExpanded)}
    />
  );
};

export default Accordion;
