import _ from 'lodash';
import React from 'react';

import Box from '@analytics-components/insights/Box';
import { InsightsError } from '@analytics-components/insights/Helper';
import DataWidget, { useDataWidget } from '@analytics-context/DataWidget';
import {
  fetchFilteredPRs,
  fetchReleasesMetrics,
  AGGREGATE_REPOS,
  unwrap,
} from '@analytics-services/api';
import { getFeature, featuresList } from '@analytics-services/flags';
import { useUserContext } from '@common-context/User';
import { stageHappening, PR_STAGE } from '@common-services/prHelpers';
import { theme } from '@styles/theme';
import { hexToRGBA } from '@utils/colors';

import releaseCreation from './releaseCreation';
import releaseSize from './releaseSize';

export const palette = {
  barColor: theme.color.stage.release,
  duo: [theme.color.stage.release, theme.color.stage.review],
  five: [
    theme.color.stage.release,
    hexToRGBA(theme.color.stage.release, 0.5),
    hexToRGBA(theme.color.stage.release, 0.3),
    theme.color.ui.yellow[50],
    hexToRGBA(theme.color.ui.yellow[100], 0.3),
  ],
};

const getReposWaitingReleases = (prs) => {
  return _(prs)
    .filter((pr) => stageHappening(pr, PR_STAGE.RELEASE))
    .groupBy('repository')
    .mapValues((reposPRs, repository) => ({
      repository,
      prs: reposPRs.length,
      added_lines: _.sumBy(reposPRs, 'size_added'),
      deleted_lines: _.sumBy(reposPRs, 'size_removed'),
    }))
    .values()
    .orderBy(['prs'], ['desc'])
    .value();
};

const InsightsReleaseDataBinder = () => {
  const { data, error, isLoading } = useDataWidget();
  const { isGod } = useUserContext();
  if (isLoading) {
    return <div className="insights-placeholder" />;
  }

  if (error) {
    return <InsightsError />;
  }

  const insights = [
    {
      // TODO: Passing `isGod` here is not the greatesst thing to do, but since this part
      // has to be refactored by making all the insights charts independent from each other
      // as it has been done for the WIP stage, we'll address it later on.
      // See: https://athenianco.atlassian.net/browse/DEV-977
      data: releaseCreation.factory(data.releaseCreation, isGod),
      component: Box,
    },
  ];

  if (isGod) {
    insights.push({
      data: releaseSize.factory(data.releaseSize),
      component: Box,
    });
  }

  return (
    <>
      {insights.map((entry, i) => (
        <entry.component key={i} {...entry.data} />
      ))}
    </>
  );
};

const InsightsRelease = () => {
  const fetcher = async (api, cachedData, apiContext) => {
    const {
      account,
      interval,
      repositories,
      contributors,
      labels,
      features,
      epics,
      issueTypes,
    } = apiContext;
    const releasesMetricsNames = ['count'];
    const contribs = contributors.map((c) => c.login).filter((c) => c);

    const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, features)?.parameters
      ?.value;

    const releasesMetrics = await fetchReleasesMetrics(
      api,
      account,
      ['day'],
      interval,
      releasesMetricsNames,
      repositories,
      contribs,
      labels,
      excludedLabels || [],
      { epics, issue_types: issueTypes },
      AGGREGATE_REPOS
    );

    let prs;
    if (apiContext.user.isGod) {
      const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, apiContext.features)
        ?.parameters?.value;
      const prsResp = await fetchFilteredPRs(
        api,
        apiContext.account,
        apiContext.interval,
        apiContext.excludeInactive,
        {
          repositories: apiContext.repositories,
          developers: apiContext.contributors,
          labels_include: apiContext.labels,
          ...(excludedLabels ? { labels_exclude: excludedLabels } : {}),
          jira: {
            epics: apiContext.epics,
            issue_types: apiContext.issueTypes,
          },
        }
      );
      prs = prsResp.prs;
    } else {
      prs = [];
    }

    return {
      releaseMetrics: unwrap(releasesMetrics, releasesMetricsNames),
      prs,
    };
  };

  const plumber = (fetchedData, cachedData, apiContext) => {
    const isGod = apiContext.user.isGod;
    // TODO: DEPRECATED::GLOBAL_PRS_ABUSE (READY)
    // Retrieve prs from '/filter/pull_request::[property:releasing], and then locally do the grouping.
    // Alternatives:
    // 1. Brand new API endpoint /filter/to_release, which return this aggregations!!
    // 2. Retrieve prs from '/filter/pull_request::[stage:releasing], and then locally do the grouping.
    const reposWaiting = isGod ? getReposWaitingReleases(fetchedData.prs) : [];
    const plumbed = {
      // TODO: Passing `isGod` here is not the greatesst thing to do, but since this part
      // has to be refactored by making all the insights charts independent from each other
      // as it has been done for the WIP stage, we'll address it later on.
      // See: https://athenianco.atlassian.net/browse/DEV-977
      releaseCreation: releaseCreation.plumber(
        {
          releaseMetrics: fetchedData.releaseMetrics,
          releases: cachedData.releases,
          reposWaiting,
        },
        cachedData,
        apiContext
      ),
    };

    if (isGod) {
      plumbed['releaseSize'] = releaseSize.plumber(
        { reposWaiting, releases: cachedData.releases },
        cachedData,
        apiContext
      );
    }

    return plumbed;
  };

  return (
    <DataWidget
      id={'release-insights'}
      fetcher={fetcher}
      plumber={plumber}
      prefetchedDataIds={['releases', 'releases-metrics']}
    >
      <InsightsReleaseDataBinder />
    </DataWidget>
  );
};

export default InsightsRelease;
