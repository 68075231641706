import { Moment } from 'moment';
import React from 'react';

import TeamsAllocationTable from '@analytics-components/tables/TeamsAllocationTable';
import { ITeamAllocationTableData } from '@analytics-components/tables/TeamsAllocationTable/types';
import {
  convertTeamsToNested,
  groupTeamsWithWorkTypes,
  getTotalValues,
  transformAppliedWorkTypes,
} from '@analytics-pages/outcome/insights/allocation/work-types/helpers';
import { IWorkType } from '@analytics-types/common';
import { ContentTypes } from '@analytics-types/enums';

import { IGroupTeamsWithWorkTypesType } from '../types';
import { OTHER_COLUMN_OBJECT } from './constants';

interface IContext {
  interval: {
    from: Moment;
    to: Moment;
  };
}
interface IPlumberData {
  context: IContext;
  prsData: ITeamAllocationTableData;
  issuesData: ITeamAllocationTableData;
}

interface IComponentProps extends IPlumberData {
  contentType: ContentTypes;
}

const teamsAllocationTable = {
  plumber: (fetchedData, apiContext, appliedWorkTypes: IWorkType[]): IPlumberData => {
    const { selectedTeams } = fetchedData;
    const rootTeamId = apiContext.teams.find((team) => !team.parent).id;

    const appliedWorkTypesData = transformAppliedWorkTypes(appliedWorkTypes);

    const prsData = groupTeamsWithWorkTypes(
      fetchedData.teamsPrs,
      selectedTeams,
      IGroupTeamsWithWorkTypesType.github
    );
    const prsNestedData = convertTeamsToNested({
      teams: selectedTeams,
      data: prsData,
      rootTeamId,
      allFieldName: OTHER_COLUMN_OBJECT.name,
    });
    const issuesData = groupTeamsWithWorkTypes(
      fetchedData.teamsIssues,
      selectedTeams,
      IGroupTeamsWithWorkTypesType.jira
    );
    const issuesNestedData = convertTeamsToNested({
      teams: selectedTeams,
      data: issuesData,
      rootTeamId,
      allFieldName: OTHER_COLUMN_OBJECT.name,
    });

    return {
      context: { interval: apiContext.interval },
      prsData: {
        workTypes: [...appliedWorkTypesData, OTHER_COLUMN_OBJECT],
        teams: prsNestedData,
        totalValues: getTotalValues(fetchedData.teamsPrs),
      },
      issuesData: {
        workTypes: [...appliedWorkTypesData, OTHER_COLUMN_OBJECT],
        teams: issuesNestedData,
        totalValues: getTotalValues(fetchedData.teamsIssues),
      },
    };
  },
  component: (props: IComponentProps) => {
    return (
      <TeamsAllocationTable
        contentType={props.contentType}
        context={props.context}
        data={props.contentType === ContentTypes.PRS ? props.prsData : props.issuesData}
      />
    );
  },
};

export default teamsAllocationTable;
