/**
 * {{ title }}
 * Server version: {{ server_version }} built on {{ build_date }} @ [{{ commit }}](https://github.com/athenianco/athenian-api/commit/{{ commit }})  Origin GitHub repository: [athenianco/api-spec](https://github.com/athenianco/api-spec).  Authorization persists between sessions. 🛡️ means that the endpoint requires account admin privileges. 👑 means that the endpoint is only accessible to Athenian authorities. <details> <summary>List of common server errors.</summary> * __500__ endpoint crashed. We reported the incident to Sentry and will fix it soon! Please include the value of `instance` in the response if you contact the support. Repeating the request will not help, most probably. * __501__ some functions are missing, either because they are not implemented yet or a non-critical runtime dependency is not satisfied. Repeating the request will not help, guaranteed. * __502__ server crashed badly, either due to a memory access violation in native code or running out of memory. We reported this incident to Sentry and will fix it soon! Try repeating the request. * __503__ server has not fully launched yet, e.g. hasn't connected to the database; server is shutting down; we are experiencing a partial outage. Try repeating the request. * __504__ endpoint took too much time and was interrupted. We reported the incident to Sentry and will see how to improve the performance. Repeating the request will not help, most probably. </details>
 *
 * Contact: admin@athenian.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import JIRAFilter from './JIRAFilter';
import ReleaseMetricID from './ReleaseMetricID';
import ReleaseWith from './ReleaseWith';

/**
 * The ReleaseMetricsRequest model module.
 * @module model/ReleaseMetricsRequest
 */
class ReleaseMetricsRequest {
    /**
     * Constructs a new <code>ReleaseMetricsRequest</code>.
     * Request of &#x60;/metrics/releases&#x60; to calculate metrics on releases.
     * @alias module:model/ReleaseMetricsRequest
     * @param _for {Array.<Array.<String>>} List of repository groups for which to calculate the metrics.
     * @param metrics {Array.<module:model/ReleaseMetricID>} List of desired release metrics.
     * @param dateFrom {Date} Date from when to start measuring the metrics.
     * @param dateTo {Date} Date until which to measure the metrics.
     * @param granularities {Array.<String>} 
     * @param account {Number} Session account ID.
     */
    constructor(_for, metrics, dateFrom, dateTo, granularities, account) { 
        
        ReleaseMetricsRequest.initialize(this, _for, metrics, dateFrom, dateTo, granularities, account);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, _for, metrics, dateFrom, dateTo, granularities, account) { 
        obj['for'] = _for;
        obj['metrics'] = metrics;
        obj['date_from'] = dateFrom;
        obj['date_to'] = dateTo;
        obj['granularities'] = granularities;
        obj['account'] = account;
    }

    /**
     * Constructs a <code>ReleaseMetricsRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ReleaseMetricsRequest} obj Optional instance to populate.
     * @return {module:model/ReleaseMetricsRequest} The populated <code>ReleaseMetricsRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ReleaseMetricsRequest();

            if (data.hasOwnProperty('for')) {
                obj['for'] = ApiClient.convertToType(data['for'], [Array]);
            }
            if (data.hasOwnProperty('with')) {
                obj['with'] = ApiClient.convertToType(data['with'], [ReleaseWith]);
            }
            if (data.hasOwnProperty('labels_include')) {
                obj['labels_include'] = ApiClient.convertToType(data['labels_include'], ['String']);
            }
            if (data.hasOwnProperty('labels_exclude')) {
                obj['labels_exclude'] = ApiClient.convertToType(data['labels_exclude'], ['String']);
            }
            if (data.hasOwnProperty('jira')) {
                obj['jira'] = JIRAFilter.constructFromObject(data['jira']);
            }
            if (data.hasOwnProperty('metrics')) {
                obj['metrics'] = ApiClient.convertToType(data['metrics'], [ReleaseMetricID]);
            }
            if (data.hasOwnProperty('date_from')) {
                obj['date_from'] = ApiClient.convertToType(data['date_from'], 'Date');
            }
            if (data.hasOwnProperty('date_to')) {
                obj['date_to'] = ApiClient.convertToType(data['date_to'], 'Date');
            }
            if (data.hasOwnProperty('timezone')) {
                obj['timezone'] = ApiClient.convertToType(data['timezone'], 'Number');
            }
            if (data.hasOwnProperty('granularities')) {
                obj['granularities'] = ApiClient.convertToType(data['granularities'], ['String']);
            }
            if (data.hasOwnProperty('quantiles')) {
                obj['quantiles'] = ApiClient.convertToType(data['quantiles'], ['Number']);
            }
            if (data.hasOwnProperty('account')) {
                obj['account'] = ApiClient.convertToType(data['account'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * List of repository groups for which to calculate the metrics.
 * @member {Array.<Array.<String>>} for
 */
ReleaseMetricsRequest.prototype['for'] = undefined;

/**
 * List of developer groups for which to calculate the metrics.
 * @member {Array.<module:model/ReleaseWith>} with
 */
ReleaseMetricsRequest.prototype['with'] = undefined;

/**
 * At least one of these labels must be present in at least one released PR.
 * @member {Array.<String>} labels_include
 */
ReleaseMetricsRequest.prototype['labels_include'] = undefined;

/**
 * None of these labels must be present in each released PR.
 * @member {Array.<String>} labels_exclude
 */
ReleaseMetricsRequest.prototype['labels_exclude'] = undefined;

/**
 * @member {module:model/JIRAFilter} jira
 */
ReleaseMetricsRequest.prototype['jira'] = undefined;

/**
 * List of desired release metrics.
 * @member {Array.<module:model/ReleaseMetricID>} metrics
 */
ReleaseMetricsRequest.prototype['metrics'] = undefined;

/**
 * Date from when to start measuring the metrics.
 * @member {Date} date_from
 */
ReleaseMetricsRequest.prototype['date_from'] = undefined;

/**
 * Date until which to measure the metrics.
 * @member {Date} date_to
 */
ReleaseMetricsRequest.prototype['date_to'] = undefined;

/**
 * Local time zone offset in minutes, used to adjust `date_from` and `date_to`.
 * @member {Number} timezone
 */
ReleaseMetricsRequest.prototype['timezone'] = undefined;

/**
 * @member {Array.<String>} granularities
 */
ReleaseMetricsRequest.prototype['granularities'] = undefined;

/**
 * Cut the distributions at certain quantiles. The default values are [0, 1] which means no cutting.
 * @member {Array.<Number>} quantiles
 */
ReleaseMetricsRequest.prototype['quantiles'] = undefined;

/**
 * Session account ID.
 * @member {Number} account
 */
ReleaseMetricsRequest.prototype['account'] = undefined;






export default ReleaseMetricsRequest;

