import _ from 'lodash';
import React, { useState, useCallback, useRef } from 'react';

import { CardTitle } from '@analytics-components/Typography';
import PanelContentSwitcher from '@analytics-components/insights/PanelContentSwitcher';
import { boxHeaderStyles } from '@analytics-components/insights/styles';
import { useOnClickOutside } from '@common-hooks/useOnClickOutside';
import { Icon, icons } from '@lib/icon';
import { Info } from '@lib/info';

import { cardBodyStyles } from './styles';

const Panel = ({ meta, chartBoxes = null, onContentChange = null, children }) => {
  const [contentIndex, setContentIndex] = useState(
    !!meta.tabs && meta.tabs.def.length > 0 ? meta.tabs.initialContentIndex || 0 : null
  );

  const onContentChangeWrapper = (index) => {
    setContentIndex(index);
    if (onContentChange) {
      onContentChange(index);
    }
  };

  return (
    <div className="card insight-card" css={({ spacing }) => ({ marginBottom: spacing.gap['06'] })}>
      <PanelHeader meta={meta} activeTab={contentIndex} onContentChange={onContentChangeWrapper} />
      {chartBoxes && <PanelBody chartBoxes={chartBoxes} />}
      {contentIndex === null ? children : meta.tabs.def[contentIndex].content}
    </div>
  );
};

const PanelHeader = ({ meta, activeTab, onContentChange }) => (
  <div css={boxHeaderStyles}>
    <div>
      <CardTitle content={meta.title} />
      {meta.description && <Info content={meta.description} />}
    </div>
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {meta.tabs && (
        <PanelContentSwitcher
          tabs={meta.tabs.def}
          activeTab={activeTab}
          onContentChange={onContentChange}
        />
      )}
      {meta.controls && <PanelControls controls={meta.controls} />}
    </div>
  </div>
);

const PanelBody = ({ chartBoxes }) => (
  <>
    {chartBoxes.map((box, i) => (
      <div key={i} css={cardBodyStyles}>
        {box}
      </div>
    ))}
  </>
);

const PanelControls = ({ controls }) => {
  const [isActive, setIsActive] = useState(false);
  const menuRef = useRef(null);
  const actionsWrapperRef = useRef(null);
  useOnClickOutside([menuRef, actionsWrapperRef], (event) => setIsActive(false));

  const onMenuButtonClick = useCallback(() => setIsActive(!isActive), [isActive, setIsActive]);
  const ctrls = _(controls)
    .map((c) => ({
      ...c,
      onClick: () => {
        c.onClick();
        setIsActive(false);
      },
    }))
    .value();

  return (
    <div
      css={({ color, spacing, radius, shadow }) => ({
        'position': 'relative',
        'marginLeft': spacing.gap['04'],
        '& > div.control-actions-wrapper': {
          zIndex: '1',
          position: 'absolute',
          background: color.neutral['white'],
          right: spacing.gap['0'],
          border: `1px solid ${color.neutral['40']}`,
          borderRadius: radius['default'],
          overflow: 'hidden',
          boxShadow: shadow.full,
          top: '34px',
        },
      })}
    >
      <ControlMenuButtonUI wrapperRef={menuRef} isActive={isActive} onClick={onMenuButtonClick} />
      {isActive && (
        <div className="control-actions-wrapper" ref={actionsWrapperRef}>
          {_(ctrls)
            .map((c, i) => <ControlAction key={i} {...c} />)
            .value()}
        </div>
      )}
    </div>
  );
};

const ControlAction = ({ icon, text, disabled, onClick }) => {
  return (
    <div
      onClick={onClick}
      css={({ spacing, color }) => ({
        'display': 'flex',
        'border': `1px solid ${color.neutral['40']}`,
        'margin': `-${spacing.gap['1px']}`,
        'gap': spacing.gap['03'],
        'justifyContent': 'space-between',
        'alignItems': 'center',
        'padding': `${spacing.gap['03']} ${spacing.gap['04']}`,
        'opacity': `${disabled ? '0.3' : '1'}`,
        'cursor': `${disabled ? 'not-allowed' : 'pointer'}`,
        'transition': 'all 0.3s ease-in-out',
        '& span.description': {
          color: color.neutral['100'],
          whiteSpace: 'nowrap',
          flexGrow: 5,
        },
        '& svg': {
          width: '8px',
          height: '8px',
          color: color.neutral['80'],
        },
        '&:hover': {
          backgroundColor: color.neutral['20'],
        },
      })}
    >
      <span>
        <Icon icon={icon} />
      </span>
      <span className="description">{text}</span>
    </div>
  );
};

const ControlMenuButtonUI = ({ isActive, onClick, wrapperRef }) => {
  return (
    <div
      ref={wrapperRef}
      onClick={onClick}
      css={({ color, spacing, radius, transition }) => {
        const baseStyle = {
          width: '26px',
          height: '26px',
          padding: spacing.gap['02'],
          borderRadius: radius['default'],
          transition: transition.default,
          cursor: 'pointer',
        };

        const stateStyle = isActive
          ? {
              color: color.neutral['white'],
              backgroundColor: color.neutral['60'],
            }
          : {
              'color': color.neutral['80'],
              ':hover': {
                backgroundColor: color.neutral['40'],
              },
            };

        const style = { ...baseStyle, ...stateStyle };
        return { '& > svg': style };
      }}
    >
      <Icon icon={icons.dots} />
    </div>
  );
};

export default Panel;
export { PanelBody };
