import React from 'react';

import illustration from '@assets/images/illustration-large.png';
import logo from '@assets/images/logos/logo-transparent.png';
import {
  getIllustrationStyle,
  getWrapperStyle,
  getContentWrapperStyle,
} from '@common-pages/account/layout/accountLayout.styles';

export const AccountLayout: React.FC = ({ children }) => {
  return (
    <div css={getWrapperStyle}>
      <div className="wrapper">
        <header>
          <img src={logo} alt="Athenian" />
        </header>
        <main>
          <div css={getIllustrationStyle}>
            <img src={illustration} alt="" />
          </div>
          <div css={getContentWrapperStyle}>
            <div className="welcome-wrapper">
              <span className="welcome">Welcome to Athenian</span>
              <span className="subtitle">Create your account</span>
            </div>
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};
