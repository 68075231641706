import React from 'react';

import Accordion from '@analytics-components/Accordion';

import { TeamEditAccordionFooter } from '../components/team-edit-accordion-footer';
import { TeamEditAccordionHeader } from '../components/team-edit-accordion-header';
import { TeamEditAccordionRow } from '../components/team-edit-accordion-row';
import { ITeamEditAccordionUI } from '../teamEditAccordion.types';

const TeamEditAccordionUI: React.FC<ITeamEditAccordionUI> = React.memo(
  ({
    name,
    members,
    usersOptions,
    jiraIdentities,
    loadedAllUsersOptions,
    parentTeamInit,
    teamNameInit,
    parentTeamOptions,
    onEdit,
    onRemove,
    onMemberDeletion,
    onMembersAddition,
    onMemberMapped,
    onMemberMappingReset,
    onLoadAllUsersOptionsClick,
  }) => (
    <Accordion
      header={{
        primary: name,
        secondary: `(${members.length} member${members.length === 1 ? '' : 's'})`,
        render: (DefaultRenderer, header) => (
          <TeamEditAccordionHeader
            DefaultRenderer={DefaultRenderer}
            header={header}
            members={members}
            onEdit={onEdit}
            onRemove={onRemove}
            teamNameInit={teamNameInit}
            parentTeamInit={parentTeamInit}
            parentTeamOptions={parentTeamOptions}
          />
        ),
      }}
      body={{
        render: (row) => (
          <TeamEditAccordionRow
            member={row}
            jiraIdentities={jiraIdentities}
            onMemberDeletion={onMemberDeletion}
            onMemberMapped={onMemberMapped}
            onMemberMappingReset={onMemberMappingReset}
          />
        ),
      }}
      footer={{
        text: '+ Add member',
        render: (footer) => (
          <TeamEditAccordionFooter
            footer={footer}
            usersOptions={usersOptions}
            loadedAllOptions={loadedAllUsersOptions}
            onMembersAddition={onMembersAddition}
            onLoadAllOptionsClick={onLoadAllUsersOptionsClick}
          />
        ),
      }}
      rows={members}
    />
  )
);

export { TeamEditAccordionUI };
