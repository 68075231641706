import { CompletedKPIMetric } from '@align-types/constants';
import {
  IFilteredPRs,
  ISerializedPullRequest,
} from '@common-services/api/public/types/pullRequests';
import { github } from '@common-services/format';
import { ObjectService } from '@common-services/objectService';
import { PR_STAGE, prLabel } from '@common-services/prHelpers';
import { uniqBy } from '@common-services/vendor/lodash';
import { IPrsTableRow } from '@lib/Table/components/pr-table/prTable.types';

const getMetricTime = (kpi: CompletedKPIMetric, pr: ISerializedPullRequest): number => {
  switch (kpi) {
    case CompletedKPIMetric.WIP:
    case CompletedKPIMetric.REVIEW:
    case CompletedKPIMetric.MERGE:
      const stage = ObjectService.getKeyByValue(CompletedKPIMetric, kpi).toLowerCase();
      return pr.filtered_stage_timings?.[stage] != null ? pr.filtered_stage_timings[stage] : null;
    case CompletedKPIMetric.WAIT:
      return (
        ((pr.first_review ? new Date(pr.first_review) : new Date()).getTime() -
          new Date(pr.review_requested).getTime()) /
        1000
      );
    case CompletedKPIMetric.OPEN:
      return (
        ((pr.closed ? new Date(pr.closed) : new Date()).getTime() -
          new Date(pr.created).getTime()) /
        1000
      );
    default:
      return Object.values(pr.filtered_stage_timings).reduce((a, b) => a + b);
  }
};

export const serializePrTableData = (
  data: IFilteredPRs,
  kpi: CompletedKPIMetric
): IPrsTableRow[] => {
  return uniqBy(
    data.prs.flatMap((pr) => {
      const stage = ObjectService.getKeyByValue(CompletedKPIMetric, kpi)?.toLowerCase() || null;
      return {
        ...pr,
        id: `${pr.repository}-${pr.number}`,
        author: pr.authors[0],
        authorAvatar: data.users[pr.authors[0]]?.avatar || null,
        reviewer: pr.reviewers.map((r) => github.userName(r)),
        reviewersAvatars: pr.reviewers.map((r) => ({
          avatar: data.users[r]?.avatar || null,
          userName: github.userName(r),
        })),
        cycleTime: getMetricTime(kpi, pr) || null,
        stage: (kpi === CompletedKPIMetric.ALL
          ? ObjectService.getKeyByValue(CompletedKPIMetric, CompletedKPIMetric.CYCLE)
          : stage) as PR_STAGE,
        statusLabel: prLabel(PR_STAGE.SUMMARY)(pr),
      };
    }),
    'id'
  );
};

export * as PrTableServices from './prTable.services';
