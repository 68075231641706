import React, { useContext } from 'react';

import { InputContext } from '@lib/Input/input.context';

import { IInputUIProps } from '../Input.types';
import { inputErrorStyles, inputWrapperStyles, labelStyles, wrapperStyles } from './InputUI.styles';
import { RawInput } from './RawInput';

const Input = React.forwardRef<HTMLInputElement, IInputUIProps>(
  (
    {
      disabled = false,
      iconColor,
      label,
      placeholder,
      value,
      width,
      withSearchIcon = false,
      autoComplete = false,
      childrenAfter = false,
      children,
      type,
      onChange,
      onClick,
      step,
    },
    ref
  ) => {
    const { error, testid } = useContext(InputContext);
    return (
      <div css={(theme) => wrapperStyles(theme, disabled)} onClick={onClick}>
        {label && (
          <div className="ath-input-label" css={labelStyles}>
            {label}
          </div>
        )}
        <div
          className="ath-raw-input-wrapper"
          css={inputWrapperStyles(width, error ? true : false)}
        >
          <RawInput
            disabled={disabled}
            iconColor={iconColor}
            placeholder={placeholder}
            value={value}
            width={width}
            withSearchIcon={withSearchIcon}
            autoComplete={autoComplete}
            childrenAfter={childrenAfter}
            onChange={onChange}
            ref={ref}
            type={type}
            step={step}
          >
            {children}
          </RawInput>
        </div>
        {error && (
          <p data-testid={`${testid}-error`} css={inputErrorStyles}>
            {error}
          </p>
        )}
      </div>
    );
  }
);

export { Input };
