import React from 'react';
import { Redirect } from 'react-router-dom';

import Content from '@analytics-components/Content';
import Prefetcher from '@analytics-components/Prefetcher';
import Sidebar from '@analytics-components/Sidebar';
import BreadcrumbProvider from '@analytics-context/Breadcrumb';
import DataProvider from '@analytics-context/Data';
import FiltersProvider from '@analytics-context/Filters';
import Help from '@analytics-context/Help';
import Page from '@analytics-pages/Page';
import { useUserContext } from '@common-context/User';
import { AuthRouteEnum } from '@routes/authRoutes';

const Main = () => {
  const { isAuthenticated, isDemo } = useUserContext();

  if (!isAuthenticated && !isDemo) return <Redirect to={AuthRouteEnum.LOGIN} />;

  return (
    <Help>
      <BreadcrumbProvider>
        <DataProvider>
          <Page>
            <FiltersProvider>
              <Prefetcher>
                <div className="row m-0">
                  <Sidebar />
                  <Content />
                </div>
              </Prefetcher>
            </FiltersProvider>
          </Page>
        </DataProvider>
      </BreadcrumbProvider>
    </Help>
  );
};

export default Main;
