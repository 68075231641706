import DistributionChart from '@analytics-components/charts/DistributionChart';
import { SimpleKPI, UNITS } from '@analytics-components/insights/KPI';
import { DEFAULT_METRICS_QUANTILES } from '@analytics-services/api';
import * as NumberService from '@common-services/numberService';
import { theme } from '@styles/theme';

const prsLocsHistogramDistribution = {
  plumber: (fetchedData, cachedData, apiContext) => {
    const logHistogramDistribution = cachedData['prs-histogram']['size'];
    const medianSize = cachedData['prs-metrics']['values']['all']['median-size'];

    // TODO: This should be removed so that the values is not calculated here,
    // but the API do not provide any metric `pr-size-count-q`.
    const quantile = Math.floor(
      logHistogramDistribution.frequencies.reduce((acc, curr) => acc + curr, 0) *
        DEFAULT_METRICS_QUANTILES[1]
    );
    const chartData = { ...logHistogramDistribution, quantile };
    const prCount = logHistogramDistribution.frequencies.reduce((acc, f) => acc + f, 0);

    return {
      empty: chartData?.empty,
      chart: {
        component: DistributionChart,
        title: 'Distribution',
        description:
          'Distribution of pull requests by size in terms of lines of code. <p class="small text-secondary mt-1 mb-0">Calculated up to the 95th percentile.</p>',
        params: {
          data: chartData,
          extra: {
            fillColor: theme.color.ui.lightblue[100],
            maxNumberOfTicks: 6,
            tooltip: {
              align: {
                horizontal: 'auto',
                vertical: 'top',
              },
              x: (v) => `${NumberService.round(v)} lines`,
            },
            axisLabels: {
              x: 'Lines of Code',
              y: 'Pull Requests',
            },
            axisFormat: { x: (x) => NumberService.round(x) },
          },
        },
      },
      kpis: [
        {
          title: { text: 'Total', bold: true },
          component: SimpleKPI,
          params: {
            value: prCount,
            unit: UNITS.pr,
          },
        },
        {
          title: { text: 'Median', bold: true },
          component: SimpleKPI,
          params: {
            value: NumberService.round(medianSize),
            unit: UNITS.line,
          },
        },
      ],
    };
  },
  prefetchedDataIds: ['prs-metrics', 'prs-histogram'],
};

export default prsLocsHistogramDistribution;
