import React from 'react';

import { Placeholder } from '@lib/Placeholder';

import { wrapperStyles } from './chartPlaceholder.styles';
import { placeholderData } from './placeholder.data';

export const ChartPlaceholder: React.FC = React.memo(() => (
  <div css={wrapperStyles}>
    <Placeholder data={placeholderData} />
  </div>
));
