import React from 'react';
import { Link } from 'react-router-dom';

import { useBreadcrumb } from '@analytics-context/Breadcrumb';
import LeadTime from '@analytics-pages/Help/sections/LeadTime';
import ReleaseFrequency from '@analytics-pages/Help/sections/ReleaseFrequency';
import Merge from '@analytics-pages/Help/sections/merge';
import Release from '@analytics-pages/Help/sections/release';
import ReleasedPrs from '@analytics-pages/Help/sections/releasedPrs';
import Review from '@analytics-pages/Help/sections/review';
import WIP from '@analytics-pages/Help/sections/work-in-progress';
import Knowledge01 from '@assets/images/knowledge/knowledge_01.svg';
import Knowledge02 from '@assets/images/knowledge/knowledge_02.svg';
import Knowledge03 from '@assets/images/knowledge/knowledge_03.svg';
import Knowledge04 from '@assets/images/knowledge/knowledge_04.svg';

const sections = {
  'lead-time': LeadTime,
  'release-frequency': ReleaseFrequency,
  'wip': WIP,
  'review': Review,
  'merge': Merge,
  'release': Release,
  'released-prs': ReleasedPrs,
};

const cards = [
  {
    title: 'Lead Time',
    content:
      'Lead Time is a metric borrowed from lean thinking and manufacturing disciplines. It’s defined as the amount of time between the moment work begins until it’s delivered to the end-customer.',
    image: Knowledge01,
    link: 'lead-time',
  },
  {
    title: 'Release Frequency',
    content:
      'Together with Lead Time, Release Frequency is a measure of software delivery performance tempo. This metric tells you how well customer feedback is incorporated along the development process.',
    image: Knowledge02,
    link: 'release-frequency',
  },
  {
    title: 'Work In Progress Cycle Time',
    content:
      'This metric helps you to understand what proportion of your end-to-end delivery pipeline is dedicated to the actual code implementation.',
    image: Knowledge03,
    link: 'wip',
  },
  {
    title: 'Review Cycle Time',
    content:
      'Code review being the most difficult and time-consuming part of the software development process, review cycle time often stands for the biggest opportunity to improve your overall speed delivery.',
    image: Knowledge04,
    link: 'review',
  },
];

const otherPosts = [
  {
    title: 'Merge Cycle Time',
    link: 'merge',
  },
  {
    title: 'Release Cycle Time',
    link: 'release',
  },
  {
    title: 'Released PRs',
  },
  {
    title: 'Pull Request Size',
    link: '',
  },
  {
    title: 'Wait Time for 1st Review',
    link: '',
  },
  {
    title: 'Pull Request Ratio Flow',
    link: '',
  },
  {
    title: 'Mean Time To restore',
    link: '',
  },
  {
    title: 'Code Review Depth',
    link: '',
  },
  {
    title: 'Work Distribution',
    link: '',
  },
  {
    title: 'Bug Fixing Ratio',
    link: '',
  },
];

const Card = ({ title, content, image, link }) => (
  <div className="col-3">
    <div className="p-2">
      <img alt="" className="w-100 mb-5" src={image} />
      <h3 className="mb-3">
        <Link to={`/analytics/learn/knowledge/${link}`} className="text-dark link">
          <span>{title}</span>
        </Link>
      </h3>
      <p>{content}</p>
    </div>
  </div>
);

const Content = ({ data, status }) => {
  const { subsection: activePost } = useBreadcrumb();
  const Section = sections[activePost];
  if (Section) {
    return (
      <div className="mt-5">
        <Section fullPage={true} />
      </div>
    );
  }

  return <Home />;
};

const Home = () => (
  <>
    <div className="row text-center">
      <div className="col">
        <h2 className="text-dark h1 mt-5 mb-2">Athenian Knowledge</h2>
        <p className="text-secondary h3 mb-5">
          Learn about Engineering metrics and improve your software delivery performance
        </p>
      </div>
    </div>

    <div className="row">
      <div className="col-12">
        <span className="text-secondary text-uppercase mb-2 pl-2">Start Here</span>
      </div>
      {cards.map((c, i) => (
        <Card key={i} {...c} />
      ))}
    </div>

    <div className="row">
      <div className="col-12">
        <span className="text-dark-orange text-uppercase d-block mb-4 pl-2">
          More Engineering Metrics
        </span>
      </div>
    </div>

    <div className="row">
      {otherPosts.map((p, i) => (
        <div
          key={i}
          className="col-3 px-2 pb-5"
          css={({ font }) => ({
            fontSize: font.size.s,
          })}
        >
          {p.link ? (
            <Link to={`/analytics/learn/knowledge/${p.link}`} className="text-dark link">
              <span className="pl-2">{p.title}</span>
            </Link>
          ) : (
            <span className="pl-2">{p.title}</span>
          )}
        </div>
      ))}
    </div>
  </>
);

export default Content;
