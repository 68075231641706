import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useChartInteraction } from '@align-hooks/useChartInteraction';
import { useOwnedDashboard } from '@align-hooks/useDashboard';
import { useMergeIssuesData } from '@align-hooks/useMergeIssuesData';
import { useMergePrData } from '@align-hooks/useMergePrData';
import { useTableTabs } from '@align-hooks/useTableTabs';
import { useCurrentTeam } from '@align-hooks/useTeams';
import { chartConfig } from '@align-pages/dashboard/config';
import { useBreakdownOptionsData, useOwnedChartConfig } from '@align-pages/dashboard/hooks';
import { useComputedIssues } from '@align-pages/dashboard/hooks/useComputedIssues';
import { useComputedPullRequests } from '@align-pages/dashboard/hooks/useComputedPullRequests';
import { useDashboardChartData } from '@align-pages/dashboard/hooks/useDashboardChartData';
import { IBreakdownOptionItem } from '@align-pages/dashboard/reducers/breakdownOptionsReducer.types';
import { DashboardBreakdownServices } from '@align-pages/dashboard/services/dashboardBreakdownServices';
import { DashboardChartServices } from '@align-pages/dashboard/services/dashboardChartServices';
import { DashboardPropertyServices } from '@align-pages/dashboard/services/dashboardPropertyServices';
import { useDashboardStore } from '@align-pages/dashboard/store';
import { ChartParamsType, IChartConfig, INewChartParam } from '@align-pages/dashboard/types';
import { ISeriesInfo } from '@align-services/api/metricService.types';
import { DateService as AlignDateService } from '@align-services/dateService';
import { MetricService } from '@align-services/metricService';
import { IDateRangeInMls, PrsIssuesTableTabs } from '@align-types/common';
import { PRsTableTabLabel } from '@align-types/constants';
import {
  DashboardChart,
  DashboardChartFilters,
  DashboardChartGroupBy,
} from '@common-services/api/private/generated-from-backend/models';
import {
  GetJIRAIssuesResponse,
  PullRequestSet,
} from '@common-services/api/public/generated-from-backend/models';
import { DateService } from '@common-services/dateService';
import { ITab } from '@lib/Tabs/Tabs.types';
import {
  ChartTypeEnum,
  IChartSeriesItem,
  LineWithPlotBandService,
  MultiLineServices,
} from '@lib/chart';
import { FilterType } from '@lib/filter-panel';

import { ChartEditServices } from './chartEdit.services';
import {
  IUseChartData,
  IUseBreakdownOptions,
  IUsePrsTable,
  IOnPointSelect,
  IUseIssuesTable,
  IUseTableElements,
  IUseTableState,
} from './chartEdit.types';

export function useFiltersProperty(): DashboardChartFilters {
  const properties = useDashboardStore((state) => state.properties);

  return DashboardPropertyServices.propertiesToFilters(properties);
}

export function useBreakdownType(): FilterType {
  const properties = useDashboardStore((state) => state.properties);

  return DashboardPropertyServices.propertiesToBreakdown(properties);
}

export function useBreakdownOptions(): IUseBreakdownOptions {
  const filters = useFiltersProperty();
  const breakdownType = useBreakdownType();
  const breakdownOptionsData = useBreakdownOptionsData(breakdownType);

  return {
    breakdownOptions: ChartEditServices.generateBreakdownOptions({
      options: breakdownOptionsData,
      filters: filters,
      type: breakdownType,
    }),
    groupBy: ChartEditServices.breakdownOptionsToGroupBy(breakdownOptionsData),
  };
}

export function useBreakdownProperty(): IBreakdownOptionItem[] {
  const { breakdownOptions } = useBreakdownOptions();
  const chartParams = useDashboardStore((state) => state.chartParams);
  const multiSeriesData = useMultiSeriesData();
  const breakdownType = useBreakdownType();
  const breakdownOptionsData = useBreakdownOptionsData(breakdownType);

  const breakdownOptionsOrder = ChartEditServices.extractOrderFromMultiSeries(multiSeriesData);
  const orderedBreakdownOptions = ChartEditServices.applyOrderToBreakdownOptions(
    breakdownOptions,
    breakdownOptionsOrder,
    breakdownOptionsData,
    breakdownType
  );

  return ChartEditServices.applySelectionToBreakdownOptions(
    orderedBreakdownOptions,
    chartParams.group_by,
    breakdownType
  );
}

export function useSelectedGroupBy(): DashboardChartGroupBy {
  const breakdownOptions = useDashboardStore((state) => state.breakdownOptions);
  const breakdownType = useBreakdownType();

  return DashboardPropertyServices.breakdownToGroupBy(breakdownOptions, breakdownType);
}

export function useExistingChart(): DashboardChart {
  const { chartId } = useParams();
  const currentTeam = useCurrentTeam();
  const { data } = useOwnedDashboard(currentTeam?.id, true);
  const targetChart = data?.charts.find((c) => c.id.toString() === chartId);

  return {
    ...targetChart,
    ...DashboardChartServices.getTimeInterval({
      time_interval: targetChart?.time_interval,
    }),
  };
}

export function useSeriesData(): IChartSeriesItem[] {
  const { data: values } = useDashboardChartData();
  const { valueType } = useOwnedChartConfig();

  return (
    MetricService.valuesSerializer({
      values,
      valueType,
    }) || []
  );
}

export function useMultiSeriesData(): ISeriesInfo[] {
  const { breakdownOptions } = useBreakdownOptions();
  const { data } = useDashboardChartData();
  return breakdownOptions
    ? MetricService.combineMultiSeriesInfo({
        values: data,
        breakdownOptions: breakdownOptions,
      })
    : [];
}

export function useChartData(onPointSelect: IOnPointSelect): IUseChartData {
  const seriesData = useSeriesData();
  const multiSeriesData = useMultiSeriesData();
  const breakdownOptions = useDashboardStore((state) => state.breakdownOptions);
  const chartParams = useDashboardStore((state) => state.chartParams);
  const breakdownType = useBreakdownType();
  const breakdownOptionsData = useBreakdownOptionsData(breakdownType);
  const { valueType, unit, isFrequency } =
    chartConfig.find((conf) => conf.metric === chartParams.metric) || {};

  const granularity = useMemo(() => {
    return DateService.calculateGranularity({
      dateFrom: chartParams.date_from,
      dateTo: chartParams.date_to,
    });
  }, [chartParams]);

  const { multiLineSeries, seriesNames, seriesColors } = useMemo(
    () =>
      MetricService.generateMultiLineSeries(
        multiSeriesData,
        valueType,
        breakdownOptions,
        breakdownType,
        breakdownOptionsData
      ),
    [multiSeriesData, valueType, breakdownOptions, breakdownType, breakdownOptionsData]
  );

  const options = useMemo(() => {
    if (breakdownType) {
      return MultiLineServices.getOptions({
        multiLineSeries,
        startDate: chartParams.date_from,
        endDate: chartParams.date_to,
        unit,
        valueType,
        granularity,
        isFrequency,
        onSelect: onPointSelect,
      });
    }
    return LineWithPlotBandService.getOptions({
      series: seriesData,
      startDate: chartParams.date_from,
      endDate: chartParams.date_to,
      unit,
      valueType,
      granularity,
      isFrequency,
      onSelect: onPointSelect,
    });
  }, [
    multiLineSeries,
    seriesData,
    chartParams,
    granularity,
    isFrequency,
    valueType,
    unit,
    breakdownType,
  ]);

  const chartType = useMemo(
    () => (breakdownType ? ChartTypeEnum.MULTI_LINE : ChartTypeEnum.FILLED_AREA),
    [breakdownType]
  );

  return {
    chartType,
    options,
    seriesNames,
    seriesColors,
  };
}

export function useTableElements(
  breakdownOptions: IBreakdownOptionItem[],
  chartParams: DashboardChart,
  chartProperties: INewChartParam[]
): IUseTableElements {
  const { activeTab, changeTab } = useTableTabs();

  const { selectedRange, handleChartPointSelect, handleRemoveTimeframe } = useChartInteraction(
    changeTab
  );

  const updatedChartParams = useMemo(() => {
    const breakdownProp = chartProperties.find((p) => p.type === ChartParamsType.BREAKDOWNS)
      ?.property;
    if (breakdownProp && breakdownOptions?.length) {
      return DashboardBreakdownServices.getFilteredChartParams(
        chartParams,
        breakdownOptions,
        breakdownProp
      );
    }
    return { ...chartParams };
  }, [chartParams, breakdownOptions, chartProperties]);

  return {
    activeTab,
    selectedRange,
    updatedChartParams,
    changeTab,
    handleChartPointSelect,
    handleRemoveTimeframe,
  };
}

export function usePrsTable(
  activeTab: PrsIssuesTableTabs,
  metric: IChartConfig,
  selectedRange: IDateRangeInMls,
  updatedChartParams: DashboardChart
): IUsePrsTable {
  const {
    data: prTableDataPortion,
    isLoading: prTableIsLoading,
    isLoadingMore: prTableIsLoadingMore,
    completedPrsCount,
    inProgressPrsCount,
    allCompletedPrs,
    onShowMoreHandler: onShowMorePrsHandler,
  } = useComputedPullRequests(updatedChartParams, metric, activeTab, selectedRange);

  const mergedPrData = useMergePrData(
    prTableDataPortion,
    activeTab,
    selectedRange,
    allCompletedPrs
  );

  return {
    completedPrsCount,
    inProgressPrsCount,
    prsData: mergedPrData,
    prTableIsLoading,
    prTableIsLoadingMore,
    onShowMorePrsHandler,
  };
}

export function useIssuesTable(
  activeTab: PrsIssuesTableTabs,
  metric: IChartConfig,
  selectedRange: IDateRangeInMls,
  updatedChartParams: DashboardChart
): IUseIssuesTable {
  const {
    data: issuesTableDataPortion,
    isLoading: issuesTableIsLoading,
    isLoadingMore: issuesTableIsLoadingMore,
    completedIssuesCount,
    inProgressIssuesCount,
    allCompletedIssues,
    onShowMoreHandler: onShowMoreIssuesHandler,
  } = useComputedIssues(updatedChartParams, metric, activeTab, selectedRange);

  const mergedIssuesData = useMergeIssuesData(
    issuesTableDataPortion,
    activeTab,
    selectedRange,
    allCompletedIssues
  );

  return {
    completedIssuesCount,
    inProgressIssuesCount,
    issuesData: mergedIssuesData,
    issuesTableIsLoading,
    issuesTableIsLoadingMore,
    onShowMoreIssuesHandler,
  };
}

export function useTableState(
  activeTab: PrsIssuesTableTabs,
  chartParams: DashboardChart,
  isJiraMetric: boolean,
  completedIssuesCount: number,
  completedPrsCount: number,
  inProgressIssuesCount: number,
  inProgressPrsCount: number,
  issuesData: GetJIRAIssuesResponse,
  prsData: PullRequestSet,
  issuesTableIsLoading: boolean,
  issuesTableIsLoadingMore: boolean,
  prTableIsLoading: boolean,
  prTableIsLoadingMore: boolean
): IUseTableState {
  const tabs = useMemo<ITab[]>(
    () => [
      {
        isActive: activeTab === PrsIssuesTableTabs.COMPLETED,
        label: PRsTableTabLabel.COMPLETED,
        value: isJiraMetric ? completedIssuesCount : completedPrsCount,
      },
      ...(AlignDateService.isBeforeToday(chartParams?.date_to)
        ? []
        : [
            {
              isActive: activeTab === PrsIssuesTableTabs.IN_PROGRESS,
              label: PRsTableTabLabel.IN_PROGRESS,
              value: isJiraMetric ? inProgressIssuesCount : inProgressPrsCount,
            },
          ]),
    ],
    [
      activeTab,
      completedIssuesCount,
      completedPrsCount,
      inProgressIssuesCount,
      inProgressPrsCount,
      chartParams,
    ]
  );

  const data = useMemo(
    () => (isJiraMetric ? (issuesData ? issuesData.issues : null) : prsData ? prsData.data : null),
    [isJiraMetric, issuesData, prsData]
  );

  const isDataLoading = useMemo(
    () =>
      issuesTableIsLoading ||
      prTableIsLoading ||
      (!data && (issuesTableIsLoadingMore || prTableIsLoadingMore)),
    [data, issuesTableIsLoading, issuesTableIsLoadingMore, prTableIsLoading, prTableIsLoadingMore]
  );

  return {
    data,
    isDataLoading,
    tabs,
  };
}
