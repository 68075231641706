import React from 'react';

import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import Panel from '@analytics-components/insights/Panel';
import { prepareChartBoxes } from '@analytics-pages/delivery-pipeline/insights/helper';

import bugsRaisedVsFixed from './bugs-raised-vs-fixed';

const meta = {
  title: 'Bugs Raised vs. Fixed',
  description: 'Measure the amount of bugs appearing in your system and ensure they get resolved.',
};

const chartBoxes = [
  {
    id: 'bugs-raised-vs-fixed',
    component: ChartBoxWithKPIWidget,
    params: bugsRaisedVsFixed,
  },
];

const BugsRaisedVsFixedPanel = () => {
  const preparedChartBoxes = prepareChartBoxes(chartBoxes);
  return <Panel meta={meta} chartBoxes={preparedChartBoxes} />;
};

export default BugsRaisedVsFixedPanel;
