import { css } from '@emotion/react';

export const listStyles = ({ spacing }) => css`
  background-color: transparent;
  display: flex;
  margin-bottom: ${spacing.gap['0']};
  padding: ${spacing.gap['0']};
`;

export const listItemStyles = ({ color, font }) => css`
  color: ${color.neutral[80]};
  font-size: ${font.size.l};
  letter-spacing: -0.4px;

  .breadcrumb-link {
    color: ${color.neutral[80]};

    &:hover {
      text-decoration: underline !important;
    }
  }

  &.active {
    color: ${color.neutral[100]};
  }
`;
