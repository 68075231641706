import React, { useCallback, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { RemoveEntityType, RemoveModal } from '@align-components/remove-modal';
import { useActionMenu } from '@align-hooks/useActionMenu';
import { ChartLegends } from '@align-pages/dashboard/components/chart-edit/components/chart-legends';
import { IDashboardChartBox } from '@align-pages/dashboard/components/dashboard-chart-box/dashboardChartBox.types';
import { useDashboardCharts } from '@align-pages/dashboard/hooks';
import { ChartOperationType } from '@align-pages/dashboard/hooks/types';
import { useDashboardResponseHandle } from '@align-pages/dashboard/hooks/useDashboardResponseHandle';
import { DashboardChartServices } from '@align-pages/dashboard/services/dashboardChartServices';
import { StringService } from '@analytics-services/stringService';
import { useHistory } from '@common-hooks/useHistory';
import { ActionMenu } from '@lib/ActionMenu';
import { Spinner } from '@lib/Spinner';
import { Chart } from '@lib/chart';
import { Icon, icons } from '@lib/icon';
import { Tooltip, TooltipPlace } from '@lib/tooltip';
import { useTheme } from '@styles/hooks';

import { useChartRemoveModal } from './dashboardChartBox.hooks';
import {
  wrapperStyles,
  headerStyles,
  bodyStyles,
  dragIconStyles,
} from './dashboardChartBox.styles';

export const DashboardChartBox: React.FC<IDashboardChartBox> = React.memo(
  ({ draggingId, chart, onDrop, onDragEnter, onDragStart, onDragOver, onDragLeave }) => {
    const history = useHistory();
    const { url } = useRouteMatch();
    const {
      isModalOpen,
      removeGoal,
      openModal,
      closeModal,
      isSuccess,
      isError,
    } = useChartRemoveModal(chart);
    const { shouldCloseMenu, setShouldCloseMenu } = useActionMenu();

    useDashboardResponseHandle({
      isSuccess,
      isError,
      operationType: ChartOperationType.REMOVE,
    });

    const { color } = useTheme();
    const { interval, display } = useMemo(
      () =>
        DashboardChartServices.getChartInterval({
          date_to: chart.date_to,
          date_from: chart.date_from,
          time_interval: chart.time_interval,
        }),
      [chart]
    );

    const {
      options,
      chartType,
      isLoading,
      groupByOptions,
      seriesNames,
      seriesColors,
    } = useDashboardCharts({
      ...interval,
      metric: chart.metric,
      filters: chart.filters,
      group_by: chart.group_by,
    });

    const [isDraggable, setIsDraggable] = useState(false);

    const handleIconMouseDown = useCallback(() => {
      setIsDraggable(true);
    }, []);

    const handleIconMouseUp = useCallback(() => {
      setIsDraggable(false);
    }, []);

    const isCurrentlyDraggedCard = useMemo(() => draggingId === chart.id, [draggingId, chart.id]);

    const isAnyDraggingHappening = useMemo(() => !!draggingId, [draggingId]);

    const actionMenuItems = useMemo(() => {
      return [
        {
          name: 'View / Edit',
          onClick: () => history.pushWithBackState(`${url}/edit/${chart.id}`),
        },
        {
          name: 'Remove',
          onClick: () => {
            openModal();
            setShouldCloseMenu(true);
          },
        },
      ];
    }, [chart.id, history, url]);

    const chartTitle = useMemo(() => StringService.truncateText(chart.name, 35), [chart.name]);

    const navigateToEditPage = useCallback(
      () => history.pushWithBackState(`${url}/edit/${chart.id}`),
      [history, url, chart]
    );

    return (
      <div
        css={wrapperStyles({
          isAnyDraggingHappening,
          isCurrentlyDraggedCard,
        })}
        draggable={isDraggable}
        onDrop={onDrop}
        onDragEnter={onDragEnter}
        onDragStart={onDragStart}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
      >
        <div css={headerStyles({ isAnyDraggingHappening, isCurrentlyDraggedCard })}>
          <div
            css={dragIconStyles({ isAnyDraggingHappening })}
            onMouseDown={handleIconMouseDown}
            onMouseLeave={handleIconMouseUp}
          >
            <Icon icon={icons.drag} size={18} color={color.neutral['80']} />
          </div>
          <div className="title-area" onClick={navigateToEditPage}>
            <div className="title">
              <span>{chartTitle}</span>
              <Tooltip
                content={chart.description}
                place={TooltipPlace.BOTTOM}
                // Tooltip has fixed positioned elements which brakes the drag view.
                // Disabling tooltip while the component isDraggable is the easiest way to avoid this
                enabled={!isDraggable}
              >
                <Icon icon={icons.info} size={16} color={color.neutral['80']} />
              </Tooltip>
            </div>
            <div>{display}</div>
          </div>
          <div className="menu">
            <ActionMenu actions={actionMenuItems} shouldClose={shouldCloseMenu} />
          </div>
        </div>
        <div css={bodyStyles}>
          {isLoading ? (
            <Spinner loading />
          ) : (
            <div className="chart-area">
              <ChartLegends breakdownOptions={groupByOptions} />
              <Chart
                options={options}
                chartType={chartType}
                size={groupByOptions?.length ? 284 : 350}
                seriesNames={seriesNames}
                colors={seriesColors}
              />
            </div>
          )}
        </div>
        <RemoveModal
          entityType={RemoveEntityType.CHART}
          isOpen={isModalOpen}
          onRemove={removeGoal}
          onCancel={closeModal}
        />
      </div>
    );
  }
);
