import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { ActionMenuListItem } from '../ActionMenuListItem';
import { wrapperStyles } from './ActionMenuList.styles';
import { IActionMenuList } from './ActionMenuList.types';

export const ActionMenuList: React.FC<IActionMenuList> = React.memo(({ actions, isExpanded }) => {
  return (
    isExpanded && (
      <div css={wrapperStyles}>
        {actions.map((item) => (
          <ActionMenuListItem key={uuidv4()} name={item.name} onClick={item.onClick} />
        ))}
      </div>
    )
  );
});
