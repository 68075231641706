import React, { useCallback, useState } from 'react';

import { IDropdownNameValue } from '@lib/Dropdown';
import Modal from '@lib/Modal';

import { TeamEditRenamePanel } from './team-edit-rename-panel';
import { wrapperStyles } from './teamEditModal.styles';
import { ITeamEditModal } from './teamEditModal.types';

export const TeamEditModal: React.FC<ITeamEditModal> = React.memo(
  ({ id, isOpen, teamNameInit, parentTeamInit, parentTeamOptions, onCancel, onEdit }) => {
    const [teamNameInput, setTeamNameInput] = useState(teamNameInit);
    const [parentIdFromEdit, setParentIdFromEdit] = useState(parentTeamInit?.value || null);
    const teamNameOnChange = useCallback((val: string) => {
      setTeamNameInput(val);
    }, []);
    const parentTeamOnChange = useCallback((parentObj: IDropdownNameValue) => {
      setParentIdFromEdit(parentObj?.value);
    }, []);
    const onPrimaryClick = useCallback(() => {
      onEdit(teamNameInput, parentIdFromEdit);
    }, [teamNameInput, parentIdFromEdit]);
    return (
      isOpen && (
        <div css={wrapperStyles}>
          <Modal
            content={
              <TeamEditRenamePanel
                teamNameInit={teamNameInput}
                parentTeamInit={parentTeamInit}
                parentTeamOptions={parentTeamOptions}
                teamNameOnChange={teamNameOnChange}
                parentTeamOnChange={parentTeamOnChange}
              />
            }
            isOpen={isOpen}
            portalId={id}
            primaryButtonText="Save"
            secondaryButtonText="Cancel"
            title="Edit Team"
            onPrimaryClick={onPrimaryClick}
            onSecondaryClick={onCancel}
            setOpen={onCancel}
          />
        </div>
      )
    );
  }
);
