import { privateApi } from '@common-services/api/private';
import {
  SearchJIRAIssuesRequest,
  SearchJIRAIssuesResponse,
} from '@common-services/api/private/generated-from-backend/models';

export const fetchSearchedIssues = async (
  params: SearchJIRAIssuesRequest,
  token: string
): Promise<SearchJIRAIssuesResponse> => {
  return privateApi.issues.fetchSearchedIssues(params, token, {
    message: `Cannot get searched Jira issues for accountId ${params.account} from private API`,
  });
};

export * as PrivateIssuesServices from './issues';
