import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Simple from '@analytics-pages/templates/Simple';
import { InvitationCheckResult } from '@common-services/api/public/generated-from-backend/models';
import { useCheckInvitation } from '@common-services/api/public/hooks/useRegistrationData';
import { useUserDetails } from '@common-services/api/public/hooks/useUserData';
import log from '@common-services/logger';
import { ACCOUNT_CONSTANTS } from '@routes/account/routesСonstants';
import { AuthRouteEnum } from '@routes/authRoutes';

interface IRedirectObj {
  pathname: string;
}

export interface ICallbackHistoryState extends Pick<InvitationCheckResult, 'type'> {
  inviteLink: string;
  name: string;
}

export const Callback: React.FC = React.memo(() => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const inviteLink = query.get('inviteLink');

  const invitationAcceptRequest = useRef(false);
  const [redirectTo, setRedirectTo] = useState<IRedirectObj>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const {
    data: userDetails,
    error: userDetailsError,
    isLoading: userDetailsIsLoading,
  } = useUserDetails();
  const {
    data: inviteLinkStatus,
    error: inviteLinkStatusError,
    isLoading: inviteLinkStatusIsLoading,
  } = useCheckInvitation({ url: inviteLink }, true);
  const userDetailsRoute = AuthRouteEnum.ACCOUNT + ACCOUNT_CONSTANTS.USER_DETAILS;
  const redirect = (path: string) => {
    // DON'T change with a client-side routing. See DEV-1620.
    window.location.replace(path);
  };
  let timeoutInstance: NodeJS.Timeout;

  useEffect(() => {
    if (inviteLink && inviteLinkStatus && userDetails) {
      invitationAcceptRequest.current = true;

      if (inviteLinkStatus?.valid === true && inviteLinkStatus?.active === true) {
        if (
          Object.keys(userDetails?.accounts).length === 0 &&
          userDetails.id.indexOf('github|') === 0
        ) {
          history.push({
            pathname: userDetailsRoute,
            state: { inviteLink, name: userDetails.name, userType: inviteLinkStatus.type },
          });
        } else {
          redirect(AuthRouteEnum.INDEX);
        }
      } else {
        timeoutInstance = setTimeout(() => redirect(AuthRouteEnum.INDEX), 10000);
        setErrorMessage('Invitation link is not valid, was revoked or expired');
      }
    }
  }, [inviteLinkStatus, userDetails]);

  useEffect(() => {
    if (inviteLinkStatusError) {
      log.error('Something went wrong while checking invitation link, please try again later.');
    }
  }, [inviteLinkStatusError]);

  useEffect(() => {
    if (userDetailsError) {
      log.error('Something went wrong while fetching user data, please try again later.');
    }
  }, [userDetailsError]);

  useEffect(() => {
    if (!inviteLink) {
      setRedirectTo({
        pathname: query.get('targetUrl') || '/',
      });
    }
    return () => {
      if (timeoutInstance) {
        clearTimeout(timeoutInstance);
      }
    };
  }, []);

  if (userDetailsIsLoading || inviteLinkStatusIsLoading) {
    return <Simple>Loading...</Simple>;
  }

  if (redirectTo?.pathname) {
    redirect(redirectTo.pathname);
    return null;
  }

  if (errorMessage) {
    return (
      <Simple linkToHome={false}>
        <div>
          <div>
            Access denied
            <span className="mt-3" style={{ display: 'block', fontStyle: 'italic' }}>
              {errorMessage}
            </span>
          </div>
          <div className="mt-5">You'll be redirected to the home page.</div>
        </div>
      </Simple>
    );
  }

  return <Simple>Not authenticated</Simple>;
});
