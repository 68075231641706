import React from 'react';

import { SelectorTsFilterSection } from '@lib/selector-ts/components/filterSection';
import { SelectorTsFooterSection } from '@lib/selector-ts/components/footerSection';
import { SelectorTsOptionsSection } from '@lib/selector-ts/components/optionsSection';
import { SelectorTsProvider } from '@lib/selector-ts/context';

import getStyle from './styles/index';

export interface SelectTsOptionSingle {
  name: string;
  subName?: string;
  id?: string | null;
  isSelected?: boolean;
  isOpened?: boolean;
  parentIds?: string[];
  children?: SelectTsOptionSingle[];
  image?: string;
  color?: string;
}

export interface SelectorTsProps {
  optionsInit: SelectTsOptionSingle[];
  maxSelected?: number;
  minSelected?: number;
  maxHeight?: number;
  withAllOption?: boolean;
  isCascading?: boolean;
  isLoading?: boolean;
  isLoadMoreEnabled?: boolean;
  loadMoreLabel?: string;
  onApply?: (selected: string[]) => void;
  onCancel?: () => void;
  onLoadMoreClick?: () => void;
}

export const SelectorTs: React.FC<SelectorTsProps> = React.memo(({ ...restProps }) => {
  return (
    <div data-cy="selector" css={(theme) => getStyle(theme, false)}>
      <SelectorTsProvider {...restProps}>
        <SelectorTsFilterSection />
        <SelectorTsOptionsSection />
        <SelectorTsFooterSection />
      </SelectorTsProvider>
    </div>
  );
});

SelectorTs.defaultProps = {
  withAllOption: false,
  isCascading: false,
  isLoading: false,
  isLoadMoreEnabled: false,
  loadMoreLabel: 'Load more',
  maxHeight: 220,
  minSelected: 0,
  optionsInit: [],
};
