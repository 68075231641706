interface ApiKeyAuthType {
  in: string;
  name: string;
  type: string;
}

interface BearerAuthType {
  accessToken: string;
  type: string;
}

interface AuthenticationsType {
  apiKeyAuth: ApiKeyAuthType;
  bearerAth: BearerAuthType;
}

interface HeadersType {
  [key: string]: string;
}

interface ApiClientType {
  authentications: AuthenticationsType;
  basePath: string;
  cache: boolean;
  defaultHeaders: HeadersType;
  enableCookies: boolean;
  plugins: any; // TODO: unknown type as currently it shows null
  requestAgent: any; // TODO: unknown type as currently it shows null
  timeout: number;
}

export interface ApiType {
  [key: string]: any; // this covers all the different methods of API client
  apiClient: ApiClientType;
}

export interface IApiBasicError {
  type: string;
  title: string;
  status: number;
  detail: string;
}

export interface SentryCaptureOutput<T extends any> {
  response: {
    body: T;
  };
}

export enum ApiMode {
  PUBLIC = 'public',
  PRIVATE = 'private',
  MOCK = 'mock',
}

export enum ApiInstanceName {
  PUBLIC = 'instance',
  PRIVATE = 'privateInstance',
}

export enum VarType {
  STRING = 'string',
  NUMBER = 'number',
  FLOAT = 'float',
}
