import { useMemo } from 'react';

import { GoalsSingleComponentServices } from '@align-pages/goals/single/components/goals-single-component';
import { ValueType } from '@align-types/constants';
import { GoalMetricParams } from '@common-services/api/private/generated-from-backend/models';
import { GoalTemplate } from '@common-services/api/public/generated-from-backend/models';

export function useIsAllowedByLimits(
  value: GoalMetricParams['threshold'],
  unit: string,
  valueType: ValueType,
  metricValue: GoalTemplate['metric']
): boolean {
  const memoIsAllowedByLimits = useMemo(() => {
    const convertedServerValue = GoalsSingleComponentServices.getConvertedServerData(
      value.toString(),
      unit,
      valueType,
      metricValue
    );
    /*Limit by 12 months*/
    return parseInt(convertedServerValue.toString()) < 31104000;
  }, [value, unit, valueType, metricValue]);
  return valueType === ValueType.date ? memoIsAllowedByLimits : true;
}
