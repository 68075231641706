import React from 'react';

import { PrimaryLayout } from '@align-components/layouts/primary';
import { PrimaryLayoutTabs } from '@align-components/layouts/primary/context.types';

import { DashboardContent } from './dashboard-content';

const Dashboard: React.FC = React.memo(() => (
  <PrimaryLayout tab={PrimaryLayoutTabs.DASHBOARD}>
    <DashboardContent />
  </PrimaryLayout>
));

export { Dashboard };
