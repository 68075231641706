import React, { useMemo } from 'react';

import { IChartLegends } from '@align-pages/dashboard/components/chart-edit/components/chart-legends/chartLegends.types';

import { wrapperStyles, badgeStyles, legendCircleStyles } from './chartLegends.styles';

export const ChartLegends: React.FC<IChartLegends> = React.memo(({ breakdownOptions }) => {
  const selectedOptions = useMemo(() => breakdownOptions?.filter(({ selected }) => selected), [
    breakdownOptions,
  ]);

  return selectedOptions?.length ? (
    <div css={wrapperStyles}>
      <div className="legends-box">
        {selectedOptions.map((item) => (
          <div css={badgeStyles} key={item.value}>
            <div css={legendCircleStyles({ circleColor: item.color })} />
            {item.name}
          </div>
        ))}
      </div>
    </div>
  ) : null;
});
