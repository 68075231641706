import { css } from '@emotion/react';

export const modalContentStyles = css`
  align-items: flex-end;
  display: flex;
`;

export const dividerStyles = ({ spacing }) => css`
  height: ${spacing.gap['06']};
`;

export const headerStyles = css`
  display: flex;
  justify-content: space-between;
`;

export const headerTitleStyles = ({ spacing }) => css`
  align-items: center;
  display: flex;

  & > div:first-of-type {
    margin-right: ${spacing.gap['03']};
  }
`;

export const spinnerWrapperStyles = css`
  display: flex;
  justify-content: center;
`;
