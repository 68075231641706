interface IAccountStatus {
  is_admin: boolean;
  has_ci: boolean;
  has_deployments: boolean;
  has_jira: boolean;
  expired: boolean;
}

interface IReposet {
  created: Date;
  id: number;
  name: string;
  precomputed: boolean;
  repos: string[];
  updated: Date;
}

interface IDefaultAccount {
  id: number;
  is_admin: boolean;
  reposets: IReposet[];
}

export interface IUser {
  accounts?: IAccountStatus[];
  defaultAccount?: IDefaultAccount;
  defaultReposet?: IReposet;
  email?: string;
  id: string;
  login: string;
  name?: string;
  native_id: string;
  picture?: string;
  updated?: Date;
  impersonated_by?: string;
}

export interface ITheme {
  color?: any;
}

interface ITeamMember {
  login: string;
  name: string;
  picture: string;
}

export interface ITeam {
  id: number;
  name: string;
  members: [ITeamMember];
  parent: number | null;
}

export enum IBadgeType {
  DEFAULT = 'default',
  GHOST = 'ghost',
}

export enum IBadgeSize {
  XSMALL = 'xsmall',
  SMALL = 'small',
  DEFAULT = 'default',
}
