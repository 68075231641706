import { css } from '@emotion/react';

export const wrapperStyles = ({ color, spacing: { gap }, radius }) => css`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: ${gap['06']};
  padding: ${gap['06']};
  background: ${color.neutral.white};
  border: 1px solid ${color.neutral['40']};
  border-radius: ${radius.default};
`;

export const headerStyles = ({ color, font, spacing: { gap } }) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 28px;

  .header-wrapper {
    display: flex;
    align-items: center;
    gap: ${gap['03']};
  }

  .header-info {
    display: flex;
    align-items: center;
    gap: ${gap['02']};

    span {
      font-size: ${font.size.s};
      font-weight: ${font.weight.regular};
      color: ${color.neutral['100']};
    }
  }
`;

export const bodyStyles = ({ spacing: { gap } }) => css`
  display: flex;
  flex-direction: column;
  gap: ${gap['06']};
`;
