import React from 'react';

import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import Panel from '@analytics-components/insights/Panel';
import { prepareChartBoxes } from '@analytics-pages/delivery-pipeline/insights/helper';

import codeReviews from './codeReviews';
import reviewTimePerRepo from './reviewTimePerRepo';

const meta = {
  title: 'Code Reviews per Repository',
};

const chartBoxes = [
  {
    id: 'code-review-time',
    component: ChartBoxWithKPIWidget,
    params: codeReviews,
  },
  {
    id: 'review-time-per-repo',
    component: ChartBoxWithKPIWidget,
    params: reviewTimePerRepo,
  },
];

const CodeReviewsPanel = () => {
  const preparedChartBoxes = prepareChartBoxes(chartBoxes);
  return <Panel meta={meta} chartBoxes={preparedChartBoxes} />;
};

export default CodeReviewsPanel;
