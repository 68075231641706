import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

import UpgradeOutcome from '@assets/images/upgrade/outcome_upgrade.png';
import UpgradeQuality from '@assets/images/upgrade/quality_upgrade.png';
import Upgrade01 from '@assets/images/upgrade/upgrade_01.svg';
import Upgrade02 from '@assets/images/upgrade/upgrade_02.svg';
import Upgrade03 from '@assets/images/upgrade/upgrade_03.svg';
import Upgrade04 from '@assets/images/upgrade/upgrade_04.svg';
import { analytics } from '@utils/analytics';

const getUpgradeBox = (backgroundImage) => ({
  backgroundImage: 'url(' + backgroundImage + ')',
  backgroundSize: '100%',
  backgroundRepeat: 'no-repeat',
  paddingTop: '200px',
});

const backgroundImage = {
  quality: UpgradeQuality,
  outcome: UpgradeOutcome,
};

const Upgrade = ({ section }) => {
  const [redirectTo, setRedirectTo] = useState(null);
  const style = getUpgradeBox(backgroundImage[section]);

  const handleLinkClick = (event, link) => {
    return (e) => {
      e.preventDefault();
      analytics.track(event, null, () => {
        setRedirectTo({
          pathname: link,
        });
      });
    };
  };

  if (redirectTo?.pathname) {
    window.location.href = redirectTo.pathname;
  }

  return (
    <div style={style} className="container">
      <div className="row text-center">
        <div className="col">
          <h2 className="text-dark h3 mt-5 mb-2">
            Upgrade your account to access Quality & Outcome insights
          </h2>
          <p className="text-secondary mb-5">Requires Jira integration</p>
        </div>
      </div>
      <div className="row text-center mb-3">
        <div className="col-3">
          <div className="p-2">
            <img alt="" className="mb-5" src={Upgrade01} />
            <h3 className="mb-3 h4">
              <span className="text-dark">Jira Integration</span>
            </h3>
            <ul className="list-unstyled upgrade-list">
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Jira Cloud &
                Server
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Atlassian
                Connect
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Mapping between
                PRs and tickets
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> PR filtering by
                Jira metadata
              </li>
            </ul>
          </div>
        </div>
        <div className="col-3">
          <div className="p-2">
            <img alt="" className="mb-5" src={Upgrade02} />
            <h3 className="mb-3 h4">
              <span className="text-dark">Software Delivery Pipeline</span>
            </h3>
            <ul className="list-unstyled upgrade-list">
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Plan & Design
                stage
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Mean Time To
                Restore (MTTR)
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Resolution
                frequency
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Throughput by
                work type
              </li>
            </ul>
          </div>
        </div>
        <div className="col-3">
          <div className="p-2">
            <img alt="" className="mb-5" src={Upgrade04} />
            <h3 className="mb-3 h4">
              <span className="text-dark">QA Metrics</span>
            </h3>
            <ul className="list-unstyled upgrade-list">
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Customer-facing
                bugs
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Bug fixing rate
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Defect
                distribution
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Breakdown by
                severity
              </li>
            </ul>
          </div>
        </div>
        <div className="col-3">
          <div className="p-2">
            <img alt="" className="mb-5" src={Upgrade03} />
            <h3 className="mb-3 h4">
              <span className="text-dark">New Features Analysis</span>
            </h3>
            <ul className="list-unstyled upgrade-list">
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Progress
                tracking
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Reliability
                score
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Knowledge
                sharing
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Breakdown by
                theme
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-12 text-center">
          <button
            className="btn btn-orange btn-large mr-3"
            onClick={handleLinkClick('Upgrade Requested', 'https://www.athenian.co/request-demo')}
          >
            Request Upgrade
          </button>
          <a
            className="btn btn-secondary btn-large"
            href="https://athenian.canny.io/changelog/quality-outcome"
          >
            Learn More
          </a>
        </div>
      </div>
    </div>
  );
};

export default Upgrade;
