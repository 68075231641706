import { BasicApiClass } from '@common-services/api/common/BasicApiClass';
import { REST_TYPES } from '@common-services/api/common/types/apiClient';
import { ApiMode } from '@common-services/api/common/types/common';
import { ISentryMethodParams } from '@common-services/api/common/types/sentry';
import { PUBLIC_API_ENDPOINTS_CONSTANTS } from '@common-services/api/public/constants';
import {
  FilterDeploymentsRequest,
  FilteredDeployments,
} from '@common-services/api/public/generated-from-backend/models';

export class DeploymentsApi extends BasicApiClass {
  constructor() {
    super(ApiMode.PUBLIC);
  }
  public fetchDeployments(
    body: FilterDeploymentsRequest,
    token: string,
    sentry?: ISentryMethodParams
  ): Promise<FilteredDeployments> {
    this.assignToken(token);
    const callApi = this.callApi<FilteredDeployments>(
      PUBLIC_API_ENDPOINTS_CONSTANTS.DEPLOYMENTS,
      REST_TYPES.POST,
      body
    );
    return sentry ? this.callSentry<FilteredDeployments>(callApi, sentry) : callApi;
  }
}
