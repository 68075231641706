import React from 'react';

import { NumberService } from '@common-services/numberService';
import { Icon, icons } from '@lib/icon';
import { theme } from '@styles/theme';

import { wrapperStyles } from './targetValue.styles';
import { ITargetValueProps } from './targetValue.types';

const TargetValue: React.FC<ITargetValueProps> = React.memo(({ value, isTLO }) => (
  <div css={wrapperStyles}>
    <Icon color={theme.color.ui.blue['100']} icon={icons.goal} size={16} />
    <div className="target-value">
      {isTLO ? `${NumberService.round(parseFloat(value) * 100, 0)} %` : value}
    </div>
  </div>
));

export { TargetValue };
