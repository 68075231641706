import { privateApi } from '@common-services/api/private';
import {
  SearchPullRequestsRequest,
  SearchPullRequestsResponse,
} from '@common-services/api/private/generated-from-backend/models';

export const fetchSearchedPRs = async (
  params: SearchPullRequestsRequest,
  token: string
): Promise<SearchPullRequestsResponse> => {
  return privateApi.pullRequests.fetchSearchedPRs(params, token, {
    message: `Cannot get searched pull requests for accountId ${params.account} from private API`,
  });
};

export const isFetchSearchedPRsEnabled = (params: SearchPullRequestsRequest): boolean => {
  return !!(params?.date_from && params?.order_by[0]?.field);
};

export * as PrivatePRsServices from './pullRequests';
