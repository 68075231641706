import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { ContentType } from '@analytics-components/insights/Box/types';
import { cardBodyStyles } from '@analytics-components/insights/styles';
import { NoData } from '@lib/empty/noData';

import ChartTitle from '../ChartTitle';
import SimpleBoxBodyRow from '../SimpleBoxBodyRow';
import WithKPIBoxBodyRow from '../WithKPIBoxBodyRow';

interface Props {
  content: ContentType[];
}

const BoxBody: React.FC<Props> = React.memo(({ content }) => {
  const contentWithID = useMemo(
    () =>
      content.map((c) => ({
        ...c,
        uniqueID: uuidv4(),
      })),
    [content]
  );
  return (
    <>
      {contentWithID.map((row) => (
        <div key={row.uniqueID} css={cardBodyStyles}>
          {row?.empty ? (
            <>
              <ChartTitle {...row.chart} />
              <NoData />
            </>
          ) : row.kpis.length > 0 ? (
            <WithKPIBoxBodyRow chart={row.chart} kpis={row.kpis} />
          ) : (
            <SimpleBoxBodyRow chart={row.chart} />
          )}
        </div>
      ))}
    </>
  );
});

export default BoxBody;
