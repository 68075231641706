import _ from 'lodash';

import { fetchJIRAMetrics } from '@analytics-services/api/jira';
import { FAKE_USERNAME } from '@common-pages/Settings/constants';
import { number } from '@common-services/format';
import { theme } from '@styles/theme';
import RingChart from '@ui/charts/RingChart';
import { hexToRGBA } from '@utils/colors';

const bugsFixingRatio = {
  fetcher: async (api, cachedData, apiContext) => {
    const {
      account,
      interval,
      excludeInactive,
      contributors,
      includeNullContributor,
      includeFakeContributor,
      epics,
    } = apiContext;
    const jiraAssignees = _(contributors).map('jira_user').filter().value();
    if (includeNullContributor) {
      jiraAssignees.push(null);
    }
    if (includeFakeContributor) {
      jiraAssignees.push(FAKE_USERNAME);
    }

    const bugs = await fetchJIRAMetrics(
      api,
      account,
      ['all'],
      interval,
      ['raised', 'resolved'],
      excludeInactive,
      { types: ['Bug'], epics },
      { assignees: jiraAssignees }
    );

    const [raised, resolved] = bugs[0].values[0].values;
    return {
      raised,
      resolved,
    };
  },
  plumber: (fetchedData, cachedData, apiContext) => {
    const chartData = [
      { value: fetchedData.raised, label: 'Raised' },
      { value: fetchedData.resolved, label: 'Fixed' },
    ];
    return {
      empty: chartData.length === 0,
      chart: {
        component: RingChart,
        params: {
          data: chartData,
          params: {
            labels: {
              primary:
                fetchedData.raised > 0
                  ? number.percentage((fetchedData.resolved / fetchedData.raised) * 100)
                  : 'No bugs',
              secondary: 'Bug Fixing Ratio',
            },
            colors: [hexToRGBA(theme.color.ui.red[100], 0.7), theme.color.ui.lightblue[100]],
          },
        },
      },
      kpis: [],
    };
  },
};

export default bugsFixingRatio;
