import _ from 'lodash';
import React from 'react';

import { SmallTitle, BigNumber } from '@analytics-components/Typography';

import {
  progressBarStyle,
  progressBarFullStyle,
  progressBarHeaderStyle,
  progressBarMultiStyle,
  progressBarLineStyle,
  progressBarStagesStyle,
} from './styles';

export const ProgressBarLine = ({ color, height, value }) => (
  <div css={(theme) => progressBarLineStyle(theme, height, color)}>
    <div
      role="progressbar"
      css={{ width: `${value}%`, backgroundColor: color }}
      aria-valuenow={value}
      aria-valuemin="0"
      aria-valuemax="100"
    />
  </div>
);

const ProgressBar = ({ value, color, labelLeft, labelRight }) => {
  return (
    <div css={progressBarStyle}>
      <div css={progressBarHeaderStyle}>
        <SmallTitle content={labelLeft} />
        <BigNumber content={labelRight} />
      </div>
      <ProgressBarLine color={color} height={4} value={value} />
    </div>
  );
};

const FlatProgressBar = ({ color, labelLeft, labelRight, value }) => (
  <div css={progressBarFullStyle}>
    <SmallTitle content={labelLeft} />
    <ProgressBarLine color={color} height={8} value={value} />
    <BigNumber content={labelRight} />
  </div>
);

export const FlatProgressBarMulti = ({ params, normalize, upperCase = true }) => {
  const normalizer = normalize ? 100 / _(params).map('value').max() : 1;
  const normalizedParams = _(params)
    .map((p) => ({ ...p, value: p.value * normalizer }))
    .value();

  return (
    <div>
      {_(normalizedParams)
        .map((p, i) => (
          <React.Fragment key={i}>
            <FlatProgressBar {...p} />
            {i < normalizedParams.length - 1}
          </React.Fragment>
        ))
        .value()}
    </div>
  );
};

export const ProgressBarMulti = ({ params, normalize, uppercase = true }) => {
  const normalizer = normalize ? 100 / _(params).map('value').max() : 1;
  const normalizedParams = _(params)
    .map((p) => ({ ...p, value: p.value * normalizer }))
    .value();

  return (
    <div css={progressBarMultiStyle}>
      {_(normalizedParams)
        .map((p, i) => <ProgressBar key={i} uppercase={uppercase} {...p} />)
        .value()}
    </div>
  );
};

export const ProgressBarStages = ({ stages, total }) => (
  <div css={progressBarStagesStyle}>
    {stages &&
      stages.map((stage, index) => (
        <div
          key={`progress-${index}`}
          role="progressbar"
          css={{ width: `${(stage.value * 100) / total}%`, backgroundColor: stage.color }}
          aria-valuenow={stage.value}
          aria-valuemin="0"
          aria-valuemax={total}
        />
      ))}
  </div>
);

export default ProgressBar;
