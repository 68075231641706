import jwt_decode from 'jwt-decode';
import React, { useContext, useEffect, useState } from 'react';

import { buildApi } from '@analytics-services/api';
import { datetime } from '@analytics-services/datetimeService';
import { useAuth0 } from '@common-context/Auth0';
import { useDemoCookie } from '@common-pages/Demo';

const Context = React.createContext({});

const useApiContext = () => useContext(Context);

const ApiProvider = ({ children }) => {
  const { loading, isAuthenticated, getTokenSilently, loginWithRedirect, logout } = useAuth0();
  const { isDemo } = useDemoCookie();
  const [token, setToken] = useState();
  const [api, setApi] = useState();

  useEffect(() => {
    if (!!api) {
      return;
    }
    if (!isAuthenticated && !isDemo) {
      return;
    }

    (async () => {
      const token = isDemo ? null : await getTokenSilently();
      setToken(token);
      const api = buildApi(token);
      setApi(api);
      const decoded = jwt_decode(token);
      const msValidity = datetime(decoded.exp * 1000).diff();
      console.log(`[${new Date()}] Token will expire at: ${decoded.exp}`);
      console.log(`[${new Date()}] Logout will be forced in ${msValidity}ms`);
      window.setTimeout(() => {
        logout({ returnTo: window.ENV.auth.logoutRedirectUri });
      }, msValidity);
    })();
  }, [api, getTokenSilently, isAuthenticated, isDemo]);

  const auth = {
    token,
    loading,
    isAuthenticated,
    loginWithRedirect,
    logout,
  };

  return (
    <Context.Provider
      value={{
        api,
        auth,
        ready: !!api,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ApiProvider;
export { useApiContext };
