import { css } from '@emotion/react';

export const pipelineThumbnailsStyles = ({ color, radius, spacing, transition }) => css`
  align-items: flex-end
  margin-top: ${spacing.gap['06']};
  margin-bottom: ${spacing.gap['06']};
  position: relative;
  z-index: 5;

  &:after {
    content: '';
    display: block;
    margin: -1px 15px 0;
    width: 100%;
  }

  .card {
    border-radius: ${radius.default};
    min-height: 122px;
    transition: ${transition.default};
  }
`;

export const pipelineStageStyles = ({ color, spacing }, isActive) => css`
  position: relative;
  border-top: 2px dashed ${color.neutral[40]};
  padding-top: ${spacing.gap['04']};

  a {
    text-decoration: none;
    color: ${color.text.secondary};
  }

  &.active {
    z-index: 20;
    border-top-style: solid;
    pointer-events: none;

    &.wip,
    &.wip .event-before,
    &.wip .event-after {
      border-color: ${color.stage.wip};
      border-width: 2px;
    }
    &.wip .event-before,
    &.wip .event-after {
      &:hover {
        background: ${color.stage.wip};
      }
    }

    &.review,
    &.review .event-before,
    &.review .event-after {
      border-color: ${color.stage.review};
      border-width: 2px;
    }
    &.review .event-before,
    &.review .event-after {
      &:hover {
        background: ${color.stage.review};
      }
    }

    &.merge,
    &.merge .event-before,
    &.merge .event-after {
      border-color: ${color.stage.merge};
      border-width: 2px;
    }
    &.merge .event-before,
    &.merge .event-after {
      &:hover {
        background: ${color.stage.merge};
      }
    }

    &.release,
    &.release .event-before,
    &.release .event-after {
      border-color: ${color.stage.release};
      border-width: 2px;
    }
    &.release .event-before,
    &.release .event-after {
      &:hover {
        background: ${color.stage.release};
      }
    }
  }

  .event-before,
  .event-after {
    display: inline-block;
    width: ${spacing.gap['04']};
    height: ${spacing.gap['04']};
    border: 2px solid ${color.neutral[40]};
    background: ${color.neutral.white};
    border-radius: ${spacing.gap['04']};
    position: absolute;
    top: -7px;
    z-index: 10;

    &:hover {
      background: ${color.neutral[40]};
    }
  }

  .event-before {
    left: -6px;
  }

  .event-after {
    right: -6px;
  }
`;
