import React, { useEffect, useState, useReducer, useRef } from 'react';

import { formControlStyles } from '@styles/components';

import {
  USE_DEFAULT_PATTERN,
  USE_CUSTOM_PATTERN,
  PLACEHOLDER_PATTERNS,
  DEFAULT_PATTERNS,
} from '../constants';
import { ITypeOptions } from '../types';
import { inputPatternReducer } from '../utils';

const Strategy = ({ id, name, pattern, defaultText, onChange }) => {
  const [edittingState, setEdittingState] = useState(false);
  const [inputPatternState, dispatchInputPattern] = useReducer(inputPatternReducer, '');
  const inputPatternRef = useRef<HTMLInputElement>();

  const saveDefault = () => {
    if (pattern !== DEFAULT_PATTERNS[name]) {
      onChange(name, DEFAULT_PATTERNS[name], () => dispatchInputPattern({}));
    }
  };

  const savePattern = (event) => {
    const inputPattern = event.target.value;
    setEdittingState(false);
    if (inputPattern === '' || inputPattern === DEFAULT_PATTERNS[name]) {
      saveDefault();
    } else if (pattern !== inputPattern) {
      onChange(name, inputPattern, null, () => {
        dispatchInputPattern({ name, pattern });
      });
    }
  };

  const changeUseDefaultPattern = (event) => {
    const useDefault = event.target.value === USE_DEFAULT_PATTERN;
    if (useDefault) {
      setEdittingState(false);
      saveDefault();
    } else {
      setEdittingState(true);
      inputPatternRef.current.focus();
    }
  };

  useEffect(() => {
    dispatchInputPattern({ name, pattern });
  }, [pattern, name]);

  if (name === ITypeOptions.AUTO || name === ITypeOptions.EVENT) {
    return <div>{defaultText}</div>;
  }

  const radioFormName = `${id}-${name}`;
  const radioFormDefaultId = `${radioFormName}-default`;
  const radioFormCustomId = `${radioFormName}-custom`;

  return (
    <>
      <div className="mr-5">
        <div className="custom-control custom-radio">
          <input
            className="custom-control-input"
            type="radio"
            name={radioFormName}
            id={radioFormDefaultId}
            checked={!edittingState && pattern === DEFAULT_PATTERNS[name]}
            value={USE_DEFAULT_PATTERN}
            onChange={changeUseDefaultPattern}
          />
          <label className="custom-control-label" htmlFor={radioFormDefaultId}>
            {defaultText}
          </label>
        </div>
      </div>

      <div className="form-check d-flex align-items-center ml-auto">
        <div className="custom-control custom-radio">
          <input
            className="custom-control-input"
            type="radio"
            name={radioFormName}
            id={radioFormCustomId}
            checked={edittingState || pattern !== DEFAULT_PATTERNS[name]}
            value={USE_CUSTOM_PATTERN}
            onChange={changeUseDefaultPattern}
          />
          <label className="custom-control-label" htmlFor={radioFormCustomId}>
            <span>Pattern:</span>
          </label>
        </div>
        <input
          css={formControlStyles}
          ref={inputPatternRef}
          className="d-inline-block w-200 ml-3"
          type="text"
          value={inputPatternState}
          placeholder={PLACEHOLDER_PATTERNS[name]}
          onFocus={() => setEdittingState(true)}
          onBlur={savePattern}
          onChange={(e) => dispatchInputPattern({ name, pattern: e.target.value })}
        />
      </div>
    </>
  );
};

export default Strategy;
