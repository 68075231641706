import React, { useCallback, useMemo } from 'react';

import { useNavigation } from '@align-components/layouts/primary/context';
import { UsersPanel } from '@align-pages/teams/list/usersSection/usersPanel';
import { useHistory } from '@common-hooks/useHistory';
import { useOwnedTeamMembers } from '@common-services/api/private/hooks/useTeamMembersData';
import { PUBLIC_API_ENDPOINTS_CONSTANTS } from '@common-services/api/public/constants';

import { UsersListPlaceholder } from './usersPanel/ui/UsersListPlaceholder';

const UsersSection: React.FC = React.memo(() => {
  const history = useHistory();
  const { currentTeam } = useNavigation();
  const { data: directMembers, isLoading: directMembersLoading } = useOwnedTeamMembers(
    currentTeam.id,
    false,
    true
  );
  const { data: allMembers, isLoading: allMembersLoading } = useOwnedTeamMembers(
    currentTeam.id,
    true,
    true
  );

  // Exclude direct members from the all members list to find remaining members
  const childMembers = useMemo(
    () =>
      allMembers?.filter((member) => !directMembers?.map((m) => m.login).includes(member.login)) ||
      [],
    [directMembers, allMembers]
  );
  const onEditUsers = useCallback(
    () => history.pushWithBackState(PUBLIC_API_ENDPOINTS_CONSTANTS.SETTINGS_TEAMS),
    [history]
  );
  return directMembersLoading || allMembersLoading ? (
    <UsersListPlaceholder />
  ) : (
    <UsersPanel
      allMembers={allMembers || []}
      childMembers={childMembers}
      directMembers={directMembers || []}
      onEditUsers={onEditUsers}
    />
  );
});

export { UsersSection };
