import React, { useCallback, useContext, useMemo, useState } from 'react';

import { PrimaryLayout } from '@align-components/layouts/primary';
import { PrimaryLayoutTabs } from '@align-components/layouts/primary/context.types';
import { RemoveModal } from '@align-components/remove-modal';
import { useGoalRemoveModal } from '@align-hooks/useGoalRemoveModal';
import { useSingleGoalData } from '@align-pages/goals/single/components/goal-view-component/hooks/useSingleGoalData';
import { GoalsSingleContext } from '@align-pages/goals/single/components/goals-single-context';
import { TeamGoalInput } from '@align-services/api/types/goalsTypes';
import { DateService } from '@align-services/dateService';
import { GoalsService } from '@align-services/goalsService';
import { IMetricsConfigNames } from '@align-types/constants';
import { useHistory } from '@common-hooks/useHistory';
import { IDropdownNameValue } from '@lib/Dropdown';

import { canSaveGoal } from '../tree-view/treeView.services';
import { useExistingTargets, useGoalCRU } from './goalsSingleWrapper.hooks';
import { IGoalsSingleWrapper } from './goalsSingleWrapper.types';

const GoalsSingleWrapper: React.FC<IGoalsSingleWrapper> = React.memo(
  ({
    children,
    expiresAt,
    goalId,
    metricsValuesError,
    mode,
    path,
    targetValues,
    teamGoal,
    templateMetric,
    validFrom,
    setValidFrom,
    setExpiresAt,
  }) => {
    const { selectedFilters, setSelectedFilters, metricParams, setMetricParams } = useContext(
      GoalsSingleContext
    );
    const [newGoalName, setNewGoalName] = useState<string>('');
    const { curGoalData } = useSingleGoalData();

    const targetValuesSummary = useMemo(() => {
      if (curGoalData && targetValues[0]?.teamId) {
        const serializedTeamGoals = GoalsService.serializeTeamGoalTree(curGoalData.team_goal);
        const existingTeamGoalInputs: TeamGoalInput[] = serializedTeamGoals
          .filter((teamGoal) => {
            return teamGoal.target && teamGoal.team_id !== targetValues[0].teamId;
          })
          .map((teamGoal) => {
            return {
              teamId: teamGoal.team_id,
              metricParams: teamGoal.metric_params,
              target: GoalsService.deNormaliseMetricValue(teamGoal.target),
            };
          });
        const targetValuesWithoutRemove = targetValues.filter((tarVal) => !tarVal.remove);
        return [...existingTeamGoalInputs, ...targetValuesWithoutRemove];
      }
      return targetValues;
    }, [curGoalData, targetValues]);

    const [isSubmit, setIsSubmit] = useState(false);
    const history = useHistory();

    const routeToGoalsList = (): void => {
      history.pushWithBackState(`/align/${path}/${PrimaryLayoutTabs.GOALS}`);
    };

    const submitHandler = useGoalCRU(
      expiresAt,
      goalId,
      metricsValuesError,
      mode,
      targetValuesSummary,
      newGoalName,
      templateMetric,
      selectedFilters,
      metricParams,
      validFrom
    );

    const { isModalOpen, openRemoveModal, removeGoal, closeModal } = useGoalRemoveModal(
      goalId,
      teamGoal?.team.id
    );

    const totalExistingTargets = useExistingTargets(teamGoal);

    const isSubmitAllowed = useMemo(
      () =>
        !isSubmit &&
        templateMetric &&
        newGoalName &&
        canSaveGoal(targetValues, totalExistingTargets)
          ? true
          : false,
      [templateMetric, totalExistingTargets, targetValues, newGoalName, isSubmit]
    );

    const dateRangeHandler = useCallback((dateRange: IDropdownNameValue) => {
      setValidFrom(dateRange.value.split('/')[0]);
      setExpiresAt(dateRange.value.split('/')[1]);
    }, []);

    const onFiltersChangeHandler = useCallback(
      ({ filterName, update }) => {
        if (filterName && update) {
          setSelectedFilters((prevState) => ({
            ...prevState,
            [filterName]: update,
          }));
        }
      },
      [selectedFilters]
    );

    const onMetricParamsChangeHandler = useCallback(
      (newMetricParams) => {
        setMetricParams(newMetricParams);
      },
      [metricParams]
    );

    const onNewGoalNameChange = useCallback((name) => {
      setNewGoalName(name);
    }, []);

    const cancelHandler = useCallback(() => {
      routeToGoalsList();
    }, []);

    return (
      <PrimaryLayout
        tab={PrimaryLayoutTabs.GOALS}
        mode={mode}
        dateRangeInit={{
          name: DateService.getQuarterYearByExpiresAt(expiresAt),
          value: `${validFrom}/${expiresAt}`,
        }}
        isSubmitAllowed={isSubmitAllowed}
        dateRangeOnChange={dateRangeHandler}
        onFiltersChange={onFiltersChangeHandler}
        selectedMetric={templateMetric as IMetricsConfigNames}
        onMetricParamsChange={onMetricParamsChangeHandler}
        metricParams={metricParams}
        onNewGoalNameChange={onNewGoalNameChange}
        onSubmit={() => {
          submitHandler();
          setIsSubmit(true);
        }}
        onCancel={cancelHandler}
        onRemove={openRemoveModal}
      >
        {children}
        <RemoveModal isOpen={isModalOpen} onRemove={removeGoal} onCancel={closeModal} />
      </PrimaryLayout>
    );
  }
);

export { GoalsSingleWrapper };
