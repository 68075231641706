import _ from 'lodash';

import { calculateGranularity } from '@analytics-components/Prefetcher';
import HoveredBarChart from '@analytics-components/charts/HoveredBarChart';
import MultiLineChart from '@analytics-components/charts/MultiLineChart';
import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import {
  METRIC_DURATION_FORMAT,
  METRIC_PERCENTAGE_FORMAT,
  METRIC_COUNT_FORMAT,
} from '@analytics-pages/Compare/Teams/Timelines/config';
import { prepareChartBoxes } from '@analytics-pages/delivery-pipeline/insights/helper';
import {
  fetchPRsMetrics,
  fetchReleasesMetrics,
  fetchCIMetrics,
  METRICS_JIRA_ENDPOINT,
  METRICS_PRS_ENDPOINT,
  METRICS_RELEASES_ENDPOINT,
  METRICS_CHECKS_ENDPOINT,
} from '@analytics-services/api';
import { fetchJIRAMetrics } from '@analytics-services/api/jira';
import { dateTime, getBestTimeUnit } from '@common-services/dateService';
import { convertTime } from '@common-services/format';
import * as NumberService from '@common-services/numberService';

const OTHER_TEAM_ID = 'other';
const OTHER_TEAM_LABEL = 'Other';
const ALL_TEAM_ID = 'all';
const ALL_TEAM_LABEL = 'All';

const buildFetcher = (teamsApplied, teamsMapping, config) => {
  return async (api, cachedData, apiContext) => {
    const {
      account,
      interval,
      excludeInactive,
      contributors,
      repositories,
      epics,
      issueTypes,
    } = apiContext;

    let granularity = calculateGranularity(interval);
    if (granularity === 'month') {
      granularity = `aligned ${granularity}`;
    }

    const filter = !!config.buildFilter
      ? await config.buildFilter(api, cachedData, apiContext)
      : {};

    filter.epics = epics;
    filter.types = issueTypes;

    const allContribs = contributors.map((c) => c.login).filter((c) => c);
    if (config.endpoint === METRICS_JIRA_ENDPOINT) {
      const with_ =
        teamsApplied.length === 0
          ? {}
          : teamsApplied.map((t) => ({ assignees: teamsMapping[t.value].jira }));
      return {
        result: await fetchJIRAMetrics(
          api,
          account,
          [granularity],
          interval,
          config.sources,
          excludeInactive,
          filter,
          with_
        ),
        config,
      };
    } else if (config.endpoint === METRICS_PRS_ENDPOINT) {
      const withgroups =
        teamsApplied.length === 0
          ? [
              {
                author: allContribs,
              },
            ]
          : teamsApplied.map((t) => ({ author: teamsMapping[t.value].github }));
      return {
        result: await fetchPRsMetrics(
          api,
          account,
          [granularity],
          interval,
          config.sources,
          {
            repositories,
            withgroups,
            jira: { epics, issue_types: issueTypes },
          },
          null,
          excludeInactive
        ),
        config,
      };
    } else if (config.endpoint === METRICS_RELEASES_ENDPOINT) {
      const contribs =
        teamsApplied.length === 0
          ? [
              {
                pr_author: allContribs,
                releaser: allContribs,
                commit_author: allContribs,
              },
            ]
          : teamsApplied.map((t) => {
              const cs = teamsMapping[t.value].github;
              return {
                pr_author: cs,
                releaser: cs,
                commit_author: cs,
              };
            });
      return {
        result: await fetchReleasesMetrics(
          api,
          account,
          [granularity],
          interval,
          config.sources,
          repositories,
          contribs,
          [],
          [],
          { epics, issue_types: issueTypes }
        ),
        config,
      };
    } else if (config.endpoint === METRICS_CHECKS_ENDPOINT) {
      const forSet =
        teamsApplied.length === 0
          ? [
              {
                repositories,
                pushers: allContribs,
                labels_include: [],
                labels_exclude: [],
                jira: { epics, issue_types: issueTypes },
              },
            ]
          : [
              {
                repositories,
                pusher_groups: teamsApplied.map((t) => teamsMapping[t.value].github),
                labels_include: [],
                labels_exclude: [],
                jira: { epics, issue_types: issueTypes },
              },
            ];
      return {
        result: await fetchCIMetrics(
          api,
          account,
          interval,
          [granularity],
          config.sources,
          null,
          null,
          null,
          null,
          null,
          null,
          forSet
        ),
        config,
      };
    } else {
      throw new Error(`Unsupported endpoint: ${config.endpoint}`);
    }
  };
};

const extractGranularity = (data, config) => {
  if ([METRICS_JIRA_ENDPOINT, METRICS_RELEASES_ENDPOINT].includes(config.endpoint)) {
    return data[0].granularity;
  } else if ([METRICS_PRS_ENDPOINT, METRICS_CHECKS_ENDPOINT].includes(config.endpoint)) {
    return data.granularities[0];
  } else {
    return null;
  }
};

const extractRawData = (data, teamsApplied, teamsMapping, config) => {
  if ([METRICS_JIRA_ENDPOINT, METRICS_RELEASES_ENDPOINT].includes(config.endpoint)) {
    return data.map((d) => d.values);
  } else if (config.endpoint === METRICS_PRS_ENDPOINT) {
    return data.calculated.map((calc) =>
      calc.values.map((v) => {
        if (!config.aggregate) {
          return v;
        } else {
          return {
            ...v,
            values: [config.aggregate(_(config.sources).zip(v.values).fromPairs().value())],
          };
        }
      })
    );
  } else if (config.endpoint === METRICS_CHECKS_ENDPOINT) {
    const pushersSets = teamsApplied.map((t) => teamsMapping[t.value].github);
    const out = data.calculated.map((calc) => {
      const matchedPushers = _(calc.for.pushers).orderBy().value();
      const index = _(pushersSets)
        .map((pushers) => _(pushers).orderBy().isEqual(matchedPushers))
        .indexOf(true);
      return {
        index,
        data: calc.values.map((v) => {
          if (!config.aggregate) {
            return v;
          } else {
            return {
              ...v,
              values: [config.aggregate(_(config.sources).zip(v.values).fromPairs().value())],
            };
          }
        }),
      };
    });
    return _(out).orderBy('index').map('data').value();
  } else {
    return null;
  }
};

const buildPlumberLine = (teamsApplied, teamsMapping, theme) => {
  return ({ result: fetchedData, config }, cachedData, apiContext) => {
    const valueMapper = (value) => {
      if ([METRIC_DURATION_FORMAT, METRIC_COUNT_FORMAT].includes(config.metricFormat.type)) {
        return value;
      } else if (config.metricFormat.type === METRIC_PERCENTAGE_FORMAT) {
        return value * 100;
      } else {
        return null;
      }
    };

    const yAxis = (config) => {
      if (config.metricFormat.type === METRIC_COUNT_FORMAT) {
        return config.metricFormat.label;
      } else {
        return null;
      }
    };

    const yTickFormat = (config) => (v) => {
      if (config.metricFormat.type === METRIC_PERCENTAGE_FORMAT) {
        return `${v}%`;
      } else if (config.metricFormat.type === METRIC_DURATION_FORMAT) {
        return null;
      } else {
        return v;
      }
    };

    const yDynamicTick = (config, maxNumberOfTicks) => (lines) => {
      if (config.metricFormat.type === METRIC_DURATION_FORMAT) {
        const referenceValue = _(lines)
          .flatMap('data')
          .map('y')
          .filter((v) => v > 0)
          .max();
        const [normalizerValue, unit] = getBestTimeUnit(referenceValue * 1000);
        const step = normalizerValue / 1000;
        const ticks = _.range(0, referenceValue, step);
        const everyTick = Math.round(ticks.length / maxNumberOfTicks);
        const filteredTicks = ticks.filter((v, i) => i % everyTick === 0);
        return {
          ticks: {
            tickTotal: filteredTicks.length,
            tickValues: filteredTicks,
            tickFormat: (v) =>
              v === 0 ? '' : `${Math.round(convertTime(v * 1000, unit))} ${unit}`,
          },
          grid: {
            tickTotal: filteredTicks.length,
            tickValues: filteredTicks,
          },
        };
      } else {
        return {};
      }
    };

    const tooltipValueFormat = (config) => (v) => {
      if (config.metricFormat.type === METRIC_DURATION_FORMAT) {
        return dateTime.human(v * 1000);
      } else if (config.metricFormat.type === METRIC_PERCENTAGE_FORMAT) {
        return `${NumberService.round(v, 2)}%`;
      } else {
        return v;
      }
    };

    const { interval } = apiContext;
    const customGranularity = extractGranularity(fetchedData, config);
    const xTickFormat = customGranularity.includes('month') ? dateTime.month : dateTime.monthDay;
    const rawData = extractRawData(fetchedData, teamsApplied, teamsMapping, config);

    const linesData = rawData.map((rd, i) => ({
      color: teamsApplied.length === 0 ? theme.color.ui.orange['100'] : teamsApplied[i].color,
      label: teamsApplied.length === 0 ? 'All' : teamsApplied[i].label,
      data: rd.map((r) => ({
        x: r.date,
        y: r.values[0] === null ? null : valueMapper(r.values[0]),
      })),
    }));

    const isNotEmpty = !!fetchedData;
    return {
      empty: !isNotEmpty,
      chart: {
        component: MultiLineChart,
        params: {
          data: {
            interval,
            linesData,
          },
          extra: {
            axisLabels: {
              y: yAxis(config),
            },
            chartHeight: 438,
            margin: {
              left: 60,
            },
            strokeWidth: 2,
            ticks: {
              x: {
                maxNumberOfTicks: 10,
                tickFormat: xTickFormat,
              },
              y: {
                maxNumberOfTicks: 5,
                tickFormat: yTickFormat(config),
                dynamic: yDynamicTick(config, 5),
              },
            },
            tooltip: {
              valueFormat: tooltipValueFormat(config),
            },
          },
        },
      },
      kpis: [],
    };
  };
};

const buildPlumberVolume = (teamsApplied, teamsMapping, theme) => {
  return ({ result: fetchedData, config }, cachedData, apiContext) => {
    const customGranularity = extractGranularity(fetchedData, config);
    const xTickFormat = _(customGranularity).includes('month') ? dateTime.month : dateTime.monthDay;
    const yTickFormat = (v) => `${v}%`;
    const rawData = extractRawData(fetchedData, teamsApplied, teamsMapping, config);
    const chartData = rawData.map((rd, i) => ({
      name: teamsApplied.length === 0 ? 'All' : teamsApplied[i].label,
      values: rd.map((d) => ({ date: d.date, value: d.values[0] })),
    }));
    const colors =
      teamsApplied.length === 0 ? [theme.color.ui.orange['100']] : teamsApplied.map((t) => t.color);
    const isNotEmpty = !!fetchedData;

    return {
      empty: !isNotEmpty,
      chart: {
        component: HoveredBarChart,
        params: {
          data: chartData,
          extra: {
            filterableLegend: true,
            height: 400,
            color: colors,
            axisKeys: {
              x: 'date',
              y: 'value',
            },
            axisFormat: {
              tickAngle: {
                x: 0,
              },
              tickSize: {
                x: 0,
              },
              tickPadding: {
                x: 15,
              },
              tickFormat: {
                x: xTickFormat,
                y: yTickFormat,
              },
            },
            maxNumberOfTicks: 5,
          },
        },
      },
      kpis: [],
    };
  };
};

const prepareChartBoxLine = (id, teamsApplied, teamsMapping, config, theme, hasJira) => {
  const chartBoxId = `teams-compare-timelines-line-${id}-${teamsApplied
    .map((t) => t.value)
    .sort()
    .join('-')}`;
  return prepareChartBoxes([
    {
      id: chartBoxId,
      component: ChartBoxWithKPIWidget,
      params: {
        fetcher: buildFetcher(teamsApplied, teamsMapping, config),
        plumber: buildPlumberLine(teamsApplied, teamsMapping, theme),
        ...(hasJira && { prefetchedDataIds: ['jira-priorities-all'] }),
      },
    },
  ]);
};

const prepareChartBoxVolume = (id, teamsApplied, teamsMapping, config, theme, hasJira) => {
  const chartBoxId = `teams-compare-timelines-volume-${id}-${teamsApplied
    .map((t) => t.value)
    .sort()
    .join('-')}`;
  const teamsIdsApplied = teamsApplied.map((t) => t.value);
  const filteredTeamsApplied = teamsApplied.filter((t) => {
    const ancestors = t.id.split('-').slice(0, -1);
    const hasAncestorsSelected = _(teamsIdsApplied).intersection(ancestors).value().length > 0;
    return !hasAncestorsSelected;
  });

  const filteredTeamsIdsApplied = filteredTeamsApplied.map((t) => t.value);

  const getAllUsers = (service) =>
    _(teamsMapping)
      .flatMap((tm) => tm[service])
      .uniq()
      .value();

  const getFilteredUsers = (service) =>
    _(teamsMapping)
      .filter((tm, id) => filteredTeamsIdsApplied.includes(id))
      .flatMap((tm) => tm[service])
      .uniq()
      .value();

  const getDiffUsers = (service) =>
    _(getAllUsers(service)).difference(getFilteredUsers(service)).value();

  const otherGhUsers = getDiffUsers('github');
  const otherJiraUsers = getDiffUsers('jira');

  teamsMapping[OTHER_TEAM_ID] = {
    github: otherGhUsers,
    jira: otherJiraUsers,
  };
  teamsMapping[ALL_TEAM_ID] = {
    github: getAllUsers('github'),
    jira: getAllUsers('jira'),
  };

  const extraItem =
    filteredTeamsApplied.length === 0
      ? {
          id: ALL_TEAM_ID,
          label: ALL_TEAM_LABEL,
          value: ALL_TEAM_ID,
          level: 1,
          color: theme.color.ui.orange['100'],
          parent: '',
        }
      : {
          id: OTHER_TEAM_ID,
          label: OTHER_TEAM_LABEL,
          value: OTHER_TEAM_ID,
          level: 1,
          color: theme.color.neutral['40'],
          parent: '',
        };

  filteredTeamsApplied.push(extraItem);

  return prepareChartBoxes([
    {
      id: chartBoxId,
      component: ChartBoxWithKPIWidget,
      params: {
        fetcher: buildFetcher(filteredTeamsApplied, teamsMapping, config),
        plumber: buildPlumberVolume(filteredTeamsApplied, teamsMapping, theme),
        ...(hasJira && { prefetchedDataIds: ['jira-priorities-all'] }),
      },
    },
  ]);
};

export { prepareChartBoxLine, prepareChartBoxVolume };
