import { css } from '@emotion/react';

import { transition } from '@styles/theme/transition';

export const wrapperStyles = (isExpanded) => ({ color, radius, spacing }) => css`
  align-items: center;
  background-color: ${isExpanded ? color.neutral.white : color.neutral['10']};
  border-top: 1px solid ${color.neutral['40']};
  border-radius: 0 0 ${radius.default} ${radius.default};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: -${spacing.gap['02']};
  margin: ${spacing.gap['0']};
  padding: ${spacing.gap['02']};
  transition: ${transition.default};

  &:hover {
    background-color: ${isExpanded ? color.neutral['10'] : color.neutral['20']};

    .teams-info span {
      color: ${color.text.dark};
    }

    > svg path {
      fill: ${color.text.dark};
    }
  }

  .teams-info {
    align-items: center;
    display: flex;

    span {
      color: ${color.neutral['80']};
      margin-left: ${spacing.gap['02']};
    }
  }
`;
