import { css } from '@emotion/react';

import { hexToRGBA } from '@utils/colors';

export const wrapperStyles = ({ color, spacing }) => css`
  padding: ${spacing.gap['06']};
  background: ${color.neutral.white};
  border-bottom: 1px solid ${color.neutral['40']};
`;

export const metricStyles = ({ color, spacing, radius }) => css`
  display: flex;
  align-items: center;
  gap: ${spacing.gap['06']};
  margin-bottom: ${spacing.gap['04']};

  .icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${hexToRGBA(color.ui.orange['100'], 0.1)};
    border-radius: ${radius.default};
  }

  .title {
    flex-basis: 65%;
  }
`;

export const descriptionStyles = ({ font, color, spacing }) => css`
  font-size: ${font.size.s};
  font-weight: ${font.weight.medium};
  color: ${color.text.secondary};
  margin-bottom: ${spacing.gap['07']};
`;

export const teamStyles = ({ spacing }) => css`
  display: flex;
  align-items: center;
  gap: ${spacing.gap['03']};
`;
