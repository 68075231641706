import _ from 'lodash';
import React from 'react';

export const firstNRepos = 10;

export const InsightsError = () => (
  <div className="row mt-5 mb-5">
    <div className="col-12 mt-5 text-center">
      <span>An error occurred when loading insights charts</span>
    </div>
  </div>
);

export const prepareChartBoxes = (api, apiContext, chartBoxes) =>
  _(chartBoxes)
    .map((cb) => {
      const { fetcher, ...otherParams } = cb.params;
      const wrappedFetcher = fetcher ? withAPIFetcher(fetcher, api, apiContext) : null;
      return <cb.component id={cb.id} fetcher={wrappedFetcher} {...otherParams} />;
    })
    .value();

const withAPIFetcher = (fetcher, api, context) => async (globalDataIDs, sessionDataIDs) =>
  fetcher(api, context, globalDataIDs, sessionDataIDs);
