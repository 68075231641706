import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { Breadcrumbs as BreadcrumbsType } from '@align-components/layouts/primary/context.types';
import { BREADCRUMBS_MEMBERS } from '@e2e/core/testIds/align/appearance';

import { slashStyles, stepStyles, wrapperStyles } from './Breadcrumbs.styles';
import { testIds } from './__tests__/testIds.ids';

export interface IBreadcrumbs {
  steps: BreadcrumbsType;
}

export const Breadcrumbs: React.FC<IBreadcrumbs> = React.memo(({ steps }) => {
  const renderSteps = useCallback(() => {
    return steps.map((step, index) => {
      const isLink = Boolean(step.path);
      return (
        <React.Fragment key={uuidv4()}>
          {isLink ? (
            <Link css={stepStyles(true)} to={step.path} data-testid={testIds.step}>
              {step.label}
            </Link>
          ) : (
            <div css={stepStyles(false)} data-testid={testIds.step}>
              {step.label}
            </div>
          )}
          {index !== steps.length - 1 && (
            <span css={slashStyles} data-testid={testIds.slash}>
              /
            </span>
          )}
        </React.Fragment>
      );
    });
  }, [steps]);

  return (
    <div
      css={wrapperStyles}
      data-testid={testIds.wrapper}
      data-pw={BREADCRUMBS_MEMBERS.BREADCRUMBS}
    >
      {renderSteps()}
    </div>
  );
});
