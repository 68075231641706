import React, { useContext } from 'react';

import { PrimaryLayoutModes } from '@align-components/layouts/primary';
import { PrimaryLayoutContext } from '@align-components/layouts/primary/context';
import { useDateRangeOptions } from '@align-components/layouts/primary/dateRangeSelector/DateRangeSelector.hooks';
import { wrapperStyles } from '@align-components/layouts/primary/dateRangeSelector/DateRangeSelector.styles';
import { Dropdown, DropdownType } from '@lib/Dropdown';
import { icons } from '@lib/icon';

const DateRangeSelector: React.FC = React.memo(() => {
  const { mode, dateRangeInit, dateRangeOnChange } = useContext(PrimaryLayoutContext);
  const options = useDateRangeOptions(mode, dateRangeInit);

  return (
    <div css={wrapperStyles}>
      Timeframe
      <Dropdown
        initialValue={dateRangeInit || options[0]}
        options={options}
        width={233}
        disabled={mode === PrimaryLayoutModes.EDIT}
        type={DropdownType.NAME_VALUE}
        icon={icons.calendar}
        onChange={dateRangeOnChange}
        placeholder="Select a timeframe"
      />
    </div>
  );
});

export { DateRangeSelector };
