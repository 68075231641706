import { useMutation } from 'react-query';

import { useNavigation } from '@align-components/layouts/primary/context';
import { IUseMutationHook } from '@align-types/common';
import { useAuth0 } from '@common-context/Auth0';
import { useDemoCookie } from '@common-pages/Demo';
import { IApiBasicError } from '@common-services/api/common/types/common';
import { DashboardUpdateRequest } from '@common-services/api/private/generated-from-backend/models';
import { DashboardServices } from '@common-services/api/private/services/dashboard';
import { TeamDashboard } from '@common-services/api/private/types/dashboard';

export function useReorderChartsOnDashboard(): IUseMutationHook<
  TeamDashboard,
  IApiBasicError,
  DashboardUpdateRequest
> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();
  const { currentTeam } = useNavigation();

  const reorderCharts = async (params: DashboardUpdateRequest): Promise<TeamDashboard> => {
    const token: string = isDemo === false ? await getTokenSilently() : null;
    return DashboardServices.reorderChartsOnDashboard(
      {
        dashboardId: 0,
        teamId: currentTeam.id,
      },
      params,
      token
    );
  };

  const { mutate, isLoading, data, error, isError, isSuccess } = useMutation<
    TeamDashboard,
    IApiBasicError,
    DashboardUpdateRequest
  >(reorderCharts);

  return { mutate, isLoading, data, error, isError, isSuccess };
}
