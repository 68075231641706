import classnames from 'classnames';
import React from 'react';

import Page from '@analytics-pages/Page';
import login from '@assets/images/login.svg';
import logo from '@assets/images/logos/logo-transparent.svg';

import Progress from './Progress';

const Layout = ({ title, message, progress, simple, children }) => {
  const ContentWrapper = simple ? ContentWrapperSimple : ContentWrapperBackground;

  return (
    <Page headerDisabled footerDisabled>
      <div className="row h-100 mb-5">
        <div className="col-12 my-auto">
          <div className="mt-3 mb-5 text-center">
            <img src={logo} alt="" width="200" />
          </div>
          <div className="col-10 offset-1">
            <div className={classnames('card waiting p-6', simple ? 'text-center' : 'waiting-bg')}>
              <div className="card-body p-4">
                <ContentWrapper>
                  <WaitingTitle title={title} />
                  {message && <WaitingMessage>{message}</WaitingMessage>}
                  {children}
                </ContentWrapper>
              </div>
            </div>
            {progress && <Progress progress={progress} />}
          </div>
        </div>
      </div>
    </Page>
  );
};

export const GhAppLink = ({ url, text, ...rest }) => (
  <a href={url} target="_blank" rel="noopener noreferrer" className="cta-button" {...rest}>
    {text || url}
  </a>
);

export const WelcomeMsg = () => {
  return (
    <div>
      <ul className="m-0 p-0">
        <li className="waiting-container mb-1">
          <div className="waiting-chart">
            <span className="waiting-box" />
          </div>
        </li>
        <li className="waiting-container mb-1">
          <div className="waiting-table">
            <span className="waiting-box" />
          </div>
        </li>
        <li className="waiting-container mb-1">
          <div className="waiting-table">
            <span className="waiting-box" />
          </div>
        </li>
        <li className="waiting-container mb-1">
          <div className="waiting-table">
            <span className="waiting-box" />
          </div>
        </li>
      </ul>
    </div>
  );
};

const WaitingTitle = ({ title }) => (
  <h2 className="h1 text-dark font-weight-normal mt-5 mb-2">{title}</h2>
);

export const WaitingMessage = ({ children }) => (
  <div className="h4 text-secondary font-weight-light mb-5">{children}</div>
);

const ContentWrapperBackground = ({ children }) => {
  return (
    <div className="row">
      <div className="col pl-5">{children}</div>
    </div>
  );
};

const ContentWrapperSimple = ({ children }) => {
  return (
    <>
      <img className="mb-5" src={login} alt="" width="200" />
      <div>{children}</div>
    </>
  );
};

export default Layout;
