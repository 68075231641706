import { LooseObject, IContributor, IWorkType } from '@analytics-types/common';
import { ArrayService } from '@common-services/arrayService';
import { map } from '@common-services/vendor/lodash';

import { IRow, ITeamAllocationTableContext, ITeamAllocationTableData } from './types';

export const getMembers = (row: IRow): IContributor[] => {
  if (!row.subRows || row.subRows.length === 0) {
    return [row.original.login];
  }

  return row.subRows.flatMap((sr) => getMembers(sr));
};

export const filterRows = (context: ITeamAllocationTableContext, rows: IRow[]): IRow[] =>
  context.devLevel ? rows : rows.filter((sr) => sr.canExpand);

export const countMembers = (row: IRow): number =>
  ArrayService.getUniqueValues(getMembers(row)).length;

export const computeMinMax = (data: IRow[], keys: LooseObject) => {
  const unrollRows = (rows) =>
    rows.flatMap((r) => {
      if (r.subRows && r.subRows.length > 0) {
        return unrollRows(r.subRows);
      } else {
        return r;
      }
    });
  const minMax = (rows, key) => {
    const values: number[] = map<number>(rows, key).filter((val) => !!val);
    return {
      min: values.length ? Math.min(...values) : undefined,
      max: values.length ? Math.max(...values) : undefined,
    };
  };

  const unrolledRows = unrollRows(data);

  return {
    global: Object.fromEntries(keys.map((k) => [k, minMax(unrolledRows, k)])),
    root: Object.fromEntries(keys.map((k) => [k, minMax(data, k)])),
  };
};

export const sortByTotalValues = (data: ITeamAllocationTableData): IWorkType[] => {
  const allTypes = data.workTypes.filter((type) => type.name !== 'Other');
  const otherField = data.workTypes.find((type) => type.name === 'Other');
  return data.totalValues
    ? [...allTypes.sort((a, b) => data.totalValues[b.name] - data.totalValues[a.name]), otherField]
    : data.workTypes;
};
