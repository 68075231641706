import React, { useState } from 'react';

import { GoalMetricParams } from '@common-services/api/private/generated-from-backend/models';
import { ISelectedFilters } from '@lib/filter-panel';

import { IGoalsSingleContext } from './goalsSingleContext.types';

export const GoalsSingleContext = React.createContext<IGoalsSingleContext>(null);

export const GoalsSingleProvider: React.FC = ({ children }) => {
  const [selectedFilters, setSelectedFilters] = useState<ISelectedFilters>(null);
  const [metricParams, setMetricParams] = useState<GoalMetricParams>(null);
  return (
    <GoalsSingleContext.Provider
      value={{ selectedFilters, setSelectedFilters, metricParams, setMetricParams }}
    >
      {children}
    </GoalsSingleContext.Provider>
  );
};
