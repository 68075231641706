import { useTheme } from '@emotion/react';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useTable, useSortBy, useExpanded } from 'react-table';

import { humanLaboralRate } from '@common-services/dateService';
import { dateTime } from '@common-services/dateService';
import { number } from '@common-services/format';
import { Table } from '@lib/Table';
import Chevron from '@ui/Chevron';
import { stringToColor } from '@utils/colors';

const countMembers = (row) => _(getMembers(row)).uniq().value().length;
const getMembers = (row) => {
  if (!row.subRows || row.subRows.length === 0) {
    return row.original.login;
  }

  return _(row.subRows)
    .flatMap((sr) => getMembers(sr))
    .value();
};

const computeMinMax = (data, keys) => {
  const unrollRows = (rows) =>
    _(rows)
      .flatMap((r) => {
        if (r.subRows && r.subRows.length > 0) {
          return unrollRows(r.subRows);
        } else {
          return r;
        }
      })
      .value();

  const minMax = (rows, key) => ({
    min: _(rows).map(key).min(),
    max: _(rows).map(key).max(),
  });

  const unrolledRows = unrollRows(data);
  return {
    global: _(keys)
      .map((k) => [k, minMax(unrolledRows, k)])
      .fromPairs()
      .value(),
    root: _(keys)
      .map((k) => [k, minMax(data, k)])
      .fromPairs()
      .value(),
  };
};

const CellMetric = ({ value, cell, row, state, lowBetter = false, formatter = null }) => {
  const theme = useTheme();
  const key = cell.column.id;
  const isRoot = row.depth === 0;
  const isTotalRow = row.values.name === 'Total';
  const { min, max } = state.colsMinMax.root[key];
  const [best, worst] = !lowBetter
    ? [theme.color.ui.blue[100], theme.color.ui.lightorange[100]]
    : [theme.color.ui.lightorange[100], theme.color.ui.blue[100]];
  const css = {};
  if (isRoot && !isTotalRow) {
    if (value >= max) {
      css.color = best;
    } else if (value <= min) {
      css.color = worst;
    }
  }
  return <span css={css}>{formatter ? formatter(value) : value}</span>;
};

const CellTeam = (context) => ({ row }) => {
  const Wrapper = ({ children, ...props }) => {
    const theme = useTheme();

    return (
      <div
        {...props}
        css={({ spacing }) => ({
          'paddingLeft': `${row.depth * 3}rem`,
          'whiteSpace': 'nowrap',
          '&': {
            svg: {
              marginLeft: spacing.gap['0'],
            },
            div: {
              display: 'inline-block',
              verticalAlign: 'middle',
              width: '25px',
              height: '25px',
              borderRadius: '100%',
              border: `2px solid ${theme.color.neutral.white}`,
              boxShadow: `0 0 2px rgba(0, 0, 0, 0.1)`,
              color: theme.color.neutral.white,
              textAlign: 'center',
              lineHeight: '20px',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundSize: '21px',
              fontSize: theme.font.size.xxs,
              fontWeight: theme.font.weight.bold,
            },
            p: {
              color: theme.color.neutral[100],
              display: 'inline-block',
              marginLeft: spacing.gap['04'],
              fontSize: '1.1rem',
              lineHeight: '12px',
              span: {
                color: theme.color.neutral[80],
                marginLeft: spacing.gap['04'],
                fontSize: '1.1rem',
                lineHeight: '12px',
              },
            },
          },
        })}
      >
        {children}
      </div>
    );
  };

  if (row.values.name === 'Total') {
    return (
      <Wrapper>
        <p>{row.values.name}</p>
      </Wrapper>
    );
  }

  const hasChildTeam = (row) => _(row.subRows).some((sr) => sr.subRows && sr.subRows.length > 0);
  const { onClick, ...wrapperProps } = row.getToggleRowExpandedProps();
  const hasChevron = hasChildTeam(row) || context.devLevel;
  const membersCount = countMembers(row);
  return (
    <Wrapper onClick={hasChevron ? onClick : null} {...wrapperProps}>
      {row.canExpand ? (
        <>
          {hasChevron && <Chevron isExpanded={row.isExpanded} />}
          <div
            css={({ spacing }) => ({
              background: stringToColor(row.values.name),
              marginLeft: hasChevron ? spacing.gap['05'] : spacing.gap['08'],
            })}
          >
            {row.values.name[0]}
          </div>
          <p>
            {row.values.name}
            <span>
              {membersCount} member{membersCount === 1 ? '' : 's'}
            </span>
          </p>
        </>
      ) : (
        <>
          <div
            css={({ spacing }) => ({
              background: `url(${row.original.avatar})`,
              marginLeft: spacing.gap['08'],
            })}
          />
          <p>{row.values.name}</p>
        </>
      )}
    </Wrapper>
  );
};

const getTableColsDefs = (context) => [
  {
    Header: 'Team',
    accessor: 'name',
    Cell: CellTeam(context),
  },
  {
    Header: 'PRs Released',
    accessor: 'prsReleased',
    Cell: CellMetric,
  },
  {
    Header: 'Release Frequency',
    accessor: 'releaseFrequency',
    Cell: ({ value, cell, row, state }) => (
      <CellMetric
        value={value}
        cell={cell}
        row={row}
        state={state}
        formatter={(value) => {
          if (!value || !context.interval.from || !context.interval.to) {
            return '-';
          }
          const { from, to } = context.interval;
          const [rate, unit] = humanLaboralRate(from, to, value);
          return `${rate} / ${unit}`;
        }}
      />
    ),
  },
  {
    Header: 'PR Size (lines)',
    accessor: 'prSize',
    Cell: ({ value, cell, row, state }) => (
      <CellMetric
        value={value}
        cell={cell}
        row={row}
        state={state}
        lowBetter={true}
        formatter={(value) => {
          if (!value) {
            return '-';
          }
          return value;
        }}
      />
    ),
  },
  {
    Header: 'PR Cycle Time',
    accessor: 'prCycleTime',
    Cell: ({ value, cell, row, state }) => (
      <CellMetric
        value={value}
        cell={cell}
        row={row}
        state={state}
        lowBetter={true}
        formatter={(value) => (!value ? '-' : dateTime.human(value * 1000))}
      />
    ),
  },
  {
    Header: 'Review Time',
    accessor: 'reviewTime',
    Cell: ({ value, cell, row, state }) => (
      <CellMetric
        value={value}
        cell={cell}
        row={row}
        state={state}
        lowBetter={true}
        formatter={(value) => (!value ? '-' : dateTime.human(value * 1000))}
      />
    ),
  },
  {
    Header: 'PRs Reviewed',
    accessor: 'prsReviewed',
    Cell: ({ value, cell, row, state }) => (
      <CellMetric
        value={value}
        cell={cell}
        row={row}
        state={state}
        formatter={(value) => (!value ? '-' : number.percentage(value))}
      />
    ),
  },
];

const TeamsComparisonTable = ({ data, context }) => {
  const theme = useTheme();
  const { teams: teamsData, total: totalData } = data;

  totalData.name = 'Total';
  const userColumns = useMemo(() => getTableColsDefs(context), [context]);
  const colsMinMax = computeMinMax(teamsData, _(userColumns).slice(1).map('accessor').value());
  const memoizedData = useMemo(() => [totalData, ...teamsData], [totalData, teamsData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: userColumns,
      data: memoizedData,
      initialState: {
        colsMinMax,
      },
    },
    useSortBy,
    useExpanded
  );
  _(rows).forEach(prepareRow);

  const totalIndex = useMemo(() => _(rows).findIndex((r) => r.values.name === 'Total'), [rows]);
  const totalRow = useMemo(() => _(rows).pullAt(totalIndex).value()[0], [rows, totalIndex]);
  const filteredRows = useMemo(
    () =>
      context.devLevel
        ? rows
        : _(rows)
            .filter((sr) => sr.canExpand)
            .value(),
    [context, rows]
  );
  const sortedRows = useMemo(() => [totalRow, ...filteredRows], [filteredRows, totalRow]);

  return (
    <Table
      rows={sortedRows}
      headerGroups={headerGroups}
      tableProps={getTableProps()}
      tableBodyProps={getTableBodyProps()}
      extraCSS={{
        body: {
          '& tr:first-of-type td': {
            borderTop: `1px solid ${theme.color.neutral[60]}`,
            borderBottom: `1px solid ${theme.color.neutral[60]}`,
          },
          '& tr:first-of-type td:first-of-type': {
            borderLeft: `1px solid ${theme.color.neutral[60]}`,
          },
          '& tr:first-of-type td:last-child': {
            borderRight: `1px solid ${theme.color.neutral[60]}`,
          },
        },
      }}
    />
  );
};

export default TeamsComparisonTable;
