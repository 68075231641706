import { ITeam } from '@common-types/common';

// exclude Root team from some places like Top Teams filter and Teams selector in Teams Compare page
export const getTeamsWithoutRoot = (teams: ITeam[]): ITeam[] => {
  const rootTeam = teams.find((t) => !t.parent);
  // make a deep copy of an array
  const teamsCopy = JSON.parse(JSON.stringify(teams));
  const teamsWithoutRoot = teamsCopy.filter((t) => t.parent);
  if (rootTeam) {
    teamsWithoutRoot.forEach((t) => {
      if (t.parent === rootTeam.id) {
        t.parent = null;
      }
    });
  }
  return teamsWithoutRoot;
};
