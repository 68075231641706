import { css } from '@emotion/react';

export const settingsContainerStyles = ({ spacing }) => css`
  > div {
    padding-top: 0;
  }
`;

export const settingsGroupStyles = ({ color, spacing, radius }) => css`
  background-color: ${color.neutral.white};
  background-clip: border-box;
  border: 1px solid ${color.neutral['40']};
  border-radius: ${radius.default};
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing.gap['06']};
  margin-top: ${spacing.gap['06']};
  min-width: 0;
  position: relative;
  word-wrap: break-word;
`;

export const settingsGroupHeaderStyles = ({ color, spacing, radius, font }) => css`
  align-items: center;
  background-color: ${color.neutral.white};
  border-bottom: 1px solid ${color.neutral['40']};
  border-radius: ${radius.default} ${radius.default} 0 0;
  display: flex;
  max-height: 60px;
  padding: ${spacing.gap['06']};

  > h2 {
    color: ${color.text.dark};
    font-size: ${font.size.s};
  }
`;

export const settingsExtraStyles = () => css`
  margin-left: auto;
  position: relative;
`;

export const settingsGroupBodyStyles = ({ spacing }) => css`
  flex: 1 1 auto;
  min-height: 1 px;
  padding: ${spacing.gap['06']};
`;
