import { css } from '@emotion/react';

export const wrapperStyles = css`
  height: 100%;
  width: 100vw;
`;

export const edgeStyles = ({ color }) => ({
  stroke: color.neutral['40'],
  strokeWidth: '4px',
});
