import { BasicApiClass } from '@common-services/api/common/BasicApiClass';
import { REST_TYPES } from '@common-services/api/common/types/apiClient';
import { ApiMode } from '@common-services/api/common/types/common';
import { ISentryMethodParams } from '@common-services/api/common/types/sentry';
import { PUBLIC_API_ENDPOINTS_CONSTANTS } from '@common-services/api/public/constants';
import {
  AcceptedInvitation,
  InvitationCheckResult,
  InvitationLink,
  InvitedUser,
} from '@common-services/api/public/generated-from-backend/models';

export class RegistrationApi extends BasicApiClass {
  constructor() {
    super(ApiMode.PUBLIC);
  }
  public checkInvitation(
    params: InvitationLink,
    token: string,
    sentry?: ISentryMethodParams
  ): Promise<InvitationCheckResult> {
    this.assignToken(token);
    const callApi = this.callApi<InvitationCheckResult>(
      PUBLIC_API_ENDPOINTS_CONSTANTS.INVITE_CHECK,
      REST_TYPES.POST,
      params
    );
    return sentry ? this.callSentry<InvitationCheckResult>(callApi, sentry) : callApi;
  }

  public acceptInvitation(
    params: AcceptedInvitation,
    token: string,
    sentry?: ISentryMethodParams
  ): Promise<InvitedUser> {
    this.assignToken(token);
    const callApi = this.callApi<InvitedUser>(
      PUBLIC_API_ENDPOINTS_CONSTANTS.INVITE_ACCEPT,
      REST_TYPES.PUT,
      params
    );
    return sentry ? this.callSentry<InvitedUser>(callApi, sentry) : callApi;
  }
}
