import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

import { PrimaryLayoutModes } from '@align-components/layouts/primary';
import { GoalTemplateComponent } from '@align-pages/goals/single/components/goal-template/goalTemplate';
import { ITemplateMetric } from '@align-services/api/types/goalsTypes';
import { GoalTemplate } from '@common-services/api/public/generated-from-backend/models';
import { Chevron } from '@lib/Chevron';

import { getHeaderStyle, getStyle, getTemplatesWrapperStyle } from './styles';

interface Props {
  goals: GoalTemplate[];
  templateNameSelected?: string;
  templateMetricSelected?: ITemplateMetric;
  mode?: PrimaryLayoutModes;
  isExpanded: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onGoalSelected?: (selectedGoal: GoalTemplate) => void;
}

const GoalsCatalogUI: React.FC<Props> = React.memo(
  ({
    goals,
    templateNameSelected,
    templateMetricSelected,
    mode,
    isExpanded,
    onClick,
    onGoalSelected,
  }) => {
    return (
      <div css={getStyle}>
        <div onClick={onClick} css={getHeaderStyle}>
          <div>
            <Chevron isExpanded={isExpanded} size={10} />
            <span>Goals</span>
          </div>
        </div>
        <AnimatePresence>
          {isExpanded && (
            <motion.div
              initial={{
                opacity: 0,
                height: '0%',
              }}
              animate={{
                opacity: 1,
                height: '100%',
              }}
              exit={{
                opacity: 0,
                height: '0%',
              }}
              css={getTemplatesWrapperStyle}
            >
              {goals.map((goal) => (
                <GoalTemplateComponent
                  key={goal.id}
                  isSelected={
                    goal.metric === templateMetricSelected && goal.name === templateNameSelected
                  }
                  mode={mode}
                  {...goal}
                  onClick={onGoalSelected ? () => onGoalSelected(goal) : null}
                />
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  }
);

export { GoalsCatalogUI };
