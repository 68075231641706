import _ from 'lodash';

import * as MultiSelectReducerService from '@analytics-components/MultiSelect/services/reducer';

const multiSelectReducer = (state, action) => {
  switch (action.type) {
    case 'load':
      return reducerLoadFn(state, action);
    case 'select':
    case 'cancel':
      return reducerSelectCancelFn(state, action);
    case 'apply':
      return reducerApplyFn(state, action);
    case 'options-change':
      return reducerOptionsChange(state, action);
    case 'options-change-ts':
      return reducerOptionsChangeTs(state, action);
    case 'select-ids-ts':
      return reducerSelectIdsTs(state, action);
    default:
      throw new Error();
  }
};

const reducerLoadFn = (state, action) => {
  if (!state.loading) {
    throw new Error('multi-select already loaded');
  }

  const options = action.payload.options;
  const getOptionValue = state.getOptionValue;
  const initialValues = action.payload.initialValues;

  const opts = formatOptions(options);
  const unrolledOptions = unrollOptions(opts, getOptionValue);
  const initialOptions = _(unrolledOptions)
    .filter((opt) =>
      _(initialValues)
        .map((v) => getOptionValue(v))
        .includes(getOptionValue(opt))
    )
    .value();
  const allSelected = unrolledOptions.length === initialOptions.length && initialOptions.length > 0;
  const allByGroupSelected = getAllByGroupSelected(opts, initialOptions, getOptionValue);
  return {
    ...state,
    loading: false,
    options: opts,
    unrolledOptions: unrolledOptions,
    selected: initialOptions,
    applied: initialOptions,
    allSelected,
    allByGroupSelected,
    getOptionValue,
  };
};

const reducerSelectCancelFn = (state, action) => {
  if (state.loading) {
    return { ...state };
  }

  const selected = action.type === 'select' ? action.payload : state.applied;
  const allSelected = state.unrolledOptions.length === selected.length && selected.length > 0;
  const allByGroupSelected = getAllByGroupSelected(state.options, selected, state.getOptionValue);
  return {
    ...state,
    selected,
    allSelected,
    allByGroupSelected,
  };
};

const reducerSelectIdsTs = (state, action) => {
  if (state.loading) {
    return { ...state };
  }
  return {
    ...state,
    selectedGroupNames: action.payload,
  };
};

const reducerApplyFn = (state, action) => {
  if (state.loading) {
    throw new Error('multi-select not loaded');
  }

  return {
    ...state,
    applied: state.selected,
  };
};

const reducerOptionsChange = (state, action) => {
  if (state.loading) {
    throw new Error('multi-select not loaded');
  }

  const opts = formatOptions(action.payload.options);
  const unrolledOptions = unrollOptions(opts, state.getOptionValue);

  let selected;
  if (state.allSelected) {
    selected = unrolledOptions;
  } else {
    // Concat selected and those options whose group is selected as a whole
    const selectedFromGroups = _(opts)
      .filter((o) => state.allByGroupSelected[o.label])
      .flatMap('options')
      .value();
    selected = _(state.selected)
      .filter((s) =>
        _(opts)
          .flatMap('options')
          .find((o) => (!s.login && o.name === s.name) || o.login === s.login)
      )
      .filter(
        (s) =>
          !selectedFromGroups.find((o) => (!s.login && o.name === s.name) || o.login === s.login)
      )
      .concat(selectedFromGroups)
      .uniqBy(state.getOptionValue)
      .value();
  }
  const applied = selected;
  const allSelected = state.unrolledOptions.length === selected.length && selected.length > 0;
  const allByGroupSelected = getAllByGroupSelected(opts, selected, state.getOptionValue);
  action.payload.appliedCb(applied);
  return {
    ...state,
    options: opts,
    unrolledOptions: unrolledOptions,
    selected,
    applied,
    allSelected,
    allByGroupSelected,
  };
};

const reducerOptionsChangeTs = (state, action) => {
  if (state.loading) {
    throw new Error('multi-select not loaded');
  }
  const selectedGroupNames = state.selectedGroupNames || action.payload.lastGroups;
  const selected = selectedGroupNames
    ? MultiSelectReducerService.getUsersWithGroups(state.options, selectedGroupNames)
    : state.selected;
  action.payload.appliedCb(selected);
  return {
    ...state,
    selected,
    applied: selected,
  };
};

const multiSelectStateInit = ({ getOptionValue, label }) => {
  return {
    label,
    loading: true,
    getOptionValue,
  };
};

/**
 * Ensure the options to have the same formatby expanding also flat options.
 * The target format is the following:
 *
 *   {
 *     "label": "group label",
 *     "options": [{
 *       "label": "option label",
 *       "group": "group label",
 *       "whatever key 1": "whatever value 1",
 *       "whatever key 2": "whatever value 2",
 *       "whatever key 3": "whatever value 3"
 *     }]
 *   }
 */
const formatOptions = (options) =>
  _(options)
    .map((opt) =>
      !opt.options
        ? {
            label: opt.label,
            options: [{ ...opt }],
          }
        : opt
    )
    .map((opt) => ({
      label: opt.label,
      options: _(opt.options)
        .map((o) => ({ group: opt.label, ...o }))
        .value(),
    }))
    .value();

const unrollOptions = (opts, getOptionValue, nonUnique) => {
  const partial = _(opts).flatMap((v) =>
    v.options ? unrollOptions(v.options, getOptionValue) : v
  );
  if (nonUnique) {
    return partial.value();
  } else {
    return partial.uniqBy(getOptionValue).value();
  }
};

const getAllByGroupSelected = (opts, selected, getOptionValue) =>
  _(opts)
    .map((opt) => {
      const groupOptionsValues = _(opt.options).map(getOptionValue).value();
      const selectedOptionsValues = _(selected).map(getOptionValue).value();
      const allGroupSelected = _(groupOptionsValues)
        .map((v) => _(selectedOptionsValues).includes(v))
        .every();
      return [opt.label, allGroupSelected];
    })
    .fromPairs()
    .value();

export { multiSelectReducer, multiSelectStateInit };
