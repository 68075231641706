import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PrimaryLayoutModes } from '@align-components/layouts/primary';
import { metricsConfig } from '@align-constants';
import { IUseSingleGoalData } from '@align-pages/goals/single/components/goal-view-component/goalViewComponent.types';
import {
  IGoalSingleParams,
  useCurGoalData,
  useTeamGoal,
  useTeamsData,
} from '@align-pages/goals/single/components/goals-single-component';
import { ITemplateMetric } from '@align-services/api/types/goalsTypes';
import { DateService } from '@align-services/dateService';

export const useSingleGoalData = (): IUseSingleGoalData => {
  const [validFrom, setValidFrom] = useState(DateService.getUnitsAheadStart(0, 'quarter'));
  const [expiresAt, setExpiresAt] = useState(DateService.getUnitsAheadEnd(0, 'quarter'));
  const [templateMetric, setTemplateMetric] = useState<ITemplateMetric>(null);

  const { path, goalId } = useParams<IGoalSingleParams>();
  const teamsData = useTeamsData(path);
  const [curGoalData, curGoalDataLoading] = useCurGoalData(goalId, teamsData);
  const [metricsValues, metricsValuesLoading] = useTeamGoal(
    curGoalData,
    [
      metricsConfig[templateMetric]?.mainKPI,
      metricsConfig[templateMetric]?.completedKPI || metricsConfig[templateMetric]?.allKPI,
    ],
    PrimaryLayoutModes.VIEW,
    {
      dateFrom: validFrom,
      dateTo: expiresAt,
      displayName: '',
    },
    [],
    teamsData,
    {
      jira_issue_types: curGoalData?.jira_issue_types,
      jira_priorities: curGoalData?.jira_priorities,
      jira_projects: curGoalData?.jira_projects,
      repositories: curGoalData?.repositories,
    },
    null
  );

  useEffect(() => {
    if (curGoalData) {
      setValidFrom(curGoalData.valid_from);
      setExpiresAt(curGoalData.expires_at);
      setTemplateMetric(curGoalData.metric);
    }
  }, [curGoalData]);

  const dateRangeInit = useMemo(
    () => ({
      name: DateService.getQuarterYearByExpiresAt(expiresAt),
      value: `${validFrom}/${expiresAt}`,
    }),
    [expiresAt, validFrom]
  );

  const isLoading = useMemo(() => curGoalDataLoading || metricsValuesLoading, [
    curGoalDataLoading,
    metricsValuesLoading,
  ]);

  return {
    curGoalData,
    templateMetric,
    dateRangeInit,
    metricsValues,
    isLoading,
  };
};
