import React from 'react';

import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import Panel from '@analytics-components/insights/Panel';
import { prepareChartBoxes } from '@analytics-pages/delivery-pipeline/insights/helper';

import ticketDistribution from './ticket-distribution';

const meta = {
  title: 'Total',
};

const chartBoxes = [
  {
    id: 'themes-ticket-distribution',
    component: ChartBoxWithKPIWidget,
    params: ticketDistribution,
  },
];

const TicketDistributionPanel = () => {
  const preparedChartBoxes = prepareChartBoxes(chartBoxes);
  return <Panel meta={meta} chartBoxes={preparedChartBoxes} />;
};

export default TicketDistributionPanel;
