import { useTheme } from '@emotion/react';
import moment from 'moment';
import React, { useContext, useEffect, useReducer, useState } from 'react';

import { getUserDetails } from '@analytics-services/api';
import { setUser as setSentryUser } from '@analytics-services/sentry';
import { useAuth0 } from '@common-context/Auth0';
import { useDemoCookie } from '@common-pages/Demo';
import { useBecomeUser } from '@common-services/api/private/hooks/useBecomeUser';
import { analytics } from '@utils/analytics';

const UserContext = React.createContext(null);

export const useUserContext = () => useContext(UserContext);

const initialState = { loading: true };
const reducer = (state, action) => {
  switch (action.type) {
    case 'set':
      const state = { loading: false };
      const {
        user,
        account,
        features,
        isReady,
        isGod,
        isAdmin,
        godMode,
        profileLink,
        hasCI,
        hasJIRA,
        hasDeployments,
        isExpired,
        tokenClaims,
      } = action.payload;
      return {
        ...state,
        user,
        account,
        features,
        isReady,
        isGod,
        isAdmin,
        godMode,
        profileLink,
        hasCI,
        hasJIRA,
        hasDeployments,
        isExpired,
        tokenClaims,
      };
    case 'fail':
      return { loading: false };
    default:
      throw new Error();
  }
};

const User = ({ children }) => {
  const theme = useTheme();
  const {
    logout,
    user: authUser,
    loading,
    isAuthenticated,
    getTokenSilently,
    getIdTokenClaims,
  } = useAuth0();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isGodMode, setIsGodMode] = useState(null);
  const { isDemo } = useDemoCookie();
  const { mutate: becomeUser, isSuccess: becomeUserIsSuccess } = useBecomeUser();

  useEffect(() => {
    if (isGodMode === false) becomeUser({ userId: '' });
  }, [isGodMode]);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (!isAuthenticated && !isDemo) {
      dispatch({ type: 'fail' });
      return;
    }

    (async () => {
      try {
        const token = isDemo ? null : await getTokenSilently();
        const tokenClaims = isDemo ? {} : await getIdTokenClaims();
        const { user, account, features, isReady } = await getUserDetails(token);
        const godMode = user ? authUser && authUser.sub !== user.id : false;
        setIsGodMode(godMode);
        const isGod = isDemo ? false : godMode ? true : becomeUserIsSuccess;
        const hasCI = user?.accounts[user.defaultAccount.id]
          ? user.accounts[user.defaultAccount.id].has_ci
          : false;
        const hasJIRA = user?.accounts[user.defaultAccount.id]
          ? user.accounts[user.defaultAccount.id].has_jira
          : false;
        const hasDeployments = user?.accounts[user.defaultAccount.id]
          ? user.accounts[user.defaultAccount.id].has_deployments
          : false;
        const isExpired = user?.accounts[user.defaultAccount.id]
          ? user.accounts[user.defaultAccount.id].expired
          : false;
        const isAdmin = user?.accounts[user.defaultAccount.id]
          ? user.accounts[user.defaultAccount.id].is_admin
          : false;
        const { repos } = user.defaultReposet;
        // the assumption here is that a user will have all his/her repos in one Github instance,
        // so taking any repo from the Reposet to define a path to Github should work
        const profileLink = !!repos.length
          ? `https://${repos[0].split('/')[0]}/${user.login}`
          : null;

        dispatch({
          type: 'set',
          payload: {
            user,
            account,
            features,
            isReady,
            isGod,
            isAdmin,
            godMode,
            profileLink,
            hasCI,
            hasJIRA,
            hasDeployments,
            isExpired,
            tokenClaims,
          },
        });
      } catch (err) {
        console.error('Could not get user with repos', err);
        dispatch({ type: 'fail' });
      }
    })();
  }, [loading, isAuthenticated, getTokenSilently, authUser, isDemo, becomeUserIsSuccess]);

  if (state.loading || (isDemo && !state.account)) {
    return null;
  }

  if (!state.godMode && !isDemo) {
    analytics.identify(state.user);
    if (state.user) {
      const tokenValidityMins = moment(state.tokenClaims.exp * 1000).diff(moment(), 'minutes');
      if (tokenValidityMins > 30) {
        window.Vitally.nps('survey', {
          productName: 'Athenian',
          autoLoadSegment: true,
          primaryColor: theme.color.ui.orange['100'],
        });
      }
    }
  }

  if (state.user) {
    setSentryUser(state.user, state.godMode);
  }

  return (
    <UserContext.Provider
      value={{
        user: state.user,
        account: state.account,
        features: state.features,
        isAuthenticated,
        isDemo,
        logout,
        isReady: state.isReady,
        isGod: state.isGod,
        isAdmin: state.isAdmin,
        godMode: state.godMode,
        profileLink: state.profileLink,
        hasCI: state.hasCI,
        hasJIRA: state.hasJIRA,
        hasDeployments: state.hasDeployments,
        isExpired: state.isExpired,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default User;
