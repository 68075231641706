import { useCallback, useMemo, useState } from 'react';

import { throttle } from '@common-services/vendor/lodash';

import { IDeploymentsRow } from '../deployments.types';
import { searchTerm } from '../services/search';

interface IDeploymentsUseSearch {
  searchedString: string;
  handleSearch: (string: string) => void;
}

export const useSearch = (
  allDeployments: IDeploymentsRow[],
  setFilteredDeployments: (deployments: IDeploymentsRow[]) => void
): IDeploymentsUseSearch => {
  const [searchedString, setSearchedString] = useState('');

  const deploymentsAfterSearch = useMemo(
    () => (deployments: IDeploymentsRow[], string: string) => searchTerm(deployments, string),
    []
  );

  const throttledSearch = throttle((string: string) => {
    const filteredData = deploymentsAfterSearch(allDeployments, string);
    setFilteredDeployments(filteredData);
  }, 300);

  const handleSearch = useCallback(
    (string: string) => {
      setSearchedString(string);
      if (string?.length > 1) {
        throttledSearch(string);
      } else {
        // in case searched string is empty or too short initialize it back to full data
        // using timeout to prevent clash in setting React state
        const timeoutId = setTimeout(
          () => setFilteredDeployments(allDeployments),
          0,
          () => clearTimeout(timeoutId)
        );
      }
    },
    [allDeployments]
  );

  return { searchedString, handleSearch };
};
