import React from 'react';

import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import Panel from '@analytics-components/insights/Panel';
import { prepareChartBoxes } from '@analytics-pages/delivery-pipeline/insights/helper';

import timelinePerRepository from './timelinePerRepository';

const meta = {
  title: 'Success Ratio - Timeline per Repository',
  description:
    'Analyze the success ratio timelines of each repository to understand which ones are getting better through time and which are not.',
};

const chartBoxes = [
  {
    id: 'success-ratio-timeline-per-repository',
    component: ChartBoxWithKPIWidget,
    params: timelinePerRepository,
  },
];

const TimelinePerRepoPanel = () => {
  const preparedChartBoxes = prepareChartBoxes(chartBoxes);
  return <Panel meta={meta} chartBoxes={preparedChartBoxes} />;
};

export default TimelinePerRepoPanel;
