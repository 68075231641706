import _ from 'lodash';
import React from 'react';
import { Hint } from 'react-vis';

import { AltTitle } from '@analytics-components/Typography';
import BubbleChart from '@analytics-components/charts/BubbleChart';
import { TooltipContainer, Group } from '@analytics-components/charts/Tooltip';
import { theme } from '@styles/theme';

const isTicketInProgress = (ticket, interval) =>
  !ticket.resolved && ticket.work_began >= interval.from;

const isTicketResolved = (ticket, interval) => !!ticket.resolved;

const isTicketResolvedInRange = (ticket, interval) =>
  !!ticket.resolved &&
  ticket.resolved >= interval.from.toDate() &&
  ticket.resolved < interval.to.toDate();

const conf = {
  plumber: (fetchedData, cachedData, apiContext) => {
    const { interval } = apiContext;
    const filteredJIRAStuff = cachedData['jira-epics'];
    const epics = _(filteredJIRAStuff.epics)
      .map((e) => ({
        label: e.id,
        title: e.title,
        link: `${apiContext.user.account.jira.url}/browse/${e.id}`,

        total: e.children.length,
        inProgress: _(e.children)
          .filter((t) => isTicketInProgress(t, interval))
          .value().length,
        resolved: _(e.children)
          .filter((t) => isTicketResolved(t, interval))
          .value().length,
        resolvedInRange: _(e.children)
          .filter((t) => isTicketResolvedInRange(t, interval))
          .value().length,
      }))
      .map((e) => ({
        ...e,
        completionRate: (e.resolved / e.total) * 100,
        size: e.resolvedInRange,
      }))
      .filter((e) => e.size > 0)
      .value();

    const chartData = epics;
    return {
      empty: chartData.length === 0,
      chart: {
        component: BubbleChart,
        params: {
          data: chartData,
          extra: {
            height: 350,
            margin: {
              top: 20,
            },
            axisKeys: {
              x: 'completionRate',
              y: 'resolvedInRange',
            },
            axisLabels: {
              x: 'Epic Progress',
              y: 'Tickets Resolved',
            },
            tickFormat: {
              x: (v) => `${v}%`,
            },
            color: theme.color.ui.purple[100],
            tooltip: {
              template: Tooltip,
              persistent: true,
            },
          },
        },
      },
      kpis: [],
    };
  },
  prefetchedDataIds: ['jira-epics'],
};

const Tooltip = ({ value, ...props }) => {
  const resolvedPerc = parseInt(value.completionRate);
  const inProgressPerc = parseInt((value.inProgress / value.size) * 100);
  return (
    <Hint {...props} value={value}>
      <TooltipContainer>
        <Group>
          <div className="w-100 d-inline-flex align-items-start mb-1">
            <img
              alt=""
              src="https://athenianco.atlassian.net/images/icons/issuetypes/epic.svg"
              width="12px"
              className="mr-1"
              css={({ spacing }) => ({ marginTop: `calc(${spacing.gap['1px']} * 2)` })}
            />
            <p className="text-dark text-left text-xs mb-1">
              <a href={value.link} className="d-inline mt-0" rel="noreferrer" target="_blank">
                <span
                  className="text-secondary d-inline"
                  css={{ ':hover': { textDecoration: 'underline' } }}
                >
                  #{value.label}
                </span>
              </a>
              <span className="d-inline"> </span>
              <a
                href={value.link}
                className="text-dark d-inline link mt-0"
                rel="noreferrer"
                target="_blank"
              >
                <span className="text-dark">{value.title}</span>
              </a>
            </p>
          </div>
          <div className="progress" style={{ height: '4px' }}>
            <div
              className="progress-bar bg-success"
              role="progressbar"
              style={{ width: `${resolvedPerc}%` }}
              aria-valuenow={resolvedPerc}
              aria-valuemin="0"
              aria-valuemax="100"
            />
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${inProgressPerc}%`, backgroundColor: '#147eec' }}
              aria-valuenow={inProgressPerc}
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
          <div className="row">
            <div className="col pl-0">
              <span className="font-weight-bold d-inline-block align-middle text-dark text-m mt-1">
                {`${value.resolvedInRange} tickets released`}
              </span>
            </div>
            <div className="col text-right pt-1">
              <AltTitle noMargin content={`${resolvedPerc}% complete`} />
              <AltTitle content={`(${value.resolved}/${value.total})`} />
            </div>
          </div>
        </Group>
      </TooltipContainer>
    </Hint>
  );
};

export default conf;
