import { css } from '@emotion/react';

export const wrapperStyles = ({ color, spacing }) => css`
  padding-left: ${spacing.layout['05']};
  padding-right: ${spacing.layout['05']};
  padding-top: ${spacing.gap['05']};
  padding-bottom: ${spacing.gap['06']};
  background: ${color.neutral.white};
  border-bottom: 1px solid ${color.neutral['40']};

  .name-input {
    margin-top: -${spacing.gap['03']};
  }
`;

export const breadcrumbsWrapperStyles = ({ spacing }) => css`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.gap['05']};
`;

export const createEditWrapperStyles = ({ spacing }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${spacing.gap['04']};
`;

export const avatarDateWrapper = ({ spacing }) => css`
  display: flex;
  align-items: center;
  gap: ${spacing.gap['08']};
`;
