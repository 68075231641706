import { css } from '@emotion/react';

import { ChartBlockSize } from '@align-pages/dashboard/config';
import { radius } from '@styles/theme/radius';

export const wrapperStyles = ({ isAnyDraggingHappening, isCurrentlyDraggedCard }) => ({
  color,
  radius,
  transition,
  shadow,
}) => css`
  align-items: stretch;
  background-color: ${color.neutral.white};
  border: 1px solid ${color.neutral['40']};
  border-radius: ${radius.default};
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${ChartBlockSize.WIDTH}px;
  height: ${ChartBlockSize.HEIGHT}px;
  transition: ${transition.default};
  ${isCurrentlyDraggedCard &&
  `
    border-color: ${color.neutral['80']};
    box-shadow: ${shadow.full};
  `};

  ${isAnyDraggingHappening ||
  `&:hover {
    border-color: ${color.neutral['80']};
    box-shadow: ${shadow.full};
  }`};
`;

export const headerStyles = ({ isAnyDraggingHappening, isCurrentlyDraggedCard }) => ({
  color,
  spacing,
  font,
  transition,
}) => css`
  height: 60px;
  background-color: ${color.neutral.white};
  border-bottom: 1px solid ${color.neutral['40']};
  border-radius: ${radius.default} ${radius.default} 0 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: ${spacing.gap['04']};
  padding-right: ${spacing.gap['06']};
  transition: ${transition.default};

  ${isCurrentlyDraggedCard &&
  `
    background-color: ${color.neutral['10']};
  `};

  ${isAnyDraggingHappening ||
  `&:hover {
    background-color: ${color.neutral['10']};
  }`};

  .title-area {
    flex-grow: 1;
    .title {
      color: ${color.text.dark};
      font-size: ${font.size.s};
      display: flex;
      align-items: center;

      span {
        margin-right: ${spacing.gap['03']};
      }
    }
  }
`;

export const dragIconStyles = ({ isAnyDraggingHappening }) => ({
  spacing,
  color,
  radius,
  transition,
}) => css`
  border-radius: ${radius.default};
  cursor: ${isAnyDraggingHappening ? 'grabbing' : 'grab'};
  margin-right: ${spacing.gap['03']};
  padding: ${spacing.gap['02']};
  transition: ${transition.default};

  ${isAnyDraggingHappening ||
  `&:hover {background-color: ${color.neutral['40']};
  }`};
`;

export const bodyStyles = ({ spacing }) => css`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${spacing.gap['05']};

  .chart-area {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
  }
`;
