import React, { useCallback } from 'react';

import { ITableSearch } from './tableSearch.types';

// TODO: Refactor this component to use reusable components
const TableSearch: React.FC<ITableSearch> = React.memo(
  ({ className = '', isDisabled = false, placeholder, value = '', onFilter }) => {
    const onChange = useCallback((e) => onFilter(e.target.value), [onFilter]);
    return (
      <div className={`input-search ${className || 'mb-5'}`}>
        <label>
          <i className="field-icon fas fa-search" aria-hidden="true" />
          <input
            aria-controls="dataTable"
            className="form-control form-control-sm"
            disabled={isDisabled}
            placeholder={placeholder}
            type="search"
            value={value}
            onChange={onChange}
          />
        </label>
      </div>
    );
  }
);

export { TableSearch };
