import { css } from '@emotion/react';

export const progressBarStyle = ({ spacing }) => css`
  .col-6 > div {
    position: relative;
    height: 100%;
  }
`;

export const progressBarHeaderStyle = ({ spacing }) => css`
  display: flex;
  justify-content: space-between;
  min-height: 36px;
  padding-bottom: ${spacing.gap['04']};
  align-items: flex-end;

  > span {
    max-width: 50%;
  }
`;

export const progressBarFullStyle = ({ spacing }) => css`
  display: grid;
  grid-template-columns: 20% 60% 18%;
  grid-gap: 1%;
  align-items: center;
  margin: ${spacing.gap['0']} ${spacing.gap['03']} ${spacing.gap['08']};

  &:last-child {
    margin-bottom: ${spacing.gap['0']};
  }

  > span {
    width: 100%;

    &:last-child {
      text-align: right;
    }
  }
`;

export const progressBarMultiStyle = ({ spacing }) => ({
  '& > div:not(:last-child)': {
    marginBottom: spacing.gap['08'],
  },
});

export const progressBarLineStyle = ({ color, spacing }, height, background) => css`
  background-color: ${color.neutral['20']};
  display: flex;
  height: ${height}px;
  overflow: hidden;
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    background-color: ${background};
    transition: width 0.6s ease;
  }
`;

export const progressBarStagesStyle = ({ color, spacing, radius }, background) => css`
  background-color: ${color.neutral['20']};
  border-radius: ${radius.default};
  display: flex;
  height: 0.4rem;
  overflow: hidden;
  width: 100%;
`;
