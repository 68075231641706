import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo, useContext } from 'react';

import { SELECTION_STATE } from '@analytics-components/MultiSelect/ui/Checkbox';
import { useExtendedState, usePrevious } from '@analytics-hooks';
import { AvatarType } from '@lib/avatar';

import SelectorWithCheckboxes from './SelectorWithCheckboxes';
import SelectorWithoutCheckboxes from './SelectorWithoutCheckboxes';
import { optsType } from './types';

const Context = React.createContext({});

const ALL_NODE_ID = 'all';
const uniqOptions = (opts) => _(opts).uniqBy('value').value();
const getSelectedNodes = (nodesStates) =>
  uniqOptions(
    _(nodesStates)
      .filter((ns) => ns.id !== ALL_NODE_ID)
      .filter((ns) => ns.selectionState === SELECTION_STATE.SELECTED)
      .map('node')
      .value()
  );
const getNextSelectionState = (nodeState) => {
  return {
    [SELECTION_STATE.NOT_SELECTED]: SELECTION_STATE.SELECTED,
    [SELECTION_STATE.SELECTED]: SELECTION_STATE.NOT_SELECTED,
    [SELECTION_STATE.INDETERMINATE]: SELECTION_STATE.NOT_SELECTED,
  }[nodeState.selectionState];
};
const getNextAllSelectionState = (nodesStates) => {
  const selected = getSelectedNodes(nodesStates);
  return selected.length === 0
    ? SELECTION_STATE.NOT_SELECTED
    : selected.length === nodesStates.length - 1
    ? SELECTION_STATE.SELECTED
    : SELECTION_STATE.INDETERMINATE;
};
const doesOptionMatch = (opt, inputValue) => {
  const normInputValue = inputValue.toUpperCase();

  return (
    !!normInputValue &&
    (opt.label.toUpperCase().indexOf(normInputValue) > -1 ||
      opt.value.toUpperCase().indexOf(normInputValue) > -1)
  );
};

const defaultShouldRenderOption = (
  nodeId,
  matchingOptions,
  matchingAncestors,
  inputValue,
  prevInputValue
) => {
  if (!!inputValue) {
    return _(matchingOptions).concat(matchingAncestors).map('id').uniq().includes(nodeId);
  } else if (!!prevInputValue) {
    return true;
  } else {
    return true;
  }
};

const useSelectorState = () => {
  const {
    nodesStates,
    setNodesStates,
    applied,
    setApplied,
    onApply,
    onSelectedChange,
  } = useContext(Context);
  return {
    nodesStates: [nodesStates, setNodesStates],
    applied: [applied, setApplied],
    onApply,
    onSelectedChange,
  };
};

const useNodeState = (id) => {
  const { nodesStates, setNodesStates, onSelectedChange } = useContext(Context);

  const nodeState = useMemo(() => _(nodesStates).find((ns) => ns.id === id), [(id, nodesStates)]);

  const getToggleSelectionStateSingleParams = () => {
    const updatedSelectionState = getNextSelectionState(nodeState);
    const updatedNodesStatesFirstPass = _(nodesStates)
      .map((ns) => ({
        ...ns,
        selectionState:
          ns.value === nodeState.value && ns.id !== ALL_NODE_ID
            ? updatedSelectionState
            : ns.selectionState,
      }))
      .value();

    const updatedSelectionStateAll = getNextAllSelectionState(updatedNodesStatesFirstPass);
    const updatedNodesStates = _(updatedNodesStatesFirstPass)
      .map((ns) => ({
        ...ns,
        selectionState: ns.id === ALL_NODE_ID ? updatedSelectionStateAll : ns.selectionState,
      }))
      .value();

    return [
      updatedSelectionState === SELECTION_STATE.SELECTED ? 'select-option' : 'deselect-option',
      updatedNodesStates,
    ];
  };

  const getToggleSelectionStateAllParams = () => {
    const updatedSelectionState = getNextSelectionState(nodeState);
    const updatedNodesStates = _(nodesStates)
      .map((ns) => ({
        ...ns,
        selectionState: updatedSelectionState,
      }))
      .value();

    return [
      updatedSelectionState === SELECTION_STATE.SELECTED
        ? 'select-all-option'
        : 'deselect-all-option',
      updatedNodesStates,
    ];
  };

  const toggleSelectionState = () => {
    const [action, updatedNodesStates] =
      id === ALL_NODE_ID
        ? getToggleSelectionStateAllParams()
        : getToggleSelectionStateSingleParams();
    setNodesStates(updatedNodesStates);
    const selectedNodes = getSelectedNodes(updatedNodesStates);
    if (onSelectedChange) {
      onSelectedChange({
        action,
        selected: selectedNodes,
      });
    }
  };

  const toggleExpansionState = () => {
    const nodes = _(nodesStates).map('node').value();
    const updatedNodesStates = _(nodesStates)
      .map((ns) => {
        const updatedIsExpanded = !ns.isExpanded;
        const ancestorsIds = _(getAncestors(nodes, ns.node)).map('id').value();
        if (
          nodeState.id === ns.id ||
          (!updatedIsExpanded && _(ancestorsIds).includes(nodeState.id))
        ) {
          return { ...ns, isExpanded: updatedIsExpanded };
        } else {
          return { ...ns };
        }
      })
      .value();
    setNodesStates(updatedNodesStates);
  };

  return [
    { ...nodeState },
    {
      toggleSelectionState,
      toggleExpansionState,
    },
  ];
};

const computeInitialNodesStates = (processedOptions, applied, shouldRenderOpt) => {
  const initialNodesStatesFirstPass = _(processedOptions)
    .map((opt) => {
      // TODO: Need to check for 'descendants' for `INDETERMINATE`
      const selectionState = _(applied).map('value').includes(opt.value)
        ? SELECTION_STATE.SELECTED
        : SELECTION_STATE.NOT_SELECTED;
      return {
        id: opt.id,
        value: opt.value,
        selectionState,
        lastAppliedSelectionState: selectionState,
        isVisible: shouldRenderOpt(opt.id, processedOptions, processedOptions),
        node: opt,
      };
    })
    .value();

  const expandedNodesIds = _(initialNodesStatesFirstPass)
    .filter((ns) => ns.selectionState !== SELECTION_STATE.NOT_SELECTED)
    .flatMap('node.ancestors')
    .map('id')
    .value();

  const initialNodesStatesSecondPass = _(initialNodesStatesFirstPass)
    .map((ns) => {
      const isExpanded = _(expandedNodesIds).includes(ns.id);
      return {
        ...ns,
        isExpanded,
      };
    })
    .value();

  const updatedSelectionStateAll = getNextAllSelectionState(initialNodesStatesSecondPass);
  const initialNodesStates = _(initialNodesStatesSecondPass)
    .map((ns) => ({
      ...ns,
      selectionState: ns.id === ALL_NODE_ID ? updatedSelectionStateAll : ns.selectionState,
    }))
    .value();

  return initialNodesStates;
};

const Selector = ({
  options,
  initialApplied,
  placeholder,
  avatarType,
  noAvatar,
  noSearch,
  noCheckboxes,
  maxSelected,
  withAllOption,
  useStorage,
  storageKey,
  inputValue: iv,
  updateOnInputValuePropChange,
  shouldRenderOption,
  onLoadFromStorage,
  onSelectedChange,
  onCancel,
  onApply,
}) => {
  const shouldRenderOpt = shouldRenderOption || defaultShouldRenderOption;
  const { optionsGraph, processedOptions, processedInitialApplied } = processOptionsAndApplied(
    options,
    initialApplied
  );

  const [inputValue, setInputValue] = useState(iv);
  const prevInputValue = usePrevious(inputValue);
  const [applied, setApplied] = useExtendedState(processedInitialApplied, {
    useStorage,
    storageKey,
  });
  const [nodesStates, setNodesStates] = useState(
    computeInitialNodesStates(processedOptions, applied, shouldRenderOpt)
  );

  useEffect(() => {
    if (useStorage && onLoadFromStorage) {
      onLoadFromStorage(uniqOptions(applied));
    }
  }, []);

  useEffect(() => {
    if (updateOnInputValuePropChange) {
      setInputValue(iv);
    }
  }, [iv]);

  useEffect(() => {
    // When the search is used, automatically expand children that matches the search.
    // When the input value is empty, reset the group expansion by showing the selected.
    if (!inputValue && !prevInputValue) {
      return;
    }
    const updateNeeded = (current, updated) => {
      const changed = (field) =>
        !_.isEqual(_(current).map(field).value(), _(updated).map(field).value());

      return changed('isVisible') || changed('isExpanded');
    };

    const matchingOptions = _(nodesStates)
      .map('node')
      .filter((n) => (!!inputValue ? doesOptionMatch(n, inputValue) : true))
      .value();
    const matchingAncestors = _(matchingOptions).flatMap('ancestors').value();

    const expandedNodesIds = !!inputValue
      ? _(matchingAncestors).map('id').uniq().value()
      : _(nodesStates)
          .filter((ns) => ns.selectionState !== SELECTION_STATE.NOT_SELECTED)
          .flatMap('node.ancestors')
          .map('id')
          .value();
    const updatedNodesStates = _(nodesStates)
      .map((ns) => ({
        ...ns,
        isVisible: shouldRenderOpt(
          ns.id,
          matchingOptions,
          matchingAncestors,
          inputValue,
          prevInputValue
        ),
        isExpanded: _(expandedNodesIds).includes(ns.id),
      }))
      .value();
    if (updateNeeded(nodesStates, updatedNodesStates)) {
      setNodesStates(updatedNodesStates);
    }
  }, [inputValue, nodesStates]);

  const onInputChange = (ev) => setInputValue(ev.target.value);
  const onInputClick = () => (inputValue !== '' ? setInputValue('') : null);

  return (
    <Context.Provider
      value={{
        nodesStates,
        setNodesStates,
        onSelectedChange,
        applied,
        setApplied,
        onApply,
      }}
    >
      {noCheckboxes ? (
        <SelectorWithoutCheckboxes options={optionsGraph} noAvatar={noAvatar} />
      ) : (
        <SelectorWithCheckboxes
          options={optionsGraph}
          placeholder={placeholder}
          inputValue={inputValue}
          avatarType={avatarType}
          noAvatar={noAvatar}
          noSearch={noSearch}
          maxSelected={maxSelected}
          withAllOption={withAllOption}
          onInputChange={onInputChange}
          onInputClick={onInputClick}
          onCancel={onCancel}
          onApply={onApply}
        />
      )}
    </Context.Provider>
  );
};

const getAncestors = (options, opt) => {
  if (!opt.parent) {
    return [];
  } else {
    const parent = _(options).find((o) => o.value === opt.parent);
    return _([parent, getAncestors(options, parent)])
      .flatMap()
      .value();
  }
};

const getDescendants = (options, opt) => {
  if (!opt.children) {
    return [];
  } else {
    return _([opt, getDescendants(options, opt)])
      .flatMap()
      .value();
  }
};

const processOptionsAndApplied = (options, initialApplied) => {
  const augmentWithIds = (collection, mapping) =>
    _(collection)
      .map((opt) => ({
        id: idMapping[opt.parent ? `${opt.parent}-${opt.value}` : opt.value],
        ...opt,
      }))
      .value();

  const augmentWithTree = (collection, optionsWithIds) =>
    _(collection)
      .map((opt) => ({
        ancestors: getAncestors(optionsWithIds, opt),
        descendants: getDescendants(optionsWithIds, opt),
        ...opt,
      }))
      .value();

  const processOptions = (idMapping = {}, parent = null, level = 1) =>
    _(options)
      .filter((opt) => (opt.parent || null) === ((parent && parent.value) || null))
      .map((opt) => {
        const mappingKey = parent ? `${parent.value}-${opt.value}` : opt.value;
        const id = parent ? `${parent.id}-${opt.value}` : opt.value;
        idMapping[mappingKey] = id;
        const children = processOptions(idMapping, { id, ...opt }, level + 1);
        return children.length === 0
          ? {
              id,
              level,
              ...opt,
            }
          : {
              id,
              level,
              ...opt,
              children,
            };
      })
      .value();

  const idMapping = {};
  const optionsGraph = processOptions(idMapping);
  const optionsWithIds = augmentWithIds(options, idMapping);
  const processedOptions = augmentWithTree(optionsWithIds, optionsWithIds);
  const initialAppliedItemsSet = _(processedOptions)
    .filter((opt) => _(initialApplied).map('value').includes(opt.value))
    .value();
  const initialAppliedItemsSetWithIds = augmentWithIds(initialAppliedItemsSet, idMapping);
  const processedInitialApplied = augmentWithTree(initialAppliedItemsSetWithIds, optionsWithIds);

  const allOption = {
    ancestors: [],
    descendants: _(processedOptions)
      .map((opt) => _(opt).omit(['ancestors', 'descendants']).value())
      .value(),
    id: ALL_NODE_ID,
    value: ALL_NODE_ID,
    label: ALL_NODE_ID,
  };

  return {
    optionsGraph,
    processedOptions: _(allOption).concat(processedOptions).value(),
    processedInitialApplied,
  };
};

Selector.propTypes = {
  options: optsType,
  initialApplied: optsType,
  placeholder: PropTypes.string,
  avatarType: PropTypes.oneOf(Object.values(AvatarType)),
  noAvatar: PropTypes.bool,
  noSearch: PropTypes.bool,
  noCheckboxes: PropTypes.bool,
  maxSelected: PropTypes.number,
  withAllOption: PropTypes.bool,
  useStorage: PropTypes.bool,
  storageKey: (props, propName, componentName, ...rest) => {
    const validator = props.useStorage
      ? () => {
          const err = PropTypes.string.isRequired(props, propName, componentName, ...rest);
          if (err) {
            return err;
          }

          if (props[propName].length === 0) {
            return new Error(`The prop '${propName}' has to have a length > 0`);
          }

          return null;
        }
      : PropTypes.string;
    return validator(props, propName, componentName, ...rest);
  },
  inputValue: PropTypes.string,
  updateOnInputValuePropChange: PropTypes.bool,
  shouldRenderOption: PropTypes.func,
  onLoadFromStorage: PropTypes.func,
  onSelectedChange: PropTypes.func,
  onCancel: PropTypes.func,
  onApply: PropTypes.func,
};

export default Selector;
export { processOptionsAndApplied, useSelectorState, useNodeState, ALL_NODE_ID };
