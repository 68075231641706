import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { CONFIG } from '@align-constants';
import { IUseQueryHookRest } from '@align-types/common';
import { useAuth0 } from '@common-context/Auth0';
import { useUserContext } from '@common-context/User';
import { useDemoCookie } from '@common-pages/Demo';
import { IApiBasicError } from '@common-services/api/common/types/common';
import { TeamTree } from '@common-services/api/private/generated-from-backend/models';
import { ITeamTreeParams } from '@common-services/api/private/types/teamTree';
import { ArrayService } from '@common-services/arrayService';

import { TeamTreeServices } from '../services/teamTree';

export function useTeamTree(
  teamId: number,
  params: ITeamTreeParams,
  isCached: boolean = false
): IUseQueryHookRest<TeamTree> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();

  const { isLoading, data, error } = useQuery<TeamTree, IApiBasicError>(
    ['teams', teamId, params.account],
    async () => {
      const token: string = isDemo === false ? await getTokenSilently() : null;
      return TeamTreeServices.getTeamTree({ teamId }, params, token);
    },
    {
      ...CONFIG.API.FETCH_OPTIONS,
      cacheTime: isCached ? CONFIG.API.FETCH_OPTIONS.cacheTime : 0,
      staleTime: isCached ? CONFIG.API.FETCH_OPTIONS.staleTime : 0,
    }
  );

  return { isLoading, data, error };
}

export function useOwnedTeamTree(isCached: boolean = false): IUseQueryHookRest<TeamTree> {
  const userContext = useUserContext();
  const account = userContext?.user?.defaultAccount?.id || null;
  return useTeamTree(CONFIG.API.TEAMS.ROOT_TEAM_ID, { account }, isCached);
}

export function useFlatTeamsTree(): TeamTree[] {
  const { data: teamsDataRoot } = useOwnedTeamTree(true);

  const flatTeamsData = useMemo<TeamTree[]>(
    () => [
      teamsDataRoot,
      ...ArrayService.getFlatArrayFromNested(teamsDataRoot?.children, 'children'),
    ],
    [teamsDataRoot]
  );

  return flatTeamsData;
}
