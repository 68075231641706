import { History } from 'history';
import { useCallback } from 'react';
import { useHistory as useRouterHistory, useLocation } from 'react-router-dom';

interface IHistory extends History {
  pushWithBackState: (pathname: string) => void;
}

export function useHistory(): IHistory {
  const location = useLocation();
  const history = useRouterHistory();

  history.pushWithBackState = useCallback(
    (pathname) => {
      history.push({ pathname, state: { from: location.pathname } });
    },
    [location.pathname]
  );

  return history;
}
