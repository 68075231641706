import React from 'react';

import TreeViewImage from '@assets/images/tree_view.svg';

import { wrapperStyles } from './emptyTree.styles';

const EmptyTree = () => {
  return (
    <div css={wrapperStyles}>
      <img alt="" src={TreeViewImage} />
      <div className="text">Select a Template to Start</div>
    </div>
  );
};

export { EmptyTree };
