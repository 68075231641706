import React from 'react';

import { PrimaryLayoutModes } from '@align-components/layouts/primary';
import { drillDownTooltipText } from '@align-constants';
import {
  containerStyles,
  fadeStyles,
  getHeaderStyle,
  getMetricWrapperStyle,
  getStyle,
  getTitleWrapperStyle,
} from '@align-pages/goals/single/components/goal-template/ui/styles';
import { StringService } from '@analytics-services/stringService';
import { Icon, icons } from '@lib/icon';
import { Tooltip } from '@lib/tooltip';

interface Props {
  isActive: boolean;
  title: string;
  icon: icons;
  color: string;
  doesDrillDown: boolean;
  metricDisplayName: string;
  mode?: PrimaryLayoutModes;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const GoalTemplateUI: React.FC<Props> = ({
  isActive,
  icon,
  color,
  doesDrillDown,
  title,
  metricDisplayName,
  mode,
  onClick,
}) => {
  return (
    <Tooltip content={doesDrillDown ? null : <div>{drillDownTooltipText}</div>}>
      <div css={containerStyles}>
        {mode === PrimaryLayoutModes.EDIT && !isActive && <div css={fadeStyles} />}
        <div onClick={onClick} css={getStyle(isActive, color, mode)}>
          <div css={getHeaderStyle(color)} />
          <div css={getTitleWrapperStyle}>
            <p>{title}</p>
          </div>
          <div css={getMetricWrapperStyle(isActive, color)}>
            <Icon icon={icon} color={color} />
            <p>{StringService.truncateText(metricDisplayName, 25)}</p>
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

export { GoalTemplateUI };
