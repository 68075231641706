import { useCallback, useState } from 'react';

import { IUseTableTabs } from '@align-pages/goals/single/components/goal-view-component/goalViewComponent.types';
import { PrsIssuesTableTabs } from '@align-types/common';

export function useTableTabs(): IUseTableTabs {
  const [activeTab, setActiveTab] = useState(PrsIssuesTableTabs.COMPLETED);

  const changeTab = useCallback((tabIndex) => setActiveTab(tabIndex), []);

  return { activeTab, changeTab };
}
