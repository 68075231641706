import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalBarSeries,
  DiscreteColorLegend,
} from 'react-vis';

import Tooltip, { onValueChange, onValueReset } from '@analytics-components/charts/Tooltip';
import defaults from '@analytics-components/charts/defaults';

const Chart = ({ data, extra, tickFormat }) => (
  <div style={{ background: 'white' }}>
    <HorizontalBarChart data={data} extra={extra} tickFormat={tickFormat} />
  </div>
);

const userImageSize = 30;

const HorizontalBarChart = ({ data, extra, tickFormat = (v) => v }) => {
  const [currentHover, setCurrentHover] = useState(null);

  const handleValueMouseOver = useCallback(
    (datapoint) => onValueChange(datapoint, currentHover, setCurrentHover),
    [currentHover]
  );

  const handleValueMouseOut = useCallback(() => onValueReset(setCurrentHover), []);

  if (data.length === 0) {
    return <></>;
  }

  const marginLeft = extra.margin?.left ?? defaults.marginLeft;
  const marginTop = 30; // It should be at least 30, because this chart has allways a DiscreteColorLegend

  const series = _(data).reduce((acc, v) => {
    _(extra.axisKeys.x).each((ax) => {
      acc[ax] = acc[ax] || [];
      acc[ax].push({
        x: v[ax],
        y: v[extra.axisKeys.y],
        ...(v.tooltip && {
          tooltip: {
            author: v.tooltip?.author,
            image: v.tooltip?.image,
            [ax]: v.tooltip && v.tooltip[ax],
          },
        }),
      });
    });
    return acc;
  }, {});

  const legend = _(series)
    .map(
      (s, k) =>
        extra.series[k].name && {
          title: extra.series[k].name,
          color: extra.series[k].color,
          strokeWidth: 10,
        }
    )
    .filter((l) => l)
    .value();

  const ChartTooltip = extra?.tooltip?.template || Tooltip;

  const rowHeight = extra.yAxis?.imageMapping ? userImageSize + 15 : 40;
  const xAxisHeight = 40;
  const chartHeight = data.length * rowHeight + xAxisHeight;

  return (
    <FlexibleWidthXYPlot
      height={chartHeight}
      margin={{ top: marginTop, left: marginLeft }}
      yType="ordinal"
    >
      <DiscreteColorLegend className="chart-legend" items={legend} orientation="horizontal" />
      <VerticalGridLines />
      <XAxis tickFormat={tickFormat} tickSizeOuter={0} tickSizeInner={0} />

      {extra && extra.yAxis && extra.yAxis.image ? (
        <CircleMask
          id={`${extra.yAxis.imageMask}-mask`}
          maskProperties={{
            cx: 15,
            cy: 15,
            r: 15,
          }}
        />
      ) : null}

      {_(series)
        .map((s, k) => (
          <HorizontalBarSeries
            data={s.reverse()}
            color={extra.series[k].color}
            key={k}
            barWidth={0.6}
            onValueMouseOver={handleValueMouseOver}
            onValueMouseOut={handleValueMouseOut}
            stroke="none"
          />
        ))
        .value()}

      {extra?.yAxis?.image && <UserNameBackground />}

      {extra && extra.yAxis && extra.yAxis.image ? (
        <YAxis
          tickSizeOuter={0}
          tickSizeInner={0}
          tickFormat={(value) => {
            const avatar = extra.avatars[value];
            return extra.yAxis.image ? (
              <g className="userIcon">
                <image
                  href={avatar}
                  clipPath={extra.yAxis.imageMask ? `url(#${extra.yAxis.imageMask}-mask)` : ''}
                  width={userImageSize}
                  height={userImageSize}
                  transform="translate(-40,-15)"
                  onError={(e) => {
                    e.target.href.baseVal = 'https://avatars2.githubusercontent.com/u/10137';
                  }}
                />
                <UserHint name={value} />
              </g>
            ) : (
              value
            );
          }}
        />
      ) : (
        <YAxis />
      )}

      <ChartTooltip
        value={currentHover}
        x={extra?.tooltip?.x}
        y={extra?.tooltip?.y}
        {...extra.tooltip}
      />
    </FlexibleWidthXYPlot>
  );
};

const CircleMask = ({ id, maskProperties }) => (
  <defs>
    <clipPath id={id} fill="black">
      <circle {...maskProperties} />
    </clipPath>
  </defs>
);

CircleMask.requiresSVG = true;

const UserNameBackground = () => (
  <defs>
    <filter x="0" y="0" width="1" height="1" id="solid">
      <feFlood floodColor="white" />
      <feComposite in="SourceGraphic" />
    </filter>
  </defs>
);

UserNameBackground.requiresSVG = true;

const userFontSize = 16;
const UserHint = ({ name }) => (
  // TODO(dpordomingo): Style the 'User Icon' tooltip using SVG native elements;
  // alternative: investigate if it can be attached a javascript handler to <svg:image:onHover>,
  //    and use it to handle the react-vis Hint passing the (x,y) position of the triggered event.
  <text
    className="userName"
    x="0"
    y={userImageSize + userFontSize * 1.3}
    transform="translate(-40,-15)"
    filter="url(#solid)"
    fill="black"
    fontSize={userFontSize}
  >
    {name}
  </text>
);

export default Chart;
