import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useOnClickOutside } from '@common-hooks/useOnClickOutside';

import { useConvertOptions, useFindInitialObject } from './Dropdown.hooks';
import { wrapperStyles } from './Dropdown.styles';
import { IDropdown, DropdownType, IDropdownNameValue } from './Dropdown.types';
import { DropdownControl } from './components';
import { DropdownOptions } from './components/DropdownOptions';

export const Dropdown: React.FC<IDropdown> = React.memo(
  ({
    disabled = false,
    options,
    placeholder,
    initialValue,
    width,
    withAvatar,
    type = DropdownType.NAME,
    withSearch,
    icon,
    onChange,
  }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState<IDropdownNameValue>({
      value: '',
      name: '',
    });
    const fieldRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const optionsObject = useConvertOptions(options, type);
    const initialObject = useFindInitialObject(initialValue, optionsObject);

    useEffect(() => {
      setValue(initialObject);
    }, [initialObject]);

    const handleFieldClick = useCallback(() => {
      if (!disabled) setIsOpen(!isOpen);
    }, [disabled, isOpen]);

    const handleSelect = useCallback(
      (newValue: IDropdownNameValue) => () => {
        setValue(newValue);
        setIsOpen(false);
        if (!isEqual(newValue, value)) {
          onChange && onChange(newValue);
        }
      },
      [value]
    );

    useOnClickOutside([fieldRef, dropdownRef], () => setIsOpen(false));

    return (
      <div css={wrapperStyles}>
        <DropdownControl
          width={width}
          value={value}
          isOpen={isOpen}
          ref={fieldRef}
          withAvatar={withAvatar}
          placeholder={placeholder}
          disabled={disabled}
          onClick={handleFieldClick}
          icon={icon}
        />
        {isOpen && (
          <DropdownOptions
            width={width}
            options={optionsObject}
            withAvatar={withAvatar}
            onClick={handleSelect}
            ref={dropdownRef}
            withSearch={withSearch}
            placeholder={placeholder}
          />
        )}
      </div>
    );
  }
);
