import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Card } from '@lib/Card';
import { icons } from '@lib/icon';

import { TeamsPanelProps } from './TeamsPanel.types';
import { TeamAccordion } from './components/TeamAccordion';

const TeamsPanel: React.FC<TeamsPanelProps> = React.memo(({ teams, onEditTeams }) => {
  return (
    <Card
      headerIcon={icons.people}
      headerText="Teams"
      headerBadgeCount={teams.length}
      headerButtonText="Edit Teams"
      onHeaderButtonClick={onEditTeams}
    >
      {teams.map((team) => (
        <TeamAccordion key={uuidv4()} team={team} />
      ))}
    </Card>
  );
});

export { TeamsPanel };
