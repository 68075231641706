import _ from 'lodash';
import React from 'react';

import { BigText, DefaultXYTooltip } from '@analytics-components/charts/Tooltip';
import VerticalBarChart from '@analytics-components/charts/VerticalBarChart';
import { firstNRepos } from '@analytics-components/insights/Helper';
import { SimpleKPI } from '@analytics-components/insights/KPI';
import { dateTime } from '@common-services/dateService';
import { github } from '@common-services/format';
import { theme } from '@styles/theme';

const reviewTimePerRepo = {
  title: 'Review Time',
  description:
    'Average review time computed per repository. The repositories are ordered by number pull requests reviewed.',
  plumber: (fetchedData, cachedData, apiContext) => {
    const prsReviewedPerRepo = _(cachedData['repos-metrics'])
      .map((v, k) => ({
        repo: github.repoName(k),
        reviewTime: v['review-time'],
        reviewed: v['reviewed'] || 0,
      }))
      .filter((pr) => !!pr.reviewed);

    const averagePerRepo = prsReviewedPerRepo.meanBy('reviewTime') || 0;
    const [biggestReviewTimeRepo] = prsReviewedPerRepo
      .orderBy(['reviewTime'], ['desc'])
      .slice(0, 1);

    const chartData = prsReviewedPerRepo.orderBy(['reviewed'], ['desc']).take(firstNRepos).value();
    const reviewTime = biggestReviewTimeRepo?.reviewTime;
    const KPIsData = {
      averagePerRepo,
      biggestReviewTimeRepo: biggestReviewTimeRepo?.repo,
      biggestReviewTime: reviewTime,
    };
    const axisKeys = {
      x: 'repo',
      y: 'reviewTime',
    };

    return {
      empty: chartData.filter((v) => v[axisKeys.y] !== 0).length === 0,
      chart: {
        component: VerticalBarChart,
        params: {
          data: chartData,
          extra: {
            axisFormat: {
              tickFormat: {
                y: (v) => dateTime.human(v * 1000),
              },
            },
            axisKeys: axisKeys,
            maxNumberOfTicks: 4,
            color: theme.color.stage.review,
            tooltip: {
              align: {
                horizontal: 'auto',
                vertical: 'top',
              },
              template: DefaultXYTooltip,
              y: (v) => (
                <BigText
                  content={`${dateTime.human(v.y * 1000)}`}
                  extra={`${v.reviewed} PR${v.reviewed === 1 ? '' : 's'}`}
                />
              ),
            },
          },
        },
      },
      kpis: [
        {
          title: { text: 'Review Time', bold: true },
          subtitle: { text: 'Average Per Repository' },
          component: SimpleKPI,
          params: {
            value: dateTime.human(KPIsData.averagePerRepo * 1000),
          },
        },
        {
          title: { text: 'Repository with the biggest', bold: true },
          subtitle: { text: 'Average Review Time' },
          component: SimpleKPI,
          params: {
            value: KPIsData.biggestReviewTimeRepo,
            secondary: `(${dateTime.human(KPIsData.biggestReviewTime * 1000)})`,
          },
        },
      ],
    };
  },
  prefetchedDataIds: ['repos-metrics'],
};

export default reviewTimePerRepo;
