import { useTheme } from '@emotion/react';
import React from 'react';

import { Icon } from '@lib/icon';
import { ITheme } from '@styles/types';

import { wrapperStyles, iconWrapperStyles, textStyles } from './DetailTag.styles';
import { DetailTagIds } from './__tests__/testIds.ids';
import { IDetailTag } from './types';

export const DetailTag: React.FC<IDetailTag> = React.memo(({ icon, text, count, textColor }) => {
  const theme = useTheme() as ITheme;
  return (
    <div css={wrapperStyles}>
      {icon && (
        <div css={iconWrapperStyles} data-testid={DetailTagIds.icon}>
          <Icon icon={icon} size={12} color={theme.color.neutral['80']} />
        </div>
      )}
      <div css={textStyles(textColor)} data-testid={DetailTagIds.text}>
        {count} {text}
        {count === 1 ? '' : 's'}
      </div>
    </div>
  );
});
