export enum featuresList {
  force_waiting_page = 'force_waiting_page',
  filter_teams_mandatory = 'filter_teams_mandatory',
  filter_prs_limit = 'filter_prs_limit',
  user_org_membership_check = 'user_org_membership_check',
  github_features_entries_preloading = 'github_features_entries_preloading',
  filter_prs_by_labels = 'filter_prs_by_labels',
  visible_ci_sections = 'visible_ci_sections',
  exclude_prs_by_labels = 'exclude_prs_by_labels',
  api_channel = 'api_channel',
  hide_review_activity = 'hide_review_activity',
  github_login_enabled = 'github_login_enabled',
  align_enabled = 'align_enabled',
  team_allocation_table_enabled = 'team_allocation_table_enabled',
  all_repos_as_filter_options = 'all_repos_as_filter_options',
  enable_new_prs_table = 'enable_new_prs_table',
  deploy_tables_enabled = 'deploy_tables_enabled',
  force_maintenance_page = 'force_maintenance_page',
}
