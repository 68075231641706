import { useMemo } from 'react';

import { metricsConfig } from '@align-constants';
import { IUseChartDonutCoordinates } from '@align-pages/goals/single/components/goal-summary/components/chart-donut/chartDonut.types';
import { GoalsSingleComponentServices } from '@align-pages/goals/single/components/goals-single-component';
import { MetricService } from '@align-services/metricService';
import { IMetricBetter } from '@align-types/constants';
import { StringService as StringServiceAnalytics } from '@analytics-services/stringService';
import {
  GoalMetricSeries,
  GoalMetricValue,
  GoalValue,
} from '@common-services/api/private/generated-from-backend/models';
import {
  GoalMetricParams,
  GoalTemplate,
} from '@common-services/api/public/generated-from-backend/models';
import { sanitize } from '@common-services/htmlService';
import { StringService as StringServiceCommon } from '@common-services/stringService';

export function useChartDonutTitle(
  threshold: GoalMetricParams['threshold'],
  currentGoalValue: GoalMetricValue,
  targetGoalValue: GoalMetricValue,
  seriesData: GoalMetricSeries,
  metricValue: GoalTemplate['metric']
): string {
  const title = useMemo(() => {
    if (threshold && currentGoalValue != null) {
      return MetricService.convertFloatToPercentage(currentGoalValue) + '%';
    } else {
      const dataWithValue = seriesData.filter((val) => val?.value);
      const aboveTargeted = dataWithValue.reduce((total, val) => {
        if (metricsConfig[metricValue].better === IMetricBetter.HIGHER) {
          return parseInt(val.value as string) >= parseInt(targetGoalValue as string)
            ? total + 1
            : total;
        } else {
          return parseInt(val.value as string) <= parseInt(targetGoalValue as string)
            ? total + 1
            : total;
        }
      }, 0);
      return `${aboveTargeted}/${dataWithValue.length}`;
    }
  }, [seriesData, threshold, currentGoalValue, targetGoalValue, metricValue]);
  return title;
}

export function useChartDonutSubtitle(
  threshold: GoalMetricParams['threshold'],
  metricValue: GoalTemplate['metric'],
  seriesGranularity: GoalValue['series_granularity'],
  beginCoordinate: number
): string {
  const subtitle = useMemo(() => {
    if (threshold && metricValue) {
      const description = metricsConfig[metricValue]?.metricDescription.short;
      const thresholdReadable = MetricService.getReadableValue(
        GoalsSingleComponentServices.deNormaliseMetricValue(threshold),
        metricsConfig[metricValue].valueType,
        metricsConfig[metricValue].unit
      );
      const subtitleReplaced = StringServiceCommon.replaceVarsInStr(description || '', {
        value: MetricService.getUnitSingularity(thresholdReadable, metricValue),
      });
      const subtitleSanitized = sanitize(subtitleReplaced, ['span']);
      return StringServiceAnalytics.truncateText(subtitleSanitized, 22);
    } else {
      return `${seriesGranularity}${beginCoordinate > 1 ? 's' : ''} on track`;
    }
  }, [threshold, metricValue, seriesGranularity, beginCoordinate]);
  return subtitle;
}

export function useChartDonutCoordinates(
  threshold: GoalMetricParams['threshold'],
  currentGoalValue: GoalMetricValue,
  title: string
): IUseChartDonutCoordinates {
  const coordinates = useMemo<IUseChartDonutCoordinates>(() => {
    if (threshold) {
      return [currentGoalValue as number, 1 - (currentGoalValue as number)];
    } else if (title?.includes('/')) {
      const coordinates = title
        .split('/')
        .map((coordinate) => parseInt(coordinate)) as IUseChartDonutCoordinates;
      return [coordinates[0], coordinates[1] - coordinates[0]];
    } else {
      return [null, null];
    }
  }, [threshold, currentGoalValue, title]);
  return coordinates;
}
