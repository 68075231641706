import React, { useMemo } from 'react';

import { useUserContext } from '@common-context/User';
import { github } from '@common-services/format';
import { FilterDropdown } from '@lib/filter-dropdown';
import { FilterType, IFilterTypeProps } from '@lib/filter-panel';
import { SelectTsOptionSingle } from '@lib/selector-ts';

import { useExistingFilters } from '../filterPanel.hooks';

export const Repository: React.FC<IFilterTypeProps> = React.memo(({ onChange, chartParamType }) => {
  const {
    user: {
      defaultReposet: { repos },
    },
  } = useUserContext();

  const selectedValues = useExistingFilters(FilterType.REPOSITORY, chartParamType);

  const options = useMemo<SelectTsOptionSingle[]>(
    () =>
      repos.map((repo) => ({
        name: github.repoName(repo),
        id: repo,
      })),
    [repos]
  );

  return (
    <FilterDropdown
      onChange={onChange}
      isLoading={false}
      options={options}
      label="Repository"
      allSelectedLabel="All Repositories"
      initialSelected={selectedValues}
    />
  );
});
