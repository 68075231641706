import { css } from '@emotion/react';

import { IBadgeSize, IBadgeType } from '@common-types/common';

const sizeProps = {
  [IBadgeSize.DEFAULT]: {
    height: '40px',
  },
  [IBadgeSize.SMALL]: {
    height: '32px',
  },
  [IBadgeSize.XSMALL]: {
    height: '28px',
  },
};

const typeProps = ({ metricColor, spacing }) => ({
  [IBadgeType.DEFAULT]: {
    background: `${metricColor}19`,
    padding: `${spacing.gap['03']}`,
    border: `1px solid ${metricColor}`,
  },
  [IBadgeType.GHOST]: {
    padding: `${spacing.gap['03']} 0px`,
  },
});

export const wrapperStyles = ({ metricColor, type, size }) => ({ spacing, radius, font }) => css`
  ${typeProps({ metricColor, spacing })[type]}
  ${sizeProps[size]}

  font-weight: ${font.weight.medium};
  color: ${metricColor};
  border-radius: ${radius.default};

  display: inline-flex;
  align-items: center;

  .icon {
    margin-right: ${spacing.gap['03']};
    width: 17px;
  }
`;
