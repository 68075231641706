import React, { useMemo } from 'react';
import { useTable } from 'react-table';

import { processPR } from '@common-services/prHelpers';
import { Table } from '@lib/Table';
import { prTableColumns } from '@lib/Table/components/pr-table/prTable.columns';
import { tableBodyStyles } from '@lib/Table/components/pr-table/prTable.styles';
import { IPrsTableRow } from '@lib/Table/components/pr-table/prTable.types';
import { tableHeaderStyles } from '@lib/Table/styles';

import { ChartPrTableServices } from './chartPrTable.services';
import { IChartPrTable } from './chartPrTable.types';

export const ChartPrTable: React.FC<IChartPrTable> = React.memo(
  ({ data, metric, isLoadingPart, onShowMoreHandler }) => {
    const serializedData = useMemo<IPrsTableRow[]>(
      () =>
        ChartPrTableServices.serializePrTableData(
          {
            deployments: {},
            prs: data?.data?.map(processPR) || [],
            users: data?.include?.users || {},
          },
          metric
        ),
      [data, metric]
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<
      IPrsTableRow
    >({
      columns: prTableColumns,
      data: serializedData,
    });

    rows.forEach(prepareRow);

    return (
      <Table
        rows={rows}
        headerGroups={headerGroups}
        tableProps={getTableProps()}
        tableBodyProps={getTableBodyProps()}
        extraCSS={{
          header: tableHeaderStyles,
          body: tableBodyStyles,
        }}
        isLoadingPart={isLoadingPart}
        isSortingEnabled={false}
        onShowMoreHandler={onShowMoreHandler}
      />
    );
  }
);
