import _ from 'lodash';

import { BigText, DefaultXYTooltip } from '@analytics-components/charts/Tooltip';
import VerticalBarChart from '@analytics-components/charts/VerticalBarChart';
import { firstNRepos } from '@analytics-components/insights/Helper';
import { SimpleKPI } from '@analytics-components/insights/KPI';
import { dateTime } from '@common-services/dateService';
import { github } from '@common-services/format';
import { theme } from '@styles/theme';

import { IKpiData } from './prs-cycle-time-per-repo.types';

const prsCycleTimePerRepo = {
  title: 'Repositories',
  description:
    'Pull request cycle time broken down per repository. The repositories are ordered by number of pull requests released.',
  plumber: (fetchedData, cachedData, apiContext) => {
    const prsLeadTimePerRepo = _(cachedData['repos-metrics'])
      .map((v, k) => ({
        repo: github.repoName(k),
        leadTime: v['lead-time'],
        released: v['release-count'],
      }))
      .filter((pr) => !!pr.leadTime);

    const averageCycleTimePerRepo = prsLeadTimePerRepo.meanBy('leadTime') || 0;
    const biggestCycleTimeRepo = prsLeadTimePerRepo.orderBy(['leadTime'], ['desc']).value()[0];

    const chartData = prsLeadTimePerRepo.orderBy(['released'], ['desc']).take(firstNRepos).value();

    const KPIsData: IKpiData = {
      averageCycleTimePerRepo,
      biggestCycleTimeRepo: biggestCycleTimeRepo?.repo,
      biggestCycleTime: biggestCycleTimeRepo?.leadTime,
    };

    const axisKeys: { x: string; y: string } = {
      x: 'repo',
      y: 'leadTime',
    };

    return {
      empty: chartData.filter((v) => v[axisKeys.y] !== 0).length === 0,
      chart: {
        component: VerticalBarChart,
        params: {
          data: chartData,
          extra: {
            axisKeys: axisKeys,
            axisFormat: {
              tickFormat: {
                y: (v) => dateTime.human(v * 1000),
              },
            },
            maxNumberOfTicks: 4,
            color: theme.color.ui.blue[100],
            tooltip: {
              align: {
                horizontal: 'auto',
                vertical: 'top',
              },
              template: DefaultXYTooltip,
              y: (v: { released: number; y: number }): JSX.Element => (
                <BigText
                  content={`${dateTime.human(v.y * 1000)}`}
                  extra={`${v.released} PR${v.released === 1 ? '' : 's'}`}
                />
              ),
            },
          },
        },
      },
      kpis: [
        {
          title: { text: 'Pull Request Cycle Time', bold: true },
          subtitle: { text: 'Average Per Repository' },
          component: SimpleKPI,
          params: {
            value: dateTime.human(KPIsData.averageCycleTimePerRepo * 1000),
          },
        },
        {
          title: { text: 'Repository with the biggest', bold: true },
          subtitle: { text: 'Pull Request Cycle Time' },
          component: SimpleKPI,
          params: {
            value: KPIsData.biggestCycleTimeRepo,
            secondary: `(${dateTime.human(KPIsData.biggestCycleTime * 1000)})`,
          },
        },
      ],
    };
  },
  prefetchedDataIds: ['repos-metrics'],
};

export default prsCycleTimePerRepo;
