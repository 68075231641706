import { useTheme } from '@emotion/react';
import _ from 'lodash';
import React from 'react';

import PipelineSummaryBox from '@analytics-components/PipelineSummaryBox';
import { DATA_STATUS } from '@analytics-components/StatusIndicator';
import TimeSeries from '@analytics-components/charts/TimeSeries';
import { BigText } from '@analytics-components/charts/Tooltip';
import { SummaryMatrixKPIs } from '@analytics-components/insights/SummaryBox';
import { useBreadcrumb } from '@analytics-context/Breadcrumb';
import DataWidget, { useDataWidget } from '@analytics-context/DataWidget';
import { fetchCIMetrics } from '@analytics-services/api';
import { getFeature, featuresList } from '@analytics-services/flags';
import { dateTime } from '@common-services/dateService';
import { number } from '@common-services/format';
import { hexToRGBA } from '@utils/colors';

const buildChart = (data, isLoading, { color }) => {
  if (isLoading) {
    return [];
  } else {
    const tickFormat = data.granularity === 'month' ? dateTime.month : dateTime.monthDay;
    const tooltip = {
      align: {
        horizontal: 'auto',
        vertical: 'top',
      },
      renderBigFn: (v) => <BigText content={dateTime.timeValue(v.y)} />,
    };
    if (_(data.granularity).includes('month') || _(data.granularity).includes('week')) {
      tooltip.dateRange = { ...data.interval };
      tooltip.granularity = data.granularity;
    }

    return {
      params: {
        data: data.chartData,
        timeMode: false,
        extra: {
          average: {
            value: data.average,
            color: color.neutral[80],
            renderFn: tooltip.renderBigFn,
          },
          axisKeys: {
            x: 'date',
            y: 'value',
          },
          color: color.ui.lightblue[100],
          fillColor: hexToRGBA(color.ui.lightblue[100], 0.1),
          maxNumberOfTicks: 10,
          ticks: {
            x: { tickFormat },
            y: { tickFormat: (v) => dateTime.timeValue(v) },
          },
          tooltip,
        },
      },
    };
  }
};

const RunTimeSummaryBox = () => {
  const { subsection: activeStage } = useBreadcrumb();

  const fetcher = async (api, cachedData, apiContext) => {
    const {
      account,
      interval,
      contributors,
      repositories,
      labels,
      features,
      epics,
      issueTypes,
    } = apiContext;
    const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, features)?.parameters
      ?.value;

    const fetch = (timeInterval, metrics, granularity) =>
      fetchCIMetrics(
        api,
        account,
        timeInterval,
        [granularity],
        metrics,
        repositories,
        contributors,
        labels,
        excludedLabels || [],
        { epics, issue_types: issueTypes }
      );

    const avgData = await fetch(interval, ['chk-concurrency', 'chk-suites-in-prs-count'], 'all');
    return {
      avgConcurrency: avgData.calculated[0].values[0].values[0],
      avgOnPR: avgData.calculated[0].values[0].values[1],
    };
  };

  const plumber = (fetchedData, cachedData, apiContext) => {
    const customGranularity = cachedData['ci-metrics'].customGranularity;
    const granularity = customGranularity === 'month' ? 'custom-aligned' : 'custom';
    const suiteRunTime = cachedData['ci-metrics'].values[granularity]['chk-suite-time'];

    return {
      average: cachedData['ci-metrics'].values.all['chk-suite-time'],
      variation: cachedData['ci-metrics'].variations['chk-suite-time'],
      formatFn: (v) => dateTime.timeValue(v),
      interval: apiContext.interval,
      granularity: customGranularity,
      empty: !suiteRunTime.length || !suiteRunTime.filter((item) => item.value).length,
      chartData: suiteRunTime,
      kpisData: [
        {
          title: 'Suite Runs',
          value: number.fixed(cachedData['ci-metrics'].values.all['chk-suites-count']),
        },
        {
          title: 'On Pull Request',
          value: number.percentage(
            (fetchedData.avgOnPR / cachedData['ci-metrics'].values.all['chk-suites-count']) * 100,
            1
          ),
        },
        {
          title: 'Run Time / PR',
          value: dateTime.timeValue(cachedData['ci-metrics'].values.all['chk-suite-time-per-pr']),
        },
        {
          title: 'Average Concurrency',
          value: number.fixed(fetchedData.avgConcurrency, 1),
        },
      ],
    };
  };

  return (
    <DataWidget
      id={`summary-box-${activeStage}`}
      fetcher={fetcher}
      plumber={plumber}
      prefetchedDataIds={['ci-metrics']}
    >
      <SummaryBoxDataBinder
        renderKPI={(data) => <SummaryMatrixKPIs kpis={data} numberOfCols={2} />}
      />
    </DataWidget>
  );
};

const SummaryBoxDataBinder = ({ className, renderKPI }) => {
  const theme = useTheme();
  const { data, isLoading } = useDataWidget();
  const chart = buildChart(data, isLoading, theme);

  return (
    <PipelineSummaryBox
      chart={
        <div css={{ marginTop: theme.spacing.gap['08'] }}>
          <TimeSeries {...chart.params} />
        </div>
      }
      className={className}
      data={data}
      dataStatus={DATA_STATUS.NO_DATA}
      isLoading={isLoading}
      renderKPI={renderKPI}
      statusColor={theme.color.neutral[80]}
      title="Suite Run Time"
    />
  );
};

export default RunTimeSummaryBox;
