import { publicApi } from '..';
import { GetJIRAIssuesRequest, GetJIRAIssuesResponse } from '../generated-from-backend/models';

export const getJiraIssues = async (
  params: GetJIRAIssuesRequest,
  token: string
): Promise<GetJIRAIssuesResponse> => {
  return publicApi.issues.getPullRequests(params, token, {
    message: `Cannot get Jira issues for accountId ${params.account} from public API`,
  });
};

export * as PublicIssuesServices from './issues';
