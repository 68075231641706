import {
  GoalMetricParams,
  GoalTemplate,
} from '@common-services/api/public/generated-from-backend/models';

export enum IMetricDescriptionTextMode {
  EDIT = 'edit',
  VIEW = 'view',
  LIST = 'list',
  TITLE = 'title',
}

export interface IMetricDescriptionText {
  metricValue: GoalTemplate['metric'];
  threshold: GoalMetricParams['threshold'];
  mode: IMetricDescriptionTextMode;
  percentage?: string;
}
