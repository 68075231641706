import { useTheme } from '@emotion/react';
import _ from 'lodash';
import React, { useState } from 'react';
import {
  FlexibleWidthXYPlot,
  HorizontalGridLines,
  XAxis,
  YAxis,
  ChartLabel,
  VerticalBarSeries,
} from 'react-vis';

import {
  onValueChange,
  onValueReset,
  DateBigNumber,
  BigText,
} from '@analytics-components/charts/Tooltip';
import { dateTime } from '@common-services/dateService';
import { hexToRGBA } from '@utils/colors';

const PositiveNegativeOverlappedBarChart = ({ data, params = {} }) => {
  const theme = useTheme();
  const [currentHover, setCurrentHover] = useState(null);

  const axisMapping = _(data)
    .map((d, i) => [i, d.x])
    .fromPairs()
    .value();
  const seriesProps = {
    colorType: 'literal',
    barWidth: 0.5,
    sameTypeIndex: 0.5,
    onValueMouseOver: (datapoint) => onValueChange(datapoint, currentHover, setCurrentHover),
    onValueMouseOut: () => onValueReset(setCurrentHover),
  };

  const ChartTooltip = params?.tooltips?.template || DateBigNumber;
  const xAxisFormatter = params?.xAxis?.tickFormat || dateTime.monthDay;
  return (
    <div style={{ background: 'white' }}>
      <FlexibleWidthXYPlot height={350} margin={{ bottom: 50, left: 60 }}>
        <HorizontalGridLines />
        <YAxis hideLine={true} tickSize={0} tickFormat={(v) => Math.abs(v)} />
        {params.yAxis && (
          <ChartLabel
            text={params.yAxis.text}
            includeMargin={false}
            xPercent={0.0}
            yPercent={0.5}
            style={{
              textAnchor: 'middle',
              transform: 'rotate(-90)',
              x: -25,
              y: -40,
            }}
          />
        )}
        <XAxis
          hideLine={true}
          tickTotal={data.length}
          tickSize={0}
          tickPadding={20}
          tickLabelAngle={-45}
          tickFormat={(v) => xAxisFormatter(axisMapping[v])}
        />
        <VerticalBarSeries
          data={data.map((v, i) => ({ x: i, y: v.y.bad }))}
          color={hexToRGBA(theme.color.ui.red[100], 0.7)}
          {...seriesProps}
        />
        <VerticalBarSeries
          data={data.map((v, i) => ({ x: i, y: -v.y.good }))}
          color={theme.color.ui.lightblue[100]}
          {...seriesProps}
        />

        {params.tooltips && (
          <ChartTooltip
            align={currentHover && params.tooltips.align[currentHover.y > 0 ? 'good' : 'bad']}
            value={currentHover}
            data={data}
            dataPoint={currentHover ? { x: axisMapping[currentHover.x], y: currentHover.y } : null}
            renderBigFn={(v) => {
              const value = Math.abs(v.y);
              const circleRadius = 5;
              return (
                <BigText
                  content={value}
                  icon={
                    <svg
                      style={{
                        display: 'inline-block',
                        width: `${circleRadius * 2}px`,
                        height: `${circleRadius * 2}px`,
                        marginRight: `${circleRadius}px`,
                        marginBottom: `${circleRadius}px`,
                      }}
                    >
                      <circle
                        cx={circleRadius}
                        cy={circleRadius}
                        r={circleRadius}
                        fill={
                          v.y > 0
                            ? hexToRGBA(theme.color.ui.red[100], 0.7)
                            : theme.color.ui.lightblue[100]
                        }
                      />
                    </svg>
                  }
                  encloseExtra={false}
                  extra={
                    params.tooltips.extra
                      ? v.y > 0
                        ? params.tooltips.extra.bad
                        : params.tooltips.extra.good
                      : null
                  }
                />
              );
            }}
            {...params.tooltips.extra}
          />
        )}
      </FlexibleWidthXYPlot>
    </div>
  );
};

export default PositiveNegativeOverlappedBarChart;
