import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { CONFIG } from '@align-constants';
import { useTeamsPath } from '@align-hooks/useTeams';
import { Dashboard } from '@align-pages/dashboard';
import { EditMetric } from '@align-pages/dashboard/components/edit-metric';
import { NewMetric } from '@align-pages/dashboard/components/new-metric';
import { GoalsList } from '@align-pages/goals/list';
import { GoalsSingleEdit } from '@align-pages/goals/single/pages/goals-single-edit';
import { GoalsSingleView } from '@align-pages/goals/single/pages/goals-single-view';
import { TeamsList } from '@align-pages/teams/list';
import { useAlignStore } from '@align-store/index';
import { useAuth0 } from '@common-context/Auth0';
import { useDemoCookie } from '@common-pages/Demo';
import {
  DASHBOARD_ROUTES_CONSTANTS,
  GOALS_ROUTES_CONSTANTS,
  TEAMS_ROUTES_CONSTANTS,
} from '@routes/align/routesConstants';
import { useDefaultTab } from '@routes/align/useDefaultTab';

export type ITabsRouteVars = {
  path: string | undefined;
  tab: string | undefined;
  dashboardId: string | undefined;
};

export const TabsModule: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  const { isDemo } = useDemoCookie();
  const { isAlignTab, redirectedPath } = useDefaultTab();
  const [preservedTeamsPath, onSetTeamsPath] = useAlignStore((state) => [
    state.teamsPath,
    state.onSetTeamsPath,
  ]);
  const teamsPath = useTeamsPath();

  // preserve current team in the store for navigating to that team's page from Analytics
  useEffect(() => {
    if (preservedTeamsPath !== teamsPath) {
      onSetTeamsPath(teamsPath);
    }
  }, [preservedTeamsPath, teamsPath]);

  return (
    <Switch>
      {!isAuthenticated && !isDemo && <Redirect to={'/login'} />}
      <Route path={CONFIG.APP_ROUTES.DASHBOARD + DASHBOARD_ROUTES_CONSTANTS.NEW_METRIC}>
        <NewMetric />
      </Route>
      <Route path={CONFIG.APP_ROUTES.DASHBOARD + DASHBOARD_ROUTES_CONSTANTS.EDIT_METRIC}>
        <EditMetric />
      </Route>
      <Route path={CONFIG.APP_ROUTES.GOALS + GOALS_ROUTES_CONSTANTS.SINGLE_EDIT}>
        <GoalsSingleEdit />
      </Route>
      <Route path={CONFIG.APP_ROUTES.GOALS + GOALS_ROUTES_CONSTANTS.SINGLE_VIEW}>
        <GoalsSingleView />
      </Route>
      <Route path={CONFIG.APP_ROUTES.GOALS + GOALS_ROUTES_CONSTANTS.ADD}>
        <GoalsSingleEdit />
      </Route>
      {!isAlignTab && <Redirect to={redirectedPath} />}
      <Route path={CONFIG.APP_ROUTES.DASHBOARD + DASHBOARD_ROUTES_CONSTANTS.LIST}>
        <Dashboard />
      </Route>
      <Route path={CONFIG.APP_ROUTES.GOALS + GOALS_ROUTES_CONSTANTS.LIST}>
        <GoalsList />
      </Route>
      <Route path={CONFIG.APP_ROUTES.TEAMS + TEAMS_ROUTES_CONSTANTS.LIST}>
        <TeamsList />
      </Route>
    </Switch>
  );
};
