import { IBreakdownOptionItem } from '@align-pages/dashboard/reducers/breakdownOptionsReducer.types';
import { ChartParamsType, INewChartParam } from '@align-pages/dashboard/types';
import {
  DashboardChartFilters,
  DashboardChartGroupBy,
} from '@common-services/api/private/generated-from-backend/models';
import { isEqual } from '@common-services/vendor/lodash';
import { FilterType } from '@lib/filter-panel';

export type IParseProperties = {
  [key in ChartParamsType]: INewChartParam[];
};

export const parseProperties = (properties: INewChartParam[]): IParseProperties =>
  properties.reduce(
    (a, c) => (a[c.type] ? { ...a, [c.type]: [...a[c.type], c] } : { ...a, [c.type]: [c] }),
    { filters: [], breakdowns: [] }
  );

export const propertiesToFilters = (properties: INewChartParam[]): DashboardChartFilters =>
  properties.reduce((a: DashboardChartFilters, property) => {
    if (property.type !== ChartParamsType.FILTERS || !property.property) {
      return a;
    }

    const typeToSpecMap = {
      [FilterType.JIRA_ISSUE_TYPES]: 'issue_types',
      [FilterType.JIRA_PROJECTS]: 'projects',
      [FilterType.JIRA_PRIORITIES]: 'priorities',
      [FilterType.JIRA_LABELS]: 'labels_include',
    };

    const jiraFilterName = typeToSpecMap[property.property];

    if (jiraFilterName) {
      return {
        ...a,
        jira: {
          ...a.jira,
          [jiraFilterName]: property.rules || [],
        },
      };
    }

    return {
      ...a,
      [property.property]: property.rules || [],
    };
  }, {});

export const breakdownToGroupBy = (
  breakdownOptions: IBreakdownOptionItem[],
  type: FilterType
): DashboardChartGroupBy => {
  if (!breakdownOptions?.length) {
    return {};
  }

  switch (type) {
    case FilterType.TEAMS:
      return {
        teams: breakdownOptions
          .filter(({ selected }) => selected)
          .map(({ value }) => (value ? parseInt(value) : null)),
      };
    default:
      return {
        [type]: breakdownOptions.filter(({ selected }) => selected).map(({ value }) => value),
      };
  }
};

export function filtersToProperties<T>(props: T, type: ChartParamsType): INewChartParam[] {
  const resultArr = [];
  props &&
    Object.keys(props).forEach((filterName) => {
      if (filterName === 'jira') {
        Object.keys(props[filterName]).forEach((jiraFilterName) => {
          // Convert backend name of `labels_include` to `labels` which is internal name.
          // This is because filter and breakdown names are not matching for jira_labels/labels_include
          const jiraFilterNameMap = jiraFilterName === 'labels_include' ? 'labels' : jiraFilterName;
          resultArr.push({
            id: `${type}/jira_${jiraFilterNameMap}`,
            property: `jira_${jiraFilterNameMap}`,
            rules: props[filterName][jiraFilterName],
            type,
          });
        });
      } else {
        resultArr.push({
          id: `${type}/${filterName}`,
          property: filterName,
          rules: props[filterName],
          type,
        });
      }
    });

  return resultArr;
}

const sortPropertiesValues = (properties: INewChartParam[]): INewChartParam[] =>
  properties.map((prop) => ({
    ...prop,
    rules: prop.rules?.sort() || [],
  }));

export function arePropertiesEqual<T>(
  chartFilters: T,
  properties: INewChartParam[],
  type: ChartParamsType
): boolean {
  if (!chartFilters && !properties?.length) return true;
  const serializedFilters = filtersToProperties<T>(chartFilters, type);
  return isEqual(sortPropertiesValues(properties), sortPropertiesValues(serializedFilters));
}

export const propertiesToBreakdown = (properties: INewChartParam[]): FilterType =>
  properties.find(({ type }) => type === ChartParamsType.BREAKDOWNS)?.property;

export * as DashboardPropertyServices from './dashboardPropertyServices';
