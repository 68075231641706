import React, { useCallback } from 'react';

import IconButton from '@lib/IconButton';
import { icons } from '@lib/icon';

import { FILTER_TYPE_OPTIONS } from './filterPanel.constants';
import { wrapperStyles } from './filterPanel.styles';
import { IFilterOnChange, IFilterProps } from './filterPanel.types';

export const FilterPanel: React.FC<IFilterProps> = React.memo(
  ({ filterName, onRemove, onFiltersChange, chartParamType }) => {
    const onApplyHandler = useCallback<IFilterOnChange>(
      (update) => {
        onFiltersChange && onFiltersChange({ filterName, update });
      },
      [filterName]
    );

    return (
      <div css={wrapperStyles}>
        {FILTER_TYPE_OPTIONS[filterName].component({
          onChange: onApplyHandler,
          chartParamType,
        })}
        {onRemove && (
          <IconButton icon={icons.cross} iconSize={8} onClick={() => onRemove(filterName)} />
        )}
      </div>
    );
  }
);
