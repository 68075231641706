// @flow
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { backdropStyles, helpPanelContentStyles, helpPanelStyles } from './components/styles';

export * from './sections';
/**
 * Help
 * Help Panels used with HelpContext
 */
const Help = ({ children, onClick }) => {
  const [isClosing, setClosing] = useState<boolean>(false);
  const contentRef = useRef(null);

  useEffect(() => {
    const removeView = (e) => {
      if (e.animationName === 'closing') onClick();
    };
    const backgrop = contentRef.current;
    backgrop.addEventListener('animationend', removeView);
    return () => {
      backgrop.removeEventListener('animationend', removeView);
    };
  }, [onClick]);

  return (
    <div css={backdropStyles} className="backdrop" onClick={() => setClosing(true)}>
      <div
        css={helpPanelStyles}
        className={classnames({ 'help-closing': isClosing })}
        onClick={(e) => e.stopPropagation()}
        ref={contentRef}
      >
        <div css={helpPanelContentStyles}>{children}</div>
      </div>
    </div>
  );
};

export default Help;
