import { UseMutateFunction } from 'react-query';

import { IApiBasicError } from '@common-services/api/common/types/common';

export interface LooseObject {
  [key: string]: any;
}

export interface IMutationBasic {
  accountId: number;
}

interface IUseQueryHookBase<T extends unknown> {
  isLoading: boolean;
  data: T | undefined;
  refetch?: () => void;
}

export interface IUseQueryHookRest<T extends unknown> extends IUseQueryHookBase<T> {
  error: IApiBasicError | null;
}

export interface IUseMutationHook<D extends unknown, T extends object, V extends object> {
  mutate: UseMutateFunction<D, T, V>;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  data: D | undefined;
  error: T;
}

export enum PrsIssuesTableTabs {
  COMPLETED = 0,
  IN_PROGRESS = 1,
}

export interface IDateRangeInMls {
  dateFrom: number;
  dateTo: number;
}
