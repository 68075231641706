import _ from 'lodash';
import React from 'react';

import { calculateGranularity } from '@analytics-components/Prefetcher';
import { DateRangeBigNumber, DateBigNumber, BigText } from '@analytics-components/charts/Tooltip';
import VerticalBarChart from '@analytics-components/charts/VerticalBarChart';
import { fetchJIRAMetrics } from '@analytics-services/api/jira';
import { FAKE_USERNAME } from '@common-pages/Settings/constants';
import { dateTime } from '@common-services/dateService';
import { theme } from '@styles/theme';
import { extend } from '@utils/colors';

const conf = {
  fetcher: async (api, cachedData, apiContext) => {
    const {
      account,
      interval,
      excludeInactive,
      contributors,
      includeNullContributor,
      includeFakeContributor,
      epics,
    } = apiContext;
    const filteredJIRAStuff = cachedData['jira-labels'];
    const jiraAssignees = _(contributors).map('jira_user').filter().value();
    if (includeNullContributor) {
      jiraAssignees.push(null);
    }
    if (includeFakeContributor) {
      jiraAssignees.push(FAKE_USERNAME);
    }

    const labels = _(filteredJIRAStuff.labels)
      .filter((l) => l.kind === 'regular')
      .map('title')
      .value();

    const tickets = await fetchJIRAMetrics(
      api,
      account,
      ['all', { label: 'auto', min: 'week' }],
      interval,
      ['resolved'],
      excludeInactive,
      {
        labels,
        epics,
      },
      { assignees: jiraAssignees },
      true
    );

    return {
      tickets,
      labels,
    };
  },
  plumber: (fetchedData, cachedData, apiContext) => {
    const topLabels = _(fetchedData.tickets)
      .filter((t) => t.granularity === 'all')
      .map((v) => ({ label: v.jira_label, value: v.values[0].values[0] }))
      .sortBy(['value', 'label'])
      .reverse()
      .map('label')
      .value()
      .slice(0, 4);

    const tickets = _(fetchedData.tickets)
      .filter((t) => t.granularity !== 'all' && _(topLabels).includes(t.jira_label))
      .map((t) => ({ ...t, index: _(topLabels).findIndex((l) => l === t.jira_label) }))
      .sortBy('index')
      .map((t) =>
        _(t.values)
          .map((v) => ({ label: t.jira_label, value: v.values[0], date: v.date }))
          .value()
      )
      .value();

    const colors = extend(theme.color.sets.six, tickets.length);
    const chartData = _(tickets)
      .map((v, i) =>
        _(v)
          .map((d) => ({
            ...d,
            color: colors[i],
          }))
          .value()
      )
      .value();

    const customGranularity = calculateGranularity(apiContext.interval);
    const tickFormat = customGranularity === 'month' ? dateTime.month : dateTime.monthDay;
    const tooltip = {
      align: {
        horizontal: 'auto',
        vertical: 'top',
      },
      template: DateBigNumber,
      left: false,
      renderBigFn: (v) => {
        return <BigText encloseExtra={false} content={`${v.y} tickets`} label={v.label} />;
      },
    };
    if (_(['month', 'week']).includes(customGranularity)) {
      tooltip.template = DateRangeBigNumber;
      tooltip.interval = { ...apiContext.interval };
      tooltip.granularity = customGranularity;
    }

    return {
      empty: chartData.length === 0,
      chart: {
        component: VerticalBarChart,
        params: {
          data: chartData,
          extra: {
            clustered: true,
            height: 350,
            color: colors,
            barWidth: 0.7,
            axisKeys: {
              x: 'date',
              y: 'value',
            },
            axisLabels: {
              y: 'Tickets Resolved',
            },
            axisFormat: {
              tickSize: {
                x: 0,
              },
              tickPadding: {
                x: 20,
              },
              tickFormat: {
                x: tickFormat,
              },
            },
            tooltip,
          },
          timeMode: true,
        },
      },
      kpis: [],
    };
  },
  prefetchedDataIds: ['jira-labels'],
};

export default conf;
