import React, { useMemo } from 'react';

import { DateService } from '@align-services/dateService';
import { Tabs } from '@lib/Tabs';
import { AvatarType } from '@lib/avatar';
import { ChartTooltipService } from '@lib/chart';
import { TagLabel } from '@lib/tag-label';

import { wrapperStyles } from './prsIssuesTableHeader.styles';
import { IPrsIssuesTableHeaderProps } from './prsIssuesTableHeader.types';

const PrsIssuesTableHeader: React.FC<IPrsIssuesTableHeaderProps> = React.memo(
  ({
    activeTab,
    customValue,
    dateFrom,
    dateTo,
    isJiraMetric,
    selectedRange,
    tabs,
    onChangeTab,
    onRemoveTimeframe,
  }) => {
    const hintSentence = useMemo(
      () =>
        `Click a data point on the chart to show the corresponding ${
          isJiraMetric ? 'issues' : 'pull requests'
        }`,
      [isJiraMetric]
    );

    const timeFrame = useMemo(() => {
      if (selectedRange) {
        return ChartTooltipService.formatRange(selectedRange.dateFrom, selectedRange.dateTo);
      }
      if (customValue) return customValue;
      return DateService.getCurrentRange({ dateFrom, dateTo });
    }, [customValue, dateFrom, dateTo, selectedRange]);

    return (
      <div css={wrapperStyles}>
        <Tabs initialActive={activeTab} tabs={tabs} onTabChange={onChangeTab} />
        <div className="hint-sentence">{hintSentence}</div>
        <div className="timeframe">
          <div className="timeframe-label">Timeframe:</div>
          <TagLabel
            avatarType={AvatarType.none}
            label={timeFrame}
            onRemove={selectedRange && onRemoveTimeframe}
          />
        </div>
      </div>
    );
  }
);

export { PrsIssuesTableHeader };
