import { css } from '@emotion/react';

export const wrapperStyles = ({ color, radius }) => css`
  border: 1px solid ${color.neutral[40]};
  border-radius: ${radius.default};
`;

export const itemStyles = ({ color, spacing }) => css`
  align-items: center;
  border-bottom: 1px solid ${color.neutral[40]};
  display: flex;
  justify-content: space-between;
  padding: ${spacing.gap['03']} ${spacing.gap['04']} ${spacing.gap['03']}
    calc(${spacing.gap['09']} + ${spacing.gap['02']});

  &:last-of-type {
    border-bottom: none;
  }
`;

export const nameStyles = ({ color, font, spacing }) => css`
  display: flex;

  .picture {
    border-radius: 50%;
    margin-right: ${spacing.gap['03']};
  }

  .full-name {
    color: ${color.text.dark};
    font-size: ${font.size.default};
    font-weight: ${font.weight.regular};
    margin-right: ${spacing.gap['03']};
  }

  .github-name {
    color: ${color.text.secondary};
    font-size: ${font.size.default};
    font-weight: ${font.weight.regular};
  }
`;

export const modalContentStyles = ({ color, font }) => css`
  color: ${color.text.secondary};
  font-size: ${font.size.default};
  font-weight: ${font.weight.regular};

  .removed-user-name {
    color: ${color.text.dark};
  }
`;

export const controlsStyles = ({ spacing }) => css`
  align-items: center;
  display: flex;

  .close-icon {
    margin-left: ${spacing.gap['04']};
  }
`;
