import { css } from '@emotion/react';

export const cardWrapperStyles = ({ spacing }) => css`
  margin: 0 auto;
  padding: ${spacing.gap['06']};
  width: 1240px;
`;

export const spinnerWrapper = css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const goalContainerStyles = (isLoading: boolean, isEmptyData: boolean) => css`
  min-height: 350px;
  ${isEmptyData && `min-height: auto;`}
  ${isLoading && `display: flex;`}
`;
