import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';

import { ActionButtons } from '@align-components/layouts/primary/actionButtons';
import { AvatarName } from '@align-components/layouts/primary/avatarName';
import { PrimaryLayoutContext, useNavigation } from '@align-components/layouts/primary/context';
import { DateRangeInfo } from '@align-components/layouts/primary/dateRangeInfo';
import { DateRangeSelector } from '@align-components/layouts/primary/dateRangeSelector';
import { RemoveModal } from '@align-components/remove-modal';
import { metricsConfig } from '@align-constants';
import { useActionMenu } from '@align-hooks/useActionMenu';
import { useGoalRemoveModal } from '@align-hooks/useGoalRemoveModal';
import {
  GoalsSingleComponentServices,
  IGoalSingleParams,
  useCurGoalData,
  useTeamsData,
} from '@align-pages/goals/single/components/goals-single-component';
import {
  IMetricDescriptionTextMode,
  MetricDescriptionText,
} from '@align-pages/goals/single/components/metric-description/components/metric-description-text';
import { FilterService } from '@align-services/filterService';
import { MetricService } from '@align-services/metricService';
import { useHistory } from '@common-hooks/useHistory';
import { useOwnedGoalTemplates } from '@common-services/api/public/hooks/useGoalTemplatesData';
import { getTeamColor } from '@common-services/colorService';
import { IBadgeSize, IBadgeType } from '@common-types/common';
import { BREADCRUMBS_MEMBERS } from '@e2e/core/testIds/align/appearance';
import { ActionMenu } from '@lib/ActionMenu';
import { Breadcrumbs } from '@lib/Breadcrumbs';
import { TeamBadge } from '@lib/TeamBadge';
import { EditableName } from '@lib/editable-name';
import { FilterBadge } from '@lib/filter-badge';
import { MetricBadge } from '@lib/metric-badge';

import { PrimaryLayoutModes } from '..';
import { FilterDetail } from './filter-detail';
import { useAppliedFilters } from './filter-detail/filterDetail.hooks';
import {
  avatarDateWrapper,
  breadcrumbsWrapperStyles,
  createEditWrapperStyles,
  wrapperStyles,
} from './teamHeader.styles';

export const TeamHeader: React.FC = React.memo(() => {
  const history = useHistory();
  const { currentTeam, breadcrumbs } = useNavigation();
  const { mode, selectedMetric, onNewGoalNameChange } = useContext(PrimaryLayoutContext);
  const { path, goalId } = useParams<IGoalSingleParams>();
  const { url } = useRouteMatch();
  const teamsData = useTeamsData(path);
  const [curGoalData] = useCurGoalData(goalId, teamsData);
  const [isAppliedFiltersOpened, setIsAppliedFiltersOpened] = useState(false);
  const { data: goalTemplatesData } = useOwnedGoalTemplates(true);

  const { isModalOpen, removeGoal, openModal, closeModal } = useGoalRemoveModal(
    goalId,
    currentTeam?.id
  );
  const { shouldCloseMenu, setShouldCloseMenu } = useActionMenu();

  const metricTitle = useMemo(
    () => goalTemplatesData?.find(({ metric }) => metric === selectedMetric)?.name,
    [goalTemplatesData, selectedMetric]
  );

  const teamColor = useMemo(() => getTeamColor(currentTeam?.name), [currentTeam]);
  const isMetricAvailable = useMemo(
    () => (selectedMetric && metricsConfig[selectedMetric] ? true : false),
    [selectedMetric]
  );
  const appliedFiltersCounter = useMemo(() => {
    if (curGoalData) {
      return FilterService.countAppliedGoalFilters(curGoalData);
    } else {
      return 0;
    }
  }, [curGoalData]);

  const editableNameDefault = useMemo(
    () =>
      curGoalData?.name ? curGoalData.name : isMetricAvailable ? metricTitle : 'Untitled Goal',
    [isMetricAvailable, metricTitle, curGoalData]
  );

  const thresholdReadable = useMemo(() => {
    const metricThreshold = curGoalData?.metric_params?.threshold;
    return metricThreshold && selectedMetric
      ? MetricService.getReadableValue(
          GoalsSingleComponentServices.deNormaliseMetricValue(metricThreshold),
          metricsConfig[selectedMetric].valueType,
          metricsConfig[selectedMetric].unit
        )
      : null;
  }, [curGoalData, selectedMetric]);

  const filterDetailData = useAppliedFilters(curGoalData);

  const filterBadgeClickCallback = useCallback(() => {
    setIsAppliedFiltersOpened(!isAppliedFiltersOpened);
  }, [isAppliedFiltersOpened]);

  const menuActions = useMemo(
    () => [
      {
        name: 'Edit',
        onClick: () => history.pushWithBackState(`${url.replace('/view', '/edit')}`),
      },
      {
        name: 'Remove',
        onClick: () => {
          openModal();
          setShouldCloseMenu(true);
        },
      },
    ],
    [history, url]
  );

  return (
    <div css={wrapperStyles} data-pw={BREADCRUMBS_MEMBERS.BLOCK}>
      <div css={breadcrumbsWrapperStyles}>
        <Breadcrumbs steps={breadcrumbs} />
        {mode === PrimaryLayoutModes.VIEW && (
          <ActionMenu actions={menuActions} shouldClose={shouldCloseMenu} />
        )}
      </div>
      {!mode && (
        <TeamBadge
          color={teamColor}
          teamName={currentTeam.name}
          teams={currentTeam.children?.length}
          members={currentTeam.total_members_count}
        />
      )}
      {mode && (
        <EditableName
          isEditable={mode === PrimaryLayoutModes.CREATE}
          onNameChange={onNewGoalNameChange}
          defaultValue={editableNameDefault}
        />
      )}
      {mode === PrimaryLayoutModes.EDIT && thresholdReadable && (
        <MetricDescriptionText
          metricValue={selectedMetric}
          threshold={thresholdReadable}
          mode={IMetricDescriptionTextMode.VIEW}
        />
      )}
      {mode && (
        <div css={createEditWrapperStyles}>
          <div css={avatarDateWrapper}>
            <AvatarName name={currentTeam.name} />
            {mode === PrimaryLayoutModes.CREATE && <DateRangeSelector />}
            {mode !== PrimaryLayoutModes.CREATE && (
              <>
                <DateRangeInfo goal={curGoalData} />
                {isMetricAvailable && (
                  <MetricBadge metric={metricsConfig[selectedMetric]} type={IBadgeType.GHOST} />
                )}
                {appliedFiltersCounter > 0 && (
                  <FilterBadge
                    counter={appliedFiltersCounter}
                    isOpened={isAppliedFiltersOpened}
                    onClick={filterBadgeClickCallback}
                    size={IBadgeSize.XSMALL}
                  />
                )}
              </>
            )}
          </div>
          <ActionButtons />
        </div>
      )}
      <FilterDetail data={filterDetailData} isOpen={isAppliedFiltersOpened} />
      <RemoveModal isOpen={isModalOpen} onRemove={removeGoal} onCancel={closeModal} />
    </div>
  );
});
