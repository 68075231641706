import _ from 'lodash';

import { fetchJIRAMetrics } from '@analytics-services/api/jira';
import { FAKE_USERNAME } from '@common-pages/Settings/constants';
import { dateTime } from '@common-services/dateService';
import { theme } from '@styles/theme';
import { ProgressBarMulti } from '@ui/ProgressBar';

const conf = {
  fetcher: async (api, cachedData, apiContext) => {
    const {
      account,
      interval,
      excludeInactive,
      contributors,
      includeNullContributor,
      includeFakeContributor,
      epics,
    } = apiContext;
    const filteredJIRAStuff = cachedData['jira-priorities-all'];
    const priorities = _(filteredJIRAStuff.priorities).map('name').value();
    const jiraAssignees = _(contributors).map('jira_user').filter().value();
    if (includeNullContributor) {
      jiraAssignees.push(null);
    }
    if (includeFakeContributor) {
      jiraAssignees.push(FAKE_USERNAME);
    }

    const fetch = async (priority, index) => {
      const mttr = await fetchJIRAMetrics(
        api,
        account,
        ['all'],
        interval,
        ['resolved', 'life-time'],
        excludeInactive,
        {
          types: ['Bug'],
          priorities: [priority],
          epics,
        },
        { assignees: jiraAssignees }
      );
      return {
        label: priority,
        count: mttr[0].values[0].values[0],
        time: mttr[0].values[0].values[1],
        color: theme.color.sets.bugs[index],
      };
    };

    const mttrByPriority = await Promise.all(
      _(priorities)
        .map((p, index) => fetch(p, index))
        .value()
    );
    return { mttrByPriority };
  },
  plumber: (fetchedData, cachedData, apiContext) => {
    const mttrByPriority = fetchedData.mttrByPriority;
    const params = _(mttrByPriority)
      .filter((v) => v.time)
      .map((v, i) => ({
        value: v.time,
        color: v.color,
        labelLeft: `${v.label} (${v.count})`,
        labelRight: v.time > 0 ? dateTime.human(v.time * 1000) : '',
      }))
      .value();

    return {
      empty: mttrByPriority.length === 0,
      chart: {
        component: ProgressBarMulti,
        params: {
          params,
          normalize: true,
        },
      },
      kpis: [],
    };
  },
  prefetchedDataIds: ['jira-priorities-all'],
};

export default conf;
