import React, { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { IDropdownOptions } from '@lib/Dropdown';
import { Input } from '@lib/Input';
import { Avatar, AvatarType } from '@lib/avatar';

import { wrapperStyles, searchStyles } from './DropdownOptions.styles';

export const DropdownOptions = React.forwardRef<HTMLDivElement, IDropdownOptions>(
  ({ width, options, onClick, withAvatar, withSearch, placeholder }, ref) => {
    const [inputValue, setInputValue] = useState('');

    const handleOnChange = useCallback((event) => {
      setInputValue(event);
    }, []);
    return (
      <div css={wrapperStyles({ width, withSearch })} ref={ref}>
        {withSearch && (
          <div css={searchStyles}>
            <Input withSearchIcon placeholder={placeholder} onChange={handleOnChange} />
          </div>
        )}
        {options.map(
          (option) =>
            option.name.toLowerCase().includes(inputValue.toLowerCase()) && (
              <div key={uuidv4()} onClick={onClick(option)} className="ath-dropdown-option">
                {withAvatar && option.name && (
                  <div className="option-avatar">
                    <Avatar label={option?.name} type={AvatarType.text} color={option.color} />
                  </div>
                )}
                {option.name}
              </div>
            )
        )}
      </div>
    );
  }
);
