import { css } from '@emotion/react';

export const filterDropdownOptionStyles = ({ color, font, radius, spacing }) => css`
  align-items: center;
  display: flex;

  .filter-dropdown-option-name {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: keep-all;
    white-space: nowrap;
  }

  .filter-dropdown-option-user {
    color: ${color.text.secondary};
    font-weight: ${font.weight.light};
    font-size: ${font.size.xs};
    line-height: 2.1rem;
    margin-left: ${spacing.gap['02']};
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: keep-all;
    white-space: nowrap;
  }
`;

export const filterDropdownImgStyles = ({ color, font, radius, spacing, shadow }) => css`
  border-radius: ${radius.circle};
  border: 2px solid ${color.neutral.white};
  box-shadow: ${shadow.full};
  color: ${color.neutral.white};
  font-size: ${font.size.xxs};
  height: 22px;
  line-height: 18px;
  margin-right: ${spacing.gap['02']};
  text-align: center;
  width: 22px;
`;
