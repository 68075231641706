import { css } from '@emotion/react';

export const boxHeaderStyles = ({ color, spacing, radius }) => css`
  align-items: center;
  background-color: ${color.neutral.white};
  border-bottom: 1px solid ${color.neutral[40]};
  display: flex;
  justify-content: space-between;
  line-height: 2rem;
  margin-bottom: ${spacing.gap['0']};
  padding: ${spacing.gap['06']};

  &:first-of-type {
    border-radius: ${radius.default} ${radius.default} ${radius.sharp} ${radius.sharp};
  }
`;

export const cardBodyStyles = ({ spacing }) => css`
  flex: 1 1 auto;
  min-height: 1px;
  padding: ${spacing.gap['08']} ${spacing.gap['06']};
`;

export const chartTitleStyles = ({ spacing }) => css`
  align-items: center;
  display: flex;
  padding: ${spacing.gap['0']} ${spacing.gap['0']} ${spacing.gap['06']} ${spacing.gap['07']};

  > div {
    min-height: 20px;
    margin-left: ${spacing.gap['02']};
  }
`;
