import { RedirectionsAlign } from '@routes/align/redirections';
import { TabsModule } from '@routes/align/tabs';

// TODO add RouteProps[] type declaration after refactoring src/js/routes/index.jsx to react-router-dom > v5
export const alignRoutes = [
  {
    route: {
      path: '/align/:path(.*)/:tab',
      exact: true,
      mainRoute: true,
    },
    component: TabsModule,
  },
  {
    route: {
      path: '/align/',
    },
    component: RedirectionsAlign,
  },
];
