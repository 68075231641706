import { IUseBreakdownOptionsData } from '@align-pages/dashboard/hooks/types';
import { useDashboardStore } from '@align-pages/dashboard/store';
import { useUserContext } from '@common-context/User';
import { useFlatTeamsTree } from '@common-services/api/private/hooks/useTeamTreeData';
import { useOwnedJiraFilter } from '@common-services/api/public/hooks/useFiltersData';
import { FilterType } from '@lib/filter-panel';

export const useBreakdownOptionsData = (type?: FilterType): IUseBreakdownOptionsData => {
  const flatTeamsData = useFlatTeamsTree();
  const {
    user: {
      defaultReposet: { repos },
    },
  } = useUserContext();
  const { data: fetchedJiraFilters } = useOwnedJiraFilter(
    ['issues', 'priorities', 'labels', 'issue_types'],
    true
  );

  switch (type) {
    case FilterType.TEAMS:
      return {
        teams: flatTeamsData,
      };
    case FilterType.REPOSITORY:
      return {
        repositories: repos,
      };
    case FilterType.JIRA_ISSUE_TYPES:
      return {
        jira_issue_types: fetchedJiraFilters?.issue_types,
      };
    case FilterType.JIRA_PRIORITIES:
      return {
        jira_priorities: fetchedJiraFilters?.priorities,
      };
    case FilterType.JIRA_LABELS:
      return {
        jira_labels: fetchedJiraFilters?.labels.filter(({ kind }) => kind !== 'component'),
      };
    default:
      return {};
  }
};

export const useSelectedBreakdownGroupData = (breakdown: FilterType) => {
  const [breakdownOptions] = useDashboardStore((state) => [state.breakdownOptions]);

  return breakdown && breakdownOptions
    ? {
        [breakdown]: breakdownOptions
          .filter(({ selected }) => selected)
          .map(({ value }) => (breakdown === FilterType.TEAMS ? parseInt(value) : value)),
      }
    : {};
};
