/**
 * {{ title }}
 * Server version: {{ server_version }} built on {{ build_date }} @ [{{ commit }}](https://github.com/athenianco/athenian-api/commit/{{ commit }})  Origin GitHub repository: [athenianco/api-spec](https://github.com/athenianco/api-spec).  Authorization persists between sessions. 🛡️ means that the endpoint requires account admin privileges. 👑 means that the endpoint is only accessible to Athenian authorities. <details> <summary>List of common server errors.</summary> * __500__ endpoint crashed. We reported the incident to Sentry and will fix it soon! Please include the value of `instance` in the response if you contact the support. Repeating the request will not help, most probably. * __501__ some functions are missing, either because they are not implemented yet or a non-critical runtime dependency is not satisfied. Repeating the request will not help, guaranteed. * __502__ server crashed badly, either due to a memory access violation in native code or running out of memory. We reported this incident to Sentry and will fix it soon! Try repeating the request. * __503__ server has not fully launched yet, e.g. hasn't connected to the database; server is shutting down; we are experiencing a partial outage. Try repeating the request. * __504__ endpoint took too much time and was interrupted. We reported the incident to Sentry and will see how to improve the performance. Repeating the request will not help, most probably. </details>
 *
 * Contact: admin@athenian.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The JIRAProject model module.
 * @module model/JIRAProject
 */
class JIRAProject {
    /**
     * Constructs a new <code>JIRAProject</code>.
     * JIRA project setting.
     * @alias module:model/JIRAProject
     * @param name {String} Long name of the project.
     * @param key {String} Short prefix of the project.
     * @param id {String} Internal project identifier that corresponds to `project` in `/filter/jira`. Note: this is a string even though this looks like an integer. That's what JIRA API sends us. 
     * @param avatarUrl {String} Avatar URL of the project.
     * @param enabled {Boolean} Indicates whether this project is enabled for analysis.
     * @param issuesCount {Number} Total number of issues in the project.
     * @param lastUpdate {Date} Timestamp of the last event in the project.
     */
    constructor(name, key, id, avatarUrl, enabled, issuesCount, lastUpdate) { 
        
        JIRAProject.initialize(this, name, key, id, avatarUrl, enabled, issuesCount, lastUpdate);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, name, key, id, avatarUrl, enabled, issuesCount, lastUpdate) { 
        obj['name'] = name;
        obj['key'] = key;
        obj['id'] = id;
        obj['avatar_url'] = avatarUrl;
        obj['enabled'] = enabled;
        obj['issues_count'] = issuesCount;
        obj['last_update'] = lastUpdate;
    }

    /**
     * Constructs a <code>JIRAProject</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/JIRAProject} obj Optional instance to populate.
     * @return {module:model/JIRAProject} The populated <code>JIRAProject</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new JIRAProject();

            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('key')) {
                obj['key'] = ApiClient.convertToType(data['key'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('avatar_url')) {
                obj['avatar_url'] = ApiClient.convertToType(data['avatar_url'], 'String');
            }
            if (data.hasOwnProperty('enabled')) {
                obj['enabled'] = ApiClient.convertToType(data['enabled'], 'Boolean');
            }
            if (data.hasOwnProperty('issues_count')) {
                obj['issues_count'] = ApiClient.convertToType(data['issues_count'], 'Number');
            }
            if (data.hasOwnProperty('last_update')) {
                obj['last_update'] = ApiClient.convertToType(data['last_update'], 'Date');
            }
        }
        return obj;
    }


}

/**
 * Long name of the project.
 * @member {String} name
 */
JIRAProject.prototype['name'] = undefined;

/**
 * Short prefix of the project.
 * @member {String} key
 */
JIRAProject.prototype['key'] = undefined;

/**
 * Internal project identifier that corresponds to `project` in `/filter/jira`. Note: this is a string even though this looks like an integer. That's what JIRA API sends us. 
 * @member {String} id
 */
JIRAProject.prototype['id'] = undefined;

/**
 * Avatar URL of the project.
 * @member {String} avatar_url
 */
JIRAProject.prototype['avatar_url'] = undefined;

/**
 * Indicates whether this project is enabled for analysis.
 * @member {Boolean} enabled
 */
JIRAProject.prototype['enabled'] = undefined;

/**
 * Total number of issues in the project.
 * @member {Number} issues_count
 */
JIRAProject.prototype['issues_count'] = undefined;

/**
 * Timestamp of the last event in the project.
 * @member {Date} last_update
 */
JIRAProject.prototype['last_update'] = undefined;






export default JIRAProject;

