import _ from 'lodash';
import React from 'react';

import TimeSeries from '@analytics-components/charts/TimeSeries';
import { BigText } from '@analytics-components/charts/Tooltip';
import { SimpleKPI } from '@analytics-components/insights/KPI';
import { dateTime } from '@common-services/dateService';
import { number } from '@common-services/format';
import { theme } from '@styles/theme';
import { hexToRGBA } from '@utils/colors';

const runTimePerPR = {
  prefetchedDataIds: ['ci-metrics'],
  plumber: (fetchedData, cachedData, apiContext) => {
    const customGranularity = cachedData['ci-metrics'].customGranularity;
    const granularity = customGranularity === 'month' ? 'custom-aligned' : 'custom';
    const chartData = cachedData['ci-metrics'].values[granularity]['chk-suite-time-per-pr'];

    const kpisData = {
      avgRunTime: parseInt(cachedData['ci-metrics'].values.all['chk-suite-time-per-pr']),
      avgSuiteRuns: cachedData['ci-metrics'].values.all['chk-suites-per-pr'],
    };

    const tickFormat = customGranularity === 'month' ? dateTime.month : dateTime.monthDay;
    const tooltip = {
      align: {
        horizontal: 'auto',
        vertical: 'top',
      },
      renderBigFn: (v) => <BigText content={dateTime.timeValue(v.y)} />,
    };
    if (_(['month', 'week']).includes(customGranularity)) {
      tooltip.dateRange = { ...apiContext.interval };
      tooltip.granularity = customGranularity;
    }
    return {
      empty: !chartData.length || chartData.reduce((acc, v) => acc + v.value, 0) === 0,
      chart: {
        component: TimeSeries,
        params: {
          data: chartData,
          timeMode: false,
          extra: {
            average: {
              value: kpisData.avgRunTime,
              color: theme.color.neutral[80],
              renderFn: tooltip.renderBigFn,
            },
            axisKeys: {
              x: 'date',
              y: 'value',
            },
            color: theme.color.ui.lightblue[100],
            fillColor: hexToRGBA(theme.color.ui.lightblue[100], 0.1),
            maxNumberOfTicks: 10,
            ticks: {
              x: { tickFormat },
              y: { tickFormat: (v) => dateTime.timeValue(v) },
            },
            tooltip,
          },
        },
      },
      kpis: [
        {
          title: { text: 'Average Run Time', bold: true },
          subtitle: { text: 'Per Pull Request' },
          component: SimpleKPI,
          params: {
            value: dateTime.timeValue(kpisData.avgRunTime),
          },
        },
        {
          title: { text: 'Average Suite Runs', bold: true },
          subtitle: { text: 'Per Pull Request' },
          component: SimpleKPI,
          params: {
            value: number.fixed(kpisData.avgSuiteRuns, 2),
          },
        },
      ],
    };
  },
};

export default runTimePerPR;
