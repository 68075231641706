import React, { useCallback } from 'react';

import Modal from '@lib/Modal';

import { modalContentStyles } from './removeModal.styles';
import { IRemoveModal, RemoveEntityType } from './removeModal.types';

const RemoveModal: React.FC<IRemoveModal> = React.memo(
  ({ entityType = RemoveEntityType.GOAL, isOpen, onRemove, onCancel }) => {
    const content =
      entityType === RemoveEntityType.GOAL ? (
        <>
          Are you sure you want to remove this Goal?
          <br />
          You will lose all the associated team targets.
        </>
      ) : (
        <>Are you sure you want to remove this chart?</>
      );

    // inside Modal it's only passing false to this function, so we are just going to call onCancel instead
    const handleSetOpen = useCallback(() => onCancel(), []);

    return (
      <Modal
        content={<div css={modalContentStyles}>{content}</div>}
        isOpen={isOpen}
        portalId="dialog-holder"
        primaryButtonText="Remove"
        secondaryButtonText="Cancel"
        title={`Remove ${entityType}`}
        onPrimaryClick={onRemove}
        onSecondaryClick={onCancel}
        setOpen={handleSetOpen}
      />
    );
  }
);

export { RemoveModal };
