import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import React, { useCallback, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { metricsConfig } from '@align-constants';
import { GoalMetricParams } from '@common-services/api/private/generated-from-backend/models';
import { Button } from '@lib/Button';
import { ButtonHtmlType, ButtonUISize } from '@lib/Button';
import { Dropdown } from '@lib/Dropdown';
import { Input } from '@lib/Input';
import { InputTypes } from '@lib/Input/Input.types';

import { useIsAllowedByLimits } from './thresholdMenu.hooks';
import { thresholdMenuSchema } from './thresholdMenu.schema';
import { wrapperStyles } from './thresholdMenu.styles';
import { IChangeDropdownHandler, IChangeInputHandler, IThresholdMenu } from './thresholdMenu.types';

export const ThresholdMenu: React.FC<IThresholdMenu> = React.memo(
  ({ submitHandler, metricValue, value, valueType, unit }) => {
    const [thresholdValue, setThresholdValue] = useState(value);
    const [thresholdUnit, setThresholdUnit] = useState(unit);
    const {
      register,
      formState: { isValid },
      handleSubmit,
      watch,
      setValue,
    } = useForm<GoalMetricParams>({
      defaultValues: {
        threshold: thresholdValue,
      },
      resolver: yupResolver(thresholdMenuSchema),
      mode: 'all',
    });

    const unitDisplay = useMemo(
      () => metricsConfig[metricValue]?.unit?.[thresholdValue === 1 ? 'singular' : 'plural'] || '',
      [metricValue, thresholdValue]
    );

    const unitDropdownOptions = useMemo(() => {
      return metricsConfig[metricValue].unit
        ? [unitDisplay]
        : ['mins', 'hours', 'days', 'weeks', 'months'];
    }, [metricValue, unitDisplay]);

    const unitDropdownInitial = useMemo(() => {
      return metricsConfig[metricValue].unit ? unitDisplay : thresholdUnit;
    }, [metricValue, unitDisplay, thresholdUnit]);

    const isAllowedByLimits = useIsAllowedByLimits(
      watch('threshold'),
      thresholdUnit,
      valueType,
      metricValue
    );

    const onSubmitHandler: SubmitHandler<GoalMetricParams> = ({ threshold }) => {
      setThresholdValue(threshold);
      submitHandler({
        display: threshold + ' ' + thresholdUnit,
        value: threshold.toString(),
        unit: thresholdUnit,
      });
    };

    const onChangeInputHandler = useCallback<IChangeInputHandler>((val) => {
      setValue('threshold', val, { shouldValidate: true });
    }, []);

    const onChangeDropdownHandler = useCallback<IChangeDropdownHandler>((data) => {
      setThresholdUnit(data.value);
    }, []);

    return (
      <div css={wrapperStyles}>
        <div className="title">Set threshold</div>
        <form onSubmit={handleSubmit(onSubmitHandler)} className="actions">
          <Input
            type={InputTypes.number}
            register={register}
            name={'threshold'}
            width={72}
            step={1}
            initialValue={thresholdValue.toString()}
            onChange={onChangeInputHandler}
          />
          <Dropdown
            width={100}
            options={unitDropdownOptions}
            initialValue={
              unitDropdownInitial === 'min' ? unitDropdownInitial + 's' : unitDropdownInitial
            }
            disabled={metricsConfig[metricValue].unit}
            onChange={onChangeDropdownHandler}
          />
          <Button
            label="Set"
            disabled={!isValid || !isAllowedByLimits}
            size={ButtonUISize.MEDIUM}
            buttonHtmlType={ButtonHtmlType.SUBMIT}
          />
        </form>
      </div>
    );
  }
);
