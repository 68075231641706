import { ActionFuncWithArg, ActionFuncWithoutArg, ActionType } from './types';

export const initOptions: ActionFuncWithArg = (data) => ({
  type: ActionType.INIT_OPTIONS,
  payload: data,
});

export const setDateInterval: ActionFuncWithArg = (interval) => ({
  type: ActionType.SET_INTERVAL,
  payload: interval,
});
export const setExcludeInactive: ActionFuncWithArg = (isInactive) => ({
  type: ActionType.SET_EXCLUDE_INACTIVE,
  payload: isInactive,
});

export const setReposOptions: ActionFuncWithArg = (repos) => ({
  type: ActionType.SET_REPOS_OPTIONS,
  payload: repos,
});
export const setContribsOptions: ActionFuncWithArg = (contribs) => ({
  type: ActionType.SET_CONTRIBS_OPTIONS,
  payload: contribs,
});

export const setAppliedRepos: ActionFuncWithArg = (repos) => ({
  type: ActionType.SET_APPLIED_REPOS,
  payload: repos,
});
export const setAppliedContribs: ActionFuncWithArg = (contribs) => ({
  type: ActionType.SET_APPLIED_CONTRIBS,
  payload: contribs,
});

export const setLabelsOptions: ActionFuncWithArg = (labels) => ({
  type: ActionType.SET_LABELS_OPTIONS,
  payload: labels,
});
export const setAppliedLabels: ActionFuncWithArg = (labels) => ({
  type: ActionType.SET_APPLIED_LABELS,
  payload: labels,
});
export const setEpicsOptions: ActionFuncWithArg = (epics) => ({
  type: ActionType.SET_EPICS_OPTIONS,
  payload: epics,
});
export const setAppliedEpics: ActionFuncWithArg = (epics) => ({
  type: ActionType.SET_APPLIED_EPICS,
  payload: epics,
});
export const setIssueTypesOptions: ActionFuncWithArg = (issueTypes) => ({
  type: ActionType.SET_ISSUE_TYPES_OPTIONS,
  payload: issueTypes,
});
export const setAppliedIssueTypes: ActionFuncWithArg = (issueTypes) => ({
  type: ActionType.SET_APPLIED_ISSUE_TYPES,
  payload: issueTypes,
});

export const setChanging: ActionFuncWithoutArg = () => ({
  type: ActionType.SET_READY,
  payload: false,
});
export const setDone: ActionFuncWithoutArg = () => ({ type: ActionType.SET_READY, payload: true });
