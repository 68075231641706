import React from 'react';

import Badge from '@analytics-components/Badge';
import { SmallTitle } from '@analytics-components/Typography';
import { useBreadcrumb } from '@analytics-context/Breadcrumb';
import DataWidget, { useDataWidget } from '@analytics-context/DataWidget';
import { PullRequests } from '@analytics-pages/Tables/pull-requests';
import { PR_STAGE } from '@common-services/prHelpers';

interface IPrsTableTab {
  isActive: boolean;
}

const PullRequestsTabHeader: React.FC<IPrsTableTab> = React.memo(({ isActive }) => {
  const { subsection: activeStage } = useBreadcrumb();
  const plumber = (fetchedData, cachedData, apiContext) => {
    const badgeCalculators = {
      summary: (metrics) => metrics['all-count'],
      wip: (metrics) => metrics['all-count'],
      review: (metrics) => metrics['all-count'] - metrics['wip-pending-count'],
      merge: (metrics) =>
        metrics['all-count'] - metrics['wip-pending-count'] - metrics['review-pending-count'],
      release: (metrics) =>
        metrics['all-count'] -
        metrics['wip-pending-count'] -
        metrics['review-pending-count'] -
        metrics['merging-pending-count'] -
        metrics['rejected'],
      deploy: (metrics) =>
        metrics['all-count'] -
        metrics['wip-pending-count'] -
        metrics['review-pending-count'] -
        metrics['merging-pending-count'] -
        metrics['release-pending-count'] -
        metrics['rejected'],
    };

    return {
      badgeValue: badgeCalculators[activeStage](cachedData['prs-metrics'].values.all),
    };
  };

  return (
    <DataWidget
      id="pull-requests-tab-header"
      plumber={plumber}
      prefetchedDataIds={['prs-metrics']}
      plumberId={`pull-requests-tab-header-plumber-${activeStage}`}
    >
      <PullRequestsTabHeaderDataBinder isActive={isActive} />
    </DataWidget>
  );
});

const PullRequestsTabHeaderDataBinder: React.FC<IPrsTableTab> = React.memo(({ isActive }) => {
  const { data, isLoading } = useDataWidget();
  const badgeValue = isLoading ? 0 : data.badgeValue;
  return (
    <>
      <SmallTitle content="Pull Requests" isBlack={isActive} />
      <Badge value={badgeValue} className="ml-3" />
    </>
  );
});

const PullRequestsTabContent: React.FC = React.memo(() => {
  const plumber = (fetchedData, cachedData, apiContext): string[] => {
    const envRepositories: Set<string> = new Set();
    cachedData.environments?.forEach((env) => {
      env.repositories?.forEach((repo) => envRepositories.add(repo));
    });
    // get intersection of selected repositories and repositories for which we have deploy data
    const repositories = Array.from(envRepositories).filter((repo) =>
      apiContext.repositories.includes(repo)
    );
    return repositories || [];
  };

  return (
    <DataWidget
      id="pull-requests-tab-content"
      plumber={plumber}
      prefetchedDataIds={['environments']}
    >
      <PullRequestsTabContentDataBinder />
    </DataWidget>
  );
});

const PullRequestsTabContentDataBinder: React.FC = React.memo(() => {
  const { subsection: activeStage } = useBreadcrumb();
  const { data } = useDataWidget();

  return <PullRequests repositories={data} stage={activeStage as PR_STAGE} />;
});

const pullRequestsTab = {
  Header: PullRequestsTabHeader,
  content: <PullRequestsTabContent />,
};

export { pullRequestsTab };
