import _ from 'lodash';
import React from 'react';

import DataWidget from '@analytics-context/DataWidget';
import { useFilters } from '@analytics-context/Filters';
import { dateTime } from '@common-services/dateService';
import { JiraServices, statusColors } from '@common-services/jiraService';
import { icons } from '@lib/icon';

import EpicsTableDataBinder from './EpicsTableDataBinder';

const Epics = () => {
  const { contribsOptions } = useFilters();

  const plumber = async (fetchedData, cachedData, apiContext) => {
    const { interval } = apiContext;
    const { from, to } = interval;

    const isSubTask = (type) => {
      const issueTypes = cachedData['jira-epics'].issue_types;
      const searchedIssueType = issueTypes.find((v) => v.name === type);
      return searchedIssueType?.is_subtask;
    };

    // this function returns data for the ProgressBar
    const getStages = (tasks, project) => {
      const stages = {};
      tasks.forEach((task) => {
        const taskStatusStage = JiraServices.getStatusStage(
          task.status,
          project,
          cachedData['jira-statuses'].statuses
        );
        // exclude To Do status from stages
        if (taskStatusStage !== 'to do') {
          if (stages[taskStatusStage]) {
            stages[taskStatusStage] += 1;
          } else {
            stages[taskStatusStage] = 1;
          }
        }
      });
      return Object.keys(stages)
        .sort((a, b) => (a < b ? -1 : 1)) // first goes To Do, then In Progress and then Done
        .map((status) => ({
          color: statusColors[status],
          value: stages[status],
        }));
    };

    const getUpdated = (task, status) => {
      let from;
      let period;
      // take into consideration the timezone offset that comes from an API field
      let offset;
      switch (status.toLowerCase()) {
        case 'in progress':
          offset = task.work_began.getTimezoneOffset() * 60 * 1000;
          from = new Date(task.work_began.getTime() - offset);
          period = dateTime.interval(from, new Date());
          return {
            content: ['Started', `${period} ago`],
            value: new Date() - from,
          };
        case 'done':
          offset = task.work_began.getTimezoneOffset() * 60 * 1000;
          const resolvedDate = new Date(
            task.work_began.getTime() - offset + parseInt(task.lead_time) * 1000
          );
          period = dateTime.interval(resolvedDate, new Date());
          return {
            content: ['Done', `${period} ago`],
            value: new Date() - resolvedDate,
          };
        default:
          offset = task.created.getTimezoneOffset() * 60 * 1000;
          from = new Date(task.created.getTime() - offset);
          period = dateTime.interval(from, new Date());
          if (task.assignee) {
            return {
              content: ['Waiting', `for ${period}`],
              value: new Date() - from,
            };
          } else {
            return {
              content: ['Unassigned', `for ${period}`],
              value: new Date() - from,
            };
          }
      }
    };

    const priorities = cachedData['jira-priorities'].priorities;

    const epics = _(cachedData['jira-epics'].epics)
      .map((v) => {
        const epicPriority = priorities.find((p) => p.name === v.priority);
        const epicStatusStage = JiraServices.getStatusStage(
          v.status,
          v.project,
          cachedData['jira-statuses'].statuses
        );
        const activeSubtasks = v.children.filter((task) =>
          _.inRange(task.updated.getTime(), from.toDate(), to.toDate())
        );
        let updated;
        try {
          updated = getUpdated(v, epicStatusStage);
        } catch (err) {
          return null;
        }
        return {
          id: v.id,
          issueType: v.type,
          issueTypeImage:
            JiraServices.getIssueTypeImage(v.type, cachedData['jira-issue-types'].issue_types) ||
            icons.jira_task,
          title: v.title,
          priority: v.priority,
          priorityImage: epicPriority?.image,
          priorityRank: epicPriority?.rank,
          reporter: v.reporter,
          assignee: v?.assignee,
          assigneeAvatar: JiraServices.getAvatar(v.assignee, contribsOptions),
          updatedText: updated.content,
          updatedValue: updated.value,
          status: v.status,
          statusColor: statusColors[epicStatusStage],
          statusStage: epicStatusStage,
          comments: v.comments,
          stages: getStages(v.children, v.project),
          leadTimeText: v.lead_time ? dateTime.human(parseInt(v.lead_time) * 1000) : null,
          leadTimeValue: v.lead_time ? parseInt(v.lead_time) : null,
          activeTicketsAmount: activeSubtasks.length,
          hasInactiveTickets: activeSubtasks.length !== v.children.length ? true : false,
          totalSubtasks: v.children.length,
          activeTicketsPrs: _(activeSubtasks).map('prs').sum(),
          totalPrs: _(v.children).map('prs').sum(),
          url: v?.url,
          subRows: _(v.children)
            .map((task) => {
              const taskPriority = priorities.find((p) => p.name === task.priority);
              const taskStatusStage = JiraServices.getStatusStage(
                task.status,
                v.project,
                cachedData['jira-statuses'].statuses
              );
              let updated;
              try {
                updated = getUpdated(task, taskStatusStage);
              } catch (err) {
                return null;
              }
              return {
                id: task.id,
                issueType: task.type,
                issueTypeImage: JiraServices.getIssueTypeImage(
                  task.type,
                  cachedData['jira-issue-types'].issue_types
                ),
                title: task.title,
                age: dateTime.ago(v.created),
                priority: task.priority,
                priorityImage: taskPriority?.image,
                priorityRank: taskPriority?.rank,
                reporter: task.reporter,
                reporterAvatar: JiraServices.getAvatar(task.reporter, contribsOptions),
                assignee: task?.assignee,
                assigneeAvatar: JiraServices.getAvatar(task?.assignee, contribsOptions),
                updatedText: updated.content,
                updatedValue: updated.value,
                status: task.status,
                statusColor: statusColors[taskStatusStage],
                statusStage: taskStatusStage,
                comments: task.comments,
                leadTimeText: task.lead_time
                  ? dateTime.human(parseInt(task.lead_time) * 1000)
                  : null,
                leadTimeValue: task.lead_time ? parseInt(task.lead_time) : null,
                isActive: _.inRange(task.updated.getTime(), from.toDate(), to.toDate()),
                subtasks: task.subtasks,
                prs: task.prs,
                url: task?.url,
              };
            })
            .filter((v) => !!v)
            .value(),
          created: v.created,
        };
      })
      .filter((v) => !!v)
      .value();

    const issuesWithoutEpics = _(cachedData['jira-epics'].issues)
      .filter((v) => !isSubTask(v.type))
      .map((v) => {
        const issuePriority = priorities.find((p) => p.name === v.priority);
        const issueStatusStage = JiraServices.getStatusStage(
          v.status,
          v.project,
          cachedData['jira-statuses'].statuses
        );
        let updated;
        try {
          updated = getUpdated(v, issueStatusStage);
        } catch (err) {
          return null;
        }
        return {
          id: v.id,
          issueType: v.type,
          issueTypeImage: JiraServices.getIssueTypeImage(
            v.type,
            cachedData['jira-issue-types'].issue_types
          ),
          title: v.title,
          age: dateTime.ago(v.created),
          priority: v.priority,
          priorityImage: issuePriority?.image,
          priorityRank: issuePriority?.rank,
          reporter: v.reporter,
          reporterAvatar: JiraServices.getAvatar(v.reporter, contribsOptions),
          assignee: v?.assignee,
          assigneeAvatar: JiraServices.getAvatar(v.assignee, contribsOptions),
          updatedText: updated.content,
          updatedValue: updated.value,
          status: v.status,
          statusColor: statusColors[issueStatusStage],
          statusStage: issueStatusStage,
          comments: v.comments,
          leadTimeText: v.lead_time ? dateTime.human(parseInt(v.lead_time) * 1000) : null,
          leadTimeValue: v.lead_time ? parseInt(v.lead_time) : null,
          subtasks: v.subtasks || 0,
          prs: v.prs?.length || 0,
          url: v?.url,
          created: v.created,
        };
      })
      .filter((v) => !!v)
      .value();

    return {
      epics,
      issuesWithoutEpics,
    };
  };

  return (
    <DataWidget
      id="epics-table"
      plumber={plumber}
      prefetchedDataIds={[
        'jira-epics',
        'jira-identities',
        'jira-priorities',
        'jira-statuses',
        'jira-issue-types',
      ]}
    >
      <EpicsTableDataBinder />
    </DataWidget>
  );
};

export default Epics;
