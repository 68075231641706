import { css } from '@emotion/react';

export const rowStyles = (hasJiraIds) => ({ color, spacing }) => css`
  display: inline-grid;
  justify-items: start;
  align-items: center;
  justify-content: flex-start;
  grid-template-columns: 32.5px 40px 2fr ${hasJiraIds ? '40px 2fr' : ''} 1fr;
  grid-template-rows: 100%;
  width: 100%;
  padding: ${spacing.gap['03']} ${spacing.gap['05']};
  border-bottom: 1px solid ${color.neutral[40]};

  & div:first-of-type {
    grid-column-start: 2;
  }

  & div:last-child {
    justify-self: end;
  }
`;

export const userStyles = ({ spacing }) => css`
  margin-left: ${spacing.gap['04']};
`;
