import { histogram } from 'd3-array';

import DistributionChart from '@analytics-components/charts/DistributionChart';
import { SimpleKPI, UNITS } from '@analytics-components/insights/KPI';
import { palette } from '@analytics-pages/delivery-pipeline/insights/release';
import { DEFAULT_METRICS_QUANTILES, HISTOGRAM_DEFAULT_BINS } from '@analytics-services/api';
import * as NumberService from '@common-services/numberService';

const releaseSizeHistogram = {
  plumber: (fetchedData, cachedData, apiContext) => {
    // TODO: DEPRECATED::GLOBAL_RELEASES_ABUSE
    // This should come from a /histogram/releases::release-prs endpoint when it exists
    const releases = fetchedData.releases.data
      .map((release) => release.prs.length)
      .sort((a, b) => a - b);
    const logHistogramDistribution = releasesDistribution(releases, HISTOGRAM_DEFAULT_BINS);

    // TODO: This should be removed so that the values is not calculated here,
    // but the API do not provide 95th quantile value.
    const quantile = Math.floor(
      logHistogramDistribution.frequencies.reduce((acc, curr) => acc + curr, 0) *
        DEFAULT_METRICS_QUANTILES[1]
    );
    const sizeHistogramData = { ...logHistogramDistribution, quantile };

    return {
      chartData: sizeHistogramData,
      KPIsData: {
        releasesCount: cachedData['releases-metrics']['values']['all']['count'],
        releaseAvgSize: cachedData['releases-metrics']['values']['all']['avg-prs'],
      },
    };
  },
  factory: (computed) => {
    const {
      chartData,
      KPIsData: { releasesCount, releaseAvgSize },
    } = computed;

    return {
      content: [
        {
          empty: chartData?.empty,
          chart: {
            component: DistributionChart,
            title: 'Distribution',
            description:
              'Distribution of releases by size in terms of released pull requests. <p class="small text-secondary mt-1 mb-0">Calculated up to the 95th percentile.</p>',
            params: {
              data: chartData,
              extra: {
                fillColor: palette.barColor,
                maxNumberOfTicks: 6,
                tooltip: {
                  align: {
                    horizontal: 'auto',
                    vertical: 'top',
                  },
                  x: (v) => `${v < 1 ? 0 : NumberService.round(v)} pull requests`,
                },
                axisLabels: {
                  x: 'Pull Requests',
                  y: 'Releases',
                },
                axisFormat: { x: (x) => (x < 1 ? 0 : NumberService.round(x)) },
              },
            },
          },
          kpis: [
            {
              title: { text: 'Total', bold: true },
              component: SimpleKPI,
              params: {
                value: releasesCount,
                unit: UNITS.release,
              },
            },
            {
              title: { text: 'Average release size', bold: true },
              component: SimpleKPI,
              params: {
                value: NumberService.round(releaseAvgSize),
                unit: UNITS.pr,
              },
            },
          ],
        },
      ],
    };
  },
};

// TODO: DEPRECATED::GLOBAL_RELEASES_ABUSE
// This should come from a /histogram/releases::release-prs endpoint when it exists
const releasesDistribution = (releases, bins) => {
  if (!releases.length) return { empty: true, frequencies: [] };

  const ticks = calcLogTicks(releases, bins);
  const bucketCenters = Array.from(new Array(ticks.length - 1)).map(
    (_, i) => (ticks[i] + ticks[i + 1]) / 2
  );

  const histRes = histogram()
    .domain([ticks[0], ticks.slice(-1)[0]])
    .thresholds(ticks.slice(1, -1))(releases);
  const frequencies = histRes.map((v) => v.length || 0);

  return {
    empty: !frequencies.find((v) => v),
    frequencies: frequencies,
    ticks,
    bucketCenters,
  };
};

const calcLogTicks = (values, bins) => {
  const sortedValues = [...values].sort((a, b) => a - b);
  const domain = [sortedValues[0], sortedValues.slice(-1)[0]];
  const logDomain = domain.map((v) => (v < 1 ? 0 : Math.log10(v)));
  const realBins = domain[0] >= 1 ? bins : bins - 1;
  const binWidth = (logDomain[1] - logDomain[0]) / realBins;
  const ticks = Array.from(new Array(realBins + 1)).map((v, i) => {
    return 10 ** (logDomain[0] + i * binWidth);
  });
  ticks[ticks.length - 1] = domain[1];
  return domain[0] === 0 ? [0].concat(...ticks) : ticks;
};

export default releaseSizeHistogram;
