import classnames from 'classnames';
import _ from 'lodash';
import React from 'react';

import Badge from '@analytics-components/Badge';
import { READY } from '@analytics-components/StatusIndicator';
import { getMetrics, getStages } from '@analytics-components/Thumbnails';
import { BigNumber, SmallTitle } from '@analytics-components/Typography';
import { dateTime } from '@common-services/dateService';
import { number } from '@common-services/format';
import { Tooltip } from '@lib/tooltip';
import { slugify } from '@utils/index';

import { overallProportionStyles, summaryMetricStyles } from './styles';

const SummaryBox = ({ title, status, summary, chart, placeholder, wrapperClassName }) => {
  const { text: summaryText, badge: summaryBadge, kpi: summaryKPI } = summary;
  const body =
    status === READY ? (
      <div className="row">
        <div className="col-4">
          <header className="text-lg mt-2">{title}</header>
          <Summary text={summaryText} badge={summaryBadge}>
            {summaryKPI}
          </Summary>
        </div>
        <div className="col-8">{chart}</div>
      </div>
    ) : (
      placeholder[status]
    );

  return (
    <div
      css={summaryMetricStyles}
      className={classnames('card summary-metric mb-4 px-2', wrapperClassName)}
    >
      <div className="card-body" style={{ minHeight: '305px' }}>
        {body}
      </div>
    </div>
  );
};

const Summary = ({ text, badge, children }) => {
  const { value: badgeValue, trend: badgeTrend } = badge;
  return (
    <div className="pl-2">
      <div className="font-weight-bold mt-4 mb-3 pb-2 border-bottom">
        <BigNumber content={text} isXL />
        <Badge value={badgeValue} className="ml-2" trend={badgeTrend} />
      </div>
      <div>{children}</div>
    </div>
  );
};

const SummaryBoxKPIs = ({ data }) => (
  <SummaryHorizontalBarChartKPIs
    bars={_(getStages())
      .map((s) => {
        const stageSlug = slugify(s);
        const { metric } = getMetrics(stageSlug);
        return {
          title: stageSlug,
          tooltip: `${dateTime.human(data.avgTimes[metric])} (${number.percentage(
            data.proportions[metric]
          )})`,
          value: data.normalizedProportions[metric],
          className: stageSlug,
        };
      })
      .value()}
  />
);

const SummaryMatrixKPIs = ({ kpis, numberOfCols }) => {
  if (12 % numberOfCols > 0) {
    return null;
  }

  const colsPerKPI = 12 / numberOfCols;
  const className = `col-md-${colsPerKPI}`;
  return (
    <div className="row">
      {kpis.map((kpi, key) => (
        <div className={`${className} mb-3`} key={key}>
          <div className="mb-2">
            <SmallTitle content={kpi.title} />
          </div>
          <div>
            <BigNumber content={kpi.value} />
          </div>
        </div>
      ))}
    </div>
  );
};

const SummaryHorizontalBarChartKPIs = ({ bars }) => {
  return (
    <>
      {bars.map((bar, i) => (
        <div key={i}>
          <div>
            <SmallTitle content={bar.title} />
          </div>
          <Tooltip
            css={overallProportionStyles}
            content={bar.tooltip}
            style={{ width: `${bar.value}%` }}
            className={classnames(bar.className)}
          />
        </div>
      ))}
    </>
  );
};

export default SummaryBox;
export { SummaryBoxKPIs, SummaryMatrixKPIs, SummaryHorizontalBarChartKPIs };
