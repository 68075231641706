/**
 * {{ title }}
 * Server version: {{ server_version }} built on {{ build_date }} @ [{{ commit }}](https://github.com/athenianco/athenian-api/commit/{{ commit }})  Origin GitHub repository: [athenianco/api-spec](https://github.com/athenianco/api-spec).  Authorization persists between sessions. 🛡️ means that the endpoint requires account admin privileges. 👑 means that the endpoint is only accessible to Athenian authorities. <details> <summary>List of common server errors.</summary> * __500__ endpoint crashed. We reported the incident to Sentry and will fix it soon! Please include the value of `instance` in the response if you contact the support. Repeating the request will not help, most probably. * __501__ some functions are missing, either because they are not implemented yet or a non-critical runtime dependency is not satisfied. Repeating the request will not help, guaranteed. * __502__ server crashed badly, either due to a memory access violation in native code or running out of memory. We reported this incident to Sentry and will fix it soon! Try repeating the request. * __503__ server has not fully launched yet, e.g. hasn't connected to the database; server is shutting down; we are experiencing a partial outage. Try repeating the request. * __504__ endpoint took too much time and was interrupted. We reported the incident to Sentry and will see how to improve the performance. Repeating the request will not help, most probably. </details>
 *
 * Contact: admin@athenian.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ForSetPullRequests from './ForSetPullRequests';
import PullRequestHistogramDefinition from './PullRequestHistogramDefinition';

/**
 * The PullRequestHistogramsRequest model module.
 * @module model/PullRequestHistogramsRequest
 */
class PullRequestHistogramsRequest {
    /**
     * Constructs a new <code>PullRequestHistogramsRequest</code>.
     * Request of &#x60;/histograms/pull_requests&#x60;.
     * @alias module:model/PullRequestHistogramsRequest
     * @param _for {Array.<module:model/ForSetPullRequests>} Sets of developers and repositories for which to calculate the histograms. The aggregation is `AND` between repositories and developers. The aggregation is `OR` inside both repositories and developers.
     * @param histograms {Array.<module:model/PullRequestHistogramDefinition>} Histogram parameters for each wanted topic.
     * @param dateFrom {Date} Date from when to start measuring the distribution.
     * @param dateTo {Date} Date until which to measure the distribution.
     * @param excludeInactive {Boolean} Value indicating whether PRs without events in the given time frame shall be ignored.
     * @param account {Number} Session account ID.
     */
    constructor(_for, histograms, dateFrom, dateTo, excludeInactive, account) { 
        
        PullRequestHistogramsRequest.initialize(this, _for, histograms, dateFrom, dateTo, excludeInactive, account);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, _for, histograms, dateFrom, dateTo, excludeInactive, account) { 
        obj['for'] = _for;
        obj['histograms'] = histograms;
        obj['date_from'] = dateFrom;
        obj['date_to'] = dateTo;
        obj['exclude_inactive'] = excludeInactive;
        obj['account'] = account;
    }

    /**
     * Constructs a <code>PullRequestHistogramsRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PullRequestHistogramsRequest} obj Optional instance to populate.
     * @return {module:model/PullRequestHistogramsRequest} The populated <code>PullRequestHistogramsRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PullRequestHistogramsRequest();

            if (data.hasOwnProperty('for')) {
                obj['for'] = ApiClient.convertToType(data['for'], [ForSetPullRequests]);
            }
            if (data.hasOwnProperty('histograms')) {
                obj['histograms'] = ApiClient.convertToType(data['histograms'], [PullRequestHistogramDefinition]);
            }
            if (data.hasOwnProperty('date_from')) {
                obj['date_from'] = ApiClient.convertToType(data['date_from'], 'Date');
            }
            if (data.hasOwnProperty('date_to')) {
                obj['date_to'] = ApiClient.convertToType(data['date_to'], 'Date');
            }
            if (data.hasOwnProperty('timezone')) {
                obj['timezone'] = ApiClient.convertToType(data['timezone'], 'Number');
            }
            if (data.hasOwnProperty('exclude_inactive')) {
                obj['exclude_inactive'] = ApiClient.convertToType(data['exclude_inactive'], 'Boolean');
            }
            if (data.hasOwnProperty('quantiles')) {
                obj['quantiles'] = ApiClient.convertToType(data['quantiles'], ['Number']);
            }
            if (data.hasOwnProperty('account')) {
                obj['account'] = ApiClient.convertToType(data['account'], 'Number');
            }
            if (data.hasOwnProperty('fresh')) {
                obj['fresh'] = ApiClient.convertToType(data['fresh'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * Sets of developers and repositories for which to calculate the histograms. The aggregation is `AND` between repositories and developers. The aggregation is `OR` inside both repositories and developers.
 * @member {Array.<module:model/ForSetPullRequests>} for
 */
PullRequestHistogramsRequest.prototype['for'] = undefined;

/**
 * Histogram parameters for each wanted topic.
 * @member {Array.<module:model/PullRequestHistogramDefinition>} histograms
 */
PullRequestHistogramsRequest.prototype['histograms'] = undefined;

/**
 * Date from when to start measuring the distribution.
 * @member {Date} date_from
 */
PullRequestHistogramsRequest.prototype['date_from'] = undefined;

/**
 * Date until which to measure the distribution.
 * @member {Date} date_to
 */
PullRequestHistogramsRequest.prototype['date_to'] = undefined;

/**
 * Local time zone offset in minutes, used to adjust `date_from` and `date_to`.
 * @member {Number} timezone
 */
PullRequestHistogramsRequest.prototype['timezone'] = undefined;

/**
 * Value indicating whether PRs without events in the given time frame shall be ignored.
 * @member {Boolean} exclude_inactive
 */
PullRequestHistogramsRequest.prototype['exclude_inactive'] = undefined;

/**
 * Cut the distributions at certain quantiles. The default values are [0, 1] which means no cutting.
 * @member {Array.<Number>} quantiles
 */
PullRequestHistogramsRequest.prototype['quantiles'] = undefined;

/**
 * Session account ID.
 * @member {Number} account
 */
PullRequestHistogramsRequest.prototype['account'] = undefined;

/**
 * Force histograms calculation on the most up to date data.
 * @member {Boolean} fresh
 */
PullRequestHistogramsRequest.prototype['fresh'] = undefined;






export default PullRequestHistogramsRequest;

