import { BasicApiClass } from '@common-services/api/common/BasicApiClass';
import { REST_TYPES } from '@common-services/api/common/types/apiClient';
import { ApiMode } from '@common-services/api/common/types/common';
import { ISentryMethodParams } from '@common-services/api/common/types/sentry';
import { PUBLIC_API_ENDPOINTS_CONSTANTS } from '@common-services/api/public/constants';
import {
  FilterPullRequestsRequest,
  GetPullRequestsRequest,
  PaginatePullRequestsRequest,
  PullRequestPaginationPlan,
  PullRequestSet,
} from '@common-services/api/public/generated-from-backend/models';

export class PullRequestsApi extends BasicApiClass {
  constructor() {
    super(ApiMode.PUBLIC);
  }
  public fetchFilteredPRsPaginationPlan(
    body: PaginatePullRequestsRequest,
    token: string,
    sentry?: ISentryMethodParams
  ): Promise<PullRequestPaginationPlan> {
    this.assignToken(token);
    const callApi = this.callApi<PullRequestPaginationPlan>(
      PUBLIC_API_ENDPOINTS_CONSTANTS.PULL_REQUESTS_PAGINATION_PLAN,
      REST_TYPES.POST,
      body
    );
    return sentry ? this.callSentry<PullRequestPaginationPlan>(callApi, sentry) : callApi;
  }

  public fetchFilteredPRs(
    body: FilterPullRequestsRequest,
    token: string,
    sentry?: ISentryMethodParams
  ): Promise<PullRequestSet> {
    this.assignToken(token);
    const callApi = this.callApi<PullRequestSet>(
      PUBLIC_API_ENDPOINTS_CONSTANTS.FILTERED_PULL_REQUESTS,
      REST_TYPES.POST,
      body
    );
    return sentry ? this.callSentry<PullRequestSet>(callApi, sentry) : callApi;
  }

  public getPullRequests(
    params: GetPullRequestsRequest,
    token: string,
    sentry?: ISentryMethodParams
  ): Promise<PullRequestSet> {
    this.assignToken(token);
    const callApi = this.callApi<PullRequestSet>(
      PUBLIC_API_ENDPOINTS_CONSTANTS.GET_PULL_REQUESTS,
      REST_TYPES.POST,
      params
    );
    return sentry ? this.callSentry<PullRequestSet>(callApi, sentry) : callApi;
  }
}
