import { breakdownConfig } from '@align-constants';
import {
  BreakdownOptionsReducerTypes,
  IBreakdownOptionItem,
  IBreakdownOptionsReducerAction,
} from '@align-pages/dashboard/reducers/breakdownOptionsReducer.types';
import { DashboardBreakdownServices } from '@align-pages/dashboard/services/dashboardBreakdownServices';

export function breakdownOptionsReducer(
  state: IBreakdownOptionItem[],
  action: IBreakdownOptionsReducerAction
): IBreakdownOptionItem[] {
  switch (action.type) {
    case BreakdownOptionsReducerTypes.INIT:
      DashboardBreakdownServices.unAssignAllColors();
      return action.list?.map((item) =>
        item.selected && !item.color ? DashboardBreakdownServices.toggleColor(item, true) : item
      );
    case BreakdownOptionsReducerTypes.CHANGE:
      const selectedCount = state.filter(({ selected }) => selected)?.length;
      if (
        (action.selected && selectedCount >= breakdownConfig.optionsMaxSelection) ||
        (!action.selected && selectedCount <= breakdownConfig.optionsMinSelection)
      ) {
        return state;
      }

      return [
        ...state.map((option) => {
          if (option.value === action.value) {
            const updatedItem = {
              ...option,
              selected: action.selected,
            };

            return DashboardBreakdownServices.toggleColor(updatedItem, action.selected);
          }

          return option;
        }),
      ];
    default:
      return [...state];
  }
}
