import React from 'react';
import { components } from 'react-select';

import IconSearch from '@assets/icons/IconSearch.svg';

import IconBug from './IconBug.svg';
import IconEpic from './IconEpic.svg';
import IconJira from './IconJira.svg';
import IconStory from './IconStory.svg';
import IconSubtask from './IconSubtask.svg';
import IconTask from './IconTask.svg';

export const SeachIcon = () => {
  return (
    <div className="select-labels-icon">
      <IconSearch />
    </div>
  );
};

export const ValueContainer = ({ children, ...props }) => (
  <components.ValueContainer {...props}>
    <SeachIcon />
    {children}
  </components.ValueContainer>
);

export const Option = (props) => {
  const {
    innerProps: { onMouseMove, onMouseOver, ...restInnerProps }, // remove mouse events
    label,
    data: { subtype },
  } = props;

  const newProps = {
    ...props,
    innerProps: { ...restInnerProps },
  };

  return (
    <components.Option {...newProps}>
      {pillIcon(subtype)}
      <span className="select-option-label">
        {typeCopy(subtype)} <strong>{label}</strong>
      </span>
    </components.Option>
  );
};

const typeCopy = (subtype) => {
  switch (subtype) {
    case 'pull_request':
      return 'Pull requests labeled';
    case 'labels':
      return 'Jira tickets labeled';
    default:
      return '';
  }
};

const pillIcon = (subtype) => {
  switch (subtype) {
    case 'pull_request':
      return <span className="icon-pull-request mr-1" style={{ color: '#ff7427' }} />;
    case 'epics':
      return <IconEpic />;
    case 'task':
      return <IconTask />;
    case 'story':
      return <IconStory />;
    case 'bug':
      return <IconBug />;
    case 'labels':
      return <IconJira />;
    case 'subtask':
      return <IconSubtask />;
    default:
      return <span className="icon-pull-request mr-1" />;
  }
};

export const SelectPill = ({ label, type, subtype, onClick }) => {
  return (
    <div className="select-pill jira-select-pill">
      <span className="pill-icon">{pillIcon(subtype)}</span>
      <div className="select-pill-label">{label}</div>
      <div className="select-pill-remove jira" onClick={onClick}>
        <svg
          height="14"
          width="14"
          fill="currentColor"
          viewBox="0 0 20 20"
          aria-hidden="true"
          focusable="false"
        >
          <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
        </svg>
      </div>
    </div>
  );
};

export const Menu = (props) => {
  const { children, getValue, setValue } = props;
  const values = getValue();
  return (
    <components.Menu {...props}>
      <div className="jira-select-pill-wrapper select-pill-wrapper">
        {values.map((val) => (
          <SelectPill
            type={val.type}
            subtype={val.subtype}
            key={val.value}
            label={val.label}
            onClick={() => setValue(values.filter((pill) => pill.value !== val.value))}
          />
        ))}
      </div>
      {children}
    </components.Menu>
  );
};

export const Input = (props) => {
  const {
    selectProps: { value },
  } = props;
  const pills = value
    ? value.reduce((acc, curr) => {
        if (acc[curr.type]) {
          acc[curr.type]++;
        } else {
          acc[curr.type] = 1;
        }
        return acc;
      }, {})
    : {};

  const typeLabel = (type) => {
    switch (type) {
      case 'labels':
        return 'label';
      case 'epics':
        return 'epic';
      default:
        return '';
    }
  };
  const pill = Object.entries(pills).map(([key, count]) => {
    return <span className="select-count" key={key}>{`${count} ${typeLabel(key)}`}</span>;
  });

  return (
    <div className="d-flex">
      {pill}
      <components.Input {...props} />
    </div>
  );
};

export const Placeholder = (props) => {
  const {
    selectProps: { value },
  } = props;
  return value && value.length ? null : <components.Placeholder {...props} />;
};
