import React from 'react';
import { ReactFlowProvider } from 'react-flow-renderer';

import { FittedTreeView } from '@align-pages/goals/single/components/tree-view/components/fitted-tree-view';

import { wrapperStyles } from './treeView.styles';
import { ITreeView } from './treeView.types';

const TreeView: React.FC<ITreeView> = React.memo(({ nodes, edges }) => {
  return (
    <div css={wrapperStyles}>
      <ReactFlowProvider>
        <FittedTreeView nodes={nodes} edges={edges} />
      </ReactFlowProvider>
    </div>
  );
});

export { TreeView };
