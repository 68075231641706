import { useTheme } from '@emotion/react';
import _ from 'lodash';
import React from 'react';

import PipelineSummaryBox from '@analytics-components/PipelineSummaryBox';
import { calculateGranularity } from '@analytics-components/Prefetcher';
import { DATA_STATUS } from '@analytics-components/StatusIndicator';
import { BigText, DateRangeBigNumber } from '@analytics-components/charts/Tooltip';
import VerticalBarChart from '@analytics-components/charts/VerticalBarChart';
import { SummaryMatrixKPIs } from '@analytics-components/insights/SummaryBox';
import { useBreadcrumb } from '@analytics-context/Breadcrumb';
import DataWidget, { useDataWidget } from '@analytics-context/DataWidget';
import { fetchCIMetrics } from '@analytics-services/api';
import { getFeature, featuresList } from '@analytics-services/flags';
import { dateTime } from '@common-services/dateService';
import { number } from '@common-services/format';
import { hexToRGBA } from '@utils/colors';
import { unslugify } from '@utils/index';

const buildChart = (data, isLoading, { color, spacing }) => {
  if (isLoading) {
    return [];
  } else {
    const tickFormat = data.granularity === 'aligned month' ? dateTime.month : dateTime.monthDay;
    const tooltip = {
      align: {
        horizontal: 'auto',
        vertical: 'top',
      },
      renderBigFn: (v) => {
        let extraValue;
        if (v.y0) {
          extraValue = (v.y - v.y0) / v.y;
        } else if (v.failValue) {
          extraValue = v.y / (v.y + v.failValue);
        } else {
          extraValue = 1;
        }
        return (
          <BigText
            content={v.y0 ? v.y - v.y0 : v.y}
            extra={number.percentage(extraValue * 100, 1)}
            icon={
              <svg
                style={{
                  display: 'inline-block',
                  width: 10,
                  height: 10,
                  marginRight: 5,
                }}
              >
                <circle cx={5} cy={5} r={5} fill={v.color} />
              </svg>
            }
          />
        );
      },
    };
    if (_(data.granularity).includes('month') || _(data.granularity).includes('week')) {
      tooltip.template = DateRangeBigNumber;
      tooltip.interval = { ...data.interval };
      tooltip.granularity = data.granularity;
    }
    return {
      params: {
        data: data.chartData,
        timeMode: true,
        extra: {
          stacked: true,
          legend: [
            { title: 'Success', strokeWidth: 10, color: color.status.success },
            { title: 'Fail', strokeWidth: 10, color: color.status.danger },
          ],
          axisFormat: {
            tickAngle: {
              x: 0,
            },
            tickFormat: {
              x: tickFormat,
            },
          },
          axisLabels: {
            y: 'Suite Runs',
          },
          axisKeys: {
            x: 'date',
            y: 'value',
          },
          color: [hexToRGBA(color.status.success, 0.7), hexToRGBA(color.status.danger, 0.7)],
          height: 350,
          maxNumberOfTicks: 7,
          tooltip,
        },
      },
    };
  }
};

const SucessRatioSummaryBox = () => {
  const theme = useTheme();
  const { subsection: activeStage } = useBreadcrumb();

  const fetcher = async (api, cachedData, apiContext) => {
    const {
      account,
      interval,
      contributors,
      repositories,
      labels,
      features,
      epics,
      issueTypes,
    } = apiContext;
    const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, features)?.parameters
      ?.value;

    const fetch = (timeInterval, metrics, granularity) =>
      fetchCIMetrics(
        api,
        account,
        timeInterval,
        [granularity],
        metrics,
        repositories,
        contributors,
        labels,
        excludedLabels || [],
        { epics, issue_types: issueTypes }
      );

    const currMetrics = await fetch(interval, ['chk-prs-merged-with-failed-checks-count'], 'all');

    let granularity = calculateGranularity(interval);
    if (granularity === 'month') {
      granularity = `aligned ${granularity}`;
    }
    const chartData = await fetch(
      interval,
      ['chk-successful-suites-count', 'chk-failed-suites-count'],
      granularity
    );
    const failChecks = _(chartData.calculated[0].values)
      .map((v) => ({
        date: v.date,
        value: v.values[1],
        color: theme.color.status.danger,
      }))
      .value();
    const successChecks = _(chartData.calculated[0].values)
      .map((v, i) => ({
        date: v.date,
        failValue: failChecks[i].value,
        value: v.values[0],
        color: theme.color.status.success,
      }))
      .value();

    const flakinessValue = cachedData['ci-metrics'].values.all['chk-suites-count']
      ? cachedData['ci-metrics'].values.all['chk-flaky-commit-checks-count'] /
        cachedData['ci-metrics'].values.all['chk-suites-count']
      : null;
    return {
      average: cachedData['ci-metrics'].values.all['chk-success-ratio'],
      variation: cachedData['ci-metrics'].variations['chk-success-ratio'],
      formatFn: (v) => number.percentage(v * 100, 1),
      interval,
      granularity,
      empty:
        !successChecks.reduce((acc, v) => acc + v.value, 0) &&
        !failChecks.reduce((acc, v) => acc + v.value, 0),
      kpisData: [
        {
          title: 'Suite Runs',
          value: number.fixed(cachedData['ci-metrics'].values.all['chk-suites-count']),
        },
        {
          title: 'Merged failing checks',
          value: `${number.fixed(currMetrics.calculated[0].values[0].values[0])} PRs`,
        },
        {
          title: 'Suite Runs / PR',
          value: number.fixed(cachedData['ci-metrics'].values.all['chk-suites-per-pr'], 2),
        },
        {
          title: 'Flakiness',
          value: flakinessValue !== null ? number.percentage(flakinessValue * 100, 1) : null,
        },
      ],
      chartData: [successChecks, failChecks],
    };
  };

  const plumber = (fetchedData) => fetchedData;

  return (
    <DataWidget
      id={`summary-box-${activeStage}`}
      fetcher={fetcher}
      plumber={plumber}
      prefetchedDataIds={['ci-metrics']}
    >
      <SummaryBoxDataBinder
        activeStage={activeStage}
        renderKPI={(data) => <SummaryMatrixKPIs kpis={data} numberOfCols={2} />}
      />
    </DataWidget>
  );
};

const SummaryBoxDataBinder = ({ activeStage, className, renderKPI }) => {
  const theme = useTheme();
  const { data, isLoading } = useDataWidget();
  const chart = buildChart(data, isLoading, theme);

  return (
    <PipelineSummaryBox
      chart={
        <div css={{ marginTop: theme.spacing.gap['08'] }}>
          <VerticalBarChart {...chart.params} />
        </div>
      }
      className={className}
      data={data}
      dataStatus={DATA_STATUS.NO_DATA}
      isLoading={isLoading}
      positiveIsBetter
      renderKPI={renderKPI}
      statusColor={theme.color.neutral[80]}
      title={unslugify(activeStage)}
    />
  );
};

export default SucessRatioSummaryBox;
