// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';

import { useHelpContext } from '@analytics-context/Help';
import {
  Main,
  Title,
  Summary,
  Subtitle,
  Subsubtitle,
  Paragraph,
  Separator,
  ContentBlock,
  BlockQuote,
  Rank,
  RankItem,
  Source,
  Sidebar,
  RelatedContent,
  Navigation,
  Image,
} from '@analytics-pages/Help/components';
import BiggestPRs from '@assets/images/educational/biggest_prs.png';
import PRSizePieChart from '@assets/images/educational/pr_size_pie_chart.png';
import Release01Img from '@assets/images/educational/release_01.png';
import ReleaseFrequencyPerRepo from '@assets/images/educational/release_frequency_per_repo.png';
import Release06Img from '@assets/images/educational/release_table.png';

const ReleaseFrequency = ({ fullPage }) => {
  const { openSection, stack } = useHelpContext();
  return (
    <>
      <Navigation pages={stack} />
      <Main>
        <section>
          <Title>Release Frequency</Title>
          <Summary>
            Together with Lead Time, Release Frequency is a measure of software delivery performance{' '}
            <i>tempo</i>. This metric tells you how well customer feedback is incorporated along the
            development process.
          </Summary>

          <Subtitle>Why is it important?</Subtitle>
          <Paragraph>
            High performance engineering organizations release with a frequency of multiple times
            per day. This is usually enabled by Continuous Integration and Continuous Deployment,
            two set of capabilities that allow people to detect any issues quickly, so that they can
            be fixed straight away.
          </Paragraph>

          <Paragraph>
            The main reason why teams should be releasing with high frequency is to reduce the risk
            associated with a release. Indeed, since changes remain small and well tested, the risk
            of failure is very low and the confidence of success is high. In theory, releases to
            production should happen so often that they become a non-event.
          </Paragraph>

          <Image src={Release01Img} />

          <Paragraph>
            Increasing release frequency not only reduces the risk of failure but also brings
            engineering teams closer to Lean Management practices which - according to the{' '}
            <a href="https://www.amazon.com/Accelerate-Software-Performing-Technology-Organizations/dp/1942788339">
              Accelerate
            </a>{' '}
            research - have a significant impact on software delivery performance with the following
            benefits.
          </Paragraph>

          <ContentBlock>
            <ul>
              <li>Shorten feedback loop with end-users</li>
              <li>Limit work in progress and increase throughput</li>
              <li>Allow regular business decisions based on data from APM tools</li>
              <li>Decrease burnout and lead to a more generative culture</li>
            </ul>
          </ContentBlock>

          <Paragraph />

          <Subtitle>How is it calculated?</Subtitle>
          <Paragraph>
            In Athenian, release frequency is the ratio of the overall number of releases - which
            occurred during the time period and set of repositories selected - by the number of
            labour days in the period. Releases could be either GitHub release tags or merge into a
            release branch depending on your workflow: more details about what we consider a release
            are available in the{' '}
            <span
              onClick={() => openSection({ label: 'Release', path: 'release' })}
              className="link"
            >
              Release
            </span>{' '}
            section.
          </Paragraph>
          <Paragraph>
            You can have a look at your releases by going to the dedicated table in the{' '}
            <b>Release</b>
            section of Athenian. It works the same way as the pull request table and includes
            several metrics for each release such as the size of the release or the time since the
            previous release. Note that sometimes releases don't have pull requests associated, this
            means the release only includes direct commits to the main branch i.e. bypassing pull
            requests.
          </Paragraph>

          <Image src={Release06Img} />

          <Separator />

          <Subtitle>How to improve it using Athenian?</Subtitle>

          <Subsubtitle>Work in Small Batches</Subsubtitle>

          <Paragraph>
            To increase release frequency, it is crucial to reduce the cost of pushing out
            individual changes. This is possible by splitting work into smaller chunks that deliver
            measurable business outcomes quickly. Such practices allow to get essential feedback on
            the on-going work so that teams can course correct. The Basecamp team developed this{' '}
            <a href="https://basecamp.com/shapeup/3.2-chapter-10#integrate-one-slice">concept</a> in
            a book definitely worth reading, called{' '}
            <a href="https://basecamp.com/shapeup">Shape Up</a>.
          </Paragraph>

          <Paragraph>
            Athenian empowers engineering teams to work in small batches and release more
            frequently. Using the insights provided on pull request and release sizes, you can set
            and monitor quantitative objectives for your engineering teams e.g.{' '}
            <b>90% of pull requests count less than 500 lines of code.</b>
          </Paragraph>

          <Image src={PRSizePieChart} />

          <Paragraph>
            Once targets are set, Athenian allows engineering teams to measure their progress and
            zoom on the units of work that threaten their objectives before they actually increase
            lead time and impact negatively the team performance.
          </Paragraph>

          <Image src={BiggestPRs} />

          <Subsubtitle>Reduce Deployment Pain</Subsubtitle>

          <Paragraph>
            The process of releasing new versions to users should be a routine activity that can be
            performed on demand at any time. However, to ensure that software delivery performance
            is not achieved through brute force by pushing teams to work more and faster until the
            breaking point, it is important to evaluate how painful the deployment process is. The
            fear and anxiety that engineers feel when they push code into production can tell a lot
            about a team's software delivery performance. In the{' '}
            <a href="https://www.amazon.com/Accelerate-Software-Performing-Technology-Organizations/dp/1942788339">
              Accelerate
            </a>{' '}
            book, the authors highlight that <i>deployment pain</i> is a factor unhealthy work/life
            balance and burnout.
          </Paragraph>

          <Paragraph>
            With Athenian, you can understand the evolution of your release frequency through work
            cycles and isolate the features which needs further product decomposition. The insights
            from the <b>Release</b> section also help engineering teams to shed light on the
            repositories where releases are infrequent and slow down the feedback loops with the
            end-users.
          </Paragraph>

          <Image src={ReleaseFrequencyPerRepo} />

          <Subsubtitle>Promote loosely coupled processes and architecture</Subsubtitle>

          <Paragraph>
            To increase velocity and release frequency, it is essential to enable teams to make
            changes to the codebas without depending on other teams or systems. In{' '}
            <a href="https://services.google.com/fh/files/misc/state-of-devops-2017.pdf">
              2017 DevOps survey research
            </a>
            , the conclusion was drawn that loosely coupled, well-encapsulated architectures - i.e.
            that can be changed and validated independently of each other - drive engineering
            performance. The different researches in the field converges saying that best-in-class
            engineering teams manage to increase release frequency by focusing on deployability and
            testability. In particular, the following best practices have shown significant results
            in improving software delivery performance.
          </Paragraph>

          <ContentBlock>
            <ul>
              <li>Make large scale changes without the permission of somebody outside the team.</li>
              <li>
                Complete work without communicating and coordinating with people outside the team.
              </li>
              <li>
                Deploy and release a feature on demand regardless of other services it depends upon.
              </li>
              <li>
                Do most of the testing on demand without requiring an integrated test environment.
              </li>
              <li>Perform deployments during normal business hours with negligible downtime.</li>
            </ul>
          </ContentBlock>

          <Paragraph>
            In high performance teams, little communication is required between delivery teams to
            get their work done, from design to release into production. This principle has been
            stated for the first time in 1968 as the{' '}
            <a href="https://www.thoughtworks.com/radar/techniques/inverse-conway-maneuver">
              Inverse Conway Maneuver
            </a>
            . DevOps is all about better collaboration between teams, and engineering leaders should
            ensure that the available communication bandwidth is not overwhelmed by fine-grained
            decision-making at the implementation level. Engineering teams can boost their
            productivity by using that bandwidth for discussing higher-level shared goals and how to
            achieve them instead.
          </Paragraph>
        </section>
        <Sidebar>
          <Rank title="Top Teams" metric="Release Frequency">
            <RankItem name="Elite" value="hourly" />
            <RankItem name="High" value="daily" />
            <RankItem name="Medium" value="weekly" />
          </Rank>
          <BlockQuote>
            "Developers should be checking multiple small releasable changes into the trunk at least
            once per day."
            <Source>Google</Source>
          </BlockQuote>
          <BlockQuote>
            "What was state of the art 3 years ago is just not good enough for today's business
            environment."
            <Source>Accelerate</Source>
          </BlockQuote>

          <RelatedContent>
            <h4>Related Content</h4>
            <ul>
              <li>
                <a href="https://basecamp.com/shapeup/3.2-chapter-11#integrate-one-slice">
                  Shape Up - Get One Piece Done
                </a>
              </li>
              <li>
                <a href="https://www.thoughtworks.com/radar/techniques/inverse-conway-maneuver">
                  Inverse Conway Maneuver
                </a>
              </li>
              <li>
                {fullPage ? (
                  <Link to={`/learn/knowledge/release`} className="link">
                    <span>Release Cycle Time</span>
                  </Link>
                ) : (
                  <span
                    onClick={() => openSection({ label: 'Release Cycle Time', path: 'release' })}
                    className="link"
                  >
                    Release Cycle Time
                  </span>
                )}
              </li>
            </ul>
          </RelatedContent>
        </Sidebar>
      </Main>
    </>
  );
};

export default ReleaseFrequency;
