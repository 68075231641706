import { css } from '@emotion/react';

export const wrapperStyles = ({ color, font }) => css`
  color: ${color.text.dark};
  font-size: ${font.size.default};
  font-weight: ${font.weight.regular};

  .priority-image {
    height: 16px;
    width: 20px;
  }
`;
