import React from 'react';

import { useBreadcrumb } from '@analytics-context/Breadcrumb';
import { ComingSoon } from '@lib/empty/comingSoon';

import InsightsRunTime from './run-time';
import InsightsSuccessRatio from './success-ratio';

const Insights = () => {
  const { subsection: activeStage } = useBreadcrumb();
  const Component =
    {
      'success-ratio': InsightsSuccessRatio,
      'run-time': InsightsRunTime,
    }[activeStage] || ComingSoon;

  return <Component />;
};

export default Insights;
