import _ from 'lodash';
import React from 'react';

import TimeSeries from '@analytics-components/charts/TimeSeries';
import { BigText } from '@analytics-components/charts/Tooltip';
import { SimpleKPI } from '@analytics-components/insights/KPI';
import { dateTime } from '@common-services/dateService';
import { number } from '@common-services/format';
import { theme } from '@styles/theme';
import { hexToRGBA } from '@utils/colors';

const timeline = {
  prefetchedDataIds: ['ci-metrics'],
  plumber: (fetchedData, cachedData, apiContext) => {
    const customGranularity = cachedData['ci-metrics'].customGranularity;
    const granularity = customGranularity === 'month' ? 'custom-aligned' : 'custom';
    const chartData = cachedData['ci-metrics'].values[granularity]['chk-success-ratio'];

    const kpisData = {
      suiteRuns: cachedData['ci-metrics'].values.all['chk-suites-count'],
      avgSuccessRatio: cachedData['ci-metrics'].values.all['chk-success-ratio'],
    };

    const tickFormat = customGranularity === 'month' ? dateTime.month : dateTime.monthDay;
    const tooltip = {
      align: {
        horizontal: 'auto',
        vertical: 'top',
      },
      renderBigFn: (v) => <BigText content={number.percentage(v.y * 100, 1)} />,
    };
    if (_(['month', 'week']).includes(customGranularity)) {
      tooltip.dateRange = { ...apiContext.interval };
      tooltip.granularity = customGranularity;
    }
    return {
      empty: !chartData.length || chartData.reduce((acc, v) => acc + v.value, 0) === 0,
      chart: {
        component: TimeSeries,
        params: {
          data: chartData,
          timeMode: false,
          extra: {
            average: {
              value: kpisData.avgSuccessRatio,
              color: theme.color.neutral[80],
              renderFn: tooltip.renderBigFn,
            },
            axisKeys: {
              x: 'date',
              y: 'value',
            },
            color: theme.color.ui.lightblue[100],
            fillColor: hexToRGBA(theme.color.ui.lightblue[100], 0.1),
            maxNumberOfTicks: 10,
            ticks: { x: { tickFormat }, y: { tickFormat: (v) => `${v * 100}%` } },
            tooltip,
          },
        },
      },
      kpis: [
        {
          title: { text: 'Suite Runs', bold: true },
          subtitle: { text: 'Total' },
          component: SimpleKPI,
          params: {
            value: number.fixed(kpisData.suiteRuns),
          },
        },
        {
          title: { text: 'Success Ratio', bold: true },
          subtitle: { text: 'Average' },
          component: SimpleKPI,
          params: {
            value: number.fixed(kpisData.avgSuccessRatio * 100, 1),
            unit: '%',
          },
        },
      ],
    };
  },
};

export default timeline;
