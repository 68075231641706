import { css } from '@emotion/react';

export const wrapperStyles = ({ color, spacing }) => css`
  position: fixed;
  height: 42px;
  background: ${color.neutral.white};
  top: 0;
  z-index: 1020;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 ${spacing.gap['06']};
  border-bottom: 1px solid ${color.neutral['40']};

  .img-profile {
    height: 3rem !important;
    width: 3rem !important;
  }

  .dropdown-toggle {
    height: auto;
  }
`;

export const leftSideStyles = css`
  flex-grow: 1;
  display: flex;
  align-items: center;
  height: 100%;
`;
