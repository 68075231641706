import { css } from '@emotion/react';

import { IMetricDescriptionTextMode } from '@align-pages/goals/single/components/metric-description/components/metric-description-text/metricDescriptionText.types';
import { hexToRGBA } from '@utils/colors';

const metricDescriptionSpan = ({ mode }) => ({ spacing, color, font, radius }) => css`
  > span {
    font-weight: ${font.weight.bold};
    border-radius: ${radius.default};
    background-color: ${mode === IMetricDescriptionTextMode.EDIT
      ? hexToRGBA(color.ui.blue['100'], 0.1)
      : color.neutral['20']};
    padding: ${spacing.gap['03']};
    margin-left: ${spacing.gap['02']};
    margin-right: ${spacing.gap['03']};
    color: ${mode === IMetricDescriptionTextMode.EDIT
      ? color.ui.blue['100']
      : color.neutral['100']};
    font-size: ${font.size.s};
    font-weight: ${font.weight.bold};
    box-sizing: border-box;
    display: inline-block;
  }
`;

export const metricDescriptionTextStyles = ({ mode }) => ({ spacing, color, font, radius }) => css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .icon {
    margin-right: ${mode === IMetricDescriptionTextMode.TITLE
      ? spacing.gap['04']
      : spacing.gap['02']};
    display: flex;
    align-items: center;
    height: 100%;
  }
  .text {
    color: ${[IMetricDescriptionTextMode.LIST, IMetricDescriptionTextMode.TITLE].includes(mode)
      ? color.ui.blue['100']
      : mode === IMetricDescriptionTextMode.VIEW
      ? color.neutral['80']
      : color.neutral['100']};
    font-size: ${mode === IMetricDescriptionTextMode.TITLE
      ? font.size.l
      : mode === IMetricDescriptionTextMode.LIST
      ? font.size.default
      : font.size.s};
    font-weight: ${IMetricDescriptionTextMode.TITLE ? font.weight.medium : font.weight.regular};
    ${(mode === IMetricDescriptionTextMode.EDIT || mode === IMetricDescriptionTextMode.VIEW) &&
    metricDescriptionSpan({ mode })({ spacing, color, font, radius })}

    > span {
      font-size: ${mode === IMetricDescriptionTextMode.TITLE
        ? font.size.l
        : mode === IMetricDescriptionTextMode.LIST
        ? font.size.default
        : font.size.s};
    }
  }
`;
