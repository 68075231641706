import { useTheme } from '@emotion/react';
import { curveMonotoneX } from 'd3-shape';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  ChartLabel,
  VerticalGridLines,
  HorizontalGridLines,
  LineSeries,
  MarkSeries,
  LineMarkSeries,
  AreaSeries,
  Crosshair,
} from 'react-vis';

import { AltTitle } from '@analytics-components/Typography';
import {
  DateBigNumber,
  DateRangeBigNumber,
  DefaultXYTooltip,
  onValueChange,
  onValueReset,
} from '@analytics-components/charts/Tooltip';
import defaults from '@analytics-components/charts/defaults';
import { dateTime } from '@common-services/dateService';
import { getBestFitDurationUnit } from '@common-services/format';
import { NoData } from '@lib/empty/noData';

const Chart = ({ data, extra, className, ...rest }) => (
  <div style={{ background: 'white' }} className={className}>
    <TimeSeries data={data} extra={extra} {...rest} />
  </div>
);

const buildChartLabel = (text, which) => {
  const labelParams = {
    x: {
      includeMargin: false,
      xPercent: 0.5,
      yPercent: 1.0,
      style: {
        y: 60,
        textAnchor: 'middle',
      },
    },
    y: {
      includeMargin: false,
      xPercent: 0.0,
      yPercent: 0.5,
      style: {
        textAnchor: 'middle',
        transform: 'rotate(-90)',
        y: -60,
      },
    },
  }[which];

  return <ChartLabel text={text} {...labelParams} />;
};

export const computeTickValues = (formattedData, maxNumberOfTicks) => {
  const tickValues = formattedData.map((v) => v.x?.getTime());

  if (maxNumberOfTicks && maxNumberOfTicks > 0) {
    const everyEach = Math.ceil(tickValues.length / maxNumberOfTicks);
    return _(tickValues).at(_.range(0, formattedData.length, everyEach)).value();
  } else {
    return tickValues;
  }
};

const filterEmptyValues = (v) => v !== null;

const TimeSeries = ({ data, extra, timeMode }) => {
  const theme = useTheme();

  const [currentHover, setCurrentHover] = useState(null);
  const [xPosition, setXPosition] = useState(null);

  const marginBottom = extra.margin?.bottom || 40;
  const marginLeft = extra.margin?.left ?? defaults.marginLeft;
  const marginTop = extra.margin?.top ?? 10;

  const formattedData = useMemo(
    () =>
      data.map((v) => ({
        ...v,
        ...(v.legend && { legend: v.legend }),
        x: v[extra.axisKeys.x],
        y: v[extra.axisKeys.y],
      })),
    [data, extra]
  );

  const [conversionValue, unit] = timeMode ? getBestFitDurationUnit(formattedData) : [1, ''];
  const scaleY = (y) => (y === null ? null : y / conversionValue);

  const dataPoints = useMemo(
    () =>
      formattedData
        .filter((v) => (extra.filterValuesFn || filterEmptyValues)(v.y))
        .map((v) => ({ ...v, y: scaleY(v.y) })),
    [extra, formattedData]
  );

  const minValue = useMemo(() => (dataPoints?.length > 0 ? _.minBy(dataPoints, (d) => d.y).y : 0), [
    dataPoints,
  ]);
  const maxValue = useMemo(() => (dataPoints?.length > 0 ? _.maxBy(dataPoints, (d) => d.y).y : 1), [
    dataPoints,
  ]);

  const yDomain = useMemo(() => {
    const [adjustedMinValue, adjustedMaxValue] =
      minValue === maxValue ? [minValue, maxValue + 1] : [minValue, maxValue];
    return {
      min: _.max([0, adjustedMinValue - (adjustedMaxValue - adjustedMinValue) / 2]),
      max: adjustedMaxValue,
    };
  }, [maxValue, minValue]);

  const handleValueMouseOver = useCallback(
    (datapoint) => onValueChange(datapoint, currentHover, setCurrentHover),
    [currentHover]
  );

  const handleMouseLeave = useCallback(() => {
    onValueReset(setCurrentHover);
    setXPosition(null);
  }, []);

  const xDomain = useMemo(
    () => ({
      min: formattedData[0].x,
      max: formattedData[formattedData.length - 1].x,
    }),
    [formattedData]
  );

  const averagedData = useMemo(() => {
    let result = [];
    if (extra.average && dataPoints.length) {
      result.push({
        x: xDomain.min,
        y: scaleY(extra.average.value),
        ...extra.average.extra,
      });
      dataPoints.forEach((point) => {
        result.push({
          x: point.x,
          y: scaleY(extra.average.value),
          ...extra.average.extra,
        });
      });
      result.push({
        x: xDomain.max,
        y: scaleY(extra.average.value),
        ...extra.average.extra,
      });
    }
    return result;
  }, [dataPoints, extra]);

  const handleAvgLineMouseOver = useCallback(() => {
    onValueChange(
      {
        text: 'AVERAGE',
        x: dataPoints[xPosition]?.x,
        y: averagedData[0].y,
        ...extra.average?.extra,
      },
      currentHover,
      setCurrentHover
    );
  }, [averagedData, currentHover, dataPoints, xPosition]);

  const setNearestXPosition = useCallback((_, { index }) => {
    setXPosition(index);
  }, []);

  if (data.length === 0) {
    return <NoData textOnly />;
  }

  const tickFormatY = extra?.ticks?.y?.tickFormat || ((y) => `${y} ${unit}`.trim());
  const tickValues = computeTickValues(formattedData, extra.maxNumberOfTicks);

  const referenceData = [];
  if (extra.reference && dataPoints.length) {
    referenceData.push({
      x: xDomain.min,
      y: extra.reference.value,
    });
    referenceData.push({
      x: xDomain.max,
      y: extra.reference.value,
    });
  }

  const currentHoverValue = currentHover
    ? {
        ...currentHover,
        y: currentHover.y === null ? null : currentHover.y / scaleY(1),
      }
    : null;

  return (
    <FlexibleWidthXYPlot
      height={extra.height || 300}
      margin={{ left: marginLeft, right: 30, bottom: marginBottom, top: marginTop }}
      xDomain={[xDomain.min, xDomain.max]}
      yDomain={[yDomain.min, yDomain.max]}
      onMouseLeave={handleMouseLeave}
    >
      <VerticalGridLines tickValues={tickValues} />
      <XAxis
        tickValues={tickValues}
        tickFormat={extra?.ticks?.x?.tickFormat || dateTime.monthDay}
        tickSizeOuter={0}
        tickSizeInner={0}
      />
      <HorizontalGridLines tickTotal={3} />
      <YAxis tickTotal={3} tickFormat={tickFormatY} tickSizeOuter={0} tickSizeInner={0} />
      {extra.axisLabels && extra.axisLabels.y && buildChartLabel(extra.axisLabels.y, 'y')}

      {extra.fillColor && (
        <AreaSeries
          data={dataPoints}
          stroke="none"
          fill={extra.fillColor}
          animation="stiff"
          curve={curveMonotoneX}
        />
      )}

      {referenceData.length > 0 && (
        <LineSeries
          data={referenceData}
          color={extra.reference.color}
          strokeStyle="dashed"
          animation="stiff"
        />
      )}
      {referenceData.length > 0 && (
        <MarkSeries
          sizeRange={[5, 15]}
          stroke={extra.reference.color}
          fill="white"
          strokeWidth={2}
          data={referenceData}
          animation="stiff"
        />
      )}

      {currentHover && !currentHover?.text && (
        <Crosshair
          style={{
            line: {
              backgroundColor: 'transparent',
              borderLeft: `2px dashed ${theme.color.neutral[60]}`,
            },
            box: {
              display: 'none',
            },
          }}
          values={[currentHoverValue]}
        />
      )}

      <Tooltip
        params={extra}
        currentHover={currentHover}
        currentHoverValue={currentHoverValue}
        dataPoints={dataPoints}
      />

      <LineMarkSeries
        curve={curveMonotoneX}
        sizeRange={[5, 15]}
        fill="white"
        stroke={extra.color}
        lineStyle={{ strokeWidth: 2 }}
        markStyle={{ strokeWidth: 2 }}
        data={dataPoints}
        animation="stiff"
        onNearestX={handleValueMouseOver}
        onValueMouseOut={handleMouseLeave}
      />

      {averagedData.length > 0 && (
        <LineSeries
          animation="stiff"
          color={extra.average.color}
          data={averagedData}
          strokeStyle="dashed"
          style={{ zIndex: 100 }}
          onSeriesMouseOver={handleAvgLineMouseOver}
          onNearestX={setNearestXPosition}
          onSeriesMouseOut={handleMouseLeave}
        />
      )}
    </FlexibleWidthXYPlot>
  );
};

const Tooltip = ({ params, currentHover, currentHoverValue, dataPoints, ...props }) => {
  if (params?.tooltip?.dateRange && !currentHover?.text) {
    return (
      <DateRangeBigNumber
        value={currentHover}
        dataPoint={currentHoverValue}
        data={dataPoints}
        granularity={params?.tooltip?.granularity}
        interval={params?.tooltip?.dateRange}
        renderBigFn={params?.tooltip?.renderBigFn}
        {...props}
      />
    );
  } else if (currentHover?.text) {
    return (
      <DefaultXYTooltip
        dataPoint={currentHoverValue}
        value={currentHover}
        x={(v) => <AltTitle content={v.text} />}
        y={params?.average?.renderFn}
        {...props}
      />
    );
  } else {
    return (
      <DateBigNumber
        value={currentHover}
        dataPoint={currentHoverValue}
        renderBigFn={params?.tooltip?.renderBigFn}
        {...props}
      />
    );
  }
};

export default Chart;
