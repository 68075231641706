import { Point } from 'highcharts';
import { useCallback, useState } from 'react';

import { IUseChartInteraction } from '@align-pages/goals/single/components/goal-view-component/goalViewComponent.types';
import { IDateRangeInMls, PrsIssuesTableTabs } from '@align-types/common';
import { ChartTooltipService } from '@lib/chart';

export function useChartInteraction(changeTab): IUseChartInteraction {
  const [selectedRange, setSelectedRange] = useState<IDateRangeInMls>(null);
  const [chartPoints, setChartPoints] = useState<Point[]>([]);

  const handleChartPointSelect = useCallback((x: number, series?: Point[]) => {
    if (!x) {
      // return Timeframe to default if the chart point was unselected
      setSelectedRange(null);
      return;
    }

    const dateTo = ChartTooltipService.getRangeEndDate({ currentValue: x, series });
    // if we don't timeout setting the range to a new value, the resetting it above happens first
    // I couldn't think of a better solution unfortunately, I know it's ugly :D
    setTimeout(
      () =>
        setSelectedRange({
          dateFrom: x,
          dateTo: dateTo,
        }),
      0
    );
    setChartPoints(series);
    changeTab(PrsIssuesTableTabs.COMPLETED);
  }, []);

  const handleRemoveTimeframe = useCallback(() => {
    setSelectedRange(null);
    // unselect all points in the chart
    chartPoints.forEach((point) => point.select(false));
  }, [chartPoints]);

  return { selectedRange, handleChartPointSelect, handleRemoveTimeframe };
}
