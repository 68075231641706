import { css } from '@emotion/react';

export const titleStyles = ({ color, font, spacing }) => css`
  align-items: center;
  display: flex;
  width: 500px;

  .chevron {
    margin-right: ${spacing.gap['07']};
  }

  .check-title {
    color: ${color.status.secondary};
    font-size: ${font.size.s};
    font-weight: ${font.weight.regular};
    margin-left: ${spacing.gap['04']};
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .check-link {
      color: ${color.status.secondary};

      &:hover {
        text-decoration: underline !important;
      }
    }

    .check-name {
      color: ${color.text.dark};
    }

    .check-subtitle {
      margin-top: ${spacing.gap['02']};
      font-size: ${font.size.xs};

      .age {
        color: ${color.text.dark};
      }
    }
  }
`;

export const searchStyles = ({ spacing }) => css`
  margin-bottom: ${spacing.gap['05']};
`;

export const chartsStyles = ({ color, font, spacing }) => css`
  display: flex;
  margin-bottom: ${spacing.gap['03']};
  margin-top: ${spacing.gap['07']};
  padding-left: ${spacing.gap['08']};
  padding-right: ${spacing.gap['08']};

  .chart {
    margin-left: ${spacing.gap['08']};
    margin-right: ${spacing.gap['08']};
    width: 50%;

    .chart-title {
      color: ${color.text.secondary};
      font-size: ${font.size.xs};
      font-weight: ${font.weight.bold};
      margin-bottom: ${spacing.gap['05']};
      text-transform: uppercase;
    }

    .no-data-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
      height: 200px;
    }
  }
`;

export const progressColumnStyles = ({ color, spacing }) => css`
  align-items: center;
  display: flex;

  .progress-details {
    margin-left: ${spacing.gap['03']};

    .progress-line {
      margin-top: ${spacing.gap['02']};
      width: 80px;

      .progress {
        justify-content: flex-start;
      }
    }
  }
`;
