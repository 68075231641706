import { useMemo } from 'react';

import { useBreakdownOptions } from '@align-pages/dashboard/components/chart-edit/chartEdit.hooks';
import { chartConfig } from '@align-pages/dashboard/config';
import { IUseDashboardChartDataReturn } from '@align-pages/dashboard/hooks/types';
import { useDashboardStore } from '@align-pages/dashboard/store';
import { IValuesSerializer } from '@align-services/api/metricService.types';
import { PUBLIC_API_ENDPOINTS_CONSTANTS } from '@common-services/api/public/constants';
import {
  CalculatedJIRAMetrics,
  CalculatedPullRequestMetrics,
  CalculatedReleaseMetrics,
} from '@common-services/api/public/generated-from-backend/models';
import {
  useOwnedJiraIssues,
  useOwnedPullRequests,
  useOwnedReleases,
} from '@common-services/api/public/hooks/useMetricsData';

export const useDashboardChartData = (): IUseDashboardChartDataReturn => {
  const chartParams = useDashboardStore((state) => state.chartParams);
  const { groupBy } = useBreakdownOptions();

  const { metric } = chartParams || {};

  const { endpoint } = chartConfig.find((conf) => conf.metric === metric) || {};

  const { data: pullRequestsData, isLoading: isPullRequestsLoading } = useOwnedPullRequests(
    { ...chartParams, group_by: groupBy },
    true,
    endpoint === PUBLIC_API_ENDPOINTS_CONSTANTS.PULL_REQUESTS
  );

  const { data: jiraIssuesData, isLoading: isJiraIssuesLoading } = useOwnedJiraIssues(
    { ...chartParams, group_by: groupBy },
    true,
    endpoint === PUBLIC_API_ENDPOINTS_CONSTANTS.ISSUES
  );

  const { data: releasesData, isLoading: isReleasesLoading } = useOwnedReleases(
    { ...chartParams, group_by: groupBy },
    true,
    endpoint === PUBLIC_API_ENDPOINTS_CONSTANTS.RELEASES
  );

  const isLoading = useMemo(
    () => isPullRequestsLoading || isJiraIssuesLoading || isReleasesLoading,
    [isPullRequestsLoading, isJiraIssuesLoading, isReleasesLoading]
  );

  const data = useMemo(() => {
    switch (endpoint) {
      case PUBLIC_API_ENDPOINTS_CONSTANTS.PULL_REQUESTS:
        return pullRequestsData;
      case PUBLIC_API_ENDPOINTS_CONSTANTS.ISSUES:
        return jiraIssuesData;
      case PUBLIC_API_ENDPOINTS_CONSTANTS.RELEASES:
        return releasesData;
      default:
        return [];
    }
  }, [endpoint, pullRequestsData, jiraIssuesData, releasesData]);

  const dataValues: IValuesSerializer['values'] = useMemo(
    () =>
      endpoint === PUBLIC_API_ENDPOINTS_CONSTANTS.PULL_REQUESTS
        ? (data as CalculatedPullRequestMetrics)?.calculated
        : (data as CalculatedJIRAMetrics | CalculatedReleaseMetrics),
    [endpoint, data]
  );

  return { data: dataValues, isLoading };
};
