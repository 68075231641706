import React, { useEffect, useMemo, useState } from 'react';

import { PR_STAGE } from '@common-services/prHelpers';
import { IPrsTableRow } from '@lib/Table/components/pr-table/prTable.types';
import { NoData } from '@lib/empty/noData';
import { TooManyRows } from '@lib/empty/tooManyRows';

import { PullRequestsTable } from './components/prs-table';
import { useFilter } from './hooks/useFilter';
import { usePrsData } from './hooks/usePrsData';
import { useSearch } from './hooks/useSearch';
import { IPrsDataProps, PRS_TABLE_STATE } from './pullRequests.types';
import { getAllPrs, getFirstPagePrs } from './services/serializers';

const PullRequests: React.FC<IPrsDataProps> = React.memo(({ repositories, stage }) => {
  const [filteredPrs, setFilteredPrs] = useState<IPrsTableRow[]>([]);

  const { earlyUserInteraction, pagesData, state } = usePrsData({
    repositories: stage === PR_STAGE.DEPLOY ? repositories : null,
    stage,
  });

  const allPrs = useMemo(
    () => (state === PRS_TABLE_STATE.DONE ? getAllPrs(pagesData, stage) : []),
    [pagesData, stage, state]
  );

  const { currentFilter, filterOptions, placeholder, handleFilter } = useFilter(
    allPrs,
    stage,
    setFilteredPrs
  );

  const { searchedString, handleSearch } = useSearch({
    allPrs,
    currentFilter,
    stage,
    setFilteredPrs,
  });

  useEffect(() => {
    if (state === PRS_TABLE_STATE.DONE) {
      setFilteredPrs(allPrs);
    } else if (pagesData?.length === 1) {
      setFilteredPrs(getFirstPagePrs(pagesData, stage));
    }
  }, [allPrs, pagesData, state]);

  if (!pagesData?.length || (earlyUserInteraction && state !== PRS_TABLE_STATE.DONE)) {
    return <div className="dataTable-placeholder filter-placeholder" />;
  }

  if (!pagesData.length) {
    return <NoData />;
  } else if (allPrs.length > 5000) {
    return <TooManyRows amount={allPrs.length} />;
  }

  return (
    <PullRequestsTable
      data={filteredPrs}
      filterOptions={filterOptions}
      filterPlaceholder={placeholder}
      currentStatus={currentFilter}
      isLoading={state !== PRS_TABLE_STATE.DONE}
      searchedString={searchedString}
      onFilter={handleFilter}
      onSearch={handleSearch}
    />
  );
});

export { PullRequests };
