import { css } from '@emotion/react';

export const titleStyles = ({ color, font, spacing }) => css`
  align-items: center;
  display: flex;

  .issue-avatar {
    height: 15px;
    margin-left: ${spacing.gap['05']};
    margin-right: ${spacing.gap['06']};
    width: 15px;
  }

  .issue-title {
    color: ${color.text.dark};
    &:hover {
      text-decoration: underline !important;
    }

    .issue-title-text {
      color: ${color.text.dark};
      font-size: ${font.size.s};
      font-weight: ${font.weight.regular};
      width: 243px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media (min-width: 1300px) {
        width: 420px;
      }

      @media (min-width: 1400px) {
        width: 500px;
      }

      .issue-id {
        color: ${color.text.secondary};
      }

      span {
        font-size: ${font.size.s};
        font-weight: ${font.weight.regular};
      }
    }

    .pr-title-text {
      color: ${color.text.dark};
      font-size: ${font.size.default};
      font-weight: ${font.weight.regular};
      width: 216px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .pr-repo {
        color: ${color.text.secondary};
      }

      span {
        font-size: ${font.size.s};
        font-weight: ${font.weight.regular};
      }

      @media (min-width: 1200px) {
        width: 293px;
      }

      @media (min-width: 1300px) {
        width: 393px;
      }

      @media (min-width: 1400px) {
        width: 473px;
      }
    }
  }

  .athenian-tooltip span {
    color: ${color.text.secondary};
    font-size: ${font.size.default};
    font-weight: ${font.weight.bold};
  }
`;

export const iconWrapperStyles = ({ spacing }) => css`
  margin-left: ${spacing.gap['10']};
  margin-right: ${spacing.gap['05']};
`;

export const placeholderStyles = css`
  min-width: 20px;
`;

export const smallText = ({ color, font }) => css`
  color: ${color.text.secondary};
  font-size: ${font.size.xs};
  font-weight: ${font.weight.light};
  white-space: nowrap;
`;

export const prSizeStyles = ({ font }) => css`
  white-space: nowrap;

  span {
    font-size: ${font.size.default};
    font-weight: ${font.weight.light};
  }
`;

export const taskDetailsStyles = ({ color, font, spacing }) => css`
  align-items: center;
  display: flex;

  .task-details {
    ${smallText({ color, font })}
    margin-left: ${spacing.gap['03']};

    span {
      font-size: ${font.size.xs};
    }

    .reporter {
      color: ${color.text.dark};
    }
  }

  .user-avatar {
    height: 22px;
    margin-right: ${spacing.gap['0']};
    width: 22px;
  }
`;

export const infoStyles = ({ color, font, spacing }) => css`
  align-items: center;
  display: flex;
  width: 60px;

  svg path {
    fill: ${color.text.secondary};
  }

  .number {
    color: ${color.text.dark};
    font-size: ${font.size.default};
    font-weight: ${font.weight.light};
    margin-left: ${spacing.gap['03']};
  }
`;

export const statusStyles = ({ font, spacing, radius }, statusColor) => css`
  align-items: center;
  border: 1px solid ${statusColor};
  border-radius: ${radius.default};
  color: ${statusColor};
  display: flex;
  height: 26px;
  justify-content: center;
  width: 150px;
  padding: ${spacing.gap['04']};

  span {
    font-size: 11px;
    font-weight: ${font.weight.regular};
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const filtersStyles = css`
  align-items: baseline;
  display: flex;
  justify-content: space-between;
`;

export const searchStyles = ({ spacing }) => css`
  &.issues-search {
    margin-bottom: ${spacing.gap['05']};
  }
`;

export const tableHeaderStyles = ({ spacing }) => css`
  th {
    &:first-of-type {
      padding-left: ${spacing.gap['11']};
    }

    &:not(:first-of-type) {
      text-align: left;
    }
  }
`;

export const tableBodyStyles = ({ font, spacing }) => css`
  font-size: ${font.size.xs};

  && td {
    &:first-of-type > div {
      padding: ${spacing.gap['0']} ${spacing.gap['06']};
    }

    &:not(:first-of-type) > div {
      justify-content: flex-start;
      padding: ${spacing.gap['0']} ${spacing.gap['04']};
    }
  }
`;
