import { useCallback, useEffect } from 'react';

import { filterGoalsWithoutTarget } from '@align-pages/goals/list/active-goals/activeGoals.services';
import { getRemainingDays } from '@align-services/dateService';
import { GoalUpdateRequest } from '@common-services/api/private/generated-from-backend/models';
import { useGoalsForOwnedTeam } from '@common-services/api/private/hooks/useGoals';
import { useUpdateGoal } from '@common-services/api/private/hooks/useUpdateGoal';
import log from '@common-services/logger';

export const useArchiveGoal = (refetchGoals: () => void) => {
  const {
    mutate: updateMutate,
    data: updateData,
    isError: updateIsError,
    isSuccess: updateIsSuccess,
  } = useUpdateGoal();

  useEffect(() => {
    if (updateIsSuccess) {
      log.ok(`Goal #${updateData?.id || 'N/A'} has been archived`);
      refetchGoals();
    }
  }, [updateData, updateIsSuccess]);

  useEffect(() => {
    if (updateIsError) {
      log.fatal('Could not archive the goal. Please try again.');
    }
  }, [updateIsError]);

  const handleArchive = useCallback((goalId: number, input: GoalUpdateRequest) => {
    updateMutate({
      goalId,
      input,
    });
  }, []);

  return handleArchive;
};

// For using single request and single cache for the goals data,
// Get the goals without target from the api and filter them on the client
export const useGoalsWithTarget = (currentTeamId: number, isCached: boolean) => {
  // Get all goals without filtering by target
  const { data, isLoading, refetch } = useGoalsForOwnedTeam(
    {
      team: currentTeamId,
      only_with_targets: true,
      include_series: true,
      account: null,
    },
    isCached
  );

  // Filter the goals by their target presence
  const filteredData = filterGoalsWithoutTarget(data);

  return { data: filteredData, isLoading, refetch };
};

export const useRemainingDays = ({ goal }) => {
  const { hasEnded, hasStarted, remainingDays } = getRemainingDays({
    date: goal?.expires_at,
    validFrom: goal?.valid_from,
  });
  return hasStarted
    ? hasEnded
      ? `Completed ${Math.abs(remainingDays)} day${remainingDays !== 1 ? 's' : ''} ago`
      : `${remainingDays} day${remainingDays !== 1 ? 's' : ''} left`
    : `Starts in ${remainingDays === 1 ? `a day` : `${remainingDays} days`} `;
};
