import React from 'react';

import { Spinner } from '@lib/Spinner';
import { Icon, icons } from '@lib/icon';
import { useTheme } from '@styles/hooks';

import { labelStyles, wrapperStyles } from './filterDropdownBox.styles';
import { FilterDropdownType, IFilterDropdownBox } from './filterDropdownBox.types';
import { FilterDropdownBoxBadge } from './ui/filter-dropdown-box-badge';
import { FilterDropdownBoxItems } from './ui/filter-dropdown-box-items';

export const FilterDropdownBox: React.FC<IFilterDropdownBox> = React.memo(
  ({
    label,
    isAllSelected,
    allSelectedLabel,
    onClick,
    badge,
    isLoading = false,
    options,
    selectedItems,
    type = FilterDropdownType.ITEMS,
    minWidth = 233,
    fullWidth,
  }) => {
    const theme = useTheme();

    return (
      <div
        onClick={isLoading ? () => null : onClick}
        css={wrapperStyles({ isLoading, minWidth, fullWidth })}
      >
        <Spinner loading={isLoading} size={6} />
        {!isLoading && (
          <>
            <div css={labelStyles}>
              {type === FilterDropdownType.BADGE ? (
                <FilterDropdownBoxBadge label={label} badge={badge} />
              ) : (
                <FilterDropdownBoxItems
                  label={label}
                  badge={badge}
                  isAllSelected={isAllSelected}
                  allSelectedLabel={allSelectedLabel}
                  items={selectedItems}
                  options={options}
                />
              )}
            </div>
            <div className="icon">
              <Icon icon={icons.caret_down} size={8} color={theme.color.neutral['60']} />
            </div>
          </>
        )}
      </div>
    );
  }
);
