import { css } from '@emotion/react';

export const wrapperStyles = ({ spacing }) => css`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.gap['04']};

  .content {
    flex-grow: 1;
    margin-right: ${spacing.gap['04']};
  }

  .prop-menu:not(:last-child) {
    margin-bottom: ${spacing.gap['04']};
  }
`;
