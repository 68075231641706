import React, { useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useHistory } from '@common-hooks/useHistory';
import { Icon, icons } from '@lib/icon';

import { wrapperStyles } from './addChartPanel.styles';

const AddChartPanel: React.FC = React.memo(() => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const addNewMetric = useCallback(() => {
    history.pushWithBackState(`${url}/new-metric`);
  }, [history, url]);

  return (
    <div css={wrapperStyles} onClick={addNewMetric}>
      <div className="add-metric">
        <Icon icon={icons.add} size={31} />
        <div className="add-metric-text">Add Metric</div>
      </div>
    </div>
  );
});

export { AddChartPanel };
