import { css } from '@emotion/react';

import selectStyles from '@analytics-components/MultiSelect/styles';
import { theme } from '@styles/theme';

export const labelStyles = ({ color, font }) => css`
  color: ${color.text.dark};
  font-size: ${font.size.default};
  font-weight: ${font.weight.regular};
`;

export const wrapperStyles = css`
  position: relative;
`;

export const menuStyles = ({ color, font, radius, spacing }) => css`
  background-color: ${color.neutral.white};
  border: 1px solid ${color.neutral[40]};
  border-radius: ${radius.default};
  min-width: 450px;
  padding: ${spacing.gap['05']};
  padding-bottom: 0;
  position: absolute;
  right: 0;
  z-index: 10;

  .add-users-label {
    margin-bottom: ${spacing.gap['05']};
    margin-top: ${spacing.gap['06']};
  }

  .dropdown-divider {
    margin-top: ${spacing.gap['07']};
  }

  .team-input {
    margin-top: ${spacing.gap['05']};
  }
`;

export const teamFormSelectStyles = {
  ...selectStyles(theme, false),
  container: (styles) => ({
    position: 'relative',
    background: theme.color.neutral[40],
    paddingTop: `calc(${theme.spacing.gap['1px']} * 2)`,
  }),
  control: (base, state) => ({
    ...base,
    'margin': theme.spacing.gap['05'],
    'minHeight': 30,
    'height': 30,
    'borderRadius': 0,
    'borderColor': state.isFocused ? theme.color.ui.lightorange[100] : theme.color.neutral[40],
    'boxShadow': state.isFocused ? '0 0 0 0.2rem rgba(255, 160, 8, 0.25)' : 0,
    '&:hover': {
      boxShadow: '0 0 0 0.2rem rgba(255, 160, 8, 0.25)',
      borderColor: theme.color.ui.lightorange[100],
    },
  }),
  menu: (styles) => ({
    ...styles,
    position: 'relative',
    top: 0,
    marginTop: theme.spacing.gap['0'],
    borderRadius: 'none',
    boxShadow: 'none',
    border: `1px solid ${theme.color.neutral[60]}`,
  }),
};
