import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import {
  FlexibleWidthXYPlot,
  XAxis,
  CustomSVGSeries,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  MarkSeries,
  ChartLabel,
  DiscreteColorLegend,
} from 'react-vis';

import Tooltip, { onValueChange, onValueReset } from '@analytics-components/charts/Tooltip';
import defaults from '@analytics-components/charts/defaults';

const Chart = ({ data, extra }) => (
  <div style={{ background: 'white' }}>
    {extra.isLogScale ? (
      <BubbleChartLogScale data={data} extra={extra} />
    ) : (
      <BubbleChart data={data} extra={extra} />
    )}
  </div>
);

const formatData = (data, extra) =>
  _(data)
    .map((v) => {
      const d = {
        ...v,
        x: extra.axisKeys && extra.axisKeys.x ? v[extra.axisKeys.x] : v.x,
        y: extra.axisKeys && extra.axisKeys.y ? v[extra.axisKeys.y] : v.y,
        size: extra.axisKeys && extra.axisKeys.size ? v[extra.axisKeys.size] : v.size,
        label: extra.axisKeys && extra.axisKeys.label ? v[extra.axisKeys.label] : v.label,
        tooltip: v.tooltip,
      };

      if (extra.grouper) {
        d[extra.grouper] = v[extra.grouper];
      }

      return d;
    })
    .value();

const buildSeries = (formattedData, extra, currentHover, setCurrentHover) => {
  const transformer = extra.isLogScale ? logScale : (v) => v;
  let marksSeries;
  if (extra.grouper) {
    marksSeries = _(formattedData)
      .groupBy(extra.grouper)
      .map((series, k) => (
        <MarkSeries
          key={k}
          strokeWidth={2}
          color={extra.groups[k].color}
          data={series.map((v) => ({
            ...v,
            x: transformer(v.x),
            y: transformer(v.y),
            size: transformer(v.size),
            ...(v.label && { label: v.label }),
            ...(v.tooltip && { tooltip: v.tooltip }),
          }))}
          sizeRange={extra.sizeRange || [5, 20]}
          onValueMouseOver={(datapoint) => onValueChange(datapoint, currentHover, setCurrentHover)}
          onValueMouseOut={() => {
            if (!extra?.tooltip?.persistent) {
              onValueReset(setCurrentHover);
            }
          }}
          onValueClick={(_, event) => event.event.stopPropagation()}
        />
      ))
      .value();
  } else {
    if (extra.useImages) {
      marksSeries = [
        <CustomSVGSeries
          key={1}
          data={formattedData.map((v) => ({
            ...v,
            x: transformer(v.x),
            y: transformer(v.y),
            size: transformer(v.size),
            ...(v.label && { label: v.label }),
            ...(v.tooltip && { tooltip: v.tooltip }),
            customComponent: () => (
              <Pic
                id={`custom-mark-${v.label}`}
                url={v.tooltip.image}
                maskId={`${extra.yAxis.imageMask}-mask`}
              />
            ),
          }))}
          sizeRange={[5, 20]}
          onNearestXY={(value, info) => {
            // This handling is used instead of `onValueMouseOver` and `onValueMouseOut`
            // because is more accurate. See: https://athenianco.atlassian.net/browse/DEV-1155.
            const event = info.event;
            const { clientX: mouseX, clientY: mouseY } = event;
            const markCoordinates = document
              .getElementById(`custom-mark-${value.label}`)
              .getBoundingClientRect();
            const markX = markCoordinates.left + (markCoordinates.right - markCoordinates.left) / 2;
            const markY = markCoordinates.top + (markCoordinates.bottom - markCoordinates.top) / 2;
            const threshold = 15; // The same of the radius of the circle mask
            const distance = Math.sqrt(Math.pow(mouseX - markX, 2) + Math.pow(mouseY - markY, 2));

            if (distance < threshold) {
              onValueChange(value, currentHover, setCurrentHover, ['customComponent']);
            } else {
              if (!extra?.tooltip?.persistent) {
                onValueReset(setCurrentHover);
              }
            }
          }}
          onValueClick={(_, event) => event.event.stopPropagation()}
        />,
      ];
    } else {
      marksSeries = [
        <MarkSeries
          key={1}
          strokeWidth={2}
          color={extra.color}
          data={formattedData.map((v) => ({
            ...v,
            x: transformer(v.x),
            y: transformer(v.y),
            size: transformer(v.size),
            ...(v.label && { label: v.label }),
            ...(v.tooltip && { tooltip: v.tooltip }),
          }))}
          sizeRange={[5, 20]}
          onValueMouseOver={(datapoint) => onValueChange(datapoint, currentHover, setCurrentHover)}
          onValueMouseOut={() => {
            if (!extra?.tooltip?.persistent) {
              onValueReset(setCurrentHover);
            }
          }}
          onValueClick={(_, event) => event.event.stopPropagation()}
        />,
      ];
    }
  }

  return marksSeries;
};

const BubbleChart = ({ data, extra }) => {
  const [currentHover, setCurrentHover] = useState(null);

  const handleMouse = useCallback(() => onValueReset(setCurrentHover), []);

  if (data.length === 0) {
    return <></>;
  }

  const marginLeft = extra.margin?.left ?? defaults.marginLeft;
  const marginTop = extra.margin?.top || Object.keys(extra.groups || {}).length ? 30 : 15; // It should be at least 30 when the DiscreteColorLegend is used

  const formattedData = formatData(data, extra);
  const marksSeries = buildSeries(formattedData, extra, currentHover, setCurrentHover);

  const legend = _(extra.groups)
    .map((v) => ({ title: v.title, color: v.color, strokeWidth: 10 }))
    .value();

  const maxValueX = _.maxBy(formattedData, 'x')['x'] || 1;
  const maxValueY = _.maxBy(formattedData, 'y')['y'] || 1;

  const ChartTooltip = extra?.tooltip?.template || Tooltip;

  const margin = { top: marginTop, left: marginLeft, right: 30, bottom: 50 };
  return (
    <div onMouseLeave={handleMouse} onClick={handleMouse}>
      <FlexibleWidthXYPlot
        height={extra.height || 300}
        margin={margin}
        xDomain={[0, maxValueX]}
        yDomain={[0, maxValueY]}
      >
        <DiscreteColorLegend className="chart-legend" items={legend} orientation="horizontal" />

        {extra && extra.yAxis && extra.yAxis.image ? (
          <CircleMask
            id={`${extra.yAxis.imageMask}-mask`}
            maskProperties={{
              cx: 15,
              cy: 15,
              r: 15,
            }}
          />
        ) : null}

        <XBubbleChartAxis
          formattedData={formattedData}
          label={extra.axisLabels.x}
          tickFormat={extra.tickFormat?.x}
          margin={margin}
          tickSizeOuter={0}
          tickSizeInner={0}
        />
        <YBubbleChartAxis
          formattedData={formattedData}
          label={extra.axisLabels.y}
          tickFormat={extra.tickFormat?.y}
          margin={margin}
          tickSizeOuter={0}
          tickSizeInner={0}
        />

        {marksSeries}
        {currentHover && <ChartTooltip value={currentHover} />}
      </FlexibleWidthXYPlot>
    </div>
  );
};

const Pic = ({ id, url, maskId }) => (
  <image
    id={id}
    href={url}
    clipPath={`url(#${maskId})`}
    width="30"
    height="30"
    transform="translate(-15,-15)"
    onError={(e) => {
      e.target.href.baseVal = 'https://avatars2.githubusercontent.com/u/10137';
    }}
  />
);

const CircleMask = ({ id, maskProperties }) => (
  <defs>
    <clipPath id={id} fill="black">
      <circle {...maskProperties} />
    </clipPath>
  </defs>
);

CircleMask.requiresSVG = true;

const BubbleChartLogScale = ({ data, extra }) => {
  const [currentHover, setCurrentHover] = useState(null);

  if (data.length === 0) {
    return <></>;
  }

  const zoomed = extra.zoomed;

  const marginLeft = extra.margin?.left ?? defaults.marginLeft;
  const marginTop = Object.keys(extra.groups || {}).length ? 30 : 15; // It should be at least 30 when the DiscreteColorLegend is used

  const formattedData = formatData(data, extra);
  const marksSeries = buildSeries(formattedData, extra, currentHover, setCurrentHover);

  const legend = _(extra.groups)
    .map((v) => ({ title: v.title, color: v.color, strokeWidth: 10 }))
    .value();

  const maxValueX = _.maxBy(formattedData, 'x')['x'];
  const maxExpX = zoomed ? logScale(nextTick(maxValueX)) : Math.floor(logScale(maxValueX) + 1);
  const minValueX = _.minBy(formattedData, 'x')['x'];
  const minExpX = zoomed ? logScale(prevTick(minValueX)) : 0;

  const maxValueY = _.maxBy(formattedData, 'y')['y'];
  const maxExpY = Math.floor(logScale(maxValueY) + 1);

  const ChartTooltip = extra?.tooltip?.template || Tooltip;

  const margin = { top: marginTop, left: marginLeft, right: 30, bottom: 50 };
  return (
    <div
      onMouseLeave={() => onValueReset(setCurrentHover)}
      onClick={() => onValueReset(setCurrentHover)}
    >
      <FlexibleWidthXYPlot
        height={300}
        margin={margin}
        xDomain={[minExpX < 0 ? 0 : minExpX, maxExpX]}
        yDomain={[0, maxExpY]}
      >
        <DiscreteColorLegend className="chart-legend" items={legend} orientation="horizontal" />

        <XBubbleChartLogAxis
          formattedData={formattedData}
          label={extra.axisLabels.x}
          zoomed={zoomed}
          margin={margin}
        />
        <YBubbleChartLogAxis
          formattedData={formattedData}
          label={extra.axisLabels.y}
          margin={margin}
        />

        {marksSeries}
        <ChartTooltip value={currentHover} />
      </FlexibleWidthXYPlot>
    </div>
  );
};

const XBubbleChartLogAxis = ({ formattedData, label, zoomed, ...props }) => (
  <BubbleChartLogAxis
    {...props}
    zoomed={zoomed}
    formattedData={formattedData}
    label={label}
    which={'x'}
  />
);

XBubbleChartLogAxis.requiresSVG = true;

const YBubbleChartLogAxis = ({ formattedData, label, ...props }) => (
  <BubbleChartLogAxis {...props} formattedData={formattedData} label={label} which={'y'} />
);

YBubbleChartLogAxis.requiresSVG = true;

const BubbleChartLogAxis = ({ formattedData, which, label, zoomed, ...props }) => {
  const buildGridLine = ({ tickValues, ...props }) => {
    const params = {
      tickValues: tickValues,
    };

    if (which === 'x') {
      return <VerticalGridLines {...props} {...params} />;
    } else {
      return <HorizontalGridLines {...props} {...params} />;
    }
  };

  const buildAxis = ({ tickValues, which, ...props }) => {
    const params = {
      tickValues: tickValues,
      tickFormat: (v) => {
        const exp = Math.round(Math.pow(10, v));
        const expStr = exp.toString();
        const firstChar = expStr.charAt(0);
        const remaining = expStr.slice(1);
        return firstChar === '1' && parseInt(remaining) === 0 ? '' : firstChar;
      },
      style: {
        text: { fontWeight: 200, fontSize: '6px' },
      },
    };

    if (which === 'x') {
      return <XAxis {...props} {...params} />;
    } else {
      return <YAxis {...props} {...params} />;
    }
  };

  const buildDecorativeAxis = ({ tickValues, which, ...props }) => {
    const params = {
      tickValues: tickValues.filter((v) => v === parseInt(v)),
      tickFormat: (v) => (v !== 0 ? Math.pow(10, v) : ''),
      tickSizeOuter: 14,
    };

    if (which === 'x') {
      return <XAxis {...props} {...params} />;
    } else {
      return <YAxis {...props} {...params} />;
    }
  };

  const buildChartLabel = ({ text, which, ...props }) => {
    const labelParams = {
      x: {
        includeMargin: false,
        xPercent: 0.5,
        yPercent: 1.0,
        style: {
          y: props.margin.top + 40,
          textAnchor: 'middle',
        },
      },
      y: {
        includeMargin: false,
        xPercent: 0.0,
        yPercent: 0.5,
        style: {
          textAnchor: 'middle',
          transform: 'rotate(-90)',
          x: -props.margin.top,
          y: -40,
        },
      },
    }[which];

    return <ChartLabel {...props} text={text} {...labelParams} />;
  };

  const minValue = _.minBy(formattedData, which)[which];
  const maxValue = _.maxBy(formattedData, which)[which];
  const tickValues = calculateLogScaleTickValues(minValue, maxValue, zoomed && which === 'x');

  const gridLineProps = {
    ...props,
    ...{ tickValues: tickValues },
  };
  const axisProps = {
    ...props,
    ...{ which: which, tickValues: tickValues },
  };
  const labelProps = {
    ...props,
    ...{ text: label, which: which },
  };

  return (
    <>
      {buildGridLine(gridLineProps)}
      {buildDecorativeAxis(axisProps)}
      {buildAxis(axisProps)}
      {buildChartLabel(labelProps)}
    </>
  );
};

BubbleChartLogAxis.requiresSVG = true;

const XBubbleChartAxis = ({ formattedData, label, ...props }) => (
  <BubbleChartAxis {...props} formattedData={formattedData} label={label} which={'x'} />
);

XBubbleChartAxis.requiresSVG = true;

const YBubbleChartAxis = ({ formattedData, label, ...props }) => (
  <BubbleChartAxis {...props} formattedData={formattedData} label={label} which={'y'} />
);

YBubbleChartAxis.requiresSVG = true;

const BubbleChartAxis = ({ formattedData, which, label, ...props }) => {
  const buildChartLabel = ({ text, which, ...props }) => {
    const labelParams = {
      x: {
        includeMargin: false,
        xPercent: 0.5,
        yPercent: 1.0,
        style: {
          y: props.margin.top + 40,
          textAnchor: 'middle',
        },
      },
      y: {
        includeMargin: false,
        xPercent: 0.0,
        yPercent: 0.5,
        style: {
          textAnchor: 'middle',
          transform: 'rotate(-90)',
          x: -props.margin.top,
          y: -40,
        },
      },
    }[which];

    return <ChartLabel {...props} text={text} {...labelParams} />;
  };

  const labelProps = {
    ...props,
    ...{ text: label, which: which },
  };

  if (which === 'x') {
    return (
      <>
        <VerticalGridLines {...props} />
        <XAxis {...props} />
        {buildChartLabel(labelProps)}
      </>
    );
  } else {
    return (
      <>
        <HorizontalGridLines {...props} />
        <YAxis {...props} />
        {buildChartLabel(labelProps)}
      </>
    );
  }
};

BubbleChartAxis.requiresSVG = true;

const calculateLogScaleTickValues = (minValue, maxValue, zoomed = false) => {
  const tickValuesSets = [1];
  for (let exp = 1; ; exp++) {
    tickValuesSets.push(_.range(2, 11).map((v) => v * 10 ** (exp - 1)));
    if (maxValue < 10 ** exp) {
      break;
    }
  }

  const unScalledTicks = _.flatten(tickValuesSets);

  if (!zoomed) return unScalledTicks.map(logScale);

  const minTickIdx = unScalledTicks.findIndex((v) => v > minValue);
  const firstTickIdx = minTickIdx > 0 ? minTickIdx - 1 : 0;
  const maxTickIdx = unScalledTicks.findIndex((v) => v > maxValue);
  const lastTickIdx = maxTickIdx !== -1 ? maxTickIdx : unScalledTicks.length - 1;

  return unScalledTicks.slice(firstTickIdx, lastTickIdx + 1).map(logScale);
};

const nextTick = (v) => {
  if (v === 0) return 1;
  const t = tens(v);
  const first = Math.floor(v / 10 ** t);
  return (1 + first) * 10 ** t;
};

const prevTick = (v) => {
  if (v <= 1) return 0;
  const t = tens(v);
  const first = Math.ceil(v / 10 ** t);
  return first > 1 ? (first - 1) * 10 ** t : 9 * 10 ** (t - 1);
};

const tens = (v) => Math.floor(logScale(v));

const logScale = (v) => (v > 1 ? Math.log10(v) : 0);

export default Chart;
