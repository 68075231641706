import React, { useMemo } from 'react';

import { IBadgeSize } from '@common-types/common';
import { IFilterBadge } from '@lib/filter-badge/filterBadge.types';
import { Icon, icons } from '@lib/icon';

import { wrapperStyles } from './filterBadge.styles';

export const FilterBadge: React.FC<IFilterBadge> = React.memo(
  ({ counter = 0, isOpened = false, size = IBadgeSize.DEFAULT, onClick }) => {
    const filterText = useMemo(() => (counter > 1 ? 'filters' : 'filter'), [counter]);
    return (
      <div css={wrapperStyles({ isOpened, size })} onClick={onClick}>
        <Icon className="icon" icon={icons.applied_filter} size={10} />
        <div>
          {size === IBadgeSize.DEFAULT &&
            (isOpened ? `Hide ${filterText}` : `Show ${counter} ${filterText}`)}
          {size === IBadgeSize.SMALL && counter}
          {size === IBadgeSize.XSMALL &&
            (isOpened ? `Hide ${filterText}` : `Show ${counter} ${filterText}`)}
        </div>
      </div>
    );
  }
);
