import React from 'react';

import Badge from '@analytics-components/Badge';
import { SmallTitle } from '@analytics-components/Typography';
import DataWidget, { useDataWidget } from '@analytics-context/DataWidget';
import { Releases as ReleasesTable } from '@analytics-pages/Tables/releases';

const ReleasesTabHeader = ({ isActive }) => {
  const plumber = (fetchedData, cachedData, apiContext) => {
    return {
      badgeValue: cachedData.releases.data.length,
    };
  };

  return (
    <DataWidget id="releases-tab-header" plumber={plumber} prefetchedDataIds={['releases']}>
      <ReleasesTabHeaderDataBinder isActive={isActive} />
    </DataWidget>
  );
};

const ReleasesTabHeaderDataBinder = ({ isActive }) => {
  const { data, isLoading } = useDataWidget();
  const badgeValue = isLoading ? 0 : data.badgeValue;
  return (
    <>
      <SmallTitle content="Releases" isBlack={isActive} />
      <Badge value={badgeValue} className="ml-3" />
    </>
  );
};

const releasesTab = {
  Header: ReleasesTabHeader,
  content: <ReleasesTable />,
};

export { releasesTab };
