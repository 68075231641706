import _ from 'lodash';
import React from 'react';

import { BigText, DefaultXYTooltip } from '@analytics-components/charts/Tooltip';
import VerticalBarChart from '@analytics-components/charts/VerticalBarChart';
import { firstNRepos } from '@analytics-components/insights/Helper';
import { SimpleKPI } from '@analytics-components/insights/KPI';
import { github, number } from '@common-services/format';
import { theme } from '@styles/theme';

const perRepository = {
  prefetchedDataIds: ['ci-metrics', 'repos-ci-metrics'],
  plumber: (fetchedData, cachedData, apiContext) => {
    const chartData = _(cachedData['repos-ci-metrics'])
      .map((v, k) => ({
        repo: github.repoName(k),
        ratio: v['chk-success-ratio'],
        count: v['chk-suites-count'] || 0,
      }))
      .filter((v) => v.ratio)
      .orderBy('count', 'desc')
      .take(firstNRepos)
      .value();

    let min, max;
    _(chartData).forEach((item) => {
      if (item.ratio) {
        if (!min || item.ratio < min) {
          min = item.ratio;
        }
        if (!max || item.ratio > max) {
          max = item.ratio;
        }
      }
    });

    const kpisData = {
      avgSuccessRatio: cachedData['ci-metrics'].values.all['chk-success-ratio'],
      max,
      min,
    };

    const tooltip = {
      align: {
        horizontal: 'auto',
        vertical: 'top',
      },
      y: (v) => <BigText content={number.percentage(v.y * 100, 1)} extra={`${v.count} runs`} />,
      template: DefaultXYTooltip,
    };

    return {
      empty: !chartData.length || chartData.reduce((acc, v) => acc + v.ratio, 0) === 0,
      chart: {
        component: VerticalBarChart,
        params: {
          data: chartData,
          timeMode: false,
          extra: {
            axisFormat: {
              tickFormat: {
                y: (v) => `${v * 100}%`,
              },
            },
            axisKeys: {
              x: 'repo',
              y: 'ratio',
            },
            maxNumberOfTicks: 5,
            color: theme.color.ui.lightblue[100],
            tooltip,
          },
        },
      },
      kpis: [
        {
          title: { text: 'Success Ratio', bold: true },
          subtitle: { text: 'Average' },
          component: SimpleKPI,
          params: {
            value: number.percentage(kpisData.avgSuccessRatio * 100, 1),
          },
        },
        {
          title: { text: 'Min-Max', bold: true },
          subtitle: { text: 'Per Repository' },
          component: SimpleKPI,
          params: {
            value: `${number.percentage(min * 100, 1)} - ${number.percentage(max * 100, 1)}`,
          },
        },
      ],
    };
  },
};

export default perRepository;
