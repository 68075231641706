import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { PrimaryLayoutTabs } from '@align-components/layouts/primary/context.types';
import { useCurrentTeam } from '@align-hooks/useTeams';
import { MetricHeader } from '@align-pages/dashboard/components/chart-header/components/metric-header';
import { useDashboardStore } from '@align-pages/dashboard/store';
import { IGoalSingleParams } from '@align-pages/goals/single/components/goals-single-component';
import { NavigationService } from '@align-services/navigationService';
import { useHistory } from '@common-hooks/useHistory';
import { useOwnedTeamTree } from '@common-services/api/private/hooks/useTeamTreeData';
import { Breadcrumbs } from '@lib/Breadcrumbs';
import { Button, ButtonType } from '@lib/Button';
import { Icon, icons } from '@lib/icon';
import { useTheme } from '@styles/hooks';

import { headerStyles } from './chartHeader.styles';
import { IChartHeader } from './chartHeader.types';

const ChartHeader: React.FC<IChartHeader> = React.memo(
  ({
    existingChart,
    isSubmitDisabled,
    onSubmit,
    onBackButtonClick,
    selectedMetric,
    onNameChange,
  }) => {
    const theme = useTheme();
    const history = useHistory();
    const currentTeam = useCurrentTeam();
    const { path } = useParams<IGoalSingleParams>();
    const { data: teamsData } = useOwnedTeamTree(true);
    const [breadcrumbs] = NavigationService.getBreadcrumbsAndTeams(
      `${path}/${PrimaryLayoutTabs.DASHBOARD}`,
      PrimaryLayoutTabs.DASHBOARD,
      teamsData
    );

    const dropChartParams = useDashboardStore((state) => state.onChartParamsDrop);
    const navigateBack = useCallback(() => {
      history.goBack();
      dropChartParams();
    }, [history]);

    return (
      <div>
        <div css={headerStyles}>
          <div className="navigation-part">
            <div className="arrow-wrapper" onClick={onBackButtonClick}>
              <Icon color={theme.color.neutral['80']} icon={icons.arrow_left} size={12} />
            </div>
            <Breadcrumbs steps={breadcrumbs} />
          </div>
          <div className="buttons-part">
            <Button label="Cancel" type={ButtonType.GHOST} onClick={navigateBack} />
            <Button
              disabled={isSubmitDisabled}
              label={existingChart ? 'Save changes' : 'Add to Dashboard'}
              type={ButtonType.PRIMARY}
              onClick={onSubmit}
            />
          </div>
        </div>
        {selectedMetric && currentTeam && (
          <MetricHeader
            existingChart={existingChart}
            metric={selectedMetric}
            currentTeam={currentTeam}
            onNameChange={onNameChange}
          />
        )}
      </div>
    );
  }
);

export { ChartHeader };
