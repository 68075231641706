import { dashboardChartIntervals } from '@align-pages/dashboard/config';
import { IChartTimeInterval } from '@align-pages/dashboard/types';
import { CacheService } from '@align-services/cacheService';
import { DateService } from '@align-services/dateService';
import { IDashboardChartTimeParams } from '@common-services/api/private/types/dashboard';
import log from '@common-services/logger';

// TODO: for the MVP this will not be used. This function is implemented as future preparation
interface IGetTimeIntervalReturn {
  date_to: string;
  date_from: string;
}

export const getTimeInterval = (
  chartTimeParams: IDashboardChartTimeParams
): IGetTimeIntervalReturn => {
  const { date_to, date_from, time_interval } = chartTimeParams;
  return time_interval ? DateService.durationToTimeInterval(time_interval) : { date_to, date_from };
};

export const getTimeIntervalDisplay = (chartTimeParams: IDashboardChartTimeParams): string => {
  const { date_to, date_from, time_interval } = chartTimeParams;
  if (time_interval) {
    return DateService.durationToReadableText({ duration: time_interval, prefix: 'Last' });
  }
  return `${date_from} to ${date_to}`;
};

interface IGetChartIntervalReturn {
  interval: { date_to: string; date_from: string };
  display: string;
}

export const getChartInterval = (
  chartTimeParams: IDashboardChartTimeParams
): IGetChartIntervalReturn => {
  return {
    interval: getTimeInterval(chartTimeParams),
    display: getTimeIntervalDisplay(chartTimeParams),
  };
};

interface IGetDateIntervalDropdownOption {
  name: string;
  value: IChartTimeInterval;
}

export const getDateIntervalDropdownOptions = (): IGetDateIntervalDropdownOption[] =>
  dashboardChartIntervals.map((option) => ({
    name: DateService.durationToReadableText({ duration: option, prefix: 'Last' }),
    value: option,
  }));

export const invalidateDashboardQuery = async (inactive: boolean = false): Promise<void> => {
  try {
    await CacheService.resetQuery(['dashboard'], { inactive });
  } catch {
    log.fatal('Cache error');
  }
};

export * as DashboardChartServices from './dashboardChartServices';
