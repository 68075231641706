import { color } from '@styles/theme/colors';
import { IColor } from '@styles/types';
import { stringToColor } from '@utils/colors';

const flattenUiColors = (): IColor[] =>
  Object.values(color.ui).reduce((a, c) => (c['100'] ? [...a, c['100']] : a), []);

export const randomUIColor = (): IColor => {
  const uiColors: IColor[] = flattenUiColors();
  const randomIndex = Math.floor(Math.random() * uiColors.length);

  return uiColors[randomIndex];
};

export const getTeamColor = (teamName: string): string => {
  return teamName ? stringToColor(teamName) : color.ui.blue['100'];
};
