import { css } from '@emotion/react';

import { hexToRGBA } from '@utils/colors';

export const pipelineThumbnailStyles = ({ color }) => css`
  &:hover {
    background: ${hexToRGBA(color.neutral.white, 0.3)};
    transition: border-color 0.3s;

    .wip & {
      border-color: ${color.stage.wip};
    }

    .review & {
      border-color: ${color.stage.review};
    }

    .merge & {
      border-color: ${color.stage.merge};
    }

    .release & {
      border-color: ${color.stage.release};
    }
  }

  &.active {
    color: ${color.neutral.white};

    a,
    span {
      color: ${color.neutral.white};
    }

    span.link {
      color: ${color.ui.orange[100]};
    }

    &:hover {
      cursor: default;
    }

    .big-number {
      color: ${color.neutral.white};
    }

    .badge {
      background: ${hexToRGBA(color.neutral.white, 0.3)};
      color: ${color.neutral.white};

      path {
        fill: ${color.neutral.white};
      }
    }
  }

  .wip &.active {
    background-color: ${color.stage.wip};
    border-color: ${color.stage.wip};
  }

  .review &.active {
    background-color: ${color.stage.review};
    border-color: ${color.stage.review};
  }

  .merge &.active {
    background-color: ${color.stage.merge};
    border-color: ${color.stage.merge};
  }

  .release &.active {
    background-color: ${color.stage.release};
    border-color: ${color.stage.release};
  }

  .big-number {
    white-space: nowrap;
  }
`;

export const stageCardHeaderStyles = ({ color, spacing }) =>
  css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${spacing.gap['07']};

    > span > span {
      margin-right: ${spacing.gap['02']};
    }

    > span > div > svg {
      margin-top: -${spacing.gap['01']};
    }
  `;
