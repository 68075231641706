import _ from 'lodash';

import DonutChart from '@analytics-components/charts/DonutChart';
import { SimpleKPI, UNITS } from '@analytics-components/insights/KPI';
import { BIG_PR_CYCLE_TIME_THRESHOLD } from '@analytics-pages/delivery-pipeline/insights/summary';
import { fetchHistogram } from '@analytics-services/api';
import { getFeature, featuresList } from '@analytics-services/flags';
import { dateTime } from '@common-services/dateService';
import { number } from '@common-services/format';
import { theme } from '@styles/theme';
import { hexToRGBA } from '@utils/colors';

const donutTicks = (biggestThreshold) => [1, 3600, 28800, 86400, biggestThreshold];

export const palette = {
  five: [
    theme.color.ui.blue['100'],
    hexToRGBA(theme.color.ui.blue['100'], 0.5),
    hexToRGBA(theme.color.ui.blue['100'], 0.1),
    theme.color.ui.lightorange[50],
    theme.color.ui.yellow[50],
  ],
};

const prsCycleTimeDonutDistribution = {
  title: 'Distribution',
  description: 'Distribution of pull requests by cycle time ie. from 1st commit to release.',
  fetcher: async (api, cachedData, apiContext) => {
    const {
      account,
      interval,
      repositories,
      contributors,
      labels,
      excludeInactive,
      features,
      epics,
      issueTypes,
    } = apiContext;
    const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, features)?.parameters
      ?.value;
    return {
      donutDistribution: await fetchHistogram(
        api,
        account,
        interval,
        ['lead-time'],
        {
          repositories: repositories,
          with: { author: contributors },
          labels_include: labels,
          ...(excludedLabels ? { labels_exclude: excludedLabels } : {}),
        },
        excludeInactive,
        null,
        null,
        donutTicks(BIG_PR_CYCLE_TIME_THRESHOLD),
        { epics, issue_types: issueTypes }
      ),
    };
  },
  plumber: (fetchedData, cachedData, apiContext) => {
    const donutDistribution = fetchedData.donutDistribution?.['lead-time'];
    const totalFrequencies = _(donutDistribution.frequencies).sum();
    const belowLimit = totalFrequencies - donutDistribution.frequencies.slice(-1)[0];

    const chartData = donutDistribution?.frequencies.map((f, i) => ({
      value: f,
      label:
        donutDistribution.ticks[i] < BIG_PR_CYCLE_TIME_THRESHOLD * 1000
          ? `${dateTime.human(donutDistribution.ticks[i])} - ${dateTime.human(
              donutDistribution.ticks[i + 1]
            )}`
          : `${dateTime.human(donutDistribution.ticks[i])}+`,
    }));
    const prsProportionBelowThreshold = belowLimit / totalFrequencies;

    return {
      empty: !chartData?.find((bin) => bin.value),
      chart: {
        component: DonutChart,
        params: {
          data: chartData,
          extra: {
            colors: palette.five,
            tooltip: {
              y: (v) => `${v} pull request${v === 1 ? '' : 's'}`,
            },
          },
        },
      },
      kpis: [
        {
          title: { text: 'Total Released', bold: true },
          component: SimpleKPI,
          params: {
            value: totalFrequencies,
            unit: UNITS.pr,
          },
        },
        {
          title: { text: 'Pull Requests Released', bold: true },
          subtitle: { text: `under ${dateTime.human(BIG_PR_CYCLE_TIME_THRESHOLD * 1000)}` },
          component: SimpleKPI,
          params: {
            value: number.percentage(100 * prsProportionBelowThreshold, 0),
          },
        },
      ],
    };
  },
  prefetchedDataIds: ['prs-histogram'],
};

export default prsCycleTimeDonutDistribution;
