import React from 'react';

import { MultiTagLabel } from '@lib/tag-label';

import { TagsContainerPlaceholder } from '../tags-container-placeholder/tagsContainerPlaceholder';
import { wrapperStyles } from './tagsContainerUI.styles';
import { ITagsContainerUI } from './tagsContainerUI.types';

export const TagsContainerUI: React.FC<ITagsContainerUI> = React.memo(
  ({ avatarType, items, placeholder, isLoading, withSmallTags = false, onRemove, children }) => {
    return (
      <div data-cy="tags-container" css={wrapperStyles(isLoading, !!children)}>
        <div data-cy="tags-container-main" className="main">
          {items.length === 0 && placeholder ? (
            <TagsContainerPlaceholder text={placeholder} />
          ) : (
            <MultiTagLabel
              avatarType={avatarType}
              items={items}
              withSmallTags={withSmallTags}
              onRemove={onRemove}
            />
          )}
        </div>
        {children && (
          <div data-cy="tags-container-extra" className="extra">
            {children}
          </div>
        )}
      </div>
    );
  }
);
