import React from 'react';

import DonutChart from '@analytics-components/charts/DonutChart';

const RADIAL_DATA = [
  { value: 1, label: 'uno' },
  { value: 2, label: 'dos' },
  { value: 5, label: 'cinco' },
  { value: 3, label: 'tres' },
  { value: 5, label: 'cinco' },
];

const Chart = () => (
  <div style={{ background: 'white' }}>
    <DonutChart data={RADIAL_DATA} />
  </div>
);

export default Chart;
