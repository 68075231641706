import React, { useContext } from 'react';

import { Icon, icons } from '@lib/icon';
import { SelectorTsContext } from '@lib/selector-ts/context';
import { ClickTypeEnum } from '@lib/selector-ts/types/context';

interface Props {
  id: string;
  isOpened: boolean;
}

export const SelectorTsOptionOpenRow: React.FC<Props> = ({ id, isOpened }) => {
  const { clickHandler } = useContext(SelectorTsContext);
  return (
    <div
      data-cy="selector-option-control"
      className="control-wrapper"
      onClick={(event) => clickHandler(event, id, ClickTypeEnum.isOpened)}
      data-testid={`selectts-open-${id}`}
    >
      {isOpened ? (
        <Icon icon={icons.angle_down} size={10} />
      ) : (
        <Icon icon={icons.angle_right} size={10} />
      )}
    </div>
  );
};
