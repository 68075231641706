import { BasicApiClass } from '@common-services/api/common/BasicApiClass';
import { REST_TYPES } from '@common-services/api/common/types/apiClient';
import { ApiMode } from '@common-services/api/common/types/common';
import { ISentryMethodParams } from '@common-services/api/common/types/sentry';
import { PUBLIC_API_ENDPOINTS_CONSTANTS } from '@common-services/api/public/constants';
import {
  CalculatedJIRAMetricValues,
  CalculatedPullRequestMetrics,
  CalculatedReleaseMetrics,
  JIRAMetricsRequest,
  PullRequestMetricsRequest,
  ReleaseMetricsRequest,
} from '@common-services/api/public/generated-from-backend/models';

export class MetricsApi extends BasicApiClass {
  constructor() {
    super(ApiMode.PUBLIC);
  }

  public getPullRequests(
    params: PullRequestMetricsRequest,
    token: string,
    sentry?: ISentryMethodParams
  ): Promise<CalculatedPullRequestMetrics> {
    this.assignToken(token);
    const callApi = this.callApi<PullRequestMetricsRequest>(
      PUBLIC_API_ENDPOINTS_CONSTANTS.PULL_REQUESTS,
      REST_TYPES.POST,
      params
    );
    return sentry ? this.callSentry<any>(callApi, sentry) : callApi;
  }

  public getJiraIssues(
    params: JIRAMetricsRequest,
    token: string,
    sentry?: ISentryMethodParams
  ): Promise<CalculatedJIRAMetricValues> {
    this.assignToken(token);
    const callApi = this.callApi<JIRAMetricsRequest>(
      PUBLIC_API_ENDPOINTS_CONSTANTS.ISSUES,
      REST_TYPES.POST,
      params
    );
    return sentry ? this.callSentry<any>(callApi, sentry) : callApi;
  }

  public getReleases(
    params: ReleaseMetricsRequest,
    token: string,
    sentry?: ISentryMethodParams
  ): Promise<CalculatedReleaseMetrics> {
    this.assignToken(token);
    const callApi = this.callApi<ReleaseMetricsRequest>(
      PUBLIC_API_ENDPOINTS_CONSTANTS.RELEASES,
      REST_TYPES.POST,
      params
    );
    return sentry ? this.callSentry<any>(callApi, sentry) : callApi;
  }
}
