import React from 'react';

import MTTAcknowledgePanel from './MTTAcknowledgePanel';
import MTTRByPriorityAveragePanel from './MTTRByPriorityAveragePanel';
import MTTRByPriorityPanel from './MTTRByPriorityPanel';
import MTTRThroughTimeByPriority from './MTTRThroughTimeByPriority';
import MTTRepairPanel from './MTTRepairPanel';

const InsightsResolution = () => {
  return (
    <>
      <div className="row mx-0 px-0 mb-4">
        <div className="col-md-9 mx-0 pl-0 pr-1">
          <MTTRByPriorityPanel />
        </div>
        <div className="col-md-3 mx-0 pl-3 pr-0">
          <MTTRByPriorityAveragePanel />
        </div>
      </div>
      <div className="row mx-0 px-0 mb-4">
        <div className="col-md-12 mx-0 px-0">
          <MTTRThroughTimeByPriority />
        </div>
      </div>
      <div className="row mx-0 px-0 mb-4">
        <div className="col-md-6 mx-0 pl-0 pr-2">
          <MTTAcknowledgePanel />
        </div>
        <div className="col-md-6 mx-0 pl-2 pr-0">
          <MTTRepairPanel />
        </div>
      </div>
    </>
  );
};

export default InsightsResolution;
