import React from 'react';

import { isNotProd } from '@utils/development';

const Hint = ({ primaryText, secondaryText, learnMore, onLearnMoreClick }) => (
  <div>
    {primaryText}
    {secondaryText && <p className="small text-secondary mt-1 mb-0">{secondaryText}</p>}
    {(isNotProd || (!isNotProd && !learnMore?.noProd)) && learnMore?.params && (
      <p>
        <span
          className="link"
          onClick={(e) => {
            onLearnMoreClick({ ...learnMore.params });
            e.preventDefault();
          }}
        >
          Learn More
        </span>
      </p>
    )}
  </div>
);

export default Hint;
