import sanitizeHtml from 'sanitize-html';

export const sanitize = (
  text: string,
  forbiddenTags: string[] = ['a', 'table', 'thead', 'tbody', 'tr', 'th', 'td', 'pre']
): any => {
  const options = {
    allowedTags: sanitizeHtml.defaults.allowedTags
      .concat(['span'])
      .filter((tag) => !forbiddenTags.includes(tag)),
    allowedAttributes: {
      ...sanitizeHtml.defaults.allowedAttributes,
      '*': (sanitizeHtml.defaults.allowedAttributes['*'] || []).concat(['class']),
    },
  };

  return sanitizeHtml(text, options);
};
