import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { optionType } from '@lib/Selector/types';
import SingleLabel from '@lib/dropdown-selector/components/single-label';

const FormattedLabel = ({ list = [], noAvatar = false, wrapperWidth }) => {
  const [renderList, setRenderList] = useState([]);
  const [measuredWidths, setMeasuredWidths] = useState({});

  useEffect(() => {
    setRenderList([...list]);
  }, [list]);

  useEffect(() => {
    if (
      !!list.length &&
      list.every((item) => _(Object.keys(measuredWidths)).includes(item.value))
    ) {
      const newRenderList = [list[0]];
      let totalWidth = measuredWidths[list[0].value];
      let extraItemsCounter = 0;
      for (let i = 1; i < list.length; i += 1) {
        totalWidth += measuredWidths[list[i].value];
        // check the width without the space for +N text (~44px)
        if (totalWidth > wrapperWidth - 44) {
          extraItemsCounter += 1;
        } else {
          newRenderList.push(list[i]);
        }
      }
      if (extraItemsCounter) {
        newRenderList.push({
          label: `+${extraItemsCounter}`,
          value: 'extra',
        });
      }
      setRenderList(newRenderList);
    }
  }, [list, measuredWidths]);

  // this method stores widths of each item label to be able to truncate if the string is too long
  const handleMeasure = useCallback(
    (index, width) => {
      const value = list?.[index].value;
      if (value && (!measuredWidths[value] || measuredWidths[value] < width)) {
        measuredWidths[value] = width;
        setMeasuredWidths({
          ...measuredWidths,
          [value]: width,
        });
      }
    },
    [list, measuredWidths]
  );

  const hasExtraLongItem = useMemo(() => {
    return list.some((item) => measuredWidths[item.value] > wrapperWidth - 44);
  }, [list, measuredWidths, wrapperWidth]);

  return renderList.map((item, index) => (
    <SingleLabel
      hasLongItem={hasExtraLongItem}
      index={index}
      item={item}
      key={`value-${item.value}`}
      noAvatar={noAvatar}
      notLast={index < renderList.length - 1}
      onMeasure={handleMeasure}
    />
  ));
};

const FormattedLabelPropTypes = {
  list: PropTypes.arrayOf(optionType),
  noAvatar: PropTypes.bool,
  wrapperWidth: PropTypes.number.isRequired,
};

FormattedLabel.propTypes = FormattedLabelPropTypes;

export default FormattedLabel;
