import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  FilterDetailRowLimitType,
  useAppliedFilters,
} from '@align-components/layouts/primary/team-header/filter-detail';
import { FilterDetailRow } from '@align-components/layouts/primary/team-header/filter-detail/filter-detail-row';

import { IActiveGoalTooltipContent } from './activeGoalTooltipContent.types';

export const ActiveGoalTooltipContent: React.FC<IActiveGoalTooltipContent> = React.memo(
  ({ goal }) => {
    const appliedFilters = useAppliedFilters(goal);
    return (
      <>
        {appliedFilters.map((data) => (
          <FilterDetailRow
            key={uuidv4()}
            {...data}
            limitOptions={{ type: FilterDetailRowLimitType.WORD, max: 2 }}
          />
        ))}
      </>
    );
  }
);
