import {
  PullRequestMetricID,
  JIRAMetricID,
  ReleaseMetricID,
} from '@common-services/api/public/generated-from-backend/models';
import { FilterType } from '@lib/filter-panel';

export enum GetGroupInfoType {
  PULL_REQUESTS = 'pull_requests',
  JIRA = 'jira',
  RELEASES = 'releases',
}

export interface IGetGranularityParams {
  dateFrom: string;
  dateTo: string;
}

export type IFilterBreakdownMap = {
  [key in FilterType]?: string;
};

export type IGetMetrics = (PullRequestMetricID | JIRAMetricID | ReleaseMetricID)[];
