import React from 'react';

import { OutcomeMainMetrics as MainMetrics } from '@analytics-components/MainMetrics';
import { useUserContext } from '@common-context/User';
import { number } from '@common-services/format';
import * as NumberService from '@common-services/numberService';

import Insights from './insights';
import { subLabelStyles } from './styles';

const mainMetricsDefinitions = (hasJira) => [
  ...(hasJira
    ? [
        {
          title: 'Tickets',
          dataGetter: (data) => [
            { raw: data.tickets.avg, formatted: data.tickets.avg },
            data.tickets.variation,
          ],
          hint: {
            primaryText: 'Number of tickets resolved during the time period selected.',
          },
          emptyMessage: 'No tickets',
        },
        {
          title: 'PR Mapping',
          dataGetter: (data) => [
            { raw: data.GHJIRAMapping.avg, formatted: number.percentage(data.GHJIRAMapping.avg) },
            data.GHJIRAMapping.variation,
          ],
          hint: {
            primaryText: 'Proportion of pull requests mapped to Jira tickets.',
          },
          emptyMessage: 'No PRs',
        },
      ]
    : [
        {
          title: 'PR Throughput',
          dataGetter: (data) => [
            {
              raw: data.prThroughput.avg,
              formatted: (
                <>
                  {NumberService.round(data.prThroughput.avg)}
                  <span css={subLabelStyles}> / dev</span>
                </>
              ),
            },
            data.prThroughput.variation,
          ],
          hint: {
            primaryText: 'Average number of pull requests created per contributor.',
          },
          emptyMessage: 'No PRs',
        },
        {
          title: 'Lines Changed',
          dataGetter: (data) => [
            {
              raw: data.linesReleased.avg,
              formatted: number.roundByThousand(data.linesReleased.avg),
            },
            data.linesReleased.variation,
          ],
          hint: {
            primaryText: 'Total number of lines of code changed.',
          },
          emptyMessage: 'No releases',
        },
      ]),
  {
    title: 'Released PRs',
    dataGetter: (data) => [
      { raw: data.releasedPRs.avg, formatted: data.releasedPRs.avg },
      data.releasedPRs.variation,
    ],
    hint: {
      primaryText:
        'Number of pull requests released during the time period selected ie. counting in the PR Cycle Time.',
    },
    emptyMessage: 'No releases',
  },
  {
    title: 'Release Freq.',
    dataGetter: (data) => {
      const [releaseRate, releaseRateUnit] = data.releases.releaseRateSpecs;
      return [
        {
          raw: releaseRate,
          formatted: (
            <>
              {releaseRate}
              <span className="text-secondary text-s ml-1">/ {releaseRateUnit}</span>
            </>
          ),
        },
        data.releases.variation,
      ];
    },
    hint: {
      primaryText: 'Release Frequency for the time period and set of repositories selected.',
      learnMore: {
        params: { label: 'Release Frequency', path: 'releaseFrequency' },
        noProd: true,
      },
    },
    emptyMessage: 'No releases',
  },
];

const Content = ({ data, status }) => {
  const { account } = useUserContext();

  return (
    <>
      <MainMetrics data={data} status={status} definitions={mainMetricsDefinitions(account.jira)} />
      <Insights />
    </>
  );
};

export default Content;
