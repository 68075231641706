import React, { useCallback, useMemo, useState } from 'react';
import { Handle, Position } from 'react-flow-renderer';

import { GoalsSingleComponentServices } from '@align-pages/goals/single/components/goals-single-component';
import { IGoalMetricValue } from '@align-services/api/types/goalsTypes';
import { getRawValue, MetricService } from '@align-services/metricService';
import { ValueType } from '@align-types/constants';
import { getTeamColor } from '@common-services/colorService';
import { DateService } from '@common-services/dateService';
import { NumberService } from '@common-services/numberService';
import { Avatar } from '@lib/avatar';

import { ExpandFooter } from './components/expand-footer';
import { NewTargetBlock } from './components/new-target-block';
import { ReferenceValue } from './components/reference-value';
import { SetTargetBlock } from './components/set-target-block';
import { TeamNodeServices } from './teamNode.services';
import { wrapperStyles } from './teamNode.styles';
import { ITeamNode } from './teamNode.types';

const TeamNode: React.FC<ITeamNode> = React.memo(({ data }) => {
  const [isTargetOpen, setIsTargetOpen] = useState(false);
  const [newValue, setNewValue] = useState<IGoalMetricValue>(null);
  // conversionValue is used to convert date values into milliseconds
  const [conversionValue, setConversionValue] = useState(1);

  const targetValue = useMemo<IGoalMetricValue>(() => {
    const target = data.targetValues.find((value) => value.teamId === data.goal?.team.id);
    if (target) {
      return target.remove ? null : target.target;
    }
    return data.goal?.value?.target || null;
  }, [conversionValue, data]);

  const referenceValue = useMemo<string>(() => {
    if (data.isTLO) {
      const rawReferenceValue = data.goal?.value?.initial
        ? GoalsSingleComponentServices.normaliseMetricValue(data.goal?.value?.initial)
        : null;

      return rawReferenceValue
        ? NumberService.round(parseFloat(rawReferenceValue.toString()) * 100, 0).toString()
        : null;
    }

    if (data.valueType === ValueType.date && data.goal?.value?.initial?.str) {
      const [value] = DateService.getBestTimeUnit(
        parseInt(data.goal.value.initial.str) * 1000,
        null,
        false
      );
      setConversionValue(value);
    }
    return data.goal?.value?.initial && data.valueType
      ? data.unit?.isFrequency
        ? TeamNodeServices.getReferenceValueWithFrequency(
            data.range,
            data.goal.value.initial,
            setConversionValue
          )
        : MetricService.getReadableValue(data.goal.value.initial, data.valueType, data.unit)
      : null;
  }, [data]);

  const handleCardCLick = useCallback(() => {
    if (!isTargetOpen) setIsTargetOpen(true);
  }, [isTargetOpen]);

  const handleCloseClick = useCallback(() => {
    setIsTargetOpen(false);
    setNewValue(null);
  }, []);

  const handleTargetValueChange = useCallback(
    (value: string) => {
      /**
       * TODO: Instead of checking NaN, make sure the input not returning NaN
       * Can be done by showing current value when it's first swithced to the edit target mode
       * This way, increase/decarease wont try to change null value
       * https://athenianco.atlassian.net/browse/DEV-5339
       */
      if (value !== 'NaN') {
        const convertedServerValue = GoalsSingleComponentServices.convertReadableToServerValue(
          value,
          data.isTLO ? ValueType.int : data.valueType,
          data.unit,
          conversionValue
        );
        /*TODO: remove after complete moving from GQL to REST*/
        const gqlSerializedData = GoalsSingleComponentServices.deNormaliseMetricValue(
          convertedServerValue
        );
        setNewValue(gqlSerializedData);
      }
    },
    [conversionValue, data]
  );

  const handleSetTarget = useCallback(() => {
    data.onSetTarget(
      data.goal,
      data.isTLO
        ? {
            float: newValue.int / 100,
          }
        : newValue,
      data.targetValues,
      data.threshold
    );
    setIsTargetOpen(false);
  }, [data, newValue]);

  const handleRemoveTarget = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      // when Remove button is clicked do not also trigger handleCardCLick call
      e.stopPropagation();
      data.onSetTarget(
        data.goal,
        { str: null, float: null, int: null },
        data.targetValues,
        data.threshold
      );
      setIsTargetOpen(false);
    },
    [data]
  );

  const teamColor = useMemo(() => getTeamColor(data.goal?.team.name), [data]);

  return (
    <>
      <Handle type="target" position={Position.Top} style={{ visibility: 'hidden' }} />
      <div css={wrapperStyles(isTargetOpen)}>
        <div className="goal-content" onClick={handleCardCLick}>
          <div className="team">
            <Avatar color={teamColor} label={data.goal?.team?.name || ''} size={22} />
            <div className="team-name">{data.goal?.team?.name}</div>
          </div>
          <div className="goal-details">
            <ReferenceValue value={referenceValue} isTLO={data.isTLO} />
            {isTargetOpen ? (
              <NewTargetBlock
                dateRange={data.range}
                onClose={handleCloseClick}
                onSetTarget={handleSetTarget}
                onValueChange={handleTargetValueChange}
              />
            ) : (
              targetValue &&
              getRawValue(targetValue) && (
                <SetTargetBlock
                  {...data}
                  conversionValue={conversionValue}
                  targetValue={targetValue}
                  onRemove={handleRemoveTarget}
                  isTLO={data.isTLO}
                />
              )
            )}
          </div>
        </div>
        {!!data.goal?.children?.length && <ExpandFooter {...data} />}
        <Handle type="source" position={Position.Bottom} style={{ visibility: 'hidden' }} />
      </div>
    </>
  );
});

export { TeamNode };
