/**
 * {{ title }}
 * Server version: {{ server_version }} built on {{ build_date }} @ [{{ commit }}](https://github.com/athenianco/athenian-api/commit/{{ commit }})  Origin GitHub repository: [athenianco/api-spec](https://github.com/athenianco/api-spec).  Authorization persists between sessions. 🛡️ means that the endpoint requires account admin privileges. 👑 means that the endpoint is only accessible to Athenian authorities. <details> <summary>List of common server errors.</summary> * __500__ endpoint crashed. We reported the incident to Sentry and will fix it soon! Please include the value of `instance` in the response if you contact the support. Repeating the request will not help, most probably. * __501__ some functions are missing, either because they are not implemented yet or a non-critical runtime dependency is not satisfied. Repeating the request will not help, guaranteed. * __502__ server crashed badly, either due to a memory access violation in native code or running out of memory. We reported this incident to Sentry and will fix it soon! Try repeating the request. * __503__ server has not fully launched yet, e.g. hasn't connected to the database; server is shutting down; we are experiencing a partial outage. Try repeating the request. * __504__ endpoint took too much time and was interrupted. We reported the incident to Sentry and will see how to improve the performance. Repeating the request will not help, most probably. </details>
 *
 * Contact: admin@athenian.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import LogicalDeploymentRules from './LogicalDeploymentRules';
import LogicalPRRules from './LogicalPRRules';
import ReleaseMatchSetting from './ReleaseMatchSetting';

/**
 * The LogicalRepository model module.
 * @module model/LogicalRepository
 */
class LogicalRepository {
    /**
     * Constructs a new <code>LogicalRepository</code>.
     * Set of rules to match PRs, releases, and deployments that has a name and pretends to be a regular GitHub repository everywhere in UI and API requests. Release settings are also visible in &#x60;/settings/release_match&#x60;. 
     * @alias module:model/LogicalRepository
     * @param name {String} The logical part of the repository name. Compared to GitHub repository name requirements, we additionally allow / to express the hierarchy further. 
     * @param parent {String} Repository name which uniquely identifies any repository in any service provider. The format matches the repository URL without the protocol part. No \".git\" should be appended. We support a special syntax for repository sets: \"{reposet id}\" adds all the repositories from the given set. 
     * @param prs {module:model/LogicalPRRules} 
     * @param releases {module:model/ReleaseMatchSetting} 
     */
    constructor(name, parent, prs, releases) { 
        
        LogicalRepository.initialize(this, name, parent, prs, releases);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, name, parent, prs, releases) { 
        obj['name'] = name;
        obj['parent'] = parent;
        obj['prs'] = prs;
        obj['releases'] = releases;
    }

    /**
     * Constructs a <code>LogicalRepository</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/LogicalRepository} obj Optional instance to populate.
     * @return {module:model/LogicalRepository} The populated <code>LogicalRepository</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LogicalRepository();

            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('parent')) {
                obj['parent'] = ApiClient.convertToType(data['parent'], 'String');
            }
            if (data.hasOwnProperty('prs')) {
                obj['prs'] = LogicalPRRules.constructFromObject(data['prs']);
            }
            if (data.hasOwnProperty('releases')) {
                obj['releases'] = ReleaseMatchSetting.constructFromObject(data['releases']);
            }
            if (data.hasOwnProperty('deployments')) {
                obj['deployments'] = LogicalDeploymentRules.constructFromObject(data['deployments']);
            }
        }
        return obj;
    }


}

/**
 * The logical part of the repository name. Compared to GitHub repository name requirements, we additionally allow / to express the hierarchy further. 
 * @member {String} name
 */
LogicalRepository.prototype['name'] = undefined;

/**
 * Repository name which uniquely identifies any repository in any service provider. The format matches the repository URL without the protocol part. No \".git\" should be appended. We support a special syntax for repository sets: \"{reposet id}\" adds all the repositories from the given set. 
 * @member {String} parent
 */
LogicalRepository.prototype['parent'] = undefined;

/**
 * @member {module:model/LogicalPRRules} prs
 */
LogicalRepository.prototype['prs'] = undefined;

/**
 * @member {module:model/ReleaseMatchSetting} releases
 */
LogicalRepository.prototype['releases'] = undefined;

/**
 * @member {module:model/LogicalDeploymentRules} deployments
 */
LogicalRepository.prototype['deployments'] = undefined;






export default LogicalRepository;

