import React, { useCallback, useState } from 'react';

import { fieldWrapperStyles } from '@common-pages/Settings/Allocation/WorkTypes/rulesDef/IssueType/styles';
import DropdownSelector from '@lib/dropdown-selector';

const GithubLabel = React.memo(({ allLabels, initialValue, onChange }) => {
  const [selectedLabels, setSelectedLabels] = useState(initialValue || []);

  const handleChange = useCallback(
    (value) => {
      setSelectedLabels(value);
      onChange(value);
    },
    [selectedLabels]
  );

  return (
    <div css={fieldWrapperStyles}>
      <DropdownSelector
        initialValue={selectedLabels}
        noAvatar
        options={allLabels}
        placeholder="All labels"
        onChange={handleChange}
      />
    </div>
  );
});

export default GithubLabel;
