import { css } from '@emotion/react';

export const wrapperStyles = ({ spacing }) => css`
  padding-bottom: ${spacing.gap['07']};
  .target-value-text {
    align-items: center;
    display: flex;
    margin-bottom: ${spacing.gap['04']};

    .target-period {
      margin-right: ${spacing.gap['03']};
    }
  }
`;

export const badgeStyles = ({ spacing }) => css`
  align-items: center;
  display: flex;

  .remove-button {
    margin-left: ${spacing.gap['02']};
  }
`;
