import { css } from '@emotion/react';

export const wrapperStyles = css`
  display: inline-block;
  position: relative;
`;

export const selectStyles = ({ color, radius, spacing, transition }) => css`
  align-items: center;
  background-color: ${color.neutral.white};
  border: 1px solid ${color.neutral[40]};
  border-radius: ${radius.default};
  cursor: pointer;
  display: inline-flex;
  height: 42px;
  padding-left: ${spacing.gap['04']};
  padding-right: ${spacing.gap['04']};
  transition: ${transition.default};

  svg {
    margin-left: ${spacing.gap['04']};
  }

  &:hover {
    border: 1px solid ${color.neutral[60]};
  }

  &:focus {
    border: 1px solid ${color.neutral[80]};
  }
`;

export const dropdownStyles = ({ color, radius, spacing, shadow }) => css`
  background-color: ${color.neutral.white};
  border: 1px solid ${color.neutral[40]};
  box-shadow: ${shadow.full};
  border-radius: ${radius.default};
  margin-top: ${spacing.gap['06']};
  padding: ${spacing.gap['04']} ${spacing.gap['05']} ${spacing.gap['05']} ${spacing.gap['05']};
  position: absolute;
  right: 0;
`;
