import _ from 'lodash';

import HorizontalBarChart from '@analytics-components/charts/HorizontalBarChart';
import { UserReviewer } from '@analytics-components/charts/Tooltip';
import { SimpleKPI } from '@analytics-components/insights/KPI';
import {
  COMMENTS,
  REVIEWED,
} from '@analytics-pages/delivery-pipeline/insights/review/ReviewActivityPanel';
import { fetchDevsMetrics } from '@analytics-services/api';
import { getFeature, featuresList } from '@analytics-services/flags';
import { github, number } from '@common-services/format';
import { theme } from '@styles/theme';
import { hexToRGBA } from '@utils/colors';

const reviewActivityHorizontalBar = {
  prefetchedDataIds: ['avatars'],
  fetcher: async (api, cachedData, apiContext) => {
    const metrics = ['prs-reviewed', 'prs-created', 'pr-comments'];
    const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, apiContext.features)
      ?.parameters?.value;
    const fetchMetrics = async () => {
      return fetchDevsMetrics(
        api,
        apiContext.account,
        ['all'],
        apiContext.interval,
        metrics,
        {
          repositories: apiContext.repositories,
          developers: _(apiContext.contributors).uniqBy('login').value(),
          labels_include: apiContext.labels,
          ...(excludedLabels ? { labels_exclude: excludedLabels } : {}),
          jira: { epics: apiContext.epics, issue_types: apiContext.issueTypes },
        },
        null
      );
    };

    const metricsRes = await fetchMetrics();
    return {
      metrics: metricsRes?.calculated.length > 0 ? metricsRes.calculated[0] : { values: [] },
    };
  },
  plumber: (fetchedData, cachedData) => {
    const metrics = fetchedData.metrics;

    const totalReviews = _(metrics.values)
      .map((v) => v[0].values[REVIEWED])
      .sum();

    const totalPRsComments = _(metrics.values)
      .map((v) => v[0].values[COMMENTS])
      .sum();

    const totalReviewers = _(metrics.values)
      .filter((v) => v[0].values[REVIEWED] > 0)
      .value().length;

    const chartData = _(metrics.values)
      .map((v, index) => {
        const [reviewsTotal, prsCommentsTotal] = [v[0].values[REVIEWED], v[0].values[COMMENTS]];
        const [reviewsPerc, prsCommentsPerc] = [
          (reviewsTotal * 100) / (totalReviews || 1),
          (prsCommentsTotal * 100) / (totalPRsComments || 1),
        ];
        const devName = metrics.for.developers[index];
        const author = github.userName(devName);

        return {
          developer: author,
          reviewsPerc,
          prsCommentsPerc,
          tooltip: {
            author,
            image: cachedData.avatars[devName],
            reviewsPerc: {
              number: reviewsTotal,
              percentage: reviewsPerc,
            },
            prsCommentsPerc: {
              number: prsCommentsTotal,
              percentage: prsCommentsPerc,
            },
          },
        };
      })
      .filter((v) => v.reviewsPerc > 0 || v.prsCommentsPerc > 0)
      .orderBy(['prsCommentsPerc', 'reviewsPerc'], ['desc', 'desc'])
      .take(10)
      .value();

    const topReviewer = _(metrics.values)
      .map((v, index) => ({
        developer: github.userName(metrics.for.developers[index]),
        reviewsPerc: (v[0].values[REVIEWED] * 100) / (totalReviews || 1),
        prsCommentsPerc: (v[0].values[COMMENTS] * 100) / (totalPRsComments || 1),
      }))
      .orderBy(['prsCommentsPerc'], ['desc'])
      .take(1)
      .nth(0);

    const computed = {
      axisKeys: {
        x: ['prsCommentsPerc', 'reviewsPerc'],
        y: 'developer',
      },
      KPIsData: {
        reviewers: totalReviewers,
        topReviewer: topReviewer,
      },
      avatars: _({ ...cachedData.avatars }).forOwn((value, key, object) => {
        // modify avatars object so that keys only have a username without GH domain
        delete object[key];
        object[key.substring(key.indexOf('/') + 1)] = value;
      }),
    };

    const {
      axisKeys,
      KPIsData: { reviewers },
      avatars,
    } = computed;

    return {
      empty: chartData.length === 0,
      chart: {
        component: HorizontalBarChart,
        params: {
          data: chartData,
          tickFormat: (tick) => `${tick}%`,
          extra: {
            yAxis: {
              image: true,
              imageMask: 'circle',
            },
            axisKeys: axisKeys,
            series: {
              prsCommentsPerc: {
                name: 'Review Comments',
                color: hexToRGBA(theme.color.stage.review, 0.7),
              },
              reviewsPerc: {
                name: 'Pull Requests Reviewed',
                color: hexToRGBA(theme.color.neutral[60], 0.7),
              },
            },
            tooltip: {
              align: {
                horizontal: 'right',
                vertical: 'auto',
              },
              template: UserReviewer,
            },
            avatars,
          },
        },
      },
      kpis: [
        {
          title: { text: 'Pull Requests Reviewers', bold: true },
          component: SimpleKPI,
          params: {
            value: reviewers,
          },
        },
        {
          title: { text: 'Proportion of Reviews Comments', bold: true },
          subtitle: {
            text: `Made by ${topReviewer?.developer}` || '',
          },
          component: SimpleKPI,
          params: {
            value: topReviewer ? number.fixed(topReviewer.prsCommentsPerc, 1) : '',
            unit: '%',
          },
        },
      ],
    };
  },
};

export default reviewActivityHorizontalBar;
