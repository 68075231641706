import { useMemo } from 'react';

import { ObjectService } from '@common-services/objectService';
import { SelectTsOptionSingle } from '@lib/selector-ts';

export function useSelectedIds(options: SelectTsOptionSingle[]): string[] {
  const memoSelectedIds = useMemo<string[]>(() => {
    let selectedIdsArr: string[] = [];
    options.forEach((option) => {
      const selectedIdInObj: string[] = ObjectService.getNestedObjAllIdsByKeyValue(
        option,
        'isSelected',
        true
      );
      selectedIdsArr = [...selectedIdsArr, ...selectedIdInObj];
    });
    return selectedIdsArr;
  }, [options]);
  return memoSelectedIds;
}
