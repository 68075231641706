import { SelectTsOptionSingle } from '@lib/selector-ts';

interface ITeamsMember {
  login: string;
  name: string;
  email?: string;
  picture?: string;
  jira_user?: string;
  team?: string;
}

export interface ITeams {
  id: string;
  parent: string;
  name: string;
  members: ITeamsMember[];
}

export function teamsSerializer(
  incTeams: ITeams[],
  team: ITeams,
  selectedTeams: string[],
  parentIds: string[]
): SelectTsOptionSingle {
  const selected = incTeams.filter((team) => selectedTeams.includes(team.name));
  const parentArr: string[] = parentIds !== null && team.parent ? [...parentIds, team.parent] : [];
  return {
    id: team.id,
    name: team.name,
    isSelected: selectedTeams.includes(team.name),
    isOpened: !!selected.find((selected) => selected.parent === team.id),
    parentIds: parentArr.filter((n) => n),
  };
}

export function convertTeamsRecursion(
  incTeams: ITeams[],
  selectedTeams: string[],
  childId: string | null = null,
  parentIds: string[] = []
): SelectTsOptionSingle[] {
  return incTeams
    .filter((item) => item.parent === childId)
    .map((team) => {
      const children =
        team.id === null
          ? []
          : convertTeamsRecursion(incTeams, selectedTeams, team.id, [...parentIds, team.parent]);
      return {
        ...teamsSerializer(incTeams, team, selectedTeams, parentIds),
        children,
      };
    });
}

export * as TeamsService from './teams';
