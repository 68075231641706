import { BasicApiClass } from '@common-services/api/common/BasicApiClass';
import { IUrlParams, REST_TYPES } from '@common-services/api/common/types/apiClient';
import { ApiMode } from '@common-services/api/common/types/common';
import { ISentryMethodParams } from '@common-services/api/common/types/sentry';
import { PRIVATE_API_ENDPOINTS_CONSTANTS } from '@common-services/api/private/constants';
import { TeamTree } from '@common-services/api/private/generated-from-backend/models';
import { ITeamTreeParams } from '@common-services/api/private/types/teamTree';

export class TeamTreeApi extends BasicApiClass {
  constructor() {
    super(ApiMode.PRIVATE);
  }
  public getTeamTree(
    urlParams: IUrlParams<'teamId'>,
    params: ITeamTreeParams,
    token: string = null,
    sentry?: ISentryMethodParams
  ): Promise<TeamTree> {
    this.assignToken(token);
    const apiPath: string = this.replaceRouteLink(
      PRIVATE_API_ENDPOINTS_CONSTANTS.ALIGN_TEAM_TREE,
      urlParams
    );
    const callApi = this.callApi<TeamTree>(`${apiPath}?account=${params.account}`, REST_TYPES.GET);
    return sentry ? this.callSentry<TeamTree>(callApi, sentry) : callApi;
  }
}
