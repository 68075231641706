import _ from 'lodash';

import { contentTypes } from '@analytics-context/Allocation';
import {
  cutLongName,
  sortData,
  generateDisplayData,
} from '@analytics-pages/outcome/insights/allocation/work-types/helpers';
import { theme } from '@styles/theme';
import { ProgressBarMulti } from '@ui/ProgressBar';

import { IAllocationType } from '../types';

const throughputTotal = {
  plumber: (fetchedData, apiContext, appliedWorkTypes) => {
    // sort PRs data by their corresponding work types as the order with which it comes from API does not guarantee it
    const sortedPrsData = sortData(fetchedData.allPrs, appliedWorkTypes);
    // remove the last item from the data array which stands for Other section
    const workTypesPrsData = sortedPrsData.slice(0, -1);
    const workTypesIssuesData = fetchedData.allIssues.slice(0, -1);
    // pick the last item from data array for calculating Other section
    const allPrs = sortedPrsData.slice(-1)[0]?.values[0].values[0] || 0;
    const allIssues = fetchedData.allIssues.slice(-1)[0]?.[0].values[0].values[0] || 0;

    const prsData = generateDisplayData({
      data: workTypesPrsData,
      workTypes: appliedWorkTypes,
      type: IAllocationType.prs,
      allData: allPrs,
    });

    const issuesData = generateDisplayData({
      data: workTypesIssuesData,
      workTypes: appliedWorkTypes,
      type: IAllocationType.issues,
      allData: allIssues,
    });

    return {
      interval: apiContext.interval,
      issuesData,
      prsData,
    };
  },
  factory: (computed, contentType) => {
    const chartData = contentType === contentTypes.prs ? computed.prsData : computed.issuesData;
    const params = _(chartData)
      .map((v, i) => ({
        value: v.count,
        color: i < chartData.length - 1 ? v.color : theme.color.neutral[80],
        labelLeft: cutLongName(v.type),
        labelRight: v.count,
      }))
      .value();

    return {
      meta: { title: 'Total', description: '' },
      empty: chartData.reduce((acc, item) => acc + item.count, 0) === 0,
      content: [
        {
          chart: {
            component: ProgressBarMulti,
            params: {
              params,
              normalize: true,
              uppercase: false,
            },
          },
          kpis: [],
        },
      ],
    };
  },
};

export default throughputTotal;
