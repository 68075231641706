import { breakdownConfig } from '@align-constants';
import {
  BreakdownOrder,
  ICalculateWeight,
} from '@align-pages/dashboard/components/chart-edit/chartEdit.types';
import { dashboardChartIntervals } from '@align-pages/dashboard/config';
import { IUseBreakdownOptionsData } from '@align-pages/dashboard/hooks/types';
import { IBreakdownOptionItem } from '@align-pages/dashboard/reducers/breakdownOptionsReducer.types';
import { DashboardBreakdownServices } from '@align-pages/dashboard/services/dashboardBreakdownServices';
import { ISeriesInfo } from '@align-services/api/metricService.types';
import { DateService } from '@align-services/dateService';
import {
  DashboardChartFilters,
  DashboardChartGroupBy,
} from '@common-services/api/private/generated-from-backend/models';
import { github } from '@common-services/format';
import { uniqBy } from '@common-services/vendor/lodash';
import { FilterType } from '@lib/filter-panel';

export const transformToBreakdownItem = (
  options: IUseBreakdownOptionsData,
  type: FilterType
): IBreakdownOptionItem[] => {
  switch (type) {
    case FilterType.TEAMS:
      return options[FilterType.TEAMS]?.map((option) => ({
        value: option.id.toString(),
        name: option.name,
      }));
    case FilterType.REPOSITORY:
      return options[FilterType.REPOSITORY]?.map((repo) => ({
        value: repo,
        name: github.repoName(repo),
      }));
    case FilterType.JIRA_PRIORITIES:
      return options[FilterType.JIRA_PRIORITIES]?.map((option) => ({
        value: option.name,
        name: option.name,
        extras: {
          color: option.color,
          index: option.rank,
          image: option.image,
        },
      }));
    case FilterType.JIRA_ISSUE_TYPES:
      return uniqBy(options[FilterType.JIRA_ISSUE_TYPES], 'normalized_name')?.map((option) => ({
        value: option.normalized_name,
        name: option.name,
        extras: {
          image: option.image,
        },
      }));
    case FilterType.JIRA_LABELS:
      return options[FilterType.JIRA_LABELS]?.map((option) => ({
        value: option.title,
        name: option.title,
        extras: {
          kind: option.kind,
        },
      }));
    default:
      return [];
  }
};

export const filterBreakdownOptions = (
  options: IBreakdownOptionItem[],
  filters: string[]
): IBreakdownOptionItem[] =>
  filters ? options.filter((option) => filters.includes(option.value.toLowerCase())) : options;

interface IGenerateBreakdownOptions {
  options: IUseBreakdownOptionsData;
  filters: DashboardChartFilters;
  type: FilterType;
}

export const generateBreakdownOptions = ({
  options,
  filters,
  type,
}: IGenerateBreakdownOptions): IBreakdownOptionItem[] => {
  const matchingFilters: string[] = DashboardBreakdownServices.getGroupByFromFilters(filters)[type];
  const optionItems = transformToBreakdownItem(options, type);

  return filterBreakdownOptions(optionItems, matchingFilters);
};

export const breakdownOptionsToGroupBy = (
  options: IUseBreakdownOptionsData
): DashboardChartGroupBy => {
  if (options.teams) {
    return {
      teams: options.teams.map(({ id }) => id),
    };
  } else if (options.repositories) {
    return {
      repositories: options.repositories,
    };
  } else if (options.jira_priorities) {
    return {
      jira_priorities: options.jira_priorities.map(({ name }) => name),
    };
  } else if (options.jira_issue_types) {
    return {
      jira_issue_types: options.jira_issue_types.map(({ normalized_name }) => normalized_name),
    };
  } else if (options.jira_labels) {
    return {
      jira_labels: options.jira_labels.map(({ title }) => title),
    };
  }

  return {};
};

export const extractOrderFromMultiSeries = (multiSeries: ISeriesInfo[]): BreakdownOrder[] => {
  return multiSeries?.length
    ? multiSeries.map(({ id, counter }) => ({
        id,
        weight: counter,
      }))
    : [];
};

export const calculateWeight = ({ type, data, orderList, value }: ICalculateWeight): number => {
  switch (type) {
    case FilterType.JIRA_PRIORITIES:
      return data.jira_priorities.find(({ name }) => name === value)?.rank || 0;
    default:
      return orderList.find(({ id }) => id === value)?.weight || 0;
  }
};

export const applyOrderToBreakdownOptions = (
  options: IBreakdownOptionItem[],
  orderList: BreakdownOrder[],
  data: IUseBreakdownOptionsData,
  type: FilterType
): IBreakdownOptionItem[] => {
  const sortedOptions =
    options?.sort((firstItem, secondItem) => {
      const firstItemWeight = calculateWeight({ type, data, orderList, value: firstItem.value });
      const secondItemWeight = calculateWeight({ type, data, orderList, value: secondItem.value });

      return secondItemWeight - firstItemWeight;
    }) || [];

  // Priority ranks have the highest priority for smaller number. Reverse the sorting if priority rank applied.
  return type === FilterType.JIRA_PRIORITIES ? sortedOptions.reverse() : sortedOptions;
};

export const applySelectionToBreakdownOptions = (
  options: IBreakdownOptionItem[],
  groupBy: DashboardChartGroupBy,
  type: FilterType
): IBreakdownOptionItem[] => {
  const groupByList = groupBy?.[type]?.map((item) => item.toString()) || null;
  return options.map((option, index) => ({
    ...option,
    selected: groupByList
      ? groupByList.includes(option.value)
      : index < breakdownConfig.optionsInitialSelection,
  }));
};

export const getDateRangeOptions = () =>
  dashboardChartIntervals.map((option) => ({
    name: DateService.durationToReadableText({ duration: option, prefix: 'Last' }),
    value: option,
  }));

export * as ChartEditServices from './chartEdit.services';
