import React, { useCallback, useState, useRef, useEffect } from 'react';

import { useOnClickOutside } from '@common-hooks/useOnClickOutside';

import { wrapperStyles, inputStyles } from './editableName.styles';
import { IEditableName } from './editableName.types';

export const EditableName: React.FC<IEditableName> = React.memo(
  ({ defaultValue, isEditable = true, onNameChange }) => {
    const [isInputEdited, setIsInputEdited] = useState(false);
    const [inputValue, setInputValue] = useState(defaultValue);
    const [isEditMode, setIsEditMode] = useState(false);
    const inputRef = useRef(null);

    const inputClickHandler = useCallback(
      (event) => {
        !isEditMode && event.target.select();
        isEditable && !isEditMode && setIsEditMode(true);
      },
      [isEditMode, isEditable]
    );

    const inputChangeHandler = useCallback(
      (event) => {
        setIsInputEdited(true);
        setInputValue(event.target.value);
        onNameChange && onNameChange(event.target.value || defaultValue);
      },
      [defaultValue]
    );

    const handleInputBlur = () => {
      setIsEditMode(false);

      if (!inputValue) {
        setInputValue(defaultValue);
        setIsInputEdited(false);
      }
    };

    const onSubmitCallback = useCallback((event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      inputRef.current.blur();
      handleInputBlur();
    }, []);

    useOnClickOutside([inputRef], () => {
      handleInputBlur();
    });

    useEffect(() => {
      if (!isInputEdited) {
        setInputValue(defaultValue);
        onNameChange && onNameChange(defaultValue);
      }
    }, [defaultValue, isInputEdited]);

    return (
      <form css={wrapperStyles} onSubmit={onSubmitCallback}>
        <input
          ref={inputRef}
          css={inputStyles({ isEditMode, isEditable })}
          value={inputValue}
          onChange={inputChangeHandler}
          onClick={inputClickHandler}
          disabled={!isEditable}
        />
      </form>
    );
  }
);
