import { ArrayService } from '@common-services/arrayService';
import { isNotProd } from '@utils/development';

const getAnalytics = (debug) => {
  const environment = window.ENV?.environment || process.env?.NODE_ENV || '';

  window.analytics = window.analytics || {};
  ['page', 'identify', 'track', 'debug'].forEach((m) => {
    if (window.ENV?.segment?.writeKey && typeof window.analytics.on === 'function') {
      window.analytics?.on(m, (event, properties, options) => {
        console.log(`analytics emitter: ${m}`, event, properties, options);
      });
    } else {
      window.analytics[m] = (...args) => {
        const cbIndex = {
          page: 4,
          identify: 3,
          track: 3,
        }[m];
        if (cbIndex && args[cbIndex]) {
          args[cbIndex]();
        }
      };
    }
  });

  const identify = (user) => {
    if (!user || window.ENV.segment.identifyCalled) {
      return;
    }

    window.ENV.segment.identifyCalled = true;
    window.analytics.identify(user.id, {
      name: user.name,
      email: user.email,
      picture: user.picture,
      updated: new Date(parseInt(user.updated.getTime() / 1000)).toString(),
      isAdmin: user.defaultAccount.isAdmin,
      githubOrgs: ArrayService.getUniqueValues(
        user.defaultReposet.repos.map((r) => r.split('/')[1]).toString()
      ),
    });
  };

  const page = (name, properties) => window.analytics.page(name, { ...properties, environment });
  const track = (event, properties, cb) => {
    window.analytics.track(event, { ...properties, environment }, null, cb);
  };

  if (debug) {
    window.analytics.debug(true);
  }

  return {
    identify,
    page,
    track,
  };
};

export const analytics = getAnalytics(isNotProd);
