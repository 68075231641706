import { withSentryCapture } from '@analytics-services/api';
import apiClient from '@analytics-services/api/openapi-client/ApiClient';
import { REST_TYPES } from '@common-services/api/common/types/apiClient';
import {
  ApiInstanceName,
  ApiMode,
  SentryCaptureOutput,
} from '@common-services/api/common/types/common';
import { ISentryMethodParams } from '@common-services/api/common/types/sentry';
import { StringService } from '@common-services/stringService';

export class BasicApiClass {
  private apiMode: ApiMode = ApiMode.PUBLIC;
  protected apiInstanceName: ApiInstanceName = ApiInstanceName.PUBLIC;
  private pathParams = {};
  private queryParams = {};
  private headerParams = {};
  private formParams = {};
  private authNames = ['bearerAuth'];
  private contentTypes = ['application/json'];
  private accepts = ['application/json'];
  private returnType = null;
  private apiBasePath = null;
  constructor(apiMode: ApiMode) {
    this.apiMode = this.getApiMode(apiMode);
    this.apiInstanceName = this.getInstanceName();
    this.assignInstanceName();
    apiClient[this.apiInstanceName].defaultHeaders = {
      'X-Athenian-Channel': window?.ENV?.api?.channel || 'stable',
    };
    apiClient[this.apiInstanceName].timeout = 5 * 60 * 1000;
  }
  protected getInstanceName(): ApiInstanceName {
    switch (this.apiMode) {
      case ApiMode.PUBLIC:
        return ApiInstanceName.PUBLIC;
      case ApiMode.PRIVATE:
        return ApiInstanceName.PRIVATE;
      default:
        return ApiInstanceName.PUBLIC;
    }
  }
  protected assignInstanceName() {
    apiClient[this.apiInstanceName].basePath = process.env.isTesting
      ? `localhost/${this.apiMode === ApiMode.PUBLIC ? 'v1' : 'private'}`
      : this.getApiBasePath();
  }
  protected getApiMode(apiMode: ApiMode): ApiMode {
    return window.MOCK ? ApiMode.MOCK : apiMode;
  }
  protected getApiBasePath(): string {
    switch (this.apiMode) {
      case ApiMode.PUBLIC:
        return window?.ENV?.api?.basePath;
      case ApiMode.PRIVATE:
        const url = StringService.removePathFromUrl(window?.ENV?.api?.basePath);
        return `${url}/private`;
      case ApiMode.MOCK:
        return window.MOCK?.REST?.HOST;
      default:
        return window?.ENV?.api?.basePath;
    }
  }
  protected assignToken(token) {
    apiClient[this.apiInstanceName].authentications.bearerAuth.accessToken = token;
  }
  protected async callSentry<T extends any>(
    promise: Promise<T>,
    sentry: ISentryMethodParams
  ): Promise<T> {
    const response: SentryCaptureOutput<T> = await withSentryCapture(
      () => promise,
      sentry.message,
      sentry.rethrow,
      sentry.retries
    );
    return response.response.body;
  }
  protected async callApi<T extends any>(to: string, type: REST_TYPES, params?: any): Promise<T> {
    return apiClient[this.apiInstanceName].callApi(
      to,
      type,
      this.pathParams,
      this.queryParams,
      this.headerParams,
      this.formParams,
      params,
      this.authNames,
      this.contentTypes,
      this.accepts,
      this.returnType,
      this.apiBasePath
    );
  }
  protected replaceRouteLink(route: string, replace: { [id: string]: any }): string {
    let link: string = route.replace(/\(.*?\)/gi, '');
    link = StringService.replaceVarsInStr(link, replace);
    return this.validateRouteLink(link);
  }
  protected validateRouteLink(link: string): string {
    return link.slice(-2) === '//' ? link.slice(0, -1) : link;
  }
}
