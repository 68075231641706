import React, { useCallback, useMemo } from 'react';

import { useApi } from '@analytics-hooks';
import { setWorkType } from '@analytics-services/api';
import {
  useJiraProjects,
  useOwnedJiraFilter,
} from '@common-services/api/public/hooks/useFiltersData';

import IssueType from '.';

const IssueTypeDataBinder = ({ rule, workType }) => {
  const { api, ready: apiReady, context } = useApi(true, false);

  const { data: issueTypesData } = useOwnedJiraFilter(['issues', 'issue_types'], true);
  const { data: projectsData } = useJiraProjects(true);
  const issueTypes = useMemo(() => {
    return issueTypesData
      ? issueTypesData?.issue_types
          .filter((item) => !item.is_subtask && !item.is_epic)
          .map((item) => ({
            count: item.count,
            image: item.image,
            label: item.name,
            project: item.project,
            value: item.normalized_name,
          }))
      : [];
  }, [issueTypesData]);
  const projects = useMemo(() => {
    return projectsData
      ? projectsData
          .filter((project) => project.enabled)
          .map((item) => ({
            id: item.id,
            image: item.avatar_url,
            label: `${item.name} (${item.key})`,
            value: item.key,
          }))
      : [];
  }, [projectsData]);

  const handleChange = useCallback(
    (newValues) => {
      if (!apiReady) return;
      const modifiedRule = workType.rules.find((item) => item.name === rule.name);
      modifiedRule.body.issueTypes = newValues.issueTypes;
      modifiedRule.body.projects = newValues.projects;
      setWorkType(api, context.account, workType.name, workType.color, workType.rules);
    },
    [api, apiReady, context]
  );

  return (
    <IssueType
      allIssueTypes={issueTypes}
      allProjects={projects}
      initialValue={rule?.body}
      onChange={handleChange}
    />
  );
};

export default IssueTypeDataBinder;
