import { faClock, faEye } from '@fortawesome/free-regular-svg-icons';
import { faCodeBranch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Hint } from 'react-vis';

import { AltTitle } from '@analytics-components/Typography';
import { github, number, isNumber } from '@common-services/format';
import * as NumberService from '@common-services/numberService';

import {
  badgeStyles,
  badgesWrapperStyles,
  bigTextStyles,
  bigTextNumberStyles,
  hintContainerStyles,
  dateRangeDayMonthStyles,
} from './styles';

const Tooltip = ({ value, ...props }) => {
  if (!value) return null;

  // TODO: This needs to be styled.
  // `value` is a flat key-value object
  return (
    <Hint {...props} value={value}>
      <TooltipContainer left>
        <Group>
          {_(value)
            .map(
              (v, k) =>
                !!v && (
                  <p key={k}>
                    {k}: {v.toString()}
                  </p>
                )
            )
            .filter((v) => !!v)
            .value()}
        </Group>
      </TooltipContainer>
    </Hint>
  );
};

export const DefaultXYTooltip = ({
  value,
  dataPoint,
  x = (v) => <AltTitle content={v.x} />,
  y = (v) => <BigText content={v.y} />,
  ...props
}) => {
  if (!value) return null;
  const point = dataPoint || value;
  const left = 'left' in props ? props.left : true;
  return (
    <Hint {...props} value={value}>
      <TooltipContainer left={left}>
        <Group>
          {x(point)}
          {y(point)}
        </Group>
      </TooltipContainer>
    </Hint>
  );
};

export const GenericTooltip = ({ value, dataPoint, x = (p) => p.x, y = (p) => p.y, ...props }) => {
  if (!value) return null;
  const point = dataPoint || value;
  return (
    <DefaultXYTooltip
      value={value}
      dataPoint={{
        x: x(point),
        y: y(point),
      }}
      {...props}
    />
  );
};

const BigNumberWithHeader = ({
  value,
  dataPoint,
  renderHeaderFn,
  renderBigFn = (v) => <BigText content={NumberService.round(v.y)} />,
  ...props
}) => {
  if (!value) return null;
  return (
    <DefaultXYTooltip
      value={value}
      dataPoint={dataPoint}
      x={renderHeaderFn}
      y={renderBigFn}
      {...props}
    />
  );
};

export const DateBigNumber = ({
  value,
  dataPoint,
  renderBigFn = (v) => <BigText content={NumberService.round(v.y)} />,
  ...props
}) => {
  return (
    <BigNumberWithHeader
      value={value}
      dataPoint={dataPoint}
      renderHeaderFn={(v) => (
        <AltTitle content={<DateWeekDayMonth date={moment(v.x).utc()} uppercase />} />
      )}
      renderBigFn={renderBigFn}
      {...props}
    />
  );
};

export const DateRangeBigNumber = ({
  value,
  dataPoint,
  data,
  granularity,
  interval,
  renderBigFn = (v) => <BigText content={NumberService.round(v.y)} />,
  ...props
}) => {
  const getValue = (v) => {
    // Some charts have the `x` value as dates and others as integer timestamps.
    // We should choose one or another for consitency.
    try {
      return v.valueOf();
    } catch (err) {
      return v;
    }
  };

  if (dataPoint && data && interval) {
    dataPoint.from = dataPoint.x;

    const daysBetweenPoints = granularity === 'week' ? 7 : moment(dataPoint.x).daysInMonth();
    const daysFromPointTillEnd = Math.round(
      interval.to.value.diff(moment(dataPoint.x), 'ms') / (1000 * 60 * 60 * 24)
    );
    const nextTickIndex =
      _(data)
        .map((d) => getValue(d.x))
        .indexOf(getValue(dataPoint.x)) + 1;
    const daysFromPointTillNext = data[nextTickIndex]
      ? Math.round((data[nextTickIndex].x - dataPoint.x) / (1000 * 60 * 60 * 24))
      : 0;
    const to =
      nextTickIndex >= data.length && daysFromPointTillEnd <= daysBetweenPoints
        ? interval.to.value
        : data[nextTickIndex] && daysFromPointTillNext <= daysBetweenPoints
        ? moment(data[nextTickIndex].x).utc().subtract(1, 'days').toDate()
        : moment(data[nextTickIndex - 1].x)
            .utc()
            .add(1, granularity === 'week' ? 'weeks' : 'months')
            .subtract(1, 'days')
            .toDate();
    dataPoint.to = to;
  }

  return (
    <BigNumberWithHeader
      value={value}
      dataPoint={dataPoint}
      renderHeaderFn={(v) => (
        <AltTitle
          content={
            <DateRangeDayMonth
              dateFrom={moment(v.from).utc()}
              dateTo={moment(v.to).utc()}
              uppercase
            />
          }
        />
      )}
      renderBigFn={renderBigFn}
      {...props}
    />
  );
};

export const PullRequestReview = ({ value, ...props }) => {
  if (!value) return null;

  const tooltip = value.tooltip;
  return (
    <Hint {...props} value={value} style={{ pointerEvents: 'auto' }}>
      <TooltipContainer left>
        <Group>
          <div className="d-flex">
            <AltTitle uppercase content={`#${tooltip.number}`} />
            {isNumber(tooltip.linesAdded) && (
              <div className="text-success ml-2">+{tooltip.linesAdded}</div>
            )}
            {isNumber(tooltip.linesRemoved) && (
              <div className="text-danger ml-1">-{tooltip.linesRemoved}</div>
            )}
          </div>
          <PullRequestRepoTitle
            repo={tooltip.repository}
            title={tooltip.title}
            number={tooltip.number}
          />
        </Group>
        {tooltip.timeWaiting && (
          <Group className={tooltip.reviewed ? 'text-turquoise' : 'text-orange'}>
            <Icon icon={faClock} />
            <span>
              {tooltip.reviewed ? 'Waited' : 'Waiting'} review for {tooltip.timeWaiting}
            </span>
          </Group>
        )}
        {tooltip.timeActive && (
          <Group className={tooltip.released ? 'text-turquoise' : 'text-orange'}>
            <Icon icon={faClock} />
            <span>
              {tooltip.released ? 'Released in' : 'In progress for'} {tooltip.timeActive}
            </span>
          </Group>
        )}
        {tooltip.lifetime && (
          <Group className="text-orange">
            <Icon icon={faClock} />
            <span>Opened during {tooltip.lifetime}</span>
          </Group>
        )}
        <Group>
          <UserAvatar src={tooltip.image} name={tooltip.author} middleText="Created by" size="18" />
        </Group>
      </TooltipContainer>
    </Hint>
  );
};

export const ReleaseOrCandidate = ({ value, ...props }) => {
  if (!value) return null;

  const tooltip = value.tooltip;
  return (
    <Hint {...props} value={value} style={{ pointerEvents: 'auto' }}>
      <TooltipContainer left>
        <Group>
          <Release project={tooltip.repository} version={tooltip.name} url={tooltip.url} />
        </Group>
        <Group>
          <ReleaseStats
            PRs={tooltip.prs}
            additions={tooltip.added_lines}
            removals={tooltip.deleted_lines}
          />
        </Group>
      </TooltipContainer>
    </Hint>
  );
};

export const UserReviewer = ({ value, ...props }) => {
  if (!value) return null;

  const tooltip = value.tooltip;
  return (
    <Hint css={hintContainerStyles} {...props} value={value}>
      <TooltipContainer>
        <Group>
          <UserAvatar src={tooltip.image} name={tooltip.author} />
        </Group>
        {tooltip.stats && (
          <Group>
            <PRCommentsStats
              prs={tooltip.stats.prsCount || 0}
              comments={tooltip.stats.commentsCount || 0}
            />
          </Group>
        )}
        {tooltip.prsCommentsPerc && (
          <Group>
            <AltTitle uppercase content="Reviews comments" />
            <BigText
              content={tooltip.prsCommentsPerc.number}
              extra={number.percentage(tooltip.prsCommentsPerc.percentage)}
            />
          </Group>
        )}
        {tooltip.reviewsPerc && (
          <Group>
            <AltTitle uppercase content="Reviews" />
            <BigText
              content={tooltip.reviewsPerc.number}
              extra={number.percentage(tooltip.reviewsPerc.percentage)}
            />
          </Group>
        )}
        {tooltip.x && (
          <Group>
            <AltTitle uppercase content="Pull Requests" />
            <BigText content={tooltip.x.number} />
          </Group>
        )}
      </TooltipContainer>
    </Hint>
  );
};

export const TimeToMerge = ({ value, ...props }) => {
  if (!value) return null;

  const tooltip = value.tooltip;
  return (
    <Hint {...props} value={value}>
      <TooltipContainer>
        <Group>
          <span className="text-secondary text-m align-middle">{tooltip.repository}</span>
        </Group>
        <Group>
          <p className="text-m text-dark m-0">
            <Icon icon={faClock} className="text-blue" />
            <span>{tooltip.time}</span>
          </p>
        </Group>
      </TooltipContainer>
    </Hint>
  );
};

export const onValueChange = (datapoint, current, setCurrent, blacklist) => {
  if (current && sameObjects(datapoint, current, blacklist)) {
    return;
  }
  setCurrent(getFilteredObject(datapoint, blacklist));
};

export const onValueReset = (setCurrent) => {
  setCurrent(null);
};

const sameObjects = (previous, current, blacklist) => {
  const filteredPrev = getFilteredObject(previous, blacklist);
  const filteredCurr = getFilteredObject(current, blacklist);

  return _.isEqual(filteredPrev, filteredCurr);
};

const getFilteredObject = (obj, blacklist) => _(obj).omit(blacklist).value();

export const TooltipContainer = ({ left = false, children }) => {
  return (
    <div className="athenian-tooltip chart-tooltip" onClick={(event) => event.stopPropagation()}>
      <div>
        <div className={classnames('card-body p-1', left ? 'text-left' : 'text-center')}>
          {children}
        </div>
      </div>
    </div>
  );
};

export const Group = ({ className, children }) => (
  <div className={classnames('w-100 mb-2', className)}>{children}</div>
);

export const BigText = ({
  content,
  extra = null,
  label = null,
  encloseExtra = true,
  newline = false,
  icon = null,
  isXL = null,
}) => (
  <span css={bigTextStyles}>
    {icon}
    <span css={(theme) => bigTextNumberStyles(theme, isXL)}>{content} </span>
    {newline && <br />}
    {extra && (
      <span className="text-secondary font-weight-normal vertical-align-middle text-m">
        {encloseExtra ? `(${extra})` : extra}
      </span>
    )}
    {_.isArray(label) && <br />}
    {label && (
      <>
        {_.isArray(label) ? (
          <div css={badgesWrapperStyles}>
            {label.slice(0, 3).map((l, i) => (
              <span css={(theme) => badgeStyles(theme)} key={`badge-${l}`}>
                {encloseExtra ? `${l}` : l}
              </span>
            ))}
            {label.length > 3 && (
              <span css={(theme) => badgeStyles(theme, true)}>{`+${label.length - 3}`}</span>
            )}
          </div>
        ) : (
          <span css={badgeStyles}>{encloseExtra ? `${label}` : label}</span>
        )}
      </>
    )}
  </span>
);

export const DateWeekDayMonth = ({ date, uppercase }) => (
  <div css={(theme) => dateRangeDayMonthStyles(theme, uppercase)}>
    {date.format('ddd')}, {date.format('Do')}, {date.format('MMM')}
  </div>
);

export const DateRangeDayMonth = ({ dateFrom, dateTo, uppercase }) => {
  if (moment(dateFrom).isSame(moment(dateTo), 'day')) {
    return <DateWeekDayMonth date={dateFrom} uppercase />;
  }

  return (
    <span css={dateRangeDayMonthStyles}>
      {dateFrom.format('MMM D')} - {dateTo.format('MMM D')}
    </span>
  );
};

export const PullRequestRepoTitle = ({ repo, title, number }) => (
  <span className="text-s">
    <span className="text-secondary">{`${github.repoOrg(repo)}/${github.repoName(repo)}`}: </span>
    <a
      href={github.prLink(repo, number)}
      target="_blank"
      rel="noopener noreferrer"
      className="text-dark font-weight-bold"
    >
      {title}
    </a>
  </span>
);

export const Icon = ({ icon, className }) => (
  <FontAwesomeIcon icon={icon} className={classnames('mr-1', className)} />
);

export const UserAvatar = ({ name, src, middleText, size = 30 }) => (
  <>
    <img
      src={src}
      alt={name}
      className="user-avatar inline-block"
      height={size}
      width={size}
      onError={(e) => {
        e.target.src = 'https://avatars2.githubusercontent.com/u/10137';
      }}
    />
    <span className="ml-2 inline-block font-weight-light align-middle">
      {middleText && <span className="text-secondary">{middleText} </span>}
      <span className={classnames('text-dark', !middleText ? 'text-m' : '')}>{name}</span>
    </span>
  </>
);

export const PRCommentsStats = ({ prs, comments }) => (
  <p className="user-info text-secondary font-weight-light m-0">
    <span className="icon-pull-request mr-1" />
    <span className="mr-3">{prs}</span>
    <Icon icon={faEye} />
    <span>{comments}</span>
  </p>
);

export const Release = ({ project, version, url }) => (
  <span className="text-dark text-m align-middle">
    <span>{project} </span>
    {version && (
      <a href={url} className="text-secondary" target="_blank" rel="noopener noreferrer">
        {version}
      </a>
    )}
  </span>
);

export const ReleaseStats = ({ PRs, additions, removals }) => (
  <p className="text-s m-0">
    <Icon icon={faCodeBranch} className="text-green" />
    <span className="text-secondary mr-2">{PRs}</span>
    <span className="mr-1 text-green">+{additions}</span>
    <span className="text-danger">-{removals}</span>
  </p>
);

export default Tooltip;
