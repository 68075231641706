import { css } from '@emotion/react';

export const colorTitleStyles = ({ color, font, spacing }) => css`
  color: ${color.neutral[100]};
  font-size: ${font.size.default};
  font-weight: ${font.weight.regular};
  line-height: 1.5;
  margin-bottom: ${spacing.gap['04']};
`;

export const colorGridStyles = css`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(6, 3fr);
`;
