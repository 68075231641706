import { BasicApiClass } from '@common-services/api/common/BasicApiClass';
import { IUrlParams, REST_TYPES } from '@common-services/api/common/types/apiClient';
import { ApiMode } from '@common-services/api/common/types/common';
import { ISentryMethodParams } from '@common-services/api/common/types/sentry';
import { PRIVATE_API_ENDPOINTS_CONSTANTS } from '@common-services/api/private/constants';
import {
  DashboardChartCreateRequest,
  DashboardChartUpdateRequest,
  DashboardUpdateRequest,
} from '@common-services/api/private/generated-from-backend/models';
import { TeamDashboard } from '@common-services/api/private/types/dashboard';
import { CreatedIdentifier } from '@common-services/api/public/generated-from-backend/models';

export class DashboardApi extends BasicApiClass {
  constructor() {
    super(ApiMode.PRIVATE);
  }

  public getDashboard(
    urlParams: IUrlParams<'dashboardId' | 'teamId'>,
    token: string,
    sentry?: ISentryMethodParams
  ): Promise<TeamDashboard> {
    this.assignToken(token);
    const apiPath: string = this.replaceRouteLink(
      PRIVATE_API_ENDPOINTS_CONSTANTS.DASHBOARD,
      urlParams
    );
    const callApi = this.callApi<TeamDashboard>(apiPath, REST_TYPES.GET);
    return sentry ? this.callSentry<TeamDashboard>(callApi, sentry) : callApi;
  }

  public addChartToDashboard(
    urlParams: IUrlParams<'dashboardId' | 'teamId'>,
    params: DashboardChartCreateRequest,
    token: string,
    sentry?: ISentryMethodParams
  ): Promise<CreatedIdentifier> {
    this.assignToken(token);
    const apiPath: string = this.replaceRouteLink(
      PRIVATE_API_ENDPOINTS_CONSTANTS.DASHBOARD_CHART_CREATE,
      urlParams
    );
    const callApi = this.callApi<CreatedIdentifier>(apiPath, REST_TYPES.POST, params);
    return sentry ? this.callSentry<CreatedIdentifier>(callApi, sentry) : callApi;
  }

  public removeChartFromDashboard(
    urlParams: IUrlParams<'dashboardId' | 'teamId' | 'chartId'>,
    token: string,
    sentry?: ISentryMethodParams
  ): Promise<void> {
    this.assignToken(token);
    const apiPath: string = this.replaceRouteLink(
      PRIVATE_API_ENDPOINTS_CONSTANTS.DASHBOARD_CHART_REMOVE,
      urlParams
    );
    const callApi = this.callApi<void>(apiPath, REST_TYPES.DELETE);
    return sentry ? this.callSentry<void>(callApi, sentry) : callApi;
  }

  public reorderChartsOnDashboard(
    urlParams: IUrlParams<'dashboardId' | 'teamId'>,
    params: DashboardUpdateRequest,
    token: string,
    sentry?: ISentryMethodParams
  ): Promise<TeamDashboard> {
    this.assignToken(token);
    const apiPath: string = this.replaceRouteLink(
      PRIVATE_API_ENDPOINTS_CONSTANTS.DASHBOARD,
      urlParams
    );
    const callApi = this.callApi<TeamDashboard>(apiPath, REST_TYPES.PUT, params);
    return sentry ? this.callSentry<TeamDashboard>(callApi, sentry) : callApi;
  }

  public editChart(
    urlParams: IUrlParams<'dashboardId' | 'teamId' | 'chartId'>,
    params: DashboardChartUpdateRequest,
    token: string,
    sentry?: ISentryMethodParams
  ): Promise<CreatedIdentifier> {
    this.assignToken(token);
    const apiPath: string = this.replaceRouteLink(
      PRIVATE_API_ENDPOINTS_CONSTANTS.DASHBOARD_CHART_EDIT,
      urlParams
    );
    const callApi = this.callApi<CreatedIdentifier>(apiPath, REST_TYPES.POST, params);
    return sentry ? this.callSentry<CreatedIdentifier>(callApi, sentry) : callApi;
  }
}
