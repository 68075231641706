import { SelectTsOptionSingle } from '@lib/selector-ts';

import { IDropdownOption } from './dropdownSelectorBody.types';

export function convertOptions(
  options: IDropdownOption[],
  selected: string[]
): SelectTsOptionSingle[] {
  return options.map((option) => {
    return {
      name: option.label,
      id: option.value,
      image: option.image,
      isSelected: selected ? selected.includes(option.value) : false,
      children: [],
    };
  });
}

export * as DropdownSelectorBodyServices from './dropdownSelectorBody.services';
