import _ from 'lodash';
import React, { useEffect, useContext } from 'react';

import { useExtendedState } from '@analytics-hooks';

const Context = React.createContext({
  items: [],
});
const useCollectionHandler = () => useContext(Context);

const CollectionHandlerBridge = ({
  initialItems,
  flagName,
  useStorage,
  storageKey,
  isLoading,
  onItemsStorageMismatch,
  onItemsStoragePreprocess,
  children,
}) => {
  return isLoading ? (
    <DummyCollectionHandler>{children}</DummyCollectionHandler>
  ) : (
    <CollectionHandler
      initialItems={initialItems}
      flagName={flagName}
      useStorage={useStorage}
      storageKey={storageKey}
      onItemsStorageMismatch={onItemsStorageMismatch}
      onItemsStoragePreprocess={onItemsStoragePreprocess}
    >
      {children}
    </CollectionHandler>
  );
};

const DummyCollectionHandler = ({ children }) => {
  return (
    <Context.Provider
      value={{
        getItems: () => [],
        getVisibleItems: () => [],
        getPosition: () => {},
        addItem: () => {},
        removeItem: () => {},
        moveUp: () => {},
        moveDown: () => {},
        moveTop: () => {},
        moveLast: () => {},
        showItem: () => {},
        hideItem: () => {},
        modifyItem: () => {},
      }}
    >
      {children}
    </Context.Provider>
  );
};

const CollectionHandler = ({
  initialItems,
  flagName,
  useStorage,
  storageKey,
  onItemsStorageMismatch,
  onItemsStoragePreprocess,
  children,
}) => {
  const processedInitialItems = _(initialItems)
    .map((item, k) => ({ ...item, initialOrder: k }))
    .value();
  const [items, setItems] = useExtendedState(processedInitialItems, { useStorage, storageKey });
  const visibilityKey = flagName || 'visible';

  useEffect(() => {
    if (useStorage && onItemsStorageMismatch) {
      let fixedItems = items;
      if (onItemsStorageMismatch) {
        const mismatch = !_(initialItems)
          .map('id')
          .orderBy()
          .sortedUniq()
          .isEqual(_(items).map('id').orderBy().sortedUniq().value());

        if (mismatch) {
          if (_(onItemsStorageMismatch).isFunction()) {
            fixedItems = onItemsStorageMismatch({ initialItems, items });
          } else if (onItemsStorageMismatch === 'reset') {
            fixedItems = initialItems;
          } else {
            throw new Error('Invalid prop for `onItemsStorageMismatch`');
          }
        }
      }

      const processedItems = !!onItemsStoragePreprocess
        ? onItemsStoragePreprocess({ initialItems, fixedItems })
        : fixedItems;
      setItems(processedItems);
    }
  }, []);

  const addItem = (item, top) =>
    setItems(top ? _([item]).concat(items).value() : _(items).concat(item).value());
  const filterItems = (id) =>
    _([...items])
      .remove((i) => i.id !== id)
      .value();
  const removeItem = (id) => setItems(filterItems(id));
  const moveItem = (id, position) => setItems(computeUpdatedItems(id, position));
  const moveTop = (id) => moveItem(id, computeSafeTargetPosition(id, 'top'));
  const moveLast = (id) => moveItem(id, computeSafeTargetPosition(id, 'last'));
  const moveUp = (id, visibleOnly) =>
    moveItem(id, computeSafeTargetPosition(id, 'up', visibleOnly));
  const moveDown = (id, visibleOnly) =>
    moveItem(id, computeSafeTargetPosition(id, 'down', visibleOnly));
  const getPosition = (id, visibleOnly) =>
    _(visibleOnly ? getVisibleItems() : getItems()).findIndex((i) => i.id === id);
  const getItems = (initialOrdering) =>
    initialOrdering ? _(items).orderBy('initialOrder').value() : items;
  const getVisibleItems = () => _(items).filter(visibilityKey).value();
  const changeItemVisibility = (id, visible) => setItems(computeUpdatedItems(id, null, visible));
  const showItem = (id) => changeItemVisibility(id, true);
  const hideItem = (id) => changeItemVisibility(id, false);
  const modifyItem = (id, change) =>
    setItems(
      computeUpdatedItems(
        id,
        change.position
          ? computeSafeTargetPosition(id, change.position.target, change.position.visibleOnly)
          : null,
        change.visible,
        change.override || {}
      )
    );
  const computeUpdatedItems = (id, position, visible, override) => {
    const currentPosition = getPosition(id);
    const itemToMove = { ...items[currentPosition], ...override };
    if (!(_(visible).isUndefined() || _(visible).isNull())) {
      itemToMove[visibilityKey] = visible;
    }

    const targetPosition =
      _(position).isUndefined() || _(position).isNull() ? currentPosition : position;
    const filteredItems = filterItems(id);
    filteredItems.splice(targetPosition, 0, itemToMove);
    return filteredItems;
  };
  const computeSafeTargetPosition = (id, target, visibleOnly) => {
    const position = getPosition(id);
    let targetPosition;
    if (target === 'up') {
      if (visibleOnly) {
        const nextUpperItemIndex = _(items).slice(0, position).findLastIndex([visibilityKey, true]);
        targetPosition = nextUpperItemIndex === -1 ? position - 1 : nextUpperItemIndex;
      } else {
        targetPosition = position - 1;
      }
    } else if (target === 'down') {
      if (visibleOnly) {
        const offset = position + 1;
        const nextLowerItemIndex = _(items)
          .slice(offset, items.length)
          .findIndex([visibilityKey, true]);
        targetPosition = nextLowerItemIndex === -1 ? offset : nextLowerItemIndex + offset;
      } else {
        targetPosition = position + 1;
      }
    } else if (target === 'top') {
      targetPosition = 0;
    } else {
      targetPosition = items.length - 1;
    }

    return Math.min(Math.max(targetPosition, 0), items.length - 1);
  };

  return (
    <Context.Provider
      value={{
        getItems,
        getVisibleItems,
        getPosition,
        addItem,
        removeItem,
        moveUp,
        moveDown,
        moveTop,
        moveLast,
        showItem,
        hideItem,
        modifyItem,
      }}
    >
      {children}
    </Context.Provider>
  );
};
export default CollectionHandlerBridge;
export { useCollectionHandler };
