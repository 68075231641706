import { useQuery } from 'react-query';

import { CONFIG } from '@align-constants';
import { IUseQueryHookRest, PrsIssuesTableTabs } from '@align-types/common';
import { useAuth0 } from '@common-context/Auth0';
import { useDemoCookie } from '@common-pages/Demo';
import { IApiBasicError } from '@common-services/api/common/types/common';
import {
  GetJIRAIssuesRequest,
  GetJIRAIssuesResponse,
} from '@common-services/api/public/generated-from-backend/models';
import { PublicIssuesServices } from '@common-services/api/public/services/issues';

interface IUseGetIssuesParams extends GetJIRAIssuesRequest {
  activeTab?: PrsIssuesTableTabs;
}

export function useGetIssues(
  params: IUseGetIssuesParams,
  isCached: boolean = false
): IUseQueryHookRest<GetJIRAIssuesResponse> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();
  const getIssues = async (): Promise<GetJIRAIssuesResponse> => {
    const token: string = isDemo === false ? await getTokenSilently() : null;
    return PublicIssuesServices.getJiraIssues(
      {
        account: params.account,
        include: params.include,
        issues: params.issues,
      },
      token
    );
  };

  const { isLoading, data, error } = useQuery<GetJIRAIssuesResponse, IApiBasicError>(
    ['getIssues', params.account, params.issues, params.activeTab],
    getIssues,
    {
      ...CONFIG.API.FETCH_OPTIONS,
      enabled: !!(params?.issues?.length > 0),
      cacheTime: isCached ? CONFIG.API.FETCH_OPTIONS.cacheTime : 0,
      staleTime: isCached ? CONFIG.API.FETCH_OPTIONS.staleTime : 0,
    }
  );

  return { isLoading, data, error };
}
