import { EmotionJSX } from '@emotion/react/types/jsx-namespace';

import {
  PullRequest,
  PullRequestStage,
  StageTimings,
} from '@common-services/api/public/generated-from-backend/models';
import {
  ISerializedPullRequest,
  IStageTimingsDeploy,
  IStageTimingsNoDeploy,
} from '@common-services/api/public/types/pullRequests';
import { github } from '@common-services/format';
import { mapValues, pickBy } from '@common-services/vendor/lodash';
import { Icon, icons } from '@lib/icon';
import { theme } from '@styles/theme';

export enum PR_STATUS {
  OPENED = 'opened',
  MERGED = 'merged',
  CLOSED = 'closed',
}

export enum PR_STAGE {
  WIP = 'wip',
  REVIEW = 'review',
  MERGE = 'merge',
  RELEASE = 'release',
  DEPLOY = 'deploy',
  DONE = 'done',
  SUMMARY = 'summary',
}

export interface IPrStageApiMapping {
  [k: string]: PR_STAGE;
}

export const PR_STAGE_API_MAPPING: IPrStageApiMapping = {
  wip: PR_STAGE.WIP,
  reviewing: PR_STAGE.REVIEW,
  merging: PR_STAGE.MERGE,
  releasing: PR_STAGE.RELEASE,
  deployed: PR_STAGE.DEPLOY,
  done: PR_STAGE.DONE,
};

const PR_STAGE_TIMELINE = [
  PR_STAGE.WIP,
  PR_STAGE.REVIEW,
  PR_STAGE.MERGE,
  PR_STAGE.RELEASE,
  PR_STAGE.DONE,
  PR_STAGE.DEPLOY,
];

export const PR_EVENT = {
  CREATION: 'created',
  COMMIT: 'committed',
  REVIEW_REQUEST: 'review_requested',
  REVIEW: 'reviewed',
  REVIEW_REJECTION: 'changes_requested',
  APPROVE: 'approved',
  MERGE: 'merged',
  RELEASE: 'released',
  CLOSURE_REJECTION: 'rejected',
  FORCE_PUSH_DROPPED: 'force_push_dropped',
};

// These are the PR labels that will appear in PR tables depending on pr status/stage/events
// and depending from which stage the PR is analyzed (see: https://athenianco.atlassian.net/browse/ENG-325)
export const PR_LABELS = {
  WIP: 'Work in Progress',
  WIP_DONE: 'Work Submitted',
  REVIEW_PENDING: 'Review Required',
  REVIEW_SUBMITTED: 'Review Submitted',
  REVIEW_REJECTED: 'Changes Requested',
  REVIEW_APPROVAL: 'Review Approved',
  REVIEW_SKIPPED: 'Not Reviewed',
  REVIEW_IGNORED: 'Review Ignored',
  MERGE_PENDING: 'Waiting for Merge',
  MERGE_COMPLETED: 'Merged',
  RELEASE_PENDING: 'Waiting for Release',
  RELEASE_COMPLETED: 'Released',
  CLOSED: 'Closed',
  NEVER_RELEASED: 'Never Released',
};

// This establishes a chronological order for the PR labels (to be used as a sorting criterion, instead of alphabetical order)
export const PR_LABELS_ORDERS = {
  [PR_LABELS.WIP]: 0,
  [PR_LABELS.WIP_DONE]: 1,
  [PR_LABELS.REVIEW_PENDING]: 2,
  [PR_LABELS.REVIEW_SUBMITTED]: 3,
  [PR_LABELS.REVIEW_REJECTED]: 4,
  [PR_LABELS.REVIEW_APPROVAL]: 5,
  [PR_LABELS.REVIEW_SKIPPED]: 6,
  [PR_LABELS.REVIEW_IGNORED]: 7,
  [PR_LABELS.MERGE_PENDING]: 8,
  [PR_LABELS.MERGE_COMPLETED]: 9,
  [PR_LABELS.RELEASE_PENDING]: 10,
  [PR_LABELS.RELEASE_COMPLETED]: 11,
  [PR_LABELS.NEVER_RELEASED]: 12,
  [PR_LABELS.CLOSED]: 13,
};

export const PR_LABELS_CLASSNAMES = {
  [PR_LABELS.WIP]: 'label-wip',
  [PR_LABELS.WIP_DONE]: 'label-review-requested',
  [PR_LABELS.REVIEW_PENDING]: 'label-review-pending',
  [PR_LABELS.REVIEW_SUBMITTED]: 'label-review-submitted',
  [PR_LABELS.REVIEW_REJECTED]: 'label-review-rejected',
  [PR_LABELS.REVIEW_APPROVAL]: 'label-review-approval',
  [PR_LABELS.REVIEW_SKIPPED]: 'label-review-skipped',
  [PR_LABELS.REVIEW_IGNORED]: 'label-review-ignored',
  [PR_LABELS.MERGE_PENDING]: 'label-merge-pending',
  [PR_LABELS.MERGE_COMPLETED]: 'label-merged',
  [PR_LABELS.RELEASE_PENDING]: 'label-release-pending',
  [PR_LABELS.RELEASE_COMPLETED]: 'label-released',
  [PR_LABELS.CLOSED]: 'label-closed',
  [PR_LABELS.NEVER_RELEASED]: 'label-never-released',
};

export const processPR = (pr: PullRequest): ISerializedPullRequest => {
  const status = extractStatus(pr);
  const { authors, mergers, reviewers } = extractParticipantsByKind(pr);
  const filtered_stage_timings = filterStageTimings(pr.stage_timings);
  const deploy_timings = filterDeployTimings(pr.stage_timings);

  pr.stages_time_machine =
    pr?.stages_time_machine?.map((p) => (PR_STAGE_API_MAPPING[p] as PullRequestStage) || p) || [];
  pr.stages_now = pr.stages_now.map((p) => (PR_STAGE_API_MAPPING[p] as PullRequestStage) || p);
  const completedStages = extractCompletedStages(pr);

  return {
    ...pr,
    status,
    completedStages,
    authors,
    mergers,
    reviewers,
    organization: github.repoOrg(pr.repository),
    repo: github.repoName(pr.repository),
    filtered_stage_timings,
    deploy_timings,
  };
};

const filterStageTimings = (obj: StageTimings): IStageTimingsNoDeploy => {
  const filteredObj = pickBy(obj, (value) => typeof value !== 'object'); // filter out props that are not strings that represent time values (like "deploy")
  return mapValues(filteredObj, (value) => (value ? parseFloat(value as string) : null)); // extract numbers from those strings
};

const filterDeployTimings = (obj: StageTimings): IStageTimingsDeploy => {
  const deployObj = obj[PR_STAGE.DEPLOY];
  if (deployObj) {
    return mapValues(deployObj, (value) => (value ? parseFloat(value as string) : null));
  }
  return null;
};

export const extractParticipantsByKind = (pr: PullRequest) =>
  pr.participants.reduce(
    (acc, participant) => {
      const has_status = (status) => participant.status.includes(status);
      if (has_status('merger')) {
        acc.mergers.push(participant.id);
      }

      if (has_status('author')) {
        acc.authors.push(participant.id);
        return acc;
      }

      if (has_status('reviewer')) {
        acc.reviewers.push(participant.id);
      }

      return acc;
    },
    { authors: [], reviewers: [], mergers: [] }
  );

const extractStatus = (pr: PullRequest): PR_STATUS => {
  if (pr.merged) {
    return PR_STATUS.MERGED;
  } else if (pr.closed) {
    return PR_STATUS.CLOSED;
  } else {
    return PR_STATUS.OPENED;
  }
};

export const happened = (pr, event) => pr.events_now.includes(event);

// TODO: DEPRECATED::GLOBAL_PRS_ABUSE (wouldNeedPagination)
// This would be no longer used once each stage fetches its own PRs from /filter/pull_requests
// TODO(vmarkovtsev): refer to pr.stages_now rather than scanning through events.
export const isInStage = (pr: ISerializedPullRequest, stage: PR_STAGE): boolean => {
  if (extractStatus(pr) === PR_STATUS.CLOSED) {
    switch (stage) {
      case PR_STAGE.DEPLOY:
      case PR_STAGE.RELEASE:
        return false;
      case PR_STAGE.MERGE:
        return happened(pr, PR_EVENT.APPROVE);
      case PR_STAGE.REVIEW:
        return (
          happened(pr, PR_EVENT.REVIEW_REQUEST) ||
          happened(pr, PR_EVENT.REVIEW) ||
          happened(pr, PR_EVENT.REVIEW_REJECTION) ||
          happened(pr, PR_EVENT.APPROVE)
        );
      case PR_STAGE.WIP:
      case PR_STAGE.DONE:
        return true;
      default:
        throw Error(`unexpected "${stage}" stage`);
    }
  }

  return stageHappening(pr, stage) || stageCompleted(pr, stage);
};

export const stageHappening = (pr: ISerializedPullRequest, stage: PR_STAGE): boolean =>
  pr.stages_time_machine.includes(stage as PullRequestStage);

const stageCompleted = (pr: ISerializedPullRequest, stage: PR_STAGE): boolean =>
  pr.completedStages.includes(stage);

const extractCompletedStages = (pr: PullRequest): PR_STAGE[] => {
  let currentStageIndex;
  if (extractStatus(pr) === PR_STATUS.CLOSED) {
    if (happened(pr, PR_EVENT.APPROVE)) {
      currentStageIndex = PR_STAGE_TIMELINE.indexOf(PR_STAGE.MERGE);
    } else if (
      happened(pr, PR_EVENT.REVIEW_REQUEST) ||
      happened(pr, PR_EVENT.REVIEW) ||
      happened(pr, PR_EVENT.REVIEW_REJECTION)
    ) {
      currentStageIndex = PR_STAGE_TIMELINE.indexOf(PR_STAGE.REVIEW);
    } else {
      currentStageIndex = PR_STAGE_TIMELINE.indexOf(PR_STAGE.WIP);
    }
  } else {
    const currentStage = pr.stages_now[0];
    currentStageIndex = PR_STAGE_TIMELINE.indexOf(currentStage as PR_STAGE);
  }

  if (PR_STAGE_TIMELINE[currentStageIndex] === PR_STAGE.DEPLOY) {
    return PR_STAGE_TIMELINE;
  }

  return PR_STAGE_TIMELINE.slice(0, currentStageIndex);
};

export const prLabel = (stage) => (pr) => {
  if (pr.status === PR_STATUS.CLOSED) {
    return PR_LABELS.CLOSED;
  }

  if (pr.stages_now.includes(PR_EVENT.FORCE_PUSH_DROPPED)) {
    return PR_LABELS.NEVER_RELEASED;
  }

  const hasCompletedStage = (stage) => pr.completedStages.includes(stage);
  const isStageHappening = (stage) => stageHappening(pr, stage);

  switch (stage) {
    case PR_STAGE.WIP:
      if (hasCompletedStage(PR_STAGE.WIP)) {
        return PR_LABELS.WIP_DONE;
      }

      return PR_LABELS.WIP;
    case PR_STAGE.REVIEW:
      if (hasCompletedStage(PR_STAGE.REVIEW)) {
        if (happened(pr, PR_EVENT.APPROVE)) {
          return PR_LABELS.REVIEW_APPROVAL;
        } else if (!happened(pr, PR_EVENT.REVIEW)) {
          return PR_LABELS.REVIEW_SKIPPED;
        }

        return PR_LABELS.REVIEW_IGNORED;
      } else if (happened(pr, PR_EVENT.REVIEW_REJECTION)) {
        return PR_LABELS.REVIEW_REJECTED;
      } else if (happened(pr, PR_EVENT.REVIEW)) {
        return PR_LABELS.REVIEW_SUBMITTED;
      }

      return PR_LABELS.REVIEW_PENDING;
    case PR_STAGE.MERGE:
      if (hasCompletedStage(PR_STAGE.MERGE)) {
        return PR_LABELS.MERGE_COMPLETED;
      }

      return PR_LABELS.MERGE_PENDING;
    case PR_STAGE.RELEASE:
      if (happened(pr, PR_EVENT.RELEASE)) {
        return PR_LABELS.RELEASE_COMPLETED;
      }

      return PR_LABELS.RELEASE_PENDING;
    default:
      if (isStageHappening(PR_STAGE.DONE)) {
        return PR_LABELS.RELEASE_COMPLETED;
      }

      if (pr.completedStages.length === 0) {
        if (isStageHappening(PR_STAGE.RELEASE)) {
          return PR_LABELS.RELEASE_PENDING;
        } else if (isStageHappening(PR_STAGE.MERGE)) {
          return PR_LABELS.MERGE_PENDING;
        } else if (isStageHappening(PR_STAGE.REVIEW)) {
          if (happened(pr, PR_EVENT.REVIEW_REJECTION)) {
            return PR_LABELS.REVIEW_REJECTED;
          } else if (happened(pr, PR_EVENT.REVIEW)) {
            return PR_LABELS.REVIEW_SUBMITTED;
          } else {
            return PR_LABELS.REVIEW_PENDING;
          }
        }

        return PR_LABELS.WIP;
      }

      if (happened(pr, PR_EVENT.RELEASE)) {
        return PR_LABELS.RELEASE_COMPLETED;
      } else if (hasCompletedStage(PR_STAGE.MERGE)) {
        return PR_LABELS.RELEASE_PENDING;
      } else if (hasCompletedStage(PR_STAGE.REVIEW)) {
        return PR_LABELS.MERGE_PENDING;
      } else if (happened(pr, PR_EVENT.REVIEW_REJECTION)) {
        return PR_LABELS.REVIEW_REJECTED;
      } else if (happened(pr, PR_EVENT.REVIEW)) {
        return PR_LABELS.REVIEW_SUBMITTED;
      } else if (hasCompletedStage(PR_STAGE.WIP)) {
        return PR_LABELS.REVIEW_PENDING;
      }

      return PR_LABELS.WIP;
  }
};

export const getPrIcon = (status: string): EmotionJSX.Element => {
  switch (status) {
    case PR_STATUS.MERGED:
      return <Icon color={theme.color.stage.merge} icon={icons.pr_merge} size={14} />;
    case PR_STATUS.CLOSED:
      return <Icon color={theme.color.ui.red[100]} icon={icons.nav_pr} size={14} />;
    case PR_STATUS.OPENED:
    default:
      return <Icon color={theme.color.stage.release} icon={icons.nav_pr} size={14} />;
  }
};
