import React from 'react';

import { SelectTsOptionSingle } from '@lib/selector-ts';

export interface ContextProps {
  filter: string;
  filterOnChangeHandler: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  filterOnClearHandler: () => void;
  clickHandler: (
    event: React.MouseEvent<HTMLDivElement>,
    id: string,
    type: ClickTypeEnum,
    isSelected?: boolean,
    parentIds?: string[],
    childrenIds?: string[]
  ) => void;
  maxSelected: number;
  minSelected: number;
  maxHeight: number;
  options: SelectTsOptionSingle[];
  selectedIds: string[];
  withAllOption?: boolean;
  isAllSelected?: boolean;
  isCascading?: boolean;
  isLoading: boolean;
  isLoadMoreEnabled?: boolean;
  loadMoreLabel?: string;
  applyHandler: () => void;
  cancelHandler: () => void;
  loadMoreHandler: () => void;
}

export enum ClickTypeEnum {
  isOpened = 'isOpened',
  isSelected = 'isSelected',
}
