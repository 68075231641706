import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useOnClickOutside } from '@common-hooks/useOnClickOutside';
import { default as SearchUI } from '@ui/Search';

const Search = ({ id = null, value, placeholder, options, maxVisible, onSave, onReset }) => {
  const [isHover, setIsHover] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(value);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const onMouseEnterClosed = () => setIsHover(true);
  const onMouseLeaveClosed = () => setIsHover(false);
  const onClickClosed = () => setIsOpen(!isOpen);
  const onCancelClick = (ev) => {
    ev.stopPropagation();
    setSelected(null);
    setIsHover(false);
    onReset && onReset();
  };
  const onOptionClick = (opt) => {
    if (!opt.isSelected) {
      setSelected(opt);
      setIsOpen(!isOpen);
      setIsHover(false);
      onSave && onSave(opt);
    }
  };
  const onInputChange = (ev) => setInputValue(ev.target.value);

  const compId = id || `search-${uuidv4()}`;

  useOnClickOutside(compId, () => {
    setIsOpen(false);
    setIsHover(false);
  });

  return (
    <SearchUI
      id={compId}
      value={selected}
      inputValue={inputValue}
      placeholder={placeholder}
      options={options}
      maxVisible={maxVisible}
      isHover={isHover}
      isOpen={isOpen}
      onOptionClick={onOptionClick}
      onClickClosed={onClickClosed}
      onCancelClick={onCancelClick}
      onMouseEnterClosed={onMouseEnterClosed}
      onMouseLeaveClosed={onMouseLeaveClosed}
      onInputChange={onInputChange}
    />
  );
};

export default Search;
