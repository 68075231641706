import React from 'react';
import { Link } from 'react-router-dom';

import { Icon, icons } from '@lib/icon';
import { AuthRouteEnum } from '@routes/authRoutes';

import { wrapperStyles } from './NavbarUserLogin.styles';

export const NavbarUserLogin: React.FC<{}> = React.memo(() => {
  return (
    <Link to={AuthRouteEnum.LOGIN} css={wrapperStyles}>
      <Icon icon={icons.logout} size={15} />
      Login
    </Link>
  );
});
