import { useTheme } from '@emotion/react';
import React, { useCallback, useMemo, useState } from 'react';
import { RadialChart } from 'react-vis';

import { AltTitle } from '@analytics-components/Typography';
import {
  DefaultXYTooltip,
  BigText,
  onValueChange,
  onValueReset,
} from '@analytics-components/charts/Tooltip';
import defaults from '@analytics-components/charts/defaults';
import { number } from '@common-services/format';
import { NoData } from '@lib/empty/noData';
import { rotate } from '@utils/colors';

const Chart = ({ data, extra, className, ...rest }) => (
  <div style={{ background: 'white' }} className={className}>
    <DonutChart data={data} extra={extra} {...rest} />
  </div>
);

const DonutChart = ({ data, extra }) => {
  const theme = useTheme();
  const [currentHover, setCurrentHover] = useState(null);
  const size = extra?.size || 250;
  const marginLeft = extra?.margin?.left ?? defaults.marginLeft;

  const handleValueMouseOver = useCallback(
    (datapoint) => onValueChange(datapoint, currentHover, setCurrentHover),
    [currentHover]
  );

  const handleValueMouseOut = useCallback(() => onValueReset(setCurrentHover), []);

  const alignConf = useMemo(
    () =>
      currentHover?.x > 0
        ? {
            horizontal: 'right',
            vertical: currentHover?.y > 0 ? 'top' : 'bottom',
          }
        : {
            horizontal: 'left',
            vertical: currentHover?.y > 0 ? 'top' : 'bottom',
          },
    [currentHover]
  );

  if (data.length === 0) {
    return <NoData textOnly />;
  }

  const total = data.reduce((acc, v) => acc + v.value, 0);
  const values = data.map((v, i) => ({
    ...v,
    percentage: v.value / (total || 1),
    color: rotate(extra?.colors || theme.color.sets.five, i),
  }));

  const INNER_PADDING = 2; // it avoids an aliassing issue.

  return (
    <div
      className="donut-chart d-flex justify-content-between align-items-center"
      style={{ paddingLeft: `${marginLeft}px`, paddingRight: theme.spacing.gap['04'] }}
    >
      <RadialChart
        width={size}
        height={size}
        data={values}
        innerRadius={size / 3.5}
        radius={size / 2 - INNER_PADDING}
        getAngle={(v) => v.value}
        stroke={0}
        animation="stiff"
        colorType="literal"
        className="chart"
        padAngle={0}
        onValueMouseOver={handleValueMouseOver}
        onValueMouseOut={handleValueMouseOut}
      >
        <DefaultXYTooltip
          align={alignConf}
          value={currentHover}
          x={(v) => <AltTitle content={v.label} />}
          y={(v) => (
            <BigText
              content={extra?.tooltip?.y?.(v.value) || v.value}
              extra={number.percentage(100 * v.percentage)}
            />
          )}
        />
      </RadialChart>
      <Legend data={values} />
    </div>
  );
};

const Legend = ({ data }) => {
  return (
    <div className="custom-legend py-3 px-4 px-xl-5 rounded">
      {data.map((v, i) => (
        <LegendRow label={v.label} percentage={v.percentage} color={v.color} key={i} />
      ))}
    </div>
  );
};

const LegendRow = ({ label, percentage, color }) => {
  return (
    <div className="line d-flex justify-content-between align-items-center py-2">
      <div className="mr-4 mr-xl-6">{label}</div>
      <div className="text-dark ml-1">
        <span className="dot mr-1 d-inline-block" style={{ backgroundColor: color }} />
        <strong>{number.percentage(100 * percentage)}</strong>
      </div>
    </div>
  );
};

export default Chart;
