import _ from 'lodash';
import React from 'react';

import Breadcrumb from '@analytics-components/Breadcrumb';
import { ShareViewButton } from '@analytics-components/ShareViewButton';
import { READY } from '@analytics-components/StatusIndicator';
import TopFilter from '@analytics-components/TopFilter';
import Permissions from '@analytics-components/layout/Permissions';
import Upgrade from '@analytics-components/layout/Upgrade';
import { useBreadcrumb } from '@analytics-context/Breadcrumb';
import DataWidget from '@analytics-context/DataWidget';
import ContentTeamsComparison from '@analytics-pages/Compare';
import { default as ContentLearn } from '@analytics-pages/Learn';
import {
  ContentEpicsTable,
  ContentIssuesTable,
  ContentPullRequestsTable,
  ContentReleasesTable,
} from '@analytics-pages/Tables';
import { default as ContentCI } from '@analytics-pages/ci';
import { default as ContentPipeline } from '@analytics-pages/delivery-pipeline';
import { default as ContentOutcome } from '@analytics-pages/outcome';
import { default as ContentQuality } from '@analytics-pages/quality';
import { useUserContext } from '@common-context/User';

import { getBreadcrumbWrapperStyle } from './Content.styles';

const pagesContentDefs = {
  '': {
    config: {
      filters: true,
      breadcrumb: true,
      shareable: true,
    },
    sections: {
      'delivery-pipeline': {
        component: ContentPipeline,
        filters: {
          usersEnabled: true,
          reposEnabled: true,
          labelsEnabled: true,
        },
      },
      'ci': {
        component: ContentCI,
        isEnabled: (userDetails) => userDetails.hasCI,
        fallback: <Permissions />,
        filters: {
          usersEnabled: true,
          reposEnabled: true,
          labelsEnabled: true,
        },
      },
      'quality': {
        component: ContentQuality,
        isEnabled: (userDetails, subsection) =>
          !!userDetails.account.jira || subsection === 'pull-request-size',
        fallback: <Upgrade section="quality" />,
        filters: {
          usersEnabled: true,
          reposEnabled: ['pull-request-size'],
          labelsEnabled: ['pull-request-size'],
        },
      },
      'outcome': {
        component: ContentOutcome,
        filters: {
          usersEnabled: true,
          reposEnabled: ['allocation'],
          labelsEnabled: false,
        },
      },
    },
  },
  'tables': {
    config: {
      filters: true,
      breadcrumb: true,
      shareable: true,
    },
    sections: {
      'pull-requests': {
        component: ContentPullRequestsTable,
        filters: {
          usersEnabled: true,
          reposEnabled: true,
          labelsEnabled: true,
        },
      },
      'epics': {
        component: ContentEpicsTable,
        isEnabled: (userDetails) => !!userDetails.account.jira,
        fallback: null,
        filters: {
          usersEnabled: true,
        },
      },
      'issues': {
        component: ContentIssuesTable,
        isEnabled: (userDetails) => !!userDetails.account.jira,
        fallback: null,
        filters: {
          usersEnabled: true,
        },
      },
      'releases': {
        component: ContentReleasesTable,
        filters: {
          usersEnabled: true,
          reposEnabled: true,
          labelsEnabled: true,
        },
      },
    },
  },
  'compare': {
    config: {
      filters: true,
      breadcrumb: true,
      shareable: true,
    },
    sections: {
      teams: {
        component: ContentTeamsComparison,
        filters: {
          usersEnabled: ['table'],
          reposEnabled: true,
          labelsEnabled: true,
        },
      },
    },
  },
  'learn': {
    config: {
      filters: false,
      breadcrumb: false,
      shareable: false,
    },
    sections: {
      knowledge: {
        component: ContentLearn,
      },
    },
  },
};

const isFilterEnabled = (sectionConfig, subsection, name) =>
  _.isArray(sectionConfig?.filters?.[name])
    ? sectionConfig.filters[name].indexOf(subsection) >= 0
    : !!sectionConfig?.filters?.[name];

const Content = () => {
  const userDetails = useUserContext();
  const { part, section, subsection } = useBreadcrumb();
  const { config: partConfig, sections: sectionsConfig } = pagesContentDefs[part];
  const {
    breadcrumb: breadcrumbEnabled,
    filters: filtersEnabled,
    shareable: shareViewEnabled,
  } = partConfig;
  const sectionConfig = sectionsConfig[section];

  const usersFilterEnabled = isFilterEnabled(sectionConfig, subsection, 'usersEnabled');
  const reposFilterEnabled = isFilterEnabled(sectionConfig, subsection, 'reposEnabled');
  const labelsFilterEnabled = isFilterEnabled(sectionConfig, subsection, 'labelsEnabled');
  const ContentComponent = sectionConfig.component;

  const isEnabled = sectionConfig.isEnabled
    ? sectionConfig.isEnabled(userDetails, subsection)
    : true;

  const content = isEnabled ? (
    <>
      {filtersEnabled && (
        <DataWidget id="top-filters">
          <TopFilter
            usersFilterEnabled={usersFilterEnabled}
            reposFilterEnabled={reposFilterEnabled}
            labelsFilterEnabled={labelsFilterEnabled}
          />
        </DataWidget>
      )}
      <div css={getBreadcrumbWrapperStyle}>
        {breadcrumbEnabled && <Breadcrumb />}
        {shareViewEnabled && <ShareViewButton />}
      </div>
      {ContentComponent && <ContentComponent data={{}} status={READY} />}
    </>
  ) : (
    sectionConfig.fallback
  );

  return (
    <div className="col content-main">
      <div className="container">{content}</div>
    </div>
  );
};

export default Content;
