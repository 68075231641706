import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useOnClickOutside } from '@common-hooks/useOnClickOutside';
import { Button, ButtonType } from '@lib/Button';
import { Icon, icons } from '@lib/icon';
import { useTheme } from '@styles/hooks';

import { dropdownViewStyles, menuItemStyles, menuStyles, wrapperStyles } from './buttonMenu.styles';
import { ButtonMenuTypes, IButtonMenuOption, IButtonMenuProps } from './buttonMenu.types';

export const ButtonMenu: React.FC<IButtonMenuProps> = React.memo(
  ({
    label,
    options,
    onSelect,
    disabledOptions,
    type = ButtonMenuTypes.DEFAULT,
    defaultText = 'Select',
    initialSelection,
  }) => {
    const { color } = useTheme();
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [selectedOption, setSelectedOption] = useState<IButtonMenuOption>();

    const toggleMenuCallback = useCallback(() => {
      setIsOpen(!isOpen);
    }, [isOpen]);

    useLayoutEffect(() => {
      if (initialSelection) {
        setSelectedOption(options.find((opt) => opt.value === initialSelection));
      }
    }, [options, initialSelection]);

    useOnClickOutside([dropdownRef], () => setIsOpen(false));

    const onSelectHandler = useCallback(
      (selectedItem: string) => {
        const selectedOptionDetails = options.find((opt) => opt.value === selectedItem);
        onSelect(selectedItem);
        setSelectedOption(selectedOptionDetails);
        setIsOpen(false);
      },
      [options]
    );

    const display = useMemo(() => {
      switch (type) {
        case ButtonMenuTypes.DEFAULT:
          return (
            <Button
              isActive={isOpen}
              label={label}
              type={ButtonType.PALE}
              onClick={toggleMenuCallback}
            />
          );
        case ButtonMenuTypes.DROPDOWN:
          return (
            <div css={dropdownViewStyles} onClick={toggleMenuCallback}>
              <Icon
                icon={selectedOption?.icon || icons.applied_filter}
                size={15}
                color={color.neutral['80']}
              />
              <div className="dropdown-text">{selectedOption?.label || defaultText}</div>
              <Icon icon={icons.caret_down} size={8} color={color.neutral['80']} />
            </div>
          );
      }
    }, [selectedOption]);

    return (
      <div css={wrapperStyles({ type })}>
        {display}
        {isOpen && (
          <div css={menuStyles} ref={dropdownRef}>
            {options.map((item) => {
              const isDisabled = disabledOptions.includes(item.value);
              return (
                <div
                  key={uuidv4()}
                  css={menuItemStyles({ isDisabled })}
                  onClick={() => !isDisabled && onSelectHandler(item.value)}
                >
                  {item.icon && (
                    <Icon
                      className="icon"
                      size={16}
                      icon={item.icon}
                      color={isDisabled ? color.neutral['20'] : color.text.secondary}
                    />
                  )}
                  {item.label}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
);
