import React from 'react';
import { CellProps, Renderer } from 'react-table';

import { IIssuesTableRow } from '@align-types/issues';

import { statusStyles } from './cellStatus.styles';

export const IssuesCellStatus: Renderer<CellProps<IIssuesTableRow>> = React.memo(({ row }) => (
  <div
    className={row.depth > 0 && `badge badge-outlined ${row.original.statusClassName}`}
    css={statusStyles(row.original.statusColor)}
  >
    <span>{row.original.status.toUpperCase()}</span>
  </div>
));
