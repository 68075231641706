import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  ITableFilterOption,
  IUseFilter,
} from '@analytics-components/tables/table-filter/tableFilter.types';
import { prLabel, PR_STAGE } from '@common-services/prHelpers';
import { IPrsTableRow } from '@lib/Table/components/pr-table/prTable.types';

export const useFilter = (
  allPrs: IPrsTableRow[],
  stage: PR_STAGE,
  setFilteredPrs: React.Dispatch<React.SetStateAction<IPrsTableRow[]>>
): IUseFilter => {
  const [currentFilter, setCurrentFilter] = useState<ITableFilterOption>(null);

  useEffect(() => setCurrentFilter(null), [stage]);

  const filterOptions = useMemo<ITableFilterOption[]>(() => {
    let options = [];
    if (stage === PR_STAGE.DEPLOY) {
      options = [...Object.keys(allPrs?.[0]?.stage_timings?.deploy || {})];
    } else {
      const getLabel = prLabel(stage);
      options = [...allPrs.reduce((acc, curr) => acc.add(getLabel(curr)), new Set<string>())];
    }
    return [{ label: 'All' }, ...options.map((value) => ({ label: value, value }))];
  }, [allPrs, stage]);

  const placeholder = useMemo(() => {
    if (stage === PR_STAGE.DEPLOY) {
      return 'Filter by environment';
    }
    return 'Filter by status';
  }, [stage]);

  const handleFilter = useCallback(
    (option: ITableFilterOption) => {
      setCurrentFilter(option);
      if (option?.value) {
        setFilteredPrs(
          allPrs.filter((pr) =>
            stage === PR_STAGE.DEPLOY
              ? pr.environment === option.value
              : prLabel(stage)(pr) === option.value
          )
        );
      } else {
        setFilteredPrs(allPrs);
      }
    },
    [allPrs, stage]
  );

  return { currentFilter, filterOptions, placeholder, handleFilter };
};
