import { KPIType } from '@analytics-components/insights/Box/types';
import { SimpleKPI } from '@analytics-components/insights/KPI';
import { dateTime } from '@common-services/dateService';

import { KPIInputType } from '.';

const kpis = (kpisData: KPIInputType): KPIType[] => [
  {
    title: { text: 'PR Cycle Time', bold: true },
    subtitle: { text: 'Average' },
    component: SimpleKPI,
    params: {
      value: dateTime.human(kpisData.cycleTime() * 1000),
    },
  },
  {
    title: { text: 'Pull Requests Deployed', bold: true },
    subtitle: { text: 'Total' },
    component: SimpleKPI,
    params: {
      value: kpisData.prsCount,
    },
  },
];
export default kpis;
