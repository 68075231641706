import PropTypes from 'prop-types';
import React from 'react';

import Checkbox, { SELECTION_STATE } from '@analytics-components/MultiSelect/ui/Checkbox';
import { optionType, optionNoColorType } from '@lib/Selector/types';
import { Icon, icons } from '@lib/icon';

import getStyle from './styles';

const Option = ({
  data,
  level = 1,
  isExpanded = false,
  noCheckbox = false,
  avatar,
  selectionState,
  children,
  onClick,
  onExpand,
}) => (
  <div data-cy="selector-option">
    <div css={getStyle(level, !!data.children)}>
      <div data-cy="selector-option-info" className="info-wrapper" onClick={onClick}>
        {!noCheckbox && (
          <div className="checkbox-wrapper">
            <Checkbox selectionState={selectionState} />
          </div>
        )}
        {avatar && <div className="avatar-wrapper">{avatar}</div>}
        <div data-cy="selector-option-label" className="label-wrapper">
          {data.labelPrefix && <span className="label-prefix">{data.labelPrefix}</span>}
          <span>{data.label}</span>
        </div>
      </div>
      {data.children && (
        <div data-cy="selector-option-control" className="control-wrapper" onClick={onExpand}>
          {isExpanded ? (
            <Icon icon={icons.angle_down} size={10} />
          ) : (
            <Icon icon={icons.angle_right} size={10} />
          )}
        </div>
      )}
    </div>
    {data.children && isExpanded && <div className="children-wrapper">{children}</div>}
  </div>
);

const AllOption = ({ allCount, selectionState, onClick }) => (
  <div data-cy="selector-option-all">
    <div css={getStyle(1, false, true)}>
      <div data-cy="selector-option-all-info" className="info-wrapper" onClick={onClick}>
        <div className="checkbox-wrapper">
          <Checkbox selectionState={selectionState} />
        </div>
        <div data-cy="selector-option-all-label" className="label-wrapper">
          All
        </div>
        <div data-cy="selector-option-all-badge" className="badge-count-wrapper">
          {allCount}
        </div>
      </div>
    </div>
  </div>
);

Option.propTypes = {
  data: (props, propName, componentName, ...rest) =>
    (!!props.noAvatar ? optionNoColorType : optionType).isRequired(
      props,
      propName,
      componentName,
      ...rest
    ),
  level: PropTypes.number,
  selectionState: PropTypes.oneOf(Object.values(SELECTION_STATE)).isRequired,
  isExpanded: PropTypes.bool,
  avatar: PropTypes.node,
  noCheckbox: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.node),
  onClick: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
};

AllOption.propTypes = {
  allCount: PropTypes.number,
  selectionState: PropTypes.oneOf(Object.values(SELECTION_STATE)).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Option;
export { AllOption };
