import React from 'react';

import noPrs from '@assets/images/empty-states/no_prs.svg';

import { Empty } from '..';

const NoWorkTypes: React.FC = React.memo(() => (
  <Empty background={noPrs}>
    <h3 className="dark">Add a Work Type</h3>
    <p>Create work types defined with your own rules</p>
  </Empty>
));

export { NoWorkTypes };
