import React from 'react';

import { PrimaryLayout } from '@align-components/layouts/primary';
import { PrimaryLayoutTabs } from '@align-components/layouts/primary/context.types';

import { ActiveGoals } from './active-goals';

export const GoalsList: React.FC = React.memo(() => {
  return (
    <PrimaryLayout tab={PrimaryLayoutTabs.GOALS}>
      <ActiveGoals />
    </PrimaryLayout>
  );
});
