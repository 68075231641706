import { ITeamAllocationTableData } from '@analytics-components/tables/TeamsAllocationTable/types';
import { DatetimeService } from '@analytics-services/datetimeService';
import { IContributor, LooseObject, ITeam, IWorkType } from '@analytics-types/common';
import { ApiType } from '@common-services/api/common/types/common';

export enum IAllocationType {
  issues = 'issues',
  prs = 'prs',
}

export interface IGetSelectedTeamsProps {
  contributors: IContributor[];
  teams: ITeam[];
}

interface IWorkTypeValue {
  value: number;
  workTypeName: string;
}

export interface ISumWorkTypeValuesData {
  [key: string]: IWorkTypeValue[];
}

export interface IConvertTeamsToNestedProps {
  rootTeamId: number;
  teams?: ITeam[];
  subTeams?: ITeam[];
  data?: ITeamAllocationTableData;
  allFieldName?: string;
}

export enum JiraFilterTypes {
  issueTypes = 'issue_types',
  jiraLabels = 'labels_include',
  projects = 'projects',
}

export type IJiraFilter = Record<JiraFilterTypes, string[]>;

export interface IGroupTeamsWithWorkTypesData {
  for: {
    jira: IJiraFilter;
    repositories: string[];
    with: {
      author: string[];
    };
  };
  granularity: string;
  values: {
    date: Date;
    values: number[];
  }[];
  workType?: IWorkType;
}

export enum IGroupTeamsWithWorkTypesType {
  'github',
  'jira',
}

export interface IGroupTeamsWithWorkTypes {
  [key: string]: IWorkTypeValue[];
}

export interface IForSet {
  issueTypes: string[];
  labels: string[];
  projects: string[];
  authors?: string[];
  jiraLabels?: string[];
  workType?: IWorkType;
}

export interface IFetchPrsProps {
  apiContext: LooseObject;
  api: ApiType;
  granularity: string;
  forSets: IForSet[];
}

export interface IFetchIssuesProps {
  apiContext: LooseObject;
  api: ApiType;
  granularity: string[];
  projects: string[];
  types: string[];
  labels: string[];
  assignees: string[];
  jiraAssignees: string[];
  workType: IWorkType;
}

export interface IFetchIssuesItem {
  granularity: string;
  with: {
    assignees: string[];
  };
  workType: IWorkType;
  values: {
    values: number[];
    date: Date;
  }[];
}

export interface IGetJiraAssignees {
  contributors: IContributor[];
  includeNullContributor: boolean;
  includeFakeContributor: boolean;
}

export interface IGetGranularity {
  interval: DatetimeService.Interval;
}
export interface IDistributeByGranularity {
  data: LooseObject;
  type: IAllocationType;
}

interface IGetTargetForSetItem {
  for: {
    labels_include: string[];
    jira: {
      labels_include: string[];
      projects: string[];
      issue_types: string[];
    };
  };
  granularity: string;
  values: {
    date: Date;
    values: number[];
  }[];
}

export interface IGetTargetForSetParams {
  item: IGetTargetForSetItem;
  forSets: IForSet[];
}

export interface IGenerateDisplayDataProps {
  data: any;
  workTypes: IWorkType[];
  type: IAllocationType;
  allData: any;
  isPercentage: boolean;
  granular: boolean;
}

export interface IGenerateDisplayDataReturn {
  name: string;
  type: string;
  count: number;
  values: { date: Date; value: number }[];
  color?: string;
}

export type IGenerateDisplayData = (
  props: IGenerateDisplayDataProps
) => IGenerateDisplayDataReturn[];
