import React from 'react';

import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import Panel from '@analytics-components/insights/Panel';

import bugsMetrics from './bugsMetrics';
import { wrapperStyles } from './styles';

const meta = {
  title: 'Bug Metrics',
  description: 'Series of metrics about bug tickets and the pull requests associated.',
};

const BugsPanel = () => {
  const { fetcher, plumber, prefetchedDataIds } = bugsMetrics;

  return (
    <Panel meta={meta}>
      <div css={wrapperStyles}>
        <ChartBoxWithKPIWidget
          fetcher={fetcher}
          plumber={plumber}
          prefetchedDataIds={prefetchedDataIds}
        />
      </div>
    </Panel>
  );
};

export default BugsPanel;
