import React from 'react';

import { Icon, icons } from '@lib/icon';

import getStyle from './styles';

interface Props {
  placeholder?: string;
  value: string;
  onChange?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
}

const Input: React.FC<Props> = ({ placeholder, value, onClick, onChange }) => (
  <div css={getStyle}>
    <Icon icon={icons.search} size={12} />
    <input
      data-cy="selector-input"
      placeholder={placeholder}
      type="text"
      value={value}
      onClick={onClick}
      onChange={onChange}
    />
  </div>
);

export default Input;
