import { DashboardChartServices } from '@align-pages/dashboard/services/dashboardChartServices';
import { IChartConfig, IChartTimeInterval } from '@align-pages/dashboard/types';
import { DashboardChart } from '@common-services/api/private/generated-from-backend/models';

export const newMetricData = (selectedMetric: IChartConfig): DashboardChart => ({
  id: null,
  metric: selectedMetric?.metric,
  name: selectedMetric?.name,
  description: selectedMetric?.description,
  ...DashboardChartServices.getTimeInterval({
    time_interval: IChartTimeInterval.P1M,
  }),
  filters: null,
  group_by: null,
});

export * as NewMetricServices from './newMetric.services';
