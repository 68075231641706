// @flow
import * as React from 'react';

export const sections = {
  leadTime: React.lazy(() => import('./LeadTime')),
  releaseFrequency: React.lazy(() => import('./ReleaseFrequency')),
  wip: React.lazy(() => import('./work-in-progress')),
  release: React.lazy(() => import('./release')),
  releasedPrs: React.lazy(() => import('./releasedPrs')),
  review: React.lazy(() => import('./review')),
  merge: React.lazy(() => import('./merge')),
};
