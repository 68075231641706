import React, { useEffect, useMemo } from 'react';

import { PrsIssuesTableHeader } from '@align-components/prs-issues-table-header';
import { ChartBreakdowns } from '@align-pages/dashboard/components/chart-edit/components/chart-breakdowns';
import { ChartLegends } from '@align-pages/dashboard/components/chart-edit/components/chart-legends';
import { ChartSidebar } from '@align-pages/dashboard/components/chart-edit/components/chart-sidebar';
import { DateRange } from '@align-pages/dashboard/components/chart-edit/components/date-range';
import { useDashboardChartData } from '@align-pages/dashboard/hooks/useDashboardChartData';
import { useDashboardStore } from '@align-pages/dashboard/store';
import { ChartMetrics } from '@align-pages/dashboard/types';
import { DateService } from '@align-services/dateService';
import { PrsIssuesTableTabs } from '@align-types/common';
import { useUserContext } from '@common-context/User';
import { isEqual } from '@common-services/vendor/lodash';
import { Spinner } from '@lib/Spinner';
import { Chart } from '@lib/chart';
import { NoData } from '@lib/empty/noData';

import {
  useBreakdownProperty,
  useBreakdownType,
  useChartData,
  useFiltersProperty,
  useIssuesTable,
  usePrsTable,
  useTableElements,
  useTableState,
} from './chartEdit.hooks';
import { ChartEditServices } from './chartEdit.services';
import {
  chartAreaStyles,
  chartContainerStyles,
  contentStyles,
  sidebarStyles,
  tabletStyles,
  wrapperStyles,
} from './chartEdit.styles';
import { IChartEdit } from './chartEdit.types';
import { ChartIssuesTable } from './components/chart-issues-table';
import { ChartPrTable } from './components/chart-pr-table';

export const ChartEdit: React.FC<IChartEdit> = React.memo(({ metric }) => {
  const { isGod } = useUserContext();

  const [chartParams, dropChartParams] = useDashboardStore((state) => [
    state.chartParams,
    state.onChartParamsDrop,
  ]);
  const filtersUpdate = useDashboardStore((state) => state.onChartFiltersUpdate);
  const [
    breakdownOptionsState,
    onBreakdownOptionsInit,
    dropBreakdownOptions,
  ] = useDashboardStore((state) => [
    state.breakdownOptions,
    state.onBreakdownOptionsInit,
    state.onDropBreakdownOptions,
  ]);
  const [properties, dropProperties] = useDashboardStore((state) => [
    state.properties,
    state.onDropProperties,
  ]);

  const filters = useFiltersProperty();
  const breakdownType = useBreakdownType();
  const { isLoading } = useDashboardChartData();
  const breakdownOptions = useBreakdownProperty();

  const {
    activeTab,
    selectedRange,
    updatedChartParams,
    changeTab,
    handleChartPointSelect,
    handleRemoveTimeframe,
  } = useTableElements(breakdownOptionsState, chartParams, properties);

  const {
    completedPrsCount,
    inProgressPrsCount,
    prsData,
    prTableIsLoading,
    prTableIsLoadingMore,
    onShowMorePrsHandler,
  } = usePrsTable(activeTab, metric, selectedRange, updatedChartParams);

  const {
    completedIssuesCount,
    inProgressIssuesCount,
    issuesData,
    issuesTableIsLoading,
    issuesTableIsLoadingMore,
    onShowMoreIssuesHandler,
  } = useIssuesTable(activeTab, metric, selectedRange, updatedChartParams);

  const { chartType, options, seriesColors, seriesNames } = useChartData(handleChartPointSelect);

  const isJiraMetric = useMemo(() => !!metric?.jiraMetricField, [metric]);

  const { data, isDataLoading, tabs } = useTableState(
    activeTab,
    chartParams,
    isJiraMetric,
    completedIssuesCount,
    completedPrsCount,
    inProgressIssuesCount,
    inProgressPrsCount,
    issuesData,
    prsData,
    issuesTableIsLoading,
    issuesTableIsLoadingMore,
    prTableIsLoading,
    prTableIsLoadingMore
  );

  useEffect(() => {
    return () => {
      dropProperties();
      dropChartParams();
      dropBreakdownOptions();
    };
  }, []);

  useEffect(() => {
    if (!isEqual(filters, chartParams?.filters)) {
      filtersUpdate(filters);
    }
  }, [filters, chartParams]);

  useEffect(() => {
    const previousState = breakdownOptionsState.map(({ value }) => value);
    const nextState = breakdownOptions.map(({ value }) => value);

    if (!isEqual(previousState, nextState)) {
      onBreakdownOptionsInit(breakdownOptions);
    }
  }, [breakdownOptions, breakdownOptionsState]);

  // TODO: DEV-6164 use setSize instead
  // This is a workaround to redraw the chart when the wrapper elements width changes
  useEffect(() => {
    const timer = setTimeout(() => window.dispatchEvent(new Event('resize')), 0);

    return () => clearTimeout(timer);
  }, [breakdownType]);

  const chartDateRange = useMemo(
    () =>
      DateService.durationToReadableText({
        duration: chartParams?.time_interval
          ? chartParams.time_interval
          : ChartEditServices.getDateRangeOptions()[3].value,
        prefix: 'Last',
      }),
    [chartParams]
  );

  // TODO: Remove the god-mode guard when Wait Time PRs are ready to be exposed to public
  const isTableVisible = useMemo(
    () => metric.metric !== ChartMetrics.PR_WAIT_FIRST_REVIEW_TIME || isGod,
    [isGod, metric]
  );

  const canShowMore = useMemo(() => {
    if (activeTab === PrsIssuesTableTabs.COMPLETED) {
      return data?.length < (completedPrsCount || completedIssuesCount);
    } else if (activeTab === PrsIssuesTableTabs.IN_PROGRESS) {
      return data?.length < (inProgressPrsCount || inProgressIssuesCount);
    }
    return false;
  }, [
    data,
    activeTab,
    completedPrsCount,
    inProgressPrsCount,
    completedIssuesCount,
    inProgressIssuesCount,
  ]);

  return (
    <div css={wrapperStyles}>
      <div css={sidebarStyles}>
        <ChartSidebar />
      </div>
      <div css={contentStyles}>
        <div className="header">
          <DateRange />
        </div>
        <div css={chartContainerStyles}>
          {isLoading ? (
            <div className="spinner-area">
              <Spinner loading />
            </div>
          ) : (
            <div css={chartAreaStyles}>
              <div className="chart">
                <ChartLegends breakdownOptions={breakdownOptionsState} />
                <Chart
                  options={options}
                  chartType={chartType}
                  size={350}
                  seriesNames={seriesNames}
                  colors={seriesColors}
                />
              </div>
              {breakdownType && (
                <div className="breakdown-options">
                  <ChartBreakdowns />
                </div>
              )}
            </div>
          )}
        </div>
        {isTableVisible && (
          <div css={tabletStyles}>
            {isDataLoading && <div className="dataTable-placeholder" />}
            {!isDataLoading && data && (
              <PrsIssuesTableHeader
                activeTab={activeTab}
                customValue={chartDateRange}
                dateFrom={chartParams?.date_from}
                dateTo={chartParams?.date_to}
                isJiraMetric={isJiraMetric}
                selectedRange={selectedRange}
                tabs={tabs}
                onChangeTab={changeTab}
                onRemoveTimeframe={handleRemoveTimeframe}
              />
            )}
            {!isDataLoading && data?.length === 0 && <NoData />}
            {!isDataLoading && data?.length > 0 && (
              <>
                {prsData?.data.length > 0 && (
                  <ChartPrTable
                    data={prsData}
                    metric={metric}
                    isLoadingPart={prTableIsLoadingMore}
                    onShowMoreHandler={canShowMore && onShowMorePrsHandler}
                  />
                )}
                {issuesData?.issues.length > 0 && (
                  <ChartIssuesTable
                    data={issuesData}
                    metric={metric}
                    isLoadingPart={issuesTableIsLoadingMore}
                    onShowMoreHandler={canShowMore && onShowMoreIssuesHandler}
                  />
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
});
