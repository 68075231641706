import { IUrlParams } from '@common-services/api/common/types/apiClient';
import { privateApi } from '@common-services/api/private';
import {
  AlignGoalsRequest,
  AlignGoalsResponse,
  GoalCreateRequest,
  GoalUpdateRequest,
  GoalUnassignTeamRequest,
} from '@common-services/api/private/generated-from-backend/models';
import { CreatedIdentifier } from '@common-services/api/public/generated-from-backend/models';

export const getGoalsList = async (
  params: AlignGoalsRequest,
  token: string
): Promise<AlignGoalsResponse> => {
  return privateApi.alignGoals.getGoalsList(params, token, {
    message: `Cannot get goals for accountId ${params.account}`,
  });
};

export const createGoal = async (
  params: GoalCreateRequest,
  token: string
): Promise<CreatedIdentifier> => {
  return privateApi.alignGoals.createGoal(params, token, {
    message: `Cannot create goal for accountId ${params.account}`,
  });
};

export const updateGoal = async (
  urlParams: IUrlParams<'goalId'>,
  params: GoalUpdateRequest,
  token: string
): Promise<CreatedIdentifier> => {
  return privateApi.alignGoals.updateGoal(urlParams, params, token, {
    message: `Cannot update goal for goalId ${urlParams.goalId}`,
  });
};

export const removeGoal = async (
  urlParams: IUrlParams<'goalId'>,
  params: GoalUnassignTeamRequest,
  token: string
): Promise<CreatedIdentifier> => {
  return privateApi.alignGoals.removeGoal(urlParams, params, token, {
    message: `Cannot remove goal for a team with ID ${params.team}`,
  });
};

export * as AlignGoalsServices from './alignGoals';
