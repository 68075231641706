import { useMutation } from 'react-query';

import { IUseMutationHook } from '@align-types/common';
import { useAuth0 } from '@common-context/Auth0';
import { useDemoCookie } from '@common-pages/Demo';
import { IApiBasicError } from '@common-services/api/common/types/common';
import { BackofficeServices } from '@common-services/api/private/services/backoffice';
import { IBecomeUserInput } from '@common-services/api/private/types/becomeUser';
import { User } from '@common-services/api/public/generated-from-backend/models';

export function useBecomeUser(): IUseMutationHook<User, IApiBasicError, IBecomeUserInput> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();

  const becomeUser = async (input: IBecomeUserInput): Promise<User> => {
    const token: string = isDemo === false ? await getTokenSilently() : null;
    return BackofficeServices.becomeUser(input?.userId || '', token);
  };

  const { mutate, isLoading, data, error, isError, isSuccess } = useMutation<
    User,
    IApiBasicError,
    IBecomeUserInput
  >(becomeUser);

  return { mutate, isLoading, data, error, isError, isSuccess };
}
