import { useQuery } from 'react-query';

import { CONFIG } from '@align-constants';
import { IUseQueryHookRest } from '@align-types/common';
import { useAuth0 } from '@common-context/Auth0';
import { useUserContext } from '@common-context/User';
import { useDemoCookie } from '@common-pages/Demo';
import { IApiBasicError } from '@common-services/api/common/types/common';
import { GoalTemplate } from '@common-services/api/public/generated-from-backend/models';

import { GoalTemplatesServices } from '../services/goalTemplates';

export function useGoalTemplates(
  accountId: number,
  isCached: boolean = false
): IUseQueryHookRest<GoalTemplate[]> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();

  const { isLoading, data, error } = useQuery<GoalTemplate[], IApiBasicError>(
    ['goalTemplates', accountId],
    async () => {
      const token: string = isDemo === false ? await getTokenSilently() : null;
      return GoalTemplatesServices.goalTemplates({ accountId }, token);
    },
    {
      ...CONFIG.API.FETCH_OPTIONS,
      cacheTime: isCached ? CONFIG.API.FETCH_OPTIONS.cacheTime : 0,
      staleTime: isCached ? CONFIG.API.FETCH_OPTIONS.staleTime : 0,
    }
  );

  return { isLoading, data, error };
}

export function useOwnedGoalTemplates(
  isCached: boolean = false
): IUseQueryHookRest<GoalTemplate[]> {
  const {
    user: {
      defaultAccount: { id: accountId },
    },
  } = useUserContext();
  return useGoalTemplates(accountId, isCached);
}

export function useGoalTemplate(
  goalId: number,
  isCached: boolean = false
): IUseQueryHookRest<GoalTemplate> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();

  const { isLoading, data, error } = useQuery<GoalTemplate, IApiBasicError>(
    ['goalTemplate', goalId],
    async () => {
      const token: string = isDemo === false ? await getTokenSilently() : null;
      return GoalTemplatesServices.goalTemplate({ goalId }, token);
    },
    {
      ...CONFIG.API.FETCH_OPTIONS,
      cacheTime: isCached ? CONFIG.API.FETCH_OPTIONS.cacheTime : 0,
      staleTime: isCached ? CONFIG.API.FETCH_OPTIONS.staleTime : 0,
    }
  );

  return { isLoading, data, error };
}
