import _ from 'lodash';

import { calculateGranularity } from '@analytics-components/Prefetcher';
import TimeSeries from '@analytics-components/charts/TimeSeries';
import { BigText } from '@analytics-components/charts/Tooltip';
import { dateTime } from '@common-services/dateService';
import * as NumberService from '@common-services/numberService';
import { theme } from '@styles/theme';
import { hexToRGBA } from '@utils/colors';

const participantsPerPullRequest = {
  title: 'Participants / Pull Request',
  description: '',
  prefetchedDataIds: ['prs-metrics'],
  plumber: (fetchedData, cachedData, apiContext) => {
    const timeseriesGranularity =
      cachedData['prs-metrics'].customGranularity === 'month' ? 'custom-aligned' : 'custom';
    const reviews = cachedData['prs-metrics'].values[timeseriesGranularity]['participants-per'];

    const chartData = _(reviews)
      .map((v) => ({
        date: v.date,
        value: Number(NumberService.round(v.value, 1)) || null,
      }))
      .value();

    const notNullValues = chartData.filter((v) => v.value);
    const avgValue = !!notNullValues.length
      ? notNullValues.reduce((acc, v) => acc + v.value, 0) / notNullValues.length
      : 0;

    const customGranularity = calculateGranularity(apiContext.interval);
    const xTickFormat = customGranularity === 'month' ? dateTime.month : dateTime.monthDay;
    const tooltip = {
      renderBigFn: (v) => <BigText content={`${NumberService.round(v.y, 1)} participants / PR`} />,
    };
    if (_(['month', 'week']).includes(customGranularity)) {
      tooltip.dateRange = {
        ...apiContext.interval,
      };
      tooltip.granularity = customGranularity;
    }

    return {
      empty: chartData.length === 0,
      chart: {
        component: TimeSeries,
        params: {
          data: chartData,
          timeMode: false,
          extra: {
            average: {
              color: theme.color.neutral[80],
              value: avgValue,
              renderFn: tooltip.renderBigFn,
            },
            axisKeys: {
              x: 'date',
              y: 'value',
            },
            axisLabels: {
              y: 'Participants',
            },
            color: theme.color.ui.yellow[100],
            fillColor: hexToRGBA(theme.color.ui.yellow[100], 0.1),
            maxNumberOfTicks: 10,
            ticks: {
              x: { tickFormat: xTickFormat },
            },
            tooltip,
          },
        },
      },
      kpis: [],
    };
  },
};

export default participantsPerPullRequest;
