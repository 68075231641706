import React from 'react';

import { NoDataForStage } from '../noDataForStage';

const NoReleases = ({ textOnly }) => (
  <NoDataForStage
    textOnly={textOnly}
    textOnlyCopy="no releases"
    companionCopy="There has not been any release in the date range selected"
  />
);

export { NoReleases };
