// This file defines a set of possible rules that one can add to their work types in Allocation settings
import React from 'react';

import { icons } from '@lib/icon';

import GithubLabelDataBinder from './rulesDef/GithubLabel/data';
import IssueTypeDataBinder from './rulesDef/IssueType/data';
import JiraLabelDataBinder from './rulesDef/JiraLabel/data';

export enum RuleTypeKey {
  issueType = 'issueType',
  githubLabel = 'githubLabel',
  jiraLabel = 'jiraLabel',
}

export interface IRuleType {
  name: string;
  active: boolean;
  label: string;
  icon: icons;
  renderFn: (rule, workType) => React.ReactNode;
  withJira: boolean;
}

export type IRuleTypes = Record<RuleTypeKey, IRuleType>;

const ruleTypes: IRuleTypes = {
  issueType: {
    name: 'issueType',
    active: true,
    label: 'Issue Type',
    icon: icons.issues,
    renderFn: (rule, workType) => <IssueTypeDataBinder rule={rule} workType={workType} />,
    withJira: true,
  },
  githubLabel: {
    name: 'githubLabel',
    active: true,
    label: 'Github Label',
    icon: icons.release,
    renderFn: (rule, workType) => <GithubLabelDataBinder rule={rule} workType={workType} />,
    withJira: false,
  },
  jiraLabel: {
    name: 'jiraLabel',
    active: true,
    label: 'Jira Label',
    icon: icons.release,
    renderFn: (rule, workType) => <JiraLabelDataBinder rule={rule} workType={workType} />,
    withJira: true,
  },
};

export default ruleTypes;
