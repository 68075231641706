import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { PrimaryLayoutContext } from '@align-components/layouts/primary/context';
import { metricsConfig } from '@align-constants';
import { GoalsSingleComponentServices } from '@align-pages/goals/single/components/goals-single-component/goalsSingleComponent.services';
import {
  IMetricDescriptionTextMode,
  MetricDescriptionText,
} from '@align-pages/goals/single/components/metric-description/components/metric-description-text';
import {
  IThresholdMenuSubmit,
  ThresholdMenu,
} from '@align-pages/goals/single/components/metric-description/components/threshold-menu';
import { MetricService } from '@align-services/metricService';
import { useOnClickOutside } from '@common-hooks/useOnClickOutside';
import { GoalMetricParams } from '@common-services/api/private/generated-from-backend/models';
import { Icon, icons } from '@lib/icon';

import { metricDescriptionStyles, thresholdEditStyles } from './metricDescription.styles';
import { IMetricDescription } from './metricDescription.types';

export const MetricDescription: React.FC<IMetricDescription> = React.memo(
  ({ metricValue, threshold, valueType }) => {
    const ref = useRef<HTMLDivElement>();
    const { onMetricParamsChange } = useContext(PrimaryLayoutContext);
    const [menuOpen, setMenuOpen] = useState(false);
    const toggleMenu = useCallback(() => setMenuOpen(!menuOpen), [menuOpen]);

    useOnClickOutside(ref, () => setMenuOpen(false));

    const thresholdReadable = useMemo(() => {
      return threshold
        ? MetricService.getReadableValue(
            GoalsSingleComponentServices.deNormaliseMetricValue(threshold),
            valueType,
            metricsConfig[metricValue].unit
          )
        : null;
    }, [threshold, valueType, metricValue]);

    const { thresholdValue, thresholdUnit } = useMemo(
      () => MetricService.getThresholdParams(thresholdReadable.toString()),
      [thresholdReadable]
    );

    const [newThresholdReadable, setNewThresholdReadable] = useState(thresholdReadable);
    const [newThresholdValue, setNewThresholdValue] = useState<GoalMetricParams['threshold']>(
      thresholdValue
    );
    const [newThresholdUnit, setNewThresholdUnit] = useState(thresholdUnit);

    useEffect(() => {
      serializeMetricParams(thresholdValue.toString(), thresholdUnit);
    }, [thresholdValue]);

    useEffect(() => {
      setNewThresholdValue(thresholdValue);
      setNewThresholdUnit(thresholdUnit);
      setNewThresholdReadable(thresholdReadable);
    }, [thresholdValue, thresholdUnit, thresholdReadable]);

    const submitThresholdHandler = useCallback<IThresholdMenuSubmit>(
      ({ display, value, unit }) => {
        setNewThresholdUnit(unit);
        setNewThresholdValue(value);
        setNewThresholdReadable(display);
        serializeMetricParams(value, unit);
        setMenuOpen(false);
      },
      [metricValue, valueType]
    );

    const serializeMetricParams = (value: string, unit: string) => {
      const convertedServerValue = GoalsSingleComponentServices.getConvertedServerData(
        value,
        unit,
        valueType,
        metricValue
      );
      onMetricParamsChange({
        threshold: convertedServerValue,
      });
    };

    return (
      <section css={metricDescriptionStyles}>
        <MetricDescriptionText
          metricValue={metricValue}
          mode={IMetricDescriptionTextMode.EDIT}
          threshold={newThresholdReadable}
        />
        <div css={thresholdEditStyles} ref={ref}>
          <div onClick={toggleMenu} className="change-icon">
            <Icon icon={icons.edit} />
          </div>
          {menuOpen && (
            <ThresholdMenu
              submitHandler={submitThresholdHandler}
              metricValue={metricValue}
              value={newThresholdValue}
              valueType={valueType}
              unit={newThresholdUnit}
            />
          )}
        </div>
      </section>
    );
  }
);
