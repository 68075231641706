import React from 'react';

import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import Panel from '@analytics-components/insights/Panel';
import { prepareChartBoxes } from '@analytics-pages/delivery-pipeline/insights/helper';

import waitTimeFirstReview from './waitTimeFirstReview';

const meta = {
  title: 'Wait Time for 1st Review',
  description:
    'Lower the time pull requests stay idle, waiting for the 1st review. <p class="small text-secondary mt-1 mb-0">Calculated up to the 95th percentile.</p>',
};

const chartBoxes = [
  {
    id: 'wait-time-first-review',
    component: ChartBoxWithKPIWidget,
    params: waitTimeFirstReview,
  },
];

const WaitTimePanel = () => {
  const preparedChartBoxes = prepareChartBoxes(chartBoxes);
  return <Panel meta={meta} chartBoxes={preparedChartBoxes} />;
};

export default WaitTimePanel;
