import { CellProps, Column } from 'react-table';

import { IssuesCellAssignee } from '@align-components/issues-table-columns/cell-assignee';
import { IssuesCellComments } from '@align-components/issues-table-columns/cell-comments';
import { IssuesCellExpand } from '@align-components/issues-table-columns/cell-expand';
import { IssuesCellMetric } from '@align-components/issues-table-columns/cell-metric';
import { IssuesCellPriority } from '@align-components/issues-table-columns/cell-priority';
import { IssuesCellSize } from '@align-components/issues-table-columns/cell-size';
import { IssuesCellStatus } from '@align-components/issues-table-columns/cell-status';
import { IssuesCellTitle } from '@align-components/issues-table-columns/cell-title';
import { IChartConfig } from '@align-pages/dashboard/types';
import { MetricColumnName } from '@align-types/constants';
import { IIssuesTableRow } from '@align-types/issues';

export const issuesTableColumns = (metric: IChartConfig): Column<IIssuesTableRow>[] => [
  {
    accessor: 'title',
    Cell: IssuesCellExpand,
  },
  {
    Header: [MetricColumnName.MTTA, MetricColumnName.MTTR, MetricColumnName.REPAIR_TIME].includes(
      metric?.metricColumnName
    )
      ? 'Bugs'
      : 'Issues',
    Cell: IssuesCellTitle,
  },
  {
    Header: 'Size',
    Cell: IssuesCellSize,
  },
  {
    Header: 'Comments',
    Cell: IssuesCellComments,
  },
  {
    Header: 'Assignee',
    Cell: IssuesCellAssignee,
  },
  {
    Header: metric?.metricColumnName || '',
    Cell: (cellProps: CellProps<IIssuesTableRow>) => (
      <IssuesCellMetric {...cellProps} metricFieldName={metric?.jiraMetricField} />
    ),
  },
  {
    Header: 'P',
    Cell: IssuesCellPriority,
  },
  {
    Header: 'Status',
    Cell: IssuesCellStatus,
  },
];
