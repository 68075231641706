import { IDeploymentsParams } from '@common-services/api/common/types/deployments';
import {
  FilterDeploymentsRequest,
  FilteredDeployments,
} from '@common-services/api/public/generated-from-backend/models';
import { getOffset } from '@common-services/dateService';

import { publicApi } from '../index';

export const fetchDeployments = async (
  params: IDeploymentsParams,
  token: string
): Promise<FilteredDeployments> => {
  const body: FilterDeploymentsRequest = {
    account: params.account,
    date_from: params.dateInterval.from.format('YYYY-MM-DD'),
    date_to: params.dateInterval.to.format('YYYY-MM-DD'),
    ...(params.environments ? { in: params.environments } : {}),
    ...(params.repositories ? { in: params.repositories } : {}),
    ...(params.jira ? { jira: params.jira } : {}),
    ...(params.labelsExclude ? { pr_labels_exclude: params.labelsExclude } : {}),
    ...(params.labelsInclude ? { pr_labels_include: params.labelsInclude } : {}),
    timezone: getOffset(),
    ...(params.contributors
      ? {
          with: {
            pr_author: params.contributors.map((c) => c.login).filter((c) => !!c),
          },
        }
      : {}),
  };
  return publicApi.deployments.fetchDeployments(body, token, {
    message: `Cannot get deployments data for accountId ${params.account}`,
  });
};

export * as DeploymentsServices from './deployments';
