import React from 'react';
import { CellProps, Renderer } from 'react-table';

import { IIssuesTableRow } from '@align-types/issues';
import { Tooltip } from '@lib/tooltip';

import {
  iconWrapperStyles,
  taskDetailsStyles,
  titleStyles,
  wrapperStyles,
} from './cellTitle.styles';

export const IssuesCellTitle: Renderer<CellProps<IIssuesTableRow>> = React.memo(({ row }) => {
  return (
    <div css={wrapperStyles}>
      <div css={iconWrapperStyles}>
        {row.depth > 0 ? (
          <div className="pr-icon">{row.original.prIcon}</div>
        ) : (
          <img alt="" src={row.original.issueAvatar} title={row.original.type} />
        )}
      </div>
      <div css={titleStyles}>
        <Tooltip
          content={
            <div className="tooltip-text">
              <span className="entity-id">#{row.original.id}</span> {row.original.title}
            </div>
          }
        >
          <a className="title" href={row.original.url || '#'} target="_blank" rel="noreferrer">
            {row.depth > 0 ? (
              <div className="pr-title-text">
                <span className="pr-repo">{row.original.repo}</span> #{row.original.id}{' '}
                {row.original.title}
              </div>
            ) : (
              <div className="issue-title-text">
                <span className="issue-id">{row.original.id}</span> {row.original.title}
              </div>
            )}
          </a>
        </Tooltip>
        <div css={taskDetailsStyles}>
          <img
            alt=""
            className="user-avatar"
            src={row.depth > 0 ? row.original.creatorAvatar : row.original.reporterAvatar}
            title={row.depth > 0 ? row.original.creator : row.original.reporter}
          />
          <div className="task-details">
            <span>{row.depth > 0 ? 'Created by ' : 'Reported by '}</span>
            <span className="reporter">
              {row.depth > 0 ? row.original.creator : row.original.reporter}
            </span>{' '}
            {row.original.age}
            <span> ago</span>
          </div>
        </div>
      </div>
    </div>
  );
});
