import { css } from '@emotion/react';

export const navButtonStyles = (isActive) => ({ spacing, color }) => css`
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: ${spacing.gap['06']};
  margin-left: ${spacing.gap['06']};
  color: ${color.neutral['80']};
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-bottom-color: transparent;
  border-top: 3px solid transparent;

  ${isActive &&
  `
    color: ${color.neutral['100']};
    border-bottom-color: ${color.ui.lightorange['100']};
  `}

  & > div {
    margin-left: ${spacing.gap['04']};
  }
`;

export const wrapperStyles = ({ spacing }) => css`
  height: 100%;
  display: flex;
  margin-left: ${spacing.gap['08']};
  cursor: pointer;
`;
