import React from 'react';

import { useApi } from '@analytics-hooks';
import Simple from '@analytics-pages/templates/Simple';

import './index.scss';

export const isDev = process.env.NODE_ENV === 'development';

const testingEnvsRegExp = new RegExp('^(development|staging|branch-[0-9a-g]+)$');
export const isNotProd = testingEnvsRegExp.test(window.ENV?.environment);

const development = {
  Breakpoints: () => (
    <div id="breakpoint-helper">
      <div className="breakPoints">
        <div className="breakPoint" id="xs">
          <span className="desc">Phone</span>
          <span className="size">(0px)</span>
          <span className="name">xs</span>
        </div>
        <div className="breakPoint" id="sm">
          <span className="desc">Tablet</span>
          <span className="size">(768px)</span>
          <span className="name">sm</span>
        </div>
        <div className="breakPoint" id="md">
          <span className="desc">Small screen</span>
          <span className="size">(992px)</span>
          <span className="name">md</span>
        </div>
        <div className="breakPoint" id="lg">
          <span className="desc">Medium screen</span>
          <span className="size">(1200px)</span>
          <span className="name">lg</span>
        </div>
        <div className="breakPoint" id="xl">
          <span className="desc">Large screen</span>
          <span className="size">(1300px)</span>
          <span className="name">xl</span>
        </div>
      </div>
    </div>
  ),

  Bearer: () => {
    const {
      auth: { token, isAuthenticated },
      context: { account },
      ready,
    } = useApi(true, false);

    if (!ready) {
      return <Simple>Loading...</Simple>;
    }

    if (!isAuthenticated) {
      return <Simple>Not authenticated!</Simple>;
    }

    return (
      <Simple>
        <div
          css={{
            div: {
              display: 'block',
              span: {
                'textAlign': 'left',
                'width': '100%',
                'display': 'block',
                '&.key': {
                  fontWeight: '600',
                  fontSize: '1.3rem',
                },
                '&.value': {
                  fontFamily:
                    'SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace',
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-all',
                },
              },
            },
          }}
        >
          <div className="account">
            <span className="key">Account ID:</span>
            <span className="value">{account}</span>
          </div>
          <div className="token">
            <span className="key">Bearer Token:</span>
            <span className="value">{token}</span>
          </div>
        </div>
      </Simple>
    );
  },

  errorBoxClass: isNotProd && 'development-error-box',
};

export default development;
