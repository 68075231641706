import { useIssuesForTable } from '@align-hooks/useIssuesForTable';
import { IDateRangeInMls, PrsIssuesTableTabs } from '@align-types/common';
import { IUseComputedIssues } from '@align-types/issues';
import { useUserContext } from '@common-context/User';
import { DashboardChart } from '@common-services/api/private/generated-from-backend/models';

import { IChartConfig } from '../types';
import { useGetSearchedIssues } from './useGetSearchedIssues';

export function useComputedIssues(
  chartParams: DashboardChart,
  metric: IChartConfig,
  activeTab: PrsIssuesTableTabs,
  selectedRange: IDateRangeInMls
): IUseComputedIssues {
  const {
    user: {
      defaultAccount: { id: accountId },
    },
  } = useUserContext();

  const {
    data: initialCompletedIssues,
    isLoading: completedIssuesIsLoading,
    error: completedIssuesError,
  } = useGetSearchedIssues(
    accountId,
    chartParams,
    metric,
    PrsIssuesTableTabs.COMPLETED,
    selectedRange
  );

  const {
    data: inProgressIssues,
    isLoading: inProgressIssuesIsLoading,
    error: inProgressIssuesError,
  } = useGetSearchedIssues(accountId, chartParams, metric, PrsIssuesTableTabs.IN_PROGRESS, null);

  const {
    data,
    completedIssues,
    isLoading,
    isLoadingMore,
    error,
    onShowMoreHandler,
  } = useIssuesForTable(
    accountId,
    activeTab,
    selectedRange,
    initialCompletedIssues,
    inProgressIssues,
    completedIssuesIsLoading,
    inProgressIssuesIsLoading,
    completedIssuesError,
    inProgressIssuesError
  );

  return {
    data,
    completedIssuesCount: completedIssues?.jira_issues?.length,
    inProgressIssuesCount: inProgressIssues?.jira_issues?.length,
    isLoading,
    isLoadingMore,
    error,
    allCompletedIssues: initialCompletedIssues,
    onShowMoreHandler,
  };
}
