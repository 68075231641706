import classnames from 'classnames';
import React from 'react';

import { KPITitleType } from '@analytics-components/insights/Box/types';
import { cardBodyStyles } from '@analytics-components/insights/styles';

interface Props {
  children?: React.ReactNode;
  subtitle?: KPITitleType;
  title: KPITitleType;
}

const BoxKPI: React.FC<Props> = React.memo(({ title, subtitle, children }) => (
  <div className="card mb-4 bg-light border-0">
    <div css={cardBodyStyles} className="card-body p-4">
      <h5
        className={classnames(
          'card-title text-xs text-uppercase',
          title.bold && 'font-weight-bold'
        )}
      >
        {title.text}
      </h5>
      {subtitle && (
        <h6
          className={classnames(
            'card-subtitle mb-2 text-xs text-uppercase',
            subtitle.bold && 'font-weight-bold'
          )}
        >
          {subtitle.text}
        </h6>
      )}
      <div className="card-text">{children}</div>
    </div>
  </div>
));

export default BoxKPI;
