import { IFilterBreakdownMap } from '@common-services/api/public/types/metrics';
import { FilterType } from '@lib/filter-panel/filterPanel.types';

export enum PUBLIC_API_ENDPOINTS_CONSTANTS {
  USER = '/user',
  INVITE_CHECK = '/invite/check',
  INVITE_ACCEPT = '/invite/accept',
  SETTINGS_TEAMS = '/settings/teams',
  GOAL_TEMPLATES = '/goal_templates/:accountId',
  GOAL_TEMPLATE = '/goal_templates/:goalId',
  PULL_REQUESTS_PAGINATION_PLAN = '/paginate/pull_requests',
  FILTERED_PULL_REQUESTS = '/filter/pull_requests',
  JIRA_FILTERS = '/filter/jira',
  JIRA_PROJECTS = '/settings/jira/projects/:accountId',
  ENVIRONMENTS = '/filter/environments',
  DEPLOYMENTS = '/filter/deployments',
  CONTRIBUTORS = '/get/contributors/:accountId',
  CONTRIBUTORS_FILTER = '/filter/contributors',
  LABELS_FILTERS = '/filter/labels',
  GET_PULL_REQUESTS = '/get/pull_requests',
  ACCOUNT_FEATURES = '/account/:accountId/features',
  PULL_REQUESTS = '/metrics/pull_requests',
  ISSUES = '/metrics/jira',
  RELEASES = '/metrics/releases',
  ACCOUNT_DETAILS = '/account/:accountId/details',
  GET_JIRA_ISSUES = '/get/jira_issues',
}

export const FILTER_BREAKDOWN_MAP: IFilterBreakdownMap = {
  [FilterType.JIRA_PRIORITIES]: 'priorities',
  [FilterType.JIRA_ISSUE_TYPES]: 'issue_types',
  [FilterType.JIRA_LABELS]: 'labels_include',
};
