import React from 'react';

import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import Panel from '@analytics-components/insights/Panel';
import { prepareChartBoxes } from '@analytics-pages/delivery-pipeline/insights/helper';

import prsCreatedByRepos from './prs-created-by-repos';
import prsCreatedTimeseries from './prs-created-timeseries';

const meta = {
  title: 'Pull Requests Created',
  description: '',
};

const chartBoxes = [
  {
    id: 'prs-created-timeseries',
    component: ChartBoxWithKPIWidget,
    params: prsCreatedTimeseries,
  },
  {
    id: 'prs-created-by-repos',
    component: ChartBoxWithKPIWidget,
    params: prsCreatedByRepos,
  },
];

const PullRequestsCreatedPanel = () => {
  const preparedChartBoxes = prepareChartBoxes(chartBoxes);
  return <Panel meta={meta} chartBoxes={preparedChartBoxes} />;
};

export default PullRequestsCreatedPanel;
