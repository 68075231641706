import { LooseObject } from '@align-types/common';
import { IColor } from '@styles/theme/colors/colors';

export enum BreakdownOptionsReducerTypes {
  INIT = 'init',
  CHANGE = 'change',
}

export interface IBreakdownOptionItem {
  name: string;
  value: string;
  selected?: boolean;
  color?: IColor;
  extras?: LooseObject;
}

export interface IBreakdownOptionsReducerAction {
  type: BreakdownOptionsReducerTypes;
  list?: IBreakdownOptionItem[];
  value?: string;
  selected?: boolean;
}
