import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PanelContentSwitcher from '@analytics-components/insights/PanelContentSwitcher';
import { useAllocationContext } from '@analytics-context/Allocation';
import { useUserContext } from '@common-context/User';
import { icons } from '@lib/icon';

import { wrapperStyles } from './styles';

const ContentSwitcher = () => {
  const { contentType, onContentTypeChange } = useAllocationContext();
  const { account } = useUserContext();
  const [activeTab, setActiveTab] = useState(contentType);

  useEffect(() => {
    setActiveTab(contentType);
  }, [contentType]);

  const tabs = useMemo(
    () => [
      {
        icon: icons.nav_pr,
        description: 'Pull Requests',
        content: null,
      },
      {
        icon: icons.issues,
        description: 'Issues',
        content: null,
      },
    ],
    []
  );

  const handleContentChange = useCallback((index) => {
    setActiveTab(index);
    onContentTypeChange(index);
  }, []);

  return account.jira ? (
    <div css={wrapperStyles}>
      <PanelContentSwitcher
        buttonSize={40}
        iconSize={14}
        tabs={tabs}
        activeTab={activeTab}
        onContentChange={handleContentChange}
      />
    </div>
  ) : null;
};

export default ContentSwitcher;
