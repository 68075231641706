import React, { useMemo } from 'react';

import { useOwnedJiraFilter } from '@common-services/api/public/hooks/useFiltersData';
import { FilterDropdown } from '@lib/filter-dropdown';
import { FilterType, IFilterTypeProps } from '@lib/filter-panel';
import { SelectTsOptionSingle } from '@lib/selector-ts';

import { useExistingFilters } from '../filterPanel.hooks';

export const JiraLabels: React.FC<IFilterTypeProps> = React.memo(({ onChange, chartParamType }) => {
  const { data: fetchedLabels, isLoading } = useOwnedJiraFilter(['issues', 'labels'], true);

  const selectedValues = useExistingFilters(FilterType.JIRA_LABELS, chartParamType);

  const options = useMemo<SelectTsOptionSingle[]>(() => {
    return fetchedLabels?.labels
      .filter(({ kind }) => kind !== 'component')
      .map((issueType) => ({
        name: issueType.title,
        id: issueType.title,
      }));
  }, [fetchedLabels]);

  return (
    <FilterDropdown
      onChange={onChange}
      options={options}
      label="Label"
      allSelectedLabel="All Labels"
      isLoading={isLoading}
      initialSelected={selectedValues}
    />
  );
});
