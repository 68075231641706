import React from 'react';

import { DATA_STATUS } from '@analytics-components/StatusIndicator';

import { NoData } from '../noData';
import { NoMerged } from '../noMerged';
import { NoReleases } from '../noReleases';
import { NoReviewed } from '../noReviewed';
import { NoSubmitted } from '../noSubmitted';

interface Props {
  dataStatus: string;
  textOnly: boolean;
}

const Nothing: React.FC<Props> = React.memo(({ textOnly, dataStatus }) => {
  switch (dataStatus) {
    case DATA_STATUS.NO_SUBMITTED:
      return <NoSubmitted textOnly={textOnly} />;
    case DATA_STATUS.NO_REVIEWED:
      return <NoReviewed textOnly={textOnly} />;
    case DATA_STATUS.NO_MERGED:
      return <NoMerged textOnly={textOnly} />;
    case DATA_STATUS.NO_RELEASES:
      return <NoReleases textOnly={textOnly} />;
    default:
      return <NoData textOnly={textOnly} />;
  }
});

export { Nothing };
