import React, { useMemo } from 'react';

import { metricsConfig } from '@align-constants';
import { Chart, ChartTypeEnum, LineWithPlotBandService } from '@lib/chart';

import { ILineChart } from './lineChart.types';

const LineChart: React.FC<ILineChart> = React.memo(({ goal, templateMetric, onSelect }) => {
  const options = useMemo(
    () =>
      LineWithPlotBandService.getOptions({
        series:
          goal?.team_goal?.value?.series?.map((item) => ({
            date: item.date,
            value: item.value ? parseInt(item.value.toString()) : null,
          })) || [],
        better: metricsConfig[templateMetric]?.better,
        targetValue: parseInt(goal?.team_goal?.value?.target.toString()),
        startDate: goal?.valid_from,
        endDate: goal?.expires_at,
        unit: metricsConfig[templateMetric]?.unit,
        valueType: metricsConfig[templateMetric]?.valueType,
        onSelect,
      }),
    [goal, templateMetric]
  );

  return <Chart chartType={ChartTypeEnum.LINE} options={options} />;
});

export { LineChart };
