import { github } from '@common-services/format';

import { DEFAULT_PATTERNS } from './constants';
import { ITypeOptions } from './types';

export const getDefaultText = (strategy: ITypeOptions, defaultBranch: string): string => {
  switch (strategy) {
    case ITypeOptions.BRANCH:
      return `Default (${defaultBranch})`;
    case ITypeOptions.TAG:
      return 'Any';
    case ITypeOptions.EVENT:
      return 'Reading releases from push events.';
    default:
      return 'Reading releases from tags, and repo default branch if there are no available tags.';
  }
};

export const inputPatternReducer = (_, action: { pattern?: string; name?: string }) =>
  action.pattern && action.pattern !== DEFAULT_PATTERNS[action?.name] ? action.pattern : '';

export const isFilteredIn = (conf: { url: string }, term: string) =>
  !term || github.repoName(conf.url).toLowerCase().includes(term.toLowerCase());
