import React from 'react';

import Checks from './Checks';
import Epics from './Epics';
import Issues from './Issues';
import { PullRequests } from './pull-requests';
import { Releases } from './releases';

const ContentPullRequestsTable = () => {
  return <PullRequests stage="summary" />;
};

export {
  Issues as ContentIssuesTable,
  Checks as ContentChecksTable,
  ContentPullRequestsTable,
  Epics as ContentEpicsTable,
  Releases as ContentReleasesTable,
};
