import React, { useCallback, useState, useRef, useEffect } from 'react';

import { useOnClickOutside } from '@common-hooks/useOnClickOutside';
import IconButton from '@lib/IconButton';
import { icons } from '@lib/icon';

import { wrapperStyles } from './ActionMenu.styles';
import { IActionMenu } from './ActionMenu.types';
import { ActionMenuList } from './components';

export const ActionMenu: React.FC<IActionMenu> = React.memo(({ actions, icon, shouldClose }) => {
  const ref = useRef<HTMLDivElement>();
  const [isExpanded, setIsExpanded] = useState(false);

  const onButtonClickHandler = useCallback(
    (event) => {
      event.stopPropagation();
      setIsExpanded(!isExpanded);
    },
    [isExpanded]
  );

  // close the menu if parent explicitly asks to
  useEffect(() => setIsExpanded(false), [shouldClose]);

  useOnClickOutside(ref, () => setIsExpanded(false));

  return (
    <div css={wrapperStyles} ref={ref}>
      <IconButton icon={icon || icons.dots} iconSize={18} onClick={onButtonClickHandler} />
      <ActionMenuList isExpanded={isExpanded} actions={actions} />
    </div>
  );
});
