import React, { useContext } from 'react';

import { ObjectService } from '@common-services/objectService';
import { SelectTsOptionSingle } from '@lib/selector-ts';
import { SelectorTsOption } from '@lib/selector-ts/components/option';
import { SelectorTsContext } from '@lib/selector-ts/context';

interface RenderChildProps {
  options: SelectTsOptionSingle[];
  level: number;
}

interface ChildProps {
  children: SelectTsOptionSingle;
  level: number;
}

const Children: React.FC<ChildProps> = ({ children, level }) => {
  return (
    <li data-cy="selector-option" data-testid={'selectts-option'}>
      <SelectorTsOption key={children.id} {...children} level={level} />
      {children.children && children.isOpened && (
        <ul className="children">
          <RenderChild options={children.children} level={level + 1} />
        </ul>
      )}
    </li>
  );
};

export const RenderChild: React.FC<RenderChildProps> = React.memo(({ options, level }) => {
  const { filter } = useContext(SelectorTsContext);
  const openAllChildrenIfFilter = (option) => {
    return filter ? ObjectService.updateNestedObjByKeyValue(option, 'isOpened', true) : option;
  };
  return (
    <>
      {options
        .filter((option) => {
          const regex = new RegExp(filter, 'gi');
          const childContain = option.children.filter((children) => {
            return ObjectService.isNestedObjContain(children, 'name', regex);
          });
          const concatenatedName = option.subName
            ? option.name.concat(' ', option.subName)
            : option.name;
          return childContain.length > 0 ? true : regex.test(concatenatedName);
        })
        .map((option) => {
          return (
            <Children children={openAllChildrenIfFilter(option)} level={level} key={option.id} />
          );
        })}
    </>
  );
});
