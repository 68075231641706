import { Redirect } from 'react-router-dom';

import { useAuth0 } from '@common-context/Auth0';
import { AuthRouteEnum } from '@routes/authRoutes';

import { useSyncShareFilters } from './hooks/useSyncShareFilters';

const Share = () => {
  const { isAuthenticated } = useAuth0();
  const [redirectTo, ready] = useSyncShareFilters();

  if (!isAuthenticated) {
    const targetUrl = encodeURIComponent(window.location.href);
    return <Redirect to={`${AuthRouteEnum.LOGIN}/?targetUrl=${targetUrl}`} />;
  }

  if (ready) {
    return <Redirect to={redirectTo} />;
  }

  return null;
};

export { Share };
