import { css } from '@emotion/react';
import _ from 'lodash';
import React from 'react';

import { fetchPRsMetrics } from '@analytics-services/api';
import { getFeature, featuresList } from '@analytics-services/flags';
import { dateTime } from '@common-services/dateService';
import { theme } from '@styles/theme';
import { FlatProgressBarMulti } from '@ui/ProgressBar';

const labelStyle = ({ color, font }) => css`
  .first-line {
    color: ${color.text.dark};
    font-size: ${font.size.default};
    font-weight: ${font.weight.light};
  }

  .second-line {
    color: ${color.text.secondary};
    font-size: ${font.size.xs};
    font-weight: ${font.weight.light};
  }
`;

const prSizeCycleTime = {
  fetcher: async (api, cachedData, apiContext) => {
    const {
      account,
      interval,
      excludeInactive,
      contributors,
      repositories,
      labels,
      features,
      epics,
      issueTypes,
    } = apiContext;
    const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, features)?.parameters
      ?.value;

    const data = await fetchPRsMetrics(
      api,
      account,
      ['all'],
      interval,
      ['lead-time', 'lead-count'],
      {
        repositories,
        with: { author: contributors },
        lines: [1, 10, 50, 100, 500, 10000000],
        labels_include: labels,
        ...(excludedLabels ? { labels_exclude: excludedLabels } : {}),
        jira: { epics, issue_types: issueTypes },
      },
      null,
      excludeInactive
    );

    return data.calculated;
  },
  plumber: (fetchedData, cachedData, apiContext) => {
    const params = _(fetchedData)
      .map((v, index) => {
        let labelLeft;
        if (index < fetchedData.length - 1) {
          labelLeft = `${v.for.lines[0]}-${v.for.lines[1]}`;
        }
        if (index === 0) {
          labelLeft = `${labelLeft} lines`;
        } else if (index === fetchedData.length - 1) {
          labelLeft = `${v.for.lines[0]}+`;
        }
        return {
          value: v.values[0].values[0],
          color: theme.color.sets.five[index],
          labelLeft: (
            <div css={labelStyle}>
              <div className="first-line">{labelLeft}</div>
              <div className="second-line">{`${v.values[0].values[1]} PRs`}</div>
            </div>
          ),
          labelRight: v.values[0].values[0] > 0 ? dateTime.human(v.values[0].values[0] * 1000) : '',
        };
      })
      .value();

    return {
      empty: !fetchedData || fetchedData.length === 0,
      chart: {
        component: FlatProgressBarMulti,
        params: {
          params,
          normalize: true,
          upperCase: false,
        },
      },
      kpis: [],
    };
  },
};

export default prSizeCycleTime;
