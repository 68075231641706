import { BasicApiClass } from '@common-services/api/common/BasicApiClass';
import { IUrlParams, REST_TYPES } from '@common-services/api/common/types/apiClient';
import { ApiMode } from '@common-services/api/common/types/common';
import { ISentryMethodParams } from '@common-services/api/common/types/sentry';
import { PUBLIC_API_ENDPOINTS_CONSTANTS } from '@common-services/api/public/constants';
import {
  Account,
  ProductFeatures,
} from '@common-services/api/public/generated-from-backend/models';

export class AccountsApiClass extends BasicApiClass {
  constructor() {
    super(ApiMode.PUBLIC);
  }

  public getAccountFeature(
    urlParams: IUrlParams<'accountId'>,
    token: string = null,
    sentry?: ISentryMethodParams
  ): Promise<ProductFeatures> {
    const apiPath = this.replaceRouteLink(
      PUBLIC_API_ENDPOINTS_CONSTANTS.ACCOUNT_FEATURES,
      urlParams
    );
    this.assignToken(token);
    const callApi = this.callApi<ProductFeatures>(apiPath, REST_TYPES.GET);
    return sentry ? this.callSentry<ProductFeatures>(callApi, sentry) : callApi;
  }

  public getAccountDetails(
    urlParams: IUrlParams<'accountId'>,
    token: string = null,
    sentry?: ISentryMethodParams
  ): Promise<Account> {
    const apiPath = this.replaceRouteLink(
      PUBLIC_API_ENDPOINTS_CONSTANTS.ACCOUNT_DETAILS,
      urlParams
    );
    this.assignToken(token);
    const callApi = this.callApi<Account>(apiPath, REST_TYPES.GET);
    return sentry ? this.callSentry<Account>(callApi, sentry) : callApi;
  }
}
