import React, { ForwardedRef, InputHTMLAttributes } from 'react';
import { Path, UseFormRegister } from 'react-hook-form';

import { IColor } from '@styles/types';

export enum InputTypes {
  number = 'number',
  text = 'text',
  email = 'email',
}

interface IValidationProps<T> extends IGeneralProps {
  name?: Path<T>;
  register?: UseFormRegister<T>;
  ref?: ForwardedRef<HTMLInputElement>;
  error?: string;
}

interface IGeneralProps {
  autoComplete?: boolean;
  children?: React.ReactNode;
  childrenAfter?: boolean;
  disabled?: boolean;
  placeholder?: string;
  type?: InputTypes;
  width?: number;
  withSearchIcon?: boolean;
  testid?: string;
  onChange?: (value: string) => void;
  step?: InputHTMLAttributes<null>['step'];
}

export interface IInputProps<T> extends IValidationProps<T> {
  initialValue?: string;
  label?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export interface IInputUIProps extends IGeneralProps {
  iconColor: IColor;
  label: string;
  value: string;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export interface IRawInputProps extends IGeneralProps {
  iconColor: IColor;
  value: string;
}

export type IInputContextProps<T> = Pick<
  IValidationProps<T>,
  'register' | 'name' | 'error' | 'testid'
>;
