import bubbles from './releaseSize_bubbles';
import donut from './releaseSize_donut';
import histogram from './releaseSize_histogram';

export const BIG_RELEASE_THRESHOLD = 30;

const releaseSize = {
  plumber: (fetchedData, cachedData, apiContext) => ({
    firstBox: histogram.plumber(fetchedData, cachedData, apiContext),
    secondBox: donut.plumber(fetchedData, cachedData, apiContext),
    thirdBox: bubbles.plumber(fetchedData, cachedData, apiContext),
  }),
  factory: (computed) => {
    const content = [
      ...histogram.factory(computed.firstBox).content,
      ...donut.factory(computed.secondBox).content,
      ...bubbles.factory(computed.thirdBox).content,
    ];

    return {
      meta: {
        title: 'Release Size',
        description:
          'Target the best engineering practices by reducing and monitoring release size.',
      },
      empty: content.filter((c) => !c.empty).length === 0,
      content,
    };
  },
};

export default releaseSize;
