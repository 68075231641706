import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import { useDemoCookie } from '@common-pages/Demo';
import { Navbar } from '@lib/Navbar';
import { alignRoutes } from '@routes/alignRoutes';
import { analytics } from '@utils/analytics';

import authRoutes, { AuthRouteEnum } from './authRoutes';
import demoRoutes from './demoRoutes';
import extraRoutes from './extraRoutes';
import mainRoutes from './mainRoutes';
import offlineRoutes from './offlineRoutes';
import { contentWrapperStyles } from './routes.styles';

const getRoutes = () =>
  _.concat(demoRoutes, authRoutes, mainRoutes, alignRoutes, offlineRoutes, extraRoutes);

const Routes = () => {
  const location = useLocation();
  const [routes, setRoutes] = useState(null);
  const { refreshDemoCookie } = useDemoCookie();

  useEffect(() => {
    const prepareRoutes = async () => setRoutes(buildRoutes(getRoutes()));
    prepareRoutes();
  }, []);

  useEffect(() => {
    analytics.track(location.pathname);
    if (!['/undemo', AuthRouteEnum.LOGIN].includes(location.pathname)) {
      refreshDemoCookie();
    }
  }, [location, refreshDemoCookie]);

  return routes ? <Switch>{routes}</Switch> : null;
};

// TODO refactor to react-router-dom > v5
const buildRoutes = (routesGroups) =>
  _(routesGroups)
    .flatMap((r, index) => {
      const { route } = r;
      const componentProps = r.componentProps || {};
      const Component = r.component;
      const Provider = r.context || React.Fragment;
      if (r.subRoutes) {
        componentProps.children = <Switch>{buildRoutes(r.subRoutes)}</Switch>;
      }
      return (
        <Route key={index} {...route}>
          <Provider>
            {route.mainRoute && <Navbar backButton={route.backButton} />}
            <div css={contentWrapperStyles}>
              <Component {...componentProps} />
            </div>
          </Provider>
        </Route>
      );
    })
    .value();

export default Routes;
