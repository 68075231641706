import { useMemo } from 'react';

import { useDashboardStore } from '@align-pages/dashboard/store';
import { ChartParamsType } from '@align-pages/dashboard/types';

import { FilterType } from './filterPanel.types';

export function useExistingFilters(
  filterType: FilterType,
  paramType?: ChartParamsType,
  serializeFn?: (value: string) => string
): string[] {
  const properties = useDashboardStore((state) => state.properties);

  const values = useMemo(
    () => properties.find((p) => p.property === filterType && p.type === paramType)?.rules,
    [filterType, properties]
  );

  return serializeFn && values ? values.map(serializeFn) : values;
}
