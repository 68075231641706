import React from 'react';

import { Badge, BadgeColorType } from '@lib/Badge';
import { Button, ButtonSize, ButtonType } from '@lib/Button';
import { Icon, icons } from '@lib/icon';

import { bodyStyles, headerStyles, wrapperStyles } from './Card.styles';
import { ICard } from './Card.types';

export const Card: React.FC<ICard> = React.memo(
  ({
    headerIcon,
    headerText,
    headerBadgeCount,
    headerButtonText,
    onHeaderButtonClick,
    buttonType = ButtonType.PRIMARY,
    buttonIcon = icons.add,
    children,
  }) => {
    return (
      <div css={wrapperStyles}>
        {(headerText || headerButtonText) && (
          <div css={headerStyles}>
            {headerText && (
              <div className="header-wrapper">
                <div className="header-info">
                  {headerIcon && <Icon icon={headerIcon} size={15} />}
                  <span>{headerText}</span>
                </div>
                {headerBadgeCount !== undefined && headerBadgeCount !== null && (
                  <Badge text={headerBadgeCount} type={BadgeColorType.NEUTRAL} />
                )}
              </div>
            )}
            {headerButtonText && (
              <Button
                disabled={false}
                label={headerButtonText}
                size={ButtonSize.SMALL}
                type={buttonType}
                onClick={onHeaderButtonClick}
                icon={buttonIcon}
              />
            )}
          </div>
        )}
        {children && <div css={bodyStyles}>{children}</div>}
      </div>
    );
  }
);
