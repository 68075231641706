import { css } from '@emotion/react';

import { PrimaryLayoutModes } from '@align-components/layouts/primary';

export const containerStyles = () => css`
  position: relative;
`;

export const fadeStyles = () => css`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.6);
`;

const getStyle = (isActive, metricColor, mode: PrimaryLayoutModes) => ({
  color,
  radius,
  shadow,
  transition,
}) => css`
  min-width: 210px;
  max-width: 210px;
  height: 120px;
  background: ${color.neutral.white};
  border: 1px solid ${isActive ? metricColor : color.neutral['40']};
  box-sizing: border-box;
  border-radius: ${radius.default};
  cursor: ${mode === PrimaryLayoutModes.CREATE ? 'pointer' : 'default'};
  transition: ${transition.default};

  ${mode === PrimaryLayoutModes.CREATE &&
  `
    &:hover {
      box-shadow: ${shadow.full};
      border: 1px solid ${isActive ? metricColor : color.neutral['80']};
    }
  `}
`;

const getHeaderStyle = (metricColor) => ({ radius }) => css`
  width: 100%;
  height: 4px;
  border-radius: ${radius.default} ${radius.default} 0 0;
  background: ${metricColor};
`;

const getTitleWrapperStyle = ({ color, spacing, font }) => css`
  width: 100%;
  height: 72px;
  padding: ${spacing.gap['05']};

  & > p {
    color: ${color.neutral['100']};
    font-size: ${font.size.s};
    font-weight: ${font.weight.regular};
  }
`;

const getMetricWrapperStyle = (isActive, metricColor) => ({ color, spacing, font }) => css`
  width: 100%;
  height: 42px;
  padding: ${spacing.gap['04']} ${spacing.gap['06']};
  background: ${isActive ? `${metricColor}19` : 'none'};
  border-top: 1px solid ${color.neutral['40']};
  display: flex;
  gap: ${spacing.gap['03']};

  & > svg {
    height: 17px;
    width: 17px;
  }

  & > p {
    color: ${metricColor};
    font-size: ${font.size.default};
    font-weight: ${font.weight.regular};
    white-space: nowrap;
  }
`;

export { getStyle, getHeaderStyle, getTitleWrapperStyle, getMetricWrapperStyle };
