import { css } from '@emotion/react';

const getFiltersStyle = () => css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > .tagged-selector-wrapper {
    flex-grow: 1;
    max-width: 75%;
  }
`;

const getChartsWrapperStyle = ({ spacing }) => css`
  margin-top: ${spacing.gap['09']};
`;

export { getFiltersStyle, getChartsWrapperStyle };
