import { css } from '@emotion/react';

import { theme } from '@styles/theme';

export const wrapperStyles = ({ spacing }) => css`
  display: flex;
  justify-content: flex-end;
  margin-bottom: -${spacing.gap['08']};
`;

export const selectStyles = css`
  flex: 0 0 170px;
  z-index: 3;
`;

export const vendorSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    'borderRadius': theme.radius.default,
    'boxShadow': 'none',
    'borderColor': state.isFocused ? theme.color.ui.lightorange[100] : theme.color.neutral[40],
    'backgroundColor': 'hsl(0,0%,100%)',
    '&:hover, &:active': {
      borderColor: theme.color.neutral[60],
    },
    '> div:last-child': {
      alignItems: 'flex-end',
    },
    'width': '170px',
  }),
  option: (provided) => ({
    ...provided,
    ':active': {
      backgroundColor: theme.color.ui.lightorange[100],
      color: theme.color.neutral.white,
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    alignSelf: 'center',
    padding: `${theme.spacing.gap['03']} ${theme.spacing.gap['05']}`,
  }),
};
