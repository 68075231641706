import { useTheme } from '@emotion/react';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';

import { Icon, icons } from '@lib/icon';
import { formControlStyles } from '@styles/components';

import { arrowStyles, pageIndexStyles, pagesStyles, settingsStyles, wrapperStyles } from './styles';

const TablePagination = (props) => {
  const {
    pageCount,
    pageIndex,
    pageSize,
    total,
    gotoPage,
    gotoNextPage,
    gotoPrevPage,
    setPageSize,
  } = props;

  const theme = useTheme();

  const handleSizeChange = useCallback((e) => {
    setPageSize(Number(e.target.value));
  }, []);

  const handlePageChange = useCallback(
    (index) => () => {
      gotoPage(index);
    },
    []
  );

  const startIndex = useMemo(() => pageIndex * pageSize + 1, [pageIndex, pageSize]);
  const endIndex = useMemo(() => (pageIndex + 1) * pageSize);

  const minVisibleIndex = useMemo(() => {
    if (pageCount <= 7) {
      return 1;
    } else {
      if (pageIndex > 3) {
        if (pageCount - pageIndex < 5) {
          return pageCount - 5;
        }
        return pageIndex - 1;
      } else {
        return 1;
      }
    }
  }, [pageCount, pageIndex]);

  const maxVisibleIndex = useMemo(() => {
    if (pageCount <= 7) {
      return pageCount - 1;
    } else {
      if (pageIndex > 3) {
        if (pageCount - pageIndex < 5) {
          return pageCount - 1;
        }
        return pageIndex + 2;
      } else {
        return 5;
      }
    }
  }, [pageCount, pageIndex]);

  return (
    <div css={wrapperStyles}>
      <div css={settingsStyles}>
        <span>Show rows: </span>
        <select
          css={formControlStyles}
          className="page-size-dropdown"
          value={pageSize}
          onChange={handleSizeChange}
        >
          {[10, 20, 30, 40, 50].map((size) => (
            <option key={`page-size-${size}`} value={size}>
              {size}
            </option>
          ))}
        </select>
        <span>{`${startIndex}-${endIndex} of ${total} entries`}</span>
      </div>
      <div css={pagesStyles}>
        <div css={arrowStyles} onClick={gotoPrevPage}>
          <Icon color={theme.color.text.dark} icon={icons.angle_left} size={10} />
        </div>
        <div className="page-indeces">
          <div
            css={(theme) => pageIndexStyles(theme, pageIndex === 0)}
            onClick={handlePageChange(0)}
          >
            1
          </div>
          {pageIndex > 3 && pageCount > 7 && (
            <div className="delimeter">
              <Icon color={theme.color.text.secondary} icon={icons.dots} size={16} />
            </div>
          )}
          {maxVisibleIndex > 1 &&
            _.range(minVisibleIndex, maxVisibleIndex).map((index) => (
              <div
                css={(theme) => pageIndexStyles(theme, pageIndex === index)}
                key={`page-${index}`}
                onClick={handlePageChange(index)}
              >
                {index + 1}
              </div>
            ))}
          {pageCount - maxVisibleIndex > 2 && (
            <div className="delimeter">
              <Icon color={theme.color.text.secondary} icon={icons.dots} size={16} />
            </div>
          )}
          {pageCount > 1 && (
            <div
              css={(theme) => pageIndexStyles(theme, pageIndex === pageCount - 1)}
              onClick={handlePageChange(pageCount - 1)}
            >
              {pageCount}
            </div>
          )}
        </div>
        <div css={arrowStyles} onClick={gotoNextPage}>
          <Icon color={theme.color.text.dark} icon={icons.angle_right} size={10} />
        </div>
      </div>
    </div>
  );
};

export default TablePagination;
