import { useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import {
  CHART_OPERATION_TEXT,
  ChartOperationType,
  IUseDashboardResponseHandle,
  IUseDashboardResponseHandleReturn,
} from '@align-pages/dashboard/hooks/types';
import { DashboardChartServices } from '@align-pages/dashboard/services/dashboardChartServices';
import { useHistory } from '@common-hooks/useHistory';
import log from '@common-services/logger';

export const useDashboardResponseHandle = ({
  isSuccess,
  isError,
  operationType,
}: IUseDashboardResponseHandle): IUseDashboardResponseHandleReturn => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const [isLoading, setIsLoading] = useState(false);

  const isCreateOrEdit = useMemo(
    () => [ChartOperationType.CREATE, ChartOperationType.EDIT].includes(operationType),
    [operationType]
  );

  useEffect(() => {
    if (isSuccess) {
      setIsLoading(true);
      DashboardChartServices.invalidateDashboardQuery(isCreateOrEdit).then(() => {
        setIsLoading(false);
        log.ok(CHART_OPERATION_TEXT[operationType].successText);

        if (isCreateOrEdit) {
          let cutPosition;
          if (operationType === ChartOperationType.CREATE) {
            cutPosition = url.indexOf('/new-metric');
          } else {
            cutPosition = url.indexOf('/edit/');
          }
          history.pushWithBackState(`${url.substring(0, cutPosition)}`);
        }
      });
    }
  }, [isCreateOrEdit, isSuccess, operationType, url]);

  useEffect(() => {
    if (isError) {
      log.error(CHART_OPERATION_TEXT[operationType].errorText);
    }
  }, [isError]);

  return { isLoading };
};
