import React from 'react';

import { NEGATIVE_IS_BETTER, POSITIVE_IS_BETTER } from '@analytics-components/Badge';
import { StatusIndicator, READY, EMPTY, LOADING } from '@analytics-components/StatusIndicator';
import SummaryBox from '@analytics-components/insights/SummaryBox';
import * as NumberService from '@common-services/numberService';

const PipelineSummaryBox = ({
  data,
  isLoading,
  title,
  statusColor,
  className,
  renderKPI,
  chart,
  dataStatus,
  positiveIsBetter = false,
}) => {
  const status = isLoading ? LOADING : data.empty ? EMPTY : READY;

  return (
    <SummaryBox
      title={title}
      status={status}
      summary={
        isLoading
          ? {}
          : {
              text: data.formatFn(data.average),
              badge: {
                value: NumberService.round(data.variation),
                trend: positiveIsBetter ? POSITIVE_IS_BETTER : NEGATIVE_IS_BETTER,
              },
              kpi: renderKPI(data.kpisData),
            }
      }
      chart={chart}
      placeholder={{
        [EMPTY]: <StatusIndicator status={status} color={statusColor} dataStatus={dataStatus} />,
        [LOADING]: <div className="stage-metrics-placeholder" />,
      }}
      wrapperClassName={className}
    />
  );
};

export default PipelineSummaryBox;
