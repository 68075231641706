import classnames from 'classnames';
import React from 'react';

import { badgeStyles } from '@analytics-components/Badge/styles';
import ErrorBoundary from '@analytics-components/ErrorBoundary';
import { isNumber } from '@common-services/format';
import { Icon, icons } from '@lib/icon';

export const POSITIVE_IS_BETTER = 'positive-variation-is-better'; // default
export const NEGATIVE_IS_BETTER = 'negative-variation-is-better';

const Badge = ({ value, trend = false, className }) => {
  return (
    <ErrorBoundary>
      <BadgeInternal value={value} trend={trend} className={className} />
    </ErrorBoundary>
  );
};

const BadgeInternal = ({ value, trend = false, className }) => {
  const commonClasses = ['badge'];
  let customClasses, iconBadge;
  let suffix = '%';

  if (typeof value === 'number' && !isFinite(value)) {
    throw new Error(`Not a valid number in a Badge; got "${value}" instead`);
  }

  if (!isNumber(value) && (typeof value !== 'string' || value === '')) {
    return '';
  }

  if (!trend) {
    customClasses = ['badge-pill', 'badge-secondary', 'py-1', 'px-2'];
  } else if (value === 0 || !isNumber(value)) {
    // TODO: this actually "covers" the content of the span due to the opacity: 1 of the css
    // this should also change the content of the badge itself and add a min-width to it.
    customClasses = ['badge-neutral'];
  } else if (value < 0) {
    customClasses = [trend === NEGATIVE_IS_BETTER ? 'badge-success' : 'badge-danger'];
    iconBadge = icons.caret_down;
  } else {
    customClasses = [trend === NEGATIVE_IS_BETTER ? 'badge-danger' : 'badge-success'];
    iconBadge = icons.caret_up;
  }

  let displayValue = value !== null && Math.abs(value) >= 0 ? `${Math.abs(value)}${suffix}` : '';
  if (Math.abs(value) >= 100) {
    const rounded = Math.round(Math.abs(value) / 100);
    displayValue = `x ${rounded + 1}`;
  }
  return (
    <span css={badgeStyles} className={classnames(className, ...commonClasses, ...customClasses)}>
      {trend ? (
        <>
          {iconBadge && <Icon icon={iconBadge} size={7} />}
          <span>{displayValue}</span>
        </>
      ) : (
        <>{value}</>
      )}
    </span>
  );
};

export default Badge;
