import { LooseObject } from '@analytics-types/common';
import { IFilteredPRs } from '@common-services/api/public/types/pullRequests';
import { PR_STAGE } from '@common-services/prHelpers';

export enum PRS_TABLE_STATE {
  WAITING_FOR_API = 0,
  WAITING_FOR_PAGINATION_PLAN = 1,
  RESOLVING_PAGINATION_PLAN = 2,
  WAITING_FOR_FIRST_PAGE = 3,
  RESOLVING_FIRST_PAGE = 4,
  WAITING_PAGE = 5,
  RESOLVING_PAGE = 6,
  DONE = 7,
}

export interface IPrsDataProps {
  repositories: string[];
  stage: PR_STAGE;
}

export interface IPrsAction {
  type: PRS_ACTION_TYPE;
  sid: number;
  payload?: any;
}

export enum PRS_ACTION_TYPE {
  LOAD_API = 'load_api',
  RESET = 'reset',
  RESOLVING_PAGINATION_PLAN = 'resolving_pagination_plan',
  FETCHED_NO_DATA = 'fetched_no_data',
  RESOLVE_PAGINATION_PLAN = 'resolve_pagination_plan',
  RESOLVING_FIRST_PAGE = 'resolving_first_page',
  RESOLVE_FIRST_PAGE = 'resolve_first_page',
  RESOLVING_PAGE = 'resolving_page',
  RESOLVE_PAGE = 'resolve_page',
  EARLY_USER_INTERACTION = 'early_user_interaction',
}

export interface IPrsTableState {
  sid?: number;
  state?: PRS_TABLE_STATE;
  api?: {
    context: LooseObject;
  };
  fetched?: number;
  pagesData?: IFilteredPRs[];
  plan?: string[];
  earlyUserInteraction?: boolean;
}
