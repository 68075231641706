import _ from 'lodash';
import React from 'react';

import { calculateGranularity } from '@analytics-components/Prefetcher';
import { DateRangeBigNumber, BigText } from '@analytics-components/charts/Tooltip';
import VerticalBarChart from '@analytics-components/charts/VerticalBarChart';
import { fetchJIRAMetrics } from '@analytics-services/api/jira';
import { FAKE_USERNAME } from '@common-pages/Settings/constants';
import { dateTime } from '@common-services/dateService';
import { theme } from '@styles/theme';

const conf = {
  fetcher: async (api, cachedData, apiContext) => {
    const {
      account,
      interval,
      excludeInactive,
      contributors,
      includeNullContributor,
      includeFakeContributor,
      epics,
    } = apiContext;
    const filteredJIRAStuff = cachedData['jira-priorities'];
    const jiraAssignees = _(contributors).map('jira_user').filter().value();
    if (includeNullContributor) {
      jiraAssignees.push(null);
    }
    if (includeFakeContributor) {
      jiraAssignees.push(FAKE_USERNAME);
    }

    const fetch = async (priority) =>
      await fetchJIRAMetrics(
        api,
        account,
        ['auto'],
        interval,
        ['raised'],
        excludeInactive,
        {
          types: ['Bug'],
          priorities: [priority],
          epics,
        },
        { assignees: jiraAssignees }
      );

    const bugs = await Promise.all(
      _(filteredJIRAStuff.priorities)
        .map((p) => fetch(p.name))
        .value()
    );

    return {
      bugs: _(bugs)
        .map((b) =>
          _(b[0].values)
            .map((v) => ({ value: v.values[0], date: v.date }))
            .value()
        )
        .value(),
      priorities: filteredJIRAStuff.priorities,
    };
  },
  plumber: (fetchedData, cachedData, apiContext) => {
    const colors = theme.color.sets.bugs;
    const chartData = _(fetchedData.priorities)
      .zip(fetchedData.bugs)
      .map((v, i) =>
        _(v[1])
          .map((d) => ({ ...d, color: colors[i], priority: v[0].name }))
          .value()
      )
      .value();
    const bugsAmount = _(chartData)
      .map((v) =>
        _(v)
          .map((d) => d.value)
          .sum()
      )
      .sum();

    const customGranularity = calculateGranularity(apiContext.interval);
    const tickFormat = customGranularity === 'month' ? dateTime.month : dateTime.monthDay;
    const tooltip = {
      align: {
        horizontal: 'auto',
        vertical: 'top',
      },
      renderBigFn: (v) => {
        const circleRadius = 5;
        return (
          <BigText
            encloseExtra={false}
            icon={
              <svg
                style={{
                  display: 'inline-block',
                  width: `${circleRadius * 2}px`,
                  height: `${circleRadius * 2}px`,
                  marginRight: `${circleRadius}px`,
                }}
              >
                <circle cx={circleRadius} cy={circleRadius} r={circleRadius} fill={v.color} />
              </svg>
            }
            content={v.y - (v.y0 || 0)}
            extra={v.priority}
          />
        );
      },
    };
    if (_(['month', 'week']).includes(customGranularity)) {
      tooltip.template = DateRangeBigNumber;
      tooltip.interval = { ...apiContext.interval };
      tooltip.granularity = customGranularity;
    }

    return {
      empty: chartData.length === 0 || !bugsAmount,
      chart: {
        component: VerticalBarChart,
        params: {
          data: chartData,
          extra: {
            stacked: true,
            height: 350,
            color: colors,
            barWidth: 0.25,
            axisKeys: {
              x: 'date',
              y: 'value',
            },
            axisLabels: {
              y: 'Bugs',
            },
            axisFormat: {
              tickSize: {
                x: 0,
              },
              tickPadding: {
                x: 20,
              },
              tickFormat: {
                x: tickFormat,
              },
            },
            tooltip,
          },
          timeMode: true,
        },
      },
      kpis: [],
    };
  },
  prefetchedDataIds: ['jira-priorities'],
};

export default conf;
