import React from 'react';
import { CellProps, Renderer } from 'react-table';

import {
  DeploymentStatus,
  IDeploymentsRow,
} from '@analytics-pages/Tables/deployments/deployments.types';
import { dateTime } from '@common-services/dateService';
import { github } from '@common-services/format';
import { Icon, icons } from '@lib/icon';
import { Tooltip } from '@lib/tooltip';
import { useTheme } from '@styles/hooks';
import Chevron from '@ui/Chevron';

import {
  detailsStyles,
  iconWrapperStyles,
  placeholderStyles,
  prTitleStyles,
  titleStyles,
  wrapperStyles,
} from './cellTitle.styles';

const DeploymentsCellTitle: Renderer<CellProps<IDeploymentsRow>> = ({ row }) => {
  const theme = useTheme();
  // @ts-ignore appears that react-table type definitions don't have onClick defined
  const { onClick } = row.getToggleRowExpandedProps();

  return (
    <div css={wrapperStyles}>
      {row.canExpand ? (
        <Chevron isExpanded={row.isExpanded} onClick={onClick} />
      ) : (
        <div css={placeholderStyles} />
      )}
      {row.depth > 0 ? (
        <>
          <div css={placeholderStyles} />
          <Icon color={theme.color.ui.green['100']} icon={icons.nav_pr} size={14} />
          <div css={prTitleStyles}>
            <div className="pr-title">
              <div className="repo-name">
                {github.repoOrg(row.original.repository)}/{github.repoName(row.original.repository)}
                &nbsp;
              </div>
              <Tooltip content={<div>{`#${row.original.number} ${row.original.title}`}</div>}>
                <a
                  className="pr-text"
                  href={github.prLink(row.original.repository, row.original.number)}
                  rel="noreferrer"
                  target="_blank"
                >
                  {`#${row.original.number} ${row.original.title}`}
                </a>
              </Tooltip>
            </div>
            <div className="pr-subtitle">
              <img
                alt=""
                className="user-avatar"
                src={row.original.authorAvatar}
                title={row.original.author}
              />
              <div className="pr-subtitle-text">
                <span>Created by&nbsp;</span>
                <span className="reporter">{row.original.author || 'an unknown author'}&nbsp;</span>
                {dateTime.ago(row.original.created)}&nbsp;
                <span>ago</span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            css={iconWrapperStyles}
            title={row.original.isSuccessful ? DeploymentStatus.SUCCESS : DeploymentStatus.FAIL}
          >
            <Icon
              color={
                row.original.isSuccessful ? theme.color.status.success : theme.color.status.danger
              }
              icon={row.original.isSuccessful ? icons.check_succeed : icons.check_failed}
              size={14}
            />
          </div>
          <div>
            <div css={titleStyles}>{row.original.name}</div>
            <div css={detailsStyles}>
              <span>{row.original.isSuccessful ? 'Deployed by' : 'Failed to deploy'}&nbsp;</span>
              <div className="time-details">
                {dateTime.ago(row.original.finished)}
                <span> ago</span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export { DeploymentsCellTitle };
