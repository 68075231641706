import React from 'react';
import { CellProps, Renderer } from 'react-table';

import { dateTime } from '@common-services/dateService';
import { columnStyles } from '@lib/Table/components/pr-table/prTable.styles';
import { IPrsTableRow } from '@lib/Table/components/pr-table/prTable.types';

export const PullRequestsCellTime: Renderer<CellProps<IPrsTableRow>> = React.memo(({ row }) => {
  return (
    <div css={columnStyles}>
      {row.original.cycleTime === undefined
        ? '-'
        : dateTime.bestTimeUnit(row.original.cycleTime * 1000, 0)}
    </div>
  );
});
