import React from 'react';

import { IChartDonut } from '@align-pages/goals/single/components/goal-summary/components/chart-donut/chartDonut.types';
import { Chart, ChartTypeEnum } from '@lib/chart';
import { useTheme } from '@styles/hooks';
import { hexToRGBA } from '@utils/colors';

import {
  useChartDonutCoordinates,
  useChartDonutSubtitle,
  useChartDonutTitle,
} from './chartDonut.hooks';

export const ChartDonut: React.FC<IChartDonut> = React.memo(({ goal }) => {
  const theme = useTheme();
  const {
    current: currentGoalValue,
    target: targetGoalValue,
    series_granularity: seriesGranularity,
    series: seriesData,
  } = goal?.team_goal?.value;
  const threshold = goal?.metric_params?.threshold;
  const metricValue = goal?.metric;
  const title = useChartDonutTitle(
    threshold,
    currentGoalValue,
    targetGoalValue,
    seriesData,
    metricValue
  );
  const [beginCoordinate, endCoordinate] = useChartDonutCoordinates(
    threshold,
    currentGoalValue,
    title
  );
  const subtitle = useChartDonutSubtitle(
    threshold,
    metricValue,
    seriesGranularity,
    beginCoordinate
  );
  return (
    <Chart
      chartType={ChartTypeEnum.DONUT}
      options={{
        series: [
          {
            data: [
              {
                color: hexToRGBA(theme.color.ui.green['100'], 0.7),
                y: beginCoordinate,
              },
              {
                color: hexToRGBA(theme.color.neutral['60'], 0.7),
                y: endCoordinate,
              },
            ],
            type: 'pie',
          },
        ],
        subtitle: {
          text: subtitle,
        },
        title: {
          text: title,
        },
      }}
      size={200}
    />
  );
});
