import { useQuery } from 'react-query';

import { CONFIG } from '@align-constants';
import { IUseQueryHookRest } from '@align-types/common';
import { useAuth0 } from '@common-context/Auth0';
import { useUserContext } from '@common-context/User';
import { useDemoCookie } from '@common-pages/Demo';
import { IApiBasicError } from '@common-services/api/common/types/common';
import {
  FilteredJIRAStuff,
  FilteredLabels,
  FilterJIRAStuff,
  FilterLabelsRequest,
  JIRAFilterReturn,
  JIRAProject,
} from '@common-services/api/public/generated-from-backend/models';

import { FilterServices } from '../services/filters';

export function useJiraFilter(
  params: FilterJIRAStuff,
  isCached: boolean = false
): IUseQueryHookRest<FilteredJIRAStuff> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();

  const { isLoading, data, error } = useQuery<FilteredJIRAStuff, IApiBasicError>(
    ['jiraFilter', params.account, params.return],
    async () => {
      const token: string = isDemo === false ? await getTokenSilently() : null;
      return FilterServices.jiraFilter(params, token);
    },
    {
      ...CONFIG.API.FETCH_OPTIONS,
      retry: 5,
      cacheTime: isCached ? CONFIG.API.FETCH_OPTIONS.cacheTime : 0,
      staleTime: isCached ? CONFIG.API.FETCH_OPTIONS.staleTime : 0,
    }
  );
  return { isLoading, data, error };
}

export function useOwnedJiraFilter(
  returnParams: JIRAFilterReturn[],
  isCached: boolean = false
): IUseQueryHookRest<FilteredJIRAStuff> {
  const {
    user: {
      defaultAccount: { id: account },
    },
  } = useUserContext();
  return useJiraFilter(
    {
      account,
      date_from: null,
      date_to: null,
      exclude_inactive: true,
      types: [],
      return: returnParams,
      with: {
        assignees: [],
        reporters: [],
        commenters: [],
      },
    },
    isCached
  );
}

export function useJiraProjects(isCached: boolean = false): IUseQueryHookRest<JIRAProject[]> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();
  const {
    user: {
      defaultAccount: { id: accountId },
    },
  } = useUserContext();

  const { isLoading, data, error } = useQuery<JIRAProject[], IApiBasicError>(
    ['jiraProjects', accountId],
    async () => {
      const token: string = isDemo === false ? await getTokenSilently() : null;
      return FilterServices.jiraProjects({ accountId }, token);
    },
    {
      ...CONFIG.API.FETCH_OPTIONS,
      cacheTime: isCached ? CONFIG.API.FETCH_OPTIONS.cacheTime : 0,
      staleTime: isCached ? CONFIG.API.FETCH_OPTIONS.staleTime : 0,
    }
  );
  return { isLoading, data: data?.filter((v) => v.enabled), error };
}

export function useLabelsFilter(
  params: FilterLabelsRequest,
  isCached: boolean = false
): IUseQueryHookRest<FilteredLabels> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();
  const { isLoading, data, error } = useQuery<FilteredLabels, IApiBasicError>(
    ['labelsFilter', params.account, params.repositories],
    async () => {
      const token: string = isDemo === false ? await getTokenSilently() : null;
      return FilterServices.labelsFilter(params, token);
    },
    {
      ...CONFIG.API.FETCH_OPTIONS,
      cacheTime: isCached ? CONFIG.API.FETCH_OPTIONS.cacheTime : 0,
      staleTime: isCached ? CONFIG.API.FETCH_OPTIONS.staleTime : 0,
    }
  );
  return { isLoading, data, error };
}

export function useOwnedLabelsFilter(
  params: FilterLabelsRequest,
  isCached: boolean = false
): IUseQueryHookRest<FilteredLabels> {
  const {
    user: {
      defaultAccount: { id: account },
    },
  } = useUserContext();
  return useLabelsFilter({ ...params, account }, isCached);
}
