import React, { useCallback, useContext, useMemo } from 'react';

import MultiSelect from '@analytics-components/MultiSelect';
import { TeamContext } from '@common-pages/Settings/teams/teams.context';
import { useTeamMembers } from '@common-pages/Settings/teams/teams.hooks';
import { useTheme } from '@styles/hooks';

import { openingDirectionStyles, panelStyles } from './addUserPanel.styles';
import { IAddUserPanel } from './addUserPanel.types';

const AddUserPanel: React.FC<IAddUserPanel> = React.memo(({ opensUp, onCancel, onSave }) => {
  const { members } = useContext(TeamContext);
  const theme = useTheme();
  const selectedMembersLogin = useMemo(() => {
    return members.map((member) => member.login);
  }, [members]);

  const {
    developersData,
    contributorsData,
    convertedDevelopers,
    convertedContributors,
    isLoading,
    isContributorsRequested,
    isContributorsLoaded,
    loadMoreClickHandler,
  } = useTeamMembers(selectedMembersLogin);
  const onSaveHandler = useCallback(
    (selectedIds: string[]) => {
      if (selectedIds.length > 0) {
        const selected = contributorsData
          ? contributorsData.filter((option) => selectedIds.includes(option.login))
          : developersData.filter((option) => selectedIds.includes(option.login));
        onSave(selected, false);
      }
    },
    [developersData, contributorsData]
  );

  return (
    <div
      className="dropdown-menu"
      css={(theme) => [panelStyles(theme), openingDirectionStyles(opensUp)]}
    >
      <div className="body">
        <h3>Add members to your team:</h3>
        <MultiSelect
          // @ts-ignore
          name={'addUser'}
          isLoading={isLoading}
          options={
            isContributorsRequested && contributorsData
              ? convertedContributors
              : convertedDevelopers
          }
          onApply={onSaveHandler}
          onCancel={onCancel}
          loadedAllOptions={contributorsData ? isContributorsRequested : isContributorsLoaded}
          onLoadAllOptionsClick={loadMoreClickHandler}
          noDropdown
          wrapperStyle={{
            marginBottom: theme.spacing.gap['10'],
          }}
        />
      </div>
    </div>
  );
});

export { AddUserPanel };
