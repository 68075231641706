import React from 'react';

import { useBreadcrumb } from '@analytics-context/Breadcrumb';
import BreadcrumbUI from '@ui/Breadcrumb';

const Breadcrumb = () => {
  const { breadcrumb } = useBreadcrumb();
  return <BreadcrumbUI breadcrumb={breadcrumb} />;
};

export default Breadcrumb;
