import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';

import { useApi } from '@analytics-hooks';
import { featuresList, getFeature } from '@analytics-services/flags';
import { useDeployments } from '@common-services/api/public/hooks/useDeployments';
import { github } from '@common-services/format';
import { NoData } from '@lib/empty/noData';
import { TooManyRows } from '@lib/empty/tooManyRows';

import { DeploymentsTable } from './components/deployments-table';
import { DeploymentStatus, IDeploymentsRow, ISerializedDeployment } from './deployments.types';
import { useFilter } from './hooks/useFilter';
import { useSearch } from './hooks/useSearch';

const Deployments: React.FC = React.memo(() => {
  const { context: apiContext } = useApi(true);
  const { account, contributors, epics, interval, issueTypes, labels, repositories } = apiContext;
  const labelsExclude = getFeature(featuresList.exclude_prs_by_labels, apiContext.features)
    ?.parameters?.value;
  const [filteredDeployments, setFilteredDeployments] = useState<IDeploymentsRow[]>([]);

  const { data, isLoading } = useDeployments(
    {
      account,
      contributors,
      dateInterval: interval,
      jira: {
        epics,
        issue_types: issueTypes,
      },
      labelsExclude,
      labelsInclude: labels,
      repositories,
    },
    true
  );

  const allDeployments = useMemo<ISerializedDeployment[]>(() => {
    const serializedData = data?.deployments
      .map((d) => {
        const startDate = new Date(d.date_started);
        const finishDate = new Date(d.date_finished);
        return {
          isSuccessful: d.conclusion === 'SUCCESS' ? true : false,
          status: d.conclusion === 'SUCCESS' ? DeploymentStatus.SUCCESS : DeploymentStatus.FAIL,
          name: d.name,
          finished: finishDate,
          additions: d.prs.reduce((acc, pr) => acc + pr.additions, 0),
          deletions: d.prs.reduce((acc, pr) => acc + pr.deletions, 0),
          deployTime: (finishDate.getTime() - startDate.getTime()) / 1000,
          environment: d.environment,
          prsAmount: d.prs.length,
          subRows: d.prs.map((pr) => ({
            repository: pr.repository,
            title: pr.title,
            number: pr.number,
            created: new Date(pr.created),
            author: github.userName(pr.author),
            authorAvatar: data.include.users[pr.author]?.avatar || null,
          })),
        };
      })
      .sort((a, b) => (a.finished > b.finished ? -1 : 1));
    return serializedData;
  }, [data]);

  useEffect(() => {
    if (allDeployments?.length) {
      setFilteredDeployments(allDeployments as IDeploymentsRow[]);
    }
  }, [allDeployments]);

  const { currentFilter, filterOptions, placeholder, handleFilter } = useFilter(
    allDeployments as IDeploymentsRow[],
    setFilteredDeployments
  );

  const { searchedString, handleSearch } = useSearch(
    allDeployments as IDeploymentsRow[],
    setFilteredDeployments
  );

  if (isLoading) {
    return <div className="dataTable-placeholder filter-placeholder" />;
  }

  if (!allDeployments.length) {
    return <NoData />;
  } else if (allDeployments.length > 5000) {
    return <TooManyRows amount={allDeployments.length} />;
  }

  return (
    <DeploymentsTable
      currentFilter={currentFilter}
      deployments={filteredDeployments}
      filterOptions={filterOptions}
      filterPlaceholder={placeholder}
      searchedString={searchedString}
      onFilter={handleFilter}
      onSearch={handleSearch}
    />
  );
});

export { Deployments };
