import { report as reportToSentry, isSentryTagsReady } from '@analytics-services/sentry';
import { DateService } from '@common-services/dateService';
import { NumberService } from '@common-services/numberService';
import { StorageService, StorageServiceType, StorageTarget } from '@common-services/storageService';
import { StringService } from '@common-services/stringService';

import {
  IResponseHealthLogItem,
  IGetHealthParams,
  IUpdateResponseHealthCache,
  IResponseGroupByRetryId,
  IGroupByStatus,
  IResponseHealthParams,
  ICalculateRatios,
  IGroupByStatusResponse,
  IHealthStatus,
} from './responseHealthService.types';

const DEFAULT_STATUS = { success: 0, fail: 0, retryGroupsCount: 0 };

export const runResponseHealthCheck = (newItems: IResponseHealthLogItem[]) => {
  const { ratios, itemCount } = getHealthParams(newItems);

  const { error, report } = checkRatios(ratios);

  if (itemCount > (window?.ENV?.responseHealthCheck?.chunkSize || 10) && isSentryTagsReady()) {
    if (error) {
      const sentryError = new Error(`API Response health is under risk level:\n${report}`);
      sentryError.name = 'Response Health Issue';
      reportToSentry(sentryError, { uniqueFingerprint: true });
    }
    clearResponseHealthCache();
  }
};

export const checkRatios = (ratios: ICalculateRatios): IHealthStatus => {
  const limits = window?.ENV?.responseHealthCheck?.minValues;
  let report = '';
  let error = false;

  if (limits?.overall) {
    Object.entries(limits.overall).forEach(([type, limit]: [string, number]) => {
      const current = ratios.overall[type];
      let reportLine = `Overall ${StringService.capitalCase(type)} Ratio: ${current * 100}% (> ${
        limit * 100
      }%)`;
      if (current < limit) {
        error = true;
        reportLine = `*${reportLine}*`;
      }
      report += `${reportLine}\n`;
    });
  }

  if (limits?.targeted) {
    Object.entries(limits.targeted).forEach(([target, ratioLimits]) => {
      Object.entries(ratioLimits).forEach(([type, limit]: [string, number]) => {
        const current = ratios.targeted[target][type];
        let reportLine = `${StringService.capitalCase(target)} ${StringService.capitalCase(
          type
        )} Ratio:  ${current * 100}% (> ${limit * 100}%)`;
        if (current < limit) {
          error = true;
          reportLine = `*${reportLine}*`;
        }
        report += `${reportLine}\n`;
      });
    });
  }

  return { error, report };
};

export const getHealthParams = (newItems: IResponseHealthLogItem[]): IGetHealthParams => {
  const { updatedLogs } = updateResponseHealthCache(newItems);
  const itemCount = updatedLogs.length;
  // Group logs by their corresponding retries
  const retryGroups = groupByRetryId(updatedLogs);
  // Group the log groups by their initial status
  // Use their first response
  const statusGroups = groupByStatus(retryGroups);

  const targets = window?.ENV?.responseHealthCheck?.minValues?.targeted;

  return {
    itemCount,
    ratios: calculateRatios({
      targetCodes: targets ? Object.keys(targets).map((code) => parseInt(code)) : [],
      statusGroups,
    }),
    dateRange: {
      from: updatedLogs[0]?.date || '0',
      to: updatedLogs?.[updatedLogs.length - 1]?.date || '0',
    },
  };
};

const updateResponseHealthCache = (
  newItems: IResponseHealthLogItem[]
): IUpdateResponseHealthCache => {
  const updatedLogs = [
    ...JSON.parse(
      StorageService.getItem({
        target: StorageTarget.RESPONSE_HEALTH_LOGS,
        type: StorageServiceType.LOCAL,
      }) || '[]'
    ),
    ...newItems,
  ].sort((a, b) => DateService.compareDateFn(a.date, b.date));

  StorageService.setItem({
    target: StorageTarget.RESPONSE_HEALTH_LOGS,
    type: StorageServiceType.LOCAL,
    value: JSON.stringify(updatedLogs),
  });
  return { updatedLogs };
};

export const clearResponseHealthCache = () => {
  StorageService.removeItem({
    target: StorageTarget.RESPONSE_HEALTH_LOGS,
    type: StorageServiceType.LOCAL,
  });
};

export const groupByRetryId = (logs: IResponseHealthLogItem[]): IResponseGroupByRetryId =>
  logs.reduce((a, c) => {
    // id's will be same for corresponding retries for a request
    // Use id's to group responses
    let currentId = c.id;
    if (!a[currentId]) {
      a[currentId] = [];
    }
    // push the log to its retry group
    a[currentId].push(c);
    return a;
  }, {});

export const groupByStatus = (retryGroups: IResponseGroupByRetryId): IGroupByStatus =>
  Object.entries(retryGroups).reduce((a, c) => {
    const retryGroupData = c[1];
    // Use the statusCode of the first item
    // Ignored different types of errors in a single group
    // eg: [503, 503, 404] because it's an edge case
    const targetGroup = retryGroupData[0].statusCode;
    if (!a[targetGroup]) {
      // Create default object for new statusCode group
      a[targetGroup] = { ...DEFAULT_STATUS };
    }

    // Update the params accordingly
    a[targetGroup].retryGroupsCount++;
    // Loop the items in the group to update the fail and success counts
    retryGroupData.forEach((log) => {
      a[targetGroup][log.status]++;
    });
    return a;
  }, {});

const getOverallResults = (data: IGroupByStatus): IGroupByStatusResponse =>
  Object.values(data).reduce(
    (a, c) => {
      Object.entries(c).forEach((r) => {
        a[r[0]] += r[1];
      });
      return a;
    },
    { ...DEFAULT_STATUS }
  );

export const calculateRatios = ({
  targetCodes,
  statusGroups,
}: IResponseHealthParams): ICalculateRatios => {
  // Get total values
  const overallValues = getOverallResults(statusGroups);

  // calculate targeted values
  const targeted = targetCodes.reduce((a, code) => {
    // if there is no log values related with the target return default
    if (!statusGroups[code]) {
      return { ...a, [code]: { general: 1, grouped: 1 } };
    }

    // target log values
    const target = statusGroups[code];
    const totalTargetRequestCount = target.fail + target.success;

    // add each statusCode ratios into their field
    return {
      ...a,
      [code]: {
        general: NumberService.round(
          totalTargetRequestCount ? target.success / totalTargetRequestCount : 1,
          2
        ),
        grouped: NumberService.round(
          target.retryGroupsCount ? target.success / target.retryGroupsCount : 1,
          2
        ),
      },
    };
  }, {});

  // calculate overall values
  const overall = {
    general:
      overallValues.fail + overallValues.success
        ? NumberService.round(
            overallValues.success / (overallValues.fail + overallValues.success),
            2
          )
        : 1,
    grouped: overallValues.retryGroupsCount
      ? NumberService.round(overallValues.success / overallValues.retryGroupsCount, 2)
      : 1,
  };

  return {
    overall,
    targeted,
  };
};
