import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { TagLabel } from '../tagLabel';
import { IMultiTagLabel, LABEL_SIZE } from '../tagLabel.types';
import { multiTagLabelStyles } from './multiTagLabel.styles';

export const MultiTagLabel: React.FC<IMultiTagLabel> = ({
  avatarType,
  disabled = false,
  items,
  withSmallTags = false,
  onRemove,
}) => {
  return (
    <div css={multiTagLabelStyles}>
      {items.map((item) => (
        <TagLabel
          key={uuidv4()}
          avatarColor={item.color}
          avatarIcon={item.icon}
          avatarImage={item.image}
          avatarType={item.avatarType || avatarType}
          disabled={disabled}
          label={item.label}
          labelSize={withSmallTags ? LABEL_SIZE.small : LABEL_SIZE.default}
          truncate={true}
          onRemove={() => onRemove(item)}
        />
      ))}
    </div>
  );
};
