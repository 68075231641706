import _ from 'lodash';
import moment from 'moment';
import React from 'react';

import { calculateGranularity } from '@analytics-components/Prefetcher';
import { SimpleKPI } from '@analytics-components/insights/KPI';
import { fetchPRsMetrics } from '@analytics-services/api';
import { fetchJIRAMetrics } from '@analytics-services/api/jira';
import { getFeature, featuresList } from '@analytics-services/flags';
import { FAKE_USERNAME } from '@common-pages/Settings/constants';
import { dateTime, humanLaboralRate } from '@common-services/dateService';
import { theme } from '@styles/theme';
import { ProgressBarMulti } from '@ui/ProgressBar';

import { kpisWrapper } from './styles';

const bugsMetrics = {
  fetcher: async (api, cachedData, apiContext) => {
    const {
      account,
      contributors,
      interval,
      excludeInactive,
      repositories,
      labels,
      features,
      includeNullContributor,
      includeFakeContributor,
      epics,
    } = apiContext;
    const granularity = calculateGranularity(interval);
    const diff = moment(interval.to).diff(interval.from, `${granularity}s`);
    const jiraAssignees = _(contributors).map('jira_user').filter().value();
    if (includeNullContributor) {
      jiraAssignees.push(null);
    }
    if (includeFakeContributor) {
      jiraAssignees.push(FAKE_USERNAME);
    }

    const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, features)?.parameters
      ?.value;

    const [bugsResolved, prs, review] = await Promise.all([
      fetchJIRAMetrics(
        api,
        account,
        [granularity],
        interval,
        ['resolved'],
        excludeInactive,
        { types: ['Bug'], epics },
        { assignees: jiraAssignees }
      ),
      fetchPRsMetrics(
        api,
        account,
        [granularity],
        interval,
        ['size', 'reviewed', 'not-reviewed', 'lead-count'],
        {
          jira: { issue_types: ['Bug'], epics },
          repositories,
          with: {
            author: contributors,
          },
          labels_include: labels,
          ...(excludedLabels ? { labels_exclude: excludedLabels } : {}),
        },
        null,
        excludeInactive
      ),
      fetchPRsMetrics(
        api,
        account,
        ['all'],
        interval,
        ['review-time'],
        {
          jira: { issue_types: ['Bug'], epics },
          repositories,
          with: {
            author: contributors,
          },
          labels_include: labels,
          ...(excludedLabels ? { labels_exclude: excludedLabels } : {}),
        },
        null,
        excludeInactive
      ),
    ]);

    return {
      bugsAmount: _(bugsResolved[0].values)
        .map((v) => v.values[0])
        .sum(),
      diff,
      granularity,
      prs: prs.calculated.length ? prs.calculated[0].values : [],
      reviewTime: review.calculated.length ? review.calculated[0].values[0].values[0] : 0,
    };
  },
  plumber: (fetchedData, cachedData, apiContext) => {
    const { bugsAmount, prs, reviewTime } = fetchedData;
    const { interval } = apiContext;

    let prAmount = 0;
    const totalPrSize = prs.reduce((avg, pr) => {
      if (pr.values[0]) {
        prAmount += 1;
        return avg + pr.values[0];
      }
      return avg;
    }, 0);

    const avgPrSize = prAmount > 0 ? Math.round(totalPrSize / prAmount) : null;
    const reviewedPrs = prs.reduce((total, pr) => total + pr.values[1], 0);
    const notReviewedPrs = prs.reduce((total, pr) => total + pr.values[2], 0);
    const releasedPrs = prs.reduce((total, pr) => total + pr.values[3], 0);
    const reviewedPrRatio =
      notReviewedPrs + reviewedPrs > 0
        ? Math.round((reviewedPrs * 100) / (notReviewedPrs + reviewedPrs))
        : null;
    const mappedToPrRatio =
      bugsAmount > 0 ? Math.min(Math.round((releasedPrs * 100) / bugsAmount), 100) : null;

    const progressBarParams = [
      {
        value: mappedToPrRatio,
        color: theme.color.ui.turquoise[100],
        labelLeft: 'Mapped to PRs',
        labelRight: mappedToPrRatio ? `${mappedToPrRatio}%` : '',
      },
      {
        value: reviewedPrRatio,
        color: theme.color.ui.orange[100],
        labelLeft: 'Reviewed',
        labelRight: reviewedPrRatio ? `${reviewedPrRatio}%` : '',
      },
    ];

    return {
      kpis: [
        {
          title: { text: 'Bugs Fixed', bold: true },
          component: SimpleKPI,
          params: {
            value: bugsAmount.toString(),
            secondary: `(${releasedPrs.toString()} PRs)`,
          },
        },
        {
          title: { text: 'Frequency', bold: true },
          component: SimpleKPI,
          params: {
            value: `
              ${humanLaboralRate(interval.from, interval.to, bugsAmount).slice(0, 2).join('/')}
            `,
          },
        },
        {
          title: { text: 'Review Time', bold: true },
          component: SimpleKPI,
          params: {
            value: reviewTime ? dateTime.human(reviewTime * 1000) : '',
          },
        },
        {
          title: { text: 'Size', bold: true },
          component: SimpleKPI,
          params: {
            value: avgPrSize ? `${avgPrSize} line${avgPrSize === 1 ? '' : 's'}` : '',
          },
        },
      ],
      kpiClassName: 'kpi-wrapper col-6',
      kpisWrapper: ({ children }) => (
        <div className="row" css={kpisWrapper}>
          {children}
        </div>
      ),
      chart: {
        component: ProgressBarMulti,
        isRenderedLast: true,
        params: {
          params: progressBarParams,
          normalize: false,
        },
      },
    };
  },
};

export default bugsMetrics;
