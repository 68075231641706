import { IGoalMetricValue } from '@align-services/api/types/goalsTypes';
import { IDateRange } from '@align-services/dateService';
import { ValueType } from '@align-types/constants';
import { dateTime } from '@common-services/dateService';

export const parseMetricValue = (metricValue: IGoalMetricValue): number => {
  return (
    Math.round(100 * (parseInt(metricValue?.str) || metricValue?.int || metricValue?.float)) / 100
  );
};

export const getValueType = (value: IGoalMetricValue): ValueType => {
  if (value?.str) {
    return ValueType.date;
  } else if (value?.int) {
    return ValueType.int;
  } else if (value?.float) {
    return ValueType.float;
  }
};

export const getCurrentRange = (goalRange: IDateRange): IDateRange => {
  const endDate = new Date(goalRange.dateTo);
  if (new Date() > endDate) {
    return goalRange;
  }
  return {
    dateFrom: goalRange.dateFrom,
    dateTo: dateTime.ymd(new Date()),
  };
};
