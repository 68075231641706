import { useEffect, useReducer, useRef } from 'react';

import {
  IMergedPrsAction,
  IMergedPrsState,
} from '@align-pages/goals/single/components/goal-view-component/goalViewComponent.types';
import { PrsIssuesTableServices } from '@align-services/prsIssuesTableService';
import { IDateRangeInMls, PrsIssuesTableTabs } from '@align-types/common';
import { SearchPullRequestsResponse } from '@common-services/api/private/generated-from-backend/models';
import { PullRequestSet } from '@common-services/api/public/generated-from-backend/models';
import { isEqual } from '@common-services/vendor/lodash';

const initialState = {
  completed: null,
  inProgress: null,
};

const reducer = (state: IMergedPrsState, action: IMergedPrsAction): IMergedPrsState => {
  if (!action.data) {
    return { ...state, completed: null };
  }
  let mergedSet: PullRequestSet;
  if (action?.data.data.length >= 0) {
    if (action?.tabIndex === PrsIssuesTableTabs.COMPLETED) {
      mergedSet = PrsIssuesTableServices.mergePrDataPortions(state.completed, action.data);
    } else {
      mergedSet = PrsIssuesTableServices.mergePrDataPortions(state.inProgress, action.data);
    }
  }
  return {
    ...state,
    ...(action?.tabIndex === PrsIssuesTableTabs.COMPLETED
      ? { completed: mergedSet }
      : { inProgress: mergedSet }),
  };
};

export function useMergePrData(
  prTableDataPortion: PullRequestSet,
  activeTab: PrsIssuesTableTabs,
  selectedRange: IDateRangeInMls,
  allCompletedPrs?: SearchPullRequestsResponse
): PullRequestSet {
  const [mergedPrData, dispatchPrData] = useReducer(reducer, initialState);
  const prevData = useRef(prTableDataPortion);

  // clean the state of completed PRs whenever Timeframe value or set of completed PRs changed
  useEffect(() => {
    dispatchPrData({ data: null });
  }, [selectedRange, allCompletedPrs]);

  useEffect(() => {
    if (prTableDataPortion) {
      // do not merge data if it did not change from the previous time
      if (!isEqual(prevData.current, prTableDataPortion)) {
        dispatchPrData({ data: prTableDataPortion, tabIndex: activeTab });
        prevData.current = prTableDataPortion;
      }
    }
  }, [activeTab, prTableDataPortion, prevData]);

  return activeTab === PrsIssuesTableTabs.COMPLETED
    ? mergedPrData.completed
    : mergedPrData.inProgress;
}
