import React from 'react';
import { CellProps, Renderer } from 'react-table';

import { IIssuesTableRow } from '@align-types/issues';
import { Icon, icons } from '@lib/icon';
import { theme } from '@styles/theme';

import { wrapperStyles } from './cellComments.styles';

export const IssuesCellComments: Renderer<CellProps<IIssuesTableRow>> = React.memo(({ row }) => (
  <div css={wrapperStyles}>
    <Icon color={theme.color.text.secondary} icon={icons.comment} size={15} />
    <div className="number">{row.original.comments}</div>
  </div>
));
