import React from 'react';

import noStageData from '@assets/images/empty-states/no-stage-data.svg';

import { Empty } from '..';

interface Props {
  companionCopy: string;
  textOnly: boolean;
  textOnlyCopy: string;
}

const NoDataForStage: React.FC<Props> = React.memo(({ textOnly, textOnlyCopy, companionCopy }) => (
  <Empty textOnly={textOnly} copy={textOnlyCopy} background={noStageData}>
    <h3>{companionCopy}</h3>
  </Empty>
));

export { NoDataForStage };
