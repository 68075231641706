import React from 'react';

import { BREADCRUMBS_MEMBERS } from '@e2e/core/testIds/align/appearance';
import { Avatar } from '@lib/avatar';

import { teamStyles, avatarStyles } from './TeamBadge.styles';
import { testIds } from './__tests__/testIds.ids';
import { TeamBadgeDetails } from './components';
import { ITeamBadge } from './types';

export const TeamBadge: React.FC<ITeamBadge> = React.memo(({ teamName, color, teams, members }) => {
  return (
    <>
      <div css={teamStyles}>
        <div css={avatarStyles}>
          <Avatar type={3} color={color} label={teamName} size={40} />
        </div>

        <span data-pw={BREADCRUMBS_MEMBERS.AVATAR} data-testid={testIds.teamname}>
          {teamName}
        </span>
      </div>
      <TeamBadgeDetails teams={teams} members={members} />
    </>
  );
});
