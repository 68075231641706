import { css } from '@emotion/react';

export const getWrapperStyle = ({ color, radius }) => css`
  margin-top: -42px;
  background: ${color.neutral[0]};
  width: calc(100vw - 16px);
  height: calc(100vh - 16px);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  & > div.wrapper {
    background: ${color.neutral[0]};
    border-radius: ${radius.default};
    width: 72vw;
    max-width: 1030px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-direction: column;
    margin-top: -90px;

    & > header {
      height: 90px;
      text-align: center;

      & > img {
        display: block;
        max-height: 100%;
        width: 210px;
      }
    }

    & > main {
      background: ${color.neutral.white};
      border: 1px solid ${color.neutral[40]};
      border-radius: ${radius.default};
      flex-grow: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      width: 100%;

      @media only screen and (max-width: 1378px) {
        width: 80%;
      }

      @media only screen and (max-width: 940px) {
        max-width: 360px;
        padding: 60px 0;
        width: 100%;
      }
    }
  }
`;

export const getIllustrationStyle = () => css`
  flex-basis: 0;
  flex-grow: 1;

  @media only screen and (max-width: 940px) {
    display: none;
  }

  & > img {
    display: block;
    max-height: 100%;
    width: 100%;
  }
`;

export const getContentWrapperStyle = ({ color, font }) => css`
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  gap: 40px;
  font-size: 0;
  line-height: 0;

  & > div.welcome-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 250px;
  }

  & span.welcome {
    font-size: ${font.size.l};
    line-height: ${font.height.l};
    letter-spacing: ${font.spacing.l};
    color: ${color.neutral['100']};
  }

  & span.subtitle {
    font-size: ${font.size.s};
    line-height: ${font.height.s};
    letter-spacing: ${font.spacing.s};
  }
`;
