import React from 'react';

import { wrapperStyles } from './tagsContainerPlaceholder.styles';
import { ITagsContainerPlaceholder } from './tagsContainerPlaceholder.types';

export const TagsContainerPlaceholder: React.FC<ITagsContainerPlaceholder> = ({ text }) => {
  return (
    <span data-cy="tags-container-placeholder" css={wrapperStyles}>
      {text}
    </span>
  );
};
