import { useTheme } from '@emotion/react';
import React from 'react';

import { IColor, ITheme } from '@styles/types';

import { ICellMetricProps } from './types';

export const CellMetric: React.FC<ICellMetricProps> = React.memo(
  ({ value, cell, row, state, lowBetter, formatter }) => {
    const { color } = useTheme() as ITheme;
    const key = cell.column.id;
    const isRoot = row.depth === 0;
    const isTotalRow = row.values.name === 'Total';
    const { min, max } = state.colsMinMax.root[key];
    const [best, worst]: [IColor, IColor] = !lowBetter
      ? [color.ui.blue[100], color.ui.lightorange[100]]
      : [color.ui.lightorange[100], color.ui.blue[100]];
    const css: { color?: IColor } = {};
    if (isRoot && !isTotalRow) {
      if (value >= max) {
        css.color = best;
      } else if (value <= min) {
        css.color = worst;
      }
    }

    return <span css={css}>{formatter ? formatter(value) : value}</span>;
  }
);
