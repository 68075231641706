import { DatetimeService } from '@analytics-services/datetimeService';

// TODO: Replace all the `any` types here
export interface IContext {
  ready: boolean;
  appliedRepos: any[];
  appliedContribs: any[];
  hasAppliedNullContrib: boolean;
  hasAppliedBotContrib: boolean;
  reposOptions: any[];
  contribsOptions: any[];
  reposReady: boolean;
  contribsReady: boolean;
  teams: any[];
  labels: ISubFilterState;
  epics: ISubFilterState;
  issueTypes: ISubFilterState;
  dateInterval: DatetimeService.Interval;
  excludeInactive: boolean;
  onReposInit: (selected: any[]) => void;
  onContribsInit: (selected: any[]) => void;
  onOmniboxInit: (selected: any[]) => void;
  onDateIntervalChange: (selected: DatetimeService.Interval) => void;
  onReposApplyChange: (selected: any[], doNothing: boolean) => void;
  onContribsApplyChange: (selected: any[], noReset: boolean) => void;
  onExcludeInactive: () => void;
  onLabelsApplyChange: (selected: any[]) => void;
}

export enum ActionType {
  INIT_OPTIONS = 'INIT_OPTIONS',

  SET_READY = 'SET_READY',
  SET_INTERVAL = 'SET_INTERVAL',
  SET_EXCLUDE_INACTIVE = 'SET_EXCLUDE_INACTIVE',

  SET_REPOS_OPTIONS = 'SET_REPOS_OPTIONS',
  SET_CONTRIBS_OPTIONS = 'SET_CONTRIBS_OPTIONS',
  SET_LABELS_OPTIONS = 'SET_LABELS_OPTIONS',
  SET_EPICS_OPTIONS = 'SET_EPICS_OPTIONS',
  SET_ISSUE_TYPES_OPTIONS = 'SET_ISSUE_TYPES_OPTIONS',

  SET_APPLIED_REPOS = 'SET_APPLIED_REPOS',
  SET_APPLIED_CONTRIBS = 'SET_APPLIED_CONTRIBS',
  SET_APPLIED_LABELS = 'SET_APPLIED_LABELS',
  SET_APPLIED_EPICS = 'SET_APPLIED_EPICS',
  SET_APPLIED_ISSUE_TYPES = 'SET_APPLIED_ISSUE_TYPES',
}

export interface IAction {
  type: ActionType;
  payload: any;
}

export type ActionFuncWithArg = (arg0: any) => IAction;
export type ActionFuncWithoutArg = () => IAction;

interface IValuesState {
  values: any[];
  ready: boolean;
}

export interface ISubFilterState {
  options: IValuesState;
  applied: IValuesState;
}

export enum SubFilter {
  REPOS = 'repos',
  CONTRIBS = 'contribs',
  LABELS = 'labels',
  EPICS = 'epics',
  ISSUE_TYPES = 'issueTypes',
}

export type IFilterState = {
  [key in SubFilter]: ISubFilterState;
} & {
  teams: {
    options: any[];
  };
  dateInterval: DatetimeService.Interval | null;
  excludeInactive: boolean;
};

export interface IGetReposOptions {
  token: string;
  accountID: number;
  dateInterval: DatetimeService.Interval;
  repos: string[];
  excludeInactive: boolean;
}
