import React from 'react';

import DistributionChart from '@analytics-components/charts/DistributionChart';
import { theme } from '@styles/theme';

const frequencies = [
  0,
  5,
  10,
  15,
  10,
  15,
  18,
  19,
  22,
  29,
  21,
  19,
  36,
  28,
  39,
  43,
  42,
  31,
  51,
  45,
  42,
  45,
  34,
  39,
  33,
  31,
  35,
  42,
  35,
  23,
  23,
  12,
  22,
  26,
  29,
  11,
  5,
  6,
  3,
  1,
  3,
  2,
  2,
  1,
  1,
  1,
  5,
  6,
  8,
];

const multiFrequencies = [
  [3, 5, 10, 15, 20, 45, 20, 30, 5, 10, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 3, 5, 10, 40, 80, 20, 35, 5, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 1, 1, 5, 10, 60, 80, 75, 20],
];

const sampleData = {
  bucketCenters: frequencies.map((_, i) => i * 60 * 1000),
  frequencies: frequencies,
};

const sampleDataMulti = {
  bucketCenters: multiFrequencies[0].map((_, i) => i * 60 * 1000),
  frequencies: multiFrequencies,
};

const extraDistribution = {
  fillColor: theme.color.ui.lightblue[50],
  height: 250,
  maxNumberOfTicks: 6,
  axisLabels: {
    x: 'days',
    y: 'pull requests',
  },
};

const extraDistributionMulti = {
  fillColor: theme.color.sets.bugs,
  height: 250,
  maxNumberOfTicks: 6,
  baseOpacity: 0.5,
  legend: {
    labels: ['highest', 'high', 'medium'],
  },
  axisLabels: {
    x: 'days',
    y: 'bugs',
  },
};

const Chart = () => (
  <>
    <DistributionChart data={sampleData} extra={extraDistribution} />
    <DistributionChart data={sampleDataMulti} extra={extraDistributionMulti} />
  </>
);

export default Chart;
