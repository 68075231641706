import React from 'react';

import RunTimePerPRPanel from './RunTimePerPRPanel';
import RunTimePerRepoPanel from './RunTimePerRepoPanel';

const InsightsRunTime = () => (
  <>
    <div className="row mx-0 px-0 mb-4">
      <div className="col-md-12 mx-0 px-0">
        <RunTimePerPRPanel />
      </div>
    </div>
    <div className="row mx-0 px-0 mb-4">
      <div className="col-md-12 mx-0 px-0">
        <RunTimePerRepoPanel />
      </div>
    </div>
  </>
);

export default InsightsRunTime;
