import PropTypes from 'prop-types';
import React from 'react';

import { optionType, optionNoColorType } from '@lib/Selector/types';
import { Avatar } from '@lib/avatar';

import { styles } from './styles';

const SingleLabel = React.forwardRef(({ hasLongItem, item, noAvatar, notLast }, ref) => (
  <div css={styles(item.value !== 'extra', hasLongItem)} ref={ref}>
    {item.value !== 'extra' && !noAvatar && (
      <Avatar color={item.color} icon={item.icon} image={item.image} label={item.label} />
    )}
    <div className="ath-label-text ath-value-label">{item.label}</div>
    <div className="ath-label-text">{notLast && item.value !== 'extra' && ','}</div>
  </div>
));

const SingleLabelPropTypes = {
  hasLongItem: PropTypes.bool,
  item: PropTypes.oneOfType([optionType, optionNoColorType]).isRequired,
  noAvatar: PropTypes.bool,
  notLast: PropTypes.bool,
};

SingleLabel.propTypes = SingleLabelPropTypes;

export default SingleLabel;
