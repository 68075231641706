import { useParams, useRouteMatch } from 'react-router-dom';

import { PrimaryLayoutTabs } from '@align-components/layouts/primary/context.types';
import { NavigationService } from '@align-services/navigationService';

export function useDefaultTab(): { isAlignTab: boolean; redirectedPath: string } {
  const { tab } = useParams();
  const { url } = useRouteMatch();
  const isAlignTab = NavigationService.isAlignTab(tab);
  const redirectedPath = `${NavigationService.validateRoutePath(url)}/${
    PrimaryLayoutTabs.DASHBOARD
  }`;
  return { isAlignTab, redirectedPath };
}
