import React, { useCallback, useEffect, useState } from 'react';

import { ArrayService } from '@common-services/arrayService';
import { ObjectService } from '@common-services/objectService';
import { SelectTsOptionSingle } from '@lib/selector-ts';
import { useSelectedIds } from '@lib/selector-ts/hooks/useSelectedIds';
import { ClickTypeEnum } from '@lib/selector-ts/types/context';

export type ClickHandlerArgs = (
  event: React.MouseEvent<HTMLDivElement>,
  id: string,
  type: ClickTypeEnum,
  isSelected: boolean,
  parentIds: string[],
  childrenIds: string[]
) => void;

export type UseCheckboxClick = {
  options: SelectTsOptionSingle[];
  isAllSelected: boolean;
  selectedIds: string[];
  clickHandler: ClickHandlerArgs;
};

export function useCheckboxClick(
  optionsInit: SelectTsOptionSingle[],
  isCascading: boolean
): UseCheckboxClick {
  const [options, setOptions] = useState<SelectTsOptionSingle[]>(optionsInit);
  const selectedIds: string[] = useSelectedIds(options);
  const [isAllSelected, setIsAllSelected] = React.useState<boolean>(() => {
    return ArrayService.getFlatArrayFromNested(options, 'children').length === selectedIds.length;
  });
  useEffect(() => {
    setOptions(optionsInit);
  }, [optionsInit]);
  const clickHandler = useCallback(
    (
      event: React.MouseEvent<HTMLDivElement>,
      id: string,
      type: ClickTypeEnum,
      isSelected: boolean = false,
      parentIds: string[] = [],
      childrenIds: string[] = []
    ): void => {
      event.stopPropagation();
      if (id === '0') {
        setIsAllSelected(!isAllSelected);
      } else if (type === ClickTypeEnum.isSelected) {
        setIsAllSelected(false);
      }
      const updateNestedToggle = (option: SelectTsOptionSingle): SelectTsOptionSingle => {
        option = ObjectService.updateNestedObjToggle(option, id, type);
        if (
          isCascading &&
          ObjectService.isNestedObjContain(option, 'id', id) &&
          type === ClickTypeEnum.isSelected
        ) {
          if (parentIds.length > 0 && isSelected) {
            option = ObjectService.updateNestedObjByIdKeyValue(
              option,
              parentIds,
              'isSelected',
              false
            );
          }
          if (childrenIds.length > 0) {
            option = ObjectService.updateNestedObjByIdKeyValue(
              option,
              childrenIds,
              'isSelected',
              !isSelected
            );
          }
        }
        return option;
      };
      const updatedOptions: SelectTsOptionSingle[] = options.map((option) => {
        return id === '0'
          ? ObjectService.updateNestedObjByKeyValue(option, 'isSelected', !isAllSelected)
          : updateNestedToggle(option);
      });
      setOptions(updatedOptions);
    },
    [options, isAllSelected]
  );
  return { options, isAllSelected, selectedIds, clickHandler };
}
