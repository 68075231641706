import { BasicApiClass } from '@common-services/api/common/BasicApiClass';
import { IUrlParams, REST_TYPES } from '@common-services/api/common/types/apiClient';
import { ApiMode } from '@common-services/api/common/types/common';
import { ISentryMethodParams } from '@common-services/api/common/types/sentry';
import { PRIVATE_API_ENDPOINTS_CONSTANTS } from '@common-services/api/private/constants';
import {
  AlignGoalsRequest,
  GoalUnassignTeamRequest,
  AlignGoalsResponse,
  GoalCreateRequest,
  GoalUpdateRequest,
} from '@common-services/api/private/generated-from-backend/models';
import { CreatedIdentifier } from '@common-services/api/public/generated-from-backend/models';

export class AlignGoalsApi extends BasicApiClass {
  constructor() {
    super(ApiMode.PRIVATE);
  }
  public getGoalsList(
    params: AlignGoalsRequest,
    token: string = null,
    sentry?: ISentryMethodParams
  ): Promise<AlignGoalsResponse> {
    this.assignToken(token);
    const callApi = this.callApi<AlignGoalsResponse>(
      PRIVATE_API_ENDPOINTS_CONSTANTS.ALIGN_GOALS_LIST,
      REST_TYPES.POST,
      params
    );
    return sentry ? this.callSentry<AlignGoalsResponse>(callApi, sentry) : callApi;
  }
  public createGoal(
    params: GoalCreateRequest,
    token: string = null,
    sentry?: ISentryMethodParams
  ): Promise<CreatedIdentifier> {
    this.assignToken(token);
    const callApi = this.callApi<CreatedIdentifier>(
      PRIVATE_API_ENDPOINTS_CONSTANTS.ALIGN_CREATE_GOAL,
      REST_TYPES.POST,
      params
    );
    return sentry ? this.callSentry<CreatedIdentifier>(callApi, sentry) : callApi;
  }
  public updateGoal(
    urlParams: IUrlParams<'goalId'>,
    params: GoalUpdateRequest,
    token: string = null,
    sentry?: ISentryMethodParams
  ): Promise<CreatedIdentifier> {
    this.assignToken(token);
    const apiPath = this.replaceRouteLink(
      PRIVATE_API_ENDPOINTS_CONSTANTS.ALIGN_UPDATE_GOAL,
      urlParams
    );
    const callApi = this.callApi<CreatedIdentifier>(apiPath, REST_TYPES.PUT, params);
    return sentry ? this.callSentry<CreatedIdentifier>(callApi, sentry) : callApi;
  }
  public removeGoal(
    urlParams: IUrlParams<'goalId'>,
    params: GoalUnassignTeamRequest,
    token: string = null,
    sentry?: ISentryMethodParams
  ): Promise<CreatedIdentifier> {
    const apiPath = this.replaceRouteLink(
      PRIVATE_API_ENDPOINTS_CONSTANTS.ALIGN_GOAL_REMOVE,
      urlParams
    );
    this.assignToken(token);
    const callApi = this.callApi<CreatedIdentifier>(apiPath, REST_TYPES.POST, params);
    return sentry ? this.callSentry<CreatedIdentifier>(callApi, sentry) : callApi;
  }
}
