import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

import { theme } from '@styles/theme';
import { IColor } from '@styles/types';

interface ISpinnerProps {
  loading: boolean;
  size?: number;
  color?: IColor;
}

const DEFAULT_SIZE = 10;
const DEFAULT_COLOR = theme.color.neutral[80];

const Spinner: React.FC<ISpinnerProps> = React.memo(
  ({ loading, size = DEFAULT_SIZE, color = DEFAULT_COLOR }) => (
    <BeatLoader size={size} color={color} loading={loading} />
  )
);

export { Spinner };
