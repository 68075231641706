import { IUrlParams } from '@common-services/api/common/types/apiClient';
import { privateApi } from '@common-services/api/private';
import { TeamMemberList } from '@common-services/api/private/generated-from-backend/models';
import { IMembersParams } from '@common-services/api/private/types/members';

export const getTeamMembers = async (
  urlParams: IUrlParams<'teamId'>,
  params: IMembersParams,
  token: string
): Promise<TeamMemberList> => {
  return privateApi.members.getTeamMembers(urlParams, params, token, {
    message: `Cannot get team members for teamId ${urlParams.teamId}, accountId ${params.account} and recursive ${params.recursive}`,
  });
};

export * as MembersServices from './members';
