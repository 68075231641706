import React, { useEffect, useState } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';

import Footer from '@analytics-components/layout/Footer';
import Simple from '@analytics-pages/templates/Simple';
import { isFeatureEnabled, featuresList } from '@analytics-services/flags';
import { useUserContext } from '@common-context/User';

const Page = ({ headerDisabled, footerDisabled, children }) => {
  const location = useLocation();
  const routingParams = useParams();
  const {
    user,
    isReady,
    isAuthenticated,
    logout,
    isDemo,
    features,
    godMode,
    isGod,
    isExpired,
  } = useUserContext();
  const [prevPath, setPrevPath] = useState('/');
  const isMainPage = 'part' in routingParams;

  useEffect(() => {
    if (location.state?.from) {
      setPrevPath(location.state.from);
    }
  }, [location]);

  if (isDemo) {
    return (
      <DemoMode
        user={user}
        headerDisabled={headerDisabled}
        footerDisabled={footerDisabled}
        isMainPage={isMainPage}
      >
        {children}
      </DemoMode>
    );
  }

  if (isExpired && !isGod) {
    return <AuthenticatedExpired logout={logout} />;
  }

  if (!isAuthenticated) {
    return <NotAuthenticated isMainPage={isMainPage} />;
  }

  if (!user) {
    return <AuthenticatedWithoutUser logout={logout} />;
  }

  if (!user.defaultAccount) {
    return <AuthenticatedWithUserNoAccount logout={logout} />;
  }

  if (!isReady && location.pathname !== '/waiting') {
    return <Redirect to="/waiting" />;
  }

  if (
    isFeatureEnabled(featuresList.force_waiting_page, features) &&
    !godMode &&
    location.pathname !== '/waiting'
  ) {
    return <Redirect to="/waiting" />;
  }

  if (
    isFeatureEnabled(featuresList.force_maintenance_page, features) &&
    !isGod &&
    location.pathname !== '/maintenance'
  ) {
    window.location = '/maintenance';
  }

  return (
    <AuthenticatedWithUser
      user={user}
      headerDisabled={headerDisabled}
      footerDisabled={footerDisabled}
      isMainPage={isMainPage}
      isGod={isGod}
      prevPath={prevPath}
    >
      {children}
    </AuthenticatedWithUser>
  );
};

const NotAuthenticated = () => <Footer />;

const AuthenticatedWithoutUser = ({ logout }) => (
  <AuthenticationError
    message="Authentication error. Please logout and use an invitation link."
    logout={logout}
  />
);

const AuthenticatedExpired = ({ logout }) => (
  <AuthenticationError
    message="Your account has expired. Please contact our team to unlock your account."
    logout={logout}
  />
);

const AuthenticatedWithUserNoAccount = ({ logout }) => (
  <AuthenticationError
    message="Your user was not invited to any account. You should accept an invitation first."
    logout={logout}
  />
);

const AuthenticationError = ({ message, logout }) => (
  <Simple>
    <div>{message}</div>

    <button
      type="button"
      className="btn btn-xlarge btn-orange"
      onClick={() => logout({ returnTo: window.ENV.auth.logoutRedirectUri })}
    >
      Logout
    </button>
  </Simple>
);

const NoError = ({ footerDisabled, isMainPage, children }) => {
  return (
    <>
      <div className={isMainPage ? 'container-fluid px-0' : 'container mt-4'}>{children}</div>

      {!footerDisabled && <Footer />}
    </>
  );
};

const DemoMode = ({ user, headerDisabled, footerDisabled, isMainPage, children }) => (
  <NoError
    user={user}
    isDemo
    isMainPage={isMainPage}
    headerDisabled={headerDisabled}
    footerDisabled={footerDisabled}
    children={children}
  />
);

const AuthenticatedWithUser = ({
  user,
  headerDisabled,
  footerDisabled,
  isMainPage,
  isGod,
  prevPath,
  children,
}) => (
  <NoError
    user={user}
    headerDisabled={headerDisabled}
    footerDisabled={footerDisabled}
    isMainPage={isMainPage}
    isDemo={false}
    isGod={isGod}
    prevPath={prevPath}
    children={children}
  />
);

export default Page;
