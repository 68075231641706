import _ from 'lodash';
import React from 'react';

import DataWidget from '@analytics-context/DataWidget';
import { useFilters } from '@analytics-context/Filters';
import { filterJIRAStuff } from '@analytics-services/api/jira';
import { FAKE_USERNAME } from '@common-pages/Settings/constants';
import { dateTime } from '@common-services/dateService';
import { github } from '@common-services/format';
import { JiraServices, statusColors } from '@common-services/jiraService';
import {
  processPR,
  prLabel,
  PR_LABELS_CLASSNAMES as prLabelClasses,
  getPrIcon,
} from '@common-services/prHelpers';
import { icons } from '@lib/icon';

import IssuesTableDataBinder from './IssuesTableDataBinder';

const Issues = () => {
  const { contribsOptions } = useFilters();

  const fetcher = async (api, cachedData, apiContext) => {
    const {
      account,
      interval,
      excludeInactive,
      contributors,
      includeNullContributor,
      includeFakeContributor,
      issueTypes,
    } = apiContext;

    const jiraAssignees = _(contributors).map('jira_user').filter().value();
    if (includeNullContributor) {
      jiraAssignees.push(null);
    }
    if (includeFakeContributor) {
      jiraAssignees.push(FAKE_USERNAME);
    }

    const allIssueTypes = cachedData['jira-issue-types'].issue_types
      .filter((v) => ['epic', 'subtask'].indexOf(v.value) < 0)
      .map((v) => v.value);

    const data = await filterJIRAStuff(
      api,
      account,
      interval,
      excludeInactive,
      ['issues', 'issue_bodies'],
      {
        assignees: jiraAssignees,
      },
      issueTypes?.length ? issueTypes : allIssueTypes
    );

    return data;
  };

  const plumber = async (fetchedData, cachedData, apiContext) => {
    const getCreator = (participants) =>
      participants.find((p) => p.status.indexOf('author') >= 0)?.id;

    const priorities = cachedData['jira-priorities'].priorities;

    return fetchedData.issues.map((issue) => {
      const bugPriority = priorities.find((p) => p.name === issue.priority);
      const bugStatusStage = JiraServices.getStatusStage(
        issue.status,
        issue.project,
        cachedData['jira-statuses'].statuses
      );
      const leadTime = parseInt(issue.lead_time) * 1000;

      return {
        age: dateTime.ago(issue.created),
        assignee: issue.assignee,
        assigneeAvatar: JiraServices.getAvatar(issue.assignee, contribsOptions),
        comments: issue.comments,
        created: new Date(issue.created),
        id: issue.id,
        issueAvatar:
          JiraServices.getIssueTypeImage(issue.type, cachedData['jira-issue-types'].issue_types) ||
          icons.jira_task,
        leadTimeText: dateTime.human(leadTime),
        leadTimeValue: leadTime,
        priority: issue.priority,
        priorityImage: bugPriority?.image,
        priorityRank: bugPriority?.rank,
        reporter: issue.reporter,
        reporterAvatar: JiraServices.getAvatar(issue.reporter, contribsOptions),
        size: issue.prs?.length || 0,
        status: issue.status,
        statusColor: statusColors[bugStatusStage],
        statusStage: bugStatusStage,
        title: issue.title,
        type: issue.type,
        url: issue.url,
        subRows: _(issue.prs || [])
          .map((pr) => {
            const creator = getCreator(pr.participants);
            const prData = processPR({
              ...pr,
              stages_time_machine: pr.stages_now, // workaround over API not providing this field
            });
            const statusStage = prLabel('summary')(prData);

            return {
              age: dateTime.ago(pr.created),
              comments: pr.comments,
              creator: github.userName(creator),
              creatorAvatar: JiraServices.getAvatar(creator, contribsOptions),
              id: pr.number,
              prIcon: getPrIcon(prData.status),
              repo: github.repoName(pr.repository),
              sizeAdded: pr.size_added,
              sizeRemoved: pr.size_removed,
              status: statusStage,
              statusClassName: prLabelClasses[statusStage],
              title: pr.title,
              url: github.prLink(pr.repository, pr.number),
            };
          })
          .value(),
      };
    });
  };

  return (
    <DataWidget
      id="issues-table"
      fetcher={fetcher}
      plumber={plumber}
      prefetchedDataIds={[
        'avatars',
        'jira-identities',
        'jira-priorities',
        'jira-statuses',
        'jira-issue-types',
      ]}
    >
      <IssuesTableDataBinder />
    </DataWidget>
  );
};

export default Issues;
