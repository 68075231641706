import React from 'react';
import { CellProps, Renderer } from 'react-table';

import { IIssuesTableRow } from '@align-types/issues';
import { Icon, icons } from '@lib/icon';
import { theme } from '@styles/theme';

import { placeholderStyles, wrapperStyles } from './cellExpand.styles';

export const IssuesCellExpand: Renderer<CellProps<IIssuesTableRow>> = React.memo(({ row }) => {
  // @ts-ignore appears that react-table type definitions don't have onClick defined
  const { onClick } = row.getToggleRowExpandedProps();
  return (
    <div css={wrapperStyles}>
      {row.canExpand ? (
        <Icon
          color={theme.color.neutral['80']}
          icon={row.isExpanded ? icons.angle_down : icons.angle_right}
          size={12}
          onClick={onClick}
        />
      ) : (
        <div css={placeholderStyles} />
      )}
    </div>
  );
});
