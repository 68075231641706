import React from 'react';

import { Icon, icons } from '@lib/icon';
import { useTheme } from '@styles/hooks';

import { wrapperStyles } from './checkbox.styles';
import { ICheckbox } from './checkbox.types';

// TODO: DEV-6157 move to libs component repository
export const Checkbox: React.FC<ICheckbox> = React.memo(({ label, color, isChecked, onChange }) => {
  const theme = useTheme();

  return (
    <div css={wrapperStyles({ isChecked })} onClick={onChange}>
      <div className="checkbox">
        {isChecked && (
          <Icon icon={icons.jira_task} size={18} color={color || theme.color.neutral['60']} />
        )}
      </div>
      <label>{label}</label>
    </div>
  );
});
