/**
 * {{ title }}
 * Server version: {{ server_version }} built on {{ build_date }} @ [{{ commit }}](https://github.com/athenianco/athenian-api/commit/{{ commit }})  Origin GitHub repository: [athenianco/api-spec](https://github.com/athenianco/api-spec).  Authorization persists between sessions. 🛡️ means that the endpoint requires account admin privileges. 👑 means that the endpoint is only accessible to Athenian authorities. <details> <summary>List of common server errors.</summary> * __500__ endpoint crashed. We reported the incident to Sentry and will fix it soon! Please include the value of `instance` in the response if you contact the support. Repeating the request will not help, most probably. * __501__ some functions are missing, either because they are not implemented yet or a non-critical runtime dependency is not satisfied. Repeating the request will not help, guaranteed. * __502__ server crashed badly, either due to a memory access violation in native code or running out of memory. We reported this incident to Sentry and will fix it soon! Try repeating the request. * __503__ server has not fully launched yet, e.g. hasn't connected to the database; server is shutting down; we are experiencing a partial outage. Try repeating the request. * __504__ endpoint took too much time and was interrupted. We reported the incident to Sentry and will see how to improve the performance. Repeating the request will not help, most probably. </details>
 *
 * Contact: admin@athenian.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ReleasedPullRequest from './ReleasedPullRequest';

/**
 * The FilteredRelease model module.
 * @module model/FilteredRelease
 */
class FilteredRelease {
    /**
     * Constructs a new <code>FilteredRelease</code>.
     * Various information about a repository release.
     * @alias module:model/FilteredRelease
     * @param name {String} Title of the release.
     * @param repository {String} Name of the repository where the release exists.
     * @param url {String} Link to the release.
     * @param published {Date} When the release was created.
     * @param age {String} Time since the previous release /\\d+s/.
     * @param addedLines {Number} Cumulative number of lines inserted since the previous release.
     * @param deletedLines {Number} Cumulative number of lines removed since the previous release.
     * @param commits {Number} Number of commits since the previous release.
     * @param publisher {String} Login of the person who created the release.
     * @param commitAuthors {Array.<String>} List of released commit authors' logins.
     * @param prs {Array.<module:model/ReleasedPullRequest>} List of released pull requests.
     */
    constructor(name, repository, url, published, age, addedLines, deletedLines, commits, publisher, commitAuthors, prs) { 
        
        FilteredRelease.initialize(this, name, repository, url, published, age, addedLines, deletedLines, commits, publisher, commitAuthors, prs);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, name, repository, url, published, age, addedLines, deletedLines, commits, publisher, commitAuthors, prs) { 
        obj['name'] = name;
        obj['repository'] = repository;
        obj['url'] = url;
        obj['published'] = published;
        obj['age'] = age;
        obj['added_lines'] = addedLines;
        obj['deleted_lines'] = deletedLines;
        obj['commits'] = commits;
        obj['publisher'] = publisher;
        obj['commit_authors'] = commitAuthors;
        obj['prs'] = prs;
    }

    /**
     * Constructs a <code>FilteredRelease</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/FilteredRelease} obj Optional instance to populate.
     * @return {module:model/FilteredRelease} The populated <code>FilteredRelease</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new FilteredRelease();

            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('repository')) {
                obj['repository'] = ApiClient.convertToType(data['repository'], 'String');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
            if (data.hasOwnProperty('published')) {
                obj['published'] = ApiClient.convertToType(data['published'], 'Date');
            }
            if (data.hasOwnProperty('age')) {
                obj['age'] = ApiClient.convertToType(data['age'], 'String');
            }
            if (data.hasOwnProperty('added_lines')) {
                obj['added_lines'] = ApiClient.convertToType(data['added_lines'], 'Number');
            }
            if (data.hasOwnProperty('deleted_lines')) {
                obj['deleted_lines'] = ApiClient.convertToType(data['deleted_lines'], 'Number');
            }
            if (data.hasOwnProperty('commits')) {
                obj['commits'] = ApiClient.convertToType(data['commits'], 'Number');
            }
            if (data.hasOwnProperty('publisher')) {
                obj['publisher'] = ApiClient.convertToType(data['publisher'], 'String');
            }
            if (data.hasOwnProperty('commit_authors')) {
                obj['commit_authors'] = ApiClient.convertToType(data['commit_authors'], ['String']);
            }
            if (data.hasOwnProperty('prs')) {
                obj['prs'] = ApiClient.convertToType(data['prs'], [ReleasedPullRequest]);
            }
            if (data.hasOwnProperty('deployments')) {
                obj['deployments'] = ApiClient.convertToType(data['deployments'], ['String']);
            }
        }
        return obj;
    }


}

/**
 * Title of the release.
 * @member {String} name
 */
FilteredRelease.prototype['name'] = undefined;

/**
 * Name of the repository where the release exists.
 * @member {String} repository
 */
FilteredRelease.prototype['repository'] = undefined;

/**
 * Link to the release.
 * @member {String} url
 */
FilteredRelease.prototype['url'] = undefined;

/**
 * When the release was created.
 * @member {Date} published
 */
FilteredRelease.prototype['published'] = undefined;

/**
 * Time since the previous release /\\d+s/.
 * @member {String} age
 */
FilteredRelease.prototype['age'] = undefined;

/**
 * Cumulative number of lines inserted since the previous release.
 * @member {Number} added_lines
 */
FilteredRelease.prototype['added_lines'] = undefined;

/**
 * Cumulative number of lines removed since the previous release.
 * @member {Number} deleted_lines
 */
FilteredRelease.prototype['deleted_lines'] = undefined;

/**
 * Number of commits since the previous release.
 * @member {Number} commits
 */
FilteredRelease.prototype['commits'] = undefined;

/**
 * Login of the person who created the release.
 * @member {String} publisher
 */
FilteredRelease.prototype['publisher'] = undefined;

/**
 * List of released commit authors' logins.
 * @member {Array.<String>} commit_authors
 */
FilteredRelease.prototype['commit_authors'] = undefined;

/**
 * List of released pull requests.
 * @member {Array.<module:model/ReleasedPullRequest>} prs
 */
FilteredRelease.prototype['prs'] = undefined;

/**
 * Deployments with this release.
 * @member {Array.<String>} deployments
 */
FilteredRelease.prototype['deployments'] = undefined;






export default FilteredRelease;

