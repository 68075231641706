import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import owl from '@assets/images/logos/logo-owl.svg';

// Progress Status
export const PROGRESS_NOT_READY = 'not-ready';
export const PROGRESS_WAITING_INSTALLATION = 'waiting';
export const PROGRESS_DOWNLOADING = 'downloading';
export const PROGRESS_FINISHED = 'finished';
export const PROGRESS_FAILED = 'failed';

const Progress = ({ progress }) => {
  let amount = null;
  let text;
  switch (progress.status) {
    case PROGRESS_WAITING_INSTALLATION:
      text = 'Waiting the Athenian GitHub App installation...';
      break;
    case PROGRESS_DOWNLOADING:
      text = 'Loading your data...';
      amount = parseInt(progress.value);
      break;
    case PROGRESS_FINISHED:
      text = 'Loading complete.';
      amount = 100;
      break;
    case PROGRESS_FAILED:
    default:
      amount = parseInt(progress.value);
      text = 'The installation process failed.';
      break;
  }

  return (
    <div className="card waiting-progress border-top-0 pl-2 px-4 pr-5 py-5">
      <div className="card-body d-flex p-0">
        <div className="icons">
          <div className="left">
            <FontAwesomeIcon icon={faGithub} />
          </div>
          <div className="right">
            <div className="arrow" />
            <img src={owl} className="app-logo owl mx-2" alt="" />
          </div>
        </div>
        <div className="flex-fill text-dark ml-5 font-weight-bold">
          <div>
            {text}
            {amount !== null && <span className="text-secondary ml-2">{amount}%</span>}
          </div>
          <div className="progress mt-2">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${amount || 0}%` }}
              aria-valuenow={amount || 0}
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Progress;
