import React from 'react';
import { useExpanded, usePagination, useSortBy, useTable } from 'react-table';

import TablePagination from '@analytics-components/tables/TablePagination';
import { TableFilter } from '@analytics-components/tables/table-filter';
import { TableSearch } from '@analytics-components/tables/table-search';
import { IDeploymentsRow } from '@analytics-pages/Tables/deployments/deployments.types';
import { Table } from '@lib/Table';
import { Icon, icons } from '@lib/icon';
import { Tooltip } from '@lib/tooltip';

import { columns } from './columns';
import {
  filtersStyles,
  searchStyles,
  tableBodyStyles,
  tableHeaderStyles,
} from './deploymentsTable.styles';
import { IDeploymentsTable } from './deploymentsTable.types';

const searchHint = (
  <div>
    You can search by deployments status (Successful / Failing), PR's title, repository or author,
    or use the following syntax for filtering:
    <br />
    <b>by PR author:</b> <span className="code">author:user_handler</span>
    <br />
    <b>by PR title:</b> <span className="code">title:user_handler</span>
    <br />
    <b>by repository:</b> <span className="code">repository:user_handler</span>
  </div>
);

const DeploymentsTable: React.FC<IDeploymentsTable> = React.memo(
  ({
    currentFilter,
    deployments,
    filterOptions,
    filterPlaceholder,
    searchedString,
    onFilter,
    onSearch,
  }) => {
    const {
      getTableProps,
      getTableBodyProps,
      gotoPage,
      headerGroups,
      nextPage,
      page,
      pageCount,
      prepareRow,
      previousPage,
      rows,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable<IDeploymentsRow>(
      {
        autoResetExpanded: false, // this prop will preserve expanded rows from collapsing when data changes
        columns,
        data: deployments,
        initialState: { pageIndex: 0 },
        paginateExpandedRows: false,
      },
      useSortBy,
      useExpanded,
      usePagination
    );
    page.forEach(prepareRow);

    return (
      <>
        <div css={filtersStyles}>
          <div css={searchStyles}>
            <TableSearch placeholder="Search..." value={searchedString} onFilter={onSearch} />
            <Tooltip className="info-icon" content={searchHint}>
              <Icon icon={icons.check_failed} size={18} />
            </Tooltip>
          </div>
          <TableFilter
            currentValue={currentFilter}
            options={filterOptions}
            placeholder={filterPlaceholder}
            setCurrentValue={onFilter}
          />
        </div>
        <Table
          rows={page}
          headerGroups={headerGroups}
          tableProps={getTableProps()}
          tableBodyProps={getTableBodyProps()}
          extraCSS={{
            header: tableHeaderStyles,
            body: tableBodyStyles,
          }}
        />
        <TablePagination
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          total={rows.length}
          gotoPage={gotoPage}
          gotoNextPage={nextPage}
          gotoPrevPage={previousPage}
          setPageSize={setPageSize}
        />
      </>
    );
  }
);

export { DeploymentsTable };
