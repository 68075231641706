export interface ContributorOption {
  label: string;
  options: Contributor[];
}

export interface Contributor {
  group: string;
  login: string;
  name: string;
  avatar: string;
  team?: string;
  jira_user?: string;
  email?: string;
}

export function getUsersWithGroups(
  options: ContributorOption[],
  selectedGroupNames: string[]
): Contributor[] {
  return options
    .filter((option) => selectedGroupNames.includes(option.label))
    .reduce((res, option) => {
      const removedRepeated = option.options.filter(
        (intOption) =>
          !res.some(
            (resOption) =>
              `${intOption.team} ${intOption.login}` === `${resOption.team} ${resOption.login}`
          )
      );
      return [...res, ...removedRepeated];
    }, []);
}

export * as MultiSelectReducerService from './reducer';
