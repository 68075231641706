import { useMemo } from 'react';

import { useSearchedIssues } from '@align-hooks/useSearchedIssues';
import { useCurrentTeam } from '@align-hooks/useTeams';
import { DateService as AlignDateService } from '@align-services/dateService';
import { IDateRangeInMls, PrsIssuesTableTabs } from '@align-types/common';
import { IUseGetSearchedIssues } from '@align-types/issues';
import {
  DashboardChart,
  SearchJIRAIssuesOrderByExpression,
  SearchJIRAIssuesRequest,
} from '@common-services/api/private/generated-from-backend/models';
import { useGetAccountDetails } from '@common-services/api/public/hooks/useGetAccountData';
import { DateService } from '@common-services/dateService';

import { IChartConfig } from '../types';

export function useGetSearchedIssues(
  accountId: number,
  chartParams: DashboardChart,
  metric: IChartConfig,
  activeTab: PrsIssuesTableTabs,
  selectedRange: IDateRangeInMls
): IUseGetSearchedIssues {
  const currentTeam = useCurrentTeam();

  const isEndDatePassed = useMemo(() => AlignDateService.isBeforeToday(chartParams?.date_to), [
    chartParams,
  ]);

  const { data: accountDetailsData, isLoading: accountDetailsLoading } = useGetAccountDetails(true);

  const isJiraDataInstalled = useMemo(() => {
    if (accountDetailsData?.datasources) return accountDetailsData?.datasources.includes('jira');
  }, [accountDetailsData]);

  const { data, isLoading: searchedIssuesIsLoading, error } = useSearchedIssues(
    {
      account: accountId,
      date_from: selectedRange
        ? DateService.formatDate(selectedRange.dateFrom, 'YYYY-MM-DD')
        : chartParams?.date_from,
      date_to: selectedRange
        ? DateService.formatDate(selectedRange.dateTo, 'YYYY-MM-DD')
        : chartParams?.date_to,
      filter: {
        issue_types: chartParams?.filters?.jira?.issue_types || [],
        projects: chartParams?.filters?.jira?.projects || [],
        priorities: chartParams?.filters?.jira?.priorities || [],
        // @ts-ignore the field in chartParams should be called "labels_include", but it was intentionally renamed by DEV-6424
        labels_include: chartParams?.filters?.jira?.labels || [],
        ...(activeTab === PrsIssuesTableTabs.IN_PROGRESS
          ? {
              status_categories:
                (metric?.inProgressStages as SearchJIRAIssuesRequest['filter']['status_categories']) ||
                [],
            }
          : {}),
      },
      with: {
        assignees: [`{${currentTeam?.id}}`],
      },
      order_by: [
        {
          field: (activeTab === PrsIssuesTableTabs.COMPLETED
            ? metric?.completedOrderBy
            : metric?.inProgressOrderBy) as SearchJIRAIssuesOrderByExpression['field'],
          direction:
            activeTab === PrsIssuesTableTabs.IN_PROGRESS && metric?.inProgressOrderAscending
              ? 'ascending'
              : 'descending',
        },
      ],
    },
    true,
    (activeTab === PrsIssuesTableTabs.IN_PROGRESS && isEndDatePassed) || // do not fetch issues for In Progress tab if the end date has passed
      !accountDetailsData ||
      !isJiraDataInstalled ||
      !metric?.jiraMetricField
  );
  const isLoading = useMemo(() => {
    return searchedIssuesIsLoading || accountDetailsLoading;
  }, [searchedIssuesIsLoading, accountDetailsLoading]);
  return { data, isLoading, error };
}
