import React from 'react';

import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import Panel from '@analytics-components/insights/Panel';
import { prepareChartBoxes } from '@analytics-pages/delivery-pipeline/insights/helper';

import prSizeReviewTime from './prSizeReviewTime';

const meta = {
  title: 'Review Time',
  description:
    'Quantify how much large pull requests slow down your review process. <p class="small text-secondary mt-1 mb-0">Calculated up to the 95th percentile.</p>',
};

const chartBoxes = [
  {
    id: 'pr-size-review-time',
    component: ChartBoxWithKPIWidget,
    params: prSizeReviewTime,
  },
];

const ReviewTimePanel = () => {
  const preparedChartBoxes = prepareChartBoxes(chartBoxes);
  return <Panel meta={meta} chartBoxes={preparedChartBoxes} />;
};

export default ReviewTimePanel;
