import React from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';

import TablePagination from '@analytics-components/tables/TablePagination';
import { TableFilter } from '@analytics-components/tables/table-filter';
import { TableSearch } from '@analytics-components/tables/table-search';
import { Table } from '@lib/Table';
import { prTableColumns } from '@lib/Table/components/pr-table/prTable.columns';
import { IPrsTableRow } from '@lib/Table/components/pr-table/prTable.types';
import { Icon, icons } from '@lib/icon';
import { Tooltip } from '@lib/tooltip';

import {
  filtersStyles,
  searchStyles,
  tableBodyStyles,
  tableHeaderStyles,
} from './pullRequestsTable.styles';
import { IPrsTableProps } from './pullRequestsTable.types';

const searchHint = (
  <div>
    You can search by pull request title, repository or participant, or use this other syntax for
    these other filters:
    <br />
    <b>by status:</b> <span className="code">status:merged</span>,{' '}
    <span className="code">status:closed</span>, <span className="code">status:opened</span>
    <br />
    <b>by number:</b> <span className="code">number:354</span>
    <br />
    <b>by author:</b> <span className="code">author:user_handler</span>
    <br />
    <b>by reviewer:</b> <span className="code">reviewer:user_handler</span>
  </div>
);

const PullRequestsTable: React.FC<IPrsTableProps> = React.memo(
  ({
    data,
    currentStatus,
    filterOptions,
    filterPlaceholder,
    isLoading,
    searchedString,
    onFilter,
    onSearch,
  }) => {
    const {
      getTableProps,
      getTableBodyProps,
      gotoPage,
      headerGroups,
      nextPage,
      page,
      pageCount,
      prepareRow,
      previousPage,
      rows,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable<IPrsTableRow>(
      {
        columns: prTableColumns,
        data,
        initialState: { pageIndex: 0 },
      },
      useSortBy,
      usePagination
    );
    page.forEach(prepareRow);

    return (
      <>
        <div css={filtersStyles}>
          <div css={searchStyles}>
            <TableSearch
              isDisabled={isLoading}
              placeholder="Search..."
              value={searchedString}
              onFilter={onSearch}
            />
            <Tooltip className="info-icon" content={searchHint}>
              <Icon icon={icons.check_failed} size={18} />
            </Tooltip>
          </div>
          <TableFilter
            currentValue={currentStatus}
            isLoading={isLoading}
            options={filterOptions}
            placeholder={filterPlaceholder}
            setCurrentValue={onFilter}
          />
        </div>
        <Table
          rows={page}
          headerGroups={headerGroups}
          tableProps={getTableProps()}
          tableBodyProps={getTableBodyProps()}
          extraCSS={{
            header: tableHeaderStyles,
            body: tableBodyStyles,
          }}
        />
        {!isLoading && (
          <TablePagination
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            total={rows.length}
            gotoPage={gotoPage}
            gotoNextPage={nextPage}
            gotoPrevPage={previousPage}
            setPageSize={setPageSize}
          />
        )}
      </>
    );
  }
);

export { PullRequestsTable };
