import React from 'react';

import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import Panel from '@analytics-components/insights/Panel';
import { prepareChartBoxes } from '@analytics-pages/delivery-pipeline/insights/helper';

import mttaBugsPerPriority from './mttaBugsPerPriority';

const meta = {
  title: 'Mean Time To Acknowledge',
  description:
    'Average time from the bug creation till work begins on the issue: earliest timestamp between the transition to In Progress and the 1st commit of the associated pull request.',
};

const chartBoxes = [
  {
    id: 'mtta-bugs-per-priority',
    component: ChartBoxWithKPIWidget,
    params: mttaBugsPerPriority,
  },
];

const MTTAcknowledgePanel = () => {
  const preparedChartBoxes = prepareChartBoxes(chartBoxes);
  return <Panel meta={meta} chartBoxes={preparedChartBoxes} />;
};

export default MTTAcknowledgePanel;
