import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { useOnClickOutside } from '@common-hooks/useOnClickOutside';

import ModalUI from './ui';

const Modal = ({
  content,
  isOpen,
  portalId = null,
  primaryButtonText,
  secondaryButtonText,
  title,
  onPrimaryClick,
  onSecondaryClick,
  setOpen,
}) => {
  const ref = useRef(null);
  const [element, setElement] = useState();

  useOnClickOutside([ref], () => setOpen(false));

  useEffect(() => {
    if (portalId) {
      setElement(document.getElementById(portalId));
    }
  }, [portalId]);

  const handlePrimaryClick = useCallback(
    (e) => {
      e.stopPropagation();
      onPrimaryClick();
      setOpen(false);
    },
    [onPrimaryClick]
  );

  const handleSecondaryClick = useCallback(
    (e) => {
      e.stopPropagation();
      if (onSecondaryClick) onSecondaryClick();
      setOpen(false);
    },
    [onSecondaryClick]
  );

  const ui = (
    <>
      {isOpen && (
        <ModalUI
          content={content}
          primaryButtonText={primaryButtonText}
          ref={ref}
          secondaryButtonText={secondaryButtonText}
          title={title}
          withPortal={!!portalId}
          onPrimaryClick={handlePrimaryClick}
          onSecondaryClick={handleSecondaryClick}
        />
      )}
    </>
  );

  return element ? createPortal(ui, element) : ui;
};

export default Modal;
