import { useTheme } from '@emotion/react';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { RadialChart } from 'react-vis';

import { BigText, onValueChange, onValueReset } from '@analytics-components/charts/Tooltip';
import { RING_CHART_THRESHOLD } from '@common-pages/Settings/constants';
import { rotate } from '@utils/colors';

const RingChart = ({ data, params = {} }) => {
  const theme = useTheme();

  const [currentHover, setCurrentHover] = useState(null);
  const size = params.size || 250;
  const total = useMemo(() => data.reduce((acc, v) => acc + v.value, 0), [data]);

  let cumSum = 0;
  const additionalValue = {
    color: theme.color.neutral[60],
    label: [],
    percentage: 0,
    value: 0,
  };

  const values = useMemo(() => {
    if (params.hasOtherSection) {
      const dataWithValue = _(data)
        .filter((v) => v.value)
        .sortBy(['value', 'label'])
        .reverse()
        .value();

      const result = dataWithValue.reduce((acc, v, i) => {
        const percentage = v.value / (total || 1);
        if (
          (cumSum <= RING_CHART_THRESHOLD / 100 || i === dataWithValue.length - 1) &&
          additionalValue.value === 0
        ) {
          acc.push({
            ...v,
            percentage,
            color: v.color || rotate(params.colors || theme.color.sets.six, i),
          });
        } else {
          // group items that exceeded a threshold into one element
          additionalValue.label.push(v.label);
          additionalValue.percentage += percentage;
          additionalValue.value += v.value;
        }
        cumSum += percentage;
        return acc;
      }, []);

      // if there were items that exceeded a threshold add them to values list as one element
      if (!!additionalValue.value) {
        result.push(additionalValue);
      }

      return result;
    } else {
      return _(data)
        .map((v, i) => ({
          ...v,
          percentage: v.value / (total || 1),
          color: v.color || rotate(params.colors || theme.color.sets.six, i),
        }))
        .filter((v) => v.value > 0)
        .value();
    }
  }, [data, params]);

  const handleMouseLeave = useCallback(() => onValueReset(setCurrentHover), []);

  const handleValueMouseOver = useCallback(
    (datapoint) => onValueChange(datapoint, currentHover, setCurrentHover),
    [currentHover]
  );

  const handleValueMouseOut = useCallback(() => {
    if (!params?.tooltip?.persistent) {
      onValueReset(setCurrentHover);
    }
  }, [params]);

  const alignConf = useMemo(
    () =>
      currentHover?.x > 0
        ? {
            horizontal: 'right',
            vertical: currentHover?.y > 0 ? 'top' : 'bottom',
          }
        : {
            horizontal: 'left',
            vertical: currentHover?.y > 0 ? 'top' : 'bottom',
          },
    [currentHover]
  );

  const INNER_PADDING = 2; // it avoids an aliassing issue.
  const radius = size / 2 - INNER_PADDING;
  const innerRadius = radius - 10;
  const { component: TooltipComponent, ...tooltipProps } = params.tooltip || {};

  return (
    <div
      onMouseLeave={handleMouseLeave}
      onClick={handleMouseLeave}
      style={{
        background: 'white',
        width: size,
      }}
    >
      <div
        className="donut-chart d-flex justify-content-between align-items-center"
        style={{
          position: 'relative',
          height: size,
          textAlign: 'center',
        }}
      >
        <RadialChart
          width={size}
          height={size}
          data={values}
          innerRadius={innerRadius}
          radius={radius}
          getAngle={(v) => v.value}
          animation="stiff"
          colorType="literal"
          className="chart"
          padAngle={0.01}
          onValueMouseOver={handleValueMouseOver}
          onValueMouseOut={handleValueMouseOut}
          style={{
            position: 'relative',
          }}
        >
          {TooltipComponent && (
            <TooltipComponent
              align={alignConf}
              value={currentHover}
              dataPoint={currentHover}
              {...tooltipProps}
            />
          )}
        </RadialChart>
        <span
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: '50%',
            margin: 'auto',
            transform: 'translateY(-50%)',
          }}
        >
          <BigText
            content={params.labels?.primary}
            extra={params.labels?.secondary}
            newline={true}
            encloseExtra={false}
            isXL={true}
          />
        </span>
      </div>
      <Legend
        data={
          params.legend && params.legend.n
            ? (params.legend.reverse ? [...values].reverse() : values).slice(0, params.legend.n)
            : values
        }
      />
    </div>
  );
};

const Legend = ({ data }) => {
  if (12 % data.length > 0) {
    return null;
  }

  const colsPerItem = 12 / data.length;
  const className = `col-md-${colsPerItem}`;
  return (
    <div className="row mx-0 mt-5">
      {data.map((v, i) => (
        <div key={i} className={`${className} d-flex justify-content-center text-center`}>
          <LegendItem label={v.label} value={v.value} color={v.color} link={v.link} />
        </div>
      ))}
    </div>
  );
};

const LegendItem = ({ label, value, color, link }) => {
  const title = link ? (
    <a href={link} className="d-inline mt-0" rel="noreferrer" target="_blank">
      <span
        className="text-secondary d-block text-truncate font-weight-normal text-xs"
        css={{ ':hover': { textDecoration: 'underline' } }}
      >
        {label}
      </span>
    </a>
  ) : (
    <span
      className="text-secondary d-block text-truncate font-weight-normal text-xs"
      css={{ textTransform: 'uppercase' }}
    >
      {label}
    </span>
  );
  return (
    <div className="w-100">
      {title}

      <div>
        <span className="big-number font-weight-bold d-inline-block align-middle text-dark text-lg">
          <Circle radius={5} fill={color} />
          {value}
        </span>
      </div>
    </div>
  );
};

const Circle = ({ radius, fill }) => (
  <svg
    style={{
      display: 'inline-block',
      width: `${radius * 2}px`,
      height: `${radius * 2}px`,
      marginRight: `${radius}px`,
      marginBottom: `${radius}px`,
    }}
  >
    <circle cx={radius} cy={radius} r={radius} fill={fill} />
  </svg>
);

export default RingChart;
