export enum ResponseStatusType {
  FAIL = 'fail',
  SUCCESS = 'success',
}

export interface IHealthStatus {
  error: boolean;
  report: string;
}

export interface IResponseHealthLogItem {
  id: string;
  status: ResponseStatusType;
  statusCode: number;
  date: string;
}

export interface IResponseHealthParams {
  targetCodes: number[];
  statusGroups: IGroupByStatus;
}

export interface IGetHealthParams {
  ratios: ICalculateRatios;
  itemCount: number;
  dateRange: {
    from: string;
    to: string;
  };
}

export interface IUpdateResponseHealthCache {
  updatedLogs: IResponseHealthLogItem[];
}

export interface IRatios {
  general: number;
  grouped: number;
}

export interface ICalculateRatios {
  overall: IRatios;
  targeted: {
    [key: string]: IRatios;
  };
}

export interface IResponseGroupByRetryId {
  [key: string]: IResponseHealthLogItem[];
}

export interface IGroupByStatusResponse {
  success: number;
  fail: number;
  retryGroupsCount: number;
}

export interface IGroupByStatus {
  [key: string]: IGroupByStatusResponse;
}
