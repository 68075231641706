import { ChartParamsType, INewChartParam } from '@align-pages/dashboard/types';

export enum PropertyReducerTypes {
  ADD_NEW = 'add',
  ADD_EXISTING = 'add_existing',
  UPDATE = 'update',
  REMOVE = 'remove',
  CLEAR_PARAM = 'clear_param',
}

export interface IPropertyReducerAction {
  type: PropertyReducerTypes;
  paramType?: ChartParamsType;
  data?: INewChartParam | INewChartParam[];
}
