import { useEffect, useState } from 'react';

export function useActionMenu() {
  const [shouldCloseMenu, setShouldCloseMenu] = useState(false);

  useEffect(() => {
    if (shouldCloseMenu) {
      setShouldCloseMenu(false);
    }
  }, [shouldCloseMenu]);

  return { shouldCloseMenu, setShouldCloseMenu };
}
