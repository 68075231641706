/**
 * {{ title }}
 * Server version: {{ server_version }} built on {{ build_date }} @ [{{ commit }}](https://github.com/athenianco/athenian-api/commit/{{ commit }})  Origin GitHub repository: [athenianco/api-spec](https://github.com/athenianco/api-spec).  Authorization persists between sessions. 🛡️ means that the endpoint requires account admin privileges. 👑 means that the endpoint is only accessible to Athenian authorities. <details> <summary>List of common server errors.</summary> * __500__ endpoint crashed. We reported the incident to Sentry and will fix it soon! Please include the value of `instance` in the response if you contact the support. Repeating the request will not help, most probably. * __501__ some functions are missing, either because they are not implemented yet or a non-critical runtime dependency is not satisfied. Repeating the request will not help, guaranteed. * __502__ server crashed badly, either due to a memory access violation in native code or running out of memory. We reported this incident to Sentry and will fix it soon! Try repeating the request. * __503__ server has not fully launched yet, e.g. hasn't connected to the database; server is shutting down; we are experiencing a partial outage. Try repeating the request. * __504__ endpoint took too much time and was interrupted. We reported the incident to Sentry and will see how to improve the performance. Repeating the request will not help, most probably. </details>
 *
 * Contact: admin@athenian.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CalculatedLinearMetricValues from './CalculatedLinearMetricValues';
import JIRAFilterWith from './JIRAFilterWith';

/**
 * The CalculatedJIRAMetricValues model module.
 * @module model/CalculatedJIRAMetricValues
 */
class CalculatedJIRAMetricValues {
    /**
     * Constructs a new <code>CalculatedJIRAMetricValues</code>.
     * Calculated JIRA metrics for a specific granularity.
     * @alias module:model/CalculatedJIRAMetricValues
     * @param granularity {String} How often the metrics are reported. The value must satisfy the following regular expression: /^all|(([1-9]\\d* )?(aligned )?(day|week|month|year))$/. \"all\" produces a single interval [`date_from`, `date_to`]. \"aligned week/month/year\" produces intervals cut by calendar week/month/year borders, for example, when `date_from` is `2020-01-15` and `date_to` is `2020-03-10`, the intervals will be `2020-01-15` - `2020-02-01` - `2020-03-01` - `2020-03-10`.
     * @param values {Array.<module:model/CalculatedLinearMetricValues>} The sequence steps from `date_from` till `date_to` by `granularity`.
     */
    constructor(granularity, values) { 
        
        CalculatedJIRAMetricValues.initialize(this, granularity, values);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, granularity, values) { 
        obj['granularity'] = granularity;
        obj['values'] = values;
    }

    /**
     * Constructs a <code>CalculatedJIRAMetricValues</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CalculatedJIRAMetricValues} obj Optional instance to populate.
     * @return {module:model/CalculatedJIRAMetricValues} The populated <code>CalculatedJIRAMetricValues</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CalculatedJIRAMetricValues();

            if (data.hasOwnProperty('granularity')) {
                obj['granularity'] = ApiClient.convertToType(data['granularity'], 'String');
            }
            if (data.hasOwnProperty('jira_label')) {
                obj['jira_label'] = ApiClient.convertToType(data['jira_label'], 'String');
            }
            if (data.hasOwnProperty('with')) {
                obj['with'] = JIRAFilterWith.constructFromObject(data['with']);
            }
            if (data.hasOwnProperty('values')) {
                obj['values'] = ApiClient.convertToType(data['values'], [CalculatedLinearMetricValues]);
            }
        }
        return obj;
    }


}

/**
 * How often the metrics are reported. The value must satisfy the following regular expression: /^all|(([1-9]\\d* )?(aligned )?(day|week|month|year))$/. \"all\" produces a single interval [`date_from`, `date_to`]. \"aligned week/month/year\" produces intervals cut by calendar week/month/year borders, for example, when `date_from` is `2020-01-15` and `date_to` is `2020-03-10`, the intervals will be `2020-01-15` - `2020-02-01` - `2020-03-01` - `2020-03-10`.
 * @member {String} granularity
 */
CalculatedJIRAMetricValues.prototype['granularity'] = undefined;

/**
 * Title of the assigned JIRA label, if `group_by_jira_label` was previously set to `true`. If is missing and `group_by_jira_label` was set to `true` then the metrics are calculated for issues without labels.
 * @member {String} jira_label
 */
CalculatedJIRAMetricValues.prototype['jira_label'] = undefined;

/**
 * @member {module:model/JIRAFilterWith} with
 */
CalculatedJIRAMetricValues.prototype['with'] = undefined;

/**
 * The sequence steps from `date_from` till `date_to` by `granularity`.
 * @member {Array.<module:model/CalculatedLinearMetricValues>} values
 */
CalculatedJIRAMetricValues.prototype['values'] = undefined;






export default CalculatedJIRAMetricValues;

