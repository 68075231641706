import React from 'react';

import { GoalViewComponent } from '@align-pages/goals/single/components/goal-view-component';
import { GoalsSingleProvider } from '@align-pages/goals/single/components/goals-single-context/goalsSingleContext';

const GoalsSingleView: React.FC = React.memo(() => {
  return (
    <GoalsSingleProvider>
      <GoalViewComponent />
    </GoalsSingleProvider>
  );
});

export { GoalsSingleView };
