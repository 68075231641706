import { css } from '@emotion/react';

const getStyle = ({ spacing, color, radius, transition, shadow }) => css`
  width: 100%;
  padding: ${spacing.gap['04']};
  background: ${color.neutral['40']};

  & > div.value-container {
    border: 1px solid ${color.neutral['40']};
    display: flex;
    flex-direction: row;
    align-items: center;
    background: ${color.neutral.white};
    min-height: 40px;
    border-radius: ${radius.default};
    padding: ${spacing.gap['04']};
    transition: ${transition.default};

    &:hover,
    &:active {
      border: 1px solid ${color.neutral['60']};
    }

    &:focus,
    &:active {
      box-shadow: ${shadow.focus};
    }
  }
`;

export default getStyle;
