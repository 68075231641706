import { css } from '@emotion/react';

export const wrapperStyles = ({ color, spacing, radius }) => css`
  background-color: ${color.neutral[20]};
  border: 1px solid ${color.neutral[40]};
  border-radius: ${radius.default};

  .accordion-row {
    padding: ${spacing.gap['03']} ${spacing.gap['10']};
    border-top: 1px solid ${color.neutral[40]};
    border-bottom: 0;
  }
`;

export const headerStyles = ({ color, spacing }) => css`
  padding: ${spacing.gap['05']};

  .content-wrapper {
    display: inline-grid;
    grid-template-columns: 32.5px auto;
    grid-template-rows: 100%;
    width: 100%;
    align-items: center;
    cursor: pointer;
  }
`;

export const expandableStyles = (isOpen) => ({ color, radius }) => css`
  background-color: ${color.neutral.white};
  border-top: 1px solid ${color.neutral[40]};
  display: ${isOpen ? 'block' : 'none'};

  &:last-child {
    border-radius: 0 0 ${radius.default} ${radius.default};
    border-top: 0;
  }
`;
