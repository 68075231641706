import React, { useMemo } from 'react';

import { metricsConfig } from '@align-constants';
import { BarChart } from '@align-pages/goals/single/components/goal-summary//components/bar-chart';
import { ChartDonut } from '@align-pages/goals/single/components/goal-summary/components/chart-donut';
import { LineChart } from '@align-pages/goals/single/components/goal-summary/components/line-chart';

import { GoalKPIs } from '../goal-kpis';
import { wrapperStyles } from './goalSummary.styles';
import { IGoalSummary } from './goalSummary.types';

export const GoalSummary: React.FC<IGoalSummary> = React.memo(
  ({ goal, metricsValues, templateMetric, onPointSelect }) => {
    const isTLO = useMemo(() => !!goal?.metric_params?.threshold, [goal]);

    return (
      <div css={wrapperStyles}>
        <div className="left-side">
          <ChartDonut goal={goal} />
          <GoalKPIs metricConfig={metricsConfig[templateMetric]} metricsValues={metricsValues} />
        </div>
        <div className="right-side">
          {isTLO ? (
            <BarChart goal={goal} templateMetric={templateMetric} onSelect={onPointSelect} />
          ) : (
            <LineChart goal={goal} templateMetric={templateMetric} onSelect={onPointSelect} />
          )}
        </div>
      </div>
    );
  }
);
