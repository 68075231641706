import { useTheme } from '@emotion/react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useExtendedState, useMeasure } from '@analytics-hooks';
import { useOnClickOutside } from '@common-hooks/useOnClickOutside';
import { optionType } from '@lib/Selector/types';

import DropdownSelectorUI from './ui';

const areDifferent = (set1, set2) =>
  !_.isEqual(_(set1).map('value').sortBy().value(), _(set2).map('value').sortBy().value());

const defaultNoCheckboxesTagLabelRender = (group, selected) =>
  `${selected?.length} ${!!group ? `(${group})` : '(no group)'}`;

const DropdownSelector = ({
  disabled = false,
  initialValue = [],
  options,
  placeholder,
  withAllOption = false,
  noAvatar = false,
  noCheckboxes = false,
  noCheckboxesTagLabelRender = null,
  useStorage,
  onLoadFromStorage,
  shouldRenderOption,
  storageKey,
  onChange,
}) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [applied, setApplied] = useExtendedState([], {
    useStorage,
    storageKey,
  });
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (useStorage) {
      setSelected(applied);
      if (onLoadFromStorage) {
        onLoadFromStorage(applied);
      }
    }
  }, []);

  useEffect(() => {
    if (initialValue?.length) {
      setSelected(initialValue);
      setApplied(initialValue);
    }
  }, []);

  const [fieldWrapperRef, { width }] = useMeasure();

  const selectorRef = useRef(null);
  const inputRef = useRef(null);

  const onApply = useCallback(
    ({ applied: newApplied }) => {
      const changed = areDifferent(applied, newApplied);
      setSelected(newApplied);
      if (changed) {
        setApplied(newApplied);
        if (onChange) {
          onChange(newApplied);
        }
      }
      setIsOpen(false);
    },
    [applied, onChange]
  );

  useOnClickOutside([fieldWrapperRef, selectorRef], () => onApply({ applied: selected }));

  const onClick = useCallback(() => {
    if (!disabled) {
      if (!inputValue) {
        if (noCheckboxes) {
          setIsOpen(document.activeElement === inputRef.current);
        } else {
          setIsOpen(!isOpen);
        }
      } else if (!isOpen) {
        setIsOpen(true);
      }
    }
  }, [disabled, isOpen, inputValue, noCheckboxes]);

  const onCancel = useCallback(() => setIsOpen(false), []);

  const onInputChange = useCallback(
    (value) => {
      setInputValue(value);
      if (!isOpen) {
        setIsOpen(true);
      }
    },
    [isOpen]
  );

  const onSelectedChange = useCallback(({ selected }) => {
    setSelected(selected);
    setInputValue('');
  }, []);

  const updatedSelection = areDifferent(selected, applied);
  const noCheckboxesTagLabelRenderFunc =
    noCheckboxesTagLabelRender || defaultNoCheckboxesTagLabelRender;

  return (
    <DropdownSelectorUI
      disabled={disabled}
      isOpen={isOpen}
      options={options}
      placeholder={placeholder}
      inputValue={inputValue}
      ref={{ fieldRef: { wrapperRef: fieldWrapperRef, inputRef }, selectorRef }}
      theme={theme}
      selected={selected}
      applied={applied}
      width={width}
      withAllOption={withAllOption}
      noAvatar={noAvatar}
      noCheckboxes={noCheckboxes}
      updatedSelection={updatedSelection}
      noCheckboxesTagLabelRender={noCheckboxesTagLabelRenderFunc}
      shouldRenderOption={shouldRenderOption}
      onSelectedChange={onSelectedChange}
      onApply={onApply}
      onCancel={onCancel}
      onClick={onClick}
      onInputChange={onInputChange}
    />
  );
};

const DropdownSelectorPropTypes = {
  disabled: PropTypes.bool,
  initialValue: PropTypes.arrayOf(optionType),
  options: PropTypes.arrayOf(optionType).isRequired,
  placeholder: PropTypes.string,
  withAllOption: PropTypes.bool,
  noAvatar: PropTypes.bool,
  noCheckboxes: PropTypes.bool,
  onChange: PropTypes.func,
  shouldRenderOption: PropTypes.func,
  noCheckboxesTagLabelRender: PropTypes.func,
};

DropdownSelector.propTypes = DropdownSelectorPropTypes;

export default DropdownSelector;
