import { writeStorage } from '@rehooks/local-storage';
import React from 'react';
import { Redirect, useParams } from 'react-router-dom';

const UIVersionStorageKey = 'ui-version';

const UIController = () => {
  const { version: uiVersion } = useParams();
  writeStorage(UIVersionStorageKey, uiVersion);
  return <Redirect to={'/'} />;
};

export default UIController;
export { UIVersionStorageKey };
