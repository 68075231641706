import { DragEvent, useCallback, useEffect, useState } from 'react';

import { isEqual } from '@common-services/vendor/lodash';

type IDnDCallback = (e: DragEvent<HTMLDivElement>) => void;

export interface IDnDOrderedElement {
  id: number;
}

export interface IUseDnD<T> {
  displayList: T[];
  draggingId: number;
  handleDragStart: (id: number) => () => void;
  handleDragEnter: (id: number) => IDnDCallback;
  handleDrop: IDnDCallback;
  handleDragEvent: IDnDCallback;
}

export function useDragAndDrop<T extends IDnDOrderedElement>(
  initialOrder: T[],
  onReorder: (data: IDnDOrderedElement[]) => void
): IUseDnD<T> {
  const [actualList, setActualList] = useState<T[]>(initialOrder);
  const [displayList, setDisplayList] = useState(actualList);
  const [draggingId, setDraggingId] = useState<number>(null);

  useEffect(() => {
    if (initialOrder && !isEqual(initialOrder, displayList)) {
      setDisplayList(initialOrder);
    }
    // displayList should not be in dependency list otherwise it creates circular dependency.
    // the logic is working this way because displayList updated since initialOrder only changes on re-render
  }, [initialOrder]);

  const handleDragStart = useCallback(
    (id: number) => () => {
      setDraggingId(id);
    },
    []
  );

  const handleDragEnter = useCallback(
    (id: number) => (e: DragEvent<HTMLDivElement>) => {
      e.preventDefault();

      const newList = [...displayList];
      const itemIndex = newList.findIndex((list) => list.id === draggingId);
      const item = newList[itemIndex];
      const dragoverIndex = newList.findIndex((list) => list.id === id);

      newList.splice(itemIndex, 1);
      newList.splice(dragoverIndex, 0, item);

      setDisplayList(newList);
    },
    [displayList, draggingId]
  );

  const handleDrop = useCallback<IDnDCallback>(() => {
    if (!isEqual(actualList, displayList)) {
      onReorder(displayList.map(({ id }) => ({ id })));
      setActualList(displayList);
    }
    setDraggingId(null);
  }, [actualList, displayList]);

  const handleDragEvent = useCallback((e) => e.preventDefault(), []);
  return { displayList, draggingId, handleDragStart, handleDragEnter, handleDrop, handleDragEvent };
}
