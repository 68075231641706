import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { UserDetails } from '@common-pages/account/user-details/userDetails';
import { ACCOUNT_CONSTANTS } from '@routes/account/routesСonstants';
import { AuthRouteEnum } from '@routes/authRoutes';

export const AccountModule: React.FC = () => {
  return (
    <Switch>
      <Route path={AuthRouteEnum.ACCOUNT + ACCOUNT_CONSTANTS.USER_DETAILS}>
        <UserDetails />
      </Route>
    </Switch>
  );
};
