import classnames from 'classnames';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Badge from '@analytics-components/Badge';
import { SmallTitle } from '@analytics-components/Typography';

import { tabStyles, tabContentStyles, tabNavStyles } from './styles';

const Tabs = ({ tabs, initialValue, leftAligned = false }) => {
  const [activeTabState, setActiveTabState] = useState(initialValue);

  useEffect(() => {
    if (activeTabState > tabs.length - 1) {
      setActiveTabState(tabs.length - 1);
    }
  }, [tabs]);

  return (
    <>
      <div className="row mb-4">
        <div className="col-12">
          <div css={(theme) => tabNavStyles(theme, leftAligned)}>
            {tabs.map((tab, i) => (
              <Tab
                key={i}
                config={tab}
                isActive={activeTabState === i}
                onClick={() => setActiveTabState(i)}
                link={tab.link}
              />
            ))}
          </div>
        </div>
      </div>
      {tabs[activeTabState] && (
        <div style={tabs[activeTabState].style || {}}>{tabs[activeTabState].content}</div>
      )}
    </>
  );
};

const Tab = ({ config, isActive, onClick, link }) => {
  const tab = (
    <div
      css={link ? null : tabStyles}
      className={classnames(isActive ? 'active' : null)}
      onClick={onClick}
    >
      <div css={tabContentStyles}>
        {config.Header ? (
          <config.Header isActive={isActive} />
        ) : (
          <>
            <SmallTitle content={config.title} isBlack={isActive} />
            {config.badge && <Badge value={config.badge} className="ml-3" />}
          </>
        )}
      </div>
    </div>
  );

  if (link) {
    return (
      <Link
        className={classnames(isActive ? 'active' : null)}
        css={({ color }) => [
          tabStyles,
          {
            color: color.neutral['100'],
          },
        ]}
        to={link}
      >
        {tab}
      </Link>
    );
  }

  return tab;
};

export default Tabs;
