import React from 'react';

import { BigText, DateRangeBigNumber } from '@analytics-components/charts/Tooltip';
import VerticalBarChart from '@analytics-components/charts/VerticalBarChart';
import { GranularityEnum } from '@analytics-constants';
import { ValueType } from '@analytics-pages/delivery-pipeline/insights/deploy/services/types';
import { IntervalType, LooseObject } from '@analytics-types/common';
import { dateTime } from '@common-services/dateService';
import { theme } from '@styles/theme';

const chart = (granularity: GranularityEnum, interval: IntervalType, values: ValueType[]) => {
  const tooltip: LooseObject = {
    align: {
      horizontal: 'auto',
      vertical: 'top',
    },
    renderBigFn: (v) => <BigText content={`${v.y} deployment${v.y === 1 ? '' : 's'}`} />,
  };
  if ([GranularityEnum.MONTH, GranularityEnum.WEEK].indexOf(granularity) >= 0) {
    tooltip.template = DateRangeBigNumber;
    tooltip.interval = { ...interval };
    tooltip.granularity = granularity;
  }

  return {
    component: VerticalBarChart,
    description: '',
    title: 'Deployment Frequency',
    params: {
      data: values,
      timeMode: true,
      extra: {
        axisFormat: {
          tickFormat: {
            x: granularity === GranularityEnum.MONTH ? dateTime.month : dateTime.monthDay,
          },
        },
        axisKeys: {
          x: 'date',
          y: 'value',
        },
        axisLabels: {
          y: 'Deployments',
        },
        color: theme.color.stage.deploy,
        margin: { bottom: 80 },
        maxNumberOfTicks: 4,
        tooltip,
      },
    },
  };
};

export default chart;
