import { useEffect, useMemo, useState } from 'react';

import { randomUIColor } from '@common-services/colorService';

import { DropdownType, IDropdownNameValue } from './Dropdown.types';

export const useConvertOptions = (
  options: string[] | IDropdownNameValue[],
  type: DropdownType
): IDropdownNameValue[] => {
  const [convertedOptions, setConvertedOptions] = useState(options);

  useEffect(() => {
    if (type !== DropdownType.NAME_VALUE) {
      setConvertedOptions(
        options.map((option) => ({ name: option, value: option, color: randomUIColor() }))
      );
    } else {
      setConvertedOptions(options);
    }
  }, [options, type]);

  return convertedOptions as IDropdownNameValue[];
};

export const useFindInitialObject = (
  initialValue: string | IDropdownNameValue,
  optionsObject: IDropdownNameValue[]
): IDropdownNameValue => {
  const initialValueString = typeof initialValue === 'string' ? initialValue : initialValue?.value;

  const initialObject = useMemo(
    () => optionsObject?.find((option) => option.value === initialValueString),
    [optionsObject]
  );

  return initialObject;
};
