import _ from 'lodash';

import { metricsConfig } from '@align-constants';
import { IUseBreakdownOptionsData } from '@align-pages/dashboard/hooks/types';
import { IBreakdownOptionItem } from '@align-pages/dashboard/reducers/breakdownOptionsReducer.types';
import { DashboardBreakdownServices } from '@align-pages/dashboard/services/dashboardBreakdownServices';
import {
  IGenerateMultiLineSeries,
  IGetMultiSeriesInfo,
  ISerializerReducer,
  ISeriesInfo,
  ISeriesValues,
  IValuesSerializer,
} from '@align-services/api/metricService.types';
import { IGoalMetricValue } from '@align-services/api/types/goalsTypes';
import { Unit, ValueType } from '@align-types/constants';
import { GoalSeriesGranularity } from '@align-types/goals';
import {
  GoalMetricValue,
  GoalValue,
  TeamGoal,
} from '@common-services/api/private/generated-from-backend/models';
import {
  CalculatedLinearMetricValues,
  GoalMetricParams,
  GoalTemplate,
} from '@common-services/api/public/generated-from-backend/models';
import { DateService } from '@common-services/dateService';
import * as NumberService from '@common-services/numberService';
import { IChartSeriesItem } from '@lib/chart';
import { FilterType } from '@lib/filter-panel';

import { IDateRange } from './dateService';

const getUnitRepr = (valueRepr: string, unit: Unit): string => {
  if (unit) {
    return valueRepr === '1' ? unit.singular : unit.plural;
  } else {
    return '';
  }
};

export const getRawValue = (value: IGoalMetricValue): number => {
  return parseInt(value?.str) || value?.int || value?.float;
};

export const getReadableValue = (
  value: IGoalMetricValue,
  valueType: ValueType,
  unit: Unit,
  withoutUnit: boolean = false,
  conversionValue: number = 1,
  isTLO = false
): string => {
  return getReadableRawValue(
    getRawValue(value),
    valueType,
    unit,
    withoutUnit,
    conversionValue,
    isTLO
  );
};

export const getThresholdParams = (
  readableThreshold: string
): { thresholdValue: number; thresholdUnit: string } => {
  const [thresholdValue, thresholdUnit] = readableThreshold.split(' ');
  return { thresholdValue: parseInt(thresholdValue), thresholdUnit };
};

export const getReadableRawValue = (
  rawValue: number,
  valueType: ValueType,
  unit: Unit,
  withoutUnit: boolean = false,
  conversionValue: number = 1,
  isTLO = false
): string => {
  switch (valueType) {
    case ValueType.date:
      return rawValue ? (isTLO ? rawValue.toString() : formatDate(rawValue)) : null;
    case ValueType.int:
      return rawValue
        ? // DEV-4454: Remove the unit for the target, for non-time units (comments, lines, issues, releases, etc)
          formatInt(
            rawValue * conversionValue,
            withoutUnit && unit.singular !== '%' ? null : unit,
            isTLO ? 2 : 1
          )
        : null;
    case ValueType.float:
      return rawValue
        ? // DEV-4454: Remove the unit for the target, for non-time units (comments, lines, issues, releases, etc)
          isTLO
          ? rawValue.toString()
          : formatFloat(rawValue, withoutUnit && unit.singular !== '%' ? null : unit)
        : null;
  }
};

const formatDate = (rawValue: number): string =>
  rawValue ? DateService.human(rawValue * 1000, 1) : '';
const formatInt = (rawValue: number, unit: Unit, decimals = 1): string => {
  const valueRepr = rawValue ? NumberService.round(rawValue, decimals).toString() : '';
  const unitRepr = unit ? getUnitRepr(valueRepr, unit) : null;
  return unitRepr ? `${valueRepr} ${unitRepr}` : valueRepr;
};
const formatFloat = (rawValue: number, unit: Unit): string => {
  let valueRepr = rawValue ? (Math.round(rawValue * 10) / 10).toString() : '';
  const unitRepr = unit ? getUnitRepr(valueRepr, unit) : null;
  // TODO: Add a `ValueType.percentage`.
  if (unitRepr === '%') {
    valueRepr = rawValue ? Math.round(rawValue * 100).toString() : '';
  }
  return unitRepr ? `${valueRepr} ${unitRepr}` : valueRepr;
};

export const hasValue = (value: IGoalMetricValue): boolean => {
  return (value?.str || value?.float || value?.int) != null;
};

export const getFrequencyValue = (
  range: IDateRange,
  goalValue: IGoalMetricValue
): [string, number] => {
  const [value, unit, conversionValue] = DateService.humanLaboralRate(
    DateService.strToMoment(range.dateFrom),
    DateService.strToMoment(range.dateTo),
    getRawValue(goalValue)
  );
  return value ? [`${value} / ${unit}`, conversionValue] : ['0', 1];
};

export interface IFlatTeamGoals {
  [keyof: string]: GoalValue;
}

// Gather all the nested team goal values into single level object recursively
export const flattenTeamGoals = (teamGoal: TeamGoal): IFlatTeamGoals => ({
  [teamGoal.team.id]: teamGoal.value,
  ...(teamGoal.children?.reduce(
    (a: IFlatTeamGoals, current: TeamGoal) => ({ ...a, ...flattenTeamGoals(current) }),
    {}
  ) || {}),
});

export const convertSeriesToNumber = (
  goalValue: GoalValue,
  from: string,
  to: string,
  unit: Unit,
  isPercentage = false
): number[] => {
  let conversionValue = 1;
  if (unit?.isFrequency && goalValue?.series_granularity) {
    const granularity = goalValue.series_granularity;
    const granularityMultiplier = granularity === GoalSeriesGranularity.WEEK ? 7 : 30;
    const daysInPeriod = DateService.daysBetween(from, to);
    conversionValue = daysInPeriod / granularityMultiplier;
  }
  return (
    goalValue.series?.map((point) => {
      const alteredValue =
        unit?.singular === '%' || isPercentage
          ? parseFloat(point.value as string) * 100
          : parseInt(point.value as string);
      return alteredValue * conversionValue;
    }) || []
  );
};

export const convertFloatToPercentage = (floatVal: GoalMetricValue): number => {
  return NumberService.round(parseFloat(floatVal.toString()) * 100);
};

export const getUnitSingularity = (
  threshold: GoalMetricParams['threshold'],
  metricValue: GoalTemplate['metric']
): GoalMetricParams['threshold'] => {
  if (typeof threshold === 'string') {
    const [amount, unit] = threshold.split(' ');
    return `${amount} ${
      parseInt(amount) === 1 ? metricsConfig[metricValue]?.unit?.singular || unit : unit
    }`;
  }
  return threshold;
};

export const valueConverter = (
  value: CalculatedLinearMetricValues['values'][0],
  type: ValueType
): number => {
  if (value == null) {
    return null;
  }
  switch (type) {
    case ValueType.float:
      return Math.round((value as number) * 1000) / 10;
    case ValueType.int:
      return Math.round((value as number) * 10) / 10;
    case ValueType.date:
      return parseInt(value as string);
  }
};

export const serializerReducer = (valueType: ValueType): ISerializerReducer => ({
  date,
  values,
}: CalculatedLinearMetricValues): IChartSeriesItem => ({
  date,
  value: valueConverter(values[0], valueType),
});

export const valuesSerializer = ({ values, valueType }: IValuesSerializer): IChartSeriesItem[] =>
  values?.[0]?.values?.map(serializerReducer(valueType)) || [];

const seriesSerializer = (values: ISeriesInfo, valueType: ValueType): IChartSeriesItem[] =>
  values?.values.map(serializerReducer(valueType)) || [];

export const generateMultiLineSeries = (
  series: ISeriesInfo[],
  valueType: ValueType,
  breakdownOptions: IBreakdownOptionItem[],
  type: FilterType,
  optionsData: IUseBreakdownOptionsData
): IGenerateMultiLineSeries => {
  const selectedOptions =
    breakdownOptions?.filter(({ selected }) => selected).map(({ value }) => value.toString()) || [];

  const multiLineSeries = [];
  const seriesNames = [];
  const seriesColors = [];

  series?.forEach((currentSeries, index) => {
    if (selectedOptions.includes(currentSeries.id)) {
      multiLineSeries.push(seriesSerializer(currentSeries, valueType));
      const currentOption = breakdownOptions.find(
        ({ value }) => value.toString() === currentSeries.id.toString()
      );
      const breakdownOptionName = DashboardBreakdownServices.getBreakdownOptionsName(
        currentOption?.value,
        type,
        optionsData
      );
      seriesNames.push(breakdownOptionName);
      seriesColors.push(currentOption?.color);
    }
  });

  return {
    multiLineSeries,
    seriesNames,
    seriesColors,
  };
};

export const combineMultiSeriesInfo = ({
  values,
  breakdownOptions,
}: IGetMultiSeriesInfo): ISeriesInfo[] => {
  if (values) {
    const for_ = values[0].for;

    // @ts-ignore
    if (for_ && for_.issue_types) {
      const out = combineMultiSeriesInfoForIssueTypes(values);
      return out;
    }
  }

  const out = values?.reduce((currentSeries, seriesItem, index) => {
    if (index % 2 === 1) {
      currentSeries[currentSeries.length - 1].counter = seriesItem.values[0].values[1];
      return currentSeries;
    }

    return [...currentSeries, { ...seriesItem, id: breakdownOptions[currentSeries.length]?.value }];
  }, []);
  return out;
};

const combineMultiSeriesInfoForIssueTypes = (values: ISeriesValues): ISeriesInfo[] => {
  // @ts-ignore
  return _(
    _(values).reduce((result, value) => {
      // @ts-ignore
      const issueType = value.for.issue_types[0];
      const granularity = value.granularity;
      result[issueType] = result[issueType] || {};
      result[issueType][granularity] = {
        ...value,
        id: issueType,
      };
      return result;
    }, {})
  )
    .map((v, k) => {
      const timeseriesGranularity = _(v)
        .keys()
        .filter((x) => x !== 'all')
        .value()[0];
      // @ts-ignore
      const counter = v.all.values[0].values[1];
      const timeseries = v[timeseriesGranularity] as Object;
      return {
        ...timeseries,
        counter,
      };
    })
    .value();
};

export * as MetricService from './metricService';
