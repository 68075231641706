import { useTheme } from '@emotion/react';
import { useMemo } from 'react';

import { metricsConfig } from '@align-constants';
import { ITeamGoal, ITemplateMetric } from '@align-services/api/types/goalsTypes';
import { MetricService } from '@align-services/metricService';
import { IMetricBetter } from '@align-types/constants';
import { Goal } from '@common-services/api/private/generated-from-backend/models';
import { ITheme } from '@styles/types';
import { hexToRGBA } from '@utils/colors';

interface IUseActiveGoalRowChartData {
  goalSeries: number[];
  seriesColours: string[];
  targetValue: number;
}

export const useActiveGoalRowChartData = (
  goal: Goal,
  team: ITeamGoal,
  templateMetric: ITemplateMetric,
  isTLO: boolean
): IUseActiveGoalRowChartData => {
  const theme = useTheme() as ITheme;
  const goalSeries = useMemo<number[]>(() => {
    // Get all the team goals to an object
    const allGoalsList = MetricService.flattenTeamGoals(goal.team_goal);
    // Select the current goal values by the team id
    const currentGoalSeries = allGoalsList[team.team.id];
    // Convert the series data to number array
    return MetricService.convertSeriesToNumber(
      currentGoalSeries,
      goal.valid_from,
      goal.expires_at,
      metricsConfig[goal.metric]?.unit,
      isTLO
    );
  }, [goal]);

  const targetValue = useMemo(() => {
    return team?.value?.target
      ? (metricsConfig[goal.metric]?.unit?.singular === '%' || isTLO ? 100 : 1) *
          MetricService.getRawValue(team?.value?.target)
      : null;
  }, [team?.value?.target]);

  const seriesColours = useMemo<string[]>(() => {
    return goalSeries.map((itemVal) => {
      const isMoreBetter: boolean =
        isTLO || metricsConfig[templateMetric]?.better === IMetricBetter.HIGHER;
      const positiveColor = hexToRGBA(theme.color.ui.green[100], 0.7);
      if (isMoreBetter) {
        return itemVal >= targetValue ? positiveColor : null;
      } else {
        return itemVal <= targetValue ? positiveColor : null;
      }
    });
  }, [goalSeries, targetValue, templateMetric]);

  return { goalSeries, seriesColours, targetValue };
};
