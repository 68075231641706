import { useMutation } from 'react-query';

import { useNavigation } from '@align-components/layouts/primary/context';
import { IUseMutationHook } from '@align-types/common';
import { useAuth0 } from '@common-context/Auth0';
import { useDemoCookie } from '@common-pages/Demo';
import { IUrlParams } from '@common-services/api/common/types/apiClient';
import { IApiBasicError } from '@common-services/api/common/types/common';
import { DashboardServices } from '@common-services/api/private/services/dashboard';

export function useRemoveChartFromDashboard(): IUseMutationHook<
  void,
  IApiBasicError,
  IUrlParams<'chartId'>
> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();
  const { currentTeam } = useNavigation();

  const removeChart = async (
    urlParams: IUrlParams<'dashboardId' | 'teamId' | 'chartId'>
  ): Promise<void> => {
    const token: string = isDemo === false ? await getTokenSilently() : null;

    return DashboardServices.removeChartFromDashboard(
      {
        dashboardId: 0,
        teamId: currentTeam.id,
        chartId: urlParams.chartId,
      },
      token
    );
  };

  const { mutate, isLoading, data, error, isError, isSuccess } = useMutation<
    void,
    IApiBasicError,
    IUrlParams<'dashboardId' | 'teamId' | 'chartId'>
  >(removeChart);

  return { mutate, isLoading, data, error, isError, isSuccess };
}
