/**
 * {{ title }}
 * Server version: {{ server_version }} built on {{ build_date }} @ [{{ commit }}](https://github.com/athenianco/athenian-api/commit/{{ commit }})  Origin GitHub repository: [athenianco/api-spec](https://github.com/athenianco/api-spec).  Authorization persists between sessions. 🛡️ means that the endpoint requires account admin privileges. 👑 means that the endpoint is only accessible to Athenian authorities. <details> <summary>List of common server errors.</summary> * __500__ endpoint crashed. We reported the incident to Sentry and will fix it soon! Please include the value of `instance` in the response if you contact the support. Repeating the request will not help, most probably. * __501__ some functions are missing, either because they are not implemented yet or a non-critical runtime dependency is not satisfied. Repeating the request will not help, guaranteed. * __502__ server crashed badly, either due to a memory access violation in native code or running out of memory. We reported this incident to Sentry and will fix it soon! Try repeating the request. * __503__ server has not fully launched yet, e.g. hasn't connected to the database; server is shutting down; we are experiencing a partial outage. Try repeating the request. * __504__ endpoint took too much time and was interrupted. We reported the incident to Sentry and will see how to improve the performance. Repeating the request will not help, most probably. </details>
 *
 * Contact: admin@athenian.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The TableFetchingProgress model module.
 * @module model/TableFetchingProgress
 */
class TableFetchingProgress {
    /**
     * Constructs a new <code>TableFetchingProgress</code>.
     * Used in InstallationProgress.tables to indicate how much data has been loaded in each DB table.
     * @alias module:model/TableFetchingProgress
     * @param fetched {Number} How many records have been stored in the DB table so far.
     * @param name {String} DB table identifier.
     * @param total {Number} How many records the DB table is expected to have.
     */
    constructor(fetched, name, total) { 
        
        TableFetchingProgress.initialize(this, fetched, name, total);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, fetched, name, total) { 
        obj['fetched'] = fetched;
        obj['name'] = name;
        obj['total'] = total;
    }

    /**
     * Constructs a <code>TableFetchingProgress</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/TableFetchingProgress} obj Optional instance to populate.
     * @return {module:model/TableFetchingProgress} The populated <code>TableFetchingProgress</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new TableFetchingProgress();

            if (data.hasOwnProperty('fetched')) {
                obj['fetched'] = ApiClient.convertToType(data['fetched'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('total')) {
                obj['total'] = ApiClient.convertToType(data['total'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * How many records have been stored in the DB table so far.
 * @member {Number} fetched
 */
TableFetchingProgress.prototype['fetched'] = undefined;

/**
 * DB table identifier.
 * @member {String} name
 */
TableFetchingProgress.prototype['name'] = undefined;

/**
 * How many records the DB table is expected to have.
 * @member {Number} total
 */
TableFetchingProgress.prototype['total'] = undefined;






export default TableFetchingProgress;

