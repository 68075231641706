import React from 'react';

import { github } from '@common-services/format';
import IconButton from '@lib/IconButton';
import { icons } from '@lib/icon';
import { primaryText, secondaryText } from '@ui/typography';

import { JIRAIdentityMatcher } from './jiraIdentityMatcher';
import { rowStyles, userStyles } from './teamEditAccordionRow.styles';
import { ITeamEditAccordionRow } from './teamEditAccordionRow.types';

const TeamEditAccordionRow: React.FC<ITeamEditAccordionRow> = React.memo(
  ({ member, jiraIdentities, onMemberDeletion, onMemberMapped, onMemberMappingReset }) => {
    const gitUser = github.userName(member.login);
    const user = gitUser || 'ANONYMOUS';
    const hasJiraIds = jiraIdentities && jiraIdentities.length > 0;

    return (
      <div css={rowStyles(hasJiraIds)}>
        <div>
          <img src={member.picture} className="pr-user-avatar" alt={member.name} />
        </div>

        <div>
          <span css={primaryText}>
            {member.name}
            <span css={(theme) => [userStyles(theme), secondaryText(theme)]}>{user}</span>
          </span>
        </div>

        {hasJiraIds && (
          <JIRAIdentityMatcher
            member={member}
            jiraIdentities={jiraIdentities}
            onSave={onMemberMapped}
            onReset={onMemberMappingReset}
          />
        )}

        <div>
          <IconButton icon={icons.cross} iconSize={10} onClick={() => onMemberDeletion(member)} />
        </div>
      </div>
    );
  }
);

export { TeamEditAccordionRow };
