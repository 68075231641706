/**
 * {{ title }}
 * Server version: {{ server_version }} built on {{ build_date }} @ [{{ commit }}](https://github.com/athenianco/athenian-api/commit/{{ commit }})  Origin GitHub repository: [athenianco/api-spec](https://github.com/athenianco/api-spec).  Authorization persists between sessions. 🛡️ means that the endpoint requires account admin privileges. 👑 means that the endpoint is only accessible to Athenian authorities. <details> <summary>List of common server errors.</summary> * __500__ endpoint crashed. We reported the incident to Sentry and will fix it soon! Please include the value of `instance` in the response if you contact the support. Repeating the request will not help, most probably. * __501__ some functions are missing, either because they are not implemented yet or a non-critical runtime dependency is not satisfied. Repeating the request will not help, guaranteed. * __502__ server crashed badly, either due to a memory access violation in native code or running out of memory. We reported this incident to Sentry and will fix it soon! Try repeating the request. * __503__ server has not fully launched yet, e.g. hasn't connected to the database; server is shutting down; we are experiencing a partial outage. Try repeating the request. * __504__ endpoint took too much time and was interrupted. We reported the incident to Sentry and will see how to improve the performance. Repeating the request will not help, most probably. </details>
 *
 * Contact: admin@athenian.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import DeployedComponent from './DeployedComponent';
import DeploymentConclusion from './DeploymentConclusion';

/**
 * The DeploymentNotification model module.
 * @module model/DeploymentNotification
 */
class DeploymentNotification {
    /**
     * Constructs a new <code>DeploymentNotification</code>.
     * Push message about a deployment. We remove unresolved components after 24h. 
     * @alias module:model/DeploymentNotification
     * @param components {Array.<module:model/DeployedComponent>} List of deployed software version. Each item identifies a Git reference in a repository, either a tag or a commit hash. 
     * @param environment {String} Name of the environment where the deployment happened.
     * @param dateStarted {Date} Timestamp of when the deployment procedure launched.
     * @param dateFinished {Date} Timestamp of when the deployment procedure completed.
     * @param conclusion {module:model/DeploymentConclusion} 
     */
    constructor(components, environment, dateStarted, dateFinished, conclusion) { 
        
        DeploymentNotification.initialize(this, components, environment, dateStarted, dateFinished, conclusion);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, components, environment, dateStarted, dateFinished, conclusion) { 
        obj['components'] = components;
        obj['environment'] = environment;
        obj['date_started'] = dateStarted;
        obj['date_finished'] = dateFinished;
        obj['conclusion'] = conclusion;
    }

    /**
     * Constructs a <code>DeploymentNotification</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DeploymentNotification} obj Optional instance to populate.
     * @return {module:model/DeploymentNotification} The populated <code>DeploymentNotification</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DeploymentNotification();

            if (data.hasOwnProperty('components')) {
                obj['components'] = ApiClient.convertToType(data['components'], [DeployedComponent]);
            }
            if (data.hasOwnProperty('environment')) {
                obj['environment'] = ApiClient.convertToType(data['environment'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
            if (data.hasOwnProperty('date_started')) {
                obj['date_started'] = ApiClient.convertToType(data['date_started'], 'Date');
            }
            if (data.hasOwnProperty('date_finished')) {
                obj['date_finished'] = ApiClient.convertToType(data['date_finished'], 'Date');
            }
            if (data.hasOwnProperty('conclusion')) {
                obj['conclusion'] = DeploymentConclusion.constructFromObject(data['conclusion']);
            }
            if (data.hasOwnProperty('labels')) {
                obj['labels'] = ApiClient.convertToType(data['labels'], Object);
            }
        }
        return obj;
    }


}

/**
 * List of deployed software version. Each item identifies a Git reference in a repository, either a tag or a commit hash. 
 * @member {Array.<module:model/DeployedComponent>} components
 */
DeploymentNotification.prototype['components'] = undefined;

/**
 * Name of the environment where the deployment happened.
 * @member {String} environment
 */
DeploymentNotification.prototype['environment'] = undefined;

/**
 * Name of the deployment. If is not specified, we generate our own by the template `<environment shortcut>-<date>-<hash of the components>`. The value may not contain '\\n' - the new line character.
 * @member {String} name
 */
DeploymentNotification.prototype['name'] = undefined;

/**
 * URL pointing at the internal details of the deployment.
 * @member {String} url
 */
DeploymentNotification.prototype['url'] = undefined;

/**
 * Timestamp of when the deployment procedure launched.
 * @member {Date} date_started
 */
DeploymentNotification.prototype['date_started'] = undefined;

/**
 * Timestamp of when the deployment procedure completed.
 * @member {Date} date_finished
 */
DeploymentNotification.prototype['date_finished'] = undefined;

/**
 * @member {module:model/DeploymentConclusion} conclusion
 */
DeploymentNotification.prototype['conclusion'] = undefined;

/**
 * Arbitrary key-value metadata that associates with the deployment.
 * @member {Object} labels
 */
DeploymentNotification.prototype['labels'] = undefined;






export default DeploymentNotification;

