import { motion } from 'framer-motion';
import React, { useCallback, useMemo, useState } from 'react';

import { useMeasure } from '@common-hooks/useMeasure';

import { AccordionBody, AccordionContent, AccordionFooter, AccordionHeader } from './components';
import { useOverrideStyle } from './hooks';
import { AnimationVariants, Props } from './types';
import { getStyle } from './ui/styles';

const Accordion: React.FC<Props> = React.memo(
  ({
    onHeaderClick,
    renderHeader,
    renderBody,
    renderFooter,
    overrideHeaderStyle,
    overrideWrapperStyle,
  }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const onHeaderClickWrapper = useCallback(
      (ev: React.MouseEvent<HTMLDivElement>) => {
        setIsExpanded(!isExpanded);
        if (onHeaderClick) {
          onHeaderClick(ev);
        }
      },
      [onHeaderClick, isExpanded]
    );

    const [
      contentRef,
      {
        border: { height: contentHeight },
      },
    ] = useMeasure<HTMLDivElement>();

    const [
      headerRef,
      {
        border: { height: headerHeight },
      },
    ] = useMeasure<HTMLDivElement>();

    const contentAnimationVariants: AnimationVariants = {
      expanded: {
        opacity: 1,
      },
      collapsed: {
        opacity: 0,
      },
    };

    const wrapperAnimationVariants = useMemo<AnimationVariants>(
      () => ({
        expanded: {
          height: headerHeight + contentHeight,
        },
        collapsed: {
          height: headerHeight,
        },
      }),
      [contentHeight, headerHeight]
    );

    const wrapperStyle = useOverrideStyle(getStyle, overrideWrapperStyle);

    return (
      <motion.div
        initial={false}
        animate={isExpanded ? 'expanded' : 'collapsed'}
        transition={{ duration: 0.1 }}
        variants={wrapperAnimationVariants}
        css={wrapperStyle}
      >
        <AccordionHeader
          ref={headerRef}
          render={renderHeader}
          overrideStyle={overrideHeaderStyle}
          isExpanded={isExpanded}
          onClick={onHeaderClickWrapper}
        />
        <AccordionContent
          ref={contentRef}
          animationVariants={contentAnimationVariants}
          animate={isExpanded ? 'expanded' : 'collapsed'}
        >
          <AccordionBody render={renderBody} isExpanded={isExpanded} />
          {renderFooter && <AccordionFooter render={renderFooter} isExpanded={isExpanded} />}
        </AccordionContent>
      </motion.div>
    );
  }
);

export { Accordion };
