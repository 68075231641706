import React, { useCallback } from 'react';

import { contentTypes, useAllocationContext } from '@analytics-context/Allocation';
import DataWidget from '@analytics-context/DataWidget';
import {
  getIssueTypes,
  getLabels,
  getJiraLabels,
  getProjects,
  getRule,
  getSelectedTeams,
  fetchPrs,
  fetchIssues,
  getJiraAssignees,
  getGranularity,
  distributeByGranularity,
} from '@analytics-pages/outcome/insights/allocation/work-types/helpers';
import { fetchJIRAMetrics } from '@analytics-services/api/jira';
import { isFeatureEnabled, featuresList } from '@analytics-services/flags';
import { useUserContext } from '@common-context/User';
import { isEqual } from '@common-services/vendor/lodash';

import { IAllocationType, IFetchIssuesProps } from '../types';
import InsightsAllocationDataBinder from './DataBinder.jsx';
import allocationChart from './allocationChart';
import allocationTotal from './allocationTotal';
import { OTHER_COLUMN_OBJECT } from './constants';
import teamsAllocationTable from './teamsAllocationTable';
import throughputChart from './throughputChart';
import throughputTotal from './throughputTotal';

export const InsightsAllocation = () => {
  const { appliedWorkTypes, contentType } = useAllocationContext();
  const { features, account, isGod } = useUserContext();

  const isTeamAllocationTableEnabled = isFeatureEnabled(
    featuresList.team_allocation_table_enabled,
    features
  );

  const reducedWorkTypes = appliedWorkTypes.filter((e) => e.rules?.length);

  const fetcher = useCallback(
    async (api, cachedData, apiContext) => {
      const {
        account,
        interval,
        excludeInactive,
        contributors,
        includeNullContributor,
        includeFakeContributor,
        epics,
        user,
        teams,
      } = apiContext;

      const jiraAssignees = account?.jira
        ? getJiraAssignees({
            contributors,
            includeNullContributor,
            includeFakeContributor,
          })
        : [];

      let granularityValue = getGranularity({ interval });

      const selectedTeams = getSelectedTeams({ contributors, teams });
      const teamAuthors = selectedTeams.map((team) => team.members.map((member) => member.login));

      const allIssueTypes = user.account.jira
        ? cachedData['jira-issue-types'].issue_types?.filter((v) => !v.is_subtask && !v.is_epic) ||
          []
        : [];

      const allProjects = user.account.jira
        ? cachedData['jira-projects']?.filter((v) => v.enabled) || []
        : [];

      const jiraUsers = selectedTeams.map((team) => team.members);

      const getJiraUserLogin = (jira_user) =>
        jiraUsers.flat().find((el) => el.jira_user === jira_user || el.name === jira_user).login;

      // PRS DATA
      const chartDataPayload = [
        ...reducedWorkTypes.map((workType) => ({
          labels: getLabels(workType, contentTypes.prs),
          projects: getProjects(workType, contentTypes.prs, allProjects),
          issueTypes: getIssueTypes(workType, contentTypes.prs, allIssueTypes),
          jiraLabels: getJiraLabels(workType, contentTypes.prs),
          workType,
        })),
        {
          labels: [],
          projects: [],
          issueTypes: [],
          jiraLabels: [],
          workType: OTHER_COLUMN_OBJECT,
        },
      ];

      const tableDataPayload = reducedWorkTypes.reduce(
        (flat, workType) => [
          ...flat,
          ...teamAuthors.map(
            (authors) => ({
              labels: getLabels(workType, contentTypes.prs),
              projects: getProjects(workType, contentTypes.prs, allProjects),
              issueTypes: getIssueTypes(workType, contentTypes.prs, allIssueTypes),
              jiraLabels: getJiraLabels(workType, contentTypes.prs),
              workType,
              authors,
            }),
            []
          ),
        ],
        [
          ...teamAuthors.map(
            (authors) => ({
              labels: [],
              projects: [],
              issueTypes: [],
              jiraLabels: [],
              authors,
              workType: OTHER_COLUMN_OBJECT,
            }),
            []
          ),
        ]
      );

      const getPrsData = async (type) =>
        await fetchPrs({
          apiContext,
          api,
          granularity: granularityValue,
          forSets: type === 'chart' ? chartDataPayload : tableDataPayload,
        });

      const prsData = await getPrsData('chart');

      // Distribute by granularity
      const fetchedPrsData = distributeByGranularity({
        data: prsData,
        type: IAllocationType.prs,
      });

      fetchedPrsData.teamsPrs =
        isGod &&
        isTeamAllocationTableEnabled &&
        (await getPrsData('table').then((response) =>
          response.filter((item) => item.granularity === 'all')
        ));

      /// ISSUES DATA
      const getIssuesData = user.account.jira
        ? async () =>
            await Promise.all([
              ...reducedWorkTypes.map((workType) => {
                return getRule(workType, contentTypes.issues).length
                  ? fetchIssues({
                      apiContext,
                      api,
                      granularity: [granularityValue, 'all'],
                      projects: getProjects(workType, contentTypes.issues, allProjects),
                      types: getIssueTypes(workType, contentTypes.issues, allIssueTypes),
                      labels: getJiraLabels(workType, contentTypes.issues),
                      jiraAssignees,
                      workType,
                    } as IFetchIssuesProps)
                  : [{ values: null }];
              }),
              fetchIssues({
                apiContext,
                api,
                granularity: [granularityValue, 'all'],
                projects: allProjects.map((project) => project.key),
                types: allIssueTypes.map((issueType) => issueType.value),
                jiraAssignees,
                workType: OTHER_COLUMN_OBJECT,
              } as IFetchIssuesProps),
            ])
        : () => [];

      const issuesChartData = await getIssuesData();

      // Distribute by granularity
      const fetchedIssuesData = distributeByGranularity({
        data: issuesChartData,
        type: IAllocationType.issues,
      });

      /// TEAMS DATA
      const fetchIssuesForTable = async ({
        granularity,
        projects,
        types,
        labels,
        withData,
        workType,
      }) => {
        const response = await fetchJIRAMetrics(
          api,
          account,
          [granularity],
          interval,
          ['resolved'],
          excludeInactive,
          {
            projects,
            types,
            epics,
            labels,
            priorities: null,
          },
          withData
        );

        return response.map((teamData) => {
          const allProjectKeys = allProjects.map((project) => project.key);

          return {
            values: teamData.values,
            workType,
            for: {
              jira: {
                issue_types: types,
                labels_included: labels,
                ...(isEqual(allProjectKeys, projects) ? {} : { projects: projects }),
              },
              with: {
                author: teamData.with.assignees.map(getJiraUserLogin),
              },
            },
          };
        });
      };

      const getTableIssuesData =
        isGod && isTeamAllocationTableEnabled && user.account.jira
          ? async () => {
              // Add OTHER_COLUMN_OBJECT to the workTypes array
              // and send a request for each workTypes
              const issuesData = await Promise.all(
                [...reducedWorkTypes, OTHER_COLUMN_OBJECT].reduce((flat, workType) => {
                  const author = jiraUsers.map((users) => ({
                    assignees: users.map((u) => u.jira_user || u.name).filter((v) => v),
                  }));
                  if (
                    workType.name === OTHER_COLUMN_OBJECT.name ||
                    getRule(workType, contentTypes.issues)?.length
                  ) {
                    return [
                      ...flat,
                      fetchIssuesForTable({
                        granularity: 'all',
                        projects: getProjects(workType, contentTypes.issues, allProjects),
                        types: getIssueTypes(workType, contentTypes.issues, allIssueTypes),
                        labels: getJiraLabels(workType, contentTypes.issues),
                        withData: author,
                        workType,
                      }),
                    ];
                  }
                  // If no rule defined for the target view then return value as 0
                  // with proper format. `for` object getting used while parsing data to
                  // the teams
                  return [
                    ...flat,
                    [
                      {
                        values: [{ values: [0] }],
                        workType,
                        for: {
                          with: {
                            author,
                          },
                        },
                      },
                    ],
                  ];
                }, [])
              );
              // Flatten the first level of the array
              return issuesData.reduce((a, c) => [...a, ...c], []);
            }
          : () => [];

      fetchedIssuesData.teamsIssues =
        isGod && isTeamAllocationTableEnabled && (await getTableIssuesData());

      return {
        allPrs: fetchedPrsData.all,
        granularPrs: fetchedPrsData[granularityValue],
        teamsPrs: fetchedPrsData.teamsPrs,
        allIssues: fetchedIssuesData?.all || [],
        granularIssues: fetchedIssuesData[granularityValue] || [],
        teamsIssues: fetchedIssuesData.teamsIssues,
        selectedTeams,
      };
    },
    [appliedWorkTypes.length]
  );

  const plumber = useCallback(
    (fetchedData, cachedData, apiContext) => {
      return {
        allocationChartData: allocationChart.plumber(fetchedData, apiContext, appliedWorkTypes),
        allocationTotalData: allocationTotal.plumber(fetchedData, apiContext, appliedWorkTypes),
        throughputChartData: throughputChart.plumber(fetchedData, apiContext, appliedWorkTypes),
        throughputTotalData: throughputTotal.plumber(fetchedData, apiContext, appliedWorkTypes),
        teamsAllocationTableData:
          isGod &&
          isTeamAllocationTableEnabled &&
          teamsAllocationTable.plumber(fetchedData, apiContext, appliedWorkTypes),
      };
    },
    [appliedWorkTypes.length]
  );
  const prefetchedDataIds = [
    ...(account?.jira ? ['jira-issue-types', 'jira-projects', 'jira-labels'] : []),
  ];
  return (
    <DataWidget
      id={`allocation-insights-${appliedWorkTypes.map((v) => v.name).join()}`}
      fetcher={fetcher}
      plumber={plumber}
      prefetchedDataIds={prefetchedDataIds}
    >
      <InsightsAllocationDataBinder contentType={contentType} />
    </DataWidget>
  );
};
