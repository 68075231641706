import { useTheme } from '@emotion/react';
import React, { useCallback, useRef, useState } from 'react';

import { useUserContext } from '@common-context/User';
import { useOnClickOutside } from '@common-hooks/useOnClickOutside';
import ruleTypes from '@common-pages/Settings/Allocation/WorkTypes/ruleTypes';
import { Button } from '@lib/Button';
import { Icon, icons } from '@lib/icon';

import { listItemStyles, listStyles, wrapperStyles } from './styles';

const AddingRule = ({ addedRules, workType, onAddRule }) => {
  const { account } = useUserContext();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef(null);
  useOnClickOutside([ref], () => setIsOpen(false));

  const handleAddRuleClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleClick = useCallback(
    (ruleName) => () => {
      if (ruleTypes[ruleName].active) {
        onAddRule(ruleName, workType);
      }
      setIsOpen(false);
    },
    [ruleTypes, workType]
  );

  return (
    <div css={wrapperStyles}>
      <Button icon={icons.add} label="Add rule" onClick={handleAddRuleClick} />
      {isOpen ? (
        <div css={listStyles} ref={ref}>
          {Object.keys(ruleTypes).map((ruleName) => {
            const isAdded = addedRules.some((item) => item.name === ruleName);
            const isActive = ruleTypes[ruleName].active;
            const isVisible = !ruleTypes[ruleName].withJira || account.jira;
            return isVisible ? (
              <div
                css={listItemStyles(isAdded || !isActive)}
                key={ruleName}
                onClick={handleClick(ruleName)}
              >
                <Icon color={theme.color.neutral[80]} icon={ruleTypes[ruleName].icon} size={14} />
                <div className="ath-rule-label">{ruleTypes[ruleName].label}</div>
              </div>
            ) : null;
          })}
        </div>
      ) : null}
    </div>
  );
};

export default AddingRule;
