import _ from 'lodash';

import { calculateGranularity } from '@analytics-components/Prefetcher';
import MultiLineChart from '@analytics-components/charts/MultiLineChart';
import { fetchJIRAMetrics } from '@analytics-services/api/jira';
import { FAKE_USERNAME } from '@common-pages/Settings/constants';
import { dateTime } from '@common-services/dateService';
import { theme } from '@styles/theme';

const mttrThroughTimeByPriority = {
  fetcher: async (api, cachedData, apiContext) => {
    const {
      account,
      interval,
      excludeInactive,
      contributors,
      includeNullContributor,
      includeFakeContributor,
      epics,
    } = apiContext;

    const jiraAssignees = _(contributors).map('jira_user').filter().value();
    if (includeNullContributor) {
      jiraAssignees.push(null);
    }
    if (includeFakeContributor) {
      jiraAssignees.push(FAKE_USERNAME);
    }

    let granularity = calculateGranularity(interval);
    if (granularity === 'month') {
      granularity = `aligned ${granularity}`;
    }

    const fetch = async (priority) => {
      const mttr = await fetchJIRAMetrics(
        api,
        account,
        [granularity],
        interval,
        ['resolved', 'life-time'],
        excludeInactive,
        {
          types: ['Bug'],
          priorities: [priority.name],
          epics,
        },
        { assignees: jiraAssignees }
      );

      return {
        color: priority.color,
        data: _(mttr[0].values)
          .map((v) => ({
            count: v.values[0],
            x: v.date,
            y: v.values[1] / (3600 * 24) || null, // get amount of days from returned seconds
          }))
          .value(),
        label: priority.name,
      };
    };

    const filteredJIRAStuff = cachedData['jira-priorities-all'];
    const priorities = filteredJIRAStuff.priorities.map((p, index) => ({
      color: theme.color.sets.bugs[index],
      name: p.name,
    }));

    const mttrByPriority = (
      await Promise.all(
        _(priorities)
          .map((p) => fetch(p))
          .value()
      )
    ).filter((item) => item.data.reduce((acc, val) => acc + (val.y || 0), 0));

    return { mttrByPriority };
  },
  plumber: (fetchedData, cachedData, apiContext) => {
    const customGranularity = cachedData['prs-metrics'].customGranularity;
    const tickFormat = customGranularity === 'month' ? dateTime.month : dateTime.monthDay;
    const { interval } = apiContext;
    const { mttrByPriority } = fetchedData;
    const isNotEmpty = mttrByPriority.some((v) => v.data.some((d) => d.y));

    return {
      empty: !isNotEmpty,
      chart: {
        component: MultiLineChart,
        params: {
          data: {
            interval,
            linesData: mttrByPriority,
          },
          extra: {
            axisLabels: {
              y: 'Days',
            },
            chartHeight: 438,
            margin: {
              left: 60,
            },
            strokeWidth: 2,
            ticks: {
              x: {
                maxNumberOfTicks: 10,
                tickFormat,
              },
            },
            tooltip: {
              valueFormat: (v) => dateTime.human(v * 24 * 3600 * 1000),
            },
          },
        },
      },
      kpis: [],
    };
  },
  prefetchedDataIds: ['jira-priorities-all', 'prs-metrics'],
};

export default mttrThroughTimeByPriority;
