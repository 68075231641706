import { IUrlParams } from '@common-services/api/common/types/apiClient';
import { publicApi } from '@common-services/api/public';
import { GoalTemplate } from '@common-services/api/public/generated-from-backend/models';

export const goalTemplates = async (
  urlParams: IUrlParams<'accountId'>,
  token: string
): Promise<GoalTemplate[]> => {
  return publicApi.goalTemplates.getGoalTemplates(urlParams, token, {
    message: `Cannot get goal templates for accountId ${urlParams.accountId}`,
  });
};

export const goalTemplate = async (
  urlParams: IUrlParams<'goalId'>,
  token: string
): Promise<GoalTemplate> => {
  return publicApi.goalTemplates.getGoalTemplate(urlParams, token, {
    message: `Cannot get goal template ${urlParams.goalId}`,
  });
};

export * as GoalTemplatesServices from './goalTemplates';
