import React, { useEffect, useMemo, useState } from 'react';

import DataWidget, { useDataWidget } from '@analytics-context/DataWidget';
import { FilteredRelease } from '@common-services/api/public/generated-from-backend/models';
import { ArrayService } from '@common-services/arrayService';
import { github } from '@common-services/format';
import { NoData } from '@lib/empty/noData';
import { TooManyRows } from '@lib/empty/tooManyRows';

import { ReleasesTable } from './components/releases-table';
import { useSearch } from './hooks/useSearch';
import { IReleasesPrRow } from './releases.types';

const Releases: React.FC = React.memo(() => {
  const plumber = (fetchedData, cachedData) => {
    return cachedData.releases;
  };

  return (
    <DataWidget id="releases-table" plumber={plumber} prefetchedDataIds={['releases']}>
      <ReleasesTableDataBinder />
    </DataWidget>
  );
});

const ReleasesTableDataBinder: React.FC = React.memo(() => {
  const { data, isLoading } = useDataWidget();
  const [filteredReleases, setFilteredReleases] = useState([]);

  const allReleases: IReleasesPrRow[] = useMemo(
    () =>
      !!data?.data?.length
        ? data.data
            .sort((a, b) => (a.published > b.published ? -1 : 1))
            .map((d: FilteredRelease) => ({
              ...d,
              author: d.publisher,
              authorAvatar: data.include.users[d.publisher]?.avatar || null,
              contribs: ArrayService.getUniqueValues(d.commit_authors),
              contribsAvatars: ArrayService.getUniqueValues(d.commit_authors).map((c) => ({
                avatar: data.include.users[c]?.avatar || null,
                userName: github.userName(c),
              })),
              reviewer: d.commit_authors,
              // @ts-ignore currently "latest_release" is not shown in schema of FilteredRelease
              status: d.latest_release ? 'Latest release' : '',
              subRows: d.prs.map((pr) => ({
                ...pr,
                repository: d.repository,
                contribs: [pr.author],
                contribsAvatars: [
                  {
                    avatar: data.include.users[pr.author]?.avatar || null,
                    userName: github.userName(pr.author),
                  },
                ],
              })),
            }))
        : [],
    [data]
  );

  const { searchedString, handleSearch } = useSearch({ allReleases, setFilteredReleases });

  useEffect(() => {
    if (allReleases?.length) {
      setFilteredReleases(allReleases);
    }
  }, [allReleases]);

  if (isLoading) {
    return <div className="dataTable-placeholder filter-placeholder" />;
  }

  if (!allReleases.length) {
    return <NoData />;
  } else if (allReleases.length > 5000) {
    return <TooManyRows amount={allReleases.length} />;
  }

  return (
    <ReleasesTable
      releases={filteredReleases}
      searchedString={searchedString}
      onSearch={handleSearch}
    />
  );
});

export { Releases };
