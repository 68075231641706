import _ from 'lodash';

import HorizontalBarChart from '@analytics-components/charts/HorizontalBarChart';
import { GenericTooltip } from '@analytics-components/charts/Tooltip';
import { SimpleKPI, withUnit, UNITS } from '@analytics-components/insights/KPI';
import { palette } from '@analytics-pages/delivery-pipeline/insights/release';
import { StringService } from '@analytics-services/stringService';
import { github } from '@common-services/format';
import { hexToRGBA } from '@utils/colors';

const releaseCreationWaitingRelease = {
  plumber: (fetchedData, cachedData, apiContext) => {
    const reposWaiting = fetchedData.reposWaiting;

    return {
      chartData: _(reposWaiting)
        .take(7)
        .map((repo) => {
          return {
            prs: repo.prs,
            repo: StringService.truncateText(github.repoName(repo.repository), 17),
          };
        })
        .value(),
      KPIsData: {
        waitingPRsCount: reposWaiting.reduce((acc, repo) => acc + repo.prs, 0),
        topWaitingPRsRepo: reposWaiting.length
          ? {
              name: github.repoName(reposWaiting[0].repository),
              prs: reposWaiting[0].prs,
            }
          : {},
      },
    };
  },
  factory: (computed) => {
    const {
      chartData,
      KPIsData: { waitingPRsCount, topWaitingPRsRepo },
    } = computed;
    return {
      content: [
        {
          empty: chartData.length === 0,
          chart: {
            component: HorizontalBarChart,
            title: 'Ready for release',
            description: 'Show the repositories with more PRs waiting for be released.',
            params: {
              data: chartData,
              extra: {
                margin: {
                  left: 100,
                },
                axisKeys: {
                  x: ['prs'],
                  y: 'repo',
                },
                series: {
                  prs: {
                    color: hexToRGBA(palette.barColor, 0.7),
                  },
                },
                tooltip: {
                  align: {
                    horizontal: 'right',
                    vertical: 'auto',
                  },
                  template: GenericTooltip,
                  x: (v) => v.y,
                  y: (v) => withUnit(v.x, UNITS.pr),
                },
              },
              tickFormat: (v) => (v === Math.round(v) ? v : ''),
            },
          },
          kpis: [
            {
              title: { text: 'waiting for release', bold: true },
              component: SimpleKPI,
              params: {
                value: waitingPRsCount,
                unit: UNITS.pr,
              },
            },
            {
              title: { text: 'Repository with more pull requests', bold: true },
              subtitle: { text: 'waiting for release' },
              component: SimpleKPI,
              params: {
                value: `${topWaitingPRsRepo.name} (${withUnit(topWaitingPRsRepo.prs)})`,
              },
            },
          ],
        },
      ],
    };
  },
};

export default releaseCreationWaitingRelease;
