import { useTheme } from '@emotion/react';
import React from 'react';
import { FlexibleWidthXYPlot, YAxis, HorizontalGridLines, VerticalBarSeries } from 'react-vis';

import { hexToRGBA } from '@utils/colors';

const data = [
  5,
  15,
  -51,
  10,
  -1,
  -3,
  9,
  7,
  2,
  55,
  40,
  10,
  -20,
  -10,
  -5,
  -1,
  0,
  0,
  15,
  -5,
  -20,
  -25,
  -30,
  -32,
  -15,
  -10,
  -8,
  -1,
  0,
  3,
  10,
  15,
  20,
  20,
  30,
  40,
];

const Chart = () => {
  const theme = useTheme();

  return (
    <div style={{ background: theme.color.neutral.white }}>
      <PositiveNegativeBarChart data={data} theme={theme} />
    </div>
  );
};

const PositiveNegativeBarChart = ({ data, theme }) => {
  const averages = data.reduce(
    (acc, v) => {
      let side;
      if (v > 0) {
        side = acc.gt;
      } else if (v < 0) {
        side = acc.lt;
      } else {
        return acc;
      }

      side.sum += v;
      side.length++;
      side.avg = side.sum / side.length;
      return acc;
    },
    { gt: { sum: 0, length: 0, avg: 0 }, lt: { sum: 0, length: 0, avg: 0 } }
  );

  return (
    <FlexibleWidthXYPlot height={250}>
      <HorizontalGridLines />
      <YAxis />
      <HorizontalGridLines
        tickValues={[averages.gt.avg]}
        style={{
          stroke: theme.color.ui.green[100],
          strokeWidth: '2px',
          strokeDasharray: [4, 4],
        }}
      />
      <HorizontalGridLines
        tickValues={[averages.lt.avg]}
        style={{
          stroke: theme.color.ui.red[100],
          strokeWidth: '2px',
          strokeDasharray: [4, 4],
        }}
      />
      <VerticalBarSeries
        data={data.map((v, i) => ({ x: i, y: v }))}
        colorType="literal"
        getColor={(v) =>
          v.y < 0 ? hexToRGBA(theme.color.ui.red[100], 0.7) : theme.color.ui.lightblue[100]
        }
      />
    </FlexibleWidthXYPlot>
  );
};

export default Chart;
