import { useTheme } from '@emotion/react';
import React, { useCallback, useRef, useState } from 'react';

import { useOnClickOutside } from '@common-hooks/useOnClickOutside';
import IconButton from '@lib/IconButton';
import { Input } from '@lib/Input';
import Modal from '@lib/Modal';
import { ColorGrid } from '@lib/color-grid';
import { icons } from '@lib/icon';

import { styles } from './styles';

const ContextMenu = ({ workType, onColorChange, onRemove, onRename }) => {
  const theme = useTheme();
  const [isContextMenuOpen, setContextMenuOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [newName, setNewName] = useState(workType.name);
  const ref = useRef(null);
  useOnClickOutside([ref], () => setContextMenuOpen(false));

  const handleIconButtonClick = useCallback(
    (e) => {
      e.stopPropagation();
      setContextMenuOpen(!isContextMenuOpen);
    },
    [isContextMenuOpen]
  );

  const handleColorChange = useCallback((event, color) => {
    event.stopPropagation();
    onColorChange(color);
    setContextMenuOpen(false);
  }, []);

  const handleClickRename = useCallback((e) => {
    e.stopPropagation();
    setModalOpen(true);
    setContextMenuOpen(false);
  }, []);

  const handleClickRemove = useCallback((e) => {
    e.stopPropagation();
    onRemove();
    setContextMenuOpen(false);
  }, []);

  const handleRename = useCallback(() => {
    onRename(newName);
  }, [newName]);

  return (
    <>
      <div css={{ position: 'relative' }}>
        <IconButton icon={icons.dots} onClick={handleIconButtonClick} />
        {isContextMenuOpen && (
          <div css={styles} ref={ref}>
            <div className="ath-menu-item" onClick={handleClickRename}>
              Rename
            </div>
            <div className="ath-divider" />
            <div className="ath-menu-item">
              <ColorGrid
                colors={theme.color.sets.colorSelection}
                value={`#${workType.color}`}
                onClick={handleColorChange}
              />
            </div>
            <div className="ath-divider" />
            <div className="ath-menu-item" onClick={handleClickRemove}>
              Remove
            </div>
          </div>
        )}
      </div>
      <Modal
        content={
          <Input
            initialValue={newName}
            label="Name"
            placeholder="New Features"
            width={410}
            onChange={setNewName}
          />
        }
        isOpen={isModalOpen}
        portalId="dialog-holder"
        primaryButtonText="Rename"
        secondaryButtonText="Cancel"
        title="Rename Work Type"
        onPrimaryClick={handleRename}
        setOpen={setModalOpen}
      />
    </>
  );
};

export default ContextMenu;
