import { BasicApiClass } from '@common-services/api/common/BasicApiClass';
import { IUrlParams, REST_TYPES } from '@common-services/api/common/types/apiClient';
import { ApiMode } from '@common-services/api/common/types/common';
import { ISentryMethodParams } from '@common-services/api/common/types/sentry';
import { PUBLIC_API_ENDPOINTS_CONSTANTS } from '@common-services/api/public/constants';
import {
  JIRAProject,
  FilteredJIRAStuff,
  FilterJIRAStuff,
  FilterLabelsRequest,
  FilteredLabels,
} from '@common-services/api/public/generated-from-backend/models';

export class FiltersApi extends BasicApiClass {
  constructor() {
    super(ApiMode.PUBLIC);
  }
  public getJiraFilter(
    params: FilterJIRAStuff,
    token: string,
    sentry?: ISentryMethodParams
  ): Promise<FilteredJIRAStuff> {
    this.assignToken(token);
    const callApi = this.callApi<FilteredJIRAStuff>(
      PUBLIC_API_ENDPOINTS_CONSTANTS.JIRA_FILTERS,
      REST_TYPES.POST,
      params
    );
    return sentry ? this.callSentry<FilteredJIRAStuff>(callApi, sentry) : callApi;
  }
  public getLabelsFilter(
    params: FilterLabelsRequest,
    token: string,
    sentry?: ISentryMethodParams
  ): Promise<FilteredLabels> {
    this.assignToken(token);
    const callApi = this.callApi<FilteredLabels>(
      PUBLIC_API_ENDPOINTS_CONSTANTS.LABELS_FILTERS,
      REST_TYPES.POST,
      params
    );
    return sentry ? this.callSentry<FilteredLabels>(callApi, sentry) : callApi;
  }
  public getJiraProjects(
    urlParams: IUrlParams<'accountId'>,
    token: string,
    sentry?: ISentryMethodParams
  ): Promise<JIRAProject[]> {
    const apiPath: string = this.replaceRouteLink(
      PUBLIC_API_ENDPOINTS_CONSTANTS.JIRA_PROJECTS,
      urlParams
    );
    this.assignToken(token);
    const callApi = this.callApi<JIRAProject[]>(apiPath, REST_TYPES.GET);
    return sentry ? this.callSentry<JIRAProject[]>(callApi, sentry) : callApi;
  }
}
