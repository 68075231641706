import React, { useMemo } from 'react';

import { DateService } from '@align-services/dateService';
import { Icon, icons } from '@lib/icon';
import { useTheme } from '@styles/hooks';

import { useParams } from './activeGoalProgress.hooks';
import { wrapperStyles } from './activeGoalProgress.styles';
import { IActiveGoalProgress } from './activeGoalProgress.types';

export const ActiveGoalProgress: React.FC<IActiveGoalProgress> = React.memo(
  ({ range, value, unit, isPercentage }) => {
    const { currentValue, targetValue } = useParams(value, unit, range, isPercentage);
    const theme = useTheme();
    const isFutureGoal = useMemo(() => {
      if (range?.dateFrom) {
        return DateService.isAfterToday(range.dateFrom);
      }
    }, [range?.dateFrom]);

    return (
      <div css={wrapperStyles}>
        {!isFutureGoal && currentValue && <div className="current">{currentValue}</div>}
        <div className="target">
          <Icon icon={icons.goal} size={12} color={theme.color.ui.blue['100']} />
          {targetValue}
        </div>
      </div>
    );
  }
);
