import { useMutation } from 'react-query';

import { IRemoveGoalId } from '@align-services/api/types/goalsTypes';
import { IUseMutationHook } from '@align-types/common';
import { useAuth0 } from '@common-context/Auth0';
import { useDemoCookie } from '@common-pages/Demo';
import { IApiBasicError } from '@common-services/api/common/types/common';
import { GoalUnassignTeamRequest } from '@common-services/api/private/generated-from-backend/models';
import { AlignGoalsServices } from '@common-services/api/private/services/alignGoals';
import { CreatedIdentifier } from '@common-services/api/public/generated-from-backend/models';

export function useRemoveGoal(
  params: GoalUnassignTeamRequest
): IUseMutationHook<CreatedIdentifier, IApiBasicError, IRemoveGoalId> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();
  const removeGoal = async (input: IRemoveGoalId): Promise<CreatedIdentifier> => {
    const token: string = isDemo === false ? await getTokenSilently() : null;
    return AlignGoalsServices.removeGoal(input, params, token);
  };

  const { mutate, isLoading, data, error, isError, isSuccess } = useMutation<
    CreatedIdentifier,
    IApiBasicError,
    IRemoveGoalId
  >(removeGoal);

  return { mutate, isLoading, data, error, isError, isSuccess };
}
