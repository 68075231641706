import { sanitize } from '@common-services/htmlService';

export const getTitleAndReleaseUrl = (name: string, repository: string): [string, string] => {
  let cleanTitle = sanitize(name).replace(/"/g, '&quot;');
  const isHash = !cleanTitle.match(/[^a-zA-Z0-9]/gi);
  // v1.3.4 otherwise take the 1st 7 chars of hash
  if (isHash) {
    cleanTitle = cleanTitle.slice(0, 7);
  }
  return [cleanTitle, `https://${repository}/${isHash ? `commit` : `releases/tag`}/${cleanTitle}`];
};
