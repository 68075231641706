import React, { useMemo } from 'react';

import Tabs from '@analytics-components/Tabs';
import Breadcrumb from '@ui/Breadcrumb';

import WorkTypes from './WorkTypes';

const Allocation = () => {
  const tabs = useMemo(
    () => [
      {
        title: 'Work Types',
        content: <WorkTypes />,
      },
    ],
    []
  );

  return (
    <>
      <Breadcrumb breadcrumb={[{ name: 'Settings' }, { name: 'Allocation' }]} />
      <Tabs tabs={tabs} initialValue={0} leftAligned />
    </>
  );
};

export default Allocation;
