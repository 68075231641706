/**
 * {{ title }}
 * Server version: {{ server_version }} built on {{ build_date }} @ [{{ commit }}](https://github.com/athenianco/athenian-api/commit/{{ commit }})  Origin GitHub repository: [athenianco/api-spec](https://github.com/athenianco/api-spec).  Authorization persists between sessions. 🛡️ means that the endpoint requires account admin privileges. 👑 means that the endpoint is only accessible to Athenian authorities. <details> <summary>List of common server errors.</summary> * __500__ endpoint crashed. We reported the incident to Sentry and will fix it soon! Please include the value of `instance` in the response if you contact the support. Repeating the request will not help, most probably. * __501__ some functions are missing, either because they are not implemented yet or a non-critical runtime dependency is not satisfied. Repeating the request will not help, guaranteed. * __502__ server crashed badly, either due to a memory access violation in native code or running out of memory. We reported this incident to Sentry and will fix it soon! Try repeating the request. * __503__ server has not fully launched yet, e.g. hasn't connected to the database; server is shutting down; we are experiencing a partial outage. Try repeating the request. * __504__ endpoint took too much time and was interrupted. We reported the incident to Sentry and will see how to improve the performance. Repeating the request will not help, most probably. </details>
 *
 * Contact: admin@athenian.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The JIRAFilter model module.
 * @module model/JIRAFilter
 */
class JIRAFilter {
    /**
     * Constructs a new <code>JIRAFilter</code>.
     * PR filters related to JIRA traits. The aggregation between each filter type is *AND*.
     * @alias module:model/JIRAFilter
     */
    constructor() { 
        
        JIRAFilter.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>JIRAFilter</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/JIRAFilter} obj Optional instance to populate.
     * @return {module:model/JIRAFilter} The populated <code>JIRAFilter</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new JIRAFilter();

            if (data.hasOwnProperty('epics')) {
                obj['epics'] = ApiClient.convertToType(data['epics'], ['String']);
            }
            if (data.hasOwnProperty('labels_include')) {
                obj['labels_include'] = ApiClient.convertToType(data['labels_include'], ['String']);
            }
            if (data.hasOwnProperty('labels_exclude')) {
                obj['labels_exclude'] = ApiClient.convertToType(data['labels_exclude'], ['String']);
            }
            if (data.hasOwnProperty('issue_types')) {
                obj['issue_types'] = ApiClient.convertToType(data['issue_types'], ['String']);
            }
            if (data.hasOwnProperty('projects')) {
                obj['projects'] = ApiClient.convertToType(data['projects'], ['String']);
            }
            if (data.hasOwnProperty('unmapped')) {
                obj['unmapped'] = ApiClient.convertToType(data['unmapped'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * PRs must be linked to at least one JIRA epic from the list.
 * @member {Array.<String>} epics
 */
JIRAFilter.prototype['epics'] = undefined;

/**
 * PRs must relate to at least one JIRA issue label from the list. Several labels may be concatenated by a comma `,` so that all of them are required.
 * @member {Array.<String>} labels_include
 */
JIRAFilter.prototype['labels_include'] = undefined;

/**
 * PRs cannot relate to JIRA issue labels from the list.
 * @member {Array.<String>} labels_exclude
 */
JIRAFilter.prototype['labels_exclude'] = undefined;

/**
 * PRs must be linked to certain JIRA issue types, e.g. Bug, Task, Design Document, etc.
 * @member {Array.<String>} issue_types
 */
JIRAFilter.prototype['issue_types'] = undefined;

/**
 * PRs must be linked to any JIRA issue in the given project keys.
 * @member {Array.<String>} projects
 */
JIRAFilter.prototype['projects'] = undefined;

/**
 * Select PRs that are not mapped to any JIRA issue. May not be specified with anything else.
 * @member {Boolean} unmapped
 */
JIRAFilter.prototype['unmapped'] = undefined;






export default JIRAFilter;

