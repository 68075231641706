import _ from 'lodash';
import React, { useRef, useState } from 'react';
import Select, { components } from 'react-select';

import * as customComponents from './CustomComponents';
import omniboxStyles from './styles';

function Omnibox({ options, onApply, applied }) {
  const selectInputRef = useRef();
  const [selectedOptions, setSelectedOptions] = useState((prev) => (applied ? [...applied] : []));

  const IndicatorsContainer = (props) => {
    const { children, getValue, ...rest } = props;
    const values = getValue();
    const apply = () => {
      onApply(values);
      selectInputRef.current.blur();
    };

    const appliedEqualSelected = !_.xor(
      values.map((v) => v.value),
      (applied || []).map((a) => a.value)
    ).length;
    return (
      <components.IndicatorsContainer {...rest}>
        {' '}
        {children}{' '}
        {!appliedEqualSelected && (
          <button
            onClick={apply}
            className="btn btn-orange px-3"
            style={{
              marginRight: 8,
            }}
          >
            Apply{' '}
          </button>
        )}{' '}
      </components.IndicatorsContainer>
    );
  };

  const onInputChange = (_string, { action }) => {
    if (action === 'menu-close') {
      const appliedEqualSelected = !_.xor(
        (selectedOptions || []).map((v) => v.value),
        applied.map((a) => a.value)
      ).length;
      if (!appliedEqualSelected) {
        onApply(selectedOptions || []);
      }
    }
  };

  return (
    <Select
      ref={selectInputRef}
      openMenuOnFocus={false}
      isMulti
      isClearable={false}
      closeMenuOnSelect={false}
      blurInputOnSelect={false}
      controlShouldRenderValue={false}
      onInputChange={onInputChange}
      onChange={setSelectedOptions}
      cacheOptions
      defaultOptions
      placeholder="Filter by Epics, Tickets, Labels..."
      options={Object.values(options)}
      formatOptionLabel={(option) => option.label}
      components={{
        ...customComponents,
        DropdownIndicator: () => null,
        IndicatorsContainer,
      }}
      styles={omniboxStyles}
      value={selectedOptions}
    />
  );
}

export default function OmniboxWrapper() {
  // const options = [
  //   {
  //     label: 'Labels',
  //     options: [
  //       { value: 'webapp1', label: 'webapp1', type: 'label', subtype: 'pull_request' },
  //       { value: 'webapp3', label: 'webapp3', type: 'label', subtype: 'ticket' },
  //       { value: 'Bug', label: 'Bug', type: 'label', subtype: 'bug' },
  //     ],
  //   },
  //   {
  //     label: 'Epics',
  //     options: [
  //       {
  //         value: '1',
  //         label: 'Enhance the user experience of the webapp',
  //         type: 'epic',
  //         subtype: 'epic',
  //       },
  //       {
  //         value: '2',
  //         label: 'Share on Slack from the webapp a PR that requires attention',
  //         type: 'epic',
  //         subtype: 'epic',
  //       },
  //     ],
  //   },
  // ];
  const jirafilters = {
    epics: [
      {
        id: 'DEV-158',
        title: 'Precomputed DB - stage 2',
        updated: '2020-08-03T23:47:00Z',
        children: ['DEV-157', 'DEV-156', 'DEV-533', 'DEV-536', 'DEV-538', 'DEV-539', 'DEV-620'],
      },
      {
        id: 'DEV-158',
        title: 'Precomputed DB - stage 2',
        updated: '2020-08-03T23:47:00Z',
        children: ['DEV-157', 'DEV-156', 'DEV-533', 'DEV-536', 'DEV-538', 'DEV-539', 'DEV-620'],
      },
    ],
    labels: [
      {
        title: 'performance',
        last_used: '2020-08-10T12:47:00Z',
        issues_count: 300,
        kind: 'Label',
      },
      {
        title: 'enhancements',
        last_used: '2020-08-10T12:47:00Z',
        issues_count: 300,
        kind: 'Label',
      },
    ],
  };

  const mappedJiraLabels = Object.entries(jirafilters).reduce((acc, [label, options]) => {
    if (!acc[label]) {
      acc[label] = {
        label,
        options: options.map(({ title, kind }) => ({
          label: title,
          type: kind ? kind.toLowerCase() : label,
          subtype: label,
          source: 'jira',
          value: title,
        })),
      };
    }
    return acc;
  }, {});

  const labels = [
    {
      name: 'enhancement',
      description: null,
      color: '84b6eb',
      used_prs: 7,
    },
    {
      name: 'bug',
      description: null,
      color: 'fc2929',
      used_prs: 5,
    },
    {
      name: 'plumbing',
      description: null,
      color: '4faccc',
      used_prs: 4,
    },
    {
      name: 'performance',
      description: null,
      color: 'fbca04',
      used_prs: 2,
    },
    {
      name: 'ssh',
      description: null,
      color: 'b0f794',
      used_prs: 2,
    },
    {
      name: 'documentation',
      description: null,
      color: 'bfdadc',
      used_prs: 1,
    },
    {
      name: 'windows',
      description: null,
      color: 'd4c5f9',
      used_prs: 1,
    },
  ];

  const combinedFilterOptions = labels.reduce((acc, curr) => {
    if (acc.labels) {
      acc.labels.options.push({
        label: curr.name,
        type: 'label',
        subtype: 'pull_request',
        source: 'github',
        value: curr.name,
      });
    }
    return acc;
  }, mappedJiraLabels);

  const [applied, onApply] = useState([]);
  return (
    <Omnibox options={Object.values(combinedFilterOptions)} onApply={onApply} applied={applied} />
  );
}
