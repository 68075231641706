import { SelectTsOptionSingle } from '@lib/selector-ts';

export enum FilterDropdownType {
  BADGE = 'badge',
  ITEMS = 'items',
}
export interface IFilterDropdownBox {
  label?: string;
  isAllSelected?: boolean;
  allSelectedLabel?: string;
  onClick?: () => void;
  badge?: string;
  isLoading?: boolean;
  options?: SelectTsOptionSingle[];
  selectedItems?: string[];
  type?: FilterDropdownType;
  minWidth?: number;
  fullWidth?: boolean;
}
