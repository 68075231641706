import React from 'react';

import { IAddTeam } from './addTeam.types';
import { TeamForm } from './team-form';

const AddTeam: React.FC<IAddTeam> = React.memo(({ teams, onSave }) => {
  return (
    <TeamForm
      btnText={'Add New Team'}
      onSave={onSave}
      teams={teams}
      options={{
        nameChangeEnabled: true,
        parentChangeEnabled: true,
        membersChangeEnabled: true,
      }}
    />
  );
});

export { AddTeam };
