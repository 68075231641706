import { css } from '@emotion/react';

export const wrapperStyles = ({ color, font, spacing }) => css`
  .SingleDatePickerInput {
    align-items: center;
    display: flex;

    .DateInput {
      align-items: center;
      display: flex;
      width: 100%;

      .DateInput_input {
        border: 0;
        color: ${color.text.dark};
        font-size: ${font.size.default};
        font-weight: ${font.weight.regular};
        padding: 0;
        margin: 0;
        width: 100px;
      }

      .DateInput_fang {
        display: none;
      }
    }

    .SingleDatePickerInput_calendarIcon {
      padding: ${spacing.gap['04']};
      margin: 0;

      svg {
        fill: ${color.neutral[80]};
      }
    }
  }

  .SingleDatePicker_picker {
    z-index: 10;
  }

  .CalendarDay__default {
    font-size: ${font.size.xs};
    border: none;
    font-weight: ${font.weight.light};
  }

  .CalendarMonth_caption {
    color: ${color.neutral[100]};
    font-size: ${font.size.default};
    font-weight: ${font.weight.light};
  }

  .CalendarDay__selected_span {
    background: ${color.ui.lightorange[100]};
    color: ${color.neutral.white};
    border: 1px solid ${color.neutral.white};
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: ${color.ui.orange[100]};
    border: 1px solid ${color.neutral.white};
    color: ${color.neutral.white};
  }

  .CalendarDay__selected:hover,
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: ${color.ui.lightorange[100]};
    border-color: ${color.neutral.white};
    color: ${color.neutral.white};
  }

  .CalendarDay__selected:hover,
  .CalendarDay__selected_span:hover {
    opacity: 0.7;
  }

  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: ${color.ui.orange[100]};
    color: ${color.neutral.white};
    border: 1px solid ${color.neutral.white};
  }

  .CalendarDay__today {
    font-weight: ${font.weight.bold};
  }
`;

export const footerWrapperStyles = ({ color, spacing, radius }) => css`
  align-items: center;
  background-color: ${color.neutral.white};
  border-top: 1px solid ${color.neutral[40]};
  border-bottom-left-radius: ${radius.default};
  border-bottom-right-radius: ${radius.default};
  display: flex;
  height: 60px;
  justify-content: flex-end;
  padding: ${spacing.gap['04']} ${spacing.gap['06']};

  button:first-of-type {
    margin-right: ${spacing.gap['03']};
  }
`;

export const arrowIconWrapper = (position) => ({ color, spacing }) => css`
  border: 1px solid ${color.neutral[40]};
  ${position === 'right' ? 'left: 22px;' : 'right: 22px;'}
  padding: ${spacing.gap['03']} ${spacing.gap['04']};
  position: absolute;
  top: 20px;
`;
