import { css } from '@emotion/react';

export const getContainerSize = () => css`
  width: 100%;
`;

export const getSelectAllStyle = ({ spacing, color }) => css`
  width: 100%;
  background: ${color.neutral['40']};
  padding-left: ${spacing.gap['04']};
  padding-right: ${spacing.gap['04']};
  margin-top: -${spacing.gap['04']};
`;
