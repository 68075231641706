import quantiles from 'compute-quantiles';
import { histogram } from 'd3-array';

import DonutChart from '@analytics-components/charts/DonutChart';
import { SimpleKPI } from '@analytics-components/insights/KPI';
import { palette } from '@analytics-pages/delivery-pipeline/insights/release';
import { BIG_RELEASE_THRESHOLD } from '@analytics-pages/delivery-pipeline/insights/release/releaseSize';
import { number } from '@common-services/format';
import * as NumberService from '@common-services/numberService';

const donutTicks = (biggestTreshHold) => [0, 1, 5, 15, biggestTreshHold];

const releaseSizeDonut = {
  plumber: (fetchedData, cachedData, apiContext) => {
    const releasesCount = cachedData['releases-metrics']['values']['all']['count'];

    // TODO: DEPRECATED::GLOBAL_RELEASES_ABUSE (ready, DEV-622)
    // API should let define ticks for /histogram/releases::release-prs[ticks:[1, 5, 15, 30, 50]]
    // see https://athenianco.atlassian.net/browse/DEV-622
    const releases = fetchedData.releases.data
      .map((release) => release.prs.length)
      .sort((a, b) => a - b);
    const { distribution: donutDistribution, limit, belowLimit } = donutLinesDistribution(
      releases,
      donutTicks(BIG_RELEASE_THRESHOLD),
      'pull requests'
    );

    return {
      chartData: donutDistribution,
      KPIsData: {
        upperLimit: limit,
        prsProportionBelowThreshold: belowLimit / releasesCount,
        // TODO: DEPRECATED::GLOBAL_PRS_ABUSE (ready, DEV-622 interquartile)
        interquartile: interquartileRange(releases),
      },
    };
  },
  factory: (computed) => {
    const {
      chartData,
      KPIsData: { upperLimit, prsProportionBelowThreshold, interquartile: itq },
    } = computed;

    let interquartileKPI = '';
    if (itq[0] >= 0 && itq[1] >= 0) {
      interquartileKPI = `${NumberService.round(itq[0])} - ${NumberService.round(
        itq[1]
      )} pull requests`;
    }

    return {
      content: [
        {
          empty: !chartData?.find((bin) => bin.value),
          chart: {
            component: DonutChart,
            params: {
              data: chartData,
              extra: {
                colors: palette.five,
                tooltip: {
                  y: (v) => `${v} releases`,
                },
              },
            },
          },
          kpis: [
            {
              title: { text: 'Proportion of releases', bold: true },
              subtitle: { text: `under ${upperLimit} pull requests` },
              component: SimpleKPI,
              params: {
                value: number.percentage(100 * prsProportionBelowThreshold, 0),
              },
            },
            {
              title: { text: 'Interquartile range', bold: true },
              component: SimpleKPI,
              params: {
                value: interquartileKPI,
              },
            },
          ],
        },
      ],
    };
  },
};

export const interquartileRange = (lines) => {
  if (!lines.length) return [];

  // TODO(dpordomingo): This should come from the API when there is an available endpoint
  // 'quantiles' function from https://github.com/compute-io/quantile lib returns q-quantiles.
  // 4-quantile is also known as interquartile range, midspread or middle fifty (https://en.wikipedia.org/wiki/Quantile#Specialized_quantiles)
  const interquartileRanges = quantiles(lines, 4);
  if (interquartileRanges.length < 5) {
    return [];
  }

  return [interquartileRanges[1], interquartileRanges[3]];
};

// TODO(dpordomingo): This should come from the API when there is an available endpoint
export const donutLinesDistribution = (lines, ticks, labelSuffix) => {
  const upperLimit = ticks.slice(-1)[0];

  if (!lines.length) return { limit: upperLimit, belowLimit: 0 };

  const bins = histogram().domain([ticks[0], Number.POSITIVE_INFINITY]).thresholds(ticks)(lines);

  const res = bins.reduce(
    (acc, bin) => {
      acc.frequencies.push({
        value: bin.length,
        label:
          bin.x0 < upperLimit
            ? `${bin.x0} - ${bin.x1} ${labelSuffix}`
            : `${bin.x0}+ ${labelSuffix}`,
      });

      if (bin.x0 < upperLimit) {
        acc.belowLimit += bin.length;
      }

      return acc;
    },
    { frequencies: [], belowLimit: 0 }
  );

  return {
    distribution: res.frequencies,
    belowLimit: res.belowLimit,
    limit: upperLimit,
  };
};

export default releaseSizeDonut;
