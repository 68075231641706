import { useCallback, useMemo, useState } from 'react';

import {
  ITableFilterOption,
  IUseFilter,
} from '@analytics-components/tables/table-filter/tableFilter.types';
import { uniqBy } from '@common-services/vendor/lodash';

import { IDeploymentsRow } from '../deployments.types';

export const useFilter = (
  allDeployments: IDeploymentsRow[],
  setFilteredDeployments: React.Dispatch<React.SetStateAction<IDeploymentsRow[]>>
): IUseFilter => {
  const [currentFilter, setCurrentFilter] = useState<ITableFilterOption>(null);

  const filterOptions = useMemo<ITableFilterOption[]>(() => {
    let options = [];
    options = uniqBy(allDeployments, 'environment').map((d) => d.environment);
    return [{ label: 'All' }, ...options.map((value) => ({ label: value, value }))];
  }, [allDeployments]);

  const placeholder = 'Filter by environment';

  const handleFilter = useCallback(
    (option: ITableFilterOption) => {
      setCurrentFilter(option);
      if (option?.value) {
        setFilteredDeployments(allDeployments.filter((d) => d.environment === option.value));
      } else {
        setFilteredDeployments(allDeployments);
      }
    },
    [allDeployments]
  );

  return { currentFilter, filterOptions, placeholder, handleFilter };
};
