import { IUrlParams } from '@common-services/api/common/types/apiClient';
import { privateApi } from '@common-services/api/private';
import {
  DashboardChartCreateRequest,
  DashboardChartUpdateRequest,
  DashboardUpdateRequest,
} from '@common-services/api/private/generated-from-backend/models';
import { TeamDashboard } from '@common-services/api/private/types/dashboard';
import { CreatedIdentifier } from '@common-services/api/public/generated-from-backend/models';

export const getDashboard = async (
  params: IUrlParams<'dashboardId' | 'teamId'>,
  token: string
): Promise<TeamDashboard> => {
  return privateApi.dashboard.getDashboard(params, token, {
    message: `Cannot get dashboard from private API`,
  });
};

export const addChartToDashboard = async (
  urlParams: IUrlParams<'dashboardId' | 'teamId'>,
  params: DashboardChartCreateRequest,
  token: string
): Promise<CreatedIdentifier> => {
  return privateApi.dashboard.addChartToDashboard(urlParams, params, token, {
    message: `Cannot add a new chart to dashboard with private API`,
  });
};

export const removeChartFromDashboard = async (
  urlParams: IUrlParams<'dashboardId' | 'teamId' | 'chartId'>,
  token: string
): Promise<void> => {
  return privateApi.dashboard.removeChartFromDashboard(urlParams, token, {
    message: `Cannot add a new chart to dashboard with private API`,
  });
};

export const reorderChartsOnDashboard = async (
  urlParams: IUrlParams<'dashboardId' | 'teamId'>,
  params: DashboardUpdateRequest,
  token: string
): Promise<TeamDashboard> => {
  return privateApi.dashboard.reorderChartsOnDashboard(urlParams, params, token, {
    message: `Cannot change the order of the charts`,
  });
};

export const editChart = async (
  urlParams: IUrlParams<'dashboardId' | 'teamId' | 'chartId'>,
  params: DashboardChartUpdateRequest,
  token: string
): Promise<CreatedIdentifier> => {
  return privateApi.dashboard.editChart(urlParams, params, token, {
    message: `Cannot edit a chart with ID ${urlParams.chartId} with private API`,
  });
};

export * as DashboardServices from './dashboard';
