export const PRODUCTION_ENV = 'production';

export enum GranularityEnum {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export enum StagesEnum {
  SUMMARY = 'summary',
  WIP = 'wip',
  REVIEW = 'review',
  MERGE = 'merge',
  RELEASE = 'release',
  DEPLOY = 'deploy',
}
