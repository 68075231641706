import { css } from '@emotion/react';

export const bigNumberStyles = ({ color, font, letter, spacing }, isXL, isLight) => css`
  color: ${isLight ? color.text.secondary : color.text.dark};
  display: inline-block;
  font-size: ${isXL ? font.size.xxl : font.size.l};
  font-weight: ${font.weight.bold};
  letter-spacing: ${isXL ? font.spacing.xxl : font.spacing.l};
  line-height: ${isXL ? font.size.xxl : font.size.l};
  margin-right: ${spacing.gap['02']};
  vertical-align: middle;

  .active & {
    color: ${color.neutral.white};
  }
`;

export const smallTitleStyles = ({ color, font, spacing }, isUppercase, isBlack) => css`
  color: ${isBlack ? color.text.dark : color.text.secondary};
  font-size: ${font.size.default};
  line-height: 1.4rem;
  text-transform: ${isUppercase ? 'uppercase' : 'capitalize'};
  vertical-align: middle;
`;

export const cardTitleStyles = ({ color, font, spacing }) => css`
  color: ${color.text.dark};
  display: inline-block;
  font-size: ${font.size.s};
  font-weight: ${font.weight.regular};
  margin-right: ${spacing.gap['02']};
  vertical-align: middle;
`;

export const altTitleStyles = ({ color, font, spacing }, uppercase, inline, noMargin, link) => css`
  color: ${color.text.secondary};
  display: ${inline ? 'inline-block' : 'block'};
  font-size: ${font.size.default};
  font-weight: ${font.weight.regular};
  margin-bottom: ${noMargin ? spacing.gap[0] : spacing.gap['02']};
  text-transform: ${uppercase ? 'uppercase' : 'none'};
  vertical-align: middle;

  &:hover {
    text-decoration: ${link ? 'underline' : 'none'};
  }
`;
