import React from 'react';

import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import Panel from '@analytics-components/insights/Panel';
import { prepareChartBoxes } from '@analytics-pages/delivery-pipeline/insights/helper';

import workByLabel from './work-by-label';

const meta = {
  title: 'Tickets Resolved per Label',
  description:
    'Use insightful Jira labels to get a representative picture of the team focus during your work cycles.',
};

const chartBoxes = [
  {
    id: 'work-by-label',
    component: ChartBoxWithKPIWidget,
    params: workByLabel,
  },
];

const WorkByLabelPanel = () => {
  const preparedChartBoxes = prepareChartBoxes(chartBoxes);
  return <Panel meta={meta} chartBoxes={preparedChartBoxes} />;
};

export default WorkByLabelPanel;
