import { PR_STAGE, PR_STATUS } from '@common-services/prHelpers';
import { theme } from '@styles/theme';
import { IColor } from '@styles/types';

export const getColor = (status: PR_STATUS): IColor => {
  switch (status) {
    case PR_STATUS.OPENED:
      return theme.color.ui.green[100];
    case PR_STATUS.MERGED:
      return theme.color.ui.purple[100];
    case PR_STATUS.CLOSED:
      return theme.color.ui.red[100];
    default:
      break;
  }
};

export const getColumnTitle = (stage: PR_STAGE): string => {
  switch (stage) {
    case PR_STAGE.SUMMARY:
      return 'Cycle Time';
    default:
      return `${stage} Time`;
  }
};
