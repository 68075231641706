import React from 'react';

import { Badge, BadgeColorType } from '@lib/Badge';

import { wrapperStyles } from './filterDropdownBoxBadge.styles';
import { IFilterDropdownBoxBadge } from './filterDropdownBoxBadge.types';

export const FilterDropdownBoxBadge: React.FC<IFilterDropdownBoxBadge> = React.memo(
  ({ label, badge }) => {
    return (
      <div css={wrapperStyles}>
        {label && <div className="label">{label}</div>}
        {badge && <Badge text={badge} type={BadgeColorType.TURQUOISE} />}
      </div>
    );
  }
);
