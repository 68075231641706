import React from 'react';
import { Link } from 'react-router-dom';

import { Badge, BadgeColorType } from '@lib/Badge';

import { getStyle } from './Tab.styles';
import { ITab } from './Tab.types';

const Tab: React.FC<ITab> = React.memo(({ label, value, isActive, route, dataPw, onClick }) => {
  const tab = () => {
    return (
      <div css={getStyle(isActive)} onClick={onClick}>
        <div className="label-wrapper">
          <span>{label}</span>
          {value != null && <Badge text={value} type={BadgeColorType.NEUTRAL} />}
        </div>
      </div>
    );
  };
  return (
    <>
      {route && (
        <Link data-pw={dataPw || ''} to={route}>
          {tab()}
        </Link>
      )}
      {!route && tab()}
    </>
  );
});

export { Tab };
