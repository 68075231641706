import { ContentType } from '@analytics-components/insights/Box/types';
import { GranularityEnum } from '@analytics-constants';
import { IntervalType } from '@analytics-types/common';

import cycleTime from './cycleTime';
import deploymentFrequency from './deploymentFrequency';
import { DataValuesType } from './types';

export const getContent = (
  data: DataValuesType,
  granularity: GranularityEnum,
  interval: IntervalType
): ContentType[] => {
  const depFrequencyData = {
    granularity: granularity,
    interval: interval,
    values: data?.depFrequency || [],
    kpisData: {
      depFrequency: data?.kpisData.depFrequency,
      deployments: data?.kpisData.deployments,
      prsCount: data?.kpisData.prsCount,
    },
  };

  const cycleTimeData = {
    env: data?.env,
    granularity: granularity,
    interval: interval,
    values: data?.cycleTime || [],
    kpisData: {
      cycleTime: data?.kpisData.cycleTime,
      prsCount: data?.kpisData.prsCount,
    },
  };

  return [deploymentFrequency(depFrequencyData), cycleTime(cycleTimeData)];
};
