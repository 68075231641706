import { CellProps, Column } from 'react-table';

import { IssuesCellAssignee } from '@align-components/issues-table-columns/cell-assignee';
import { IssuesCellComments } from '@align-components/issues-table-columns/cell-comments';
import { IssuesCellExpand } from '@align-components/issues-table-columns/cell-expand';
import { IssuesCellMetric } from '@align-components/issues-table-columns/cell-metric';
import { IssuesCellPriority } from '@align-components/issues-table-columns/cell-priority';
import { IssuesCellSize } from '@align-components/issues-table-columns/cell-size';
import { IssuesCellStatus } from '@align-components/issues-table-columns/cell-status';
import { IssuesCellTitle } from '@align-components/issues-table-columns/cell-title';
import { metricsConfig } from '@align-constants';
import { ITemplateMetric } from '@align-services/api/types/goalsTypes';
import { IMetricsConfigNames } from '@align-types/constants';
import { IIssuesTableRow } from '@align-types/issues';

export const issuesTableColumns = (templateMetric: ITemplateMetric): Column<IIssuesTableRow>[] => [
  {
    accessor: 'title',
    Cell: IssuesCellExpand,
  },
  {
    Header:
      metricsConfig[templateMetric]?.mainKPI === IMetricsConfigNames.jiraLifeTime
        ? 'Bugs'
        : 'Issues',
    Cell: IssuesCellTitle,
  },
  {
    Header: 'Size',
    Cell: IssuesCellSize,
  },
  {
    Header: 'Comments',
    Cell: IssuesCellComments,
  },
  {
    Header: 'Assignee',
    Cell: IssuesCellAssignee,
  },
  {
    Header: metricsConfig[templateMetric]?.displayName || '',
    Cell: (cellProps: CellProps<IIssuesTableRow>) => (
      <IssuesCellMetric
        {...cellProps}
        metricFieldName={metricsConfig[templateMetric]?.jiraMetricField}
      />
    ),
  },
  {
    Header: 'P',
    Cell: IssuesCellPriority,
  },
  {
    Header: 'Status',
    Cell: IssuesCellStatus,
  },
];
