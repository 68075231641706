import { IUrlParams } from '@common-services/api/common/types/apiClient';
import { privateApi } from '@common-services/api/private';
import { TeamTree } from '@common-services/api/private/generated-from-backend/models';
import { ITeamTreeParams } from '@common-services/api/private/types/teamTree';

export const getTeamTree = async (
  urlParams: IUrlParams<'teamId'>,
  params: ITeamTreeParams,
  token: string
): Promise<TeamTree> => {
  return privateApi.teamTree.getTeamTree(urlParams, params, token, {
    message: `Cannot get team tree for teamId ${urlParams.teamId} and accountId ${params.account}`,
  });
};

export * as TeamTreeServices from './teamTree';
