import {
  DashboardChart,
  DashboardChartFilters,
  DashboardChartGroupBy,
} from '@common-services/api/private/generated-from-backend/models';

export enum ChartParamsReducerTypes {
  INIT = 'init',
  DATE_CHANGE = 'date_change',
  FILTERS_UPDATE = 'filters_update',
  GROUP_BY_UPDATE = 'group_by_update',
}

export interface IChartParamsReducerAction {
  type: ChartParamsReducerTypes;
  params?: DashboardChart;
  dateParams?: Pick<DashboardChart, 'date_to' | 'date_from' | 'time_interval'>;
  filtersParams?: DashboardChartFilters;
  groupByParams?: DashboardChartGroupBy;
}
