import React, { useContext, useMemo } from 'react';

import Checkbox from '@analytics-components/MultiSelect/ui/Checkbox';
import { ObjectService } from '@common-services/objectService';
import { Badge, BadgeColorType } from '@lib/Badge';
import { SelectTsOptionSingle } from '@lib/selector-ts';
import { SelectorTsOptionOpenRow } from '@lib/selector-ts/components/optionOpenRow';
import { SelectorTsContext } from '@lib/selector-ts/context';
import { getStyleOption } from '@lib/selector-ts/styles/option';
import { ClickTypeEnum } from '@lib/selector-ts/types/context';

type Props = SelectTsOptionSingle & {
  level?: number;
  allOptionsCounter?: number;
};

export const SelectorTsOption: React.FC<Props> = ({
  id,
  name,
  subName,
  isSelected,
  isOpened,
  parentIds,
  children,
  level,
  allOptionsCounter,
  image,
  color,
}) => {
  const { clickHandler } = useContext(SelectorTsContext);
  const unselectedChildrenIds: string[] = useMemo(() => {
    return ObjectService.getNestedObjAllIdsByKeyValue(
      { id: null, children: children || [], isSelected },
      'id',
      null
    );
  }, [id, children, isSelected]);
  return (
    <div css={getStyleOption(level, children?.length > 0, allOptionsCounter)}>
      <div
        data-cy="selector-option-info"
        className="info-wrapper"
        onClick={(event) =>
          clickHandler(
            event,
            id,
            ClickTypeEnum.isSelected,
            isSelected,
            parentIds,
            unselectedChildrenIds.filter((n) => n)
          )
        }
      >
        <div className="checkbox-wrapper" data-testid={`selectts-checkbox-${id}`}>
          <Checkbox selectionState={isSelected ? 1 : 0} />
        </div>
        <div data-cy="selector-option-label" className="label-wrapper">
          <span className="option-color-image-wrapper">
            {image && <img src={image} alt={image} className="option-image" />}
            {color && <span className="option-color" style={{ backgroundColor: color }} />}
          </span>
          <span className="option-name">
            {name} {subName && <span>{subName}</span>}
          </span>
          {allOptionsCounter && (
            <span className="selector-option-badge">
              <Badge text={allOptionsCounter} type={BadgeColorType.TURQUOISE} />
            </span>
          )}
        </div>
        {children?.length > 0 && <SelectorTsOptionOpenRow id={id} isOpened={isOpened} />}
      </div>
    </div>
  );
};
