import { DateService } from '@align-services/dateService';
import { privateApi } from '@common-services/api/private';
import {
  TeamMetricsRequest,
  TeamMetricsResponse,
} from '@common-services/api/private/generated-from-backend/models';

export const getTeamMetrics = async (
  params: TeamMetricsRequest,
  token: string
): Promise<TeamMetricsResponse> => {
  return privateApi.metrics.getTeamMetrics(params, token, {
    message: `Cannot get team metrics for teamId ${params.team}`,
  });
};

export const isGetMetricsEnabled = (params: TeamMetricsRequest): boolean => {
  return (
    params?.team &&
    params?.metrics_with_params.filter((metric) => metric?.name).length > 0 &&
    DateService.isBeforeToday(params.valid_from)
  );
};

export * as MetricsServices from './metrics';
