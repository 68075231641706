import { css } from '@emotion/react';

export const wrapperStyles = ({ spacing, color, font }) => css`
  display: flex;
  align-items: center;
  gap: ${spacing.gap['04']};
  font-size: ${font.size.m};
  font-weight: ${font.weight.medium};

  .current {
    color: ${color.text.dark};
  }

  .target {
    color: ${color.ui.blue['100']};
    display: flex;
    align-items: center;
    gap: ${spacing.gap['02']};
  }
`;
