import React, { useCallback } from 'react';

import { useNavigation } from '@align-components/layouts/primary/context';
import { useHistory } from '@common-hooks/useHistory';

import { TeamsPanel } from './teamsPanel/TeamsPanel';

const TeamsSection: React.FC = React.memo(() => {
  const { currentTeam } = useNavigation();
  const history = useHistory();
  const onEditTeams = useCallback(() => history.pushWithBackState('/settings/teams'), [history]);

  return (
    <>
      {currentTeam.children && (
        <TeamsPanel teams={currentTeam.children} onEditTeams={onEditTeams} />
      )}
    </>
  );
});

export { TeamsSection };
