import { IThemeFont } from '@styles/types';

import { FontFamily } from './font-family';
import { FontSize } from './font-size';
import { FontWeight } from './font-weight';
import { LetterSpacing } from './letter-spacing';
import { LineHeight } from './line-height';

export const font: IThemeFont = {
  family: FontFamily,
  size: FontSize,
  weight: FontWeight,
  spacing: LetterSpacing,
  height: LineHeight,
};
