import { css } from '@emotion/react';

const primaryText = ({ color, font }) => css`
  color: ${color.neutral[100]};
  font-weight: ${font.weight.regular};
`;

const secondaryText = ({ color, font }) => css`
  color: ${color.neutral[80]};
  font-weight: ${font.weight.light};
`;

export { primaryText, secondaryText };
