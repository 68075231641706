import React from 'react';
import Select, { components } from 'react-select';

import { StatusIndicator, LOADING } from '@analytics-components/StatusIndicator';
import { Icon, icons } from '@lib/icon';

import { selectStyles, vendorSelectStyles, wrapperStyles } from './tableFilter.styles';
import { ITableFilter } from './tableFilter.types';

const TableFilter: React.FC<ITableFilter> = React.memo(
  ({ options, setCurrentValue, currentValue, isLoading = false, placeholder }) => {
    return (
      <div css={wrapperStyles}>
        <div css={selectStyles}>
          {/* TODO: Refactor to use a reusable Dropdown component */}
          <Select
            isDisabled={isLoading}
            value={currentValue}
            isClearable={false}
            placeholder={placeholder || 'Filter by status'}
            options={options}
            onChange={(value) => {
              if (value && !value.value) {
                setCurrentValue(null);
                return;
              }
              setCurrentValue(value);
            }}
            components={{
              DropdownIndicator: (props) => {
                return (
                  <>
                    {isLoading && <StatusIndicator size={5} status={LOADING} margin={0} />}
                    <components.DropdownIndicator {...props}>
                      <Icon icon={icons.caret_down} size={7} />
                    </components.DropdownIndicator>
                  </>
                );
              },
            }}
            styles={vendorSelectStyles}
          />
        </div>
      </div>
    );
  }
);

export { TableFilter };
