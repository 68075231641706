/**
 * {{ title }}
 * Server version: {{ server_version }} built on {{ build_date }} @ [{{ commit }}](https://github.com/athenianco/athenian-api/commit/{{ commit }})  Origin GitHub repository: [athenianco/api-spec](https://github.com/athenianco/api-spec).  Authorization persists between sessions. 🛡️ means that the endpoint requires account admin privileges. 👑 means that the endpoint is only accessible to Athenian authorities. <details> <summary>List of common server errors.</summary> * __500__ endpoint crashed. We reported the incident to Sentry and will fix it soon! Please include the value of `instance` in the response if you contact the support. Repeating the request will not help, most probably. * __501__ some functions are missing, either because they are not implemented yet or a non-critical runtime dependency is not satisfied. Repeating the request will not help, guaranteed. * __502__ server crashed badly, either due to a memory access violation in native code or running out of memory. We reported this incident to Sentry and will fix it soon! Try repeating the request. * __503__ server has not fully launched yet, e.g. hasn't connected to the database; server is shutting down; we are experiencing a partial outage. Try repeating the request. * __504__ endpoint took too much time and was interrupted. We reported the incident to Sentry and will see how to improve the performance. Repeating the request will not help, most probably. </details>
 *
 * Contact: admin@athenian.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import JIRAEpic from './JIRAEpic';
import JIRAFilterWith from './JIRAFilterWith';
import JIRAHistogramDefinition from './JIRAHistogramDefinition';

/**
 * The JIRAHistogramsRequest model module.
 * @module model/JIRAHistogramsRequest
 */
class JIRAHistogramsRequest {
    /**
     * Constructs a new <code>JIRAHistogramsRequest</code>.
     * Request of &#x60;/histograms/jira&#x60;.
     * @alias module:model/JIRAHistogramsRequest
     * @param histograms {Array.<module:model/JIRAHistogramDefinition>} Histogram parameters for each wanted topic.
     * @param dateFrom {Date} Date from when to start measuring the distribution.
     * @param dateTo {Date} Date until which to measure the distribution.
     * @param excludeInactive {Boolean} Value indicating whether issues with the last update older than `date_from` should be ignored.
     * @param account {Number} Session account ID.
     */
    constructor(histograms, dateFrom, dateTo, excludeInactive, account) { 
        
        JIRAHistogramsRequest.initialize(this, histograms, dateFrom, dateTo, excludeInactive, account);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, histograms, dateFrom, dateTo, excludeInactive, account) { 
        obj['histograms'] = histograms;
        obj['date_from'] = dateFrom;
        obj['date_to'] = dateTo;
        obj['exclude_inactive'] = excludeInactive;
        obj['account'] = account;
    }

    /**
     * Constructs a <code>JIRAHistogramsRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/JIRAHistogramsRequest} obj Optional instance to populate.
     * @return {module:model/JIRAHistogramsRequest} The populated <code>JIRAHistogramsRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new JIRAHistogramsRequest();

            if (data.hasOwnProperty('priorities')) {
                obj['priorities'] = ApiClient.convertToType(data['priorities'], ['String']);
            }
            if (data.hasOwnProperty('types')) {
                obj['types'] = ApiClient.convertToType(data['types'], ['String']);
            }
            if (data.hasOwnProperty('epics')) {
                obj['epics'] = ApiClient.convertToType(data['epics'], [JIRAEpic]);
            }
            if (data.hasOwnProperty('labels_include')) {
                obj['labels_include'] = ApiClient.convertToType(data['labels_include'], ['String']);
            }
            if (data.hasOwnProperty('labels_exclude')) {
                obj['labels_exclude'] = ApiClient.convertToType(data['labels_exclude'], ['String']);
            }
            if (data.hasOwnProperty('with')) {
                obj['with'] = ApiClient.convertToType(data['with'], [JIRAFilterWith]);
            }
            if (data.hasOwnProperty('projects')) {
                obj['projects'] = ApiClient.convertToType(data['projects'], ['String']);
            }
            if (data.hasOwnProperty('histograms')) {
                obj['histograms'] = ApiClient.convertToType(data['histograms'], [JIRAHistogramDefinition]);
            }
            if (data.hasOwnProperty('date_from')) {
                obj['date_from'] = ApiClient.convertToType(data['date_from'], 'Date');
            }
            if (data.hasOwnProperty('date_to')) {
                obj['date_to'] = ApiClient.convertToType(data['date_to'], 'Date');
            }
            if (data.hasOwnProperty('timezone')) {
                obj['timezone'] = ApiClient.convertToType(data['timezone'], 'Number');
            }
            if (data.hasOwnProperty('exclude_inactive')) {
                obj['exclude_inactive'] = ApiClient.convertToType(data['exclude_inactive'], 'Boolean');
            }
            if (data.hasOwnProperty('quantiles')) {
                obj['quantiles'] = ApiClient.convertToType(data['quantiles'], ['Number']);
            }
            if (data.hasOwnProperty('account')) {
                obj['account'] = ApiClient.convertToType(data['account'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * Selected issue priorities.
 * @member {Array.<String>} priorities
 */
JIRAHistogramsRequest.prototype['priorities'] = undefined;

/**
 * Selected issue types.
 * @member {Array.<String>} types
 */
JIRAHistogramsRequest.prototype['types'] = undefined;

/**
 * @member {Array.<module:model/JIRAEpic>} epics
 */
JIRAHistogramsRequest.prototype['epics'] = undefined;

/**
 * PRs must relate to at least one JIRA issue label from the list. Several labels may be concatenated by a comma `,` so that all of them are required.
 * @member {Array.<String>} labels_include
 */
JIRAHistogramsRequest.prototype['labels_include'] = undefined;

/**
 * PRs cannot relate to JIRA issue labels from the list.
 * @member {Array.<String>} labels_exclude
 */
JIRAHistogramsRequest.prototype['labels_exclude'] = undefined;

/**
 * Groups of issue participants. The histograms will be calculated for each group.
 * @member {Array.<module:model/JIRAFilterWith>} with
 */
JIRAHistogramsRequest.prototype['with'] = undefined;

/**
 * Issues must belong to these JIRA projects.
 * @member {Array.<String>} projects
 */
JIRAHistogramsRequest.prototype['projects'] = undefined;

/**
 * Histogram parameters for each wanted topic.
 * @member {Array.<module:model/JIRAHistogramDefinition>} histograms
 */
JIRAHistogramsRequest.prototype['histograms'] = undefined;

/**
 * Date from when to start measuring the distribution.
 * @member {Date} date_from
 */
JIRAHistogramsRequest.prototype['date_from'] = undefined;

/**
 * Date until which to measure the distribution.
 * @member {Date} date_to
 */
JIRAHistogramsRequest.prototype['date_to'] = undefined;

/**
 * Local time zone offset in minutes, used to adjust `date_from` and `date_to`.
 * @member {Number} timezone
 */
JIRAHistogramsRequest.prototype['timezone'] = undefined;

/**
 * Value indicating whether issues with the last update older than `date_from` should be ignored.
 * @member {Boolean} exclude_inactive
 */
JIRAHistogramsRequest.prototype['exclude_inactive'] = undefined;

/**
 * Cut the distributions at certain quantiles. The default values are [0, 1] which means no cutting.
 * @member {Array.<Number>} quantiles
 */
JIRAHistogramsRequest.prototype['quantiles'] = undefined;

/**
 * Session account ID.
 * @member {Number} account
 */
JIRAHistogramsRequest.prototype['account'] = undefined;






export default JIRAHistogramsRequest;

