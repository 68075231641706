import { css } from '@emotion/react';

export const badgeStyles = ({ color, font, radius, spacing, transition }) => css`
  border-radius: ${radius.default};
  display: inline-block;
  font-size: ${font.size.default};
  font-weight: ${font.weight.regular};
  line-height: 1;
  padding: ${spacing.gap['02']} ${spacing.gap['03']};
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  transition: ${transition.default};

  svg path {
    margin-right: ${spacing.gap['01']};
    vertical-align: bottom;
  }

  span {
    margin-left: ${spacing.gap['02']};
  }

  &.badge-pill {
    font-size: ${font.size.xs};
    line-height: 0.75;
  }

  &.badge-danger {
    background: ${color.ui.red[20]};
    color: ${color.ui.red[100]};

    svg path {
      fill: ${color.ui.red[100]};
    }
  }

  &.badge-neutral {
    background: ${color.neutral[40]};
    color: ${color.text.secondary};
    min-width: 44px;
  }

  &.badge-success {
    background: ${color.ui.green[20]};
    color: ${color.ui.green[100]};

    svg path {
      fill: ${color.ui.green[100]};
    }
  }

  &.badge-secondary {
    background: ${color.neutral[40]};
    color: ${color.text.secondary};
  }
`;

export const outlinedBadgeStyles = ({ color, font, radius, spacing, transition }) => css`
  background: transparent;
  border: 1px solid;
  border-radius: ${radius.default};
  display: inline-block;
  font-size: ${font.size.default};
  font-weight: ${font.weight.regular};
  line-height: 1.8rem;
  padding: ${spacing.gap['03']} ${spacing.gap['04']};
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  transition: ${transition.default};
  text-transform: uppercase;
  min-width: 100px;

  &.badge-primary {
    border-color: ${color.status.primary};
    color: ${color.text.secondary};
  }

  &.badge-secondary {
    border-color: ${color.text.secondary};
    color: ${color.text.secondary};
  }

  &.badge-success {
    border-color: ${color.success};
    color: ${color.success};
  }

  &.badge-warning {
    border-color: ${color.status.warning};
    color: ${color.status.warning};
  }

  &.badge-info {
    border-color: ${color.status.info};
    color: ${color.status.info};
  }

  &.badge-danger {
    border-color: ${color.status.danger};
    color: ${color.status.danger};
  }

  &.label-wip {
    border-color: ${color.stage.wip};
    color: ${color.stage.wip};
  }

  &.label-review-requested {
    border-color: ${color.ui.brown[100]};
    color: ${color.ui.brown[100]};
  }

  &.label-review-pending {
    border-color: ${color.stage.review};
    color: ${color.stage.review};
  }

  &.label-review-submitted {
    border-color: ${color.ui.lightorange[50]};
    color: ${color.ui.lightorange[50]};
  }

  &.label-review-rejected {
    border-color: ${color.ui.red[100]};
    color: ${color.ui.red[100]};
  }

  &.label-merge-pending {
    border-color: ${color.ui.lightpink[100]};
    color: ${color.ui.lightpink[100]};
  }

  &.label-merged {
    border-color: ${color.stage.merge};
    color: ${color.stage.merge};
  }

  &.label-released,
  &.label-review-approval {
    border-color: ${color.stage.release};
    color: ${color.stage.release};
  }

  &.label-release-pending {
    border-color: ${color.ui.turquoise[100]};
    color: ${color.ui.turquoise[100]};
  }

  &.label-never-released {
    border-color: ${color.text.secondary};
    color: ${color.text.secondary};
  }

  &.label-closed {
    border-color: ${color.text.secondary};
    color: ${color.text.secondary};
  }

  &.label-review-skipped {
    border-color: ${color.neutral[40]};
    color: ${color.neutral[40]};
  }

  &.label-review-ignored {
    border-color: ${color.neutral[80]};
    color: ${color.neutral[80]};
  }
  &.label-latest-release {
    border-color: ${color.ui.turquoise[100]};
    color: ${color.ui.turquoise[100]};
  }
`;
