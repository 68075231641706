import { css } from '@emotion/react';

const getStyle = (isActive) => (theme) => {
  const style = [getBaseStyle(theme)];
  if (isActive) {
    style.push(getActiveStyle(theme));
  } else {
    style.push(getInactiveStyle(theme));
  }

  return style;
};

const getBaseStyle = ({ spacing: { gap }, color, font }) => css`
  padding: ${gap['0']};
  height: 38px;
  width: fit-content;

  & > div.label-wrapper {
    display: flex;
    gap: ${gap['03']};
    align-items: center;
    align-content: center;

    & > span {
      color: ${color.neutral['100']};
      font-weight: ${font.weight.medium};
      font-size: ${font.size.default};
      text-align: center;
      text-transform: capitalize;
    }
  }
`;

const getInactiveStyle = ({ color }) => css`
  &:hover {
    cursor: pointer;
    border-bottom: 3px solid ${color.neutral['60']};
  }
`;

const getActiveStyle = ({ color }) => css`
  border-bottom: 3px solid ${color.ui.lightorange['100']};
`;

export { getStyle };
