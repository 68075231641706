import { v4 as uuidv4 } from 'uuid';

import { IPrsAction, IPrsTableState, PRS_ACTION_TYPE, PRS_TABLE_STATE } from './pullRequests.types';

const initPrsTableState = (): IPrsTableState => ({
  sid: uuidv4(),
  state: PRS_TABLE_STATE.WAITING_FOR_API,
  api: {
    context: {},
  },
  fetched: 0,
  pagesData: [],
  plan: [],
  earlyUserInteraction: false,
});

const prsTableReducer = (state: IPrsTableState, action: IPrsAction): IPrsTableState => {
  if (state.sid !== action.sid) {
    return { ...state };
  }
  switch (action.type) {
    case PRS_ACTION_TYPE.LOAD_API:
      if (state.state !== PRS_TABLE_STATE.WAITING_FOR_API) {
        throw new Error('API already loaded');
      }

      const apiContext = action.payload.apiContext;

      return {
        ...state,
        state: PRS_TABLE_STATE.WAITING_FOR_PAGINATION_PLAN,
        api: {
          context: apiContext,
        },
      };
    case PRS_ACTION_TYPE.RESET: {
      return initPrsTableState();
    }
    case PRS_ACTION_TYPE.RESOLVING_PAGINATION_PLAN:
      if (state.state !== PRS_TABLE_STATE.WAITING_FOR_PAGINATION_PLAN) {
        throw new Error('Pagination plan already being resolved');
      }

      return {
        ...state,
        state: PRS_TABLE_STATE.RESOLVING_PAGINATION_PLAN,
      };
    case PRS_ACTION_TYPE.FETCHED_NO_DATA:
      return {
        ...state,
        state: PRS_TABLE_STATE.DONE,
        pagesData: [{ deployments: {}, prs: [], users: {} }],
        fetched: 1,
      };
    case PRS_ACTION_TYPE.RESOLVE_PAGINATION_PLAN:
      if (state.state !== PRS_TABLE_STATE.RESOLVING_PAGINATION_PLAN) {
        throw new Error('Pagination plan already resolved');
      }

      return {
        ...state,
        state: PRS_TABLE_STATE.WAITING_FOR_FIRST_PAGE,
        plan: action.payload,
      };
    case PRS_ACTION_TYPE.RESOLVING_FIRST_PAGE:
      if (state.state !== PRS_TABLE_STATE.WAITING_FOR_FIRST_PAGE) {
        throw new Error('First page already being resolved');
      }

      return {
        ...state,
        state: PRS_TABLE_STATE.RESOLVING_FIRST_PAGE,
      };
    case PRS_ACTION_TYPE.RESOLVE_FIRST_PAGE:
      if (state.state !== PRS_TABLE_STATE.RESOLVING_FIRST_PAGE) {
        throw new Error('First page already resolved');
      }

      return {
        ...state,
        state: state.plan.length === 1 ? PRS_TABLE_STATE.DONE : PRS_TABLE_STATE.WAITING_PAGE,
        pagesData: [action.payload],
        fetched: 1,
      };
    case PRS_ACTION_TYPE.RESOLVING_PAGE:
      return {
        ...state,
        state: PRS_TABLE_STATE.RESOLVING_PAGE,
      };
    case PRS_ACTION_TYPE.RESOLVE_PAGE:
      if (state.state !== PRS_TABLE_STATE.RESOLVING_PAGE) {
        throw new Error('Not waiting for page being resolved');
      }

      return {
        ...state,
        state:
          state.plan.length === state.fetched + 1
            ? PRS_TABLE_STATE.DONE
            : PRS_TABLE_STATE.WAITING_PAGE,
        pagesData: [...state.pagesData, action.payload],
        fetched: state.fetched + 1,
      };
    case PRS_ACTION_TYPE.EARLY_USER_INTERACTION:
      return {
        ...state,
        earlyUserInteraction: true,
      };
    default:
      throw new Error();
  }
};

export { prsTableReducer, initPrsTableState };
