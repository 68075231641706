import React from 'react';

import Knowledge01 from '@assets/images/knowledge/knowledge_01.svg';
import Knowledge02 from '@assets/images/knowledge/knowledge_02.svg';
import Knowledge03 from '@assets/images/knowledge/knowledge_03.svg';
import Knowledge04 from '@assets/images/knowledge/knowledge_04.svg';

const Knowledge = () => {
  return (
    <div className="container">
      <div className="row text-center">
        <div className="col">
          <h2 className="text-dark h1 mt-5 mb-2">Athenian Knowledge</h2>
          <p className="text-secondary h3 mb-5">
            Learn about Engineering metrics and improve your software delivery performance
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <span className="text-secondary text-uppercase mb-2 pl-2">Start Here</span>
        </div>
        <div className="col-3">
          <div className="p-2">
            <img alt="" className="w-100 mb-5" src={Knowledge01} />
            <h3 className="mb-3">
              <button className="text-dark link">Lead Time</button>
            </h3>
            <p>
              Lead Time is a metric borrowed from lean thinking and manufacturing disciplines. It’s
              defined as the amount of time between the moment work begins until it’s delivered to
              the end-customer.
            </p>
          </div>
        </div>
        <div className="col-3">
          <div className="p-2">
            <img alt="" className="w-100 mb-5" src={Knowledge02} />
            <h3 className="mb-3">
              <button className="text-dark link">Release Frequency</button>
            </h3>
            <p>
              Together with Lead Time, Release Frequency is a measure of software delivery
              performance tempo. This metric tells you how well customer feedback is incorporated
              along the development process.
            </p>
          </div>
        </div>
        <div className="col-3">
          <div className="p-2">
            <img alt="" className="w-100 mb-5" src={Knowledge03} />
            <h3 className="mb-3">
              <button className="text-dark link">Work In Progress Cycle Time</button>
            </h3>
            <p>
              This metric helps you to understand what proportion of your end-to-end delivery
              pipeline is dedicated to the actual code implementation.
            </p>
          </div>
        </div>
        <div className="col-3">
          <div className="p-2">
            <img alt="" className="w-100 mb-5" src={Knowledge04} />
            <h3 className="mb-3">
              <button className="text-dark link">Review Cycle Time</button>
            </h3>
            <p>
              Code review being the most difficult and time-consuming part of the software
              development process, review cycle time often stands for the biggest opportunity to
              improve your overall speed delivery.
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <span className="text-dark-orange text-uppercase d-block mb-4 pl-2">
            More Engineering Metrics
          </span>
        </div>
        <ul className="p-2 list-unstyled list-4 card-columns col-12">
          <li className="px-2 pb-5">
            <div className="text-dark">Release Cycle Time</div>
          </li>
          <li className="px-2 pb-5">
            <div className="text-dark">Pull Request Size</div>
          </li>
          <li className="px-2 pb-5">
            <div className="text-dark">Wait Time for 1st Review</div>
          </li>
          <li className="px-2 pb-5">
            <div className="text-dark">Pull Request Ratio Flow</div>
          </li>
          <li className="px-2 pb-5">
            <div className="text-dark">Mean Time To restore</div>
          </li>
          <li className="px-2 pb-5">
            <div className="text-dark">Code Review Depth</div>
          </li>
          <li className="px-2 pb-5">
            <div className="text-dark">Work Distribution</div>
          </li>
          <li className="px-2 pb-5">
            <div className="text-dark">Bug Fixing Ratio</div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Knowledge;
