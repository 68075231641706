import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Redirect } from 'react-router-dom';

const DEMO_COOKIE_KEY = 'athenian.isDemo';
const DEMO_COOKIE_MAX_AGE = 60 * 60 * 1;

const Demo = () => {
  const { isDemo, setDemoCookie } = useDemoCookie();
  useEffect(() => {
    setDemoCookie();
  }, [setDemoCookie]);

  if (isDemo) {
    return <Redirect to={'/'} />;
  }

  return null;
};

const Undemo = () => {
  const { isDemo, removeDemoCookie } = useDemoCookie();
  useEffect(() => {
    removeDemoCookie();
  }, [removeDemoCookie]);

  if (!isDemo) {
    return <Redirect to={'/'} />;
  }

  return null;
};

const useDemoCookie = () => {
  const [cookies, setCookie, removeCookie] = useCookies([DEMO_COOKIE_KEY]);
  const isDemo = cookies[DEMO_COOKIE_KEY] === 'true';
  const setDemoCookie = () => {
    setCookie(DEMO_COOKIE_KEY, true, {
      path: '/',
      maxAge: DEMO_COOKIE_MAX_AGE,
    });
  };
  return {
    isDemo: isDemo,
    setDemoCookie,
    removeDemoCookie: () => removeCookie(DEMO_COOKIE_KEY),
    refreshDemoCookie: () => {
      if (isDemo) {
        setDemoCookie();
      }
    },
  };
};

export { Demo, Undemo, useDemoCookie };
