import React from 'react';

import noData from '@assets/images/empty-states/no-data.svg';

import { Empty } from '..';

const AddWorkTypes: React.FC = React.memo(() => (
  <Empty background={noData}>
    <h3>Add work types to see Allocation Insights</h3>
    <p>
      You can manage Work Types in the{' '}
      <a className="text-dark-orange" href={'/settings/allocation'}>
        Settings
      </a>
    </p>
  </Empty>
));

export { AddWorkTypes };
