import _ from 'lodash';
import React from 'react';

import { DefaultXYTooltip, BigText } from '@analytics-components/charts/Tooltip';
import VerticalBarChart from '@analytics-components/charts/VerticalBarChart';
import { firstNRepos } from '@analytics-components/insights/Helper';
import { SimpleKPI } from '@analytics-components/insights/KPI';
import { palette } from '@analytics-pages/delivery-pipeline/insights/release';
import { dateTime, humanLaboralRate } from '@common-services/dateService';
import { number, github } from '@common-services/format';

const releaseCreationPerRepo = {
  plumber: (fetchedData, cachedData, apiContext) => {
    const labourDays = dateTime.labourDays(apiContext.interval.from, apiContext.interval.to);
    const dataRaw = _(fetchedData.releases.data)
      .groupBy('repository')
      .map((series, i, b) => {
        return {
          repo: github.repoName(i),
          releases: series.length,
        };
      })
      .sort((a, b) => b.releases - a.releases)
      .value();
    const dailyReleaserCount = dataRaw.filter((repo) => repo.releases >= labourDays).length;
    const mostReleasingRepo = dataRaw[0];

    const releaseRateUnits = mostReleasingRepo?.releases
      ? humanLaboralRate(
          apiContext.interval.from,
          apiContext.interval.to,
          mostReleasingRepo.releases
        )
      : 'day';

    return {
      chartData: dataRaw.slice(0, firstNRepos),
      KPIsData: {
        dailyReleaserCount,
        mostReleasingRepo: {
          name: mostReleasingRepo?.repo,
          percentage:
            mostReleasingRepo?.releases /
            (cachedData['releases-metrics']['values']['all']['count'] || 1),
        },
      },
      axisKeys: {
        x: 'repo',
        y: 'releases',
      },
      releaseRateUnit: releaseRateUnits[1],
      interval: apiContext.interval,
    };
  },
  factory: (computed) => ({
    content: [
      {
        empty: computed.chartData.filter((v) => v[computed.axisKeys.y] !== 0).length === 0,
        chart: {
          component: VerticalBarChart,
          title: 'Repositories',
          description: 'Monitor the release frequency for each repository.',
          params: {
            data: computed.chartData,
            timeMode: false,
            extra: {
              axisLabels: {
                y: `Releases / ${computed.releaseRateUnit}`,
              },
              margin: { bottom: 80 },
              maxNumberOfTicks: 5,
              axisKeys: computed.axisKeys,
              axisFormat: {
                tickFormat: {
                  y: (v) => {
                    const rateSpecs = humanLaboralRate(
                      computed.interval.from,
                      computed.interval.to,
                      v,
                      1
                    );
                    return rateSpecs[1] === computed.releaseRateUnit ? rateSpecs[0] : '';
                  },
                },
              },
              color: palette.barColor,
              tooltip: {
                align: {
                  horizontal: 'auto',
                  vertical: 'top',
                },
                template: DefaultXYTooltip,
                y: (v) => {
                  const rateSpecs = humanLaboralRate(
                    computed.interval.from,
                    computed.interval.to,
                    v.y,
                    1
                  );
                  return (
                    <BigText
                      content={`${rateSpecs[0]} releases`}
                      extra={`/ ${rateSpecs[1]}`}
                      encloseExtra={false}
                    />
                  );
                },
              },
            },
          },
        },
        kpis: [
          {
            title: { text: 'Repositories Releasing', bold: true },
            subtitle: { text: 'on a daily basis' },
            component: SimpleKPI,
            params: {
              value: computed.KPIsData.dailyReleaserCount,
            },
          },
          {
            title: { text: 'Repository with', bold: true },
            subtitle: { text: 'highest release frequency' },
            component: SimpleKPI,
            params: {
              value: `${computed.KPIsData.mostReleasingRepo.name}`,
              secondary: `(${number.percentage(
                computed.KPIsData.mostReleasingRepo.percentage * 100
              )})`,
            },
          },
        ],
      },
    ],
  }),
};

export default releaseCreationPerRepo;
