import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Control, Footer, Option, AllOption } from '@lib/Selector/components';
import { optsType } from '@lib/Selector/types';
import { AvatarType } from '@lib/avatar';
import { TagsContainer } from '@lib/tags-container';

import getStyle from './styles';

const WithoutCheckboxes = ({
  appliedOptions = [],
  optionsGroups = [],
  noAvatar = false,
  onRemove,
}) => {
  return (
    <div data-cy="selector" css={(theme) => getStyle(theme, false, true)}>
      {appliedOptions.length > 0 && (
        <TagsContainer
          avatarType={noAvatar ? AvatarType.none : null}
          items={appliedOptions}
          placeholder=""
          isLoading={false}
          withSmallTags
          onRemove={onRemove}
        />
      )}
      <div data-cy="selector-menu" className="menu">
        <div className="menu-options">
          {_(optionsGroups)
            .filter((optsGroup) => optsGroup.options.length > 0)
            .map((optsGroup, i) => (
              <OptionsGroups key={i} optionsGroup={optsGroup} noAvatar={noAvatar} />
            ))
            .value()}
        </div>
      </div>
    </div>
  );
};

const OptionsGroups = ({ optionsGroup, noAvatar }) => {
  return (
    <div>
      {optionsGroup.group && <div className="options-group">{optionsGroup.group}</div>}
      {_(optionsGroup.options)
        .map((opt, i) => <Option key={i} data={opt} noAvatar={noAvatar} noCheckbox={true} />)
        .value()}
    </div>
  );
};

const WithCheckboxes = ({
  options = [],
  placeholder = '',
  inputValue = '',
  avatarType,
  noAvatar = false,
  noSearch = false,
  isApplyDisabled = false,
  withAllOption,
  onInputChange,
  onInputClick,
  onCancel,
  onApply,
}) => {
  return (
    <div data-cy="selector" css={(theme) => getStyle(theme, withAllOption)}>
      {!noSearch && (
        <Control
          placeholder={placeholder}
          inputValue={inputValue}
          onInputClick={onInputClick}
          onInputChange={onInputChange}
        />
      )}
      <div data-cy="selector-menu" className="menu">
        {withAllOption && <AllOption />}
        <div className="menu-options">
          {_(options)
            .map((opt, i) => (
              <Option avatarType={avatarType} key={i} data={opt} noAvatar={noAvatar} />
            ))
            .value()}
        </div>
      </div>
      <Footer isApplyDisabled={isApplyDisabled} onCancel={onCancel} onApply={onApply} />
    </div>
  );
};

WithoutCheckboxes.propTypes = {
  appliedOptions: optsType,
  optionsGroups: PropTypes.arrayOf(
    PropTypes.exact({
      group: PropTypes.string.isRequired,
      options: optsType,
    })
  ),
  noAvatar: PropTypes.bool,
  onRemove: PropTypes.func,
};

WithCheckboxes.propTypes = {
  options: optsType,
  placeholder: PropTypes.string,
  inputValue: PropTypes.string,
  avatarType: PropTypes.oneOf(Object.values(AvatarType)),
  noAvatar: PropTypes.bool,
  noSearch: PropTypes.bool,
  isApplyDisabled: PropTypes.bool,
  withAllOption: PropTypes.bool,
  onInputChange: PropTypes.func,
  onInputClick: PropTypes.func,
  onCancel: PropTypes.func,
  onApply: PropTypes.func,
};

export { WithCheckboxes, WithoutCheckboxes };
