import React from 'react';

import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import Panel from '@analytics-components/insights/Panel';
import { prepareChartBoxes } from '@analytics-pages/delivery-pipeline/insights/helper';

import mttRepairByPriority from './mttRepairByPriority';

const meta = {
  title: 'Mean Time To Repair',
  description:
    'Average time to implement the fix: from the moment work begins till the fix is released in production.',
};

const chartBoxes = [
  {
    id: 'mtt-repair-by-priority',
    component: ChartBoxWithKPIWidget,
    params: mttRepairByPriority,
  },
];

const MTTRepairPanel = () => {
  const preparedChartBoxes = prepareChartBoxes(chartBoxes);
  return <Panel meta={meta} chartBoxes={preparedChartBoxes} />;
};

export default MTTRepairPanel;
