import { IUrlParams } from '@common-services/api/common/types/apiClient';
import { privateApi } from '@common-services/api/private';
import { ProductFeatures } from '@common-services/api/public/generated-from-backend/models';

export const updateAccountFeature = async (
  urlParams: IUrlParams<'accountId'>,
  params: ProductFeatures,
  token: string
): Promise<ProductFeatures> => {
  return privateApi.accounts.updateAccountFeature(urlParams, params, token, {
    message: `Cannot update account feature for accountId ${urlParams.accountId}`,
  });
};

export * as AccountsServices from './accounts';
