import {
  JIRAIssueType,
  JIRAPriority,
  JIRAProject,
} from '@common-services/api/public/generated-from-backend/models';
import { FilterType } from '@lib/filter-panel';

import { IFilterDetailRow } from './filter-detail-row';

export interface IFilterDetail {
  data: IFilterDetailRow[];
  isOpen: boolean;
}

export enum FilterDetailRowLimitType {
  LETTER = 'letter',
  WORD = 'word',
}

export interface IFilterDetailRowLimitOptions {
  type: FilterDetailRowLimitType;
  max?: number;
}

export interface IFilterListReducer {
  mainList: string[];
  moreList: string[];
}

export type IFilterListFormatterFn = (filterKey: FilterType, value: string) => string;

export type IFilterListFormatter = (filters?: {
  jiraIssueTypes?: JIRAIssueType[];
  jiraProjects?: JIRAProject[];
  jiraPriorities?: JIRAPriority[];
}) => IFilterListFormatterFn;
