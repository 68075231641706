import { css } from '@emotion/react';

export const wrapperStyles = ({ spacing, radius, color }) => css`
  position: absolute;
  top: calc(100% + ${spacing.gap['02']});
  right: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: ${radius.default};
  background: ${color.neutral.white};
  z-index: 100;
`;
