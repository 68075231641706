import { useMemo } from 'react';

import { useSearchedPRs } from '@align-hooks/useSearchedPRs';
import { useCurrentTeam } from '@align-hooks/useTeams';
import { DateService as AlignDateService } from '@align-services/dateService';
import { IDateRangeInMls, PrsIssuesTableTabs } from '@align-types/common';
import { IUseGetSearchedPRs } from '@align-types/prs';
import {
  DashboardChart,
  SearchPullRequestsOrderByExpression,
  SearchPullRequestsRequest,
} from '@common-services/api/private/generated-from-backend/models';
import { useGetAccountDetails } from '@common-services/api/public/hooks/useGetAccountData';
import { DateService } from '@common-services/dateService';

import { ChartConfigGroup } from '../config';
import { IChartConfig } from '../types';

export function useGetSearchedPRs(
  accountId: number,
  chartParams: DashboardChart,
  metric: IChartConfig,
  activeTab: PrsIssuesTableTabs,
  selectedRange: IDateRangeInMls
): IUseGetSearchedPRs {
  const currentTeam = useCurrentTeam();

  const { data: accountDetailsData, isLoading: accountDetailsLoading } = useGetAccountDetails(true);

  const isEndDatePassed = useMemo(() => AlignDateService.isBeforeToday(chartParams?.date_to), [
    chartParams,
  ]);

  const isJiraDataInstalled = useMemo(() => {
    if (accountDetailsData?.datasources) return accountDetailsData?.datasources.includes('jira');
  }, [accountDetailsData]);

  const { data, isLoading: searchedPRsIsLoading, error } = useSearchedPRs(
    {
      account: accountId,
      date_from: selectedRange
        ? DateService.formatDate(selectedRange.dateFrom, 'YYYY-MM-DD')
        : chartParams?.date_from,
      date_to: selectedRange
        ? DateService.formatDate(selectedRange.dateTo, 'YYYY-MM-DD')
        : chartParams?.date_to,
      repositories: chartParams?.filters?.repositories || [],
      ...(isJiraDataInstalled && {
        jira: {
          issue_types: chartParams?.filters?.jira?.issue_types || [],
          projects: chartParams?.filters?.jira?.projects || [],
          priorities: chartParams?.filters?.jira?.priorities || [],
          // @ts-ignore the field in chartParams should be called "labels_include", but it was intentionally renamed by DEV-6424
          labels_include: chartParams?.filters?.jira?.labels || [],
        },
      }),
      participants: {
        author: [`{${currentTeam?.id}}`],
      },
      order_by: [
        {
          field: (activeTab === PrsIssuesTableTabs.COMPLETED
            ? metric?.completedOrderBy
            : metric?.inProgressOrderBy) as SearchPullRequestsOrderByExpression['field'],
          direction:
            activeTab === PrsIssuesTableTabs.IN_PROGRESS && metric?.inProgressOrderAscending
              ? 'ascending'
              : 'descending',
        },
      ],
      ...(activeTab === PrsIssuesTableTabs.COMPLETED
        ? {}
        : {
            stages: metric?.inProgressStages as SearchPullRequestsRequest['stages'],
          }),
    },
    true,
    (activeTab === PrsIssuesTableTabs.IN_PROGRESS && isEndDatePassed) || // do not fetch PRs for In Progress tab if the end date has passed
      !accountDetailsData ||
      metric?.group === ChartConfigGroup.JIRA ||
      !metric?.metricColumnName
  );

  const isLoading = useMemo(() => {
    return searchedPRsIsLoading || accountDetailsLoading;
  }, [searchedPRsIsLoading, accountDetailsLoading]);

  return { data, isLoading, error };
}
