import React, { useMemo } from 'react';

import Tabs from '@analytics-components/Tabs';
import AllocationProvider from '@analytics-context/Allocation';

import WorkTypes from './work-types';

const InsightsAllocation = () => {
  const tabs = useMemo(
    () => [
      {
        title: 'Work Types',
        content: <WorkTypes />,
      },
    ],
    []
  );

  return (
    <AllocationProvider>
      <Tabs tabs={tabs} initialValue={0} leftAligned />
    </AllocationProvider>
  );
};

export default InsightsAllocation;
