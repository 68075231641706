import React from 'react';
import { Link } from 'react-router-dom';

import { useNavigation } from '@align-components/layouts/primary/context';
import { getTeamColor } from '@common-services/colorService';
import { Avatar, AvatarType } from '@lib/avatar';
import { Icon, icons } from '@lib/icon';

import { NestedAccordionHeaderProps } from '../types';
import { getNestedAcoordionHeaderStyle } from './ui/styles';

const NestedAccordionHeader: React.FC<NestedAccordionHeaderProps> = React.memo(
  ({ name, ancestorsNames, totalMembersCount, totalTeamsCount }) => {
    const { currentTeamPath, section } = useNavigation();
    return (
      <div css={getNestedAcoordionHeaderStyle}>
        <Avatar type={AvatarType.text} label={name} color={getTeamColor(name)} />
        <span>
          {section ? (
            <Link
              to={`/align/${currentTeamPath}/${
                ancestorsNames.length > 0 ? ancestorsNames.join('/') + '/' : ''
              }${encodeURIComponent(name.toLowerCase())}/${section}`}
            >
              {name}
            </Link>
          ) : (
            name
          )}
        </span>
        <div>
          <Icon icon={icons.person} size={15} />
          <span>
            {totalMembersCount} member{totalMembersCount === 1 ? '' : 's'}
          </span>
        </div>
        <div>
          <Icon icon={icons.people} size={15} />
          <span>
            {totalTeamsCount} team{totalTeamsCount === 1 ? '' : 's'}
          </span>
        </div>
      </div>
    );
  }
);

export { NestedAccordionHeader };
