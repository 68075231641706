import { css } from '@emotion/react';

const getNestedAcoordionHeaderStyle = ({ spacing: { gap }, color, font }) => css`
  display: flex;
  gap: ${gap['04']};
  align-items: center;

  & > span {
    &:hover {
      text-decoration: underline;
    }

    & > a {
      color: ${color.neutral['100']};
      font-size: ${font.size.default};
    }
  }

  & > div {
    display: flex;
    gap: ${gap['02']};

    & > span {
      color: ${color.neutral['80']};
      font-size: ${font.size.default};
    }
  }
`;

const getOverrideWrapperStyle = (level) => ({ radius }) =>
  level > 0
    ? css`
        border: 0px;
        border-radius: ${radius.sharp};
      `
    : css``;

const getOverrideHeaderStyle = (level, subteams) => ({ spacing: { gap }, color, radius }) => {
  const col =
    {
      0: '20',
      1: '10',
    }[level] || 'white';
  const styleOverride = css`
    ${level > 0 ? 'height: 48px;' : ''}
    background: ${color.neutral[col]};
    padding-top: ${gap['04']};
    padding-right: ${gap['04']};
    padding-bottom: ${gap['04']};
    padding-left: calc(${gap['04']} + (${level} * ${gap['07']}));
    border-radius: ${radius.sharp};
  `;

  const arrowStyle =
    subteams?.length > 0
      ? css``
      : css`
          cursor: default;

          & > div:first-of-type {
            visibility: hidden;
          }
        `;

  return [styleOverride, arrowStyle];
};

export { getNestedAcoordionHeaderStyle, getOverrideWrapperStyle, getOverrideHeaderStyle };
