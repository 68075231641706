import { useMemo } from 'react';

import { GoalsSingleComponentServices } from '@align-pages/goals/single/components/goals-single-component';
import { IGoalMetricValue } from '@align-services/api/types/goalsTypes';
import { IGoalValue } from '@align-services/api/types/goalsTypes';
import { IDateRange } from '@align-services/dateService';
import { MetricService } from '@align-services/metricService';
import { Unit } from '@align-types/constants';
import { useProgress } from '@common-hooks/useProgress';

import { getCurrentRange, getValueType, parseMetricValue } from './activeGoalProgress.services';
import { IUseParams } from './activeGoalProgress.types';

const getPercentageValue = (value: IGoalMetricValue): string => {
  const normalisedValue = GoalsSingleComponentServices.normaliseMetricValue(value);
  return normalisedValue != null
    ? `${MetricService.convertFloatToPercentage(normalisedValue)} %`
    : null;
};

export const useParams = (
  value: IGoalValue,
  unit: Unit,
  range: IDateRange = null,
  isPercentage: boolean
): IUseParams => {
  const { target, current, percentage } = useProgress(
    parseMetricValue(value?.initial),
    parseMetricValue(value?.target),
    parseMetricValue(value?.current)
  );

  const readableTarget = useMemo(() => {
    return unit?.isFrequency && range
      ? MetricService.getFrequencyValue(range, value.target)[0]
      : MetricService.getReadableRawValue(target, getValueType(value.target), unit);
  }, [target, range, value.target, unit]);

  const readableCurrent = useMemo(() => {
    return unit?.isFrequency && range
      ? MetricService.getFrequencyValue(getCurrentRange(range), value.current)[0]
      : MetricService.getReadableRawValue(current, getValueType(value.current), unit);
  }, [current, range, value.current, unit]);

  const currentValue = useMemo(
    () =>
      isPercentage
        ? getPercentageValue(value.current)
        : readableCurrent || `${Math.round(percentage)}%`,
    [isPercentage, readableCurrent, percentage, value.current]
  );

  const targetValue = useMemo(
    () => (isPercentage ? getPercentageValue(value.target) : readableTarget),
    [isPercentage, readableTarget, value.target]
  );

  return {
    currentValue,
    targetValue,
  };
};
