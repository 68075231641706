import { css } from '@emotion/react';

const getStyle = (enableHover: boolean) => ({ radius, color }) => css`
  border-radius: ${radius.default};
  border: 1px solid ${color.neutral['40']};
  background: ${color.neutral.white};
  display: flex;
  align-content: center;
  flex-direction: column;

  ${enableHover &&
  `&:hover {
    cursor: pointer;
    border: 1px solid ${color.neutral['80']};
    background: ${color.neutral['10']};
  }`}
`;

export { getStyle };
