import React from 'react';

import { Button, ButtonType } from '@lib/Button';

import { wrapperStyles } from './styles';

const Modal = React.forwardRef(
  (
    {
      content,
      primaryButtonText,
      secondaryButtonText,
      title,
      withPortal,
      onPrimaryClick,
      onSecondaryClick,
    },
    ref
  ) => (
    <div css={(theme) => wrapperStyles(theme, withPortal)} ref={ref}>
      <div className="ath-modal-content">
        <div className="ath-modal-title">{title}</div>
        {content}
      </div>
      <div className="ath-modal-footer">
        <Button label={secondaryButtonText} type={ButtonType.TEXT} onClick={onSecondaryClick} />
        <Button label={primaryButtonText} onClick={onPrimaryClick} />
      </div>
    </div>
  )
);

export default Modal;
