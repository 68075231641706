import React from 'react';

import { PrimaryLayout } from '@align-components/layouts/primary';
import { PrimaryLayoutTabs } from '@align-components/layouts/primary/context.types';
import { getStyle } from '@align-pages/teams/list/teamsList.styles';
import { TeamsSection } from '@align-pages/teams/list/teamsSection';
import { UsersSection } from '@align-pages/teams/list/usersSection';

export const TeamsList: React.FC = React.memo(() => {
  return (
    <PrimaryLayout tab={PrimaryLayoutTabs.TEAMS}>
      <div css={getStyle}>
        <TeamsSection />
        <UsersSection />
      </div>
    </PrimaryLayout>
  );
});
