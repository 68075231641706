export enum PRIVATE_API_ENDPOINTS_CONSTANTS {
  ALIGN_GOALS_LIST = '/align/goals',
  ALIGN_TEAM_METRICS = '/align/team_metrics',
  ALIGN_TEAM_TREE = '/align/team_tree/:teamId',
  ALIGN_MEMBERS = '/align/members/:teamId',
  ALIGN_CREATE_GOAL = '/align/goal/create',
  ALIGN_UPDATE_GOAL = '/align/goal/:goalId',
  ALIGN_GOAL_REMOVE = '/align/goal/:goalId/unassign_team',
  BECOME = '/become',
  SEARCHED_PULL_REQUESTS = '/search/pull_requests',
  SEARCHED_ISSUES = '/search/jira_issues',
  ACCOUNT_FEATURES = '/account/:accountId/features',
  DASHBOARD = '/team/:teamId/dashboard/:dashboardId',
  DASHBOARD_CHART_CREATE = '/team/:teamId/dashboard/:dashboardId/chart/create',
  DASHBOARD_CHART_REMOVE = '/team/:teamId/dashboard/:dashboardId/chart/:chartId',
  DASHBOARD_CHART_EDIT = '/team/:teamId/dashboard/:dashboardId/chart/:chartId',
}
