import { capitalCase } from 'case-anything';

import { VarType } from '@common-services/api/common/types/common';

export const reduceLoginName = (loginName: string): string => loginName.replace('github.com/', '');

export const removePathFromUrl = (url: string): string => {
  return url ? url.substring(0, url.lastIndexOf('/')) : null;
};

export const isString = (n: string | number): boolean => {
  return typeof n === 'string';
};

export const isInteger = (n: string | number): boolean => {
  return Number(n) === n && n % 1 === 0;
};

export const isFloat = (n: string | number): boolean => {
  return Number(n) === n && n % 1 !== 0;
};

export const getVarType = (value: string | number): VarType => {
  if (isString(value)) {
    return VarType.STRING;
  } else if (isFloat(value)) {
    return VarType.FLOAT;
  } else if (isInteger(value)) {
    return VarType.NUMBER;
  } else {
    return null;
  }
};

export { capitalCase };

export const replaceVarsInStr = (str: string, replace: { [id: string]: any }): string => {
  for (const key in replace) {
    if (replace.hasOwnProperty(key)) {
      const reg: RegExp = new RegExp(`:${key}`);
      const replaceValue: string = replace[key].toString() || '';
      str = str.replace(reg, replaceValue);
    }
  }
  return str;
};

export * as StringService from './stringService';
