import React from 'react';

import TicketDistributionPanel from './TicketDistributionPanel';
import WorkByEpicPanel from './WorkByEpicPanel';

const InsightsNewFeatures = () => {
  return (
    <>
      <div className="row mx-0 px-0 mb-4">
        <div className="col-md-9 mx-0 pl-0 pr-1">
          <WorkByEpicPanel />
        </div>
        <div className="col-md-3 mx-0 pl-3 pr-0">
          <TicketDistributionPanel />
        </div>
      </div>
    </>
  );
};

export default InsightsNewFeatures;
