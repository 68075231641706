import { css } from '@emotion/react';

export const tabNavStyles = ({ color }, leftAligned) => css`
  border-bottom: 1px solid ${color.neutral[40]};
  display: flex;
  justify-content: ${leftAligned ? 'left' : 'center'};
`;

export const tabStyles = ({ color, spacing, transition }) => css`
  border-color: ${color.neutral[40]};
  cursor: pointer;
  height: 52px;
  margin: ${spacing.gap['0']} ${spacing.gap['06']};
  transition: ${transition.default};

  &:first-of-type {
    margin-left: ${spacing.gap['0']};
  }

  &:last-child {
    margin-right: ${spacing.gap['0']};
  }

  &:hover {
    border-bottom: 0.3rem solid ${color.neutral[40]};
  }

  &.active,
  &.active:hover {
    border-bottom: 0.3rem solid ${color.ui.lightorange[100]};
    cursor: default;
  }
`;

export const tabContentStyles = ({ spacing }) => css`
  padding: ${spacing.gap['04']} ${spacing.gap['0']};
`;
