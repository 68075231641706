import React, { useState, useRef, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';

import defaultImage from '@assets/images/default-user-image.png';
import { useOnClickOutside } from '@common-hooks/useOnClickOutside';
import { IUser } from '@common-types/common';

import { InvitationCreator } from '../InvitationCreator';
import {
  wrapperStyles,
  toggleStyles,
  avatarStyles,
  dropdownStyles,
  settingsButtonStyles,
  loginButtonStyles,
} from './NavbarUserMenu.styles';

interface INavbarUserMenu {
  user: IUser;
}

export const NavbarUserMenu: React.FC<INavbarUserMenu> = React.memo(({ user }) => {
  const location = useLocation();
  const avatarRef = useRef();
  const dropdownRef = useRef();
  const [showDropdown, setShowDropdown] = useState(false);

  useOnClickOutside([avatarRef, dropdownRef], () => showDropdown && setShowDropdown(false));

  const toggleDropdown = useCallback(() => setShowDropdown(!showDropdown), [showDropdown]);

  return (
    <div css={wrapperStyles}>
      <div css={toggleStyles} onClick={toggleDropdown} id="dropdown-toggle" ref={avatarRef}>
        <img css={avatarStyles} src={user.picture || defaultImage} alt="" />
      </div>
      <div css={dropdownStyles(showDropdown)} id="dropdown-menu" ref={dropdownRef}>
        <header>
          <img css={avatarStyles} src={user.picture || defaultImage} alt="" />
          <p>{user.name}</p>
        </header>
        <section>
          <InvitationCreator user={user} />
        </section>
        <section>
          <div css={settingsButtonStyles}>
            <Link to={{ pathname: '/settings', state: { from: location.pathname } }}>Settings</Link>
          </div>
        </section>
        <footer>
          <Link css={loginButtonStyles} to="/logout">
            Logout
          </Link>
        </footer>
      </div>
    </div>
  );
});
