import { useCallback, useEffect, useMemo, useState } from 'react';

import { IUseTeamMembers } from '@common-pages/Settings/teams/teams.types';
import {
  useOwnedContributors,
  useOwnedContributorsFiltered,
} from '@common-services/api/public/hooks/useContributorsData';
import { ArrayService } from '@common-services/arrayService';

import { TeamsServices } from './teams.services';

export function useTeamMembers(filtered: string[] = [], selected: string[] = []): IUseTeamMembers {
  const [isContributorsRequested, setIsContributorsRequested] = useState(false);
  const [isContributorsLoaded, setIsContributorsLoaded] = useState(false);
  const { data: developersData, isLoading: developersIsLoading } = useOwnedContributorsFiltered(
    true
  );
  const { data: contributorsData, isLoading: contributorsIsLoading } = useOwnedContributors(
    isContributorsRequested,
    true
  );
  const isLoading = useMemo(() => developersIsLoading || contributorsIsLoading, [
    developersIsLoading,
    contributorsIsLoading,
  ]);
  const convertedDevelopers = useMemo(() => {
    return developersData
      ? TeamsServices.convertDevelopers(developersData, filtered, selected)
      : [];
  }, [developersData, filtered, selected]);
  const convertedContributors = useMemo(() => {
    if (contributorsData) {
      const convertedContributors = TeamsServices.convertContributors(
        contributorsData,
        filtered,
        selected
      );
      return ArrayService.sortByKeyValue(convertedContributors, ['name'], ['asc']);
    } else {
      return [];
    }
  }, [contributorsData, filtered, selected]);
  const loadMoreClickHandler = useCallback(() => {
    setIsContributorsRequested(true);
  }, []);
  useEffect(() => {
    if (contributorsData) setIsContributorsLoaded(true);
  }, [contributorsData]);
  return {
    developersData,
    contributorsData,
    convertedDevelopers,
    convertedContributors,
    isLoading,
    loadMoreClickHandler,
    isContributorsRequested,
    isContributorsLoaded,
  };
}
