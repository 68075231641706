import {
  ChartMetrics,
  ChartParamSelection,
  ChartParamsType,
  IChartConfig,
  IChartParamsConfig,
  IChartTimeInterval,
} from '@align-pages/dashboard/types';
import {
  IMetricsConfigNames,
  JiraInProgressOrderByField,
  JiraIssueMetricField,
  JiraStatusLabel,
  MetricColumnName,
  PrInProgressOrderByField,
  ValueType,
} from '@align-types/constants';
import { PUBLIC_API_ENDPOINTS_CONSTANTS } from '@common-services/api/public/constants';
import { ObjectService } from '@common-services/objectService';
import { PR_STAGE, PR_STAGE_API_MAPPING } from '@common-services/prHelpers';
import { ChartTypeEnum } from '@lib/chart';
import { FilterType } from '@lib/filter-panel';
import { icons } from '@lib/icon';

export const dashboardChartIntervals: IChartTimeInterval[] = [
  IChartTimeInterval.P2W,
  IChartTimeInterval.P1M,
  IChartTimeInterval.P3M,
  IChartTimeInterval.P6M,
  IChartTimeInterval.P1Y,
];

// index of the option that will be the selected option as default
export const defaultChartIntervalIndex = 2;
type IIntervalNameOverwrites = {
  [key in IChartTimeInterval]?: string;
};

// To use as names for dashboardChartIntervals
// If not defined, it'll use default value which created from the duration itself
export const intervalNameOverwrites: IIntervalNameOverwrites = {
  [IChartTimeInterval.P2W]: 'Last 14 days',
  [IChartTimeInterval.P1M]: 'Last 30 days',
  [IChartTimeInterval.P6M]: 'Last 6 months',
  [IChartTimeInterval.P1Y]: 'Last 12 months',
};

export enum ChartConfigGroup {
  JIRA = 'jira',
  GITHUB = 'github',
}

export const chartConfig: IChartConfig[] = [
  {
    description:
      'Elapsed time between the moment an issue is picked up (transition to an In Progress Jira status category) and the moment it gets resolved. Calculated up to the 95th percentile.',
    metric: ChartMetrics.JIRA_LEAD_TIME,
    name: 'Lead Time',
    endpoint: PUBLIC_API_ENDPOINTS_CONSTANTS.ISSUES,
    valueType: ValueType.date,
    chartType: ChartTypeEnum.FILLED_AREA,
    unit: null,
    group: ChartConfigGroup.JIRA,
    completedOrderBy: IMetricsConfigNames.jiraLeadTime,
    inProgressOrderBy: JiraInProgressOrderByField.WORK_BEGAN,
    inProgressStages: [JiraStatusLabel.IN_PROGRESS],
    inProgressOrderAscending: true,
    metricColumnName: MetricColumnName.LEAD_TIME,
    jiraMetricField: JiraIssueMetricField.LEAD_TIME,
  },
  {
    description:
      'Elapsed time between min(1st commit of a PR, PR creation) and the code being used in production. Calculated up to the 95th percentile.',
    metric: ChartMetrics.PR_CYCLE_TIME,
    name: 'PR Cycle Time',
    endpoint: PUBLIC_API_ENDPOINTS_CONSTANTS.PULL_REQUESTS,
    valueType: ValueType.date,
    chartType: ChartTypeEnum.FILLED_AREA,
    unit: null,
    group: ChartConfigGroup.GITHUB,
    completedOrderBy: IMetricsConfigNames.prCycleTime,
    inProgressOrderBy: PrInProgressOrderByField.TOTAL_STAGE,
    inProgressStages: [
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.WIP),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.REVIEW),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.MERGE),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.RELEASE),
    ],
    metricColumnName: MetricColumnName.CYCLE_TIME,
  },
  {
    description:
      'Elapsed time between the 1st commit of the pull request and the 1st review request. Calculated up to the 95th percentile.',
    metric: ChartMetrics.PR_WIP_TIME,
    name: 'WIP Time',
    endpoint: PUBLIC_API_ENDPOINTS_CONSTANTS.PULL_REQUESTS,
    valueType: ValueType.date,
    chartType: ChartTypeEnum.FILLED_AREA,
    unit: null,
    group: ChartConfigGroup.GITHUB,
    completedOrderBy: IMetricsConfigNames.prWipTime,
    inProgressOrderBy: PrInProgressOrderByField.WIP,
    inProgressStages: [ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.WIP)],
    metricColumnName: MetricColumnName.WIP_TIME,
  },
  {
    description:
      'Elapsed time between the 1st review request and the last pull request approval. Calculated up to the 95th percentile.',
    metric: ChartMetrics.PR_REVIEW_TIME,
    name: 'Review Time',
    endpoint: PUBLIC_API_ENDPOINTS_CONSTANTS.PULL_REQUESTS,
    valueType: ValueType.date,
    chartType: ChartTypeEnum.FILLED_AREA,
    unit: null,
    group: ChartConfigGroup.GITHUB,
    completedOrderBy: IMetricsConfigNames.prReviewTime,
    inProgressOrderBy: PrInProgressOrderByField.REVIEW_REQUEST,
    inProgressStages: [ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.REVIEW)],
    metricColumnName: MetricColumnName.REVIEW_TIME,
  },
  {
    description:
      'Elapsed time between the last approval and the pull request being merged. Calculated up to the 95th percentile.',
    metric: ChartMetrics.PR_MERGE_TIME,
    name: 'Merge Time',
    endpoint: PUBLIC_API_ENDPOINTS_CONSTANTS.PULL_REQUESTS,
    valueType: ValueType.date,
    chartType: ChartTypeEnum.FILLED_AREA,
    unit: null,
    group: ChartConfigGroup.GITHUB,
    completedOrderBy: IMetricsConfigNames.prMergeTime,
    inProgressOrderBy: PrInProgressOrderByField.MERGE,
    inProgressStages: [
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.WIP),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.REVIEW),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.MERGE),
    ],
    metricColumnName: MetricColumnName.MERGE_TIME,
  },
  {
    description:
      'Elapsed time between the pull request being merged and the moment it gets released according to the release settings. Calculated up to the 95th percentile.',
    metric: ChartMetrics.PR_RELEASE_TIME,
    name: 'Release Time',
    endpoint: PUBLIC_API_ENDPOINTS_CONSTANTS.PULL_REQUESTS,
    valueType: ValueType.date,
    chartType: ChartTypeEnum.FILLED_AREA,
    unit: null,
    group: ChartConfigGroup.GITHUB,
    completedOrderBy: IMetricsConfigNames.prReleaseTime,
    inProgressOrderBy: PrInProgressOrderByField.RELEASE,
    inProgressStages: [
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.WIP),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.REVIEW),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.MERGE),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.RELEASE),
    ],
    metricColumnName: MetricColumnName.RELEASE_TIME,
  },
  {
    description:
      'Elapsed time between the 1st review request and the 1st review submitted. Calculated up to the 95th percentile.',
    metric: ChartMetrics.PR_WAIT_FIRST_REVIEW_TIME,
    name: 'Wait time for 1st Review',
    endpoint: PUBLIC_API_ENDPOINTS_CONSTANTS.PULL_REQUESTS,
    valueType: ValueType.date,
    chartType: ChartTypeEnum.FILLED_AREA,
    unit: null,
    group: ChartConfigGroup.GITHUB,
    completedOrderBy: IMetricsConfigNames.prWaitFirstReviewTime,
    inProgressOrderBy: PrInProgressOrderByField.REVIEW_REQUEST,
    inProgressStages: [
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.WIP),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.REVIEW),
    ],
    metricColumnName: MetricColumnName.WAIT_TIME,
  },
  {
    description:
      'Elapsed time between the moment the pull request is opened and the moment it gets merged. Calculated up to the 95th percentile.',
    metric: ChartMetrics.PR_OPEN_TIME,
    name: 'PR Open Time',
    endpoint: PUBLIC_API_ENDPOINTS_CONSTANTS.PULL_REQUESTS,
    valueType: ValueType.date,
    chartType: ChartTypeEnum.FILLED_AREA,
    unit: null,
    group: ChartConfigGroup.GITHUB,
    completedOrderBy: IMetricsConfigNames.prOpenTime,
    inProgressOrderBy: PrInProgressOrderByField.TOTAL_STAGE,
    inProgressStages: [
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.WIP),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.REVIEW),
      ObjectService.getKeyByValue(PR_STAGE_API_MAPPING, PR_STAGE.MERGE),
    ],
    metricColumnName: MetricColumnName.OPEN_TIME,
  },
  {
    description: 'Average size of pull requests active during the timeframe in lines of code.',
    metric: ChartMetrics.PR_SIZE,
    name: 'PR Size',
    endpoint: PUBLIC_API_ENDPOINTS_CONSTANTS.PULL_REQUESTS,
    valueType: ValueType.int,
    chartType: ChartTypeEnum.FILLED_AREA,
    unit: {
      singular: 'line',
      plural: 'lines',
    },
    group: ChartConfigGroup.GITHUB,
  },
  {
    description: 'Percentage of pull requests mapped to Jira issues.',
    metric: ChartMetrics.PR_ALL_MAPPED_TO_JIRA,
    name: 'PR Mapping',
    endpoint: PUBLIC_API_ENDPOINTS_CONSTANTS.PULL_REQUESTS,
    valueType: ValueType.float,
    chartType: ChartTypeEnum.FILLED_AREA,
    unit: {
      singular: '%',
      plural: '%',
    },
    group: ChartConfigGroup.JIRA,
  },
  {
    description: 'Number of opened pull requests.',
    metric: ChartMetrics.PR_OPENED,
    name: 'PRs Created',
    endpoint: PUBLIC_API_ENDPOINTS_CONSTANTS.PULL_REQUESTS,
    valueType: ValueType.int,
    // TODO: It will be Vertical Bar Chart
    chartType: ChartTypeEnum.FILLED_AREA,
    unit: {
      singular: 'pull request',
      plural: 'pull requests',
    },
    group: ChartConfigGroup.GITHUB,
  },
  {
    description: 'Number of pull requests merged.',
    metric: ChartMetrics.PR_MERGED,
    name: 'PRs Merged',
    endpoint: PUBLIC_API_ENDPOINTS_CONSTANTS.PULL_REQUESTS,
    valueType: ValueType.int,
    // TODO: It will be Vertical Bar Chart
    chartType: ChartTypeEnum.FILLED_AREA,
    unit: {
      singular: 'pull request',
      plural: 'pull requests',
    },
    group: ChartConfigGroup.GITHUB,
  },
  {
    description: 'Number of pull requests released according to the release settings',
    metric: ChartMetrics.PR_CYCLE_COUNT,
    name: 'PRs Released',
    endpoint: PUBLIC_API_ENDPOINTS_CONSTANTS.PULL_REQUESTS,
    valueType: ValueType.int,
    // TODO: It will be Vertical Bar Chart
    chartType: ChartTypeEnum.FILLED_AREA,
    unit: {
      singular: 'pull request',
      plural: 'pull requests',
    },
    group: ChartConfigGroup.GITHUB,
  },
  {
    description: 'Percentage of pull requests that get at least one code review.',
    metric: ChartMetrics.PR_REVIEWED_RATIO,
    name: 'Ratio of PRs Reviewed',
    endpoint: PUBLIC_API_ENDPOINTS_CONSTANTS.PULL_REQUESTS,
    valueType: ValueType.float,
    chartType: ChartTypeEnum.FILLED_AREA,
    unit: {
      singular: '%',
      plural: '%',
    },
    group: ChartConfigGroup.GITHUB,
  },
  {
    description: 'Average number of comments per pull request.',
    metric: ChartMetrics.PR_COMMENTS,
    name: 'Comments / PR',
    endpoint: PUBLIC_API_ENDPOINTS_CONSTANTS.PULL_REQUESTS,
    valueType: ValueType.int,
    chartType: ChartTypeEnum.FILLED_AREA,
    unit: {
      singular: 'comment',
      plural: 'comments',
    },
    group: ChartConfigGroup.GITHUB,
  },
  {
    description: 'Average number of code reviews per pull request.',
    metric: ChartMetrics.PR_REVIEWS,
    name: 'Code Reviews / PR',
    endpoint: PUBLIC_API_ENDPOINTS_CONSTANTS.PULL_REQUESTS,
    valueType: ValueType.int,
    chartType: ChartTypeEnum.FILLED_AREA,
    unit: {
      singular: 'review',
      plural: 'reviews',
    },
    group: ChartConfigGroup.GITHUB,
  },
  {
    description: 'Average number of participants per pull request. Excluding bots.',
    metric: ChartMetrics.PR_PARTICIPANTS,
    name: 'Participants / PR',
    endpoint: PUBLIC_API_ENDPOINTS_CONSTANTS.PULL_REQUESTS,
    valueType: ValueType.int,
    chartType: ChartTypeEnum.FILLED_AREA,
    unit: {
      singular: 'participant',
      plural: 'participants',
    },
    group: ChartConfigGroup.GITHUB,
  },
  {
    description: 'Number of Jira issues raised.',
    metric: ChartMetrics.JIRA_RAISED,
    name: 'Issues Created',
    endpoint: PUBLIC_API_ENDPOINTS_CONSTANTS.ISSUES,
    valueType: ValueType.int,
    // TODO: It will be Vertical Bar Chart
    chartType: ChartTypeEnum.FILLED_AREA,
    unit: {
      singular: 'issue',
      plural: 'issues',
    },
    group: ChartConfigGroup.JIRA,
  },
  {
    description: 'Number of Jira issues resolved.',
    metric: ChartMetrics.JIRA_RESOLVED,
    name: 'Issues Resolved',
    endpoint: PUBLIC_API_ENDPOINTS_CONSTANTS.ISSUES,
    valueType: ValueType.int,
    // TODO: It will be Vertical Bar Chart
    chartType: ChartTypeEnum.FILLED_AREA,
    unit: {
      singular: 'issue',
      plural: 'issues',
    },
    group: ChartConfigGroup.JIRA,
  },
  {
    description: 'Frequency of releases according to the release settings',
    metric: ChartMetrics.RELEASE_COUNT,
    name: 'Release Frequency',
    endpoint: PUBLIC_API_ENDPOINTS_CONSTANTS.RELEASES,
    valueType: ValueType.int,
    // TODO: It will be Vertical Bar Chart
    chartType: ChartTypeEnum.FILLED_AREA,
    unit: {
      singular: 'release',
      plural: 'releases',
    },
    isFrequency: true,
    group: ChartConfigGroup.GITHUB,
  },
  {
    description:
      'Elapsed time between the moment an issue has been raised and the moment it gets resolved. Calculated up to the 95th percentile.',
    metric: ChartMetrics.JIRA_LIFE_TIME,
    name: 'Mean Time To Restore',
    endpoint: PUBLIC_API_ENDPOINTS_CONSTANTS.ISSUES,
    valueType: ValueType.date,
    chartType: ChartTypeEnum.FILLED_AREA,
    unit: null,
    group: ChartConfigGroup.JIRA,
    completedOrderBy: IMetricsConfigNames.jiraLifeTime,
    inProgressOrderBy: JiraInProgressOrderByField.CREATED,
    inProgressStages: [JiraStatusLabel.TO_DO, JiraStatusLabel.IN_PROGRESS],
    inProgressOrderAscending: true,
    metricColumnName: MetricColumnName.MTTR,
    jiraMetricField: JiraIssueMetricField.MTTR,
  },
  {
    description:
      'Elapsed time between the moment an issue is picked up (transition to an In Progress Jira status category) and the moment it gets resolved. Calculated up to the 95th percentile.',
    metric: ChartMetrics.JIRA_LEAD_TIME,
    name: 'Mean Time To Repair',
    endpoint: PUBLIC_API_ENDPOINTS_CONSTANTS.ISSUES,
    valueType: ValueType.date,
    chartType: ChartTypeEnum.FILLED_AREA,
    unit: null,
    group: ChartConfigGroup.JIRA,
    completedOrderBy: IMetricsConfigNames.jiraLeadTime,
    inProgressOrderBy: JiraInProgressOrderByField.WORK_BEGAN,
    inProgressStages: [JiraStatusLabel.IN_PROGRESS],
    inProgressOrderAscending: true,
    metricColumnName: MetricColumnName.REPAIR_TIME,
    jiraMetricField: JiraIssueMetricField.LEAD_TIME,
  },
  {
    description:
      'Elapsed time between the moment an issue has been raised and the moment it gets actually picked up (transition to an In Progress Jira status category). Calculated up to the 95th percentile.',
    metric: ChartMetrics.JIRA_ACKNOWLEDGE_TIME,
    name: 'Mean Time To Acknowledge',
    endpoint: PUBLIC_API_ENDPOINTS_CONSTANTS.ISSUES,
    valueType: ValueType.date,
    chartType: ChartTypeEnum.FILLED_AREA,
    unit: null,
    group: ChartConfigGroup.JIRA,
    completedOrderBy: IMetricsConfigNames.jiraAckTime,
    inProgressOrderBy: JiraInProgressOrderByField.CREATED,
    inProgressStages: [JiraStatusLabel.TO_DO],
    inProgressOrderAscending: true,
    metricColumnName: MetricColumnName.MTTA,
    jiraMetricField: JiraIssueMetricField.ACK_TIME,
  },
];

export const ChartParamsConfig: IChartParamsConfig = {
  [ChartParamsType.FILTERS]: {
    enabled: true,
    name: 'Filters',
    singular: 'Filter',
    description: 'Filter your metric by repository, issue type, priority, initiaive...',
    selection: ChartParamSelection.MULTI,
    properties: [
      {
        value: FilterType.REPOSITORY,
        label: 'Repository',
        icon: icons.repository,
        rules: true,
        group: ChartConfigGroup.GITHUB,
      },
      {
        value: FilterType.JIRA_PROJECTS,
        label: 'Project',
        icon: icons.nav_epic,
        rules: true,
        group: ChartConfigGroup.JIRA,
      },
      {
        value: FilterType.JIRA_LABELS,
        label: 'Jira Labels',
        icon: icons.nav_epic,
        rules: true,
        group: ChartConfigGroup.JIRA,
      },
      {
        value: FilterType.JIRA_ISSUE_TYPES,
        label: 'Issue Type',
        icon: icons.issues,
        rules: true,
        group: ChartConfigGroup.JIRA,
      },
      {
        value: FilterType.JIRA_PRIORITIES,
        label: 'Priority',
        icon: icons.check_failed,
        rules: true,
        group: ChartConfigGroup.JIRA,
      },
    ],
  },
  [ChartParamsType.BREAKDOWNS]: {
    enabled: true,
    name: 'Breakdowns',
    singular: 'Breakdown',
    description: 'Segment and group your metric into teams, repositories...',
    selection: ChartParamSelection.SINGLE,
    properties: [
      {
        value: FilterType.TEAMS,
        label: 'Team',
        icon: icons.people,
        group: ChartConfigGroup.GITHUB,
      },
      {
        value: FilterType.REPOSITORY,
        label: 'Repository',
        icon: icons.repository,
        group: ChartConfigGroup.GITHUB,
      },
      {
        value: FilterType.JIRA_LABELS,
        label: 'Jira Label',
        icon: icons.nav_epic,
        group: ChartConfigGroup.JIRA,
      },
      {
        value: FilterType.JIRA_ISSUE_TYPES,
        label: 'Issue Type',
        icon: icons.issues,
        group: ChartConfigGroup.JIRA,
      },
      {
        value: FilterType.JIRA_PRIORITIES,
        label: 'Priority',
        icon: icons.check_failed,
        group: ChartConfigGroup.JIRA,
      },
    ],
  },
};

type IUnsupportedChartFilters = {
  [key in ChartMetrics]?: FilterType[];
};

export const UnsupportedChartFilters: IUnsupportedChartFilters = {
  [ChartMetrics.PR_ALL_MAPPED_TO_JIRA]: [
    FilterType.JIRA_PRIORITIES,
    FilterType.JIRA_ISSUE_TYPES,
    FilterType.JIRA_LABELS,
    FilterType.JIRA_PROJECTS,
  ],
  [ChartMetrics.JIRA_LEAD_TIME]: [FilterType.REPOSITORY],
  [ChartMetrics.JIRA_RAISED]: [FilterType.REPOSITORY],
  [ChartMetrics.JIRA_RESOLVED]: [FilterType.REPOSITORY],
  [ChartMetrics.JIRA_LIFE_TIME]: [FilterType.REPOSITORY],
  [ChartMetrics.JIRA_LEAD_TIME]: [FilterType.REPOSITORY],
  [ChartMetrics.JIRA_ACKNOWLEDGE_TIME]: [FilterType.REPOSITORY],
};

export type ICounterMetric = {
  [key in ChartMetrics]?: ChartMetrics;
};

export const counterMetric: ICounterMetric = {
  [ChartMetrics.JIRA_LEAD_TIME]: ChartMetrics.JIRA_RESOLVED,
  [ChartMetrics.PR_CYCLE_TIME]: ChartMetrics.PR_CYCLE_COUNT,
  [ChartMetrics.PR_WIP_TIME]: ChartMetrics.PR_WIP_COUNT,
  [ChartMetrics.PR_REVIEW_TIME]: ChartMetrics.PR_REVIEW_COUNT,
  [ChartMetrics.PR_MERGE_TIME]: ChartMetrics.PR_MERGING_COUNT,
  [ChartMetrics.PR_RELEASE_TIME]: ChartMetrics.RELEASE_COUNT,
  [ChartMetrics.PR_WAIT_FIRST_REVIEW_TIME]: ChartMetrics.PR_WAIT_FIRST_REVIEW_COUNT,
  [ChartMetrics.PR_OPEN_TIME]: ChartMetrics.PR_OPENED,
  [ChartMetrics.PR_SIZE]: ChartMetrics.PR_OPENED,
  [ChartMetrics.PR_ALL_MAPPED_TO_JIRA]: ChartMetrics.PR_OPENED,
  [ChartMetrics.PR_OPENED]: ChartMetrics.PR_OPEN_COUNT,
  [ChartMetrics.PR_MERGED]: ChartMetrics.PR_MERGED,
  [ChartMetrics.PR_CYCLE_COUNT]: ChartMetrics.PR_CYCLE_COUNT,
  [ChartMetrics.PR_REVIEWED_RATIO]: ChartMetrics.PR_REVIEWED,
  [ChartMetrics.PR_COMMENTS]: ChartMetrics.PR_MERGED,
  [ChartMetrics.PR_REVIEWS]: ChartMetrics.PR_MERGED,
  [ChartMetrics.PR_PARTICIPANTS]: ChartMetrics.PR_MERGED,
  [ChartMetrics.JIRA_RAISED]: ChartMetrics.JIRA_RAISED,
  [ChartMetrics.JIRA_RESOLVED]: ChartMetrics.JIRA_RESOLVED,
  [ChartMetrics.RELEASE_COUNT]: ChartMetrics.RELEASE_COUNT,
  [ChartMetrics.JIRA_LIFE_TIME]: ChartMetrics.JIRA_RESOLVED,
  [ChartMetrics.JIRA_ACKNOWLEDGE_TIME]: ChartMetrics.JIRA_ACKNOWLEDGED,
};

export enum ChartBlockSize {
  WIDTH = 670,
  HEIGHT = 422,
}
