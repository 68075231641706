import { css } from '@emotion/react';

const getStyle = ({ spacing, radius, font, color }) => css`
  height: 48px;
  border-bottom: 1px solid ${color.neutral['40']};
  display: flex;
  align-content: center;
  align-items: center;
  gap: ${spacing.gap['03']};
  padding: ${spacing.gap['03']} ${spacing.gap['05']};

  &:last-of-type {
    border-bottom: 0px;
  }

  & > div > img {
    border-radius: ${radius.circle};
  }

  & > span {
    font-size: ${font.size.default};
    font-weight: ${font.weight.regular};

    &.user-name {
      color: ${color.neutral['100']};
    }

    &.user-github {
      color: ${color.neutral['80']};
    }
  }
`;

export { getStyle };
