import _ from 'lodash';
import moment from 'moment';
import React from 'react';

import TimeSeries from '@analytics-components/charts/TimeSeries';
import { BigText } from '@analytics-components/charts/Tooltip';
import { SimpleKPI } from '@analytics-components/insights/KPI';
import { fetchPRsMetrics } from '@analytics-services/api';
import { getFeature, featuresList } from '@analytics-services/flags';
import { dateTime } from '@common-services/dateService';
import { number } from '@common-services/format';
import * as NumberService from '@common-services/numberService';
import { theme } from '@styles/theme';
import { hexToRGBA } from '@utils/colors';

const prsRatioFlowTimeseries = {
  fetcher: async (api, cachedData, apiContext) => {
    const {
      account,
      interval,
      repositories,
      contributors,
      labels,
      excludeInactive,
      features,
      epics,
      issueTypes,
    } = apiContext;
    const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, features)?.parameters
      ?.value;

    // Cannot use global data as the KPI for PRs ratio flow needs `day` granularty
    return {
      timeseries: await fetchPRsMetrics(
        api,
        account,
        ['day'],
        interval,
        ['flow-ratio'],
        {
          repositories,
          with: { author: contributors },
          labels_include: labels,
          ...(excludedLabels ? { labels_exclude: excludedLabels } : {}),
          jira: { epics, issue_types: issueTypes },
        },
        null,
        excludeInactive
      ),
    };
  },
  plumber: (fetchedData, cachedData, apiContext) => {
    const customGranularity = cachedData['prs-metrics'].customGranularity;
    const timeseriesGranularity = customGranularity === 'month' ? 'custom-aligned' : 'custom';
    const prsMetrics = cachedData['prs-metrics']['values'][timeseriesGranularity];
    const chartData = _(_.zip(prsMetrics['flow-ratio'], prsMetrics['opened'], prsMetrics['closed']))
      .map((v) => {
        const ratio = v[0].value;
        const opened = v[1].value;
        const closed = v[2].value;
        const trickedOpened = opened !== null || closed !== null ? (opened + 1).toFixed() : null;
        const trickedClosed = opened !== null || closed !== null ? (closed + 1).toFixed() : null;
        return {
          day: v[0].date,
          value: ratio,
          legend: { ratio, opened: trickedOpened, closed: trickedClosed },
        };
      })
      .value();
    const timeSeries = fetchedData.timeseries.calculated.length
      ? fetchedData.timeseries.calculated[0].values
      : [];
    const KPIsData = {
      avgRatioFlow: cachedData['prs-metrics']['values']['all']['flow-ratio'],
      highestDay: _(timeSeries)
        .map((v) => ({
          day: moment(v.date).format('dddd'),
          value: v.values[0],
        }))
        .groupBy('day')
        .map((series, k) => ({
          day: k,
          value: _(series).map('value').mean(),
        }))
        .maxBy('value'),
    };
    const axisKeys = {
      x: 'day',
      y: 'value',
    };

    const tickFormat = customGranularity === 'month' ? dateTime.month : dateTime.monthDay;
    const tooltip = {
      renderBigFn: (v) => (
        <BigText
          content={`${v.legend.opened}/${v.legend.closed}`}
          extra={NumberService.round(v.legend.ratio, 1)}
        />
      ),
    };
    if (_(['month', 'week']).includes(customGranularity)) {
      tooltip.dateRange = {
        from: apiContext.interval.from,
        to: apiContext.interval.to,
      };
      tooltip.granularity = customGranularity;
    }
    return {
      empty: chartData.filter((v) => v[axisKeys.y] !== null).length === 0,
      chart: {
        component: TimeSeries,
        params: {
          data: chartData,
          extra: {
            reference: {
              value: 1,
              color: theme.color.neutral['80'],
            },
            axisLabels: {
              y: 'PR Ratio Flow',
            },
            maxNumberOfTicks: 10,
            axisKeys: axisKeys,
            color: theme.color.stage.wip,
            fillColor: hexToRGBA(theme.color.stage.wip, 0.1),
            ticks: {
              x: { tickFormat },
            },
            tooltip,
          },
        },
      },
      kpis: [
        {
          title: { text: 'Average Pull Request', bold: true },
          subtitle: { text: 'Ratio Flow' },
          component: SimpleKPI,
          params: {
            value: number.fixed(KPIsData.avgRatioFlow, 2),
          },
        },
        {
          title: { text: 'Day with the Highest Pull Request', bold: true },
          subtitle: { text: 'Ratio Flow' },
          component: SimpleKPI,
          params: {
            value: KPIsData.highestDay ? `${KPIsData.highestDay.day}` : null,
            secondary: KPIsData.highestDay
              ? `(${number.fixed(KPIsData.highestDay.value, 2)})`
              : null,
          },
        },
      ],
    };
  },
  prefetchedDataIds: ['prs-metrics'],
};

export default prsRatioFlowTimeseries;
