import React from 'react';

import Box from '@analytics-components/insights/Box';
import { InsightsError } from '@analytics-components/insights/Helper';
import { useDataWidget } from '@analytics-context/DataWidget';
import { StringService } from '@analytics-services/stringService';
import { DataErrorType } from '@analytics-types/common';
import { NoDeployments } from '@lib/empty/noDeployments';

import { getContent } from './services';
import { DataType } from './services/types';

interface Data {
  data: DataType;
  error: DataErrorType;
  isLoading: boolean;
}

const InsightsDeployDataBinder: React.FC = React.memo(() => {
  const { data, error, isLoading }: Data = useDataWidget();

  if (isLoading) {
    return <div className="insights-placeholder" />;
  }

  if (error) {
    return <InsightsError />;
  }

  if (data.noDeployments) {
    return <NoDeployments />;
  }

  return (
    <>
      {(data?.dataValues || []).map((d) => (
        <Box
          content={getContent(d, data.granularity, data.interval)}
          key={d.env}
          meta={{
            description: '',
            title: StringService.capitalizeFirstLetter(d.env),
          }}
        />
      ))}
    </>
  );
});

export default InsightsDeployDataBinder;
