import { useMutation } from 'react-query';

import { useCurrentTeam } from '@align-hooks/useTeams';
import { IUseMutationHook } from '@align-types/common';
import { useAuth0 } from '@common-context/Auth0';
import { useDemoCookie } from '@common-pages/Demo';
import { IApiBasicError } from '@common-services/api/common/types/common';
import { DashboardServices } from '@common-services/api/private/services/dashboard';
import { IDashboardAddChartParams } from '@common-services/api/private/types/dashboard';
import { CreatedIdentifier } from '@common-services/api/public/generated-from-backend/models';

export function useAddChartToDashboard(): IUseMutationHook<
  CreatedIdentifier,
  IApiBasicError,
  IDashboardAddChartParams
> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();
  const currentTeam = useCurrentTeam();

  const addChart = async (params: IDashboardAddChartParams): Promise<CreatedIdentifier> => {
    const token: string = isDemo === false ? await getTokenSilently() : null;

    return DashboardServices.addChartToDashboard(
      {
        dashboardId: 0,
        teamId: currentTeam.id,
      },
      {
        metric: params.metric.metric,
        name: params.chartName,
        description: params.metric.description,
        time_interval: params.time_interval,
        filters: params.filters,
        group_by: params.group_by,
      },
      token
    );
  };

  const { mutate, isLoading, data, error, isError, isSuccess } = useMutation<
    CreatedIdentifier,
    IApiBasicError,
    IDashboardAddChartParams
  >(addChart);

  return { mutate, isLoading, data, error, isError, isSuccess };
}
