import { css } from '@emotion/react';

export const wrapperStyles = css`
  height: 20px;
  display: inline-flex;
  align-items: center;
`;

export const iconWrapperStyles = ({ spacing, color }) => css`
  margin-right: ${spacing.gap['02']};
  display: inline-flex;
  align-items: center;
  color: ${color.neutral['80']};
`;

export const textStyles = (textColor) => ({ color }) => css`
  color: ${textColor || color.neutral['100']};
`;
