import { useQuery } from 'react-query';

import { CONFIG } from '@align-constants';
import { IUseQueryHookRest } from '@align-types/common';
import { useAuth0 } from '@common-context/Auth0';
import { useDemoCookie } from '@common-pages/Demo';
import { IApiBasicError } from '@common-services/api/common/types/common';
import {
  SearchJIRAIssuesRequest,
  SearchJIRAIssuesResponse,
} from '@common-services/api/private/generated-from-backend/models';
import { PrivateIssuesServices } from '@common-services/api/private/services/issues';

export function useSearchedIssues(
  params: SearchJIRAIssuesRequest,
  isCached: boolean = false,
  isDisabled: boolean = false
): IUseQueryHookRest<SearchJIRAIssuesResponse> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();
  const fetchSearchedIssues = async (): Promise<SearchJIRAIssuesResponse> => {
    const token: string = isDemo === false ? await getTokenSilently() : null;
    return PrivateIssuesServices.fetchSearchedIssues(params, token);
  };

  const { isLoading, data, error } = useQuery<SearchJIRAIssuesResponse, IApiBasicError>(
    [
      'searchedIssues',
      params.account,
      params.date_from,
      params.date_to,
      params.filter?.epics || null,
      params.filter?.issue_types || null,
      params.filter?.projects || null,
      params.filter?.priorities || null,
      params.filter?.status_categories || null,
      params.filter?.labels_include || null,
      params.with?.assignees,
      params.order_by[0].field,
    ],
    fetchSearchedIssues,
    {
      ...CONFIG.API.FETCH_OPTIONS,
      enabled: !isDisabled,
      cacheTime: isCached ? CONFIG.API.FETCH_OPTIONS.cacheTime : 0,
      staleTime: isCached ? CONFIG.API.FETCH_OPTIONS.staleTime : 0,
    }
  );

  return { isLoading, data, error };
}
