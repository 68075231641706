import { usePrsForTable } from '@align-hooks/usePrsForTable';
import { ITemplateMetric } from '@align-services/api/types/goalsTypes';
import { IDateRangeInMls, PrsIssuesTableTabs } from '@align-types/common';
import { IUseComputedPullRequests } from '@align-types/prs';
import { useUserContext } from '@common-context/User';
import { Goal } from '@common-services/api/private/generated-from-backend/models';

import { useGetSearchedPRs } from './useGetSearchedPRs';

export function useComputedPullRequests(
  curGoalData: Goal,
  templateMetric: ITemplateMetric,
  activeTab: PrsIssuesTableTabs,
  selectedRange: IDateRangeInMls
): IUseComputedPullRequests {
  const {
    user: {
      defaultAccount: { id: accountId },
    },
  } = useUserContext();

  const {
    data: initialCompletedPRs,
    isLoading: completedPRsIsLoading,
    error: completedPRsError,
  } = useGetSearchedPRs(
    accountId,
    curGoalData,
    templateMetric,
    PrsIssuesTableTabs.COMPLETED,
    selectedRange
  );

  const {
    data: inProgressPRs,
    isLoading: inProgressPRsIsLoading,
    error: inProgressPRsError,
  } = useGetSearchedPRs(
    accountId,
    curGoalData,
    templateMetric,
    PrsIssuesTableTabs.IN_PROGRESS,
    null
  );

  const { data, completedPRs, isLoading, isLoadingMore, error, onShowMoreHandler } = usePrsForTable(
    accountId,
    activeTab,
    selectedRange,
    initialCompletedPRs,
    inProgressPRs,
    completedPRsIsLoading,
    inProgressPRsIsLoading,
    completedPRsError,
    inProgressPRsError
  );

  return {
    data,
    completedPrsCount: completedPRs?.pull_requests?.length,
    inProgressPrsCount: inProgressPRs?.pull_requests?.length,
    isLoading,
    isLoadingMore,
    error,
    onShowMoreHandler,
  };
}
