import { create, GetState, SetState, State, StoreApi } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export const createStore = <T extends State>(
  fn: (set: SetState<T>, get: GetState<T>, api: StoreApi<T>) => T,
  name: string,
  isPersistent: boolean = false
) => {
  const persistentFn = isPersistent ? (fn) => persist(fn, { name }) : (fn) => fn;
  if (process.env.NODE_ENV === 'development') {
    return create<T>()(persistentFn(devtools(fn, { name })));
  }
  return create<T>(persistentFn(fn));
};
