import { useQuery } from 'react-query';

import { CONFIG } from '@align-constants';
import { IUseQueryHookRest } from '@align-types/common';
import { useAuth0 } from '@common-context/Auth0';
import { useDemoCookie } from '@common-pages/Demo';
import { IApiBasicError } from '@common-services/api/common/types/common';
import {
  SearchPullRequestsRequest,
  SearchPullRequestsResponse,
} from '@common-services/api/private/generated-from-backend/models';
import { PrivatePRsServices } from '@common-services/api/private/services/pullRequests';

export function useSearchedPRs(
  params: SearchPullRequestsRequest,
  isCached: boolean = false,
  isDisabled: boolean = false
): IUseQueryHookRest<SearchPullRequestsResponse> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();
  const fetchSearchedPRs = async (): Promise<SearchPullRequestsResponse> => {
    const token: string = isDemo === false ? await getTokenSilently() : null;
    return PrivatePRsServices.fetchSearchedPRs(params, token);
  };

  const { isLoading, data, error } = useQuery<SearchPullRequestsResponse, IApiBasicError>(
    [
      'searchedPRs',
      params.account,
      params.date_from,
      params.date_to,
      params.repositories,
      params.jira?.epics || null,
      params.jira?.issue_types || null,
      params.jira?.projects || null,
      params.jira?.priorities || null,
      params.jira?.labels_include || null,
      params.participants.author,
      params.order_by[0].field,
      params.stages,
    ],
    fetchSearchedPRs,
    {
      ...CONFIG.API.FETCH_OPTIONS,
      enabled: !isDisabled && PrivatePRsServices.isFetchSearchedPRsEnabled(params),
      cacheTime: isCached ? CONFIG.API.FETCH_OPTIONS.cacheTime : 0,
      staleTime: isCached ? CONFIG.API.FETCH_OPTIONS.staleTime : 0,
    }
  );

  return { isLoading, data, error };
}
