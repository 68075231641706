import React from 'react';

import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import Panel from '@analytics-components/insights/Panel';
import { prepareChartBoxes } from '@analytics-pages/delivery-pipeline/insights/helper';

import runTimePerPR from './runTimePerPR';

const meta = {
  title: 'Run Time - Average per Pull Request',
  description:
    'Keep track of the average time developers have wait for GitHub checks to run in each of their pull request. <p class="small text-secondary mt-1 mb-0">Calculated up to the 95th percentile.</p>',
};

const chartBoxes = [
  {
    id: 'run-time-per-pull-request',
    component: ChartBoxWithKPIWidget,
    params: runTimePerPR,
  },
];

const RunTimePerPRPanel = () => {
  const preparedChartBoxes = prepareChartBoxes(chartBoxes);
  return <Panel meta={meta} chartBoxes={preparedChartBoxes} />;
};

export default RunTimePerPRPanel;
