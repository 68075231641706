import classnames from 'classnames';
import React, { useState, useEffect } from 'react';

import { getInvitation } from '@analytics-services/api';
import { useAuth0 } from '@common-context/Auth0';

import { wrapperStyles } from './InvitationCreator.styles';
import { IInvitationCreatorProps } from './InvitationCreator.types';
import { CopyButton } from './components/CopyButton';

const InvitationCreator: React.FC<IInvitationCreatorProps> = React.memo(({ user, className }) => {
  const { getTokenSilently } = useAuth0();

  const [urlState, setUrlState] = useState('');
  const [loadingState, setLoadingState] = useState(false);

  useEffect(() => {
    setUrlState('');
    if (!user) {
      return;
    }

    setLoadingState(true);
    getTokenSilently()
      .then((token) => getInvitation(token, user.defaultAccount.id))
      .then(setUrlState)
      .catch((err) => console.error('Could not get invitation', err))
      .finally(() => setLoadingState(false));
  }, [user]);

  return loadingState ? (
    <div
      className={classnames(
        'invitationCreator d-flex flex-row d-flex justify-content-between align-items-center text-xs',
        className
      )}
    >
      loading...
    </div>
  ) : (
    urlState && (
      <div css={wrapperStyles}>
        <div className="desc">
          <div className="invite-text">Invite</div>
          <div className="url-text">{urlState}</div>
        </div>

        <CopyButton content={urlState} />
      </div>
    )
  );
});

InvitationCreator.defaultProps = {
  className: null,
};

export { InvitationCreator };
