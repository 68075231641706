import React from 'react';

import ButtonUI from '@analytics-components/ButtonUI';
import { FooterButtonsIds } from '@lib/FooterButtons/__tests__/testIds.ids';

import { getApplyButtonStyle, getCancelButtonStyle, getWrapperStyle } from './styles';

export interface FooterButtonsProps {
  label?: string;
  title?: string;
  isDisabled?: boolean;
  onApply?: () => void;
  onCancel?: () => void;
}

export const FooterButtons: React.FC<FooterButtonsProps> = React.memo(
  ({ label, title, isDisabled, onApply, onCancel }) => (
    <div
      data-cy="selector-footer"
      css={(theme) => getWrapperStyle(theme)}
      data-testid={FooterButtonsIds.btnBlock}
    >
      <ButtonUI
        label={'Cancel'}
        onClick={onCancel}
        style={getCancelButtonStyle}
        dataTestId={FooterButtonsIds.cancelBtn}
      />
      <ButtonUI
        label={label}
        disabled={isDisabled}
        onClick={onApply}
        style={(theme) => getApplyButtonStyle(theme, false)}
        title={title}
        dataTestId={FooterButtonsIds.applyBtn}
      />
    </div>
  )
);

FooterButtons.defaultProps = {
  label: 'Apply',
};
