import { useQuery } from 'react-query';

import { CONFIG } from '@align-constants';
import { IUseQueryHookRest, PrsIssuesTableTabs } from '@align-types/common';
import { useAuth0 } from '@common-context/Auth0';
import { useDemoCookie } from '@common-pages/Demo';
import { IApiBasicError } from '@common-services/api/common/types/common';
import {
  GetPullRequestsRequest,
  PullRequestSet,
} from '@common-services/api/public/generated-from-backend/models';
import { PublicPRsServices } from '@common-services/api/public/services/pullRequests';

interface IUseGetPRsParams extends GetPullRequestsRequest {
  activeTab?: PrsIssuesTableTabs;
}

export function useGetPRs(
  params: IUseGetPRsParams,
  isCached: boolean = false
): IUseQueryHookRest<PullRequestSet> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();
  const getPullRequests = async (): Promise<PullRequestSet> => {
    const token: string = isDemo === false ? await getTokenSilently() : null;
    return PublicPRsServices.getPullRequests(params, token);
  };

  const { isLoading, data, error } = useQuery<PullRequestSet, IApiBasicError>(
    ['getPullRequests', params.account, params.prs, params.activeTab],
    getPullRequests,
    {
      ...CONFIG.API.FETCH_OPTIONS,
      enabled: params?.prs?.length > 0,
      cacheTime: isCached ? CONFIG.API.FETCH_OPTIONS.cacheTime : 0,
      staleTime: isCached ? CONFIG.API.FETCH_OPTIONS.staleTime : 0,
    }
  );

  return { isLoading, data, error };
}
