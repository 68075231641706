import _ from 'lodash';
import React, { useState } from 'react';

import MultiSelect from '@analytics-components/MultiSelect';

const optionsSimpleGroups = [
  {
    label: 'Value [0-2]',
    options: [
      {
        label: 'Value 0',
        value: 0,
      },
      {
        label: 'Value 1',
        value: 1,
      },
    ],
  },
  {
    label: 'Value [3-5]',
    options: [
      {
        label: 'Value 3',
        value: 3,
      },
      {
        label: 'Value 4',
        value: 4,
      },
      {
        label: 'Value 5',
        value: 5,
      },
    ],
  },
  {
    label: 'Value [6-8]',
    options: [
      {
        label: 'Value 6',
        value: 6,
      },
      {
        label: 'Value 8',
        value: 8,
      },
    ],
  },
];

const optionsSimpleGroupsExtended = [
  {
    label: 'Value [0-2]',
    options: [
      {
        label: 'Value 0',
        value: 0,
      },
      {
        label: 'Value 1',
        value: 1,
      },
      {
        label: 'Value 2',
        value: 2,
      },
    ],
  },
  {
    label: 'Value [3-5]',
    options: [
      {
        label: 'Value 3',
        value: 3,
      },
      {
        label: 'Value 5',
        value: 5,
      },
    ],
  },
  {
    label: 'Value [6-8]',
    options: [
      {
        label: 'Value 6',
        value: 6,
      },
      {
        label: 'Value 7',
        value: 7,
      },
      {
        label: 'Value 8',
        value: 8,
      },
    ],
  },
  {
    label: 'Value [3,9-11]',
    options: [
      {
        label: 'Value 3',
        value: 3,
      },
      {
        label: 'Value 9',
        value: 9,
      },
      {
        label: 'Value 10',
        value: 10,
      },
      {
        label: 'Value 11',
        value: 11,
      },
    ],
  },
  {
    label: 'Value [12-13]',
    options: [
      {
        label: 'Value 12',
        value: 12,
      },
      {
        label: 'Value 13',
        value: 13,
      },
    ],
  },
  {
    label: 'Value [14]',
    options: [
      {
        label: 'Value 14',
        value: 14,
      },
    ],
  },
];

const optionsSimpleFlat = [
  {
    label: 'Value 0',
    value: 0,
  },
  {
    label: 'Value 1',
    value: 1,
  },
  {
    label: 'Value 3',
    value: 3,
  },
  {
    label: 'Value 4',
    value: 4,
  },
  {
    label: 'Value 5',
    value: 5,
  },
];

const optionsSimpleFlatExtended = [
  {
    label: 'Value 0',
    value: 0,
  },
  {
    label: 'Value 1',
    value: 1,
  },
  {
    label: 'Value 2',
    value: 1,
  },
  {
    label: 'Value 3',
    value: 3,
  },
  {
    label: 'Value 5',
    value: 5,
  },
];

const OPTIONS_GROUPS = {
  0: optionsSimpleGroups,
  1: optionsSimpleGroupsExtended,
};

const OPTIONS_FLAT = {
  0: optionsSimpleFlat,
  1: optionsSimpleFlatExtended,
};

const MultiSelectComponent = () => {
  const label = 'prototype';
  const isLoading = false;

  const [result, setResult] = useState();
  const [optionsIndex, setOptionsIndex] = useState(0);
  const [optionsType, setOptionsType] = useState(0);

  const toggleOptions = () => {
    setOptionsIndex((optionsIndex + 1) % Object.keys(OPTIONS_GROUPS).length);
  };

  const toggleOptionsType = () => {
    if (optionsType === 0) {
      setOptionsType(1);
    } else {
      setOptionsType(0);
    }
  };

  const onApply = (values) => setResult(_(values).map('label').value().join());
  const onCancel = () => {};

  const optionsBucket = optionsType === 0 ? OPTIONS_GROUPS : OPTIONS_FLAT;
  const options = optionsBucket[optionsIndex];
  return (
    <div>
      <button onClick={toggleOptionsType}>
        Toggle options type [current options type: {optionsType === 0 ? 'GROUPS' : 'FLAT'}]
      </button>
      <button onClick={toggleOptions}>
        Toggle options index [current options index: {optionsIndex}]
      </button>
      <div>
        <span>Result: </span>
        {result}
      </div>
      <MultiSelect
        label={label}
        isLoading={isLoading}
        options={options}
        onApply={onApply}
        onCancel={onCancel}
        forceGroups={true}
      />
    </div>
  );
};

export default MultiSelectComponent;
