import { css } from '@emotion/react';

export const wrapperStyles = css`
  display: flex;
  flex: 1;
`;

export const sidebarStyles = ({ color }) => css`
  flex: 1;
  background: ${color.neutral['10']};
  border-right: 1px solid ${color.neutral['40']};
  overflow: visible;
  max-width: 400px;
`;

export const contentStyles = ({ spacing }) => css`
  flex: 1;
  padding: ${spacing.gap['05']} ${spacing.gap['09']};

  .header {
    margin-bottom: ${spacing.gap['06']};
  }
`;
export const chartContainerStyles = ({ color, radius }) => css`
  border: 1px solid ${color.neutral['40']};
  border-radius: ${radius.default};

  .spinner-area {
    min-height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const chartAreaStyles = ({ color, spacing }) => css`
  display: flex;
  background: ${color.neutral.white};

  .chart {
    flex: 1 1 100%;
    // Css trick to have dynamic width for this area when there is a Chart component inside
    width: 0;
    padding: ${spacing.gap['06']};
  }

  .breakdown-options {
    flex: 0 0 200px;
    width: 200px;
    border-left: 1px solid ${color.neutral['40']};
    max-height: 560px;
  }
`;

export const tabletStyles = ({ spacing }) => css`
  margin-top: ${spacing.gap['04']};
`;
