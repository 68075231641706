import React, { useCallback, useEffect, useMemo, useState } from 'react';

import IssuesTable from '@analytics-components/tables/IssuesTable';
import { useDataWidget } from '@analytics-context/DataWidget';
import { throttle, unionBy } from '@common-services/vendor/lodash';

const searchTermByField = (issues, fieldName, term) => {
  const filteredData = [];
  issues.forEach((issue) => {
    if (issue[fieldName]?.toLowerCase().indexOf(term.toLowerCase()) >= 0) {
      // add whole issue to the list if there is a match in its field
      filteredData.push(issue);
    } else {
      // search for PRs that might have a match in their field
      const filteredPrs = issue.subRows.filter(
        (pr) => pr[fieldName]?.toString().toLowerCase().indexOf(term.toLowerCase()) >= 0
      );
      if (filteredPrs?.length) {
        // in case such PRs were found add them including their issue
        filteredData.push({
          ...issue,
          subRows: filteredPrs,
        });
      }
    }
  });
  return filteredData;
};

const searchTerm = (issues, term, status) => {
  // the list of searchable fields
  const fields = ['reporter', 'assignee', 'creator', 'priority', 'id', 'title', 'type'];
  const sepIndex = term.indexOf(':');
  const parts = sepIndex === -1 ? [term] : [term.slice(0, sepIndex), term.slice(sepIndex + 1)];
  let filteredData = [];
  if (parts.length > 1) {
    // in case field name was specified in the Search field
    const [fieldName, term] = parts;
    if (fields.indexOf(fieldName) >= 0) {
      filteredData = searchTermByField(issues, fieldName.trim(), term.trim());
    }
  } else {
    // in case field name was not specified we search in all searchable fields
    fields.forEach((fieldName) => {
      const matchingIssues = searchTermByField(issues, fieldName, term.trim());
      // merge issue and their PRs
      matchingIssues.forEach((issue) => {
        const existingIssue = filteredData.find((v) => v.id === issue.id);
        if (existingIssue) {
          existingIssue.subRows = unionBy(existingIssue.subRows, issue.subRows, 'id');
        } else {
          filteredData.push(issue);
        }
      });
    });
  }
  if (status) {
    // if there is a status filter set, apply it
    filteredData = filteredData.filter((issue) => issue.status === status.value);
  }
  return filteredData;
};

const IssuesTableDataBinder = () => {
  const { data, isLoading } = useDataWidget();
  const [filteredIssues, setFilteredIssues] = useState();
  const [currentStatus, setCurrentStatus] = useState(null);
  const [searchedString, setSearchedString] = useState('');

  // initialize data after it's returned from the plumber
  useEffect(() => {
    if (!!data?.length) {
      setFilteredIssues(data.sort((a, b) => b.created - a.created));
    }
  }, [data]);

  const throttledSearch = useCallback(
    throttle((string) => {
      const filteredData = searchTerm(data, string, currentStatus);
      setFilteredIssues(filteredData);
    }, 300),
    [currentStatus, data]
  );

  const handleSearch = useCallback(
    (string) => {
      setSearchedString(string);
      if (string?.length > 1) {
        throttledSearch(string);
      } else {
        // in case searched string is empty or too short initialize it back to full data
        // using timeout to prevent clash in setting React state
        const timeoutId = setTimeout(
          () => {
            let filteredData = data;
            if (currentStatus) {
              // if there is a status filter set, apply it
              filteredData = filteredData.filter((issue) => issue.status === currentStatus.value);
            }
            setFilteredIssues(filteredData);
          },
          0,
          () => clearTimeout(timeoutId)
        );
      }
    },
    [currentStatus, filteredIssues]
  );

  const uniqueStatuses = useMemo(() => {
    const statuses = new Set();
    const statusStages = {};
    if (data?.length) {
      data.forEach((issue) => {
        statuses.add(issue.status);
        statusStages[issue.status] = issue.statusStage;
      });
    }
    return Array.from(statuses)
      .sort((a, b) => (statusStages[a] > statusStages[b] ? -1 : 1))
      .map((status) => ({
        label: status,
        value: status,
      }));
  }, [data]);

  const statusOptions = useMemo(
    () => [
      {
        label: 'All',
        value: null,
      },
      ...uniqueStatuses,
    ],
    [uniqueStatuses]
  );

  const filterByStatus = useCallback(
    (status) => {
      let newFilteredIssues = data.sort((a, b) => b.created - a.created);
      if (searchedString) {
        newFilteredIssues = searchTerm(newFilteredIssues, searchedString, status);
      } else if (status) {
        newFilteredIssues = newFilteredIssues.filter((issue) => issue.status === status.value);
      }
      setCurrentStatus(status);
      setFilteredIssues(newFilteredIssues);
    },
    [data, searchedString]
  );

  if (isLoading) {
    return <div className="dataTable-placeholder filter-placeholder" />;
  }

  if (!filteredIssues) {
    return null;
  }

  return (
    <IssuesTable
      data={filteredIssues}
      currentStatus={currentStatus}
      searchedString={searchedString}
      filterOptions={statusOptions}
      onFilter={filterByStatus}
      onSearch={handleSearch}
    />
  );
};

export default IssuesTableDataBinder;
