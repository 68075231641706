import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { NestedAccordion } from './components';
import { TeamAccordionProps } from './types';

const TeamAccordion: React.FC<TeamAccordionProps> = React.memo(
  ({ team, level = 0, ancestorsNames = [] }) => {
    return (
      <NestedAccordion
        level={level}
        ancestorsNames={ancestorsNames}
        name={team.name}
        totalMembersCount={team.total_members_count}
        totalTeamsCount={team.children?.length || 0}
        subteams={team.children}
      >
        {team.children?.map((child) => (
          <TeamAccordion
            key={uuidv4()}
            team={child}
            level={level + 1}
            ancestorsNames={ancestorsNames.concat(team.name.toLowerCase())}
          />
        ))}
      </NestedAccordion>
    );
  }
);

export { TeamAccordion };
