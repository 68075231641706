import { ITeamGoal } from '@align-services/api/types/goalsTypes';
import {
  AlignGoalsResponse,
  Goal,
  TeamGoal,
  TeamTree,
} from '@common-services/api/private/generated-from-backend/models';
import { slugify } from '@utils/index';

export const filterTeamGoalsWithoutTarget = (data: TeamGoal[]): TeamGoal[] =>
  data
    ?.filter((currentGoal: TeamGoal) => {
      const children = filterTeamGoalsWithoutTarget(currentGoal?.children) || [];
      return children.length || currentGoal?.value?.target;
    })
    ?.map((currentGoal: TeamGoal) => {
      const children = filterTeamGoalsWithoutTarget(currentGoal?.children) || [];
      return {
        ...currentGoal,
        children,
      };
    }) || [];

export const filterGoalsWithoutTarget = (data: AlignGoalsResponse): AlignGoalsResponse =>
  data?.map((goal: Goal) => {
    const children = filterTeamGoalsWithoutTarget(goal.team_goal?.children) || [];
    let teamGoal = {};
    if (children.length || goal.team_goal?.value?.target) {
      teamGoal = {
        team_goal: {
          ...goal.team_goal,
          children,
        },
      };
    }

    return {
      ...goal,
      ...teamGoal,
    };
  }) || [];

const getTeamsPath = (teamId: number, teamTree: TeamTree): string[] => {
  let path: string[] = [];

  function search(team: TeamTree): boolean {
    if (team.id === teamId) {
      path.push(slugify(team.name.toLowerCase()));
      return true;
    }
    for (const child of team?.children || []) {
      if (search(child)) {
        path.push(slugify(team.name.toLowerCase()));
        return true;
      }
    }
    return false;
  }

  search(teamTree);
  return path.reverse();
};

export const getPathToGoalView = (teamsTree: TeamTree, team: ITeamGoal, goalId: number): string => {
  if (!teamsTree) return null;
  const teamNames = getTeamsPath(team.team.id, teamsTree);
  return teamNames?.length ? `/align/${teamNames.join('/')}/goals/view/${goalId}` : null;
};

export * as ActiveGoalsService from './activeGoals.services';
