import { faClock, faUser } from '@fortawesome/free-regular-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import React from 'react';

import { AltTitle } from '@analytics-components/Typography';
import {
  TooltipContainer,
  Group,
  BigText,
  DateWeekDayMonth,
  PullRequestRepoTitle,
  Icon,
  UserAvatar,
  PRCommentsStats,
  ReleaseStats,
  Release,
} from '@analytics-components/charts/Tooltip';
import chart01 from '@analytics-pages/prototypes/tooltip-info/tooltip-charts/chart01.png';
import chart02 from '@analytics-pages/prototypes/tooltip-info/tooltip-charts/chart02.png';
import chart03 from '@analytics-pages/prototypes/tooltip-info/tooltip-charts/chart03.png';
import chart04 from '@analytics-pages/prototypes/tooltip-info/tooltip-charts/chart04.png';
import chart05 from '@analytics-pages/prototypes/tooltip-info/tooltip-charts/chart05.png';
import chart06 from '@analytics-pages/prototypes/tooltip-info/tooltip-charts/chart06.png';
import chart07 from '@analytics-pages/prototypes/tooltip-info/tooltip-charts/chart07.png';
import chart10 from '@analytics-pages/prototypes/tooltip-info/tooltip-charts/chart10.png';
import chart11 from '@analytics-pages/prototypes/tooltip-info/tooltip-charts/chart11.png';
import chart12 from '@analytics-pages/prototypes/tooltip-info/tooltip-charts/chart12.png';
import chart13 from '@analytics-pages/prototypes/tooltip-info/tooltip-charts/chart13.png';
import chart14 from '@analytics-pages/prototypes/tooltip-info/tooltip-charts/chart14.png';

const SmallText = ({ content }) => (
  <span className="font-weight-bold d-inline-block align-middle text-dark text-m">{content}</span>
);

const Tooltips = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 mb-3">
          <p className="text-centerleft font-weight-light text-dark text-lg">Tooltips</p>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-12">
          <h4 className="text-centerleft text-dark font-weight-light mr-2 mb-2">Pull requests</h4>

          <img className="mb-2" src={chart01} alt="" width="620" />

          <p className="font-weight-light text-m mt-2 mb-3">Regular Pull Request</p>

          <TooltipContainer left>
            <Group>
              <AltTitle uppercase content="#458" />
              <PullRequestRepoTitle
                repo="src-d/go-git"
                title="git: remove potentially duplicate check for unstaged files"
              />
            </Group>
            <Group>
              <UserAvatar
                src="https://randomuser.me/api/portraits/women/44.jpg"
                name="jennifer_38"
                middleText="6 hours ago by"
                size="18"
              />
            </Group>
          </TooltipContainer>

          <p className="font-weight-light text-m mt-5 mb-3">Pull Request waiting for Review</p>

          <TooltipContainer left>
            <Group>
              <AltTitle uppercase content="#458" />
              <PullRequestRepoTitle
                repo="src-d/go-git"
                title="git: remove potentially duplicate check for unstaged files"
              />
            </Group>
            <Group className="text-orange">
              <Icon icon={faClock} />
              <span>Waiting review for 16 hours</span>
            </Group>
            <Group>
              <UserAvatar
                src="https://randomuser.me/api/portraits/women/44.jpg"
                name="jennifer_38"
                middleText="Created by"
                size="18"
              />
            </Group>
          </TooltipContainer>

          <p className="font-weight-light text-m mt-5 mb-3">Pull Request Reviewed</p>

          <TooltipContainer left>
            <Group>
              <AltTitle uppercase content="#458" />
              <PullRequestRepoTitle
                repo="src-d/go-git"
                title="git: remove potentially duplicate check for unstaged files"
              />
            </Group>
            <Group className="text-turquoise">
              <Icon icon={faClock} />
              <span>Waited review for 7 hours</span>
            </Group>
            <Group>
              <UserAvatar
                src="https://randomuser.me/api/portraits/women/44.jpg"
                name="jennifer_38"
                middleText="Created by"
                size="18"
              />
            </Group>
          </TooltipContainer>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-12">
          <h4 className="text-centerleft text-dark font-weight-light mt-3 mr-2 mb-4">
            User tooltips
          </h4>

          <img className="mb-2" src={chart02} alt="" width="620" />

          <p className="font-weight-light text-m mt-2 mb-3">User with PRs and comments</p>

          <TooltipContainer>
            <Group>
              <UserAvatar
                src="https://randomuser.me/api/portraits/women/44.jpg"
                name="jennifer_38"
              />
            </Group>
            <Group>
              <PRCommentsStats prs={10} comments={8} />
            </Group>
          </TooltipContainer>

          <p className="font-weight-light text-m mt-5 mb-3">User Reviews comments</p>

          <TooltipContainer>
            <Group>
              <UserAvatar
                src="https://randomuser.me/api/portraits/women/44.jpg"
                name="jennifer_38"
              />
            </Group>
            <Group>
              <AltTitle uppercase content="Reviews comments" />
              <BigText content={23} extra="75%" />
            </Group>
          </TooltipContainer>

          <p className="font-weight-light text-m mt-5 mb-2">User Reviews</p>

          <TooltipContainer>
            <Group>
              <UserAvatar
                src="https://randomuser.me/api/portraits/women/44.jpg"
                name="jennifer_38"
              />
            </Group>
            <Group>
              <AltTitle uppercase content="Reviews" />
              <BigText content={15} extra="35%" />
            </Group>
          </TooltipContainer>

          <p className="font-weight-light text-m mt-5 mb-3">User Icon</p>

          <TooltipContainer>
            <Group>
              <UserAvatar
                src="https://randomuser.me/api/portraits/women/44.jpg"
                name="jennifer_38"
              />
            </Group>
          </TooltipContainer>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-12">
          <h4 className="text-centerleft text-dark font-weight-light mt-5 mr-2 mb-4">
            Lead Time + 4 Summary Line Charts + Wait time for 1st Review
          </h4>

          <img className="mb-2" src={chart03} alt="" width="620" />
          <br />

          <img className="mb-2" src={chart06} alt="" width="620" />

          <p className="font-weight-light text-m mt-2 mb-3">One date with time</p>

          <TooltipContainer left>
            <Group>
              <AltTitle content={<DateWeekDayMonth date={moment('2020-03-23')} uppercase />} />
              <BigText content="6 hours" />
            </Group>
          </TooltipContainer>

          <p className="font-weight-light text-m mt-5 mb-3">One date, time and pull requests</p>

          <TooltipContainer left>
            <Group>
              <AltTitle content={<DateWeekDayMonth date={moment('2020-03-23')} uppercase />} />
              <BigText content="35min" />
              <p className="align-middle text-dark text-m">6 pull requests</p>
            </Group>
          </TooltipContainer>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-12">
          <h4 className="text-centerleft text-dark font-weight-light mt-5 mr-2 mb-4">
            Pull Request Ratio Flow
          </h4>

          <img className="mb-2" src={chart04} alt="" width="620" />

          <p className="font-weight-light text-m mt-2 mb-3">PR Ratio Flow</p>

          <TooltipContainer>
            <Group>
              <AltTitle content={<DateWeekDayMonth date={moment('2020-03-23')} uppercase />} />
              <BigText content="7/5" extra={1.4} />
            </Group>
          </TooltipContainer>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-12">
          <h4 className="text-centerleft text-dark font-weight-light mt-5 mr-2 mb-4">
            Most Active Developers
          </h4>

          <img className="mb-2" src={chart05} alt="" width="620" />

          <p className="font-weight-light text-m mt-5 mb-3">Pull Requests Created</p>

          <TooltipContainer>
            <Group>
              <AltTitle uppercase content="Pull Requests" />
              <BigText content={19} />
            </Group>
          </TooltipContainer>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-12">
          <h4 className="text-centerleft text-dark font-weight-light mr-2 mb-4">Merge Delays</h4>

          <img className="mb-2" src={chart07} alt="" width="620" />

          <p className="font-weight-light text-m mt-5 mb-3">Time to merge</p>

          <TooltipContainer>
            <Group>
              <span className="text-secondary text-m align-middle">athenian/api</span>
            </Group>
            <Group>
              <p className="text-m text-dark m-0">
                <Icon icon={faClock} className="text-blue" />
                <span>10.3 hours</span>
              </p>
            </Group>
          </TooltipContainer>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-12">
          <h4 className="text-centerleft text-dark font-weight-light mr-2 mt-5">
            Text and numbers
          </h4>

          <img className="mb-2" src={chart07} alt="" width="620" />
          <br />

          <p className="font-weight-light text-m mt-5 mb-3">One title and one number</p>

          <TooltipContainer>
            <Group>
              <AltTitle uppercase content="PRs Created" />
              <BigText content={49} />
            </Group>
          </TooltipContainer>

          <p className="font-weight-light text-m mt-5 mb-3">Two titles and two numbers</p>

          <TooltipContainer left>
            <Group>
              <AltTitle uppercase content="Average time to merge" />
              <SmallText content="18 hours" />
            </Group>
            <Group>
              <AltTitle uppercase content="Developers with merge privileges" />
              <SmallText content={6} />
            </Group>
          </TooltipContainer>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-12">
          <h4 className="text-centerleft text-dark font-weight-light mr-2">Repositories</h4>

          <p className="font-weight-light text-m mt-5 mb-3">Single repository</p>

          <TooltipContainer>
            <Group>
              <span className="text-secondary text-m align-middle">athenian/api</span>
            </Group>
            <Group>
              <p className="text-m text-dark m-0">
                <Icon icon={faClock} className="text-blue" />
                <span className="mr-3">38 min</span>
                <Icon icon={faUser} className="text-secondary" />
                <span>5</span>
              </p>
            </Group>
          </TooltipContainer>

          <p className="font-weight-light text-m mt-5 mb-3">Multiple repositories</p>

          <TooltipContainer>
            <Group>
              <span className="text-secondary text-m align-middle">5 repositories</span>
            </Group>
            <Group>
              <p className="text-m text-dark m-0">
                <Icon icon={faClock} className="text-orange" />
                <span className="mr-3">38 min</span>
                <Icon icon={faUser} className="text-secondary" />
                <span>5</span>
              </p>
            </Group>
          </TooltipContainer>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-12">
          <h4 className="text-centerleft text-dark font-weight-light mr-2">Releases</h4>

          <img className="mb-2" src={chart10} alt="" width="620" />
          <br />

          <p className="font-weight-light text-m mt-5 mb-3">Single release</p>

          <TooltipContainer>
            <Group>
              <Release project="webapp" version="v1.3.2" />
            </Group>
            <Group>
              <ReleaseStats PRs={9} additions={1543} removals={504} />
            </Group>
          </TooltipContainer>

          <p className="font-weight-light text-m mt-5 mb-3">Multiple releases</p>

          <TooltipContainer>
            <Group>
              <Release project="webapp" version="v1.3.2" />
            </Group>
            <Group>
              <ReleaseStats PRs={9} additions={1543} removals={504} />
            </Group>
            <Group className="mt-3">
              <Release project="api" version="v1.4.0" />
            </Group>
            <Group>
              <ReleaseStats PRs={5} additions={328} removals={139} />
            </Group>
          </TooltipContainer>

          <p className="font-weight-light text-m mt-5 mb-3">Multiple releases</p>

          <TooltipContainer>
            <Group>
              <Release project="webapp" version="v1.3.2" />
            </Group>
            <Group>
              <ReleaseStats PRs={9} additions={1543} removals={504} />
            </Group>
            <Group className="mt-3">
              <Release project="api" version="v1.4.0" />
            </Group>
            <Group>
              <ReleaseStats PRs={5} additions={328} removals={139} />
            </Group>
          </TooltipContainer>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-12">
          <h4 className="text-centerleft text-dark font-weight-light mr-2 mb-3">Bugs raised</h4>

          <img className="mb-2" src={chart11} alt="" width="980" />
          <br />

          <p className="font-weight-light text-m mt-5 mb-3">Bugs raised by priority</p>

          <TooltipContainer>
            <Group>
              <AltTitle content={<DateWeekDayMonth date={moment('2020-03-23')} uppercase />} />
              <Icon icon={faCircle} className="text-orange" />
              <BigText content="5" extra="Medium" />
            </Group>
          </TooltipContainer>

          <p className="font-weight-light text-m mt-5 mb-3">Bugs raised distribution</p>

          <TooltipContainer>
            <Group>
              <AltTitle content="Medium" uppercase />
              <Icon icon={faCircle} className="text-orange" />
              <BigText content="27" extra="50%" />
            </Group>
          </TooltipContainer>

          <p className="font-weight-light text-m mt-5 mb-3">Multiple releases</p>

          <TooltipContainer>
            <Group>
              <Release project="webapp" version="v1.3.2" />
            </Group>
            <Group>
              <ReleaseStats PRs={9} additions={1543} removals={504} />
            </Group>
            <Group className="mt-3">
              <Release project="api" version="v1.4.0" />
            </Group>
            <Group>
              <ReleaseStats PRs={5} additions={328} removals={139} />
            </Group>
          </TooltipContainer>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-12">
          <h4 className="text-centerleft text-dark font-weight-light mr-2 mb-3">Bugs raised</h4>

          <img className="mb-2" src={chart11} alt="" width="980" />
          <br />

          <p className="font-weight-light text-m mt-5 mb-3">Bugs raised by priority</p>

          <TooltipContainer>
            <Group>
              <AltTitle content={<DateWeekDayMonth date={moment('2020-03-23')} uppercase />} />
              <Icon icon={faCircle} className="text-orange" />
              <BigText content="5" extra="Medium" />
            </Group>
          </TooltipContainer>

          <p className="font-weight-light text-m mt-5 mb-3">Bugs raised distribution</p>

          <TooltipContainer>
            <Group>
              <AltTitle content="Medium" uppercase />
              <Icon icon={faCircle} className="text-orange" />
              <BigText content="27" extra="50%" />
            </Group>
          </TooltipContainer>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-12">
          <h4 className="text-centerleft text-dark font-weight-light mr-2 mb-3">Outcome</h4>

          <img className="mb-2" src={chart12} alt="" width="980" />
          <br />

          <p className="font-weight-light text-m mt-5 mb-3">Work By Label</p>

          <TooltipContainer>
            <Group>
              <AltTitle content={<DateWeekDayMonth date={moment('2020-03-23')} uppercase />} />
              <i className="icon-pull-request text-purple h4 vertical-align-middle mr-1" />
              <BigText content="21" label="reliability" />
            </Group>
          </TooltipContainer>

          <p className="font-weight-light text-m mt-5 mb-3">Pull Requests distribution</p>

          <TooltipContainer>
            <Group>
              <i className="icon-pull-request text-turquoise h4 vertical-align-middle mr-1" />
              <BigText content="81" extra="38%" label="perform" />
            </Group>
          </TooltipContainer>

          <img className="mb-2 mt-5" src={chart13} alt="" width="980" />
          <br />

          <p className="font-weight-light text-m mt-5 mb-3">Work Type</p>

          <TooltipContainer>
            <Group>
              <AltTitle content={<DateWeekDayMonth date={moment('2020-03-23')} uppercase />} />
              <div className="w-100 d-inline-flex justify-content-center">
                <img
                  alt=""
                  src="https://athenianco.atlassian.net/secure/viewavatar?size=medium&avatarId=10303&avatarType=issuetype"
                  width="12px"
                  className="mr-1"
                />
                <AltTitle content="Bugs" />
              </div>
              <i className="icon-pull-request text-pink h4 vertical-align-middle mr-1" />
              <BigText content="5" />
            </Group>
          </TooltipContainer>

          <p className="font-weight-light text-m mt-5 mb-3">Pull Requests distribution</p>

          <TooltipContainer>
            <Group>
              <div className="w-100 mb-1">
                <img
                  alt=""
                  src="https://athenianco.atlassian.net/secure/viewavatar?size=medium&avatarId=10303&avatarType=issuetype"
                  width="12px"
                  className="mr-1 d-inline-block"
                />
                <AltTitle content="Bugs" inline />
              </div>
              <i className="icon-pull-request text-pink h4 vertical-align-middle mr-1" />
              <BigText content="76" extra="40%" />
            </Group>
          </TooltipContainer>

          <img className="mb-2 mt-5" src={chart14} alt="" width="980" />
          <br />

          <p className="font-weight-light text-m mt-5 mb-3">Work By Epic</p>

          <TooltipContainer>
            <Group>
              <div className="w-100 d-inline-flex justify-content-center mb-1">
                <img
                  alt=""
                  src="https://athenianco.atlassian.net/images/icons/issuetypes/epic.svg"
                  width="12px"
                  className="mr-1"
                />
                <p className="text-dark text-xs mb-1 text-truncate">
                  <span className="text-secondary">#DEV-263 </span>
                  <span>Support for GitHub enterprise</span>
                </p>
              </div>
              <div className="progress" style={{ height: '4px' }}>
                <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  style={{ width: '25%' }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
              <div className="row">
                <div className="col">
                  <span className="font-weight-bold d-inline-block align-middle text-dark text-m mt-2">
                    7 PRs released
                  </span>
                </div>
                <div className="col text-right pt-1">
                  <AltTitle content="47% complete" />
                </div>
              </div>
            </Group>
          </TooltipContainer>

          <p className="font-weight-light text-m mt-5 mb-3">Epic distribution</p>

          <TooltipContainer>
            <Group>
              <div className="w-100 mb-1">
                <img
                  alt=""
                  src="https://athenianco.atlassian.net/images/icons/issuetypes/epic.svg"
                  width="12px"
                  className="mr-1 d-inline-block"
                />
                <AltTitle content="#DEV-263" inline />
              </div>
              <i className="icon-pull-request text-purple h4 vertical-align-middle mr-1" />
              <BigText content="76" extra="40%" />
            </Group>
          </TooltipContainer>
        </div>
      </div>
    </div>
  );
};

export default Tooltips;
