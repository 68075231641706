import { css } from '@emotion/react';

import { hexToRGBA } from '@utils/colors';

const getStyle = ({ color, spacing, radius, font }) => css`
  align-items: center;
  color: ${color.ui.orange['100']};
  border: 1px solid ${color.ui.orange['100']};
  box-sizing: border-box;
  border-radius: ${radius.default};
  display: flex;
  flex-direction: row;
  padding: ${spacing.gap['01']} ${spacing.gap['02']};
  height: 15px;
  font-size: ${font.size.xxs};
  line-height: 8px;
  text-transform: uppercase;
  width: fit-content;
`;

const turquoiseType = ({ color, spacing, font }) => css`
  background: ${color.ui.turquoise[100]};
  color: ${color.neutral.white};
  border: none;
  padding: ${spacing.gap['02']} ${spacing.gap['03']};
  line-height: 6px;
  font-size: ${font.size.xs};
`;

const neutralType = ({ color, spacing, font }) => css`
  background: ${color.neutral[40]};
  color: ${color.neutral[80]};
  border: none;
  padding: ${spacing.gap['02']} ${spacing.gap['03']};
  line-height: 6px;
  font-size: ${font.size.xs};
`;

const targetType = ({ color, font, spacing }) => css`
  background-color: ${hexToRGBA(color.ui.blue['100'], 0.1)};
  border: none;
  color: ${color.ui.blue['100']};
  font-size: ${font.size.m};
  font-weight: ${font.weight.regular};
  height: auto;
  padding: ${spacing.gap['02']};
  text-transform: none;
`;

export { getStyle, turquoiseType, neutralType, targetType };
