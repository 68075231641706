import React from 'react';

import { Button, ButtonSize, ButtonType } from '@lib/Button';
import { icons } from '@lib/icon';

import { copyOnSuccess } from './ShareViewButton.services';
import { useShareUrlAction } from './hooks/useShareUrlAction';

const ShareViewButton = () => {
  const action = useShareUrlAction();

  return (
    <Button
      disabled={!action}
      type={ButtonType.GHOST}
      size={ButtonSize.SMALL}
      width={148}
      action={action}
      onSuccess={copyOnSuccess}
      icon={icons.copy}
      label="Share this view"
      pendingLabel="Generating Link..."
      successLabel="Copied!"
      successIcon={icons.check_succeed}
      errorLabel="Error"
      errorIcon={icons.check_failed}
    />
  );
};

export { ShareViewButton };
