import { useLocalStorage } from '@rehooks/local-storage';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';

import * as ReposService from '@analytics-components/TopFilter/services/repos';
import * as TeamsService from '@analytics-components/TopFilter/services/teams';
import { useOnClickOutside } from '@common-hooks/useOnClickOutside';
import * as ArrayService from '@common-services/arrayService';
import { SelectorTs } from '@lib/selector-ts';

import { multiSelectReducer, multiSelectStateInit } from './reducer';
import { selectTsStyles } from './styles';
import { Dropdown } from './ui';

function deepCompareEquals(a, b) {
  return _.isEqual(a, b);
}

function useDeepCompareMemoize(value) {
  const ref = useRef();
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useDeepCompareEffect(callback, dependencies) {
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

const PersistentMultiSelect = React.memo((props) => {
  const { onInit, persistentKey, resetStorage, areOptionsLoading, ...inner } = props;

  const [last, setLast, removeLast] = useLocalStorage(persistentKey);
  const [initDone, setInitDone] = useState(false);
  const defaultSerialize = (v) => v;
  const defaultDeserialize = (v) => v;
  const serialize = props.serialize || defaultSerialize;
  const deserialize = props.deserialize || defaultDeserialize;

  const onApplyWrapper = async (selected, ...args) => {
    setLast(serialize(selected));
    return inner.onApply(selected, ...args);
  };

  useEffect(() => {
    if (!areOptionsLoading && !initDone) {
      setInitDone(true);

      if (resetStorage) {
        removeLast();
        onInit(inner.initialValues);
      } else {
        const deserializedLast = deserialize(last);
        if (!!deserializedLast && deserializedLast.length > 0) {
          const lastAppliedValues = inner.uniquenessKey
            ? _(deserializedLast).uniqBy(inner.uniquenessKey).value()
            : deserializedLast;
          onInit(lastAppliedValues);
        } else {
          onInit(inner.initialValues);
        }
      }
    }
  }, [
    areOptionsLoading,
    initDone,
    resetStorage,
    removeLast,
    onInit,
    inner,
    inner.initialValues,
    inner.uniquenessKey,
    inner.getOptionValue,
    last,
  ]);

  return <MultiSelect {...inner} onApply={onApplyWrapper} />;
});

/*

  1. When "All" is selected and options change, the "All" must be preserved,
  2. When a whole group is selected and options change, the selection of
     the whole group must be preserved,
  3. When a set of options is selected and options change, the set of options selected
     must be preserved, even if some of those are not available options,

 */
// TODO: remove component in next refactoring cycle
const MultiSelect = React.memo((props) => {
  const {
    initialValues,
    isDisabled,
    isLoading,
    label,
    name,
    noDropdown,
    nullOption,
    options,
    wrapperStyle,
    onCancel,
    onApply,
    teams,
    persistentKeyGroups,
    loadedAllOptions,
    onLoadAllOptionsClick,
  } = props;
  const defaultGetOptionValue = (opt) => opt.value;
  const getOptionValue = props.getOptionValue || defaultGetOptionValue;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedCounter, setSelectedCounter] = useState(null);
  const [lastGroups, setLastGroups] = useLocalStorage(persistentKeyGroups);

  const [state, dispatch] = useReducer(
    multiSelectReducer,
    { getOptionValue, label },
    multiSelectStateInit
  );

  const wrapperId = `multi-select-${label}`;

  const onDropdownToggle = () => setIsMenuOpen(!isMenuOpen);

  const onSelectionChange = (values) => {
    dispatch({ type: 'select', payload: _(values).uniqBy(getOptionValue).value() });
  };

  const onSelectionGroupChangeTs = (selectedNames) => {
    dispatch({ type: 'select-ids-ts', payload: selectedNames });
  };

  const onAction = (actionType, cb, cbArgs) => {
    dispatch({ type: actionType });
    setIsMenuOpen(false);
    if (cb) {
      cb(cbArgs);
    }
  };
  const onCancelWrapper = () => onAction('cancel', onCancel);
  const onApplyAddUser = useCallback(
    (selectedIds) => {
      onAction('apply', onApply, selectedIds);
    },
    [options]
  );
  const onApplyRepos = useCallback(
    (selectedIds) => {
      const selectedRepos = options.filter((option) => selectedIds.includes(option.value));
      onSelectionChange(selectedRepos);
      onAction('apply', onApply, selectedRepos);
    },
    [options]
  );
  const onApplyTeams = (selectedIds) => {
    const selectedGroupNames = teams
      .filter((team) => selectedIds.includes(team.id))
      .map((team) => team.name);
    const selectedValues = teams
      .filter((team) => {
        return selectedIds.includes(team.id);
      })
      .reduce((prev, current) => {
        const members = current.members.map((mem) => {
          const avatar = mem.picture;
          return { ...mem, avatar };
        });
        return [...prev, ...members];
      }, []);
    setSelectedCounter(selectedIds.filter((id) => id).length);
    onSelectionGroupChangeTs(selectedGroupNames);
    onSelectionChange(selectedValues);
    setLastGroups(selectedGroupNames);
    onAction('apply', onApply, selectedValues);
  };

  const selectedGroups = useMemo(() => {
    return initialValues
      ? ArrayService.getUniqueValues(
          initialValues.filter((item) => item.team).map((item) => item.team)
        )
      : [];
  }, [initialValues]);

  const selectedRepos = useMemo(() => {
    return initialValues ? initialValues.map((initialValue) => initialValue.value) : [];
  }, [initialValues]);

  useEffect(() => {
    if (
      initialValues?.length > 0 &&
      initialValues?.filter((val) => val.group).length === initialValues.length
    ) {
      const withoutUnassigned = initialValues.filter((opt) => opt.group !== 'Unassigned');
      const selectedCounter = ArrayService.getUniqueValues(
        withoutUnassigned.filter((item) => item.team).map((item) => item.team)
      ).length;
      setSelectedCounter(selectedCounter);
    }
  }, [initialValues]);

  const getCounter = useCallback(() => {
    switch (name) {
      case 'users':
        return selectedCounter;
      default:
        return _(state.selected)
          .filter((opt) => !nullOption || getOptionValue(opt) !== getOptionValue(nullOption))
          .value().length;
    }
  }, [state.selected, selectedCounter]);

  useOnClickOutside(wrapperId, onCancelWrapper);

  useEffect(() => {
    if (!isLoading && state.loading) {
      dispatch({
        type: 'load',
        payload: {
          options,
          initialValues,
        },
      });
    }
  }, [initialValues, isLoading, options, state.loading]);

  useDeepCompareEffect(() => {
    // See notes in `js/context/Filters/index.jsx:onContribsApplyChange`
    //
    // In addition to those, the usage of `useDeepCompareEffect` here is quite hacky.
    // The problem is that the state mgmt of the filters context was causing this component
    // to re-render in loop. Once the state mgmt is refactored in the filters context,
    // this should be revisited as well.
    if (!isLoading && !state.loading) {
      const appliedCb = (applied) => {
        onApply(applied, true);
      };

      if (name === 'users') {
        dispatch({ type: 'options-change-ts', payload: { options, appliedCb, lastGroups } });
      } else {
        dispatch({ type: 'options-change', payload: { options, appliedCb } });
      }
    }
  }, [isLoading, options, state.loading]);

  return (
    <div
      id={wrapperId}
      data-cy={wrapperId}
      style={{
        ...(wrapperStyle || {}),
        position: noDropdown ? 'static' : 'relative',
        maxWidth: noDropdown ? '100%' : '300px',
      }}
    >
      {!noDropdown && (
        <Dropdown
          label={label}
          isLoading={isLoading}
          count={getCounter()}
          onClick={onDropdownToggle}
          isOpen={isMenuOpen}
          isDisabled={isDisabled}
        />
      )}
      {((!isDisabled && isMenuOpen) || noDropdown) && (
        <>
          {name === 'addUser' && (
            <SelectorTs
              optionsInit={options}
              withAllOption={false}
              isLoading={isLoading}
              isLoadMoreEnabled={!loadedAllOptions}
              loadMoreLabel={'Load all users'}
              minSelected={1}
              onLoadMoreClick={onLoadAllOptionsClick}
              onCancel={onCancelWrapper}
              onApply={onApplyAddUser}
            />
          )}
          {name === 'repositories' && (
            <div css={selectTsStyles}>
              <SelectorTs
                optionsInit={ReposService.convertRepos(selectedRepos, options)}
                withAllOption={true}
                onCancel={onCancelWrapper}
                onApply={onApplyRepos}
              />
            </div>
          )}
          {name === 'users' && (
            <div css={selectTsStyles}>
              <SelectorTs
                optionsInit={TeamsService.convertTeamsRecursion(teams, selectedGroups)}
                withAllOption={true}
                isCascading={true}
                minSelected={1}
                onCancel={onCancelWrapper}
                onApply={onApplyTeams}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
});

export default MultiSelect;
export { PersistentMultiSelect };
