import React, { useCallback, useEffect, useState } from 'react';

import { ChartEditServices } from '@align-pages/dashboard/components/chart-edit/chartEdit.services';
import { defaultChartIntervalIndex } from '@align-pages/dashboard/config';
import { DashboardChartServices } from '@align-pages/dashboard/services/dashboardChartServices';
import { useDashboardStore } from '@align-pages/dashboard/store';
import { Dropdown, DropdownType } from '@lib/Dropdown';
import { icons } from '@lib/icon';

export const DateRange: React.FC = React.memo(() => {
  const [chartParams, onChartDateUpdate] = useDashboardStore((state) => [
    state.chartParams,
    state.onChartDateUpdate,
  ]);

  const options = ChartEditServices.getDateRangeOptions();

  const [selected, setSelected] = useState({
    value: chartParams.time_interval || options[defaultChartIntervalIndex].value,
    name: null,
  });

  useEffect(() => {
    // Initialize state
    onChartDateUpdate({
      ...DashboardChartServices.getTimeInterval({
        time_interval: selected.value,
      }),
      time_interval: selected.value,
    });
  }, []);

  const onChangeCallback = useCallback(({ value }) => {
    onChartDateUpdate({
      ...DashboardChartServices.getTimeInterval({ time_interval: value }),
      time_interval: value,
    });
    setSelected({ value: value, name: '' });
  }, []);

  return (
    <Dropdown
      icon={icons.calendar}
      type={DropdownType.NAME_VALUE}
      initialValue={selected}
      options={options}
      width={200}
      onChange={onChangeCallback}
    />
  );
});
