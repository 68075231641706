/*TODO: refactor after DEV-4898*/
import {
  FilterDetailRowLimitType,
  IFilterDetailRowLimitOptions,
  IFilterListFormatter,
  IFilterListFormatterFn,
  IFilterListReducer,
} from '@align-components/layouts/primary/team-header/filter-detail';
import {
  IFilterDetailRow,
  MAX_FILTER_LIST_LETTER_COUNT,
  MAX_FILTER_LIST_WORD_COUNT,
} from '@align-components/layouts/primary/team-header/filter-detail/filter-detail-row';
import { Goal } from '@common-services/api/private/generated-from-backend/models';
import { github } from '@common-services/format';
import { FILTER_TYPE_OPTIONS, FilterType } from '@lib/filter-panel';

export type IAppliedGoalFilters = {
  [key in FilterType]?: string[];
};

export const countAppliedGoalFilters = (goal: Goal): number => {
  return Object.keys(FILTER_TYPE_OPTIONS).filter((key) => goal[key] && goal[key].length > 0).length;
};

export const filterNameFormatter: IFilterListFormatter = (filters): IFilterListFormatterFn => (
  filterKey,
  val
) => {
  switch (filterKey) {
    case FilterType.REPOSITORY:
      return github.repoName(val);
    case FilterType.JIRA_ISSUE_TYPES:
      return (
        filters.jiraIssueTypes?.find(({ normalized_name }) => normalized_name === val)?.name || val
      );
    case FilterType.JIRA_PROJECTS:
      return filters.jiraProjects?.find(({ key }) => key === val)?.name || val;
    case FilterType.JIRA_PRIORITIES:
      return (
        filters.jiraPriorities?.find(({ name }) => name.toLowerCase() === val.toLowerCase())
          ?.name || val
      );
    default:
      return val;
  }
};

export const listReducer = (
  list: string[],
  limitOptions: IFilterDetailRowLimitOptions
): IFilterListReducer => {
  let letterCount = 0;
  let mainList = [];
  let moreList = [];

  list.forEach((listItem, index) => {
    const maxLetterCount = limitOptions?.max || MAX_FILTER_LIST_LETTER_COUNT;
    const maxWordCount = limitOptions?.max || MAX_FILTER_LIST_WORD_COUNT;
    // Add the length of the item string and add 2 more for comma and blank chars
    letterCount += listItem?.length + 2;
    // If max letter cap or max word cap exceeded put the item to moreList
    if (
      (limitOptions.type === FilterDetailRowLimitType.LETTER && letterCount > maxLetterCount) ||
      (limitOptions.type === FilterDetailRowLimitType.WORD && index >= maxWordCount)
    ) {
      moreList.push(listItem);
    } else {
      mainList.push(listItem);
    }
  });

  return {
    mainList,
    moreList,
  };
};

export const appliedGoalFilters = (
  goal: Goal,
  formatter: IFilterListFormatterFn
): IFilterDetailRow[] => {
  let appliedFilters: IFilterDetailRow[] = [];
  if (goal) {
    Object.keys(goal).forEach((key) => {
      const filterField = FILTER_TYPE_OPTIONS[key];
      if (filterField && goal[key]?.length > 0) {
        appliedFilters.push({
          name: filterField.label,
          icon: filterField.icon,
          list: goal[key].map((value) => formatter(key as FilterType, value)),
        });
      }
    });
  }
  return appliedFilters;
};

export * as FilterService from './filterService';
