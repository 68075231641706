import { BasicApiClass } from '@common-services/api/common/BasicApiClass';
import { IUrlParams, REST_TYPES } from '@common-services/api/common/types/apiClient';
import { ApiMode } from '@common-services/api/common/types/common';
import { ISentryMethodParams } from '@common-services/api/common/types/sentry';
import { PRIVATE_API_ENDPOINTS_CONSTANTS } from '@common-services/api/private/constants';
import { ProductFeatures } from '@common-services/api/public/generated-from-backend/models';

export class AccountsApiClass extends BasicApiClass {
  constructor() {
    super(ApiMode.PRIVATE);
  }
  public updateAccountFeature(
    urlParams: IUrlParams<'accountId'>,
    params: ProductFeatures,
    token: string = null,
    sentry?: ISentryMethodParams
  ): Promise<ProductFeatures> {
    const apiPath = this.replaceRouteLink(
      PRIVATE_API_ENDPOINTS_CONSTANTS.ACCOUNT_FEATURES,
      urlParams
    );
    this.assignToken(token);
    const callApi = this.callApi<ProductFeatures>(apiPath, REST_TYPES.POST, params);
    return sentry ? this.callSentry<ProductFeatures>(callApi, sentry) : callApi;
  }
}
