import React from 'react';

import { useBreadcrumb } from '@analytics-context/Breadcrumb';
import { ComingSoon } from '@lib/empty/comingSoon';

import InsightsDeploy from './deploy';
import InsightsMerge from './merge';
import InsightsRelease from './release';
import InsightsReview from './review';
import InsightsSummary from './summary';
import InsightsWorkInProgress from './work-in-progress';

const Insights = () => {
  const { subsection: activeStage } = useBreadcrumb();
  const Component =
    {
      summary: InsightsSummary,
      wip: InsightsWorkInProgress,
      review: InsightsReview,
      merge: InsightsMerge,
      release: InsightsRelease,
      deploy: InsightsDeploy,
    }[activeStage] || ComingSoon;

  return <Component />;
};

export default Insights;
