import copy from 'copy-to-clipboard';

import { ShareService } from '@analytics-services/shareService';
import { IShareAPI, ShareFilters } from '@analytics-services/shareService.types';
import { LooseObject } from '@analytics-types/common';
import { ButtonAction, ButtonActionResponse, OnActionDone } from '@lib/Button';

type CreateURLActionFactory = (
  api: IShareAPI,
  apiContext: LooseObject,
  targetUrl: string,
  filters: ShareFilters
) => ButtonAction<string>;

const copyOnSuccess: OnActionDone<string> = async (resp: ButtonActionResponse<string>) => {
  copy(resp.data);
};

const getCreateUrlAction: CreateURLActionFactory = (
  api,
  apiContext,
  targetUrl,
  filters
) => async (): Promise<ButtonActionResponse<string>> => {
  try {
    const url = await ShareService.createUrl(api, apiContext.account, targetUrl, filters);
    return {
      isError: false,
      data: url,
    };
  } catch {
    return {
      isError: true,
      data: null,
    };
  }
};

export { copyOnSuccess, getCreateUrlAction };
