import { useQuery } from 'react-query';

import { CONFIG } from '@align-constants';
import { IUseQueryHookRest } from '@align-types/common';
import { useAuth0 } from '@common-context/Auth0';
import { useDemoCookie } from '@common-pages/Demo';
import { IApiBasicError } from '@common-services/api/common/types/common';
import { User } from '@common-services/api/public/generated-from-backend/models';
import { UserServices } from '@common-services/api/public/services/user';

export function useUserDetails(isCached: boolean = false): IUseQueryHookRest<User> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();

  const fetchUserDetails = async (): Promise<User> => {
    const token: string = isDemo === false ? await getTokenSilently() : null;
    return UserServices.getUserDetails(token);
  };

  const { isLoading, data, error } = useQuery<User, IApiBasicError>(
    ['userDetails'],
    fetchUserDetails,
    {
      ...CONFIG.API.FETCH_OPTIONS,
      cacheTime: isCached ? CONFIG.API.FETCH_OPTIONS.cacheTime : 0,
      staleTime: isCached ? CONFIG.API.FETCH_OPTIONS.staleTime : 0,
    }
  );

  return { isLoading, data, error };
}
