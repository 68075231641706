// @flow
import * as React from 'react';

import IconBack from '@assets/images/icons/IconBack.svg';

import {
  helpBlockStyles,
  helpSidebarStyles,
  helpSummaryStyles,
  mainHelpStyles,
  relatedContentStyles,
  helpSeparatorStyles,
  helpRankingStyles,
  helpNavigationStyles,
} from './styles';

// type Props = {
//   children: React.Node,
// };

export const Main = ({ children }) => {
  return <main css={mainHelpStyles}>{children}</main>;
};

export const Title = ({ children }) => {
  return <h2>{children}</h2>;
};

export const Summary = ({ children }) => {
  return (
    <div css={helpSummaryStyles}>
      <p>{children}</p>
      <hr />
    </div>
  );
};

export const Subtitle = ({ children }) => {
  return <h3>{children}</h3>;
};

export const Subsubtitle = ({ children }) => {
  return <h4>{children}</h4>;
};

export const Paragraph = ({ children }) => {
  return <p>{children}</p>;
};

export const ContentBlock = ({ children }) => {
  return <aside css={helpBlockStyles}>{children}</aside>;
};

export const Image = ({ src }) => {
  return <img src={src} alt="" />;
};

export const Separator = () => {
  return <hr css={helpSeparatorStyles} />;
};

export const Sidebar = ({ children }) => {
  return <div css={helpSidebarStyles}>{children}</div>;
};

export const BlockQuote = ({ children }) => {
  return <blockquote>{children}</blockquote>;
};

export const Rank = ({ title, metric, children }) => {
  return (
    <div css={helpRankingStyles}>
      <h5>
        <span>{title}</span>
        <span>{metric}</span>
      </h5>
      <ol>{children}</ol>
    </div>
  );
};

export const RankItem = ({ name, value }) => {
  return (
    <li>
      <span className="rank-item-name text-secondary">{name}</span>
      <span className="rank-item-number text-dark">{value}</span>
    </li>
  );
};

export const Source = ({ children }) => {
  return <div className="source">{children}</div>;
};

export const RelatedContent = ({ children }) => {
  return <div css={relatedContentStyles}>{children}</div>;
};

export const Navigation = ({ pages }) => {
  const { items, pop } = pages;
  const [latestPage] = items.slice(items.length - 2);

  return (
    <nav css={helpNavigationStyles}>
      {items.length > 1 && (
        <span>
          <IconBack /> <span onClick={() => pop()}>{latestPage.label}</span>
        </span>
      )}
    </nav>
  );
};
