import { useQuery } from 'react-query';

import { CONFIG } from '@align-constants';
import { IUseQueryHookRest } from '@align-types/common';
import { useAuth0 } from '@common-context/Auth0';
import { useDemoCookie } from '@common-pages/Demo';
import { IApiBasicError } from '@common-services/api/common/types/common';
import {
  TeamMetricsRequest,
  TeamMetricsResponse,
  TeamMetricWithParams1,
  TeamMetricWithParamsNumericThreshold,
  TeamMetricWithParamsTimeDurationThreshold,
} from '@common-services/api/private/generated-from-backend/models';
import { MetricsServices } from '@common-services/api/private/services/metrics';

type ITeamMetricWithParams =
  | TeamMetricWithParamsNumericThreshold
  | TeamMetricWithParamsTimeDurationThreshold;

function isMetricParamExisting(value: TeamMetricWithParams1): value is ITeamMetricWithParams {
  return value.hasOwnProperty('metric_params');
}

export function useTeamMetrics(
  params: TeamMetricsRequest,
  isCached: boolean = false
): IUseQueryHookRest<TeamMetricsResponse> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();
  const fetchTeamMetrics = async (): Promise<TeamMetricsResponse> => {
    const token: string = isDemo === false ? await getTokenSilently() : null;
    return MetricsServices.getTeamMetrics(params, token);
  };

  const { isLoading, data, error } = useQuery<TeamMetricsResponse, IApiBasicError>(
    [
      'teamMetrics',
      params.team,
      params.valid_from,
      params.expires_at,
      params.repositories,
      params.jira_issue_types,
      params.jira_projects,
      params.jira_priorities,
      params.metrics_with_params[0]?.name,
      isMetricParamExisting(params.metrics_with_params[0])
        ? params.metrics_with_params[0].metric_params?.threshold
        : null,
    ],
    fetchTeamMetrics,
    {
      ...CONFIG.API.FETCH_OPTIONS,
      enabled: MetricsServices.isGetMetricsEnabled(params),
      cacheTime: isCached ? CONFIG.API.FETCH_OPTIONS.cacheTime : 0,
      staleTime: isCached ? CONFIG.API.FETCH_OPTIONS.staleTime : 0,
    }
  );

  return { isLoading, data, error };
}
