import classnames from 'classnames';
import React from 'react';

import { Spinner } from '@lib/Spinner';
import { Nothing } from '@lib/empty/nothing';
import { theme } from '@styles/theme';

const DEFAULT_COLOR = theme.color.neutral[80];

export const READY = 'ready';
export const LOADING = 'loading';
export const FAILED = 'failed';
export const EMPTY = 'empty';

export const DATA_STATUS = {
  NO_DATA: 'NO_DATA',
  NO_SUBMITTED: 'NO_SUBMITTED',
  NO_REVIEWED: 'NO_REVIEWED',
  NO_MERGED: 'NO_MERGED',
  NO_RELEASES: 'NO_RELEASES',
};

const StatusIndicator = ({
  status = null,
  color = DEFAULT_COLOR,
  margin = 5,
  textOnly = false,
  size,
  dataStatus = DATA_STATUS.NO_DATA,
}) => {
  let message;
  switch (status) {
    case LOADING:
      message = <Spinner loading={true} color={color} size={size} />;
      break;
    case FAILED:
      message = 'error loading data';
      break;
    case EMPTY:
      message = <Nothing textOnly={textOnly} dataStatus={dataStatus} />;
      break;
    case READY:
    default:
      return null;
  }

  return (
    <div
      className={classnames(
        `my-${margin}`,
        'mx-auto justify-content-center d-flex align-items-center'
      )}
    >
      {message}
    </div>
  );
};

export { StatusIndicator };
