// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';

import { useHelpContext } from '@analytics-context/Help';
import {
  Main,
  Title,
  Summary,
  Subtitle,
  Paragraph,
  Separator,
  ContentBlock,
  BlockQuote,
  Source,
  Sidebar,
  RelatedContent,
  Navigation,
} from '@analytics-pages/Help/components';

const Merge = ({ fullPage }) => {
  const { openSection, stack } = useHelpContext();
  return (
    <>
      <Navigation pages={stack} />
      <Main>
        <section>
          <Title>Merge Cycle Time</Title>
          <Summary>
            After manual approval, pull requests enter the Merge stage where most of the work can be
            run in a fully automated fashion.
          </Summary>

          <Subtitle>Why is it important?</Subtitle>
          <Paragraph>
            Unlike the WIP and review process, finally merging pull requests can be done in several
            minutes without decreasing code quality by using the right tooling. Following the best
            engineering practices here can free up people for higher-value problem-solving work
            instead of performing repetitive and non-rewarding tasks.
          </Paragraph>
          <Paragraph>
            The most important approach to reduce Merge Cycle Time is to avoid this repetitive work
            that takes a long time, such as manual testing, and invest in simplifying and automating
            this work. To become high-performing and merge pull requests in several minutes,
            engineering teams have two main enablers to follow:
          </Paragraph>

          <ContentBlock>
            <ol>
              <li>
                <a href="https://applitools.com/blog/test-automation-key-enabler-for-success/">
                  Automated Testing
                </a>{' '}
                brings you the confidence that deployed changes aren’t broken or break any other
                part of your application.
              </li>
              <li>
                <a href="https://nodramadevops.com/2019/02/improving-software-delivery-performance-with-continuous-integration-and-continuous-delivery/">
                  Continuous Integration
                </a>{' '}
                gives your team the ability to merge and prepare several different application
                versions using a seamless automated build system.
              </li>
            </ol>
          </ContentBlock>

          <Paragraph>
            In theory, any change to environments or the software that runs on them should be
            applied using automated process from version control. There is still room for manual
            approvals but once approved all changes should be applied automatically.
          </Paragraph>

          <Subtitle>How is it calculated?</Subtitle>
          <Paragraph>
            In Athenian the Merge Cycle Time is calculated as the average time for pull requests to
            go from the last approval to being merged. In particular, the metric is calculated on
            pull requests that have been merged during the time period selected. If a pull request
            does not have an explicit approval or is not even reviewed, the calculation starts from
            the last commit before merge. There should be no more manual work required in this
            elapsed time apart from clicking the "merge" button.
          </Paragraph>

          <Separator />

          <Title>How to improve it using Athenian?</Title>
          <Paragraph>
            With Athenian, you get the average Merge Cycle Time of your teams which you can put in
            perspective with top performing organizations. The timeline and distribution insights
            allow you to detect outliers in your day-to-day delivery, and the table let you zoom on
            the specific pull requests stuck in the Merge stage that require special attention.
            Since slow Merge Cycle Time are often direct consequences of issues in specific
            projects, Athenian breaks down the metric per repository so that you can understand
            where exactly it takes your team the longest to merge newly created features and how
            many merges happen daily.
          </Paragraph>
          <Paragraph>
            For drastic and sustainable speed improvements in merging pull requests, Athenian helps
            your teams successfully introduce Continuous Integration into their workflow by
            following the next steps and measuring your progress on a weekly basis.
          </Paragraph>

          <ContentBlock>
            <ol>
              <li>Enable linter and other static analysis tools.</li>
              <li>Start writing tests for critical parts of the codebase.</li>
              <li>Add tools for proactive monitoring of production environment.</li>
              <li>Use a CI service to run automatic tests on every push to the main repository.</li>
              <li>Have your team integrate changes every day.</li>
              <li>Fix the build as soon as it breaks.</li>
              <li>Write tests for every new story that you implement.</li>
            </ol>
          </ContentBlock>
          <Paragraph>
            At the end the Merge stage is all about increasing team accountability for delivered
            code. Low-Performance Teams dedicate certain team members to merge new features into the
            main branch. On the other hand, High-Performance Organisations encourage everyone to
            participate in the process. The responsibility is put into the hands of those who know
            the feature the best: the developers who wrote the code.
          </Paragraph>
        </section>
        <Sidebar>
          <BlockQuote>
            "A team that develops a system is also responsible for operating and supporting that
            system."
            <Source>Netflix</Source>
          </BlockQuote>
          <BlockQuote>
            "The whole process of merging accepted pull requests takes around 10 mins end-to-end."
            <Source>Intercom</Source>
          </BlockQuote>

          <RelatedContent>
            <h4>Related Content</h4>
            <ul>
              <li>
                <a href="https://applitools.com/blog/test-automation-key-enabler-for-success/">
                  Automated Testing
                </a>
              </li>
              <li>
                <a href="https://nodramadevops.com/2019/02/improving-software-delivery-performance-with-continuous-integration-and-continuous-delivery/">
                  Continuous Integration
                </a>
              </li>
              <li>
                {fullPage ? (
                  <Link to={`/learn/knowledge/lead-time`} className="link">
                    <span>Lead Time</span>
                  </Link>
                ) : (
                  <span
                    onClick={() => openSection({ label: 'Lead Time', path: 'leadTime' })}
                    className="link"
                  >
                    Lead Time
                  </span>
                )}
              </li>
            </ul>
          </RelatedContent>
        </Sidebar>
      </Main>
    </>
  );
};

export default Merge;
