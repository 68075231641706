import { useQuery } from 'react-query';

import { CONFIG } from '@align-constants';
import { IUseQueryHookRest } from '@align-types/common';
import { useAuth0 } from '@common-context/Auth0';
import { useDemoCookie } from '@common-pages/Demo';
import { IUrlParams } from '@common-services/api/common/types/apiClient';
import { IApiBasicError } from '@common-services/api/common/types/common';
import { DashboardServices } from '@common-services/api/private/services/dashboard';
import { TeamDashboard } from '@common-services/api/private/types/dashboard';

function useDashboard(
  urlParams: IUrlParams<'dashboardId' | 'teamId'>,
  isCached: boolean = false
): IUseQueryHookRest<TeamDashboard> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();

  const { isLoading, data, error } = useQuery<TeamDashboard, IApiBasicError>(
    ['dashboard', urlParams.teamId, urlParams.dashboardId],
    async () => {
      const token: string = isDemo === false ? await getTokenSilently() : null;
      return DashboardServices.getDashboard(urlParams, token);
    },
    {
      ...CONFIG.API.FETCH_OPTIONS,
      cacheTime: isCached ? 60000 : 0,
      staleTime: isCached ? 60000 : 0,
    }
  );

  return { isLoading, data, error };
}

export function useOwnedDashboard(
  teamId: number,
  isCached: boolean = false
): IUseQueryHookRest<TeamDashboard> {
  return useDashboard({ teamId, dashboardId: 0 }, isCached);
}
