import { BasicApiClass } from '@common-services/api/common/BasicApiClass';
import { REST_TYPES } from '@common-services/api/common/types/apiClient';
import { ApiMode } from '@common-services/api/common/types/common';
import { ISentryMethodParams } from '@common-services/api/common/types/sentry';
import { PRIVATE_API_ENDPOINTS_CONSTANTS } from '@common-services/api/private/constants';
import { User } from '@common-services/api/public/generated-from-backend/models';

export class BackofficeApiClass extends BasicApiClass {
  constructor() {
    super(ApiMode.PRIVATE);
  }
  public becomeUser(
    userId: string,
    token: string = null,
    sentry?: ISentryMethodParams
  ): Promise<User> {
    this.assignToken(token);
    const callApi = this.callApi<User>(
      PRIVATE_API_ENDPOINTS_CONSTANTS.BECOME + (userId ? `?id=${userId}` : ''),
      REST_TYPES.GET
    );
    return sentry ? this.callSentry<User>(callApi, sentry) : callApi;
  }
}
