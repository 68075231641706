import React from 'react';

export enum TooltipPlace {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

export interface ITooltipProps {
  content: string | JSX.Element;
  className?: string;
  style?: any;
  children: React.ReactNode;
  place?: TooltipPlace;
  enabled?: boolean;
}
