import { css } from '@emotion/react';

export const contextMenuStyles = ({ color, font, radius, spacing, shadow }) => css`
  background-color: ${color.neutral.white};
  border-radius: ${radius.default};
  box-shadow: ${shadow.full};
  position: absolute;
  right: 0;
  top: ${spacing.gap['08']};
  width: 140px;
  z-index: 100;

  .dropdown-item {
    border-bottom: 1px solid ${color.neutral[40]};
    color: ${color.text.dark};
    font-size: ${font.size.default};
    font-weight: ${font.weight.regular};
    padding: ${spacing.gap['03']} ${spacing.gap['04']};
    display: flex;
    align-items: center;

    .option-avatar {
      margin-right: ${spacing.gap['03']};
    }

    &:last-of-type {
      border-bottom: none;
    }

    &:hover {
      background-color: ${color.neutral[20]};
    }
  }
`;

export const wrapperStyles = css`
  display: inline-grid;
  justify-items: center;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: auto auto;
  grid-template-rows: 100%;
  width: 100%;
`;

export const rightSideStyles = ({ spacing }) => css`
  cursor: default;

  .avatars,
  .actions {
    display: inline-block;
    vertical-align: middle;
  }

  .actions {
    margin-left: ${spacing.gap['05']};
  }
`;
