import { useQuery } from 'react-query';

import { CONFIG } from '@align-constants';
import { IUseQueryHookRest } from '@align-types/common';
import { useAuth0 } from '@common-context/Auth0';
import { useUserContext } from '@common-context/User';
import { useDemoCookie } from '@common-pages/Demo';
import { IApiBasicError } from '@common-services/api/common/types/common';
import {
  AlignGoalsRequest,
  AlignGoalsResponse,
} from '@common-services/api/private/generated-from-backend/models';
import { AlignGoalsServices } from '@common-services/api/private/services/alignGoals';

export function useGoals(
  params: AlignGoalsRequest,
  isCached: boolean = false
): IUseQueryHookRest<AlignGoalsResponse> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();
  const fetchGoals = async (): Promise<AlignGoalsResponse> => {
    const token: string = isDemo === false ? await getTokenSilently() : null;
    return AlignGoalsServices.getGoalsList(params, token);
  };

  const { isLoading, data, error, refetch } = useQuery<AlignGoalsResponse, IApiBasicError>(
    ['goals', params.account, params.team, params.only_with_targets, params.include_series],
    fetchGoals,
    {
      ...CONFIG.API.FETCH_OPTIONS,
      enabled: params.team ? true : false,
      cacheTime: isCached ? 60000 : 0,
      staleTime: isCached ? 60000 : 0,
    }
  );

  return { isLoading, data, error, refetch };
}

export function useGoalsForOwnedTeam(
  params: AlignGoalsRequest,
  isCached: boolean = false
): IUseQueryHookRest<AlignGoalsResponse> {
  const {
    user: {
      defaultAccount: { id: accountId },
    },
  } = useUserContext();
  return useGoals({ ...params, account: accountId }, isCached);
}
