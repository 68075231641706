import React from 'react';

import { ChartParamsType } from '@align-pages/dashboard/types';
import { LooseObject } from '@analytics-types/common';
import {
  GoalJiraIssueTypes,
  GoalJiraPriorities,
  GoalJiraProjects,
  GoalRepositories,
} from '@common-services/api/private/generated-from-backend/models';
import { Datasource } from '@common-services/api/public/generated-from-backend/models';
import { icons } from '@lib/icon';

export enum FilterType {
  REPOSITORY = 'repositories',
  JIRA_ISSUE_TYPES = 'jira_issue_types',
  JIRA_PROJECTS = 'jira_projects',
  JIRA_PRIORITIES = 'jira_priorities',
  JIRA_LABELS = 'jira_labels',
  TEAMS = 'teams',
}

export interface IFilterProps {
  filterName: FilterType;
  chartParamType?: ChartParamsType;
  onRemove?: (filterName: FilterType) => void;
  onChange?: (props: { filterName: FilterType; update: LooseObject }) => void;
  onFiltersChange?: (props: { filterName: FilterType; update: string[] }) => void;
}

export type IFilterOnChange = (update: string[]) => void;

export interface IFilterTypeProps {
  onChange: IFilterOnChange;
  chartParamType?: ChartParamsType;
}

// TODO: DEV-5878 - Use DashboardChartGroupBy | DashboardChartFilters
export type ISelectedFilters = {
  jira_issue_types?: GoalJiraIssueTypes;
  jira_priorities?: GoalJiraPriorities;
  jira_projects?: GoalJiraProjects;
  repositories?: GoalRepositories;
  teams?: number[];
};

export interface IFilterTypeOption {
  label: string;
  value: FilterType;
  icon: icons;
  component?: ({
    onChange,
    chartParamType,
  }: {
    onChange: IFilterOnChange;
    chartParamType?: ChartParamsType;
  }) => React.ReactNode;
}

export type IFilterTypeOptions = {
  [key in FilterType]: IFilterTypeOption;
};

export interface IFilterBarOption {
  type: FilterType;
  group: Datasource;
}
