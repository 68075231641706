/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const Sidebar = () => {
  return (
    <nav className="sidebar-nav">
      <div className="sidebar-sticky">
        <ul className="nav flex-column">
          <li className="nav-item">
            <a className="nav-link active" href="#">
              <i className="icon-nav_summary" />
              <span>Summary</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#"
              data-toggle="collapse"
              data-target="#pipeline-collapse"
            >
              <i className="icon-nav_pipeline" />
              <span>Pipeline</span>
              <i className="icon-angle_right" />
            </a>
            <ul className="nav nav-sub mt-1 mb-2 collapse" id="pipeline-collapse">
              <li className="nav-item">
                <a className="nav-link active sub-level" href="#">
                  <span>Plan & Design</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link sub-level" href="#">
                  <span>Code</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link sub-level" href="#">
                  <span>Review</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link sub-level" href="#">
                  <span>Merge</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link sub-level" href="#">
                  <span>Release</span>
                </a>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#" data-toggle="collapse" data-target="#quality-collapse">
              <i className="icon-nav_quality" />
              <span>Quality</span>
              <i className="icon-angle_right" />
            </a>
            <ul className="nav nav-sub mt-1 mb-2 collapse" id="quality-collapse">
              <li className="nav-item">
                <a className="nav-link sub-level" href="#">
                  <span>Bugs</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link sub-level" href="#">
                  <span>Incident Management</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link sub-level" href="#">
                  <span>Pull Request Size</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link sub-level" href="#">
                  <span>Code Review</span>
                </a>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="collapse" data-target="#outcome-collapse" href="#">
              <i className="icon-nav_outcome" />
              <span>Outcome</span>
              <i className="icon-angle_right" />
            </a>
            <ul className="nav nav-sub mt-1 mb-2 collapse" id="outcome-collapse">
              <li className="nav-item">
                <a className="nav-link sub-level" href="#">
                  <span>Work Distribution</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link sub-level" href="#">
                  <span>New Features</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link sub-level" href="#">
                  <span>Codebase</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>

        <h6 className="small-title font-weight-bold text-xs text-uppercase text-bright m-3 align-middle">
          <span>Tables</span>
        </h6>
        <ul className="nav flex-column">
          <li className="nav-item">
            <a className="nav-link" href="#">
              <i className="icon-nav_pr" />
              <span>Pull Requests</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              <i className="icon-nav_epic" />
              <span>Epics</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              <i className="icon-nav_bug" />
              <span>Bugs</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              <i className="icon-nav_release" />
              <span>Releases</span>
            </a>
          </li>
        </ul>

        <h6 className="small-title font-weight-bold text-xs text-uppercase text-bright m-3 align-middle">
          <span>Compare</span>
        </h6>
        <ul className="nav flex-column">
          <li className="nav-item">
            <a className="nav-link" href="#">
              <i className="icon-nav_compare" />
              <span>Teams</span>
            </a>
          </li>
        </ul>

        <h6 className="small-title font-weight-bold text-xs text-uppercase text-bright m-3 align-middle">
          <span>Learn</span>
        </h6>
        <ul className="nav flex-column">
          <li className="nav-item">
            <a className="nav-link" href="#">
              <i className="icon-nav_knowledge" />
              <span>Knowledge</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
