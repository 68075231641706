/**
 * {{ title }}
 * Server version: {{ server_version }} built on {{ build_date }} @ [{{ commit }}](https://github.com/athenianco/athenian-api/commit/{{ commit }})  Origin GitHub repository: [athenianco/api-spec](https://github.com/athenianco/api-spec).  Authorization persists between sessions. 🛡️ means that the endpoint requires account admin privileges. 👑 means that the endpoint is only accessible to Athenian authorities. <details> <summary>List of common server errors.</summary> * __500__ endpoint crashed. We reported the incident to Sentry and will fix it soon! Please include the value of `instance` in the response if you contact the support. Repeating the request will not help, most probably. * __501__ some functions are missing, either because they are not implemented yet or a non-critical runtime dependency is not satisfied. Repeating the request will not help, guaranteed. * __502__ server crashed badly, either due to a memory access violation in native code or running out of memory. We reported this incident to Sentry and will fix it soon! Try repeating the request. * __503__ server has not fully launched yet, e.g. hasn't connected to the database; server is shutting down; we are experiencing a partial outage. Try repeating the request. * __504__ endpoint took too much time and was interrupted. We reported the incident to Sentry and will see how to improve the performance. Repeating the request will not help, most probably. </details>
 *
 * Contact: admin@athenian.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import JIRAFilterReturn from './JIRAFilterReturn';

/**
 * The FilterJIRAStuff model module.
 * @module model/FilterJIRAStuff
 */
class FilterJIRAStuff {
    /**
     * Constructs a new <code>FilterJIRAStuff</code>.
     * Request of &#x60;/filter/jira&#x60; to retrieve epics, labels, types, priorities, users, etc.
     * @alias module:model/FilterJIRAStuff
     * @param account {Number} Session account ID.
     * @param dateFrom {Date} `null` disables the time filter boundary, thus everything is returned. `date_from` and `date_to` must be both either `null` or not `null`.
     * @param dateTo {Date} `null` disables the time filter boundary, thus everything is returned. `date_from` and `date_to` must be both either `null` or not `null`.
     * @param excludeInactive {Boolean} Value indicating whether issues with the last update older than `date_from` should be ignored. If `date_from` and `date_to` are `null`, does nothing.
     */
    constructor(account, dateFrom, dateTo, excludeInactive) { 
        
        FilterJIRAStuff.initialize(this, account, dateFrom, dateTo, excludeInactive);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, account, dateFrom, dateTo, excludeInactive) { 
        obj['account'] = account;
        obj['date_from'] = dateFrom;
        obj['date_to'] = dateTo;
        obj['exclude_inactive'] = excludeInactive;
    }

    /**
     * Constructs a <code>FilterJIRAStuff</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/FilterJIRAStuff} obj Optional instance to populate.
     * @return {module:model/FilterJIRAStuff} The populated <code>FilterJIRAStuff</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new FilterJIRAStuff();

            if (data.hasOwnProperty('account')) {
                obj['account'] = ApiClient.convertToType(data['account'], 'Number');
            }
            if (data.hasOwnProperty('date_from')) {
                obj['date_from'] = ApiClient.convertToType(data['date_from'], 'Date');
            }
            if (data.hasOwnProperty('date_to')) {
                obj['date_to'] = ApiClient.convertToType(data['date_to'], 'Date');
            }
            if (data.hasOwnProperty('timezone')) {
                obj['timezone'] = ApiClient.convertToType(data['timezone'], 'Number');
            }
            if (data.hasOwnProperty('priorities')) {
                obj['priorities'] = ApiClient.convertToType(data['priorities'], ['String']);
            }
            if (data.hasOwnProperty('types')) {
                obj['types'] = ApiClient.convertToType(data['types'], ['String']);
            }
            if (data.hasOwnProperty('labels_include')) {
                obj['labels_include'] = ApiClient.convertToType(data['labels_include'], ['String']);
            }
            if (data.hasOwnProperty('labels_exclude')) {
                obj['labels_exclude'] = ApiClient.convertToType(data['labels_exclude'], ['String']);
            }
            if (data.hasOwnProperty('exclude_inactive')) {
                obj['exclude_inactive'] = ApiClient.convertToType(data['exclude_inactive'], 'Boolean');
            }
            if (data.hasOwnProperty('with')) {
                obj['with'] = ApiClient.convertToType(data['with'], Object);
            }
            if (data.hasOwnProperty('projects')) {
                obj['projects'] = ApiClient.convertToType(data['projects'], ['String']);
            }
            if (data.hasOwnProperty('return')) {
                obj['return'] = ApiClient.convertToType(data['return'], [JIRAFilterReturn]);
            }
        }
        return obj;
    }


}

/**
 * Session account ID.
 * @member {Number} account
 */
FilterJIRAStuff.prototype['account'] = undefined;

/**
 * `null` disables the time filter boundary, thus everything is returned. `date_from` and `date_to` must be both either `null` or not `null`.
 * @member {Date} date_from
 */
FilterJIRAStuff.prototype['date_from'] = undefined;

/**
 * `null` disables the time filter boundary, thus everything is returned. `date_from` and `date_to` must be both either `null` or not `null`.
 * @member {Date} date_to
 */
FilterJIRAStuff.prototype['date_to'] = undefined;

/**
 * Local time zone offset in minutes, used to adjust `date_from` and `date_to`.
 * @member {Number} timezone
 */
FilterJIRAStuff.prototype['timezone'] = undefined;

/**
 * Selected issue priorities.
 * @member {Array.<String>} priorities
 */
FilterJIRAStuff.prototype['priorities'] = undefined;

/**
 * Selected issue types. Ignored for epics.
 * @member {Array.<String>} types
 */
FilterJIRAStuff.prototype['types'] = undefined;

/**
 * JIRA issues must contain at least one label from the list. Several labels may be concatenated by a comma `,` so that all of them are required.
 * @member {Array.<String>} labels_include
 */
FilterJIRAStuff.prototype['labels_include'] = undefined;

/**
 * JIRA issues may not contain labels from this list.
 * @member {Array.<String>} labels_exclude
 */
FilterJIRAStuff.prototype['labels_exclude'] = undefined;

/**
 * Value indicating whether issues with the last update older than `date_from` should be ignored. If `date_from` and `date_to` are `null`, does nothing.
 * @member {Boolean} exclude_inactive
 */
FilterJIRAStuff.prototype['exclude_inactive'] = undefined;

/**
 * @member {Object} with
 */
FilterJIRAStuff.prototype['with'] = undefined;

/**
 * Issues must belong to these JIRA projects.
 * @member {Array.<String>} projects
 */
FilterJIRAStuff.prototype['projects'] = undefined;

/**
 * Specifies which items are required, an empty or missing array means everything.
 * @member {Array.<module:model/JIRAFilterReturn>} return
 */
FilterJIRAStuff.prototype['return'] = undefined;






export default FilterJIRAStuff;

