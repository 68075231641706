import { publicApi } from '@common-services/api/public';
import '@common-services/api/public/generated-from-backend/models';
import {
  CalculatedJIRAMetricValues,
  CalculatedPullRequestMetrics,
  CalculatedReleaseMetrics,
  ForSetPullRequests,
  Granularities,
  JIRAGroups,
  JIRAMetricID,
  JIRAMetricsRequest,
  PullRequestMetricID,
  ReleaseMetricID,
  ReleaseMetricsRequest,
  ReleaseWith,
  RepositorySet,
} from '@common-services/api/public/generated-from-backend/models';
import { IUseMetricsParams } from '@common-services/api/public/hooks/useMetricsData';
import { MetricsHelpers } from '@common-services/api/public/services/metrics';
import { GetGroupInfoType } from '@common-services/api/public/types/metrics';

export const getPullRequests = async (
  params: IUseMetricsParams,
  token: string
): Promise<CalculatedPullRequestMetrics> => {
  const hasBreakdown = !!Object.entries(params.group_by || {}).length;

  const teamGroupParams = MetricsHelpers.generateTeamGroupParams(
    params?.group_by?.teams,
    GetGroupInfoType.PULL_REQUESTS
  );

  const forWithParams: ForSetPullRequests = teamGroupParams
    ? { withgroups: teamGroupParams }
    : { with: { author: [`{${params.teamId}}`] } };

  const repositoriesList: RepositorySet = params.filters?.repositories?.length
    ? params.filters?.repositories
    : params?.group_by?.repositories || [];

  const forRepositoriesParams: ForSetPullRequests = repositoriesList?.length
    ? { repositories: repositoriesList }
    : {};

  const forRepoGroupsParams: ForSetPullRequests =
    params?.group_by?.repositories && repositoriesList?.length
      ? {
          repogroups: repositoriesList.map((item, index) => [index]) as any,
        }
      : {};

  const jiraGroupParams = MetricsHelpers.generateJiraGroupParams(
    params.group_by,
    params.filters?.jira
  );

  const forJiraParams: ForSetPullRequests = jiraGroupParams
    ? { jiragroups: jiraGroupParams }
    : { jira: params.filters?.jira };

  const metrics: PullRequestMetricID[] = MetricsHelpers.generateMetrics(
    params.metric,
    hasBreakdown
  ) as PullRequestMetricID[];

  const granularities = MetricsHelpers.generateGranularities(
    { dateFrom: params.date_from, dateTo: params.date_to },
    hasBreakdown
  );

  return publicApi.metrics.getPullRequests(
    {
      account: params.accountId,
      for: [
        {
          ...forWithParams,
          ...forRepositoriesParams,
          ...forRepoGroupsParams,
          ...forJiraParams,
        },
      ],
      date_from: params.date_from,
      date_to: params.date_to,
      metrics,
      granularities: granularities as Granularities,
      exclude_inactive: true,
      quantiles: [0, 0.95],
    },
    token,
    {
      message: `Cannot get ${params.metric} metrics`,
    }
  );
};

export const getJiraIssues = async (
  params: IUseMetricsParams,
  token: string
): Promise<CalculatedJIRAMetricValues> => {
  const hasBreakdown = !!Object.entries(params.group_by || {}).length;

  const teamGroupParams = MetricsHelpers.generateTeamGroupParams(
    params?.group_by?.teams,
    GetGroupInfoType.JIRA
  );

  const withParams = teamGroupParams ? { with: teamGroupParams } : {};

  const metrics: JIRAMetricID[] = MetricsHelpers.generateMetrics(
    params.metric,
    hasBreakdown
  ) as JIRAMetricID[];

  const jiraGroupParams: JIRAGroups = MetricsHelpers.generateJiraGroupParams(
    params.group_by,
    params.filters?.jira
  );

  const jiraFilterParams = !jiraGroupParams
    ? MetricsHelpers.generateJiraFilterParams(params.filters?.jira || {})
    : {};

  const jiraForParams: Pick<JIRAMetricsRequest, 'for'> = jiraGroupParams
    ? { for: jiraGroupParams }
    : {};

  const granularities = MetricsHelpers.generateGranularities(
    { dateFrom: params.date_from, dateTo: params.date_to },
    hasBreakdown
  );

  return publicApi.metrics.getJiraIssues(
    {
      account: params.accountId,
      date_from: params.date_from,
      date_to: params.date_to,
      metrics,
      granularities: granularities as Granularities,
      exclude_inactive: true,
      quantiles: [0, 0.95],
      ...jiraFilterParams,
      ...jiraForParams,
      ...withParams,
    },
    token,
    {
      message: `Cannot get ${params.metric} metrics`,
    }
  );
};

export const getReleases = async (
  params: IUseMetricsParams,
  token: string
): Promise<CalculatedReleaseMetrics> => {
  const hasBreakdown = !!Object.entries(params.group_by || {}).length;

  const teamGroupParams: ReleaseWith[] = MetricsHelpers.generateTeamGroupParams(
    params?.group_by?.teams,
    GetGroupInfoType.RELEASES
  );

  const withParams: ReleaseWith[] = teamGroupParams
    ? teamGroupParams
    : [{ pr_author: [`{${params.teamId}}`] }];

  const jiraGroupParams: JIRAGroups = MetricsHelpers.generateJiraGroupParams(
    params.group_by,
    params.filters?.jira
  );

  const jiraFilterParams = !jiraGroupParams ? { jira: params.filters?.jira } : {};

  const jiraGroupsParams: Pick<ReleaseMetricsRequest, 'jiragroups'> = jiraGroupParams
    ? { jiragroups: jiraGroupParams }
    : {};

  const metrics: ReleaseMetricsRequest['metrics'] = MetricsHelpers.generateMetrics(
    params.metric,
    hasBreakdown
  ) as ReleaseMetricID[];

  const granularities = MetricsHelpers.generateGranularities(
    { dateFrom: params.date_from, dateTo: params.date_to },
    hasBreakdown
  );

  const repoFilters = params.filters?.repositories || [];
  const repoGroups = params.group_by?.repositories || [];

  const forParams = repoGroups.length
    ? (repoFilters.length ? repoFilters : repoGroups).map((repo) => [repo])
    : [repoFilters];

  return publicApi.metrics.getReleases(
    {
      for: forParams,
      with: withParams,
      account: params.accountId,
      date_from: params.date_from,
      date_to: params.date_to,
      metrics,
      granularities: granularities as Granularities,
      ...jiraFilterParams,
      ...jiraGroupsParams,
    },
    token,
    {
      message: `Cannot get ${params.metric} metrics`,
    }
  );
};
