import React from 'react';
import { CellProps, Renderer } from 'react-table';

import { IIssuesTableRow } from '@align-types/issues';
import { Icon, icons } from '@lib/icon';
import { theme } from '@styles/theme';

import { wrapperStyles } from './cellSize.styles';

export const IssuesCellSize: Renderer<CellProps<IIssuesTableRow>> = React.memo(({ row }) => (
  <div css={wrapperStyles}>
    {row.depth > 0 ? (
      <>
        <span className="size green">+{row.original.size_added}</span>
        &nbsp;
        <span className="size red">-{row.original.size_removed}</span>
      </>
    ) : (
      <>
        <Icon color={theme.color.text.secondary} icon={icons.nav_pr} size={14} />
        <div className="number">{row.original.size}</div>
      </>
    )}
  </div>
));
