import React, { useMemo } from 'react';

import {
  IFilterListReducer,
  FilterDetailRowLimitType,
} from '@align-components/layouts/primary/team-header/filter-detail';
import { FilterService } from '@align-services/filterService';
import { Icon } from '@lib/icon';
import { Tooltip } from '@lib/tooltip';
import { useTheme } from '@styles/hooks';

import { MAX_FILTER_LIST_LETTER_COUNT } from './filterDetailRow.constants';
import { wrapperStyles } from './filterDetailRow.styles';
import { IFilterDetailRow, IFilterDetailRowMore } from './filterDetailRow.types';

export const FilterDetailRowMore: React.FC<IFilterDetailRowMore> = React.memo(
  ({ limitOptions, list }) => {
    return (
      <>
        {', '}
        {limitOptions.type === FilterDetailRowLimitType.LETTER ? (
          <Tooltip content={list.join(', ')}>
            <div className="more">+{list.length}</div>
          </Tooltip>
        ) : (
          <div className="more">+{list.length}</div>
        )}
      </>
    );
  }
);

export const FilterDetailRow: React.FC<IFilterDetailRow> = React.memo(
  ({
    icon,
    name,
    list,
    limitOptions = { type: FilterDetailRowLimitType.LETTER, max: MAX_FILTER_LIST_LETTER_COUNT },
  }) => {
    const theme = useTheme();
    const parsedList = useMemo<IFilterListReducer>(
      () => FilterService.listReducer(list, limitOptions),
      [name, list]
    );
    return (
      <div css={wrapperStyles({ isCompact: limitOptions.type === FilterDetailRowLimitType.WORD })}>
        <div className="icon">
          <Icon icon={icon} size={14} color={theme.color.neutral['80']} />
        </div>
        <div className="name">{name} :</div>
        <div className="list">
          {parsedList.mainList.join(', ')}
          {parsedList?.moreList?.length > 0 && (
            <FilterDetailRowMore limitOptions={limitOptions} list={parsedList.moreList} />
          )}
        </div>
      </div>
    );
  }
);
