import React, { useCallback } from 'react';

import { calculateGranularity } from '@analytics-components/Prefetcher';
import { PRODUCTION_ENV } from '@analytics-constants';
import DataWidget from '@analytics-context/DataWidget';
import { fetchDeploymentMetrics, fetchPRsMetrics } from '@analytics-services/api';
import { getFeature, featuresList } from '@analytics-services/flags';
import { humanLaboralRate } from '@common-services/dateService';

import InsightsDeployDataBinder from './InsightsDeployDataBinder';

const InsightsDeploy: React.FC = React.memo(() => {
  const fetcher = useCallback(async (api, cachedData, apiContext) => {
    const {
      account,
      contributors,
      epics,
      excludeInactive,
      features,
      interval,
      issueTypes,
      labels,
      repositories,
    } = apiContext;

    let granularity = calculateGranularity(interval);
    if (granularity === 'month') {
      granularity = `aligned ${granularity}`;
    }

    // if the company did not provide any deployment data
    if (cachedData['environments'].status === 422) {
      return null;
    }

    const environments = cachedData['environments'].map((env) => env.name);

    const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, features)?.parameters
      ?.value;

    const deployments = environments.length
      ? await fetchDeploymentMetrics(api, account, interval, [granularity], ['count'], {
          environments,
          jira: { epics, issue_types: issueTypes },
          pr_labels_include: labels,
          pr_labels_exclude: excludedLabels || [],
          repositories,
          with: { pr_author: contributors.map((c) => c.login).filter((c) => !!c) },
        })
      : [];

    const leadTime = environments.length
      ? await fetchPRsMetrics(
          api,
          account,
          [granularity, 'all'],
          interval,
          ['lead-deployment-time'],
          {
            environments,
            jira: { epics, issue_types: issueTypes },
            labels_include: labels,
            labels_exclude: excludedLabels || [],
            repositories,
            with: { author: contributors.map((c) => c.login).filter((c) => !!c) },
          },
          null,
          excludeInactive
        )
      : [];

    const cycleTime = (leadTime?.calculated?.[0]?.for?.environments || []).reduce(
      (acc, env, index) => ({
        ...acc,
        [env]: (leadTime?.calculated?.[0]?.values || []).map((v) => {
          const normalisedVar = Array.isArray(v.values[0]) ? v.values[0][index] : v.values[0];
          return {
            date: v.date,
            value: normalisedVar ? parseInt(normalisedVar) * 1000 : null,
          };
        }),
      }),
      {}
    );

    const cycleTimeAvg = (leadTime?.calculated?.[1]?.for?.environments || []).reduce(
      (acc, env, index) => ({
        ...acc,
        [env]: () => {
          const val = leadTime?.calculated?.[1]?.values?.[0]?.values?.[0] || null;
          const normalisedVar = Array.isArray(val) ? val[index] : val;
          return normalisedVar || null;
        },
      }),
      {}
    );

    const depFrequency = deployments.reduce(
      (acc, m) => ({
        ...acc,
        [m.for?.environments?.[0]]:
          m.values?.map((v) => ({
            date: v.date,
            value: v.values[0],
          })) || [],
      }),
      {}
    );

    return {
      cycleTime,
      cycleTimeAvg,
      depFrequency,
    };
  }, []);

  const plumber = useCallback((fetchedData, cachedData, apiContext) => {
    if (!fetchedData) {
      return { noDeployments: true };
    }
    // sort environments so that Production comes first and then those that have least amount of deployments
    const environments = cachedData['environments'].sort((a, b) =>
      a.name === PRODUCTION_ENV || a.deployments_count < b.deployments_count ? -1 : 1
    );
    const dataValues = environments.map((env) => {
      const cachedDepData = cachedData['deployments']?.find(
        (d) => d.for?.environments?.[0] === env.name
      );
      const deployments = cachedDepData?.values?.[0]?.values?.[0];
      return {
        cycleTime: fetchedData.cycleTime[env.name],
        depFrequency: fetchedData.depFrequency[env.name],
        env: env.name,
        kpisData: {
          cycleTime: fetchedData.cycleTimeAvg[env.name],
          depFrequency: humanLaboralRate(
            apiContext.interval.from,
            apiContext.interval.to,
            cachedDepData?.values?.[0]?.values?.[0] || 0
          ),
          deployments,
          prsCount: cachedDepData?.values?.[0]?.values?.[2],
        },
      };
    });

    const customGranularity = cachedData['prs-metrics'].customGranularity;

    return {
      granularity: customGranularity,
      interval: apiContext.interval,
      dataValues,
    };
  }, []);

  return (
    <DataWidget
      id="deploy-insights"
      fetcher={fetcher}
      plumber={plumber}
      prefetchedDataIds={['deployments', 'environments', 'prs-metrics']}
    >
      <InsightsDeployDataBinder />
    </DataWidget>
  );
});

export default InsightsDeploy;
