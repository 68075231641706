/**
 * {{ title }}
 * Server version: {{ server_version }} built on {{ build_date }} @ [{{ commit }}](https://github.com/athenianco/athenian-api/commit/{{ commit }})  Origin GitHub repository: [athenianco/api-spec](https://github.com/athenianco/api-spec).  Authorization persists between sessions. 🛡️ means that the endpoint requires account admin privileges. 👑 means that the endpoint is only accessible to Athenian authorities. <details> <summary>List of common server errors.</summary> * __500__ endpoint crashed. We reported the incident to Sentry and will fix it soon! Please include the value of `instance` in the response if you contact the support. Repeating the request will not help, most probably. * __501__ some functions are missing, either because they are not implemented yet or a non-critical runtime dependency is not satisfied. Repeating the request will not help, guaranteed. * __502__ server crashed badly, either due to a memory access violation in native code or running out of memory. We reported this incident to Sentry and will fix it soon! Try repeating the request. * __503__ server has not fully launched yet, e.g. hasn't connected to the database; server is shutting down; we are experiencing a partial outage. Try repeating the request. * __504__ endpoint took too much time and was interrupted. We reported the incident to Sentry and will see how to improve the performance. Repeating the request will not help, most probably. </details>
 *
 * Contact: admin@athenian.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AccountStatus from './AccountStatus';

/**
 * The User model module.
 * @module model/User
 */
class User {
    /**
     * Constructs a new <code>User</code>.
     * User details. \&quot;updated\&quot; and \&quot;accounts\&quot; are populated only for the calling user.
     * @alias module:model/User
     * @param id {String} Auth0 user identifier.
     * @param nativeId {String} Auth backend user identifier.
     * @param login {String} Auth backend user login name.
     */
    constructor(id, nativeId, login) { 
        
        User.initialize(this, id, nativeId, login);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, nativeId, login) { 
        obj['id'] = id;
        obj['native_id'] = nativeId;
        obj['login'] = login;
    }

    /**
     * Constructs a <code>User</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/User} obj Optional instance to populate.
     * @return {module:model/User} The populated <code>User</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new User();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('native_id')) {
                obj['native_id'] = ApiClient.convertToType(data['native_id'], 'String');
            }
            if (data.hasOwnProperty('login')) {
                obj['login'] = ApiClient.convertToType(data['login'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('picture')) {
                obj['picture'] = ApiClient.convertToType(data['picture'], 'String');
            }
            if (data.hasOwnProperty('updated')) {
                obj['updated'] = ApiClient.convertToType(data['updated'], 'Date');
            }
            if (data.hasOwnProperty('accounts')) {
                obj['accounts'] = ApiClient.convertToType(data['accounts'], {'String': AccountStatus});
            }
            if (data.hasOwnProperty('impersonated_by')) {
                obj['impersonated_by'] = ApiClient.convertToType(data['impersonated_by'], 'String');
            }
        }
        return obj;
    }


}

/**
 * Auth0 user identifier.
 * @member {String} id
 */
User.prototype['id'] = undefined;

/**
 * Auth backend user identifier.
 * @member {String} native_id
 */
User.prototype['native_id'] = undefined;

/**
 * Auth backend user login name.
 * @member {String} login
 */
User.prototype['login'] = undefined;

/**
 * Full name of the user.
 * @member {String} name
 */
User.prototype['name'] = undefined;

/**
 * Email of the user.
 * @member {String} email
 */
User.prototype['email'] = undefined;

/**
 * Avatar URL of the user.
 * @member {String} picture
 */
User.prototype['picture'] = undefined;

/**
 * Date and time of the last profile update.
 * @member {Date} updated
 */
User.prototype['updated'] = undefined;

/**
 * Mapping from user's account ID the membership status.
 * @member {Object.<String, module:model/AccountStatus>} accounts
 */
User.prototype['accounts'] = undefined;

/**
 * Identifier of the god user who is acting on behalf of.
 * @member {String} impersonated_by
 */
User.prototype['impersonated_by'] = undefined;






export default User;

