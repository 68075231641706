import { Demo, Undemo } from '@common-pages/Demo';

const demoRoutes = [
  {
    route: {
      path: '/demo',
    },
    component: Demo,
  },
  {
    route: {
      path: '/undemo',
    },
    component: Undemo,
  },
];

export default demoRoutes;
