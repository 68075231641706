import { useCallback, useEffect } from 'react';

import { PrimaryLayoutModes } from '@align-components/layouts/primary';
import { useSuccessRedirect } from '@align-hooks/useSuccessRedirect';
import { ITeamGoal, ITemplateMetric, TeamGoalInput } from '@align-services/api/types/goalsTypes';
import { GoalsService } from '@align-services/goalsService';
import { IApiBasicError } from '@common-services/api/common/types/common';
import {
  GoalCreateRequest,
  GoalMetricParams,
  TeamGoalAssociations,
} from '@common-services/api/private/generated-from-backend/models';
import { useCreateGoalOwnedTeam } from '@common-services/api/private/hooks/useCreateGoal';
import { useUpdateGoal } from '@common-services/api/private/hooks/useUpdateGoal';
import { ISelectedFilters } from '@lib/filter-panel';

import { logErrorMessage } from './helpers';

type voidFn = () => void;

export const useGoalCRU = (
  expiresAt: string,
  goalId: string,
  metricsValuesError: IApiBasicError,
  mode: PrimaryLayoutModes,
  teamGoals: TeamGoalInput[],
  templateName: string,
  templateMetric: ITemplateMetric,
  selectedFilters: ISelectedFilters,
  metricParams: GoalMetricParams,
  validFrom: string
): voidFn => {
  const {
    mutate: createMutate,
    data: createData,
    isError: createIsError,
    isSuccess: createIsSuccess,
  } = useCreateGoalOwnedTeam();
  const {
    mutate: updateMutate,
    data: updateData,
    isError: updateIsError,
    isSuccess: updateIsSuccess,
  } = useUpdateGoal();

  const { successRedirect } = useSuccessRedirect();

  const submitHandler = useCallback(() => {
    if (mode === PrimaryLayoutModes.CREATE) {
      const createGoalData: Partial<GoalCreateRequest> = {
        name: templateName,
        metric: templateMetric,
        valid_from: validFrom,
        expires_at: expiresAt,
        team_goals: GoalsService.serializeTeamGoalInput(teamGoals) as TeamGoalAssociations,
        ...GoalsService.serializeFilters(selectedFilters),
        ...(metricParams ? { metric_params: metricParams } : ''),
      };
      createMutate(createGoalData);
    } else {
      updateMutate({
        goalId: parseInt(goalId),
        input: {
          ...GoalsService.serializeFilters(selectedFilters),
          archived: false,
          name: templateName,
          team_goals: GoalsService.serializeTeamGoalInput(teamGoals) as TeamGoalAssociations,
        },
      });
    }
  }, [
    templateName,
    templateMetric,
    teamGoals,
    validFrom,
    expiresAt,
    mode,
    goalId,
    selectedFilters,
    metricParams,
  ]);

  useEffect(() => {
    if (createIsSuccess) {
      successRedirect(`Goal #${createData?.id || 'N/A'} has been created`);
    }
  }, [createIsSuccess]);

  useEffect(() => {
    if (updateIsSuccess) {
      successRedirect(`Goal #${updateData?.id || 'N/A'} has been updated`);
    }
  }, [updateIsSuccess]);

  useEffect(() => {
    const metricIsError = !!metricsValuesError;
    logErrorMessage({
      createIsError,
      updateIsError,
      metricIsError,
    });
  }, [createIsError, updateIsError, metricsValuesError]);

  return submitHandler;
};

export const useExistingTargets = (teamGoal: ITeamGoal): number => {
  let existingTargetsCount = 0;
  const countTargets = (goal: ITeamGoal) => {
    if (!goal) return;
    if (goal.value?.target) {
      existingTargetsCount += 1;
    }
    goal.children.forEach((childGoal) => countTargets(childGoal));
  };
  countTargets(teamGoal);
  return existingTargetsCount;
};
