import _ from 'lodash';
import moment from 'moment';

import { getBestTimeUnit } from '@common-services/dateService';
import * as NumberService from '@common-services/numberService';

export const getBestFitDurationUnit = (data) => {
  const maxValue = _(data)
    .map((v) => v.y)
    .max();

  return getBestTimeUnit(maxValue);
};

export const convertTime = (milliseconds, targetUnit) => {
  const value = moment.duration(milliseconds);
  switch (targetUnit) {
    case 'secs':
      return value.asSeconds();
    case 'mins':
      return value.asMinutes();
    case 'hours':
      return value.asHours();
    case 'days':
      return value.asDays();
    case 'weeks':
      return value.asWeeks();
    case 'months':
      return value.asMonths();
    case 'years':
      return value.asYears();
    default:
      return null;
  }
};

export const github = {
  repoOrg: (fullName) => fullName.split('/')[1],
  repoName: (fullName) => {
    // possible formats
    // github.com/athenianco/athenian-webapp/alpha/beta -> athenian-webapp/alpha/beta
    // github.com/athenianco/athenian-webapp/alpha      -> athenian-webapp/alpha
    // github.com/athenianco/athenian-webapp            -> athenian-webapp
    // athenianco/helm-repo/alpha/beta                  -> helm-repo/alpha/beta
    // athenianco/helm-repo/alpha                       -> helm-repo/alpha
    // athenianco/helm-repo                             -> helm-repo
    const parts = fullName.split('/');
    if (parts.length < 2) {
      return 'INVALID: ' + fullName;
    }
    const dotPos = fullName.indexOf('.');
    if (dotPos >= 0 && dotPos < parts[0].length) {
      parts.shift();
    }
    return parts.slice(1).join('/');
  },
  userName: (fullName) => (fullName ? fullName.substring(fullName.indexOf('/') + 1) : ''),
  prLink: (fullName, number) => {
    let parts = fullName.split('/');
    if (parts.length < 2) {
      return 'INVALID: ' + fullName;
    }
    if (parts.length > 3) {
      // remove the logical component
      parts = parts.slice(0, 3);
    }
    return `https://${parts.join('/')}/pull/${number}`;
  },
  userImageIndex: (usersData) =>
    _(usersData).reduce(
      (res, v, k) => {
        res[github.userName(k)] = v.avatar;
        return res;
      },
      { undefined: 'https://avatars2.githubusercontent.com/u/10137' }
    ),
  commitLink: (repo, hash) => `https://${repo}/commit/${hash}`,
};

const KILO = 1000;
const MEGA = 1000 * KILO;

export const isNumber = (n) => typeof n === 'number' && isFinite(n);

export const number = {
  si: (n) => {
    if (n < KILO) {
      return n;
    } else if (n < MEGA) {
      return Math.round((n * 10) / KILO) / 10 + 'K';
    } else if (n < 500 * MEGA) {
      return Math.round((n * 10) / MEGA) / 10 + 'M';
    }

    return '>500M';
  },
  // rounds a number and returns it as a zero-padded string
  fixed: (number, decimals = 0) => (isNumber(number) ? number.toFixed(decimals) : number),
  // rounds a number returning a number
  roundByThousand: (num) => {
    if (num < 1000) {
      return num;
    } else if (num < 1000000) {
      return `${NumberService.round(num / 1000)}k`;
    } else if (num < 1000000000) {
      return `${NumberService.round(num / 1000000)}M`;
    } else {
      return `${NumberService.round(num / 1000000000)}B`;
    }
  },
  percentage: (n, decimals = 0) => {
    return NumberService.round(n, decimals) + '%';
  },
};

export const userImage = (avatars) => (user) => {
  const userAvatar = avatars[user];
  if (userAvatar) {
    const userName = github.userName(user);
    return `<img
      src="${userAvatar.avatar}"
      title="${userName}"
      alt="${userName}"
      class="pr-user-avatar"
      onerror="this.onerror=null;this.src='https://avatars2.githubusercontent.com/u/10137';"
    />`;
  }

  return `<span title="" class="pr-user-avatar pr-user-unknown">?</span>`;
};

const avatarPlaceholder = (count) => {
  return `<span class="pr-user-avatar count">+${count}</span>`;
};

export const renderAvatars = (
  contributors,
  avatars,
  imageFactory = userImage,
  placeholderFn = avatarPlaceholder,
  count = 5
) => {
  if (Array.isArray(contributors)) {
    if (contributors.length <= count) {
      return contributors.map(imageFactory(avatars));
    } else {
      const rest = contributors.slice(count, contributors.length);
      const groupedAvatars = contributors.slice(0, count).map(imageFactory(avatars));

      const remainCount = placeholderFn(rest.length);
      groupedAvatars.push(remainCount);

      return groupedAvatars;
    }
  }
  return [imageFactory(avatars)(contributors)];
};
