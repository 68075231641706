import React, { useMemo } from 'react';

import { PrsIssuesTableHeader } from '@align-components/prs-issues-table-header';
import { metricsConfig } from '@align-constants';
import { PrTable } from '@align-pages/goals/single/components/goal-details/components/pr-table';
import { DateService } from '@align-services/dateService';
import { PrsIssuesTableTabs } from '@align-types/common';
import { PRsTableTabLabel } from '@align-types/constants';
import { ITab } from '@lib/Tabs/components/Tab/Tab.types';
import { NoData } from '@lib/empty/noData';

import { IssuesTable } from './components/issues-table';
import { wrapperStyles } from './goalDetails.styles';
import { IGoalDetails } from './goalDetails.types';

export const GoalDetails: React.FC<IGoalDetails> = React.memo(
  ({
    activeTab,
    completedCount,
    inProgressCount,
    prData,
    issuesData,
    goal,
    isLoading,
    isLoadingMore,
    selectedRange,
    templateMetric,
    onShowMoreHandler,
    onChangeTab,
    onRemoveTimeframe,
  }) => {
    const isGoalComplete = useMemo(() => DateService.isBeforeToday(goal?.expires_at), [goal]);

    const tabs = useMemo<ITab[]>(
      () => [
        {
          isActive: activeTab === PrsIssuesTableTabs.COMPLETED,
          label: metricsConfig[templateMetric]?.allKPI
            ? PRsTableTabLabel.ALL
            : PRsTableTabLabel.COMPLETED,
          value: completedCount,
        },
        ...(isGoalComplete
          ? []
          : [
              {
                isActive: activeTab === PrsIssuesTableTabs.IN_PROGRESS,
                label: PRsTableTabLabel.IN_PROGRESS,
                value: inProgressCount,
              },
            ]),
      ],
      [activeTab, completedCount, inProgressCount, isGoalComplete, templateMetric]
    );

    const isJiraIssues = useMemo(() => !!metricsConfig[templateMetric]?.jiraMetricField, [
      templateMetric,
    ]);

    const data = useMemo(
      () =>
        isJiraIssues ? (issuesData ? issuesData?.issues : null) : prData ? prData?.data : null,
      [isJiraIssues, prData, issuesData]
    );

    const canShowMore = useMemo(() => {
      if (activeTab === PrsIssuesTableTabs.COMPLETED && completedCount) {
        return data?.length < completedCount;
      } else if (activeTab === PrsIssuesTableTabs.IN_PROGRESS && inProgressCount) {
        return data?.length < inProgressCount;
      }
      return false;
    }, [activeTab, completedCount, inProgressCount, data, isLoading]);

    return (
      <div css={wrapperStyles}>
        {(isLoading || (!data && isLoadingMore)) && <div className="dataTable-placeholder" />}
        {!isLoading && (
          <PrsIssuesTableHeader
            activeTab={activeTab}
            dateFrom={goal?.valid_from}
            dateTo={goal?.expires_at}
            isJiraMetric={metricsConfig[templateMetric]?.jiraMetricField}
            selectedRange={selectedRange}
            tabs={tabs}
            onChangeTab={onChangeTab}
            onRemoveTimeframe={onRemoveTimeframe}
          />
        )}
        {!isLoading && !isLoadingMore && data?.length === 0 && <NoData />}
        {prData?.data.length > 0 && (
          <PrTable
            data={prData}
            templateMetric={templateMetric}
            isLoadingPart={isLoadingMore}
            onShowMoreHandler={canShowMore && onShowMoreHandler}
          />
        )}
        {issuesData?.issues.length > 0 && (
          <IssuesTable
            data={issuesData}
            templateMetric={templateMetric}
            isLoadingPart={isLoadingMore}
            onShowMoreHandler={canShowMore && onShowMoreHandler}
          />
        )}
      </div>
    );
  }
);
