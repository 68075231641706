export enum IColor {
  white = '#FFFFFF',
  neutral_100 = '#121343',
  neutral_80 = '#8889A1',
  neutral_60 = '#C0C1CE',
  neutral_40 = '#E7E7EC',
  neutral_20 = '#F3F3F5',
  neutral_10 = '#F7F7F8',
  neutral_0 = '#FAFAFB',
  ui_blue_100 = '#147EEC',
  ui_blue_50 = '#89BEF5',
  ui_purple_100 = '#9260E2',
  ui_purple_50 = '#C8AFF0',
  ui_darkpurple_100 = '#451298',
  ui_darkpurple_50 = '#A288CB',
  ui_lightpink_100 = '#DA4CF3',
  ui_lightpink_50 = '#ECA5F9',
  ui_pink_100 = '#FC1763',
  ui_pink_50 = '#FD8BB1',
  ui_red_100 = '#EF3837',
  ui_red_50 = '#F79B9B',
  ui_red_20 = '#FCD7D7',
  ui_darkred_100 = '#910F0F',
  ui_brown_100 = '#913E0F',
  ui_brown_50 = '#C89E87',
  ui_orange_100 = '#FF7427',
  ui_orange_50 = '#FFB993',
  ui_lightorange_100 = '#FFA008',
  ui_lightorange_50 = '#FFCF83',
  ui_yellow_100 = '#FFC508',
  ui_yellow_50 = '#FFE283',
  ui_yellow_10 = '#FFF9E6',
  ui_green_100 = '#2FCC71',
  ui_green_50 = '#97E5B8',
  ui_green_20 = '#D5F5E3',
  ui_darkgreen_100 = '#00B400',
  ui_turquoise_100 = '#24C7CC',
  ui_turquoise_50 = '#91E3E5',
  ui_lightblue_100 = '#4DC7EE',
  ui_lightblue_50 = '#A6E3F6',
  ui_ocean_100 = '#ACCDD7',
}
