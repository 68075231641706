import React from 'react';

import { CardTitle } from '@analytics-components/Typography';
import { MetaType } from '@analytics-components/insights/Box/types';
import { boxHeaderStyles } from '@analytics-components/insights/styles';
import { Info } from '@lib/info';

interface Props {
  meta: MetaType;
}

const BoxHeader: React.FC<Props> = React.memo(({ meta }) => (
  <div css={boxHeaderStyles}>
    <div>
      <CardTitle content={meta.title} />
      <Info content={meta.description} />
    </div>
  </div>
));

export default BoxHeader;
