import { IColor } from './colors';

export const neutral = {
  100: IColor.neutral_100,
  80: IColor.neutral_80,
  60: IColor.neutral_60,
  40: IColor.neutral_40,
  20: IColor.neutral_20,
  10: IColor.neutral_10,
  0: IColor.neutral_0,
  white: IColor.white,
};
