import React from 'react';

import { GhAppLink, WaitingMessage } from './Layout';

interface Props {
  isAdmin?: boolean;
}

const WaitingInstall: React.FC<Props> = React.memo(({ isAdmin }) => {
  const ghAppUrl = window.ENV.application.githubAppUri;

  if (!isAdmin) {
    return (
      <WaitingMessage>
        Please wait till the administrator of your organization installs Athenian on your GitHub
        organization.
      </WaitingMessage>
    );
  }

  return (
    <WaitingMessage>
      <div className="my-5">
        <GhAppLink url={ghAppUrl} text="Install Athenian GitHub Application" />
      </div>
      <div className="mt-6">
        Once you install and configure the GitHub App, Athenian will start loading your data from
        GitHub. The loading process will take a while.
      </div>
    </WaitingMessage>
  );
});

export default WaitingInstall;
