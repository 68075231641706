import { sortBy } from '@common-services/vendor/lodash';

export function getFlatArrayFromNested(arr: any[], nestedParamName: string = null): any[] {
  let result = [];
  arr.forEach((item) => {
    const childrenArr = item?.[nestedParamName]
      ? getFlatArrayFromNested(item[nestedParamName], nestedParamName)
      : [];
    const resultArr = [item, ...childrenArr].map((itemMap) => {
      return {
        ...itemMap,
        [nestedParamName]: [],
      };
    });
    result = [...result, ...resultArr];
  });
  return result;
}

export function getNullArray(size: number): null[] {
  return new Array(size).fill(null);
}

export function getNumberedArray(size: number): number[] {
  return getNullArray(size).map((_, i) => i);
}

export function sortByKeyValue<T extends object, K extends keyof T>(
  arr: T[],
  keys: K[],
  sortingType: ('asc' | 'dsc')[]
): T[] {
  return sortBy(arr, keys, sortingType);
}

export function getUniqueValues<T extends unknown>(arr: T[]): T[] {
  return [...new Set(arr)];
}

export * as ArrayService from './arrayService';
