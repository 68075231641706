import _ from 'lodash';
import React from 'react';
import { useTable, useSortBy, useExpanded, usePagination } from 'react-table';
import ReactTooltip from 'react-tooltip';

import TablePagination from '@analytics-components/tables/TablePagination';
import { TableFilter } from '@analytics-components/tables/table-filter';
import { TableSearch } from '@analytics-components/tables/table-search';
import { Table } from '@lib/Table';
import { Icon, icons } from '@lib/icon';
import { theme } from '@styles/theme';
import Chevron from '@ui/Chevron';

import {
  filtersStyles,
  iconWrapperStyles,
  infoStyles,
  placeholderStyles,
  prSizeStyles,
  searchStyles,
  smallText,
  statusStyles,
  tableHeaderStyles,
  tableBodyStyles,
  taskDetailsStyles,
  titleStyles,
} from './styles';

const CellIssueTitle = ({ row }) => {
  const { onClick } = row.getToggleRowExpandedProps();

  return (
    <div css={titleStyles}>
      {row.canExpand ? (
        <Chevron isExpanded={row.isExpanded} onClick={onClick} />
      ) : (
        <div css={placeholderStyles} />
      )}
      {row.depth > 0 ? (
        <div css={iconWrapperStyles}>{row.original.prIcon}</div>
      ) : (
        <img
          alt=""
          className="issue-avatar"
          src={row.original.issueAvatar}
          title={row.original.type}
        />
      )}
      <div>
        <>
          <a
            className="issue-title"
            data-tip
            data-for={`tooltip-${row.original.id}`}
            href={row.original.url || '#'}
            target="_blank"
            rel="noreferrer"
          >
            {row.depth > 0 ? (
              <div className="pr-title-text">
                <span className="pr-repo">{row.original.repo}</span> #{row.original.id}{' '}
                {row.original.title}
              </div>
            ) : (
              <div className="issue-title-text">
                <span className="issue-id">{row.original.id}</span> {row.original.title}
              </div>
            )}
          </a>
          <ReactTooltip
            className="athenian-tooltip"
            id={`tooltip-${row.original.id}`}
            effect="solid"
            place="bottom"
            type="light"
            getContent={() => (
              <>
                <span>#{row.original.id}</span> {row.original.title}
              </>
            )}
          />
        </>
        <div css={taskDetailsStyles}>
          <img
            alt=""
            className="user-avatar"
            src={row.depth > 0 ? row.original.creatorAvatar : row.original.reporterAvatar}
            title={row.depth > 0 ? row.original.creator : row.original.reporter}
          />
          <div className="task-details">
            <span>{row.depth > 0 ? 'Created by ' : 'Reported by '}</span>
            <span className="reporter">
              {row.depth > 0 ? row.original.creator : row.original.reporter}
            </span>{' '}
            {row.original.age}
            <span> ago</span>
          </div>
        </div>
      </div>
    </div>
  );
};

// duplicate
const Align = ({ align, children }) => (
  <div css={{ textAlign: align, width: '100%' }}>{children}</div>
);

const columns = [
  {
    Header: 'Issues',
    accessor: 'created',
    Cell: CellIssueTitle,
  },
  {
    Header: 'Size',
    accessor: 'size',
    Cell: ({ row }) =>
      row.depth > 0 ? (
        <div css={prSizeStyles}>
          <span css={{ color: theme.color.ui.green[100] }}>+{row.original.sizeAdded}</span>{' '}
          <span css={{ color: theme.color.ui.red[100] }}>-{row.original.sizeRemoved}</span>
        </div>
      ) : (
        <div css={infoStyles}>
          <Icon icon={icons.nav_pr} size={14} />
          <div className="number">{row.original.size}</div>
        </div>
      ),
  },
  {
    Header: 'Comments',
    accessor: 'comments',
    Cell: ({ row }) => (
      <div css={infoStyles}>
        <Icon icon={icons.comment} size={15} />
        <div className="number">{row.original.comments}</div>
      </div>
    ),
  },
  {
    Header: 'Assignee',
    accessor: 'assignee',
    Cell: ({ row }) =>
      row.depth === 0 && (
        <Align align="center">
          <img
            alt=""
            className="pr-user-avatar"
            css={({ spacing }) => ({ marginRight: spacing.gap['0'] })}
            src={row.original.assigneeAvatar}
            title={row.original.assignee}
          />
        </Align>
      ),
  },
  {
    Header: 'Lead Time',
    accessor: 'leadTimeValue',
    Cell: ({ row }) => row.depth === 0 && <div css={smallText}>{row.original.leadTimeText}</div>,
  },
  {
    Header: 'P',
    accessor: 'priority',
    Cell: ({ row }) =>
      row.depth === 0 && (
        <Align align="center">
          <img
            alt={row.original.priority}
            css={{ height: 16, width: 20 }}
            src={row.original.priorityImage}
            title={row.original.priority}
          />
        </Align>
      ),
    sortType: (a, b) => {
      const priorityA = a.original.priorityRank;
      const priorityB = b.original.priorityRank;
      return priorityA > priorityB ? -1 : 1;
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ row }) => (
      <div
        className={row.depth > 0 && `badge badge-outlined ${row.original.statusClassName}`}
        css={statusStyles(theme, row.original.statusColor)}
      >
        <span>{row.original.status.toUpperCase()}</span>
      </div>
    ),
    sortType: (a, b) => {
      // order by status stages
      const order = ['done', 'in progress', 'to do'];
      const statusStageA = order.indexOf(a.original.statusStage);
      const statusStageB = order.indexOf(b.original.statusStage);
      if (statusStageA > statusStageB) {
        return -1;
      } else if (statusStageA < statusStageB) {
        return 1;
      } else {
        // if status stages are the same order alphabetically
        const statusA = a.original.status;
        const statusB = b.original.status;
        return statusA > statusB ? 1 : -1;
      }
    },
    width: 170,
  },
];

const IssuesTable = ({
  data,
  currentStatus,
  filterOptions,
  searchedString,
  onSearch,
  onFilter,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageCount,
    prepareRow,
    previousPage,
    rows,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      autoResetExpanded: false, // this prop will preserve expanded rows from collapsing when data changes
      columns,
      data,
      initialState: { pageIndex: 0 },
      paginateExpandedRows: false,
    },
    useSortBy,
    useExpanded,
    usePagination
  );
  _(page).forEach(prepareRow);

  return (
    <>
      <div css={filtersStyles}>
        {onSearch && (
          <TableSearch
            className="issues-search"
            css={searchStyles}
            placeholder="Search..."
            value={searchedString}
            onFilter={onSearch}
          />
        )}
        <TableFilter
          options={filterOptions}
          currentValue={currentStatus}
          setCurrentValue={onFilter}
        />
      </div>
      <Table
        rows={page}
        headerGroups={headerGroups}
        tableProps={getTableProps()}
        tableBodyProps={getTableBodyProps()}
        extraCSS={{
          header: tableHeaderStyles,
          body: tableBodyStyles,
        }}
      />
      <TablePagination
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        total={rows.length}
        gotoPage={gotoPage}
        gotoNextPage={nextPage}
        gotoPrevPage={previousPage}
        setPageSize={setPageSize}
      />
    </>
  );
};

export default IssuesTable;
