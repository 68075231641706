import { css } from '@emotion/react';

export const wrapperStyles = ({ color, font, spacing }) => css`
  display: flex;
  justify-content: center;
  width: 100%;

  .page-content {
    margin-top: ${spacing.gap['09']};
    margin-bottom: ${spacing.gap['09']};
    width: 1010px;

    .title {
      color: ${color.text.dark};
      font-size: ${font.size.l};
      font-weight: ${font.weight.regular};
    }

    .subtitle {
      color: ${color.text.secondary};
      font-size: ${font.size.default};
      font-weight: ${font.weight.regular};
      margin-bottom: ${spacing.gap['06']};
      margin-top: ${spacing.gap['04']};
    }
  }
`;

export const templatesListStyles = ({ spacing }) => css`
  display: grid;
  gap: ${spacing.gap['06']};
  grid-template-columns: repeat(3, 1fr);
`;

export const spinnerWrapper = css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`;
