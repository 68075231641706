import { useMemo } from 'react';

import { icons } from '@lib/icon';

export const useRatio = (refValue: number, targetValue: number): number => {
  const ratio = useMemo(() => {
    return refValue ? Math.round(((refValue - targetValue) * 100) / refValue) : null;
  }, [refValue, targetValue]);

  return ratio;
};

export const useCaretIcon = (positiveIsBetter: boolean, ratio: number): icons => {
  const caretIcon = useMemo(() => {
    if (ratio > 0) {
      return positiveIsBetter ? icons.caret_down : icons.caret_up;
    } else if (ratio < 0) {
      return positiveIsBetter ? icons.caret_up : icons.caret_down;
    }
    return null;
  }, [positiveIsBetter, ratio]);

  return caretIcon;
};
