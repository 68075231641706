import React from 'react';

import { SmallTitle } from '@analytics-components/Typography';
import { chartTitleStyles } from '@analytics-components/insights/styles';
import { Info } from '@lib/info';

interface Props {
  description?: string;
  title: string;
}

const ChartTitle: React.FC<Props> = React.memo(({ title, description }) => {
  if (!title) return null;

  return (
    <div css={chartTitleStyles}>
      <SmallTitle content={title} />
      <Info content={description} />
    </div>
  );
});

export default ChartTitle;
