import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { filterJIRAStuff } from '@analytics-services/api/jira';
import {
  ApiClient,
  CalculatedDeveloperMetrics,
  CalculatedPullRequestHistogram,
  CalculatedPullRequestMetrics,
  CodeCheckMetricsRequest,
  DefaultApi,
  DeploymentMetricsRequest,
  DeveloperMetricsRequest,
  FilterCodeChecksRequest,
  FilterCommitsRequest,
  FilterLabelsRequest,
  FilterPullRequestsRequest,
  FilterReleasesRequest,
  HistogramScale,
  PaginatePullRequestsRequest,
  PullRequestHistogramsRequest,
  PullRequestMetricsRequest,
  ReleaseMatchRequest,
  TeamCreateRequest,
  TeamUpdateRequest,
} from '@analytics-services/api/openapi-client';
import DeploymentMetricID from '@analytics-services/api/openapi-client/model/DeploymentMetricID';
import DeveloperMetricID from '@analytics-services/api/openapi-client/model/DeveloperMetricID';
import FilterContributorsRequest from '@analytics-services/api/openapi-client/model/FilterContributorsRequest';
import FilterRepositoriesRequest from '@analytics-services/api/openapi-client/model/FilterRepositoriesRequest';
import ForSetCodeChecks from '@analytics-services/api/openapi-client/model/ForSetCodeChecks';
import ForSetDeployments from '@analytics-services/api/openapi-client/model/ForSetDeployments';
import ForSetDevelopers from '@analytics-services/api/openapi-client/model/ForSetDevelopers';
import ForSetPullRequests from '@analytics-services/api/openapi-client/model/ForSetPullRequests';
import InvitationLink from '@analytics-services/api/openapi-client/model/InvitationLink';
import PullRequestHistogramDefinition from '@analytics-services/api/openapi-client/model/PullRequestHistogramDefinition';
import PullRequestMetricID from '@analytics-services/api/openapi-client/model/PullRequestMetricID';
import ReleaseMetricID from '@analytics-services/api/openapi-client/model/ReleaseMetricID';
import ReleaseMetricsRequest from '@analytics-services/api/openapi-client/model/ReleaseMetricsRequest';
import { DatetimeService } from '@analytics-services/datetimeService';
import { featuresList, getFeature } from '@analytics-services/flags';
import { report as reportToSentry } from '@analytics-services/sentry';
import { IAccountDetails, IDeveloper, ITeam, LooseObject } from '@analytics-types/common';
import { FAKE_USERNAME } from '@common-pages/Settings/constants';
import { ApiType } from '@common-services/api/common/types/common';
import { DeploymentForType, DeploymentType } from '@common-services/api/common/types/deployments';
import { WithType } from '@common-services/api/common/types/deployments';
import { EnvironmentType } from '@common-services/api/common/types/environments';
import { ArrayService } from '@common-services/arrayService';
import { getOffset } from '@common-services/dateService';
import { DateService } from '@common-services/dateService';
import { processPR } from '@common-services/prHelpers';
import { ResponseHealthService } from '@common-services/response-health-service';
import {
  IResponseHealthLogItem,
  ResponseStatusType,
} from '@common-services/response-health-service/responseHealthService.types';

export const DEFAULT_METRICS_QUANTILES = [0, 0.95];

export const METRICS_JIRA_ENDPOINT = '/metrics/jira';
export const METRICS_PRS_ENDPOINT = '/metrics/pull_requests';
export const METRICS_RELEASES_ENDPOINT = '/metrics/releases';
export const METRICS_CHECKS_ENDPOINT = '/metrics/code_checks';
export const METRICS_DEVELOPERS_ENDPOINT = '/metrics/developers';

class APIError extends Error {
  constructor(message) {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APIError);
    }

    this.name = 'APIError';
  }
}
const delay = (t) => {
  return new Promise(function (resolve) {
    window.setTimeout(resolve, t);
  });
};

/*TODO: refactor with proper TS and split calls*/
export const withSentryCapture = (
  apiCall,
  message,
  rethrow = false,
  retries = 5,
  err = null,
  requestId = null
) => {
  // Gather the requests logs to ship
  let healthCheckItems: IResponseHealthLogItem[] = [];
  // Use the previous requestId coming from retry or create a new id
  const newRequestId = requestId || uuidv4();
  if (!retries) {
    if (rethrow) {
      return Promise.reject(err);
    } else {
      return null;
    }
  }

  return apiCall()
    .then((r) => {
      healthCheckItems.push({
        id: newRequestId,
        status: ResponseStatusType.SUCCESS,
        statusCode: 200,
        date: DateService.getCurrentTime(),
      });
      return r;
    })
    .catch((e) => {
      healthCheckItems.push({
        id: newRequestId,
        status: ResponseStatusType.FAIL,
        statusCode: e.status,
        date: DateService.getCurrentTime(),
      });
      let remainingRetries = 0;
      if (e instanceof Error) {
        reportToSentry(e);
        throw e;
      } else {
        if (e.status === 401) {
          window.location.href = '/logout';
          throw e;
        }

        remainingRetries = !e.response || e.status === 503 ? retries - 1 : 0;

        const errMsg = e.response
          ? `${message}: ${e.status} - ${e.statusText}`
          : `${message}: got no response from api (${e.error.message})`;
        const err = new APIError(errMsg);
        const sentryCtx: LooseObject = {
          extra: {
            request: e.request,
            err: e.error,
            remainingRetries,
          },
        };

        if (sentryCtx.extra.err.rawResponse && sentryCtx.extra.err.rawResponse.length >= 2000) {
          sentryCtx.extra.err.rawResponse = sentryCtx.extra.err.rawResponse.slice(0, 2000);
          sentryCtx.extra.err.croppedRawResponse = true;
        }

        if (e.response) {
          sentryCtx.extra = {
            ...sentryCtx.extra,
            status: e.status,
            statusText: e.statusText,
            body: e.body,
            response: e.response,
          };
        }
        // if status code is 502 or 503 report to Sentry only the last retry [DEV-1614]
        if (!_([502, 503]).includes(e.status) || remainingRetries === 0) {
          reportToSentry(err, sentryCtx);
          throw e;
        }
      }

      return delay(1000).then(() =>
        withSentryCapture(apiCall, message, rethrow, remainingRetries, e, newRequestId)
      );
    })
    .finally(() => {
      // Send a new healthCheckItems and run the health check.
      // It will send sentry report if required
      ResponseHealthService.runResponseHealthCheck(healthCheckItems);
    });
};

export const getUserDetails = async (token: string) => {
  const api = buildApi(token);
  const user = await withSentryCapture(() => api.getUser(), 'Cannot get user');

  const getAccountRepos = async (accountID, isAdmin) => {
    let reposets;
    try {
      reposets = await withSentryCapture(
        () => api.listReposets(Number(accountID)),
        'Cannot list reposets',
        true
      );
    } catch (err) {
      console.error(
        `Could not list reposets from account #${accountID}. Err#${err.body.status} ${err.body.type}. ${err.body.detail}`
      );
      return { id: Number(accountID), isAdmin, reposets: [] };
    }

    const reposetsContent = await Promise.all(
      reposets.map(async (reposetListItem) => {
        const reposetWithName = await withSentryCapture(
          () => api.getReposet(reposetListItem.id),
          'Cannot get reposet',
          true
        );
        return {
          ...reposetListItem,
          repos: reposetWithName.items,
          precomputed: reposetWithName.precomputed,
        };
      })
    );

    return { id: Number(accountID), isAdmin, reposets: reposetsContent };
  };

  const defaultAccountID = getDefaultAccountID(user.accounts);
  if (!defaultAccountID) {
    return null;
  }

  const [features, account, { defaultAccount, defaultReposet }] = await Promise.all([
    getFeatures(api, defaultAccountID),
    getAccountDetails(api, defaultAccountID),
    getAccountRepos(defaultAccountID, user.accounts[defaultAccountID].is_admin).then(
      (defaultAccount) => {
        const defaultReposet = defaultAccount.reposets?.find((reposet) => reposet.name === 'all');
        return {
          defaultAccount,
          defaultReposet: defaultReposet || { repos: [] },
        };
      }
    ),
  ]);

  // This is clearly a hack, but we really need it to set the header in buildApi().
  // window.ENV is written before loading React (by config.js), so
  // we should not update window.ENV here, especially as a side effect.
  const api_channel = getFeature(featuresList.api_channel, features);
  if (api_channel) {
    window.ENV.api.channel = api_channel.parameters;
  }

  return {
    user: {
      ...user,
      defaultAccount,
      defaultReposet,
    },
    account,
    features,
    isReady: !!defaultReposet.repos.length && defaultReposet.precomputed,
  };
};

const getDefaultAccountID = (accounts) => {
  if (!accounts) {
    return null;
  }

  const accounts_ = _(accounts).reduce(
    (result, value, key) => {
      if (value.is_admin) {
        result.isAdmin.push(key);
      } else {
        result.isNotAdmin.push(key);
      }
      return result;
    },
    { isAdmin: [], isNotAdmin: [] }
  );

  const adminAccounts = accounts_.isAdmin || [];
  const nonAdminAccounts = accounts_.isNotAdmin || [];

  return adminAccounts[0] || nonAdminAccounts[0];
};

export const acceptInvite = async (api: ApiType, inviteLink) => {
  const body = new InvitationLink(inviteLink);

  let check;
  try {
    check = await withSentryCapture(() => api.checkInvitation(body), 'Cannot check invitation');
  } catch (err) {
    throw new Error(`Error reading the invitation ${err.error.message}`);
  }

  if (!check.valid || !check.active) {
    const cause = !check.valid ? 'valid' : 'active';
    throw new Error(`Invitation is not ${cause}`);
  }

  try {
    await withSentryCapture(() => api.acceptInvitation(body), 'Cannot accept invitation');
  } catch (err) {
    throw new Error(err.body?.detail || 'Could not accept the invitation.');
  }

  return check;
};

export const getReleases = (
  api: ApiType,
  accountID: number,
  dateInterval: DatetimeService.Interval,
  inRepos,
  contributors = [],
  labels = [],
  excludedLabels = [],
  jira = {}
) => {
  if (!inRepos.length || !contributors.filter((c) => c).length) {
    return { data: [], include: { users: {} } };
  }
  const filter = new FilterReleasesRequest(
    accountID,
    dateInterval.from.toDate(),
    dateInterval.to.toDate()
  );
  filter.in = inRepos;
  filter.timezone = getOffset();
  const uniqueContributors = ArrayService.getUniqueValues(contributors);
  filter.with = {
    pr_author: uniqueContributors,
    releaser: uniqueContributors,
    commit_author: uniqueContributors,
  };
  filter.labels_include = labels;
  filter.labels_exclude = excludedLabels;
  filter.jira = jira;
  return withSentryCapture(() => api.filterReleases({ body: filter }), 'Cannot fetch releases');
};

export const getRepos = (
  token: string,
  userAccount: number,
  dateInterval: DatetimeService.Interval,
  repos,
  exclude_inactive
) => {
  const api = buildApi(token);
  const filter = new FilterRepositoriesRequest(
    userAccount,
    dateInterval.from.toDate(),
    dateInterval.to.toDate()
  );
  filter.in = repos;
  filter.timezone = getOffset();
  filter.exclude_inactive = exclude_inactive;

  return withSentryCapture(
    () => api.filterRepositories({ body: filter }),
    'Cannot fetch repos'
  ).then((repos) => [...repos]);
};

export const getContributors = async (
  token: string,
  userAccount: number,
  dateInterval: DatetimeService.Interval,
  repos,
  excludeInactive
) => {
  const api = buildApi(token);

  const { account } = await getUserDetails(token);

  const jiraStuff = account.jira
    ? await filterJIRAStuff(api, userAccount, dateInterval, excludeInactive, ['users', 'issues'])
    : null;

  const contribs = _(
    await fetchContributors(api, userAccount, dateInterval, { repositories: repos })
  )
    .filter(
      (c) => (c.updates?.prs || 0) + (c.updates?.reviewer || 0) + (c.updates?.commenter || 0) > 0
    )
    .map((c) => {
      const { updates, ...rest } = c;
      return rest;
    })
    .value();

  const pureJiraUsers = jiraStuff
    ? _(jiraStuff.users)
        .filter((u) => !contribs.find((c) => c.jira_user === u.name))
        .map((u) => ({
          avatar: u.avatar,
          jira_user: u.name,
          login: '',
          name: u.name,
        }))
        .value()
    : [];

  return _.union(contribs, pureJiraUsers);
};

export const getDevelopers = (api: ApiType, accountID: number): IDeveloper[] => {
  return api.getContributors(accountID);
};

export const createTeam = (
  api: ApiType,
  accountID: number,
  name: string,
  members: string[],
  parent: number
) => {
  return api.createTeam({ body: new TeamCreateRequest(accountID, name, members, parent) });
};

export const removeTeam = (api: ApiType, id: number) => {
  return api.deleteTeam(id);
};

export const updateTeam = (api: ApiType, id, name, members, parent) => {
  return api.updateTeam(id, { body: new TeamUpdateRequest(name, members, parent) });
};

export const getTeams = (api: ApiType, accountID: number): Promise<ITeam[]> => {
  return api.listTeams(accountID);
};

export const getInvitation = async (token: string, accountID: number) => {
  const api = buildApi(token);
  const invitation = await withSentryCapture(
    () => api.genUserInvitation(accountID),
    'Cannot get invitation link'
  );
  return invitation.url;
};

export const buildApi = (token: string) => {
  const client: any = new ApiClient();
  client.authentications.bearerAuth.accessToken = token;
  client.basePath = window.ENV.api.basePath;
  client.defaultHeaders = {
    'X-Athenian-Channel': window.ENV.api.channel || 'stable',
  };
  client.timeout = 5 * 60 * 1000;

  return new DefaultApi(client);
};

export const fetchContributors = async (
  api: ApiType,
  accountID: number,
  dateInterval: DatetimeService.Interval,
  filter = { repositories: [] }
): Promise<IDeveloper[]> => {
  filter.repositories = filter.repositories || [];

  const filter_ = new FilterContributorsRequest(
    accountID,
    dateInterval.from.toDate(),
    dateInterval.to.toDate()
  );
  filter_.in = filter.repositories;
  filter_.timezone = getOffset();
  filter_.as = []; // get avatar from authors & reviewers

  return withSentryCapture(
    () => api.filterContributors({ body: filter_ }),
    'Cannot fetch contributors'
  );
};

const prepareFetchFilterPRsRequest = (
  accountID: number,
  dateInterval: DatetimeService.Interval,
  excludeInactive,
  filter = {
    repositories: [],
    developers: [],
    stages: [],
    labels_include: [],
    labels_exclude: [],
    jira: {},
  },
  limit = null,
  updatedFrom = null,
  updatedTo = null
) => {
  filter.repositories = filter.repositories || [];
  filter.developers = _(filter.developers || [])
    .filter((v) => v.login)
    .map((v) => v.login)
    .value();
  filter.stages = filter.stages || ['wip', 'reviewing', 'merging', 'releasing', 'done'];
  filter.labels_include = filter.labels_include || [];
  filter.labels_exclude = filter.labels_exclude || [];

  const filter_ = new FilterPullRequestsRequest(
    accountID,
    dateInterval.from.toDate(),
    dateInterval.to.toDate(),
    null,
    null
  );

  if (filter.repositories.length > 0) {
    filter_.in = filter.repositories;
  }

  filter_.stages = filter.stages;
  if (filter.developers.length > 0) {
    filter_.with = {
      author: filter.developers,
    };
  }

  filter_.timezone = getOffset();
  filter_.exclude_inactive = excludeInactive;
  filter_.labels_include = filter.labels_include;
  filter_.labels_exclude = filter.labels_exclude;
  if (limit) filter_.limit = limit;
  if (updatedFrom) filter_.updated_from = updatedFrom;
  if (updatedTo) filter_.updated_to = updatedTo;
  filter_.jira = filter.jira;

  return filter_;
};

export const fetchFilteredPRsPaginationPlan = async (
  api: ApiType,
  accountID: number,
  dateInterval: DatetimeService.Interval,
  excludeInactive,
  filter = {
    repositories: [],
    developers: [],
    stages: [],
    labels_include: [],
    labels_exclude: [],
    jira: {},
  },
  batch = 100
) => {
  if (!filter.repositories?.length || !filter.developers?.filter((d) => d.login).length) {
    return {
      updated: [],
    };
  }
  filter.developers = filter.developers.filter((d) => d.login);

  const filter_ = prepareFetchFilterPRsRequest(accountID, dateInterval, excludeInactive, filter);
  const paginationPlanRequest = new PaginatePullRequestsRequest(batch, filter_);
  return withSentryCapture(
    () => api.paginatePrs({ body: paginationPlanRequest }),
    'Cannot fetch paginated pull requests',
    true
  );
};

export const fetchFilteredPRs = async (
  api: ApiType,
  accountID: number,
  dateInterval: DatetimeService.Interval,
  excludeInactive,
  filter = {
    repositories: [],
    developers: [],
    stages: [],
    labels_include: [],
    labels_exclude: [],
    jira: {},
  },
  limit,
  updatedFrom,
  updatedTo
) => {
  if (!filter.repositories?.length || !filter.developers?.filter((d) => d.login).length) {
    return {
      prs: [],
      users: {},
    };
  }

  filter.developers = filter.developers.filter((d) => d.login);

  const filter_ = prepareFetchFilterPRsRequest(
    accountID,
    dateInterval,
    excludeInactive,
    filter,
    limit,
    updatedFrom,
    updatedTo
  );

  const prs = await withSentryCapture(
    () => api.filterPrs({ filterPullRequestsRequest: filter_ }),
    'Cannot fetch filtered pull requests',
    true
  );

  return {
    prs: prs.data.map(processPR),
    users: prs?.include?.users || {},
  };
};

export const getLabels = (token: string, accountID: number, repos = []) => {
  const api = buildApi(token);
  const body = new FilterLabelsRequest(accountID);
  body.repositories = repos;

  return withSentryCapture(() => api.filterLabels({ body }), 'Cannot fetch labels', true);
};

const PrMetricsIDs = new PullRequestMetricID();

export const fetchPRsMetrics = async (
  api: ApiType,
  accountID: number,
  granularities,
  dateInterval: DatetimeService.Interval,
  metrics = [],
  filter: any = {
    repositories: [],
    with: {},
    withgroups: [],
    labels_include: [],
    labels_exclude: [],
    jira: {},
  },
  groupBy,
  exclude_inactive,
  quantiles = DEFAULT_METRICS_QUANTILES
) => {
  const transformFunc = (result, v, k) => {
    result[k] = _(v)
      .map((v) => (_(v).isString() ? v : v.login))
      .filter((v) => !!v)
      .value();
  };

  filter.repositories = filter.repositories || [];
  filter.with = _(filter.with || {})
    .transform(transformFunc)
    .value();

  filter.with.author = ArrayService.getUniqueValues(filter.with.author) || [];

  if (filter.withgroups) {
    filter.withgroups = _(filter.withgroups)
      .map((t) => _(t).transform(transformFunc, {}).value())
      .value();
  }

  if (
    !filter.repositories?.length ||
    (!filter.with?.author?.length && !filter.withgroups?.length)
  ) {
    const emptyResponse = new CalculatedPullRequestMetrics(
      [],
      metrics.map((m) => PrMetricsIDs[m]),
      dateInterval.from.toDate(),
      dateInterval.to.toDate(),
      granularities,
      exclude_inactive
    );
    return emptyResponse;
  }

  const forSet = buildForSetPullRequests(filter, groupBy);
  const body = new PullRequestMetricsRequest(
    forSet,
    metrics.map((m) => PrMetricsIDs[m]),
    dateInterval.from.toDate(),
    dateInterval.to.toDate(),
    granularities,
    exclude_inactive,
    accountID
  );

  body.timezone = getOffset();
  if (quantiles) {
    body.quantiles = quantiles;
  }

  return withSentryCapture(
    () => api.calcMetricsPrs(body),
    'Cannot fetch pull requests metrics',
    true
  );
};

// This API method is created specifically for Allocation page to fetch with several ForSets,
// so please adjust it correspondingly if it's needed in other places too
export const fetchPRsMetricsForSet = async (
  api: ApiType,
  accountID: number,
  granularities,
  dateInterval: DatetimeService.Interval,
  metrics = [],
  filters = [],
  exclude_inactive,
  quantiles = DEFAULT_METRICS_QUANTILES
) => {
  if (!filters?.length || !metrics?.length) {
    const emptyResponse = new CalculatedPullRequestMetrics(
      [],
      metrics.map((m) => PrMetricsIDs[m]),
      dateInterval.from.toDate(),
      dateInterval.to.toDate(),
      granularities,
      exclude_inactive
    );
    return emptyResponse;
  }

  const body = new PullRequestMetricsRequest(
    filters,
    metrics.map((m) => PrMetricsIDs[m]),
    dateInterval.from.toDate(),
    dateInterval.to.toDate(),
    granularities,
    exclude_inactive,
    accountID
  );

  body.timezone = getOffset();
  if (quantiles) {
    body.quantiles = quantiles;
  }

  return withSentryCapture(
    () => api.calcMetricsPrs(body),
    'Cannot fetch pull requests metrics',
    true
  );
};

export const fetchCommitsBypassingPRs = (
  api: ApiType,
  accountID: number,
  dateInterval: DatetimeService.Interval,
  repositories = [],
  contributors = [],
  type
) => {
  if (!repositories.length || !contributors.filter((c) => c.login).length) {
    return [];
  }

  const body = new FilterCommitsRequest(
    accountID,
    dateInterval.from.toDate(),
    dateInterval.to.toDate(),
    repositories,
    type
  );

  body.with_author = contributors.filter((a) => a.login).map((a) => a.login);
  return withSentryCapture(
    () => api.filterCommits({ body: { ...body, only_default_branch: true } }),
    'Cannot fetch commits',
    true
  );
};

export const fetchDevsMetrics = async (
  api: ApiType,
  accountID: number,
  granularities = [],
  dateInterval: DatetimeService.Interval,
  metrics = [],
  filter: {
    repositories?: any[];
    developers?: any[];
    labels_include?: any[];
    labels_exclude?: any[];
    jira?: LooseObject;
  } = { repositories: [], developers: [], labels_include: [], labels_exclude: [], jira: {} },
  groupBy = null
) => {
  filter.repositories = filter.repositories || [];
  filter.developers = _(filter.developers || [])
    .filter((v) => v.login)
    .uniqBy('login')
    .map((v) => v.login)
    .value();

  const metricIDs = new DeveloperMetricID();

  if (!filter.repositories?.length || !filter.developers?.length) {
    return new CalculatedDeveloperMetrics(
      [],
      metrics.map((m) => metricIDs[m]),
      dateInterval.from.toDate(),
      dateInterval.to.toDate(),
      null
    );
  }

  const forSet = buildForSetDevelopers(filter, groupBy);
  const body = new DeveloperMetricsRequest(
    forSet,
    metrics.map((m) => metricIDs[m]),
    dateInterval.from.toDate(),
    dateInterval.to.toDate(),
    accountID,
    granularities
  );

  body.timezone = getOffset();
  return withSentryCapture(
    () => api.calcMetricsDevelopers(body),
    'Cannot fetch developers metrics'
  );
};

const buildForSetPullRequests = (filter, groupBy = null) =>
  _buildForSet(filter, groupBy, ForSetPullRequests);

const buildForSetDevelopers = (filter, groupBy) => _buildForSet(filter, groupBy, ForSetDevelopers);

const buildForSetCodeChecks = (filter, groupBy) => _buildForSet(filter, groupBy, ForSetCodeChecks);

const buildForSetDeployments = (filter, groupBy) =>
  _buildForSet(filter, groupBy, ForSetDeployments);

const _buildForSet = (filter, groupBy, type) => {
  const forSet = [];
  if (!groupBy) {
    forSet.push(type.constructFromObject(filter));
  } else if (!['repositories', 'developers'].includes(groupBy)) {
    throw new Error('Invalid groupby');
  } else if (groupBy === 'repositories' && [ForSetPullRequests, ForSetCodeChecks].includes(type)) {
    // The `/metrics/developers` endpoint does not support the `repogroups` parameter
    forSet.push(
      type.constructFromObject({
        ...filter,
        repogroups: _.range(filter.repositories.length).map((i) => [i]),
      })
    );
  } else {
    for (const g of filter[groupBy]) {
      const f = _.clone(filter);
      f[groupBy] = [g];
      forSet.push(type.constructFromObject(f));
    }
  }

  return forSet;
};

export const fetchReleaseSettings = async (api: ApiType, accountID: number) => {
  const config = await withSentryCapture(
    () => api.listReleaseMatchSettings(accountID),
    'Cannot fetch release settings'
  );
  return [config];
};

export const saveRepoSettings = async (
  api: ApiType,
  accountId: number,
  repos,
  strategy,
  branchPattern,
  tagPattern
) => {
  const repoSettings = new ReleaseMatchRequest(accountId, repos, strategy);
  repoSettings.branches = branchPattern;
  repoSettings.tags = tagPattern;

  return withSentryCapture(
    () => api.setReleaseMatch({ body: repoSettings }),
    'Cannot set release match',
    true
  );
};

// Get the backend versions {key: value} from /v1/versions. Auth is not required.
export const fetchVersions = async () => {
  const api = buildApi(null);
  const versions = await withSentryCapture(() => api.getVersions(), 'Cannot fetch versions');
  const webappRelease = window.META?.release;
  if (webappRelease) {
    versions['webapp'] = webappRelease.startsWith('v') ? webappRelease.substring(1) : webappRelease;
  }
  return versions;
};

export const getFeatures = async (api: ApiType, accountID: number) => {
  return await withSentryCapture(
    () => api.getAccountFeatures(accountID),
    'Cannot fetch enabled product features'
  );
};

export const setFeatures = async (api: ApiType, accountID: number, payload) => {
  if (!_.isArray(payload)) return;
  payload.forEach((item) => {
    if (!item?.name || !item.parameters) return;
  });
  return await withSentryCapture(
    () => api.setAccountFeatures(accountID, { body: payload }),
    'Cannot set product features'
  );
};

export const getAccountDetails = async (
  api: ApiType,
  accountID: number
): Promise<IAccountDetails> => {
  return await withSentryCapture(
    () => api.getAccountDetails(accountID),
    'Cannot fetch account details'
  );
};

export class InstallationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'Installation Error';

    // TODO (dpordomingo) : once we have ESlint...
    // https://github.com/babel/eslint-plugin-babel/issues/185#issuecomment-569996329
    // https://github.com/eslint/eslint/issues/11045#issuecomment-436685184

    // eslint-disable-next-line
    Error.captureStackTrace?.(this, InstallationError);
  }
}

export const getProgress = async (api: ApiType, accountId: number) => {
  const progress = await withSentryCapture(
    () => api.evalMetadataProgress(accountId),
    'Cannot fetch installation progress',
    true
  );

  return estimateProgress(progress);
};

const estimateProgress = (progress) => {
  if (!Array.isArray(progress.tables)) {
    throw new InstallationError('Could not parse the installation progress');
  }

  const { fetched, total } = progress.tables.reduce(
    (acc, table) => ({
      fetched: acc.fetched + (table.fetched || 0),
      total: acc.total + (table.total || 0),
    }),
    { fetched: 0, total: 0 }
  );

  if (total === 0) {
    throw new InstallationError('Could not calculate the total number of tables');
  }

  return {
    finished: progress.finished_date,
    value: (100 * fetched) / total,
  };
};

export const HISTOGRAM_LOG_SCALE = HistogramScale.constructFromObject('log');
export const HISTOGRAM_LINEAR_SCALE = HistogramScale.constructFromObject('linear');
export const HISTOGRAM_DEFAULT_BINS = 15;

export const fetchHistogram = async (
  api: ApiType,
  accountID: number,
  dateInterval: DatetimeService.Interval,
  metrics = [],
  filter: any = { repositories: [], with: {}, labels_include: [], labels_exclude: [] },
  exclude_inactive = true,
  scale = HISTOGRAM_LOG_SCALE,
  bins = HISTOGRAM_DEFAULT_BINS,
  ticks = null,
  jira = {}
) => {
  filter.repositories = filter.repositories || [];
  filter.labels_include = filter.labels_include || [];
  filter.labels_exclude = filter.labels_exclude || [];
  filter.with = _(filter.with || {})
    .transform((result, v, k) => {
      result[k] = _(v)
        .filter((v) => v.login)
        .uniqBy('login')
        .map((v) => v.login)
        .value();
    })
    .value();
  filter.jira = jira;

  const metricIDs = new PullRequestMetricID();
  const quantiles = [0.05, 1];

  if (!filter.repositories?.length || !filter.with?.author?.length) {
    const emptyResponse = metrics.reduce((acc, metric) => {
      acc[metric] = new CalculatedPullRequestHistogram(
        [],
        metrics.map((m) => metricIDs[m]),
        scale,
        [],
        [],
        {}
      );
      return acc;
    }, {});
    return emptyResponse;
  }

  const forSet = buildForSetPullRequests(filter);
  const histograms: any = metrics.map((m) => {
    const def = new PullRequestHistogramDefinition(metricIDs[m]);
    if (!ticks) {
      def.scale = scale;
      def.bins = bins;
    } else {
      def.ticks = ticks;
    }

    return def;
  });
  const body = new PullRequestHistogramsRequest(
    forSet,
    histograms,
    dateInterval.from.toDate(),
    dateInterval.to.toDate(),
    exclude_inactive,
    accountID
  );

  body.timezone = getOffset();
  body.quantiles = quantiles;

  const data = await withSentryCapture(
    () => api.calcHistogramPrs(body),
    'Cannot fetch histogtam metrics',
    true
  );

  return metrics.reduce((acc, metricName) => {
    // Response of '/histogram/prs' is not sorted by the requested 'metrics', so it's needed to 'find'
    const histogram = data.find((h) => h.metric === PrMetricsIDs[metricName]);
    const { ticks, bucketCenters } = histogram.ticks.reduce(
      (acc, tick, idxTick, srcTicks) => {
        const parsedTick = parseHistogramTick(metricName, tick);
        acc.ticks.push(parsedTick);
        if (idxTick <= srcTicks.length - 2) {
          const nextTick = parseHistogramTick(metricName, srcTicks[idxTick + 1]);
          acc.bucketCenters.push((parsedTick + nextTick) / 2);
        }
        return acc;
      },
      { ticks: [], bucketCenters: [] }
    );

    acc[metricName] = {
      empty: !histogram.frequencies.find((v) => v),
      frequencies: histogram.frequencies,
      ticks,
      bucketCenters,
      interquartile: histogram.interquartile,
    };

    return acc;
  }, {});
};

const timeFormatRegExp = /^\d+s$/;
const parseHistogramTick = (metric, tick) => {
  if (timeFormatRegExp.test(tick)) {
    return parseInt(tick) * 1000;
  }

  if (typeof tick !== 'number') {
    throw new Error(`Unexpected histogram tick format "${tick}"`);
  }

  // XXX: There is a limitation in the generated API client, not sending TimeDurations but a Number
  // This means that all durations metrics need to be added in this list.
  if (
    [
      'wip-time',
      'review-time',
      'merging-time',
      'release-time',
      'lead-time',
      'cycle-time',
      'wait-first-review-time',
      'deployment-time',
    ].includes(metric)
  ) {
    return tick * 1000;
  }

  return tick;
};

export const GROUP_BY_REPOS = Symbol.for('groupByRepo');
export const AGGREGATE_REPOS = Symbol.for('aggregateRepos');
export const fetchReleasesMetrics = async (
  api: ApiType,
  accountID: number,
  granularities = [],
  dateInterval: DatetimeService.Interval,
  metrics = [],
  repositories = [],
  contributors = [],
  labels = [],
  excludedLabels = [],
  jira = {},
  behavior = AGGREGATE_REPOS
) => {
  const metricIDs = new ReleaseMetricID();
  metrics = metrics.map((m) => metricIDs[m]);

  if (!repositories.length || !contributors.length) {
    return [];
  }

  const forFilter =
    behavior === AGGREGATE_REPOS
      ? [repositories]
      : repositories.reduce((acc, repo) => acc.concat([[repo]]), []);

  const body = new ReleaseMetricsRequest(
    forFilter,
    metrics,
    dateInterval.from.toDate(),
    dateInterval.to.toDate(),
    granularities,
    accountID
  );

  body.timezone = getOffset();
  if (contributors.length > 0 && _(contributors[0]).isObject()) {
    body.with = contributors;
  } else {
    const uniqueContibutors = ArrayService.getUniqueValues(contributors);
    body.with = [
      {
        pr_author: uniqueContibutors,
        releaser: uniqueContibutors,
        commit_author: uniqueContibutors,
      },
    ];
  }
  body.labels_include = labels;
  body.labels_exclude = excludedLabels;
  body.jira = jira;
  return withSentryCapture(
    () => api.calcMetricsReleases(body),
    'Cannot fetch releases metrics',
    true
  );
};

/**
 * Takes a response from /metrics/prs or /metrics/releases, with some granularities [g1, g2] and metrics [m1, m2]
 * and returns an unfolded structure.
 * @param {Array} metrics Response from /metrics/prs or /metrics/releases
 *  [{
 *    granularity: g1,
 *    metrics: [m1, m2],
 *    values: [{date: d0, values: [d0_g1_m1, d0_g1_m2]}, {date: d1, values: [d1_g1_m1, d1_g1_m2]}, ...],
 *  }, {
 *    granularity: all,
 *    metrics: [m1, m2],
 *    values: [{date: d0, values: [d0_g2_m1, d0_g2_m2]}],
 *  }]
 * @param {Array.<String>} metricsAliases Defines the metric names to be returned, following the same order than in the passed metrics response
 *  [M1, M2]
 * @return {Object} Unfolded structure
 *  {
 *    g1: {
 *      M1: [{
 *        date: d0,
 *        value: d0_g1_m1,
 *      },{
 *        date: d1,
 *        value: d1_g1_m1,
 *      }],
 *      M2: [{
 *        date: d0,
 *        value: d0_g1_m2,
 *      },{
 *        date: d1,
 *        value: d1_g1_m2,
 *      }],
 *    },
 *    all: {
 *      m1: d0_g2_m1,
 *      m2: d0_g2_m2,
 *    }
 *  }
 */
export const unwrap = (metrics, metricsAliases, isPrevValue = false) => {
  if (!metrics.length) return {};

  if (!metricsAliases || metricsAliases.length !== metrics?.[0]?.values?.[0]?.values.length) {
    throw new Error('Can not unwrap metrics response without a valid metricsAliases definition');
  }

  return metrics.reduce((acc, granularMetrics) => {
    acc[granularMetrics.granularity] = metricsAliases.reduce((accm, metric, i) => {
      if (granularMetrics.granularity === 'all') {
        if (isPrevValue) {
          accm[metric] =
            granularMetrics.prevValues[0].values[i] !== undefined
              ? granularMetrics.prevValues[0].values[i]
              : 0;
        } else {
          accm[metric] =
            granularMetrics.values[0].values[i] !== undefined
              ? granularMetrics.values[0].values[i]
              : 0;
        }
      } else {
        accm[metric] = granularMetrics.values.map((v) => ({
          date: v.date,
          value: v.values[i] !== undefined ? v.values[i] : 0,
        }));
      }
      return accm;
    }, {});
    return acc;
  }, {});
};

export const fetchCIMetrics = (
  api: ApiType,
  accountID: number,
  dateInterval: DatetimeService.Interval,
  granularities = [],
  metrics = [],
  repositories = [],
  contributors = [],
  labels = [],
  excludedLabels = [],
  jira = {},
  groupBy = null,
  forSet = null
) => {
  if (!forSet) {
    if (!repositories.length || !contributors.filter((c) => c.login).length) {
      return [];
    }

    const _for = {
      repositories,
      pushers: ArrayService.getUniqueValues(
        contributors.filter((a) => a.login).map((a) => a.login)
      ),
      labels_include: labels,
      labels_exclude: excludedLabels,
      jira,
    };
    forSet = buildForSetCodeChecks(_for, groupBy);
  }

  const body = new CodeCheckMetricsRequest(
    forSet,
    metrics,
    dateInterval.from.toDate(),
    dateInterval.to.toDate(),
    granularities,
    accountID
  );

  body.timezone = getOffset();
  body.quantiles = [0, 0.95];
  body.split_by_check_runs = false;

  return withSentryCapture(() => api.calcMetricsCodeChecks(body), 'Cannot fetch CI metrics', true);
};

export const fetchCodeChecks = (
  api: ApiType,
  accountID: number,
  dateInterval: DatetimeService.Interval,
  repositories = [],
  contributors = [],
  labels = [],
  excludedLabels = [],
  jira = {}
) => {
  if (!repositories.length || !contributors.filter((c) => c.login).length) {
    return [];
  }

  const body = new FilterCodeChecksRequest(
    repositories,
    dateInterval.from.toDate(),
    dateInterval.to.toDate(),
    accountID
  );

  body.timezone = getOffset();
  body.quantiles = [0, 0.95];
  body.triggered_by = _(contributors)
    .filter((c) => c.login)
    .map('login')
    .value();
  body.labels_include = labels;
  body.labels_exclude = excludedLabels;
  body.jira = jira;

  return withSentryCapture(() => api.filterCodeChecks({ body }), 'Cannot fetch code checks', true);
};

// TODO: remove this method after Alloacation section uses correct work types in Selector
export const getWorkTypes = async (
  token,
  userAccount,
  dateInterval: DatetimeService.Interval,
  contributors,
  excludeInactive,
  includeNullContributor,
  includeFakeContributor
) => {
  const api = buildApi(token);

  const jiraAssignees = _(contributors).map('jira_user').filter().value();
  if (includeNullContributor) {
    jiraAssignees.push(null);
  }
  if (includeFakeContributor) {
    jiraAssignees.push(FAKE_USERNAME);
  }

  const fetchedData = await filterJIRAStuff(
    api,
    userAccount,
    dateInterval,
    excludeInactive,
    ['issues', 'labels', 'issue_types'],
    { assignees: jiraAssignees, reporters: [], commenters: [] }
  );

  const allIssueTypes = fetchedData.issue_types?.filter((v) => !v.is_subtask && !v.is_epic) || [];

  // as there could be duplicated names of issue types, we deduplicate them and sum up their count
  const issueTypesObj = {};
  allIssueTypes.forEach((item) => {
    if (issueTypesObj[item.name]) {
      issueTypesObj[item.name].count += item.count;
    } else {
      issueTypesObj[item.name] = item;
    }
  });

  const issueTypes = Object.values(issueTypesObj).sort((a: any, b: any) =>
    a.count > b.count ? -1 : 1
  );

  return issueTypes;
};

export const setWorkType = (
  api: ApiType,
  accountID: number,
  name: string,
  color: string,
  rules = []
) => {
  const body = {
    account: accountID,
    work_type: {
      name,
      color: color?.indexOf('#') === 0 ? color.substring(1) : color,
      rules,
    },
  };
  return withSentryCapture(() => api.setWorkType({ body }), 'Cannot add/update work type', true);
};

export const fetchWorkTypes = (api: ApiType, accountID: number) => {
  return withSentryCapture(() => api.listWorkTypes(accountID), 'Cannot fetch work types', true);
};

export const removeWorkType = (api: ApiType, accountID: number, name: string) => {
  const body = {
    account: accountID,
    name,
  };
  return withSentryCapture(() => api.deleteWorkType({ body }), 'Cannot fetch work types', true);
};

export const removeUser = (api: ApiType, accountID: number, userID: string) => {
  const body = {
    account: accountID,
    user: userID,
    status: 'banished',
  };
  return withSentryCapture(() => api.changeUser({ body }), 'Cannot remove user', true);
};

export const changeUserRole = (api: ApiType, accountID: number, userID: string, role: string) => {
  const body = {
    account: accountID,
    user: userID,
    status: role,
  };
  return withSentryCapture(() => api.changeUser({ body }), 'Cannot remove user', true);
};

export const fetchEnvironments = (
  api: ApiType,
  accountID: number,
  dateInterval: DatetimeService.Interval,
  repositories: string[]
): Promise<EnvironmentType[]> => {
  const body = {
    account: accountID,
    date_from: dateInterval.from.toDate(),
    date_to: dateInterval.to.toDate(),
    repositories,
    timezone: getOffset(),
  };
  return withSentryCapture(
    () => api.filterEnvironments({ body }),
    'Cannot fetch environments',
    true
  );
};

export const getUniqueFiltersWith = (filtersWith: WithType): WithType => {
  return Object.entries(filtersWith).reduce(
    (a, c) => ({
      ...a,
      [c[0]]: ArrayService.getUniqueValues(c[1]),
    }),
    {}
  );
};

export const fetchDeploymentMetrics = (
  api: ApiType,
  accountID: number,
  dateInterval: DatetimeService.Interval,
  granularities: string[],
  metrics: string[] = [],
  filter: DeploymentForType = {
    environments: [],
    jira: {},
    pr_labels_exclude: [],
    pr_labels_include: [],
    repogroups: [],
    repositories: [],
    with: {},
    withgroups: [],
  },
  groupBy = null,
  quantiles = DEFAULT_METRICS_QUANTILES
): Promise<DeploymentType[]> => {
  const uniqueFilters = {
    ...filter,
    with: getUniqueFiltersWith(filter.with),
  };
  const forSet = buildForSetDeployments(uniqueFilters, groupBy);
  const DepMetricIDs = new DeploymentMetricID();
  const body = new DeploymentMetricsRequest(
    forSet,
    metrics.map((m) => DepMetricIDs[m]),
    dateInterval.from.toDate(),
    dateInterval.to.toDate(),
    granularities,
    accountID
  );
  body.timezone = getOffset();
  if (quantiles) {
    body.quantiles = quantiles;
  }

  return withSentryCapture(
    () => api.calcMetricsDeployments(body),
    'Cannot fetch deployment metrics',
    true
  );
};
