import React from 'react';

import Search from '@analytics-components/Search';
import JIRALogo from '@assets/icons/jira.svg';

import { IJiraIdentityMatcher } from './teamEditAccordionRow.types';

const JIRAIdentityMatcher: React.FC<IJiraIdentityMatcher> = React.memo(
  ({ member, jiraIdentities, onSave, onReset }) => {
    const jiraMapped = member.jira_user;
    return (
      <>
        <div>
          <img
            src={JIRALogo}
            alt="JIRA logo"
            css={{
              width: '26px',
              height: '26px',
            }}
          />
        </div>

        <Search
          value={jiraMapped ? { label: jiraMapped } : null}
          placeholder="Find a user..."
          options={jiraIdentities}
          maxVisible={5}
          onSave={(jiraIdentity) => onSave(member, jiraIdentity)}
          onReset={() => onReset(member)}
        />
      </>
    );
  }
);

export { JIRAIdentityMatcher };
