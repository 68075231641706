import _ from 'lodash';

import { withSentryCapture, DEFAULT_METRICS_QUANTILES } from '@analytics-services/api';
import { HISTOGRAM_LOG_SCALE, HISTOGRAM_DEFAULT_BINS } from '@analytics-services/api';
import {
  FilterJIRAStuff,
  JIRAFilterWith,
  JIRAHistogramDefinition,
  JIRAHistogramsRequest,
  JIRAMetricID,
  JIRAMetricsRequest,
  SetMappedJIRAIdentitiesRequest,
} from '@analytics-services/api/openapi-client';
import { DatetimeService } from '@analytics-services/datetimeService';
import { IJiraIdentity } from '@analytics-types/common';
import { ApiType } from '@common-services/api/common/types/common';
import { ArrayService } from '@common-services/arrayService';
import { getOffset } from '@common-services/dateService';

const JIRAMetricsIDs = new JIRAMetricID();

const fetchJIRAidentities = async (api: ApiType, accountID: number): Promise<IJiraIdentity[]> => {
  return withSentryCapture(
    () => api.getJiraIdentities(accountID),
    'Cannot fetch jira identities',
    true
  );
};

const updateJIRAidentity = async (api, accountID, githubId, jiraId) => {
  const body = new SetMappedJIRAIdentitiesRequest(accountID, [
    {
      // @ts-expect-error: some types are broken in the autogenerated code
      developer_id: githubId,
      jira_name: jiraId,
    },
  ]);
  return withSentryCapture(
    () => api.setJiraIdentities({ body }),
    'Cannot update jira identities',
    true
  );
};

const fetchJIRAMetrics = async (
  api,
  accountID,
  granularities,
  dateInterval: DatetimeService.Interval,
  metrics = [],
  exclude_inactive,
  filter = {
    types: [],
    priorities: [],
    labels: [],
    projects: [],
    epics: [],
  },
  // TODO: Replace the `any` type
  with_: any = {
    assignees: [],
    reporters: [],
    commenters: [],
  },
  group_by_jira_label = false,
  quantiles = DEFAULT_METRICS_QUANTILES
) => {
  filter.types = filter.types || [];
  filter.priorities = filter.priorities || [];
  filter.labels = filter.labels || [];
  filter.projects = filter.projects || null;
  filter.epics = filter.epics || [];

  if (!_(with_).isArray()) {
    with_ = [with_];
  }

  const bodyWith = _(with_)
    .map((w) =>
      JIRAFilterWith.constructFromObject(
        {
          assignees: ArrayService.getUniqueValues(w.assignees) || [],
          reporters: ArrayService.getUniqueValues(w.reporters) || [],
          commenters: ArrayService.getUniqueValues(w.commenters) || [],
        },
        null
      )
    )
    .value();

  with_.assignees = ArrayService.getUniqueValues(with_.assignees) || [];
  with_.reporters = ArrayService.getUniqueValues(with_.reporters) || [];
  with_.commenters = ArrayService.getUniqueValues(with_.commenters) || [];

  const parsedGranularities = _(granularities)
    .map((g) => {
      if ((_(g).isObject() && g.label === 'auto') || g === 'auto') {
        const minGranularity = g === 'auto' ? 'day' : g.min;
        const customGranularity = dateInterval.calculateGranularity(minGranularity);
        const customAlignedGranularity = `aligned ${customGranularity}`;
        return customGranularity === 'month' ? customAlignedGranularity : customGranularity;
      } else {
        return g;
      }
    })
    .value();

  const body = new JIRAMetricsRequest(
    accountID,
    dateInterval.from.toDate(),
    dateInterval.to.toDate(),
    exclude_inactive,
    metrics.map((m) => JIRAMetricsIDs[m]),
    parsedGranularities
  );

  body.timezone = getOffset();
  body.quantiles = quantiles;
  body.types = filter.types;
  body.priorities = filter.priorities;
  body.epics = filter.epics;
  body.labels_include = filter.labels;
  if (filter.projects) {
    body.projects = filter.projects;
  }
  body.with = bodyWith;
  body.group_by_jira_label = group_by_jira_label;

  return withSentryCapture(
    () => api.calcMetricsJiraLinear(body),
    'Cannot fetch jira metrics',
    true
  );
};

const fetchJIRAHistograms = async (
  api,
  accountID,
  dateInterval: DatetimeService.Interval,
  metrics = [],
  exclude_inactive,
  filter = {
    types: [],
    priorities: [],
    epics: [],
  },
  with_ = {
    assignees: [],
    reporters: [],
    commenters: [],
  },
  scale = HISTOGRAM_LOG_SCALE,
  bins = HISTOGRAM_DEFAULT_BINS,
  ticks,
  quantiles = DEFAULT_METRICS_QUANTILES
) => {
  filter.types = filter.types || [];
  filter.priorities = filter.priorities || [];

  with_.assignees = ArrayService.getUniqueValues(with_.assignees) || [];
  with_.reporters = ArrayService.getUniqueValues(with_.reporters) || [];
  with_.commenters = ArrayService.getUniqueValues(with_.commenters) || [];

  const histograms = _(metrics)
    .map((m) => {
      const def = new JIRAHistogramDefinition(JIRAMetricsIDs[m]);
      if (!ticks) {
        def.scale = scale;
        def.bins = bins;
      } else {
        def.ticks = ticks;
      }

      return def;
    })
    .value();

  const body = new JIRAHistogramsRequest(
    // @ts-expect-error: some types are broken in the autogenerated code
    histograms,
    dateInterval.from.toDate(),
    dateInterval.to.toDate(),
    exclude_inactive,
    accountID
  );

  body.timezone = getOffset();
  body.types = filter.types;
  body.priorities = filter.priorities;
  body.quantiles = quantiles;
  body.with = [JIRAFilterWith.constructFromObject(with_, null)];

  return withSentryCapture(() => api.calcHistogramJira(body), 'Cannot fetch jira histograms', true);
};

const filterJIRAStuff = async (
  api,
  accountID,
  dateInterval: DatetimeService.Interval,
  exclude_inactive,
  what = [],
  // TODO: Replace the `any` types
  with_: {
    assignees?: any[];
    reporters?: any[];
    commenters?: any[];
  } = {
    assignees: [],
    reporters: [],
    commenters: [],
  },
  types = []
) => {
  with_.assignees = ArrayService.getUniqueValues(with_.assignees) || [];
  with_.reporters = ArrayService.getUniqueValues(with_.reporters) || [];
  with_.commenters = ArrayService.getUniqueValues(with_.commenters) || [];

  const body = new FilterJIRAStuff(
    accountID,
    dateInterval && dateInterval.from ? dateInterval.from.toDate() : null,
    dateInterval && dateInterval.to ? dateInterval.to.toDate() : null,
    exclude_inactive
  );

  body.types = types;
  body.return = what;
  body.with = JIRAFilterWith.constructFromObject(with_, null);
  body.timezone = getOffset();
  return withSentryCapture(() => api.filterJiraStuff({ body }), 'Cannot fetch jira stuff', true);
};

const getJiraProjects = (api, accountID) => {
  return withSentryCapture(
    () => api.getJiraProjects(accountID),
    'Cannot fetch Jira projects',
    true
  );
};

export {
  fetchJIRAidentities,
  updateJIRAidentity,
  filterJIRAStuff,
  fetchJIRAMetrics,
  fetchJIRAHistograms,
  getJiraProjects,
};
