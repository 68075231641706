import { useTheme } from '@emotion/react';
import { useCallback, useEffect } from 'react';
import { Edge, Node, useEdgesState, useNodesState } from 'react-flow-renderer';

import { ITeamGoal, TeamGoalInput } from '@align-services/api/types/goalsTypes';
import { Unit, ValueType } from '@align-types/constants';
import { ITheme } from '@styles/types';

import { removeEdges, removeNodes, TreeViewServices } from './treeView.services';
import { edgeStyles } from './treeView.styles';
import { INodeData, INodeEdgeGenerator } from './treeView.types';

type ITreeBuilderResult = [Node[], Edge[]];

export const useTreeBuilder = (rootGoal: INodeEdgeGenerator): ITreeBuilderResult => {
  const theme = useTheme() as ITheme;
  const [nodes, setNodes] = useNodesState<INodeData>([]);
  const [edges, setEdges] = useEdgesState<Edge>([]);

  const generateRootNode = () => {
    setNodes(() => {
      const rootNode = TreeViewServices.generateNode(
        rootGoal,
        null,
        rootGoal.teamGoal,
        rootGoal.targetValues,
        rootGoal.valueType,
        rootGoal.unit,
        null,
        null,
        handleToggle,
        rootGoal.isTLO,
        rootGoal.threshold
      );
      return [rootNode];
    });
    setEdges([]);
  };

  const handleToggle = useCallback(
    (parentGoal: ITeamGoal, targetValues: TeamGoalInput[], valueType: ValueType, unit: Unit) => {
      // this variable is needed to track when the isExpanded prop of the parent node has changed
      let isParentNodeUpdated = false;
      let isParentExpanded;

      setNodes((nds) => {
        let updatedNodes = [...nds];
        parentGoal.children?.forEach((childGoal, index) => {
          // since we do not know which node is a parent node, but know only its ID, we need to find it
          const parentNode = nds.find((node) => parseInt(node.id) === parentGoal.team.id);
          if (!isParentNodeUpdated) {
            if (parentNode) {
              isParentExpanded = parentNode.data.isExpanded;
              parentNode.data = {
                ...parentNode.data,
                isExpanded: !parentNode.data.isExpanded,
              };
              // after we update the parent node we do not do that again when traversing other children
              isParentNodeUpdated = true;
            }
          }

          if (isParentExpanded) {
            // exclude the child node from the nodes tree if collapsing
            updatedNodes = removeNodes(updatedNodes, childGoal);
          } else {
            // add the child node to the nodes tree if expanding
            updatedNodes = [
              ...updatedNodes,
              TreeViewServices.generateNode(
                rootGoal,
                parentGoal,
                childGoal,
                targetValues,
                valueType,
                unit,
                parentNode,
                index,
                handleToggle,
                parentNode.data.isTLO,
                parentNode.data.threshold
              ),
            ];
          }
        });
        return updatedNodes;
      });

      setEdges((eds) => {
        let updatedEdges = [...eds];
        parentGoal.children?.forEach((childGoal) => {
          if (isParentExpanded) {
            // exclude the edge if the child node is excluded
            updatedEdges = removeEdges(updatedEdges, childGoal);
          } else {
            // add a new edge for a child node
            updatedEdges = [
              ...updatedEdges,
              TreeViewServices.generateEdge(
                parentGoal,
                childGoal,
                edgeStyles({ color: theme.color })
              ),
            ];
          }
        });
        return updatedEdges;
      });
    },
    [rootGoal]
  );

  // initialize the node tree with the parent node
  useEffect(() => {
    if (rootGoal.teamGoal && nodes.length === 0) {
      generateRootNode();
    }
  }, [rootGoal.teamGoal, nodes]);

  // update target values in nodes in case there were changes
  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => ({
        ...node,
        data: {
          ...node.data,
          range: {
            dateFrom: rootGoal.validFrom,
            dateTo: rootGoal.expiresAt,
          },
          targetValues: rootGoal.targetValues,
          valueType: rootGoal.valueType,
          unit: rootGoal.unit,
          isTLO: rootGoal.isTLO,
          threshold: rootGoal.threshold,
        },
      }))
    );
  }, [
    rootGoal.valueType,
    rootGoal.targetValues,
    rootGoal.unit,
    rootGoal.validFrom,
    rootGoal.expiresAt,
    rootGoal.isTLO,
    rootGoal.threshold,
  ]);

  // update current values in nodes in case there were changes
  useEffect(() => {
    if (rootGoal.teamGoal) {
      setNodes((nds) =>
        nds.map((node) => ({
          ...node,
          data: {
            ...node.data,
            goal: TreeViewServices.findNestedTeamById(rootGoal.teamGoal, parseInt(node.id)),
          },
        }))
      );
    }
  }, [rootGoal.teamGoal]);

  return [nodes, edges];
};
