import React from 'react';

import { ITeamsContextProps } from '@common-pages/Settings/teams/teams.types';

export const TeamContext = React.createContext<ITeamsContextProps>(null);

export const TeamProvider: React.FC<ITeamsContextProps> = ({ children, members }) => {
  return (
    <TeamContext.Provider
      value={{
        members,
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};
