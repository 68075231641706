import React, { useMemo } from 'react';

import { useJiraProjects } from '@common-services/api/public/hooks/useFiltersData';
import { FilterDropdown } from '@lib/filter-dropdown';
import { FilterType, IFilterTypeProps } from '@lib/filter-panel';
import { SelectTsOptionSingle } from '@lib/selector-ts';

import { useExistingFilters } from '../filterPanel.hooks';

export const JiraProjects: React.FC<IFilterTypeProps> = React.memo(
  ({ onChange, chartParamType }) => {
    const { data: fetchedProjects, isLoading } = useJiraProjects(true);

    const selectedValues = useExistingFilters(FilterType.JIRA_PROJECTS, chartParamType);

    const options = useMemo<SelectTsOptionSingle[]>(() => {
      return fetchedProjects?.map((project) => ({
        name: project.name,
        id: project.key,
      }));
    }, [fetchedProjects]);

    return (
      <FilterDropdown
        options={options}
        isLoading={isLoading}
        onChange={onChange}
        label="Project"
        allSelectedLabel="All Projects"
        initialSelected={selectedValues}
      />
    );
  }
);
