import React from 'react';

import { FilterPanel, IFilterProps } from '@lib/filter-panel';

import { wrapperStyles } from './chartRule.styles';

export const ChartRule: React.FC<IFilterProps> = React.memo(
  ({ filterName, chartParamType, onFiltersChange }) => {
    return (
      <div css={wrapperStyles}>
        <div className="condition">is</div>
        <FilterPanel
          filterName={filterName}
          onFiltersChange={onFiltersChange}
          chartParamType={chartParamType}
        />
      </div>
    );
  }
);
