import React from 'react';

import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import Panel from '@analytics-components/insights/Panel';
import { prepareChartBoxes } from '@analytics-pages/delivery-pipeline/insights/helper';

import bugsFixingRatio from './bugs-fixing-ratio';

const meta = {
  title: 'Bugs Fixing Ratio',
  description:
    'Ratio of bugs fixed to resolved during the time period selected. Useful to measure how is evolving the amount of bugs in your backlog.',
};

const chartBoxes = [
  {
    id: 'bugs-fixing-ratio',
    component: ChartBoxWithKPIWidget,
    params: bugsFixingRatio,
  },
];

const BugsFixingRatioPanel = () => {
  const preparedChartBoxes = prepareChartBoxes(chartBoxes);
  return <Panel meta={meta} chartBoxes={preparedChartBoxes} />;
};

export default BugsFixingRatioPanel;
