import React from 'react';

import { getStyle, neutralType, turquoiseType, targetType } from './Badge.styles';

interface Props {
  text: number | string | React.ReactNode;
  type?: BadgeColorType;
}

export enum BadgeColorType {
  DEFAULT = 'default',
  TURQUOISE = 'turquoise',
  NEUTRAL = 'neutral',
  TARGET = 'target',
}

const Badge: React.FC<Props> = ({ text, type }) => {
  const styles = (theme) => {
    switch (type) {
      case BadgeColorType.TURQUOISE:
        return [getStyle(theme), turquoiseType(theme)];
      case BadgeColorType.NEUTRAL:
        return [getStyle(theme), neutralType(theme)];
      case BadgeColorType.TARGET:
        return [getStyle(theme), targetType(theme)];
      default:
        return getStyle(theme);
    }
  };
  return (
    <div data-cy="badge" css={styles}>
      {text}
    </div>
  );
};

Badge.defaultProps = {
  type: BadgeColorType.DEFAULT,
};

export { Badge };
