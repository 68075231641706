import _ from 'lodash';
import React, { useReducer, useEffect, useContext, useRef, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useBreadcrumb } from '@analytics-context/Breadcrumb';
import pageToDataIds from '@analytics-context/pageToDataIds';

const Context = React.createContext({});

const dataReducer = (state, action) => {
  const actionType =
    _(['done', 'error']).includes(action.type) && state.isResetting ? 'reinit' : action.type;

  switch (actionType) {
    case 'reset':
      // The optimal scenario would be to store in the state also the eventually on-going XHR request so that it can be cancelled.
      // Another approach that is sub-optimal is to store here the promise that is awaited when calling `await Promise.all` so that we can at least cancel that one.
      return {
        ...state,
        id: action.payload.id,
        mapping: action.payload.mapping,
        sid: uuidv4(),
        done: false,
        isResetting: true,
        data: null,
        error: null,
      };
    case 'reinit': {
      return {
        ...state,
        isLoading: false,
        isResetting: false,
      };
    }
    case 'loading':
      return { ...state, isLoading: true, mapping: action.payload.mapping };
    case 'done':
      return { ...state, isLoading: false, done: true, data: action.payload.data };
    case 'error':
      return { ...state, isLoading: false, done: true, error: action.payload.error };
    default:
      return { ...state };
  }
};

const useDataHelper = () => {
  const {
    missingMetrics,
    getRegistry,
    register,
    subscribe,
    unsubscribe,
    get,
    set,
    reset,
    merge,
    setReloading,
  } = useContext(Context);
  return {
    missingMetrics,
    getRegistry,
    register,
    subscribe,
    unsubscribe,
    get,
    set,
    reset,
    merge,
    setReloading,
  };
};

const useData = (id, funcOrMapping, cond = true) => {
  const isMounted = useRef(false);
  const isSingle = _(funcOrMapping).isFunction();
  const mapping = useMemo(() => (isSingle ? { [id]: funcOrMapping } : funcOrMapping), [
    isSingle,
    funcOrMapping,
    id,
  ]);
  const { getOrSet, subscribe, unsubscribe } = useContext(Context);
  // The `sid` is not actually used in the logic, but it is useful for debugging
  // we can remove it at some point
  const [state, dispatch] = useReducer(dataReducer, {
    id,
    sid: uuidv4(),
    mapping: null,
    done: false,
    isLoading: false,
    isResetting: false,
    data: null,
    error: null,
  });

  useEffect(() => {
    subscribe(id, 'reset', () => {
      dispatch({ type: 'reset', payload: { id, mapping } });
    });

    if (cond) {
      if (!isMounted.current) {
        isMounted.current = true;
      } else if (state.id !== id) {
        dispatch({ type: 'reset', payload: { id, mapping } });
      }
    }

    return () => {
      unsubscribe(id);
    };
  }, [cond, id, mapping, state.id, subscribe, unsubscribe]);

  useEffect(() => {
    const sid = state.sid;

    if (state.isLoading) {
      return;
    }

    if (state.isResetting) {
      dispatch({ type: 'reinit', sid });
      return;
    }

    if (!cond || state.done) {
      return;
    }

    (async () => {
      dispatch({ type: 'loading', payload: { mapping } });
      const proms = _(mapping)
        .map((func, id) => [id, getOrSet(id, func)])
        .value();
      const ids = _(proms)
        .map((v) => v[0])
        .value();
      const data = await Promise.all(
        _(proms)
          .map((v) => v[1])
          .value()
      );
      const mappedData = _(ids).zipObject(data).value();
      if (
        _(data)
          .map((v) => v instanceof Error)
          .some()
      ) {
        console.log(`[${id}][${new Date()}][2] Error`, mappedData);
        dispatch({ type: 'error', payload: { error: mappedData }, sid });
      } else {
        const d = isSingle ? mappedData[id] : mappedData;
        dispatch({ type: 'done', payload: { data: d }, sid });
      }
    })();
  }, [id, cond, state.isLoading, state.done, getOrSet, mapping, state, isSingle]);

  if (id !== state.id) {
    return { id, done: false, isLoading: false, data: null, error: null };
  } else {
    return {
      id: state.id,
      done: state.done,
      isLoading: state.isLoading,
      data: state.data,
      error: state.error,
    };
  }
};

const DataProvider = ({ children }) => {
  const session = useRef({});
  const cache = useRef({});
  const registry = useRef({});
  const subscribers = useRef({
    'reset': {},
    'registry-change': {},
  });

  const [missingMetrics, setMissingMetrics] = useState(null);
  const { section, subsection } = useBreadcrumb();

  // the following effect triggers on each section change
  // it checks if there are metrics that are needed for the section and were not yet fetched
  useEffect(() => {
    const reloadingDefs = Object.keys(pageToDataIds);
    let prevMissingMetrics = missingMetrics;
    _(reloadingDefs).forEach((def) => {
      const allPageMetrics = pageToDataIds[def]?.[section]?.[subsection] || [];
      // get the data for corresponding data definition from cache
      const cachedData = get(def)?.data;
      const cachedDataMetrics =
        Object.keys(cachedData || {}).length > 0 ? pageToDataIds[def]?.accessor(cachedData) : [];
      // define metrics that do not exist in cache
      const metrics = _.difference(allPageMetrics, cachedDataMetrics);
      if (metrics.length > 0) {
        prevMissingMetrics = {
          ...(prevMissingMetrics || {}),
          [def]: metrics,
        };
      }
    });
    setMissingMetrics(prevMissingMetrics);
  }, [section, subsection]);

  const getOrSet = async (id, func) => {
    const cachedData = cache.current[id] || session.current[id];
    let f = func;
    if (!!cachedData && cachedData.done) {
      return cachedData.data || cachedData.error;
    } else if (!f) {
      if (registry.current[id]) {
        f = registry.current[id];
      } else {
        throw new Error(`[${id}][${new Date()}] Missing func`);
      }
    }

    const prom = cachedData ? cachedData.promise : f();
    if (!cachedData) {
      cache.current[id] = { promise: prom };
      registry.current[id] = f;
      _(subscribers.current['registry-change']).forEach((cb, _) => cb(id));
    }

    try {
      cache.current[id].done = false;
      const fetched = await prom;
      if (!cache.current[id]) {
        // This means that it has been reset while awaiting, in this case
        // we simply ignore.
        return null;
      }

      if (!cache.current[id].data) {
        cache.current[id].data = fetched;
        cache.current[id].done = true;
      }
      return fetched;
    } catch (err) {
      if (!cache.current[id].error) {
        cache.current[id].error = err;
        cache.current[id].done = true;
      }
      return err;
    }
  };
  const get = (id) => cache.current[id] || session.current[id];
  const set = (id, value, persistent = false) => {
    const store = persistent ? session : cache;
    if (!store.current[id]) {
      registry.current[id] = null;
      _(subscribers.current['registry-change']).forEach((cb, _) => cb(id));
      store.current[id] = { data: value, done: true };
    }
  };
  const setReloading = (id) => {
    if (cache.current[id]) {
      delete registry.current[id];
      cache.current[id].done = false;
      _(subscribers.current['reset']).forEach((cb, _) => cb(id));
    }
  };
  const merge = (id, value, func) => {
    if (get(id)) {
      cache.current[id].data = _.merge({}, cache.current[id].data, value);
      cache.current[id].done = true;
      registry.current[id] = func;
      _(subscribers.current['registry-change']).forEach((cb, _) => cb(id));

      const newMissingMetrics = { ...missingMetrics };
      delete newMissingMetrics[id];
      if (Object.values(newMissingMetrics).length > 0) {
        setMissingMetrics(newMissingMetrics);
      } else {
        setMissingMetrics(null);
      }
    } else {
      set(id, value);
    }
  };

  const reset = (id) => {
    if (id) {
      delete cache.current[id];
      delete registry.current[id];
    } else {
      cache.current = {};
      registry.current = _(Object.keys(session.current))
        .map((k) => [k, registry.current[k]])
        .fromPairs()
        .value();
    }
    _(subscribers.current['reset']).forEach((cb, _) => cb(id));
  };
  const register = (id, func) => {
    registry.current[id] = func;
    _(subscribers.current['registry-change']).forEach((cb, _) => cb(id));
  };

  const getRegistry = () => registry.current;

  const subscribe = (id, event, callback) => {
    subscribers.current[event][id] = callback;
  };
  const unsubscribe = (id, event) => {
    if (!event) {
      _(subscribers.current).forEach((_, ev) => {
        delete subscribers.current[ev][id];
      });
    } else {
      delete subscribers.current[event][id];
    }
  };

  return (
    <Context.Provider
      value={{
        missingMetrics,
        getOrSet,
        get,
        set,
        reset,
        getRegistry,
        register,
        subscribe,
        unsubscribe,
        merge,
        setReloading,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default DataProvider;
export { useData, useDataHelper };
