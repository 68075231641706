import * as yup from 'yup';

import { UserDetailsPutParams } from '@common-pages/account/user-details/userDetails.types';

export const userDetailsFormSchema: yup.SchemaOf<UserDetailsPutParams> = yup.object().shape({
  name: yup.string().min(1).required(),
  email: yup
    .string()
    .email('Work Email must be a valid email')
    .required()
    .test('isWorkEmailCheck', 'Please, use your work email', (email) => {
      const domain = email.substring(email.lastIndexOf('@') + 1);
      return !['gmail.com', 'yahoo.com', 'hotmail.com'].some((element) => domain === element);
    }),
});
