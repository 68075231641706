import { css } from '@emotion/react';

const getStyle = ({ spacing, radius, color, shadow, font, transition }, withTagsContainer) => css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${spacing.gap['0']};
  background: ${color.neutral.white};
  border: 1px solid ${color.neutral['40']};
  box-sizing: border-box;
  box-shadow: ${shadow.full};
  border-radius: ${radius.default};
  flex: none;
  margin: ${spacing.gap['0']};
  overflow: hidden;
  min-width: 240px;

  ${withTagsContainer &&
  `
  & > div:first-of-type {
    border: 0;
    border-bottom: 1px solid ${color.neutral['40']};
    border-radius: ${radius.sharp};
    width: 100%;
  }
  `}

  & div.menu {
    margin-top: ${spacing.gap['0']};
    width: 100%;

    & .menu-options {
      width: 100%;
      overflow: auto;
      margin: 0;
      padding: 0;

      & ul {
        list-style: none;
        padding: 0;
      }

      & .load-more {
        cursor: pointer;
        font-size: ${font.size.default};
        padding: ${spacing.gap['03']};
        text-align: center;
        list-style: none;
        border-bottom: 1px solid ${color.neutral['40']};
        transition: ${transition.long};
        &:hover {
          background: ${color.neutral['10']};
        }
      }

      & div.options-group {
        padding: ${spacing.gap['03']} ${spacing.gap['03']} ${spacing.gap['03']} ${spacing.gap['05']};
        line-height: 13px;
        border-bottom: 1px solid ${color.neutral['40']};
        text-transform: uppercase;
        background: ${color.neutral['20']};
        color: ${color.neutral['80']};
      }
    }
  }
`;

export default getStyle;
