import { css } from '@emotion/react';

export const fieldWrapperStyles = (withMargin) => ({ spacing }) => css`
  width: 233px;

  ${withMargin &&
  `
    margin-right: ${spacing.gap['03']};
  `}
`;
