import React from 'react';

import noPrs from '@assets/images/empty-states/no_prs.svg';

import { Empty } from '..';
import { EntityType, ITooManyRows } from './tooManyRows.types';

const TooManyRows: React.FC<ITooManyRows> = React.memo(
  ({ amount, entityType = EntityType.prs }) => (
    <Empty background={noPrs}>
      <h3>
        There are <strong>{amount}</strong>{' '}
        {entityType === EntityType.prs ? 'active pull requests' : 'releases'} with the selected
        filters.
      </h3>
      <p>The table is not appropriate to get valueable insights at that scale.</p>
    </Empty>
  )
);

export { TooManyRows };
