import { css } from '@emotion/react';

export const panelStyles = ({ color, font, spacing }) => css`
min-width: 45rem !important; // TODO: this !important is required, otherwise bootstrap rule has priority
padding: ${spacing.gap['0']};
background-color: transparent;
border: 0;

& > div {
  padding: ${spacing.gap['05']};

  &.body {
    max-height: 460px;
    min-width: 450px;
    background-color: ${color.neutral[10]};
    border: 1px solid ${color.neutral[40]};
    position: relative;
    box-sizing: content-box;
    padding-bottom: 0;

    & h3 {
      color: ${color.neutral[100]};
      font-size: ${font.size.default};
      font-weight: ${font.weight.regular};
      margin-bottom: ${spacing.gap['05']};
    }

    & > div > div {
      width: 100%;
    }
  }

  &.footer {
    background-color: ${color.neutral.white};
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
`;

export const menuListStyles = ({ font, spacing }) => css`
  cursor: pointer;
  font-size: ${font.size.default};
  padding: ${spacing.gap['03']};
  text-align: center;
`;

export const openingDirectionStyles = (opensUp) => css`
  display: flex;
  left: 75px;
  right: auto;
  height: 460px;

  ${opensUp
    ? `
    top: -455px;
    align-items: flex-end;
  `
    : `
    top: 45px;
    align-items: flex-start;
  `}
`;
