import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { act } from 'react-dom/test-utils';
import { SubmitHandler, useForm } from 'react-hook-form';

import { userDetailsFormSchema } from '@common-pages/account/user-details/components/user-details-form/userDetailsForm.schema';
import { getFormStyle } from '@common-pages/account/user-details/userDetails.styles';
import {
  UserDetailsPutParams,
  UserDetailsTestEnums,
} from '@common-pages/account/user-details/userDetails.types';
import { Button, ButtonHtmlType, ButtonUISize, PresetButtonUIType } from '@lib/Button';
import { Input } from '@lib/Input';

interface Prop {
  initName?: string;
  onSubmit: (data: UserDetailsPutParams) => void;
}

export const UserDetailsForm: React.FC<Prop> = React.memo(({ initName, onSubmit }) => {
  const {
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    handleSubmit,
  } = useForm<UserDetailsPutParams>({
    defaultValues: {
      name: initName,
    },
    resolver: yupResolver(userDetailsFormSchema),
    mode: 'all',
  });
  const orderFormSubmitHandler: SubmitHandler<UserDetailsPutParams> = async (data) => {
    act(() => {
      onSubmit(data);
    });
  };
  return (
    <form css={getFormStyle} onSubmit={handleSubmit(orderFormSubmitHandler)}>
      <div className="block">
        <Input
          placeholder={'eg. John Doe'}
          label={'Full Name'}
          register={register}
          name={'name'}
          error={errors?.name?.message || null}
          testid={UserDetailsTestEnums.NAME}
        />
      </div>
      <div className="block">
        <Input
          label={'Work Email'}
          register={register}
          name={'email'}
          error={errors?.email?.message || null}
          testid={UserDetailsTestEnums.EMAIL}
        />
      </div>
      <div className="submit">
        <Button
          disabled={!isDirty || !isValid || isSubmitting}
          label={isSubmitting ? 'Loading...' : 'Get Started'}
          type={PresetButtonUIType.PRIMARY}
          size={ButtonUISize.XLARGE}
          buttonHtmlType={ButtonHtmlType.SUBMIT}
          dataTestId={UserDetailsTestEnums.SUBMIT_BTN}
        />
      </div>
    </form>
  );
});
