import React from 'react';

import { reduceLoginName } from '@common-services/stringService';
import { Avatar, AvatarType } from '@lib/avatar';

import { IUser } from '../UsersPanel.types';
import { getStyle } from './UserRow.styles';

const UserRow: React.FC<IUser> = React.memo(({ picture, name, login }) => {
  return (
    <div css={getStyle}>
      <Avatar type={AvatarType.image} image={picture} />
      <span className="user-name">{name}</span>
      <span className="user-github">{reduceLoginName(login)}</span>
    </div>
  );
});

export { UserRow };
