import { css } from '@emotion/react';

export const infoStyles = ({ color, font, spacing }) => css`
  align-items: center;
  display: flex;

  .number {
    color: ${color.text.dark};
    font-size: ${font.size.default};
    margin-left: ${spacing.gap['03']};
  }
`;

export const infoHeader = ({ radius, spacing }) => css`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .dot {
    width: 12px;
    height: 12px;
    border-radius: ${radius.circle};
    margin-right: ${spacing.gap['02']};
  }
`;

export const cellTeamWrapper = ({ row }) => ({ spacing, color, font, radius, shadow }) => css`
  padding-left: ${row.depth * 3}rem;
  white-space: nowrap;

  svg {
    margin-left: ${spacing.gap['0']};
  }

  div {
    display: inline-block;
    vertical-align: middle;
    width: 25px;
    height: 25px;
    border-radius: ${radius.circle};
    border: 2px solid ${color.neutral.white};
    box-shadow: ${shadow.default};
    color: ${color.neutral.white};
    text-align: center;
    line-height: ${font.height.s};
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 21px;
    font-size: ${font.size.xxs};
    font-weight: ${font.weight.bold};
  }

  p {
    color: ${color.neutral[100]};
    display: inline-block;
    margin-left: ${spacing.gap['04']};
    font-size: ${font.size.default};
    line-height: ${font.height.xs};

    span {
      color: ${color.neutral[80]};
      margin-left: ${spacing.gap['04']};
      font-size: ${font.size.default};
      line-height: ${font.height.xs};
    },
  }
`;
