import React from 'react';

import { Icon, icons } from '@lib/icon';

import { UserRowHeaderProps } from '../UsersPanel.types';
import { getStyle } from './UserRowHeader.styles';

const UserRowHeader: React.FC<UserRowHeaderProps> = React.memo(({ text }) => {
  return (
    <div css={getStyle}>
      <Icon icon={icons.person} size={15} />
      <span>{text}</span>
    </div>
  );
});

export { UserRowHeader };
