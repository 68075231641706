import { css } from '@emotion/react';

export const wrapperStyles = ({ color, spacing }) => css`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: 426px;

  .search {
    flex: 0 0 auto;
    padding: ${spacing.gap['04']};
    background: ${color.neutral['10']};
    border-bottom: 1px solid ${color.neutral['40']};
  }

  .list {
    flex: 1 1 auto;
    overflow-y: auto;

    .list-item {
      padding: 0 ${spacing.gap['05']};
      margin-top: ${spacing.gap['04']};

      &:last-child {
        margin-bottom: ${spacing.gap['04']};
      }
    }
  }
`;
