import { css } from '@emotion/react';

export const wrapperStyles = ({ fullWidth }) => ({ spacing }) => css`
  position: relative;
  ${fullWidth && `width: 100%;`};

  .content {
    position: absolute;
    top: calc(100% + ${spacing.gap['02']});
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    ${fullWidth && `width: 100%;`};
    z-index: 100;
  }
`;
