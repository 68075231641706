import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { CacheService } from '@align-services/cacheService';
import { useApi } from '@analytics-hooks';
import { updateTeam } from '@analytics-services/api';
import { updateJIRAidentity } from '@analytics-services/api/jira';
import { IContributor, IDeveloper } from '@analytics-types/common';
import { getTeamColor } from '@common-services/colorService';
import log from '@common-services/logger';

import { ISelectedJiraLabel } from '../../teams.types';
import { ITeamEditAccordion } from './teamEditAccordion.types';
import { TeamEditAccordionUI } from './ui';

const TeamEditAccordion: React.FC<ITeamEditAccordion> = React.memo(
  ({
    id,
    name,
    members,
    parent,
    allMembers,
    developers,
    jiraIdentities,
    allUsersLoaded,
    teams,
    onRemove,
    onLoadAllUsers,
    onMembersChange,
  }) => {
    const [teamMembers, setTeamMembers] = useState(members);
    const [userOptions, setUserOptions] = useState<IDeveloper[]>();
    const { api, ready: apiReady, context } = useApi(true, false);

    useEffect(() => {
      const filteredOptions = _(developers)
        .filter((m) => !_(teamMembers).map('login').includes(m.login))
        .value();
      setUserOptions(filteredOptions);
    }, [teamMembers, developers]);

    useEffect(() => {
      setTeamMembers(members);
    }, [members]);

    const updateTeam_ = async (nameUpdate, membersUpdate, cb) => {
      if (!apiReady) {
        log.fatal(`API not ready`);
        return;
      }

      try {
        await updateTeam(
          api,
          id,
          nameUpdate,
          membersUpdate.map((developer) => developer.login),
          parent
        );

        await invalidateMembersQuery();
        log.ok(`Team ${nameUpdate} has been updated`);

        cb();
      } catch (err) {
        log.fatal(`Could not update the team`, err);
      }
    };

    const onMemberDeletion = async (deletedMember: IContributor) => {
      const filteredTeamMembers = _(allMembers)
        .filter((m) => m.login !== deletedMember.login)
        .value();
      updateTeam_(name, filteredTeamMembers, () => {
        setTeamMembers(members.filter((m) => m.login !== deletedMember.login));
        onMembersChange();
      });
    };

    const onMembersAddition = async (newMembers: IDeveloper[]) => {
      const newTeamMembers = _(teamMembers).concat(newMembers).uniqBy('login').value();
      updateTeam_(name, newTeamMembers, () => {
        setTeamMembers(_(members).concat(newMembers).uniqBy('login').value());
        onMembersChange();
      });
    };

    const onMemberMapped = async (member: IContributor, jiraIdentity: ISelectedJiraLabel) => {
      if (!apiReady) {
        log.fatal(`API not ready`);
        return;
      }

      const remappedMembers = _(teamMembers)
        .map((m) => (m.login !== member.login ? m : { ...m, jira_user: jiraIdentity.label }))
        .value();

      try {
        await updateJIRAidentity(api, context.account, member.login, jiraIdentity.label);
        setTeamMembers(remappedMembers);
        onMembersChange();
        log.ok(`Jira mapping updated`);
      } catch (err) {
        log.fatal(`Could not update JIRA identity mapping`, err);
      }
    };
    const onMemberMappingReset = async (member: IContributor) =>
      await onMemberMapped(member, { label: null });

    const onRemoveWrapped = () => onRemove(id);

    const processedJiraIdentities = _(jiraIdentities)
      .map((ji) => ({ ...ji, isSelected: _(developers).map('jira_name').includes(ji.label) }))
      .uniqBy('label')
      .value();

    const onEdit = useCallback(async (teamNameFromEdit: string, parentIdFromEdit: any) => {
      try {
        if (!apiReady) {
          log.fatal(`API not ready`);
        }
        await updateTeam(
          api,
          id,
          teamNameFromEdit,
          members.map((developer) => developer.login),
          parentIdFromEdit
        );
        await invalidateTeamsQuery();
        log.ok(`Team #${teamNameFromEdit} has been updated`);
        onMembersChange();
      } catch (err) {
        log.fatal(`Could not update the team`, err);
      }
    }, []);

    const parentTeamOptions = useMemo(() => {
      return teams
        .filter((team) => team.name !== name)
        .map((team) => {
          return {
            name: team.name,
            value: team.id,
            color: getTeamColor(team.name),
          };
        });
    }, [teams]);

    const parentTeamInit = useMemo(() => {
      return teams
        .filter((team) => team.id === parent)
        .map((team) => {
          return {
            name: team.name,
            value: team.id,
          };
        });
    }, [teams, parent]);

    const invalidateTeamsQuery = async (): Promise<void> => {
      try {
        await CacheService.removeQuery(['teams']);
      } catch {
        log.fatal('Cache error');
      }
    };

    const invalidateMembersQuery = async (): Promise<void> => {
      try {
        await CacheService.removeQuery(['members']);
      } catch {
        log.fatal('Cache error');
      }
    };

    return (
      <TeamEditAccordionUI
        name={name}
        members={teamMembers}
        usersOptions={userOptions}
        jiraIdentities={processedJiraIdentities}
        loadedAllUsersOptions={allUsersLoaded}
        onEdit={onEdit}
        onRemove={onRemoveWrapped}
        onMemberDeletion={onMemberDeletion}
        onMembersAddition={onMembersAddition}
        onMemberMapped={onMemberMapped}
        onMemberMappingReset={onMemberMappingReset}
        onLoadAllUsersOptionsClick={onLoadAllUsers}
        teamNameInit={name}
        parentTeamInit={parentTeamInit ? parentTeamInit[0] : parentTeamOptions[0]}
        parentTeamOptions={parentTeamOptions}
      />
    );
  }
);

export { TeamEditAccordion };
