import { css } from '@emotion/react';

export const wrapperStyles = ({ spacing }) => css`
  padding: ${spacing.gap['05']};
  display: flex;
  align-items: center;
  gap: ${spacing.gap['05']};
  height: 80px;
  z-index: 100;
`;
