/**
 * {{ title }}
 * Server version: {{ server_version }} built on {{ build_date }} @ [{{ commit }}](https://github.com/athenianco/athenian-api/commit/{{ commit }})  Origin GitHub repository: [athenianco/api-spec](https://github.com/athenianco/api-spec).  Authorization persists between sessions. 🛡️ means that the endpoint requires account admin privileges. 👑 means that the endpoint is only accessible to Athenian authorities. <details> <summary>List of common server errors.</summary> * __500__ endpoint crashed. We reported the incident to Sentry and will fix it soon! Please include the value of `instance` in the response if you contact the support. Repeating the request will not help, most probably. * __501__ some functions are missing, either because they are not implemented yet or a non-critical runtime dependency is not satisfied. Repeating the request will not help, guaranteed. * __502__ server crashed badly, either due to a memory access violation in native code or running out of memory. We reported this incident to Sentry and will fix it soon! Try repeating the request. * __503__ server has not fully launched yet, e.g. hasn't connected to the database; server is shutting down; we are experiencing a partial outage. Try repeating the request. * __504__ endpoint took too much time and was interrupted. We reported the incident to Sentry and will see how to improve the performance. Repeating the request will not help, most probably. </details>
 *
 * Contact: admin@athenian.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The FilterContributorsRequest model module.
 * @module model/FilterContributorsRequest
 */
class FilterContributorsRequest {
    /**
     * Constructs a new <code>FilterContributorsRequest</code>.
     * Filters for &#x60;/filter/contributors&#x60;.
     * @alias module:model/FilterContributorsRequest
     * @param account {Number} Session account ID.
     * @param dateFrom {Date} Updates must be later than or equal to this date. An update is any action that influences the stage assignment.
     * @param dateTo {Date} Updates must be earlier than or equal to this date. An update is any action that influences the stage assignment.
     */
    constructor(account, dateFrom, dateTo) { 
        
        FilterContributorsRequest.initialize(this, account, dateFrom, dateTo);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, account, dateFrom, dateTo) { 
        obj['account'] = account;
        obj['date_from'] = dateFrom;
        obj['date_to'] = dateTo;
    }

    /**
     * Constructs a <code>FilterContributorsRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/FilterContributorsRequest} obj Optional instance to populate.
     * @return {module:model/FilterContributorsRequest} The populated <code>FilterContributorsRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new FilterContributorsRequest();

            if (data.hasOwnProperty('account')) {
                obj['account'] = ApiClient.convertToType(data['account'], 'Number');
            }
            if (data.hasOwnProperty('date_from')) {
                obj['date_from'] = ApiClient.convertToType(data['date_from'], 'Date');
            }
            if (data.hasOwnProperty('date_to')) {
                obj['date_to'] = ApiClient.convertToType(data['date_to'], 'Date');
            }
            if (data.hasOwnProperty('timezone')) {
                obj['timezone'] = ApiClient.convertToType(data['timezone'], 'Number');
            }
            if (data.hasOwnProperty('in')) {
                obj['in'] = ApiClient.convertToType(data['in'], ['String']);
            }
            if (data.hasOwnProperty('as')) {
                obj['as'] = ApiClient.convertToType(data['as'], ['String']);
            }
        }
        return obj;
    }


}

/**
 * Session account ID.
 * @member {Number} account
 */
FilterContributorsRequest.prototype['account'] = undefined;

/**
 * Updates must be later than or equal to this date. An update is any action that influences the stage assignment.
 * @member {Date} date_from
 */
FilterContributorsRequest.prototype['date_from'] = undefined;

/**
 * Updates must be earlier than or equal to this date. An update is any action that influences the stage assignment.
 * @member {Date} date_to
 */
FilterContributorsRequest.prototype['date_to'] = undefined;

/**
 * Local time zone offset in minutes, used to adjust `date_from` and `date_to`.
 * @member {Number} timezone
 */
FilterContributorsRequest.prototype['timezone'] = undefined;

/**
 * Set of repositories. An empty list raises a bad response 400. Duplicates are automatically ignored.
 * @member {Array.<String>} in
 */
FilterContributorsRequest.prototype['in'] = undefined;

/**
 * Filter by contribution type. A `null` or an empty list select all.
 * @member {Array.<module:model/FilterContributorsRequest.AsEnum>} as
 */
FilterContributorsRequest.prototype['as'] = undefined;





/**
 * Allowed values for the <code>as</code> property.
 * @enum {String}
 * @readonly
 */
FilterContributorsRequest['AsEnum'] = {

    /**
     * value: "author"
     * @const
     */
    "author": "author",

    /**
     * value: "reviewer"
     * @const
     */
    "reviewer": "reviewer",

    /**
     * value: "commit_author"
     * @const
     */
    "commit_author": "commit_author",

    /**
     * value: "commit_committer"
     * @const
     */
    "commit_committer": "commit_committer",

    /**
     * value: "commenter"
     * @const
     */
    "commenter": "commenter",

    /**
     * value: "merger"
     * @const
     */
    "merger": "merger",

    /**
     * value: "releaser"
     * @const
     */
    "releaser": "releaser"
};



export default FilterContributorsRequest;

