import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ChartEdit } from '@align-pages/dashboard/components/chart-edit';
import { chartConfig } from '@align-pages/dashboard/config';
import { useSelectedBreakdownGroupData } from '@align-pages/dashboard/hooks';
import { ChartOperationType } from '@align-pages/dashboard/hooks/types';
import { useDashboardResponseHandle } from '@align-pages/dashboard/hooks/useDashboardResponseHandle';
import { DashboardPropertyServices } from '@align-pages/dashboard/services/dashboardPropertyServices';
import { useDashboardStore } from '@align-pages/dashboard/store';
import { IChartConfig } from '@align-pages/dashboard/types';
import { useHistory } from '@common-hooks/useHistory';
import { useAddChartToDashboard } from '@common-services/api/private/hooks/useAddChartToDashboard';

import { ChartHeader } from '../chart-header';
import { MetricOptions } from '../metric-options/metricOptions';
import { NewMetricServices } from './newMetric.services';

const NewMetric: React.FC = React.memo(() => {
  const [properties, dropProperties] = useDashboardStore((state) => [
    state.properties,
    state.onDropProperties,
  ]);
  const [chartParams, onChartParamsInit, dropChartParams] = useDashboardStore((state) => [
    state.chartParams,
    state.onChartParamsInit,
    state.onChartParamsDrop,
  ]);
  const selectedMetric = useMemo(
    () => chartConfig.find((conf) => conf.metric === chartParams?.metric),
    [chartParams]
  );

  const history = useHistory();
  const [chartName, setChartName] = useState(selectedMetric?.name);

  const { mutate: createChart, isSuccess, isError, isLoading } = useAddChartToDashboard();

  const { isLoading: isResponseHandleLoading } = useDashboardResponseHandle({
    isSuccess,
    isError,
    operationType: ChartOperationType.CREATE,
  });

  const filters = useMemo(() => DashboardPropertyServices.propertiesToFilters(properties), [
    properties,
  ]);

  const breakdown = useMemo(() => DashboardPropertyServices.propertiesToBreakdown(properties), [
    properties,
  ]);

  const group_by = useSelectedBreakdownGroupData(breakdown);

  const addChart = useCallback(() => {
    createChart({
      time_interval: chartParams.time_interval,
      metric: selectedMetric,
      chartName,
      filters,
      group_by,
    });
  }, [chartParams, chartName, filters, group_by]);

  const selectMetric = useCallback(
    (metric: IChartConfig) => () => {
      onChartParamsInit(NewMetricServices.newMetricData(metric));
    },
    []
  );

  const handleBackButtonClick = useCallback(() => {
    if (!chartParams) history.goBack();
    dropChartParams();
    dropProperties();
  }, [chartParams, history]);

  const handleNameChange = useCallback((name) => {
    setChartName(name);
  }, []);

  useEffect(() => {
    if (!selectedMetric) {
      window.scrollTo(0, 0);
    }
  }, [selectedMetric]);

  return (
    <>
      <ChartHeader
        isSubmitDisabled={
          !selectedMetric?.metric ||
          !chartParams?.time_interval ||
          isLoading ||
          isResponseHandleLoading
        }
        onSubmit={addChart}
        onBackButtonClick={handleBackButtonClick}
        selectedMetric={selectedMetric}
        onNameChange={handleNameChange}
      />
      {selectedMetric ? (
        <ChartEdit metric={selectedMetric} />
      ) : (
        <MetricOptions onSelect={selectMetric} />
      )}
    </>
  );
});

export { NewMetric };
