import React from 'react';
import { Link } from 'react-router-dom';

import { listItemStyles, listStyles } from './styles';

const Breadcrumb = ({ breadcrumb }) => (
  <div css={listStyles} aria-label="breadcrumb">
    {breadcrumb.slice(0, -1).map((b) => (
      <div css={listItemStyles} key={b.name}>
        {b.link ? (
          <Link className="breadcrumb-link" to={b.link}>
            {b.name}
          </Link>
        ) : (
          b.name
        )}{' '}
        /&nbsp;
      </div>
    ))}
    <div className="active" css={listItemStyles} aria-current="page">
      {breadcrumb.slice(-1).pop().name}
    </div>
  </div>
);

export default Breadcrumb;
