// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';

import { useHelpContext } from '@analytics-context/Help';
import {
  Main,
  Title,
  Summary,
  Subtitle,
  Paragraph,
  Separator,
  ContentBlock,
  BlockQuote,
  Source,
  Sidebar,
  RelatedContent,
  Navigation,
  Image,
} from '@analytics-pages/Help/components';
import Release01Img from '@assets/images/educational/release_01.png';
import Release02Img from '@assets/images/educational/release_02.png';

const ReleasedPrs = ({ fullPage }) => {
  const { openSection, stack } = useHelpContext();
  return (
    <>
      <Navigation pages={stack} />
      <Main>
        <section>
          <Title>Release Cycle Time</Title>
          <Summary>
            Release is a metric borrowed from lean thinking and manufacturing disciplines. It’s
            defined as the amount of time between the moment work begins until it’s delivered to the
            end customer.
          </Summary>

          <Subtitle>Why is Release important?</Subtitle>
          <Paragraph>
            Release in software development is important because it helps you understand how fast
            work is moving through your <a href="https://athenian.co">software delivery pipeline</a>
            . It allows you to detect early bottlenecks and resolve them on the spot. It also helps
            you to gain precision when estimating the time to deliver a new feature to the end-user.
            Together with other metrics that tell you about volume, engagement, quality and impact,
            you can understand if you are improving as a team.
          </Paragraph>

          <ContentBlock>
            Shorter Releases brings the following benefits to the team’s output performance:
            <br />
            <br />
            <ol>
              <li>A faster feedback loop with your users</li>
              <li>Beating competition to market</li>
              <li>Reducing risk in the deployment process by working in smaller batches</li>
            </ol>
          </ContentBlock>

          <Paragraph>
            In Google’s 2019 DevOps research, the authors revealed that High-Performance
            Organisations have a Release under a week, Elite organizations under a day.
          </Paragraph>

          <Image src={Release01Img} />

          <Subtitle>How is it calculated?</Subtitle>
          <Paragraph>
            Athenian calculates it as the average time for pull requests to go from their initial
            commit until they are released. It sums the time pull requests spend in all the stages
            composing the software delivery pipeline: Work in Progress, Review, Merge and Release.
          </Paragraph>
          <Paragraph>
            It is important to emphasize that Release is calculated on released pull requests only.
            If a pull request goes out of the delivery pipeline before being released, it is not
            included in the calculation.
          </Paragraph>

          <Separator />

          <Title>How to improve it using Athenian?</Title>
          <Paragraph>
            Athenian allows you to quickly spot which segment of the pipeline adds the most time to
            total Release. Generally speaking, this segment should then be tackled first. The pull
            request table offers the ability to zoom on the pull requests that get stuck and slow
            down the end-to-end delivery.
          </Paragraph>

          <Image src={Release02Img} />

          <Paragraph>
            A series of metrics and insights help you understand the current state of your software
            delivery pipeline. By setting progressive and reasonable targets your team can aim to
            reduce Release without having a negative impact on the other pillars constituting the
            best engineering practices.
          </Paragraph>

          <ContentBlock>
            <ul className="li-dash">
              <li>Reduce pull request size to ease code reviews.</li>
              <li>Minimize the time pull requests remain idle in the pipeline.</li>
              <li>Encourage knowledge sharing to avoid human bottlenecks.</li>
              <li>Ship smaller and more often to shorten feedback loops.</li>
            </ul>
          </ContentBlock>
        </section>
        <Sidebar>
          <BlockQuote>
            Developers should be checking multiple small releasable changes into the trunk at least
            once per day.
            <Source>GOOGLE</Source>
          </BlockQuote>

          <RelatedContent>
            <h4>Related Content</h4>
            <ul>
              <li>
                <a href="/">Software Delivery Pipeline</a>
              </li>
              <li>
                {fullPage ? (
                  <Link to={`/learn/knowledge/lead-time`} className="link">
                    <span>Lead Time</span>
                  </Link>
                ) : (
                  <span
                    onClick={() => openSection({ label: 'Lead Time', path: 'leadTime' })}
                    className="link"
                  >
                    Lead Time
                  </span>
                )}
              </li>
              <li>
                <a href="/">DevOps Research</a>
              </li>
            </ul>
          </RelatedContent>
        </Sidebar>
      </Main>
    </>
  );
};

export default ReleasedPrs;
