import { motion } from 'framer-motion';
import React from 'react';

import { Icon, icons } from '@lib/icon';
import { theme } from '@styles/theme';

import { getStyle } from './styles';

const variants = {
  collapsed: { rotate: 0 },
  expanded: { rotate: 90 },
};

interface Props {
  isExpanded: boolean;
  size: number;
  color?: string;
}

const Chevron: React.FC<Props> = React.memo(
  ({ isExpanded, size, color = theme.color.neutral[80] }) => (
    <motion.div
      initial={false}
      animate={isExpanded ? 'expanded' : 'collapsed'}
      variants={variants}
      css={getStyle}
    >
      <Icon icon={icons.angle_right} size={size} color={color} />
    </motion.div>
  )
);

export { Chevron };
