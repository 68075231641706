import classnames from 'classnames';
import React from 'react';
import { Link, Route, useParams } from 'react-router-dom';

import Sidebar from '@analytics-components/Sidebar';
import BreadcrumbsContext from '@analytics-context/Breadcrumb';
import Page from '@analytics-pages/Page';

const Prototypes = ({ prototypes }) => {
  const { name: activePrototype } = useParams();

  return (
    <BreadcrumbsContext>
      <Page>
        <style
          dangerouslySetInnerHTML={{
            __html: `
                    #root > .container {
                        padding: 0;
                        max-width: unset;
                        margin: 0 !important;
                    }
            `.replace(/\s{2,}/g, ' '),
          }}
        />
        <div className="row m-0">
          <Sidebar />
          <div className="navbar navbar-expand-sm navbar-light bg-white static-top border-bottom w-100">
            <div className="container">
              <ol
                id="mainBreadcrumbs"
                className="breadcrumb bg-white mb-0 py-0 p-0"
                aria-label="breadcrumb"
              >
                {Object.keys(prototypes).map((prototype) => (
                  <li
                    className={classnames(
                      'breadcrumb-item',
                      prototype === activePrototype ? 'active' : 'text-xs'
                    )}
                    aria-current={prototype === activePrototype ? 'page' : 'false'}
                    key={prototype}
                  >
                    {prototype === activePrototype ? (
                      prototype
                    ) : (
                      <Link to={`/prototypes/${prototype}`} className="text-bright">
                        {prototype}
                      </Link>
                    )}
                  </li>
                ))}
              </ol>
            </div>
          </div>
          <div className="col content-main mt-4">
            {Object.keys(prototypes).map((prototype) => (
              <Route path={`/prototypes/${prototype}`} key={prototype}>
                {prototypes[prototype]}
              </Route>
            ))}
          </div>
        </div>
      </Page>
    </BreadcrumbsContext>
  );
};

export default Prototypes;
