import _ from 'lodash';
import React, { useState, useEffect } from 'react';

import { fetchVersions } from '@analytics-services/api/index';

const Footer = () => {
  const [versions, setVersions] = useState('');

  useEffect(() => {
    const getAndSetVersions = async () => {
      const versions = _(await fetchVersions())
        .map((v, k) => `${k} v${v}`)
        .join(' · ');
      setVersions(versions);
    };
    getAndSetVersions();
  }, []);

  return (
    <footer css={{ marginLeft: '20rem' }} className="sticky-footer bg-white border-top">
      <div className="container my-auto">
        <div className="text-center my-auto">
          <span>Copyright &copy; Athenian.co 2020-2023</span>
          <br />
          <span className="text-bright small">{versions}</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
