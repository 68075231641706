import React from 'react';

import { NoDataForStage } from '../noDataForStage';

const NoReviewed = ({ textOnly }) => (
  <NoDataForStage
    textOnly={textOnly}
    textOnlyCopy="no PRs reviewed"
    companionCopy="There has not been any reviewed PR in the date range selected"
  />
);

export { NoReviewed };
