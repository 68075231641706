import classnames from 'classnames';
import React from 'react';

import { footerWrapperStyles } from './styles';

const FilterFooter = ({ onCancel, onApply, isAcceptable, extra }) => (
  <div css={footerWrapperStyles}>
    <div>{extra}</div>
    <div>
      <button onClick={onCancel} className="btn btn-link text-secondary px-3">
        Cancel
      </button>
      <button
        disabled={!isAcceptable}
        onClick={onApply}
        className={classnames('btn btn-orange px-3', !isAcceptable && 'btn-disabled')}
      >
        Apply
      </button>
    </div>
  </div>
);

export default FilterFooter;
