import { useTheme } from '@emotion/react';
import { curveMonotoneX } from 'd3-shape';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ChartLabel,
  Crosshair,
  FlexibleWidthXYPlot,
  HorizontalGridLines,
  LineSeries,
  MarkSeries,
  VerticalGridLines,
  XAxis,
  YAxis,
} from 'react-vis';

import LegendSelect from '@analytics-components/LegendSelect';
import { computeTickValues } from '@analytics-components/charts/TimeSeries';
import { DateRangeDayMonth } from '@analytics-components/charts/Tooltip';
import { DateService, dateTime } from '@common-services/dateService';

import { legendWrapperStyles, listItemStyles, tooltipStyles } from './styles';

const buildChartLabel = (text) => {
  const labelParams = {
    includeMargin: false,
    xPercent: 0.0,
    yPercent: 0.5,
    style: {
      textAnchor: 'middle',
      transform: 'rotate(-90)',
      x: -40,
      y: -40,
    },
  };

  return <ChartLabel text={text} {...labelParams} />;
};

const MultiLineChart = ({ data, extra }) => {
  const theme = useTheme();

  const { interval, linesData } = data;
  const [dataPoint, setDataPoint] = useState(null);
  const [lines, setLines] = useState([]);

  useEffect(() => {
    setLines(linesData);
  }, [linesData]);

  const xTicks = useMemo(
    () =>
      !!lines.length ? computeTickValues(lines[0].data, extra?.ticks?.x?.maxNumberOfTicks) : [],
    [lines]
  );

  const legend = useMemo(
    () =>
      linesData.map((line) => ({
        color: line.color,
        label: line.label,
      })),
    [linesData]
  );

  const tooltipPeriod = useMemo(() => {
    if (dataPoint && !!lines.length) {
      const firstDate = _(lines)
        .map('data')
        .filter((arr) => arr.length)
        .first()[0]
        .x.getTime();
      const isFirstPoint = dataPoint.x.getTime() === firstDate;
      const from = isFirstPoint ? interval.from.repr : dataPoint.x.toISOString();
      const nextTickIndex =
        lines[0].data.map((d) => d.x.getTime()).indexOf(dataPoint.x.getTime()) + 1;
      const to =
        nextTickIndex >= lines[0].data.length
          ? interval.to.repr
          : new Date(
              DateService.subtractPeriod(lines[0].data[nextTickIndex].x.getTime(), 1, 'days')
            ).toISOString();
      return { from, to };
    }
  }, [dataPoint, lines]);

  const tooltipValues = useMemo(() => {
    if (dataPoint) {
      let noData = true;
      const values = lines.map((line) => {
        const point = line.data.find((d) => d.x.getTime() === dataPoint.x.getTime());
        if (point?.y) {
          noData = false;
          return {
            count: point.count,
            color: line.color,
            label: line.label,
            value: extra.tooltip?.valueFormat ? extra.tooltip.valueFormat(point.y) : point.y,
          };
        }
        return null;
      });
      return noData ? [] : values;
    }
    return [];
  }, [dataPoint, lines]);

  const minValue = useMemo(
    () => (lines?.length > 0 ? _.min(lines.map((line) => _.minBy(line.data, 'y')?.y)) || 0 : 0),
    [lines]
  );
  const maxValue = useMemo(
    () => (lines?.length > 0 ? _.max(lines.map((line) => _.maxBy(line.data, 'y')?.y)) || 1 : 1),
    [lines]
  );

  const yDomain = useMemo(
    () => ({
      min: minValue === maxValue ? 0 : _.max([0, minValue - (maxValue - minValue) / 2]),
      max: maxValue,
    }),
    [lines]
  );

  const handleMouseOver = useCallback((point) => {
    setDataPoint(point);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setDataPoint(null);
  }, []);

  const handleLegendClick = useCallback(
    (actives) => {
      setLines(linesData.filter((_, i) => actives[i]));
    },
    [linesData]
  );

  const defaultYAxisTicks = {
    tickFormat: extra.ticks?.y?.tickFormat,
    tickTotal: extra.ticks?.y?.maxNumberOfTicks || 10,
    tickSizeOuter: 0,
    tickSizeInner: 0,
  };

  const defaultYAxisGrid = {
    tickTotal: extra.ticks?.y?.maxNumberOfTicks || 10,
  };

  const dynamicYAxisTicks = extra.ticks?.y?.dynamic ? extra.ticks.y.dynamic(lines) : {};

  const yAxisTicks = { ...defaultYAxisTicks, ...(dynamicYAxisTicks.ticks || {}) };
  const yAxisGrid = { ...defaultYAxisGrid, ...(dynamicYAxisTicks.grid || {}) };

  return (
    <>
      <div css={legendWrapperStyles}>
        <LegendSelect items={legend} onActivesChange={handleLegendClick} />
      </div>
      <FlexibleWidthXYPlot
        height={extra?.chartHeight || 300}
        margin={{
          bottom: extra?.margin?.bottom || 20,
          left: extra?.margin?.left || 40,
          right: extra?.margin?.right || 50,
          top: extra?.margin?.top || 50,
        }}
        yDomain={[yDomain.min, yDomain.max]}
        onMouseLeave={handleMouseLeave}
      >
        <VerticalGridLines tickValues={xTicks} />
        <XAxis
          tickValues={xTicks}
          tickSizeOuter={0}
          tickSizeInner={0}
          tickFormat={extra?.ticks?.x?.tickFormat || dateTime.monthDay}
        />
        <HorizontalGridLines {...yAxisGrid} />
        <YAxis {...yAxisTicks} />
        {!!extra?.axisLabels?.y && buildChartLabel(extra?.axisLabels?.y, 'y')}

        {lines.map((line) => (
          <LineSeries
            animation="stiff"
            color={line.color}
            curve={curveMonotoneX}
            data={line.data.filter((v) => v.y !== null)}
            key={`line-${line.label}`}
          />
        ))}

        {lines.map((line) => (
          <MarkSeries
            animation="stiff"
            data={line.data}
            fill="white"
            key={`mark-${line.label}`}
            stroke={line.color}
            strokeWidth={2}
            getNull={(v) => v.y !== null}
            onNearestX={handleMouseOver}
          />
        ))}

        {dataPoint && tooltipValues.length > 0 && (
          <Crosshair
            style={{
              line: {
                backgroundColor: 'transparent',
                borderLeft: `2px dashed ${theme.color.neutral[80]}`,
              },
            }}
            values={[dataPoint]}
          >
            <div css={tooltipStyles}>
              <DateRangeDayMonth
                className="tooltip-date"
                dateFrom={DateService.strToMoment(tooltipPeriod.from)}
                dateTo={DateService.strToMoment(tooltipPeriod.to)}
                uppercase
              />
              <table>
                <tbody>
                  {_(tooltipValues)
                    .orderBy(['value'], ['desc'])
                    .map(
                      (v) =>
                        v && (
                          <tr key={`tooltip-row-${v.label}`}>
                            <td className="priority-name">
                              <div css={(theme) => listItemStyles(theme, v.color)} />
                              <span>{`${v.label} ${v.count ? `(${v.count})` : ''}`}</span>
                            </td>
                            <td className="value">{v.value}</td>
                          </tr>
                        )
                    )
                    .value()}
                </tbody>
              </table>
            </div>
          </Crosshair>
        )}
      </FlexibleWidthXYPlot>
    </>
  );
};

export default MultiLineChart;
