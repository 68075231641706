import React, { useCallback, useMemo, useState } from 'react';

import { PrimaryLayoutModes } from '@align-components/layouts/primary';
import { metricsConfig } from '@align-constants';
import { GoalsCatalogUI } from '@align-pages/goals/single/components/goals-catalog/ui';
import { ITemplateMetric } from '@align-services/api/types/goalsTypes';
import { GoalTemplate } from '@common-services/api/public/generated-from-backend/models';

interface PropsCallbacks {
  templateNameSelected?: string;
  templateMetricSelected?: ITemplateMetric;
  mode?: PrimaryLayoutModes;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onGoalSelected?: (selectedGoal: GoalTemplate) => void;
}

interface GoalsConfig {
  goals: GoalTemplate[];
}

type Props = GoalsConfig & PropsCallbacks;

const GoalsCatalog: React.FC<Props> = React.memo(
  ({ goals, templateNameSelected, templateMetricSelected, mode, onClick, onGoalSelected }) => {
    const [isExpanded, setIsExpanded] = useState(true);

    const onClickWrapper = useCallback(
      (ev) => {
        setIsExpanded(!isExpanded);
        if (onClick) {
          onClick(ev);
        }
      },
      [isExpanded, onClick]
    );

    const visibleGoals = useMemo(
      () =>
        goals.filter(
          (goal) => goal.available && goal.metric && !metricsConfig[goal.metric]?.hidden
        ),
      [goals]
    );

    return (
      <GoalsCatalogUI
        goals={visibleGoals}
        templateNameSelected={templateNameSelected}
        templateMetricSelected={templateMetricSelected}
        mode={mode}
        isExpanded={isExpanded}
        onClick={onClickWrapper}
        onGoalSelected={onGoalSelected}
      />
    );
  }
);

export { GoalsCatalog };
