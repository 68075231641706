import { motion } from 'framer-motion';
import React from 'react';

import { theme } from '@styles/theme';

const variants = {
  expanded: { rotate: 0 },
  collapsed: { rotate: -90 },
};

const Chevron = ({ isExpanded, size = 20, color = theme.color.neutral[80], ...attributes }) => (
  <motion.svg
    height={size}
    width={size}
    viewBox={`0 0 ${size} ${size}`}
    aria-hidden="true"
    focusable="false"
    initial={false}
    animate={isExpanded ? 'expanded' : 'collapsed'}
    variants={variants}
    css={{
      cursor: 'pointer',
      verticalAlign: 'middle',
    }}
    {...attributes}
  >
    <path
      fill={color}
      d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
    />
  </motion.svg>
);

export default Chevron;
