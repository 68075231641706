import { unionBy } from '@common-services/vendor/lodash';

import { IDeploymentsRow } from '../deployments.types';

export const searchTermByField = (
  deployments: IDeploymentsRow[],
  fieldName: string,
  term: string
): IDeploymentsRow[] => {
  const filteredData: IDeploymentsRow[] = [];
  deployments.forEach((deployment) => {
    if (deployment[fieldName]?.toString().toLowerCase().indexOf(term.toLowerCase()) >= 0) {
      // add whole deployment to the list if there is a match in its field
      filteredData.push(deployment);
    } else {
      // search for PRs that might have a match in their field
      const filteredPrs = deployment.subRows.filter(
        (pr) => pr[fieldName]?.toString().toLowerCase().indexOf(term.toLowerCase()) >= 0
      );
      if (filteredPrs?.length) {
        // in case such PRs were found add them including their deployment
        filteredData.push({
          ...deployment,
          subRows: filteredPrs,
        });
      }
    }
  });
  return filteredData;
};

export const searchTerm = (deployments: IDeploymentsRow[], term: string): IDeploymentsRow[] => {
  // the list of searchable fields
  const fields = ['repository', 'status', 'author', 'title'];
  const sepIndex = term.indexOf(':');
  const parts = sepIndex === -1 ? [term] : [term.slice(0, sepIndex), term.slice(sepIndex + 1)];
  let filteredData: IDeploymentsRow[] = [];
  if (parts.length > 1) {
    // in case field name was specified in the Search field
    const [fieldName, term] = parts;
    if (fields.indexOf(fieldName) >= 0) {
      filteredData = searchTermByField(deployments, fieldName.trim(), term.trim());
    }
  } else {
    // in case field name was not specified we search in all searchable fields
    fields.forEach((fieldName) => {
      const matchingDeployments = searchTermByField(deployments, fieldName, term.trim());
      matchingDeployments.forEach((deployment) => {
        const existingDeployment = filteredData.find((v) => v.name === deployment.name);
        if (existingDeployment) {
          existingDeployment.subRows = unionBy(
            existingDeployment.subRows,
            deployment.subRows,
            'number'
          );
        } else {
          filteredData.push(deployment);
        }
      });
    });
  }
  return filteredData;
};
