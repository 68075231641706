import React from 'react';

import { AddUserPanel } from '@common-pages/Settings/teams/components/team-edit-accordion/components/add-user-panel';
import { Button } from '@lib/Button';

import { footerStyles } from '../teamEditAccordionFooter.styles';
import { ITeamEditAccordionFooterUI, OpeningDirection } from '../teamEditAccordionFooter.types';

const TeamEditAccordionFooterUI: React.FC<ITeamEditAccordionFooterUI> = React.memo(
  ({
    footer,
    isUsersPanelOpen,
    buttonId,
    userPanelOpeningDirection,
    loadedAllOptions,
    onMembersAddition,
    onMembersAdditionCancel,
    onUserPanelButtonClick,
    onLoadAllOptionsClick,
  }) => (
    <div css={footerStyles} className="dropdown">
      <div className="button-wrapper" id={buttonId}>
        <Button label={footer.text} onClick={onUserPanelButtonClick} />
      </div>
      {isUsersPanelOpen && (
        <AddUserPanel
          opensUp={userPanelOpeningDirection === OpeningDirection.up}
          loadedAllOptions={loadedAllOptions}
          onSave={onMembersAddition}
          onCancel={onMembersAdditionCancel}
          onLoadAllOptionsClick={onLoadAllOptionsClick}
        />
      )}
    </div>
  )
);

export { TeamEditAccordionFooterUI };
