import React from 'react';

import { Circle } from '@lib/circle';

import { colorGridStyles, colorTitleStyles } from './colorGrid.styles';
import { IColorGrid } from './colorGrid.types';

export const ColorGrid: React.FC<IColorGrid> = React.memo(({ colors = [], value, onClick }) => (
  <>
    <div css={colorTitleStyles}>Select a color</div>
    <div css={colorGridStyles}>
      {colors.map((color) => (
        <Circle
          key={`color-${color}`}
          active={value === color}
          color={color}
          size={20}
          onClick={onClick}
        />
      ))}
    </div>
  </>
));
