import React from 'react';

import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import Panel from '@analytics-components/insights/Panel';
import { prepareChartBoxes } from '@analytics-pages/delivery-pipeline/insights/helper';

import workByEpic from './work-by-epic';

const meta = {
  title: 'Tickets Resolved per Epic',
  description:
    'Quantitatively understand which features have concentrated your attention this cycle and how close are they from final delivery.',
};

const chartBoxes = [
  {
    id: 'work-by-epic',
    component: ChartBoxWithKPIWidget,
    params: workByEpic,
  },
];

const WorkByEpicPanel = () => {
  const preparedChartBoxes = prepareChartBoxes(chartBoxes);
  return <Panel meta={meta} chartBoxes={preparedChartBoxes} />;
};

export default WorkByEpicPanel;
