import React from 'react';
import { CellProps, Renderer } from 'react-table';

import { IReleasesPrRow } from '@analytics-pages/Tables/releases/releases.types';
import { dateTime } from '@common-services/dateService';
import { github } from '@common-services/format';
import { Icon, icons } from '@lib/icon';
import { useTheme } from '@styles/hooks';
import Chevron from '@ui/Chevron';

import { getTitleAndReleaseUrl } from './cellTitle.services';
import {
  detailsStyles,
  iconWrapperStyles,
  placeholderStyles,
  subTitleStyles,
  titleStyles,
  wrapperStyles,
} from './cellTitle.styles';

const ReleasesCellTitle: Renderer<CellProps<IReleasesPrRow>> = ({ row }) => {
  const theme = useTheme();
  // @ts-ignore appears that react-table type definitions don't have onClick defined
  const { onClick } = row.getToggleRowExpandedProps();
  const [title, releaseUrl] = getTitleAndReleaseUrl(row.original.name, row.original.repository);
  const author = github.userName(row.original.publisher);
  const released = dateTime.interval(new Date(row.original.published), new Date());
  const prLink = github.prLink(row.original.repository, row.original.number);

  return (
    <div css={wrapperStyles}>
      {row.canExpand ? (
        <Chevron isExpanded={row.isExpanded} onClick={onClick} />
      ) : (
        <div css={placeholderStyles(row.depth > 0)} />
      )}
      {row.depth > 0 ? (
        <>
          <div css={placeholderStyles(row.depth > 0)} />
          <a css={subTitleStyles} href={prLink} rel="noreferrer" target="_blank">
            <span className="pr-number">#{row.original.number}&nbsp;</span>
            <span className="pr-title">{row.original.title}</span>
          </a>
        </>
      ) : (
        <>
          <div css={iconWrapperStyles}>
            <Icon color={theme.color.ui.turquoise['100']} icon={icons.release} size={22} />
          </div>
          <div>
            <div css={titleStyles}>
              <div className="repo-name">
                {row.original.repository.split('/').slice(1).join('/')}&nbsp;
              </div>
              <a className="release-text" href={releaseUrl} rel="noreferrer" target="_blank">
                {title}
              </a>
            </div>
            <div css={detailsStyles}>
              <img alt="" className="user-avatar" src={row.original.authorAvatar} title={author} />
              <div className="details-text">
                <span>Released by </span>
                <span className="reporter">{author || 'an unknown author'}</span> {released}
                <span> ago</span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export { ReleasesCellTitle };
