// Reference: https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#common_weight_name_mapping
export enum FontWeight {
  extraBlack = '950',
  black = '900',
  extraBold = '800',
  bold = '700',
  semiBold = '600',
  medium = '500',
  regular = '400',
  light = '300',
  extraLight = '200',
  thin = '100',
}
