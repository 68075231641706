import React from 'react';

import maintenance from '@assets/images/empty-states/maintenance.svg';

import { Empty } from '..';

const Maintenance: React.FC = React.memo(() => {
  return (
    <Empty background={maintenance}>
      <h3>Servers are down for maintenance</h3>
      <p>Athenian will be back soon.</p>
      <a className="text-dark-orange" href={window.ENV.settings.statusUrl}>
        Athenian Status
      </a>
    </Empty>
  );
});

export { Maintenance };
