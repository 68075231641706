import React from 'react';

import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import Panel from '@analytics-components/insights/Panel';
import { prepareChartBoxes } from '@analytics-pages/delivery-pipeline/insights/helper';

import timeline from './timeline';

const meta = {
  title: 'Success Ratio - Timeline',
  description: 'Evolution of the average success ratio though time.',
};

const chartBoxes = [
  {
    id: 'success-ratio-timeline',
    component: ChartBoxWithKPIWidget,
    params: timeline,
  },
];

const TimelinePanel = () => {
  const preparedChartBoxes = prepareChartBoxes(chartBoxes);
  return <Panel meta={meta} chartBoxes={preparedChartBoxes} />;
};

export default TimelinePanel;
