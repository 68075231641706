import { useTheme } from '@emotion/react';
import _ from 'lodash';
import React, { useState, useEffect, useContext } from 'react';

import CollectionHandler, { useCollectionHandler } from '@analytics-components/CollectionHandler';
import { AddChartButton } from '@analytics-components/TeamsComparison';
import Panel, { PanelBody } from '@analytics-components/insights/Panel';
import { useApi } from '@analytics-hooks';
import {
  prepareChartBoxLine,
  prepareChartBoxVolume,
} from '@analytics-pages/Compare/Teams/Timelines/chartBox';
import metricsGroups, {
  CHART_TYPE_ABSOLUTE,
  CHART_TYPE_RELATIVE,
} from '@analytics-pages/Compare/Teams/Timelines/config';
import { getTeams } from '@analytics-services/api';
import { getTeamsWithoutRoot } from '@analytics-services/teamsService';
import { FAKE_USERNAME } from '@common-pages/Settings/constants';
import TaggedSelector from '@lib/TaggedSelector';
import { AvatarType } from '@lib/avatar';
import { icons } from '@lib/icon';

import { getFiltersStyle, getChartsWrapperStyle } from './styles';

const Context = React.createContext({
  teamsOptions: [],
  teamsApplied: [],
});

const TEAM_TAGGED_SELECTOR_STORAGE_KEY_PREFIX = 'filter.teams-tagged-selector.';
const METRICS_SELECTOR_STORAGE_KEY_PREFIX = 'filter.metrics-selector.';

const MAX_SELECTED_TEAMS = 8;

const useTeamsOptions = () => useContext(Context);

const TeamsOptionsContext = ({ children }) => {
  const [teamsOptions, setTeamsOptions] = useState([]);
  const [teamsMapping, setTeamsMapping] = useState({});
  const [teamsApplied, setTeamsApplied] = useState([]);
  return (
    <Context.Provider
      value={{
        teamsOptions,
        teamsMapping,
        teamsApplied,
        setTeamsOptions,
        setTeamsMapping,
        setTeamsApplied,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const ContentTeamsComparisonTimelines = () => {
  const {
    context: { account, user },
    ready,
  } = useApi(true, false);

  const hasJira = ready ? !!user.account.jira : false;
  const processedMetricsItems = processMetrics(metricsGroups, hasJira);

  const onItemsStorageMismatch = ({ initialItems, items }) => {
    const selectedItemsIDs = _(items).filter('selected').map('id').value();
    const fixedItems = _(initialItems)
      .map((item) => ({ ...item, selected: _(selectedItemsIDs).includes(item.id) }))
      .value();
    return fixedItems;
  };

  const onItemsStoragePreprocess = ({ initialItems, fixedItems }) => {
    const mapping = _(initialItems).keyBy('id').value();
    return _(fixedItems)
      .map((fixedItem) => {
        const funcsMapping = _(mapping[fixedItem.id])
          .pickBy((v, k) => _(v).isFunction())
          .value();
        return { ...mapping[fixedItem.id], ...fixedItem, ...funcsMapping };
      })
      .value();
  };

  return (
    <TeamsOptionsContext>
      <CollectionHandler
        initialItems={processedMetricsItems}
        flagName={'selected'}
        isLoading={!ready}
        useStorage={true}
        storageKey={`${METRICS_SELECTOR_STORAGE_KEY_PREFIX}${account}`}
        onItemsStorageMismatch={onItemsStorageMismatch}
        onItemsStoragePreprocess={onItemsStoragePreprocess}
      >
        <Filters />
        <ChartsWrapper />
      </CollectionHandler>
    </TeamsOptionsContext>
  );
};

const TEAMS_COLORS = [
  'orange',
  'lightpink',
  'darkpurple',
  'yellow',
  'green',
  'ocean',
  'lightblue',
  'blue',
];

const COLOR_SET_CACHE = {};

// Use the same colors for the same set of teams applied before
const getColorSetFromCache = (applied, colors) => {
  // create the ID of selected teams
  const colorSetId = applied
    .sort()
    .map(({ value }) => value)
    .join('-');
  // use the previous set of colors for the applied teams if it's exist
  if (COLOR_SET_CACHE[colorSetId]) {
    return COLOR_SET_CACHE[colorSetId];
  }

  // create color set cache if this is the first time with these applied teams
  COLOR_SET_CACHE[colorSetId] = colors;
  return colors;
};

const Filters = () => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const {
    api,
    context: { account, contributors },
    ready: apiReady,
  } = useApi();
  const { teamsOptions, setTeamsOptions, setTeamsMapping, setTeamsApplied } = useTeamsOptions();

  const colors = _(theme.color.ui)
    .filter((c, name) => _(TEAMS_COLORS).includes(name))
    .map((c) => c['100'])
    .value();

  useEffect(() => {
    const prepareTeamOptions = async () => {
      const teams = await getTeams(api, account);
      const teamsWithoutRoot = getTeamsWithoutRoot(teams);
      const teamsOptions = _(teamsWithoutRoot)
        .map((t) => ({
          label: t.name,
          value: t.id.toString(),
          parent: (t.parent || '').toString(),
        }))
        .value();
      const ghJiraMapping = _(contributors)
        .filter('login')
        .map((c) => [c.login, c.jira_user || FAKE_USERNAME])
        .fromPairs()
        .value();
      const teamMembersMapping = _(teamsWithoutRoot)
        .map((t) => {
          const ghLogins = _(t.members).map('login').uniq().value();
          const jiraUsers = _(ghLogins)
            .map((ghl) => ghJiraMapping[ghl] || null)
            .uniq()
            .value();
          return [t.id.toString(), { github: ghLogins, jira: jiraUsers }];
        })
        .fromPairs()
        .value();
      setTeamsOptions(teamsOptions);
      setTeamsMapping(teamMembersMapping);
      setIsLoading(false);
    };

    if (apiReady) {
      prepareTeamOptions();
    }
  }, [apiReady]);

  const onAppliedChange = (applied, colors) => {
    const colorSet = getColorSetFromCache(applied, colors);

    setTeamsApplied(applied.map((opt) => ({ ...opt, color: colorSet[opt.value] })));
  };

  return (
    <div css={getFiltersStyle}>
      <div className="tagged-selector-wrapper">
        <TaggedSelector
          isCascading={true}
          avatarType={AvatarType.none}
          isLoading={isLoading}
          options={teamsOptions}
          maxSelected={MAX_SELECTED_TEAMS}
          button={{
            titleOnLimitReached: `You reached the maximum of ${MAX_SELECTED_TEAMS} teams.`,
          }}
          colors={colors}
          onAppliedChange={onAppliedChange}
          useStorage={true}
          storageKey={`${TEAM_TAGGED_SELECTOR_STORAGE_KEY_PREFIX}${account}`}
        />
      </div>
      <AddChartButton />
    </div>
  );
};

const processMetrics = (metricsGroups, hasJira) =>
  _(metricsGroups)
    .map((mg) =>
      _(mg.metrics)
        .map((m) => ({ ...m, group: mg.group }))
        .value()
    )
    .flatMap()
    .map((m) => ({
      id: _(m.sources).concat([m.endpoint, m.group, m.displayName]).join('|'),
      name: m.displayName,
      group: m.group,
      ...m,
    }))
    .filter((m) => (hasJira ? true : !m.requiresJira))
    .value();

const ChartsWrapper = () => {
  const { getVisibleItems } = useCollectionHandler();
  const selectedMetrics = getVisibleItems();

  return (
    <div css={getChartsWrapperStyle}>
      {_(selectedMetrics)
        .map((m, i) => <MovableMetricPanel key={m.id} {...m} />)
        .value()}
    </div>
  );
};

const MovableMetricPanel = ({ id, group, name, ...config }) => {
  const {
    context: { user },
    ready,
  } = useApi(true, false);

  const hasJira = ready ? !!user.account.jira : false;
  const theme = useTheme();
  const { teamsApplied, teamsMapping } = useTeamsOptions();
  const {
    getVisibleItems,
    getPosition,
    moveUp,
    moveDown,
    hideItem,
    modifyItem,
  } = useCollectionHandler();
  const totalVisibleItems = getVisibleItems().length;
  const visiblePosition = getPosition(id, true);
  const controls = [
    {
      icon: icons.arrow_up,
      text: 'Move Up',
      disabled: visiblePosition === 0,
      onClick: () => moveUp(id, true),
    },
    {
      icon: icons.arrow_down,
      text: 'Move Down',
      disabled: visiblePosition === totalVisibleItems - 1,
      onClick: () => moveDown(id, true),
    },
    {
      icon: icons.cross,
      text: 'Remove',
      disabled: false,
      onClick: () => {
        hideItem(id);
      },
    },
  ];

  const chartBoxLine = prepareChartBoxLine(id, teamsApplied, teamsMapping, config, theme, hasJira);

  const panelProps = {
    meta: {
      title: name,
      description: config.description || ``,
      controls,
    },
  };

  if (group === 'Throughput') {
    panelProps.onContentChange = (index) => {
      modifyItem(id, {
        override: { chartType: index === 0 ? CHART_TYPE_ABSOLUTE : CHART_TYPE_RELATIVE },
      });
    };

    const chartBoxVolume = prepareChartBoxVolume(
      id,
      teamsApplied,
      teamsMapping,
      config,
      theme,
      hasJira
    );

    panelProps.meta.tabs = {
      initialContentIndex: config.chartType === CHART_TYPE_RELATIVE ? 1 : 0,
      def: [
        {
          icon: icons.chart_line,
          description: 'Absolute',
          content: <PanelBody chartBoxes={chartBoxLine} />,
        },
        {
          icon: icons.chart_stacked_bar,
          description: 'Relative',
          content: <PanelBody chartBoxes={chartBoxVolume} />,
        },
      ],
    };
  } else {
    panelProps.chartBoxes = chartBoxLine;
  }

  return <Panel {...panelProps} />;
};

export default ContentTeamsComparisonTimelines;
