import { css } from '@emotion/react';

export const getStyle = ({ spacing, color, radius, transition, shadow, font }) => css`
  width: 100%;
  padding: ${spacing.gap['04']};
  background: ${color.neutral['40']};

  & > div.value-container {
    border: 1px solid ${color.neutral['40']};
    display: flex;
    flex-direction: row;
    align-items: center;
    background: ${color.neutral.white};
    min-height: 40px;
    height: 40px;
    border-radius: ${radius.default};
    padding: ${spacing.gap['04']};
    transition: ${transition.default};

    &:hover,
    &:active {
      border: 1px solid ${color.neutral['60']};
    }

    &:focus,
    &:active {
      box-shadow: ${shadow.focus};
    }
    & .inputContainer {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      align-items: center;
      line-height: 16px;
      gap: ${spacing.gap['03']};

      & > .searchIcon {
        height: 12px;
        width: 18px;

        & > path {
          fill: ${color.neutral['60']};
        }
      }

      & > input {
        border: ${spacing.gap['0']};
        outline: ${spacing.gap['0']};
        flex-grow: 1;
        width: 100%;
        font-size: ${font.size.default};
      }
      & > .closeIcon {
        cursor: pointer;
      }
    }
  }
`;
