import { useMemo } from 'react';

import { PrimaryLayoutModes } from '@align-components/layouts/primary';
import { useTeamMetrics } from '@align-hooks/useTeamMetrics';
import { GoalsSingleComponentServices } from '@align-pages/goals/single/components/goals-single-component';
import { ITeamGoal, TeamGoalInput } from '@align-services/api/types/goalsTypes';
import { IDateRangeWithDisplayName } from '@align-services/dateService';
import { NavigationService } from '@align-services/navigationService';
import { useUserContext } from '@common-context/User';
import { IApiBasicError } from '@common-services/api/common/types/common';
import {
  Goal,
  GoalMetric,
  GoalMetricParams,
  TeamMetricsRequest,
  TeamTree,
} from '@common-services/api/private/generated-from-backend/models';
import { useGoalsForOwnedTeam } from '@common-services/api/private/hooks/useGoals';
import { useOwnedTeamTree, useTeamTree } from '@common-services/api/private/hooks/useTeamTreeData';
import { ISelectedFilters } from '@lib/filter-panel';

export const useTeamsData = (path: string): TeamTree => {
  const { user } = useUserContext();
  const { data: teamsDataRoot } = useOwnedTeamTree(true);
  const fullPath = `${path}/goals`;

  const currentTeam = useMemo(() => {
    return teamsDataRoot ? NavigationService.getCurrentTeam(fullPath, teamsDataRoot) : null;
  }, [teamsDataRoot]);

  const { data: teamsData } = useTeamTree(
    currentTeam?.id,
    { account: user?.defaultAccount.id },
    true
  );

  return teamsData;
};

export const useCurGoalData = (
  goalId: string,
  teamsData: TeamTree
): [Goal, boolean, () => void] => {
  const {
    data: goalData,
    isLoading: goalsLoading,
    refetch: goalsListRefetch,
  } = useGoalsForOwnedTeam(
    {
      team: teamsData?.id,
      only_with_targets: true,
      account: null,
      include_series: true,
    },
    true
  );

  const curGoalData = useMemo(() => goalData?.filter((goal) => goal.id === parseInt(goalId))[0], [
    goalData,
    goalId,
  ]);

  return [curGoalData, goalsLoading, goalsListRefetch];
};

export const useTeamGoal = (
  curGoalData: Goal,
  metricNames: GoalMetric[],
  mode: PrimaryLayoutModes,
  prevDateRange: IDateRangeWithDisplayName,
  newTeamGoals: TeamGoalInput[],
  teamsData: TeamTree,
  filters: ISelectedFilters,
  metricParams: GoalMetricParams
): [ITeamGoal[], boolean, IApiBasicError] => {
  const teamMetricsParams: TeamMetricsRequest = {
    ...filters,
    team: teamsData?.id,
    valid_from: prevDateRange.dateFrom,
    expires_at: prevDateRange.dateTo,
    metrics_with_params: [
      {
        name: metricNames[0],
        ...(metricParams?.threshold
          ? {
              metric_params: {
                threshold: metricParams.threshold,
              },
            }
          : {}),
      },
      ...metricNames.slice(1).map((metricName) => ({
        name: metricName,
        ...(metricParams?.threshold
          ? {
              metric_params: {
                threshold: metricParams.threshold,
              },
            }
          : {}),
      })),
    ],
  };

  const {
    data: metricsValuesData,
    error: metricsValuesError,
    isLoading: metricsValuesLoading,
  } = useTeamMetrics(teamMetricsParams, true);

  const teamGoals = useMemo(() => {
    const targetValues = GoalsSingleComponentServices.convertToTargetValue(
      curGoalData?.team_goal ? [curGoalData?.team_goal] : []
    );
    return metricsValuesData
      ? metricsValuesData.map((metricsValuesDataItem) =>
          GoalsSingleComponentServices.updateMetricsValues(
            metricsValuesDataItem.value,
            mode === PrimaryLayoutModes.CREATE ? newTeamGoals : targetValues
          )
        )
      : null;
  }, [metricsValuesData, curGoalData, mode, newTeamGoals]);

  return [teamGoals, metricsValuesLoading, metricsValuesError];
};
