import { css } from '@emotion/react';

export const badgesWrapperStyles = css`
  display: flex;
`;

export const badgeStyles = ({ color, font, spacing, radius }, extra = false) => css`
  align-items: center;
  align-self: flex-start;
  background-color: ${color.neutral[20]};
  border-radius: ${radius.default};
  color: ${color.neutral[80]};
  display: flex;
  font-size: ${font.size.default};
  font-weight: ${font.weight.light};
  justify-content: center;
  margin-left: ${spacing.gap['02']};
  padding: ${spacing.gap['02']} ${spacing.gap['03']};
  width: fit-content;

  ${extra &&
  `
    background-color: ${color.neutral[60]};
    color: ${color.neutral.white};
  `}
`;

export const bigTextStyles = () => css`
  white-space: nowrap;
`;

export const bigTextNumberStyles = ({ color, font }, isXL) => css`
  color: ${color.text.dark};
  font-size: ${isXL ? font.size.xl : font.size.l};
  font-weight: ${font.weight.bold};
  vertical-align: middle;
`;

export const bigTextExtraStyles = ({ color, font }) => css`
  color: ${color.text.secondary};
  font-size: ${font.size.default};
  font-weight: ${font.weight.default};
  vertical-align: middle;
`;

export const hintContainerStyles = css`
  width: fit-content;
`;

export const dateRangeDayMonthStyles = ({ font }, uppercase) => css`
  font-size: ${font.size.xs};
  text-transform: ${uppercase ? 'uppercase' : 'none'};
`;
