import { css } from '@emotion/react';

export const wrapperStyles = ({ color }, disabled) => css`
  width: 100%;

  &:hover {
    ${!disabled &&
    `
      .ath-raw-input-wrapper {
        border: 1px solid ${color.neutral[80]};
      }
    `}
  }

  ${disabled &&
  `
    cursor: not-allowed;
    opacity: 0.5;

    input,
    .ath-input-placeholder {
      cursor: not-allowed;
    }
  `}

  &:focus-within {
    ${!disabled &&
    `
      .ath-input-label {
        color: ${color.neutral[100]};
      }
    `}
  }
`;

export const labelStyles = ({ color, font, spacing, transition }) => css`
  color: ${color.neutral[80]};
  font-size: ${font.size.default};
  font-weight: ${font.weight.regular};
  line-height: 1.5;
  margin-bottom: ${spacing.gap['03']};
  transition: ${transition.default};
`;

export const inputWrapperStyles = (width, isError) => ({
  color,
  font,
  radius,
  spacing,
  transition,
}) => css`
  background-color: ${color.neutral.white};
  border: 1px solid ${isError ? color.ui.red[100] : color.neutral[40]};
  border-radius: ${radius.default};
  font-size: ${font.size.default};
  font-weight: ${font.weight.regular};
  line-height: 16px;
  overflow: hidden;
  padding: ${spacing.gap['04']};
  position: relative;
  transition: ${transition.default};
  width: ${width ? `${width}px` : '100%'};
`;

export const getRawInputStyle = (width, isNumber) => ({ color, spacing }) => css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: ${spacing.gap['03']};
  height: 16px;

  & > div.ath-input-wrapper {
    position: relative;
    width: 100%;
  }

  input {
    appearance: none;
    border: none;
    color: ${color.neutral[100]};
    padding: 0;
    width: calc(
      ${width ? `calc(${width}px - 2 * ${spacing.gap['04']})` : '100%'} -
        ${isNumber ? spacing.gap['05'] : '0px'}
    );

    &:focus {
      outline: none;

      & + .ath-input-placeholder {
        display: none;
      }
    }

    &:not(:placeholder-shown) + .ath-input-placeholder {
      display: none;
    }
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .ath-input-placeholder {
    position: absolute;
    top: 0;
    margin: ${spacing.gap['0']};
    color: ${color.neutral[80]};
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: ${width ? `calc(${width}px - 2 * ${spacing.gap['04']})` : '100%'};
  }
`;

export const arrowButtonsStyles = ({ color, spacing }) => css`
  bottom: 50%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: ${spacing.gap['02']};
  top: 50%;

  .arrow-button {
    padding: 0 ${spacing.gap['02']};

    &:active {
      background-color: ${color.neutral['40']};
    }
  }
`;

export const inputErrorStyles = ({ color, spacing, font }) => css`
  color: ${color.ui.red[100]};
  padding: ${spacing.gap['02']} 0;
  font-size: ${font.size.default};
  font-weight: ${font.weight.regular};
  line-height: ${font.height.l};
`;
