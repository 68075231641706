import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ANALYTICS_ALIGN_SWITCHER } from '@e2e/core/testIds/align/appearance';
import { Badge } from '@lib/Badge';

import { isActiveLink } from '../../helpers';
import { navButtonStyles, wrapperStyles } from './NavbarButtons.styles';

export const NavbarButtons: React.FC = React.memo(() => {
  const location = useLocation();

  return (
    <div data-pw={ANALYTICS_ALIGN_SWITCHER.BLOCK} css={wrapperStyles}>
      <Link data-pw={ANALYTICS_ALIGN_SWITCHER.ANALYTICS} to="/analytics">
        <div css={navButtonStyles(isActiveLink('/analytics', location.pathname))}>ANALYTICS</div>
      </Link>
      <Link data-pw={ANALYTICS_ALIGN_SWITCHER.ALIGN} to="/align">
        <div css={navButtonStyles(isActiveLink('/align', location.pathname))}>
          ALIGN <Badge text="alpha" />
        </div>
      </Link>
    </div>
  );
});
