import { css } from '@emotion/react';

export const getStyle = ({ color, spacing }) => css`
  padding-top: ${spacing.gap['03']};
  padding-bottom: ${spacing.gap['06']};

  & > div.tabs-wrapper {
    border-bottom: 1px solid ${color.neutral['40']};
    padding: ${spacing.gap['0']};
    height: 59px;
    min-width: fit-content;
    display: flex;
    gap: ${spacing.gap['09']};
    align-items: flex-end;
  }
`;
