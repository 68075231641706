import React, { useCallback, useState, useRef } from 'react';

import { useOnClickOutside } from '@common-hooks/useOnClickOutside';

const ButtonWithSelector = ({
  buttonFn,
  isInitiallyOpen,
  selectorFn,
  alignRight,
  width,
  style,
  onStateChange,
}) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);
  const buttonRef = useRef(null);
  const menuRef = useRef(null);

  const setIsOpenWrapper = useCallback(
    (flag) => {
      setIsOpen(flag);
      if (!!onStateChange) {
        onStateChange(flag);
      }
    },
    [setIsOpen, onStateChange]
  );
  useOnClickOutside([buttonRef, menuRef], (event) => setIsOpenWrapper(false));

  const css = {
    wrapper: style?.wrapper || {
      position: 'relative',
      display: 'flex',
      justifyContent: alignRight ? 'flex-end' : 'flex-start',
    },
    buttomWrapper: style?.buttomWrapper || {},
    menuWrapper: style?.menuWrapper || {
      position: 'absolute',
      top: '36px',
      zIndex: 1,
      width,
    },
  };

  return (
    <div css={css.wrapper}>
      <div css={css.buttonWrapper} ref={buttonRef}>
        {buttonFn(isOpen, setIsOpenWrapper, buttonRef)}
      </div>
      {isOpen && (
        <div css={css.menuWrapper} ref={menuRef}>
          {selectorFn(isOpen, setIsOpenWrapper, menuRef)}
        </div>
      )}
    </div>
  );
};

export default ButtonWithSelector;
