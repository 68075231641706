import { css } from '@emotion/react';

export const subtitleStyles = ({ color, font, spacing }) => css`
  color: ${color.text.dark};
  font-size: ${font.size.default};
  font-weight: ${font.weight.regular};
  margin-bottom: ${spacing.gap['04']};
`;

export const expireSectionStyles = ({ spacing }) => css`
  margin-top: ${spacing.gap['08']};
`;

export const messageWrapperStyles = (color) => ({ font, spacing }) => css`
  align-items: center;
  display: flex;
  margin-bottom: ${spacing.gap['04']};
  margin-top: ${spacing.gap['04']};

  .message {
    color: ${color};
    font-size: ${font.size.default};
    font-weight: ${font.weight.regular};
    margin-left: ${spacing.gap['02']};
  }
`;
