import { css } from '@emotion/react';

export const columnStyles = ({ color, font, spacing }) => css`
  align-items: center;
  display: flex;
  font-size: ${font.size.default};
  font-weight: ${font.weight.regular};

  .prs-count {
    color: ${color.text.dark};
    margin-left: ${spacing.gap['02']};
  }

  .size-added {
    color: ${color.status.success};
    margin-left: ${spacing.gap['05']};
  }

  .size-removed {
    color: ${color.status.danger};
    margin-left: ${spacing.gap['03']};
  }
`;

export const badgeStyles = ({ color, font, radius, spacing }) => css`
  border: 1px solid ${color.ui.green['100']};
  border-radius: ${radius.default};
  color: ${color.ui.green['100']};
  font-size: ${font.size.default};
  font-weight: ${font.weight.medium};
  padding: ${spacing.gap['02']} ${spacing.gap['04']};
  text-align: center;
  text-transform: uppercase;
  width: 159px;
`;

export const tableHeaderStyles = ({ spacing }) => css`
  th:first-of-type {
    padding-left: ${spacing.gap['11']};
  }
`;

export const tableBodyStyles = ({ spacing }) => css`
  && td {
    &:not(:first-of-type) > div {
      justify-content: flex-start;
      padding: ${spacing.gap['0']} ${spacing.gap['04']};
    }
  }
`;

export const filtersStyles = ({ spacing }) => css`
  align-items: baseline;
  display: flex;
  justify-content: space-between;
`;

export const searchStyles = ({ spacing }) => css`
  align-items: baseline;
  display: flex;
  margin-bottom: -${spacing.gap['05']};

  .info-icon {
    margin-left: ${spacing.gap['02']};
  }
`;
