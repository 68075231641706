import { useTheme } from '@emotion/react';
import React from 'react';

import { Info } from '@lib/info';

const SELECTION_STATE = {
  NOT_SELECTED: 0,
  SELECTED: 1,
  INDETERMINATE: 2,
};

const Checkbox = (props) => {
  const { selectionState, onClick, label, info } = props;
  const theme = useTheme();

  let mark;
  switch (selectionState) {
    case SELECTION_STATE.NOT_SELECTED:
      mark = null;
      break;
    case SELECTION_STATE.SELECTED:
      mark = (
        <polygon
          fill={theme.color.ui.turquoise[100]}
          transform="translate(2.000000, 3.000000)"
          points="4.22898961 6.47146254 1.97497639 4.14922711 0.5 5.65814507 4.22898961 9.5 11.5 2.00891795 10.0354108 0.5"
        />
      );
      break;
    case SELECTION_STATE.INDETERMINATE:
      mark = (
        <rect
          stroke={theme.color.ui.turquoise[100]}
          strokeWidth="1"
          x="3"
          y="7.375"
          width="10"
          height="1.25"
          fill={theme.color.ui.turquoise[100]}
        />
      );
      break;
    default:
      mark = null;
  }

  return (
    <label
      data-cy="checkbox-label"
      data-value={selectionState}
      onClick={onClick}
      className="d-flex justify-content-between align-items-center checkbox"
    >
      <svg width="16px" height="16px" viewBox="0 0 16 16">
        <rect
          stroke={theme.color.neutral[60]}
          strokeWidth="1"
          x="0"
          y="0"
          width="16"
          height="16"
          fill={theme.color.neutral.white}
        />
        {mark}
      </svg>
      {label && <span className="checkbox-label">{label}</span>}
      {info && <Info content={info} />}
    </label>
  );
};

export default Checkbox;
export { SELECTION_STATE };
