import { useCallback, useEffect, useMemo, useState } from 'react';

import { IDateRangeInMls, PrsIssuesTableTabs } from '@align-types/common';
import { IUseIssuesForTable } from '@align-types/issues';
import { IApiBasicError } from '@common-services/api/common/types/common';
import { SearchJIRAIssuesResponse } from '@common-services/api/private/generated-from-backend/models';
import {
  GetJIRAIssuesRequest,
  GetJIRAIssuesResponse,
} from '@common-services/api/public/generated-from-backend/models';
import log from '@common-services/logger';

import { useGetIssues } from './useGetIssues';

export function useIssuesForTable(
  accountId: number,
  activeTab: PrsIssuesTableTabs,
  selectedRange: IDateRangeInMls,
  initialCompletedIssues: SearchJIRAIssuesResponse,
  inProgressIssues: SearchJIRAIssuesResponse,
  completedIssuesIsLoading: boolean,
  inProgressIssuesIsLoading: boolean,
  completedIssuesError: IApiBasicError,
  inProgressIssuesError: IApiBasicError
): IUseIssuesForTable {
  const [completedIssuesOffset, setCompletedIssuesOffset] = useState(0);
  const [inProgressIssuesOffset, setInProgressIssuesOffset] = useState(0);
  const [completedIssues, setCompletedIssues] = useState<SearchJIRAIssuesResponse>(null);

  useEffect(() => {
    setCompletedIssuesOffset(0);
    // Completed issues should be reset whenever Timeframe value changes
    setCompletedIssues(null);
  }, [selectedRange]);

  useEffect(() => {
    setCompletedIssuesOffset(0);
    setCompletedIssues(initialCompletedIssues);
  }, [initialCompletedIssues]);

  const issuesOffset = useMemo(
    () =>
      activeTab === PrsIssuesTableTabs.COMPLETED ? completedIssuesOffset : inProgressIssuesOffset,
    [activeTab, completedIssuesOffset, inProgressIssuesOffset]
  );

  const issuesToRequest = useMemo<GetJIRAIssuesRequest['issues']>(() => {
    const issues = activeTab === PrsIssuesTableTabs.COMPLETED ? completedIssues : inProgressIssues;
    const formattedIssues = (issues?.jira_issues || [])
      .slice(issuesOffset, issuesOffset + 10)
      .map((issue) => issue.id);
    return formattedIssues.length ? (formattedIssues as GetJIRAIssuesRequest['issues']) : null;
  }, [activeTab, completedIssues, inProgressIssues, issuesOffset]);

  const { data: issuesData, isLoading: issuesIsLoading, error: issuesIsError } = useGetIssues(
    {
      account: accountId,
      issues: issuesToRequest,
      include: ['github_users', 'jira_users'],
      activeTab,
    },
    true
  );

  const data = useMemo<GetJIRAIssuesResponse>(() => {
    if (
      !(activeTab === PrsIssuesTableTabs.COMPLETED ? completedIssues : inProgressIssues)
        ?.jira_issues?.length
    ) {
      return {
        issues: [],
        include: {
          github_users: {},
          jira_users: [],
        },
      };
    }
    return issuesData ? { ...issuesData } : null;
  }, [activeTab, completedIssues, inProgressIssues, issuesData]);

  const isLoading = useMemo(
    () =>
      activeTab === PrsIssuesTableTabs.COMPLETED
        ? completedIssuesIsLoading
        : inProgressIssuesIsLoading,
    [activeTab, completedIssuesIsLoading, inProgressIssuesIsLoading]
  );

  const error = useMemo(() => completedIssuesError || inProgressIssuesError || issuesIsError, [
    completedIssuesError,
    inProgressIssuesError,
    issuesIsError,
  ]);

  useEffect(() => {
    if (error) {
      log.error(
        'We are sorry, but we can not process your request at the moment. Please try again later.'
      );
    }
  }, [error]);

  const onShowMoreHandler = useCallback(() => {
    if (activeTab === PrsIssuesTableTabs.COMPLETED) {
      setCompletedIssuesOffset((prevOffset) => prevOffset + 10);
    } else {
      setInProgressIssuesOffset((prevOffset) => prevOffset + 10);
    }
  }, [activeTab]);

  return {
    data,
    completedIssues,
    isLoading,
    isLoadingMore: issuesIsLoading,
    error,
    onShowMoreHandler,
  };
}
