import React from 'react';

import { useBreadcrumb } from '@analytics-context/Breadcrumb';
import { ComingSoon } from '@lib/empty/comingSoon';

import InsightsBugs from './bugs';
import InsightsPullRequestSize from './pull-request-size';
import InsightsResolution from './resolution';

const Insights = () => {
  const { subsection: activeStage } = useBreadcrumb();

  const Component =
    {
      'bugs': InsightsBugs,
      'resolution': InsightsResolution,
      'pull-request-size': InsightsPullRequestSize,
    }[activeStage] || ComingSoon;

  return <Component />;
};

export default Insights;
