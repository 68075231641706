import { Redirect } from 'react-router-dom';

import Waiting from '@common-pages/Waiting';
import Login from '@common-pages/auth/Login';
import Logout from '@common-pages/auth/Logout';
import SignUp from '@common-pages/auth/SignUp';
import { Callback } from '@common-pages/auth/callback/callback';
import { AccountModule } from '@routes/account/pages';

export enum AuthRouteEnum {
  INDEX = '/',
  SIGNUP = '/signup',
  CALLBACK = '/callback',
  LOGIN = '/login',
  LOGOUT = '/logout',
  WAITING = '/waiting',
  ACCOUNT = '/account',
}

const authRoutes = [
  {
    route: {
      path: '/i/:code(\\w{8})',
    },
    component: Redirect,
    componentProps: {
      to: {
        pathname: AuthRouteEnum.SIGNUP,
        state: { inviteLink: window.location.href },
      },
    },
  },
  {
    route: {
      path: AuthRouteEnum.CALLBACK,
    },
    component: Callback,
  },
  {
    route: {
      path: AuthRouteEnum.SIGNUP,
    },
    component: SignUp,
  },
  {
    route: {
      path: AuthRouteEnum.LOGIN,
    },
    component: Login,
  },
  {
    route: {
      path: AuthRouteEnum.LOGOUT,
    },
    component: Logout,
  },
  {
    route: {
      path: AuthRouteEnum.WAITING,
    },
    component: Waiting,
  },
  {
    route: {
      path: AuthRouteEnum.ACCOUNT,
    },
    component: AccountModule,
  },
];

export default authRoutes;
