import { css } from '@emotion/react';

export const emptyContainerStyles = ({ font, color, spacing, letter }) => css`
  margin: ${spacing.gap['12']} auto;
  text-align: center;

  > img {
    margin-bottom: ${spacing.gap['08']};
    max-width: 80%;
  }

  > h3 {
    color: ${color.text.dark};
    font-weight: ${font.weight.regular};
    letter-spacing: ${font.spacing.l};
    margin-bottom: ${spacing.gap['04']};
    -webkit-font-smoothing: antialiased;

    &.dark {
      color: ${color.text.dark};
    }
  }

  > p {
    color: ${color.text.secondary};
    font-size: ${font.size.m};
    font-weight: ${font.weight.light};
    margin: ${spacing.gap['0']};
  }

  > a,
  button {
    margin: 0 auto;
    margin-top: ${spacing.gap['05']};
  }
`;
