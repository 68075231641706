import includes from 'lodash/includes';
import React from 'react';

import DistributionChart from '@analytics-components/charts/DistributionChart';
import TimeSeries from '@analytics-components/charts/TimeSeries';
import { BigText } from '@analytics-components/charts/Tooltip';
import { GranularityEnum } from '@analytics-constants';
import { LooseObject } from '@analytics-types/common';
import { dateTime } from '@common-services/dateService';
import { theme } from '@styles/theme';
import { hexToRGBA } from '@utils/colors';

const STAGE_METRICS_CHART_HEIGHT = 280;
const MAX_NUMBER_TICKS = 6;
const CHART_MARGIN_BOTTOM = 40;
const CHART_MARGIN_LEFT = 100;

interface ChartType {
  Component: any;
  name: string;
  props: {
    data: any;
    extra: any;
    timeMode?: boolean;
  };
}

const buildMultiChartConf = (data: any, isLoading: boolean, color: string): ChartType[] => {
  if (isLoading) {
    return [];
  } else {
    const tickFormat =
      data.granularity === GranularityEnum.MONTH ? dateTime.month : dateTime.monthDay;
    const tooltip: LooseObject = {
      renderBigFn: (v) => <BigText content={dateTime.human(v.y, 1)} />,
    };
    if (includes([GranularityEnum.MONTH, GranularityEnum.WEEK], data.granularity)) {
      tooltip.dateRange = {
        from: data.interval.from,
        to: data.interval.to,
      };
      tooltip.granularity = data.granularity;
    }
    return [
      {
        name: 'Timeline',
        Component: TimeSeries,
        props: {
          data: data.timeseries,
          extra: {
            color,
            fillColor: hexToRGBA(color, 0.2),
            height: STAGE_METRICS_CHART_HEIGHT,
            axisKeys: {
              x: 'x',
              y: 'y',
            },
            maxNumberOfTicks: MAX_NUMBER_TICKS,
            average: {
              value: data.average * 1000,
              color: theme.color.ui.blue[100],
              renderFn: tooltip.renderBigFn,
            },
            ticks: {
              x: {
                tickFormat,
              },
            },
            tooltip,
            margin: {
              left: CHART_MARGIN_LEFT,
              bottom: CHART_MARGIN_BOTTOM,
            },
          },
          timeMode: true,
        },
      },
      {
        name: 'Distribution',
        Component: DistributionChart,
        props: {
          data: data.distribution,
          extra: {
            color,
            fillColor: hexToRGBA(color, 0.7),
            height: STAGE_METRICS_CHART_HEIGHT,
            maxNumberOfTicks: MAX_NUMBER_TICKS,
            axisLabels: { y: 'Pull Requests' },
            margin: { bottom: CHART_MARGIN_BOTTOM, left: CHART_MARGIN_LEFT },
            tooltip: {
              align: {
                horizontal: 'auto',
                vertical: 'top',
              },
            },
          },
        },
      },
    ];
  }
};

export default buildMultiChartConf;
