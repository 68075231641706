import React, { useCallback, useMemo } from 'react';

import { IDropdownSelectorBodyProps } from '@lib/dropdown-selector/components/dropdown-selector-body/dropdownSelectorBody.types';
import { selectorWrapperStyles } from '@lib/dropdown-selector/ui/styles';
import { SelectorTs } from '@lib/selector-ts';

import { DropdownSelectorBodyServices } from './dropdownSelectorBody.services';

/*TODO: remove TS postfix after DropdownSelector full refactoring*/
export const DropdownSelectorBodyTs = React.forwardRef<HTMLDivElement, IDropdownSelectorBodyProps>(
  ({ options, applied, onApply, onCancel }, ref) => {
    const convertedApplied = useMemo(() => {
      return applied.map((appliedSingle) => appliedSingle.value);
    }, [applied]);
    const convertedOptions = useMemo(() => {
      return options ? DropdownSelectorBodyServices.convertOptions(options, convertedApplied) : [];
    }, [options, convertedApplied]);
    const onApplyHandler = useCallback(
      (selectedId: string[] = []) => {
        const selectedOptions = options.filter((option) => selectedId?.includes(option.value));
        onApply({ applied: selectedOptions });
      },
      [options]
    );
    return (
      <div css={selectorWrapperStyles} ref={ref}>
        <SelectorTs optionsInit={convertedOptions} onApply={onApplyHandler} onCancel={onCancel} />
      </div>
    );
  }
);
