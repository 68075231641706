import { css } from '@emotion/react';

export const wrapperStyles = ({ color, radius, spacing }) => css`
  display: flex;

  .condition {
    height: 38px;
    width: 38px;
    background: ${color.neutral['40']};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${radius.default};
    margin-right: ${spacing.gap['04']};
  }
`;
