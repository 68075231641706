import { IUrlParams } from '@common-services/api/common/types/apiClient';
import { publicApi } from '@common-services/api/public';
import {
  FilteredJIRAStuff,
  FilteredLabels,
  FilterJIRAStuff,
  FilterLabelsRequest,
  JIRAProject,
} from '@common-services/api/public/generated-from-backend/models';

export const jiraFilter = async (
  params: FilterJIRAStuff,
  token: string
): Promise<FilteredJIRAStuff> => {
  return publicApi.filters.getJiraFilter(params, token, {
    message: `Cannot get jiraFilter for accountId ${params.account}`,
  });
};

export const jiraProjects = async (
  urlParams: IUrlParams<'accountId'>,
  token: string
): Promise<JIRAProject[]> => {
  return publicApi.filters.getJiraProjects(urlParams, token, {
    message: `Cannot get jiraProjects for accountId ${urlParams.accountId}`,
  });
};

export const labelsFilter = async (
  params: FilterLabelsRequest,
  token: string
): Promise<FilteredLabels> => {
  return publicApi.filters.getLabelsFilter(params, token, {
    message: `Cannot get labelsFilter for accountId ${params.account}`,
  });
};

export * as FilterServices from './filters';
