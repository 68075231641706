import React from 'react';

import { useNavigation } from '@align-components/layouts/primary/context';
import { PrimaryLayoutTabs } from '@align-components/layouts/primary/context.types';
import { useGoalsForOwnedTeam } from '@common-services/api/private/hooks/useGoals';
import { TABS_SECTION } from '@e2e/core/testIds/align/appearance';
import { Tab } from '@lib/Tabs/components/Tab';

import { getStyle } from './NavigationTabs.styles';

export const NavigationTabs: React.FC = React.memo(() => {
  const { section, currentTeam } = useNavigation();
  const { isLoading, data: goalsData } = useGoalsForOwnedTeam(
    {
      team: currentTeam.id,
      only_with_targets: true,
      include_series: true,
      account: null,
    },
    true
  );

  return (
    <div css={getStyle}>
      <div className="tabs-wrapper" data-pw={TABS_SECTION.BLOCK}>
        <Tab
          label={PrimaryLayoutTabs.DASHBOARD}
          isActive={section === PrimaryLayoutTabs.DASHBOARD}
          route={PrimaryLayoutTabs.DASHBOARD}
          dataPw={TABS_SECTION.DASHBOARD}
        />
        <Tab
          label={PrimaryLayoutTabs.GOALS}
          isActive={section === PrimaryLayoutTabs.GOALS}
          value={isLoading ? null : goalsData.length}
          route={PrimaryLayoutTabs.GOALS}
          dataPw={TABS_SECTION.GOALS}
        />
        <Tab
          label={PrimaryLayoutTabs.TEAMS}
          isActive={section === PrimaryLayoutTabs.TEAMS}
          route={PrimaryLayoutTabs.TEAMS}
          dataPw={TABS_SECTION.TEAMS}
        />
      </div>
    </div>
  );
});
