import React, { useMemo } from 'react';

import { PrimaryLayout, PrimaryLayoutModes } from '@align-components/layouts/primary';
import { PrimaryLayoutTabs } from '@align-components/layouts/primary/context.types';
import { metricsConfig } from '@align-constants';
import { useChartInteraction } from '@align-hooks/useChartInteraction';
import { useMergeIssuesData } from '@align-hooks/useMergeIssuesData';
import { useMergePrData } from '@align-hooks/useMergePrData';
import { useTableTabs } from '@align-hooks/useTableTabs';
import { GoalDetails } from '@align-pages/goals/single/components/goal-details';
import { GoalSummary } from '@align-pages/goals/single/components/goal-summary';
import { IMetricsConfigNames } from '@align-types/constants';
import { useUserContext } from '@common-context/User';
import { Card } from '@lib/Card';
import { Spinner } from '@lib/Spinner';

import { GoalNotStarted } from '../goal-not-started';
import { GoalViewTitle } from '../goal-view-title';
import { cardWrapperStyles, goalContainerStyles, spinnerWrapper } from './goalViewComponent.styles';
import { useComputedIssues } from './hooks/useComputedIssues';
import { useComputedPullRequests } from './hooks/useComputedPullRequests';
import { useSingleGoalData } from './hooks/useSingleGoalData';
import { useTarget } from './hooks/useTarget';

const GoalViewComponent: React.FC = React.memo(() => {
  const { isGod } = useUserContext();
  const {
    curGoalData,
    dateRangeInit,
    metricsValues,
    isLoading: goalDataIsLoading,
    templateMetric,
  } = useSingleGoalData();

  const { targetValue, targetPercentage } = useTarget(curGoalData, templateMetric);

  const { activeTab, changeTab } = useTableTabs();

  const { selectedRange, handleChartPointSelect, handleRemoveTimeframe } = useChartInteraction(
    changeTab
  );

  const {
    data: prTableDataPortion,
    isLoading: prTableIsLoading,
    isLoadingMore: prTableIsLoadingMore,
    completedPrsCount,
    inProgressPrsCount,
    onShowMoreHandler: onShowMorePrsHandler,
  } = useComputedPullRequests(curGoalData, templateMetric, activeTab, selectedRange);

  const {
    data: issuesTableDataPortion,
    isLoading: issuesTableIsLoading,
    isLoadingMore: issuesTableIsLoadingMore,
    completedIssuesCount,
    inProgressIssuesCount,
    onShowMoreHandler: onShowMoreIssuesHandler,
  } = useComputedIssues(curGoalData, templateMetric, activeTab, selectedRange);

  const handleShowMore = useMemo(
    () =>
      metricsConfig[templateMetric]?.jiraMetricField
        ? onShowMoreIssuesHandler
        : onShowMorePrsHandler,
    [templateMetric, onShowMorePrsHandler, onShowMoreIssuesHandler]
  );

  const mergedPrData = useMergePrData(prTableDataPortion, activeTab, selectedRange);
  const mergedIssuesData = useMergeIssuesData(issuesTableDataPortion, activeTab, selectedRange);

  const isGoalNotStarted = useMemo(() => new Date(curGoalData?.valid_from) > new Date(), [
    curGoalData,
  ]);

  // TODO: Remove the god-mode guard when Wait Time PRs are ready to be exposed to public
  const isTableVisible = useMemo(
    () =>
      metricsConfig[templateMetric]?.name !== IMetricsConfigNames.prWaitFirstReviewTimeThreshold ||
      isGod,
    [isGod, templateMetric]
  );

  return (
    <PrimaryLayout
      tab={PrimaryLayoutTabs.GOALS}
      mode={PrimaryLayoutModes.VIEW}
      dateRangeInit={dateRangeInit}
      selectedMetric={templateMetric as IMetricsConfigNames}
      onNewGoalNameChange={() => {}}
    >
      <div css={cardWrapperStyles}>
        <Card>
          {isGoalNotStarted ? (
            <GoalNotStarted startDate={curGoalData?.valid_from} />
          ) : (
            <>
              <div
                css={goalContainerStyles(
                  goalDataIsLoading,
                  Boolean(mergedPrData?.data?.length === 0)
                )}
              >
                {goalDataIsLoading && (
                  <div css={spinnerWrapper}>
                    <Spinner loading />
                  </div>
                )}
                {curGoalData && metricsValues && (
                  <>
                    <GoalViewTitle
                      targetValue={targetValue}
                      metricName={templateMetric}
                      targetPercentage={targetPercentage}
                    />
                    {curGoalData && (
                      <GoalSummary
                        goal={curGoalData}
                        metricsValues={metricsValues}
                        templateMetric={templateMetric}
                        onPointSelect={handleChartPointSelect}
                      />
                    )}
                  </>
                )}
              </div>
              {isTableVisible && (
                <GoalDetails
                  activeTab={activeTab}
                  completedCount={completedPrsCount || completedIssuesCount}
                  inProgressCount={inProgressPrsCount || inProgressIssuesCount}
                  prData={mergedPrData}
                  issuesData={mergedIssuesData}
                  goal={curGoalData}
                  isLoading={prTableIsLoading || issuesTableIsLoading}
                  isLoadingMore={prTableIsLoadingMore || issuesTableIsLoadingMore}
                  selectedRange={selectedRange}
                  templateMetric={templateMetric}
                  onChangeTab={changeTab}
                  onShowMoreHandler={handleShowMore}
                  onRemoveTimeframe={handleRemoveTimeframe}
                />
              )}
            </>
          )}
        </Card>
      </div>
    </PrimaryLayout>
  );
});

export { GoalViewComponent };
