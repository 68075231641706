import _ from 'lodash';

import { TimeToMerge } from '@analytics-components/charts/Tooltip';
import VerticalBarChart from '@analytics-components/charts/VerticalBarChart';
import { firstNRepos } from '@analytics-components/insights/Helper';
import { SimpleKPI } from '@analytics-components/insights/KPI';
import { fetchPRsMetrics, fetchDevsMetrics } from '@analytics-services/api';
import { getFeature, featuresList } from '@analytics-services/flags';
import { dateTime } from '@common-services/dateService';
import { github } from '@common-services/format';
import * as NumberService from '@common-services/numberService';
import { theme } from '@styles/theme';

const mergeDelays = {
  fetcher: async (api, cachedData, apiContext) => {
    const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, apiContext.features)
      ?.parameters?.value;
    const fetchChartData = async () => {
      const granularity = 'all';
      const metrics = ['merging-time', 'merging-count'];

      return await fetchPRsMetrics(
        api,
        apiContext.account,
        [granularity],
        apiContext.interval,
        metrics,
        {
          repositories: apiContext.repositories,
          with: {
            author: _(apiContext.contributors).uniqBy('login').value(),
          },
          labels_include: apiContext.labels,
          ...(excludedLabels ? { labels_exclude: excludedLabels } : {}),
          jira: { epics: apiContext.epics, issue_types: apiContext.issueTypes },
        },
        'repositories',
        apiContext.excludeInactive
      );
    };

    const fetchKPIsData = async () => {
      const metrics = ['prs-merged'];

      // TODO: This call is incorrect as it needs to groupBy repositories, but
      // this is not possible right now due to performance issue on big organization.
      // See: https://athenianco.atlassian.net/browse/DEV-946
      // The consequence is that the 'Number of Mergers' KPI is incorrect.
      return fetchDevsMetrics(api, apiContext.account, ['all'], apiContext.interval, metrics, {
        repositories: apiContext.repositories,
        developers: _(apiContext.contributors).uniqBy('login').value(),
        labels_include: apiContext.labels,
        ...(excludedLabels ? { labels_exclude: excludedLabels } : {}),
        jira: { epics: apiContext.epics, issue_types: apiContext.issueTypes },
      });
    };

    return Promise.resolve({
      chartData: await fetchChartData(),
      KPIsData: await fetchKPIsData(),
    });
  },
  plumber: (fetchedData, cachedData, apiContext) => {
    const repos = _(fetchedData.chartData.calculated)
      .map((v) => ({
        repo: github.repoName(v.for.repositories[0]),
        prsMerged: v.values[0].values[1] || 0,
      }))
      .filter((v) => v.prsMerged > 0)
      .map((v) => v.repo)
      .value();
    const totalRepos = repos.length;

    return {
      chartData: _(fetchedData.chartData.calculated)
        .map((v) => ({
          repo: github.repoName(v.for.repositories[0]),
          prsMerged: v.values[0].values[1] || 0,
          delay: v.values[0].values[0] || 0,
          tooltip: {
            repository:
              github.repoOrg(v.for.repositories[0]) + '/' + github.repoName(v.for.repositories[0]),
            time: dateTime.human((v.values[0].values[0] || 0) * 1000),
          },
        }))
        .filter((v) => _(repos).includes(v.repo))
        .orderBy(['prsMerged'], ['desc'])
        .take(firstNRepos)
        .value(),
      KPIsData: {
        avgTimeToMerge: _(fetchedData.chartData.calculated)
          .map((v) => (v.values[0].values[0] || 0) * 1000)
          .mean(),
        avgUniqueMergesPerRepo:
          _(fetchedData.KPIsData.calculated)
            .map((v) => ({
              repo: github.repoName(v.for.repositories[0]),
              mergers: _(v.values)
                .flatMap()
                .map((v) => v.values)
                .filter((v) => v > 0)
                .value().length,
            }))
            .filter((v) => _(repos).includes(v.repo))
            .sumBy('mergers') / totalRepos,
      },
      axisKeys: {
        x: 'repo',
        y: 'delay',
      },
      totalPRs: fetchedData.length,
    };
  },
  factory: (computed) => ({
    meta: {
      title: 'Merge Time',
      description:
        'Identify in which repositories pull requests take longest from review approval to being merged.',
    },
    content: [
      {
        empty: computed.chartData.length === 0,
        chart: {
          component: VerticalBarChart,
          params: {
            data: computed.chartData,
            extra: {
              axisFormat: {
                tickFormat: {
                  y: (v) => dateTime.human(v * 1000),
                },
              },
              axisKeys: computed.axisKeys,
              height: '350',
              margin: { bottom: 80, left: 80 },
              maxNumberOfTicks: 4,
              color: theme.color.stage.merge,
              tooltip: {
                align: {
                  horizontal: 'auto',
                  vertical: 'top',
                },
                template: TimeToMerge,
              },
            },
          },
        },
        kpis: [
          {
            title: { text: 'Number of Mergers', bold: true },
            subtitle: { text: 'Per Repository' },
            component: SimpleKPI,
            params: {
              value: NumberService.round(computed.KPIsData.avgUniqueMergesPerRepo, 1),
            },
          },
          {
            title: { text: 'Average Merge Time', bold: true },
            subtitle: { text: 'Per Repository' },
            component: SimpleKPI,
            params: {
              value: dateTime.human(computed.KPIsData.avgTimeToMerge, 1),
            },
          },
        ],
      },
    ],
  }),
};

export default mergeDelays;
