import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { AccountLayout } from '@common-pages/account/layout/accountLayout';
import { UserDetailsForm } from '@common-pages/account/user-details/components/user-details-form/userDetailsForm';
import { UserDetailsPutParams } from '@common-pages/account/user-details/userDetails.types';
import { ICallbackHistoryState } from '@common-pages/auth/callback/callback';
import { useInvitationAccept } from '@common-services/api/public/hooks/useRegistrationData';
import log from '@common-services/logger';
import { AuthRouteEnum } from '@routes/authRoutes';

export const UserDetails: React.FC = () => {
  const location = useLocation();
  const locationState = location.state as ICallbackHistoryState;
  const {
    mutate: invitationAcceptMutate,
    isError: invitationAcceptError,
    isSuccess: invitationAcceptIsSuccess,
  } = useInvitationAccept();

  const submitHandler = useCallback(async (data: UserDetailsPutParams) => {
    invitationAcceptMutate({
      ...data,
      url: locationState?.inviteLink || null,
    });
  }, []);

  useEffect(() => {
    if (invitationAcceptIsSuccess) {
      // Legacy: DON'T change with a client-side routing. See DEV-1620.
      window.location.replace(
        locationState.type === 'admin' ? AuthRouteEnum.WAITING : AuthRouteEnum.INDEX
      );
    }
  }, [invitationAcceptIsSuccess]);

  useEffect(() => {
    if (invitationAcceptError) {
      log.error(
        'We are sorry, but we can not process your request at the moment. Please try again later.'
      );
    }
  }, [invitationAcceptError]);

  return (
    <AccountLayout>
      <UserDetailsForm initName={locationState?.name || ''} onSubmit={submitHandler} />;
    </AccountLayout>
  );
};
