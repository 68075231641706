import { useTheme } from '@emotion/react';
import { useLocalStorage, writeStorage } from '@rehooks/local-storage';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Accordion from '@analytics-components/Accordion';
import { LOADING } from '@analytics-components/StatusIndicator';
import { filtersStorageKeys } from '@analytics-context/Filters';
import { useApi } from '@analytics-hooks';
import { fetchWorkTypes, removeWorkType, setWorkType } from '@analytics-services/api';
import { SettingsGroup } from '@common-pages/Settings';
import { Button } from '@lib/Button';
import { Input } from '@lib/Input';
import Modal from '@lib/Modal';
import { Spinner } from '@lib/Spinner';
import { Circle } from '@lib/circle';
import { ColorSelect } from '@lib/color-select';
import { NoWorkTypes } from '@lib/empty/noWorkTypes';
import { icons } from '@lib/icon';

import AddingRule from './components/AddingRule';
import ContextMenu from './components/ContextMenu';
import Rule from './components/Rule';
import {
  dividerStyles,
  headerStyles,
  headerTitleStyles,
  modalContentStyles,
  spinnerWrapperStyles,
} from './styles';

const ModalContent = ({ color, name, onColorChange, onNameChange }) => {
  const theme = useTheme();
  return (
    <div css={modalContentStyles}>
      <Input
        label="Name"
        placeholder="Choose a name that is explanatory of your work type"
        initialValue={name}
        tabIndex={0}
        width={324}
        onChange={onNameChange}
      />
      <div css={{ width: theme.spacing.gap['04'] }} />
      <ColorSelect
        colors={theme.color.sets.colorSelection}
        initialValue={color}
        tabIndex={1}
        onChange={onColorChange}
      />
    </div>
  );
};

const AddButton = ({ onClickAdd }) => {
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState(null);
  const [color, setColor] = useState(theme.color.sets.colorSelection[0]);

  const handleClick = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const handleAddWorkType = useCallback(() => {
    onClickAdd(name, color);
    setColor(theme.color.sets.colorSelection[0]);
    setName(null);
  }, [name, color]);

  return (
    <>
      <Button icon={icons.add} label="Add Work Type" onClick={handleClick} />
      <Modal
        content={
          <ModalContent color={color} name={name} onColorChange={setColor} onNameChange={setName} />
        }
        isOpen={isOpen}
        primaryButtonText="Add"
        secondaryButtonText="Cancel"
        title="Add a New Work Type"
        onPrimaryClick={handleAddWorkType}
        setOpen={setIsOpen}
      />
    </>
  );
};

const WorkTypes = () => {
  const { api, ready: apiReady, context } = useApi(true, false);
  const [workTypes, setWorkTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [colorChangeOrder, setColorChangeOrder] = useState(null);

  const persistedKey = useMemo(() => filtersStorageKeys.WORK_TYPES(context.account), [context]);
  const [storedWorkTypes] = useLocalStorage(persistedKey);

  const fetchData = useCallback(() => {
    if (!apiReady) return;
    fetchWorkTypes(api, context.account).then((result) => {
      setWorkTypes(result?.length ? result.sort((a, b) => (a.name > b.name ? 1 : -1)) : []);
      setIsLoading(false);
    });
  }, [api, apiReady, context]);

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [apiReady]);

  useEffect(() => {
    if (!colorChangeOrder) return;
    const rules = workTypes.find((wt) => wt.name === colorChangeOrder.name)?.rules;
    setWorkType(api, context.account, colorChangeOrder.name, colorChangeOrder.color, rules).then(
      () => {
        fetchData();
      }
    );
  }, [colorChangeOrder]);

  const handleAddWorkType = useCallback(
    (name, color) => {
      if (!apiReady) return;
      setWorkType(api, context.account, name, color).then(() => {
        fetchData();
      });
    },
    [api, apiReady, context]
  );

  const handleColorChange = useCallback(
    (color, workType) => {
      if (!apiReady || color.substring(1) === workType.color) return;
      setColorChangeOrder({ color, name: workType.name });
    },
    [api, context]
  );

  const handleRemoveWorkType = useCallback(
    (workType) => () => {
      const name = workType.name;
      if (!apiReady) return;
      removeWorkType(api, context.account, workType.name).then(() => {
        fetchData();

        // remove work type from local storage too
        if (storedWorkTypes?.indexOf(name) >= 0) {
          const newValue = storedWorkTypes.filter((item) => item !== name);
          writeStorage(persistedKey, newValue);
        }
      });
    },
    [api, apiReady, context, persistedKey, storedWorkTypes]
  );

  const handleRename = useCallback(
    (name, workType) => {
      const oldName = workType.name;
      if (!apiReady) return;
      removeWorkType(api, context.account, workType.name).then(() => {
        setWorkType(api, context.account, name, workType.color, workType.rules).then(() => {
          fetchData();

          // update local storage to be consistent with work type names
          if (storedWorkTypes?.indexOf(oldName) >= 0) {
            const newValue = storedWorkTypes.filter((item) => item !== oldName);
            newValue.push(name);
            writeStorage(persistedKey, newValue);
          }
        });
      });
    },
    [api, apiReady, context, persistedKey, storedWorkTypes]
  );

  const handleAddRule = useCallback(
    (ruleName, workType) => {
      if (!apiReady) return;
      setWorkType(api, context.account, workType.name, workType.color, [
        ...workType.rules.filter((item) => item.name !== ruleName),
        { name: ruleName, body: {} },
      ]).then(() => {
        fetchData();
      });
    },
    [api, apiReady, context]
  );

  const handleRemoveRule = useCallback(
    (ruleName, workType) => {
      if (!apiReady) return;
      setWorkType(
        api,
        context.account,
        workType.name,
        workType.color,
        workType.rules.filter((item) => item.name !== ruleName)
      ).then(() => {
        fetchData();
      });
    },
    [api, apiReady, context]
  );

  return (
    <SettingsGroup extra={<AddButton onClickAdd={handleAddWorkType} />} title="Work Types">
      {isLoading ? (
        <div css={spinnerWrapperStyles}>
          <Spinner status={LOADING} />
        </div>
      ) : !workTypes.length ? (
        <NoWorkTypes />
      ) : (
        workTypes.map((wt, index) => (
          <React.Fragment key={wt.name}>
            <Accordion
              header={{
                primary: wt.name,
                secondary: wt.rules.length
                  ? `${wt.rules.length} rule${wt.rules.length === 1 ? '' : 's'}`
                  : 'No rules',
                render: (DefaultRenderer, header) => (
                  <div css={headerStyles}>
                    <div css={headerTitleStyles}>
                      <Circle color={`#${wt.color}`} size={12} />
                      <DefaultRenderer header={header} />
                    </div>
                    <ContextMenu
                      workType={wt}
                      onColorChange={(color) => handleColorChange(color, wt)}
                      onRemove={handleRemoveWorkType(wt)}
                      onRename={(name) => handleRename(name, wt)}
                    />
                  </div>
                ),
              }}
              body={{
                render: (row) => <Rule rule={row} workType={wt} onRemove={handleRemoveRule} />,
              }}
              footer={{
                render: () => (
                  <AddingRule addedRules={wt.rules} workType={wt} onAddRule={handleAddRule} />
                ),
              }}
              rows={wt.rules}
            />
            {index < workTypes.length - 1 && <div css={dividerStyles} />}
          </React.Fragment>
        ))
      )}
    </SettingsGroup>
  );
};

export default WorkTypes;
