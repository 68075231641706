import { IEnvironmentsParams } from '@common-services/api/common/types/environments';
import { publicApi } from '@common-services/api/public';
import {
  FilteredEnvironment,
  FilterEnvironmentsRequest,
} from '@common-services/api/public/generated-from-backend/models';
import { DateService } from '@common-services/dateService';

export const fetchEnvironments = async (
  params: IEnvironmentsParams,
  token: string
): Promise<FilteredEnvironment[]> => {
  const body: FilterEnvironmentsRequest = {
    account: params.account,
    date_from: params.dateInterval.from.format('YYYY-MM-DD'),
    date_to: params.dateInterval.to.format('YYYY-MM-DD'),
    repositories: params.repositories,
    timezone: DateService.getOffset(),
  };
  return publicApi.environments.fetchEnvironments(body, token, {
    message: `Cannot get environments for accountId ${params.account}`,
  });
};
