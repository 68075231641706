import React, { useCallback, useMemo, useState } from 'react';

import { useDashboardStore } from '@align-pages/dashboard/store';
import { Input } from '@lib/Input';
import { Checkbox } from '@lib/checkbox';

import { wrapperStyles } from './chartBreakdowns.styles';

export const ChartBreakdowns: React.FC = React.memo(() => {
  const [filterText, setFilterText] = useState(null);
  const [breakdownOptions, onBreakdownOptionsChange] = useDashboardStore((state) => [
    state.breakdownOptions,
    state.onBreakdownOptionsChange,
  ]);

  const filteredBreakdownOptions = useMemo(() => {
    return filterText
      ? breakdownOptions.filter(({ name }) => name.toLowerCase().includes(filterText.toLowerCase()))
      : breakdownOptions;
  }, [breakdownOptions, filterText]);

  const onChangeHandler = useCallback((value, isChecked) => {
    onBreakdownOptionsChange(value, isChecked);
  }, []);

  return (
    <div css={wrapperStyles}>
      <div className="search">
        <Input withSearchIcon placeholder="Search..." onChange={setFilterText} />
      </div>
      <div className="list">
        {filteredBreakdownOptions?.map(({ name, value, selected, color }, index) => (
          <div className="list-item" key={index}>
            <Checkbox
              label={name?.toString()}
              value={value}
              isChecked={selected}
              color={color}
              onChange={() => onChangeHandler(value, !selected)}
            />
          </div>
        ))}
      </div>
    </div>
  );
});
