import { useMutation } from 'react-query';

import { useCurrentTeam } from '@align-hooks/useTeams';
import { IUseMutationHook } from '@align-types/common';
import { useAuth0 } from '@common-context/Auth0';
import { useDemoCookie } from '@common-pages/Demo';
import { IApiBasicError } from '@common-services/api/common/types/common';
import { DashboardServices } from '@common-services/api/private/services/dashboard';
import { IDashboardEditChartParams } from '@common-services/api/private/types/dashboard';
import { CreatedIdentifier } from '@common-services/api/public/generated-from-backend/models';

export function useEditChart(): IUseMutationHook<
  CreatedIdentifier,
  IApiBasicError,
  IDashboardEditChartParams
> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();
  const currentTeam = useCurrentTeam();

  const editChart = async (params: IDashboardEditChartParams): Promise<CreatedIdentifier> => {
    const token: string = isDemo === false ? await getTokenSilently() : null;

    return DashboardServices.editChart(
      {
        dashboardId: 0,
        teamId: currentTeam.id,
        chartId: params.chartId,
      },
      {
        filters: params.filters,
        group_by: params.group_by,
        name: params.name,
        time_interval: params.time_interval,
      },
      token
    );
  };

  const { mutate, isLoading, data, error, isError, isSuccess } = useMutation<
    CreatedIdentifier,
    IApiBasicError,
    IDashboardEditChartParams
  >(editChart);

  return { mutate, isLoading, data, error, isError, isSuccess };
}
