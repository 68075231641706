export const gapSpacing = {
  '0': '0',
  '1px': '1px',
  '01': '0.2rem', // Equivalent to 2px
  '02': '0.4rem', // Equivalent to 4px
  '03': '0.8rem', // Equivalent to 8px
  '04': '1.2rem', // Equivalent to 12px
  '05': '1.6rem', // Equivalent to 16px
  '06': '2rem', // Equivalent to 20px
  '07': '2.4rem', // Equivalent to 24px
  '08': '3.2rem', // Equivalent to 32px
  '09': '4rem', // Equivalent to 40px
  '10': '4.8rem', // Equivalent to 48px
  '11': '5.6rem', // Equivalent to 56px
  '12': '6.4rem', // Equivalent to 64px
};
