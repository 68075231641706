/**
 * {{ title }}
 * Server version: {{ server_version }} built on {{ build_date }} @ [{{ commit }}](https://github.com/athenianco/athenian-api/commit/{{ commit }})  Origin GitHub repository: [athenianco/api-spec](https://github.com/athenianco/api-spec).  Authorization persists between sessions. 🛡️ means that the endpoint requires account admin privileges. 👑 means that the endpoint is only accessible to Athenian authorities. <details> <summary>List of common server errors.</summary> * __500__ endpoint crashed. We reported the incident to Sentry and will fix it soon! Please include the value of `instance` in the response if you contact the support. Repeating the request will not help, most probably. * __501__ some functions are missing, either because they are not implemented yet or a non-critical runtime dependency is not satisfied. Repeating the request will not help, guaranteed. * __502__ server crashed badly, either due to a memory access violation in native code or running out of memory. We reported this incident to Sentry and will fix it soon! Try repeating the request. * __503__ server has not fully launched yet, e.g. hasn't connected to the database; server is shutting down; we are experiencing a partial outage. Try repeating the request. * __504__ endpoint took too much time and was interrupted. We reported the incident to Sentry and will see how to improve the performance. Repeating the request will not help, most probably. </details>
 *
 * Contact: admin@athenian.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AcceptedInvitation from '../model/AcceptedInvitation';
import Account from '../model/Account';
import AccountUserChangeRequest from '../model/AccountUserChangeRequest';
import CalculatedCodeCheckHistogram from '../model/CalculatedCodeCheckHistogram';
import CalculatedCodeCheckMetrics from '../model/CalculatedCodeCheckMetrics';
import CalculatedDeploymentMetric from '../model/CalculatedDeploymentMetric';
import CalculatedDeveloperMetrics from '../model/CalculatedDeveloperMetrics';
import CalculatedJIRAHistogram from '../model/CalculatedJIRAHistogram';
import CalculatedJIRAMetricValues from '../model/CalculatedJIRAMetricValues';
import CalculatedPullRequestHistogram from '../model/CalculatedPullRequestHistogram';
import CalculatedPullRequestMetrics from '../model/CalculatedPullRequestMetrics';
import CalculatedReleaseMetric from '../model/CalculatedReleaseMetric';
import CodeBypassingPRsMeasurement from '../model/CodeBypassingPRsMeasurement';
import CodeCheckHistogramsRequest from '../model/CodeCheckHistogramsRequest';
import CodeCheckMetricsRequest from '../model/CodeCheckMetricsRequest';
import CodeFilter from '../model/CodeFilter';
import CommitsList from '../model/CommitsList';
import Contributor from '../model/Contributor';
import CreateTokenRequest from '../model/CreateTokenRequest';
import CreatedIdentifier from '../model/CreatedIdentifier';
import CreatedToken from '../model/CreatedToken';
import DeleteEventsCacheRequest from '../model/DeleteEventsCacheRequest';
import DeploymentMetricsRequest from '../model/DeploymentMetricsRequest';
import DeploymentNotification from '../model/DeploymentNotification';
import DeveloperMetricsRequest from '../model/DeveloperMetricsRequest';
import DeveloperSummary from '../model/DeveloperSummary';
import DiffReleasesRequest from '../model/DiffReleasesRequest';
import DiffedReleases from '../model/DiffedReleases';
import FilterCodeChecksRequest from '../model/FilterCodeChecksRequest';
import FilterCommitsRequest from '../model/FilterCommitsRequest';
import FilterContributorsRequest from '../model/FilterContributorsRequest';
import FilterDeploymentsRequest from '../model/FilterDeploymentsRequest';
import FilterEnvironmentsRequest from '../model/FilterEnvironmentsRequest';
import FilterJIRAStuff from '../model/FilterJIRAStuff';
import FilterLabelsRequest from '../model/FilterLabelsRequest';
import FilterPullRequestsRequest from '../model/FilterPullRequestsRequest';
import FilterReleasesRequest from '../model/FilterReleasesRequest';
import FilterRepositoriesRequest from '../model/FilterRepositoriesRequest';
import FilteredCodeCheckRuns from '../model/FilteredCodeCheckRuns';
import FilteredDeployments from '../model/FilteredDeployments';
import FilteredEnvironment from '../model/FilteredEnvironment';
import FilteredJIRAStuff from '../model/FilteredJIRAStuff';
import FilteredLabel from '../model/FilteredLabel';
import GenericError from '../model/GenericError';
import GetPullRequestsRequest from '../model/GetPullRequestsRequest';
import GetReleasesRequest from '../model/GetReleasesRequest';
import InstallationProgress from '../model/InstallationProgress';
import InvalidRequestError from '../model/InvalidRequestError';
import InvitationCheckResult from '../model/InvitationCheckResult';
import InvitationLink from '../model/InvitationLink';
import InvitedUser from '../model/InvitedUser';
import JIRAHistogramsRequest from '../model/JIRAHistogramsRequest';
import JIRAMetricsRequest from '../model/JIRAMetricsRequest';
import JIRAProject from '../model/JIRAProject';
import JIRAProjectsRequest from '../model/JIRAProjectsRequest';
import ListedToken from '../model/ListedToken';
import LogicalRepository from '../model/LogicalRepository';
import LogicalRepositoryGetRequest from '../model/LogicalRepositoryGetRequest';
import LogicalRepositoryRequest from '../model/LogicalRepositoryRequest';
import MappedJIRAIdentity from '../model/MappedJIRAIdentity';
import MatchIdentitiesRequest from '../model/MatchIdentitiesRequest';
import MatchedIdentity from '../model/MatchedIdentity';
import PaginatePullRequestsRequest from '../model/PaginatePullRequestsRequest';
import PatchTokenRequest from '../model/PatchTokenRequest';
import ProductFeature from '../model/ProductFeature';
import PullRequestHistogramsRequest from '../model/PullRequestHistogramsRequest';
import PullRequestMetricsRequest from '../model/PullRequestMetricsRequest';
import PullRequestPaginationPlan from '../model/PullRequestPaginationPlan';
import PullRequestSet from '../model/PullRequestSet';
import ReleaseMatchRequest from '../model/ReleaseMatchRequest';
import ReleaseMatchSetting from '../model/ReleaseMatchSetting';
import ReleaseMetricsRequest from '../model/ReleaseMetricsRequest';
import ReleaseNotification from '../model/ReleaseNotification';
import ReleaseNotificationStatus from '../model/ReleaseNotificationStatus';
import ReleaseSet from '../model/ReleaseSet';
import RepositorySetCreateRequest from '../model/RepositorySetCreateRequest';
import RepositorySetListItem from '../model/RepositorySetListItem';
import RepositorySetWithName from '../model/RepositorySetWithName';
import SetMappedJIRAIdentitiesRequest from '../model/SetMappedJIRAIdentitiesRequest';
import Share from '../model/Share';
import Team from '../model/Team';
import TeamCreateRequest from '../model/TeamCreateRequest';
import TeamUpdateRequest from '../model/TeamUpdateRequest';
import User from '../model/User';
import Versions from '../model/Versions';
import WorkType from '../model/WorkType';
import WorkTypeGetRequest from '../model/WorkTypeGetRequest';
import WorkTypePutRequest from '../model/WorkTypePutRequest';

/**
* Default service.
* @module api/DefaultApi
*/
export default class DefaultApi {

    /**
    * Constructs a new DefaultApi.
    * @alias module:api/DefaultApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Accept the account membership invitation and finish registration. The user must be already authorized in Auth0.
     * @param {module:model/AcceptedInvitation} body Accepted invitation details.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InvitedUser} and HTTP response
     */
    acceptInvitationWithHttpInfo(body) {
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling acceptInvitation");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InvitedUser;
      return this.apiClient.callApi(
        '/invite/accept', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Accept the account membership invitation and finish registration. The user must be already authorized in Auth0.
     * @param {module:model/AcceptedInvitation} body Accepted invitation details.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InvitedUser}
     */
    acceptInvitation(body) {
      return this.acceptInvitationWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }

    /**
     * Measure the amount of code that was pushed outside of pull requests.
     * @param {module:model/CodeFilter} body Query for measuring the amount of code that was pushed outside of pull requests.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/CodeBypassingPRsMeasurement>} and HTTP response
     */
    calcCodeBypassingPrsWithHttpInfo(body) {
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling calcCodeBypassingPrs");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [CodeBypassingPRsMeasurement];
      return this.apiClient.callApi(
        '/metrics/code_bypassing_prs', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Measure the amount of code that was pushed outside of pull requests.
     * @param {module:model/CodeFilter} body Query for measuring the amount of code that was pushed outside of pull requests.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CodeBypassingPRsMeasurement>}
     */
    calcCodeBypassingPrs(body) {
      return this.calcCodeBypassingPrsWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Calculate histograms on continuous integration runs, such as GitHub Actions, Jenkins, Circle, etc.
     * @param {module:model/CodeCheckHistogramsRequest} body Select the check runs of interest and the metrics to calculate.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/CalculatedCodeCheckHistogram>} and HTTP response
     */
    calcHistogramCodeChecksWithHttpInfo(body) {
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling calcHistogramCodeChecks");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [CalculatedCodeCheckHistogram];
      return this.apiClient.callApi(
        '/histograms/code_checks', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Calculate histograms on continuous integration runs, such as GitHub Actions, Jenkins, Circle, etc.
     * @param {module:model/CodeCheckHistogramsRequest} body Select the check runs of interest and the metrics to calculate.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CalculatedCodeCheckHistogram>}
     */
    calcHistogramCodeChecks(body) {
      return this.calcHistogramCodeChecksWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Calculate histograms over JIRA issue activities.
     * @param {module:model/JIRAHistogramsRequest} body Query for selecting JIRA issues and binned activities.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/CalculatedJIRAHistogram>} and HTTP response
     */
    calcHistogramJiraWithHttpInfo(body) {
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling calcHistogramJira");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [CalculatedJIRAHistogram];
      return this.apiClient.callApi(
        '/histograms/jira', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Calculate histograms over JIRA issue activities.
     * @param {module:model/JIRAHistogramsRequest} body Query for selecting JIRA issues and binned activities.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CalculatedJIRAHistogram>}
     */
    calcHistogramJira(body) {
      return this.calcHistogramJiraWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Calculate histograms over PR distributions.
     * @param {module:model/PullRequestHistogramsRequest} body Desired histogram definitions.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/CalculatedPullRequestHistogram>} and HTTP response
     */
    calcHistogramPrsWithHttpInfo(body) {
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling calcHistogramPrs");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [CalculatedPullRequestHistogram];
      return this.apiClient.callApi(
        '/histograms/pull_requests', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Calculate histograms over PR distributions.
     * @param {module:model/PullRequestHistogramsRequest} body Desired histogram definitions.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CalculatedPullRequestHistogram>}
     */
    calcHistogramPrs(body) {
      return this.calcHistogramPrsWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Calculate metrics on continuous integration runs, such as GitHub Actions, Jenkins, Circle, etc.
     * @param {module:model/CodeCheckMetricsRequest} body Select the check runs of interest and the metrics to calculate.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CalculatedCodeCheckMetrics} and HTTP response
     */
    calcMetricsCodeChecksWithHttpInfo(body) {
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling calcMetricsCodeChecks");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = CalculatedCodeCheckMetrics;
      return this.apiClient.callApi(
        '/metrics/code_checks', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Calculate metrics on continuous integration runs, such as GitHub Actions, Jenkins, Circle, etc.
     * @param {module:model/CodeCheckMetricsRequest} body Select the check runs of interest and the metrics to calculate.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CalculatedCodeCheckMetrics}
     */
    calcMetricsCodeChecks(body) {
      return this.calcMetricsCodeChecksWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Calculate metrics on deployments submitted by `/events/deployments`.
     * @param {module:model/DeploymentMetricsRequest} body Select the deployments of interest and the metrics to calculate.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/CalculatedDeploymentMetric>} and HTTP response
     */
    calcMetricsDeploymentsWithHttpInfo(body) {
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling calcMetricsDeployments");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [CalculatedDeploymentMetric];
      return this.apiClient.callApi(
        '/metrics/deployments', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Calculate metrics on deployments submitted by `/events/deployments`.
     * @param {module:model/DeploymentMetricsRequest} body Select the deployments of interest and the metrics to calculate.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CalculatedDeploymentMetric>}
     */
    calcMetricsDeployments(body) {
      return this.calcMetricsDeploymentsWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Calculate metrics over developer activities.
     * @param {module:model/DeveloperMetricsRequest} body Query for selecting developers and measured activities.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CalculatedDeveloperMetrics} and HTTP response
     */
    calcMetricsDevelopersWithHttpInfo(body) {
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling calcMetricsDevelopers");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = CalculatedDeveloperMetrics;
      return this.apiClient.callApi(
        '/metrics/developers', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Calculate metrics over developer activities.
     * @param {module:model/DeveloperMetricsRequest} body Query for selecting developers and measured activities.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CalculatedDeveloperMetrics}
     */
    calcMetricsDevelopers(body) {
      return this.calcMetricsDevelopersWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Calculate metrics over JIRA issue activities.
     * @param {module:model/JIRAMetricsRequest} body Query for selecting JIRA issues and measured activities.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/CalculatedJIRAMetricValues>} and HTTP response
     */
    calcMetricsJiraLinearWithHttpInfo(body) {
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling calcMetricsJiraLinear");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [CalculatedJIRAMetricValues];
      return this.apiClient.callApi(
        '/metrics/jira', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Calculate metrics over JIRA issue activities.
     * @param {module:model/JIRAMetricsRequest} body Query for selecting JIRA issues and measured activities.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CalculatedJIRAMetricValues>}
     */
    calcMetricsJiraLinear(body) {
      return this.calcMetricsJiraLinearWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Calculate linear metrics over PRs.
     * @param {module:model/PullRequestMetricsRequest} body Desired metric definitions.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CalculatedPullRequestMetrics} and HTTP response
     */
    calcMetricsPrsWithHttpInfo(body) {
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling calcMetricsPrs");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = CalculatedPullRequestMetrics;
      return this.apiClient.callApi(
        '/metrics/pull_requests', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Calculate linear metrics over PRs.
     * @param {module:model/PullRequestMetricsRequest} body Desired metric definitions.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CalculatedPullRequestMetrics}
     */
    calcMetricsPrs(body) {
      return this.calcMetricsPrsWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Calculate linear metrics over releases.
     * @param {module:model/ReleaseMetricsRequest} body Desired metric definitions.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/CalculatedReleaseMetric>} and HTTP response
     */
    calcMetricsReleasesWithHttpInfo(body) {
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling calcMetricsReleases");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [CalculatedReleaseMetric];
      return this.apiClient.callApi(
        '/metrics/releases', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Calculate linear metrics over releases.
     * @param {module:model/ReleaseMetricsRequest} body Desired metric definitions.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CalculatedReleaseMetric>}
     */
    calcMetricsReleases(body) {
      return this.calcMetricsReleasesWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * 🛡️ Change the status of an account member: regular, admin, or banished (deleted). This endpoint is allowed only for account admins.
     * @param {Object} opts Optional parameters
     * @param {module:model/AccountUserChangeRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Account} and HTTP response
     */
    changeUserWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Account;
      return this.apiClient.callApi(
        '/account/user', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * 🛡️ Change the status of an account member: regular, admin, or banished (deleted). This endpoint is allowed only for account admins.
     * @param {Object} opts Optional parameters
     * @param {module:model/AccountUserChangeRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Account}
     */
    changeUser(opts) {
      return this.changeUserWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Given an invitation URL, get its type (admin or regular account member) and find whether it is correctly formed and is enabled or disabled.
     * @param {module:model/InvitationLink} body Checked invitation details.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InvitationCheckResult} and HTTP response
     */
    checkInvitationWithHttpInfo(body) {
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling checkInvitation");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InvitationCheckResult;
      return this.apiClient.callApi(
        '/invite/check', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Given an invitation URL, get its type (admin or regular account member) and find whether it is correctly formed and is enabled or disabled.
     * @param {module:model/InvitationLink} body Checked invitation details.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InvitationCheckResult}
     */
    checkInvitation(body) {
      return this.checkInvitationWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Reset the precomputed data related to the pushed events.
     * @param {Object} opts Optional parameters
     * @param {module:model/DeleteEventsCacheRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    clearPrecomputedEventsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/events/clear_cache', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Reset the precomputed data related to the pushed events.
     * @param {Object} opts Optional parameters
     * @param {module:model/DeleteEventsCacheRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    clearPrecomputedEvents(opts) {
      return this.clearPrecomputedEventsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * 🛡️ Create a repository set.
     * @param {Object} opts Optional parameters
     * @param {module:model/RepositorySetCreateRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CreatedIdentifier} and HTTP response
     */
    createReposetWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = CreatedIdentifier;
      return this.apiClient.callApi(
        '/reposet/create', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * 🛡️ Create a repository set.
     * @param {Object} opts Optional parameters
     * @param {module:model/RepositorySetCreateRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CreatedIdentifier}
     */
    createReposet(opts) {
      return this.createReposetWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a team. Admin is not required.
     * @param {Object} opts Optional parameters
     * @param {module:model/TeamCreateRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CreatedIdentifier} and HTTP response
     */
    createTeamWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = CreatedIdentifier;
      return this.apiClient.callApi(
        '/team/create', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Create a team. Admin is not required.
     * @param {Object} opts Optional parameters
     * @param {module:model/TeamCreateRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CreatedIdentifier}
     */
    createTeam(opts) {
      return this.createTeamWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a new Personal Access Token for the calling user and the specified account.
     * @param {Object} opts Optional parameters
     * @param {module:model/CreateTokenRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CreatedToken} and HTTP response
     */
    createTokenWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = CreatedToken;
      return this.apiClient.callApi(
        '/token/create', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Create a new Personal Access Token for the calling user and the specified account.
     * @param {Object} opts Optional parameters
     * @param {module:model/CreateTokenRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CreatedToken}
     */
    createToken(opts) {
      return this.createTokenWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * 🛡️ Delete a logical repository.
     * @param {Object} opts Optional parameters
     * @param {module:model/LogicalRepositoryGetRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    deleteLogicalRepositoryWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/settings/logical_repository/delete', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * 🛡️ Delete a logical repository.
     * @param {Object} opts Optional parameters
     * @param {module:model/LogicalRepositoryGetRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    deleteLogicalRepository(opts) {
      return this.deleteLogicalRepositoryWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * 🛡️ Delete a repository set. The user must be an admin of the account that owns the reposet.
     * @param {Number} id Numeric identifier of the repository set to delete.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    deleteReposetWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deleteReposet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/reposet/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * 🛡️ Delete a repository set. The user must be an admin of the account that owns the reposet.
     * @param {Number} id Numeric identifier of the repository set to delete.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    deleteReposet(id) {
      return this.deleteReposetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a team.
     * @param {Number} id Numeric identifier of the team to delete.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    deleteTeamWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deleteTeam");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/team/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Delete a team.
     * @param {Number} id Numeric identifier of the team to delete.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    deleteTeam(id) {
      return this.deleteTeamWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a Personal Access Token belonging to the user.
     * @param {Number} id Numeric identifier of the token.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    deleteTokenWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deleteToken");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/token/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Delete a Personal Access Token belonging to the user.
     * @param {Number} id Numeric identifier of the token.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    deleteToken(id) {
      return this.deleteTokenWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Remove the work type given the name.
     * @param {Object} opts Optional parameters
     * @param {module:model/WorkTypeGetRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    deleteWorkTypeWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/settings/work_type/delete', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Remove the work type given the name.
     * @param {Object} opts Optional parameters
     * @param {module:model/WorkTypeGetRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    deleteWorkType(opts) {
      return this.deleteWorkTypeWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find releases between the two given ones per repository.
     * @param {Object} opts Optional parameters
     * @param {module:model/DiffReleasesRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DiffedReleases} and HTTP response
     */
    diffReleasesWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = DiffedReleases;
      return this.apiClient.callApi(
        '/diff/releases', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Find releases between the two given ones per repository.
     * @param {Object} opts Optional parameters
     * @param {module:model/DiffReleasesRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DiffedReleases}
     */
    diffReleases(opts) {
      return this.diffReleasesWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Return the current JIRA installation progress in Athenian.
     * @param {Number} id Numeric identifier of the account that is installing JIRA.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InstallationProgress} and HTTP response
     */
    evalJiraProgressWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling evalJiraProgress");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InstallationProgress;
      return this.apiClient.callApi(
        '/invite/jira_progress/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Return the current JIRA installation progress in Athenian.
     * @param {Number} id Numeric identifier of the account that is installing JIRA.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InstallationProgress}
     */
    evalJiraProgress(id) {
      return this.evalJiraProgressWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Return the current GitHub installation progress in Athenian.
     * @param {Number} id Numeric identifier of the account that is installing GitHub.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InstallationProgress} and HTTP response
     */
    evalMetadataProgressWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling evalMetadataProgress");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InstallationProgress;
      return this.apiClient.callApi(
        '/invite/progress/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Return the current GitHub installation progress in Athenian.
     * @param {Number} id Numeric identifier of the account that is installing GitHub.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InstallationProgress}
     */
    evalMetadataProgress(id) {
      return this.evalMetadataProgressWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find code check runs that match the specified query. [What a code check run is exactly.](https://docs.github.com/en/rest/guides/getting-started-with-the-checks-api#about-check-runs)
     * @param {Object} opts Optional parameters
     * @param {module:model/FilterCodeChecksRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/FilteredCodeCheckRuns} and HTTP response
     */
    filterCodeChecksWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = FilteredCodeCheckRuns;
      return this.apiClient.callApi(
        '/filter/code_checks', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Find code check runs that match the specified query. [What a code check run is exactly.](https://docs.github.com/en/rest/guides/getting-started-with-the-checks-api#about-check-runs)
     * @param {Object} opts Optional parameters
     * @param {module:model/FilterCodeChecksRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/FilteredCodeCheckRuns}
     */
    filterCodeChecks(opts) {
      return this.filterCodeChecksWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find commits that match the specified query.
     * @param {Object} opts Optional parameters
     * @param {module:model/FilterCommitsRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CommitsList} and HTTP response
     */
    filterCommitsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = CommitsList;
      return this.apiClient.callApi(
        '/filter/commits', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Find commits that match the specified query.
     * @param {Object} opts Optional parameters
     * @param {module:model/FilterCommitsRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CommitsList}
     */
    filterCommits(opts) {
      return this.filterCommitsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find developers that made an action within the given timeframe.
     * @param {Object} opts Optional parameters
     * @param {module:model/FilterContributorsRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/DeveloperSummary>} and HTTP response
     */
    filterContributorsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [DeveloperSummary];
      return this.apiClient.callApi(
        '/filter/contributors', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Find developers that made an action within the given timeframe.
     * @param {Object} opts Optional parameters
     * @param {module:model/FilterContributorsRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/DeveloperSummary>}
     */
    filterContributors(opts) {
      return this.filterContributorsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List the deployments that satisfy the provided filters. We accept new deployment notifications at `/events/deployments`.
     * @param {Object} opts Optional parameters
     * @param {module:model/FilterDeploymentsRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/FilteredDeployments} and HTTP response
     */
    filterDeploymentsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = FilteredDeployments;
      return this.apiClient.callApi(
        '/filter/deployments', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List the deployments that satisfy the provided filters. We accept new deployment notifications at `/events/deployments`.
     * @param {Object} opts Optional parameters
     * @param {module:model/FilterDeploymentsRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/FilteredDeployments}
     */
    filterDeployments(opts) {
      return this.filterDeploymentsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List the deployment environments.
     * @param {Object} opts Optional parameters
     * @param {module:model/FilterEnvironmentsRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/FilteredEnvironment>} and HTTP response
     */
    filterEnvironmentsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [FilteredEnvironment];
      return this.apiClient.callApi(
        '/filter/environments', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List the deployment environments.
     * @param {Object} opts Optional parameters
     * @param {module:model/FilterEnvironmentsRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/FilteredEnvironment>}
     */
    filterEnvironments(opts) {
      return this.filterEnvironmentsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find various JIRA entities relevant to the specified date interval.
     * @param {Object} opts Optional parameters
     * @param {module:model/FilterJIRAStuff} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/FilteredJIRAStuff} and HTTP response
     */
    filterJiraStuffWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = FilteredJIRAStuff;
      return this.apiClient.callApi(
        '/filter/jira', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Find various JIRA entities relevant to the specified date interval.
     * @param {Object} opts Optional parameters
     * @param {module:model/FilterJIRAStuff} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/FilteredJIRAStuff}
     */
    filterJiraStuff(opts) {
      return this.filterJiraStuffWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find labels used in the given repositories.
     * @param {Object} opts Optional parameters
     * @param {module:model/FilterLabelsRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/FilteredLabel>} and HTTP response
     */
    filterLabelsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [FilteredLabel];
      return this.apiClient.callApi(
        '/filter/labels', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Find labels used in the given repositories.
     * @param {Object} opts Optional parameters
     * @param {module:model/FilterLabelsRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/FilteredLabel>}
     */
    filterLabels(opts) {
      return this.filterLabelsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List pull requests that satisfy the query.
     * @param {Object} opts Optional parameters
     * @param {module:model/FilterPullRequestsRequest} opts.filterPullRequestsRequest
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PullRequestSet} and HTTP response
     */
    filterPrsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['filterPullRequestsRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = PullRequestSet;
      return this.apiClient.callApi(
        '/filter/pull_requests', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List pull requests that satisfy the query.
     * @param {Object} opts Optional parameters
     * @param {module:model/FilterPullRequestsRequest} opts.filterPullRequestsRequest
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PullRequestSet}
     */
    filterPrs(opts) {
      return this.filterPrsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find releases that were published in the given time fram in the given repositories.
     * @param {Object} opts Optional parameters
     * @param {module:model/FilterReleasesRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ReleaseSet} and HTTP response
     */
    filterReleasesWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ReleaseSet;
      return this.apiClient.callApi(
        '/filter/releases', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Find releases that were published in the given time fram in the given repositories.
     * @param {Object} opts Optional parameters
     * @param {module:model/FilterReleasesRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ReleaseSet}
     */
    filterReleases(opts) {
      return this.filterReleasesWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find repositories that were updated within the given timeframe.
     * @param {Object} opts Optional parameters
     * @param {module:model/FilterRepositoriesRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<String>} and HTTP response
     */
    filterRepositoriesWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ['String'];
      return this.apiClient.callApi(
        '/filter/repositories', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Find repositories that were updated within the given timeframe.
     * @param {Object} opts Optional parameters
     * @param {module:model/FilterRepositoriesRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<String>}
     */
    filterRepositories(opts) {
      return this.filterRepositoriesWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * 👑 Create a new unique account registration link.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InvitationLink} and HTTP response
     */
    genAccountInvitationWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InvitationLink;
      return this.apiClient.callApi(
        '/invite/generate', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * 👑 Create a new unique account registration link.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InvitationLink}
     */
    genAccountInvitation() {
      return this.genAccountInvitationWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Generate a JIRA integration installation link. The caller must be an admin of the specified account.
     * @param {Number} id Numeric identifier of the account who is installing JIRA integration.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InvitationLink} and HTTP response
     */
    genJiraLinkWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling genJiraLink");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InvitationLink;
      return this.apiClient.callApi(
        '/invite/jira/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Generate a JIRA integration installation link. The caller must be an admin of the specified account.
     * @param {Number} id Numeric identifier of the account who is installing JIRA integration.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InvitationLink}
     */
    genJiraLink(id) {
      return this.genJiraLinkWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create an account invitation link for regular users. The caller must be an admin of the specified account. The link is persistent, so multiple calls return the same result.
     * @param {Number} id Numeric identifier of the account where to invite new users.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InvitationLink} and HTTP response
     */
    genUserInvitationWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling genUserInvitation");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InvitationLink;
      return this.apiClient.callApi(
        '/invite/generate/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Create an account invitation link for regular users. The caller must be an admin of the specified account. The link is persistent, so multiple calls return the same result.
     * @param {Number} id Numeric identifier of the account where to invite new users.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InvitationLink}
     */
    genUserInvitation(id) {
      return this.genUserInvitationWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List the calling user's account members and installed GitHub and JIRA organizations.
     * @param {Number} id Numeric identifier of the account. The user must belong to that account. To find out which accounts the user belongs to, see `/user`.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Account} and HTTP response
     */
    getAccountDetailsWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getAccountDetails");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Account;
      return this.apiClient.callApi(
        '/account/{id}/details', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List the calling user's account members and installed GitHub and JIRA organizations.
     * @param {Number} id Numeric identifier of the account. The user must belong to that account. To find out which accounts the user belongs to, see `/user`.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Account}
     */
    getAccountDetails(id) {
      return this.getAccountDetailsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List the product features enabled for the account.
     * @param {Number} id Numeric identifier of the account. The user must belong to that account. To find out which accounts the user belongs to, see `/user`.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ProductFeature>} and HTTP response
     */
    getAccountFeaturesWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getAccountFeatures");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ProductFeature];
      return this.apiClient.callApi(
        '/account/{id}/features', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List the product features enabled for the account.
     * @param {Number} id Numeric identifier of the account. The user must belong to that account. To find out which accounts the user belongs to, see `/user`.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ProductFeature>}
     */
    getAccountFeatures(id) {
      return this.getAccountFeaturesWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List all the contributors belonging to the specified account.
     * @param {Number} id Numeric identifier of the account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Contributor>} and HTTP response
     */
    getContributorsWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getContributors");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Contributor];
      return this.apiClient.callApi(
        '/get/contributors/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List all the contributors belonging to the specified account.
     * @param {Number} id Numeric identifier of the account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Contributor>}
     */
    getContributors(id) {
      return this.getContributorsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Download all the data collected by Athenian for custom analysis.
     * @param {Object} opts Optional parameters
     * @param {Number} opts.account Numeric identifier of the account. Raises HTTP 400 if the user belongs to more than one account.
     * @param {module:model/String} opts.format Output file format. The default is `parquet`.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
     */
    getEverythingWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'account': opts['account'],
        'format': opts['format']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = [];
      let accepts = ['application/zip', 'application/json'];
      let returnType = File;
      return this.apiClient.callApi(
        '/get/export', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Download all the data collected by Athenian for custom analysis.
     * @param {Object} opts Optional parameters
     * @param {Number} opts.account Numeric identifier of the account. Raises HTTP 400 if the user belongs to more than one account.
     * @param {module:model/String} opts.format Output file format. The default is `parquet`.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
     */
    getEverything(opts) {
      return this.getEverythingWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Fetch the GitHub<>JIRA user identity mapping.
     * @param {Number} id Numeric identifier of the account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/MappedJIRAIdentity>} and HTTP response
     */
    getJiraIdentitiesWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getJiraIdentities");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [MappedJIRAIdentity];
      return this.apiClient.callApi(
        '/settings/jira/identities/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Fetch the GitHub<>JIRA user identity mapping.
     * @param {Number} id Numeric identifier of the account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/MappedJIRAIdentity>}
     */
    getJiraIdentities(id) {
      return this.getJiraIdentitiesWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List the currently enabled JIRA project settings.
     * @param {Number} id Numeric identifier of the account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/JIRAProject>} and HTTP response
     */
    getJiraProjectsWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getJiraProjects");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [JIRAProject];
      return this.apiClient.callApi(
        '/settings/jira/projects/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List the currently enabled JIRA project settings.
     * @param {Number} id Numeric identifier of the account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/JIRAProject>}
     */
    getJiraProjects(id) {
      return this.getJiraProjectsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List pull requests by repository and number.
     * @param {Object} opts Optional parameters
     * @param {module:model/GetPullRequestsRequest} opts.getPullRequestsRequest
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PullRequestSet} and HTTP response
     */
    getPrsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['getPullRequestsRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = PullRequestSet;
      return this.apiClient.callApi(
        '/get/pull_requests', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List pull requests by repository and number.
     * @param {Object} opts Optional parameters
     * @param {module:model/GetPullRequestsRequest} opts.getPullRequestsRequest
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PullRequestSet}
     */
    getPrs(opts) {
      return this.getPrsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List releases by repository and name.
     * @param {Object} opts Optional parameters
     * @param {module:model/GetReleasesRequest} opts.getReleasesRequest
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ReleaseSet} and HTTP response
     */
    getReleasesWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['getReleasesRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ReleaseSet;
      return this.apiClient.callApi(
        '/get/releases', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List releases by repository and name.
     * @param {Object} opts Optional parameters
     * @param {module:model/GetReleasesRequest} opts.getReleasesRequest
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ReleaseSet}
     */
    getReleases(opts) {
      return this.getReleasesWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List a repository set. The user must be in the account that owns the reposet.
     * @param {Number} id Numeric identifier of the repository set to list.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/RepositorySetWithName} and HTTP response
     */
    getReposetWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getReposet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = RepositorySetWithName;
      const apiBasePath = window.MOCK ? window.MOCK?.REST?.HOST : null;
      return this.apiClient.callApi(
        '/reposet/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, apiBasePath
      );
    }

    /**
     * List a repository set. The user must be in the account that owns the reposet.
     * @param {Number} id Numeric identifier of the repository set to list.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/RepositorySetWithName}
     */
    getReposet(id) {
      return this.getReposetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Load the previously saved state of the UI views.
     * @param {String} id Reference to the share previously saved by `PUT /share`.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Share} and HTTP response
     */
    getShareWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getShare");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Share;
      return this.apiClient.callApi(
        '/share/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Load the previously saved state of the UI views.
     * @param {String} id Reference to the share previously saved by `PUT /share`.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Share}
     */
    getShare(id) {
      return this.getShareWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List the team's members. The user must belong to the account that owns the team.
     * @param {Number} id Numeric identifier of the team to list.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Team} and HTTP response
     */
    getTeamWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getTeam");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Team;
      return this.apiClient.callApi(
        '/team/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List the team's members. The user must belong to the account that owns the team.
     * @param {Number} id Numeric identifier of the team to list.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Team}
     */
    getTeam(id) {
      return this.getTeamWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Show details about the calling user.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/User} and HTTP response
     */
    getUserWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = User;
      return this.apiClient.callApi(
        '/user', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Show details about the calling user.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/User}
     */
    getUser() {
      return this.getUserWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Query the versions of the backend components.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Versions} and HTTP response
     */
    getVersionsWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Versions;
      return this.apiClient.callApi(
        '/versions', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Query the versions of the backend components.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Versions}
     */
    getVersions() {
      return this.getVersionsWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Fetch the definition of the work type given the name.
     * @param {Object} opts Optional parameters
     * @param {module:model/WorkTypeGetRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WorkType} and HTTP response
     */
    getWorkTypeWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WorkType;
      return this.apiClient.callApi(
        '/settings/work_type/get', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Fetch the definition of the work type given the name.
     * @param {Object} opts Optional parameters
     * @param {module:model/WorkTypeGetRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WorkType}
     */
    getWorkType(opts) {
      return this.getWorkTypeWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List the currently configured logical repositories. A logical repository is a set of rules to match PRs, releases, and deployments that has a name and pretends to be a regular GitHub repository everywhere in UI and API requests. This feature is particularly useful to reap monorepos into separate, joint or disjoint parts.
     * @param {Number} id Numeric identifier of the account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/LogicalRepository>} and HTTP response
     */
    listLogicalRepositoriesWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listLogicalRepositories");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [LogicalRepository];
      return this.apiClient.callApi(
        '/settings/logical_repositories/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List the currently configured logical repositories. A logical repository is a set of rules to match PRs, releases, and deployments that has a name and pretends to be a regular GitHub repository everywhere in UI and API requests. This feature is particularly useful to reap monorepos into separate, joint or disjoint parts.
     * @param {Number} id Numeric identifier of the account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/LogicalRepository>}
     */
    listLogicalRepositories(id) {
      return this.listLogicalRepositoriesWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List the current release matching settings. Release settings are rules by which to mark releases in GitHub repositories.
     * @param {Number} id Numeric identifier of the account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object.<String, module:model/{String: ReleaseMatchSetting}>} and HTTP response
     */
    listReleaseMatchSettingsWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listReleaseMatchSettings");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = {'String': ReleaseMatchSetting};
      return this.apiClient.callApi(
        '/settings/release_match/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List the current release matching settings. Release settings are rules by which to mark releases in GitHub repositories.
     * @param {Number} id Numeric identifier of the account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object.<String, module:model/{String: ReleaseMatchSetting}>}
     */
    listReleaseMatchSettings(id) {
      return this.listReleaseMatchSettingsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List the repository sets belonging to the calling user.
     * @param {Number} id Numeric identifier of the account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/RepositorySetListItem>} and HTTP response
     */
    listReposetsWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listReposets");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [RepositorySetListItem];
      return this.apiClient.callApi(
        '/reposets/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List the repository sets belonging to the calling user.
     * @param {Number} id Numeric identifier of the account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/RepositorySetListItem>}
     */
    listReposets(id) {
      return this.listReposetsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List the teams belonging to the calling user.
     * @param {Number} id Numeric identifier of the account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Team>} and HTTP response
     */
    listTeamsWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listTeams");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Team];
      return this.apiClient.callApi(
        '/teams/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List the teams belonging to the calling user.
     * @param {Number} id Numeric identifier of the account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Team>}
     */
    listTeams(id) {
      return this.listTeamsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List Personal Access Tokens of the user in the account.
     * @param {Number} id Numeric identifier of the account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ListedToken>} and HTTP response
     */
    listTokensWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listTokens");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ListedToken];
      return this.apiClient.callApi(
        '/tokens/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List Personal Access Tokens of the user in the account.
     * @param {Number} id Numeric identifier of the account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ListedToken>}
     */
    listTokens(id) {
      return this.listTokensWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List the current work types - rule sets to group PRs, releases, etc. together.
     * @param {Number} id Numeric identifier of the account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/WorkType>} and HTTP response
     */
    listWorkTypesWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listWorkTypes");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [WorkType];
      return this.apiClient.callApi(
        '/settings/work_types/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List the current work types - rule sets to group PRs, releases, etc. together.
     * @param {Number} id Numeric identifier of the account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/WorkType>}
     */
    listWorkTypes(id) {
      return this.listWorkTypesWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Match provided people names/logins/emails to the account's GitHub organization members.
     * @param {module:model/MatchIdentitiesRequest} body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/MatchedIdentity>} and HTTP response
     */
    matchIdentitiesWithHttpInfo(body) {
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling matchIdentities");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [MatchedIdentity];
      return this.apiClient.callApi(
        '/match/identities', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Match provided people names/logins/emails to the account's GitHub organization members.
     * @param {module:model/MatchIdentitiesRequest} body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/MatchedIdentity>}
     */
    matchIdentities(body) {
      return this.matchIdentitiesWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Notify about new deployments.
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/DeploymentNotification>} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    notifyDeploymentsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/events/deployments', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Notify about new deployments.
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/DeploymentNotification>} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    notifyDeployments(opts) {
      return this.notifyDeploymentsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Notify about new releases. The release settings must be set to \"event\".
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/ReleaseNotification>} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ReleaseNotificationStatus>} and HTTP response
     */
    notifyReleasesWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [ReleaseNotificationStatus];
      return this.apiClient.callApi(
        '/events/releases', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Notify about new releases. The release settings must be set to \"event\".
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/ReleaseNotification>} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ReleaseNotificationStatus>}
     */
    notifyReleases(opts) {
      return this.notifyReleasesWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Compute the balanced pagination plan for `/filter/pull_requests`.
     * @param {Object} opts Optional parameters
     * @param {module:model/PaginatePullRequestsRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PullRequestPaginationPlan} and HTTP response
     */
    paginatePrsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = PullRequestPaginationPlan;
      return this.apiClient.callApi(
        '/paginate/pull_requests', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Compute the balanced pagination plan for `/filter/pull_requests`.
     * @param {Object} opts Optional parameters
     * @param {module:model/PaginatePullRequestsRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PullRequestPaginationPlan}
     */
    paginatePrs(opts) {
      return this.paginatePrsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Change Personal Access Token's details.
     * @param {Number} id Numeric identifier of the token.
     * @param {Object} opts Optional parameters
     * @param {module:model/PatchTokenRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    patchTokenWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling patchToken");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/token/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Change Personal Access Token's details.
     * @param {Number} id Numeric identifier of the token.
     * @param {Object} opts Optional parameters
     * @param {module:model/PatchTokenRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    patchToken(id, opts) {
      return this.patchTokenWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * 🛡️ Delete all the teams belonging to the account and then clone from GitHub. The \"Bots\" team will remain intact. The rest of the teams will be identical to what's on GitHub.
     * @param {Number} id Numeric identifier of the account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Team>} and HTTP response
     */
    resyncTeamsWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling resyncTeams");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['apiKeyAuth', 'bearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Team];
      return this.apiClient.callApi(
        '/teams/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * 🛡️ Delete all the teams belonging to the account and then clone from GitHub. The \"Bots\" team will remain intact. The rest of the teams will be identical to what's on GitHub.
     * @param {Number} id Numeric identifier of the account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Team>}
     */
    resyncTeams(id) {
      return this.resyncTeamsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Save the state of UI views and return a reference.
     * @param {Object} opts Optional parameters
     * @param {Object} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link String} and HTTP response
     */
    saveShareWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/share', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Save the state of UI views and return a reference.
     * @param {Object} opts Optional parameters
     * @param {Object} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link String}
     */
    saveShare(opts) {
      return this.saveShareWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * 👑 Change account features. The calling user must be marked internally as a super admin.
     * @param {Number} id Numeric identifier of the account.
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/ProductFeature>} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ProductFeature>} and HTTP response
     */
    setAccountFeaturesWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling setAccountFeatures");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [ProductFeature];
      return this.apiClient.callApi(
        '/account/{id}/features', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * 👑 Change account features. The calling user must be marked internally as a super admin.
     * @param {Number} id Numeric identifier of the account.
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/ProductFeature>} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ProductFeature>}
     */
    setAccountFeatures(id, opts) {
      return this.setAccountFeaturesWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * 🛡️ Add or override GitHub<>JIRA user identity mapping.
     * @param {Object} opts Optional parameters
     * @param {module:model/SetMappedJIRAIdentitiesRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/MappedJIRAIdentity>} and HTTP response
     */
    setJiraIdentitiesWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [MappedJIRAIdentity];
      return this.apiClient.callApi(
        '/settings/jira/identities', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * 🛡️ Add or override GitHub<>JIRA user identity mapping.
     * @param {Object} opts Optional parameters
     * @param {module:model/SetMappedJIRAIdentitiesRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/MappedJIRAIdentity>}
     */
    setJiraIdentities(opts) {
      return this.setJiraIdentitiesWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * 🛡️ Set the enabled JIRA projects.
     * @param {Object} opts Optional parameters
     * @param {module:model/JIRAProjectsRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/JIRAProject>} and HTTP response
     */
    setJiraProjectsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [JIRAProject];
      return this.apiClient.callApi(
        '/settings/jira/projects', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * 🛡️ Set the enabled JIRA projects.
     * @param {Object} opts Optional parameters
     * @param {module:model/JIRAProjectsRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/JIRAProject>}
     */
    setJiraProjects(opts) {
      return this.setJiraProjectsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * 🛡️ Insert or update a logical repository - a named set of rules to match PRs, releases, and deployments that pretends to be a regular GitHub repository everywhere in UI and API requests.
     * @param {Object} opts Optional parameters
     * @param {module:model/LogicalRepositoryRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LogicalRepository} and HTTP response
     */
    setLogicalRepositoryWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = LogicalRepository;
      return this.apiClient.callApi(
        '/settings/logical_repository', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * 🛡️ Insert or update a logical repository - a named set of rules to match PRs, releases, and deployments that pretends to be a regular GitHub repository everywhere in UI and API requests.
     * @param {Object} opts Optional parameters
     * @param {module:model/LogicalRepositoryRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LogicalRepository}
     */
    setLogicalRepository(opts) {
      return this.setLogicalRepositoryWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Set the release matching rule for a list of repositories. Only for account admins. Release settings are rules by which to mark releases in GitHub repositories.
     * @param {Object} opts Optional parameters
     * @param {module:model/ReleaseMatchRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<String>} and HTTP response
     */
    setReleaseMatchWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ['String'];
      return this.apiClient.callApi(
        '/settings/release_match', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Set the release matching rule for a list of repositories. Only for account admins. Release settings are rules by which to mark releases in GitHub repositories.
     * @param {Object} opts Optional parameters
     * @param {module:model/ReleaseMatchRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<String>}
     */
    setReleaseMatch(opts) {
      return this.setReleaseMatchWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create or update a work type - a rule set to group PRs, releases, etc. together.
     * @param {Object} opts Optional parameters
     * @param {module:model/WorkTypePutRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WorkType} and HTTP response
     */
    setWorkTypeWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WorkType;
      return this.apiClient.callApi(
        '/settings/work_type', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Create or update a work type - a rule set to group PRs, releases, etc. together.
     * @param {Object} opts Optional parameters
     * @param {module:model/WorkTypePutRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WorkType}
     */
    setWorkType(opts) {
      return this.setWorkTypeWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * 🛡️ Update a repository set. The user must be an admin of the account that owns the reposet.
     * @param {Number} id Numeric identifier of the repository set to update.
     * @param {Object} opts Optional parameters
     * @param {module:model/RepositorySetWithName} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    updateReposetWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateReposet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/reposet/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * 🛡️ Update a repository set. The user must be an admin of the account that owns the reposet.
     * @param {Number} id Numeric identifier of the repository set to update.
     * @param {Object} opts Optional parameters
     * @param {module:model/RepositorySetWithName} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    updateReposet(id, opts) {
      return this.updateReposetWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a team.
     * @param {Number} id Numeric identifier of the team to update.
     * @param {Object} opts Optional parameters
     * @param {module:model/TeamUpdateRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    updateTeamWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateTeam");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/team/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Update a team.
     * @param {Number} id Numeric identifier of the team to update.
     * @param {Object} opts Optional parameters
     * @param {module:model/TeamUpdateRequest} opts.body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    updateTeam(id, opts) {
      return this.updateTeamWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
