export const enum API_ROUTES_CONSTANTS {
  MEMBERS = '/members',
  GOALS_LIST = '/goals',
  METRICS_VALUES = '/metrics-values',
}

export const enum GOALS_ROUTES_CONSTANTS {
  ADD = '/:goalId',
  SINGLE_EDIT = '/edit/:goalId',
  SINGLE_VIEW = '/view/:goalId',
  LIST = '/',
}

export const enum TEAMS_ROUTES_CONSTANTS {
  LIST = '/',
}

export const enum DASHBOARD_ROUTES_CONSTANTS {
  LIST = '/',
  NEW_METRIC = '/new-metric',
  EDIT_METRIC = '/edit/:chartId',
}
