import { ISerializedIssues } from '@align-types/issues';
import {
  FilteredJIRAStuff,
  GetJIRAIssuesResponse,
  JIRAUser,
  PullRequestSet,
} from '@common-services/api/public/generated-from-backend/models';
import { DateService } from '@common-services/dateService';
import { github } from '@common-services/format';
import { JiraServices } from '@common-services/jiraService';
import { PR_LABELS_CLASSNAMES, getPrIcon, prLabel, processPR } from '@common-services/prHelpers';
import { uniqBy } from '@common-services/vendor/lodash';
import { icons } from '@lib/icon';

export const mergePrDataPortions = (
  accumulated: PullRequestSet,
  portion: PullRequestSet
): PullRequestSet => {
  return {
    data: [...(accumulated?.data || []), ...(portion?.data || [])],
    include: {
      deployments: {},
      users: { ...(accumulated?.include?.users || {}), ...(portion?.include?.users || {}) },
    },
  };
};

const combineJiraUsers = (accumulatedUsers: JIRAUser[], portionUsers: JIRAUser[]): JIRAUser[] => {
  const combinedUsers = [...accumulatedUsers];
  portionUsers.forEach((user) => {
    const existingUser = combinedUsers.find((u) => u.name === user.name);
    if (!existingUser) {
      combinedUsers.push(user);
    }
  });
  return combinedUsers;
};

export const mergeIssuesDataPortions = (
  accumulated: GetJIRAIssuesResponse,
  portion: GetJIRAIssuesResponse
): GetJIRAIssuesResponse => {
  return {
    issues: [...(accumulated?.issues || []), ...(portion?.issues || [])],
    include: {
      github_users: {
        ...(accumulated?.include?.github_users || {}),
        ...(portion?.include?.github_users || {}),
      },
      jira_users: combineJiraUsers(
        accumulated?.include?.jira_users || [],
        portion?.include?.jira_users || []
      ),
    },
  };
};

export const serializeIssuesTableData = (
  data: GetJIRAIssuesResponse,
  jiraStuff: FilteredJIRAStuff
): ISerializedIssues[] => {
  return uniqBy(
    data.issues.map((issue) => {
      const issuePriorityObj = (jiraStuff?.priorities || []).find((p) => p.name === issue.priority);
      const issueStatusStage = JiraServices.getStatusStage(
        issue.status,
        issue.project,
        jiraStuff?.statuses || []
      );
      return {
        ...issue,
        age: DateService.dateTime.ago(new Date(issue.created)),
        assigneeAvatar: JiraServices.getAvatarFromJiraUsers(
          issue.assignee,
          data.include?.jira_users || []
        ),
        issueAvatar:
          JiraServices.getIssueTypeImage(issue.type, jiraStuff?.issue_types || []) ||
          icons.jira_task,
        priorityImage: issuePriorityObj?.image,
        priorityRank: issuePriorityObj?.rank,
        reporterAvatar: JiraServices.getAvatarFromJiraUsers(
          issue.reporter,
          data.include?.jira_users || []
        ),
        size: issue.prs?.length || 0,
        statusColor: JiraServices.statusColors[issueStatusStage],
        subRows: issue.prs?.map((pr) => {
          const creator = pr.participants.find((p) => p.status.indexOf('author') >= 0)?.id;
          const prData = processPR({
            ...pr,
            stages_time_machine: pr.stages_now, // workaround over API not providing this field
          });
          const prStatusStage = prLabel('summary')(prData);
          return {
            ...pr,
            id: pr.number,
            age: DateService.dateTime.ago(new Date(pr.created)),
            creator: github.userName(creator),
            creatorAvatar: JiraServices.getAvatarFromGHUsers(
              creator,
              data.include?.github_users || {}
            ),
            prIcon: getPrIcon(prData.status),
            repo: github.repoName(pr.repository),
            status: prStatusStage,
            statusClassName: PR_LABELS_CLASSNAMES[prStatusStage],
            url: github.prLink(pr.repository, pr.number),
          };
        }),
      };
    }),
    'id'
  );
};

export * as PrsIssuesTableServices from './prsIssuesTableService';
