import React, { useCallback, useState } from 'react';

import { useCheckboxClick } from '@lib/selector-ts/hooks/useCheckboxClick';
import { SelectorTsProps } from '@lib/selector-ts/index';
import { ContextProps } from '@lib/selector-ts/types/context';

export const SelectorTsContext = React.createContext<ContextProps>(null);

export const SelectorTsProvider: React.FC<SelectorTsProps> = React.memo(
  ({
    children,
    optionsInit,
    maxSelected,
    minSelected,
    maxHeight,
    withAllOption,
    isCascading,
    isLoading,
    isLoadMoreEnabled,
    loadMoreLabel,
    onApply,
    onCancel,
    onLoadMoreClick,
  }) => {
    const { options, isAllSelected, selectedIds, clickHandler } = useCheckboxClick(
      optionsInit,
      isCascading
    );
    const [filter, setFilter] = useState('');
    const filterOnChangeHandler = useCallback(
      (event: React.SyntheticEvent<HTMLInputElement>): void => {
        setFilter(event.currentTarget.value);
      },
      []
    );
    const filterOnClearHandler = useCallback((): void => {
      setFilter('');
    }, []);
    const applyHandler = useCallback(() => {
      onApply(selectedIds);
    }, [selectedIds]);
    const cancelHandler = useCallback(() => {
      onCancel();
    }, []);
    const loadMoreHandler = useCallback(() => {
      onLoadMoreClick();
    }, []);
    return (
      <SelectorTsContext.Provider
        value={{
          filter,
          filterOnChangeHandler,
          filterOnClearHandler,
          maxSelected,
          minSelected,
          maxHeight,
          clickHandler,
          cancelHandler,
          applyHandler,
          options,
          selectedIds,
          withAllOption,
          isAllSelected,
          isCascading,
          isLoading,
          isLoadMoreEnabled,
          loadMoreLabel,
          loadMoreHandler,
        }}
      >
        {children}
      </SelectorTsContext.Provider>
    );
  }
);
