/**
 * {{ title }}
 * Server version: {{ server_version }} built on {{ build_date }} @ [{{ commit }}](https://github.com/athenianco/athenian-api/commit/{{ commit }})  Origin GitHub repository: [athenianco/api-spec](https://github.com/athenianco/api-spec).  Authorization persists between sessions. 🛡️ means that the endpoint requires account admin privileges. 👑 means that the endpoint is only accessible to Athenian authorities. <details> <summary>List of common server errors.</summary> * __500__ endpoint crashed. We reported the incident to Sentry and will fix it soon! Please include the value of `instance` in the response if you contact the support. Repeating the request will not help, most probably. * __501__ some functions are missing, either because they are not implemented yet or a non-critical runtime dependency is not satisfied. Repeating the request will not help, guaranteed. * __502__ server crashed badly, either due to a memory access violation in native code or running out of memory. We reported this incident to Sentry and will fix it soon! Try repeating the request. * __503__ server has not fully launched yet, e.g. hasn't connected to the database; server is shutting down; we are experiencing a partial outage. Try repeating the request. * __504__ endpoint took too much time and was interrupted. We reported the incident to Sentry and will see how to improve the performance. Repeating the request will not help, most probably. </details>
 *
 * Contact: admin@athenian.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import JIRAFilter from './JIRAFilter';

/**
 * The ForSetDevelopers model module.
 * @module model/ForSetDevelopers
 */
class ForSetDevelopers {
    /**
     * Constructs a new <code>ForSetDevelopers</code>.
     * Filter for &#x60;/metrics/developers&#x60;. Achieve the best performance by packing all your filters in a single &#x60;ForSetDevelopers&#x60; instead of sending multiple &#x60;ForSetDevelopers&#x60;-s. For example, use &#x60;repogroups&#x60; to request separate metrics for several repository sets. 
     * @alias module:model/ForSetDevelopers
     * @param repositories {Array.<String>} Set of repositories. An empty list raises a bad response 400. Duplicates are automatically ignored.
     * @param developers {Object} 
     */
    constructor(repositories, developers) { 
        
        ForSetDevelopers.initialize(this, repositories, developers);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, repositories, developers) { 
        obj['repositories'] = repositories;
        obj['developers'] = developers;
    }

    /**
     * Constructs a <code>ForSetDevelopers</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ForSetDevelopers} obj Optional instance to populate.
     * @return {module:model/ForSetDevelopers} The populated <code>ForSetDevelopers</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ForSetDevelopers();

            if (data.hasOwnProperty('repositories')) {
                obj['repositories'] = ApiClient.convertToType(data['repositories'], ['String']);
            }
            if (data.hasOwnProperty('developers')) {
                obj['developers'] = ApiClient.convertToType(data['developers'], Object);
            }
            if (data.hasOwnProperty('repogroups')) {
                obj['repogroups'] = ApiClient.convertToType(data['repogroups'], Object);
            }
            if (data.hasOwnProperty('aggregate_devgroups')) {
                obj['aggregate_devgroups'] = ApiClient.convertToType(data['aggregate_devgroups'], Object);
            }
            if (data.hasOwnProperty('labels_include')) {
                obj['labels_include'] = ApiClient.convertToType(data['labels_include'], ['String']);
            }
            if (data.hasOwnProperty('labels_exclude')) {
                obj['labels_exclude'] = ApiClient.convertToType(data['labels_exclude'], ['String']);
            }
            if (data.hasOwnProperty('jira')) {
                obj['jira'] = JIRAFilter.constructFromObject(data['jira']);
            }
        }
        return obj;
    }


}

/**
 * Set of repositories. An empty list raises a bad response 400. Duplicates are automatically ignored.
 * @member {Array.<String>} repositories
 */
ForSetDevelopers.prototype['repositories'] = undefined;

/**
 * @member {Object} developers
 */
ForSetDevelopers.prototype['developers'] = undefined;

/**
 * @member {Object} repogroups
 */
ForSetDevelopers.prototype['repogroups'] = undefined;

/**
 * @member {Object} aggregate_devgroups
 */
ForSetDevelopers.prototype['aggregate_devgroups'] = undefined;

/**
 * PRs must be labeled with at least one name from this list so that the related metrics include them.
 * @member {Array.<String>} labels_include
 */
ForSetDevelopers.prototype['labels_include'] = undefined;

/**
 * PRs must not be labeled with any name from this list so that the related metrics exclude them.
 * @member {Array.<String>} labels_exclude
 */
ForSetDevelopers.prototype['labels_exclude'] = undefined;

/**
 * @member {module:model/JIRAFilter} jira
 */
ForSetDevelopers.prototype['jira'] = undefined;






export default ForSetDevelopers;

