import { useTheme } from '@emotion/react';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTable, useSortBy, useExpanded, usePagination } from 'react-table';
import ReactTooltip from 'react-tooltip';

import TablePagination from '@analytics-components/tables/TablePagination';
import { TableFilter } from '@analytics-components/tables/table-filter';
import { TableSearch } from '@analytics-components/tables/table-search';
import { Table } from '@lib/Table';
import { Icon, icons } from '@lib/icon';
import Chevron from '@ui/Chevron';
import { ProgressBarStages } from '@ui/ProgressBar';

import {
  titleStyles,
  placeholderStyles,
  progressBarStyles,
  taskDetailsStyles,
  statusStyles,
  infoStyles,
  leadTimeStyles,
  searchStyles,
  showInactiveLinkStyles,
  filtersStyles,
  updatedColumnStyles,
  tableHeaderStyles,
  tableBodyStyles,
} from './styles';

const CellEpicTitle = (row, onServiceRowClick) => {
  const theme = useTheme();
  const total = useMemo(() => row.original.totalSubtasks, [row]);
  const { onClick } = row.getToggleRowExpandedProps();

  const handleServiceRowClick = useCallback((id) => () => onServiceRowClick(id), [
    onServiceRowClick,
  ]);

  return (
    <div css={titleStyles}>
      <div className="epic-details">
        {row.canExpand && (!row.original.hasInactiveTickets || row.subRows.length >= 1) ? (
          <Chevron isExpanded={row.isExpanded} onClick={onClick} />
        ) : (
          <div css={placeholderStyles} />
        )}
        {!row.original.serviceRow && (
          <img
            alt=""
            className="issue-type"
            src={row.original.issueTypeImage}
            title={row.original.issueType}
          />
        )}
        {row.original.serviceRow && row.depth === 0 && (
          <>
            <div className="issue-type">
              <Icon color={theme.color.neutral[80]} icon={icons.issues} size={16} />
            </div>
            <div className="epic-title no-link">
              <div className="epic-title-text">
                {row.original.title}
                <div className="issues-amount">{row.original.issues}</div>
              </div>
            </div>
          </>
        )}
        <div className="epic-title-box">
          {!row.original.serviceRow && (
            <>
              <a
                className="epic-title"
                data-tip
                data-for={`tooltip-${row.original.id}`}
                href={row.original.url}
                target="_blank"
                rel="noreferrer"
              >
                <div className="epic-title-text">
                  <span className="epic-id">{row.original.id}</span>
                  &nbsp;
                  {row.original.title}
                </div>
              </a>
              <ReactTooltip
                className="athenian-tooltip"
                id={`tooltip-${row.original.id}`}
                effect="solid"
                place="bottom"
                type="light"
                getContent={() => `${row.original.id} ${row.original.title}`}
              />
            </>
          )}
          {row.depth === 0 && row.subRows.length > 0 && !row.original.serviceRow && (
            <div css={progressBarStyles}>
              <div className="bar-wrapper">
                <ProgressBarStages total={total} stages={row.original.stages} />
              </div>
              <div className="progress-label">{`${
                row.original.stages[0]?.value || 0
              }/${total}`}</div>
            </div>
          )}
          {row.depth > 0 && (
            <>
              {row.original.serviceRow ? (
                <div css={showInactiveLinkStyles} onClick={handleServiceRowClick(row.original.id)}>
                  <div className="empty-icon" />
                  <div className="link-text">{row.original.title}</div>
                </div>
              ) : (
                <div css={taskDetailsStyles}>
                  <img
                    alt=""
                    className="pr-user-avatar"
                    src={row.original.reporterAvatar}
                    title={row.original.reporter}
                  />
                  <div className="task-details">
                    <span>Reported by </span>
                    <span className="reporter">{row.original.reporter}</span> {row.original.age}
                    <span> ago</span>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const Align = ({ align, children }) => (
  <div css={{ textAlign: align, width: '100%' }}>{children}</div>
);

const getColumns = (onShowInactive) => [
  {
    Header: 'Epic | Created',
    accessor: 'id',
    Cell: ({ row }) => CellEpicTitle(row, onShowInactive),
    sortType: (a, b) => {
      return moment(a).isAfter(b) ? -1 : 1;
    },
  },
  {
    Header: 'Issues',
    accessor: 'issues',
    Cell: ({ row }) =>
      !row.original.serviceRow && (
        <div css={(theme) => infoStyles(theme, { isChild: row.depth > 0 })}>
          <Icon icon={row.depth > 0 ? icons.subtasks : icons.issues} size={15} />
          <div className="number">
            {row.depth > 0 ? row.original.subtasks : row.original.issues}
          </div>
        </div>
      ),
  },
  {
    Header: 'PRs',
    accessor: 'prs',
    Cell: ({ row }) =>
      !row.original.serviceRow && (
        <div css={(theme) => infoStyles(theme, { isChild: row.depth > 0 })}>
          <Icon icon={icons.nav_pr} size={15} />
          <div className="number">{row.original.prs}</div>
        </div>
      ),
  },
  {
    Header: 'Comments',
    accessor: 'comments',
    Cell: ({ row }) =>
      !row.original.serviceRow && (
        <div css={(theme) => infoStyles(theme, { isChild: row.depth > 0 })}>
          <Icon icon={icons.comment} size={15} />
          <div className="number">{row.original.comments}</div>
        </div>
      ),
  },
  {
    Header: 'Updated',
    accessor: 'updatedValue',
    Cell: ({ row }) =>
      !row.original.serviceRow && (
        <div css={updatedColumnStyles}>
          <img
            alt=""
            className="pr-user-avatar"
            css={{ marginRight: 0 }}
            src={row.original.assigneeAvatar}
            title={row.original.assignee}
          />
          <div className="text">
            <div>{row.original.updatedText[0]}</div>
            <div>{row.original.updatedText[1]}</div>
          </div>
        </div>
      ),
  },
  {
    Header: <div css={{ whiteSpace: 'nowrap' }}>Lead time</div>,
    accessor: 'leadTimeValue',
    Cell: ({ row }) =>
      !row.original.serviceRow && (
        <div css={(theme) => leadTimeStyles(theme, { isChild: row.depth > 0 })}>
          {row.original.leadTimeText ? `${row.original.leadTimeText}` : '-'}
        </div>
      ),
  },
  {
    Header: 'P',
    accessor: 'priority',
    Cell: ({ row }) =>
      !row.original.serviceRow && (
        <Align align="center">
          <img
            alt={row.original.priority}
            css={{ height: 16, width: 20 }}
            src={row.original.priorityImage}
            title={row.original.priority}
          />
        </Align>
      ),
    sortType: (a, b) => {
      const priorityA = a.original.priorityRank;
      const priorityB = b.original.priorityRank;
      return priorityA > priorityB ? -1 : 1;
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ row }) =>
      !row.original.serviceRow && (
        <div css={(theme) => statusStyles(theme, row.original.statusColor)}>
          <span>{row.original.status.toUpperCase()}</span>
        </div>
      ),
    sortType: (a, b) => {
      // order by status stages
      const order = ['done', 'in progress', 'to do'];
      const statusStageA = order.indexOf(a.original.statusStage);
      const statusStageB = order.indexOf(b.original.statusStage);
      if (statusStageA > statusStageB) {
        return -1;
      } else if (statusStageA < statusStageB) {
        return 1;
      } else {
        // if status stages are the same order alphabetically
        const statusA = a.original.status;
        const statusB = b.original.status;
        return statusA > statusB ? 1 : -1;
      }
    },
    width: 170,
  },
];

const EpicsTable = ({
  data,
  currentStatus,
  filterOptions,
  searchedString,
  onFilter,
  onSearch,
  onServiceRowClick,
}) => {
  const [sorting, setSorting] = useState([]);
  const {
    getTableProps,
    getTableBodyProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageCount,
    rows,
    prepareRow,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      autoResetExpanded: false, // this prop will preserve expanded rows from collapsing when data changes
      autoResetPage: false,
      columns: useMemo(() => getColumns(onServiceRowClick), [onServiceRowClick]),
      data,
      initialState: {
        pageIndex: 0,
        sortBy: sorting,
      },
      paginateExpandedRows: false,
    },
    useSortBy,
    useExpanded,
    usePagination
  );

  useEffect(() => {
    setSorting(sortBy);
  }, [sortBy]);

  const [tableRows, setTableRows] = useState([]);

  useEffect(() => {
    _(page).forEach(prepareRow);
    // the following block of code is intended to put "Show inactive items" row always at the bottom of its parent group
    let sortedRows = [...page];
    sortedRows.forEach((row, index) => {
      if (row.original.serviceRow) {
        // in case it's a service row that shows more rows inside of an epic
        if (row.depth > 0) {
          // we define the group by its ID field, children have same ID with postfixes like 15.1 or 15.2 where 15 is ID of a parent
          const parentIndex = row.id.split('.')[0];
          let i = index + 1;
          // we traverse an array until we get to the next ID and then we stop
          while (i < page.length && sortedRows[i].id.indexOf(parentIndex) === 0) {
            // this is a simple replacement algorith to move service row down
            const serviceRow = { ...sortedRows[i - 1] };
            sortedRows[i - 1] = { ...sortedRows[i] };
            sortedRows[i] = serviceRow;
            i += 1;
          }
        } else {
          // in case it's a service row that shows issues without epics
          const [serviceRow] = sortedRows.splice(index, 1);
          // serviceRowWithIssues is an array of a service row with all its subrows
          let serviceRowWithIssues = [serviceRow];
          if (row.isExpanded) {
            let i = 0;
            while (i < sortedRows.length && sortedRows[i].id !== serviceRow.id) {
              if (sortedRows[i].id.split('.')[0] === serviceRow.id) {
                serviceRowWithIssues.push(...sortedRows.splice(i, 1));
              } else {
                i += 1;
              }
            }
          }

          // in case it's a last page just more service row to the bottom
          if (pageIndex === pageCount - 1) {
            sortedRows.push(...serviceRowWithIssues);
          } else {
            // in case it's not a last page remove service row from it and move one more item from the next page
            const extraRow = rows[(pageIndex + 1) * pageSize];
            rows.splice((pageIndex + 1) * pageSize, 1);
            prepareRow(extraRow);
            sortedRows.push(extraRow);
            rows.push(serviceRow);
          }
        }
      }
    });
    setTableRows(sortedRows);
  }, [page, rows]);

  return (
    <>
      <div css={filtersStyles}>
        {onSearch && (
          <TableSearch
            className="epics-search"
            css={searchStyles}
            placeholder="Search..."
            value={searchedString}
            onFilter={onSearch}
          />
        )}
        <TableFilter
          options={filterOptions}
          currentValue={currentStatus}
          setCurrentValue={onFilter}
        />
      </div>
      <Table
        rows={tableRows}
        headerGroups={headerGroups}
        tableProps={getTableProps()}
        tableBodyProps={getTableBodyProps()}
        extraCSS={{
          header: tableHeaderStyles,
          body: tableBodyStyles,
        }}
      />
      <TablePagination
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        total={rows.length}
        gotoPage={gotoPage}
        gotoNextPage={nextPage}
        gotoPrevPage={previousPage}
        setPageSize={setPageSize}
      />
    </>
  );
};

export default EpicsTable;
