import { css } from '@emotion/react';

import { COLOR_TYPE, LABEL_SIZE } from './tagLabel.types';

export const tagLabelStyles = (bgColorType, truncate, labelSize, hasRemoveButton) => ({
  font,
  spacing,
  radius,
  color,
}) => {
  const base = baseStyle(bgColorType, labelSize, hasRemoveButton, { font, spacing, radius, color });
  if (!truncate) {
    return base;
  } else {
    const truncatedStyle = css`
      max-width: 100%;

      .label-wrapper {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `;
    return [base, truncatedStyle];
  }
};

export const multiTagLabelStyles = ({ spacing, color }) => css`
  min-height: 26px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: ${spacing.gap['0']};
  margin: ${spacing.gap['0']};
  flex: none;
  flex-grow: 0;
  gap: ${spacing.gap['03']};
`;

const baseStyle = (
  bgColorType,
  labelSize,
  hasRemoveButton,
  { font, spacing, radius, color }
) => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${spacing.gap['02']} ${hasRemoveButton ? spacing.gap['02'] : spacing.gap['03']}
    ${spacing.gap['02']} ${spacing.gap['03']};
  background-color: ${bgColorType === COLOR_TYPE.light
    ? color.neutral[20]
    : bgColorType === COLOR_TYPE.medium
    ? color.neutral[40]
    : color.ui.turquoise[100]};
  border-radius: ${radius.default};
  flex: none;
  flex-grow: 0;
  width: fit-content;

  .label-wrapper {
    margin: ${spacing.gap['0']} ${spacing.gap['02']};
    color: ${color.neutral['100']};
    font-size: ${labelSize === LABEL_SIZE.default ? font.size.default : font.size.xs};
    font-weight: ${font.weight.regular};
    line-height: 18px;
  }
`;
