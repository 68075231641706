import React, { useCallback } from 'react';

import { Dropdown, DropdownType, IDropdownNameValue } from '@lib/Dropdown';
import { Input } from '@lib/Input';
import { InputTypes } from '@lib/Input/Input.types';

import { teamEditModalBlock, teamEditModalContentStyles } from '../teamEditModal.styles';

interface Props {
  teamNameInit: string;
  teamNameOnChange: (value: string) => void;
  parentTeamInit: IDropdownNameValue;
  parentTeamOptions: IDropdownNameValue[];
  parentTeamOnChange: (parent: IDropdownNameValue) => void;
}

export const TeamEditRenamePanel: React.FC<Props> = React.memo(
  ({ teamNameInit, teamNameOnChange, parentTeamInit, parentTeamOptions, parentTeamOnChange }) => {
    const clickContainer = useCallback((event: React.SyntheticEvent<HTMLDivElement>) => {
      event.stopPropagation();
    }, []);
    return (
      <div css={teamEditModalContentStyles} onClick={clickContainer}>
        <div css={teamEditModalBlock}>
          <Input
            type={InputTypes.text}
            initialValue={teamNameInit}
            placeholder="Team name"
            onChange={teamNameOnChange}
          />
        </div>
        <h6>Parent Team</h6>
        <div css={teamEditModalBlock}>
          <Dropdown
            options={parentTeamOptions}
            type={DropdownType.NAME_VALUE}
            initialValue={parentTeamInit}
            width={233}
            onChange={parentTeamOnChange}
            placeholder="Find a team..."
            withAvatar
            withSearch
          />
        </div>
      </div>
    );
  }
);
