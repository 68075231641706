import PropTypes from 'prop-types';
import React from 'react';

import Controls, { ButtonControl } from '@lib/TaggedSelector/Controls';
import { optionType } from '@lib/TaggedSelector/types';
import { AvatarType } from '@lib/avatar';
import { TagsContainer } from '@lib/tags-container';

const TaggedSelector = ({
  isCascading,
  options = [],
  appliedOptions = [],
  placeholder = '',
  maxSelected,
  button,
  isLoading = false,
  isLimitReached = false,
  avatarType,
  extraControl,
  onRemove,
  onSelectorCancel,
  onSelectorApply,
}) => {
  return (
    <TagsContainer
      items={appliedOptions}
      placeholder={placeholder}
      isLoading={isLoading}
      avatarType={avatarType}
      onRemove={onRemove}
    >
      <Controls>
        {extraControl}
        <ButtonControl
          isCascading={isCascading}
          options={options}
          initialApplied={appliedOptions}
          maxSelected={maxSelected}
          button={button}
          isLoading={isLoading}
          isLimitReached={isLimitReached}
          avatarType={avatarType}
          onSelectorCancel={onSelectorCancel}
          onSelectorApply={onSelectorApply}
        />
      </Controls>
    </TagsContainer>
  );
};

TaggedSelector.propTypes = {
  options: PropTypes.arrayOf(optionType),
  appliedOptions: PropTypes.arrayOf(optionType),
  placeholder: PropTypes.string,
  maxSelected: PropTypes.number,
  button: PropTypes.shape({
    label: PropTypes.element,
    icon: PropTypes.string,
    titleOnLimitReached: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  isLimitReached: PropTypes.bool,
  avatarType: PropTypes.oneOf(Object.values(AvatarType)),
  extraControl: PropTypes.elementType,
  onRemove: PropTypes.func.isRequired,
  onSelectorCancel: PropTypes.func.isRequired,
  onSelectorApply: PropTypes.func.isRequired,
};

export default TaggedSelector;
