import { ITableFilterOption } from '@analytics-components/tables/table-filter/tableFilter.types';
import { prLabel, PR_STAGE } from '@common-services/prHelpers';
import { IPrsTableRow } from '@lib/Table/components/pr-table/prTable.types';

export const searchTermByField = (
  prs: IPrsTableRow[],
  fieldName: string,
  term: string
): IPrsTableRow[] => {
  const filteredData: IPrsTableRow[] = [];
  prs.forEach((pr) => {
    if (Array.isArray(pr?.[fieldName])) {
      if (pr[fieldName].some((v) => v.toLowerCase().indexOf(term.toLowerCase()) >= 0)) {
        filteredData.push(pr);
      }
    } else if (pr[fieldName]?.toString().toLowerCase().indexOf(term.toLowerCase()) >= 0) {
      // add whole issue to the list if there is a match in its field
      filteredData.push(pr);
    }
  });
  return filteredData;
};

export const searchTerm = (
  prs: IPrsTableRow[],
  term: string,
  status: ITableFilterOption,
  stage: PR_STAGE
): IPrsTableRow[] => {
  // the list of searchable fields
  const fields = ['title', 'repo', 'status', 'number', 'author', 'reviewer'];
  const sepIndex = term.indexOf(':');
  const parts = sepIndex === -1 ? [term] : [term.slice(0, sepIndex), term.slice(sepIndex + 1)];
  let filteredData: IPrsTableRow[] = [];
  if (parts.length > 1) {
    // in case field name was specified in the Search field
    const [fieldName, term] = parts;
    if (fields.indexOf(fieldName) >= 0) {
      filteredData = searchTermByField(prs, fieldName.trim(), term.trim());
    }
  } else {
    // in case field name was not specified we search in all searchable fields
    fields.forEach((fieldName) => {
      const matchingPrs = searchTermByField(prs, fieldName, term.trim());
      filteredData = filteredData.concat(matchingPrs);
    });
  }
  if (status) {
    // if there is a status filter set, apply it
    filteredData = filteredData.filter((pr) => prLabel(stage)(pr) === status.value);
  }
  return filteredData;
};
