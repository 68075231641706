import classnames from 'classnames';
import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

const MultiChart = ({ charts }) => {
  const [activeChart, setActiveChart] = useState(0);
  return (
    <>
      <header className="text-center text-center mb-3 pt-2" style={{ marginLeft: '100px' }}>
        <div className="btn-group-toggle btn-group" role="group" aria-label="Chart type">
          {charts.map((c, i) => (
            <button
              key={i}
              type="button"
              className={classnames('btn btn-secondary px-4', activeChart === i && 'active')}
              css={{ minWidth: 110 }}
              onClick={() => setActiveChart(i)}
            >
              {c.name}
            </button>
          ))}
        </div>
      </header>
      <div className="multi-chart-container">
        {charts.map((c, i) => (
          <CSSTransition
            key={i}
            in={activeChart === i}
            timeout={500}
            classNames="toogle-chart"
            mountOnEnter={true}
          >
            <div className="chart">
              <c.Component {...c.props} />
            </div>
          </CSSTransition>
        ))}
      </div>
    </>
  );
};

export default MultiChart;
