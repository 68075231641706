import _ from 'lodash';

import DonutChart from '@analytics-components/charts/DonutChart';
import { SimpleKPI } from '@analytics-components/insights/KPI';
import { BIG_PR_THRESHOLD } from '@analytics-pages/delivery-pipeline/insights/work-in-progress';
import { fetchHistogram } from '@analytics-services/api';
import { getFeature, featuresList } from '@analytics-services/flags';
import { number } from '@common-services/format';
import * as NumberService from '@common-services/numberService';

const donutTicks = (biggestTreshHold) => [1, 10, 50, 100, biggestTreshHold];

const prsLocsDonutDistribution = {
  fetcher: async (api, cachedData, apiContext) => {
    const {
      account,
      interval,
      repositories,
      contributors,
      labels,
      excludeInactive,
      features,
      epics,
      issueTypes,
    } = apiContext;
    const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, features)?.parameters
      ?.value;
    return {
      donutDistribution: await fetchHistogram(
        api,
        account,
        interval,
        ['size'],
        {
          repositories: repositories,
          with: { author: contributors },
          labels_include: labels,
          ...(excludedLabels ? { labels_exclude: excludedLabels } : {}),
        },
        excludeInactive,
        null,
        null,
        donutTicks(BIG_PR_THRESHOLD),
        { epics, issue_types: issueTypes }
      ),
    };
  },
  plumber: (fetchedData, cachedData, apiContext) => {
    const interquartile = cachedData['prs-histogram']['size'].interquartile;
    const donutDistribution = fetchedData.donutDistribution?.size;
    const totalFrequencies = _(donutDistribution.frequencies).sum();
    const belowLimit = totalFrequencies - donutDistribution.frequencies.slice(-1)[0];

    const chartData = donutDistribution?.frequencies.map((f, i) => ({
      value: f,
      label:
        donutDistribution.ticks[i] < BIG_PR_THRESHOLD
          ? `${NumberService.round(donutDistribution.ticks[i])} - ${NumberService.round(
              donutDistribution.ticks[i + 1]
            )} lines`
          : `${NumberService.round(donutDistribution.ticks[i])}+ lines`,
    }));
    const prsProportionBelowThreshold = belowLimit / totalFrequencies;

    return {
      empty: !chartData?.find((bin) => bin.value),
      chart: {
        component: DonutChart,
        params: {
          data: chartData,
          extra: {
            tooltip: {
              y: (v) => `${v} pull requests`,
            },
          },
        },
      },
      kpis: [
        {
          title: { text: 'Proportion of pull requests', bold: true },
          subtitle: { text: `under ${BIG_PR_THRESHOLD} lines` },
          component: SimpleKPI,
          params: {
            value: number.percentage(100 * prsProportionBelowThreshold, 0),
          },
        },
        {
          title: { text: 'Middle range', bold: true },
          subtitle: { text: '25% - 75%' },
          component: SimpleKPI,
          params: {
            value:
              interquartile.left &&
              interquartile.right &&
              `${NumberService.round(interquartile.left)} - ${NumberService.round(
                interquartile.right
              )} lines`,
          },
        },
      ],
    };
  },
  prefetchedDataIds: ['prs-histogram'],
};

export default prsLocsDonutDistribution;
