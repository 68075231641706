import PropTypes from 'prop-types';

const optionType = PropTypes.oneOfType([
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }),
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }),
]);

const optionNoColorType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

const optsType = (props, propName, componentName, ...rest) =>
  PropTypes.arrayOf(!!props.noAvatar ? optionNoColorType : optionType)(
    props,
    propName,
    componentName,
    ...rest
  );

export { optionType, optionNoColorType, optsType };
