import _ from 'lodash';

export const hexToRGBA = (hex, alpha) => {
  const rgb = hexToRGBParts(hex);
  alpha = alpha > 1 || alpha < 0 || typeof alpha !== 'number' ? 1 : alpha;
  return rgba(rgb, alpha);
};

export const hexToRGBParts = (hex) => {
  return {
    r: parseInt(hex.slice(1, 3), 16),
    g: parseInt(hex.slice(3, 5), 16),
    b: parseInt(hex.slice(5, 7), 16),
  };
};

export const rgba = (rgb, alpha) => {
  return 'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', ' + alpha + ')';
};

/**
 * Transform string into color
 * @param {string} str
 */
export const stringToColor = (str) => {
  if (stringToColor[str]) return stringToColor[str];

  const brandColors = getBrandColors();

  const x = hashCode(str);
  const b = x % brandColors.length;
  const l = 40 + (x % 40);

  stringToColor[str] = `hsl(${brandColors[b][0]}, ${brandColors[b][1]}%, ${l}%)`;

  return stringToColor[str];
};

const hashCode = (str) => {
  if (!str.length) return 0;

  const hash = str
    .split('')
    .reduce((hash, char) => ((hash << 5) - hash + char.charCodeAt(0)) | 0, 0);
  return hash >>> 0;
};

// Color ranges used to generate new colors based on strings
// for teams select
const colorsFromCSS = [
  'blue',
  'purple',
  'light-pink',
  'pink',
  'red',
  'brown',
  'orange',
  'light-orange',
  'yellow',
  'green',
  'turquoise',
  'wheat',
];

const docNode = getComputedStyle(document.documentElement);

const fallbackColors = [
  [210.6, 85, 50.2],
  [263.1, 69.1, 63.1],
  [0.3, 85.2, 57.6],
  [263.1, 69.1, 63.1],
  [340.1, 97.4, 53.9],
  [21.7, 81.3, 31.4],
  [36.9, 100, 51.6],
  [145.2, 62.5, 49.2],
  [45.9, 100, 51.6],
  [181.8, 70, 47.1],
];

// If css vars are supported use colors from _variables.scss
const getBrandColors = () => {
  if (!window.CSS || !CSS.supports('(--foo: red)')) {
    return fallbackColors;
  }

  const colors = colorsFromCSS.map((color) =>
    hexToHSL(docNode.getPropertyValue(`--athenian-${color}`).trim())
  );
  if (
    _(colors)
      .flatMap()
      .map((v) => v === 0)
      .every()
  ) {
    return fallbackColors;
  } else {
    return colors;
  }
};

function hexToHSL(H) {
  // Convert hex to RGB first
  // https://css-tricks.com/converting-color-spaces-in-javascript/
  let r = 0,
    g = 0,
    b = 0;
  if (H.length === 4) {
    r = '0x' + H[1] + H[1];
    g = '0x' + H[2] + H[2];
    b = '0x' + H[3] + H[3];
  } else if (H.length === 7) {
    r = '0x' + H[1] + H[2];
    g = '0x' + H[3] + H[4];
    b = '0x' + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return [h, s, l];
}

export const rotate = (collection, position) => collection[position % collection.length];
export const extend = (collection, n) =>
  _(Array(n))
    .map((_, i) => rotate(collection, i))
    .value();
