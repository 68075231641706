import { css } from '@emotion/react';

export const getStyleOption = (level, hasChildren, isAll) => ({
  color,
  spacing,
  radius,
  transition,
  font,
  shadow,
}) => css`
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing.gap['02']} ${spacing.gap['05']} ${spacing.gap['02']}
    calc(${spacing.gap['04']} * ${level});
  background: ${color.neutral[isAll ? 40 : hasChildren ? 20 : 0]};
  border-bottom: 1px solid ${color.neutral['40']};
  flex: none;
  margin: ${spacing.gap['0']};
  transition: ${transition.long};

  & > div.info-wrapper {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ${spacing.gap['01']} ${spacing.gap['0']};
    margin: ${spacing.gap['0']};
    flex-grow: 1;
    max-width: 100%;

    & > div.checkbox-wrapper {
      padding: ${spacing.gap['01']};
      margin-right: ${spacing.gap['03']};
    }

    & > div.avatar-wrapper {
      & > div {
        margin-right: ${spacing.gap['0']};
      }
    }

    & > div.label-wrapper {
      margin-left: ${spacing.gap['02']};
      margin-right: ${spacing.gap['02']};
      line-height: 18px;
      color: ${color.neutral['100']};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      display: flex;
      align-items: center;

      .option-color-image-wrapper {
        margin-right: ${spacing.gap['02']};
      }

      .option-image {
        border-radius: ${radius.circle};
        border: 2px solid ${color.neutral.white};
        box-shadow: ${shadow.full};
        color: ${color.neutral.white};
        font-size: ${font.size.xxs};
        height: 22px;
        line-height: 18px;
        margin-right: ${spacing.gap['02']};
        text-align: center;
        width: 22px;
        display: block;
      }

      .option-color {
        width: 12px;
        height: 12px;
        display: flex;
        border-radius: ${radius.circle};
      }

      .option-name {
        font-size: ${font.size.default};
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: keep-all;
        white-space: nowrap;
        > span {
          color: ${color.neutral['80']};
          font-size: ${font.size.xs};
        }
      }

      & > span.label-prefix {
        margin-right: ${spacing.gap['02']};
        color: ${color.neutral['80']};
      }
      & > span.selector-option-badge {
        margin-left: ${spacing.gap['03']};
      }
    }
  }

  & div.control-wrapper {
    width: 30px;
    text-align: right;
  }

  ${!isAll &&
  `
  &:hover {
    background: ${color.neutral[hasChildren ? 40 : 20]};
  }
`}
`;
