import { css } from '@emotion/react';

import { transition } from '@styles/theme/transition';

export const wrapperStyles = (isTargetOpen) => ({ color, font, radius, shadow, spacing }) => css`
  background-color: ${color.neutral.white};
  border: 1px solid ${color.neutral['40']};
  border-radius: ${radius.default};
  cursor: pointer;
  margin: -${spacing.gap['03']} ${spacing.gap['0']};
  transition: ${transition.default};
  width: 300px;

  &:hover {
    border-color: ${color.neutral['80']};
    box-shadow: ${shadow.full};
  }

  .goal-content {
    padding: ${spacing.gap['06']} ${spacing.gap['06']} ${spacing.gap['0']};
  }

  .team {
    align-items: center;
    display: flex;

    .team-name {
      color: ${color.text.dark};
      font-size: ${font.size.s};
      font-weight: ${font.weight.regular};
      margin-left: ${spacing.gap['03']};
    }
  }

  .goal-details {
    align-items: flex-start;
    display: flex;
    margin-top: ${spacing.gap['06']};
  }
`;
