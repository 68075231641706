import {
  Contributors,
  DeveloperSummaries,
} from '@common-services/api/public/generated-from-backend/models';
import { github } from '@common-services/format';
import { SelectTsOptionSingle } from '@lib/selector-ts';

export function convertDevelopers(
  allUsers: DeveloperSummaries,
  filtered: string[],
  selected: string[]
): SelectTsOptionSingle[] {
  return allUsers
    .filter((user) => user.login && !filtered.includes(user.login))
    .map((user) => {
      return {
        name: user.name || '',
        subName: github.userName(user.login),
        id: user.login,
        image: user.avatar,
        isSelected: selected ? selected.includes(user.login) : false,
        children: [],
      };
    });
}

export function convertContributors(
  allUsers: Contributors,
  filtered: string[],
  selected: string[]
): SelectTsOptionSingle[] {
  return allUsers
    .filter((user) => user.login && !filtered.includes(user.login))
    .map((user) => {
      return {
        name: user.name || '',
        subName: github.userName(user.login),
        id: user.login,
        image: user.picture,
        isSelected: selected ? selected.includes(user.login) : false,
        children: [],
      };
    });
}

export * as TeamsServices from './teams.services';
