import { css } from '@emotion/react';

export const wrapperStyles = ({ color, spacing }) => css`
  display: flex;
  align-items: center;
  color: ${color.neutral['80']};

  svg {
    margin: ${spacing.gap['02']};
  }

  &:hover {
    color: ${color.neutral['60']};

    path {
      fill: ${color.neutral['60']};
    }
  }
`;
