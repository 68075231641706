import React from 'react';

import { PipelineMainMetrics as MainMetrics } from '@analytics-components/MainMetrics';
import Tabs from '@analytics-components/Tabs';
import Thumbnails from '@analytics-components/Thumbnails';
import { useBreadcrumb } from '@analytics-context/Breadcrumb';
import { featuresList, getFeature } from '@analytics-services/flags';
import { useUserContext } from '@common-context/User';
import { dateTime } from '@common-services/dateService';
import { PR_STAGE } from '@common-services/prHelpers';

import SummaryBox from './SummaryBox';
import Insights from './insights';
import { deploymentsTab } from './tabs/deployments';
import { pullRequestsTab } from './tabs/pullRequests';
import { releasesTab } from './tabs/releases';

const mainMetricsDefinitions = (hasJira) => [
  ...(hasJira
    ? [
        {
          title: 'Lead Time',
          dataGetter: (data) => [
            { raw: data.leadTime.avg, formatted: dateTime.human(data.leadTime.avg) },
            data.leadTime.variation,
          ],
          hint: {
            primaryText:
              'Elapsed time between the ticket moved to In Progress and the issue being resolved.',
            secondaryText: 'Calculated up to the 95th percentile.',
            learnMore: {
              params: { label: 'Lead Time', path: 'leadTime' },
            },
          },
          emptyMessage: 'No releases',
          negativeIsBetter: true,
        },
      ]
    : []),
  {
    title: 'PR Cycle Time',
    dataGetter: (data) => [
      { raw: data.PRsCycleTime.avg, formatted: dateTime.human(data.PRsCycleTime.avg) },
      data.PRsCycleTime.variation,
    ],
    hint: {
      primaryText:
        'Elapsed time between min(1st commit of a PR, PR creation) and the code being used in production.',
      secondaryText: 'Calculated up to the 95th percentile.',
    },
    emptyMessage: 'No releases',
    negativeIsBetter: true,
  },
  {
    title: 'Released PRs',
    dataGetter: (data) => [
      { raw: data.releasedPRs.avg, formatted: data.releasedPRs.avg },
      data.releasedPRs.variation,
    ],
    hint: {
      primaryText: 'Number of pull requests released, counting in the PR Cycle Time.',
    },
    emptyMessage: 'No releases',
  },
  {
    title: 'Release Freq.',
    dataGetter: (data) => {
      const [releaseRate, releaseRateUnit] = data.releases.releaseRateSpecs;
      return [
        {
          raw: releaseRate,
          formatted: (
            <>
              {releaseRate}
              <span className="text-secondary text-s ml-1">/ {releaseRateUnit}</span>
            </>
          ),
        },
        data.releases.variation,
      ];
    },
    hint: {
      primaryText: 'Release Frequency for the time period and set of repositories selected.',
      learnMore: {
        params: { label: 'Release Frequency', path: 'releaseFrequency' },
        noProd: true,
      },
    },
    emptyMessage: 'No releases',
  },
  ...(!hasJira
    ? [
        {
          title: 'Contributors',
          dataGetter: (data) => [
            { raw: data.contributors.avg, formatted: data.contributors.avg },
            data.contributors.variation,
          ],
          hint: {
            primaryText:
              'Overall number of contributors who showed some GitHub activity during the date range selected.',
          },
          emptyMessage: 'No contributors',
        },
      ]
    : []),
];

const Content = () => {
  const userDetails = useUserContext();
  const isDeployTablesFlagEnabled = getFeature(
    featuresList.deploy_tables_enabled,
    userDetails.features
  );
  const { subsection: activeStage } = useBreadcrumb();
  const tabs = [];
  tabs.push({
    title: 'Insights',
    content: <Insights />,
  });
  if (activeStage !== PR_STAGE.DEPLOY) {
    tabs.push(pullRequestsTab);
  }
  if (activeStage === PR_STAGE.RELEASE) {
    tabs.push(releasesTab);
  }
  if ((isDeployTablesFlagEnabled || userDetails.isGod) && activeStage === PR_STAGE.DEPLOY) {
    tabs.push(pullRequestsTab);
    tabs.push(deploymentsTab);
  }

  return (
    <>
      <MainMetrics
        definitions={mainMetricsDefinitions(userDetails.hasJIRA)}
        hasJIRA={userDetails.hasJIRA}
      />
      <Thumbnails />
      <SummaryBox />
      <Tabs tabs={tabs} initialValue={0} />
    </>
  );
};

export default Content;
