import React from 'react';
import { CellProps, Renderer } from 'react-table';

import { PR_LABELS, PR_LABELS_CLASSNAMES, PR_STAGE } from '@common-services/prHelpers';
import { badgeStyles } from '@lib/Table/components/pr-table/prTable.styles';
import { IPrsTableRow } from '@lib/Table/components/pr-table/prTable.types';

export const PullRequestsCellStage: Renderer<CellProps<IPrsTableRow>> = React.memo(({ row }) => {
  return row.original.stage === PR_STAGE.DEPLOY ? (
    <div
      css={badgeStyles}
      className={`badge badge-outlined ${PR_LABELS_CLASSNAMES[PR_LABELS.RELEASE_COMPLETED]}`}
    >
      {row.original.environment}
    </div>
  ) : (
    <div
      css={badgeStyles}
      className={`badge badge-outlined ${PR_LABELS_CLASSNAMES[row.original.statusLabel]}`}
    >
      {row.original.statusLabel}
    </div>
  );
});
