import _ from 'lodash';
import React from 'react';

import { AltTitle } from '@analytics-components/Typography';
import { DefaultXYTooltip, BigText } from '@analytics-components/charts/Tooltip';
import { number } from '@common-services/format';
import { theme } from '@styles/theme';
import RingChart from '@ui/charts/RingChart';

import { taskIconStyles, tooltipWrapperStyles } from './styles';

const isTicketResolvedInRange = (ticket, interval) =>
  !!ticket.resolved &&
  ticket.resolved >= interval.from.toDate() &&
  ticket.resolved < interval.to.toDate();

const conf = {
  plumber: (fetchedData, cachedData, apiContext) => {
    const filteredJIRAStuff = cachedData['jira-epics'];
    const epics = _(filteredJIRAStuff.epics)
      .map((e) => ({
        label: e.id,
        title: e.title,
        link: `${apiContext.user.account.jira.url}/browse/${e.id}`,
        children: e.children,
      }))
      .value();

    const chartData = _(epics)
      .map((v) => ({
        ...v,
        label: `#${v.label}`,
        value: _(v.children)
          .filter((t) => isTicketResolvedInRange(t, apiContext.interval))
          .value().length,
      }))
      .filter((t) => t.value > 0)
      .sortBy('value')
      .value()
      .reverse();

    const totalTickets = _(chartData).map('value').sum();

    return {
      empty: chartData.length === 0,
      chart: {
        component: RingChart,
        params: {
          data: chartData,
          params: {
            hasOtherSection: true,
            labels: {
              primary: totalTickets,
              secondary: 'Tickets',
            },
            colors: theme.color.sets.six,
            legend: {
              n: 3,
              reverse: false,
            },
            tooltip: {
              component: DefaultXYTooltip,
              persistent: true,
              x: (v) => (
                <div css={tooltipWrapperStyles}>
                  <img
                    alt=""
                    css={taskIconStyles}
                    src="https://athenianco.atlassian.net/images/icons/issuetypes/epic.svg"
                  />
                  <div>
                    <a href={v.link} rel="noreferrer" target="_blank">
                      <AltTitle content={v.label} inline link />
                    </a>
                  </div>
                </div>
              ),
              y: (v) => {
                return (
                  <BigText
                    content={`${v.value} tickets`}
                    extra={number.percentage(v.percentage * 100)}
                  />
                );
              },
            },
          },
        },
      },
      kpis: [],
    };
  },
  prefetchedDataIds: ['jira-epics'],
};

export default conf;
