import { icons } from '@lib/icon';
import { IColor } from '@styles/types';

export interface IDropdownNameValue {
  name: string;
  withAvatar?: boolean;
  value: any;
  color?: IColor | string;
}

export enum DropdownType {
  NAME = 'name',
  NAME_VALUE = 'name-value',
}

export interface IDropdownControl {
  value: IDropdownNameValue;
  isOpen?: boolean;
  width?: number;
  withAvatar?: boolean;
  placeholder?: string;
  disabled?: boolean;
  onClick?: () => void;
  icon?: icons;
}

export interface IDropdownOptions {
  width?: number;
  options: IDropdownNameValue[];
  onClick?: (newValue: IDropdownNameValue) => React.MouseEventHandler<HTMLDivElement>;
  withAvatar?: boolean;
  withSearch?: boolean;
  placeholder?: string;
}

export interface IDropdown {
  disabled?: boolean;
  options?: string[] | IDropdownNameValue[];
  placeholder?: string;
  initialValue?: string | IDropdownNameValue;
  width?: number;
  type?: DropdownType;
  withAvatar?: boolean;
  onChange?: (v: string | IDropdownNameValue) => void;
  withSearch?: boolean;
  icon?: icons;
}
