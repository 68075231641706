import { css } from '@emotion/react';

export const activeGoalDetailsStyles = ({ color, spacing }) => css`
  display: flex;
  align-items: center;
  gap: ${spacing.gap['03']};

  .team-name {
    color: ${color.text.dark};
    display: flex;
    align-items: center;
    gap: ${spacing.gap['03']};
  }
`;
