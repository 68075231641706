import _ from 'lodash';
import React from 'react';

import { PersistentMultiSelect } from '@analytics-components/MultiSelect';
import { github } from '@common-services/format';

const RepositoriesMultiSelect = (props) => {
  const {
    onInit,
    onApply,
    isLoading,
    options,
    areOptionsLoading,
    initialValues,
    persistentKey,
    resetStorage,
    isDisabled,
  } = props;

  const reposLabelFormat = (val) => github.repoName(val.label) || 'UNKNOWN';
  const getOptionValueRepos = (val) => val.value;
  const serialize = (selected) => _(selected).map('label').value();
  const deserialize = (selected) =>
    _(selected)
      .map((s) => ({ label: s, value: s }))
      .value();
  const onApplyWrapper = (selected, ...args) => onApply(serialize(selected), ...args);
  const onInitWrapper = (selected) =>
    // TODO: if it's a list of string, it's already serialized as it has been loaded
    // from the local storage
    onInit &&
    onInit(
      _(selected)
        .map((v) => (typeof v === 'string' ? v : v.label))
        .value()
    );

  return (
    <PersistentMultiSelect
      label="Repositories"
      className="filter"
      name="repositories"
      noDataMsg="There are no repositories for the date interval filter"
      persistentKey={persistentKey}
      resetStorage={resetStorage}
      isLoading={isLoading}
      getOptionLabel={reposLabelFormat}
      getOptionValue={getOptionValueRepos}
      options={formatValue(options)}
      areOptionsLoading={areOptionsLoading}
      onInit={onInitWrapper}
      onApply={onApplyWrapper}
      deserialize={deserialize}
      serialize={serialize}
      initialValues={formatValue(initialValues)}
      isDisabled={isDisabled}
    />
  );
};

const formatValue = (opts) =>
  _(opts)
    .map((v) => ({ label: v, value: v }))
    .value();

export default RepositoriesMultiSelect;
