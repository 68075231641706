import { css } from '@emotion/react';

export const wrapperStyles = ({ spacing }) => css`
  align-items: center;
  display: flex;

  .target-value {
    margin-left: ${spacing.gap['02']};
  }
`;
