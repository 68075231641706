import React, { useCallback, useMemo } from 'react';

import { useApi } from '@analytics-hooks';
import { setWorkType } from '@analytics-services/api';
import { useOwnedJiraFilter } from '@common-services/api/public/hooks/useFiltersData';

import JiraLabel from '.';

const JiraLabelDataBinder = ({ rule, workType }) => {
  const { api, ready: apiReady, context } = useApi(true, false);
  const { data: jiraLabelsData } = useOwnedJiraFilter(['issues', 'labels'], true);
  const jiraLabels = useMemo(() => {
    return jiraLabelsData
      ? jiraLabelsData?.labels
          .filter((item) => item.kind === 'regular')
          .map((item) => ({
            count: item.issues_count,
            label: item.title,
            value: item.title,
          }))
          .sort((a, b) => (a.count < b.count ? 1 : -1))
      : [];
  }, [jiraLabelsData]);

  const handleChange = useCallback(
    (value) => {
      if (!apiReady) return;
      const modifiedRule = workType.rules.find((item) => item.name === rule.name);
      modifiedRule.body.jiraLabels = value;
      setWorkType(api, context.account, workType.name, workType.color, workType.rules);
    },
    [apiReady]
  );

  return (
    <JiraLabel
      allJiraLabels={jiraLabels}
      initialValue={rule?.body?.jiraLabels}
      onChange={handleChange}
    />
  );
};

export default JiraLabelDataBinder;
