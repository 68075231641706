import _ from 'lodash';
import React from 'react';

import { NEGATIVE_IS_BETTER } from '@analytics-components/Badge';
import TimeSeries from '@analytics-components/charts/TimeSeries';
import { BigText } from '@analytics-components/charts/Tooltip';
import { SimpleKPI } from '@analytics-components/insights/KPI';
import { dateTime } from '@common-services/dateService';
import { number, isNumber } from '@common-services/format';
import { theme } from '@styles/theme';
import { hexToRGBA } from '@utils/colors';

const waitTimeFirstReview = {
  prefetchedDataIds: ['prs-metrics'],
  plumber: (fetchedData, cachedData, apiContext) => {
    const customGranularity = cachedData['prs-metrics'].customGranularity;
    const waitFirstReviewVariation =
      cachedData['prs-metrics']['variations']['wait-first-review-time'];

    const currWaitFirstReview =
      cachedData['prs-metrics']['values']['all']['wait-first-review-time'];
    const currReviewTime = cachedData['prs-metrics']['values']['all']['review-time'];

    let currReviewProportion = null;
    if (currWaitFirstReview === 0) {
      currReviewProportion = 0;
    } else if (isNumber(currWaitFirstReview) && isNumber(currReviewTime) && currReviewTime !== 0) {
      currReviewProportion = (currWaitFirstReview * 100) / currReviewTime;
    }

    const timeseriesGranularity = customGranularity === 'month' ? 'custom-aligned' : 'custom';
    const computed = {
      chartData: _(
        cachedData['prs-metrics']['values'][timeseriesGranularity]['wait-first-review-time']
      )
        .map((v) => ({ day: v.date, value: v.value === null ? null : v.value * 1000 }))
        .value(),
      KPIsData: {
        avgWaitingTime: {
          value: currWaitFirstReview * 1000,
          variation: waitFirstReviewVariation,
        },
        reviewProportion: {
          value: currReviewProportion,
        },
      },
      axisKeys: {
        x: 'day',
        y: 'value',
      },
      interval: apiContext.interval,
      customGranularity,
    };

    const tickFormat = computed.customGranularity === 'month' ? dateTime.month : dateTime.monthDay;
    const tooltip = {
      renderBigFn: (v) => <BigText content={dateTime.human(v.y)} />,
    };
    if (_(['month', 'week']).includes(computed.customGranularity)) {
      tooltip.dateRange = {
        from: computed.interval.from,
        to: computed.interval.to,
      };
      tooltip.granularity = computed.customGranularity;
    }

    return {
      empty: computed.chartData.filter((v) => v[computed.axisKeys.y] !== null).length === 0,
      chart: {
        component: TimeSeries,
        params: {
          data: computed.chartData,
          timeMode: true,
          extra: {
            average: {
              value: computed.KPIsData.avgWaitingTime.value,
              color: theme.color.neutral[80],
              renderFn: tooltip.renderBigFn,
            },
            maxNumberOfTicks: 10,
            axisKeys: computed.axisKeys,
            color: theme.color.stage.review,
            fillColor: hexToRGBA(theme.color.stage.review, 0.1),
            tooltip,
            ticks: { x: { tickFormat } },
          },
        },
      },
      kpis: [
        {
          title: { text: 'Average Wait Time', bold: true },
          subtitle: { text: 'For 1st Review' },
          component: SimpleKPI,
          params: {
            value: dateTime.bestTimeUnit(computed.KPIsData.avgWaitingTime.value, 0),
            variation: computed.KPIsData.avgWaitingTime.variation,
            variationMeaning: NEGATIVE_IS_BETTER,
          },
        },
        {
          title: { text: 'Proportion of the Review Time', bold: true },
          component: SimpleKPI,
          params: {
            value: number.fixed(computed.KPIsData.reviewProportion.value, 0),
            variation: computed.KPIsData.reviewProportion.variation,
            variationMeaning: NEGATIVE_IS_BETTER,
            unit: '%',
          },
        },
      ],
    };
  },
};

export default waitTimeFirstReview;
