import { css } from '@emotion/react';

const getStyle = ({ spacing, radius, color }) => css`
  min-height: 34px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-end;
  justify-content: flex-end;
  flex: none;
`;

export default getStyle;
