import { css } from '@emotion/react';

const getStyle = css`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

const getHeaderStyle = ({ color, spacing, font }) => css`
  width: 100%;
  height: 60px;
  padding: ${spacing.gap['05']};
  border-bottom: 1px solid ${color.neutral['40']};
  background: ${color.neutral['20']};
  display: flex;
  align-items: center;
  align-content: center;

  &:hover {
    cursor: pointer;
  }

  & > div {
    padding: ${spacing.gap['03']};
    display: flex;
    gap: ${spacing.gap['04']};
    align-items: center;
    align-content: center;

    & > span {
      color: ${color.neutral['100']};
      font-size: ${font.size.s};
      font-weight: ${font.weight.regular};
    }
  }
`;

const getTemplatesWrapperStyle = ({ color, spacing }) => css`
  width: 100%;
  overflow: auto;
  display: flex;
  gap: ${spacing.gap['06']};
  padding: ${spacing.gap['05']};
  background: ${color.neutral['0']};
  border-bottom: 1px solid ${color.neutral['40']};
  border-left: 1px solid ${color.neutral['40']};
  border-right: 1px solid ${color.neutral['40']};
`;

export { getStyle, getHeaderStyle, getTemplatesWrapperStyle };
