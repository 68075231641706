import { css } from '@emotion/react';

export const filtersStyles = ({ spacing }) => css`
  margin-bottom: ${spacing.gap['02']};
  margin-top: ${spacing.gap['06']};
  position: relative;
  z-index: 10;

  & div.form {
    margin-bottom: ${spacing.gap['06']};
    display: flex;
    justify-content: space-between;
    align-item: center;
    align-content: center;
    column-gap: 100px;
    font-size: 1.1rem;
    line-height: 1.4rem;

    & > div.left {
      display: flex;
      justify-content: flex-start;
      align-item: center;
      align-content: center;
      column-gap: 10px;
      flex-grow: 1;

      & > div:last-of-type {
        flex-grow: 1;
        max-width: 412px;
      }
    }
  }

  .col {
    min-width: auto;
  }
`;
