import { css } from '@emotion/react';
import React from 'react';

import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import Panel from '@analytics-components/insights/Panel';

import codeReviewsPerPR from './codeReviewsPerPullRequest';
import participantsPerPR from './participantsPerPullRequest';
import pullRequestsReviewed from './pullRequestsReviewed';
import reviewCommentsPerPR from './reviewCommentsPerPullRequest';

const wrapperStyles = ({ spacing }) => css`
  display: flex;
  margin: ${spacing.gap['0']} -${spacing.gap['04']};
`;

const chartWrapperStyles = ({ spacing }) => css`
  padding: ${spacing.gap['09']};
  width: 50%;
`;

const meta = {
  title: 'Code Review Engagement',
  description:
    'Understand how engaged developers are in the code review process. <p class="small text-secondary mt-1 mb-0">Metrics computed over reviewed PRs.</p>',
};

const ReviewEngagementPanel = () => {
  return (
    <Panel meta={meta}>
      <div css={wrapperStyles}>
        <div css={chartWrapperStyles}>
          <ChartBoxWithKPIWidget
            description={pullRequestsReviewed.description}
            id="code-review-engagement"
            plumber={pullRequestsReviewed.plumber}
            prefetchedDataIds={pullRequestsReviewed.prefetchedDataIds}
            title={pullRequestsReviewed.title}
          />
        </div>
        <div css={chartWrapperStyles}>
          <ChartBoxWithKPIWidget
            description={codeReviewsPerPR.description}
            id="code-reviews-per-pull-request"
            plumber={codeReviewsPerPR.plumber}
            prefetchedDataIds={codeReviewsPerPR.prefetchedDataIds}
            title={codeReviewsPerPR.title}
          />
        </div>
      </div>
      <div css={wrapperStyles}>
        <div css={chartWrapperStyles}>
          <ChartBoxWithKPIWidget
            description={reviewCommentsPerPR.description}
            id="review-comments-per-pull-request"
            plumber={reviewCommentsPerPR.plumber}
            prefetchedDataIds={reviewCommentsPerPR.prefetchedDataIds}
            title={reviewCommentsPerPR.title}
          />
        </div>
        <div css={chartWrapperStyles}>
          <ChartBoxWithKPIWidget
            description={participantsPerPR.description}
            id="participants-per-pull-request"
            plumber={participantsPerPR.plumber}
            prefetchedDataIds={participantsPerPR.prefetchedDataIds}
            title={participantsPerPR.title}
          />
        </div>
      </div>
    </Panel>
  );
};

export default ReviewEngagementPanel;
