import React from 'react';

import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import Panel from '@analytics-components/insights/Panel';
import { prepareChartBoxes } from '@analytics-pages/delivery-pipeline/insights/helper';

import prsLocsDonutDistribution from './prs-locs-donut-distribution';
import prsLocsHistogramDistribution from './prs-locs-histogram-distribution';

const meta = {
  title: 'Pull Request Size Distribution',
  description:
    'Distribution of pull requests by size in terms of lines of code. <p class="small text-secondary mt-1 mb-0">Calculated up to the 95th percentile.</p>',
};

const chartBoxes = [
  {
    id: 'prs-locs-histogram-distribution',
    component: ChartBoxWithKPIWidget,
    params: prsLocsHistogramDistribution,
  },
  {
    id: 'prs-locs-donut-distribution',
    component: ChartBoxWithKPIWidget,
    params: prsLocsDonutDistribution,
  },
];

const PRSizeDistributionPanel = () => {
  const preparedChartBoxes = prepareChartBoxes(chartBoxes);
  return <Panel meta={meta} chartBoxes={preparedChartBoxes} />;
};

export default PRSizeDistributionPanel;
