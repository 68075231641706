import { css } from '@emotion/react';

export const wrapperStyles = ({ spacing }) => css`
  position: relative;
  height: 100%;
`;

export const toggleStyles = ({ spacing }) => css`
  height: 100%;
  padding-left: ${spacing.gap['03']};
  padding-right: ${spacing.gap['03']};
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

export const avatarStyles = css`
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;

export const dropdownStyles = (visible) => ({ spacing, color, font, shadow }) => css`
  ${!visible
    ? `
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    transform: scale(0.95);
  `
    : `
    opacity: 1;
    transform: scale(1);
  `}
  transition: all 0.1s;
  width: 30rem;
  position: absolute;
  right: 0;
  top: calc(100% + ${spacing.gap['1px']});
  background: ${color.neutral.white};
  box-shadow: ${shadow.full};
  border: 1px solid ${color.neutral['40']};

  header,
  footer,
  section {
    padding: ${spacing.gap['04']} ${spacing.gap['05']};

    &:not(:last-child) {
      border-bottom: 1px solid ${color.neutral['40']};
    }
  }

  button:hover svg {
    color: ${color.neutral.white} !important;
  }

  header {
    min-height: 4.3rem;
    border-bottom: 1px solid ${color.neutral['40']};
    background: ${color.neutral['0']};

    display: flex;
    align-items: center;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: ${spacing.gap['0']};
      margin-left: ${spacing.gap['04']};
      font-size: ${font.size.m};
      color: ${color.neutral['100']};
    }
  }

  footer {
    background: ${color.neutral['0']};
    text-align: right;
  }
`;

export const settingsButtonStyles = ({ font, color }) => css`
  font-weight: ${font.weight.light};

  a {
    color: ${color.neutral['100']};

    &:hover {
      color: ${color.neutral['80']};
    }
  }
`;

export const loginButtonStyles = ({ color }) => css`
  color: ${color.neutral['80']};

  &:hover {
    color: ${color.neutral['100']};
  }
`;
