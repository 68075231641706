import moment, { unitOfTime } from 'moment';

import { intervalNameOverwrites } from '@align-pages/dashboard/config';
import { Unit } from '@align-types/constants';

export interface IDateRange {
  dateFrom: string;
  dateTo: string;
}

export interface IDateRangeWithDisplayName extends IDateRange {
  displayName: string;
}

interface IGetRemainingDays {
  date: string;
  validFrom?: string;
}

interface IGetRemainingDaysResult {
  hasEnded: boolean;
  hasStarted: boolean;
  remainingDays: number;
}

enum DateUnits {
  YEARS = 'years',
  MONTHS = 'months',
  WEEKS = 'weeks',
  DAYS = 'days',
}

type IDateUnitNames = {
  [key in DateUnits]: Unit;
};

const DateUnitNames: IDateUnitNames = {
  [DateUnits.YEARS]: {
    singular: 'year',
    plural: 'years',
  },
  [DateUnits.MONTHS]: {
    singular: 'month',
    plural: 'months',
  },
  [DateUnits.WEEKS]: {
    singular: 'week',
    plural: 'weeks',
  },
  [DateUnits.DAYS]: {
    singular: 'day',
    plural: 'days',
  },
};

export const isBeforeToday = (date: string) => moment().format('YYYY-MM-DD') > date;

export const isAfterToday = (date: string) => moment().format('YYYY-MM-DD') < date;

export const getRemainingDays = ({
  date,
  validFrom,
}: IGetRemainingDays): IGetRemainingDaysResult => {
  const hasStarted = !validFrom || isBeforeToday(validFrom);
  const hasEnded = date && isBeforeToday(date);
  const givenDate = hasStarted ? date : validFrom;
  const remainingDays = getDaysDiff(moment().format('YYYY-MM-DD'), givenDate);
  return { hasEnded, hasStarted, remainingDays };
};

const getDaysDiff = (date1: string, date2: string): number =>
  moment(date2).diff(moment(date1), 'days');

export const getPrevRange = ({ dateFrom, dateTo }: IDateRange): IDateRangeWithDisplayName => {
  // check if it's a quarter
  const threeMonthsAhead = moment(dateFrom).add(3, 'months').subtract(1, 'days');
  if (moment(dateTo).isSame(threeMonthsAhead)) {
    // we've a got a quarter
    const quarterBack = moment(dateFrom).subtract(3, 'months');
    return {
      dateFrom: quarterBack.format('YYYY-MM-DD'),
      dateTo: moment(dateFrom).subtract(1, 'days').format('YYYY-MM-DD'),
      displayName: `Q${quarterBack.quarter()}-${quarterBack.year()}`,
    };
  } else {
    const yearAhead = moment(dateFrom).add(1, 'years').subtract(1, 'days');
    if (moment(dateTo).isSame(yearAhead)) {
      // we've a got a year
      const yearBack = moment(dateFrom).subtract(1, 'year');
      return {
        dateFrom: yearBack.format('YYYY-MM-DD'),
        dateTo: moment(dateFrom).subtract(1, 'days').format('YYYY-MM-DD'),
        displayName: yearBack.year().toString(),
      };
    }
    // we've got a custom date range
    const diff = getDaysDiff(dateFrom, dateTo);
    return {
      dateFrom: moment(dateFrom)
        .subtract(1 + diff, 'days')
        .format('YYYY-MM-DD'),
      dateTo: moment(dateFrom).subtract(1, 'days').format('YYYY-MM-DD'),
      displayName: `Last ${diff} days`,
    };
  }
};

export const getCurrentRange = ({ dateFrom, dateTo }: IDateRange): string => {
  // check if it's a quarter
  const threeMonthsAhead = moment(dateFrom).add(3, 'months').subtract(1, 'days');
  if (moment(dateTo).isSame(threeMonthsAhead)) {
    // we've a got a quarter
    return `Q${moment(dateFrom).quarter()}-${moment(dateFrom).year()}`;
  } else {
    const yearAhead = moment(dateFrom).add(1, 'years').subtract(1, 'days');
    if (moment(dateTo).isSame(yearAhead)) {
      // we've a got a year
      return moment(dateFrom).year().toString();
    } else {
      // we've got a custom date range
      const daysDiff = getDaysDiff(dateFrom, dateTo);
      return `Next ${daysDiff} days`;
    }
  }
};

export const getQuarterYearAhead = (quarterAhead: number): string => {
  return moment().add(quarterAhead, 'Q').format('[Q]Q - YYYY');
};

export const getYearAhead = (yearsAhead: number = 0): string => {
  return moment().add(yearsAhead, 'years').format('YYYY');
};

export const getUnitsAheadStart = (
  amount: number = 0,
  unitName: unitOfTime.DurationConstructor
): string => {
  return moment().add(amount, unitName).startOf(unitName).format('YYYY-MM-DD');
};

export const getUnitsAheadEnd = (
  amount: number = 0,
  unitName: unitOfTime.DurationConstructor
): string => {
  return moment().add(amount, unitName).endOf(unitName).format('YYYY-MM-DD');
};

export const getUnitsBefore = (
  amount: number = 0,
  unitName: unitOfTime.DurationConstructor
): string => {
  return moment().subtract(amount, unitName).format('YYYY-MM-DD');
};

export const getQuarterYearByExpiresAt = (expiresAt: string): string => {
  const quarter = moment(expiresAt).quarter();
  const year = moment(expiresAt).year();
  return `Q${quarter} - ${year}`;
};

export const getCurrentDate = (): string => {
  return moment().format('YYYY-MM-DD');
};

export const durationToTimeInterval = (duration: string) => {
  const daysDiff = moment.duration(duration).asDays();
  return {
    date_from: moment().subtract(daysDiff, 'd').format('YYYY-MM-DD'),
    date_to: getCurrentDate(),
  };
};

// This method created because moment.duration not supporting weeks. There is a discussion over it and related
// PR seems released but still not working with weeks.
// https://github.com/moment/moment/issues/2797 => https://github.com/moment/moment/pull/2818
interface IParseIso8601Return {
  [DateUnits.YEARS]: number;
  [DateUnits.MONTHS]: number;
  [DateUnits.WEEKS]: number;
  [DateUnits.DAYS]: number;
}

const parseIso8601 = (isoString: string): IParseIso8601Return => {
  const isoRegex = /^P(?!$)(?:([-+]?[0-9,.]*)Y)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)W)?(?:([-+]?[0-9,.]*)D)?$/;
  const parsedData = isoString.match(isoRegex);
  return {
    [DateUnits.YEARS]: parseInt(parsedData[1]) || 0,
    [DateUnits.MONTHS]: parseInt(parsedData[2]) || 0,
    [DateUnits.WEEKS]: parseInt(parsedData[3]) || 0,
    [DateUnits.DAYS]: parseInt(parsedData[4]) || 0,
  };
};

interface IDurationToReadableTextParams {
  duration: string;
  prefix?: string;
}

export const durationToReadableText = ({
  duration,
  prefix,
}: IDurationToReadableTextParams): string => {
  const nameOverwrite = intervalNameOverwrites[duration];
  if (nameOverwrite) {
    return nameOverwrite;
  }

  const data = parseIso8601(duration);
  const unitOrder = [DateUnits.YEARS, DateUnits.MONTHS, DateUnits.WEEKS, DateUnits.DAYS];
  const displayUnit: DateUnits = unitOrder.find((unitName) => data[unitName]);
  const unitValue: number = data[displayUnit];
  return `${prefix ? `${prefix} ` : ''}${
    unitValue > 1 ? `${unitValue} ${displayUnit}` : DateUnitNames[displayUnit].singular
  }`;
};

export * as DateService from './dateService';
