import React from 'react';

import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import Panel from '@analytics-components/insights/Panel';
import { prepareChartBoxes } from '@analytics-pages/delivery-pipeline/insights/helper';

import runTimePerRepository from './runTimePerRepository';

const meta = {
  title: 'Suite Run Time per Repository',
  description:
    'Follow the evolution of the average suite run time per repository and take action if the one curve is soaring. <p class="small text-secondary mt-1 mb-0">Calculated up to the 95th percentile.</p>',
};

const chartBoxes = [
  {
    id: 'suite-run-time-per-repository',
    component: ChartBoxWithKPIWidget,
    params: runTimePerRepository,
  },
];

const RunTimePerRepoPanel = () => {
  const preparedChartBoxes = prepareChartBoxes(chartBoxes);
  return <Panel meta={meta} chartBoxes={preparedChartBoxes} />;
};

export default RunTimePerRepoPanel;
