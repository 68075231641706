interface IDeploymentsPR {
  repository: string;
  title: string;
  number: number;
  created: Date;
  author: string;
  authorAvatar: string;
}

export enum DeploymentStatus {
  SUCCESS = 'Successful',
  FAIL = 'Failing',
}

export interface ISerializedDeployment {
  isSuccessful: boolean;
  status: DeploymentStatus;
  name: string;
  finished: Date;
  additions: number;
  deletions: number;
  deployTime: number;
  environment: string;
  prsAmount: number;
  subRows: IDeploymentsPR[];
}

export type IDeploymentsRow = ISerializedDeployment & IDeploymentsPR;
