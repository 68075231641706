import React from 'react';

import { icons } from '@lib/icon';

import { IFilterTypeOptions, FilterType } from './filterPanel.types';
import { JiraIssueTypes } from './jira-issue-types';
import { JiraLabels } from './jira-labels';
import { JiraPriorities } from './jira-priorities';
import { JiraProjects } from './jira-projects';
import { Repository } from './repository';

export const FILTER_TYPE_OPTIONS: IFilterTypeOptions = {
  [FilterType.REPOSITORY]: {
    label: 'Repository',
    icon: icons.repository,
    value: FilterType.REPOSITORY,
    component: ({ onChange, chartParamType }) => (
      <Repository onChange={onChange} chartParamType={chartParamType} />
    ),
  },
  [FilterType.JIRA_ISSUE_TYPES]: {
    label: 'Issue Type',
    icon: icons.issues,
    value: FilterType.JIRA_ISSUE_TYPES,
    component: ({ onChange, chartParamType }) => (
      <JiraIssueTypes onChange={onChange} chartParamType={chartParamType} />
    ),
  },
  [FilterType.JIRA_PROJECTS]: {
    label: 'Project',
    icon: icons.nav_epic,
    value: FilterType.JIRA_PROJECTS,
    component: ({ onChange, chartParamType }) => (
      <JiraProjects onChange={onChange} chartParamType={chartParamType} />
    ),
  },
  [FilterType.JIRA_PRIORITIES]: {
    label: 'Priority',
    icon: icons.nav_epic,
    value: FilterType.JIRA_PRIORITIES,
    component: ({ onChange, chartParamType }) => (
      <JiraPriorities onChange={onChange} chartParamType={chartParamType} />
    ),
  },
  [FilterType.JIRA_LABELS]: {
    label: 'Jira Labels',
    icon: icons.nav_epic,
    value: FilterType.JIRA_LABELS,
    component: ({ onChange, chartParamType }) => (
      <JiraLabels onChange={onChange} chartParamType={chartParamType} />
    ),
  },
  [FilterType.TEAMS]: {
    label: 'Teams',
    icon: icons.people,
    value: FilterType.TEAMS,
  },
};
