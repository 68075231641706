import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { ChartType, KPIType } from '@analytics-components/insights/Box/types';

import BoxKPI from '../BoxKPI';
import ChartTitle from '../ChartTitle';

interface Props {
  chart: ChartType;
  kpis: KPIType[];
}

const WithKPIBoxBodyRow: React.FC<Props> = React.memo(({ chart, kpis }) => {
  const kpisWithID = useMemo(
    () =>
      kpis.map((kpi) => ({
        ...kpi,
        uniqueID: uuidv4(),
      })),
    [kpis]
  );
  return (
    <>
      <ChartTitle {...chart} />
      <div className="row">
        <div className="col-7">
          <chart.component {...chart.params} />
        </div>
        <div className="col-5 align-self-center">
          <div className="row justify-content-center">
            <div className="col-8">
              {kpisWithID.map((kpi) => (
                <BoxKPI key={kpi.uniqueID} title={kpi.title} subtitle={kpi.subtitle}>
                  <kpi.component params={kpi.params} />
                </BoxKPI>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default WithKPIBoxBodyRow;
