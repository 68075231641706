import { css } from '@emotion/react';

export const wrapperStyles = ({ circleColor, size, isOnClick }) => ({ color, shadow }) => css`
  align-items: center;
  background-color: ${circleColor};
  border-radius: calc(${size}px / 2);
  display: flex;
  height: ${size}px;
  justify-content: center;
  position: relative;
  width: ${size}px;
  box-shadow: ${shadow.full};
  ${isOnClick && `cursor: pointer`};

  .ath-outer-circle {
    border: 1px solid ${color.neutral[80]};
    border-radius: calc((${size}px + 6px) / 2);
    height: calc(${size}px + 6px);
    left: -3px;
    position: absolute;
    top: -3px;
    width: calc(${size}px + 6px);
  }
`;
