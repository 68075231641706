import { css } from '@emotion/react';

const getStyle = ({ radius, color }) => css`
  overflow: hidden;
  position: relative;
  border-radius: ${radius.default};
  border: 1px solid ${color.neutral['40']};
`;

export { getStyle };
