import { ThemeProvider } from '@emotion/react';
import React, { useCallback, useEffect, useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import { QueryClientProvider, QueryClient } from 'react-query';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
import { ReactQueryDevtools } from 'react-query/devtools';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';
import { Router } from 'react-router-dom';
import { Slide, toast, ToastContainer } from 'react-toastify';

import { CacheService } from '@align-services/cacheService';
import ApiContext from '@analytics-context/Api';
import history from '@analytics-services/history';
import { init as initSentry } from '@analytics-services/sentry';
import Auth0Context from '@common-context/Auth0';
import UserContext from '@common-context/User';
import Maintenance from '@common-pages/offline/Maintenance';
import log from '@common-services/logger';
import Routes from '@routes/index';
import { isUnderMaintenance } from '@routes/offlineRoutes';
import { theme } from '@styles/theme';
import Development from '@utils/development';

initSentry();
toast.configure();

export const queryClient = new QueryClient();
const localStoragePersistor = createWebStoragePersistor({ storage: window.localStorage });

const App = () => {
  const [isMaintenance, setIsMaintenance] = useState(null);

  // A function that routes the user to the right place after login
  const onRedirectCallback = useCallback((appState) => {
    let qp = new URLSearchParams(appState).toString();
    history.push('/callback?' + qp);
  }, []);

  const initPersistCache = async () => {
    try {
      await persistQueryClient({
        queryClient,
        persistor: localStoragePersistor,
      });
      await CacheService.removeQuery(['userDetails']);
    } catch {
      log.error('Something went wrong while cache initialisation, please reload the page.');
    }
  };

  useEffect(() => {
    async function checkMaintenance() {
      const result = await isUnderMaintenance();
      setIsMaintenance(result);
      if (result) {
        history.push('/maintenance');
      }
    }
    checkMaintenance().then();
    initPersistCache().then();
  }, []);

  const Body = () =>
    isMaintenance ? (
      <Maintenance />
    ) : (
      <CookiesProvider>
        <QueryClientProvider client={queryClient}>
          <React.StrictMode>
            <UserContext>
              <ApiContext>
                <Router history={history}>
                  <Routes />
                </Router>
              </ApiContext>
            </UserContext>
          </React.StrictMode>
          {process.env.NODE_ENV === 'development' && (
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          )}
        </QueryClientProvider>
      </CookiesProvider>
    );

  return (
    <>
      <Auth0Context
        domain={window.ENV.auth.domain}
        client_id={window.ENV.auth.clientId}
        redirect_uri={window.ENV.auth.redirectUri}
        audience={window.ENV.auth.audience}
        onRedirectCallback={onRedirectCallback}
        // https://auth0.com/docs/libraries/auth0-spa-js#change-storage-options
        cacheLocation={'localstorage'}
        // https://auth0.com/docs/libraries/auth0-spa-js#use-rotating-refresh-tokens
        useRefreshTokens={true}
      >
        <ThemeProvider theme={theme}>{isMaintenance !== null && <Body />}</ThemeProvider>
      </Auth0Context>
      <ToastContainer
        position="top-center"
        hideProgressBar
        closeButton={false}
        closeOnClick
        draggable={false}
        pauseOnFocusLoss={true}
        pauseOnHover={true}
        transition={Slide}
      />
      <div id="dialog-holder" />
      {process.env.NODE_ENV === 'development' && <Development.Breakpoints />}
    </>
  );
};

export default App;
