import { github } from '@common-services/format';
import { SelectTsOptionSingle } from '@lib/selector-ts';

export interface IReposInput {
  value: string;
  label: string;
}

export function convertRepos(
  selectedRepos: string[],
  allRepos: IReposInput[]
): SelectTsOptionSingle[] {
  return allRepos.map((repo) => {
    return {
      name: github.repoName(repo.label),
      isSelected: selectedRepos.includes(repo.value),
      id: repo.value,
      children: [],
    };
  });
}

export * as ReposService from './repos';
