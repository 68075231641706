import _ from 'lodash';
import React from 'react';

import { Info } from '@lib/info';

import {
  activeIconWrapperStyle,
  baseIconWrapperStyle,
  borderStyle,
  firstIconWrapperStyle,
  lastIconWrapperStyle,
  switcherWrapperStyles,
} from './styles';

const PanelContentSwitcher = ({
  buttonSize = 28,
  iconSize = 12,
  tabs,
  activeTab,
  onContentChange,
}) => {
  const buildStyle = (index) => {
    const isActive = activeTab === index;
    const style = [baseIconWrapperStyle(buttonSize)];

    if (index === 0 || index === tabs.length - 1) {
      style.push(borderStyle);
      if (index === 0) {
        style.push(firstIconWrapperStyle);
      } else {
        style.push(lastIconWrapperStyle);
      }
    }

    if (isActive) {
      style.push(activeIconWrapperStyle);
    }

    return style;
  };

  return (
    <div css={switcherWrapperStyles}>
      {_(tabs)
        .map((t, i) => (
          <div key={i} css={buildStyle(i)} onClick={() => onContentChange(i)}>
            <Info
              content={t.description}
              tooltipStyle={{
                lineHeight: '1rem',
                marginLeft: 0,
              }}
              icon={t.icon}
              size={iconSize}
            />
          </div>
        ))
        .value()}
    </div>
  );
};

export default PanelContentSwitcher;
