import PropTypes from 'prop-types';
import React from 'react';

import { Button, ButtonType } from '@lib/Button';

import { footerWrapperStyles } from './styles';

const Footer = ({ onApply, onCancel }) => {
  return (
    <div css={footerWrapperStyles}>
      <Button label="Cancel" type={ButtonType.TEXT} onClick={onCancel} />
      <Button label="Apply" onClick={onApply} />
    </div>
  );
};

const FooterPropTypes = {
  onApply: PropTypes.func,
  onCancel: PropTypes.func,
};

Footer.propTypes = FooterPropTypes;

export default Footer;
