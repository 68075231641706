import React, { useMemo } from 'react';

import { getTeamColor } from '@common-services/colorService';
import { Avatar } from '@lib/avatar';
import { EditableName } from '@lib/editable-name';
import { Icon, icons } from '@lib/icon';
import { useTheme } from '@styles/hooks';

import { wrapperStyles, metricStyles, descriptionStyles, teamStyles } from './metricHeader.styles';
import { IMetricHeader } from './metricHeader.types';

export const MetricHeader: React.FC<IMetricHeader> = React.memo(
  ({ existingChart, metric, currentTeam, onNameChange }) => {
    const teamColor = useMemo(() => getTeamColor(currentTeam?.name), [currentTeam]);
    const { color } = useTheme();
    return (
      <div css={wrapperStyles}>
        <div css={metricStyles}>
          <div className="icon">
            <Icon icon={icons.line_chart} color={color.ui.orange['100']} size={18} />
          </div>
          <div className="title">
            <EditableName
              isEditable={true}
              defaultValue={existingChart?.name || metric.name}
              onNameChange={onNameChange}
            />
          </div>
        </div>
        <div css={descriptionStyles}>{metric.description}</div>
        <div css={teamStyles}>
          <Avatar type={3} color={teamColor} label={currentTeam.name} size={22} />
          {currentTeam.name}
        </div>
      </div>
    );
  }
);
