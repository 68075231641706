import { useTheme } from '@emotion/react';
import { curveLinear, curveNatural, curveCatmullRom, curveBundle, curveMonotoneX } from 'd3-shape';
import React, { useState } from 'react';
import { FlexibleWidthXYPlot, YAxis, HorizontalGridLines, LineSeries, MarkSeries } from 'react-vis';

const data = [42, 35, 23, 23, 12, 22, 26, 45, 45, 29, 11, 0, 0, 20];
const dataPoints = data.map((v, i) => ({ x: i, y: v }));

const edgeCasesData = [
  42,
  35,
  23,
  23,
  12,
  150,
  150,
  0,
  0,
  300,
  300,
  50,
  10,
  0,
  0,
  150,
  20,
  20,
  20,
  20,
  20,
  20,
  20,
  20,
  20,
  20,
  20,
  20,
];
const edgeCasesDataPoints = edgeCasesData.map((v, i) => ({ x: i, y: v }));

const Chart = () => (
  <div style={{ background: 'white' }}>
    <SmoothLinesChart data={dataPoints} />
    <div style={{ marginBottom: '50px' }} />
    <SmoothLinesChart data={edgeCasesDataPoints} />
  </div>
);

const ALL = Symbol();
const SHARP = Symbol();
const NATURAL = Symbol();
const CATMULL_ROM_ZERO = Symbol();
const CATMULL_ROM_HALF = Symbol();
const CATMULL_ROM_ONE = Symbol();
const BUNDLE = Symbol();
const MONOTONE = Symbol();

const items = [
  {
    id: SHARP,
    curve: curveLinear,
    title: 'sharp',
    stroke: 'black',
    strokeDasharray: [1, 0],
    strokeWidth: 1,
  },
  {
    id: ALL,
    title: 'all',
    stroke: 'black',
    strokeDasharray: [4, 2],
    strokeWidth: 2,
  },
  {
    id: NATURAL,
    curve: curveNatural,
    title: 'natural',
    stroke: 'cyan',
    strokeDasharray: [4, 2],
    strokeWidth: 2,
  },
  {
    id: CATMULL_ROM_ZERO,
    curve: curveCatmullRom.alpha(0),
    title: 'catmull::0',
    stroke: 'green',
    strokeDasharray: [4, 2],
    strokeWidth: 2,
  },
  {
    id: CATMULL_ROM_HALF,
    curve: curveCatmullRom.alpha(0.5),
    title: 'catmull::0.5',
    stroke: 'orange',
    strokeDasharray: [4, 2],
    strokeWidth: 2,
  },
  {
    id: CATMULL_ROM_ONE,
    curve: curveCatmullRom.alpha(1),
    title: 'catmull::1',
    stroke: 'red',
    strokeDasharray: [4, 2],
    strokeWidth: 2,
  },
  {
    id: BUNDLE,
    curve: curveBundle.beta(1),
    title: 'bundle::1',
    stroke: 'purple',
    strokeDasharray: [5, 2, 1, 2],
    strokeWidth: 2,
  },
  {
    id: MONOTONE,
    curve: curveMonotoneX,
    title: 'monotone',
    stroke: 'magenta',
    strokeDasharray: [4, 2],
    strokeWidth: 2,
  },
];

const conf = (id) => items.find((item) => item.id === id);

const SmoothLinesChart = ({ data }) => {
  const [curveState, setCurveState] = useState(ALL);

  return (
    <>
      <FlexibleWidthXYPlot height={250}>
        <HorizontalGridLines />
        <YAxis />

        {curveState !== ALL && <LineSeries data={data} {...conf(curveState)} />}

        {curveState === ALL && <LineSeries data={data} {...conf(NATURAL)} />}
        {curveState === ALL && <LineSeries data={data} {...conf(CATMULL_ROM_ZERO)} />}
        {curveState === ALL && <LineSeries data={data} {...conf(CATMULL_ROM_HALF)} />}
        {curveState === ALL && <LineSeries data={data} {...conf(CATMULL_ROM_ONE)} />}
        {curveState === ALL && <LineSeries data={data} {...conf(BUNDLE)} />}
        {curveState === ALL && <LineSeries data={data} {...conf(MONOTONE)} />}

        <LineSeries data={data} {...conf(SHARP)} />
        <MarkSeries data={data} stroke="black" fill="white" size="3" />
      </FlexibleWidthXYPlot>

      <Config select={setCurveState} selectedId={curveState} />
    </>
  );
};

const Config = ({ ...rest }) => (
  <div style={{ textAlign: 'center' }}>
    {items.slice(1).map((item, i) => (
      <CurveType name={item.title} id={item.id} key={i} {...rest} />
    ))}
  </div>
);

const CurveType = ({ name, id, select, selectedId, last }) => {
  const theme = useTheme();
  const selected = selectedId === id;

  return (
    <span
      onClick={() => select(id)}
      style={{
        color: conf(id).stroke,
        cursor: selected ? 'default' : 'pointer',
        display: 'block-inline',
        marginLeft: '-1px',
        padding: '3px 5px',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderTopColor: selected ? theme.color.neutral[80] : 'transparent',
        borderRightColor: !last || selected ? theme.color.neutral[80] : 'transparent',
        borderBottomColor: selected ? '#222' : 'transparent',
        borderLeftColor: selected ? theme.color.neutral[80] : 'transparent',
      }}
    >
      {name}
    </span>
  );
};

export default Chart;
