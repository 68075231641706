import { css } from '@emotion/react';

export const wrapperStyles = ({ width, withSearch }) => ({
  color,
  font,
  radius,
  spacing,
  shadow,
}) => css`
  background-color: ${color.neutral.white};
  border-radius: ${radius.default};
  box-shadow: ${shadow.full};
  position: absolute;
  top: calc(32px + ${spacing.gap['04']});
  ${withSearch && 'min-width: 233px;'}
  width: ${width ? `${width}px` : 'fit-content'};
  z-index: 100;

  .ath-dropdown-option {
    border-bottom: 1px solid ${color.neutral[40]};
    color: ${color.text.dark};
    font-size: ${font.size.default};
    font-weight: ${font.weight.regular};
    padding: ${spacing.gap['03']} ${spacing.gap['04']};
    display: flex;
    align-items: center;

    .option-avatar {
      margin-right: ${spacing.gap['03']};
    }

    &:last-of-type {
      border-bottom: none;
    }

    &:hover {
      background-color: ${color.neutral[20]};
    }
  }
`;

export const searchStyles = ({ color, spacing }) => css`
  min-width: 233px;
  background: ${color.neutral['40']};
  padding: ${spacing.gap['04']};
`;
