import { useTheme } from '@emotion/react';
import _ from 'lodash';
import React from 'react';

import { Icon } from '@lib/icon';

const ButtonUI = (props) => {
  const {
    style,
    icon,
    color,
    size,
    label,
    disabled,
    buttonRef,
    title,
    dataTestId,
    ...handlers
  } = props;
  const theme = useTheme();
  const safeHandlers = _(handlers)
    .pickBy((v, k) => _(k).startsWith('on'))
    .value();
  return (
    <button
      className="btn"
      css={style}
      disabled={disabled}
      ref={buttonRef}
      title={title}
      data-testid={dataTestId}
      {...safeHandlers}
    >
      {icon && <Icon color={color || theme.color.neutral.white} icon={icon} size={size || 14} />}
      <div className="label">{label}</div>
    </button>
  );
};

export default ButtonUI;
