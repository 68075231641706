import { IUrlParams } from '@common-services/api/common/types/apiClient';
import { publicApi } from '@common-services/api/public';
import {
  Contributors,
  DeveloperSummaries,
  FilterContributorsRequest,
} from '@common-services/api/public/generated-from-backend/models';

export const getContributors = async (
  urlParams: IUrlParams<'accountId'>,
  token: string
): Promise<Contributors> => {
  return publicApi.contributors.getContributors(urlParams, token, {
    message: `Cannot get contributors for accountId ${urlParams.accountId}`,
  });
};

export const getFilteredContributors = async (
  params: FilterContributorsRequest,
  token: string
): Promise<DeveloperSummaries> => {
  return publicApi.contributors.getFilteredContributors(params, token, {
    message: `Cannot get filtered contributors for accountId ${params.account}`,
  });
};

export * as ContributorsServices from './contributors';
