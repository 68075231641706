import React, { useState, useCallback, useEffect, useMemo, useLayoutEffect } from 'react';

import { SelectorTs, SelectTsOptionSingle } from '@lib/selector-ts';

import { wrapperStyles } from './filterDropdown.styles';
import { IFilterDropdown } from './filterDropdown.types';
import { FilterDropdownBox } from './ui/filter-dropdown-box';

export const FilterDropdown: React.FC<IFilterDropdown> = React.memo(
  ({
    options = [],
    isLoading,
    onChange,
    label,
    allSelectedLabel,
    isBadgeEnabled = true,
    fullWidth = false,
    initialSelected = null,
  }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [selectedItems, setSelectedItems] = useState<string[]>(options.map(({ id }) => id));

    useLayoutEffect(() => {
      if (initialSelected) {
        setSelectedItems(initialSelected);
      }
    }, [initialSelected]);

    useEffect(() => {
      if (!isLoading && !initialSelected?.length) {
        const allIds = options.map(({ id }) => id?.toString());
        setSelectedItems(allIds);
        onChange(allIds);
      }
    }, [isLoading]);

    const onApplyCallback = useCallback(
      (update) => {
        setIsAllSelected(update.length === 0);
        onChange(update.length === 0 ? options.map(({ id }) => id?.toString()) : update);
        setSelectedItems(update);
        setIsOpen(false);
      },
      [options]
    );

    const onClickCallback = useCallback(() => {
      setIsOpen((prevState) => !prevState);
    }, []);

    const menuOptions = useMemo<SelectTsOptionSingle[]>(
      () =>
        options?.map((option) => ({
          name: option.name,
          id: option.id,
          isSelected: selectedItems.includes(option.id),
          isOpened: false,
          children: [],
          parentIds: [],
          image: option.image,
        })),
      [options, selectedItems]
    );

    return (
      <div css={wrapperStyles({ fullWidth })}>
        <FilterDropdownBox
          label={label}
          allSelectedLabel={allSelectedLabel}
          isAllSelected={isAllSelected}
          onClick={onClickCallback}
          badge={isBadgeEnabled ? selectedItems.length.toString() : null}
          isLoading={isLoading}
          selectedItems={selectedItems}
          options={options}
          fullWidth={fullWidth}
        />
        {isOpen && (
          <div className="content">
            <SelectorTs
              withAllOption={true}
              optionsInit={menuOptions}
              onApply={onApplyCallback}
              onCancel={() => setIsOpen(false)}
            />
          </div>
        )}
      </div>
    );
  }
);
