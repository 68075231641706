import React, { useMemo } from 'react';

import { ChartBoxWithKPIUI } from '@analytics-components/insights/ChartBox';
import { InsightsError } from '@analytics-components/insights/Helper';
import Panel from '@analytics-components/insights/Panel';

import { useBiggestPRs } from './biggest-prs.hooks';
import { getChartData } from './biggest-prs.services';

const meta = {
  title: 'Largest Pull Requests',
  description: 'Shed light on the largest pull requests which slow down your software delivery.',
};

const BiggestPRsPanel: React.FC = React.memo(() => {
  const { prs, users, isLoading, isError } = useBiggestPRs();
  const chartData = useMemo(() => getChartData(prs, users), [prs, users]);

  if (isError) {
    return <InsightsError />;
  }

  return (
    <Panel meta={meta}>
      {isLoading ? (
        <div className="insights-placeholder" />
      ) : (
        <ChartBoxWithKPIUI data={chartData} />
      )}
    </Panel>
  );
});

export { BiggestPRsPanel };
