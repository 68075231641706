import { ValueType } from '@npm/athenian-lib-components';

import {
  IMetricsConfigNames,
  JiraInProgressOrderByField,
  JiraIssueMetricField,
  JiraStatusLabel,
  MetricColumnName,
  PrInProgressOrderByField,
  Unit,
} from '@align-types/constants';
import { PUBLIC_API_ENDPOINTS_CONSTANTS } from '@common-services/api/public/constants';
import { Datasource } from '@common-services/api/public/generated-from-backend/models';
import { IPrStageApiMapping } from '@common-services/prHelpers';
import { ChartTypeEnum } from '@lib/chart';
import { FilterType } from '@lib/filter-panel';
import { icons } from '@lib/icon';

export enum ChartMetrics {
  JIRA_LEAD_TIME = 'jira-lead-time',
  PR_CYCLE_TIME = 'pr-cycle-time',
  PR_WIP_TIME = 'pr-wip-time',
  PR_REVIEW_TIME = 'pr-review-time',
  PR_MERGE_TIME = 'pr-merging-time',
  PR_RELEASE_TIME = 'pr-release-time',
  PR_WAIT_FIRST_REVIEW_TIME = 'pr-wait-first-review-time',
  PR_OPEN_TIME = 'pr-open-time',
  PR_SIZE = 'pr-size',
  PR_ALL_MAPPED_TO_JIRA = 'pr-all-mapped-to-jira',
  PR_OPENED = 'pr-opened',
  PR_MERGED = 'pr-merged',
  PR_CYCLE_COUNT = 'pr-cycle-count',
  PR_REVIEWED_RATIO = 'pr-reviewed-ratio',
  PR_COMMENTS = 'pr-comments-per',
  PR_REVIEWS = 'pr-reviews-per',
  PR_PARTICIPANTS = 'pr-participants-per',
  PR_WIP_COUNT = 'pr-wip-count',
  PR_REVIEW_COUNT = 'pr-review-count',
  PR_MERGING_COUNT = 'pr-merging-count',
  PR_OPEN_COUNT = 'pr-open-count',
  PR_WAIT_FIRST_REVIEW_COUNT = 'pr-wait-first-review-count',
  PR_REVIEWED = 'pr-reviewed',
  JIRA_RAISED = 'jira-raised',
  JIRA_RESOLVED = 'jira-resolved',
  RELEASE_COUNT = 'release-count',
  JIRA_LIFE_TIME = 'jira-life-time',
  JIRA_ACKNOWLEDGE_TIME = 'jira-acknowledge-time',
  JIRA_ACKNOWLEDGED = 'jira-acknowledged',
}

export enum IChartTimeInterval {
  P1W = 'P1W',
  P2W = 'P2W',
  P1M = 'P1M',
  P3M = 'P3M',
  P6M = 'P6M',
  P1Y = 'P1Y',
}

export interface IChartConfig {
  description: string;
  metric: ChartMetrics;
  name: string;
  endpoint?: PUBLIC_API_ENDPOINTS_CONSTANTS;
  valueType: ValueType;
  chartType: ChartTypeEnum;
  unit: Unit;
  isFrequency?: boolean;
  group: Datasource;
  completedOrderBy?: IMetricsConfigNames;
  inProgressStages?: (keyof IPrStageApiMapping | JiraStatusLabel)[];
  inProgressOrderBy?: PrInProgressOrderByField | JiraInProgressOrderByField;
  inProgressOrderAscending?: boolean;
  metricColumnName?: MetricColumnName;
  jiraMetricField?: JiraIssueMetricField;
}

export interface INewChartParam {
  id: string;
  type: ChartParamsType;
  property: FilterType;
  rules?: string[];
}
export interface IChartParamProperties {
  value: FilterType;
  label: string;
  icon: icons;
  rules?: boolean;
  group: Datasource;
}

export enum ChartParamSelection {
  SINGLE = 'single',
  MULTI = 'multi',
}

export interface IChartParam {
  enabled: boolean;
  name: string;
  singular: string;
  description: string;
  selection: ChartParamSelection;
  properties: IChartParamProperties[];
}

export enum ChartParamsType {
  FILTERS = 'filters',
  BREAKDOWNS = 'breakdowns',
}

export type IChartParamsConfig = {
  [key in ChartParamsType]: IChartParam;
};

export type IUnsupportedChartFilters = {
  [key in ChartMetrics]?: FilterType[];
};
