import React from 'react';

import { PrimaryLayoutModes } from '@align-components/layouts/primary/index';
import { IMetricsConfigNames } from '@align-types/constants';
import {
  GoalMetricParams,
  TeamTree,
} from '@common-services/api/private/generated-from-backend/models';
import { IDropdownNameValue } from '@lib/Dropdown';
import { FilterType } from '@lib/filter-panel';

interface IBreadcrumb {
  label: string;
  path?: string;
}
export type Breadcrumbs = IBreadcrumb[];
export interface IPrimaryLayoutContextHandlers {
  mode?: PrimaryLayoutModes;
  isSubmitAllowed?: boolean;
  dateRangeInit?: IDropdownNameValue;
  selectedMetric?: IMetricsConfigNames;
  metricParams?: GoalMetricParams;
  dateRangeOnChange?: (dateRange: IDropdownNameValue) => void;
  onFiltersChange?: (props: { filterName: FilterType; update: string[] }) => void;
  onMetricParamsChange?: (props: GoalMetricParams) => void;
  onNewGoalNameChange?: (name: string) => void;
  onSubmit?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onCancel?: () => void;
  onRemove?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
export interface IPrimaryLayoutContext extends IPrimaryLayoutContextHandlers {
  path: string;
  currentTeamPath: string;
  section: string;
  parentTeams: TeamTree[];
  currentTeam: TeamTree | null;
  breadcrumbs: Breadcrumbs;
}

export interface IPrimaryLayoutProvider extends IPrimaryLayoutContextHandlers {
  tab: PrimaryLayoutTabs;
}

export enum PrimaryLayoutTabs {
  DASHBOARD = 'dashboard',
  GOALS = 'goals',
  TEAMS = 'teams',
}
