import _ from 'lodash';

import { getMetrics, getStages } from '@analytics-components/Thumbnails';
import { PRODUCTION_ENV, StagesEnum } from '@analytics-constants';
import { IntervalType } from '@analytics-types/common';
import { dateTime, humanLaboralRate } from '@common-services/dateService';
import { number } from '@common-services/format';
import { slugify } from '@utils/index';

import { subInfoStyles } from '../styles';

interface KPIType {
  title: string;
  value: any;
}

interface SummaryResulType {
  avgTimes: { [x: string]: number };
  normalizedProportions: { [x: string]: number };
  proportions: { [x: string]: number };
}

const buildKPIs = (
  stageSlug: string,
  data: any,
  interval: IntervalType
): KPIType[] | SummaryResulType => {
  const kpis = {
    wip: ['proportion of cycle time', 'pull requests created', 'authors', 'repositories'],
    review: ['proportion of cycle time', 'pull requests reviewed', 'reviewers', 'repositories'],
    merge: ['proportion of cycle time', 'pull requests merged', 'mergers', 'repositories'],
    release: ['proportion of cycle time', 'pull requests released', 'releases', 'repositories'],
    deploy: [
      'deployment frequency',
      'time to deploy',
      'deployments',
      'success ratio',
      'change failure rate',
    ],
  };
  const avgTimes = _(data['prs-metrics']['values']['all'])
    .pick(
      _(getStages())
        .map((s) => getMetrics(slugify(s)).metric)
        .value()
    )
    .mapValues((v) => v * 1000)
    .value();
  const stageCycleTimeSum = _(avgTimes).values().sum();

  const buildKPIsForSummary = () => {
    const proportions = _(avgTimes)
      .mapValues((v) => (v * 100) / stageCycleTimeSum)
      .value();
    const fastest = _(proportions).values().max();
    const normalizedProportions = _(proportions)
      .mapValues((v) => (v / fastest) * 100)
      .value();

    return {
      normalizedProportions,
      proportions,
      avgTimes,
    };
  };

  const buildKPIsForWIP = () => {
    const proportion = stageCycleTimeSum
      ? ((data['prs-metrics']['values']['all']['wip-time'] * 1000) / stageCycleTimeSum) * 100
      : null;
    const createdPRs = data['prs-metrics']['values']['all']['opened'];
    const authors = _(data['devs-metrics'])
      .filter((v) => v['prs-created'] > 0)
      .value().length;
    const repos = _(data['repos-metrics'])
      .filter((v) => v['opened'] > 0)
      .value().length;
    return [number.percentage(proportion), createdPRs, authors, repos];
  };

  const buildKPIsForReview = () => {
    const proportion = stageCycleTimeSum
      ? ((data['prs-metrics']['values']['all']['review-time'] * 1000) / stageCycleTimeSum) * 100
      : null;
    const reviewed = data['prs-metrics']['values']['all']['reviewed'];
    const reviewers = _(data['devs-metrics'])
      .filter(
        (v) =>
          v['reviews'] + v['pr-comments'] + v['regular-pr-comments'] + v['review-pr-comments'] > 0
      )
      .value().length;
    const repos = _(data['repos-metrics'])
      .filter((v) => v['reviewed'] > 0)
      .value().length;
    return [number.percentage(proportion), reviewed, reviewers, repos];
  };

  const buildKPIsForMerge = () => {
    const proportion = stageCycleTimeSum
      ? ((data['prs-metrics']['values']['all']['merging-time'] * 1000) / stageCycleTimeSum) * 100
      : null;
    const mergedPRs = data['prs-metrics']['values']['all']['merged'];
    const mergerers = _(data['devs-metrics'])
      .filter((v) => v['prs-merged'] > 0)
      .value().length;
    const repos = _(data['repos-metrics'])
      .filter((v) => v['merged'] > 0)
      .value().length;
    return [number.percentage(proportion), mergedPRs, mergerers, repos];
  };

  const buildKPIsForRelease = () => {
    const proportion = stageCycleTimeSum
      ? ((data['prs-metrics']['values']['all']['release-time'] * 1000) / stageCycleTimeSum) * 100
      : null;
    const releasedPRs = data['prs-metrics']['values']['all']['lead-count'];
    const releases = data['releases-metrics']['values']['all']['count'];
    const repos = _(data['repos-metrics'])
      .filter((v) => v['release-count'] > 0)
      .value().length;
    return [number.percentage(proportion), releasedPRs, releases, repos];
  };

  const buildKPIsForDeploy = () => {
    const prodData =
      data['deployments'].find((d) => d.for?.environments?.[0] === PRODUCTION_ENV) || {};

    const deploymentFrequencyValue = humanLaboralRate(
      interval.from,
      interval.to,
      prodData.values?.[0]?.values?.[0] || 0
    );
    const deploymentFrequency = (
      <div>
        {deploymentFrequencyValue[0]}
        <span css={subInfoStyles}> / {deploymentFrequencyValue[1]}</span>
      </div>
    );
    const timeToDeploy = dateTime.timeValue(prodData.values?.[0]?.values?.[1] || 0);
    const deploymentsCount = prodData.values?.[0]?.values?.[0] || 0;
    const prsCount = prodData.values?.[0]?.values?.[2] || 0;
    const deployments = (
      <div>
        {deploymentsCount}
        <span css={subInfoStyles}> ({prsCount} PRs)</span>
      </div>
    );
    const successRatio = number.percentage(prodData.values?.[0]?.values?.[3] * 100 || 0, 1);
    const changeFailureRate = number.percentage(prodData.values?.[0]?.values?.[4] * 100 || 0, 1);

    return [deploymentFrequency, timeToDeploy, deployments, successRatio, changeFailureRate];
  };

  let stageKPIs;
  switch (stageSlug) {
    case StagesEnum.SUMMARY:
      return buildKPIsForSummary();
    case StagesEnum.WIP:
      stageKPIs = buildKPIsForWIP();
      break;
    case StagesEnum.REVIEW:
      stageKPIs = buildKPIsForReview();
      break;
    case StagesEnum.MERGE:
      stageKPIs = buildKPIsForMerge();
      break;
    case StagesEnum.RELEASE:
      stageKPIs = buildKPIsForRelease();
      break;
    case StagesEnum.DEPLOY:
      stageKPIs = buildKPIsForDeploy();
      break;
    default:
      return null;
  }

  return _(kpis[stageSlug])
    .zip(stageKPIs)
    .map((v) => ({ title: v[0], value: v[1] }))
    .value();
};

export default buildKPIs;
