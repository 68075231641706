import { css } from '@emotion/react';

export const mainMetricStyles = ({ color, radius, spacing }) => css`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 48px;
  min-width: 0;
  word-wrap: break-word;
  background-color: ${color.neutral.white};
  background-clip: border-box;
  border: 1px solid ${color.neutral[40]};
  border-radius: ${radius.default};
  padding: ${spacing.gap['03']} ${spacing.gap['04']};
`;

export const mainMetricTextStyles = ({ color, radius, spacing }) => css`
  align-items: center;
  display: flex;
  margin: auto ${spacing.gap['0']};

  > span {
    margin-right: ${spacing.gap['02']};
  }

  > div > svg {
    margin-top: -${spacing.gap['02']};
  }
`;

export const mainMetricValueStyles = ({ color, radius, spacing }) => css`
  align-items: center;
  display: flex;
  margin: auto ${spacing.gap['0']} auto auto;
`;
