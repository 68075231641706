import { css } from '@emotion/react';

export const wrapperStyles = css`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;

export const teamEditModalContentStyles = () => css`
  width: 450px;
`;

export const teamEditModalBlock = ({ spacing }) => css`
  margin-bottom: ${spacing.gap['05']};
`;
