// @ts-nocheck
// TODO: remove TS ignore comment and fix all the type issues in this component
import React, { useMemo } from 'react';
import { useTable, useSortBy, useExpanded } from 'react-table';

import { Table } from '@lib/Table';

import { getTableColsDefs } from './getTableColsDefs';
import { computeMinMax, filterRows } from './helpers';
import { ITeamAllocationsTable } from './types';

export const TeamsAllocationTable: React.FC<ITeamAllocationsTable> = React.memo(
  ({ data, context, contentType }) => {
    const { teams: teamsData } = data;

    const userColumns = useMemo(() => getTableColsDefs(context, data, contentType), [
      context,
      contentType,
      data,
    ]);

    const colsMinMax = useMemo(
      () =>
        computeMinMax(
          teamsData,
          userColumns.slice(1).map((col) => col.accessor)
        ),
      [teamsData, userColumns]
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
      {
        columns: userColumns,
        data: teamsData,
        initialState: { colsMinMax },
      },
      useSortBy,
      useExpanded
    );

    rows.forEach(prepareRow);

    const filteredRows = useMemo(() => filterRows(context, rows), [context, rows]);

    return (
      <Table
        rows={filteredRows}
        headerGroups={headerGroups}
        tableProps={getTableProps()}
        tableBodyProps={getTableBodyProps()}
      />
    );
  }
);
