import { useQuery } from 'react-query';

import { CONFIG } from '@align-constants';
import { IUseQueryHookRest } from '@align-types/common';
import { useAuth0 } from '@common-context/Auth0';
import { useUserContext } from '@common-context/User';
import { useDemoCookie } from '@common-pages/Demo';
import { IApiBasicError } from '@common-services/api/common/types/common';
import { TeamMemberList } from '@common-services/api/private/generated-from-backend/models';
import { IMembersParams } from '@common-services/api/private/types/members';

import { MembersServices } from '../services/members';

export function useTeamMembers(
  teamId: number,
  params: IMembersParams,
  isCached: boolean = false
): IUseQueryHookRest<TeamMemberList> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();

  const { isLoading, data, error } = useQuery<TeamMemberList, IApiBasicError>(
    ['members', teamId, params.account, params.recursive],
    async () => {
      const token: string = isDemo === false ? await getTokenSilently() : null;
      return MembersServices.getTeamMembers({ teamId }, params, token);
    },
    {
      ...CONFIG.API.FETCH_OPTIONS,
      cacheTime: isCached ? CONFIG.API.FETCH_OPTIONS.cacheTime : 0,
      staleTime: isCached ? CONFIG.API.FETCH_OPTIONS.staleTime : 0,
    }
  );

  return { isLoading, data, error };
}

export function useOwnedTeamMembers(
  teamId: number,
  recursive: boolean = false,
  isCached: boolean = false
): IUseQueryHookRest<TeamMemberList> {
  const {
    user: {
      defaultAccount: { id: account },
    },
  } = useUserContext();
  return useTeamMembers(teamId, { account, recursive }, isCached);
}
