import { css } from '@emotion/react';

export const wrapperStyles = ({ spacing }) => css`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  padding: ${spacing.gap['08']};
`;

export const kpisWrapper = ({ spacing }) => css`
  .kpi-wrapper {
    margin-bottom: ${spacing.gap['06']};

    .card-body {
      padding: ${spacing.gap['0']} ${spacing.gap['0']} ${spacing.gap['06']} ${spacing.gap['0']};
      text-align: center;

      .card-text {
        margin-top: ${spacing.gap['04']};
      }
    }
  }
`;
