import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { SELECTION_STATE } from '@analytics-components/MultiSelect/ui/Checkbox';
import { useSelectorState, ALL_NODE_ID } from '@lib/Selector';
import { optsType } from '@lib/Selector/types';
import { AvatarType } from '@lib/avatar';

import { WithCheckboxes as SelectorWithCheckboxesUI } from './ui';

const uniqOptions = (opts) => _(opts).uniqBy('value').value();
const getSelectedNodes = (nodesStates) =>
  uniqOptions(
    _(nodesStates)
      .filter((ns) => ns.id !== ALL_NODE_ID)
      .filter((ns) => ns.selectionState === SELECTION_STATE.SELECTED)
      .map('node')
      .value()
  );

const SelectorWithCheckboxes = ({
  options,
  placeholder,
  inputValue,
  avatarType,
  noAvatar,
  noSearch,
  maxSelected,
  withAllOption,
  onInputChange,
  onInputClick,
  onCancel,
  onApply,
}) => {
  const {
    nodesStates: [nodesStates, setNodesStates],
    applied: [, setApplied],
  } = useSelectorState();

  const onCbWrapper = (cb, updatedNodesStates, updateApply) => {
    setNodesStates(updatedNodesStates);
    const applied = getSelectedNodes(updatedNodesStates);
    if (updateApply) {
      setApplied(applied);
    }
    if (!!cb) {
      cb({ selected: applied, applied });
    }
  };

  const onCancelWrapper = () => {
    const updatedNodesStates = _(nodesStates)
      .map((ns) => ({
        ...ns,
        selectionState: ns.lastAppliedSelectionState,
      }))
      .value();
    onCbWrapper(onCancel, updatedNodesStates);
  };

  const onApplyWrapper = () => {
    const updatedNodesStates = _(nodesStates)
      .map((ns) => ({
        ...ns,
        lastAppliedSelectionState: ns.selectionState,
      }))
      .value();
    onCbWrapper(onApply, updatedNodesStates, true);
  };

  const isApplyDisabled = maxSelected > 0 && getSelectedNodes(nodesStates).length > maxSelected;

  return (
    <SelectorWithCheckboxesUI
      options={options}
      placeholder={placeholder}
      inputValue={inputValue}
      avatarType={avatarType}
      noAvatar={noAvatar}
      noSearch={noSearch}
      isApplyDisabled={isApplyDisabled}
      withAllOption={withAllOption}
      onInputChange={onInputChange}
      onInputClick={onInputClick}
      onCancel={onCancelWrapper}
      onApply={onApplyWrapper}
    />
  );
};

SelectorWithCheckboxes.propTypes = {
  options: optsType,
  placeholder: PropTypes.string,
  inputValue: PropTypes.string,
  avatarType: PropTypes.oneOf(Object.values(AvatarType)),
  noAvatar: PropTypes.bool,
  noSearch: PropTypes.bool,
  maxSelected: PropTypes.number,
  withAllOption: PropTypes.bool,
  onInputChange: PropTypes.func,
  onInputClick: PropTypes.func,
  onCancel: PropTypes.func,
  onApply: PropTypes.func,
};

export default SelectorWithCheckboxes;
