import _ from 'lodash';
import React from 'react';

import { NEGATIVE_IS_BETTER } from '@analytics-components/Badge';
import TimeSeries from '@analytics-components/charts/TimeSeries';
import { BigText } from '@analytics-components/charts/Tooltip';
import { SimpleKPI, UNITS } from '@analytics-components/insights/KPI';
import { dateTime } from '@common-services/dateService';
import * as NumberService from '@common-services/numberService';
import { theme } from '@styles/theme';
import { hexToRGBA } from '@utils/colors';

const prsLocsTimeline = {
  plumber: (fetchedData, cachedData, apiContext) => {
    const customGranularity = cachedData['prs-metrics'].customGranularity;
    const timeseriesGranularity = customGranularity === 'month' ? 'custom-aligned' : 'custom';
    const prsMetrics = cachedData['prs-metrics']['values'][timeseriesGranularity]['size'];
    const prSizeVariation = cachedData['prs-metrics']['variations']['size'];
    const chartData = prsMetrics.map((v) => ({
      day: v.date,
      value: v.value,
    }));
    const sortedPrSize = _(cachedData['prs-metrics']['values'][timeseriesGranularity]['size'])
      .filter((v) => v.value !== null)
      .sortBy('value')
      .value();
    const KPIsData = {
      prAvgSize: cachedData['prs-metrics']['values']['all']['size'],
      extremeValues: [sortedPrSize[0]?.value, sortedPrSize.slice(-1)[0]?.value],
    };
    const axisKeys = {
      x: 'day',
      y: 'value',
    };
    const tickFormat = customGranularity === 'month' ? dateTime.month : dateTime.monthDay;
    const tooltip = {
      renderBigFn: (v) => <BigText content={`${v.y} lines`} />,
    };
    if (_(['month', 'week']).includes(customGranularity)) {
      tooltip.dateRange = {
        from: apiContext.interval.from,
        to: apiContext.interval.to,
      };
      tooltip.granularity = customGranularity;
    }
    return {
      empty: chartData.filter((v) => v[axisKeys.y] !== null).length === 0,
      chart: {
        component: TimeSeries,
        params: {
          data: chartData,
          extra: {
            average: {
              value: NumberService.round(KPIsData.prAvgSize),
              color: theme.color.ui.blue[100],
              renderFn: tooltip.renderBigFn,
            },
            axisLabels: {
              y: 'Lines of Code',
            },
            maxNumberOfTicks: 10,
            axisKeys: axisKeys,
            color: theme.color.ui.lightblue[100],
            fillColor: hexToRGBA(theme.color.ui.lightblue[100], 0.2),
            ticks: {
              x: { tickFormat },
            },
            tooltip,
          },
        },
      },
      kpis: [
        {
          title: { text: 'Pull Request Size', bold: true },
          subtitle: { text: 'p95 Average' },
          component: SimpleKPI,
          params: {
            value: NumberService.round(KPIsData.prAvgSize),
            unit: UNITS.line,
            variation: prSizeVariation,
            variationMeaning: NEGATIVE_IS_BETTER,
          },
        },
        {
          title: { text: 'Min - Max', bold: true },
          component: SimpleKPI,
          params: {
            value:
              KPIsData.extremeValues[0] > 0
                ? KPIsData.extremeValues[0] &&
                  KPIsData.extremeValues[1] &&
                  `${NumberService.round(KPIsData.extremeValues[0])} - ${NumberService.round(
                    KPIsData.extremeValues[1]
                  )} lines`
                : '',
          },
        },
      ],
    };
  },
  prefetchedDataIds: ['prs-metrics'],
};

export default prsLocsTimeline;
