import { ActionType, IAction, IFilterState, ISubFilterState, SubFilter } from './types';

export const filterReducer = (state: IFilterState, action: IAction): IFilterState => {
  switch (action.type) {
    case ActionType.INIT_OPTIONS:
      const { repos, contribs, teams, labels, epics, issueTypes } = action.payload;
      return {
        ...state,
        repos: {
          ...state[SubFilter.REPOS],
          options: {
            ready: true,
            values: repos,
          },
        },
        contribs: {
          ...state[SubFilter.CONTRIBS],
          options: {
            ready: true,
            values: contribs,
          },
        },
        teams: {
          options: teams,
        },
        labels: {
          ...state[SubFilter.LABELS],
          options: {
            ready: true,
            values: labels,
          },
        },
        epics: {
          ...state[SubFilter.EPICS],
          options: {
            ready: true,
            values: epics,
          },
        },
        issueTypes: {
          ...state[SubFilter.ISSUE_TYPES],
          options: {
            ready: true,
            values: issueTypes,
          },
        },
      };
    case ActionType.SET_READY:
      const newState = { ...state };
      newState[SubFilter.REPOS].options.ready = action.payload;
      newState[SubFilter.REPOS].applied.ready = action.payload;
      newState[SubFilter.CONTRIBS].options.ready = action.payload;
      newState[SubFilter.CONTRIBS].applied.ready = action.payload;
      newState[SubFilter.EPICS].options.ready = action.payload;
      newState[SubFilter.EPICS].applied.ready = action.payload;
      newState[SubFilter.ISSUE_TYPES].options.ready = action.payload;
      newState[SubFilter.ISSUE_TYPES].applied.ready = action.payload;
      return newState;
    case ActionType.SET_INTERVAL:
      return {
        ...state,
        dateInterval: action.payload,
      };
    case ActionType.SET_REPOS_OPTIONS:
      return set({ ...state }, SubFilter.REPOS, 'options', action.payload);
    case ActionType.SET_APPLIED_REPOS:
      return set({ ...state }, SubFilter.REPOS, 'applied', action.payload);
    case ActionType.SET_CONTRIBS_OPTIONS:
      return set({ ...state }, SubFilter.CONTRIBS, 'options', action.payload);
    case ActionType.SET_APPLIED_CONTRIBS:
      return set({ ...state }, SubFilter.CONTRIBS, 'applied', action.payload);
    case ActionType.SET_LABELS_OPTIONS:
      return set({ ...state }, SubFilter.LABELS, 'options', action.payload);
    case ActionType.SET_APPLIED_LABELS:
      return set({ ...state }, SubFilter.LABELS, 'applied', action.payload);
    case ActionType.SET_EPICS_OPTIONS:
      return set({ ...state }, SubFilter.EPICS, 'options', action.payload);
    case ActionType.SET_APPLIED_EPICS:
      return set({ ...state }, SubFilter.EPICS, 'applied', action.payload);
    case ActionType.SET_ISSUE_TYPES_OPTIONS:
      return set({ ...state }, SubFilter.ISSUE_TYPES, 'options', action.payload);
    case ActionType.SET_APPLIED_ISSUE_TYPES:
      return set({ ...state }, SubFilter.ISSUE_TYPES, 'applied', action.payload);
    case ActionType.SET_EXCLUDE_INACTIVE:
      return {
        ...state,
        excludeInactive: action.payload,
      };
    default:
      return state;
  }
};

export const defaultFilter: IFilterState = {
  repos: {
    options: {
      values: [],
      ready: false,
    },
    applied: {
      values: [],
      ready: false,
    },
  },
  contribs: {
    options: {
      values: [],
      ready: false,
    },
    applied: {
      values: [],
      ready: false,
    },
  },
  teams: {
    options: [],
  },
  labels: {
    options: {
      ready: false,
      values: [],
    },
    applied: {
      values: [],
      ready: false,
    },
  },
  epics: {
    options: {
      ready: false,
      values: [],
    },
    applied: {
      values: [],
      ready: false,
    },
  },
  issueTypes: {
    options: {
      ready: false,
      values: [],
    },
    applied: {
      values: [],
      ready: false,
    },
  },
  dateInterval: null,
  excludeInactive: true,
};

const set = (
  state: IFilterState,
  filter: SubFilter,
  what: keyof ISubFilterState,
  values: any
): IFilterState => {
  state[filter][what] = {
    ready: true,
    values,
  };
  return state;
};
