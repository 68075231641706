import { css } from '@emotion/react';

export const wrapperStyles = ({ isChecked }) => ({ color, radius, spacing }) => css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${spacing.gap['03']};
  cursor: pointer;

  .checkbox {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    border: ${isChecked ? 0 : '1px'} solid ${color.neutral['60']};
    border-radius: ${radius.default};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  label {
    margin: 0;
    cursor: pointer;
  }
`;
