import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

import { wrapperStyles } from './filterDetailAnimationWrapper.styles';

interface IFilterDetailAnimationWrapper {
  isOpen: boolean;
  rowCount: number;
  children: React.ReactNode;
}

export const FilterDetailAnimationWrapper: React.FC<IFilterDetailAnimationWrapper> = React.memo(
  ({ isOpen, rowCount, children }) => {
    return (
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{
              opacity: 0,
              height: '0%',
            }}
            animate={{
              opacity: 1,
              // Each row in Filter Detail has 32px height
              height: `${rowCount * 32}px`,
            }}
            exit={{
              opacity: 0,
              height: '0%',
            }}
            css={wrapperStyles}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    );
  }
);
