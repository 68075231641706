import { css } from '@emotion/react';

export const wrapperStyles = ({ color, font, spacing }) => css`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: ${spacing.gap['05']};
  padding: 0 ${spacing.gap['05']};

  .kpi-block {
    .kpi-title {
      color: ${color.text.secondary};
      font-size: ${font.size.default};
      font-weight: ${font.weight.m};
      margin-bottom: ${spacing.gap['04']};
    }

    .kpi-value {
      align-items: center;
      color: ${color.text.dark};
      display: flex;
      font-size: ${font.size.l};
      font-weight: ${font.weight.m};

      > span {
        margin-left: ${spacing.gap['03']};
        font-size: ${font.size.l};
      }
    }
  }
`;
