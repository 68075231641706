import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { v4 as uuidv4 } from 'uuid';

import { UserService } from '@common-services/userService';

export const init = () => {
  if (window.ENV?.sentry.dsn) {
    const sentryConf = {
      dsn: window.ENV.sentry.dsn,
      environment: window.ENV.environment,
      normalizeDepth: 10,
      integrations: [new BrowserTracing()],
      tracesSampleRate: window.ENV.sentry.samplingRate || 0,
    };

    const isDev =
      window.ENV.environment === 'development' || process.env.NODE_ENV === 'development';

    if (!isDev && window.META?.release?.length) {
      sentryConf.release = window.META.release;
    }

    Sentry.init(sentryConf);

    if (window.ENV.sentry.developer) {
      Sentry.configureScope(function (scope) {
        scope.setTag('developer', window.ENV.sentry.developer);
      });
    }
  }
};

export const setUser = (user, godMode) => {
  Sentry.configureScope(function (scope) {
    scope.setTag('user.id', user.id);
    scope.setTag('user.name', user.name);
    scope.setTag('user.login', user.login);
    scope.setTag('user.account', user.defaultAccount.id);
    scope.setTag('user.isAdmin', user.defaultAccount.isAdmin);
    scope.setTag('user.godMode', godMode);
    scope.setTag('user.organization', UserService.getOrg(user.defaultReposet.repos));
  });
};

export const report = (error, context = {}) => {
  Sentry.withScope((scope) => {
    scope.setTags(context.tags);
    scope.setExtras(context.extra);
    scope.setFingerprint(
      context.uniqueFingerprint
        ? [uuidv4()]
        : [error.toString() + ' ' + context?.extra?.status || 'n/a']
    );
    Sentry.captureException(error);
    console.error('Reported to Sentry', error, context);
  });
};

export const isSentryTagsReady = () => {
  let tags = {};
  Sentry.withScope((scope) => {
    tags = scope._tags;
  });

  return Boolean(Object.values(tags)?.length);
};
