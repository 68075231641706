import _ from 'lodash';
import React from 'react';

import { AltTitle } from '@analytics-components/Typography';
import { DefaultXYTooltip, BigText } from '@analytics-components/charts/Tooltip';
import { fetchJIRAMetrics } from '@analytics-services/api/jira';
import { FAKE_USERNAME } from '@common-pages/Settings/constants';
import { number } from '@common-services/format';
import { theme } from '@styles/theme';
import RingChart from '@ui/charts/RingChart';

const conf = {
  fetcher: async (api, cachedData, apiContext) => {
    const {
      account,
      interval,
      excludeInactive,
      contributors,
      includeNullContributor,
      includeFakeContributor,
      epics,
    } = apiContext;
    const filteredJIRAStuff = cachedData['jira-priorities'];
    const jiraAssignees = _(contributors).map('jira_user').filter().value();
    if (includeNullContributor) {
      jiraAssignees.push(null);
    }
    if (includeFakeContributor) {
      jiraAssignees.push(FAKE_USERNAME);
    }

    const fetch = async (priority) =>
      await fetchJIRAMetrics(
        api,
        account,
        ['all'],
        interval,
        ['raised'],
        excludeInactive,
        {
          types: ['Bug'],
          priorities: [priority],
          epics,
        },
        { assignees: jiraAssignees }
      );
    const bugs = await Promise.all(
      _(filteredJIRAStuff.priorities)
        .map((p) => fetch(p.name))
        .value()
    );

    return {
      bugs: _(bugs)
        .map((b) => b[0].values[0].values[0])
        .value(),
      priorities: filteredJIRAStuff.priorities,
    };
  },
  plumber: (fetchedData, cachedData, apiContext) => {
    const colors = theme.color.sets.bugs;
    const chartData = _(fetchedData.priorities)
      .zip(fetchedData.bugs)
      .map((d, i) => ({ value: d[1], label: d[0].name, color: colors[i] }))
      .value();
    const totalBugs = _(chartData).map('value').sum();

    return {
      empty: chartData.length === 0,
      chart: {
        component: RingChart,
        params: {
          data: chartData,
          params: {
            labels: {
              primary: totalBugs || 'No bugs',
              secondary: 'Bugs Raised',
            },
            colors,
            legend: {
              n: 3,
            },
            tooltip: {
              component: DefaultXYTooltip,
              x: (v) => <AltTitle content={v.label} uppercase />,
              y: (v) => {
                const circleRadius = 5;
                return (
                  <BigText
                    icon={
                      <svg
                        style={{
                          display: 'inline-block',
                          width: `${circleRadius * 2}px`,
                          height: `${circleRadius * 2}px`,
                          marginRight: `${circleRadius}px`,
                        }}
                      >
                        <circle
                          cx={circleRadius}
                          cy={circleRadius}
                          r={circleRadius}
                          fill={v.color}
                        />
                      </svg>
                    }
                    content={v.value}
                    extra={number.percentage(v.percentage * 100)}
                  />
                );
              },
            },
          },
        },
      },
      kpis: [],
    };
  },
  prefetchedDataIds: ['jira-priorities'],
};

export default conf;
