import React from 'react';

import { Card } from '@lib/Card';
import { icons } from '@lib/icon';

import { UsersPanelProps } from './UsersPanel.types';
import { UsersPanelBody } from './ui/UsersPanelBody';

const UsersPanel: React.FC<UsersPanelProps> = React.memo(
  ({ allMembers, directMembers, childMembers, onEditUsers }) => {
    return (
      <Card
        headerIcon={icons.person}
        headerText="Members"
        headerBadgeCount={allMembers?.length}
        headerButtonText="Edit Members"
        onHeaderButtonClick={onEditUsers}
      >
        <UsersPanelBody childMembers={childMembers} directMembers={directMembers} />
      </Card>
    );
  }
);

export { UsersPanel };
