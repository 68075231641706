import _ from 'lodash';
import React from 'react';

import DataWidget from '@analytics-context/DataWidget';
import { fetchCodeChecks } from '@analytics-services/api';
import { getFeature, featuresList } from '@analytics-services/flags';
import { github } from '@common-services/format';

import ChecksTableDataBinder from './ChecksTableDataBinder.jsx';

const Checks = () => {
  const fetcher = async (api, cachedData, apiContext) => {
    const {
      account,
      interval,
      contributors,
      repositories,
      labels,
      features,
      epics,
      issueTypes,
    } = apiContext;
    const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, features)?.parameters
      ?.value;

    const data = await fetchCodeChecks(
      api,
      account,
      interval,
      repositories,
      contributors,
      labels,
      excludedLabels,
      { epics, issue_types: issueTypes }
    );

    return data;
  };

  const plumber = (fetchedData, cachedData, apiContext) => {
    const customGranularity = cachedData['ci-metrics'].customGranularity;

    return fetchedData.items
      .map((check) => {
        const total = check.total_stats.count;
        return {
          flakiness: total ? (check.total_stats.flaky_count * 100) / total : null,
          granularity: customGranularity,
          interval: apiContext.interval,
          lastRun: new Date() - check.last_execution_time,
          repo: check.repository,
          repoName: `${github.repoOrg(check.repository)}/${github.repoName(check.repository)}`,
          runTime: check.total_stats.mean_execution_time
            ? parseInt(check.total_stats.mean_execution_time)
            : null,
          runTimeTimeline: _(fetchedData.timeline)
            .zip(check.total_stats.mean_execution_time_timeline)
            .map((values) => ({
              date: values[0],
              value: values[1] ? parseInt(values[1]) : null,
            }))
            .value(),
          skipped:
            total || check.total_stats.skips
              ? (check.total_stats.skips * 100) / (total + check.total_stats.skips)
              : null,
          successRatio: total ? (check.total_stats.successes * 100) / total : null,
          successRatioTimeline: _(fetchedData.timeline)
            .zip(check.total_stats.count_timeline, check.total_stats.successes_timeline)
            .map((values) => ({
              date: values[0],
              value: values[1] ? values[2] / values[1] : null,
            }))
            .value(),
          title: check.title,
          total,
          url: check.last_execution_url,
        };
      })
      .filter((check) => Boolean(check?.total));
  };

  return (
    <DataWidget
      id="github-checks-table"
      fetcher={fetcher}
      plumber={plumber}
      prefetchedDataIds={['ci-metrics']}
    >
      <ChecksTableDataBinder />
    </DataWidget>
  );
};

export default Checks;
