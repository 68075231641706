import log from '@common-services/logger';

export enum errorMessages {
  CREATE = 'This goal already exists. Please edit it from the table instead.',
  REMOVE = 'Unfortunately, removing the goal did not succeed. Please try again.',
  UPDATE = 'Something went wrong while updating the goal. Please try again.',
  METRIC = 'Something went wrong while fetching data from the server. Please try again.',
}

interface ILogErrorMessageParams {
  createIsError: boolean;
  updateIsError: boolean;
  metricIsError: boolean;
}

type ILogErrorMessage = (params: ILogErrorMessageParams) => void;

export const logErrorMessage: ILogErrorMessage = ({
  createIsError,
  updateIsError,
  metricIsError,
}) => {
  if (createIsError) {
    log.fatal(errorMessages.CREATE);
  }
  if (updateIsError) {
    log.fatal(errorMessages.UPDATE);
  }
  if (metricIsError) {
    log.fatal(errorMessages.METRIC);
  }
};
