import { css } from '@emotion/react';

export const wrapperStyles = ({ color, font, spacing }) => css`
  align-items: center;
  display: flex;
  width: 60px;

  .number {
    color: ${color.text.dark};
    font-size: ${font.size.default};
    font-weight: ${font.weight.light};
    margin-left: ${spacing.gap['03']};
  }

  .size {
    font-size: ${font.size.default};
    font-weight: ${font.weight.light};

    &.red {
      color: ${color.ui.red['100']};
    }

    &.green {
      color: ${color.ui.green['100']};
    }
  }
`;
