import React, { useEffect, useMemo, useRef, useState } from 'react';

interface Bounds {
  width: number;
  height: number;
}

interface Output {
  border: Bounds;
  content: Bounds;
}

export function useMeasure<T extends Element>(): [React.MutableRefObject<T>, Output] {
  const ref = useRef<T>();
  const [bounds, setBounds] = useState({
    border: { width: 0, height: 0 },
    content: { width: 0, height: 0 },
  });
  const ro = useMemo(
    () =>
      new ResizeObserver(([entry]) => {
        const { inlineSize: borderWidth, blockSize: borderHeight } = entry.borderBoxSize[0];
        const { inlineSize: contentWidth, blockSize: contentHeight } = entry.contentBoxSize[0];
        if (
          borderWidth !== bounds.border.width ||
          borderHeight !== bounds.border.height ||
          contentWidth !== bounds.content.width ||
          contentHeight !== bounds.content.height
        ) {
          setBounds({
            border: { width: borderWidth, height: borderHeight },
            content: { width: contentWidth, height: contentHeight },
          });
        }
      }),
    []
  );

  useEffect(() => {
    if (!ref) return;
    const observerOptions: ResizeObserverOptions = {
      box: 'border-box',
    };

    if (ref.current) ro.observe(ref.current, observerOptions);
    return () => ro.disconnect();
  }, [ref.current]);

  return [ref, bounds];
}
