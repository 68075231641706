import React from 'react';

import { NoDataForStage } from '../noDataForStage';

interface Props {
  textOnly: boolean;
}

const NoMerged: React.FC<Props> = React.memo(({ textOnly }) => (
  <NoDataForStage
    textOnly={textOnly}
    textOnlyCopy="no PRs merged"
    companionCopy="There has not been any merged PR in the date range selected"
  />
));

export { NoMerged };
