import { IUrlParams } from '@common-services/api/common/types/apiClient';
import { publicApi } from '@common-services/api/public';
import {
  Account,
  ProductFeatures,
} from '@common-services/api/public/generated-from-backend/models';

export const getAccountFeature = async (
  urlParams: IUrlParams<'accountId'>,
  token: string
): Promise<ProductFeatures> => {
  return publicApi.accounts.getAccountFeature(urlParams, token, {
    message: `Cannot get account feature for accountId ${urlParams.accountId}`,
  });
};

export const getAccountDetails = async (
  urlParams: IUrlParams<'accountId'>,
  token: string
): Promise<Account> => {
  return publicApi.accounts.getAccountDetails(urlParams, token, {
    message: `Cannot get account details for accountId ${urlParams.accountId}`,
  });
};

export * as AccountsServices from './accounts';
