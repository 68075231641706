import React from 'react';

import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import Panel from '@analytics-components/insights/Panel';
import { prepareChartBoxes } from '@analytics-pages/delivery-pipeline/insights/helper';

import prsRatioFlowTimeseries from './prs-ratio-flow-timeseries';

const meta = {
  title: 'Pull Request Ratio Flow',
  description:
    'Track the ratio of opened/closed pull requests per day and anticipate congestions in the delivery pipeline.',
};

const chartBoxes = [
  {
    id: 'prs-ratio-flow-timeseries',
    component: ChartBoxWithKPIWidget,
    params: prsRatioFlowTimeseries,
  },
];

const PullRequestsRatioFlowPanel = () => {
  const preparedChartBoxes = prepareChartBoxes(chartBoxes);
  return <Panel meta={meta} chartBoxes={preparedChartBoxes} />;
};

export default PullRequestsRatioFlowPanel;
