import { css } from '@emotion/react';

export const wrapperStyles = ({ isLoading, minWidth, fullWidth }) => ({
  color,
  radius,
  spacing,
}) => css`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: ${isLoading ? 'center' : 'space-between'};
  border-radius: ${radius.default};
  background: ${color.neutral.white};
  padding: ${spacing.gap['05']};
  color: ${color.text.dark};
  width: 190px;
  min-width: ${minWidth}px;
  cursor: pointer;
  border: 1px solid ${color.neutral['40']};
  transition: border 0.3s;
  user-select: none;

  ${fullWidth && `width: 100%;`};

  &:hover {
    border: 1px solid ${color.neutral['60']};
  }

  .icon {
    margin-left: ${spacing.gap['02']};
  }
`;

export const labelStyles = css`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  max-width: 100%;
  overflow: hidden;
`;
