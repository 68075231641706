import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  ITeamEditAccordionFooter,
  IOptionData,
  OpeningDirection,
} from './teamEditAccordionFooter.types';
import { TeamEditAccordionFooterUI } from './ui';

const TeamEditAccordionFooter: React.FC<ITeamEditAccordionFooter> = React.memo(
  ({ footer, usersOptions, loadedAllOptions, onMembersAddition, onLoadAllOptionsClick }) => {
    const [isUsersPanelOpen, setIsUsersPanelOpen] = useState(false);
    const [userPanelOpeningDirection, setUserPanelOpeningDirection] = useState(
      OpeningDirection.down
    );
    const buttonId = useRef(`team-add-users-${uuidv4()}`);

    const onUserPanelButtonClick = () => setIsUsersPanelOpen(!isUsersPanelOpen);

    const onMembersAdditionWrapped = (selected, flag) => {
      if (!flag) {
        onMembersAddition(selected);
        setIsUsersPanelOpen(false);
      }
    };

    const onMembersAdditionCancelWrapped = () => setIsUsersPanelOpen(false);

    const handleFiltering = ({ data }: IOptionData, input: string): boolean => {
      const lowerCaseInput = input.toLowerCase();
      if (
        data?.login?.toLowerCase().includes(lowerCaseInput) ||
        data?.name?.toLowerCase().includes(lowerCaseInput) ||
        data?.jira_user?.toLowerCase().includes(lowerCaseInput)
      ) {
        return true;
      }
      return false;
    };

    useEffect(() => {
      const onScroll = () => {
        const el = window.document.getElementById(buttonId.current);
        const viewportOffset = el.getBoundingClientRect();
        const top = viewportOffset.top;
        const viewportHeight = document.documentElement.clientHeight;
        const openingDirection =
          top < 500 || top / viewportHeight < 0.5 ? OpeningDirection.down : OpeningDirection.up;
        setUserPanelOpeningDirection(openingDirection);
      };

      onScroll();
      window.addEventListener('scroll', onScroll);
      return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return (
      <TeamEditAccordionFooterUI
        footer={footer}
        usersOptions={usersOptions}
        isUsersPanelOpen={isUsersPanelOpen}
        buttonId={buttonId.current}
        userPanelOpeningDirection={userPanelOpeningDirection}
        loadedAllOptions={loadedAllOptions}
        filterOption={handleFiltering}
        onMembersAddition={onMembersAdditionWrapped}
        onMembersAdditionCancel={onMembersAdditionCancelWrapped}
        onUserPanelButtonClick={onUserPanelButtonClick}
        onLoadAllOptionsClick={onLoadAllOptionsClick}
      />
    );
  }
);

export { TeamEditAccordionFooter };
