import React from 'react';
import { Row } from 'react-table';

import { IIssuesTableRow } from '@align-types/issues';
import { dateTime } from '@common-services/dateService';

import { wrapperStyles } from './cellMetric.styles';

interface IIssuesCellMetricProps {
  row: Row<IIssuesTableRow>;
  metricFieldName: string;
}

export const IssuesCellMetric: React.FC<IIssuesCellMetricProps> = React.memo(
  ({ row, metricFieldName }) => {
    const metricField = row.original?.[metricFieldName];
    const metricValue = metricField ? parseInt(metricField) * 1000 : null;
    return <div css={wrapperStyles}>{metricValue != null ? dateTime.human(metricValue) : ''}</div>;
  }
);
