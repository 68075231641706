import React, { useEffect, useState, useRef } from 'react';

import { LOADING } from '@analytics-components/StatusIndicator';
import { useApi } from '@analytics-hooks';
import { getProgress, InstallationError } from '@analytics-services/api';
import { isFeatureEnabled, featuresList } from '@analytics-services/flags';
import { report as reportToSentry } from '@analytics-services/sentry';
import { useUserContext } from '@common-context/User';
import Layout, { WelcomeMsg } from '@common-pages/waiting/Layout';
import {
  PROGRESS_NOT_READY,
  PROGRESS_WAITING_INSTALLATION,
  PROGRESS_DOWNLOADING,
  PROGRESS_FINISHED,
  PROGRESS_FAILED,
} from '@common-pages/waiting/Progress';
import WaitingInstall from '@common-pages/waiting/WaitingInstall';
import { Spinner } from '@lib/Spinner';

const PROGRESS_POLL_INTERVAL_MIN = 5 * 1000;
const PROGRESS_POLL_INTERVAL_MAX = PROGRESS_POLL_INTERVAL_MIN * 2 ** 6;
const MAX_ALLOWED_CONSECUTIVE_ERRORS = 3;

const pollProgress = async (
  api,
  accountId,
  prevProgress,
  consecutiveErrors,
  updateFn,
  setTimerId,
  nextDelayFn,
  isReady
) => {
  let newProgress;
  try {
    newProgress = await getProgress(api, accountId);
    if (newProgress.finished && isReady) {
      updateFn({ status: PROGRESS_FINISHED });
      return;
    }

    updateFn({ status: PROGRESS_DOWNLOADING, value: newProgress.value });
    consecutiveErrors = 0;
  } catch (err) {
    console.error(err);
    if (prevProgress === null && !(err instanceof InstallationError)) {
      updateFn({ status: PROGRESS_WAITING_INSTALLATION });
    } else {
      consecutiveErrors++;
      if (consecutiveErrors >= MAX_ALLOWED_CONSECUTIVE_ERRORS) {
        reportToSentry(new InstallationError(`Got ${consecutiveErrors} consecutive errors`), {
          extra: {
            account: accountId,
            progress: prevProgress,
            lastError: err,
          },
        });
        consecutiveErrors = 0;
      }
    }
  }

  const currentProgress = newProgress?.value || prevProgress;
  const timerId = setTimeout(
    () =>
      pollProgress(
        api,
        accountId,
        currentProgress,
        consecutiveErrors,
        updateFn,
        setTimerId,
        nextDelayFn,
        isReady
      ),
    nextDelayFn(currentProgress !== prevProgress)
  );

  setTimerId(timerId);
};

const throttler = (
  minDelay = PROGRESS_POLL_INTERVAL_MIN,
  maxDelay = PROGRESS_POLL_INTERVAL_MAX
) => {
  let prevDelay = null;
  return (reset) => {
    if (!prevDelay || reset) {
      prevDelay = minDelay;
    } else if (prevDelay * 2 < maxDelay) {
      prevDelay *= 2;
    } else {
      prevDelay = maxDelay;
    }

    return prevDelay;
  };
};

const Waiting = () => {
  const { api, ready: apiReady, context } = useApi(true, false);
  const [progressState, setProgressState] = useState({ status: PROGRESS_NOT_READY, value: 0 });
  const { features, godMode, isReady } = useUserContext();

  const timerId = useRef(null);
  const updateTimer = (id) => (timerId.current = id);

  const { current: getNextDelay } = useRef(
    throttler(PROGRESS_POLL_INTERVAL_MIN, PROGRESS_POLL_INTERVAL_MAX)
  );

  useEffect(() => {
    if (!apiReady) return;

    if (isFeatureEnabled(featuresList.force_waiting_page, features) && !godMode) {
      setProgressState({ status: PROGRESS_DOWNLOADING, value: 99 });
      return;
    }

    if (progressState.status === PROGRESS_FINISHED || progressState.status === PROGRESS_FAILED) {
      return;
    }

    pollProgress(
      api,
      context.account,
      null,
      0,
      setProgressState,
      updateTimer,
      getNextDelay,
      isReady
    );

    return () => {
      clearInterval(timerId.current);
    };
  }, [api, apiReady, context.account, features, getNextDelay, godMode, progressState.status]);

  switch (progressState.status) {
    case PROGRESS_NOT_READY:
      return (
        <Layout title="Welcome to Athenian">
          <div className="mt-5">
            <Spinner status={LOADING} />
          </div>
        </Layout>
      );
    case PROGRESS_WAITING_INSTALLATION:
      return (
        <Layout title="Welcome to Athenian">
          <WaitingInstall isAdmin={context.isAdmin} />
        </Layout>
      );
    case PROGRESS_DOWNLOADING:
      return (
        <Layout
          title="Welcome to Athenian"
          message="Please, wait while we are fetching data..."
          progress={progressState}
        >
          <WelcomeMsg />
        </Layout>
      );
    case PROGRESS_FINISHED:
      return (
        <Layout
          title="We're all set up!"
          message="Get started with Athenian and improve your engineering productivity"
          progress={progressState}
          simple
        >
          <RefreshInToOverview />
        </Layout>
      );
    case PROGRESS_FAILED:
    default:
      return (
        <Layout
          title="Oops! Installation failed"
          message="We've been notified of a failed installation and will contact you
            shortly once we've resolved it. No action is needed on your side."
          progress={progressState}
        />
      );
  }
};

const RefreshInToOverview = () => {
  // It is used an <HTML.a> instead of a <ReactRouter.Link> in order to force a webapp state refresh.
  // (Otherwise, the user and its default account will be in a pre-finished state)
  return (
    <a href="/" className="cta-button">
      Get the insights
    </a>
  );
};

export default Waiting;
