import { useParams } from 'react-router-dom';

import { PrimaryLayoutTabs } from '@align-components/layouts/primary/context.types';
import { IGoalSingleParams } from '@align-pages/goals/single/components/goals-single-component';
import { CacheService } from '@align-services/cacheService';
import { useHistory } from '@common-hooks/useHistory';
import log from '@common-services/logger';

export function useSuccessRedirect() {
  const history = useHistory();
  const { path } = useParams<IGoalSingleParams>();

  const routeToGoalsList = (): void => {
    history.pushWithBackState(`/align/${path}/${PrimaryLayoutTabs.GOALS}`);
  };

  const successRedirect = (msg: string) => {
    CacheService.removeQuery(['goals']);
    log.ok(msg);
    routeToGoalsList();
  };

  return { successRedirect };
}
