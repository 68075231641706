import { useMemo } from 'react';

import { FilterService } from '@align-services/filterService';
import { Goal } from '@common-services/api/private/generated-from-backend/models';

export function useAppliedFiltersCounter(goal: Goal): number {
  const appliedFiltersCounter = useMemo(() => {
    if (goal) {
      return FilterService.countAppliedGoalFilters(goal);
    } else {
      return 0;
    }
  }, [goal]);
  return appliedFiltersCounter;
}
