import { css } from '@emotion/react';

export const wrapperStyles = ({ spacing, color, transition }) => css`
  color: ${color.neutral['100']};
  padding: ${spacing.gap['03']} ${spacing.gap['04']};
  border-bottom: 1px solid ${color.neutral['40']};
  cursor: pointer;
  user-select: none;
  transition: ${transition.default};
  min-width: 100px;

  &:hover {
    background: ${color.neutral['20']};
  }

  &:last-child {
    border-bottom: none;
  }
`;
