import { publicApi } from '@common-services/api/public';
import {
  AcceptedInvitation,
  InvitationCheckResult,
  InvitationLink,
  InvitedUser,
} from '@common-services/api/public/generated-from-backend/models';

export const checkInvitation = async (
  params: InvitationLink,
  token: string
): Promise<InvitationCheckResult> => {
  return params?.url
    ? publicApi.registration.checkInvitation(params, token, {
        message: 'Cannot check invitation',
      })
    : null;
};

export const putInvitationAccept = async (
  params: AcceptedInvitation,
  token: string
): Promise<InvitedUser> => {
  return publicApi.registration.acceptInvitation(params, token, {
    message: 'Cannot submit data from user details page',
  });
};

export * as RegistrationServices from './registration';
