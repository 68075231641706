import classnames from 'classnames';
import React from 'react';

import { report as reportToSentry } from '@analytics-services/sentry';
import development, { isNotProd } from '@utils/development';

const ErrorBoundary = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { err: null };
  }

  static getDerivedStateFromError(err) {
    return { err };
  }

  componentDidCatch(err, stack) {
    reportToSentry(err, { extra: { stack: stack.componentStack } });
  }

  render() {
    if (this.state.err) {
      return (
        <div
          className={classnames('d-inline-block p-1', development.errorBoxClass)}
          title={this.state.err.toString()}
        >
          {isNotProd && 'error'}
        </div>
      );
    }

    return this.props.children;
  }
};

export default ErrorBoundary;
