import { useCallback, useState } from 'react';

import { DashboardChart } from '@common-services/api/private/generated-from-backend/models';
import { useRemoveChartFromDashboard } from '@common-services/api/private/hooks/useRemoveChartFromDashboard';

interface IUseChartRemoveModal {
  isModalOpen: boolean;
  isError: boolean;
  isSuccess: boolean;
  openRemoveModal: () => void;
  removeGoal: () => void;
  openModal: () => void;
  closeModal: () => void;
}

export function useChartRemoveModal(chart: DashboardChart): IUseChartRemoveModal {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openRemoveModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const { mutate: removeChart, isSuccess, isError } = useRemoveChartFromDashboard();

  const removeGoal = useCallback(() => {
    removeChart({ chartId: chart.id });
  }, [chart]);

  const openModal = useCallback(() => setIsModalOpen(true), []);

  const closeModal = useCallback(() => setIsModalOpen(false), []);

  return { isModalOpen, openRemoveModal, removeGoal, openModal, closeModal, isSuccess, isError };
}
