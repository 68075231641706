import React, { useMemo } from 'react';

import { INodeData } from '@align-pages/goals/single/components/tree-view/treeView.types';
import { IGoalMetricValue } from '@align-services/api/types/goalsTypes';
import { getCurrentRange } from '@align-services/dateService';
import { getFrequencyValue, getRawValue, getReadableValue } from '@align-services/metricService';
import { Badge, BadgeColorType } from '@lib/Badge';
import IconButton from '@lib/IconButton';
import { icons } from '@lib/icon';

import { TargetValue } from '../target-value';
import { VariationBadge } from '../variation-badge';
import { badgeStyles, wrapperStyles } from './setTargetBlock.styles';

interface ISetTargetBlock extends INodeData {
  conversionValue: number;
  targetValue: IGoalMetricValue;
  onRemove: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isTLO: boolean;
}

const SetTargetBlock: React.FC<ISetTargetBlock> = React.memo(
  ({ conversionValue, goal, range, targetValue, valueType, unit, onRemove, isTLO }) => {
    const targetDisplayValue = useMemo(
      () =>
        unit?.isFrequency
          ? getFrequencyValue(range, targetValue)[0]
          : getReadableValue(targetValue, valueType, unit, true, conversionValue, isTLO),
      [conversionValue, range, targetValue, valueType, unit]
    );

    return (
      <div css={wrapperStyles}>
        <div className="target-value-text">
          <div className="target-period">
            {getCurrentRange({ dateFrom: range.dateFrom, dateTo: range.dateTo })}
          </div>
          <VariationBadge
            refValue={getRawValue(goal?.value?.initial)}
            targetValue={getRawValue(targetValue)}
            positiveIsBetter
          />
        </div>
        <div css={badgeStyles}>
          <Badge
            text={<TargetValue value={targetDisplayValue} isTLO={isTLO} />}
            type={BadgeColorType.TARGET}
          />
          <div className="remove-button">
            <IconButton icon={icons.delete} title="Remove target" onClick={onRemove} />
          </div>
        </div>
      </div>
    );
  }
);

export { SetTargetBlock };
