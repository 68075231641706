import React from 'react';

import PullRequestsRatioFlowPanel from './PullRequestsRatioFlowPanel';

export const BIG_PR_THRESHOLD = 500;
export const EXTREMELY_BIG_PR_THRESHOLD = 1000000;

const InsightsWorkInProgress = () => {
  return (
    <>
      <PullRequestsRatioFlowPanel />
    </>
  );
};

export default InsightsWorkInProgress;
