import { css } from '@emotion/react';

const getStyle = (level, hasChildren, isAll) => ({ color, spacing, radius, transition }) => css`
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing.gap['02']} ${spacing.gap['04']} ${spacing.gap['02']}
    calc(${spacing.gap['04']} * ${level});
  background: ${color.neutral[isAll ? 40 : hasChildren ? 20 : 0]};
  border-bottom: 1px solid ${color.neutral['40']};
  flex: none;
  margin: ${spacing.gap['0']};
  transition: ${transition.long};

  & > div.info-wrapper {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${spacing.gap['01']} ${spacing.gap['0']};
    margin: ${spacing.gap['0']};
    flex-grow: 1;
    max-width: 100%;

    & > div.checkbox-wrapper {
      padding: ${spacing.gap['01']};
      margin-right: ${spacing.gap['03']};
    }

    & > div.avatar-wrapper {
      & > div {
        margin-right: ${spacing.gap['0']};
      }
    }

    & > div.label-wrapper {
      margin-left: ${spacing.gap['02']};
      line-height: 18px;
      color: ${color.neutral['100']};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      & > span.label-prefix {
        margin-right: ${spacing.gap['02']};
        color: ${color.neutral['80']};
      }
    }

    & > div.badge-count-wrapper {
      margin-left: ${spacing.gap['04']};
      border-radius: ${radius.default};
      background-color: ${color.ui.turquoise['100']};
      color: ${color.neutral.white};
      padding: ${spacing.gap['0']} ${spacing.gap['03']};
      line-height: 15px;
      height: 15px;
    }
  }

  & > div.control-wrapper {
    margin-left: ${spacing.gap['08']};
    color: ${color.neutral['80']};
    cursor: pointer;
  }

  ${!isAll &&
  `
  &:hover {
    background: ${color.neutral[hasChildren ? 40 : 20]};
  }
`}
`;

export default getStyle;
