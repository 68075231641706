import { css } from '@emotion/react';
import 'react-dates/lib/css/_datepicker.css';

export const footerWrapperStyles = ({ color, spacing, radius }) => css`
  align-items: center;
  background-color: ${color.neutral.white};
  border-top: 1px solid ${color.neutral[40]};
  border-bottom-left-radius: ${radius.default};
  border-bottom-right-radius: ${radius.default};
  box-shadow: 0 2px 6px rgb(0 0 0 / 5%), 0 0 0 1px rgb(0 0 0 / 7%);
  display: flex;
  height: 60px;
  justify-content: space-between;
  margin-left: -120px;
  padding: ${spacing.gap['04']} ${spacing.gap['06']};
`;

export const leftPanelStyles = ({ color, font, spacing, radius }) => css`
  background-color: ${color.neutral[20]};
  border: 1px solid ${color.neutral[40]};
  border-bottom: none;
  border-top-left-radius: ${radius.default};
  height: calc(100% - 59px);
  margin-left: -120px;
  padding: ${spacing.gap['05']} ${spacing.gap['04']};
  position: absolute;
  top: -1px;
  width: 120px;

  .interval-option {
    align-items: center;
    color: ${color.text.secondary};
    cursor: pointer;
    display: flex;
    font-size: ${font.size.xs};
    font-weight: ${font.weight.regular};
    height: 24px;
    padding-left: ${spacing.gap['04']};
    padding-right: ${spacing.gap['04']};
    width: 100%;

    &:hover {
      background-color: ${color.neutral.white};
    }

    &.active {
      background-color: ${color.neutral.white};
      color: ${color.text.dark};
    }
  }
`;

export const datePickerWrapperStyles = ({ color, font, spacing, radius }) => css`
  float: right;

  .DayPicker_transitionContainer {
    border-radius: ${radius.sharp};
  }

  .DayPicker__withBorder {
    border-radius: ${radius.sharp};
    border-top-right-radius: ${radius.default};
    border-bottom-right-radius: ${radius.default};
  }

  .DayPicker__horizontal,
  .DayPicker,
  .CalendarMonth,
  .CalendarMonthGrid,
  .CalendarDay__default,
  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:active,
  .CalendarDay__blocked_out_of_range:hover {
    background-color: ${color.neutral[0]};
  }

  .CalendarDay__default {
    font-size: ${font.size.xs};
    border: none;
    font-weight: ${font.weight.light};
  }

  .CalendarMonth_caption {
    color: ${color.neutral[100]};
    font-size: ${font.size.default};
    font-weight: ${font.weight.light};
  }

  .DateInput {
    background: transparent;
  }

  .DateInput_input__small {
    background: transparent;
    color: ${color.neutral[100]};
    font-size: ${font.size.default};
    font-weight: ${font.weight.regular};
    padding: 1rem ${spacing.gap['0']} ${spacing.gap['03']};
    text-align: center;
    cursor: pointer;
  }

  .DateInput_input__focused {
    border-bottom: 2px solid ${color.ui.lightorange[100]};
  }

  .DateInput_fang {
    top: 41px;
  }

  .DateRangePickerInput_arrow {
    margin-right: ${spacing.gap['04']};
  }

  .DateRangePickerInput__withBorder {
    transition: ease-in-out border 0.3s;

    &:hover {
      border-color: ${color.neutral[60]};
    }
  }

  .DateRangePickerInput_calendarIcon {
    margin-left: ${spacing.gap['0']};
    margin-right: ${spacing.gap['0']};
  }

  .DateRangePickerInput {
    border-radius: ${radius.default};
    height: 40px;

    &_clearDates {
      margin: 0 ${spacing.gap['02']};
    }
  }

  .DateRangePickerInput_clearDates {
    align-items: center;
    display: flex;
    height: 28px;
    width: 28px;
  }

  .CalendarDay__selected_span {
    background: ${color.ui.lightorange[100]};
    color: ${color.neutral.white};
    border: 1px solid ${color.neutral.white};
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: ${color.ui.orange[100]};
    border: 1px solid ${color.neutral.white};
    color: ${color.neutral.white};
  }

  .CalendarDay__selected:hover,
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: ${color.ui.lightorange[100]};
    border-color: ${color.neutral.white};
    color: ${color.neutral.white};
  }

  .CalendarDay__selected:hover,
  .CalendarDay__selected_span:hover {
    opacity: 0.7;
  }

  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: ${color.ui.orange[100]};
    color: ${color.neutral.white};
    border: 1px solid ${color.neutral.white};
  }

  .CalendarDay__today {
    font-weight: ${font.weight.bold};
  }

  .DayPicker_weekHeader_li {
    width: 3rem;
  }

  .DayPicker_weekHeader_li small {
    color: ${color.text.secondary};
    font-size: ${font.size.xs};
    font-weight: ${font.weight.light};
  }

  .CalendarMonth_table {
    margin-top: ${spacing.gap['03']};
  }

  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px ${color.ui.lightorange[100]};
  }
`;
