import _ from 'lodash';
import React from 'react';

import { DefaultXYTooltip, BigText } from '@analytics-components/charts/Tooltip';
import { fetchJIRAMetrics } from '@analytics-services/api/jira';
import { FAKE_USERNAME } from '@common-pages/Settings/constants';
import { number } from '@common-services/format';
import { theme } from '@styles/theme';
import RingChart from '@ui/charts/RingChart';
import { extend } from '@utils/colors';

const conf = {
  fetcher: async (api, cachedData, apiContext) => {
    const {
      account,
      interval,
      excludeInactive,
      contributors,
      includeNullContributor,
      includeFakeContributor,
      epics,
    } = apiContext;
    const filteredJIRAStuff = cachedData['jira-labels'];
    const jiraAssignees = _(contributors).map('jira_user').filter().value();
    if (includeNullContributor) {
      jiraAssignees.push(null);
    }
    if (includeFakeContributor) {
      jiraAssignees.push(FAKE_USERNAME);
    }

    const labels = _(filteredJIRAStuff.labels)
      .filter((l) => l.kind === 'regular')
      .map('title')
      .value();

    const tickets = await fetchJIRAMetrics(
      api,
      account,
      ['all'],
      interval,
      ['resolved'],
      excludeInactive,
      {
        labels,
        epics,
      },
      { assignees: jiraAssignees },
      true
    );

    return {
      tickets,
    };
  },
  plumber: (fetchedData, cachedData, apiContext) => {
    const colors = extend(theme.color.sets.six, fetchedData.tickets.length);
    const chartData = _(fetchedData.tickets)
      .map((v) => ({ value: v.values[0].values[0], label: v.jira_label }))
      .filter((v) => v.value > 0)
      .sortBy(['value', 'label'])
      .reverse()
      .map((v, i) => ({ ...v, color: colors[i] }))
      .value();
    const totalTickets = _(chartData).map('value').sum();

    return {
      empty: chartData.length === 0,
      chart: {
        component: RingChart,
        params: {
          data: chartData,
          params: {
            hasOtherSection: true,
            labels: {
              primary: totalTickets,
              secondary: `Label${totalTickets === 1 ? '' : 's'}`,
            },
            colors,
            legend: {
              n: 3,
              reverse: false,
            },
            tooltip: {
              component: DefaultXYTooltip,
              x: (v) => '',
              y: (v) => {
                return (
                  <BigText
                    // icon={<i className="icon-jira h4 vertical-align-middle mr-1"></i>}
                    content={`${v.value} ticket${v.value === 1 ? '' : 's'}`}
                    extra={number.percentage(v.percentage * 100)}
                    label={v.label}
                  />
                );
              },
            },
          },
        },
      },
      kpis: [],
    };
  },
  prefetchedDataIds: ['jira-labels'],
};

export default conf;
