import _ from 'lodash';

import { calculateGranularity } from '@analytics-components/Prefetcher';
import TimeSeries from '@analytics-components/charts/TimeSeries';
import { BigText } from '@analytics-components/charts/Tooltip';
import { fetchJIRAMetrics } from '@analytics-services/api/jira';
import { FAKE_USERNAME } from '@common-pages/Settings/constants';
import { dateTime } from '@common-services/dateService';
import * as NumberService from '@common-services/numberService';
import { theme } from '@styles/theme';
import { hexToRGBA } from '@utils/colors';

const bugFixing = {
  prefetchedDataIds: ['jira-issue-types'],
  fetcher: async (api, cachedData, apiContext) => {
    const {
      account,
      interval,
      excludeInactive,
      contributors,
      includeNullContributor,
      includeFakeContributor,
      epics,
    } = apiContext;

    const jiraAssignees = _(contributors).map('jira_user').filter().value();
    if (includeNullContributor) {
      jiraAssignees.push(null);
    }
    if (includeFakeContributor) {
      jiraAssignees.push(FAKE_USERNAME);
    }

    const filteredJIRAStuff = cachedData['jira-issue-types'];
    const standardIssueTypes = _(filteredJIRAStuff.issue_types)
      .filter((v) => !v.is_subtask && !v.is_epic)
      .map('name')
      .value();

    const fetch = async (issueTypes) =>
      await fetchJIRAMetrics(
        api,
        account,
        ['auto'],
        interval,
        ['resolved'],
        excludeInactive,
        {
          types: issueTypes,
          epics,
        },
        { assignees: jiraAssignees }
      );

    const [standardTicketsResolved, bugsResolved] = await Promise.all([
      fetch(standardIssueTypes),
      fetch(['Bug']),
    ]);

    const mapValues = (list) =>
      list.map((item) => ({
        date: item.date,
        amount: item.values[0],
      }));

    return {
      allTickets: mapValues(standardTicketsResolved[0].values),
      bugs: mapValues(bugsResolved[0].values),
    };
  },
  plumber: (fetchedData, cachedData, apiContext) => {
    const { allTickets, bugs } = fetchedData;

    const combinedData = _.zip(allTickets, bugs);

    let totalBugs = 0;
    let totalTickets = 0;
    const chartData = combinedData.map((item) => {
      const { date } = item[0];
      const bugsRatio = item[0].amount ? (item[1].amount * 100) / item[0].amount : 0;
      totalBugs += item[1].amount;
      totalTickets += item[0].amount;
      return {
        date: new Date(date),
        value: item[1].amount === 0 ? null : bugsRatio,
        bugsAmount: item[1].amount,
      };
    });
    const avgBugs = NumberService.round((totalBugs * 100) / totalTickets);

    const customGranularity = calculateGranularity(apiContext.interval);
    const xTickFormat = customGranularity === 'month' ? dateTime.month : dateTime.monthDay;
    const tooltip = {
      renderBigFn: (v) => (
        <BigText
          content={`${v.bugsAmount} bug${v.bugsAmount === 1 ? '' : 's'}`}
          extra={`${NumberService.round(v.y)}%`}
        />
      ),
    };
    if (_(['month', 'week']).includes(customGranularity)) {
      tooltip.dateRange = {
        ...apiContext.interval,
      };
      tooltip.granularity = customGranularity;
    }

    return {
      empty: totalBugs === 0,
      chart: {
        component: TimeSeries,
        params: {
          data: chartData,
          timeMode: false,
          extra: {
            average: {
              value: avgBugs,
              color: theme.color.ui.turquoise[100],
              renderFn: tooltip.renderBigFn,
              extra: {
                bugsAmount: totalBugs,
              },
            },
            axisKeys: {
              x: 'date',
              y: 'value',
            },
            axisLabels: {
              y: 'Tickets Fixing Bugs',
            },
            color: theme.color.ui.pink[100],
            fillColor: hexToRGBA(theme.color.ui.pink[100], 0.1),
            labels: {
              y: 'Tickets resolved',
            },
            ticks: {
              x: { tickFormat: xTickFormat },
              y: { tickFormat: (v) => `${v}%` },
            },
            maxNumberOfTicks: 10,
            tooltip,
          },
        },
      },
      kpis: [],
    };
  },
};

export default bugFixing;
