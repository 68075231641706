import React from 'react';
import { CellProps, Renderer } from 'react-table';
import { v4 as uuidv4 } from 'uuid';

import { renderAvatars } from '@common-services/format';
import { IPrsTableRow } from '@lib/Table/components/pr-table/prTable.types';

export const PullRequestsCellReviewers: Renderer<CellProps<IPrsTableRow>> = React.memo(
  ({ row }) => {
    return (
      <>
        {renderAvatars(
          row.original.reviewersAvatars,
          null,
          // @ts-ignore Ignoring TS error because of rudimental renderAvatars method
          () => (reviewer) => (
            <img
              key={uuidv4()}
              src={reviewer.avatar}
              className="pr-user-avatar"
              alt={reviewer.userName}
              title={reviewer.userName}
              onError={(e) => {
                (e.target as HTMLImageElement).src =
                  'https://avatars2.githubusercontent.com/u/10137';
              }}
            />
          ),
          (count) => (
            <span key="count" className="pr-user-avatar count">
              +{count}
            </span>
          )
        )}
      </>
    );
  }
);
