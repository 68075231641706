import React from 'react';

import { Icon, icons } from '@lib/icon';
import { IInfoProps } from '@lib/info/info.types';
import { Tooltip } from '@lib/tooltip';

export const Info: React.FC<IInfoProps> = React.memo(({ content, icon, size, tooltipStyle }) => {
  return (
    <Tooltip content={content} style={tooltipStyle || {}}>
      <Icon icon={icon || icons.info} size={size || 16} />
    </Tooltip>
  );
});
