import React, { useCallback, useState } from 'react';

import { IUserWithRole } from '@common-pages/Settings/Users/Users.types';
import { Dropdown, IDropdownNameValue } from '@lib/Dropdown';
import IconButton from '@lib/IconButton';
import Modal from '@lib/Modal';
import { icons } from '@lib/icon';

import {
  controlsStyles,
  itemStyles,
  modalContentStyles,
  nameStyles,
  wrapperStyles,
} from './UsersList.styles';
import { IUsersList } from './UsersList.types';

const UsersList: React.FC<IUsersList> = React.memo(
  ({ isAdmin, roles, users, onRemoveUser, onRoleChange }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [removedUser, setRemovedUser] = useState<IUserWithRole>(null);

    const handleRemoveIconClick = useCallback(
      (user: IUserWithRole) => () => {
        setRemovedUser(user);
        setIsModalOpen(true);
      },
      []
    );

    const handleCancelRemove = useCallback(() => {
      setRemovedUser(null);
    }, []);

    const handleRoleChange = useCallback(
      (userId: string) => (roleObj: IDropdownNameValue) => {
        onRoleChange(userId, roleObj.value);
      },
      []
    );

    return (
      <div css={wrapperStyles}>
        {users.map((user) => (
          <div css={itemStyles} key={`user-${user.id}`}>
            <div css={nameStyles}>
              <img alt="" className="picture" height={22} src={user.picture} width={22} />
              <div className="full-name">{user.name}</div>
              <div className="github-name">{user.login}</div>
            </div>
            <div css={controlsStyles}>
              <Dropdown
                disabled={!isAdmin}
                initialValue={user.role}
                options={roles}
                width={124}
                onChange={handleRoleChange(user.id)}
                placeholder="Select a role"
              />
              {/* hide Remove button from regular users */}
              {isAdmin && (
                <div className="close-icon">
                  <IconButton
                    icon={icons.cross}
                    iconSize={8}
                    title="Remove user"
                    onClick={handleRemoveIconClick(user)}
                  />
                </div>
              )}
            </div>
          </div>
        ))}
        <Modal
          content={
            <div css={modalContentStyles}>
              You are about to remove the access for the user:{' '}
              <span className="removed-user-name">{removedUser?.login}</span>
              <br />
              You can invite this user back by sharing a new invite link.
            </div>
          }
          isOpen={isModalOpen}
          portalId="dialog-holder"
          primaryButtonText="Remove"
          secondaryButtonText="Cancel"
          title="Remove User"
          onPrimaryClick={onRemoveUser(removedUser?.id)}
          onSecondaryClick={handleCancelRemove}
          setOpen={setIsModalOpen}
        />
      </div>
    );
  }
);

export { UsersList };
