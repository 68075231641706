import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { SingleDatePicker } from 'react-dates';

import { Icon, icons } from '@lib/icon';

import Footer from './Footer';
import { arrowIconWrapper, wrapperStyles } from './styles';

const DatePicker = ({
  disabled,
  initialValue = new Date().toString(),
  opensUp = true,
  onChange,
}) => {
  const [value, setValue] = useState(null);
  const [tempValue, setTempValue] = useState(null);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    setValue(moment(new Date(initialValue)));
    setTempValue(moment(new Date(initialValue)));
  }, [initialValue]);

  const handleChange = useCallback(
    (newValue) => {
      if (!newValue) {
        setFocused(true);
        return;
      }
      setTempValue(value);
      setValue(newValue);
    },
    [value]
  );

  const handleApply = useCallback(() => {
    setFocused(false);
    onChange(value);
    setTempValue(null);
  }, [value]);

  const handleCancel = useCallback(() => {
    setValue(tempValue);
    setFocused(false);
  }, [tempValue]);

  return (
    <div css={wrapperStyles}>
      <SingleDatePicker
        autoFocus
        customCloseIcon={<Icon icon={icons.caret_down} size={8} />}
        date={value}
        disabled={disabled}
        displayFormat="MMM Do, YYYY"
        focused={focused}
        isOutsideRange={() => false}
        hideKeyboardShortcutsPanel
        keepOpenOnDateSelect
        navNext={
          <div css={arrowIconWrapper('left')}>
            <Icon icon={icons.angle_right} size={10} />
          </div>
        }
        navPrev={
          <div css={arrowIconWrapper('right')}>
            <Icon icon={icons.angle_left} size={10} />
          </div>
        }
        numberOfMonths={1}
        openDirection={opensUp ? 'up' : 'down'}
        readOnly
        renderCalendarInfo={() => <Footer onCancel={handleCancel} onApply={handleApply} />}
        showClearDate
        showDefaultInputIcon
        onDateChange={handleChange}
        onFocusChange={(v) => setFocused(v.focused)}
      />
    </div>
  );
};

const DatePickerPropTypes = {
  disabled: PropTypes.bool,
  initialValue: PropTypes.string,
  opensUp: PropTypes.bool,
  onChange: PropTypes.func,
};

DatePicker.propTypes = DatePickerPropTypes;

export default DatePicker;
