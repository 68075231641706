//TODO: integrate this with datetimeService when rebasing against master
import { timeFormat, utcFormat } from 'd3-time-format';
import moment, { unitOfTime } from 'moment';

import { NumberService } from './numberService';

const MILLISECOND = 1;
const SECOND = 1000 * MILLISECOND;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;
const MONTH = 30 * DAY;
const YEAR = 365 * DAY;

const ymd = utcFormat('%Y-%m-%d');
const month = utcFormat('%b');
const monthDay = utcFormat('%b %d');
const monthDayYear = utcFormat('%b %d, %Y');

export type MomentDateType = moment.Moment;

export enum TimeUnit {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

// if useSmallerUnitOnSmallValues param is true, "mins" will be used in case the value is less than 3 hours
export const getBestTimeUnit = (
  milliseconds: number,
  short?: boolean,
  useSmallerUnitOnSmallValues: boolean = true
): [number, string] => {
  const units: [number, string][] = [
    [SECOND, short ? 's' : 'secs'],
    [MINUTE, short ? 'm' : 'mins'],
    [HOUR, short ? 'h' : 'hours'],
    [DAY, short ? 'd' : 'days'],
    [WEEK, short ? 'w' : 'weeks'],
    [MONTH, short ? 'mo' : 'months'],
    [YEAR, short ? 'y' : 'years'],
  ];

  if (milliseconds === 0) {
    return units[0];
  }

  let current: [number, string] = [MILLISECOND, 'ms'];
  for (const u of units) {
    const v = milliseconds / u[0];
    if (v < (useSmallerUnitOnSmallValues ? 3 : 1.75)) {
      if (v >= 1 && current[0] === MILLISECOND) {
        current = [SECOND, short ? 's' : 'secs'];
      }

      break;
    }

    current = u;
  }

  return current;
};

const roundDecimals = (num: number, decimals: number = 0): number =>
  Math.round((num + Number.EPSILON) * 10 ** decimals) / 10 ** decimals;

export const bestTimeUnit = (
  milliseconds: number,
  decimals: number = 2,
  short: boolean = false
): string => {
  const [conversionValue, durationUnit] = getBestTimeUnit(milliseconds, short);
  const value = roundDecimals(milliseconds / conversionValue, decimals);
  return `${value}${short ? '' : ' '}${
    value !== 1 || short ? durationUnit : durationUnit.slice(0, -1)
  }`;
};

/**
 * Returns the human format for the passed number of milliseconds.
 * e.g. if passed the equivalent of 242 seconds it will return '4 mins'
 * @param detailed If true, the full number of seconds and years will be returned; otherwise it will be used '<1min', '~1min' and '>5years'
 */
export const human = (
  milliseconds: number,
  decimals: number = 0,
  detailed: boolean = false,
  roundup: boolean = false
): string => {
  if (isNaN(milliseconds)) {
    return '';
  }

  let value, unit;
  if (milliseconds === 0) {
    return '0 seconds';
  } else if (detailed && milliseconds <= 1.75 * MINUTE) {
    value = roundDecimals(milliseconds / SECOND);
    if (roundup && value === 60) {
      value = 1;
      unit = 'min';
    } else {
      unit = 'secs';
    }
  } else if (milliseconds < MINUTE) {
    value = '<1';
    unit = 'min';
  } else if (milliseconds <= 1.75 * MINUTE) {
    value = '1';
    unit = 'min';
  } else if (milliseconds <= 1.75 * HOUR) {
    value = roundDecimals(milliseconds / MINUTE, decimals);
    if (roundup && value === 60) {
      value = 1;
      unit = 'hour';
    } else {
      unit = 'mins';
    }
  } else if (milliseconds <= 1.75 * DAY) {
    value = roundDecimals(milliseconds / HOUR, decimals);
    if (roundup && value === 24) {
      value = 1;
      unit = 'day';
    } else {
      unit = 'hours';
    }
  } else if (milliseconds <= 12 * DAY) {
    value = roundDecimals(milliseconds / DAY, decimals);
    if (roundup && value === 7) {
      value = 1;
      unit = 'week';
    } else {
      unit = 'days';
    }
  } else if (milliseconds <= 8 * WEEK) {
    value = roundDecimals(milliseconds / WEEK, decimals);
    if (roundup && value === 4) {
      value = 1;
      unit = 'month';
    } else {
      unit = 'weeks';
    }
  } else if (milliseconds <= 22 * MONTH) {
    value = roundDecimals(milliseconds / MONTH, decimals);
    if (roundup && value === 12) {
      value = 1;
      unit = 'year';
    } else {
      unit = 'months';
    }
  } else if (milliseconds <= 5 * YEAR || detailed) {
    value = roundDecimals(milliseconds / YEAR, decimals);
    unit = 'years';
  } else {
    value = '>5';
    unit = 'years';
  }

  return `${value} ${unit}`;
};

export const timeValue = (seconds: number): string => {
  let format = '%M:%S';
  if (seconds >= 3600) {
    format = '%H:%M:%S';
  }
  return utcFormat(format)(seconds * 1000);
};

const getPrevMonday = (t: moment.Moment): moment.Moment =>
  moment(t).day(moment(t).day() === 0 ? -6 : 1);

/**
 * Returns the week days, excluding weekends (not holidays) between two passed dates.
 * It takes the interval considering the start of the day of the first argument, and
 * the end of the day of the second argument.
 * e.g. labourDays(a, a) -> 1 if 'a' is not weekend; 0 if it eiter saturday or sunday
 * @param {date|moment} t0 first day of the interval to consider
 * @param {date|moment} t1 last day of the interval to consider
 * @return {int} number of labour days between t0 and t1
 */
export const labourDays = (t0: Date, t1: Date): number => {
  const start = moment(t0).startOf('day');
  const end = moment(t1).endOf('day');
  if (start.day() !== 1) {
    const prevMonday = getPrevMonday(start).toDate();
    return (
      labourDays(prevMonday, end.toDate()) -
      labourDays(prevMonday, start.subtract(1, 'day').toDate())
    );
  }
  const diff = 1 + end.diff(start, 'day');
  return 5 * Math.floor(diff / 7) + Math.min(diff % 7, 5);
};

export const dateTime = {
  formater: timeFormat,
  ymd,
  month,
  monthDay,
  monthDayYear,
  timeValue,

  milliseconds: (secondsString) => {
    if (!secondsString) {
      return 0;
    }

    const seconds = parseInt(secondsString);
    if (!seconds) {
      return 0;
    }

    return 1000 * seconds;
  },
  ago: (date) => human(Date.now() - date),
  interval: (dateFrom, dateTo) => human(dateTo - dateFrom),
  human,
  bestTimeUnit,
  labourDays,
};

export const getOffset = () => moment().utcOffset();

const humanTimeRate = (validDaysPerWeek: number) => (
  timesPerValidDay: number,
  decimals: number = 0
): [number, string, number] => {
  let value: number, unit: string, conversionValue: number;

  if (timesPerValidDay >= 1 / validDaysPerWeek) {
    conversionValue = validDaysPerWeek;
    unit = 'week';
  } else if (timesPerValidDay >= 1 / ((validDaysPerWeek * 30) / 7)) {
    conversionValue = (validDaysPerWeek * 30) / 7;
    unit = 'month';
  } else {
    conversionValue = (validDaysPerWeek * 365) / 7;
    unit = 'year';
  }
  value = timesPerValidDay * conversionValue;

  return [NumberService.round(value, decimals), unit, conversionValue];
};

export const daysBetween = (from: string, to: string): number =>
  strToMoment(to).diff(strToMoment(from), 'days');

/**
 * Extrapolates a frequency of a recurrent event in a human-friendly manner
 * given the number of times the event occurred in certain date interval.
 *
 * Examples:
 * something that happens once per day, from Monday to Sunday, is returned as "7/week"
 *
 * @param {date} from start date when events were measured
 * @param {date} to end date when events were measured
 * @param {number} times number of times an event occurred
 * @param {int} decimals precision
 * @return {[int, string, number]} frequency, units (week|month|year) of the frequency and conversion value
 */
export const humanLaboralRate = (
  from: moment.Moment,
  to: moment.Moment,
  times: number,
  decimals: number = 0
): [number, string, number] => {
  const days = to.diff(from, 'day');
  const timesPerDay = times / (days || 1);
  const [value, unit, conversionValue] = humanTimeRate(7)(timesPerDay, decimals);
  return [value, unit, conversionValue / (days || 1)];
};

export const strToMoment = (str?: string): moment.Moment => moment(str);

export const getCurrentTime = (): string => {
  return moment().format();
};

export const compareDateFn = (a: string, b: string): number => (moment(a) < moment(b) ? -1 : 1);

export const getConvertRate = (unit: string): number => {
  switch (unit) {
    case 'mins':
    case 'min':
      return MINUTE;
    case 'hours':
    case 'hour':
      return HOUR;
    case 'days':
    case 'day':
      return DAY;
    case 'weeks':
    case 'week':
      return WEEK;
    case 'months':
    case 'month':
      return MONTH;
    case 'years':
    case 'year':
      return YEAR;
    default:
      return 1;
  }
};
export const daysInMonth = (dateInMilliseconds: number): number =>
  moment(dateInMilliseconds).daysInMonth();

export const subtractPeriod = (
  dateInMilliseconds: number,
  amount: number,
  unit: unitOfTime.DurationConstructor
): number => moment(dateInMilliseconds).subtract(amount, unit).valueOf();

export const addPeriod = (
  dateInMilliseconds: number,
  amount: number,
  unit: unitOfTime.DurationConstructor
): number => moment(dateInMilliseconds).add(amount, unit).valueOf();

export const formatDate = (dateInMilliseconds: number, format: string): string =>
  moment(dateInMilliseconds).utc().format(format);

export const calculateGranularity = ({
  dateFrom,
  dateTo,
}: {
  dateFrom: string;
  dateTo: string;
}): TimeUnit => {
  const diffDays = daysBetween(dateFrom, dateTo);
  return diffDays <= 4 * 7 ? TimeUnit.DAY : diffDays <= 5 * 30 ? TimeUnit.WEEK : TimeUnit.MONTH;
};

export * as DateService from './dateService';
