import { useCallback, useState } from 'react';
import { useMemo } from 'react';

import { throttle } from '@common-services/vendor/lodash';

import { IReleasesPrRow } from '../releases.types';
import { searchTerm } from '../services/search';

interface IUseSearchProps {
  allReleases: IReleasesPrRow[];
  setFilteredReleases: (releases: IReleasesPrRow[]) => void;
}

export const useSearch = ({ allReleases, setFilteredReleases }: IUseSearchProps) => {
  const [searchedString, setSearchedString] = useState('');

  const releasesAfterSearch = useMemo(
    () => (releases: IReleasesPrRow[], string: string) => searchTerm(releases, string),
    []
  );

  const throttledSearch = throttle((string: string) => {
    const filteredData = releasesAfterSearch(allReleases, string);
    setFilteredReleases(filteredData);
  }, 300);

  const handleSearch = useCallback(
    (string: string) => {
      setSearchedString(string);
      if (string?.length > 1) {
        throttledSearch(string);
      } else {
        // in case searched string is empty or too short initialize it back to full data
        // using timeout to prevent clash in setting React state
        const timeoutId = setTimeout(
          () => setFilteredReleases(allReleases),
          0,
          () => clearTimeout(timeoutId)
        );
      }
    },
    [allReleases]
  );

  return { searchedString, handleSearch };
};
