export enum LineHeight {
  xxxl = '4.8rem', // 48px
  xxl = '4rem', // 40px
  xl = '3.2rem', // 21px
  l = '2.4rem', // 16px
  s = '2rem', // 20px
  default = '1.6rem', // 16px
  xs = '1.2rem', // 12px
  xxs = '1rem', // 10px
}
