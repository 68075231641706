import { css } from '@emotion/react';

export const wrapperStyles = ({ spacing }) => css`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.gap['08']};
`;

export const settingsStyles = ({ color, font, spacing }) => css`
  align-items: center;
  display: flex;
  margin-left: ${spacing.gap['04']};

  span {
    color: ${color.text.secondary};
    font-size: ${font.size.default};
    font-weight: ${font.weight.light};
    white-space: nowrap;
  }

  .page-size-dropdown {
    margin-left: ${spacing.gap['04']};
    margin-right: ${spacing.gap['04']};
  }
`;

export const pagesStyles = ({ spacing }) => css`
  display: flex;

  .page-indeces {
    align-items: center;
    display: flex;
    margin-left: ${spacing.gap['04']};
    margin-right: ${spacing.gap['04']};

    .delimeter {
      margin-left: ${spacing.gap['04']};
      margin-right: ${spacing.gap['04']};
    }
  }
`;

export const arrowStyles = ({ color }) => css`
  align-items: center;
  background: ${color.neutral.white};
  border: 1px solid ${color.neutral[40]};
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  opacity: 50%;
  width: 30px;

  &:hover {
    border-color: ${color.neutral[60]};
  }
`;

export const pageIndexStyles = ({ color, font, spacing }, isActive) => css`
  align-items: center;
  color: ${color.text.secondary};
  cursor: pointer;
  display: flex;
  font-size: ${font.size.default};
  font-weight: ${font.weight.light};
  height: 30px;
  justify-content: center;
  margin-right: ${spacing.gap['02']};
  width: 30px;

  &:hover {
    border: 1px solid ${color.neutral[40]};
    color: ${color.text.dark};
  }

  &:last-child {
    margin-right: ${spacing.gap['0']};
  }

  ${isActive &&
  `
    border: 1px solid ${color.neutral[40]};
    background-color: ${color.neutral.white};
    color: ${color.text.dark};
  `}
`;
