import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import ChecksTable from '@analytics-components/tables/ChecksTable';
import { useDataWidget } from '@analytics-context/DataWidget';

const searchTermByField = (checks, fieldName, term) => {
  const filteredData = [];
  checks.forEach((check) => {
    if (check[fieldName].toLowerCase().indexOf(term.toLowerCase()) >= 0) {
      filteredData.push(check);
    }
  });
  return filteredData;
};

const searchTerm = (checks, term) => {
  // the list of searchable fields
  const fields = ['repoName', 'title'];
  const sepIndex = term.indexOf(':');
  const parts = sepIndex === -1 ? [term] : [term.slice(0, sepIndex), term.slice(sepIndex + 1)];
  let filteredData = [];
  if (parts.length > 1) {
    // in case field name was specified in the Search field
    const [fieldName, term] = parts;
    if (fields.indexOf(fieldName) >= 0) {
      filteredData = searchTermByField(checks, fieldName.trim(), term.trim());
    }
  } else {
    // in case field name was not specified we search in all searchable fields
    fields.forEach((fieldName) => {
      const matchingChecks = searchTermByField(checks, fieldName, term.trim());
      filteredData = filteredData.concat(matchingChecks);
    });
  }
  return filteredData;
};

const ChecksTableDataBinder = () => {
  const { data, isLoading } = useDataWidget();
  const [checks, setChecks] = useState(null);
  const [searchedString, setSearchedString] = useState('');

  // initialize data after it's returned from the plumber
  useEffect(() => {
    setChecks(data?.sort((a, b) => (a.total > b.total ? -1 : 1)));
  }, [data]);

  const throttledSearch = useCallback(
    _.throttle((string) => {
      const filteredData = searchTerm(data, string);
      setChecks(filteredData);
    }, 300),
    [data]
  );

  const handleSearch = useCallback(
    (string) => {
      setSearchedString(string);
      if (string?.length > 1) {
        throttledSearch(string);
      } else {
        // in case searched string is empty or too short initialize it back to full data
        // using timeout to prevent clash in setting React state
        const timeoutId = setTimeout(
          () => {
            setChecks(data);
          },
          0,
          () => clearTimeout(timeoutId)
        );
      }
    },
    [data]
  );

  if (isLoading) {
    return <div className="dataTable-placeholder filter-placeholder" />;
  }

  if (!checks) {
    return null;
  }

  return <ChecksTable data={checks} searchedString={searchedString} onSearch={handleSearch} />;
};

export default ChecksTableDataBinder;
