import React, { useCallback, useEffect, useMemo, useState } from 'react';

import EpicsTable from '@analytics-components/tables/EpicsTable';
import { useDataWidget } from '@analytics-context/DataWidget';
import { throttle, unionBy } from '@common-services/vendor/lodash';

const searchTermByField = (data, fieldName, term) => {
  const filteredData = [];
  data.forEach((epic) => {
    if (epic[fieldName] && epic[fieldName].toLowerCase().indexOf(term.toLowerCase()) >= 0) {
      // add whole epic to the list if there is a match in its field
      filteredData.push(epic);
    } else {
      // search for subtasks that might have a match in their field
      const filteredSubtasks = epic.subRows.filter(
        (task) =>
          (!task.serviceRow && task[fieldName]?.toLowerCase().indexOf(term.toLowerCase()) >= 0) ||
          task.serviceRow
      );
      if ((filteredSubtasks?.length && !epic.hasInactiveTickets) || filteredSubtasks?.length > 1) {
        // in case such subtasks were found add them including their epic
        filteredData.push({
          ...epic,
          subRows: filteredSubtasks,
        });
      }
    }
  });
  return filteredData;
};

const searchTerm = (data, term, status) => {
  // the list of searchable fields
  const fields = ['reporter', 'assignee', 'priority', 'id', 'title'];
  const sepIndex = term.indexOf(':');
  const parts = sepIndex === -1 ? [term] : [term.slice(0, sepIndex), term.slice(sepIndex + 1)];
  let filteredData = [];
  if (parts.length > 1) {
    // in case field name was specified in the Search field
    const [fieldName, term] = parts;
    if (fields.indexOf(fieldName) >= 0) {
      filteredData = searchTermByField(data, fieldName.trim(), term.trim());
    }
  } else {
    // in case field name was not specified we search in all searchable fields
    fields.forEach((fieldName) => {
      const matchingEpics = searchTermByField(data, fieldName, term.trim());
      // merge epics and their subtasks
      matchingEpics.forEach((epic) => {
        const existingEpic = filteredData.find((v) => v.id === epic.id);
        if (existingEpic) {
          existingEpic.subRows = unionBy(existingEpic.subRows, epic.subRows, 'id');
        } else {
          filteredData.push(epic);
        }
      });
    });
  }
  if (status) {
    // if there is a status filter set, apply it
    filteredData = filteredData.filter((epic) => epic.status === status.value);
  }
  return filteredData;
};

const getEpicsWithActiveSubtasks = (data, visibleIssuesCount) => {
  const epics = data
    .filter((v) => !v.serviceRow)
    .map((epic) => {
      if (epic.hasInactiveTickets) {
        const activeIssues = epic.subRows.filter((task) => task.isActive);
        return {
          ...epic,
          issues: epic.activeTicketsAmount,
          prs: epic.activeTicketsPrs,
          showsOnlyActive: true,
          subRows: [
            ...activeIssues,
            {
              id: epic.id,
              serviceRow: true,
              title: 'Show inactive tickets',
            },
          ],
        };
      }
      return {
        ...epic,
        issues: epic.activeTicketsAmount,
        prs: epic.activeTicketsPrs,
      };
    });

  const issuesWithoutEpics = data.find((v) => v.serviceRow);

  return [
    ...epics,
    ...(issuesWithoutEpics
      ? [
          {
            ...issuesWithoutEpics,
            subRows: [
              ...issuesWithoutEpics.subRows.slice(0, visibleIssuesCount),
              ...(issuesWithoutEpics.subRows.length > visibleIssuesCount
                ? [
                    {
                      id: 'without_epics.1',
                      serviceRow: true,
                      title: 'Show more issues',
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
  ];
};

const flattenData = (dataObj) => {
  return [
    ...dataObj.epics,
    ...(dataObj.issuesWithoutEpics?.length > 0
      ? [
          {
            id: 'without_epics',
            issues: dataObj.issuesWithoutEpics.length,
            serviceRow: true,
            subRows: dataObj.issuesWithoutEpics,
            title: 'Issues without epics',
          },
        ]
      : []),
  ];
};

const EpicsTableDataBinder = () => {
  const { data, isLoading } = useDataWidget();
  const [flatData, setFlatData] = useState([]);
  const [filteredEpics, setFilteredEpics] = useState(null);
  const [searchedString, setSearchedString] = useState('');
  const [currentStatus, setCurrentStatus] = useState(null);
  const [visibleIssuesCount, setVisibleIssuesCount] = useState(10);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setFlatData(flattenData(data));
    }
  }, [data]);

  // initialize data after it's returned from the plumber
  useEffect(() => {
    if (flatData && flatData.length > 0) {
      setFilteredEpics(getEpicsWithActiveSubtasks(flatData, visibleIssuesCount));
    }
  }, [flatData, visibleIssuesCount]);

  const throttledSearch = useCallback(
    throttle((string) => {
      const filteredData = searchTerm(getEpicsWithActiveSubtasks(flatData), string, currentStatus);
      setFilteredEpics(filteredData);
    }, 300),
    [currentStatus, flatData, visibleIssuesCount]
  );

  const handleSearch = useCallback(
    (string) => {
      setSearchedString(string);
      if (string?.length > 1) {
        throttledSearch(string);
      } else {
        // in case searched string is empty or too short initialize it back to full data
        // using timeout to prevent clash in setting React state
        const timeoutId = setTimeout(
          () => {
            let filteredData = getEpicsWithActiveSubtasks(flatData, visibleIssuesCount);
            if (currentStatus) {
              // if there is a status filter set, apply it
              filteredData = filteredData.filter((epic) => epic.status === currentStatus.value);
            }
            setFilteredEpics(filteredData);
          },
          0,
          () => clearTimeout(timeoutId)
        );
      }
    },
    [currentStatus, flatData, filteredEpics, visibleIssuesCount]
  );

  const handleServiceRowClick = useCallback(
    (id) => {
      const originalEpic = flatData.find((epic) => epic.id === id);
      if (originalEpic) {
        let epicsWithInactiveSubtasks = filteredEpics.map((epic) => {
          if (epic.id === id) {
            if (epic.showsOnlyActive) {
              return {
                ...epic,
                issues: originalEpic.subRows.length,
                prs: originalEpic.totalPrs,
                showsOnlyActive: false,
                subRows: [
                  ...originalEpic.subRows.filter((task) => !task.serviceRow),
                  {
                    id: epic.id,
                    serviceRow: true,
                    title: 'Hide inactive tickets',
                  },
                ],
              };
            } else {
              return {
                ...epic,
                issues: epic.activeTicketsAmount,
                prs: epic.activeTicketsPrs,
                showsOnlyActive: true,
                subRows: [
                  ...epic.subRows.filter((task) => task.isActive && !task.serviceRow),
                  {
                    id: epic.id,
                    serviceRow: true,
                    title: 'Show inactive tickets',
                  },
                ],
              };
            }
          }
          return epic;
        });
        if (searchedString) {
          epicsWithInactiveSubtasks = searchTerm(
            epicsWithInactiveSubtasks,
            searchedString,
            currentStatus
          );
        }
        setFilteredEpics(epicsWithInactiveSubtasks);
      } else {
        setVisibleIssuesCount(visibleIssuesCount + 10);
      }
    },
    [currentStatus, flatData, filteredEpics, searchedString]
  );

  const uniqueStatuses = useMemo(() => {
    const statuses = new Set();
    const statusStages = {};
    if (flatData?.length) {
      flatData.forEach((epic) => {
        statuses.add(epic.status);
        statusStages[epic.status] = epic.statusStage;
      });
    }
    return Array.from(statuses)
      .sort((a, b) => (statusStages[a] > statusStages[b] ? -1 : 1))
      .map((status) => ({
        label: status,
        value: status,
      }));
  }, [flatData]);

  const statusOptions = useMemo(
    () => [
      {
        label: 'All',
        value: null,
      },
      ...uniqueStatuses,
    ],
    [uniqueStatuses]
  );

  const filterByStatus = useCallback(
    (status) => {
      let newFilteredEpics = getEpicsWithActiveSubtasks(flatData, visibleIssuesCount);
      if (searchedString) {
        newFilteredEpics = searchTerm(newFilteredEpics, searchedString, status);
      } else if (status) {
        newFilteredEpics = newFilteredEpics.filter((epic) => epic.status === status.value);
      }
      setCurrentStatus(status);
      setFilteredEpics(newFilteredEpics);
    },
    [flatData, searchedString, visibleIssuesCount]
  );

  if (isLoading) {
    return <div className="dataTable-placeholder filter-placeholder" />;
  }

  if (!filteredEpics) {
    return null;
  }

  return (
    <EpicsTable
      data={filteredEpics}
      currentStatus={currentStatus}
      filterOptions={statusOptions}
      searchedString={searchedString}
      onFilter={filterByStatus}
      onSearch={handleSearch}
      onServiceRowClick={handleServiceRowClick}
    />
  );
};

export default EpicsTableDataBinder;
