import { useMemo } from 'react';

import { filterNameFormatter, FilterService } from '@align-services/filterService';
import { Goal } from '@common-services/api/private/generated-from-backend/models';
import {
  useJiraProjects,
  useOwnedJiraFilter,
} from '@common-services/api/public/hooks/useFiltersData';

import { IFilterDetailRow } from './filter-detail-row';

export function useAppliedFilters(goal: Goal): IFilterDetailRow[] {
  const { data: jiraIssueTypesPriorities } = useOwnedJiraFilter(
    ['issues', 'issue_types', 'priorities'],
    true
  );
  const { data: jiraProjects } = useJiraProjects(true);

  const appliedFilters = useMemo(() => {
    return FilterService.appliedGoalFilters(
      goal,
      filterNameFormatter({
        jiraIssueTypes: jiraIssueTypesPriorities?.issue_types,
        jiraProjects,
        jiraPriorities: jiraIssueTypesPriorities?.priorities,
      })
    );
  }, [goal, jiraIssueTypesPriorities, jiraProjects]);

  return appliedFilters;
}
