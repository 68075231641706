import { useMemo } from 'react';

import { metricsConfig } from '@align-constants';
import { IUseTarget } from '@align-pages/goals/single/components/goal-view-component/goalViewComponent.types';
import { GoalsSingleComponentServices } from '@align-pages/goals/single/components/goals-single-component';
import { ITemplateMetric } from '@align-services/api/types/goalsTypes';
import { MetricService } from '@align-services/metricService';
import { Goal } from '@common-services/api/private/generated-from-backend/models';
import { NumberService } from '@common-services/numberService';

export const useTarget = (curGoalData: Goal, templateMetric: ITemplateMetric): IUseTarget => {
  const threshold = useMemo(() => curGoalData?.metric_params?.threshold, [curGoalData]);

  const targetValue = useMemo(
    () =>
      threshold || curGoalData
        ? MetricService.getReadableValue(
            GoalsSingleComponentServices.deNormaliseMetricValue(
              threshold || curGoalData.team_goal.value.target
            ),
            metricsConfig[templateMetric]?.valueType,
            metricsConfig[templateMetric]?.unit
          )
        : null,
    [curGoalData, threshold, templateMetric]
  );

  const targetPercentage = useMemo(() => {
    if (threshold) {
      return `${NumberService.round(
        parseFloat(curGoalData.team_goal.value.target.toString()) * 100
      )}%`;
    }
    return null;
  }, [curGoalData, threshold]);

  return { targetValue, targetPercentage };
};
