import { css } from '@emotion/react';

import { ChartBlockSize } from '@align-pages/dashboard/config';

export const wrapperStyles = ({ color, font, radius, spacing }) => css`
  align-items: center;
  background-color: ${color.neutral.white};
  border: 1px dashed ${color.neutral['40']};
  border-radius: ${radius.default};
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: ${ChartBlockSize.HEIGHT}px;
  width: ${ChartBlockSize.WIDTH}px;

  path {
    fill: ${color.neutral['60']};
  }

  .add-metric {
    text-align: center;

    .add-metric-text {
      color: ${color.text.secondary};
      font-size: ${font.size.s};
      font-weight: ${font.weight.medium};
      margin-top: ${spacing.gap['05']};
    }
  }

  &:hover {
    background-color: ${color.neutral['10']};
    border-color: ${color.neutral['80']};

    path {
      fill: ${color.neutral['80']};
    }

    .add-metric-text {
      color: ${color.text.dark};
    }
  }
`;
