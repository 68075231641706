import React from 'react';

import { ChartType } from '@analytics-components/insights/Box/types';

import ChartTitle from '../ChartTitle';

interface Props {
  chart: ChartType;
}

const SimpleBoxBodyRow: React.FC<Props> = React.memo(({ chart }) => (
  <>
    <ChartTitle {...chart} />
    <div className="row">
      <ChartTitle {...chart} />
      <div className="col-12">
        <chart.component {...chart.params} />
      </div>
    </div>
  </>
));

export default SimpleBoxBodyRow;
