import _ from 'lodash';
import React, { useContext, useState, useEffect } from 'react';

import { useData, useDataHelper } from '@analytics-context/Data';
import { useApi } from '@analytics-hooks';

const Context = React.createContext({
  data: null,
  error: null,
  isLoading: true,
});

const useDataWidget = () => useContext(Context);

const DataWidget = ({
  id,
  children,
  fetcher = async (api, cachedData, apiContext, other) => ({ ...other }),
  plumber = (fetchedData, cachedData, apiContext, other) => ({
    fetchedData,
    cachedData,
    ...other,
  }),
  prefetchedDataIds = [],
  fetcherId = `${id}-fetcher`,
  plumberId = `${id}-plumber`,
}) => {
  const { api, context: apiContext, ready: apiReady } = useApi(true);
  const [prefetchedDataReady, setPrefetchedDataReady] = useState(false);
  const [dataDefs, setDataDefs] = useState({});
  const { missingMetrics, getRegistry, subscribe, unsubscribe } = useDataHelper();

  useEffect(() => {
    const updateState = () => {
      const registeredDataDefs = getRegistry();
      const diff = _(prefetchedDataIds).difference(Object.keys(registeredDataDefs)).value();
      const updatedDataDefs = _(registeredDataDefs)
        .pickBy((v, k) => _(prefetchedDataIds).includes(k))
        .value();

      setDataDefs(updatedDataDefs);
      setPrefetchedDataReady(diff.length === 0);

      return diff.length !== 0;
    };

    subscribe(id, 'reset', () => {
      setPrefetchedDataReady(false);
      setDataDefs(null);
    });

    if (!prefetchedDataReady) {
      if (prefetchedDataIds.length === 0) {
        setPrefetchedDataReady(true);
      } else {
        const hasChanges = updateState();
        if (hasChanges || missingMetrics) {
          subscribe(id, 'registry-change', (key) => {
            if (_(prefetchedDataIds).includes(key)) {
              updateState();
            }
          });
        }
      }
    }

    return () => {
      unsubscribe(id);
    };
  }, [id, prefetchedDataIds, prefetchedDataReady, missingMetrics]);

  const { done: cachedDataReady, data: cachedData, error: cachedError } = useData(
    `${id}-cached`,
    dataDefs,
    apiReady && prefetchedDataReady
  );
  const { done: fetchedDataReady, data: fetchedData, error: fetchedError } = useData(
    fetcherId,
    () => fetcher(api, cachedData, apiContext),
    apiReady && cachedDataReady
  );
  const { done: plumbedDataReady, data: plumbedData, error: plumbedError } = useData(
    plumberId,
    () => plumber(fetchedData, cachedData, apiContext),
    fetchedDataReady
  );

  return (
    <Context.Provider
      value={{
        data: plumbedData,
        error: plumbedError || fetchedError || cachedError,
        isLoading: !plumbedDataReady,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default DataWidget;
export { useDataWidget };
