import { createStore } from './helpers/createStore.helper';
import { IAlignStore } from './store.types';

export const useAlignStore = createStore<IAlignStore>(
  (set) => ({
    teamsPath: null,
    onSetTeamsPath: (path) => {
      set((state) => ({
        ...state,
        teamsPath: path,
      }));
    },
  }),
  'alignStore',
  true
);
