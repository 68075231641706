import React from 'react';

import Upgrade from '@analytics-components/layout/Upgrade';
import { useBreadcrumb } from '@analytics-context/Breadcrumb';
import { useUserContext } from '@common-context/User';
import { ComingSoon } from '@lib/empty/comingSoon';

import InsightsAllocation from './allocation';
import InsightsNewFeatures from './new-features';
import InsightsTheme from './themes';

const Insights = () => {
  const { subsection: activeStage } = useBreadcrumb();
  const { account } = useUserContext();
  const Component = {
    'allocation': <InsightsAllocation />,
    'themes': !!account.jira ? <InsightsTheme /> : <Upgrade section="outcome" />,
    'new-features': !!account.jira ? <InsightsNewFeatures /> : <Upgrade section="outcome" />,
  }[activeStage] || <ComingSoon />;

  return Component;
};

export default Insights;
