import { useTheme } from '@emotion/react';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ChartLabel,
  FlexibleWidthXYPlot,
  HorizontalGridLines,
  LabelSeries,
  MarkSeries,
  VerticalBarSeries,
  XAxis,
  YAxis,
} from 'react-vis';

import LegendSelect from '@analytics-components/LegendSelect';
import defaults from '@analytics-components/charts/defaults';
import { dateTime } from '@common-services/dateService';
import { hexToRGBA } from '@utils/colors';

import { legendWrapperStyles } from './styles';

const mapData = (extra) => (data, colors, index) => {
  return {
    ...data,
    values: _(data.values)
      .map((v) => ({
        ...v,
        color: colors[index],
        name: data.name,
        x: v[extra.axisKeys.x]?.getTime(),
        y: v[extra.axisKeys.y] || 0,
      }))
      .value(),
  };
};

const getPrevValuesSum = (data, outIndex, inIndex) => {
  let sum = 0;
  for (let i = outIndex - 1; i >= 0; i -= 1) {
    sum += data[i].values[inIndex]?.value || 0;
  }
  return sum;
};

const HoveredBarChart = ({ data, extra }) => {
  const theme = useTheme();

  const [currentHover, setCurrentHover] = useState(null);
  const [colors, setColors] = useState([]);
  const [activeData, setActiveData] = useState(Array(data.length).fill(true));

  useEffect(() => {
    setColors(Array.prototype.concat(extra.color));
  }, [extra.color]);

  const marginLeft = extra.margin?.left ?? defaults.marginLeft;
  const marginTop = 15;
  const marginBottom = extra.margin?.bottom ?? 60;

  const mappedData = useMemo(() => mapData(extra), [extra]);

  const formattedData = useMemo(() => {
    const outData = data.map((dataItem, i) => mappedData(dataItem, colors, i));
    const allValues = _(outData)
      .map('values')
      .map((values) => _(values).map('y').value())
      .value();
    const filteredAllValues = _(allValues)
      .zip(activeData)
      .filter((v) => v[1])
      .map((v) => v[0])
      .value();
    const sums = _.zip(...filteredAllValues).map((arr) => _(arr).sum());
    const rescaledData = _(outData)
      .map((out, i) => ({
        ...out,
        enabled: activeData[i],
        values: _(out.values)
          .map((v, j) => {
            const value = activeData[i] ? (v.value * 100) / sums[j] : 0;
            return {
              ...v,
              value,
              y: value,
            };
          })
          .value(),
      }))
      .value();

    return rescaledData;
  }, [colors, data, mappedData, activeData]);

  const labelData = useMemo(() => {
    if (currentHover) {
      return formattedData.map((item, outerIndex) => {
        if (item.name === currentHover.name) {
          return item.values.map((elem, innerIndex) => {
            // calculate sum of prev values to position label correctly since react-vis does not support stacked labels
            // https://github.com/uber/react-vis/issues/1037
            const prevValuesSum = getPrevValuesSum(formattedData, outerIndex, innerIndex);
            const returnObj = {
              label: elem.y > 3 ? `${Math.round(elem.value)}%` : '',
              x: elem.x,
              y: elem.y + prevValuesSum,
              yOffset: 10,
            };
            return returnObj;
          });
        }
        return [];
      });
    }
    return [];
  }, [currentHover, formattedData]);

  // this data is used to put transparent marks on top of labels so that hovering over them will not cause flickering
  const markData = useMemo(() => {
    const addition = [];
    return formattedData.map((item, outerIndex) => {
      addition.push({ values: [] });
      return {
        ...item,
        values: item.values.map((elem, innerIndex) => {
          addition[outerIndex].values.push({
            value: elem.y > 3 ? 0 : elem.y,
          });
          const prevValuesSum = getPrevValuesSum(formattedData, outerIndex, innerIndex);
          return elem.y > 3
            ? {
                ...elem,
                // TODO: the logic below is quite complex and was created by trying different scenarios
                // it's possible that it could be buggy, so might need refactoring
                y:
                  elem.y -
                  3 +
                  (outerIndex >= 1 && prevValuesSum ? 3 : 0) +
                  getPrevValuesSum(addition, outerIndex, innerIndex),
              }
            : { date: elem.date, y: 0 };
        }),
      };
    });
  }, [formattedData]);

  const handleValueMouseOver = useCallback(
    (datapoint) => {
      setCurrentHover(datapoint);
      const itemIndex = formattedData.findIndex((v) => v.name === datapoint.name);
      if (itemIndex >= 0) {
        const newColors = [...colors];
        newColors.forEach((color, index) => {
          newColors[index] = index === itemIndex ? theme.color.neutral[100] : hexToRGBA(color, 0.5);
        });
        setColors(newColors);
      }
    },
    [currentHover, formattedData]
  );

  const handleValueMouseOut = useCallback(() => {
    setColors(Array.prototype.concat(extra.color));
    setCurrentHover(null);
  }, [extra]);

  const legend = useMemo(
    () =>
      _(formattedData)
        .map((item, index) => ({
          color: item.values[0]?.color || colors[index],
          label: item.name,
        }))
        .value(),
    [colors, formattedData]
  );

  // TODO: at some point we might decide to not render all the X ticks and show them without angle
  // const xTicks = useMemo(
  //   () =>
  //     computeTickValues(
  //       formattedData[0].values.map((v) => ({
  //         ...v,
  //         x: v.date,
  //       })),
  //       extra?.ticks?.x?.maxNumberOfTicks || 15
  //     ),
  //   [formattedData]
  // );

  const barWidth = useMemo(() => {
    const amountOfXTicks = formattedData[0].values.length;
    if (amountOfXTicks > 10) {
      return 0.6;
    }
    return 0.4;
  }, [formattedData]);

  if (!data || data.length === 0) {
    return <></>;
  }

  const maxY = _(formattedData).flatMap().maxBy('y');
  const maxNumberOfTicks =
    (extra.maxNumberOfTicks || 10) > maxY?.y ? maxY?.y || 0 : extra.maxNumberOfTicks || 10;

  const notTooManyTicks = data[0].values.length < 15;

  const onActivesChange = extra.filterableLegend ? setActiveData : null;

  return (
    <div>
      <div css={legendWrapperStyles}>
        <LegendSelect items={legend} onActivesChange={onActivesChange} />
      </div>
      <FlexibleWidthXYPlot
        height={extra.height || 300}
        margin={{ top: marginTop, left: marginLeft, bottom: marginBottom }}
        xType="ordinal"
        stackBy="y"
      >
        <XAxis
          tickLabelAngle={
            extra.axisFormat?.tickAngle?.x !== undefined && notTooManyTicks
              ? extra.axisFormat.tickAngle.x
              : -45
          }
          tickFormat={
            extra.axisFormat?.tickFormat?.x ? extra.axisFormat.tickFormat.x : dateTime.monthDay
          }
          tickSize={extra.axisFormat?.tickSize?.x}
          tickPadding={notTooManyTicks ? 10 : extra.axisFormat?.tickPadding?.x}
          // tickValues={xTicks}
        />

        <HorizontalGridLines tickTotal={maxNumberOfTicks} />
        <YAxis
          tickTotal={maxNumberOfTicks}
          tickFormat={extra.axisFormat?.tickFormat?.y || ((y) => y)}
          tickSize={extra.axisFormat?.tickSize?.y}
          tickPadding={extra.axisFormat?.tickPadding?.y}
        />
        {extra.axisLabels &&
          extra.axisLabels.y &&
          buildChartLabel(extra.axisLabels.y, 'y', marginLeft)}

        {formattedData.map((dataItem, index) => (
          <VerticalBarSeries
            barWidth={extra.barWidth || barWidth}
            colorType="literal"
            data={dataItem.values}
            key={`bar-${index}`}
            onValueMouseOver={handleValueMouseOver}
            onValueMouseOut={handleValueMouseOut}
          />
        ))}

        {labelData.map((dataArr, index) => (
          <LabelSeries
            data={dataArr}
            labelAnchorX="middle"
            labelAnchorY="end"
            key={`label-${index}`}
            style={{
              fill: theme.color.neutral.white,
              fontSize: theme.font.size.xs,
              fontWeight: theme.font.weight.bold,
            }}
          />
        ))}

        {markData.map((dataArr, index) => (
          <MarkSeries
            color="transparent"
            data={dataArr.values}
            key={`invisible-${index}`}
            size={9}
            onValueMouseOver={handleValueMouseOver}
            onValueMouseOut={handleValueMouseOut}
          />
        ))}
      </FlexibleWidthXYPlot>
    </div>
  );
};

const buildChartLabel = (text, which, marginLeft) => {
  const labelParams = {
    x: {
      includeMargin: false,
      xPercent: 0.5,
      yPercent: 1.0,
      style: {
        y: 60,
        textAnchor: 'middle',
      },
    },
    y: {
      includeMargin: false,
      xPercent: 0.0,
      yPercent: 0.5,
      style: {
        textAnchor: 'middle',
        transform: 'rotate(-90)',
        y: -(marginLeft - 20),
      },
    },
  }[which];

  return <ChartLabel text={text} {...labelParams} />;
};

export default HoveredBarChart;
