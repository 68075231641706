/**
 * {{ title }}
 * Server version: {{ server_version }} built on {{ build_date }} @ [{{ commit }}](https://github.com/athenianco/athenian-api/commit/{{ commit }})  Origin GitHub repository: [athenianco/api-spec](https://github.com/athenianco/api-spec).  Authorization persists between sessions. 🛡️ means that the endpoint requires account admin privileges. 👑 means that the endpoint is only accessible to Athenian authorities. <details> <summary>List of common server errors.</summary> * __500__ endpoint crashed. We reported the incident to Sentry and will fix it soon! Please include the value of `instance` in the response if you contact the support. Repeating the request will not help, most probably. * __501__ some functions are missing, either because they are not implemented yet or a non-critical runtime dependency is not satisfied. Repeating the request will not help, guaranteed. * __502__ server crashed badly, either due to a memory access violation in native code or running out of memory. We reported this incident to Sentry and will fix it soon! Try repeating the request. * __503__ server has not fully launched yet, e.g. hasn't connected to the database; server is shutting down; we are experiencing a partial outage. Try repeating the request. * __504__ endpoint took too much time and was interrupted. We reported the incident to Sentry and will see how to improve the performance. Repeating the request will not help, most probably. </details>
 *
 * Contact: admin@athenian.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import LinkedJIRAIssue from './LinkedJIRAIssue';
import PullRequestEvent from './PullRequestEvent';
import PullRequestLabel from './PullRequestLabel';
import PullRequestParticipant from './PullRequestParticipant';
import PullRequestStage from './PullRequestStage';
import StageTimings from './StageTimings';

/**
 * The PullRequest model module.
 * @module model/PullRequest
 */
class PullRequest {
    /**
     * Constructs a new <code>PullRequest</code>.
     * Details of a pull request. All the attributes, stats and events correspond to \&quot;today\&quot;, not &#x60;date_to&#x60;, *except for the PR stages &#x60;wip&#x60;, &#x60;reviewing&#x60;, &#x60;merging&#x60;, &#x60;releasing&#x60;*, which correspond to &#x60;date_to&#x60;.
     * @alias module:model/PullRequest
     * @param repository {String} PR is/was open in this repository.
     * @param _number {Number} PR number.
     * @param title {String} Title of the PR.
     * @param sizeAdded {Number} Overall number of lines added.
     * @param sizeRemoved {Number} Overall number of lines removed.
     * @param filesChanged {Number} Number of files changed in this PR.
     * @param created {Date} When this PR was created.
     * @param updated {Date} When this PR was last updated.
     * @param comments {Number} Number of *regular* (not review) comments in this PR.
     * @param commits {Number} Number of commits in this PR.
     * @param stageTimings {module:model/StageTimings} 
     * @param eventsNow {Array.<module:model/PullRequestEvent>} List of PR events that ever happened.
     * @param stagesNow {Array.<module:model/PullRequestStage>} List of current PR stages (normally, of length 1).
     * @param participants {Array.<module:model/PullRequestParticipant>} List of developers related to this PR.
     */
    constructor(repository, _number, title, sizeAdded, sizeRemoved, filesChanged, created, updated, comments, commits, stageTimings, eventsNow, stagesNow, participants) { 
        
        PullRequest.initialize(this, repository, _number, title, sizeAdded, sizeRemoved, filesChanged, created, updated, comments, commits, stageTimings, eventsNow, stagesNow, participants);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, repository, _number, title, sizeAdded, sizeRemoved, filesChanged, created, updated, comments, commits, stageTimings, eventsNow, stagesNow, participants) { 
        obj['repository'] = repository;
        obj['number'] = _number;
        obj['title'] = title;
        obj['size_added'] = sizeAdded;
        obj['size_removed'] = sizeRemoved;
        obj['files_changed'] = filesChanged;
        obj['created'] = created;
        obj['updated'] = updated;
        obj['comments'] = comments;
        obj['commits'] = commits;
        obj['stage_timings'] = stageTimings;
        obj['events_now'] = eventsNow;
        obj['stages_now'] = stagesNow;
        obj['participants'] = participants;
    }

    /**
     * Constructs a <code>PullRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PullRequest} obj Optional instance to populate.
     * @return {module:model/PullRequest} The populated <code>PullRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PullRequest();

            if (data.hasOwnProperty('repository')) {
                obj['repository'] = ApiClient.convertToType(data['repository'], 'String');
            }
            if (data.hasOwnProperty('number')) {
                obj['number'] = ApiClient.convertToType(data['number'], 'Number');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('size_added')) {
                obj['size_added'] = ApiClient.convertToType(data['size_added'], 'Number');
            }
            if (data.hasOwnProperty('size_removed')) {
                obj['size_removed'] = ApiClient.convertToType(data['size_removed'], 'Number');
            }
            if (data.hasOwnProperty('files_changed')) {
                obj['files_changed'] = ApiClient.convertToType(data['files_changed'], 'Number');
            }
            if (data.hasOwnProperty('created')) {
                obj['created'] = ApiClient.convertToType(data['created'], 'Date');
            }
            if (data.hasOwnProperty('updated')) {
                obj['updated'] = ApiClient.convertToType(data['updated'], 'Date');
            }
            if (data.hasOwnProperty('closed')) {
                obj['closed'] = ApiClient.convertToType(data['closed'], 'Date');
            }
            if (data.hasOwnProperty('comments')) {
                obj['comments'] = ApiClient.convertToType(data['comments'], 'Number');
            }
            if (data.hasOwnProperty('commits')) {
                obj['commits'] = ApiClient.convertToType(data['commits'], 'Number');
            }
            if (data.hasOwnProperty('review_requested')) {
                obj['review_requested'] = ApiClient.convertToType(data['review_requested'], 'Date');
            }
            if (data.hasOwnProperty('first_review')) {
                obj['first_review'] = ApiClient.convertToType(data['first_review'], 'Date');
            }
            if (data.hasOwnProperty('approved')) {
                obj['approved'] = ApiClient.convertToType(data['approved'], 'Date');
            }
            if (data.hasOwnProperty('review_comments')) {
                obj['review_comments'] = ApiClient.convertToType(data['review_comments'], 'Number');
            }
            if (data.hasOwnProperty('reviews')) {
                obj['reviews'] = ApiClient.convertToType(data['reviews'], 'Number');
            }
            if (data.hasOwnProperty('merged')) {
                obj['merged'] = ApiClient.convertToType(data['merged'], 'Date');
            }
            if (data.hasOwnProperty('merged_with_failed_check_runs')) {
                obj['merged_with_failed_check_runs'] = ApiClient.convertToType(data['merged_with_failed_check_runs'], ['String']);
            }
            if (data.hasOwnProperty('released')) {
                obj['released'] = ApiClient.convertToType(data['released'], 'Date');
            }
            if (data.hasOwnProperty('release_url')) {
                obj['release_url'] = ApiClient.convertToType(data['release_url'], 'String');
            }
            if (data.hasOwnProperty('stage_timings')) {
                obj['stage_timings'] = StageTimings.constructFromObject(data['stage_timings']);
            }
            if (data.hasOwnProperty('events_time_machine')) {
                obj['events_time_machine'] = ApiClient.convertToType(data['events_time_machine'], [PullRequestEvent]);
            }
            if (data.hasOwnProperty('stages_time_machine')) {
                obj['stages_time_machine'] = ApiClient.convertToType(data['stages_time_machine'], [PullRequestStage]);
            }
            if (data.hasOwnProperty('events_now')) {
                obj['events_now'] = ApiClient.convertToType(data['events_now'], [PullRequestEvent]);
            }
            if (data.hasOwnProperty('stages_now')) {
                obj['stages_now'] = ApiClient.convertToType(data['stages_now'], [PullRequestStage]);
            }
            if (data.hasOwnProperty('participants')) {
                obj['participants'] = ApiClient.convertToType(data['participants'], [PullRequestParticipant]);
            }
            if (data.hasOwnProperty('labels')) {
                obj['labels'] = ApiClient.convertToType(data['labels'], [PullRequestLabel]);
            }
            if (data.hasOwnProperty('jira')) {
                obj['jira'] = ApiClient.convertToType(data['jira'], [LinkedJIRAIssue]);
            }
        }
        return obj;
    }


}

/**
 * PR is/was open in this repository.
 * @member {String} repository
 */
PullRequest.prototype['repository'] = undefined;

/**
 * PR number.
 * @member {Number} number
 */
PullRequest.prototype['number'] = undefined;

/**
 * Title of the PR.
 * @member {String} title
 */
PullRequest.prototype['title'] = undefined;

/**
 * Overall number of lines added.
 * @member {Number} size_added
 */
PullRequest.prototype['size_added'] = undefined;

/**
 * Overall number of lines removed.
 * @member {Number} size_removed
 */
PullRequest.prototype['size_removed'] = undefined;

/**
 * Number of files changed in this PR.
 * @member {Number} files_changed
 */
PullRequest.prototype['files_changed'] = undefined;

/**
 * When this PR was created.
 * @member {Date} created
 */
PullRequest.prototype['created'] = undefined;

/**
 * When this PR was last updated.
 * @member {Date} updated
 */
PullRequest.prototype['updated'] = undefined;

/**
 * When this PR was last closed.
 * @member {Date} closed
 */
PullRequest.prototype['closed'] = undefined;

/**
 * Number of *regular* (not review) comments in this PR.
 * @member {Number} comments
 */
PullRequest.prototype['comments'] = undefined;

/**
 * Number of commits in this PR.
 * @member {Number} commits
 */
PullRequest.prototype['commits'] = undefined;

/**
 * When was the first time the author of this PR requested a review.
 * @member {Date} review_requested
 */
PullRequest.prototype['review_requested'] = undefined;

/**
 * When the first review of this PR happened.
 * @member {Date} first_review
 */
PullRequest.prototype['first_review'] = undefined;

/**
 * When this PR was approved.
 * @member {Date} approved
 */
PullRequest.prototype['approved'] = undefined;

/**
 * Number of review comments this PR received. A review comment is left at a specific line in a specific file. In other words: review summaries are *not* considered review comments; refer to `reviews`. Comments by the PR author are considered as `comments`, not as `review_comments`.
 * @member {Number} review_comments
 */
PullRequest.prototype['review_comments'] = undefined;

/**
 * Number of times this PR was reviewed. Reviews by the PR author are not taken into account.
 * @member {Number} reviews
 */
PullRequest.prototype['reviews'] = undefined;

/**
 * When this PR was merged.
 * @member {Date} merged
 */
PullRequest.prototype['merged'] = undefined;

/**
 * PR was merged with these failed check runs.
 * @member {Array.<String>} merged_with_failed_check_runs
 */
PullRequest.prototype['merged_with_failed_check_runs'] = undefined;

/**
 * When this PR was released.
 * @member {Date} released
 */
PullRequest.prototype['released'] = undefined;

/**
 * URL of the earliest release that includes this merged PR.
 * @member {String} release_url
 */
PullRequest.prototype['release_url'] = undefined;

/**
 * @member {module:model/StageTimings} stage_timings
 */
PullRequest.prototype['stage_timings'] = undefined;

/**
 * List of PR events which happened until `date_to`. `date_from` does not matter.
 * @member {Array.<module:model/PullRequestEvent>} events_time_machine
 */
PullRequest.prototype['events_time_machine'] = undefined;

/**
 * List of PR stages as of `date_to` (normally, of length 1).
 * @member {Array.<module:model/PullRequestStage>} stages_time_machine
 */
PullRequest.prototype['stages_time_machine'] = undefined;

/**
 * List of PR events that ever happened.
 * @member {Array.<module:model/PullRequestEvent>} events_now
 */
PullRequest.prototype['events_now'] = undefined;

/**
 * List of current PR stages (normally, of length 1).
 * @member {Array.<module:model/PullRequestStage>} stages_now
 */
PullRequest.prototype['stages_now'] = undefined;

/**
 * List of developers related to this PR.
 * @member {Array.<module:model/PullRequestParticipant>} participants
 */
PullRequest.prototype['participants'] = undefined;

/**
 * List of PR labels.
 * @member {Array.<module:model/PullRequestLabel>} labels
 */
PullRequest.prototype['labels'] = undefined;

/**
 * List of linked JIRA issues.
 * @member {Array.<module:model/LinkedJIRAIssue>} jira
 */
PullRequest.prototype['jira'] = undefined;






export default PullRequest;

