import React from 'react';

import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import Panel from '@analytics-components/insights/Panel';
import { prepareChartBoxes } from '@analytics-pages/delivery-pipeline/insights/helper';

import prsCycleTimeDonutDistribution from './prs-cycle-time-donut-distribution';
import prsCycleTimePerRepo from './prs-cycle-time-per-repo';

const meta = {
  title: 'Pull Request Cycle Time',
  description: '',
};

const chartBoxes = [
  {
    id: 'prs-cycle-time-donut-distribution',
    component: ChartBoxWithKPIWidget,
    params: prsCycleTimeDonutDistribution,
  },
  {
    id: 'prs-cycle-time-per-repo',
    component: ChartBoxWithKPIWidget,
    params: prsCycleTimePerRepo,
  },
];

const PullRequestsCycleTimePanel = () => {
  const preparedChartBoxes = prepareChartBoxes(chartBoxes);
  return <Panel meta={meta} chartBoxes={preparedChartBoxes} />;
};

export default PullRequestsCycleTimePanel;
