import { css } from '@emotion/react';

export const multiTagLabelStyles = ({ spacing, color }) => css`
  min-height: 26px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: ${spacing.gap['0']};
  margin: ${spacing.gap['0']};
  flex: none;
  flex-grow: 0;
  gap: ${spacing.gap['03']};
`;
