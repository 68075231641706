import { css } from '@emotion/react';

export const teamWrapperStyles = (isVisible) => () => css`
  display: ${isVisible ? 'block' : 'none'};
`;

export const teamsMainWrapperStyles = ({ spacing }) => css`
  display: flex;
  flex-direction: column;
  gap: ${spacing.gap['06']};
`;
