import React from 'react';

import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import Panel from '@analytics-components/insights/Panel';
import { prepareChartBoxes } from '@analytics-pages/delivery-pipeline/insights/helper';

import prSizeCycleTime from './prSizeCycleTime';

const meta = {
  title: 'Cycle Time',
  description:
    'Measure the impact of pull request size on your end-to-end delivery speed. <p class="small text-secondary mt-1 mb-0">Calculated up to the 95th percentile.</p>',
};

const chartBoxes = [
  {
    id: 'pr-size-cycle-time',
    component: ChartBoxWithKPIWidget,
    params: prSizeCycleTime,
  },
];

const CycleTimePanel = () => {
  const preparedChartBoxes = prepareChartBoxes(chartBoxes);
  return <Panel meta={meta} chartBoxes={preparedChartBoxes} />;
};

export default CycleTimePanel;
