import { css } from '@emotion/react';

export const footerStyles = ({ color, spacing }) => css`
  width: 100%;
  border-bottom: 1px solid ${color.neutral[40]};
  padding: ${spacing.gap['03']} ${spacing.gap['05']};

  .button-wrapper {
    margin-left: ${spacing.gap['08']};
  }
`;
