import { breakdownOptionsReducer } from '@align-pages/dashboard/reducers/breakdownOptionsReducer';
import { BreakdownOptionsReducerTypes } from '@align-pages/dashboard/reducers/breakdownOptionsReducer.types';
import { chartParamsReducer } from '@align-pages/dashboard/reducers/chartParamsReducer';
import { ChartParamsReducerTypes } from '@align-pages/dashboard/reducers/chartParamsReducerTypes';
import { propertyReducer } from '@align-pages/dashboard/reducers/propertyReducer';
import { PropertyReducerTypes } from '@align-pages/dashboard/reducers/propertyReducer.types';
import { IDashboardStore } from '@align-pages/dashboard/store/dashboard.types';
import { createStore } from '@align-store/helpers/createStore.helper';
import { DashboardChart } from '@common-services/api/private/generated-from-backend/models';

export const useDashboardStore = createStore<IDashboardStore>(
  (set) => ({
    properties: [],
    breakdownOptions: [],
    chartParams: null,
    onAddParam: (propType) => {
      set((state) => ({
        properties: propertyReducer(state.properties, {
          type: PropertyReducerTypes.ADD_NEW,
          paramType: propType,
        }),
      }));
    },
    onAddExisting: (data) => {
      set((state) => ({
        properties: propertyReducer(state.properties, {
          type: PropertyReducerTypes.ADD_EXISTING,
          data,
        }),
      }));
    },
    onUpdateProperty: (data) => {
      set((state) => ({
        properties: propertyReducer(state.properties, {
          type: PropertyReducerTypes.UPDATE,
          data,
        }),
      }));
    },
    onRemoveParam: (data) => {
      set((state) => ({
        properties: propertyReducer(state.properties, {
          type: PropertyReducerTypes.REMOVE,
          data,
        }),
      }));
    },
    onClearParam: (type) => {
      set((state) => ({
        properties: propertyReducer(state.properties, {
          type: PropertyReducerTypes.CLEAR_PARAM,
          paramType: type,
        }),
      }));
    },
    onDropProperties: () => {
      set(() => ({
        properties: [],
      }));
    },
    onBreakdownOptionsInit: (list) => {
      set((state) => ({
        breakdownOptions: breakdownOptionsReducer(state.breakdownOptions, {
          type: BreakdownOptionsReducerTypes.INIT,
          list,
        }),
      }));
    },
    onBreakdownOptionsChange: (value, selected) => {
      set((state) => ({
        breakdownOptions: breakdownOptionsReducer(state.breakdownOptions, {
          type: BreakdownOptionsReducerTypes.CHANGE,
          value,
          selected,
        }),
      }));
    },
    onDropBreakdownOptions: () => {
      set(() => ({
        breakdownOptions: [],
      }));
    },
    onChartParamsInit: (params) => {
      set((state) => ({
        chartParams: chartParamsReducer(state.chartParams, {
          type: ChartParamsReducerTypes.INIT,
          params,
        }),
      }));
    },
    onChartDateUpdate: (
      dateParams: Pick<DashboardChart, 'date_from' | 'date_to' | 'time_interval'>
    ) => {
      set((state) => ({
        chartParams: chartParamsReducer(state.chartParams, {
          type: ChartParamsReducerTypes.DATE_CHANGE,
          dateParams,
        }),
      }));
    },
    onChartFiltersUpdate: (filtersParams) => {
      set((state) => ({
        chartParams: chartParamsReducer(state.chartParams, {
          type: ChartParamsReducerTypes.FILTERS_UPDATE,
          filtersParams,
        }),
      }));
    },
    onChartGroupByUpdate: (groupByParams) => {
      set((state) => ({
        chartParams: chartParamsReducer(state.chartParams, {
          type: ChartParamsReducerTypes.GROUP_BY_UPDATE,
          groupByParams,
        }),
      }));
    },
    onChartParamsDrop: () => {
      set(() => ({
        chartParams: null,
      }));
    },
  }),
  'dashboardStore'
);
