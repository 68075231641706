import { useTheme } from '@emotion/react';
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import { useBreadcrumb } from '@analytics-context/Breadcrumb';
import { useMeasure } from '@analytics-hooks';
import { getFeature, featuresList } from '@analytics-services/flags';
import { useUserContext } from '@common-context/User';
import { Badge } from '@lib/Badge';
import { Icon, icons } from '@lib/icon';
import { slugify } from '@utils/index';

import {
  listItemStyles,
  sectionNameStyles,
  leafSectionHeaderStyles,
  sectionHeaderStyles,
  subsectionsStyles,
  wrapperStyles,
} from './styles';

const PartContext = React.createContext({ name: '' });
const usePartContext = () => useContext(PartContext);

const SidebarContext = React.createContext({
  activeSection: '',
  activeSubsection: '',
  expandedSection: '',
  setExpandedSection: (s) => {},
});
const useSidebar = () => useContext(SidebarContext);

const SectionHeaderContent = ({ name: sectionName, isAlpha, iconName, isActive }) => {
  const theme = useTheme();

  return (
    <div css={{ alignItems: 'center', display: 'flex' }}>
      {iconName && (
        <Icon
          color={isActive ? theme.color.ui.orange[100] : theme.color.neutral[60]}
          icon={icons[iconName]}
          size={20}
        />
      )}
      {!iconName && <span css={(theme) => listItemStyles(theme, isActive)} />}
      <span css={(theme) => sectionNameStyles(theme, iconName, isActive)}>{sectionName}</span>
      {isAlpha && <Badge text="alpha" />}
    </div>
  );
};

const LeafSectionHeader = ({ name: sectionName, isActive, iconName, link }) => (
  <Link
    css={(theme) => leafSectionHeaderStyles(theme, isActive)}
    to={link}
    className={`${isActive ? 'active' : ''}`}
  >
    <SectionHeaderContent name={sectionName} iconName={iconName} isActive={isActive} />
  </Link>
);

const NonLeafSectionHeader = ({
  name: sectionName,
  isAlpha,
  isActive,
  onClick,
  iconName,
  isExpanded,
}) => {
  const theme = useTheme();
  const onClickWrapper = (e) => {
    e.preventDefault();
    onClick();
  };
  return (
    <div css={(theme) => sectionHeaderStyles(theme, iconName, isActive)} onClick={onClickWrapper}>
      <SectionHeaderContent
        name={sectionName}
        isAlpha={isAlpha}
        iconName={iconName}
        isActive={isActive}
      />
      <div>
        <Icon
          color={isActive ? theme.color.neutral[100] : theme.color.neutral[80]}
          icon={isExpanded ? icons.angle_down : icons.angle_right}
          size={iconName ? 12 : 10}
          transitionTime={0.3}
        />
      </div>
    </div>
  );
};

const LeafSection = ({ name: sectionName, isActive, iconName, link }) => {
  return (
    <LeafSectionHeader name={sectionName} isActive={isActive} iconName={iconName} link={link} />
  );
};

const NonLeafSection = ({
  name: sectionName,
  isAlpha,
  isActive,
  iconName,
  subsections = [],
  linkPrefix,
  children,
  parent,
}) => {
  const theme = useTheme();
  const outerRef = useRef(null);
  const [innerRef, { height }] = useMeasure();
  const { activeSection, activeSubsection, expandedSection, setExpandedSection } = useSidebar();
  const [isExpanded, setIsExpanded] = useState(false);

  let ssActiveIndex = -1;
  for (const [ssIndex, s] of subsections.entries()) {
    const ssSlug = slugify(s);
    const ssIsActive = ssSlug === activeSubsection;
    if (ssIsActive) {
      ssActiveIndex = ssIndex;
      break;
    }
  }

  useEffect(() => {
    setIsExpanded(
      !!(
        expandedSection === slugify(sectionName) ||
        (children &&
          children.map((c) => c && slugify(c.props.name)).indexOf(expandedSection) >= 0) ||
        (parent && expandedSection === slugify(parent) && activeSection === slugify(sectionName))
      )
    );
  }, [sectionName]);

  useEffect(() => {
    if (outerRef?.current && innerRef?.current) {
      outerRef.current.style.height = isExpanded ? `${height}px` : 0;
    }
  }, [innerRef, outerRef, isExpanded, height]);

  const onHeaderClick = () => {
    if (!isExpanded) {
      setExpandedSection(slugify(sectionName));
    }
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <NonLeafSectionHeader
        name={sectionName}
        isAlpha={isAlpha}
        iconName={iconName}
        isActive={isActive}
        isExpanded={isExpanded}
        onClick={onHeaderClick}
      />
      <div css={() => wrapperStyles(theme, isExpanded)} ref={outerRef}>
        <div ref={innerRef}>
          {!!subsections.length ? (
            <ul className="nav" css={() => subsectionsStyles(theme, parent)}>
              {subsections.map((s, i) => {
                return (
                  <li key={s} className="nav-item">
                    <Link
                      to={`${linkPrefix}/${slugify(s)}`}
                      className={`nav-link sub-level ${
                        isActive && ssActiveIndex === i ? 'active' : ''
                      }`}
                    >
                      <span css={parent && { marginLeft: theme.spacing.gap['05'] }}>{s}</span>
                      {/* temporary badge for Deploy section, will be eventually removed */}
                      {s === 'Deploy' && (
                        <div className="badge-wrapper">
                          <Badge text="alpha" />
                        </div>
                      )}
                    </Link>
                  </li>
                );
              })}
            </ul>
          ) : (
            <div css={() => subsectionsStyles(theme)}>{children}</div>
          )}
        </div>
      </div>
    </>
  );
};

const Section = ({
  name: sectionName,
  subsections,
  iconName,
  children,
  isAlpha,
  parent,
  index,
}) => {
  const { activeSection } = useSidebar();
  const { name: partName } = usePartContext();
  const partSlug = slugify(partName || '');
  const sectionSlug = slugify(sectionName);
  const isActive =
    sectionSlug === activeSection ||
    (children && children.map((c) => c && slugify(c.props.name)).indexOf(activeSection) >= 0);
  const linkPrefix = `${
    partSlug ? `/analytics/${partSlug}/${sectionSlug}` : `/analytics/${sectionSlug}`
  }`;
  if (subsections || children) {
    return (
      <NonLeafSection
        name={sectionName}
        isAlpha={isAlpha}
        isActive={isActive}
        iconName={iconName}
        subsections={subsections}
        linkPrefix={linkPrefix}
        parent={parent}
        index={index}
      >
        {children}
      </NonLeafSection>
    );
  } else {
    return (
      <LeafSection name={sectionName} isActive={isActive} iconName={iconName} link={linkPrefix} />
    );
  }
};

const Part = ({ name, children }) => (
  <PartContext.Provider
    value={{
      name,
    }}
  >
    {name && (
      <h6 className="small-title font-weight-bold text-xs text-uppercase text-bright m-3 align-middle">
        <span>{name}</span>
      </h6>
    )}
    <ul className="nav flex-column">{children}</ul>
  </PartContext.Provider>
);

const MainPart = () => {
  const { account, features } = useUserContext();
  const visibleCISectionsFeatureFlag = getFeature(featuresList.visible_ci_sections, features);
  const visibleCISectionsCount = visibleCISectionsFeatureFlag?.parameters?.value || 0;

  return (
    <Part>
      <Section name="Velocity" iconName="nav_pipeline">
        <Section
          name="Delivery Pipeline"
          parent="Velocity"
          subsections={['Summary', 'WIP', 'Review', 'Merge', 'Release', 'Deploy']}
        />
        {visibleCISectionsCount > 0 && (
          <Section
            name="CI"
            parent="Velocity"
            subsections={
              visibleCISectionsCount === 1 ? ['Success Ratio'] : ['Run Time', 'Success Ratio']
            }
          />
        )}
      </Section>
      <Section
        name="Quality"
        subsections={[...(!!account.jira ? ['Bugs', 'Resolution'] : []), 'Pull Request Size']}
        iconName="nav_quality"
      />
      <Section
        name="Outcome"
        subsections={['Allocation', ...(account?.jira ? ['Themes', 'New Features'] : [])]}
        iconName="nav_outcome"
      />
    </Part>
  );
};

const TablesPart = () => {
  const { account } = useUserContext();
  return (
    <Part name="Tables">
      <Section name="Pull Requests" iconName="nav_pr" />
      {!!account.jira && (
        <>
          <Section name="Epics" iconName="nav_epic" />
          <Section name="Issues" iconName="nav_issues" />
        </>
      )}
      <Section name="Releases" iconName="nav_release" />
    </Part>
  );
};

const ComparePart = () => {
  return (
    <Part name="Compare">
      <Section name="Teams" iconName="nav_compare" />
    </Part>
  );
};

const LearnPart = () => (
  <Part name="Learn">
    <Section name="Knowledge" iconName="nav_knowledge" />
  </Part>
);

const Sidebar = () => {
  const { section: activeSection, subsection: activeSubsection } = useBreadcrumb();
  const [expandedSection, setExpandedSection] = useState(activeSection);

  return (
    <SidebarContext.Provider
      value={{
        activeSection,
        activeSubsection,
        expandedSection,
        setExpandedSection,
      }}
    >
      <nav className="sidebar-nav">
        <div className="sidebar-sticky">
          <MainPart />
          <TablesPart />
          <ComparePart />
          <LearnPart />
        </div>
      </nav>
    </SidebarContext.Provider>
  );
};

export default Sidebar;
