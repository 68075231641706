import { Breadcrumbs, PrimaryLayoutTabs } from '@align-components/layouts/primary/context.types';
import { CacheService } from '@align-services/cacheService';
import { TeamTree } from '@common-services/api/private/generated-from-backend/models';
import logger from '@common-services/logger';
import { slugify } from '@utils/index';

export interface ITeamNamesMatchProps {
  teams: TeamTree[];
  teamNames: string[];
}

export const teamNamesMatch = ({ teams, teamNames }: ITeamNamesMatchProps): boolean => {
  if (!teamNames.length) return true;
  if (!teams.length) return false;
  const currentTeam = teams.find((team) => team.name.toLowerCase() === teamNames[0].toLowerCase());
  if (!currentTeam) return false;
  return teamNamesMatch({ teams: currentTeam?.children || [], teamNames: teamNames.slice(1) });
};

const invalidateTeamsCache = async () => {
  try {
    await CacheService.resetQuery(['teams']);
    await CacheService.removeQuery(['teams']);
  } catch {
    logger.fatal('Something went wrong while invalidating Teams query cache');
  }
};

export const getRedirectToTeam = (
  team: TeamTree,
  tab: PrimaryLayoutTabs = PrimaryLayoutTabs.DASHBOARD,
  teamsPath?: string
): string => {
  const teamsExist =
    !!teamsPath && teamNamesMatch({ teams: [team], teamNames: teamsPath.split('/') });
  return `/align/${(teamsExist ? teamsPath : false) || team.name.toLowerCase()}/${tab}`;
};

export const getBreadcrumbsAndTeams = (
  path: string,
  section: PrimaryLayoutTabs,
  teams: TeamTree
): [Breadcrumbs, TeamTree[]] | null => {
  const splitPath = path.split('/');
  const splitTeamPath = splitPath.slice(0, -1);
  const lastElement = splitPath.at(-1);
  const tab = isAlignTab(lastElement) ? lastElement : null;
  const depth = splitTeamPath.length;
  const teamPath = getTeamPath(splitTeamPath, teams);

  if (teamPath.length !== depth) {
    invalidateTeamsCache();
    // this is a workaround to not show Align looping on /align URL while RQ cache is being invalidated
    const timerId = setTimeout(() => {
      window.location.href = '/align';
      clearTimeout(timerId);
    }, 3000);
    return [null, null];
  }

  return [
    [
      ...teamPath.map((t, i) => {
        return {
          label: t.name,
          path: `/align/${splitPath.slice(0, i + 1).join('/')}${`/${tab}` || ''}`,
        };
      }),
      { label: section.replace(/(^|\s)\S/g, (c) => c.toUpperCase()) },
    ],
    teamPath,
  ];
};

const getTeamPath = (splitTeamPath: string[], initialNode: TeamTree): TeamTree[] => {
  const teamPath: TeamTree[] = [];
  if (splitTeamPath.length === 0) {
    return teamPath;
  }

  const sp = decodeURIComponent(splitTeamPath.shift());
  if (initialNode && slugify(sp) === slugify(initialNode?.name.toLowerCase())) {
    teamPath.push(initialNode);
    for (let c of initialNode.children || []) {
      teamPath.push(...getTeamPath([...splitTeamPath], c));
    }
  }

  return teamPath;
};

export const isAlignTab = (tab: string): boolean => {
  return [PrimaryLayoutTabs.DASHBOARD, PrimaryLayoutTabs.GOALS, PrimaryLayoutTabs.TEAMS].includes(
    tab as PrimaryLayoutTabs
  );
};

export const validateRoutePath = (url: string): string => {
  return url.charAt(url.length - 1) === '/' ? url.slice(0, -1) : url;
};

export const getTeamsFromPath = (
  path: string,
  initialNode: TeamTree,
  removeSuffix: boolean = true
): TeamTree[] => {
  const splitPath = path.split('/');
  const splitTeamPath = removeSuffix ? splitPath.slice(0, -1) : splitPath;
  return getTeamPath(splitTeamPath, initialNode);
};

export const getCurrentTeam = (
  path: string,
  initialNode: TeamTree,
  removeSuffix: boolean = true
): TeamTree => {
  const teams = getTeamsFromPath(path, initialNode, removeSuffix);
  return teams[teams.length - 1];
};

export * as NavigationService from './navigationService';
