import { BasicApiClass } from '@common-services/api/common/BasicApiClass';
import { REST_TYPES } from '@common-services/api/common/types/apiClient';
import { ApiMode } from '@common-services/api/common/types/common';
import { ISentryMethodParams } from '@common-services/api/common/types/sentry';
import { PUBLIC_API_ENDPOINTS_CONSTANTS } from '@common-services/api/public/constants';
import {
  GetJIRAIssuesRequest,
  GetJIRAIssuesResponse,
} from '@common-services/api/public/generated-from-backend/models';

export class JiraIssuesApi extends BasicApiClass {
  constructor() {
    super(ApiMode.PUBLIC);
  }

  public getPullRequests(
    params: GetJIRAIssuesRequest,
    token: string,
    sentry?: ISentryMethodParams
  ): Promise<GetJIRAIssuesResponse> {
    this.assignToken(token);
    const callApi = this.callApi<GetJIRAIssuesResponse>(
      PUBLIC_API_ENDPOINTS_CONSTANTS.GET_JIRA_ISSUES,
      REST_TYPES.POST,
      params
    );
    return sentry ? this.callSentry<GetJIRAIssuesResponse>(callApi, sentry) : callApi;
  }
}
