import {
  IFilteredPRs,
  IPrsDeployments,
  IPrsUsers,
} from '@common-services/api/public/types/pullRequests';
import { github } from '@common-services/format';
import { isInStage, prLabel, PR_STAGE } from '@common-services/prHelpers';
import { uniqBy } from '@common-services/vendor/lodash';
import { IPrsTableRow } from '@lib/Table/components/pr-table/prTable.types';

const getEnvironment = (prDeployments: string[], allDeployments: IPrsDeployments): string => {
  if (!prDeployments?.length) return '';
  const prod = prDeployments.find((d) => allDeployments[d].environment === 'production');
  if (prod) return allDeployments[prod].environment;
  return prDeployments[0] ? allDeployments[prDeployments[0]].environment : '';
};

export const getAllPrs = (data: IFilteredPRs[], stage: PR_STAGE): IPrsTableRow[] => {
  const allUsers: IPrsUsers = data.reduce((acc, d) => ({ ...acc, ...d.users }), {});
  const allDeployments: IPrsDeployments = data.reduce(
    (acc, d) => ({ ...acc, ...d.deployments }),
    {}
  );
  return uniqBy(
    data
      .flatMap((d) => d.prs)
      .filter(stage === PR_STAGE.SUMMARY ? () => true : (pr) => isInStage(pr, stage))
      .map((pr) => {
        const environment = getEnvironment(pr.deployments, allDeployments);
        return {
          ...pr,
          id: `${pr.repository}-${pr.number}`,
          author: pr.authors[0],
          authorAvatar: allUsers[pr.authors[0]]?.avatar || null,
          reviewer: pr.reviewers.map((r) => github.userName(r)),
          reviewersAvatars: pr.reviewers.map((r) => ({
            avatar: allUsers[r]?.avatar || null,
            userName: github.userName(r),
          })),
          cycleTime:
            stage === PR_STAGE.SUMMARY
              ? Object.values(pr.filtered_stage_timings).reduce((a, b) => a + b)
              : stage === PR_STAGE.DEPLOY
              ? pr.deploy_timings[environment]
              : pr.filtered_stage_timings[stage],
          stage,
          statusLabel: prLabel(stage)(pr),
          environment,
        };
      })
      .sort((a, b) => (a.updated < b.updated ? 1 : -1)),
    'id'
  );
};

export const getFirstPagePrs = (data: IFilteredPRs[], stage: PR_STAGE): IPrsTableRow[] => {
  return data[0].prs
    .filter(stage === PR_STAGE.SUMMARY ? () => true : (pr) => isInStage(pr, stage))
    .map((pr) => {
      const environment = getEnvironment(pr.deployments, data[0].deployments);
      return {
        ...pr,
        author: pr.authors[0],
        authorAvatar: data[0].users[pr.authors[0]]?.avatar || null,
        reviewer: pr.reviewers.map((r) => github.userName(r)),
        reviewersAvatars: pr.reviewers.map((r) => ({
          avatar: data[0].users[r]?.avatar || null,
          userName: github.userName(r),
        })),
        cycleTime:
          stage === PR_STAGE.SUMMARY
            ? Object.values(pr.filtered_stage_timings).reduce((a, b) => a + b)
            : stage === PR_STAGE.DEPLOY
            ? pr.deploy_timings[environment]
            : pr.filtered_stage_timings[stage],
        stage,
        statusLabel: prLabel(stage)(pr),
        environment,
      };
    })
    .sort((a, b) => (a.updated < b.updated ? 1 : -1))
    .slice(0, 10);
};
