import { css } from '@emotion/react';

const getHeaderStyle = ({ spacing, color, radius, font }) => css`
  cursor: pointer;
  display: flex;
  padding: ${spacing.gap['04']};
  background: ${color.neutral['20']};
  color: ${color.neutral['100']};
  height: 60px;
  align-items: center;
  align-content: center;
  font-size: ${font.size.default};
  border-radius: ${radius.default};
  border-bottom: 1px solid ${color.neutral['40']};

  & > div:first-of-type {
    padding: ${spacing.gap['03']};
  }
`;

const getContentStyle = () => css`
  position: absolute;
  width: 100%;
`;

export { getHeaderStyle, getContentStyle };
