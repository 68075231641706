import { BasicApiClass } from '@common-services/api/common/BasicApiClass';
import { REST_TYPES } from '@common-services/api/common/types/apiClient';
import { ApiMode } from '@common-services/api/common/types/common';
import { ISentryMethodParams } from '@common-services/api/common/types/sentry';
import { PUBLIC_API_ENDPOINTS_CONSTANTS } from '@common-services/api/public/constants';
import {
  FilteredEnvironment,
  FilterEnvironmentsRequest,
} from '@common-services/api/public/generated-from-backend/models';

export class EnvironmentsApi extends BasicApiClass {
  constructor() {
    super(ApiMode.PUBLIC);
  }
  public fetchEnvironments(
    body: FilterEnvironmentsRequest,
    token: string,
    sentry?: ISentryMethodParams
  ): Promise<FilteredEnvironment[]> {
    this.assignToken(token);
    const callApi = this.callApi<FilteredEnvironment[]>(
      PUBLIC_API_ENDPOINTS_CONSTANTS.ENVIRONMENTS,
      REST_TYPES.POST,
      body
    );
    return sentry ? this.callSentry<FilteredEnvironment[]>(callApi, sentry) : callApi;
  }
}
