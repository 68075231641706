import { motion } from 'framer-motion';
import React from 'react';

const MotionWrapper: React.FC = React.memo(({ children }) => (
  <motion.div
    initial={{
      opacity: 0,
      height: '0%',
    }}
    animate={{
      opacity: 1,
      height: '100%',
    }}
    exit={{
      opacity: 0,
      height: '0%',
    }}
  >
    {children}
  </motion.div>
));

export { MotionWrapper };
