export enum FontSize {
  xxxl = '3.6rem', // 36px
  xxl = '3rem', // 30px
  xl = '2.4rem', // 24px
  l = '2.1rem', // 21px
  m = '1.6rem', // 16px
  s = '1.4rem', // 14px
  default = '1.2rem', // 12px
  xs = '1rem', // 10px
  xxs = '0.8rem', // 8px
}
