import { useMutation } from 'react-query';

import { IMutationBasic, IUseMutationHook } from '@align-types/common';
import { useAuth0 } from '@common-context/Auth0';
import { useUserContext } from '@common-context/User';
import { useDemoCookie } from '@common-pages/Demo';
import { IApiBasicError } from '@common-services/api/common/types/common';
import { GoalCreateRequest } from '@common-services/api/private/generated-from-backend/models';
import { CreatedIdentifier } from '@common-services/api/public/generated-from-backend/models';

import { AlignGoalsServices } from '../services/alignGoals';

export function useCreateGoal(
  params: IMutationBasic
): IUseMutationHook<CreatedIdentifier, IApiBasicError, GoalCreateRequest> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();
  const createGoal = async (input: GoalCreateRequest): Promise<CreatedIdentifier> => {
    const token: string = isDemo === false ? await getTokenSilently() : null;
    return AlignGoalsServices.createGoal({ ...input, account: params.accountId }, token);
  };

  const { mutate, isLoading, data, error, isError, isSuccess } = useMutation<
    CreatedIdentifier,
    IApiBasicError,
    GoalCreateRequest
  >(createGoal);

  return { mutate, isLoading, data, error, isError, isSuccess };
}

export function useCreateGoalOwnedTeam(): IUseMutationHook<
  CreatedIdentifier,
  IApiBasicError,
  Partial<GoalCreateRequest>
> {
  const {
    user: {
      defaultAccount: { id: accountId },
    },
  } = useUserContext();
  return useCreateGoal({ accountId });
}
