import { css } from '@emotion/react';

export const styles = (withTextAvatar) => ({ spacing, radius, color }) => css`
  & > div {
    ${
      withTextAvatar &&
      `
      border: 2px solid ${color.neutral.white};
      border-radius: ${radius.circle};
    `
    }
    margin: ${spacing.gap['0']};
  }
`;

export const avatarTextStyles = (size) => ({ color, font }) => css`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${color.neutral.white};
  font-size: ${size * 0.4}px;
  font-weight: ${font.weight.bold};
  text-transform: capitalize;
`;
