import { css } from '@emotion/react';

const getBreadcrumbWrapperStyle = ({ spacing }) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing.gap['06']};
`;

export { getBreadcrumbWrapperStyle };
