import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Badge, BadgeColorType } from '@lib/Badge';

import { FilterDropdownBoxItem } from '../filter-dropdown-box-item/filterDropdownBoxItem';
import { boxItemsStyles } from './filterDropdownBoxItems.styles';
import { IFilterDropdownBoxItems } from './filterDropdownBoxItems.types';

export const FilterDropdownBoxItems: React.FC<IFilterDropdownBoxItems> = React.memo(
  ({ isAllSelected, allSelectedLabel, options, items, label, badge }) => {
    const optionsObject = useMemo(
      () =>
        options.reduce(
          (accumulator, current) => ({
            ...accumulator,
            [current.id]: current,
          }),
          {}
        ),
      [options]
    );

    return (
      <div css={boxItemsStyles}>
        <div className="content-block">
          {label && !isAllSelected && items.length === 0 && (
            <div className="placeholder">{label}</div>
          )}
          {isAllSelected ? (
            <div className="placeholder">{allSelectedLabel}</div>
          ) : (
            items?.map((id, index) => {
              const comma = !optionsObject[id]?.image && index < items.length - 1 ? ',' : '';
              return (
                <div className="box-items" key={uuidv4()}>
                  <FilterDropdownBoxItem
                    data={{
                      ...optionsObject[id],
                      name: (optionsObject[id]?.name || optionsObject[id]?.subName) + comma,
                    }}
                  />
                </div>
              );
            })
          )}
        </div>
        {badge && (
          <div className="badge-block">
            <Badge text={badge} type={BadgeColorType.TURQUOISE} />
          </div>
        )}
      </div>
    );
  }
);
