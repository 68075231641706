import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';

import { useApi } from '@analytics-hooks';
import { setWorkType } from '@analytics-services/api';
import { useUserContext } from '@common-context/User';
import { useOwnedLabelsFilter } from '@common-services/api/public/hooks/useFiltersData';

import GithubLabel from '.';

const GithubLabelDataBinder = ({ rule, workType }) => {
  const { api, ready: apiReady, context } = useApi(true, false);
  const {
    user: {
      defaultReposet: { repos },
    },
  } = useUserContext();

  const { data: labelsData } = useOwnedLabelsFilter({ repositories: repos, account: null }, true);
  const labels = useMemo(() => {
    return labelsData
      ? _(labelsData)
          .uniqBy((item) => item.name.toLowerCase())
          .map((item) => ({ label: item.name, value: item.name }))
          .sort((a, b) => (a.value > b.value ? 1 : -1))
          .value()
      : [];
  }, [labelsData]);

  const handleChange = useCallback(
    (value) => {
      if (!apiReady) return;
      const modifiedRule = workType.rules.find((item) => item.name === rule.name);
      modifiedRule.body.labels = value;
      setWorkType(api, context.account, workType.name, workType.color, workType.rules);
    },
    [apiReady, context, workType]
  );

  return (
    <GithubLabel allLabels={labels} initialValue={rule?.body?.labels} onChange={handleChange} />
  );
};

export default GithubLabelDataBinder;
