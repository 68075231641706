import { css } from '@emotion/react';

export const titleStyles = ({ color, font }) => css`
  display: flex;
  width: 243px;
  white-space: nowrap;

  @media (min-width: 1300px) {
    width: 420px;
  }

  @media (min-width: 1400px) {
    width: 450px;
  }

  .repo-name {
    color: ${color.text.secondary};
    font-size: ${font.size.s};
    font-weight: ${font.weight.regular};
  }

  .pr-text {
    color: ${color.text.dark};
    font-size: ${font.size.s};
    font-weight: ${font.weight.bold};
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      text-decoration: underline !important;
    }
  }
`;

export const detailsStyles = ({ color, font, spacing }) => css`
  align-items: center;
  display: flex;

  .user-avatar {
    height: 22px;
    margin-right: ${spacing.gap['02']};
    width: 22px;
  }

  .details-text {
    color: ${color.text.secondary};
    font-size: ${font.size.xs};
    font-weight: ${font.weight.regular};

    .reporter {
      color: ${color.text.dark};
    }
  }
`;
