import { Column } from 'react-table';
import { v4 as uuidv4 } from 'uuid';

import { IReleasesPrRow } from '@analytics-pages/Tables/releases/releases.types';
import { number, renderAvatars } from '@common-services/format';
import { Icon, icons } from '@lib/icon';
import { theme } from '@styles/theme';

import { ReleasesCellTitle } from '../cell-title/cellTitle';
import { badgeStyles, columnStyles } from './releasesTable.styles';

export const columns: Column<IReleasesPrRow>[] = [
  {
    Header: 'Releases',
    accessor: 'published',
    Cell: ReleasesCellTitle,
  },
  {
    Header: 'Pull Requests',
    accessor: (row) => row.prs?.length || 0,
    Cell: ({ row }) =>
      row.depth === 0 ? (
        <div css={columnStyles}>
          <Icon color={theme.color.ui.turquoise['100']} icon={icons.nav_pr} size={16} />
          <span className="prs-count">{row.original.prs?.length || 0}</span>
        </div>
      ) : null,
  },
  {
    Header: 'Size',
    accessor: (row) => row.added_lines + row.deleted_lines,
    Cell: ({ row }) => (
      <div css={columnStyles}>
        <span className="size-added">
          +{number.si(row.depth > 0 ? row.original.additions : row.original.added_lines)}
        </span>
        <span className="size-removed">
          -{number.si(row.depth > 0 ? row.original.deletions : row.original.deleted_lines)}
        </span>
      </div>
    ),
  },
  {
    Header: 'Contributors',
    accessor: (row) => row.contribs.length,
    Cell: ({ row }) => (
      <>
        {renderAvatars(
          row.original.contribsAvatars,
          null,
          // @ts-ignore Ignoring TS error because of rudimental renderAvatars method
          () => (contrib) => (
            <img
              key={uuidv4()}
              src={contrib.avatar}
              className="pr-user-avatar"
              alt={contrib.userName}
              title={contrib.userName}
              onError={(e) => {
                (e.target as HTMLImageElement).src =
                  'https://avatars2.githubusercontent.com/u/10137';
              }}
            />
          ),
          (count) => (
            <span key="count" className="pr-user-avatar count">
              +{count}
            </span>
          )
        )}
      </>
    ),
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ row }) => (
      <>
        {/* TODO: Replace this with a reusable component */}
        <div css={badgeStyles} className="badge badge-outlined label-latest-release">
          {row.original.status}
        </div>
      </>
    ),
  },
];
