import React from 'react';

import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import Panel from '@analytics-components/insights/Panel';
import { prepareChartBoxes } from '@analytics-pages/delivery-pipeline/insights/helper';

import reviewActivityBubble from './reviewActivityBubble';
import reviewActivityHorizontalBar from './reviewActivityHorizontalBar';

export const [REVIEWED, CREATED, COMMENTS] = [0, 1, 2];

const meta = {
  title: 'Review Activity',
  description: 'Understand the role of each team member in the review process.',
};

const chartBoxes = [
  {
    id: 'review-activity-bubble',
    component: ChartBoxWithKPIWidget,
    params: reviewActivityBubble,
  },
  {
    id: 'review-activity-horizontal-bar',
    component: ChartBoxWithKPIWidget,
    params: reviewActivityHorizontalBar,
  },
];

const ReviewActivityPanel = () => {
  const preparedChartBoxes = prepareChartBoxes(chartBoxes);
  return <Panel meta={meta} chartBoxes={preparedChartBoxes} />;
};

export default ReviewActivityPanel;
