import { css } from '@emotion/react';

export const wrapperStyles = ({ spacing, radius, color, shadow, font }) => css`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  white-space: nowrap;

  .option-image {
    border-radius: ${radius.circle};
    border: 2px solid ${color.neutral.white};
    box-shadow: ${shadow.full};
    color: ${color.neutral.white};
    font-size: ${font.size.xxs};
    height: 22px;
    line-height: ${font.height.s};
    margin-right: ${spacing.gap['02']};
    text-align: center;
    width: 22px;
  }
`;
