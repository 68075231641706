const pageToDataIds = {
  'prs-metrics': {
    'accessor': (obj) => Object.keys(obj.values.all),
    'delivery-pipeline': {
      summary: [
        'lead-time',
        'lead-count',
        'lead-count-q',
        'all-count',
        'cycle-time',
        'wip-time',
        'wip-count',
        'wip-count-q',
        'wip-pending-count',
        'review-time',
        'review-count',
        'review-count-q',
        'review-pending-count',
        'merging-time',
        'merging-count',
        'merging-count-q',
        'merging-pending-count',
        'release-time',
        'release-count',
        'release-count-q',
        'release-pending-count',
        'rejected',
        'opened',
      ],
      wip: [
        'lead-time',
        'lead-count',
        'all-count',
        'cycle-time',
        'wip-time',
        'wip-count',
        'wip-count-q',
        'wip-pending-count',
        'review-time',
        'review-count',
        'review-count-q',
        'review-pending-count',
        'merging-time',
        'merging-count',
        'merging-count-q',
        'merging-pending-count',
        'release-time',
        'release-count',
        'release-count-q',
        'release-pending-count',
        'rejected',
        'opened',
        'closed',
        'flow-ratio',
      ],
      review: [
        'lead-time',
        'lead-count',
        'all-count',
        'cycle-time',
        'wip-time',
        'wip-count',
        'wip-count-q',
        'wip-pending-count',
        'review-time',
        'review-count',
        'review-count-q',
        'review-pending-count',
        'merging-time',
        'merging-count',
        'merging-count-q',
        'merging-pending-count',
        'release-time',
        'release-count',
        'release-count-q',
        'release-pending-count',
        'rejected',
        'reviewed',
        'not-reviewed',
        'reviews-per',
        'participants-per',
        'review-comments-per',
        'wait-first-review-time',
      ],
      merge: [
        'lead-time',
        'lead-count',
        'all-count',
        'cycle-time',
        'wip-time',
        'wip-count',
        'wip-count-q',
        'wip-pending-count',
        'review-time',
        'review-count',
        'review-count-q',
        'review-pending-count',
        'merging-time',
        'merging-count',
        'merging-count-q',
        'merging-pending-count',
        'release-time',
        'release-count',
        'release-count-q',
        'release-pending-count',
        'rejected',
        'merged',
      ],
      release: [
        'lead-time',
        'lead-count',
        'all-count',
        'cycle-time',
        'wip-time',
        'wip-count',
        'wip-count-q',
        'wip-pending-count',
        'review-time',
        'review-count',
        'review-count-q',
        'review-pending-count',
        'merging-time',
        'merging-count',
        'merging-count-q',
        'merging-pending-count',
        'release-time',
        'release-count',
        'release-count-q',
        'release-pending-count',
        'rejected',
      ],
      deploy: [
        'lead-time',
        'lead-count',
        'all-count',
        'cycle-time',
        'wip-time',
        'wip-count',
        'wip-count-q',
        'wip-pending-count',
        'review-time',
        'review-count',
        'review-count-q',
        'review-pending-count',
        'merging-time',
        'merging-count',
        'merging-count-q',
        'merging-pending-count',
        'release-time',
        'release-count',
        'release-count-q',
        'release-pending-count',
        'rejected',
        'deployment-time',
        'deployment-count',
        'deployment-count-q',
      ],
    },
    'quality': {
      'bugs': ['size'],
      'resolution': ['size'],
      'pull-request-size': [
        'median-size',
        'size',
        'reviewed',
        'not-reviewed',
        'review-comments-per',
        'opened',
      ],
    },
    'outcome': {
      'allocation': ['lead-count', 'done-mapped-to-jira'],
      'themes': ['lead-count', 'done-mapped-to-jira'],
      'new-features': ['lead-count', 'done-mapped-to-jira'],
    },
  },
  'repos-metrics': {
    'accessor': (obj) => Object.keys(obj[Object.keys(obj)[0]]),
    'delivery-pipeline': {
      summary: ['opened', 'reviewed', 'merged', 'release-count', 'lead-time'],
      wip: ['opened', 'reviewed', 'merged', 'release-count'],
      review: ['opened', 'reviewed', 'merged', 'release-count', 'not-reviewed', 'review-time'],
      merge: ['opened', 'reviewed', 'merged', 'release-count'],
      release: ['opened', 'reviewed', 'merged', 'release-count'],
    },
  },
};

export default pageToDataIds;
