export enum StorageServiceType {
  LOCAL = 'local',
  SESSION = 'session',
}

export enum StorageTarget {
  RESPONSE_HEALTH_LOGS = 'response_health_logs',
}

interface IGetItem {
  target: StorageTarget;
  type: StorageServiceType;
}

interface ISetItem extends IGetItem {
  value: string;
}

export const setItem = ({ target, type, value }: ISetItem): void => {
  switch (type) {
    case StorageServiceType.SESSION:
      sessionStorage.setItem(target, value);
      break;
    case StorageServiceType.LOCAL:
      localStorage.setItem(target, value);
      break;
  }
};

export const getItem = ({ target, type }: IGetItem): string => {
  switch (type) {
    case StorageServiceType.SESSION:
      return sessionStorage.getItem(target);
    case StorageServiceType.LOCAL:
      return localStorage.getItem(target);
    default:
      return null;
  }
};

export const removeItem = ({ target, type }: IGetItem): void => {
  switch (type) {
    case StorageServiceType.LOCAL:
      localStorage.removeItem(target);
      break;
    case StorageServiceType.SESSION:
      sessionStorage.removeItem(target);
      break;
  }
};

export * as StorageService from './storageService';
