import _ from 'lodash';

import { fetchJIRAMetrics } from '@analytics-services/api/jira';
import { FAKE_USERNAME } from '@common-pages/Settings/constants';
import { dateTime } from '@common-services/dateService';
import { theme } from '@styles/theme';
import { FlatProgressBarMulti } from '@ui/ProgressBar';

const mttRepairByPriority = {
  fetcher: async (api, cachedData, apiContext) => {
    const {
      account,
      interval,
      excludeInactive,
      contributors,
      includeNullContributor,
      includeFakeContributor,
      epics,
    } = apiContext;

    const jiraAssignees = _(contributors).map('jira_user').filter().value();
    if (includeNullContributor) {
      jiraAssignees.push(null);
    }
    if (includeFakeContributor) {
      jiraAssignees.push(FAKE_USERNAME);
    }

    const fetch = async (priority) => {
      const mtta = await fetchJIRAMetrics(
        api,
        account,
        ['all'],
        interval,
        ['resolved', 'lead-time'],
        excludeInactive,
        {
          types: ['Bug'],
          priorities: [priority.name],
          epics,
        },
        { assignees: jiraAssignees }
      );

      return {
        color: priority.color,
        count: mtta[0].values[0].values[0],
        label: priority.name,
        time: mtta[0].values[0].values[1],
      };
    };

    const filteredJIRAStuff = cachedData['jira-priorities-all'];
    const priorities = filteredJIRAStuff.priorities.map((p, index) => ({
      color: theme.color.sets.bugs[index],
      name: p.name,
    }));

    const mttrByPriority = await Promise.all(
      _(priorities)
        .map((p) => fetch(p))
        .value()
    );

    return { mttrByPriority };
  },
  plumber: (fetchedData, cachedData, apiContext) => {
    const { mttrByPriority } = fetchedData;
    const params = _(mttrByPriority)
      .filter((v) => v.time)
      .map((v) => ({
        value: v.time,
        color: v.color,
        labelLeft: `${v.label} (${v.count})`,
        labelRight: v.time > 0 ? dateTime.human(v.time * 1000) : '',
      }))
      .value();

    return {
      empty: mttrByPriority.length === 0,
      chart: {
        component: FlatProgressBarMulti,
        params: {
          params,
          normalize: true,
        },
      },
      kpis: [],
    };
  },
  prefetchedDataIds: ['jira-priorities-all'],
};

export default mttRepairByPriority;
