import React, { useMemo } from 'react';

import { metricsConfig } from '@align-constants';
import { GoalsSingleComponentServices } from '@align-pages/goals/single/components/goals-single-component';
import { MetricService } from '@align-services/metricService';
import { Chart, ChartTypeEnum, ColumnWithPlotBandService, IChartSeriesItem } from '@lib/chart';

import { IBarChart } from './barChart.types';

const BarChart: React.FC<IBarChart> = React.memo(({ goal, templateMetric, onSelect }) => {
  const thresholdReadable = useMemo(
    () =>
      goal &&
      templateMetric &&
      MetricService.getReadableValue(
        GoalsSingleComponentServices.deNormaliseMetricValue(goal?.metric_params?.threshold),
        metricsConfig[templateMetric].valueType,
        metricsConfig[templateMetric].unit
      ),
    [goal, templateMetric]
  );
  const options = useMemo(
    () =>
      ColumnWithPlotBandService.getOptions({
        startDate: goal?.valid_from,
        endDate: goal?.expires_at,
        better: metricsConfig[templateMetric]?.better,
        series: (goal?.team_goal?.value?.series || []) as IChartSeriesItem[],
        targetValue: parseFloat(goal?.team_goal?.value?.target.toString()),
        threshold: MetricService.getUnitSingularity(thresholdReadable, templateMetric),
        unit: null,
        onSelect,
      }),
    [goal, templateMetric]
  );

  return <Chart chartType={ChartTypeEnum.PLOT_BAND_COLUMN} options={options} />;
});

export { BarChart };
