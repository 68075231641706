import { useLocalStorage } from '@rehooks/local-storage';
import _ from 'lodash';
import React, { useCallback, useContext, useReducer, useRef } from 'react';

import { nullUser } from '@analytics-components/filters/UserMultiSelect';
import { useDataHelper } from '@analytics-context/Data';
import { useMountEffect } from '@analytics-hooks';
import { buildApi, getContributors, getLabels, getRepos, getTeams } from '@analytics-services/api';
import { filterJIRAStuff } from '@analytics-services/api/jira';
import { datetime, DatetimeService } from '@analytics-services/datetimeService';
import { isFeatureEnabled, getFeature, featuresList } from '@analytics-services/flags';
import { useAuth0 } from '@common-context/Auth0';
import { useUserContext } from '@common-context/User';
import { ArrayService } from '@common-services/arrayService';

import {
  initOptions,
  setAppliedContribs,
  setAppliedEpics,
  setAppliedIssueTypes,
  setAppliedLabels,
  setAppliedRepos,
  setChanging,
  setContribsOptions,
  setDateInterval,
  setDone,
  setExcludeInactive,
  setLabelsOptions,
  setReposOptions,
} from './actions';
import { defaultFilter, filterReducer } from './filterReducer';
import { IAction, IContext, IFilterState, IGetReposOptions, SubFilter } from './types';

export const filtersStorageKeys: {
  [key: string]: (account: number) => string;
} = {
  REPOSITORIES: (account) => `filter.repositories.${account}`,
  CONTRIBUTORS: (account) => `filter.contributors.${account}`,
  CONTRIBUTOR_TEAMS: (account) => `filter.contributor_teams.${account}`,
  LABELS: (account) => `filter.labels.${account}`,
  DATE_RANGE: (account) => `filter.date_range.${account}`,
  WORK_TYPES: (account) => `filter.work_types.${account}`,
  OMNIBOX: (account) => `filter.omnibox.${account}`,
  ALLOCATION_CONTENT_TYPE: (account) => `switch.allocation_content_type.${account}`,
};

const Context = React.createContext<IContext>({} as IContext);
const useFilters = () => useContext(Context);

const FiltersProvider = ({ children }) => {
  const { getTokenSilently } = useAuth0();
  const tokenRef = useRef(null);

  const {
    user: {
      defaultAccount: { id: accountID },
      defaultReposet: { repos: contextRepos },
    },
    account,
    isDemo,
    isGod,
    features,
  } = useUserContext();
  const { reset: resetData, set: setData } = useDataHelper();

  const [lastDateInterval] = useLocalStorage<DatetimeService.RawInterval>(
    filtersStorageKeys.DATE_RANGE(accountID)
  );
  const defaultDateInterval = DatetimeService.interval(
    lastDateInterval
      ? datetime(lastDateInterval.from, true)
      : isDemo
      ? DatetimeService.Presets.SIX_MONTHS_AGO
      : DatetimeService.Defaults.FROM,
    lastDateInterval ? datetime(lastDateInterval.to, true) : DatetimeService.Defaults.TO
  );

  const [lastLabels] = useLocalStorage<any[]>(filtersStorageKeys.LABELS(accountID));
  const defaultLabels = lastLabels || [];

  const [filterData, dispatchFilter] = useReducer<React.Reducer<IFilterState, IAction>>(
    filterReducer,
    {
      ...defaultFilter,
      dateInterval: defaultDateInterval,
      labels: {
        ...defaultFilter[SubFilter.LABELS],
        applied: {
          values: defaultLabels,
          ready: true,
        },
      },
    }
  );

  const appliedContribs =
    filterData[SubFilter.CONTRIBS].applied.values.length > 0
      ? filterData[SubFilter.CONTRIBS].applied.values
      : filterData[SubFilter.CONTRIBS].options.values;

  const nonNullAppliedContribs = _(appliedContribs)
    .filter((c) => c.name !== nullUser.name)
    .value();
  const hasAppliedNullContrib = appliedContribs.length > nonNullAppliedContribs.length;
  const hasAppliedBotContrib = !!appliedContribs.find((c) => c.team === 'Bots');

  const all_repos_as_filter_options_ff = getFeature(
    featuresList.all_repos_as_filter_options,
    features
  );
  const all_repos_as_filter_options_enabled =
    !!all_repos_as_filter_options_ff &&
    (!all_repos_as_filter_options_ff.parameters.god_only || isGod);
  const getReposOptions = async (params: IGetReposOptions) => {
    return all_repos_as_filter_options_enabled ? contextRepos : await getReposForFilter(params);
  };

  useMountEffect(() => {
    const getContributors = async (teams) => {
      if (isFeatureEnabled(featuresList.filter_teams_mandatory, features)) {
        if (teams.length === 0) return [];

        const allContribs = await getContribsForFilter(
          tokenRef.current,
          accountID,
          filterData.dateInterval,
          contextRepos,
          filterData.excludeInactive
        );
        const inTeams = _(teams).flatMap('members').map('login').uniq().value();
        return _(allContribs)
          .filter((c) => inTeams.includes(c.login))
          .value();
      }

      return await getContribsForFilter(
        tokenRef.current,
        accountID,
        filterData.dateInterval,
        contextRepos,
        filterData.excludeInactive
      );
    };

    (async () => {
      tokenRef.current = isDemo ? null : await getTokenSilently();

      const reposLabelsPromise = getReposOptions({
        token: tokenRef.current,
        accountID,
        dateInterval: filterData.dateInterval,
        repos: contextRepos,
        excludeInactive: filterData.excludeInactive,
      }).then(async (repos) => {
        const labels = _(await getLabels(tokenRef.current, accountID, repos))
          .orderBy(['used_prs'], ['desc'])
          .value();
        return { repos, labels };
      });

      const teamsContribsPromise = getTeamsForFilter(tokenRef.current, accountID).then(
        async (teams) => {
          const contribs = await getContributors(teams);
          return { teams, contribs };
        }
      );

      // TODO: Move this logic to Prefetcher to fetch and cache avatars there too
      const [{ repos, labels }, { teams, contribs }] = await Promise.all([
        reposLabelsPromise,
        teamsContribsPromise,
      ]);

      const epics = account.jira
        ? _(
            (
              await filterJIRAStuff(
                buildApi(tokenRef.current),
                accountID,
                filterData.dateInterval,
                filterData.excludeInactive,
                ['epics']
              )
            ).epics
          )
            .orderBy(['prs', 'updated'], ['desc', 'desc'])
            .value()
        : [];

      const issueTypes = account.jira
        ? _(
            (
              await filterJIRAStuff(
                buildApi(tokenRef.current),
                accountID,
                filterData.dateInterval,
                filterData.excludeInactive,
                ['issues', 'issue_types']
              )
            ).issue_types
          )
            .values()
            .orderBy(['count'], ['desc'])
            .map((v) => ({
              image: v.image,
              label: v.name,
              value: v.normalized_name,
            }))
            .value()
        : [];

      setData(
        'avatars',
        _(contribs)
          .map((c) => [c.login, c.avatar])
          .fromPairs()
          .value(),
        true
      );
      const contribsAuthors = _(contribs).concat(nullUser).value();
      dispatchFilter(
        initOptions({ repos, contribs: contribsAuthors, teams, labels, epics, issueTypes })
      );
    })();
  });

  const onReposInit = async (selected) => dispatchFilter(setAppliedRepos(selected));
  const onContribsInit = async (selected) => dispatchFilter(setAppliedContribs(selected));
  const onOmniboxInit = async (selected) => {
    const selectedGhLabels = _(selected)
      .filter((label) => label.labelType === 'github')
      .value();
    const selectedJiraEpics = _(selected)
      .filter((label) => label.labelType === 'jira')
      .value();
    const selectedIssueTypes = _(selected)
      .filter((label) => label.labelType === 'issueType')
      .value();
    dispatchFilter(setAppliedLabels(selectedGhLabels));
    dispatchFilter(setAppliedEpics(selectedJiraEpics));
    dispatchFilter(setAppliedIssueTypes(selectedIssueTypes));
  };

  const onDateIntervalChange = useCallback(
    async (selectedDateInterval: DatetimeService.Interval) => {
      dispatchFilter(setChanging());
      resetData();

      const { updatedRepos, updatedContribs, labels } = await getReposOptions({
        token: tokenRef.current,
        accountID,
        dateInterval: selectedDateInterval,
        repos: [],
        excludeInactive: filterData.excludeInactive,
      }).then(async (updatedRepos) => {
        const [updatedContribs, labels] = await Promise.all([
          getContribsForFilter(
            tokenRef.current,
            accountID,
            selectedDateInterval,
            updatedRepos,
            filterData.excludeInactive
          ),
          getLabels(tokenRef.current, accountID, updatedRepos),
        ]);
        return { updatedRepos, updatedContribs, labels };
      });

      setData(
        'avatars',
        _(updatedContribs)
          .map((c) => [c.login, c.avatar])
          .fromPairs()
          .value(),
        true
      );

      const contribsAuthors = _(updatedContribs).concat(nullUser).value();
      dispatchFilter(setContribsOptions(contribsAuthors));

      const oldAppliedContribs = filterData.contribs.applied.values;
      const newAppliedContribs = oldAppliedContribs.filter((c) =>
        contribsAuthors.find((a) => (!a.login && a.name === c.name) || c.login === a.login)
      );
      dispatchFilter(setAppliedContribs(newAppliedContribs));

      dispatchFilter(setExcludeInactive(filterData.excludeInactive));
      dispatchFilter(setDateInterval(selectedDateInterval));

      const oldOptionsRepos = filterData.repos.options.values;
      const oldAppliedRepos = filterData.repos.applied.values;
      const newOptionsRepos = ArrayService.getUniqueValues(
        updatedRepos.concat(oldAppliedRepos)
      ).sort();
      const newAppliedRepos =
        oldOptionsRepos.length === oldAppliedRepos.length ? newOptionsRepos : oldAppliedRepos;

      dispatchFilter(setReposOptions(newOptionsRepos));
      dispatchFilter(setAppliedRepos(newAppliedRepos));
      dispatchFilter(setLabelsOptions(labels));

      dispatchFilter(setDone());
    },
    [accountID, filterData]
  );

  const onReposApplyChange = useCallback(
    async (selectedRepos: string[], doNothing: boolean) => {
      // See notes in `onContribsApplyChange`.
      if (doNothing) return;

      dispatchFilter(setChanging());
      resetData();

      const [contribs, labels] = await Promise.all([
        getContribsForFilter(
          tokenRef.current,
          accountID,
          filterData.dateInterval,
          contextRepos,
          filterData.excludeInactive
        ),
        getLabels(tokenRef.current, accountID, selectedRepos),
      ]);

      setData(
        'avatars',
        _(contribs)
          .map((c) => [c.login, c.avatar])
          .fromPairs()
          .value(),
        true
      );

      const contribsAuthors = _(contribs).concat(nullUser).value();

      dispatchFilter(setAppliedRepos(selectedRepos));
      dispatchFilter(setContribsOptions(contribsAuthors));
      dispatchFilter(setLabelsOptions(labels));
      dispatchFilter(setDone());
    },
    [accountID, contextRepos, filterData.dateInterval, resetData]
  );

  const onContribsApplyChange = useCallback(
    async (selectedContribs, noReset) => {
      // This `noReset` param is quite hacky. It has been introduced to fix DEV-1353
      // as fast as possible. The problem was that the applied contributors can change
      // in two ways:
      //
      // 1. when clicking the Apply button,
      // 2. when the options are changed with a superset and the "All" checkbox is
      //    selected (this is happening on date interval change).
      //
      // The latter is the bug described in DEV-1353. The problem is that there's
      // currently no distinction between a change in applied contributors happening due
      // to a user interaction (case 1) or due to a programmatic change (case 2).
      // DEV-1353 has been fixed by calling this apply callback also for programatic change,
      // but in that case the data needs to be not reset.
      //
      // This kind of programmatic change happens only for contributors, hence the
      // same callback needs to be not called for repositories. That's the reason of the
      // `doNothing` param in `onReposApplyChange`.
      //
      // This has to be refactored! We need to change the state mgmt of this context.
      // An idea could be to have a mechanism where for any filter change an ACK is required
      // from each so that each filter can update whatever before considering the whole set
      // as ready. Something like:
      //
      //     onFilterChange:
      //         - setChanging
      //         - resetData
      //         - waitForACK
      //         - dispatchStateChange
      //         - setDone
      //
      // See also `@components/MultiSelect/index.jsx`.
      if (!noReset) {
        dispatchFilter(setChanging());
        resetData();
      }

      // XXX: Do NOT remove this dummy await!
      // See linked discussion here: https://athenianco.atlassian.net/browse/DEV-1663
      // TL;DR: this prevents React to batch the state changes for the `filterReady`.
      // Since the reset and the re-triggering of the data layer rely on that flag
      // changing, it's important that the state changes are not batched.
      await Promise.resolve();
      dispatchFilter(setAppliedContribs(selectedContribs));

      setData(
        'avatars',
        _(selectedContribs)
          .map((c) => [c.login, c.avatar])
          .fromPairs()
          .value(),
        true
      );

      if (!noReset) {
        dispatchFilter(setDone());
      }
    },
    [resetData]
  );

  const onExcludeInactive = () => dispatchFilter(setExcludeInactive(!filterData.excludeInactive));

  const onLabelsApplyChange = useCallback(
    async (selectedLabels) => {
      dispatchFilter(setChanging());
      resetData();

      const contribs = await getContribsForFilter(
        tokenRef.current,
        accountID,
        filterData.dateInterval,
        contextRepos,
        filterData.excludeInactive
      );
      setData(
        'avatars',
        _(contribs)
          .map((c) => [c.login, c.avatar])
          .fromPairs()
          .value(),
        true
      );

      const selectedGhLabels = _(selectedLabels)
        .filter((label) => label.labelType === 'github')
        .value();
      const selectedJiraEpics = _(selectedLabels)
        .filter((label) => label.labelType === 'jira')
        .value();
      const selectedIssueTypes = _(selectedLabels)
        .filter((label) => label.labelType === 'issueType')
        .value();
      dispatchFilter(setAppliedLabels(selectedGhLabels));
      dispatchFilter(setAppliedEpics(selectedJiraEpics));
      dispatchFilter(setAppliedIssueTypes(selectedIssueTypes));
      dispatchFilter(setDone());
    },
    [accountID, contextRepos, filterData.dateInterval]
  );

  const filterReady =
    filterData.repos.options.ready &&
    filterData.contribs.options.ready &&
    filterData.repos.applied.ready &&
    filterData.contribs.applied.ready;

  const appliedRepos =
    filterData.repos.applied.values.length > 0
      ? filterData.repos.applied.values
      : filterData.repos.options.values;

  return (
    <Context.Provider
      value={{
        ready: filterReady,
        appliedRepos,
        appliedContribs: appliedContribs,
        hasAppliedNullContrib,
        hasAppliedBotContrib,
        reposOptions: filterData.repos.options.values,
        contribsOptions: filterData.contribs.options.values,
        reposReady: filterData.repos.options.ready,
        contribsReady: filterData.contribs.options.ready,
        teams: filterData.teams.options,
        labels: filterData.labels,
        epics: filterData.epics,
        issueTypes: filterData.issueTypes,
        dateInterval: filterData.dateInterval,
        excludeInactive: filterData.excludeInactive,
        onReposInit,
        onContribsInit,
        onOmniboxInit,
        onDateIntervalChange,
        onReposApplyChange,
        onContribsApplyChange,
        onExcludeInactive,
        onLabelsApplyChange,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const getDataForFilter = async (
  _dataName,
  dataFetcherFn,
  token,
  accountID,
  dateInterval: DatetimeService.Interval,
  inRepos = [],
  exclude_inactive
) => dataFetcherFn(token, accountID, dateInterval, inRepos, exclude_inactive);

const getReposForFilter = ({
  token,
  accountID,
  dateInterval,
  repos,
  excludeInactive,
}: IGetReposOptions) =>
  getDataForFilter(
    'repositories',
    getRepos,
    token,
    accountID,
    dateInterval,
    repos,
    excludeInactive
  );

const getContribsForFilter = (
  token,
  accountID,
  dateInterval: DatetimeService.Interval,
  inRepos,
  excludeInactive
) =>
  getDataForFilter(
    'contributors',
    getContributors,
    token,
    accountID,
    dateInterval,
    inRepos,
    excludeInactive
  );

const getTeamsForFilter = (token, accountID) => getTeams(buildApi(token), accountID);

export default FiltersProvider;
export { useFilters };
