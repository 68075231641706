import React from 'react';

import { Circle } from '@lib/circle';
import { Icon } from '@lib/icon';

import { styles, avatarTextStyles } from './avatar.styles';
import { IAvatar, AvatarType } from './avatar.types';

export const Avatar: React.FC<IAvatar> = React.memo(
  ({ color, icon, image, label, type, size = 20 }) => {
    let inferredType = AvatarType.none;
    if (!!icon) {
      inferredType = AvatarType.icon;
    } else if (!!image) {
      inferredType = AvatarType.image;
    } else if (!!color) {
      inferredType = AvatarType.text;
    }

    const avType = type || inferredType;
    if (avType === AvatarType.none) return null;
    return (
      <div css={styles(avType === AvatarType.text)}>
        {avType === AvatarType.icon && !!icon ? (
          <Icon color={color} icon={icon} size={size} />
        ) : avType === AvatarType.image && !!image ? (
          <img alt="" height={size} src={image} width={size} />
        ) : (
          <Circle color={color} size={avType === AvatarType.text ? size + 2 : 12}>
            <div css={avatarTextStyles(size)}>
              {avType === AvatarType.text ? label.charAt(0) : ''}
            </div>
          </Circle>
        )}
      </div>
    );
  }
);
