import { css } from '@emotion/react';

import { hexToRGBA } from '@utils/colors';

export const wrapperStyles = ({ color, radius, spacing }) => css`
  align-items: center;
  background-color: ${hexToRGBA(color.ui.blue['100'], 0.1)};
  border-radius: ${radius.default};
  display: flex;
  height: 40px;
  padding: ${spacing.gap['03']} ${spacing.gap['04']};
  width: 100%;
`;
