import { GoalsSingleComponentServices } from '@align-pages/goals/single/components/goals-single-component';
import { IGoalMetricValue, TeamGoalInput } from '@align-services/api/types/goalsTypes';
import {
  GoalCreateRequest,
  GoalMetricValue,
  TeamGoal,
  TeamGoalAssociation,
  TeamGoalAssociations,
} from '@common-services/api/private/generated-from-backend/models';
import { StringService } from '@common-services/stringService';
import { ISelectedFilters } from '@lib/filter-panel';

export const serializeTeamGoalTree = (teamGoalTree: TeamGoal): TeamGoalAssociations => {
  let res: TeamGoalAssociations = [
    {
      team_id: teamGoalTree.team.id,
      target: teamGoalTree.value.target,
      metric_params: teamGoalTree.metric_params,
    },
  ];
  teamGoalTree.children.forEach((tg) => {
    res = [...res, ...serializeTeamGoalTree(tg)];
  });
  return res;
};

export const serializeTeamGoalInput = (teamGoalInputs: TeamGoalInput[]): TeamGoalAssociation[] => {
  return teamGoalInputs.map((teamGoal) => {
    const association: TeamGoalAssociation = {
      team_id: teamGoal.teamId,
      target: GoalsSingleComponentServices.normaliseMetricValue(teamGoal?.target),
    };
    if (teamGoal?.metricParams) {
      association.metric_params = teamGoal?.metricParams;
    }
    return association;
  });
};

export const serializeFilters = (selectedFilters: ISelectedFilters): Partial<GoalCreateRequest> => {
  return {
    ...(selectedFilters?.repositories?.length
      ? { repositories: selectedFilters?.repositories }
      : ''),
    ...(selectedFilters?.jira_issue_types?.length
      ? { jira_issue_types: selectedFilters?.jira_issue_types }
      : ''),
    ...(selectedFilters?.jira_projects?.length
      ? { jira_projects: selectedFilters?.jira_projects }
      : ''),
    ...(selectedFilters?.jira_priorities?.length
      ? { jira_priorities: selectedFilters?.jira_priorities }
      : ''),
  };
};

export const deNormaliseMetricValue = (value: GoalMetricValue): IGoalMetricValue => {
  if (StringService.isString(value)) {
    return { str: value as string };
  } else if (StringService.isInteger(value)) {
    return { int: value as number };
  } else if (StringService.isFloat(value)) {
    return { float: value as number };
  } else {
    return null;
  }
};

export * as GoalsService from './goalsService';
