import { css } from '@emotion/react';

export const styles = (isNotExtra, hasLongItem) => ({ color, font, spacing }) => css`
  align-items: center;
  display: flex;
  margin-right: ${spacing.gap['03']};
  white-space: nowrap;
  ${hasLongItem && isNotExtra && 'overflow: hidden;'}

  .ath-label-text {
    color: ${color.neutral[100]};
    font-size: ${font.size.default};
    font-weight: ${font.weight.regular};
    line-height: 1.5;
  }

  .ath-value-label {
    margin-left: ${spacing.gap['02']};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: ${isNotExtra ? '1' : '0 0 auto'};
  }

  .ath-circle-label {
    color: ${color.neutral.white};
    font-size: ${font.size.xxs};
    font-weight: ${font.weight.regular};
  }
`;
