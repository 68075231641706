import { useTheme } from '@emotion/react';
import React, { ForwardedRef, useCallback, useEffect, useState } from 'react';

import { InputProvider } from '@lib/Input/input.context';
import { ITheme } from '@styles/types';

import { IInputProps, InputTypes } from './Input.types';
import { Input as InputUI } from './ui/InputUI';

type GenericPropsInput = <T>(props: IInputProps<T>) => React.ReactElement;

const Input: GenericPropsInput = React.forwardRef(
  (
    {
      disabled = false,
      label,
      placeholder,
      initialValue = '',
      width,
      withSearchIcon = false,
      autoComplete = false,
      childrenAfter = false,
      children,
      type = InputTypes.text,
      onChange,
      step,
      onClick,
      register,
      name,
      error,
      testid,
    },
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const theme = useTheme() as ITheme;
    const [value, setValue] = useState(initialValue);

    const handleChange = useCallback((newValue: string) => {
      setValue(newValue);
      if (onChange) onChange(newValue);
    }, []);

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    const defaultOnClick = (e) => e.stopPropagation();
    const handleClick = onClick || defaultOnClick;

    return (
      <InputProvider register={register} name={name} error={error} testid={testid}>
        <InputUI
          disabled={disabled}
          iconColor={theme.color.neutral[80]}
          label={label}
          placeholder={placeholder}
          value={value}
          width={width}
          withSearchIcon={withSearchIcon}
          autoComplete={autoComplete}
          onChange={handleChange}
          onClick={handleClick}
          childrenAfter={childrenAfter}
          ref={ref}
          type={type}
          step={step}
        >
          {children}
        </InputUI>
      </InputProvider>
    );
  }
);

export { Input };
