import { css } from '@emotion/react';

export const wrapperStyles = () => css`
  align-items: center;
  display: flex;
`;

export const placeholderStyles = css`
  min-width: 20px;
`;

export const iconWrapperStyles = ({ spacing }) => css`
  margin-left: ${spacing.gap['05']};
  margin-right: ${spacing.gap['06']};
`;

export const titleStyles = ({ color, font }) => css`
  color: ${color.text.dark};
  font-size: ${font.size.s};
  font-weight: ${font.weight.medium};
  width: 500px;
`;

export const detailsStyles = ({ color, font, spacing }) => css`
  align-items: center;
  color: ${color.text.secondary};
  display: flex;
  font-size: ${font.size.xs};
  font-weight: ${font.weight.regular};

  .time-details {
    color: ${color.text.dark};
  }
`;

export const prTitleStyles = ({ color, font, spacing }) => css`
  margin-left: ${spacing.gap['05']};
  width: 500px;

  .pr-title {
    align-items: center;
    display: flex;
    font-size: ${font.size.s};
    font-weight: ${font.weight.medium};

    .repo-name {
      color: ${color.text.secondary};
      white-space: nowrap;
    }

    .pr-text {
      color: ${color.text.dark};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .pr-subtitle {
    align-items: center;
    display: flex;

    .user-avatar {
      height: 22px;
      margin-right: ${spacing.gap['02']};
      width: 22px;
    }

    .pr-subtitle-text {
      color: ${color.text.secondary};
      font-size: ${font.size.xs};
      font-weight: ${font.weight.regular};

      .reporter {
        color: ${color.text.dark};
      }
    }
  }
`;
