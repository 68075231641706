import moment from 'moment';

export enum TimeUnit {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export class DatetimeFormatter {
  static ymd(dt: Datetime): string {
    return dt.format('YYYY-MM-DD');
  }
}

export class Datetime {
  private readonly value: moment.Moment;
  private readonly offset: number;
  private readonly repr: string;

  constructor();
  constructor(value: moment.Moment | IToDatetime);
  constructor(value: moment.Moment | IToDatetime, noOffset?: boolean);
  constructor(value?: moment.Moment | IToDatetime, noOffset?: boolean) {
    const rawValue = value ? moment(value) : moment();
    this.offset = noOffset ? 0 : rawValue.utcOffset();
    this.value = rawValue.utcOffset('+00:00', true).startOf(TimeUnit.DAY);
    this.repr = this.value.format();
  }

  utcOffset(): number {
    return this.offset;
  }

  asTimestamp(): number {
    return this.value.valueOf();
  }

  toDate(): Date {
    return this.value.toDate();
  }

  toMoment(): moment.Moment {
    return this.value;
  }

  format(formatString?: string): string {
    return this.value.format(formatString);
  }

  diff(): number;
  diff(other: Datetime): number;
  diff(other: Datetime, unit: TimeUnit): number;
  diff(other?: Datetime, unit?: TimeUnit): number {
    return this.value.diff((other || datetime()).value, unit);
  }

  add(value: number): Datetime;
  add(value: number, unit: TimeUnit): Datetime;
  add(value: number, unit?: TimeUnit): Datetime {
    return new Datetime(this.value.clone().utcOffset(this.offset).add(value, unit));
  }

  subtract(value: number): Datetime;
  subtract(value: number, unit: TimeUnit): Datetime;
  subtract(value: number, unit?: TimeUnit): Datetime {
    return new Datetime(this.value.clone().utcOffset(this.offset).subtract(value, unit));
  }

  equals(other: Datetime): boolean {
    return this.value.format() === other.value.format();
  }
}

export class Interval {
  readonly from: Datetime;
  readonly to: Datetime;

  constructor(from: Datetime, to: Datetime) {
    this.from = from;
    this.to = to;
  }

  duration?(unit?: TimeUnit): number {
    return this.to.diff(this.from, unit);
  }

  getPrevious?(): Interval {
    const diffDays = this.duration(TimeUnit.DAY);
    const prevTo = this.from.subtract(1, TimeUnit.DAY);
    const prevFrom = prevTo.subtract(diffDays, TimeUnit.DAY);
    return new Interval(prevFrom, prevTo);
  }

  calculateGranularity?(minGranularity: TimeUnit = TimeUnit.DAY): string {
    const granularities: TimeUnit[] = [TimeUnit.DAY, TimeUnit.WEEK, TimeUnit.MONTH];
    const diffDays = this.duration(TimeUnit.DAY);
    const granularity =
      diffDays <= 4 * 7 ? TimeUnit.DAY : diffDays <= 5 * 30 ? TimeUnit.WEEK : TimeUnit.MONTH;
    return granularities.indexOf(minGranularity) > granularities.indexOf(granularity)
      ? minGranularity
      : granularity;
  }
}

type IToDatetime = Date | number | string;

export const datetime = (value?: IToDatetime, noOffset?: boolean): Datetime =>
  new Datetime(value, noOffset);
export const interval = (from: Datetime | IToDatetime, to: Datetime | IToDatetime): Interval => {
  const f = from instanceof Datetime ? from : datetime(from);
  const t = to instanceof Datetime ? to : datetime(to);
  return new Interval(f, t);
};

class DatetimePresets {
  get SIX_MONTHS_AGO(): Datetime {
    return datetime().subtract(6, TimeUnit.MONTH);
  }

  get OLDEST(): Datetime {
    return datetime().subtract(15, TimeUnit.MONTH);
  }

  get TODAY(): Datetime {
    return datetime();
  }
}

class DatetimeDefaults {
  get FROM(): Datetime {
    return process.env.REACT_APP_DATE_FILTER_FROM
      ? datetime(process.env.REACT_APP_DATE_FILTER_FROM)
      : datetime().subtract(2, TimeUnit.WEEK).add(1, TimeUnit.DAY);
  }

  get TO(): Datetime {
    return process.env.REACT_APP_DATE_FILTER_TO
      ? datetime(process.env.REACT_APP_DATE_FILTER_TO)
      : datetime();
  }
}

export interface RawInterval {
  from: string;
  to: string;
}

export const Presets = new DatetimePresets();
export const Defaults = new DatetimeDefaults();

export * as DatetimeService from './datetimeService';
