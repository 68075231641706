import React from 'react';

import { getCurrentRange } from '@align-services/dateService';
import { Button } from '@lib/Button';
import IconButton from '@lib/IconButton';
import { Input } from '@lib/Input';
import { InputTypes } from '@lib/Input/Input.types';
import { icons } from '@lib/icon';

import { wrapperStyles } from './newTargetBlock.styles';
import { INewTargetBlockProps } from './newTargetBlock.types';

const NewTargetBlock: React.FC<INewTargetBlockProps> = React.memo(
  ({ dateRange, onClose, onSetTarget, onValueChange }) => (
    <div css={wrapperStyles}>
      <div className="set-target-text">
        Set Target for {getCurrentRange({ dateFrom: dateRange.dateFrom, dateTo: dateRange.dateTo })}
      </div>
      <div className="set-target-value">
        <Input type={InputTypes.number} width={72} onChange={onValueChange} />
        <div className="set-button-wrapper">
          <Button label="Set" onClick={onSetTarget} />
        </div>
        <IconButton icon={icons.cross} iconSize={8} onClick={onClose} />
      </div>
    </div>
  )
);

export { NewTargetBlock };
