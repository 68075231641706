import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import UpgradeQuality from '@assets/images/upgrade/quality_upgrade.png';
import Upgrade01 from '@assets/images/upgrade/upgrade_01.svg';
import Upgrade02 from '@assets/images/upgrade/upgrade_02.svg';
import Upgrade03 from '@assets/images/upgrade/upgrade_03.svg';
import Upgrade04 from '@assets/images/upgrade/upgrade_04.svg';

const upgradeBox = {
  backgroundImage: 'url(' + UpgradeQuality + ')',
  backgroundSize: '100%',
  backgroundRepeat: 'no-repeat',
  paddingTop: '200px',
};

const Upgrade = () => {
  return (
    <div style={upgradeBox} className="container">
      <div className="row text-center">
        <div className="col">
          <h2 className="text-dark h3 mt-5 mb-2">
            Upgrade your account to access Quality & Outcome insights
          </h2>
          <p className="text-secondary mb-5">Requires Jira integration</p>
        </div>
      </div>
      <div className="row text-center mb-3">
        <div className="col-3">
          <div className="p-2">
            <img alt="" className="mb-5" src={Upgrade01} />
            <h3 className="mb-3 h4">
              <div className="text-dark link">Jira Integration</div>
            </h3>
            <ul className="list-unstyled upgrade-list">
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Jira Cloud &
                Server
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Atlassian
                Connect
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Mapping between
                PRs and tickets
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> PR filtering by
                Jira metadata
              </li>
            </ul>
          </div>
        </div>
        <div className="col-3">
          <div className="p-2">
            <img alt="" className="mb-5" src={Upgrade02} />
            <h3 className="mb-3 h4">
              <div className="text-dark link">Software Delivery Pipeline</div>
            </h3>
            <ul className="list-unstyled upgrade-list">
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Plan & Design
                stage
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Mean Time To
                Restore (MTTR)
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Resolution
                frequency
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Throughput by
                work type
              </li>
            </ul>
          </div>
        </div>
        <div className="col-3">
          <div className="p-2">
            <img alt="" className="mb-5" src={Upgrade04} />
            <h3 className="mb-3 h4">
              <div className="text-dark link">QA Metrics</div>
            </h3>
            <ul className="list-unstyled upgrade-list">
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Customer-facing
                bugs
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Bug fixing rate
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Defect
                distribution
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Breakdown by
                severity
              </li>
            </ul>
          </div>
        </div>
        <div className="col-3">
          <div className="p-2">
            <img alt="" className="mb-5" src={Upgrade03} />
            <h3 className="mb-3 h4">
              <div className="text-dark link">New Features Analysis</div>
            </h3>
            <ul className="list-unstyled upgrade-list">
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Progress
                tracking
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Reliability
                score
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Knowledge
                sharing
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Breakdown by
                theme
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-12 text-center">
          <a className="btn btn-orange btn-large mr-3" href="https://www.athenian.co/pricing">
            Upgrade Now
          </a>
          <a className="btn btn-secondary btn-large" href="https://www.athenian.co">
            Learn More
          </a>
        </div>
      </div>
    </div>
  );
};

export default Upgrade;
