import { BasicApiClass } from '@common-services/api/common/BasicApiClass';
import { REST_TYPES } from '@common-services/api/common/types/apiClient';
import { ApiMode } from '@common-services/api/common/types/common';
import { ISentryMethodParams } from '@common-services/api/common/types/sentry';
import { PRIVATE_API_ENDPOINTS_CONSTANTS } from '@common-services/api/private/constants';
import {
  TeamMetricsRequest,
  TeamMetricsResponse,
} from '@common-services/api/private/generated-from-backend/models';

export class MetricsApiClass extends BasicApiClass {
  constructor() {
    super(ApiMode.PRIVATE);
  }
  public getTeamMetrics(
    params: TeamMetricsRequest,
    token: string = null,
    sentry?: ISentryMethodParams
  ): Promise<TeamMetricsResponse> {
    this.assignToken(token);
    const callApi = this.callApi<TeamMetricsResponse>(
      PRIVATE_API_ENDPOINTS_CONSTANTS.ALIGN_TEAM_METRICS,
      REST_TYPES.POST,
      params
    );
    return sentry ? this.callSentry<TeamMetricsResponse>(callApi, sentry) : callApi;
  }
}
