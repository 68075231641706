export enum ANALYTICS_ALIGN_SWITCHER {
  BLOCK = 'analytics-align-switcher-block',
  ANALYTICS = 'analytics-align-switcher-analytics',
  ALIGN = 'analytics-align-switcher-align',
}

export enum BREADCRUMBS_MEMBERS {
  BLOCK = 'breadcrumbs-members',
  BREADCRUMBS = 'breadcrumbs-members-breadcrumbs',
  AVATAR = 'breadcrumbs-members-avatar',
  MEMBERS = 'breadcrumbs-members-members',
  TEAMS = 'breadcrumbs-members-teams',
}

export enum TABS_SECTION {
  BLOCK = 'tabs-section',
  DASHBOARD = 'tabs-section-dashboard',
  GOALS = 'tabs-section-goals',
  TEAMS = 'tabs-section-teams',
}
