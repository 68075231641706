import React from 'react';

import noPrs from '@assets/images/empty-states/no_prs.svg';

import { Empty } from '..';

const NoActiveGoals: React.FC = React.memo(() => (
  <Empty background={noPrs}>
    <h3 className="dark">There are no active goals</h3>
    <p>Add a goal and start to continuously improve</p>
  </Empty>
));

export { NoActiveGoals };
