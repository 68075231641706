import { ChartBlockSize } from '@align-pages/dashboard/config';
import { IPlaceholderData } from '@lib/Placeholder';

export const placeholderData: IPlaceholderData = {
  height: ChartBlockSize.HEIGHT,
  items: [
    {
      count: 1,
      border: true,
      height: ChartBlockSize.HEIGHT,
      gap: 16,
      elements: [
        {
          width: ChartBlockSize.WIDTH,
          height: 60,
          left: 0,
          top: 0,
        },
        {
          width: 600,
          height: 300,
          left: 35,
          top: 90,
        },
      ],
    },
  ],
};
