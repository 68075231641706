import React from 'react';

import outage from '@assets/images/empty-states/outage.svg';

import { Empty } from '..';

const Outage: React.FC = React.memo(() => {
  return (
    <Empty background={outage}>
      <h3>Looks like something went wrong!</h3>
      <p>Please try refreshing and contact us if the problem persists.</p>
      <a className="text-dark-orange" href={window.ENV.settings.statusUrl}>
        Athenian Status
      </a>
    </Empty>
  );
});

export { Outage };
