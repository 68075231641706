import { css } from '@emotion/react';

import { ButtonMenuTypes } from '@lib/button-menu/buttonMenu.types';

export const wrapperStyles = ({ type }) => css`
  position: relative;
  display: ${type === ButtonMenuTypes.DROPDOWN ? 'block' : 'inline-block'};
`;

export const menuStyles = ({ color, spacing, radius }) => css`
  position: absolute;
  top: calc(100% + ${spacing.gap['02']});
  left: 0;
  background: ${color.neutral.white};
  padding: ${spacing.gap['03']};
  border-radius: ${radius.default};
  min-width: 180px;
  border: 1px solid ${color.neutral['40']};
  z-index: 100;
`;

export const menuItemStyles = ({ isDisabled }) => ({ color, spacing, radius }) => css`
  white-space: nowrap;
  padding: ${spacing.gap['03']} ${spacing.gap['04']};
  border-radius: ${radius.default};
  transition: background 0.3s;
  ${isDisabled
    ? `
    color: ${color.text.secondary};
    cursor: not-allowed;
  `
    : `
    cursor: pointer;
    color: ${color.text.dark};

    &:hover {
      background: ${color.neutral['80']}19;
    }
  `}

  .icon {
    margin-right: ${spacing.gap['03']};
  }
`;

export const dropdownViewStyles = ({ color, radius, spacing }) => css`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: ${spacing.gap['04']};
  border: 1px solid ${color.neutral['40']};
  border-radius: ${radius.default};
  background: ${color.neutral.white};
  cursor: pointer;

  .dropdown-text {
    flex-grow: 1;
    padding-left: ${spacing.gap['04']};
  }
`;
