import { publicApi } from '@common-services/api/public';
import {
  FilterPullRequestsRequest,
  GetPullRequestsRequest,
  PaginatePullRequestsRequest,
  PullRequestPaginationPlan,
  PullRequestSet,
} from '@common-services/api/public/generated-from-backend/models';
import { IFilteredPRs, IFilteredPRsParams } from '@common-services/api/public/types/pullRequests';
import { ArrayService } from '@common-services/arrayService';
import { getOffset } from '@common-services/dateService';
import { processPR } from '@common-services/prHelpers';

export const fetchFilteredPRsPaginationPlan = async (
  params: IFilteredPRsParams,
  token: string
): Promise<PullRequestPaginationPlan> => {
  const body: PaginatePullRequestsRequest = {
    batch: 100,
    request: {
      account: params.account,
      date_from: params.interval.from.format('YYYY-MM-DD'),
      date_to: params.interval.to.format('YYYY-MM-DD'),
      timezone: getOffset(),
      in: params.filters.repositories || [],
      stages: params.filters.stages || ['wip', 'reviewing', 'merging', 'releasing', 'done'],
      ...(params.filters.developers?.length
        ? {
            with: {
              author: ArrayService.getUniqueValues(
                params.filters.developers.filter((d) => d.login).map((d) => d.login)
              ),
            },
          }
        : {}),
      labels_include: params.filters.labels_include,
      labels_exclude: params.filters.labels_exclude,
      exclude_inactive: params.excludeInactive,
      jira: params.filters.jira,
    },
  };
  return publicApi.pullRequests.fetchFilteredPRsPaginationPlan(body, token, {
    message: `Cannot get pull requests pagination plan for accountId ${params.account}`,
  });
};

export const fetchFilteredPRs = async (
  params: IFilteredPRsParams,
  token: string
): Promise<IFilteredPRs> => {
  const body: FilterPullRequestsRequest = {
    account: params.account,
    date_from: params.interval.from.format('YYYY-MM-DD'),
    date_to: params.interval.to.format('YYYY-MM-DD'),
    timezone: getOffset(),
    in: params.filters.repositories || [],
    stages: params.filters.stages || ['wip', 'reviewing', 'merging', 'releasing', 'done'],
    ...(params.filters.developers?.length
      ? {
          with: {
            author: ArrayService.getUniqueValues(
              params.filters.developers.filter((d) => d.login).map((d) => d.login)
            ),
          },
        }
      : {}),
    labels_include: params.filters.labels_include,
    labels_exclude: params.filters.labels_exclude,
    exclude_inactive: params.excludeInactive,
    jira: params.filters.jira,
    limit: params.limit,
    ...(params.updatedFrom
      ? {
          updated_from: params.updatedFrom,
        }
      : {}),
    ...(params.updatedTo
      ? {
          updated_to: params.updatedTo,
        }
      : {}),
  };
  const prs = await publicApi.pullRequests.fetchFilteredPRs(body, token, {
    message: `Cannot get filtered pull requests for accountId ${params.account} from public API`,
  });

  return {
    deployments: prs?.include?.deployments || {},
    prs: prs.data.map(processPR),
    users: prs?.include?.users || {},
  };
};

export const getPullRequests = async (
  params: GetPullRequestsRequest,
  token: string
): Promise<PullRequestSet> => {
  return publicApi.pullRequests.getPullRequests(
    {
      account: params.account,
      prs: params.prs,
    },
    token,
    {
      message: `Cannot get pull requests for accountId ${params.account} from public API`,
    }
  );
};

export * as PublicPRsServices from './pullRequests';
