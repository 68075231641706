import { css } from '@emotion/react';

export const wrapperStyles = ({ color, spacing, radius, shadow }) => css`
  position: absolute;
  background: ${color.neutral.white};
  padding: ${spacing.gap['04']};
  left: 0;
  z-index: 1000;
  color: ${color.text.dark};
  border-radius: ${radius.default};
  box-shadow: ${shadow.full};

  .actions {
    margin-top: ${spacing.gap['03']};
    display: flex;
    align-items: center;
    gap: ${spacing.gap['03']};
  }
`;
