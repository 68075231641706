import _ from 'lodash';
import React from 'react';

import { LOADING, READY } from '@analytics-components/StatusIndicator';
import DataWidget, { useDataWidget } from '@analytics-context/DataWidget';
import {
  BIG_PR_THRESHOLD,
  EXTREMELY_BIG_PR_THRESHOLD,
} from '@analytics-pages/delivery-pipeline/insights/work-in-progress';
import { fetchDevsMetrics, fetchPRsMetrics } from '@analytics-services/api';
import { fetchJIRAMetrics } from '@analytics-services/api/jira';
import { DatetimeService } from '@analytics-services/datetimeService';
import { featuresList, getFeature } from '@analytics-services/flags';
import { useUserContext } from '@common-context/User';
import { FAKE_USERNAME } from '@common-pages/Settings/constants';
import { humanLaboralRate } from '@common-services/dateService';
import { default as MainMetricsUI } from '@ui/MainMetrics';

export const calcVariation = (prev, curr) => (prev > 0 ? ((curr - prev) * 100) / prev : 0);

const PipelineMainMetrics = ({ definitions, hasJIRA }) => {
  const userDetails = useUserContext();

  const fetcher = async (api, cachedData, apiContext) => {
    const {
      account,
      interval,
      excludeInactive,
      contributors,
      labels,
      repositories,
      includeNullContributor,
      includeFakeContributor,
      epics,
      issueTypes,
      features,
    } = apiContext;
    const jiraAssignees = _(contributors).map('jira_user').filter().value();
    if (includeNullContributor) {
      jiraAssignees.push(null);
    }
    if (includeFakeContributor) {
      jiraAssignees.push(FAKE_USERNAME);
    }

    const fetch = async (interval) => {
      if (!hasJIRA) return 0;

      const filteredJIRAStuff = cachedData['jira-issue-types'];
      const allIssueTypes = _(filteredJIRAStuff.issue_types)
        .filter((v) => !v.is_subtask && !v.is_epic)
        .map('value')
        .value();

      const leadTime = await fetchJIRAMetrics(
        api,
        account,
        ['all'],
        interval,
        ['lead-time'],
        excludeInactive,
        {
          types: issueTypes?.length ? issueTypes : allIssueTypes,
          epics,
        },
        { assignees: jiraAssignees }
      );
      return leadTime[0].values[0].values[0];
    };

    const prevInterval = interval.getPrevious();
    const [leadTimePrev, leadTimeCurr] = await Promise.all([fetch(prevInterval), fetch(interval)]);
    const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, features)?.parameters
      ?.value;

    const activeDevs = !userDetails.account.jira
      ? await fetchDevsMetrics(api, account, ['all'], prevInterval, ['active0'], {
          repositories,
          developers: _(contributors).uniqBy('login').value(),
          labels_include: labels,
          ...(excludedLabels ? { labels_exclude: excludedLabels } : {}),
          jira: { epics, issue_types: issueTypes },
        })
      : null;

    return {
      leadTime: {
        prev: leadTimePrev,
        curr: leadTimeCurr,
      },
      prevActiveDevs:
        activeDevs?.calculated?.[0]?.values?.reduce(
          (acc, item) => acc + (item?.[0]?.values[0] || 0),
          0
        ) || 0,
    };
  };

  const plumber = (fetchedData, cachedData, apiContext) => {
    const { interval } = apiContext;

    const releases = cachedData['releases-metrics']['values']['all']['count'];
    const releaseRateSpecs = humanLaboralRate(interval.from, interval.to, releases);

    const leadTimePrev = fetchedData.leadTime.prev * 1000;
    const leadTimeCurr = fetchedData.leadTime.curr * 1000;

    const currentActiveDevs =
      Object.values(cachedData['devs-metrics']).filter((v) => v.worked).length || 0;

    return {
      leadTime: {
        avg: leadTimeCurr,
        variation: calcVariation(leadTimePrev, leadTimeCurr),
      },
      PRsCycleTime: {
        avg: cachedData['prs-metrics']['values']['all']['lead-time'] * 1000,
        variation: cachedData['prs-metrics']['variations']['lead-time'],
      },
      releases: {
        releaseRateSpecs,
        variation: cachedData['releases-metrics']['variations']['count'],
      },
      releasedPRs: {
        avg: cachedData['prs-metrics']['values']['all']['lead-count'] || 0,
        variation: cachedData['prs-metrics']['variations']['lead-count'],
      },
      contributors: {
        avg: currentActiveDevs,
        variation: calcVariation(fetchedData.prevActiveDevs, currentActiveDevs),
      },
    };
  };

  const prefetchedDataIds = ['prs-metrics', 'releases-metrics', 'devs-metrics'];
  if (hasJIRA) {
    prefetchedDataIds.push('jira-issue-types');
  }

  return (
    <DataWidget
      id="pipeline-main-metrics"
      fetcher={fetcher}
      plumber={plumber}
      prefetchedDataIds={prefetchedDataIds}
    >
      <MainMetricsDataBinder definitions={definitions} />
    </DataWidget>
  );
};

const CIMainMetrics = ({ definitions }) => {
  const plumber = (fetchedData, cachedData, apiContext) => ({
    successRatio: {
      avg: cachedData['ci-metrics'].values.all['chk-success-ratio'],
      variation: cachedData['ci-metrics'].variations['chk-success-ratio'],
    },
    suiteRunTime: {
      avg: parseInt(cachedData['ci-metrics'].values.all['chk-suite-time']),
      variation: calcVariation(
        parseInt(cachedData['ci-metrics'].prevValues.all['chk-suite-time']),
        parseInt(cachedData['ci-metrics'].values.all['chk-suite-time'])
      ),
    },
    runTime: {
      avg: parseInt(cachedData['ci-metrics'].values.all['chk-suite-time-per-pr']),
      variation: calcVariation(
        parseInt(cachedData['ci-metrics'].prevValues.all['chk-suite-time-per-pr']),
        parseInt(cachedData['ci-metrics'].values.all['chk-suite-time-per-pr'])
      ),
    },
    flakiness: {
      avg:
        cachedData['ci-metrics'].values.all['chk-flaky-commit-checks-count'] /
        cachedData['ci-metrics'].values.all['chk-suites-count'],
      variation: calcVariation(
        cachedData['ci-metrics'].prevValues.all['chk-flaky-commit-checks-count'] /
          cachedData['ci-metrics'].prevValues.all['chk-suites-count'],
        cachedData['ci-metrics'].values.all['chk-flaky-commit-checks-count'] /
          cachedData['ci-metrics'].values.all['chk-suites-count']
      ),
    },
  });

  return (
    <DataWidget id="ci-main-metrics" plumber={plumber} prefetchedDataIds={['ci-metrics']}>
      <MainMetricsDataBinder definitions={definitions} />
    </DataWidget>
  );
};

const QualityMainMetrics = ({ definitions }) => {
  const userDetails = useUserContext();

  const fetcher = async (api, cachedData, apiContext) => {
    const {
      account,
      interval,
      excludeInactive,
      contributors,
      repositories,
      includeNullContributor,
      includeFakeContributor,
      epics,
      labels,
      features,
      issueTypes,
    } = apiContext;
    const prevInterval = interval.getPrevious();
    const diffDays = interval.duration(DatetimeService.TimeUnit.DAY) + 1;
    const allPeriodDays = diffDays === 1 ? 'day' : `${diffDays} day`;
    const wholeInterval = DatetimeService.interval(prevInterval.from, interval.to);
    const jiraAssignees = _(contributors).map('jira_user').filter().value();
    if (includeNullContributor) {
      jiraAssignees.push(null);
    }
    if (includeFakeContributor) {
      jiraAssignees.push(FAKE_USERNAME);
    }

    const fetchBugs = async () => {
      const bugs = await fetchJIRAMetrics(
        api,
        account,
        [allPeriodDays],
        wholeInterval,
        ['raised'],
        excludeInactive,
        { types: ['Bug'], epics },
        { assignees: jiraAssignees }
      );
      return [bugs[0].values[0].values[0], bugs[0].values[1].values[0]];
    };

    const fetchMTTR = async () => {
      const filteredJIRAStuff = cachedData['jira-priorities-all'];
      const topPrioties = _(filteredJIRAStuff.priorities.slice(0, 2)).map('name').value();
      const MTTR = await fetchJIRAMetrics(
        api,
        account,
        [allPeriodDays],
        wholeInterval,
        ['life-time'],
        excludeInactive,
        { types: ['Bug'], priorities: topPrioties, epics },
        { assignees: jiraAssignees }
      );
      return [MTTR[0].values[0].values[0] * 1000, MTTR[0].values[1].values[0] * 1000];
    };

    const fetchBugFixing = async () => {
      const filteredJIRAStuff = cachedData['jira-issue-types'];
      const standardIssueTypes = _(filteredJIRAStuff.issue_types)
        .filter((v) => !v.is_subtask && !v.is_epic)
        .map('name')
        .value();

      const fetch = async (issueTypes) =>
        await fetchJIRAMetrics(
          api,
          account,
          [allPeriodDays],
          wholeInterval,
          ['resolved'],
          excludeInactive,
          { types: issueTypes, epics },
          { assignees: jiraAssignees }
        );

      const [standardTicketsResolved, bugsResolved] = await Promise.all([
        fetch(standardIssueTypes),
        fetch(['Bug']),
      ]);

      return [
        (bugsResolved[0].values[0].values[0] / standardTicketsResolved[0].values[0].values[0]) *
          100,
        standardTicketsResolved[0].values[1].values[0]
          ? (bugsResolved[0].values[1].values[0] / standardTicketsResolved[0].values[1].values[0]) *
            100
          : 0,
      ];
    };

    const [[bugsPrev, bugsCurr], [MTTRPrev, MTTRCurr], [bugFixingPrev, bugFixingCurr]] = userDetails
      .account?.jira
      ? await Promise.all([fetchBugs(), fetchMTTR(), fetchBugFixing()])
      : [
          [null, null],
          [null, null],
          [null, null],
        ];

    const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, features)?.parameters
      ?.value;

    // fetch amount of opened PRs and divide them by lines of code
    const fetchPRs = async (timeInterval) =>
      await fetchPRsMetrics(
        api,
        account,
        ['all'],
        timeInterval,
        ['opened'],
        {
          repositories,
          with: {
            author: _(contributors).uniqBy('login').value(),
          },
          labels_include: labels,
          ...(excludedLabels ? { labels_exclude: excludedLabels } : {}),
          jira: { epics: epics, issue_types: issueTypes },
          lines: [0, BIG_PR_THRESHOLD, EXTREMELY_BIG_PR_THRESHOLD],
        },
        null,
        excludeInactive
      );

    const currPrs = !userDetails.account?.jira ? (await fetchPRs(interval))?.calculated : null;
    const prevPrs = !userDetails.account?.jira ? (await fetchPRs(prevInterval))?.calculated : null;

    return {
      bugs: {
        prev: bugsPrev,
        curr: bugsCurr,
      },
      MTTR: {
        prev: MTTRPrev,
        curr: MTTRCurr,
      },
      bugFixing: {
        prev: bugFixingPrev,
        curr: bugFixingCurr,
      },
      currPrs,
      prevPrs,
    };
  };

  const plumber = (fetchedData, cachedData, apiContext) => {
    const currentReviews =
      (cachedData['prs-metrics'].values.all['reviewed'] * 100) /
      (cachedData['prs-metrics'].values.all['reviewed'] +
        cachedData['prs-metrics'].values.all['not-reviewed']);

    const currentPrsLines = fetchedData?.currPrs
      ? (fetchedData.currPrs[0].values[0].values[0] * 100) /
        (fetchedData.currPrs[0].values[0].values[0] + fetchedData.currPrs[1].values[0].values[0])
      : null;

    return {
      bugs: {
        avg: fetchedData?.bugs.curr,
        variation: calcVariation(fetchedData?.bugs.prev, fetchedData?.bugs.curr),
      },
      MTTR: {
        avg: fetchedData?.MTTR.curr,
        variation: calcVariation(fetchedData?.MTTR.prev, fetchedData?.MTTR.curr),
      },
      bugFixing: {
        avg: fetchedData?.bugFixing.curr,
        variation: calcVariation(fetchedData?.bugFixing.prev, fetchedData?.bugFixing.curr),
      },
      PRsSize: {
        avg: cachedData['prs-metrics'].values.all.size,
        variation: cachedData['prs-metrics'].variations.size,
      },
      reviews: {
        avg: currentReviews,
        variation: calcVariation(
          (cachedData['prs-metrics'].prevValues.all['reviewed'] * 100) /
            (cachedData['prs-metrics'].prevValues.all['reviewed'] +
              cachedData['prs-metrics'].prevValues.all['not-reviewed']),
          currentReviews
        ),
      },
      comments: {
        avg: cachedData['prs-metrics'].values.all['review-comments-per'],
        variation: cachedData['prs-metrics'].variations['review-comments-per'],
      },
      prsLines: {
        avg: currentPrsLines,
        variation:
          fetchedData?.currPrs && fetchedData?.prevPrs
            ? calcVariation(
                (fetchedData.prevPrs[0].values[0].values[0] * 100) /
                  (fetchedData.prevPrs[0].values[0].values[0] +
                    fetchedData.prevPrs[1].values[0].values[0]),
                currentPrsLines
              )
            : null,
      },
    };
  };
  return (
    <DataWidget
      id="quality-main-metrics"
      fetcher={fetcher}
      plumber={plumber}
      prefetchedDataIds={['prs-metrics', 'jira-priorities-all', 'jira-issue-types']}
    >
      <MainMetricsDataBinder definitions={definitions} />
    </DataWidget>
  );
};

const OutcomeMainMetrics = ({ definitions }) => {
  const userDetails = useUserContext();

  const fetcher = async (api, cachedData, apiContext) => {
    const {
      account,
      interval,
      excludeInactive,
      contributors,
      repositories,
      includeNullContributor,
      includeFakeContributor,
      epics,
      features,
      labels,
      issueTypes,
      user,
    } = apiContext;
    const prevInterval = interval.getPrevious();
    const diffDays = interval.duration(DatetimeService.TimeUnit.DAY) + 1;
    const allPeriodDays = diffDays === 1 ? 'day' : `${diffDays} day`;
    const wholeInterval = DatetimeService.interval(prevInterval.from, interval.to);
    const jiraAssignees = _(contributors).map('jira_user').filter().value();
    if (includeNullContributor) {
      jiraAssignees.push(null);
    }
    if (includeFakeContributor) {
      jiraAssignees.push(FAKE_USERNAME);
    }

    const fetchTickets = async () => {
      const filteredJIRAStuff = cachedData['jira-issue-types'];
      const issueTypes = _(filteredJIRAStuff.issue_types)
        .filter((v) => !v.is_subtask && !v.is_epic)
        .map('value')
        .value();

      const tickets = await fetchJIRAMetrics(
        api,
        account,
        [allPeriodDays],
        wholeInterval,
        ['resolved'],
        excludeInactive,
        { types: issueTypes, epics },
        { assignees: jiraAssignees }
      );
      return [tickets[0].values[0].values[0], tickets[0].values[1].values[0]];
    };

    const [ticketsPrev, ticketsCurr] = !!user.account.jira ? await fetchTickets() : [0, 0];

    const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, features)?.parameters
      ?.value;

    const createdPrs = !userDetails.account.jira
      ? await fetchDevsMetrics(api, account, ['all'], prevInterval, ['prs-created'], {
          repositories,
          developers: _(contributors).uniqBy('login').value(),
          labels_include: labels,
          ...(excludedLabels ? { labels_exclude: excludedLabels } : {}),
          jira: { epics, issue_types: issueTypes },
        })
      : null;

    return {
      tickets: {
        prev: ticketsPrev,
        curr: ticketsCurr,
      },
      prevPRThroughput: createdPrs?.calculated?.[0].values.length
        ? createdPrs.calculated[0].values.reduce((acc, item) => acc + (item[0].values[0] || 0), 0) /
          createdPrs.calculated[0].values.length
        : 0,
    };
  };

  const plumber = (fetchedData, cachedData, apiContext) => {
    const { interval } = apiContext;
    const releases = cachedData['releases-metrics']['values']['all']['count'];
    const releaseRateSpecs = humanLaboralRate(interval.from, interval.to, releases);
    const currPRThroughput =
      _(Object.values(cachedData['devs-metrics'])).sumBy('prs-created') /
        Object.keys(cachedData['devs-metrics']).length || 0;

    return {
      tickets: {
        avg: fetchedData.tickets.curr,
        variation: calcVariation(fetchedData.tickets.prev, fetchedData.tickets.curr),
      },
      GHJIRAMapping: {
        avg: cachedData['prs-metrics'].values.all['done-mapped-to-jira'] * 100,
        variation: cachedData['prs-metrics'].variations['done-mapped-to-jira'],
      },
      releasedPRs: {
        avg: cachedData['prs-metrics']['values']['all']['lead-count'] || 0,
        variation: cachedData['prs-metrics']['variations']['lead-count'],
      },
      releases: {
        releaseRateSpecs,
        variation: cachedData['releases-metrics']['variations']['count'],
      },
      prThroughput: {
        avg: currPRThroughput,
        variation: calcVariation(fetchedData.prevPRThroughput, currPRThroughput),
      },
      linesReleased: {
        avg: cachedData['releases-metrics'].values.all['lines'] || 0,
        variation: cachedData['releases-metrics'].variations['lines'],
      },
    };
  };
  const prefetchedDataIds = [
    'prs-metrics',
    'releases-metrics',
    'devs-metrics',
    ...(userDetails?.account?.jira ? ['jira-issue-types'] : []),
  ];
  return (
    <DataWidget
      id="outcome-main-metrics"
      fetcher={fetcher}
      plumber={plumber}
      prefetchedDataIds={prefetchedDataIds}
    >
      <MainMetricsDataBinder definitions={definitions} />
    </DataWidget>
  );
};

const MainMetricsDataBinder = ({ definitions }) => {
  const { data, isLoading } = useDataWidget();
  const status = isLoading ? LOADING : READY;
  return <MainMetricsUI data={data} status={status} definitions={definitions} />;
};

const SummaryMainMetrics = PipelineMainMetrics;

export {
  SummaryMainMetrics,
  PipelineMainMetrics,
  CIMainMetrics,
  QualityMainMetrics,
  OutcomeMainMetrics,
};
