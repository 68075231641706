import { IColor } from './colors';

export const status = {
  danger: IColor.ui_red_100,
  info: IColor.ui_blue_100,
  primary: IColor.ui_orange_100,
  secondary: IColor.neutral_80,
  success: IColor.ui_green_100,
  warning: IColor.ui_yellow_100,
};
