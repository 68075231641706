import React, { useMemo } from 'react';

import { wrapperStyles } from '@align-components/layouts/primary/dateRangeInfo/dateRangeInfo.styles';
import { IDateRangeInfo } from '@align-components/layouts/primary/dateRangeInfo/dateRangeInfo.types';
import { useRemainingDays } from '@align-pages/goals/list/active-goals/activeGoals.hooks';
import { getCurrentRange } from '@align-services/dateService';
import { Icon, icons } from '@lib/icon';
import { useTheme } from '@styles/hooks';

const DateRangeInfo: React.FC<IDateRangeInfo> = React.memo(({ goal }) => {
  const theme = useTheme();
  const remainingDays = useRemainingDays({ goal });
  const timeFrame = useMemo(
    () => getCurrentRange({ dateFrom: goal?.valid_from, dateTo: goal?.expires_at }),
    [goal?.valid_from, goal?.expires_at]
  );

  return (
    <div css={wrapperStyles}>
      <Icon icon={icons.calendar} color={theme.color.neutral['80']} size={16} />
      <div className="timeframe">{timeFrame}</div>
      <div className="remaining-days">{remainingDays}</div>
    </div>
  );
});

export { DateRangeInfo };
