import { css } from '@emotion/react';

export const wrapperStyles = ({ color, font, spacing }) => css`
  margin-right: ${spacing.gap['07']};
  padding-bottom: ${spacing.gap['07']};

  .time-range {
    color: ${color.text.secondary};
    display: flex;
    font-size: ${font.size.default};
    font-weight: ${font.weight.regular};

    .info-icon {
      margin-left: ${spacing.gap['02']};
    }
  }

  .reference-value {
    color: ${color.text.dark};
    font-size: ${font.size.l};
    font-weight: ${font.weight.regular};
    letter-spacing: ${font.spacing.l};
    line-height: ${font.height.l};
    margin-top: ${spacing.gap['04']};
  }
`;
