import { IPlaceholderData } from '@lib/Placeholder';

export const placeholderData: IPlaceholderData = {
  height: 176,
  items: [
    {
      count: 2,
      border: true,
      height: 80,
      gap: 16,
      elements: [
        {
          // Expand button
          circle: true,
          width: 20,
          left: 14,
          top: 30,
        },
        {
          // Title
          width: 410,
          height: 20,
          left: 50,
          top: 13,
        },
        {
          // Team Avatar
          circle: true,
          width: 18,
          left: 50,
          top: 44,
        },
        {
          // Team Name
          width: 170,
          height: 15,
          left: 80,
          top: 47,
        },
        {
          // Type
          width: 100,
          height: 20,
          right: 282,
          top: 28,
        },
        {
          // Progress Bar
          width: 180,
          height: 40,
          right: 82,
          top: 20,
        },
        {
          // Menu Button
          circle: true,
          width: 30,
          right: 22,
          top: 24,
        },
      ],
    },
  ],
};
