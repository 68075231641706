import _ from 'lodash';

import DistributionChart from '@analytics-components/charts/DistributionChart';
import { HISTOGRAM_LOG_SCALE, HISTOGRAM_DEFAULT_BINS } from '@analytics-services/api';
import { fetchJIRAHistograms } from '@analytics-services/api/jira';
import { FAKE_USERNAME } from '@common-pages/Settings/constants';
import { theme } from '@styles/theme';

const mttrByPriority = {
  fetcher: async (api, cachedData, apiContext) => {
    const {
      account,
      interval,
      excludeInactive,
      contributors,
      includeNullContributor,
      includeFakeContributor,
      epics,
    } = apiContext;
    const jiraAssignees = _(contributors).map('jira_user').filter().value();
    if (includeNullContributor) {
      jiraAssignees.push(null);
    }
    if (includeFakeContributor) {
      jiraAssignees.push(FAKE_USERNAME);
    }

    const filteredJIRAStuff = cachedData['jira-priorities-all'];
    const topPriorities = _(filteredJIRAStuff.priorities).map('name').value();

    const fetch = async (priorities, ticks) => {
      const mttr = await fetchJIRAHistograms(
        api,
        account,
        interval,
        ['life-time'],
        excludeInactive,
        {
          types: ['Bug'],
          priorities,
          epics,
        },
        { assignees: jiraAssignees },
        HISTOGRAM_LOG_SCALE,
        HISTOGRAM_DEFAULT_BINS,
        ticks
      );
      return {
        frequencies: mttr[0].frequencies,
        ticks: mttr[0].ticks,
      };
    };

    const overallTopPrioritiesMTTR = await fetch(topPriorities);
    const mttr = !!overallTopPrioritiesMTTR.ticks.length
      ? await Promise.all(
          _(topPriorities)
            .map((p) => fetch([p], overallTopPrioritiesMTTR.ticks))
            .value()
        )
      : [];
    return { mttr, ticks: overallTopPrioritiesMTTR.ticks, priorities: topPriorities };
  },
  plumber: (fetchedData, cachedData, apiContext) => {
    const frequenciesSeries = _(fetchedData.mttr).map('frequencies').value();
    const frequencies = [];
    const priorities = [];
    const colors = [];
    frequenciesSeries.forEach((item, index) => {
      if (_.sum(item)) {
        frequencies.push(item);
        priorities.push(fetchedData.priorities[index]);
        colors.push(theme.color.sets.bugs[index]);
      }
    });
    const bucketCenters = _(fetchedData.ticks)
      .map((v) => v * 1000)
      .value();
    const chartData = {
      frequencies,
      bucketCenters,
    };

    return {
      empty: bucketCenters.length === 0,
      chart: {
        component: DistributionChart,
        params: {
          data: chartData,
          extra: {
            legend: {
              labels: priorities,
            },
            fillColor: colors,
            height: 350,
            maxNumberOfTicks: 6,
            margin: {
              right: 60,
            },
            baseOpacity: 0.5,
            axisLabels: {
              x: 'Mean Time To Restore',
              y: 'Bugs',
            },
            tooltip: {
              align: {
                horizontal: 'auto',
                vertical: 'top',
              },
            },
          },
        },
      },
      kpis: [],
    };
  },
  prefetchedDataIds: ['jira-priorities-all'],
};

export default mttrByPriority;
