import { DatetimeService } from '@analytics-services/datetimeService';

import {
  IShareAPI,
  RawShareFilters,
  ShareFilters,
  ShareParams,
  VersionType,
} from './shareService.types';

const FORMAT_VERSION: VersionType = 'v1';

export async function createUrl(
  api: IShareAPI,
  account: number,
  targetUrl: string,
  filters: ShareFilters
): Promise<string> {
  const body = {
    account,
    targetUrl,
    filters: filtersToRawFilters(filters),
    version: FORMAT_VERSION,
  };
  const shareId = await api.saveShare({ body });
  const baseShareUrl = `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }`;
  const shareUrl = `${baseShareUrl}/share/?id=${shareId}`;
  return shareUrl;
}

function filtersToRawFilters(filters: ShareFilters): RawShareFilters {
  return {
    ...filters,
    dateRange: filters.dateRange
      ? {
          from: filters.dateRange.from.format('YYYY-MM-DD'),
          to: filters.dateRange.to.format('YYYY-MM-DD'),
        }
      : null,
  };
}

export async function getShareParams(api: IShareAPI, id: string): Promise<ShareParams> {
  const resp = await api.getShare(id);
  return {
    ...resp,
    data: {
      ...resp.data,
      filters: {
        ...resp.data.filters,
        dateRange: resp.data.filters.dateRange
          ? DatetimeService.interval(
              resp.data.filters.dateRange.from,
              resp.data.filters.dateRange.to
            )
          : null,
      },
    },
  };
}

export * as ShareService from './shareService';
