import { css } from '@emotion/react';

export const wrapperStyles = ({ spacing }) => css`
  display: flex;
  align-items: center;
  margin-left: -${spacing.gap['04']};
`;

const editModeStyles = ({ isEditMode, color, shadow }) =>
  isEditMode
    ? { cursor: 'text', boxShadow: shadow.full, border: `1px solid ${color.neutral['80']}` }
    : { '&:hover': { cursor: 'pointer', background: color.neutral['20'] } };

export const inputStyles = ({ isEditMode, isEditable }) => ({
  font,
  color,
  spacing,
  radius,
  shadow,
}) => css`
  font-size: ${font.size.l};
  color: ${color.text.dark};
  padding: ${spacing.gap['04']};
  height: 48px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
  border: 1px solid transparent;
  border-radius: ${radius.default};
  outline: none;
  transition: background 0.3s;
  background: transparent;
  cursor: default;
  margin-left: -${spacing.gap['01']};

  ${isEditable && editModeStyles({ isEditMode, color, shadow })}
`;
