import { css } from '@emotion/react';

export const wrapperStyles = ({ color, font, spacing }) => css`
  align-items: center;
  display: flex;

  .variation-value {
    color: ${color.ui.blue['100']};
    font-size: ${font.size.default};
    font-weight: ${font.weight.regular};
    margin-left: ${spacing.gap['02']};
  }
`;
