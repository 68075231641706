import { css } from '@emotion/react';

export const itemStyles = ({ spacing }) => css`
  display: flex;
  flex-wrap: nowrap;

  margin-right: ${spacing.gap['03']};
`;

export const placeholderStyles = ({ color }) => css`
  color: ${color.neutral['80']};
`;

export const boxItemsStyles = ({ color, spacing }) => css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    max-width: 100%;
    overflow: hidden;
  }
  .content-block {
    width: 100%;
    .placeholder {
      color: ${color.neutral['80']};
    }
    .box-items {
      margin-right: ${spacing.gap['03']};
    }
  }
  .badge-block {
    padding: 0 ${spacing.gap['04']};
  }
`;
