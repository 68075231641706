import { BasicApiClass } from '@common-services/api/common/BasicApiClass';
import { IUrlParams, REST_TYPES } from '@common-services/api/common/types/apiClient';
import { ApiMode } from '@common-services/api/common/types/common';
import { ISentryMethodParams } from '@common-services/api/common/types/sentry';
import { PUBLIC_API_ENDPOINTS_CONSTANTS } from '@common-services/api/public/constants';
import {
  Contributors,
  DeveloperSummaries,
  FilterContributorsRequest,
} from '@common-services/api/public/generated-from-backend/models';

export class ContributorsApi extends BasicApiClass {
  constructor() {
    super(ApiMode.PUBLIC);
  }
  public getContributors(
    urlParams: IUrlParams<'accountId'>,
    token: string,
    sentry?: ISentryMethodParams
  ): Promise<Contributors> {
    const apiPath = this.replaceRouteLink(PUBLIC_API_ENDPOINTS_CONSTANTS.CONTRIBUTORS, urlParams);
    this.assignToken(token);
    const callApi = this.callApi<Contributors>(apiPath, REST_TYPES.GET);
    return sentry ? this.callSentry<Contributors>(callApi, sentry) : callApi;
  }
  public getFilteredContributors(
    params: FilterContributorsRequest,
    token: string,
    sentry?: ISentryMethodParams
  ): Promise<DeveloperSummaries> {
    this.assignToken(token);
    const callApi = this.callApi<DeveloperSummaries>(
      PUBLIC_API_ENDPOINTS_CONSTANTS.CONTRIBUTORS_FILTER,
      REST_TYPES.POST,
      params
    );
    return sentry ? this.callSentry<DeveloperSummaries>(callApi, sentry) : callApi;
  }
}
