import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { CacheService } from '@align-services/cacheService';
import { useAlignStore } from '@align-store/index';
import { useApi } from '@analytics-hooks';
import defaultImage from '@assets/images/default-user-image.png';
import { useUserContext } from '@common-context/User';
import { SettingsGroup } from '@common-pages/Settings';
import { useBecomeUser } from '@common-services/api/private/hooks/useBecomeUser';
import { useUpdateAccountFeature } from '@common-services/api/private/hooks/useUpdateAccountFeature';
import { useGetAccountFeature } from '@common-services/api/public/hooks/useGetAccountData';
import log from '@common-services/logger';
import { UserService } from '@common-services/userService';
import DatePicker from '@lib/DatePicker';
import { formControlStyles } from '@styles/components/forms';
import Breadcrumb from '@ui/Breadcrumb';

import ExpiryMessage from './ExpiryMessage';
import { expireSectionStyles, subtitleStyles } from './styles';

const Profile = () => {
  const { user, isGod, godMode, profileLink } = useUserContext();
  const org = UserService.getOrg(user.defaultReposet.repos);

  return (
    <>
      <Breadcrumb breadcrumb={[{ name: 'Settings' }, { name: 'Profile' }]} />
      <SettingsGroup title="Profile">
        <p className="text-secondary mt-2 mb-3">User Information</p>
        <div className="card bg-light mb-5">
          <div className="card-body d-flex align-items-center">
            <img
              className="rounded-circle mr-3"
              src={user.picture || defaultImage}
              alt=""
              width="80"
            />
            <div>
              <h3 className="text-truncate h5">
                <a
                  href={profileLink}
                  className="text-dark"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {user.name} <FontAwesomeIcon icon={faGithub} />
                </a>
              </h3>
            </div>
          </div>
        </div>

        <p className="text-secondary mt-2 mb-3">Organization Information</p>
        <div className="row">
          <div className="form-group col-6">
            <div className="input-group">
              <div className="input-group-prepend">
                <FontAwesomeIcon icon={faGithub} />
              </div>
              <input
                css={formControlStyles}
                type="text"
                id="orgNameInput"
                placeholder={org}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </SettingsGroup>

      <Admin userId={user.id} isGod={isGod} isGodMode={godMode} />
    </>
  );
};

const Admin = ({ userId, isGod, isGodMode }) => {
  const history = useHistory();
  const { ready: apiReady } = useApi(true, false);
  const [targetUserState, setTargetUserState] = useState(isGodMode ? userId : '');
  const {
    mutate: becomeUser,
    isSuccess: becomeUserIsSuccess,
    error: becomeUserError,
  } = useBecomeUser();
  const {
    mutate: updateAccountFeature,
    data: updateAccountFeatureData,
    isSuccess: updateAccountFeatureIsSuccess,
    error: updateAccountFeatureError,
  } = useUpdateAccountFeature();
  const { data: GetAccountFeatureData } = useGetAccountFeature(false);
  const onSetTeamsPath = useAlignStore((state) => state.onSetTeamsPath);

  const removeCacheQuery = async (cacheIdsArr) => {
    try {
      await CacheService.removeQuery(cacheIdsArr);
    } catch {
      log.fatal('Cache error');
    }
  };

  const expiresAt = useMemo(() => {
    const accountFeatureData = updateAccountFeatureData || GetAccountFeatureData;
    if (accountFeatureData) {
      return accountFeatureData.find((v) => v.name === 'expires_at')?.parameters || null;
    }
  }, [updateAccountFeatureData, GetAccountFeatureData]);

  const submitBecomeUserHandler = useCallback(
    (event) => {
      event.preventDefault();
      onSetTeamsPath(null);
      removeCacheQuery().then();
      becomeUser({ userId: isGodMode ? '' : targetUserState });
    },
    [isGodMode, targetUserState]
  );

  const handleDateChange = useCallback(async (newValue) => {
    updateAccountFeature([
      {
        name: 'expires_at',
        parameters: newValue,
      },
    ]);
  }, []);

  useEffect(() => {
    if (becomeUserIsSuccess) history.go();
  }, [becomeUserIsSuccess]);

  useEffect(() => {
    if (becomeUserError) log.fatal('Could not impersonate user', becomeUserError);
  }, [becomeUserError]);

  useEffect(() => {
    if (updateAccountFeatureIsSuccess) {
      log.ok('Expiring date has been updated');
      removeCacheQuery(['accountFeature']).then();
    }
  }, [updateAccountFeatureIsSuccess]);

  useEffect(() => {
    if (updateAccountFeatureError) {
      log.fatal('Could not update expiring date', updateAccountFeatureError);
    }
  }, [updateAccountFeatureError]);

  if (!isGod || !apiReady) return null;

  return (
    <SettingsGroup title="Admin">
      <p css={subtitleStyles}>Impersonate user</p>
      <div className="row">
        <form onSubmit={submitBecomeUserHandler} className="form-group col-6">
          <label>User id</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <FontAwesomeIcon icon={faUserSecret} />
            </div>
            <input
              type="text"
              className={classnames('form-control', !isGodMode && 'enabled')}
              id="targetUserIdInput"
              placeholder="github|0000000"
              value={targetUserState}
              onChange={(e) => setTargetUserState(e.target.value)}
              disabled={isGodMode}
            />
            <div className="input-group-append">
              <button className="btn btn-outline-secondary" type="submit">
                {isGodMode ? 'revert' : 'become'}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div css={expireSectionStyles}>
        <p css={subtitleStyles}>Expiring Date</p>
        <ExpiryMessage date={expiresAt} />
        <DatePicker initialValue={expiresAt} onChange={handleDateChange} />
      </div>
    </SettingsGroup>
  );
};

export default Profile;
