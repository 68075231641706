import React, {
  FormEvent,
  InputHTMLAttributes,
  KeyboardEvent,
  useCallback,
  useContext,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import { StringService } from '@common-services/stringService';
import { InputContext } from '@lib/Input/input.context';
import { Icon, icons } from '@lib/icon';
import { theme } from '@styles/theme';

import { InputTypes, IRawInputProps } from '../Input.types';
import { arrowButtonsStyles, getRawInputStyle } from './InputUI.styles';

const RawInput = React.forwardRef<HTMLInputElement, IRawInputProps>(
  (
    {
      disabled = false,
      iconColor,
      placeholder,
      value,
      width,
      withSearchIcon = false,
      autoComplete = false,
      childrenAfter = false,
      children,
      type,
      onChange,
      step = 0.1,
    },
    ref
  ) => {
    const labelId = uuidv4();
    const { register, name, testid } = useContext(InputContext);
    const handleChange = useCallback(
      (e: FormEvent<HTMLInputElement>) => onChange(e.currentTarget.value),
      [onChange]
    );

    const handleIncrement = useCallback(
      (step: InputHTMLAttributes<null>['step']) => () => {
        const newValue = StringService.isInteger(step)
          ? parseInt(value) + parseInt(step.toString())
          : (parseFloat(value) + parseFloat(step.toString())).toFixed(1);
        onChange((newValue < 0 ? 0 : newValue).toString());
      },
      [value, onChange]
    );

    const handleKeyDown = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        if (
          type === InputTypes.number &&
          StringService.isInteger(step) &&
          (e.key === '.' || e.key === ',' || e.key === '-')
        ) {
          e.preventDefault();
        }
      },
      [type]
    );

    return (
      <div className="ath-input" css={getRawInputStyle(width, type === InputTypes.number)}>
        {withSearchIcon && (
          <div className="ath-input-search-icon">
            <Icon color={iconColor} icon={icons.search} size={12} />
          </div>
        )}
        {!childrenAfter && children}
        <div className="ath-input-wrapper">
          {register ? (
            <input
              {...register(name)}
              id={`ath-text-input-${labelId}`}
              disabled={disabled}
              placeholder="&nbsp;"
              data-testid={testid}
            />
          ) : (
            <input
              disabled={disabled}
              id={`ath-text-input-${labelId}`}
              placeholder="&nbsp;"
              value={value}
              autoComplete={autoComplete ? 'on' : 'off'}
              onChange={handleChange}
              ref={ref}
              type={type}
              step={step}
              min={0}
              data-testid={testid}
              onKeyDown={handleKeyDown}
            />
          )}
          <label className="ath-input-placeholder" htmlFor={`ath-text-input-${labelId}`}>
            {placeholder}
          </label>
        </div>
        {type === InputTypes.number && (
          <div css={arrowButtonsStyles}>
            <div className="arrow-button" onClick={handleIncrement(step)}>
              <Icon color={theme.color.neutral['80']} icon={icons.caret_up} size={8} />
            </div>
            <div className="arrow-button" onClick={handleIncrement(-step)}>
              <Icon color={theme.color.neutral['80']} icon={icons.caret_down} size={8} />
            </div>
          </div>
        )}
        {childrenAfter && children}
      </div>
    );
  }
);

export { RawInput };
