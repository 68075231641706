import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { chartConfig } from '@align-pages/dashboard/config';
import { useGetAccountDetails } from '@common-services/api/public/hooks/useGetAccountData';
import { Spinner } from '@lib/Spinner';

import { MetricTemplate } from '../metric-template';
import { spinnerWrapper, templatesListStyles, wrapperStyles } from './metricOptions.styles';
import { IMetricOptions } from './metricOptions.types';

const MetricOptions: React.FC<IMetricOptions> = React.memo(({ onSelect }) => {
  const { data: accountDetailsData, isLoading: accountDetailsLoading } = useGetAccountDetails(true);
  const chartConfigAvailable = useMemo(() => {
    const dataSources = accountDetailsData?.datasources;
    if (dataSources) {
      return chartConfig.filter((metric) => dataSources.includes(metric.group));
    }
  }, [accountDetailsData]);
  return (
    <div css={wrapperStyles}>
      <div className="page-content">
        <div className="title">Add a New Metric</div>
        <div className="subtitle">
          Browse our catalog and select the metric that is more relevant to your needs.
        </div>
        {accountDetailsLoading && (
          <div css={spinnerWrapper}>
            <Spinner loading />
          </div>
        )}
        {!accountDetailsLoading && (
          <div css={templatesListStyles}>
            {chartConfigAvailable?.map((metric) => (
              <MetricTemplate key={uuidv4()} metric={metric} onSelect={onSelect} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
});

export { MetricOptions };
