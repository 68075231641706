import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import Upgrade02 from '@assets/images/upgrade/upgrade_02.svg';
import Upgrade03 from '@assets/images/upgrade/upgrade_03.svg';
import Upgrade04 from '@assets/images/upgrade/upgrade_04.svg';
import { GH_SETTINGS_LINK, CHANGELOG_CI_INSIGHTS_LINK } from '@common-pages/Settings/constants';

const Upgrade = ({ section }) => {
  return (
    <div className="container" css={{ paddingTop: 200 }}>
      <div className="row text-center">
        <div className="col">
          <h2 className="text-dark h3 mt-5 mb-2">
            Requires additional GitHub permissions to show CI insights.
          </h2>
          <p className="text-secondary mb-5">
            Go to the app{' '}
            <a href={GH_SETTINGS_LINK} rel="noreferrer" target="_blank">
              settings
            </a>{' '}
            and allow read access to GitHub actions, checks, deployments, environments and commit
            statuses.
          </p>
        </div>
      </div>
      <div className="row text-center mb-3">
        <div className="col-4">
          <div className="p-2">
            <img alt="" className="mb-5" src={Upgrade04} />
            <h3 className="mb-3 h4">
              <span className="text-dark">Success Ratio</span>
            </h3>
            <ul className="list-unstyled upgrade-list">
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Overall
                Distribution
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Timelines per
                Repository
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Flakiness
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Analysis per PR
                Size
              </li>
            </ul>
          </div>
        </div>
        <div className="col-4">
          <div className="p-2">
            <img alt="" className="mb-5" src={Upgrade03} />
            <h3 className="mb-3 h4">
              <span className="text-dark">Run Time</span>
            </h3>
            <ul className="list-unstyled upgrade-list">
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Average Suite
                Run Time
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Timelines per
                Repository
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Run Time per
                Pull Request
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Analysis per PR
                Size
              </li>
            </ul>
          </div>
        </div>
        <div className="col-4">
          <div className="p-2">
            <img alt="" className="mb-5" src={Upgrade02} />
            <h3 className="mb-3 h4">
              <span className="text-dark">CI Consumption</span>
            </h3>
            <ul className="list-unstyled upgrade-list">
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Overall
                Distribution
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Timelines per
                Repository
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Daily
                Consumption per Contributor
              </li>
              <li>
                <FontAwesomeIcon className="text-dark-orange mr-1" icon={faCheck} /> Concurrency
                Analysis
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-12 text-center">
          <a
            className="btn btn-orange btn-large mr-3"
            css={{ minWidth: 250 }}
            href={GH_SETTINGS_LINK}
            rel="noreferrer"
            target="_blank"
          >
            Settings
          </a>
          <a
            className="btn btn-secondary btn-large"
            css={{ minWidth: 250 }}
            href={CHANGELOG_CI_INSIGHTS_LINK}
            rel="noreferrer"
            target="_blank"
          >
            Find out more
          </a>
        </div>
      </div>
    </div>
  );
};

export default Upgrade;
