import React from 'react';

//TODO: refactor to use Tabs component from lib instead
import Tabs from '@analytics-components/Tabs';
import { useBreadcrumb } from '@analytics-context/Breadcrumb';
import ContentTeamsComparisonTable from '@analytics-pages/Compare/Teams/Table';
import ContentTeamsComparisonTimelines from '@analytics-pages/Compare/Teams/Timelines';

const ContentTeamsComparison = () => {
  const { subsection } = useBreadcrumb();
  const tabs = [
    {
      title: 'Timelines',
      content: <ContentTeamsComparisonTimelines />,
      link: '/analytics/compare/teams/timelines',
    },
    {
      title: 'Table',
      content: <ContentTeamsComparisonTable />,
      link: '/analytics/compare/teams/table',
    },
  ];

  return <Tabs tabs={tabs} initialValue={subsection === 'table' ? 1 : 0} leftAligned />;
};

export default ContentTeamsComparison;
