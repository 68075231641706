import _ from 'lodash';
import React from 'react';

import { BigText, DefaultXYTooltip } from '@analytics-components/charts/Tooltip';
import VerticalBarChart from '@analytics-components/charts/VerticalBarChart';
import { firstNRepos } from '@analytics-components/insights/Helper';
import { SimpleKPI } from '@analytics-components/insights/KPI';
import { github } from '@common-services/format';
import * as NumberService from '@common-services/numberService';
import { theme } from '@styles/theme';

const prsCreatedByRepos = {
  title: 'Repositories',
  description: 'Identify the repositories that show the biggest number of pull requests created.',
  plumber: (fetchedData, cachedData, apiContext) => {
    const prsPerRepo = _(cachedData['repos-metrics']).map((v, k) => ({
      repo: github.repoName(k),
      count: v.opened || 0,
    }));

    const average = prsPerRepo.meanBy('count') || 0;

    const chartData = prsPerRepo
      .orderBy(['count'], ['desc'])
      .filter((pr) => !!pr.count)
      .take(firstNRepos)
      .value();
    const KPIsData = {
      average,
    };
    const axisKeys = {
      x: 'repo',
      y: 'count',
    };

    return {
      empty: chartData.filter((v) => v[axisKeys.y] !== 0).length === 0,
      chart: {
        component: VerticalBarChart,
        params: {
          data: chartData,
          extra: {
            axisKeys: axisKeys,
            axisLabels: {
              y: 'Pull Requests',
            },
            color: theme.color.ui.blue[100],
            tooltip: {
              align: {
                horizontal: 'auto',
                vertical: 'top',
              },
              template: DefaultXYTooltip,
              y: (v) => <BigText content={`${v.y} Pull Requests`} />,
            },
          },
        },
      },
      kpis: [
        {
          title: { text: 'Pull Requests Created', bold: true },
          subtitle: { text: 'Per Repository' },
          component: SimpleKPI,
          params: {
            value: NumberService.round(KPIsData.average, 1),
          },
        },
      ],
    };
  },
  prefetchedDataIds: ['repos-metrics'],
};

export default prsCreatedByRepos;
