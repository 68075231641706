import { css } from '@emotion/react';

export const wrapperStyles = ({ color, font, spacing }) => css`
  align-items: flex-start;
  display: flex;

  .desc {
    margin-right: ${spacing.gap['02']};
    min-width: 0;

    .invite-text {
      color: ${color.text.primary};
      font-size: ${font.size.default};
      font-weight: ${font.weight.regular};
    }

    .url-text {
      color: ${color.text.secondary};
      font-size: ${font.size.default};
      font-weight: ${font.weight.regular};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;
