import { css } from '@emotion/react';

export const tooltipStyles = ({ color, font, spacing, radius, shadow }) => css`
  background: ${color.neutral.white};
  border-radius: ${radius.default};
  box-shadow: ${shadow.full};
  color: ${color.neutral[80]};
  margin-top: 100px;
  padding: ${spacing.gap['04']};
  width: max-content;

  table {
    margin-top: ${spacing.gap['04']};

    .priority-name {
      align-items: center;
      display: flex;
      margin-right: ${spacing.gap['04']};

      span {
        color: ${color.text.secondary};
        font-size: ${font.size.xs};
        font-weight: ${font.weight.light};
        margin-left: ${spacing.gap['02']};
        white-space: nowrap;
      }
    }

    .value {
      color: ${color.text.dark};
      font-size: ${font.size.xs};
      font-weight: ${font.weight.regular};
      text-align: right;
      white-space: nowrap;
    }
  }
`;

export const listItemStyles = ({ radius }, color) => css`
  background-color: ${color};
  border-radius: ${radius.default};
  height: 4px;
  width: 4px;
`;

export const legendWrapperStyles = css`
  text-align: center;
  width: 100%;
`;
