import _ from 'lodash';
import moment from 'moment';
import React from 'react';

import { BigText, DateRangeBigNumber } from '@analytics-components/charts/Tooltip';
import VerticalBarChart from '@analytics-components/charts/VerticalBarChart';
import { SimpleKPI, withUnit, UNITS } from '@analytics-components/insights/KPI';
import { palette } from '@analytics-pages/delivery-pipeline/insights/release';
import { dateTime, humanLaboralRate } from '@common-services/dateService';
import * as NumberService from '@common-services/numberService';

const releaseCreationTimeline = {
  plumber: (fetchedData, cachedData, apiContext) => {
    const customGranularity = cachedData['releases-metrics'].customGranularity;
    const timeseriesGranularity = customGranularity === 'month' ? 'custom-aligned' : 'custom';
    const dayReleases = fetchedData.releaseMetrics?.['day']
      ? fetchedData.releaseMetrics['day']['count']
      : null;
    const releases = cachedData['releases-metrics']['values'][timeseriesGranularity]['count'];
    const releasesCount = cachedData['releases-metrics']['values']['all']['count'];
    const avgRateSpecs = humanLaboralRate(
      apiContext.interval.from,
      apiContext.interval.to,
      releasesCount
    );

    return {
      chartData: releases.map((v) => ({
        day: v.date,
        value: v.value,
      })),
      interval: apiContext.interval,
      customGranularity,
      KPIsData: {
        avgRateSpecs,
        highestDay: _(dayReleases)
          .map((v) => ({
            day: moment(v.date).format('dddd'),
            value: v.value,
          }))
          .groupBy('day')
          .map((series, k) => ({
            day: k,
            value: _(series).map('value').mean(),
          }))
          .maxBy('value'),
      },
      axisKeys: {
        x: 'day',
        y: 'value',
      },
    };
  },
  factory: (computed) => {
    const tickFormat = computed.customGranularity === 'month' ? dateTime.month : dateTime.monthDay;
    const tooltip = {
      align: {
        horizontal: 'auto',
        vertical: 'top',
      },
      renderBigFn: (v) => <BigText content={`${v.y} releases`} />,
    };
    if (_(['month', 'week']).includes(computed.customGranularity)) {
      tooltip.template = DateRangeBigNumber;
      tooltip.interval = { ...computed.interval };
      tooltip.granularity = computed.customGranularity;
    }

    return {
      content: [
        {
          empty: computed.chartData.filter((v) => v[computed.axisKeys.y]).length === 0,
          chart: {
            component: VerticalBarChart,
            title: 'Timeline',
            description: 'Measure the evolution of the release frequency through time.',
            params: {
              data: computed.chartData,
              timeMode: true,
              extra: {
                axisLabels: {
                  y: 'Releases',
                },
                axisFormat: {
                  tickFormat: {
                    x: tickFormat,
                  },
                },
                margin: { bottom: 60 },
                maxNumberOfTicks: 5,
                axisKeys: computed.axisKeys,
                color: palette.barColor,
                tooltip,
              },
            },
          },
          kpis: [
            {
              title: { text: 'Average Release Frequency', bold: true },
              component: SimpleKPI,
              params: {
                ...formatRate(computed.KPIsData.avgRateSpecs, UNITS.release),
              },
            },
            {
              title: { text: 'Average Day', bold: true },
              subtitle: { text: 'With Highest Release Frequency' },
              component: SimpleKPI,
              params: {
                value: computed.KPIsData.highestDay?.day
                  ? `${computed.KPIsData.highestDay.day}`
                  : '',
                secondary:
                  computed.KPIsData.highestDay?.day &&
                  `(${withUnit(NumberService.round(computed.KPIsData.highestDay.value, 1))})`,
              },
            },
          ],
        },
      ],
    };
  },
};

const formatRate = (rateSpecs, unitConfig) => {
  return {
    value: rateSpecs[0],
    unit: {
      singular: `${unitConfig.singular} / ${rateSpecs[1]}`,
      plural: `${unitConfig.plural} / ${rateSpecs[1]}`,
    },
  };
};

export default releaseCreationTimeline;
