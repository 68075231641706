import { color } from '@styles/theme/colors';

import { IPlaceholderItem } from './Placeholder.types';

const getFlatData = (data: IPlaceholderItem[]): IPlaceholderItem[] =>
  data.reduce((a, c) => [...a, ...new Array(c.count).fill(c)], []);

export const generateBackground = (data: IPlaceholderItem[]): string => {
  const flatData = getFlatData(data);
  const bgImage = [];
  const bgSize = [];
  const bgPos = [];

  flatData.forEach((item, index) => {
    const itemPosition = (item.height + item.gap) * index;

    item.elements.forEach((element) => {
      let leftPos =
        element.right !== undefined && element.right !== null
          ? `calc(100% - ${element.right}px)`
          : `${element.left}px`;
      if (element.circle) {
        bgImage.push(
          `radial-gradient(${element.width}px circle at ${element.width / 2}px ${
            element.width / 2
          }px, ${color.neutral['40']} 50%, transparent 51%)`
        );
      } else {
        bgImage.push(`linear-gradient(${color.neutral['40']} 100%, transparent 0)`);
      }

      bgPos.push(`${leftPos} ${itemPosition + element.top}px`);
      bgSize.push(`${element.width}px ${element.height || element.width}px`);
    });

    if (item.border) {
      bgImage.push(
        `linear-gradient(${color.neutral.white} 100%, transparent 0), linear-gradient(${color.neutral['40']} 100%, transparent 0)`
      );
      bgPos.push(`1px ${itemPosition + 1}px, 0 ${itemPosition}px`);
      bgSize.push(`calc(100% - 2px) ${item.height - 2}px, calc(100%) ${item.height}px`);
    }
  });

  return `
    background-image: ${bgImage.join(',')};
    background-position: ${bgPos.join(',')};
    background-size: ${bgSize.join(',')};`;
};
