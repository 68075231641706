import React from 'react';

import Chevron from '@ui/Chevron';

import { CellTeamExpandable } from './CellTeamExpandable';
import { CellTeamSingleLine } from './CellTeamSingleLine';
import { countMembers } from './helpers';
import { cellTeamWrapper } from './styles';
import { IRow } from './types';

interface Props {
  row: IRow;
  devLevel: number;
}

export const CellTeam: React.FC<Props> = React.memo(({ row, devLevel }) => {
  const hasChildTeam = (row: IRow): boolean =>
    row.subRows.some((sr) => sr.subRows && sr.subRows.length > 0);
  const { onClick, ...wrapperProps } = row.getToggleRowExpandedProps();
  const hasChevron = hasChildTeam(row) || Boolean(devLevel);
  const membersCount: number = countMembers(row);

  return (
    <div {...wrapperProps} onClick={hasChevron ? onClick : null} css={cellTeamWrapper({ row })}>
      {row.canExpand ? (
        <>
          {hasChevron && <Chevron isExpanded={row.isExpanded} />}
          <CellTeamExpandable row={row} hasChevron={hasChevron} membersCount={membersCount} />
        </>
      ) : (
        <CellTeamSingleLine row={row} />
      )}
    </div>
  );
});
