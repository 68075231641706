import React, { useCallback, useContext, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { PrimaryLayoutContext } from '@align-components/layouts/primary/context';
import { metricsConfig } from '@align-constants';
import { filterBarOptions } from '@align-pages/goals/single/components/filter-bar/filterBar.constants';
import { IMetricsConfigNames } from '@align-types/constants';
import { useGetAccountDetails } from '@common-services/api/public/hooks/useGetAccountData';
import { ButtonMenu } from '@lib/button-menu';
import { FilterPanel, FILTER_TYPE_OPTIONS, FilterType, IFilterTypeOption } from '@lib/filter-panel';
import { MetricBadge } from '@lib/metric-badge';

import { wrapperStyles } from './filterBar.styles';
import { IFilterBar, IMemoizedFilters } from './filterBar.types';

type IUnsupportedFiltersList = {
  [key in IMetricsConfigNames]?: FilterType[];
};

const unsupportedFiltersList: IUnsupportedFiltersList = {
  [IMetricsConfigNames.jiraResolved]: [FilterType.REPOSITORY],
  [IMetricsConfigNames.jiraLeadTime]: [FilterType.REPOSITORY],
  [IMetricsConfigNames.jiraLifeTime]: [FilterType.REPOSITORY],
  [IMetricsConfigNames.jiraLeadTimeThreshold]: [FilterType.REPOSITORY],
  [IMetricsConfigNames.jiraLifeTimeThreshold]: [FilterType.REPOSITORY],
  [IMetricsConfigNames.prAllMappedToJira]: [
    FilterType.JIRA_ISSUE_TYPES,
    FilterType.JIRA_PRIORITIES,
    FilterType.JIRA_PROJECTS,
  ],
};

export const FilterBar: React.FC<IFilterBar> = React.memo(({ metric }) => {
  const { onFiltersChange } = useContext(PrimaryLayoutContext);
  const [selectedFilters, setSelectedFilters] = useState<FilterType[]>([]);
  const { data: accountDetailsData, isLoading: accountDetailsLoading } = useGetAccountDetails(true);

  // Use order of the FILTER_TYPE_OPTIONS to sort the filters
  const sortingOrder = useMemo(() => Object.keys(FILTER_TYPE_OPTIONS), []);

  const onSelectCallback = useCallback(
    (newFilter) => {
      setSelectedFilters((prevState) =>
        [...prevState, newFilter].sort((a, b) => sortingOrder.indexOf(a) - sortingOrder.indexOf(b))
      );
    },
    [selectedFilters, sortingOrder]
  );

  const onRemoveCallback = useCallback(
    (filterName) => {
      setSelectedFilters((prevState) => prevState.filter((item) => item !== filterName));
      onFiltersChange({ filterName: filterName, update: [] });
    },
    [selectedFilters]
  );

  const unsupportedFilters = useMemo(
    () => (metric && unsupportedFiltersList[metric] ? unsupportedFiltersList[metric] : []),
    [metric]
  );

  // Create memoized components for all the active filters to have memoized state
  const memoizedFilters = useMemo<IMemoizedFilters>(
    () =>
      Object.values(FILTER_TYPE_OPTIONS).reduce(
        (a, filter) => ({
          ...a,
          [filter.value]: (
            <FilterPanel
              key={uuidv4()}
              filterName={filter.value}
              onRemove={onRemoveCallback}
              onFiltersChange={onFiltersChange}
            />
          ),
        }),
        {}
      ),
    []
  );

  // Render the filter that selected
  const renderFilters = useMemo<React.ReactNode>(
    () =>
      selectedFilters
        .filter((filterName) => {
          const isUnsupported = unsupportedFilters.includes(filterName);
          if (isUnsupported) {
            onRemoveCallback(filterName);
          }
          return !isUnsupported;
        })
        .map((filter) => memoizedFilters[filter]),
    [selectedFilters, memoizedFilters, unsupportedFilters]
  );

  const options = useMemo<IFilterTypeOption[]>(() => {
    if (accountDetailsData?.datasources) {
      return filterBarOptions
        .filter((value) => {
          return (
            !unsupportedFilters?.includes(value.type) &&
            accountDetailsData?.datasources.includes(value.group)
          );
        })
        .map((value) => FILTER_TYPE_OPTIONS[value.type]);
    }
  }, [unsupportedFilters, accountDetailsData]);

  return (
    <div css={wrapperStyles}>
      {metric && metricsConfig[metric] && <MetricBadge metric={metricsConfig[metric]} />}
      {renderFilters}
      {options?.length ? (
        <ButtonMenu
          label={accountDetailsLoading ? 'Loading...' : '+ Add Filter'}
          options={options}
          onSelect={onSelectCallback}
          disabledOptions={selectedFilters}
        />
      ) : null}
    </div>
  );
});
