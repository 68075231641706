import { css } from '@emotion/react';

export const wrapperStyles = ({ spacing }) => css`
  display: flex;
  margin-bottom: ${spacing.gap['07']};
  margin-top: ${spacing.gap['06']};

  .left-side {
    margin-left: ${spacing.gap['11']};
    margin-right: ${spacing.gap['11']};
    margin-top: ${spacing.gap['08']};
    min-width: 220px;
  }

  .right-side {
    width: 100%;
  }
`;
