import React from 'react';

import Badge from '@analytics-components/Badge';
import { BigNumber, SecondaryNumber } from '@analytics-components/Typography';
import { isNumber } from '@common-services/format';
import * as NumberService from '@common-services/numberService';

/*
  Component that shows a simple KPI content insidea BoxKPI.

  - params: object{
        value: number (required)
        variation: number (optional)
        variationMeaning: NEGATIVE_IS_BETTER | POSITIVE_IS_BETTER (optional, default:POSITIVE_IS_BETTER)
        unit: string | object{
            singular: string
            plural: string
        } (optional)
    }

  E.g:

  const title = "a title";
  const subtitle = "a subtitle";
  const params = {
      value: 10,
      variation: -2,
      unit: {
          singular: "Pull request",
          plural: "Pull requests"
      }
  };

  <BoxKPI title={title} subtitle={subtitle}>
    <SimpleKPI {...params}></SimpleKPI>
  </BoxKPI>

 */
export const SimpleKPI = ({ params }) => {
  if (!isNumber(params.value) && typeof params.value !== 'string') {
    return '';
  }

  return (
    <div className="font-weight-bold">
      {params.value && <BigNumber content={withUnit(params.value, params.unit)} />}
      {params.secondary && <SecondaryNumber content={params.secondary} />}
      {typeof params.variation !== 'undefined' && (
        <Badge
          value={NumberService.round(params.variation)}
          trend={params.variationMeaning}
          className="ml-2"
        />
      )}
    </div>
  );
};

export const MultiKPI = ({ params }) => (
  <div className="font-weight-bold">
    {params.map(
      (v, i) =>
        (isNumber(params.value) || typeof params.value !== 'string') && (
          <BigNumber
            key={i}
            content={withUnit(v.value, v.unit)}
            className={i === params.length - 1 ? '' : 'mr-3'}
          />
        )
    )}
  </div>
);

export const withUnit = (value, unitConf) => {
  if (value === null || value === undefined) return '';
  const unit = getUnit(value, unitConf);
  return value + (unit ? ` ${unit}` : '');
};

export const getUnit = (value, unitConf) => {
  if (!isNumber(value) && typeof value !== 'string') {
    return '';
  }

  let unit = '';
  if (typeof unitConf === 'string') {
    unit = unitConf;
  } else if (unitConf?.singular && unitConf?.plural) {
    unit = value !== 1 ? unitConf.plural : unitConf.singular;
  }

  return unit;
};

export const UNITS = {
  repository: {
    singular: 'repository',
    plural: 'repositories',
  },
  release: {
    singular: 'release',
    plural: 'releases',
  },
  pr: {
    singular: 'pull request',
    plural: 'pull requests',
  },
  developer: {
    singular: 'developer',
    plural: 'developers',
  },
  reviewer: {
    singular: 'reviewer',
    plural: 'reviewers',
  },
  line: {
    singular: 'line',
    plural: 'lines',
  },
};
