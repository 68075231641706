import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalBarSeries,
  ChartLabel,
  LineMarkSeries,
  DiscreteColorLegend,
} from 'react-vis';

import Tooltip, {
  onValueChange,
  onValueReset,
  DateBigNumber,
} from '@analytics-components/charts/Tooltip';
import defaults from '@analytics-components/charts/defaults';
import { dateTime } from '@common-services/dateService';
import { hexToRGBA } from '@utils/colors';

const Chart = ({ data, extra, ...rest }) => (
  <div style={{ background: 'white' }}>
    <VerticalBarChart data={data} extra={extra} {...rest} />
  </div>
);

const mapData = (extra, timeMode) => (data) => {
  return _(data)
    .map((v) => ({
      x: timeMode ? v[extra.axisKeys.x]?.getTime() : v[extra.axisKeys.x],
      y: v[extra.axisKeys.y] || 0,
      tooltip: v.tooltip,
      ...v,
    }))
    .value();
};

const VerticalBarChart = ({ data, extra, timeMode }) => {
  const [currentHover, setCurrentHover] = useState(null);
  const marginLeft = extra.margin?.left ?? defaults.marginLeft;
  const marginTop = extra.legend ? 30 : 15; // It should be at least 30, only when this chart has a DiscreteColorLegend
  const marginBottom = extra.margin?.bottom ?? 60;

  const handleValueMouseOver = useCallback(
    (datapoint) => onValueChange(datapoint, currentHover, setCurrentHover),
    [currentHover]
  );

  const handleValueMouseOut = useCallback(() => {
    if (!extra?.tooltip?.persistent) {
      onValueReset(setCurrentHover);
    }
  }, [extra]);

  const handleMouseLeave = useCallback(() => onValueReset(setCurrentHover), []);

  const mappedData = useMemo(() => mapData(extra, timeMode), [extra, timeMode]);

  const formattedData = useMemo(
    () =>
      extra.stacked || extra.clustered
        ? data.map((dataArr) => mappedData(dataArr))
        : [mappedData(data)],
    [data, extra, mappedData]
  );
  const color = useMemo(() => Array.prototype.concat(extra.color), [extra]);

  formattedData.forEach((arrItem, index) => {
    arrItem.forEach((item) => {
      item.color =
        extra.stacked || (currentHover && item.x === currentHover.x)
          ? color[index]
          : hexToRGBA(color[index], 0.7);
    });
  });

  const ChartTooltip = useMemo(
    () =>
      timeMode
        ? extra?.tooltip?.template
          ? extra.tooltip.template
          : DateBigNumber
        : extra?.tooltip?.template || Tooltip,
    [extra, timeMode]
  );

  const yField = useMemo(() => extra.axisKeys?.y, [extra]);
  const minValue = useMemo(
    () =>
      data?.length > 0 && !extra.stacked && !extra.clustered
        ? _.minBy(data, (d) => d[yField])[yField]
        : 0,
    [data, extra, yField]
  );
  const maxValue = useMemo(
    () =>
      data?.length > 0 && !extra.stacked && !extra.clustered
        ? _.maxBy(data, (d) => d[yField])[yField]
        : 1,
    [data, extra, yField]
  );

  const yDomain = useMemo(
    () => ({
      min: minValue === maxValue ? 0 : _.max([0, minValue - (maxValue - minValue) / 2]),
      max: maxValue,
    }),
    [maxValue, minValue]
  );

  const truncateXTick = useCallback((value) => {
    if (value.length > 12) {
      return `${value.substring(0, 11)}...`;
    }
    return value;
  }, []);

  if (!data || data.length === 0) {
    return <></>;
  }

  const maxY =
    !extra.stacked && !extra.clustered
      ? _(formattedData).maxBy('y')
      : _(formattedData).flatMap().maxBy('y'); // TO FIX
  const maxNumberOfTicks =
    (extra.maxNumberOfTicks || 10) > maxY?.y ? maxY?.y || 0 : extra.maxNumberOfTicks || 10;

  const averagedData = [];
  if (extra.average) {
    averagedData.push({
      x: formattedData[0][0].x,
      y: extra.average.value,
    });
    averagedData.push({
      x: formattedData[0][formattedData[0].length - 1].x,
      y: extra.average.value,
    });
  }

  const notTooManyTicks = extra.stacked ? data[0].length < 15 : data.length < 15;

  return (
    <div onMouseLeave={handleMouseLeave} onClick={handleMouseLeave}>
      <FlexibleWidthXYPlot
        height={extra.height || 300}
        margin={{ top: marginTop, left: marginLeft, bottom: marginBottom }}
        xType="ordinal"
        stackBy={extra.stacked && 'y'}
        yDomain={extra.stacked || extra.clustered ? undefined : [yDomain.min, yDomain.max]}
      >
        {extra.legend && (
          <DiscreteColorLegend
            className="chart-legend"
            items={extra.legend}
            orientation="horizontal"
          />
        )}
        <XAxis
          tickLabelAngle={
            extra.axisFormat?.tickAngle?.x !== undefined && notTooManyTicks
              ? extra.axisFormat.tickAngle.x
              : -45
          }
          tickFormat={
            extra.axisFormat?.tickFormat?.x
              ? extra.axisFormat.tickFormat.x
              : timeMode
              ? dateTime.monthDay
              : truncateXTick
          }
          tickSize={extra.axisFormat?.tickSize?.x}
          tickPadding={extra.axisFormat?.tickPadding?.x}
          tickSizeOuter={0}
          tickSizeInner={0}
        />

        <HorizontalGridLines tickTotal={maxNumberOfTicks} />
        <YAxis
          tickTotal={maxNumberOfTicks}
          tickFormat={extra.axisFormat?.tickFormat?.y || ((y) => y)}
          tickSize={extra.axisFormat?.tickSize?.y}
          tickPadding={extra.axisFormat?.tickPadding?.y}
          tickSizeOuter={0}
          tickSizeInner={0}
        />
        {extra.axisLabels &&
          extra.axisLabels.y &&
          buildChartLabel(extra.axisLabels.y, 'y', marginLeft)}

        {formattedData.map((arrItem, index) => {
          return (
            <VerticalBarSeries
              barWidth={extra.barWidth || 0.5}
              colorType="literal"
              data={arrItem}
              key={index}
              onValueMouseOver={handleValueMouseOver}
              onValueMouseOut={handleValueMouseOut}
              stroke="none"
              style={{ transition: 'all 0.2s ease-in-out' }}
            />
          );
        })}

        {averagedData.length > 0 && (
          <LineMarkSeries
            data={averagedData}
            strokeWidth={0}
            stroke={extra.average.color}
            strokeStyle="dashed"
            fill="white"
            animation="stiff"
          />
        )}

        {extra.tooltip && (
          <ChartTooltip
            value={currentHover}
            dataPoint={currentHover}
            data={formattedData[0]}
            {...extra?.tooltip}
          />
        )}
      </FlexibleWidthXYPlot>
    </div>
  );
};

const buildChartLabel = (text, which, marginLeft) => {
  const labelParams = {
    x: {
      includeMargin: false,
      xPercent: 0.5,
      yPercent: 1.0,
      style: {
        y: 60,
        textAnchor: 'middle',
      },
    },
    y: {
      includeMargin: false,
      xPercent: 0.0,
      yPercent: 0.5,
      style: {
        textAnchor: 'middle',
        transform: 'rotate(-90)',
        y: -(marginLeft - 20),
      },
    },
  }[which];

  return <ChartLabel text={text} {...labelParams} />;
};

export default Chart;
