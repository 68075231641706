import React from 'react';

import { ContentTypes } from '@analytics-types/enums';
import { Icon, icons } from '@lib/icon';
import { theme } from '@styles/theme';

import { CellMetric } from './CellMetric';
import { CellTeam } from './CellTeam';
import { sortByTotalValues } from './helpers';
import { infoStyles, infoHeader } from './styles';
import {
  ITeamAllocationTableContext,
  ITeamAllocationTableData,
  ITableColDefinition,
} from './types';

export const getTableColsDefs = (
  context: ITeamAllocationTableContext,
  data: ITeamAllocationTableData,
  contentType: ContentTypes
): ITableColDefinition[] => [
  {
    Header: 'Team',
    accessor: 'name',
    Cell: CellTeam,
  },
  {
    Header: contentType === ContentTypes.PRS ? 'Pull Requests' : 'Issues',
    accessor: 'pullRequests',
    Cell: ({ value, cell, row, state }) => (
      <CellMetric
        value={value}
        cell={cell}
        row={row}
        state={state}
        formatter={(value) => {
          return (
            <div css={infoStyles}>
              <span>
                <Icon
                  icon={contentType === ContentTypes.PRS ? icons.nav_pr : icons.issues}
                  size={14}
                  color={theme.color.text.secondary}
                />
              </span>
              <span className="number">{value ?? 0}</span>
            </div>
          );
        }}
      />
    ),
  },
  ...sortByTotalValues(data).map((w) => {
    return {
      Header: (
        <div css={infoHeader}>
          <div className="dot" style={{ background: `#${w.color}` }} />
          {w.name ?? `N/A`}
        </div>
      ),
      accessor: `workTypesValues.${w.name}`,
      Cell: ({ value, cell, row, state }) => (
        <CellMetric
          value={value}
          cell={cell}
          row={row}
          state={state}
          formatter={(value) => {
            return (
              <div css={infoStyles}>
                <span className="number">
                  {value === undefined || value === null ? '0%' : `${value.toFixed(0)}%`}
                </span>
              </div>
            );
          }}
        />
      ),
    };
  }),
];
