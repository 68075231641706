/**
 * {{ title }}
 * Server version: {{ server_version }} built on {{ build_date }} @ [{{ commit }}](https://github.com/athenianco/athenian-api/commit/{{ commit }})  Origin GitHub repository: [athenianco/api-spec](https://github.com/athenianco/api-spec).  Authorization persists between sessions. 🛡️ means that the endpoint requires account admin privileges. 👑 means that the endpoint is only accessible to Athenian authorities. <details> <summary>List of common server errors.</summary> * __500__ endpoint crashed. We reported the incident to Sentry and will fix it soon! Please include the value of `instance` in the response if you contact the support. Repeating the request will not help, most probably. * __501__ some functions are missing, either because they are not implemented yet or a non-critical runtime dependency is not satisfied. Repeating the request will not help, guaranteed. * __502__ server crashed badly, either due to a memory access violation in native code or running out of memory. We reported this incident to Sentry and will fix it soon! Try repeating the request. * __503__ server has not fully launched yet, e.g. hasn't connected to the database; server is shutting down; we are experiencing a partial outage. Try repeating the request. * __504__ endpoint took too much time and was interrupted. We reported the incident to Sentry and will see how to improve the performance. Repeating the request will not help, most probably. </details>
 *
 * Contact: admin@athenian.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The JIRAIssueType model module.
 * @module model/JIRAIssueType
 */
class JIRAIssueType {
    /**
     * Constructs a new <code>JIRAIssueType</code>.
     * Details about a JIRA issue type.
     * @alias module:model/JIRAIssueType
     * @param name {String} Name of the issue type.
     * @param normalizedName {String} Normalized name of the issue type. For example, \"Sub-tásks\" becomes \"subtask\".
     * @param count {Number} Number of issues that satisfy the filters and belong to this type.
     * @param image {String} Icon URL.
     * @param project {String} Bound project identifier.
     * @param isSubtask {Boolean} Value indicating whether this issue type refers to a sub-task.
     * @param isEpic {Boolean} Value indicating whether this issue type refers to an epic.
     */
    constructor(name, normalizedName, count, image, project, isSubtask, isEpic) { 
        
        JIRAIssueType.initialize(this, name, normalizedName, count, image, project, isSubtask, isEpic);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, name, normalizedName, count, image, project, isSubtask, isEpic) { 
        obj['name'] = name;
        obj['normalized_name'] = normalizedName;
        obj['count'] = count;
        obj['image'] = image;
        obj['project'] = project;
        obj['is_subtask'] = isSubtask;
        obj['is_epic'] = isEpic;
    }

    /**
     * Constructs a <code>JIRAIssueType</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/JIRAIssueType} obj Optional instance to populate.
     * @return {module:model/JIRAIssueType} The populated <code>JIRAIssueType</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new JIRAIssueType();

            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('normalized_name')) {
                obj['normalized_name'] = ApiClient.convertToType(data['normalized_name'], 'String');
            }
            if (data.hasOwnProperty('count')) {
                obj['count'] = ApiClient.convertToType(data['count'], 'Number');
            }
            if (data.hasOwnProperty('image')) {
                obj['image'] = ApiClient.convertToType(data['image'], 'String');
            }
            if (data.hasOwnProperty('project')) {
                obj['project'] = ApiClient.convertToType(data['project'], 'String');
            }
            if (data.hasOwnProperty('is_subtask')) {
                obj['is_subtask'] = ApiClient.convertToType(data['is_subtask'], 'Boolean');
            }
            if (data.hasOwnProperty('is_epic')) {
                obj['is_epic'] = ApiClient.convertToType(data['is_epic'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * Name of the issue type.
 * @member {String} name
 */
JIRAIssueType.prototype['name'] = undefined;

/**
 * Normalized name of the issue type. For example, \"Sub-tásks\" becomes \"subtask\".
 * @member {String} normalized_name
 */
JIRAIssueType.prototype['normalized_name'] = undefined;

/**
 * Number of issues that satisfy the filters and belong to this type.
 * @member {Number} count
 */
JIRAIssueType.prototype['count'] = undefined;

/**
 * Icon URL.
 * @member {String} image
 */
JIRAIssueType.prototype['image'] = undefined;

/**
 * Bound project identifier.
 * @member {String} project
 */
JIRAIssueType.prototype['project'] = undefined;

/**
 * Value indicating whether this issue type refers to a sub-task.
 * @member {Boolean} is_subtask
 */
JIRAIssueType.prototype['is_subtask'] = undefined;

/**
 * Value indicating whether this issue type refers to an epic.
 * @member {Boolean} is_epic
 */
JIRAIssueType.prototype['is_epic'] = undefined;






export default JIRAIssueType;

