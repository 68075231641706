import React from 'react';

import IconButton from '@lib/IconButton';
import { Avatar } from '@lib/avatar';
import { icons } from '@lib/icon';

import { tagLabelStyles } from './tagLabel.styles';
import { ITagLabel, COLOR_TYPE, LABEL_SIZE } from './tagLabel.types';

export const TagLabel: React.FC<ITagLabel> = React.memo(
  ({
    avatarColor,
    avatarIcon,
    avatarImage,
    avatarType,
    bgType = COLOR_TYPE.light,
    disabled = false,
    label,
    labelSize = LABEL_SIZE.default,
    truncate = false,
    onRemove,
  }) => {
    return (
      <div
        data-cy="tags-container-tag-wrapper"
        css={tagLabelStyles(bgType, truncate, labelSize, !!onRemove)}
      >
        <Avatar
          color={avatarColor}
          icon={avatarIcon}
          image={avatarImage}
          type={avatarType}
          label={label}
          size={labelSize === LABEL_SIZE.small ? 13 : 20}
        />
        <div data-cy="tags-container-tag-label" className="label-wrapper">
          {label}
        </div>
        {onRemove && (
          <div data-cy="tags-container-tag-control">
            <IconButton
              buttonSize={20}
              disabled={disabled}
              icon={icons.cross}
              iconSize={8}
              onClick={onRemove}
            />
          </div>
        )}
      </div>
    );
  }
);
