import { css } from '@emotion/react';

export const wrapperStyles = ({ level, isLink }) => ({ color, radius, spacing }) => css`
  align-items: center;
  background-color: ${level ? color.neutral.white : color.neutral['20']};
  border-top-left-radius: ${radius.default};
  border-top-right-radius: ${radius.default};
  display: flex;
  min-height: 60px;
  transition: background-color 300ms linear;

  &:not(:last-child) {
    border-bottom: 1px solid ${color.neutral['40']};
  }

  &:last-child {
    border-bottom-left-radius: ${radius.default};
    border-bottom-right-radius: ${radius.default};
  }

  ${isLink &&
  `
    cursor: pointer;

    &:hover {
      background-color: ${level ? color.neutral['10'] : color.neutral.white};
    }
  `}
`;

export const titleStyles = ({ spacing }) => css`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: ${spacing.gap['04']};
  padding-bottom: ${spacing.gap['04']};
  padding-right: ${spacing.gap['07']};

  .separator {
    flex-grow: 1;
  }
`;

export const mainColStyles = css`
  display: flex;
  width: 35%;
`;

export const expandStyles = ({ level }) => ({ spacing }) => css`
  width: ${spacing.gap['08']};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: calc(${spacing.gap['03']} + ${spacing.gap['07']} * ${level});
  margin-right: ${spacing.gap['03']};
`;

export const nameColStyles = ({ color, font, spacing }) => css`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    color: ${color.text.dark};
    font-size: ${font.size.s};
    letter-spacing: ${font.spacing.s};
    margin-bottom: ${spacing.gap['02']};

    &.with-link:hover {
      text-decoration: underline;
    }
  }

  .description {
    margin-bottom: ${spacing.gap['02']};
  }
`;

export const badgesStyles = ({ spacing }) => css`
  display: flex;
  gap: ${spacing.gap['04']};
`;

export const progressAreaStyles = ({ spacing }) => css`
  display: flex;
  gap: ${spacing.gap['06']};
`;

export const chartStyles = css`
  display: flex;
  justify-content: flex-end;
  height: 36px;
  min-width: 100px;
`;

export const actionsStyles = ({ spacing }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${spacing.gap['04']};
  width: 26px;
`;
