import React from 'react';

import { FilterTestIds } from '@lib/Filter/__tests__/testIds.ids';
import { Icon, icons } from '@lib/icon';

import { getStyle } from './styles';

interface Props {
  placeholder?: string;
  inputValue: string;
  onInputChange?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  onClear?: () => void;
}

export const Filter: React.FC<Props> = React.memo(
  ({ placeholder, inputValue, onInputChange, onClear }) => (
    <div css={getStyle}>
      <div className="value-container">
        <div className="inputContainer">
          <Icon
            className="searchIcon"
            icon={icons.search}
            size={12}
            dataTestId={FilterTestIds.searchIcon}
          />
          <input
            data-cy="selector-input"
            placeholder={placeholder}
            type="text"
            value={inputValue}
            onChange={onInputChange}
            data-testid={FilterTestIds.input}
          />
          <Icon
            onClick={onClear}
            className={'closeIcon'}
            icon={icons.cross}
            size={12}
            dataTestId={FilterTestIds.closeIcon}
          />
        </div>
      </div>
    </div>
  )
);
