import _ from 'lodash';

import BubbleChart from '@analytics-components/charts/BubbleChart';
import { UserReviewer } from '@analytics-components/charts/Tooltip';
import { SimpleKPI } from '@analytics-components/insights/KPI';
import {
  CREATED,
  REVIEWED,
} from '@analytics-pages/delivery-pipeline/insights/review/ReviewActivityPanel';
import { fetchDevsMetrics } from '@analytics-services/api';
import { getFeature, featuresList } from '@analytics-services/flags';
import { github } from '@common-services/format';
import * as NumberService from '@common-services/numberService';
import { theme } from '@styles/theme';

const reviewActivityBubble = {
  prefetchedDataIds: ['prs-metrics', 'avatars'],
  fetcher: async (api, cachedData, apiContext) => {
    const metrics = ['prs-reviewed', 'prs-created', 'pr-comments'];
    const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, apiContext.features)
      ?.parameters?.value;
    const fetchMetrics = async () => {
      return fetchDevsMetrics(
        api,
        apiContext.account,
        ['all'],
        apiContext.interval,
        metrics,
        {
          repositories: apiContext.repositories,
          developers: _(apiContext.contributors).uniqBy('login').value(),
          labels_include: apiContext.labels,
          ...(excludedLabels ? { labels_exclude: excludedLabels } : {}),
          jira: { epics: apiContext.epics, issue_types: apiContext.issueTypes },
        },
        null
      );
    };

    const metricsRes = await fetchMetrics();
    return {
      metrics: metricsRes?.calculated.length > 0 ? metricsRes.calculated[0] : { values: [] },
    };
  },
  plumber: (fetchedData, cachedData) => {
    const distinctReviewedPRs = cachedData['prs-metrics']['values']['all']['review-count'];

    const metrics = fetchedData.metrics;

    const totalPRsCreated = _(metrics.values)
      .map((v) => v[0].values[CREATED])
      .sum();

    const totalReviewedPRs = _(metrics.values)
      .map((v) => v[0].values[REVIEWED])
      .sum();

    const totalReviewers = _(metrics.values)
      .filter((v) => v[0].values[REVIEWED] > 0)
      .value().length;

    const computed = {
      chartData: _(metrics.values)
        .map((v, index) => {
          const devName = metrics.for.developers[index];
          const author = github.userName(devName);
          return {
            developer: author,
            reviews: v[0].values[REVIEWED],
            prs: v[0].values[CREATED],
            size: 5,
            tooltip: {
              author,
              image: cachedData.avatars[devName],
              stats: {
                prsCount: v[0].values[CREATED],
                commentsCount: v[0].values[REVIEWED],
              },
            },
          };
        })
        .filter((v) => v.reviews > 0 || v.prs > 0)
        .orderBy(['reviews'], ['desc'])
        .take(20)
        .value(),
      axisKeys: {
        x: 'reviews',
        y: 'prs',
        size: 'size',
        label: 'developer',
      },
      KPIsData: {
        createdPRs: totalPRsCreated,
        avgReviewedPRsPerDev: totalReviewedPRs / totalReviewers,
      },
      avatars: cachedData.avatars,
    };

    const {
      chartData,
      axisKeys,
      KPIsData: { createdPRs, avgReviewedPRsPerDev },
    } = computed;

    return {
      empty: chartData.length === 0,
      chart: {
        component: BubbleChart,
        params: {
          data: chartData,
          extra: {
            useImages: true,
            yAxis: {
              imageMask: 'circle',
            },
            axisKeys,
            axisLabels: {
              x: 'Pull Requests Reviewed',
              y: 'Pull Requests Created',
            },
            color: theme.color.ui.turquoise[100],
            tooltip: { template: UserReviewer },
          },
        },
      },
      kpis: [
        {
          title: { text: 'Pull Requests Reviewed', bold: true },
          subtitle: { text: 'Per Developer' },
          component: SimpleKPI,
          params: {
            value: NumberService.round(avgReviewedPRsPerDev, 1),
          },
        },
        {
          title: { text: 'Ratio of Pull Requests', bold: true },
          subtitle: { text: 'Reviewed / Created' },
          component: SimpleKPI,
          params: {
            value: `${distinctReviewedPRs}/${createdPRs}`,
          },
        },
      ],
    };
  },
};

export default reviewActivityBubble;
