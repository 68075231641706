import { IColor } from './colors';

export const sets = {
  five: [
    IColor.ui_purple_100,
    IColor.ui_lightpink_100,
    IColor.ui_orange_100,
    IColor.ui_lightorange_100,
    IColor.ui_yellow_100,
  ],
  six: [
    IColor.ui_turquoise_100,
    IColor.ui_purple_100,
    IColor.ui_lightpink_100,
    IColor.ui_orange_100,
    IColor.ui_lightorange_100,
    IColor.ui_yellow_100,
  ],
  bugs: [
    IColor.ui_darkred_100,
    IColor.ui_red_100,
    IColor.ui_yellow_100,
    IColor.ui_darkgreen_100,
    IColor.ui_green_100,
    IColor.neutral_60,
  ],
  colorSelection: [
    IColor.ui_blue_100,
    IColor.ui_lightblue_100,
    IColor.ui_darkgreen_100,
    IColor.ui_green_100,
    IColor.ui_turquoise_100,
    IColor.ui_blue_50,
    IColor.ui_purple_100,
    IColor.ui_purple_50,
    IColor.ui_lightpink_100,
    IColor.ui_lightpink_50,
    IColor.ui_orange_100,
    IColor.ui_lightorange_50,
    IColor.ui_red_100,
    IColor.ui_red_50,
    IColor.ui_pink_100,
    IColor.ui_pink_50,
    IColor.ui_yellow_100,
    IColor.ui_lightorange_100,
  ],
  issueTypes: [
    IColor.ui_orange_100,
    IColor.ui_lightorange_50,
    IColor.ui_ocean_100,
    IColor.ui_lightblue_100,
    IColor.ui_blue_100,
  ],
};
