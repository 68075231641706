import React from 'react';

import ContentSwitcher from './components/ContentSwitcher';
import IssueSelector from './components/IssueSelector';
import Insights from './insights';

const WorkTypes = () => {
  return (
    <>
      <div className="row mx-0 px-0 mb-4">
        <div className="col-md-9 mx-0 pl-0 pr-1">
          <IssueSelector />
        </div>
        <div className="col-md-3 mx-0 pl-3 pr-0">
          <ContentSwitcher />
        </div>
      </div>
      <Insights />
    </>
  );
};

export default WorkTypes;
