import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { useMeasure } from '@analytics-hooks';
import { optionType, optionNoColorType } from '@lib/Selector/types';

import SingleLabelUI from './ui';

const SingleLabel = ({ hasLongItem, index, item, noAvatar, notLast, onMeasure }) => {
  const theme = useTheme();
  const [labelRef, { width }] = useMeasure();

  useEffect(() => {
    // calculate the width and send it to parent for storing
    if (width) onMeasure(index, width + parseFloat(theme.spacing.gap['03']) * 10);
  }, [width]);

  return (
    <SingleLabelUI
      hasLongItem={hasLongItem}
      item={item}
      noAvatar={noAvatar}
      notLast={notLast}
      ref={labelRef}
    />
  );
};

const SingleLabelPropTypes = {
  hasLongItem: PropTypes.bool,
  index: PropTypes.number.isRequired,
  item: PropTypes.oneOfType([optionType, optionNoColorType]).isRequired,
  noAvatar: PropTypes.bool,
  notLast: PropTypes.bool,
  onMeasure: PropTypes.func,
};

SingleLabel.propTypes = SingleLabelPropTypes;

export default SingleLabel;
