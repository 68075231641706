import { useLocalStorage, writeStorage } from '@rehooks/local-storage';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { filtersStorageKeys } from '@analytics-context/Filters';
import { useApi } from '@analytics-hooks';
import { fetchWorkTypes } from '@analytics-services/api';

export const contentTypes = {
  prs: 0,
  issues: 1,
};

const Context = React.createContext({
  appliedWorkTypes: [],
  contentType: contentTypes.prs,
  workTypes: [],
  workTypesReady: false,
});

export const useAllocationContext = () => useContext(Context);

const AllocationProvider = ({ children }) => {
  const { api, context, ready: apiReady } = useApi();

  const [appliedWorkTypes, setAppliedWorkTypes] = useState([]);
  const [workTypes, setWorkTypes] = useState([]);
  const [workTypesReady, setWorkTypesReady] = useState(false);
  const [contentType, setContentType] = useState(null);

  const [lastWorkTypes] = useLocalStorage(filtersStorageKeys.WORK_TYPES(context.account), []);
  const [lastContentType] = useLocalStorage(
    filtersStorageKeys.ALLOCATION_CONTENT_TYPE(context.account),
    context?.account?.jira ? contentTypes.issues : contentTypes.prs
  );

  useEffect(() => {
    setContentType(
      _.isNil(lastContentType)
        ? context?.account?.jira
          ? contentTypes.issues
          : contentTypes.prs
        : lastContentType
    );
  }, [context.account, lastContentType]);

  useEffect(() => {
    if (!apiReady) return;
    (async () => {
      const fetchedWorkTypes = await fetchWorkTypes(api, context.account);
      setWorkTypes(fetchedWorkTypes.sort((a, b) => (a.name > b.name ? 1 : -1)));
      setWorkTypesReady(true);

      if (!appliedWorkTypes.length) {
        if (lastWorkTypes?.length) {
          setAppliedWorkTypes(
            fetchedWorkTypes.filter((workType) =>
              lastWorkTypes.find((name) => name === workType.name)
            )
          );
        } else {
          // in case there were no issue types selected set it to top 5
          const topIssueTypes = fetchedWorkTypes.slice(0, 5);
          setAppliedWorkTypes(topIssueTypes);
          const persistedKey = filtersStorageKeys.WORK_TYPES(context.account);
          writeStorage(
            persistedKey,
            topIssueTypes.map((v) => v.name)
          );
        }
      }
    })();
  }, [apiReady, context.account]);

  const onWorkTypesChange = useCallback(
    (selectedWorkTypes) => {
      setWorkTypesReady(false);

      const persistedKey = filtersStorageKeys.WORK_TYPES(context.account);
      writeStorage(
        persistedKey,
        selectedWorkTypes.map((workType) => workType.name)
      );

      setAppliedWorkTypes(selectedWorkTypes);
      setWorkTypesReady(true);
    },
    [context.account]
  );

  const onContentTypeChange = useCallback(
    (value) => {
      setContentType(value);
      const persistedKey = filtersStorageKeys.ALLOCATION_CONTENT_TYPE(context.account);
      writeStorage(persistedKey, value);
    },
    [context.account]
  );

  return (
    <Context.Provider
      value={{
        contentType,
        appliedWorkTypes,
        workTypes,
        workTypesReady,
        onContentTypeChange,
        onWorkTypesChange,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AllocationProvider;
