import { useTheme } from '@emotion/react';
import { extent } from 'd3-array';
import { curveMonotoneX } from 'd3-shape';
import React from 'react';
import { FlexibleXYPlot, AreaSeries, LineSeries, GradientDefs } from 'react-vis';

import { hexToRGBA } from '@utils/colors';

const filterEmptyValues = (v) => v !== null;

const extendedRange = (data, mapFn, topMarginPercentage = 0, minMarginPercentage = 0) => {
  let [min, max] = extent(data || [], mapFn);
  min = min || 0;
  max = max || 0;

  const minMaxDifference = max - min;
  max += (minMaxDifference * topMarginPercentage) / 100;
  min -= (minMaxDifference * minMarginPercentage) / 100;

  return [min, max];
};

const TOP_MARGIN_PERCENTAGE = 5;
const BOTTOM_MARGIN_PERCENTAGE = 20;

const CleanAreaChart = ({ fill, stroke, data, dataStatus }) => {
  const theme = useTheme();

  const key = () => Math.random().toString(36).substring(2, 15);
  const gradients = [];

  var fillColor, strokeColor;

  if (fill && fill.stops) {
    const id = 'FillGradient-' + key();
    gradients.push(<LinearGradient key={id} id={id} config={fill} />);
    fillColor = 'url(#' + id + ')';
  } else {
    fillColor = fill;
  }

  if (stroke && stroke.stops) {
    const id = 'StrokeGradient-' + key();
    gradients.push(<LinearGradient key={id} id={id} config={stroke} />);
    strokeColor = 'url(#' + id + ')';
  } else {
    strokeColor = stroke;
  }

  let [yMin, yMax] = extendedRange(
    data,
    (d) => d.y,
    TOP_MARGIN_PERCENTAGE,
    BOTTOM_MARGIN_PERCENTAGE
  );

  const dataPoints = data ? data.filter((v) => filterEmptyValues(v.y)) : [];

  if (dataPoints.length === 0) {
    // TODO: both `yDomain` and `data` are using some magic numbers just for drawing
    // a straight horiziontal line at the right height, those should be fixed properly
    return (
      <FlexibleXYPlot yDomain={[0, 300]} margin={{ left: 0, right: 0, top: 0, bottom: 0 }}>
        <LineSeries
          data={[
            { x: 0, y: 0 },
            { x: 300, y: 0 },
          ]}
          strokeWidth={5}
          color={theme.color.neutral[20]}
          animation="stiff"
        />
      </FlexibleXYPlot>
    );
  }

  // If there is only one datapoint, it must be shown a straight line
  // it requires to add a new extra datapoint with the same value of the existent one in order to create an horizontal line.
  if (dataPoints.length === 1) {
    const timestamp = dataPoints[0].x.getTime?.();
    timestamp &&
      dataPoints.push({ y: dataPoints[0].y, x: new Date(timestamp + 24 * 60 * 60 * 1000) });
  }

  // If all datapoints have the same 'y', it must be shown a straight line
  // it requires to adjust the Y range and to avoid using a stroke gradient which does not work in horizontal lines.
  if (!dataPoints.find((v) => v.y !== dataPoints[0].y)) {
    [yMin, yMax] = [dataPoints[0].y - 1, dataPoints[0].y + 1];
    strokeColor = stroke.stops?.[0].color || stroke;
  }

  return (
    <FlexibleXYPlot yDomain={[yMin, yMax]} margin={{ left: 0, right: 0, top: 0, bottom: 0 }}>
      <GradientDefs>{gradients}</GradientDefs>
      <AreaSeries
        data={dataPoints}
        curve={curveMonotoneX}
        stroke="none"
        fill={fillColor}
        animation="stiff"
      />
      <LineSeries data={dataPoints} curve={curveMonotoneX} color={strokeColor} animation="stiff" />
    </FlexibleXYPlot>
  );
};

const LinearGradient = ({ id, config }) => (
  <linearGradient id={id} gradientTransform={`rotate(${getRotation(config.direction)})`}>
    <GradientStops stops={config.stops} />
  </linearGradient>
);

const GradientStops = ({ stops }) =>
  stops.map((stop, i) => (
    <stop
      offset={stop.offset}
      key={i}
      stopColor={hexToRGBA(stop.color)}
      stopOpacity={stop.opacity}
    />
  ));

export const vertical = 'vertical';
export const horizontal = 'horizontal';
const getRotation = (direction) => {
  switch (direction) {
    case vertical:
      return 90;
    case horizontal:
      return 0;
    default:
      return typeof direction === 'number' ? direction : 0;
  }
};

export default CleanAreaChart;
