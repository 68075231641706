import Highcharts from 'highcharts';

import { IBreakdownOptionItem } from '@align-pages/dashboard/reducers/breakdownOptionsReducer.types';
import { ISeriesValues } from '@align-services/api/metricService.types';
import { DashboardChart } from '@common-services/api/private/generated-from-backend/models';
import { TeamTree } from '@common-services/api/private/generated-from-backend/models';
import {
  JIRAIssueType,
  JIRALabel,
  JIRAPriority,
} from '@common-services/api/public/generated-from-backend/models';
import { ChartTypeEnum } from '@lib/chart';
import { IColor } from '@styles/theme/colors/colors';

export interface IUseBreakdownOptionsData {
  teams?: TeamTree[];
  repositories?: string[];
  jira_priorities?: JIRAPriority[];
  jira_issue_types?: JIRAIssueType[];
  jira_labels?: JIRALabel[];
}

export interface IUseDashboardChartDataReturn {
  data: ISeriesValues;
  isLoading: boolean;
}

export type IUseDashboardChartsParams = Pick<
  DashboardChart,
  'metric' | 'date_to' | 'date_from' | 'filters' | 'group_by'
>;

export interface IUseDashboardChartsReturn {
  options: Highcharts.Options;
  chartType: ChartTypeEnum;
  isLoading: boolean;
  seriesNames?: string[];
  seriesColors?: IColor[];
  groupByOptions?: IBreakdownOptionItem[];
}

export enum ChartOperationType {
  REMOVE = 'remove',
  CREATE = 'create',
  REORDER = 'reorder',
  EDIT = 'edit',
}

export interface IUseDashboardResponseHandle {
  isSuccess: boolean;
  isError: boolean;
  operationType: ChartOperationType;
}

export type IChartOperationText = {
  [key in ChartOperationType]: {
    successText: string;
    errorText: string;
  };
};

export const CHART_OPERATION_TEXT: IChartOperationText = {
  [ChartOperationType.CREATE]: {
    successText: 'New chart added to the dashboard.',
    errorText: 'Creating a new chart has failed. Please try again later.',
  },
  [ChartOperationType.REMOVE]: {
    successText: 'Chart is removed from the dashboard.',
    errorText: 'Removing the chart has failed. Please try again later.',
  },
  [ChartOperationType.REORDER]: {
    successText: 'Charts order is updated',
    errorText:
      'Changing the order of the charts has failed. Current order is not saved. Please try again later.',
  },
  [ChartOperationType.EDIT]: {
    successText: 'Chart has been successfully updated',
    errorText: 'Something went wrong while updating the chart. Please try again later.',
  },
};

export interface IUseDashboardResponseHandleReturn {
  isLoading: boolean;
}
