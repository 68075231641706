import PropTypes from 'prop-types';
import React from 'react';

import ButtonUI from '@analytics-components/ButtonUI';

import { getWrapperStyle, getCancelButtonStyle, getApplyButtonStyle } from './styles';

const Footer = ({ isApplyDisabled, onCancel, onApply }) => {
  const [cancelBtnText, applyBtnText] = ['Cancel', 'Apply'];
  return (
    <div data-cy="selector-footer" css={getWrapperStyle}>
      <ButtonUI label={cancelBtnText} onClick={onCancel} style={getCancelButtonStyle} />
      <ButtonUI
        label={applyBtnText}
        disabled={isApplyDisabled}
        onClick={onApply}
        style={(theme) => getApplyButtonStyle(theme, isApplyDisabled)}
      />
    </div>
  );
};

Footer.propTypes = {
  isApplyDisabled: PropTypes.bool,
  onCancel: PropTypes.func,
  onApply: PropTypes.func,
};

export default Footer;
