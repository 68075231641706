import { css } from '@emotion/react';

export const getFormStyle = ({ spacing }) => css`
  & > div.block {
    margin-bottom: ${spacing.gap['06']};
  }
  & > div.submit {
    padding-top: ${spacing.gap['06']};
  }
`;
