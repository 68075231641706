/**
 * {{ title }}
 * Server version: {{ server_version }} built on {{ build_date }} @ [{{ commit }}](https://github.com/athenianco/athenian-api/commit/{{ commit }})  Origin GitHub repository: [athenianco/api-spec](https://github.com/athenianco/api-spec).  Authorization persists between sessions. 🛡️ means that the endpoint requires account admin privileges. 👑 means that the endpoint is only accessible to Athenian authorities. <details> <summary>List of common server errors.</summary> * __500__ endpoint crashed. We reported the incident to Sentry and will fix it soon! Please include the value of `instance` in the response if you contact the support. Repeating the request will not help, most probably. * __501__ some functions are missing, either because they are not implemented yet or a non-critical runtime dependency is not satisfied. Repeating the request will not help, guaranteed. * __502__ server crashed badly, either due to a memory access violation in native code or running out of memory. We reported this incident to Sentry and will fix it soon! Try repeating the request. * __503__ server has not fully launched yet, e.g. hasn't connected to the database; server is shutting down; we are experiencing a partial outage. Try repeating the request. * __504__ endpoint took too much time and was interrupted. We reported the incident to Sentry and will see how to improve the performance. Repeating the request will not help, most probably. </details>
 *
 * Contact: admin@athenian.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import JIRAFilter from './JIRAFilter';
import PullRequestEvent from './PullRequestEvent';
import PullRequestStage from './PullRequestStage';
import PullRequestWith from './PullRequestWith';

/**
 * The FilterPullRequestsRequest model module.
 * @module model/FilterPullRequestsRequest
 */
class FilterPullRequestsRequest {
    /**
     * Constructs a new <code>FilterPullRequestsRequest</code>.
     * Filters for &#x60;/filter/pull_requests&#x60;. Empty &#x60;properties&#x60; passes through all PRs matched by the other filters.
     * @alias module:model/FilterPullRequestsRequest
     * @param account {Number} Session account ID.
     * @param dateFrom {Date} PRs must be updated later than or equal to this date.
     * @param dateTo {Date} PRs must be updated earlier than or equal to this date.
     * @param _in {Array.<String>} Set of repositories. An empty list raises a bad response 400. Duplicates are automatically ignored.
     * @param excludeInactive {Boolean} Value indicating whether PRs without events in the given time frame shall be ignored.
     */
    constructor(account, dateFrom, dateTo, _in, excludeInactive) { 
        
        FilterPullRequestsRequest.initialize(this, account, dateFrom, dateTo, _in, excludeInactive);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, account, dateFrom, dateTo, _in, excludeInactive) { 
        obj['account'] = account;
        obj['date_from'] = dateFrom;
        obj['date_to'] = dateTo;
        obj['in'] = _in;
        obj['exclude_inactive'] = excludeInactive;
    }

    /**
     * Constructs a <code>FilterPullRequestsRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/FilterPullRequestsRequest} obj Optional instance to populate.
     * @return {module:model/FilterPullRequestsRequest} The populated <code>FilterPullRequestsRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new FilterPullRequestsRequest();

            if (data.hasOwnProperty('account')) {
                obj['account'] = ApiClient.convertToType(data['account'], 'Number');
            }
            if (data.hasOwnProperty('date_from')) {
                obj['date_from'] = ApiClient.convertToType(data['date_from'], 'Date');
            }
            if (data.hasOwnProperty('date_to')) {
                obj['date_to'] = ApiClient.convertToType(data['date_to'], 'Date');
            }
            if (data.hasOwnProperty('timezone')) {
                obj['timezone'] = ApiClient.convertToType(data['timezone'], 'Number');
            }
            if (data.hasOwnProperty('in')) {
                obj['in'] = ApiClient.convertToType(data['in'], ['String']);
            }
            if (data.hasOwnProperty('events')) {
                obj['events'] = ApiClient.convertToType(data['events'], [PullRequestEvent]);
            }
            if (data.hasOwnProperty('stages')) {
                obj['stages'] = ApiClient.convertToType(data['stages'], [PullRequestStage]);
            }
            if (data.hasOwnProperty('with')) {
                obj['with'] = PullRequestWith.constructFromObject(data['with']);
            }
            if (data.hasOwnProperty('labels_include')) {
                obj['labels_include'] = ApiClient.convertToType(data['labels_include'], ['String']);
            }
            if (data.hasOwnProperty('labels_exclude')) {
                obj['labels_exclude'] = ApiClient.convertToType(data['labels_exclude'], ['String']);
            }
            if (data.hasOwnProperty('exclude_inactive')) {
                obj['exclude_inactive'] = ApiClient.convertToType(data['exclude_inactive'], 'Boolean');
            }
            if (data.hasOwnProperty('jira')) {
                obj['jira'] = JIRAFilter.constructFromObject(data['jira']);
            }
            if (data.hasOwnProperty('updated_from')) {
                obj['updated_from'] = ApiClient.convertToType(data['updated_from'], 'Date');
            }
            if (data.hasOwnProperty('updated_to')) {
                obj['updated_to'] = ApiClient.convertToType(data['updated_to'], 'Date');
            }
            if (data.hasOwnProperty('limit')) {
                obj['limit'] = ApiClient.convertToType(data['limit'], 'Number');
            }
            if (data.hasOwnProperty('environment')) {
                obj['environment'] = ApiClient.convertToType(data['environment'], 'String');
            }
        }
        return obj;
    }


}

/**
 * Session account ID.
 * @member {Number} account
 */
FilterPullRequestsRequest.prototype['account'] = undefined;

/**
 * PRs must be updated later than or equal to this date.
 * @member {Date} date_from
 */
FilterPullRequestsRequest.prototype['date_from'] = undefined;

/**
 * PRs must be updated earlier than or equal to this date.
 * @member {Date} date_to
 */
FilterPullRequestsRequest.prototype['date_to'] = undefined;

/**
 * Local time zone offset in minutes, used to adjust `date_from` and `date_to`.
 * @member {Number} timezone
 */
FilterPullRequestsRequest.prototype['timezone'] = undefined;

/**
 * Set of repositories. An empty list raises a bad response 400. Duplicates are automatically ignored.
 * @member {Array.<String>} in
 */
FilterPullRequestsRequest.prototype['in'] = undefined;

/**
 * Allowed PR events that happened between `date_from` and `date_to`.
 * @member {Array.<module:model/PullRequestEvent>} events
 */
FilterPullRequestsRequest.prototype['events'] = undefined;

/**
 * Allowed PR stages that are defined at `date_to`.
 * @member {Array.<module:model/PullRequestStage>} stages
 */
FilterPullRequestsRequest.prototype['stages'] = undefined;

/**
 * @member {module:model/PullRequestWith} with
 */
FilterPullRequestsRequest.prototype['with'] = undefined;

/**
 * At least one of these labels must be present in each returned PR.
 * @member {Array.<String>} labels_include
 */
FilterPullRequestsRequest.prototype['labels_include'] = undefined;

/**
 * None of these labels must be present in each returned PR.
 * @member {Array.<String>} labels_exclude
 */
FilterPullRequestsRequest.prototype['labels_exclude'] = undefined;

/**
 * Value indicating whether PRs without events in the given time frame shall be ignored.
 * @member {Boolean} exclude_inactive
 */
FilterPullRequestsRequest.prototype['exclude_inactive'] = undefined;

/**
 * @member {module:model/JIRAFilter} jira
 */
FilterPullRequestsRequest.prototype['jira'] = undefined;

/**
 * Paginate the response: the returned PRs are updated no older than this timestamp, including the beginning.
 * @member {Date} updated_from
 */
FilterPullRequestsRequest.prototype['updated_from'] = undefined;

/**
 * Paginate the response: the returned PRs are updated no later than this timestamp, not including the ending.
 * @member {Date} updated_to
 */
FilterPullRequestsRequest.prototype['updated_to'] = undefined;

/**
 * Maximum number of pull requests to return. The list is sorted by the last update timestamp. `null` means no limit.
 * @member {Number} limit
 */
FilterPullRequestsRequest.prototype['limit'] = undefined;

/**
 * Target environment for the deployment events, impacts `events` and `stages`.
 * @member {String} environment
 */
FilterPullRequestsRequest.prototype['environment'] = undefined;






export default FilterPullRequestsRequest;

