import { useTheme } from '@emotion/react';
import React from 'react';

const LegendSelect = ({ items, onClick }) => {
  const theme = useTheme();

  return (
    <div
      className="btn-group"
      role="group"
      style={{
        borderColor: theme.color.neutral[40],
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '5px',
      }}
    >
      {items.map((item, i) => {
        const style = {
          backgroundColor: theme.color.neutral.white,
          boxShadow: 'none',
          borderWidth: 0,
          opacity: item.active ? 1 : 0.5,
        };
        const circleRadius = 5;
        return (
          <button
            key={i}
            type="button"
            className="btn btn-light"
            onClick={() => onClick(i)}
            style={style}
          >
            <svg
              style={{
                display: 'inline-block',
                width: `${circleRadius * 2}px`,
                height: `${circleRadius * 2}px`,
                marginRight: `${circleRadius}px`,
              }}
            >
              <circle cx={circleRadius} cy={circleRadius} r={circleRadius} fill={item.color} />
            </svg>
            <span
              className="vertical-align-middle"
              css={{
                display: 'inline-block',
                maxWidth: '80px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {item.label}
            </span>
          </button>
        );
      })}
    </div>
  );
};

export default LegendSelect;
