import React from 'react';

import { CIMainMetrics as MainMetrics } from '@analytics-components/MainMetrics';
import Tabs from '@analytics-components/Tabs';
import ChecksTable from '@analytics-pages/Tables/Checks';
import { dateTime } from '@common-services/dateService';
import { number } from '@common-services/format';

import SummaryBox from './SummaryBox';
import Insights from './insights';

const mainMetricsDefinitions = [
  {
    title: 'Success Ratio',
    dataGetter: (data) => [
      {
        raw: data.successRatio.avg,
        formatted: number.percentage(data.successRatio.avg * 100, 1),
      },
      data.successRatio.variation,
    ],
    hint: {
      primaryText:
        'Ratio of successful suite runs over all suite runs completed (skipped ones are excluded).',
    },
    negativeIsBetter: false,
  },
  {
    title: 'Suite Run Time',
    dataGetter: (data) => [
      {
        raw: data.suiteRunTime.avg,
        formatted: dateTime.timeValue(data.suiteRunTime.avg),
      },
      data.suiteRunTime.variation,
    ],
    hint: {
      primaryText: 'Average run time for the completed suite runs (skipped ones are excluded).',
      secondaryText: 'Calculated up to the 95th percentile.',
    },
    negativeIsBetter: true,
  },
  {
    title: 'Run Time',
    dataGetter: (data) => [
      {
        raw: data.runTime.avg,
        formatted: (
          <>
            {dateTime.timeValue(data.runTime.avg)}
            <span className="text-secondary text-s ml-1">/ PR</span>
          </>
        ),
      },
      data.runTime.variation,
    ],
    hint: {
      primaryText: 'Average run time per pull request.',
      secondaryText: 'Calculated up to the 95th percentile.',
    },
    negativeIsBetter: true,
  },
  {
    title: 'Flakiness',
    dataGetter: (data) => [
      {
        raw: data.flakiness.avg,
        formatted: number.percentage(data.flakiness.avg * 100, 1),
      },
      data.flakiness.variation,
    ],
    hint: {
      primaryText:
        'Percentage of suite runs triggered by the same commit but having different outcomes: one successful and one failed.',
    },
    negativeIsBetter: true,
  },
];

const tabs = [
  {
    title: 'Insights',
    content: <Insights />,
  },
  {
    title: 'Github Checks',
    content: <ChecksTable />,
  },
];

const Content = () => {
  return (
    <>
      <MainMetrics definitions={mainMetricsDefinitions} />
      <SummaryBox />
      <Tabs tabs={tabs} initialValue={0} />
    </>
  );
};

export default Content;
