import { css } from '@emotion/react';

const getStyle = ({ spacing, radius, color, shadow }, withAllOption, withTagsContainer) => css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${spacing.gap['0']};
  background: ${color.neutral.white};
  border: 1px solid ${color.neutral['40']};
  box-sizing: border-box;
  box-shadow: ${shadow.full};
  border-radius: ${radius.default};
  flex: none;
  margin: ${spacing.gap['0']};
  overflow: hidden;

  ${withTagsContainer &&
  `
  & > div:first-of-type {
    border: 0;
    border-bottom: 1px solid ${color.neutral['40']};
    border-radius: ${radius.sharp};
    width: 100%;    
  }
  `}

  & div.menu {
    margin-top: ${withAllOption ? `-${spacing.gap['04']}` : spacing.gap['0']};
    width: 100%;

    & div.menu-options {
      width: 100%;
      overflow: auto;

      & div.options-group {
        padding: ${spacing.gap['03']} ${spacing.gap['03']} ${spacing.gap['03']} ${spacing.gap['05']};
        line-height: 13px;
        border-bottom: 1px solid ${color.neutral['40']};
        text-transform: uppercase;
        background: ${color.neutral['20']};
        color: ${color.neutral['80']};
      }
    }
  }
`;

export default getStyle;
