import React from 'react';

import { IInputContextProps } from '@lib/Input/Input.types';

export const InputContext = React.createContext<IInputContextProps<any>>({
  name: null,
  register: null,
  error: null,
  testid: null,
});

export const InputProvider: React.FC<IInputContextProps<any>> = ({
  children,
  register,
  name,
  error,
  testid,
}) => {
  return (
    <InputContext.Provider
      value={{
        name,
        register,
        error,
        testid,
      }}
    >
      {children}
    </InputContext.Provider>
  );
};
