import Maintenance, { pingAPI } from '@common-pages/offline/Maintenance';

const isUnderMaintenance = async () => {
  try {
    const ping = await pingAPI();
    if (!ping.ok) {
      return true;
    }
  } catch (err) {
    return true;
  }

  return false;
};

const offlineRoutes = [
  {
    route: {
      path: '/maintenance',
    },
    component: Maintenance,
  },
];

export default offlineRoutes;
export { isUnderMaintenance };
