import React from 'react';

import { GoalsSingleComponent } from '@align-pages/goals/single/components/goals-single-component/goalsSingleComponent';
import { GoalsSingleProvider } from '@align-pages/goals/single/components/goals-single-context/goalsSingleContext';

export const GoalsSingleEdit: React.FC = React.memo(() => {
  return (
    <GoalsSingleProvider>
      <GoalsSingleComponent />
    </GoalsSingleProvider>
  );
});
