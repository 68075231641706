import { css } from '@emotion/react';

import { hexToRGBA } from '@utils/colors';

export const backdropStyles = () => css`
  position: sticky;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  height: 100vh;
  animation: fadeIn 0.4s ease-in;
`;

export const helpPanelStyles = ({ color }) => css`
  width: 70vw;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: ${color.neutral.white};
  overflow-y: scroll;
  animation: slideIn 0.5s ease-in-out;

  &.help-closing {
    animation: closing 0.5s !important;
  }

  @keyframes closing {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(100%);
      opacity: 0;
    }
  }

  @keyframes slideIn {
    0% {
      transform: translateX(50%);
    }
    100% {
      transform: translateX(0);
    }
  }
`;

export const helpPanelContentStyles = () => css`
  margin: 72px 52px 52px 52px;
  animation: fadeIn 0.4s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

export const mainHelpStyles = ({ color, radius, spacing, letter, font }) => css`
  display: grid;
  font-weight: ${font.weight.regular};
  grid-template-columns: 1fr 300px;
  gap: 24px;
  animation: slideUp 0.5s ease-in-out;
  -webkit-font-smoothing: antialiased;

  h2 {
    color: ${color.text.dark};
    font-size: ${font.size.xxl};
    font-weight: ${font.weight.bold};
    letter-spacing: ${font.spacing.xl};
    margin-bottom: ${spacing.gap['06']};
  }

  h3 {
    color: ${color.text.dark};
    font-size: ${font.size.xl};
    font-weight: ${font.weight.bold};
    letter-spacing: ${font.spacing.l};
    margin-bottom: ${spacing.gap['08']};
    margin-top: ${spacing.gap['12']};
  }

  h4 {
    color: ${color.text.dark};
    font-size: ${font.size.l};
    font-weight: ${font.weight.regular};
    letter-spacing: ${font.spacing.l};
    line-height: 26px;
    margin-bottom: ${spacing.gap['08']};
    margin-top: ${spacing.gap['10']};
  }

  p {
    color: ${color.text.secondary};
    font-size: ${font.size.m};
    letter-spacing: ${font.spacing.m};
    line-height: 24px;
    margin-bottom: ${spacing.gap['08']};

    a {
      color: ${color.ui.orange['100']};
      text-decoration: underline;

      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  img {
    margin-bottom: ${spacing.gap['08']};
    margin-top: ${spacing.gap['08']};
    width: 100%;
  }

  @keyframes slideUp {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const helpSummaryStyles = ({ color, spacing, letter, font }) => css`
  > p {
    color: ${color.text.secondary};
    font-size: ${font.size.l};
    letter-spacing: ${font.spacing.l};
    line-height: 28px;
    margin-bottom: ${spacing.gap['09']};
    margin-top: ${spacing.gap['08']};
    width: 85%;
  }

  hr {
    background-color: ${color.neutral['100']};
    width: 6rem;
    height: 0.2rem;
    border: 0px;
    margin: ${spacing.gap['0']} ${spacing.gap['0']} ${spacing.gap['12']};
  }
`;

export const helpBlockStyles = ({ color, spacing, font, letter }) => css`
  background: ${hexToRGBA(color.ui.orange['100'], 0.1)};
  border-left: 4px solid ${color.ui.orange['100']};
  padding: ${spacing.gap['07']} ${spacing.gap['06']};
  color: ${color.text.dark};
  margin-bottom: ${spacing.gap['09']};
  font-size: ${font.size.m};
  letter-spacing: ${font.spacing.m};
  line-height: 28px;

  > p {
    margin-bottom: ${spacing.gap['06']};
  }
  > ul,
  ol {
    > li {
      margin: ${spacing.gap['06']} 0;

      &::marker {
        color: ${color.ui.orange['100']};
        font-weight: ${font.weight.bold};
      }

      &:last-of-type {
        margin-bottom: ${spacing.gap['0']};
      }
      &:first-of-type {
        margin-top: ${spacing.gap['0']};
      }
    }

    &.li-dash {
      list-style: none;
      padding-left: ${spacing.gap['07']};

      & > li {
        text-indent: -5px;

        &:before {
          content: '2014a0';
          text-indent: -5px;
        }
      }
    }
  }
`;

export const helpSidebarStyles = ({ color, font, spacing, letter }) => css`
  display: flex;
  flex-direction: column;
  position: relative;

  blockquote {
    background: ${color.neutral['20']};
    color: ${color.text.dark};
    font-size: ${font.size.l};
    font-weight: ${font.weight.bold};
    letter-spacing: ${font.spacing.l};
    line-height: 28px;
    padding: ${spacing.gap['08']};
    margin-bottom: ${spacing.gap['10']};
    border-top: 4px solid ${color.ui.orange['100']};
    font-style: italic;

    .source {
      color: ${color.ui.orange['100']};
      font-size: ${font.size.m};
      margin-top: ${spacing.gap['06']};
      font-style: normal;
    }
  }
`;

export const relatedContentStyles = ({ color, font, spacing }) => css`
  background: ${color.neutral['20']};
  border-top: 4px solid ${color.neutral['80']};
  text-align: left;
  font-size: ${font.size.m};
  line-height: 24px;
  padding: ${spacing.gap['08']};
  width: 300px;

  & > h4 {
    text-transform: uppercase;
    color: ${color.text.secondary};
    font-size: ${font.size.m};
    margin: ${spacing.gap['0']} ${spacing.gap['0']} ${spacing.gap['06']};
  }
  & > ul {
    list-style: none;
    padding: ${spacing.gap['0']};
    margin: ${spacing.gap['0']};

    li {
      margin-bottom: ${spacing.gap['05']};

      a,
      span.link {
        color: ${color.text.dark};

        &:hover {
          text-decoration: underline !important;
          opacity: 0.7;
        }

        &:hover,
        &:visited {
          color: ${color.text.dark};
        }
      }
    }
  }
`;

export const helpSeparatorStyles = ({ color, font, spacing }) => css`
  background: ${color.neutral['40']};
  border: 0;
  height: 2px;
  margin: ${spacing.gap['12']} ${spacing.gap['0']};
  width: 100%;
`;

export const helpRankingStyles = ({ color, font, spacing }) => css`
  h5 {
    font-size: ${font.size.m};

    > span {
      &:first-of-type {
        color: ${color.ui.orange['100']};
        text-transform: uppercase;
      }

      &:last-child {
        color: ${color.text.secondary};
        display: block;
        float: right;
      }
    }
  }

  ol {
    list-style: none;
    counter-reset: help-rank-counter;
    margin: ${spacing.gap['06']} ${spacing.gap['0']} ${spacing.gap['10']};
    padding: ${spacing.gap['0']};

    li {
      background-color: ${color.neutral['20']};
      counter-increment: help-rank-counter;
      padding: ${spacing.gap['06']};
      margin-bottom: ${spacing.gap['04']};

      &::before {
        content: counter(help-rank-counter);
        color: ${color.ui.orange['100']};
        font-size: ${font.size.l};
        line-height: 2.4rem;
        margin-right: ${spacing.gap['04']};
      }

      span {
        &:first-of-type {
          font-size: ${font.size.s};
          line-height: 2.4rem;
          text-transform: uppercase;
        }

        &:last-child {
          font-size: ${font.size.xl};
          line-height: 2.4rem;
          font-weight: ${font.weight.bold};
          float: right;
        }
      }
    }
  }
`;

export const helpNavigationStyles = ({ font }) => css`
  position: absolute;
  top: 24px;
  font-size: ${font.size.m};

  span {
    display: flex;
    align-items: center;
    margin-left: -8px;
    cursor: pointer;
  }
`;
