import { css } from '@emotion/react';

export const wrapperStyles = ({ spacing, font, color }) => css`
  padding: ${spacing.gap['06']};
  border-bottom: 1px solid ${color.neutral['40']};

  .header {
    display: flex;
    cursor: pointer;
  }

  .name {
    flex-grow: 1;
    font-size: ${font.size.s};
    color: ${color.text.dark};
  }

  .description {
    color: ${color.text.secondary};
    margin-top: ${spacing.gap['05']};
    margin-bottom: ${spacing.gap['05']};
  }

  .property {
    margin-bottom: ${spacing.gap['05']};

    &:not(:last-child) {
      border-bottom: 1px solid ${color.neutral['40']};
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;
  }
`;
