import React, { useCallback, useContext, useMemo } from 'react';

import { PrimaryLayoutModes } from '@align-components/layouts/primary';
import { PrimaryLayoutContext } from '@align-components/layouts/primary/context';
import { metricsConfig } from '@align-constants';
import { GoalTemplateUI } from '@align-pages/goals/single/components/goal-template/ui';
import { GoalTemplate } from '@common-services/api/public/generated-from-backend/models';

interface PropsCallbacks {
  isSelected?: boolean;
  mode?: PrimaryLayoutModes;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

type Props = GoalTemplate & PropsCallbacks;

const GoalTemplateComponent: React.FC<Props> = React.memo(
  ({ name, isSelected = false, mode, metric, onClick, metric_params }) => {
    const { onMetricParamsChange } = useContext(PrimaryLayoutContext);

    const onClickWrapper = useCallback(
      (ev) => {
        if (mode === PrimaryLayoutModes.CREATE) {
          if (onClick) {
            if (metric_params?.threshold) {
              onMetricParamsChange({
                threshold: metric_params?.threshold,
              });
            } else {
              onMetricParamsChange(null);
            }

            onClick(ev);
          }
        }
      },
      [onClick, mode]
    );

    const doesDrillDown = useMemo(
      () => metricsConfig[metric]?.mainKPI && metricsConfig[metric]?.inProgressStages,
      [metric]
    );

    return metricsConfig[metric] ? (
      <GoalTemplateUI
        isActive={isSelected}
        icon={metricsConfig[metric]?.icon}
        color={metricsConfig[metric]?.color}
        doesDrillDown={doesDrillDown}
        title={name}
        metricDisplayName={metricsConfig[metric].displayName}
        mode={mode}
        onClick={onClickWrapper}
      />
    ) : null;
  }
);

export { GoalTemplateComponent };
