import React, { useMemo } from 'react';

import { useOwnedJiraFilter } from '@common-services/api/public/hooks/useFiltersData';
import { uniqBy } from '@common-services/vendor/lodash';
import { FilterDropdown } from '@lib/filter-dropdown';
import { FilterType, IFilterTypeProps } from '@lib/filter-panel';
import { SelectTsOptionSingle } from '@lib/selector-ts';

import { useExistingFilters } from '../filterPanel.hooks';

export const JiraIssueTypes: React.FC<IFilterTypeProps> = React.memo(
  ({ onChange, chartParamType }) => {
    const { data: fetchedIssueTypes, isLoading } = useOwnedJiraFilter(
      ['issues', 'issue_types'],
      true
    );

    const selectedValues = useExistingFilters(FilterType.JIRA_ISSUE_TYPES, chartParamType);

    const options = useMemo<SelectTsOptionSingle[]>(() => {
      const normalized = uniqBy(fetchedIssueTypes?.issue_types, 'normalized_name');
      return normalized?.map((issueType) => ({
        name: issueType.name,
        id: issueType.normalized_name,
        image: issueType.image,
      }));
    }, [fetchedIssueTypes]);

    return (
      <FilterDropdown
        onChange={onChange}
        options={options}
        label="Issue Type"
        allSelectedLabel="All Issue Types"
        isLoading={isLoading}
        initialSelected={selectedValues}
      />
    );
  }
);
