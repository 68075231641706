import { css } from '@emotion/react';

export const wrapperStyles = ({ width, isOpen, disabled, withAvatar }) => ({
  color,
  font,
  radius,
  spacing,
  transition,
}) => css`
  align-items: center;
  background-color: ${color.neutral.white};
  border: 1px solid ${color.neutral[40]};
  border-radius: ${radius.default};
  display: flex;
  height: 40px;
  padding: ${spacing.gap['03']} ${spacing.gap['04']};
  transition: ${transition.default};
  width: ${width ? `${width}px` : 'fit-content'};

  > svg {
    height: 20px;
    margin-right: ${spacing.gap['03']};
    width: 20px;
  }

  .avatar {
    margin-right: ${spacing.gap['04']};
  }

  ${
    !disabled &&
    `
  &:hover {
    border-color: ${color.neutral[60]};
  }`
  }

  ${isOpen && `border-color: ${color.neutral[60]};`}

  ${
    disabled &&
    `
    cursor: not-allowed;
    opacity: 0.5;
  `
  }

  .value {
    color: ${color.text.dark};
    font-size: ${font.size.default};
    font-weight: ${font.weight.regular};
    width: 100%;
  }

  .placeholder {
    color: ${color.text.secondary};
    font-size: ${font.size.default};
    font-weight: ${font.weight.regular};
    width: 100%;
  }

  .icon {
    margin-left: ${spacing.gap['03']};
    margin-right: ${spacing.gap['02']};

    > svg {
      height: 8px;
      width: 8px;
    }
  }
`;
