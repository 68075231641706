import React from 'react';
import { CellProps, Renderer } from 'react-table';

import { PR_STATUS } from '@common-services/prHelpers';
import { getColor } from '@lib/Table/components/pr-table/prTable.helpers';
import { IPrsTableRow } from '@lib/Table/components/pr-table/prTable.types';
import { Icon, icons } from '@lib/icon';

export const PullRequestsCellIcon: Renderer<CellProps<IPrsTableRow>> = React.memo(({ row }) => {
  return (
    <Icon
      color={getColor(row.original.status)}
      icon={row.original.status === PR_STATUS.MERGED ? icons.pr_merge : icons.nav_pr}
      size={18}
    />
  );
});
