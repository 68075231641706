import { BasicApiClass } from '@common-services/api/common/BasicApiClass';
import { IUrlParams, REST_TYPES } from '@common-services/api/common/types/apiClient';
import { ApiMode } from '@common-services/api/common/types/common';
import { ISentryMethodParams } from '@common-services/api/common/types/sentry';
import { PRIVATE_API_ENDPOINTS_CONSTANTS } from '@common-services/api/private/constants';
import { TeamMemberList } from '@common-services/api/private/generated-from-backend/models';
import { IMembersParams } from '@common-services/api/private/types/members';

export class MembersApi extends BasicApiClass {
  constructor() {
    super(ApiMode.PRIVATE);
  }
  public getTeamMembers(
    urlParams: IUrlParams<'teamId'>,
    params: IMembersParams,
    token: string = null,
    sentry?: ISentryMethodParams
  ): Promise<TeamMemberList> {
    this.assignToken(token);
    const apiPath: string = this.replaceRouteLink(
      PRIVATE_API_ENDPOINTS_CONSTANTS.ALIGN_MEMBERS,
      urlParams
    );
    const callApi = this.callApi<TeamMemberList>(
      `${apiPath}?account=${params.account}&recursive=${params.recursive}`,
      REST_TYPES.GET
    );
    return sentry ? this.callSentry<TeamMemberList>(callApi, sentry) : callApi;
  }
}
