import { icons } from '@lib/icon';

export enum ButtonMenuTypes {
  DEFAULT = 'default',
  DROPDOWN = 'dropdown',
}

export interface IButtonMenuOption {
  label: string;
  value: string;
  icon?: icons;
}

export interface IButtonMenuProps {
  label?: string;
  options: IButtonMenuOption[];
  onSelect: (selectedItem: string) => void;
  disabledOptions: string[];
  type?: ButtonMenuTypes;
  defaultText?: string;
  initialSelection?: string;
}
