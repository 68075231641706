// From https://auth0.com/docs/quickstart/spa/react#install-the-auth0-react-wrapper
import createAuth0Client from '@auth0/auth0-spa-js';
import React, { useState, useEffect, useContext, useCallback } from 'react';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

const Auth0Context = React.createContext();

export const useAuth0 = () => useContext(Auth0Context);

const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes('code=')) {
        if (!window.location.search.includes('state=')) {
          // IdP-initiated, go to the second lap
          // https://github.com/auth0/auth0-spa-js/issues/213#issuecomment-1030027525
          auth0FromHook.loginWithRedirect({
            redirect_uri: initOptions.redirect_uri,
            connection: new URLSearchParams(window.location.search).get('connection'),
          });
        } else {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        }
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
      }

      setLoading(false);
    };
    initAuth0();
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: useCallback((...p) => auth0Client.getIdTokenClaims(...p), [auth0Client]),
        loginWithRedirect: useCallback(
          async (p) => {
            const { redirectMethod, extra, ...urlOptions } = p || {};
            const url = await auth0Client.buildAuthorizeUrl(urlOptions);
            const finalizedUrl = `${url}&${extra || 'purpose=signin'}`;
            window.location[redirectMethod || 'assign'](finalizedUrl);
          },
          [auth0Client]
        ),
        getTokenSilently: useCallback((...p) => auth0Client.getTokenSilently(...p), [auth0Client]),
        getTokenWithPopup: useCallback((...p) => auth0Client.getTokenWithPopup(...p), [
          auth0Client,
        ]),
        logout: useCallback((...p) => auth0Client.logout(...p), [auth0Client]),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

export default Auth0Provider;
