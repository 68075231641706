import React, { useMemo } from 'react';

import { metricsConfig } from '@align-constants';
import { MetricService } from '@align-services/metricService';
import { sanitize } from '@common-services/htmlService';
import { StringService } from '@common-services/stringService';
import { Icon, icons } from '@lib/icon';
import { useTheme } from '@styles/hooks';

import { metricDescriptionTextStyles } from './metricDescriptionText.styles';
import { IMetricDescriptionText, IMetricDescriptionTextMode } from './metricDescriptionText.types';

export const MetricDescriptionText: React.FC<IMetricDescriptionText> = React.memo(
  ({ metricValue, threshold, mode, percentage }) => {
    const theme = useTheme();
    const metricDescription = useMemo(() => {
      const { short, full } = metricsConfig[metricValue]?.metricDescription;
      const descriptionConcatenated = `${full} ${short}`;
      return StringService.replaceVarsInStr(descriptionConcatenated || '', {
        percentage: percentage || 'Percentage',
        value: MetricService.getUnitSingularity(threshold, metricValue),
      });
    }, [metricValue, threshold, percentage]);
    return (
      <div css={metricDescriptionTextStyles({ mode })}>
        {[IMetricDescriptionTextMode.LIST, IMetricDescriptionTextMode.TITLE].includes(mode) && (
          <div className="icon">
            <Icon
              icon={icons.goal}
              size={mode === IMetricDescriptionTextMode.TITLE ? 21 : 12}
              color={theme.color.ui.blue['100']}
            />
          </div>
        )}
        <div dangerouslySetInnerHTML={{ __html: sanitize(metricDescription) }} className="text" />
      </div>
    );
  }
);
