import { ContentType } from '@analytics-components/insights/Box/types';
import { GranularityEnum } from '@analytics-constants';
import { ValueType } from '@analytics-pages/delivery-pipeline/insights/deploy/services/types';
import { IntervalType } from '@analytics-types/common';

import chart from './chart';
import kpis from './kpis';

export interface KPIInputType {
  depFrequency: [number, string];
  deployments: number;
  prsCount: number;
}

interface DataType {
  granularity: GranularityEnum;
  interval: IntervalType;
  kpisData: KPIInputType;
  values: ValueType[];
}

const deploymentFrequency = (data: DataType): ContentType => {
  const { granularity, interval, kpisData, values } = data;

  return {
    chart: chart(granularity, interval, values),
    kpis: kpis(kpisData),
  };
};

export default deploymentFrequency;
