// @flow
import * as React from 'react';

import { useHelpContext } from '@analytics-context/Help';
import {
  Main,
  Title,
  Summary,
  Subtitle,
  Paragraph,
  Separator,
  ContentBlock,
  BlockQuote,
  Rank,
  RankItem,
  Source,
  Sidebar,
  RelatedContent,
  Navigation,
} from '@analytics-pages/Help/components';

const LeadTime = () => {
  const { stack } = useHelpContext();
  return (
    <>
      <Navigation pages={stack} />
      <Main>
        <section>
          <Title>Lead Time</Title>
          <Summary>
            Lead Time is a metric borrowed from lean thinking and manufacturing disciplines. It’s
            defined as the amount of time between the moment work begins until it’s delivered to the
            end-customer.
          </Summary>

          <Subtitle>Why is Lead Time important?</Subtitle>
          <Paragraph>
            Lead Time in software development is essential because it helps you understand how fast
            work is moving through your <a href="https://athenian.co">software delivery pipeline</a>
            . It allows you to detect early bottlenecks and resolve them on the spot. It also helps
            you to gain precision when estimating the time to deliver a new feature to the end-user.
            Together with other metrics that tell you about volume, engagement, quality and impact,
            you can understand if you are improving as a team.
          </Paragraph>

          <Paragraph>
            Shorter lead times brings the following benefits to the team’s output performance:
          </Paragraph>

          <ContentBlock>
            <ul>
              <li>Faster feedback loop with your users</li>
              <li>Beating competition to market</li>
              <li>Reducing risk in the deployment process by working in smaller batches</li>
            </ul>
          </ContentBlock>

          <Paragraph>
            In Google’s 2019{' '}
            <a
              href="https://cloud.google.com/blog/products/devops-sre/the-2019-accelerate-state-of-devops-elite-performance-productivity-and-scaling"
              target="_blank"
              rel="noopener noreferrer"
            >
              DevOps research
            </a>
            , the authors revealed that High-Performance Organisations have a Lead Time under a
            week, Elite organizations under a day.
          </Paragraph>

          <Subtitle>How is it calculated?</Subtitle>
          <Paragraph>
            Athenian calculates it as the average time for Jira tickets to go from the beginning of
            the work until it is delivered. To be more precise, the work begins when the ticket
            moves to the <b>In Progress</b> Jira status category (blue status) and it ends when the
            last pull requests associated gets released or when the ticket is resolved.
          </Paragraph>

          <Paragraph>
            It is important to emphasize that the Lead Time is calculated on delivered tickets only.
            If a ticket steps away from the delivery pipeline before being released, it is not
            included in the computation.
          </Paragraph>

          <Separator />

          <Title>How to improve it using Athenian?</Title>
          <Paragraph>
            Athenian allows you to quickly spot which segment of the pipeline adds the most time to
            total lead time. Generally speaking, this segment should then be tackled first. The pull
            request table offers the ability to zoom on the pull requests that get stuck and slow
            down the end-to-end delivery.
          </Paragraph>
          <Paragraph>
            A series of metrics and insights help you to understand the current state of your
            software delivery pipeline. By setting progressive and reasonable targets your team can
            aim to reduce lead time without having a negative impact on the other pillars
            constituting the best engineering practices.
          </Paragraph>

          <Paragraph>
            By measuring the right engineering metrics, Athenian makes your team focus on what
            matters to sustainably reduce end-to-end Lead Time.
          </Paragraph>

          <ContentBlock>
            <ol>
              <li>Reduce pull request size to ease code reviews.</li>
              <li>Minimize the time pull requests remain idle in the pipeline.</li>
              <li>Encourage knowledge sharing and avoid human bottlenecks.</li>
              <li>Ship smaller and more often to shorten feedback loops.</li>
            </ol>
          </ContentBlock>
        </section>
        <Sidebar>
          <Rank title="Top Teams" metric="Lead Time">
            <RankItem name="Elite" value="1 hour" />
            <RankItem name="High" value="1 day" />
            <RankItem name="Medium" value="1 week" />
          </Rank>

          <BlockQuote>
            "Developers should be checking multiple small releasable changes into the trunk at least
            once per day."
            <Source>GOOGLE</Source>
          </BlockQuote>

          <RelatedContent>
            <h4>Related Content</h4>
            <ul>
              <li>
                <a href="https://cloud.google.com/blog/products/devops-sre/the-2019-accelerate-state-of-devops-elite-performance-productivity-and-scaling">
                  DevOps Research
                </a>
              </li>
              <li>
                <a href="https://cloud.google.com/solutions/devops/devops-process-working-in-small-batches">
                  How to work in small batches
                </a>
              </li>
              <li>
                <a href="https://www.agilealliance.org/resources/experience-reports/actionable-metrics-siemens-health-services/">
                  Actionable metrics at Siemens
                </a>
              </li>
            </ul>
          </RelatedContent>
        </Sidebar>
      </Main>
    </>
  );
};

export default LeadTime;
