import { css } from '@emotion/react';

export const subInfoStyles = ({ color, font }) => css`
  color: ${color.text.secondary};
  font-size: ${font.size.s};
  font-weight: ${font.weight.regular};
`;

export const titleBadgeStyles = ({ color, font, radius, spacing }) => css`
  background-color: ${color.neutral[20]};
  border-radius: ${radius.default};
  color: ${color.text.dark};
  font-size: ${font.size.default};
  font-weight: ${font.weight.regular};
  margin-left: ${spacing.gap['04']};
  padding: calc(${spacing.gap['01']} + ${spacing.gap['02']}) ${spacing.gap['03']};
`;
