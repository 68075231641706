import _ from 'lodash';
import React from 'react';

import TeamsComparisonTable from '@analytics-components/tables/TeamsComparisonTable';
import DataWidget, { useDataWidget } from '@analytics-context/DataWidget';
import { fetchPRsMetrics, fetchReleasesMetrics } from '@analytics-services/api';
import { getFeature, featuresList } from '@analytics-services/flags';
import { ArrayService } from '@common-services/arrayService';

const ContentTeamsComparisonTable = () => {
  const allTeamId = 0;
  const allTeamName = 'All';

  const fetcher = async (api, cachedData, apiContext) => {
    const {
      account,
      contributors,
      labels,
      repositories,
      interval,
      excludeInactive,
      teams,
      features,
      epics,
      issueTypes,
    } = apiContext;

    const selectedTeams = ArrayService.getUniqueValues(contributors.map((item) => item.team));

    const allTeam = {
      id: allTeamId,
      name: allTeamName,
      members: contributors,
      parent: null,
    };
    const filteredTeams = [allTeam, ...teams.filter((team) => selectedTeams.includes(team.name))];
    const expandedTeamsMap = Object.assign(
      {},
      ..._(filteredTeams)
        .map((t) => ({
          [t.id]: {
            members: t.members,
            parent: t.parent,
          },
        }))
        .value()
    );

    _(filteredTeams).forEach((ft) => {
      let parent = ft.parent;
      while (!!parent) {
        const parentTeam = expandedTeamsMap[parent];
        if (!parentTeam) {
          parent = null;
        } else {
          const mergedUniqMembers = _(parentTeam.members).unionBy(ft.members, 'login').value();
          parentTeam.members = mergedUniqMembers;
          parent = parentTeam.parent;
        }
      }
    });

    const filteredExpandedTeams = _(filteredTeams)
      .map((t) => ({
        ...t,
        members: expandedTeamsMap[t.id].members,
      }))
      .value();

    const contribsGroups = _(filteredExpandedTeams)
      .map((t) => {
        const contribs = _(t.members)
          .map('login')
          .filter((c) => c)
          .value();
        return {
          pr_author: contribs,
          releaser: contribs,
          commit_author: contribs,
        };
      })
      .value();

    const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, features)?.parameters
      ?.value;

    const [prsMetrics, releasesMetrics] = await Promise.all([
      fetchPRsMetrics(
        api,
        account,
        ['all'],
        interval,
        ['size', 'cycle-time', 'review-time', 'reviewed', 'not-reviewed', 'release-count'],
        {
          repositories,
          withgroups: _(filteredExpandedTeams)
            .map((t) => ({ author: t.members }))
            .value(),
          labels_include: labels,
          ...(excludedLabels ? { labels_exclude: excludedLabels } : {}),
          jira: { epics, issue_types: issueTypes },
        },
        null,
        excludeInactive
      ),
      fetchReleasesMetrics(
        api,
        account,
        ['all'],
        interval,
        ['prs', 'count'],
        repositories,
        contribsGroups,
        labels,
        excludedLabels || [],
        { epics, issue_types: issueTypes }
      ),
    ]);

    return {
      teams: filteredExpandedTeams,
      prsMetrics,
      releasesMetrics,
    };
  };

  const plumber = (fetchedData, cachedData, apiContext) => {
    const { teams, prsMetrics, releasesMetrics } = fetchedData;
    if (prsMetrics.calculated.length === 0) {
      return null;
    }

    const teamsMetrics = _(_.zip(teams, prsMetrics.calculated, releasesMetrics))
      .map((value) => {
        const [team, prsMets, relMets] = value;
        return [
          team.id,
          {
            name: team.name,
            prsReleased: prsMets.values[0].values[5],
            releaseFrequency: relMets.values[0].values[1],
            prSize: prsMets.values[0].values[0],
            prCycleTime: prsMets.values[0].values[1],
            reviewTime: prsMets.values[0].values[2],
            prsReviewed:
              (prsMets.values[0].values[3] /
                (prsMets.values[0].values[3] + prsMets.values[0].values[4])) *
              100,
            // No stats added for now
            subRows: _(team.members)
              .map((m) => ({ avatar: m.picture, name: m.name, login: m.login }))
              .value(),
          },
        ];
      })
      .fromPairs()
      .value();

    _(teams).forEach((t) => {
      if (t.parent && teamsMetrics[t.parent]) {
        teamsMetrics[t.parent].subRows = [...teamsMetrics[t.parent].subRows, teamsMetrics[t.id]];
      }
    });

    const filteredTeamsMetrics = Object.keys(teamsMetrics)
      .filter((key) => {
        if (key === '0') return true;
        const team = teams.find((teamInt) => teamInt.name === teamsMetrics[key].name);
        return team.parent === null ? true : !(team.parent && teamsMetrics[team.parent]);
      })
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: teamsMetrics[key],
        };
      }, {});

    const { [allTeamId]: allTeamMetrics, ...realTeamsMetrics } = filteredTeamsMetrics;
    return {
      data: {
        teams: _(realTeamsMetrics).values().value(),
        total: allTeamMetrics,
      },
      context: {
        interval: apiContext.interval,
      },
    };
  };

  return (
    <DataWidget id="team-comparison-metrics" fetcher={fetcher} plumber={plumber}>
      <ContentTeamsComaprisonTableDataBinder />
    </DataWidget>
  );
};

const ContentTeamsComaprisonTableDataBinder = () => {
  const { data, isLoading } = useDataWidget();
  if (isLoading) {
    return <div className="dataTable-placeholder filter-placeholder" />;
  }

  if (!data) {
    return null;
  }

  const { data: tableData, context } = data;
  return <TeamsComparisonTable data={tableData} context={context} />;
};

export default ContentTeamsComparisonTable;
