import React from 'react';

import { ChartBoxWithKPIWidget } from '@analytics-components/insights/ChartBox';
import Panel from '@analytics-components/insights/Panel';
import { prepareChartBoxes } from '@analytics-pages/delivery-pipeline/insights/helper';

import mttrThroughTimeByPriority from './mttrThroughTimeByPriority';

const meta = {
  title: 'Mean Time To Restore - Timeline by Priority',
  description:
    'Monitor the evolution of your MTTR through time and ensure your SLAs get respected.',
};

const chartBoxes = [
  {
    id: 'mttr-through-time',
    component: ChartBoxWithKPIWidget,
    params: mttrThroughTimeByPriority,
  },
];

const MTTRThroughTimeByPriorityPanel = () => {
  const preparedChartBoxes = prepareChartBoxes(chartBoxes);
  return <Panel meta={meta} chartBoxes={preparedChartBoxes} />;
};

export default MTTRThroughTimeByPriorityPanel;
