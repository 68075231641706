import React, { useCallback } from 'react';

import { INodeData } from '@align-pages/goals/single/components/tree-view/treeView.types';
import { Icon, icons } from '@lib/icon';
import { theme } from '@styles/theme';

import { wrapperStyles } from './expandFooter.styles';

const ExpandFooter: React.FC<INodeData> = React.memo((data) => {
  const color = theme.color.neutral['80'];

  const handleFooterClick = useCallback(
    () => data.onToggle(data.goal, data.targetValues, data.valueType, data.unit),
    [data]
  );

  return (
    <div css={wrapperStyles(data.isExpanded)} onClick={handleFooterClick}>
      <Icon
        color={color}
        icon={data.isExpanded ? icons.chevrons_up : icons.chevrons_down}
        size={12}
      />
      <div className="teams-info">
        <Icon color={color} icon={icons.people} size={12} />
        <span>
          {data.goal?.children.length} team{data.goal?.children.length === 1 ? '' : 's'}
        </span>
      </div>
      <Icon
        color={color}
        icon={data.isExpanded ? icons.chevrons_up : icons.chevrons_down}
        size={12}
      />
    </div>
  );
});

export { ExpandFooter };
