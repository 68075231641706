import { useTheme } from '@emotion/react';
import React from 'react';

import LegendSelect from '@analytics-components/LegendSelect';
import { cardBodyStyles } from '@analytics-components/insights/styles';
import DistributionChart from '@analytics-pages/prototypes/charts/DistributionChart';
import DonutChartExample from '@analytics-pages/prototypes/charts/DonutChartExample';
import PositiveNegativeBarChart from '@analytics-pages/prototypes/charts/PositiveNegativeBarChart';
import PositiveNegativeOverlappedBarChart from '@analytics-pages/prototypes/charts/PositiveNegativeOverlappedBarChart';
import RingChartExample from '@analytics-pages/prototypes/charts/RingChartExample';
import SmoothLinesChart from '@analytics-pages/prototypes/charts/SmoothLinesChart';

const Charts = () => {
  const theme = useTheme();

  const metrics = [
    { title: 'Radial Chart', chart: DonutChartExample, color: theme.color.ui.pink[100] },
    { title: 'Ring Chart', chart: RingChartExample, color: theme.color.ui.pink[50] },
    {
      title: 'Distribution Chart',
      chart: DistributionChart,
      color: theme.color.ui.blue[100],
    },
    { title: 'Smooth Lines', chart: SmoothLinesChart, color: theme.color.ui.purple[100] },
    {
      title: 'Positive Negative Bar Chart',
      chart: PositiveNegativeBarChart,
      color: theme.color.ui.darkred[100],
    },
    {
      title: 'Positive Negative Overlapped Bar Chart',
      chart: PositiveNegativeOverlappedBarChart,
      color: theme.color.ui.green[100],
    },
  ];

  return (
    <>
      <div className="container">
        {metrics.map((metric, key) => (
          <Metric title={metric.title} color={metric.color} chart={metric.chart} key={key} />
        ))}
      </div>
      <div className="container">
        <LegendSelect
          items={[
            { label: 'highest', color: theme.color.sets.bugs[0] },
            { label: 'high', color: theme.color.sets.bugs[1] },
            { label: 'medium', color: theme.color.sets.bugs[2] },
          ]}
          onActivesChange={() => {}}
        />
      </div>
    </>
  );
};

const Metric = ({ title, chart: Chart, color }) => (
  <div className="card mb-4">
    <div className="card-header p-4 bg-white font-weight-bold text-dark p-4">
      <span className="text-m">
        <span
          className="mr-3"
          style={{ display: 'inline-block', width: '20px', height: '20px', background: color }}
        />
        {title}
      </span>
    </div>
    <div css={cardBodyStyles} className="card-body py-5 px-4">
      <div className="row">
        <div className="col-7">
          <Chart />
        </div>
        <div className="col-5 align-self-center">KPIs</div>
      </div>
    </div>
  </div>
);

export default Charts;
