import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { CacheService } from '@align-services/cacheService';
import { useOnClickOutside } from '@common-hooks/useOnClickOutside';
import { useTeamMembers } from '@common-pages/Settings/teams/teams.hooks';
import { getTeamColor } from '@common-services/colorService';
import log from '@common-services/logger';
import { Dropdown, DropdownType, IDropdownNameValue } from '@lib/Dropdown';
import { FilterDropdownBox } from '@lib/filter-dropdown/ui/filter-dropdown-box';
import { SelectorTs } from '@lib/selector-ts';
import { formControlStyles } from '@styles/components/forms';

import { labelStyles, menuStyles, wrapperStyles } from './TeamForm.styles';
import { ITeamForm } from './TeamForm.types';

const plusIcon = String.fromCharCode(43);

const closeDropdown = () => document.body.click();

const TeamForm: React.FC<ITeamForm> = React.memo(
  ({ btnText, teams, options, className, onSave }) => {
    const [isMembersOpen, setIsMembersOpen] = useState(false);

    const onMembersCloseHandler = useCallback(() => {
      setIsMembersOpen(false);
    }, []);
    const onMembersClickHandler = useCallback(() => {
      setIsMembersOpen((prevState) => !prevState);
    }, []);
    const onMembersChangeHandler = useCallback((selectedIds: string[]) => {
      setTeamMembers(selectedIds);
      setIsMembersOpen(false);
    }, []);

    const [teamName, setTeamName] = useState<string>('');
    const [parentTeam, setParentTeam] = useState<IDropdownNameValue>(null);
    const [teamMembers, setTeamMembers] = useState<string[]>([]);
    const [menuOpen, setMenuOpen] = useState(false);

    const {
      contributorsData,
      convertedDevelopers,
      convertedContributors,
      isLoading: isLoadingMembers,
      isContributorsRequested,
      isContributorsLoaded,
      loadMoreClickHandler,
    } = useTeamMembers([], teamMembers);

    const buttonRef = useRef(null);
    const menuRef = useRef(null);
    useOnClickOutside([buttonRef, menuRef], () => setMenuOpen(false));

    useEffect(() => {
      if (teams.length > 0) {
        const initialParentTeam = teams
          .filter((teamSingle) => teamSingle.parent === null)
          .map((teamSingle) => ({
            name: teamSingle.name,
            value: teamSingle.id,
            color: getTeamColor(teamSingle.name),
          }));
        setParentTeam(initialParentTeam ? initialParentTeam[0] : null);
      }
    }, [teams]);

    const parentTeamOnChange = useCallback((selectedParent) => {
      if (selectedParent?.value) {
        setParentTeam(selectedParent.value);
      }
    }, []);

    const opts = options || {};
    opts.nameChangeEnabled = opts.nameChangeEnabled || false;
    opts.parentChangeEnabled = opts.parentChangeEnabled || false;
    opts.membersChangeEnabled = opts.membersChangeEnabled || false;
    opts.strings = _.assign(
      {
        title: 'New team',
        save: 'Add',
        buttonPreffix: plusIcon,
      },
      opts.strings || {}
    );

    const onChange = (ev) => {
      const {
        target: { value },
      } = ev;
      setTeamName(value);
      setTeamMembers(teamMembers);
    };

    const saveTeam = () => {
      onSave({
        name: teamName,
        members: teamMembers,
        parentTeam: parentTeam?.value || parentTeam,
      });
      resetForm();
      invalidateTeamsQuery().then();
    };

    const resetForm = useCallback(() => {
      setIsMembersOpen(false);
      setTeamName('');
      closeDropdown();
      setTeamMembers([]);
    }, []);

    const invalidateTeamsQuery = async (): Promise<void> => {
      try {
        await CacheService.removeQuery(['teams']);
      } catch {
        log.fatal('Cache error');
      }
    };

    const handleAddClick = useCallback(() => {
      setMenuOpen(!menuOpen);
    }, [menuOpen]);

    const applyDisabled =
      opts.nameChangeEnabled && opts.membersChangeEnabled
        ? !teamName.length || !teamMembers.length
        : opts.nameChangeEnabled
        ? !teamName.length
        : !teamMembers.length;

    const parentTeamOptions = useMemo<IDropdownNameValue[]>(
      () =>
        teams.map((teamSingle) => {
          return {
            name: teamSingle.name,
            value: teamSingle.id,
            color: getTeamColor(teamSingle.name),
          };
        }),
      [teams]
    );

    const currentMembersOptions = useMemo(() => {
      return isContributorsRequested && contributorsData
        ? convertedContributors
        : convertedDevelopers;
    }, [isContributorsRequested, contributorsData, convertedContributors, convertedDevelopers]);

    return (
      <div css={wrapperStyles}>
        <button
          type="button"
          className={className || 'btn btn-orange'}
          ref={buttonRef}
          onClick={handleAddClick}
        >
          {opts.strings.buttonPreffix} {btnText}
        </button>
        {menuOpen && (
          <div css={menuStyles} ref={menuRef}>
            {opts.nameChangeEnabled && (
              <div>
                <h3 css={labelStyles}>{opts.strings.title}</h3>
                <div className="team-input">
                  <input
                    css={formControlStyles}
                    type="text"
                    name="teamName"
                    placeholder="Team name"
                    value={teamName}
                    onChange={onChange}
                    autoComplete="off"
                  />
                </div>
              </div>
            )}
            {opts.parentChangeEnabled && (
              <div>
                <div className="add-users-label">
                  <span css={labelStyles}>Parent Team:</span>
                </div>
                <Dropdown
                  options={parentTeamOptions}
                  type={DropdownType.NAME_VALUE}
                  initialValue={parentTeam}
                  width={415}
                  onChange={parentTeamOnChange}
                  placeholder="Select a Team..."
                  withAvatar
                  withSearch
                />
              </div>
            )}
            {opts.membersChangeEnabled && (
              <div>
                <div className="add-users-label">
                  <span css={labelStyles}>Add members to your team:</span>
                </div>
                <FilterDropdownBox
                  label={'Add members'}
                  badge={teamMembers.length.toString()}
                  selectedItems={teamMembers}
                  options={currentMembersOptions}
                  minWidth={415}
                  onClick={onMembersClickHandler}
                />
                {isMembersOpen && (
                  <SelectorTs
                    optionsInit={currentMembersOptions}
                    withAllOption={false}
                    isLoading={isLoadingMembers}
                    isLoadMoreEnabled={
                      !(contributorsData ? isContributorsRequested : isContributorsLoaded)
                    }
                    loadMoreLabel={'Load all users'}
                    onLoadMoreClick={loadMoreClickHandler}
                    onApply={onMembersChangeHandler}
                    onCancel={onMembersCloseHandler}
                  />
                )}
              </div>
            )}
            <div className="dropdown-divider" />
            <div className="bg-white font-weight-light d-flex align-items-center justify-content-end p-3">
              <button className="btn text-s text-secondary mr-3" onClick={resetForm}>
                Cancel
              </button>
              <button className="btn btn-orange" onClick={saveTeam} disabled={applyDisabled}>
                {opts.strings.save}
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
);

export { TeamForm };
