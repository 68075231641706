import classnames from 'classnames';
import _ from 'lodash';
import React from 'react';

import Badge, { NEGATIVE_IS_BETTER } from '@analytics-components/Badge';
import { BigNumber, SmallTitle } from '@analytics-components/Typography';
import PipelineCardMiniChart from '@analytics-components/charts/PipelineCardMiniChart';
import { useHelpContext } from '@analytics-context/Help';
import * as NumberService from '@common-services/numberService';
import { Info } from '@lib/info';
import { theme } from '@styles/theme';
import Hint from '@ui/Hint';
import { pipelineThumbnailStyles, stageCardHeaderStyles } from '@ui/StageCard/styles';

const StageCard = ({
  data,
  isLoading,
  title,
  text,
  hint,
  badge,
  variation,
  color,
  active,
  onClick,
}) => (
  <div
    css={pipelineThumbnailStyles}
    className={classnames('card', active && 'active')}
    onClick={onClick}
  >
    <div className="card-body p-3">
      <StageCardHeader title={title} hint={hint} badgeValue={isLoading ? null : badge} />
      {isLoading ? (
        <div className="thumbnail-placeholder" />
      ) : (
        <StageCardBody text={text} variation={variation} data={data} color={color} />
      )}
    </div>
  </div>
);

const StageCardHeader = ({ title, hint, badgeValue }) => (
  <div css={stageCardHeaderStyles}>
    <span>
      <SmallTitle content={title} isUppercase />
      <Info content={<StageCardHint {...hint} />} />
    </span>
    {!_(badgeValue).isNil() && <Badge value={badgeValue} />}
  </div>
);

const StageCardBody = ({ text, variation, data, color }) => (
  <div className="row no-gutters card-text">
    <div className="col-5">
      <BigNumber
        css={{ lineHeight: '2.8rem', marginBottom: theme.spacing.gap['02'], width: '100%' }}
        content={text}
      />
      <Badge trend={NEGATIVE_IS_BETTER} value={NumberService.round(variation)} />
    </div>
    <div className="col-7 pl-2" style={{ height: 55 }}>
      <PipelineCardMiniChart data={data} config={{ color }} />
    </div>
  </div>
);

const StageCardHint = ({ primaryText, secondaryText, learnMore }) => {
  const { openSection } = useHelpContext();
  return (
    <Hint
      primaryText={primaryText}
      secondaryText={secondaryText}
      learnMore={learnMore}
      onLearnMoreClick={openSection}
    />
  );
};

export default StageCard;
