import { useQuery } from 'react-query';

import { CONFIG } from '@align-constants';
import { IUseQueryHookRest } from '@align-types/common';
import { DatetimeService } from '@analytics-services/datetimeService';
import { useAuth0 } from '@common-context/Auth0';
import { useUserContext } from '@common-context/User';
import { useDemoCookie } from '@common-pages/Demo';
import { IApiBasicError } from '@common-services/api/common/types/common';
import {
  Contributors,
  DeveloperSummaries,
  FilterContributorsRequest,
} from '@common-services/api/public/generated-from-backend/models';
import { getOffset } from '@common-services/dateService';

import { ContributorsServices } from '../services/contributors';

export function useContributors(
  accountId: number,
  enabled: boolean,
  isCached: boolean = false
): IUseQueryHookRest<Contributors> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();

  const { isLoading, data, error } = useQuery<Contributors, IApiBasicError>(
    ['contributors', accountId],
    async () => {
      const token: string = isDemo === false ? await getTokenSilently() : null;
      return ContributorsServices.getContributors({ accountId }, token);
    },
    {
      ...CONFIG.API.FETCH_OPTIONS,
      enabled,
      cacheTime: isCached ? CONFIG.API.FETCH_OPTIONS.cacheTime : 0,
      staleTime: isCached ? CONFIG.API.FETCH_OPTIONS.staleTime : 0,
    }
  );

  return { isLoading, data, error };
}

export function useOwnedContributors(
  enabled: boolean,
  isCached: boolean = false
): IUseQueryHookRest<Contributors> {
  const {
    user: {
      defaultAccount: { id: accountId },
    },
  } = useUserContext();
  return useContributors(accountId, enabled, isCached);
}

export function useContributorsFiltered(
  params: FilterContributorsRequest,
  isCached: boolean = false
): IUseQueryHookRest<DeveloperSummaries> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();

  const { isLoading, data, error } = useQuery<DeveloperSummaries, IApiBasicError>(
    ['contributors_filtered', params.account, params.date_from, params.date_to, params.timezone],
    async () => {
      const token: string = isDemo === false ? await getTokenSilently() : null;
      return ContributorsServices.getFilteredContributors(params, token);
    },
    {
      ...CONFIG.API.FETCH_OPTIONS,
      cacheTime: isCached ? CONFIG.API.FETCH_OPTIONS.cacheTime : 0,
      staleTime: isCached ? CONFIG.API.FETCH_OPTIONS.staleTime : 0,
    }
  );

  return { isLoading, data, error };
}

export function useOwnedContributorsFiltered(
  isCached: boolean = false
): IUseQueryHookRest<DeveloperSummaries> {
  const {
    user: {
      defaultAccount: { id: accountId },
    },
  } = useUserContext();
  return useContributorsFiltered(
    {
      account: accountId,
      timezone: getOffset(),
      date_to: (DatetimeService.Defaults.TO.toDate() as unknown) as string,
      date_from: (DatetimeService.Defaults.FROM.toDate() as unknown) as string,
    },
    isCached
  );
}
