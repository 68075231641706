import React, { useCallback, useEffect, useState } from 'react';

import { ChartEdit } from '@align-pages/dashboard/components/chart-edit';
import {
  useExistingChart,
  useFiltersProperty,
  useSelectedGroupBy,
} from '@align-pages/dashboard/components/chart-edit/chartEdit.hooks';
import { useOwnedChartConfig } from '@align-pages/dashboard/hooks';
import { ChartOperationType } from '@align-pages/dashboard/hooks/types';
import { useDashboardResponseHandle } from '@align-pages/dashboard/hooks/useDashboardResponseHandle';
import { DashboardPropertyServices } from '@align-pages/dashboard/services/dashboardPropertyServices';
import { useDashboardStore } from '@align-pages/dashboard/store';
import { ChartParamsType } from '@align-pages/dashboard/types';
import { useHistory } from '@common-hooks/useHistory';
import {
  DashboardChartFilters,
  DashboardChartGroupBy,
} from '@common-services/api/private/generated-from-backend/models';
import { useEditChart } from '@common-services/api/private/hooks/useEditChart';
import { isEqual } from '@common-services/vendor/lodash';

import { ChartHeader } from '../chart-header';

const EditMetric: React.FC = React.memo(() => {
  const existingChart = useExistingChart();
  const [initialChartState, setInitialChartState] = useState(null);
  const [filtersInitialized, setFiltersInitialized] = useState(false);
  const [chartParams, initializeChartParams] = useDashboardStore((state) => [
    state.chartParams,
    state.onChartParamsInit,
  ]);
  const [properties, addExistingFilter] = useDashboardStore((state) => [
    state.properties,
    state.onAddExisting,
  ]);

  // Update chart state with existing chart
  useEffect(() => {
    if (!initialChartState) {
      setInitialChartState(existingChart);
    }

    if (!chartParams && existingChart) {
      initializeChartParams(existingChart);
    }
  }, [existingChart, chartParams]);

  // Update properties state with existing properties
  useEffect(() => {
    const filters = existingChart?.filters
      ? DashboardPropertyServices.filtersToProperties<DashboardChartFilters>(
          existingChart.filters,
          ChartParamsType.FILTERS
        )
      : [];
    const breakdown = existingChart?.group_by
      ? DashboardPropertyServices.filtersToProperties<DashboardChartGroupBy>(
          existingChart.group_by,
          ChartParamsType.BREAKDOWNS
        )
      : [];

    const existingProps = [...filters, ...breakdown];

    if (existingProps && !filtersInitialized && !isEqual(properties, existingProps)) {
      addExistingFilter(existingProps);
      setFiltersInitialized(true);
    }
  }, [properties, existingChart, filtersInitialized]);

  const [name, setName] = useState<string>(null);
  const metric = useOwnedChartConfig();
  const filters = useFiltersProperty();
  const group_by = useSelectedGroupBy();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const history = useHistory();

  const { mutate: editChart, isLoading, isSuccess, isError } = useEditChart();
  const { isLoading: isResponseHandleLoading } = useDashboardResponseHandle({
    isSuccess,
    isError,
    operationType: ChartOperationType.EDIT,
  });

  useEffect(() => {
    const hasNameChanged = name?.trim() !== initialChartState?.name?.trim();
    const hasFiltersChanged = !isEqual(filters, initialChartState?.filters || {});
    const hasGroupByChanged = !isEqual(group_by, initialChartState?.group_by);
    const hasTimeIntervalChanged = chartParams?.time_interval !== initialChartState?.time_interval;
    if (
      initialChartState &&
      (hasNameChanged || hasFiltersChanged || hasGroupByChanged || hasTimeIntervalChanged) &&
      !isResponseHandleLoading &&
      !isLoading
    ) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [name, filters, group_by, initialChartState, isLoading, isResponseHandleLoading]);

  const handleBackButtonClick = useCallback(() => history.goBack(), [history]);

  const handleSaveChanges = useCallback(() => {
    editChart({
      chartId: chartParams.id,
      name,
      filters,
      group_by,
      time_interval: chartParams.time_interval,
    });
  }, [name, filters, group_by, chartParams]);

  return (
    <>
      <ChartHeader
        existingChart={existingChart}
        isSubmitDisabled={submitDisabled}
        selectedMetric={metric}
        onSubmit={handleSaveChanges}
        onBackButtonClick={handleBackButtonClick}
        onNameChange={setName}
      />
      {chartParams?.metric && <ChartEdit metric={metric} />}
    </>
  );
});

export { EditMetric };
