import React, { useMemo } from 'react';

import { wrapperStyles } from '@align-components/layouts/primary/avatarName/AvatarName.styles';
import { getTeamColor } from '@common-services/colorService';
import { Avatar, AvatarType } from '@lib/avatar';

interface Props {
  name: string;
}

export const AvatarName: React.FC<Props> = React.memo(({ name }) => {
  const teamColor = useMemo(() => getTeamColor(name), [name]);
  return (
    <div css={wrapperStyles}>
      <Avatar label={name} type={AvatarType.text} color={teamColor} />
      {name}
    </div>
  );
});
