import React from 'react';
import { Redirect, useParams } from 'react-router-dom';

import Main from '@analytics-pages/Main';
import Settings from '@common-pages/Settings';

import settingsSubRoutes from './settingsSubRoutes';

const WithParamsRedirect = ({ to }) => {
  const params = useParams();
  const toReplace = to.match(/(:[\w\d_]+)/);
  let parsedTo = to;
  for (const tr of toReplace) {
    parsedTo = parsedTo.replace(tr, params[tr.slice(1)]);
  }

  return <Redirect to={parsedTo} />;
};

const mainRoutes = [
  {
    route: {
      path: '/settings/:name?',
      mainRoute: true,
      backButton: true,
    },
    component: Settings,
    subRoutes: settingsSubRoutes,
  },
  {
    route: {
      path: '/',
      exact: true,
      mainRoute: true,
    },
    component: Redirect,
    componentProps: {
      to: '/analytics/delivery-pipeline',
    },
  },
  {
    route: {
      path: '/analytics',
      exact: true,
      mainRoute: true,
    },
    component: Redirect,
    componentProps: {
      to: '/analytics/delivery-pipeline',
    },
  },
  {
    route: {
      path: '/analytics/:part(delivery-pipeline|ci|quality|outcome)',
      exact: true,
      mainRoute: true,
    },
    component: WithParamsRedirect,
    componentProps: {
      to: '/analytics/:part/summary',
    },
  },
  {
    route: {
      path: '/analytics/:part(compare)/:section(teams)',
      exact: true,
      mainRoute: true,
    },
    component: Redirect,
    componentProps: {
      to: '/analytics/compare/teams/timelines',
    },
  },
  {
    route: {
      path: '/analytics/:part(compare)/:section(teams)/:subsection(timelines|table)',
      exact: true,
      mainRoute: true,
    },
    component: Main,
  },
  {
    route: {
      path:
        '/analytics/:part(delivery-pipeline|ci|quality|outcome|tables|learn)/:section?/:subsection?',
      exact: true,
      mainRoute: true,
    },
    component: Main,
  },
];

export default mainRoutes;
