import { useQuery } from 'react-query';

import { CONFIG } from '@align-constants';
import { IUseQueryHookRest } from '@align-types/common';
import { useAuth0 } from '@common-context/Auth0';
import { useDemoCookie } from '@common-pages/Demo';
import { IApiBasicError } from '@common-services/api/common/types/common';
import { IDeploymentsParams } from '@common-services/api/common/types/deployments';
import { FilteredDeployments } from '@common-services/api/public/generated-from-backend/models';
import { DeploymentsServices } from '@common-services/api/public/services/deployments';

export function useDeployments(
  params: IDeploymentsParams,
  isCached: boolean = false
): IUseQueryHookRest<FilteredDeployments> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();

  const { isLoading, data, error } = useQuery<FilteredDeployments, IApiBasicError>(
    [
      'fetchDeployments',
      params.contributors,
      params.dateInterval,
      params.jira,
      params.labelsInclude,
      params.repositories,
    ],
    async () => {
      const token: string = isDemo === false ? await getTokenSilently() : null;
      return DeploymentsServices.fetchDeployments(params, token);
    },
    {
      ...CONFIG.API.FETCH_OPTIONS,
      cacheTime: isCached ? CONFIG.API.FETCH_OPTIONS.cacheTime : 0,
      staleTime: isCached ? CONFIG.API.FETCH_OPTIONS.staleTime : 0,
    }
  );

  return { isLoading, data, error };
}
