import { css } from '@emotion/react';

export const wrapperStyles = ({ color, font, radius, spacing, shadow }, withPortal) => css`
  background-color: ${color.neutral[0]};
  border: 1px solid ${color.neutral[40]};
  box-shadow: ${shadow.full};
  border-radius: ${radius.default};
  margin-top: ${spacing.gap['03']};
  min-width: 300px;
  position: fixed;
  width: fit-content;
  z-index: 100;
  ${withPortal
    ? `
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  `
    : `
    right: 0;
  `}

  .ath-modal-content {
    padding: ${spacing.gap['06']};

    .ath-modal-title {
      color: ${color.neutral[100]};
      font-size: ${font.size.default};
      font-weight: ${font.weight.regular};
      line-height: 1.5;
      margin-bottom: ${spacing.gap['06']};
    }
  }

  .ath-modal-footer {
    background-color: ${color.neutral.white};
    border-top: 1px solid ${color.neutral[40]};
    display: flex;
    justify-content: flex-end;
    padding: ${spacing.gap['03']} ${spacing.gap['04']};
  }
`;
