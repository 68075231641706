import React, { useMemo } from 'react';

import { drillDownTooltipText, metricsConfig } from '@align-constants';
import { ActiveGoalTooltipContent } from '@align-pages/goals/list/active-goals/components/active-goal-tooltip-content';
import { IBadgeSize } from '@common-types/common';
import { FilterBadge } from '@lib/filter-badge';
import { MetricBadge } from '@lib/metric-badge';
import { Tooltip, TooltipPlace } from '@lib/tooltip';

import { useAppliedFiltersCounter } from './activeGoalBadges.hooks';
import { IActiveGoalBadges } from './activeGoalBadges.types';

export const ActiveGoalBadges: React.FC<IActiveGoalBadges> = React.memo(({ goal, metricName }) => {
  const appliedFiltersCounter = useAppliedFiltersCounter(goal);

  const doesDrillDown = useMemo(
    () => metricsConfig[metricName]?.mainKPI && metricsConfig[metricName]?.inProgressStages,
    [metricName]
  );

  return (
    <>
      <Tooltip content={doesDrillDown ? null : <div>{drillDownTooltipText}</div>}>
        <MetricBadge metric={metricsConfig[metricName]} size={IBadgeSize.SMALL} />
      </Tooltip>
      {appliedFiltersCounter > 0 && (
        <Tooltip content={<ActiveGoalTooltipContent goal={goal} />} place={TooltipPlace.BOTTOM}>
          <FilterBadge counter={appliedFiltersCounter} size={IBadgeSize.SMALL} />
        </Tooltip>
      )}
    </>
  );
});
