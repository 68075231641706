import React from 'react';

import {
  IMetricDescriptionTextMode,
  MetricDescriptionText,
} from '../metric-description/components/metric-description-text';
import { wrapperStyles } from './goalViewTitle.styles';
import { IGoalViewTitle } from './goalViewTitle.types';

const GoalViewTitle: React.FC<IGoalViewTitle> = React.memo(
  ({ targetValue, metricName, targetPercentage }) => {
    return (
      <div css={wrapperStyles}>
        {metricName && targetValue && (
          <MetricDescriptionText
            metricValue={metricName}
            threshold={targetValue}
            mode={IMetricDescriptionTextMode.TITLE}
            percentage={targetPercentage}
          />
        )}
      </div>
    );
  }
);

export { GoalViewTitle };
