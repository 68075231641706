import { css } from '@emotion/react';

export const wrapperStyles = ({ color, font, spacing }) => css`
  padding-bottom: ${spacing.gap['05']};

  .set-target-text {
    color: ${color.text.secondary};
    font-size: ${font.size.default};
    font-weight: ${font.weight.regular};
    margin-bottom: ${spacing.gap['02']};
  }

  .set-target-value {
    align-items: center;
    display: flex;

    .ath-raw-input-wrapper {
      height: 40px;
    }

    .set-button-wrapper {
      margin-left: ${spacing.gap['02']};
      margin-right: ${spacing.gap['02']};

      > button {
        height: 40px;
      }
    }
  }
`;
