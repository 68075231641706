import { css, keyframes } from '@emotion/react';

import { generateBackground } from './Placeholder.helpers';

const blinking = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% {  opacity: 1; }
`;

export const wrapperStyles = ({ data }) => () => css`
  height: ${data.height}px;
  background-repeat: no-repeat;
  ${generateBackground(data.items)}
  animation: ${blinking} 2s ease-in-out infinite;
  position: relative;
  overflow: hidden;
  z-index: 100;
`;
