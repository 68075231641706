import React from 'react';
import { CellProps, Renderer } from 'react-table';

import { IIssuesTableRow } from '@align-types/issues';

import { wrapperStyles } from './cellPriority.styles';

export const IssuesCellPriority: Renderer<CellProps<IIssuesTableRow>> = React.memo(
  ({ row }) =>
    row.depth === 0 && (
      <div css={wrapperStyles}>
        <img
          alt={row.original.priority}
          className="priority-image"
          src={row.original.priorityImage}
          title={row.original.priority}
        />
      </div>
    )
);
