import { css } from '@emotion/react';
import React from 'react';

import Badge from '@analytics-components/Badge';
import { SmallTitle } from '@analytics-components/Typography';
import DataWidget, { useDataWidget } from '@analytics-context/DataWidget';
import { Deployments } from '@analytics-pages/Tables/deployments';

interface IDeploymentsTab {
  isActive: boolean;
}

const wrapperStyles = ({ spacing }) => css`
  .header-badge {
    margin-left: ${spacing.gap['04']};
  }
`;

const DeploymentsTabHeader: React.FC<IDeploymentsTab> = React.memo(({ isActive }) => {
  const plumber = (fetchedData, cachedData) => {
    return {
      badgeValue: cachedData.deployments.reduce((acc, env) => acc + env.values[0].values[0], 0),
    };
  };

  return (
    // TODO: Rewrite DataWidget here in favor of React Query approach with hooks
    <DataWidget id="deployments-tab-header" plumber={plumber} prefetchedDataIds={['deployments']}>
      <DeploymentsTabHeaderDataBinder isActive={isActive} />
    </DataWidget>
  );
});

const DeploymentsTabHeaderDataBinder: React.FC<IDeploymentsTab> = React.memo(({ isActive }) => {
  const { data, isLoading } = useDataWidget();
  const badgeValue = isLoading ? 0 : data.badgeValue;

  return (
    <div css={wrapperStyles}>
      <SmallTitle content="Deployments" isBlack={isActive} />
      <Badge value={badgeValue} className="header-badge" />
    </div>
  );
});

const deploymentsTab = {
  Header: DeploymentsTabHeader,
  content: <Deployments />,
};

export { deploymentsTab };
