import React from 'react';

import { PrimaryLayoutProvider } from '@align-components/layouts/primary/context';
import {
  IPrimaryLayoutContextHandlers,
  PrimaryLayoutTabs,
} from '@align-components/layouts/primary/context.types';
import { NavigationTabs } from '@align-components/layouts/primary/navigationTabs';
import { TeamHeader } from '@align-components/layouts/primary/team-header';
import { Container } from '@lib/Container';

import { wrapperStyles } from './primary.styles';

interface Props extends IPrimaryLayoutContextHandlers {
  tab: PrimaryLayoutTabs;
}

export enum PrimaryLayoutModes {
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view',
}

export const PrimaryLayout: React.FC<Props> = React.memo(
  ({
    children,
    tab,
    mode,
    isSubmitAllowed,
    dateRangeInit,
    selectedMetric,
    metricParams,
    dateRangeOnChange,
    onFiltersChange,
    onMetricParamsChange,
    onNewGoalNameChange,
    onSubmit,
    onCancel,
    onRemove,
  }) => {
    return (
      <PrimaryLayoutProvider
        tab={tab}
        mode={mode}
        isSubmitAllowed={isSubmitAllowed}
        selectedMetric={selectedMetric}
        metricParams={metricParams}
        dateRangeInit={dateRangeInit}
        dateRangeOnChange={dateRangeOnChange}
        onFiltersChange={onFiltersChange}
        onMetricParamsChange={onMetricParamsChange}
        onNewGoalNameChange={onNewGoalNameChange}
        onSubmit={onSubmit}
        onCancel={onCancel}
        onRemove={onRemove}
      >
        <div css={wrapperStyles}>
          <TeamHeader />
          {mode && children}
          {!mode && (
            <Container>
              <NavigationTabs />
              {children}
            </Container>
          )}
        </div>
      </PrimaryLayoutProvider>
    );
  }
);
