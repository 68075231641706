import React, { useMemo } from 'react';

import { useOwnedJiraFilter } from '@common-services/api/public/hooks/useFiltersData';
import { FilterDropdown } from '@lib/filter-dropdown';
import { FilterType, IFilterTypeProps } from '@lib/filter-panel';
import { SelectTsOptionSingle } from '@lib/selector-ts';

import { useExistingFilters } from '../filterPanel.hooks';

export const JiraPriorities: React.FC<IFilterTypeProps> = React.memo(
  ({ onChange, chartParamType }) => {
    const { data: fetchedPriorities, isLoading } = useOwnedJiraFilter(
      ['issues', 'priorities'],
      true
    );

    const selectedValues = useExistingFilters(FilterType.JIRA_PRIORITIES, chartParamType);

    const options = useMemo<SelectTsOptionSingle[]>(() => {
      return fetchedPriorities?.priorities?.map((priority) => ({
        name: priority.name,
        id: priority.name.toLowerCase(),
      }));
    }, [fetchedPriorities]);

    return (
      <FilterDropdown
        options={options}
        isLoading={isLoading}
        onChange={onChange}
        label="Priority"
        allSelectedLabel="All Priorities"
        initialSelected={selectedValues}
      />
    );
  }
);
