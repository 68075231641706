import React, { useCallback } from 'react';

import { ExternalStaticUri } from '@analytics-types/enums';
import comingSoon from '@assets/images/empty-states/coming-soon.svg';
import { Button, ButtonSize } from '@lib/Button';

import { Empty } from '..';

const NoDeployments: React.FC = React.memo(() => {
  const handleClick = useCallback((e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    window.open(ExternalStaticUri.INTERCOM_URI, '_blank');
  }, []);

  return (
    <Empty background={comingSoon}>
      <h3>No deployments detected</h3>
      <p>Use our API to notify us about your deployments and get valuable metrics.</p>
      <Button label="Get Deployment Metrics" size={ButtonSize.LARGE} onClick={handleClick} />
    </Empty>
  );
});

export { NoDeployments };
