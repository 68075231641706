// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';

import { useHelpContext } from '@analytics-context/Help';
import {
  Main,
  Title,
  Summary,
  Subtitle,
  Paragraph,
  Separator,
  ContentBlock,
  BlockQuote,
  Source,
  Sidebar,
  RelatedContent,
  Navigation,
  Image,
} from '@analytics-pages/Help/components';
import Release03Img from '@assets/images/educational/release_03.png';
import Release04Img from '@assets/images/educational/release_04.png';
import Release05Img from '@assets/images/educational/release_pr_table.png';
import Release06Img from '@assets/images/educational/release_table.png';

const Release = ({ fullPage }) => {
  const { openSection, stack } = useHelpContext();
  return (
    <>
      <Navigation pages={stack} />
      <Main>
        <section>
          <Title>Release Cycle Time</Title>
          <Summary>
            Once merged, pull requests enter the final stage of the pipeline, waiting to be included
            in a release that will reach the end-user.
          </Summary>

          <Subtitle>Why is it important?</Subtitle>
          <Paragraph>
            The final stage of the Software delivery pipeline is deployment to production. When the
            application is small, with only a few deployed services, there’s not much pain. But as
            the system complexity inevitably grows, the manual process of releasing will impose
            downtime. In High-Performance Technology Operations, delivery to production is a click
            of a button or sometimes even seamlessly integrated into the automated delivery
            procedure. This reduces the number of points of failure and is designed in a way that
            customers are not affected.
          </Paragraph>

          <Paragraph>
            According to Reinersten's book{' '}
            <a href="https://www.slideshare.net/SebastianRadics/the-principles-of-product-development-flow-a-summary">
              Principles of Product Development Flow
            </a>{' '}
            published in 2009, reducing Release Cycle Time has the following benefits on your
            delivery pipeline:
          </Paragraph>
          <ContentBlock>
            <ol>
              <li>Reduce cycle times and variability in flow.</li>
              <li>Accelerate feedback.</li>
              <li>Reduce risk and overhead.</li>
              <li>Improve efficiency.</li>
              <li>Increase motivation and urgency.</li>
              <li>Reduce cost and schedule growth.</li>
            </ol>
          </ContentBlock>

          <Subtitle>How is it calculated?</Subtitle>
          <Paragraph>
            The Release Cycle Time is the average time that pull requests need to complete the last
            section of the delivery pipeline: from merge to release in production. When is a pull
            request considered released? What does a release mean in Athenian? Since each team has
            its own release workflow, Athenian allows users to customize what "released" means for
            each repository. For the sake of simplicity we have divided any release workflow into
            two different strategies: Tag or Branch. Athenian also includes a heuristic to
            automatically select the right strategy for each repository. The diagram below
            illustrates the 3 modes that you can pick: Automatic, Tag, or Branch.
          </Paragraph>

          <Image src={Release03Img} />

          <Paragraph>
            You can set your own release settings by going to your profile picture &gt;{' '}
            <b>Settings</b> &gt; <b>Releases</b>. There, you can select from the breakdown the right
            strategy that matches your release workflow for each repository. Once you’ve updated
            your release settings, all metrics based on it, such as Lead Time will update. You’ll
            also see an update of the “released” label in the pull requests table.
          </Paragraph>

          <Image src={Release04Img} />

          <Separator />

          <Title>How to improve it using Athenian?</Title>
          <Paragraph>
            The insights provided in Athenian allow you to answer the following questions which are
            essential in order to keep control over your release cycles and reduce your end-to-end
            Lead Time.
          </Paragraph>

          <ContentBlock>
            <ol>
              <li>What is inside a release?</li>
              <li>How many pull requests are waiting to be released?</li>
              <li>Since when haven't we released to production?</li>
              <li>How big is going to be the next release?</li>
              <li>What is my Release Cycle Time per repository?</li>
              <li>What are the releases that slowed down the entire Lead Time?</li>
            </ol>
          </ContentBlock>

          <Paragraph>
            In the Release section, you can find two tables. The first one lists all your active
            pull requests in the time period selected and the second shows the releases from the
            same time period. To reduce Release Cycle Time, it is important to create momentum in
            the release workflow. We recommend going to the pull request table, filter by the status{' '}
            <b>Waiting for Release</b>, and sort by <b>Release Time</b>. Now seeing the pull
            requests which are waiting to be released for the longest time, the question is: what is
            preventing those from being released in production? According to Nicole Forsgren's
            research summarized in the <a href="https://nicolefv.com/book">Accelerate</a> book,
            engineering teams should be asking themselves this question every day at least in order
            to become high-performing.
          </Paragraph>

          <Image src={Release05Img} />

          <Paragraph>
            Reaching high-performing release throughput require both the right tooling with
            Continuous Deployment set up and a culture of shipping small and frequent. In the
            release section of Athenian, you get a series of insights analyzing your release
            frequency, distribution of releases by size, and the impact of the latest releases on
            your Release Cycle Time. Going to the release table, you can (1) understand what has
            been released in each version (2) get detailed metrics per release so that your teams
            can identify, during their retrospective, what slowed down their delivery and take
            specific actions with quantitative objectives.
          </Paragraph>

          <Image src={Release06Img} />

          <Paragraph>
            Looking at the table above which shows the latest releases of our own webapp repository,
            a reasonable objective we could set for the next quarter for example could be:{' '}
            <i>deploy the webapp repository at least once per week.</i>
            <br />
            In general, to increase the probability of completing your objectives and improve your
            engineering performance in the long run, we highly recommend following the{' '}
            <a href="https://www.tinyhabits.com/">Tiny Habits</a> method from BJ Fogg and make your
            objectives both specific and really easy to reach.
          </Paragraph>
        </section>
        <Sidebar>
          <BlockQuote>
            "Developers should be checking multiple small releasable changes into the trunk at least
            once per day."
            <Source>Google</Source>
          </BlockQuote>
          <BlockQuote>
            "Deploy code more than 80 times per day, safely, easily, and painlessly."
            <Source>Etsy</Source>
          </BlockQuote>

          <RelatedContent>
            <h4>Related Content</h4>
            <ul>
              <li>
                <a href="https://techbeacon.com/devops/high-performance-continuous-delivery-3-technical-practices-work#:~:text=What's%20most%20remarkable%2C%20however%2C%20is,safely%2C%20easily%2C%20and%20painlessly.">
                  The Etsy model
                </a>
              </li>
              <li>
                <a href="https://www.tinyhabits.com/">Tiny Habits</a>
              </li>
              <li>
                {fullPage ? (
                  <Link to={`/learn/knowledge/lead-time`} className="link">
                    <span>Lead Time</span>
                  </Link>
                ) : (
                  <span
                    onClick={() => openSection({ label: 'Lead Time', path: 'leadTime' })}
                    className="link"
                  >
                    Lead Time
                  </span>
                )}
              </li>
            </ul>
          </RelatedContent>
        </Sidebar>
      </Main>
    </>
  );
};

export default Release;
