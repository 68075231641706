import React from 'react';

import { IBadgeSize, IBadgeType } from '@common-types/common';
import { Icon } from '@lib/icon';

import { testIds } from './__tests__/testIds.ids';
import { wrapperStyles } from './metricBadge.styles';
import { IMetricBadge } from './metricBadge.types';

export const MetricBadge: React.FC<IMetricBadge> = React.memo(
  ({ metric, type = IBadgeType.DEFAULT, size = IBadgeSize.DEFAULT }) => {
    if (!metric) return null;

    return (
      <div
        data-testid={testIds.wrapper}
        css={wrapperStyles({ metricColor: metric?.color, type, size })}
      >
        {metric?.icon && <Icon className="icon" icon={metric.icon} color={metric.color} />}
        <div>{metric?.displayName}</div>
      </div>
    );
  }
);
