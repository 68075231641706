import React from 'react';

import { IUser } from '@common-types/common';

import { NavbarUserMenu, NavbarUserLogin } from './components';

interface INavbarUser {
  user: IUser;
  isDemo: boolean;
}

export const NavbarUser: React.FC<INavbarUser> = React.memo(({ user, isDemo }) => {
  return user && !isDemo ? <NavbarUserMenu user={user} /> : <NavbarUserLogin />;
});
