import { useTheme } from '@emotion/react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React from 'react';

import Button from '@ui/Button';
import { primaryText, secondaryText } from '@ui/typography';

const Search = ({
  id,
  value,
  inputValue,
  placeholder,
  options,
  maxVisible,
  isHover,
  isOpen,
  onOptionClick,
  onClickClosed,
  onCancelClick,
  onMouseEnterClosed,
  onMouseLeaveClosed,
  onInputChange,
}) => {
  const canHover = isHover && !isOpen;
  return isOpen ? (
    <SearchOpen
      id={id}
      inputValue={inputValue}
      options={options}
      maxVisible={maxVisible}
      onOptionClick={onOptionClick}
      onInputChange={onInputChange}
    />
  ) : (
    <SearchClosed
      value={value}
      placeholder={placeholder}
      onClick={onClickClosed}
      onCancelClick={onCancelClick}
      onMouseEnter={onMouseEnterClosed}
      onMouseLeave={onMouseLeaveClosed}
      canHover={canHover}
    />
  );
};

const SearchClosed = ({
  value,
  placeholder,
  canHover,
  onClick,
  onCancelClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const theme = useTheme();

  const baseStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.spacing.gap['02']} ${theme.spacing.gap['04']}`,
    width: '100%',
    border: 0,
    backgroundColor: 'transparent',
    height: '35px',
  };

  const isHoverStyle = {
    border: `1px solid ${theme.color.neutral[40]}`,
    borderRadius: '1px',
    backgroundColor: theme.color.neutral[20],
    cursor: 'text',
  };

  const css = [baseStyle];
  if (canHover) {
    css.push(isHoverStyle);
  }

  return (
    <div onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} css={css}>
      {!!value ? (
        <span css={primaryText}>{value.label}</span>
      ) : (
        <span css={[secondaryText, { fontWeight: theme.font.weight.regular }]}>{placeholder}</span>
      )}
      <Button
        style={{
          color: theme.color.neutral[80],
          visibility: canHover ? 'visible' : 'hidden',
        }}
        onClick={onCancelClick}
      >
        <FontAwesomeIcon icon={faTimes} />
      </Button>
    </div>
  );
};

const SearchOpen = ({ id, inputValue, options, maxVisible, onOptionClick, onInputChange }) => {
  const filteredOptions = _(options)
    .filter((opt) => !inputValue || _(opt.label.toLowerCase()).includes(inputValue.toLowerCase()))
    .value();
  const optionHeightPx = 35;
  return (
    <div
      id={id || 'search'}
      css={({ color, spacing, transition }) => ({
        'width': '100%',
        'height': `${optionHeightPx}px`,
        '& div.choices': {
          height: `${optionHeightPx * (maxVisible || filteredOptions.length)}px`,
          overflow: 'auto',
        },
        '& .option': {
          border: `1px solid ${color.neutral[40]}`,
          borderRadius: '1px',
          background: color.neutral.white,
          marginBottom: -spacing.gap['1px'],
          outline: '0px',
          padding: `${spacing.gap['03']} ${spacing.gap['05']}`,
          position: 'relative',
          transition: `${transition.default}`,
          width: '100%',
          zIndex: 1000,
        },
        '& .option:hover': {
          backgroundColor: color.neutral[0],
        },
        '& div.option': {
          '&.selectable': {
            cursor: 'pointer',
          },
          '&.not-selectable': {
            cursor: 'not-allowed',
          },
        },
      })}
    >
      <div>
        <input autoFocus className="option" type="text" onChange={onInputChange} />
      </div>
      <div className="choices">
        {filteredOptions.map((opt, i) => {
          return (
            <div
              className={`option ${opt.isSelected ? 'not-selectable' : 'selectable'}`}
              key={i}
              onClick={() => onOptionClick(opt)}
            >
              <span css={opt.isSelected ? [secondaryText, { fontWeight: '500' }] : primaryText}>
                {opt.label}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Search;
