import { css } from '@emotion/react';

export const wrapperStyles = ({ isCompact }) => ({ spacing, color, font }) => css`
  display: flex;
  height: 20px;

  .icon {
    width: 16px;
    margin-right: ${spacing.gap['02']};
  }

  .name {
    color: ${color.neutral['80']};
    font-size: ${font.size.default};
    line-height: ${font.height.s};
    padding-right: ${spacing.gap['02']};
    ${!isCompact && `width: 100px;`}
  }

  .list {
    color: ${color.text.dark};
    font-size: ${font.size.default};
    line-height: ${font.height.s};
  }

  .more {
    cursor: default;
    user-select: none;
    ${!isCompact && `border-bottom: 1px dashed ${color.neutral['80']};`}
    height: 20px;
    display: inline-block;
  }
`;
