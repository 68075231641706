import React from 'react';

import Box from '@analytics-components/insights/Box';
import { InsightsError } from '@analytics-components/insights/Helper';
import DataWidget, { useDataWidget } from '@analytics-context/DataWidget';

import mergeDelays from './mergeDelays';

const InsightsMergeDataBinder = () => {
  const { data, error, isLoading } = useDataWidget();
  if (isLoading) {
    return <div className="insights-placeholder" />;
  }

  if (error) {
    return <InsightsError />;
  }

  const insights = [mergeDelays.factory(data.mergeDelays)];

  return (
    <>
      {insights.map((ins, i) => (
        <Box meta={ins.meta} content={ins.content} empty={ins.empty} key={i} />
      ))}
    </>
  );
};

const InsightsMerge = () => {
  const fetcher = async (api, cachedData, apiContext) => {
    return { mergeDelays: await mergeDelays.fetcher(api, cachedData, apiContext) };
  };

  const plumber = (fetchedData, cachedData) => ({
    mergeDelays: mergeDelays.plumber(fetchedData.mergeDelays, cachedData),
  });

  return (
    <DataWidget id={'merge-insights'} fetcher={fetcher} plumber={plumber}>
      <InsightsMergeDataBinder />
    </DataWidget>
  );
};

export default InsightsMerge;
