import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PrimaryLayoutModes } from '@align-components/layouts/primary';
import { metricsConfig } from '@align-constants';
import { EmptyTree } from '@align-pages/goals/single/components/empty-tree';
import { GoalsCatalog } from '@align-pages/goals/single/components/goals-catalog';
import { GoalsSingleComponentServices } from '@align-pages/goals/single/components/goals-single-component';
import { GoalsSingleContext } from '@align-pages/goals/single/components/goals-single-context';
import { GoalsSingleWrapper } from '@align-pages/goals/single/components/goals-single-wrapper';
import { MetricDescription } from '@align-pages/goals/single/components/metric-description';
import { useTreeBuilder } from '@align-pages/goals/single/components/tree-view/treeView.hooks';
import { SetTargetFn } from '@align-pages/goals/single/components/tree-view/treeView.types';
import { ITemplateMetric, TeamGoalInput } from '@align-services/api/types/goalsTypes';
import { DateService, IDateRangeWithDisplayName } from '@align-services/dateService';
import { MetricService } from '@align-services/metricService';
import { ValueType } from '@align-types/constants';
import {
  GoalMetricParams,
  GoalTemplate,
} from '@common-services/api/public/generated-from-backend/models';
import { useOwnedGoalTemplates } from '@common-services/api/public/hooks/useGoalTemplatesData';
import { Spinner } from '@lib/Spinner';

import { FilterBar } from '../filter-bar';
import { TreeView } from '../tree-view';
import { useCurGoalData, useTeamGoal, useTeamsData } from './goalsSingleComponent.hooks';
import { treeViewWrapperStyles } from './goalsSingleComponent.styles';
import { IGoalSingleParams } from './goalsSingleComponent.types';

const GoalsSingleComponent: React.FC = React.memo(() => {
  const { selectedFilters, setSelectedFilters, metricParams } = useContext(GoalsSingleContext);
  const [templateName, setTemplateName] = useState('');
  const [templateMetric, setTemplateMetric] = useState<ITemplateMetric>(null);
  const [metricValue, setMetricValue] = useState<GoalTemplate['metric']>(null);
  const [metricThreshold, setMetricThreshold] = useState<GoalMetricParams['threshold']>(null);
  const [validFrom, setValidFrom] = useState(DateService.getUnitsAheadStart(0, 'quarter'));
  const [expiresAt, setExpiresAt] = useState(DateService.getUnitsAheadEnd(0, 'quarter'));
  const [valueType, setValueType] = useState<ValueType>(null);
  const [teamGoals, setTeamGoals] = useState<TeamGoalInput[]>([]);

  const { path, goalId } = useParams<IGoalSingleParams>();

  const mode = useMemo(
    () => (goalId === 'add' ? PrimaryLayoutModes.CREATE : PrimaryLayoutModes.EDIT),
    [goalId]
  );

  const prevDateRange: IDateRangeWithDisplayName = {
    dateFrom: DateService.getUnitsBefore(30, 'days'),
    dateTo: DateService.getCurrentDate(),
    displayName: 'Last 30 days',
  };

  const teamsData = useTeamsData(path);
  const [curGoalData, goalsLoading] = useCurGoalData(goalId, teamsData);

  const appliedFilters = GoalsSingleComponentServices.generateFiltersObject(
    mode === PrimaryLayoutModes.EDIT ? curGoalData : selectedFilters
  );
  const [teamGoal, metricsValuesLoading, metricsValuesError] = useTeamGoal(
    curGoalData,
    [metricValue],
    mode,
    prevDateRange,
    teamGoals,
    teamsData,
    appliedFilters,
    /*TODO: remove after complete moving from GQL to REST*/
    metricThreshold
      ? {
          threshold: metricThreshold,
        }
      : metricParams
  );

  const { data: goalTemplatesData } = useOwnedGoalTemplates(true);
  const targetSetHandler = useCallback<SetTargetFn>(
    (goal, targetValue, allTargetValues, threshold) => {
      const hasExistingTarget = !!goal.value.target;
      const otherTeamGoals = allTargetValues.filter((g) => g.teamId !== goal.team.id);
      const newTeamGoals: TeamGoalInput[] = [...otherTeamGoals];

      if (mode === PrimaryLayoutModes.CREATE) {
        // When new goal is being created, push all the new values
        if (targetValue && MetricService.hasValue(targetValue)) {
          newTeamGoals.push({
            teamId: goal.team.id,
            target: targetValue,
            ...(threshold
              ? {
                  metricParams: {
                    threshold,
                  },
                }
              : {}),
          });
        }
      } else if (mode === PrimaryLayoutModes.EDIT) {
        // When a goal is edited, only existing targets or new values will be pushed
        // If a team don't have an existing target and current value it means no
        // change is done. No change situation can only happen when a user set and removed a target
        // without submitting
        if (MetricService.hasValue(targetValue) || hasExistingTarget) {
          newTeamGoals.push({
            teamId: goal.team.id,
            // If there is no value set, then add a remove flag
            ...(MetricService.hasValue(targetValue) ? { target: targetValue } : { remove: true }),
            ...(threshold
              ? {
                  metricParams: {
                    threshold,
                  },
                }
              : {}),
          });
        }
      }

      setTeamGoals(newTeamGoals);
    },
    []
  );

  const [nodes, edges] = useTreeBuilder({
    validFrom,
    expiresAt,
    templateMetric,
    teamGoal: teamGoal?.[0],
    valueType,
    unit: metricsConfig[metricValue]?.unit,
    targetValues: teamGoals,
    onSetTarget: targetSetHandler,
    isTLO: Boolean(metricThreshold),
    threshold: metricThreshold,
  });

  useEffect(() => {
    if (mode === PrimaryLayoutModes.EDIT && curGoalData) {
      setTemplateName(curGoalData.name);
      /*TODO: remove type conversion and use original type coming from yaml*/
      setTemplateMetric(curGoalData.metric as ITemplateMetric);
      setValidFrom(curGoalData.valid_from);
      setExpiresAt(curGoalData.expires_at);
      setSelectedFilters({
        ...(curGoalData.repositories ? { repositories: curGoalData.repositories } : ''),
        ...(curGoalData.jira_priorities ? { jira_priorities: curGoalData.jira_priorities } : ''),
        ...(curGoalData.jira_projects ? { jira_projects: curGoalData.jira_projects } : ''),
        ...(curGoalData.jira_issue_types ? { jira_issue_types: curGoalData.jira_issue_types } : ''),
      });
    }
  }, [curGoalData, mode]);

  useEffect(() => {
    if (mode === PrimaryLayoutModes.EDIT) {
      if (curGoalData) {
        const templateValueType = metricsConfig[curGoalData.metric]?.valueType;
        if (templateValueType) {
          setValueType(templateValueType);
          setMetricValue(curGoalData.metric);
        }
      }
    }
  }, [metricsConfig, mode, curGoalData]);

  useEffect(() => {
    setMetricThreshold(curGoalData?.metric_params?.threshold);
  }, [curGoalData]);

  useEffect(() => {
    if (metricParams?.threshold) {
      setMetricThreshold(metricParams.threshold);
    }
  }, [metricParams]);

  useEffect(() => {
    setTeamGoals((oldTeamGoals) =>
      oldTeamGoals.map((goal) => ({
        ...goal,
        metricParams: {
          threshold: metricThreshold,
        },
      }))
    );
  }, [metricThreshold]);

  const goalSelectedHandler = useCallback(
    (selectedGoal: GoalTemplate) => {
      if (mode === PrimaryLayoutModes.CREATE) {
        setTemplateName(selectedGoal.name);
        setTemplateMetric(selectedGoal.metric);
        setMetricValue(selectedGoal.metric);
        setMetricThreshold(selectedGoal?.metric_params?.threshold || null);
        setValueType(metricsConfig[selectedGoal.metric]?.valueType || ValueType.int);
        setTeamGoals([]);
      }
    },
    [metricsConfig, mode]
  );

  const isLoading = useMemo(() => goalsLoading || metricsValuesLoading, [
    goalsLoading,
    metricsValuesLoading,
  ]);

  return (
    <GoalsSingleWrapper
      expiresAt={expiresAt}
      goalId={goalId}
      metricsValuesError={metricsValuesError}
      mode={mode}
      path={path}
      teamGoal={teamGoal?.[0]}
      targetValues={teamGoals}
      templateName={templateName}
      templateMetric={templateMetric}
      validFrom={validFrom}
      setValidFrom={setValidFrom}
      setExpiresAt={setExpiresAt}
    >
      {mode === PrimaryLayoutModes.CREATE && (
        <>
          <GoalsCatalog
            templateNameSelected={templateName}
            templateMetricSelected={templateMetric}
            goals={goalTemplatesData || []}
            mode={mode}
            onGoalSelected={goalSelectedHandler}
          />
          {metricThreshold && (
            <MetricDescription
              metricValue={metricValue}
              threshold={metricThreshold}
              valueType={valueType}
            />
          )}
          <FilterBar metric={templateMetric} />
        </>
      )}
      <div css={treeViewWrapperStyles}>
        {isLoading ? (
          <Spinner loading={isLoading} />
        ) : teamGoal ? (
          <TreeView nodes={nodes} edges={edges} />
        ) : (
          <EmptyTree />
        )}
      </div>
    </GoalsSingleWrapper>
  );
});

export { GoalsSingleComponent };
