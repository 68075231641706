import { QueryFilters } from 'react-query/types/core/utils';

import { queryClient } from '@app';

export const resetQuery = (queryIds: string[], filters?: QueryFilters): Promise<void> => {
  return queryClient.resetQueries(queryIds, filters);
};

export const removeQuery = (queryIds: string[]): void => {
  return queryClient.removeQueries(queryIds);
};

export * as CacheService from './cacheService';
