import { useMutation } from 'react-query';

import { IUseMutationHook } from '@align-types/common';
import { useAuth0 } from '@common-context/Auth0';
import { useDemoCookie } from '@common-pages/Demo';
import { IApiBasicError } from '@common-services/api/common/types/common';
import { GoalUpdateRequest } from '@common-services/api/private/generated-from-backend/models';
import { CreatedIdentifier } from '@common-services/api/public/generated-from-backend/models';

import { AlignGoalsServices } from '../services/alignGoals';

export type IUpdateGoal = {
  goalId: number;
  input: GoalUpdateRequest;
};

export function useUpdateGoal(): IUseMutationHook<CreatedIdentifier, IApiBasicError, IUpdateGoal> {
  const { getTokenSilently } = useAuth0();
  const { isDemo } = useDemoCookie();
  const updateGoal = async (param: IUpdateGoal): Promise<CreatedIdentifier> => {
    const token: string = isDemo === false ? await getTokenSilently() : null;
    return AlignGoalsServices.updateGoal({ goalId: param.goalId }, param.input, token);
  };

  const { mutate, isLoading, data, error, isError, isSuccess } = useMutation<
    CreatedIdentifier,
    IApiBasicError,
    IUpdateGoal
  >(updateGoal);

  return { mutate, isLoading, data, error, isError, isSuccess };
}
