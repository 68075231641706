import { Redirect } from 'react-router-dom';

import SettingsAllocation from '@common-pages/Settings/Allocation';
import SettingsProfile from '@common-pages/Settings/Profile';
import SettingsReleases from '@common-pages/Settings/Releases';
import { Users as SettingsUsers } from '@common-pages/Settings/Users';
import { Teams as SettingsTeam } from '@common-pages/Settings/teams';

const settingsSubRoutes = [
  {
    route: {
      path: '/settings',
      exact: true,
    },
    component: Redirect,
    componentProps: {
      to: '/settings/profile',
    },
  },
  {
    route: {
      path: '/settings/profile',
      exact: true,
    },
    component: SettingsProfile,
  },
  {
    route: {
      path: '/settings/teams',
      exact: true,
    },
    component: SettingsTeam,
  },
  {
    route: {
      path: '/settings/releases',
      exact: true,
    },
    component: SettingsReleases,
  },
  {
    route: {
      path: '/settings/allocation',
      exact: true,
    },
    component: SettingsAllocation,
  },
  {
    route: {
      path: '/settings/users',
      exact: true,
    },
    component: SettingsUsers,
  },
];

export default settingsSubRoutes;
