import React, { useEffect, useMemo } from 'react';

import { featuresList, isFeatureEnabled } from '@analytics-services/flags';
import { useUserContext } from '@common-context/User';
import { Maintenance as MaintenanceVisual } from '@lib/empty/maintenance';

let timeoutId;

const Maintenance = () => {
  const user = useUserContext();

  const isForcedMaintenance = useMemo(
    () => isFeatureEnabled(featuresList?.force_maintenance_page, user?.features) && !user?.isGod,
    [user]
  );

  useEffect(() => {
    checkAvailability(isForcedMaintenance);
  }, [isForcedMaintenance]);

  return (
    <div className="mt-2">
      <MaintenanceVisual />
    </div>
  );
};

const pingAPI = () => {
  const timestamp = +new Date();
  return fetch(new URL(`status?t=${timestamp}`, window.ENV.api.basePath).href, {
    redirect: 'error',
  });
};

const checkAvailability = (isForcedMaintenance) =>
  pingAPI()
    .then((r) => {
      if (!r.ok || isForcedMaintenance) {
        timeoutId = window.setTimeout(() => {
          clearTimeout(timeoutId);
          checkAvailability();
          if (isForcedMaintenance) window.location = '/maintenance';
        }, 5000);
      } else {
        window.location = '/';
      }
    })
    .catch(() => {
      timeoutId = window.setTimeout(() => {
        clearTimeout(timeoutId);
        checkAvailability();
      }, 5000);
    });

export default Maintenance;
export { pingAPI };
