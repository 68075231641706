import React, { useEffect } from 'react';

type TRefObject = React.RefObject<HTMLInputElement | HTMLDivElement>;
type TIdOrRefs = TRefObject | TRefObject[];

export const useOnClickOutside = (
  idOrRefs: TIdOrRefs,
  cb: (event: React.MouseEvent<HTMLInputElement | HTMLDivElement>) => any,
  deps?: any[]
) => {
  useEffect(() => {
    const idsOrRefs = !Array.isArray(idOrRefs) ? [idOrRefs] : idOrRefs;
    const objects = idsOrRefs.map((ior) => ior?.current || document.getElementById(ior.toString()));

    const callbackIfNotThese = (e) => {
      if (objects.every((r) => !!r && !r.contains(e.target))) {
        cb(e);
      }
    };

    const body = window.document.body;
    body.addEventListener('click', callbackIfNotThese);
    body.addEventListener('touchstart', callbackIfNotThese);
    return () => {
      body.removeEventListener('click', callbackIfNotThese);
      body.removeEventListener('touchstart', callbackIfNotThese);
    };
  }, [cb, idOrRefs, deps]);
};
