import { IReleasesPrRow } from '../releases.types';

export const searchTermByField = (
  releases: IReleasesPrRow[],
  fieldName: string,
  term: string
): IReleasesPrRow[] => {
  const filteredData: IReleasesPrRow[] = [];
  releases.forEach((release) => {
    if (Array.isArray(release?.[fieldName])) {
      if (release[fieldName].some((v) => v.toLowerCase().indexOf(term.toLowerCase()) >= 0)) {
        filteredData.push(release);
      }
    } else if (release[fieldName]?.toString().toLowerCase().indexOf(term.toLowerCase()) >= 0) {
      // add whole issue to the list if there is a match in its field
      filteredData.push(release);
    }
  });
  return filteredData;
};

export const searchTerm = (releases: IReleasesPrRow[], term: string): IReleasesPrRow[] => {
  // the list of searchable fields
  const fields = ['name', 'repository', 'status', 'author', 'reviewer'];
  const sepIndex = term.indexOf(':');
  const parts = sepIndex === -1 ? [term] : [term.slice(0, sepIndex), term.slice(sepIndex + 1)];
  let filteredData: IReleasesPrRow[] = [];
  if (parts.length > 1) {
    // in case field name was specified in the Search field
    const [fieldName, term] = parts;
    if (fields.indexOf(fieldName) >= 0) {
      filteredData = searchTermByField(releases, fieldName.trim(), term.trim());
    }
  } else {
    // in case field name was not specified we search in all searchable fields
    fields.forEach((fieldName) => {
      const matchingReleases = searchTermByField(releases, fieldName, term.trim());
      filteredData = filteredData.concat(matchingReleases);
    });
  }
  return filteredData;
};
