import { css } from '@emotion/react';

export const columnStyles = ({ color, font, spacing }) => css`
  align-items: center;
  display: flex;

  .prs-count {
    color: ${color.text.secondary};
    font-size: ${font.size.default};
    font-weight: ${font.weight.bold};
    margin-left: ${spacing.gap['02']};
  }

  .size-added {
    color: ${color.status.success};
    margin-left: ${spacing.gap['05']};
  }

  .size-removed {
    color: ${color.status.danger};
    margin-left: ${spacing.gap['03']};
  }
`;

export const badgeStyles = css`
  width: 159px;
`;

export const tableBodyStyles = ({ spacing }) => css`
  && td {
    &:not(:first-of-type) > div {
      justify-content: flex-start;
      padding: ${spacing.gap['0']} ${spacing.gap['04']};
    }
  }
`;

export const searchStyles = ({ spacing }) => css`
  align-items: baseline;
  display: flex;
  margin-bottom: -${spacing.gap['06']};

  .info-icon {
    margin-left: ${spacing.gap['02']};
  }
`;
