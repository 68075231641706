import { calculateGranularity } from '@analytics-components/Prefetcher';
import { ITeamAllocationTableData } from '@analytics-components/tables/TeamsAllocationTable/types';
import { contentTypes } from '@analytics-context/Allocation';
import { fetchPRsMetricsForSet } from '@analytics-services/api';
import { fetchJIRAMetrics } from '@analytics-services/api/jira';
import { LooseObject, IWorkType, ITeam } from '@analytics-types/common';
import { ContentTypes } from '@analytics-types/enums';
import ruleTypes, { IRuleType } from '@common-pages/Settings/Allocation/WorkTypes/ruleTypes';
import { FAKE_USERNAME } from '@common-pages/Settings/constants';
import * as NumberService from '@common-services/numberService';
import { isEqual } from '@common-services/vendor/lodash';

import {
  IGetJiraAssignees,
  IGetGranularity,
  IFetchPrsProps,
  IDistributeByGranularity,
  IAllocationType,
  IForSet,
  IGetTargetForSetParams,
  IGenerateDisplayData,
  IGenerateDisplayDataReturn,
  IFetchIssuesItem,
  IFetchIssuesProps,
} from './types';
import {
  IGetSelectedTeamsProps,
  ISumWorkTypeValuesData,
  IConvertTeamsToNestedProps,
  IGroupTeamsWithWorkTypesData,
  IGroupTeamsWithWorkTypesType,
  JiraFilterTypes,
} from './types';

export const getRule = (
  workType: IWorkType,
  contentType: ContentTypes,
  ruleType: IRuleType = undefined
) => {
  let rules;
  if (!!workType.rules?.length) {
    if (contentType === contentTypes.prs) {
      rules = workType.rules.filter((rule) => Object.keys(ruleTypes).includes(rule.name));
    } else {
      rules = workType.rules.filter((rule) =>
        Object.keys(ruleTypes)
          .filter((ruleName) => ruleTypes[ruleName].withJira === true)
          .includes(rule.name)
      );
    }
    return ruleType !== undefined ? rules.find((rule) => rule.name === ruleType.name) : rules;
  }
  return rules;
};

export const getLabels = (workType, contentType) => {
  const rule = getRule(workType, contentType, ruleTypes.githubLabel);
  if (!!rule?.body?.labels?.length) {
    return rule.body.labels.map((item) => item.label);
  }
  return [];
};

export const getProjects = (workType, contentType, allProjects) => {
  const rule = getRule(workType, contentType, ruleTypes.issueType);
  if (!!rule?.body?.projects?.length) {
    return rule.body.projects.map((item) => item.value);
  }
  return contentType === contentTypes.prs ? [] : allProjects.map((project) => project.key);
};

export const getIssueTypes = (workType, contentType, allIssueTypes) => {
  const rule = getRule(workType, contentType, ruleTypes.issueType);
  if (!!rule?.body?.issueTypes?.length) {
    return rule.body.issueTypes.map((item) => item.value);
  }
  return contentType === contentTypes.prs ? [] : allIssueTypes.map((issueType) => issueType.value);
};

export const getJiraLabels = (workType, contentType) => {
  const rule = getRule(workType, contentType, ruleTypes.jiraLabel);
  if (!!rule?.body?.jiraLabels?.length) {
    return rule.body.jiraLabels.map((item) => item.value);
  }
  return [];
};

export const sortData = (data, workTypes) => {
  const sortedData = [];
  // data array also includes an item that stands for all combinations of labels/projects/issue types
  // we need to put it at the end of the sorted array and that what foundIndices is used for
  const foundIndices = [];
  if (!!workTypes?.length) {
    workTypes.forEach((workType) => {
      const labels = getLabels(workType, contentTypes.prs);
      const jiraLabels = getJiraLabels(workType, contentTypes.prs);
      const projects = getProjects(workType, contentTypes.prs, null);
      const issueTypes = getIssueTypes(workType, contentTypes.prs, null);
      data.some((item, index) => {
        const foundCorrespondingItem =
          isEqual(item.for.labels_include || [], labels) &&
          isEqual(item.for.jira.projects || [], projects) &&
          isEqual(item.for.jira.issue_types || [], issueTypes) &&
          isEqual(item.for.jira.labels_include || [], jiraLabels);
        if (foundCorrespondingItem) {
          sortedData.push(item);
          // store the index to be able to identify later the remaining item that doesn't correspond to any work type
          foundIndices.push(index);
        }
        return foundCorrespondingItem;
      });
    });
    data.some((item, index) => {
      const isFound = foundIndices.indexOf(index) >= 0;
      if (!isFound) {
        sortedData.push(item);
      }
      return !isFound;
    });
  } else {
    return data;
  }
  return sortedData;
};

export const cutLongName = (string) => {
  if (string?.length > 15) {
    return `${string.substring(0, 14)}...`;
  }
  return string;
};

export const getSelectedTeams = ({ contributors, teams }: IGetSelectedTeamsProps): ITeam[] =>
  contributors
    // Unique by group
    .reduce((a, c) => (a.find((i) => i.group === c.group) ? a : [...a, c]), [])
    .map((item) => teams[teams.findIndex((o: ITeam) => o.name === item.group)])
    .filter((i) => i);

const sumWorkTypeValues = (data: ISumWorkTypeValuesData, allFieldName?: string): LooseObject => {
  let total = 0;
  const colData = data
    ? Object.entries(data).reduce((a: LooseObject, entry) => {
        const [name, value] = entry;
        const totalValue = value.reduce((i, j) => i + j.value, 0);
        if (!allFieldName || (allFieldName && allFieldName !== name)) {
          total += totalValue;
        }
        return {
          ...a,
          [name]: totalValue,
        };
      }, {})
    : {};

  if (allFieldName && colData[allFieldName]) {
    colData[allFieldName] = colData[allFieldName] - total;
    total += colData[allFieldName];
    colData[allFieldName] = Math.max(0, colData[allFieldName]);
  }

  return {
    ...Object.entries(colData).reduce((sum, entry) => {
      const [name, value] = entry;
      return {
        ...sum,
        [name]: value && (value / total) * 100,
      };
    }, {}),
    total,
  };
};

const fixExceedingMaxPercentage = (colData: LooseObject, allFieldName?: string): LooseObject => {
  const totalPercentage = Object.entries(colData).reduce(
    (a, c) => (c[0] === 'total' ? a : a + Math.round(c[1])),
    0
  );

  colData[allFieldName] = colData.total
    ? Math.max(0, colData[allFieldName] - (totalPercentage - 100))
    : 0;

  return colData;
};

export const convertTeamsToNested = ({
  teams,
  subTeams,
  data,
  rootTeamId,
  allFieldName,
}: IConvertTeamsToNestedProps) => {
  const teamsList = subTeams || teams || [];
  const teamIdsList = teamsList.map((team) => team.id);
  return teamsList
    .map(({ name, members, id, parent }) => {
      const colData = fixExceedingMaxPercentage(
        sumWorkTypeValues(data[name], allFieldName),
        allFieldName
      );

      return (
        (!parent || parent === rootTeamId || !teamIdsList.includes(parent)) && {
          name,
          pullRequests: colData.total,
          workTypesValues: colData,
          subRows: [
            ...members,
            ...convertTeamsToNested({
              rootTeamId,
              teams,
              data,
              subTeams: teams.filter((i) => i.parent === id).map(({ parent, ...others }) => others),
              allFieldName,
            }),
          ],
        }
      );
    })
    .filter((i) => i);
};
export const getTotalValues = (data: IGroupTeamsWithWorkTypesData[]): LooseObject =>
  data.reduce((a, c) => {
    const workTypeName = c.workType.name;
    const value = c.values[0].values[0];
    return {
      ...a,
      [workTypeName]: a[workTypeName] ? a[workTypeName] + value : value,
    };
  }, {});

export const groupTeamsWithWorkTypes = (
  data: IGroupTeamsWithWorkTypesData[],
  teams: ITeam[],
  type: IGroupTeamsWithWorkTypesType
): ITeamAllocationTableData => {
  // If it's jira issues
  // Remove undefined users from the list if there is an user without jira_user
  const reducedTeams =
    type === IGroupTeamsWithWorkTypesType.jira
      ? teams.reduce((a, c) => {
          return [
            ...a,
            {
              ...c,
              members: c.members.filter((m) => m.jira_user || m.name),
            },
          ];
        }, [])
      : teams;

  const groupByTeams: LooseObject | {} = data
    .reduce(
      (a, item) => [
        ...a,
        ...reducedTeams
          .filter((team) =>
            isEqual(
              team.members.map((m) => m.login),
              item.for.with.author
            )
          )
          .map((team) => ({
            ...item,
            team: team.name,
          })),
      ],
      []
    )
    // Group by team name
    .reduce(
      (a, c) =>
        a[c.team]
          ? {
              ...a,
              [c.team]: [...a[c.team], c],
            }
          : { ...a, [c.team]: [c] },
      {}
    );

  const groupByWorkTypes = {};

  Object.entries(groupByTeams).forEach(
    ([name, value]) =>
      (groupByWorkTypes[name] = value
        .map((item) => ({
          value: item.values[0].values[0] || 0,
          workTypeName: item.workType.name,
        }))
        .reduce(
          (a, c) =>
            a[c.workTypeName]
              ? {
                  ...a,
                  [c.workTypeName]: [...a[c.workTypeName], c],
                }
              : { ...a, [c.workTypeName]: [c] },
          {}
        ))
  );

  return groupByWorkTypes;
};

export const transformAppliedWorkTypes = (appliedWorkTypes: IWorkType[]): IWorkType[] => {
  const output = appliedWorkTypes.map(({ rules, ...otherFields }) => ({
    ...otherFields,
    rules: Object.keys(rules).length
      ? rules.reduce((ruleA, ruleC) => {
          return {
            ...ruleA,
            ...Object.entries(ruleC.body).reduce(
              (a, [key, value]: [string, LooseObject[]]) =>
                value.length
                  ? { ...a, [JiraFilterTypes[key]]: value.map((rule) => rule.value) }
                  : a,
              {}
            ),
          };
        }, {})
      : { emptyRuleName: otherFields.name },
  }));
  return output;
};

export const getJiraAssignees = ({
  contributors,
  includeNullContributor,
  includeFakeContributor,
}: IGetJiraAssignees): string[] => {
  const jiraAssignees = contributors.map((contributor) => contributor.jira_user).filter((v) => !!v);

  if (includeNullContributor) {
    jiraAssignees.push(null);
  }

  if (includeFakeContributor) {
    jiraAssignees.push(FAKE_USERNAME);
  }

  return jiraAssignees;
};

export const getGranularity = ({ interval }: IGetGranularity): string => {
  let granularityValue = calculateGranularity(interval);
  if (granularityValue === 'month') {
    granularityValue = `aligned ${granularityValue}`;
  }

  return granularityValue;
};

export const distributeByGranularity = ({ data, type }: IDistributeByGranularity) => {
  return data.reduce((a, c) => {
    if (type === IAllocationType.issues) {
      c.forEach((inner) => {
        if (!a[inner.granularity]) {
          a[inner.granularity] = [];
        }
        a[inner.granularity].push([inner]);
      });
    } else {
      if (!a[c.granularity]) {
        a[c.granularity] = [];
      }

      a[c.granularity] = [...a[c.granularity], c];
    }

    return a;
  }, {});
};

export const fetchIssues = async ({
  apiContext,
  api,
  granularity,
  projects,
  types,
  labels,
  assignees,
  jiraAssignees,
  workType,
}: IFetchIssuesProps): Promise<IFetchIssuesItem[]> => {
  const { account, interval, excludeInactive, epics } = apiContext;
  const response = await fetchJIRAMetrics(
    api,
    account,
    granularity,
    interval,
    ['resolved'],
    excludeInactive,
    {
      projects,
      types,
      epics,
      labels,
      priorities: null,
    },
    { assignees: assignees || jiraAssignees }
  );
  return response.map((byGranularity) => ({
    ...byGranularity,
    workType,
  }));
};

const getTargetForSet = ({ item, forSets }: IGetTargetForSetParams): IForSet =>
  forSets.find(({ issueTypes, jiraLabels, projects, labels }) =>
    isEqual(
      {
        issueTypes,
        jiraLabels,
        projects,
        labels,
      },
      {
        issueTypes: item.for?.jira?.issue_types || [],
        jiraLabels: item.for?.jira?.labels_include || [],
        projects: item.for?.jira?.projects || [],
        labels: item.for?.labels_include || [],
      }
    )
  );

export const fetchPrs = async ({ apiContext, api, granularity, forSets }: IFetchPrsProps) => {
  const { account, interval, excludeInactive, contributors, repositories } = apiContext;
  // Keep the 'all' value to have allocation table data
  const granularityArray = ['all', granularity];
  const response = await fetchPRsMetricsForSet(
    api,
    account,
    granularityArray,
    interval,
    ['lead-count'],
    forSets.map((forSet) => ({
      repositories,
      with: {
        author:
          forSet.authors || contributors.map((contributor) => contributor.login).filter((v) => !!v),
      },
      labels_include: forSet.labels,
      jira: {
        projects: forSet.projects,
        issue_types: forSet.issueTypes,
        labels_include: forSet.jiraLabels,
      },
    })),
    excludeInactive
  );

  return response?.calculated.map((item) => ({
    ...item,
    workType: getTargetForSet({ item, forSets }).workType,
  }));
};

export const generateDisplayData: IGenerateDisplayData = ({
  data,
  workTypes,
  type,
  allData,
  isPercentage,
  granular,
}) => {
  let totalPercentage = 0;

  const counts = data.map((data) => {
    const target = type === IAllocationType.issues ? data[0] : data;
    if (granular) {
      return {
        name: target?.workType?.name,
        values: target.values.map((val) => {
          return {
            date: val.date,
            value: val.values[0],
          };
        }),
      };
    }

    return {
      name: target?.workType?.name,
      count: target.values[0].values[0],
    };
  });

  const totals: IGenerateDisplayDataReturn[] = workTypes
    .map((item) => {
      const targetItem = counts.find((typeValue) => typeValue.name === item.name);
      const count = targetItem?.count || 0;
      const values = targetItem?.values || [];
      const percentage = allData && count ? NumberService.round((count * 100) / allData) : 0;
      totalPercentage += percentage;

      return {
        type: item.name,
        name: item.name,
        color: `#${item.color}`,
        count: isPercentage ? percentage : count,
        values,
      };
    })
    .sort((a, b) => {
      if (granular) {
        return a.values.reduce((acc, item) => acc + item.value, 0) >
          b.values.reduce((acc, item) => acc + item.value, 0)
          ? -1
          : 1;
      }
      return a.count > b.count ? -1 : 1;
    });

  const amount = !!data?.length
    ? data.reduce((acc, val) => {
        const targetVal = type === IAllocationType.issues ? val[0] : val;
        return acc + targetVal.values[0].values[0];
      }, 0)
    : 0;

  let otherCount;
  let otherValues = [];

  if (granular) {
    const granularTotals = [];
    totals.forEach((c) => {
      c.values.forEach((step) => {
        const index = granularTotals.findIndex(
          (currentStep) => currentStep.date.toString() === step.date.toString()
        );

        if (index === -1) {
          granularTotals.push({ ...step });
        } else {
          granularTotals[index].value += step.value;
        }
      });
    });

    allData.forEach((allDataItem) => {
      const index = granularTotals.findIndex(
        (dateStep) => dateStep.date.toString() === allDataItem.date.toString()
      );
      otherValues.push({
        date: allDataItem.date,
        value: allDataItem.values[0] - granularTotals[index]?.value,
      });
    });
  }

  if (isPercentage) {
    otherCount = totalPercentage < 100 ? 100 - totalPercentage : 0;
  } else {
    otherCount = allData > amount ? allData - amount : 0;
  }

  totals.push({
    name: totals?.length ? 'Other' : 'All',
    type: totals?.length ? 'Other' : 'All',
    count: otherCount,
    values: otherValues,
  });

  return totals;
};
