import { css } from '@emotion/react';
import classnames from 'classnames';
import _ from 'lodash';
import React from 'react';

import { altTitleStyles, bigNumberStyles, cardTitleStyles, smallTitleStyles } from './styles';

export const nbsp = '\u00A0';

export const BigNumber = ({ content, isXL, isLight, className }) => (
  <span css={(theme) => bigNumberStyles(theme, isXL, isLight)} className={classnames(className)}>
    {content}
  </span>
);

export const SmallTitle = ({ content, isBlack = false, isUppercase = false, className = null }) => (
  <span
    css={(theme) => smallTitleStyles(theme, isUppercase, isBlack)}
    className={classnames(className)}
  >
    {content}
  </span>
);

export const CardTitle = ({ content }) => <span css={cardTitleStyles}>{content}</span>;

const listStyles = ({ color, font }) => css`
  display: flex;
  white-space: nowrap;

  .additional-label {
    color: ${color.neutral[100]};
    font-weight: ${font.weight.bold};
  }
`;

export const AltTitle = ({
  uppercase = false,
  inline = false,
  content,
  noMargin = false,
  link = false,
}) => (
  <div css={(theme) => altTitleStyles(theme, uppercase, inline, noMargin, link)}>
    {_.isArray(content) ? (
      <div css={listStyles}>
        {content.slice(0, 2).join(', ')}
        {content.length > 2 && <div className="additional-label">, +{content.length - 2}</div>}
      </div>
    ) : (
      content
    )}
  </div>
);

export const RegularTitle = ({ content, uppercase = false }) => (
  <span
    className={classnames('d-inline-block align-middle text-m regular-title', {
      'text-uppercase': uppercase,
    })}
  >
    {content}
  </span>
);

export const SecondaryNumber = ({ content }) => {
  return <span className="secondary-number">{content}</span>;
};
