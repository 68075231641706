import _ from 'lodash';

import { calculateGranularity } from '@analytics-components/Prefetcher';
import MultiLineChart from '@analytics-components/charts/MultiLineChart';
import { fetchCIMetrics } from '@analytics-services/api';
import { getFeature, featuresList } from '@analytics-services/flags';
import { dateTime } from '@common-services/dateService';
import { github } from '@common-services/format';
import { theme } from '@styles/theme';

const runTimePerRepository = {
  fetcher: async (api, cachedData, apiContext) => {
    const {
      account,
      interval,
      contributors,
      repositories,
      labels,
      features,
      epics,
      issueTypes,
    } = apiContext;

    let granularity = calculateGranularity(interval);
    if (granularity === 'month') {
      granularity = `aligned ${granularity}`;
    }

    // take only 5 repositories with highest Suite Runs
    const repos = Object.keys(cachedData['repos-ci-metrics'])
      .sort((a, b) =>
        cachedData['repos-ci-metrics'][a]['chk-suites-count'] >
        cachedData['repos-ci-metrics'][b]['chk-suites-count']
          ? -1
          : 1
      )
      .filter((repo) => _(repositories).includes(repo))
      .slice(0, 5);

    const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, features)?.parameters
      ?.value;

    const data = await fetchCIMetrics(
      api,
      account,
      interval,
      [granularity],
      ['chk-suite-time'],
      repos,
      contributors,
      labels,
      excludedLabels || [],
      { epics, issue_types: issueTypes },
      'repositories'
    );

    return data.calculated;
  },
  plumber: (fetchedData, cachedData, apiContext) => {
    const customGranularity = cachedData['ci-metrics'].customGranularity;
    const tickFormat = _(customGranularity).includes('month') ? dateTime.month : dateTime.monthDay;
    const { interval } = apiContext;

    const chartData = _(fetchedData)
      .map((repo, i) => ({
        color: theme.color.sets.six[i],
        data: repo.values.map((item) => ({
          x: item.date,
          y: item.values[0],
        })),
        label: github.repoName(repo.for.repositories[0]),
      }))
      .value();

    const isNotEmpty = chartData.some((v) => v.data.some((d) => d.y));

    return {
      empty: !isNotEmpty,
      chart: {
        component: MultiLineChart,
        params: {
          data: {
            interval,
            linesData: chartData,
          },
          extra: {
            chartHeight: 438,
            margin: {
              left: 60,
            },
            strokeWidth: 2,
            ticks: {
              x: {
                maxNumberOfTicks: 10,
                tickFormat,
              },
              y: {
                maxNumberOfTicks: 5,
                tickFormat: (v) => dateTime.timeValue(v),
              },
            },
            tooltip: {
              valueFormat: (v) => dateTime.timeValue(v),
            },
          },
        },
      },
      kpis: [],
    };
  },
  prefetchedDataIds: ['ci-metrics', 'repos-ci-metrics'],
};

export default runTimePerRepository;
