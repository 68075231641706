import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Simple from '@analytics-pages/templates/Simple';
import { useAuth0 } from '@common-context/Auth0';

const SignUp = () => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();
  const location = useLocation();
  const inviteLink = location.state?.inviteLink;

  useEffect(() => {
    if (!isAuthenticated && inviteLink) {
      loginWithRedirect({
        appState: { inviteLink },
        extra: 'purpose=signup',
      });
    }
  }, []);

  if (!inviteLink) {
    return <NoInvitation />;
  }

  if (loading) {
    return (
      <Simple>
        <div className="my-5">
          <p className="text-secondary">Loading...</p>
        </div>
      </Simple>
    );
  }

  if (isAuthenticated) {
    return (
      <Simple>
        <div className="my-5">
          <p className="text-secondary">
            You must <Link to="/logout">logout</Link> first, and then open the invitation link.
          </p>
        </div>
      </Simple>
    );
  }

  return null;
};

const NoInvitation = () => (
  <Simple>
    <div className="my-5">
      <h2 className="text-dark h4 mb-2">Welcome!</h2>
      <p className="text-secondary">You need an invitation link to create your Athenian account</p>
    </div>

    <div className="my-5">
      <a className="text-dark-orange" href="https://www.athenian.co">
        I don’t have an account
      </a>
    </div>
  </Simple>
);

export default SignUp;
