import { useEffect, useReducer, useRef } from 'react';

import {
  IMergedIssuesAction,
  IMergedIssuesState,
} from '@align-pages/goals/single/components/goal-view-component/goalViewComponent.types';
import { PrsIssuesTableServices } from '@align-services/prsIssuesTableService';
import { IDateRangeInMls, PrsIssuesTableTabs } from '@align-types/common';
import { SearchJIRAIssuesResponse } from '@common-services/api/private/generated-from-backend/models';
import { GetJIRAIssuesResponse } from '@common-services/api/public/generated-from-backend/models';
import { isEqual } from '@common-services/vendor/lodash';

const initialState = {
  completed: null,
  inProgress: null,
};

const reducer = (state: IMergedIssuesState, action: IMergedIssuesAction): IMergedIssuesState => {
  if (!action.data) {
    return { ...state, completed: null };
  }
  let mergedSet: GetJIRAIssuesResponse;
  if (action?.data.issues.length >= 0) {
    if (action?.tabIndex === PrsIssuesTableTabs.COMPLETED) {
      mergedSet = PrsIssuesTableServices.mergeIssuesDataPortions(state.completed, action.data);
    } else {
      mergedSet = PrsIssuesTableServices.mergeIssuesDataPortions(state.inProgress, action.data);
    }
  }
  return {
    ...state,
    ...(action?.tabIndex === PrsIssuesTableTabs.COMPLETED
      ? { completed: mergedSet }
      : { inProgress: mergedSet }),
  };
};

export function useMergeIssuesData(
  issuesTableDataPortion: GetJIRAIssuesResponse,
  activeTab: PrsIssuesTableTabs,
  selectedRange: IDateRangeInMls,
  allCompletedIssues?: SearchJIRAIssuesResponse
): GetJIRAIssuesResponse {
  const [mergedIssuesData, dispatchIssuesData] = useReducer(reducer, initialState);
  const prevData = useRef(issuesTableDataPortion);

  // clean the state of completed issues whenever Timeframe value or set of all completed issues changed
  useEffect(() => {
    dispatchIssuesData({ data: null });
  }, [selectedRange, allCompletedIssues]);

  useEffect(() => {
    if (issuesTableDataPortion) {
      // do not merge data if it did not change from the previous time
      if (!isEqual(prevData.current, issuesTableDataPortion)) {
        dispatchIssuesData({ data: issuesTableDataPortion, tabIndex: activeTab });
        prevData.current = issuesTableDataPortion;
      }
    }
  }, [activeTab, issuesTableDataPortion, prevData]);

  return activeTab === PrsIssuesTableTabs.COMPLETED
    ? mergedIssuesData.completed
    : mergedIssuesData.inProgress;
}
