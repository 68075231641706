import React from 'react';

import { DATA_STATUS } from '@analytics-components/StatusIndicator';
import { getMetrics } from '@analytics-components/Thumbnails';
import { SummaryBoxKPIs, SummaryMatrixKPIs } from '@analytics-components/insights/SummaryBox';
import { StagesEnum } from '@analytics-constants';
import { useBreadcrumb } from '@analytics-context/Breadcrumb';
import DataWidget from '@analytics-context/DataWidget';
import { theme } from '@styles/theme';
import { unslugify } from '@utils/index';

import SummaryBoxDataBinder from './SummaryBoxDataBinder';
import buildKPIs from './services/buildKPI';
import commonPlumber from './services/commonPlumber';
import { titleBadgeStyles } from './styles';

export const noDataStatus = {
  wip: DATA_STATUS.NO_SUBMITTED,
  review: DATA_STATUS.NO_REVIEWED,
  merge: DATA_STATUS.NO_MERGED,
  release: DATA_STATUS.NO_RELEASES,
};

const SummaryBox = () => {
  const { subsection: activeStage } = useBreadcrumb();

  const plumber = (fetchedData, cachedData, apiContext) => {
    const { metric, metricCount, metricCountQ } = getMetrics(activeStage);
    return {
      kpisData: buildKPIs(activeStage, cachedData, apiContext.interval),
      interval: apiContext.interval,
      ...commonPlumber(cachedData, metric, metricCount, metricCountQ, noDataStatus[activeStage]),
    };
  };

  const title =
    activeStage === StagesEnum.DEPLOY ? (
      <div>
        {unslugify(activeStage)}
        <span css={titleBadgeStyles}>Production</span>
      </div>
    ) : (
      unslugify(activeStage)
    );

  const dataBinderProps =
    activeStage === StagesEnum.SUMMARY
      ? {
          title: 'Pull Request Cycle Time',
          color: theme.color.ui.blue[100],
          className: 'leadtime',
          renderKPI: (data) => <SummaryBoxKPIs data={data} />,
        }
      : {
          title,
          color: theme.color.stage[activeStage],
          className: activeStage,
          renderKPI: (data) => <SummaryMatrixKPIs kpis={data} numberOfCols={2} />,
        };

  return (
    <DataWidget
      id={`summary-box-${activeStage}`}
      plumber={plumber}
      prefetchedDataIds={[
        'prs-metrics',
        'prs-histogram',
        'devs-metrics',
        'repos-metrics',
        'releases-metrics',
        ...(activeStage === StagesEnum.DEPLOY ? ['deployments', 'environments'] : []),
      ]}
    >
      <SummaryBoxDataBinder activeStage={activeStage} {...dataBinderProps} />
    </DataWidget>
  );
};

export default SummaryBox;
