import _ from 'lodash';

import { calculateGranularity } from '@analytics-components/Prefetcher';
import { BigText, DateRangeBigNumber } from '@analytics-components/charts/Tooltip';
import VerticalBarChart from '@analytics-components/charts/VerticalBarChart';
import { SimpleKPI } from '@analytics-components/insights/KPI';
import { fetchDevsMetrics } from '@analytics-services/api';
import { getFeature, featuresList } from '@analytics-services/flags';
import { dateTime } from '@common-services/dateService';
import { theme } from '@styles/theme';

const prsRatioFlowTimeseries = {
  fetcher: async (api, cachedData, apiContext) => {
    const {
      account,
      interval,
      repositories,
      contributors,
      labels,
      features,
      epics,
      issueTypes,
    } = apiContext;

    let granularity = calculateGranularity(interval);
    if (granularity === 'month') {
      granularity = `aligned ${granularity}`;
    }

    const excludedLabels = getFeature(featuresList.exclude_prs_by_labels, features)?.parameters
      ?.value;

    const developers = _(contributors)
      .filter((c) => c.login) // exclude pure Jira users from chart data
      .uniqBy('login')
      .value();
    const activeContributorsData = await fetchDevsMetrics(
      api,
      account,
      [granularity],
      interval,
      ['worked'],
      {
        developers,
        repositories,
        labels_include: labels,
        ...(excludedLabels ? { labels_exclude: excludedLabels } : {}),
        jira: { epics, issue_types: issueTypes },
      }
    );

    return {
      activeContributors:
        activeContributorsData.calculated.length > 0
          ? activeContributorsData.calculated[0].values
          : null,
      activeDevelopers:
        activeContributorsData.calculated.length > 0
          ? activeContributorsData.calculated[0].for.developers
          : null,
    };
  },
  plumber: (fetchedData, cachedData, apiContext) => {
    const customGranularity = cachedData['prs-metrics'].customGranularity;
    const tickFormat = customGranularity === 'month' ? dateTime.month : dateTime.monthDay;

    let activeDevs = {};
    const chartData = fetchedData.activeContributors
      ? fetchedData.activeContributors[0].map((contrib, index) => ({
          day: contrib.date,
          value: fetchedData.activeContributors.reduce((acc, v, devIndex) => {
            if (v[index].values[0]) {
              activeDevs[fetchedData.activeDevelopers[devIndex]] = 1;
              return acc + 1;
            }
            return acc;
          }, 0),
        }))
      : [];

    const KPIsData = {
      activeContributors: Object.keys(activeDevs).length,
    };

    const tooltip = {
      align: {
        horizontal: 'auto',
        vertical: 'top',
      },
      renderBigFn: (v) => <BigText content={`${v.y} contributor${v.y === 1 ? '' : 's'}`} />,
    };
    if (_(['month', 'week']).includes(customGranularity)) {
      tooltip.template = DateRangeBigNumber;
      tooltip.interval = { ...apiContext.interval };
      tooltip.granularity = customGranularity;
    }

    return {
      empty: chartData.length === 0,
      chart: {
        component: VerticalBarChart,
        params: {
          data: chartData,
          timeMode: true,
          extra: {
            axisLabels: {
              y: 'Contributors',
            },
            axisFormat: {
              tickFormat: {
                x: tickFormat,
              },
            },
            margin: { bottom: 60 },
            maxNumberOfTicks: Math.min(Object.keys(activeDevs).length, 6),
            axisKeys: {
              x: 'day',
              y: 'value',
            },
            color: theme.color.ui.blue[100],
            tooltip,
          },
        },
      },
      kpis: [
        {
          title: { text: 'Contributors', bold: true },
          subtitle: { text: 'Total' },
          component: SimpleKPI,
          params: {
            value: KPIsData.activeContributors,
          },
        },
      ],
    };
  },
  prefetchedDataIds: ['prs-metrics'],
};

export default prsRatioFlowTimeseries;
