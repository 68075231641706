/**
 * {{ title }}
 * Server version: {{ server_version }} built on {{ build_date }} @ [{{ commit }}](https://github.com/athenianco/athenian-api/commit/{{ commit }})  Origin GitHub repository: [athenianco/api-spec](https://github.com/athenianco/api-spec).  Authorization persists between sessions. 🛡️ means that the endpoint requires account admin privileges. 👑 means that the endpoint is only accessible to Athenian authorities. <details> <summary>List of common server errors.</summary> * __500__ endpoint crashed. We reported the incident to Sentry and will fix it soon! Please include the value of `instance` in the response if you contact the support. Repeating the request will not help, most probably. * __501__ some functions are missing, either because they are not implemented yet or a non-critical runtime dependency is not satisfied. Repeating the request will not help, guaranteed. * __502__ server crashed badly, either due to a memory access violation in native code or running out of memory. We reported this incident to Sentry and will fix it soon! Try repeating the request. * __503__ server has not fully launched yet, e.g. hasn't connected to the database; server is shutting down; we are experiencing a partial outage. Try repeating the request. * __504__ endpoint took too much time and was interrupted. We reported the incident to Sentry and will see how to improve the performance. Repeating the request will not help, most probably. </details>
 *
 * Contact: admin@athenian.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import JIRAEpicChildAllOf from './JIRAEpicChildAllOf';
import JIRAEpicIssueCommon from './JIRAEpicIssueCommon';

/**
 * The JIRAEpicChild model module.
 * @module model/JIRAEpicChild
 */
class JIRAEpicChild {
    /**
     * Constructs a new <code>JIRAEpicChild</code>.
     * Details about a JIRA issue contained in an epic.
     * @alias module:model/JIRAEpicChild
     * @implements module:model/JIRAEpicIssueCommon
     * @implements module:model/JIRAEpicChildAllOf
     * @param id {String} JIRA issue key `PROJECT-###`.
     * @param title {String} Title of this issue.
     * @param created {Date} When this issue was created.
     * @param updated {Date} When this issue was last updated.
     * @param lifeTime {Object} 
     * @param reporter {String} Name of the person who reported the issue.
     * @param comments {Number} Number of comments in the issue excluding sub-tasks and children.
     * @param priority {String} Name of the priority. The details are returned in `FilteredJIRAStuff.priorities`.
     * @param status {String} Name of the status. The details are returned in `FilteredJIRAStuff.statuses`.
     * @param type {String} Name of the issue type. The details are returned in `FilteredJIRAStuff.issue_types`.
     * @param url {String} Link to the issue in JIRA web application.
     * @param subtasks {Number} Number of sub-tasks.
     * @param prs {Number} Number of mapped pull requests.
     */
    constructor(id, title, created, updated, lifeTime, reporter, comments, priority, status, type, url, subtasks, prs) { 
        JIRAEpicIssueCommon.initialize(this, id, title, created, updated, lifeTime, reporter, comments, priority, status, type, url);JIRAEpicChildAllOf.initialize(this, subtasks, prs);
        JIRAEpicChild.initialize(this, id, title, created, updated, lifeTime, reporter, comments, priority, status, type, url, subtasks, prs);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, title, created, updated, lifeTime, reporter, comments, priority, status, type, url, subtasks, prs) { 
        obj['id'] = id;
        obj['title'] = title;
        obj['created'] = created;
        obj['updated'] = updated;
        obj['life_time'] = lifeTime;
        obj['reporter'] = reporter;
        obj['comments'] = comments;
        obj['priority'] = priority;
        obj['status'] = status;
        obj['type'] = type;
        obj['url'] = url;
        obj['subtasks'] = subtasks;
        obj['prs'] = prs;
    }

    /**
     * Constructs a <code>JIRAEpicChild</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/JIRAEpicChild} obj Optional instance to populate.
     * @return {module:model/JIRAEpicChild} The populated <code>JIRAEpicChild</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new JIRAEpicChild();
            JIRAEpicIssueCommon.constructFromObject(data, obj);
            JIRAEpicChildAllOf.constructFromObject(data, obj);

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('created')) {
                obj['created'] = ApiClient.convertToType(data['created'], 'Date');
            }
            if (data.hasOwnProperty('updated')) {
                obj['updated'] = ApiClient.convertToType(data['updated'], 'Date');
            }
            if (data.hasOwnProperty('work_began')) {
                obj['work_began'] = ApiClient.convertToType(data['work_began'], 'Date');
            }
            if (data.hasOwnProperty('resolved')) {
                obj['resolved'] = ApiClient.convertToType(data['resolved'], 'Date');
            }
            if (data.hasOwnProperty('lead_time')) {
                obj['lead_time'] = ApiClient.convertToType(data['lead_time'], Object);
            }
            if (data.hasOwnProperty('life_time')) {
                obj['life_time'] = ApiClient.convertToType(data['life_time'], Object);
            }
            if (data.hasOwnProperty('reporter')) {
                obj['reporter'] = ApiClient.convertToType(data['reporter'], 'String');
            }
            if (data.hasOwnProperty('assignee')) {
                obj['assignee'] = ApiClient.convertToType(data['assignee'], 'String');
            }
            if (data.hasOwnProperty('comments')) {
                obj['comments'] = ApiClient.convertToType(data['comments'], 'Number');
            }
            if (data.hasOwnProperty('priority')) {
                obj['priority'] = ApiClient.convertToType(data['priority'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = ApiClient.convertToType(data['type'], 'String');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
            if (data.hasOwnProperty('subtasks')) {
                obj['subtasks'] = ApiClient.convertToType(data['subtasks'], 'Number');
            }
            if (data.hasOwnProperty('prs')) {
                obj['prs'] = ApiClient.convertToType(data['prs'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * JIRA issue key `PROJECT-###`.
 * @member {String} id
 */
JIRAEpicChild.prototype['id'] = undefined;

/**
 * Title of this issue.
 * @member {String} title
 */
JIRAEpicChild.prototype['title'] = undefined;

/**
 * When this issue was created.
 * @member {Date} created
 */
JIRAEpicChild.prototype['created'] = undefined;

/**
 * When this issue was last updated.
 * @member {Date} updated
 */
JIRAEpicChild.prototype['updated'] = undefined;

/**
 * When the issue entered the \"In Progress\" stage or received a PR. This timestamp can be missing and is always less than or equal to `resolved`.
 * @member {Date} work_began
 */
JIRAEpicChild.prototype['work_began'] = undefined;

/**
 * When the issue finished: the stage is \"Done\" and all PRs are either released or rejected. This timestamp can be missing and is always greater than or equal to `work_began`.
 * @member {Date} resolved
 */
JIRAEpicChild.prototype['resolved'] = undefined;

/**
 * @member {Object} lead_time
 */
JIRAEpicChild.prototype['lead_time'] = undefined;

/**
 * @member {Object} life_time
 */
JIRAEpicChild.prototype['life_time'] = undefined;

/**
 * Name of the person who reported the issue.
 * @member {String} reporter
 */
JIRAEpicChild.prototype['reporter'] = undefined;

/**
 * Name of the assigned person.
 * @member {String} assignee
 */
JIRAEpicChild.prototype['assignee'] = undefined;

/**
 * Number of comments in the issue excluding sub-tasks and children.
 * @member {Number} comments
 */
JIRAEpicChild.prototype['comments'] = undefined;

/**
 * Name of the priority. The details are returned in `FilteredJIRAStuff.priorities`.
 * @member {String} priority
 */
JIRAEpicChild.prototype['priority'] = undefined;

/**
 * Name of the status. The details are returned in `FilteredJIRAStuff.statuses`.
 * @member {String} status
 */
JIRAEpicChild.prototype['status'] = undefined;

/**
 * Name of the issue type. The details are returned in `FilteredJIRAStuff.issue_types`.
 * @member {String} type
 */
JIRAEpicChild.prototype['type'] = undefined;

/**
 * Link to the issue in JIRA web application.
 * @member {String} url
 */
JIRAEpicChild.prototype['url'] = undefined;

/**
 * Number of sub-tasks.
 * @member {Number} subtasks
 */
JIRAEpicChild.prototype['subtasks'] = undefined;

/**
 * Number of mapped pull requests.
 * @member {Number} prs
 */
JIRAEpicChild.prototype['prs'] = undefined;


// Implement JIRAEpicIssueCommon interface:
/**
 * JIRA issue key `PROJECT-###`.
 * @member {String} id
 */
JIRAEpicIssueCommon.prototype['id'] = undefined;
/**
 * Title of this issue.
 * @member {String} title
 */
JIRAEpicIssueCommon.prototype['title'] = undefined;
/**
 * When this issue was created.
 * @member {Date} created
 */
JIRAEpicIssueCommon.prototype['created'] = undefined;
/**
 * When this issue was last updated.
 * @member {Date} updated
 */
JIRAEpicIssueCommon.prototype['updated'] = undefined;
/**
 * When the issue entered the \"In Progress\" stage or received a PR. This timestamp can be missing and is always less than or equal to `resolved`.
 * @member {Date} work_began
 */
JIRAEpicIssueCommon.prototype['work_began'] = undefined;
/**
 * When the issue finished: the stage is \"Done\" and all PRs are either released or rejected. This timestamp can be missing and is always greater than or equal to `work_began`.
 * @member {Date} resolved
 */
JIRAEpicIssueCommon.prototype['resolved'] = undefined;
/**
 * @member {Object} lead_time
 */
JIRAEpicIssueCommon.prototype['lead_time'] = undefined;
/**
 * @member {Object} life_time
 */
JIRAEpicIssueCommon.prototype['life_time'] = undefined;
/**
 * Name of the person who reported the issue.
 * @member {String} reporter
 */
JIRAEpicIssueCommon.prototype['reporter'] = undefined;
/**
 * Name of the assigned person.
 * @member {String} assignee
 */
JIRAEpicIssueCommon.prototype['assignee'] = undefined;
/**
 * Number of comments in the issue excluding sub-tasks and children.
 * @member {Number} comments
 */
JIRAEpicIssueCommon.prototype['comments'] = undefined;
/**
 * Name of the priority. The details are returned in `FilteredJIRAStuff.priorities`.
 * @member {String} priority
 */
JIRAEpicIssueCommon.prototype['priority'] = undefined;
/**
 * Name of the status. The details are returned in `FilteredJIRAStuff.statuses`.
 * @member {String} status
 */
JIRAEpicIssueCommon.prototype['status'] = undefined;
/**
 * Name of the issue type. The details are returned in `FilteredJIRAStuff.issue_types`.
 * @member {String} type
 */
JIRAEpicIssueCommon.prototype['type'] = undefined;
/**
 * Link to the issue in JIRA web application.
 * @member {String} url
 */
JIRAEpicIssueCommon.prototype['url'] = undefined;
// Implement JIRAEpicChildAllOf interface:
/**
 * Number of sub-tasks.
 * @member {Number} subtasks
 */
JIRAEpicChildAllOf.prototype['subtasks'] = undefined;
/**
 * Number of mapped pull requests.
 * @member {Number} prs
 */
JIRAEpicChildAllOf.prototype['prs'] = undefined;




export default JIRAEpicChild;

